import React, { useContext, useState, useLayoutEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import renderRawHtml from "../../../../common/render_raw_html";
import { baseContext, fraudContext } from '../../common/contexts';
import { savePasteInfo } from '../../common/logic/fraud_check';
import PageLoader from '../../common/components/page_loader';
import StartButton from './start_button';

const GoogleRecaptcha = ({ nextStep, loadingOnly, projectId, respondentId }) => {
  const [ showStartButton, setShowStartButton ] = useState(false);
  const { googleCaptchaSiteKey } = useContext(fraudContext);
  const { translations, fullLanguageCode } = useContext(baseContext);
  const languageCode = (fullLanguageCode || '').split('_')[0];

  const onChange = () => {
    nextStep();
  };

  const handleErrored = () => {
    setShowStartButton(true);
  };

  useLayoutEffect(() => {
    if (loadingOnly || !projectId || !respondentId) {
      return;
    }
    savePasteInfo(projectId, respondentId);
  }, []);

  return (
    <div className="survey-layout_content -start">
      {
        !loadingOnly &&
        <div className="survey-start">
          <h3
            className="survey-start_title"
            { ...renderRawHtml(translations.survey_captcha) }
          />
          <div className="survey-start_recaptcha">
            {
              !showStartButton &&
              <ReCAPTCHA
                hl={ languageCode }
                sitekey={ googleCaptchaSiteKey }
                onChange={ onChange }
                onErrored={ handleErrored }
              />
            }
            { showStartButton && <StartButton nextStep={ nextStep } /> }
          </div>
        </div>
      }
      { loadingOnly && <PageLoader loading /> }
    </div>
  );
};

export default GoogleRecaptcha;
