import React, { useContext } from "react";
import { filter } from "lodash";
import { IndexData } from "../../common/index-context";
import FiltersDropdown from './filtersDropdown';
import SliderRadioButtons from './sliderRadioButtons';

const ComparisonsFilter = () => {
  const { data, setSelectedOptions, selectedBsg, setSelectedBsg, loadData } = useContext(IndexData);

  const onChangeBase = (v) => {
    setSelectedOptions([]);
    const filters = { selected_bsg: v, selected_sub_groups: [] };
    setSelectedBsg(v);
    loadData(filters, null, null, null, { fromUrl: true });
  };

  const subGroupsWithoutBsg = filter(data.sub_groups, (el) => (el.value !== selectedBsg));

  return (
    <div className="comparisons_filters">
      <div className="comparisons_grid -wrap">
        {data.bsg &&
          <div className="comparisons_grid-item -fix">
            <SliderRadioButtons
              initValue={ selectedBsg }
              options={
                [
                  { value: data.bsg.value, title: data.bsg.label },
                  { value: 'total', title: 'Total' }
                ]
              }
              onChange={ (v) => { onChangeBase(v); } }
              modificator="-full-width -white-bg -bordered bsg-selector"
            />
          </div>}

        <div className="comparisons_grid-item -grow">
          <div className="comparisons_grid">
            <div className="comparisons_grid-item">
              <FiltersDropdown options={ subGroupsWithoutBsg } />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonsFilter;
