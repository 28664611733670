import React from "react";
import { map } from "lodash";
import Ad from "./ad";

const DataWrapper = ({ data, videosData, setVideosData }) => {
  const updateVideoData = (index) => (url, metaData) => {
    setVideosData((oldList) => {
      oldList[index].url = url;
      oldList[index].metaData = metaData;
      return [ ...oldList ];
    });
  };

  return (
    <ul className="products-greed-list">
      {
        map(data, (el, index) => (
          <li key={ index } className="products-greed-item">
            <Ad
              data={ el }
              videoData={ videosData[index] }
              updateVideo={ updateVideoData(index) }
              previousVideoData={ index && videosData[index - 1].url ? videosData[index - 1] : null }
            />
          </li>
        ))
      }
    </ul>
  );
};

export default DataWrapper;
