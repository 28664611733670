import React, { useState } from "react";
import { StateContext } from "../common/contexts";
import { Router,  initialState } from './components/router';


const ConceptA = ({ excludeShared }) => {
  const [path, updatePath] = useState(initialState);
  return(
    <div className="top-split-div">
      <StateContext.Provider value={ [path, updatePath] }>
        <Router excludeShared={ excludeShared } />
      </StateContext.Provider>
    </div>
  )
};

export default ConceptA;