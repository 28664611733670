import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { spotlightsDetailsContext } from '../contexts';
import PortalModal from '../common/portal';

const VideoPlayer = ({ title, url, onClose }) => {
  return (
    <PortalModal
      className="-sm"
      title={ title }
      open
      handleClose={ onClose }
      hideFooter
    >
      <video className="portal-video" controls autoPlay>
        <source src={ url } type="video/mp4" />
      </video>
    </PortalModal>
  );
};

const Video = ({ name, videoUrl, className }) => {
  const spotlightContextHash = useContext(spotlightsDetailsContext);
  const playerName = name || spotlightContextHash.name;
  const playerVideoUrl = videoUrl || spotlightContextHash.video_url;
  const [ showVideo, setShowVideo ] = useState(false);
  const openVideoPlayer = () => {
    setShowVideo(true);
  };
  const closeVideoPlayer = () => {
    setShowVideo(false);
  };
  return (
    <>
      {
        playerVideoUrl &&
        <div
          className={ classnames("customer-spotlight_video", className) }
          onClick={ openVideoPlayer }
        />
      }
      {
        showVideo &&
        <VideoPlayer
          title={ playerName }
          url={ playerVideoUrl }
          onClose={ closeVideoPlayer }
        />
      }
    </>
  );
};

export default Video;
