import React, { Fragment, useContext, useState, useLayoutEffect } from 'react';
import { map, compact, find, findIndex } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../common/render_raw_html';
import {
  LineDataContext, SetContext, PageContext,
  CloseCarrotsContext,
  MobileCompareContext
} from '../../common/contexts';
import { Header } from '../../common/header';
import { Subheader } from '../../common/subheader';
import { getCurrentLine, updateHashByKey } from '../../common/statistics';
import { BAU, perkPacks } from '../../common/plans_data';
import { AllPlansInclude } from "../../common/allPlansIncluded";
import Dropdown from '../../common/dropdown';

const { compareList } = BAU;

const ComparePlanHead = ({
  plan,
  onPlanSelect,
  plans,
  chosenPlanIds,
  perkPackIds,
  updateChosenPlanIds,
  colIndex
}) => {
  const { delayAnimation } = useContext(SetContext);
  const {
    getClickedSelectButton,
    setClickedSelectButton
  } = useContext(PageContext);
  const { closeAllCarrots } = useContext(CloseCarrotsContext);
  const { setLoading } = useContext(LineDataContext);
  const clickedSelectButton = getClickedSelectButton();

  const clickSelectButton = (event, plan, packName) => {
    setClickedSelectButton({ planId: plan.id, packName });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setClickedSelectButton(null);
      onPlanSelect(event, plan, packName);
    }, delayAnimation);
  };

  const onUpdatePlans = (planId) => {
    updateHashByKey(`lines.${getCurrentLine()}.plan${planId}CompareDropdownSelected`);
    closeAllCarrots();
  };

  const planPerkId = (plan) => (
    plan.choosePerkPack?.length > 0
      ? perkPackIds[colIndex] : void 0
  );

  return (
    <div className="compare-table_cell -head">
      <div className="compare-table_head">
        <Dropdown
          index={ colIndex }
          selectedPlan={ plan }
          allPlans={ plans }
          chosenPlanIds={ chosenPlanIds }
          chosenPlanPackIds={ perkPackIds }
          updateChosenPlanIds={ updateChosenPlanIds }
          onUpdatePlans={ onUpdatePlans }
        />
        <div className="compare-table_head-info">
          <div className="compare-table_head-price">
            <div className="compare-table_head-price-dollar">$</div>
            <h3 className="compare-table_head-price-value">{ plan.price }</h3>
            <div className="compare-table_head-price-hint">
              <div className="compare-table_head-price-hint-line -bold">/mo</div>
              <div className="compare-table_head-price-hint-line">/line</div>
            </div>
          </div>
          <div className="compare-table_head-description">{ plan.taxPhrase }</div>
          <div className="compare-table_head-button">
            <button
              className={
                classnames(
                  'button -sm -bordered plan-select-button',
                  {
                    '-clicked': clickedSelectButton &&
                      clickedSelectButton.planId === plan.id &&
                      (!planPerkId(plan) || clickedSelectButton.packName === planPerkId(plan))
                  }
                )
              }
              onClick={
                (event) => {
                  clickSelectButton(event, plan, planPerkId(plan));
                }
              }
              disabled={
                clickedSelectButton &&
                clickedSelectButton.planId !== plan.id &&
                (!planPerkId(plan) || clickedSelectButton.packName !== planPerkId(plan))
              }
            >
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ComparePlanRow = ({ row, plans, rowGroupId, perkPackIds }) => {
  const [ open, setOpen ] = useState(false);
  const updateVisibility = () => {
    setOpen(!open);
  };
  const { getCloseCarrotsHash } = useContext(CloseCarrotsContext);
  const { getDescriptions, getDescriptionsNotBlankNumber } = useContext(MobileCompareContext);
  const closeCarrotsHash = getCloseCarrotsHash();
  useLayoutEffect(() => {
    if (closeCarrotsHash) {
      setOpen(false);
    }
  }, [ closeCarrotsHash ]);

  const canOutputDescription = (item, index) => (
    !index || !item.same
  );

  const descriptions = getDescriptions(rowGroupId, row);

  const descriptionsNotBlankNumber = getDescriptionsNotBlankNumber(
    rowGroupId, row, descriptions
  );

  const descriptionsNumberToOutput = compact(
    map(descriptions, (item, index) => (
      canOutputDescription(item, index)
    ))).length;

  return (
    <>
      {
        !!descriptionsNotBlankNumber &&
        <div className="compare-table_row-wrapper">
          <div className="compare-table_row -top">
            <div className={ `compare-table_cell -cell-0` }>
              <div className="compare-feature">
                <div className="compare-feature_name">
                  <div className={ `compare-feature_header -cell-0` } onClick={ updateVisibility }>
                    <span className="compare-feature_title">{ row.title }</span>
                    <span className={ `compare-feature_caret ${open ? "-open" : ""}` }>
                      <svg className="icon -caret">
                        <use xlinkHref="#svg_caret" />
                      </svg>
                    </span>
                  </div>
                  {
                    open &&
                    <div
                      className={
                        classnames(
                          "compare-feature_description",
                          { '-few': descriptionsNumberToOutput > 1 }
                        )
                      }
                    >
                      {
                        map(descriptions, (descriptionItem, index) => (
                          <Fragment key={ `compareFeatureDescriptionRow-${rowGroupId}-${row.id}-${index}` }>
                            {
                              canOutputDescription(descriptionItem, index) &&
                              <div
                                className="feature-description"
                                { ...renderRawHtml(descriptionItem.text) }
                              />
                            }
                          </Fragment>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="compare-table_row -bottom">
            {
              map(plans, (plan, index) => {
                let planCompFeatureValue = false;
                let planCompFeatureNote = false;
                if (plan.compare && plan.compare[rowGroupId] && plan.compare[rowGroupId][row.id]) {
                  planCompFeatureValue = plan.compare[rowGroupId][row.id].value;
                  planCompFeatureNote = plan.compare[rowGroupId][row.id].about ? plan.compare[rowGroupId][row.id].about.note : false;
                } else if (plan.choosePerkPack) {
                  const pack = plan.choosePerkPack.find((pack) => pack.id === perkPackIds[index]);
                  if (
                    pack.compare &&
                    pack.compare[rowGroupId] &&
                    pack.compare[rowGroupId][row.id] &&
                    pack.compare[rowGroupId][row.id].about &&
                    pack.compare[rowGroupId][row.id].value
                  ) {
                    planCompFeatureValue = pack.compare[rowGroupId][row.id].value;
                    planCompFeatureNote = pack.compare[rowGroupId][row.id].about.note;
                  }
                }

                return (
                  <div
                    className={ `compare-table_cell -cell-${index}` }
                    key={ `comparePlanItem${plan.id}${index}` }
                  >
                    <div className="compare-feature">
                      <div className="compare-feature_value">
                        <div className="compare-feature_value_icon">
                          {
                            planCompFeatureValue ?
                              <svg className="icon -checkmark-alt">
                                <use xlinkHref="#svg_checkmark-alt" />
                              </svg>
                              :
                              <svg className="icon -close">
                                <use xlinkHref="#svg_close" />
                              </svg>
                          }
                        </div>
                        {
                          planCompFeatureNote &&
                          <div
                            className="compare-feature_value_note"
                            { ...renderRawHtml(planCompFeatureNote) }
                          />
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      }
    </>

  );
};

const ComparePage = ({
  updatedCurrentLine, onPlanSelect, toOverview,
  startIdPlan, startIdPack
}) => {
  const { plans } = useContext(LineDataContext);
  const [ closeCarrotsHash, setCloseCarrotsHash ] = useState(null);
  const closeCarrotsValue = {
    getCloseCarrotsHash: () => (closeCarrotsHash),
    closeAllCarrots: () => {
      setCloseCarrotsHash({});
    }
  };

  const nextIdPlan = (planId, packId) => {
    const currentPlan = find(plans, (item) => (item.id === planId));
    const packIndex = findIndex(perkPacks, (item) => (item.id === packId));

    if (currentPlan.choosePerkPack?.length > 0 && packIndex === 0) {
      return planId;
    }

    const currentPlanIndex = findIndex(plans, (item) => item.id === planId);

    if (currentPlanIndex !== -1 && currentPlanIndex + 1 < plans.length) {
      return plans[currentPlanIndex + 1].id;
    }
    return 1;
  };

  const packIds = (firstPlanId, nextPlanId, packId) => {
    const packIndex = findIndex(perkPacks, (item) => (item.id === packId));
    if (firstPlanId === nextPlanId && packIndex === 0) {
      return map(perkPacks, (item) => item.id);
    }
    const leftPlan = find(plans, (item) => (item.id === firstPlanId));

    if (leftPlan.choosePerkPack?.length > 0) {
      return [ find(perkPacks, (item) => item.id === packId).id, null ];
    }

    const rightPlan = find(plans, (item) => (item.id === nextPlanId));

    if (rightPlan.choosePerkPack?.length > 0) {
      return [ null, perkPacks[0].id ];
    }

    return [ null, null ];
  };

  const [ chosenPlanIds, updateChosenPlanIds ] = useState(() => (
    [ startIdPlan, nextIdPlan(startIdPlan, startIdPack) ]
  ));
  const [ perkPackIds, setPerkPackIds ] = useState(() => (
    packIds(startIdPlan, nextIdPlan(startIdPlan, startIdPack), startIdPack)
  ));

  const filterPlans = (plansList, planIds) => map(planIds, (id) => (
    find(plansList, (item) => (item.id === id))
  ));

  const [ plansFiltered, updatePlanFiltered ] = useState(() =>
    filterPlans(plans, chosenPlanIds)
  );

  const updPlans = (newPlansList, newPerkList) => {
    updateChosenPlanIds(newPlansList);
    setPerkPackIds(newPerkList);
    const plansFilteredNew = filterPlans(plans, newPlansList);
    updatePlanFiltered(plansFilteredNew);
  };

  const subtitlesPresent = (group) => (!!compact(
    map(plansFiltered, (plan, index) => {
      let subtitle = plan.compare &&
        plan.compare[group.id] &&
        plan.compare[group.id].subtitle
        ? plan.compare[group.id].subtitle
        : false;
      if (plan.choosePerkPack) {
        const pack = plan.choosePerkPack.find((pack) => pack.id === perkPackIds[index]);
        if (!subtitle && pack) {
          subtitle = pack.compare &&
          pack.compare[group.id] &&
          pack.compare[group.id].subtitle
            ? pack.compare[group.id].subtitle
            : false;
        }
      }
      return subtitle;
    }
    )).length);

  const getDescriptions = (rowGroupId, row) => {
    let previousCompareDescription = {
      text: null, same: false
    };
    return map(plansFiltered, (plan, index) => {
      const planCompFeatureDescription = {
        text: '',
        same: false
      };
      if (
        plan.compare &&
        plan.compare[rowGroupId] &&
        plan.compare[rowGroupId][row.id] &&
        plan.compare[rowGroupId][row.id].about
      ) {
        planCompFeatureDescription.text = plan.compare[rowGroupId][row.id].about.description;
      } else if (plan.choosePerkPack) {
        const pack = plan.choosePerkPack.find((pack) => pack.id === perkPackIds[index]);
        if (
          pack.compare &&
          pack.compare[rowGroupId] &&
          pack.compare[rowGroupId][row.id] &&
          pack.compare[rowGroupId][row.id].about
        ) {
          planCompFeatureDescription.text = pack.compare[rowGroupId][row.id].about.description;
        }
      }
      if (planCompFeatureDescription.text === previousCompareDescription.text) {
        planCompFeatureDescription.same = true;
      }
      previousCompareDescription = planCompFeatureDescription;
      return planCompFeatureDescription;
    });
  };
  const getDescriptionsNotBlankNumber = (rowGroupId, row, list) => (
    compact(
      map(
        list || getDescriptions(rowGroupId, row),
        (item) => (item.same ? null : item.text)
      )
    )
  ).length;

  const rowsPresent = (group) => (
    !!compact(
      map(
        group.list,
        (row) => (!!getDescriptionsNotBlankNumber(group.id, row))
      )
    ).length
  );

  const mobileCompareValue = {
    getDescriptions,
    getDescriptionsNotBlankNumber
  };

  return (
    <MobileCompareContext.Provider value={ mobileCompareValue }>
      <CloseCarrotsContext.Provider value={ closeCarrotsValue } >
        <div className="content">
          <Header />
          <Subheader currentLine={ updatedCurrentLine } />
          <div className="tabs">
            <div className="tab" onClick={ () => {toOverview();} }>Overview</div>
            <div className="tab -active">Compare</div>
          </div>
          <div className="compare-page -bau -flex">
            <div className="inner-content -compare -bau">

              <div className="compare-page_header">
                <h2 className="compare-page_title">
                  Compare plans
                </h2>
                <div className="compare-page_subtitle">
                  Select two plans to compare side by side.
                </div>
              </div>
              <div className="compare-table">
                <div className="compare-table_row -head">
                  {
                    map(plansFiltered, (plan, index) => {
                      return (
                        <ComparePlanHead
                          colIndex={ index }
                          key={ `comparePlanItem${plan.id}${index}` }
                          plan={ plan }
                          plans={ plans }
                          chosenPlanIds={ [ ...chosenPlanIds ] }
                          perkPackIds={ [ ...perkPackIds ] }
                          updateChosenPlanIds={ updPlans }
                          onPlanSelect={ onPlanSelect }
                        />
                      );
                    })
                  }
                </div>
                {
                  map(compareList, (compareRowGroup, index) => (
                    compareRowGroup.id !== 'allPlansInclude' &&
                  (
                    rowsPresent(compareRowGroup) ||
                    (compareRowGroup.subtitles && subtitlesPresent(compareRowGroup))
                  ) &&
                  <div className="compare-table_group" key={ `comparePlanRowGroup-${compareRowGroup.id}` }>
                    <div className={ `compare-table_group-header -group-${index}` }>
                      <h4 className="compare-table_group-title">{compareRowGroup.title}</h4>
                    </div>
                    {
                      compareRowGroup.subtitles && subtitlesPresent(compareRowGroup) &&
                      <div className="compare-table_row -subtitles">
                        {
                          map(plansFiltered, (plan, filteredIndex) => {
                            let subtitle = false;
                            if (plan.compare
                                && plan.compare[compareRowGroup.id]
                                && plan.compare[compareRowGroup.id].subtitle
                            ) {
                              subtitle = plan.compare[compareRowGroup.id].subtitle;
                            } else if (plan.choosePerkPack) {
                              const pack = plan.choosePerkPack.find((pack) => pack.id === perkPackIds[filteredIndex]);
                              if (pack
                                && pack.compare
                                && pack.compare[compareRowGroup.id]
                                && pack.compare[compareRowGroup.id].subtitle) {
                                subtitle = pack.compare[compareRowGroup.id].subtitle;
                              }
                            }
                            return (
                              <div className="compare-table_cell -subtitle" key={ `comparePlanSubtitleItem${plan.id}${filteredIndex}` }>
                                {subtitle.title && <h4 className="compare-table_group-subtitle-title">{subtitle.title}</h4>}
                                {subtitle.description && <div className="compare-table_group-subtitle-description">{subtitle.description}</div>}
                              </div>
                            );
                          })
                        }
                      </div>
                    }
                    {
                      map(compareRowGroup.list, (compareRow, indexRow) => (
                        <ComparePlanRow
                          row={ compareRow }
                          key={ indexRow }
                          plans={ plansFiltered }
                          perkPackIds={ perkPackIds }
                          rowGroupId={ compareRowGroup.id }
                        />
                      ))
                    }
                  </div>
                  ))
                }
              </div>
            </div>
            <div className="inner-content -bau">
              <AllPlansInclude
                filteredIds={ [ 1, 2, 3, 4, 5, 6 ] }
                closeCarrotsHash={ closeCarrotsHash }
              />
              <footer className="footer">
                <span className="footer_item">Important plan information</span>
                <span className="footer_item">Important broadband information</span>
              </footer>
            </div>
          </div>
        </div>
      </CloseCarrotsContext.Provider>
    </MobileCompareContext.Provider>
  );
};

export default ComparePage;


