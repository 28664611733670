import renderRawHtml from "../../../../common/render_raw_html";
import classnames from "classnames";
import React from "react";

const OptionTextOutput = ({ label, multi, forLabel }) => (
  <label
    htmlFor={ forLabel }
    className={ multi ? "checkbox_label" : "radio_label" }
    { ...renderRawHtml(label) }
  />
);

const OptionTextWithImageOutput = ({ label, imageUrl, forLabel }) => (
  <div className="label-with-image">
    {
      imageUrl &&
      <div className="label-image">
        <img src={ imageUrl } alt={ label } />
      </div>
    }
    <div className={ classnames("label-text", { "-full-width": !imageUrl }) }>
      <label htmlFor={ forLabel } className="label-center" { ...renderRawHtml(label) }  />
    </div>
  </div>
);

const OptionLabelOutput = ({ answer, multi, withImages, images, forLabel }) => {
  const label = answer.label;
  const imageUrl = withImages ? images[answer.id] : null;
  return (
    <>
      {
        withImages ?
          <OptionTextWithImageOutput
            label={ label }
            imageUrl={ imageUrl }
            forLabel={ forLabel || answer.id }
          /> :
          <OptionTextOutput
            label={ label }
            multi={ multi }
            forLabel={ forLabel || answer.id }
          />
      }
    </>
  );
};

export { OptionLabelOutput };