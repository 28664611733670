import React from 'react';
import { map } from "lodash";
import classnames from 'classnames';
import DropdownButton from '../app-index/dropdown-button';
import { runExport } from '../../../../../express/common/sidebar/requests';
import {
  classByReportKind, reportKindsArray, reportKindTitle, kindByIndex, nameByIndex
} from '../../../../../express/researcher/common/headerLinksHelper';
import { userDownloadCCPlatform } from '../../../../common/helpers';
import renderRawHtml from '../../../../../common/render_raw_html';
import SliderRadioButtons
  from '../../../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';
import FiltersDropdown from "./filters_dropdown";

const TabsWithFilter = ({
  tab, setTab, pids, subGroups, selectedOptions, setSelectedOptions, onFilterApply, hasBsg, selectedBsg, onBsgApply
}) => {
  const options = [
    { title: 'Scorecard', value: 'scorecard' },
    { title: 'Customer Challenges with the Experience', value: 'challenges' },
    { title: 'Diagnostics', value: 'diagnostics' },
    { title: 'Unhappy Path Scenario', value: 'unhappy' }
  ];

  const reports = [
    {
      title: 'Case Comparison',
      name: pids?.length > 4 ? [ 'verizon_customer_case_v2024_cc_overview' ] : [ 'verizon_customer_case_v2024_cc_overview', 'verizon_customer_case_v2024_cc_comparative', 'verizon_customer_case_v2024_cc_comparative' ],
      kinds: pids?.length > 4 ? [ 'google' ] : [ 'google', 'google', 'google_pdf' ],
      kindTitles: pids?.length > 4 ? [ 'Google Sheets' ] : [ 'Google Sheets', 'Google Slides', 'PDF' ]
    }
  ];

  const startExport = (event, name, kind, reportTitle, formatTitle) => {
    runExport(
      event,
      '/express/api/projects/database_report',
      {
        name,
        report_format: kind,
        filter: JSON.stringify(selectedOptions).replace('&', '<amp>'),
        ids: pids,
        report_title: reportTitle,
        format_title: formatTitle,
        platform: userDownloadCCPlatform,
        selected_bsg: selectedBsg
      }
    );
  };

  return (
    <div className="comparisons_filters">
      <div className="comparisons_grid -wrap">
        <div className="comparisons_grid-item -full">
          <div className="radio-tabs -gray-bg -bordered -full-width">
            {map(options, (option) => (
              <label className="radio-tabs_item" key={ `project-${option.value}` }>
                <input
                  id={ `project-${option.value}` }
                  type="radio"
                  checked={ tab === option.value }
                  onChange={ ($event) => { setTab($event.target.value); } }
                  value={ option.value }
                />
                <div className="radio-tabs_label -comparison">{option.title}</div>
              </label>
            ))}
          </div>
        </div>

        {hasBsg && <div className="comparisons_grid-item -full">Show each case among:</div>}

        <div className="comparisons_grid-item -full">
          <div className="comparisons_grid">
            {hasBsg &&
              <div className="comparisons_grid-item -fix">
                <SliderRadioButtons
                  initValue={ selectedBsg }
                  options={
                    [
                      { value: 'none', title: 'Targeted Subgroup' },
                      { value: 'total', title: 'Total' }
                    ]
                  }
                  onChange={ (v) => { onBsgApply(v); } }
                  modificator="-full-width -white-bg -bordered bsg-selector"
                />
              </div>}

            <div className="comparisons_grid-item -grow">
              <FiltersDropdown
                options={ subGroups }
                selectedOptions={ selectedOptions }
                setSelectedOptions={ setSelectedOptions }
                onFilterApply={ onFilterApply }
              />
            </div>

            <div className="comparisons_grid-item -fix">
              <DropdownButton listClassName="-to-right-bottom" buttonName="Export">
                {
                  map(reports, (item, index) => (
                    <li key={ index }>
                      <span className="-no-wrap" { ...renderRawHtml(item.title) } />
                      <span>
                        {
                          map(reportKindsArray(item), (kind, kindIndex) => (
                            <a
                              key={ `${index}${kindIndex}` }
                              className={ classnames("-export", classByReportKind(item, kindIndex)) }
                              onClick={
                                (event) => {
                                  startExport(
                                    event,
                                    nameByIndex(item, kindIndex),
                                    kindByIndex(item, kindIndex),
                                    item.title,
                                    reportKindTitle(item, kindIndex)
                                  );
                                }
                              }
                              { ...renderRawHtml(reportKindTitle(item, kindIndex)) }
                            />
                          ))
                        }
                      </span>
                    </li>
                  ))
                }
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabsWithFilter;
