import React, { useContext } from 'react';
import EnableButton from "./components/EnableButton";
import CheckBoxes from "./components/CheckBoxes";
import ShelfList from "./components/shelf-list";
import { DataContext } from "./data-context";

const Form = () => {
  const { cartExercise } = useContext(DataContext);

  return (
    <div className="form -bigger-font">
      <EnableButton />
      {cartExercise &&
      <>
        <CheckBoxes />
        <hr />
        <ShelfList />
      </>}
    </div>
  );
};

export default Form;
