import React from 'react';
import ConceptUnlimitedBAU from './concept_unlimited_bau';
import { SetContext } from "./common/contexts";
import { setConceptName } from './concept_unlimited_bau/plans_data';

// ComponentName = 'A' | 'B' | 'C' | 'D' | 'E' | 'F'
const App = ({ conceptName, set }) => {
  setConceptName(conceptName.toUpperCase());
  return (
    <SetContext.Provider value={ { set } }>
      <ConceptUnlimitedBAU />
    </SetContext.Provider>
  );
};

export default App;
