import React, { useState } from 'react';
import TypesList from '../common/types/types-list';
import Header from './components/app-new/header';
import LetsStart from './components/app-new/lets-start';
import CreateForm from './components/app-new/create-form';
import { IndexData } from './components/app-new/contexts';

const App = ({
  buzzback_user, projects_path, create_path, product_categories,
  countries, projects_allowance
}) => {
  const [ showCreate, setShowCreate ] = useState(false);
  const [ selectedType, setSelectedType ] = useState('');
  const [ selectedTitle, setSelectedTitle ] = useState('');
  const onClose = () => {
    setShowCreate(false);
  };
  const onCreate = (projectType, projectTitle) => {
    setShowCreate(true);
    setSelectedType(projectType);
    setSelectedTitle(projectTitle);
  };

  const contextData = {
    buzzbackUser: buzzback_user
  };

  return (
    <IndexData.Provider value={ contextData }>
      <div className="content -grey-background">
        <Header backUrl={ projects_path } />
        <div className="content_body -express">
          <LetsStart />
          <TypesList
            title="Select project type"
            onCreate={ onCreate }
            projectsAllowance={ projects_allowance }
          />
        </div>
      </div>
      {
        showCreate &&
        <CreateForm
          createPath={ create_path }
          categories={ product_categories }
          countries={ countries }
          selectedType={ selectedType }
          selectedTitle={ selectedTitle }
          onClose={ onClose }
        />
      }
    </IndexData.Provider>
  );
};

export default App;
