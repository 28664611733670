import React, { useState } from 'react';
import classnames from 'classnames';
import { checkIfCanStart } from './helper';

const SuccessPage = ({ taskId, completed }) => {
  const [ available ] = useState(() => checkIfCanStart(taskId));
  return (
    <>
      {
        available &&
        <div className="success-page">
          <div className={ classnames("success-title", { "-fail": !completed }) }>
            {
              completed &&
              <>
                You have completed this task.
                <br />
                Please click "I'm done" to proceed.
              </>
            }
            {
              !completed &&
              <>
                Sorry, you have failed to complete this task.
              </>
            }
          </div>
        </div>
      }
    </>
  );
};

export default SuccessPage;
