import React, { useEffect } from 'react';

const Swipe = ({ tool, nextStep }) => {
  useEffect(() => {
    window.postMessage = (params) => {
      if (params.finishSurvey) {
        nextStep();
      }
    };
  });

  const onFrameLoad = (event) => {
    if (event.target.src) {
      window.frames[0].postMessage(
        {
          updateQuestion: tool.hash,
          conceptIdsForSwipe: tool.concept_ids
        },
        "*"
      );
    }
  };

  return (
    <div className="tool">
      <iframe
        frameBorder="0"
        scrolling="no"
        style={ { overflow: 'hidden', position: 'absolute', left: 0, top: 0, width: "100%", height: "100%" } }
        src={ tool.url }
        onLoad={ (event) => { onFrameLoad(event); } }
      />
    </div>
  );
};

export default Swipe;
