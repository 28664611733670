import React from 'react';
import DesktopBau1 from './components/desktop/bau1';
import DesktopBau2 from './components/desktop/bau2';
import DesktopAdd from './components/desktop/add';
import DesktopMvp from './components/desktop/mvp';
import DesktopFlexBau from './components/desktop/flex-bau';
import DesktopFlex from './components/desktop/flex';
import MobileBau1 from './components/mobile/bau1';
import MobileBau2 from './components/mobile/bau2';
import MobileAdd from './components/mobile/add';
import MobileMvp from './components/mobile/mvp';
import MobileFlex from './components/mobile/flex';
import MobileFlexBau from './components/mobile/flexBau';

const App = (props) => {
  // props.concept can be "BAU1", "BAU2", "ADD", "MVP", "FLEX-BAU", "FLEX"
  // "MOBILE_BAU1", "MOBILE_BAU2", "MOBILE_ADD", "MOBILE_MVP", "MOBILE_FLEX-BAU", "MOBILE_FLEX",
  switch (props.concept) {
    case 'BAU1': return <DesktopBau1 {...props} />;
    case 'BAU2': return <DesktopBau2 {...props} />;
    case 'ADD': return <DesktopAdd {...props} />;
    case 'MVP': return <DesktopMvp {...props} />;
    case 'FLEX': return <DesktopFlex {...props} />;
    case 'FLEX-BAU': return <DesktopFlexBau {...props} />;
    case 'MOBILE_BAU1': return <MobileBau1 {...props} />;
    case 'MOBILE_BAU2': return <MobileBau2 {...props} />;
    case 'MOBILE_ADD': return <MobileAdd {...props} />;
    case 'MOBILE_MVP': return <MobileMvp {...props} />;
    case 'MOBILE_FLEX': return <MobileFlex {...props} />;
    case 'MOBILE_FLEX-BAU': return <MobileFlexBau {...props} />;
    default: return null;
  }
};

export default App;