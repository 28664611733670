import { withPrefix } from '../helpers';

const prefixByTitle = (sidebar, verizonType, mediaKind, title) => (
  withPrefix(verizonType, mediaKind, title, false, !sidebar, !!sidebar)
);

const filesNaming = {
  current: (sidebar, verizonType, mediaKind, combine5gAndLte) => (
    prefixByTitle(
      sidebar, verizonType, mediaKind,
      combine5gAndLte ? 'Latest Report & Performance Metrics' : 'Current Monthly Report'
    )
  ),
  archived: (sidebar, verizonType, mediaKind, combine5gAndLte) => (
    prefixByTitle(
      sidebar, verizonType, mediaKind,
      combine5gAndLte ? 'Additional Resources' : 'Archived Monthly Reports'
    )
  ),
  additional: (sidebar, verizonType, mediaKind, combine5gAndLte) => (
    prefixByTitle(
      sidebar, verizonType, mediaKind,
      combine5gAndLte ? 'Supporting Documents' : 'Additional Documents'
    )
  )
};

const filesPlaceToShow = {
  current: (verizonType, mediaKind, combine5gAndLte) => (
    filesNaming.current(true, verizonType, mediaKind, combine5gAndLte)
  ),
  archived: (verizonType, mediaKind, combine5gAndLte) => (
    filesNaming.archived(true, verizonType, mediaKind, combine5gAndLte)
  ),
  additional: (verizonType, mediaKind, combine5gAndLte) => (
    filesNaming.additional(true, verizonType, mediaKind, combine5gAndLte)
  )
};

export { filesNaming, filesPlaceToShow };
