import React, { useContext } from 'react';
import { map } from 'lodash';
import { LaunchContext } from '../../contexts';
import InfoToolTip from '../../../common/infoToolTip';
import DetailsAndConditions from './detailsAndConditions';

const NSizeForConcepts = () => {
  const { sizeData, nSizes, nSize, setNSize, textItemName } = useContext(LaunchContext);
  const handleOnChange = (event) => {
    setNSize(event.target.value);
  };
  return (
    <div className="form_section -flexible -restrict-width">
      <div className="form-pay_price-grid -grow-with-max">
        <div className="form-pay_price-grid-item">
          <div className="form-group">
            <label className="form-label form-pay_size-label">
              Number of respondents per {textItemName}:
              <InfoToolTip
                className="-force-margin"
                remote
                text={
                  <>
                    Select the number of respondents that will evaluate each {textItemName}.
                    {' '}
                    The price will update based on the total number of respondents.
                  </>
                }
                position="top-right"
              />
            </label>
            <select
              className="form-field form-pay_size-select"
              value={ nSize }
              onChange={ handleOnChange }
            >
              {
                map(nSizes, (option) => (
                  <option key={ option.id } value={ option.id }>{ option.text }</option>
                ))
              }
            </select>
            <div className="selected-number-message">
              N={ sizeData.nsize } per {textItemName} (N={ sizeData.total } overall)
            </div>
          </div>
        </div>
        <div className="form-pay_price-grid-item"><DetailsAndConditions /></div>
      </div>
    </div>
  );
};

export default NSizeForConcepts;
