import React from 'react';

const HiddenFields = ({ options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="project[diagnostics_num]" id="project_diagnostics_num" value={ model.diagnosticsNum || '' } />
      <input type="hidden" name="project[cf_enabled]" id="project_cf_enabled" value={ model.cfEnabled || 'false' } />
      <input type="hidden" name="project[tb_enabled]" id="project_tb_enabled" value={ model.tbEnabled || 'false' } />
    </>
  );
};

export default HiddenFields;
