import { getLinesCount } from '../common/statistics';
import {
  concept_plans_prebuild, concept_plans_construct,
  concept_a_perk_groups, allPlansIncludeConcept,
  allPerksFlatten
} from '../concept_a/plans_data';

const PERK_PRICE = 10;
const concept_b_pricing = [
  [],
  [],
  [],
  [],
  [],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 65, 55, 40, 30, 25 ]
];

const CONCEPT_B = {
  allPerksFlatten,
  PERK_PRICE,
  plans_prebuild: concept_plans_prebuild.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1] + (el.perks.length * PERK_PRICE);
    },
    get price() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  plans_construct: concept_plans_construct.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  concept_a_perk_groups,
  allPlansIncludeConcept
};

export { CONCEPT_B  };
