import React from "react";
import cn from "classnames";
import WorkoutCard from "./WorkoutCard";
import { Link } from "react-router-dom";
import { BRAND_DETAILS_COUNTER_KEY } from "../counter";

const WorkoutCarousel = ({ workouts, className }) => (
  <div className="workout-carousel">
    <div className={cn("workout-carousel_list", className)}>
      {workouts.map((workout, i) =>
        workout.id ? (
          <Link
            className="workout-carousel_item"
            to={`/explore/class/${workout.id}`}
            data-counter-key={workout.id ? BRAND_DETAILS_COUNTER_KEY : null}
            key={i}
          >
            <WorkoutCard workout={workout} />
          </Link>
        ) : (
          <div className="workout-carousel_item" key={i}>
            <WorkoutCard workout={workout} />
          </div>
        )
      )}
    </div>
  </div>
);

export default WorkoutCarousel;
