import React from 'react';
import { map } from 'lodash';
import HalfDonut from '../../../../../express/researcher/verizon_customer_case_v2024/components/mainInfo/halfDonut';
import GreenRed from '../../../../../express/researcher/verizon_customer_case_v2024/components/greenRed';
import Arrow from '../../../../../express/researcher/verizon_customer_case_v2024/components/arrow';
import CommonTrs from './commonTrs';
import TrData from './trData';
import TrGray from "./trGray";

const Scorecard = ({ pids, data, vccCustomerFirst }) => {
  const cData = data.scorecard_data;

  return (
    <>
      <div className="comparisons-table_wrapper">
        <table className="table comparisons-table">
          <tbody>
            <CommonTrs pids={ pids } data={ data } />

            <TrData
              pids={ pids }
              data={ cData }
              text="Recommendation"
              metric="recommendation"
              format="tag"
              forceNA={ (pid) => (cData && !cData[pid].has_highlighting) }
            />

            <tr>
              <th className="th col-name -sticky-left">
                <b>
                  Customer Case Score
                </b>
              </th>
              {map(pids, (pid) => (
                <td key={ pid } className="col-data -center">
                  {
                    !!cData && cData[pid].has_highlighting &&
                    <HalfDonut
                      red={ cData[pid].ease_meter.revise }
                      green={ cData[pid].ease_meter.pass }
                      value={ cData[pid].ease_meter.value }
                      color={ cData[pid].ease_meter.color }
                    />
                  }
                  {
                    (data.arrows_mode || (!!cData && !cData[pid].has_highlighting)) &&
                    <>
                      <b>{ cData[pid].ease_meter.percent_value }</b>
                      { data.arrows_mode && <Arrow color={ cData[pid].ease_meter.arrow } /> }
                    </>
                  }
                </td>
              ))}
            </tr>

            <TrGray
              colSpan={ pids.length + 1 }
              text="Customer Case KPIs"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Ease of Experience"
              hint="(&quot;Little&quot; or &quot;No&quot; effort)"
              metric="vcc_effort"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text={ vccCustomerFirst }
              hint="(Agree &quot;Completely&quot; or &quot;Somewhat&quot;)"
              metric="vcc_customer_first"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Created with My Needs in Mind"
              hint="(Agree &quot;Completely&quot; or &quot;Somewhat&quot;)"
              metric="vcc_needs"
            />

            <TrData
              className="-dashed"
              pids={ pids }
              data={ cData }
              text="CX 'North Star'"
              hint="(Feel &quot;Delighted&quot;)"
              metric="vcc_delight"
            />
          </tbody>
        </table>
      </div>
      <GreenRed arrows={ data.arrows_mode } text={ data.arrows_label_text } />
    </>
  );
};

export default Scorecard;
