import React, { useContext, useState } from 'react';
import { baseContext } from '../context';
import Notification from '../../common/notification';
import { copyToClipboard } from '../../../../common/clipboard-helper';

const CopyLink = () => {
  const { data } = useContext(baseContext);
  const [ copying, setCopying ] = useState(false);
  const [ messageObject, setMessageObject ] = useState(null);

  const goodCallbackFunction = () => {
    setMessageObject({
      message: "Link is copied to the clipboard",
      success: true
    });
  };

  const badCallbackFunction = () => {
    setMessageObject({
      message: "Copy Link operation is failed for some reason",
      success: false
    });
  };

  const handleCopyLinkClick = () => {
    setCopying(true);
    const url = `/express/api/concepts_database/${data.params_id}/copy_link`;
    copyToClipboard({
      asyncUrl: url, asyncField: 'url',
      goodCallback: () => {
        setCopying(false);
        goodCallbackFunction();
      },
      badCallback: () => {
        setCopying(false);
        badCallbackFunction();
      }
    });
  };

  const handleOnCloseMessage = () => {
    setMessageObject(null);
  };

  const copyButtonDisabled = copying;

  return (
    <>
      <button
        className="button concept-tags_header-button copy"
        disabled={ copyButtonDisabled }
        onClick={ handleCopyLinkClick }
      >
        {
          copyButtonDisabled ?
            (<>Copying Link...</>) :
            (<>Copy Link</>)
        }
      </button>
      <Notification
        messageObject={ messageObject }
        onDeactivate={ handleOnCloseMessage }
        interval={ 10 }
      />
    </>

  );
};

export default CopyLink;
