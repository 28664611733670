import React from 'react';
import { Provider } from 'react-redux';
import storeSequential from '../common/store/naming_store.js';
import storeClaim from '../common/store/claim_store.js';
import WithAppTopContexts from "../common/components/with_app_top_contexts";
import Sequential from './sequential';
import MaxDiff from './max_diff';

const App = (props) => {
  const sequential = props.subKind === 'sequential';
  const store = sequential ? storeSequential : storeClaim;

  return (
    <Provider store={ store }>
      <WithAppTopContexts initProps={ props }>
        { sequential && <Sequential { ...props } /> }
        { !sequential && <MaxDiff { ...props } /> }
      </WithAppTopContexts>
    </Provider>
  );
};

export default App;
