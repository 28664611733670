import React, { useContext, useState } from 'react';
import { map } from "lodash";
import cn from "classnames";
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../../common/index-context";
import renderRawHtml from "../../../../../common/render_raw_html";

const BrandComparisonModal = () => {
  const {
    loading, onBrandComparisonModalClose, brandComparisonModalData
  } = useContext(IndexData);

  const [ localLoading, setLocalLoading ] = useState(false);
  const [ comparisonMode, setComparisonMode ] = useState(0);

  const reportOptions = [ "Standard report", "Show category and brand users side-by-side" ];

  const exportReport = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { exportFunction, params } = brandComparisonModalData;
    const postParams = { ...params, ...{ brand_comparison_mode: comparisonMode } };
    setLocalLoading(true);
    exportFunction(event, postParams, () => {
      setLocalLoading(false);
      onBrandComparisonModalClose();
    });
  };

  const processing = (loading || localLoading);

  return (
    <Modal modalSize="esm" modalClassName="centered">
      { processing && <div className="page-loader -inner-loader" /> }
      <div className="modal-title">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ onBrandComparisonModalClose }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title -center" id="clientLabel">
          Export Options
        </h3>
      </div>

      <div className="brand-options">
        <ul
          className={
            cn("brand-options_list")
          }
        >
          {
            map(reportOptions, (option, index) => (
              <li
                key={ index }
              >
                <div
                  className={ 'brand-option-list-inner' }
                >
                  <label
                    className={ 'radio' }
                  >
                    <input
                      type="radio"
                      name={ option }
                      value={ option }
                      checked={ index === comparisonMode }
                      onChange={ () => { setComparisonMode(index); } }
                    />
                    <div
                      className={ 'radio_label' }
                      { ...renderRawHtml(option) }
                    />
                  </label>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="font-selection_footer">
        <div className="font-selection_footer-item">
          <button
            className="button -next"
            type="button"
            onClick={ exportReport }
          >
            <svg className="icon -arrow-next-button">
              <use xlinkHref="#svg_long-arrow" />
            </svg>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BrandComparisonModal;
