import React, { useState } from 'react';
import cn from "classnames";
import SvgIcon from "../../../../common/components/svg-icon";

const TwoFields = ({
  id1, title1, hint1, placeholder1, errors1, initValue1, onChange1,
  id2, title2, hint2, placeholder2, errors2, initValue2, onChange2
}) => {
  const [ v1, setV1 ] = useState(initValue1 || '');
  const [ v2, setV2 ] = useState(initValue2 || '');

  const handleOnChange1 = (event) => {
    setV1(event.target.value);
    onChange1(event.target.value);
  };

  const handleOnChange2 = (event) => {
    setV2(event.target.value);
    onChange2(event.target.value);
  };

  return (
    <div className="form_section">
      <div className="form_grid">

        <div className="form_grid-item -width-1-2">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>{title1}</h3>
            </div>
            {hint1 &&
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>

                <div className="info-tooltip_drop">
                  <div className="info-tooltip_drop-inner">
                    {hint1}
                  </div>
                </div>
              </div>
            </div>}
          </div>
          <div className={ cn("form-group", errors1 ? 'has-error' : '') }>
            <input
              id={ id1 }
              placeholder={ placeholder1 }
              className="form-field -block"
              maxLength="250"
              size="250"
              type="text"
              value={ v1 }
              onChange={ handleOnChange1 }
            />
            <div className="form-error">{errors1}</div>
          </div>
        </div>

        <div className="form_grid-item -width-1-2">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>{title2}</h3>
            </div>
            {hint2 &&
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>

                <div className="info-tooltip_drop">
                  <div className="info-tooltip_drop-inner">
                    {hint2}
                  </div>
                </div>
              </div>
            </div>}
          </div>
          <div className={ cn("form-group", errors2 ? 'has-error' : '') }>
            <input
              id={ id2 }
              placeholder={ placeholder2 }
              className="form-field -block"
              maxLength="250"
              size="250"
              type="text"
              value={ v2 }
              onChange={ handleOnChange2 }
            />
            <div className="form-error">{errors2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFields;
