import remoteFiles from '../../../common/remote_files';
const {
  ActivitiesBarSvg, ContinuousLineSvg,
  CaloriesBarSvg, AchievementsSvg
} = remoteFiles.equinox;

const activities = [
  {
    title: "Activities",
    value: "12",
    growth: "12%",
    lastUpdate: "Updated 15min ago",
    source: {
      name: "Apple & 2 Others",
      lastUpdate: "Last Week"
    },
    imgUrl: ActivitiesBarSvg
  },
  {
    title: "Heart Rate",
    value: "152",
    measure: "BPM",
    lastUpdate: "Up to date",
    source: {
      name: "Apple Watch",
      lastUpdate: "Now"
    },
    imgUrl: ContinuousLineSvg
  },
  {
    title: "Calories",
    value: "2,345",
    growth: "12%",
    lastUpdate: "Updated 15min ago",
    source: {
      name: "Whoop",
      lastUpdate: "Last Night"
    },
    imgUrl: CaloriesBarSvg
  },
  {
    title: "Achievements",
    value: "18",
    source: {
      name: "Unlocked 43 of 63",
      lastUpdate: "Since May 16"
    },
    imgUrl: AchievementsSvg
  }
];

export default activities;
