import React from "react";
import Tooltip from "../common/tooltip";
import AllPlansIncludeWithModal from "../common/allPlansIncludedWithModal";

export const PlansUnder = ({ allPlansInclude }) => {
  const showReviewLinks = false;
  return (
    <div className="plans-under">
      <div className="plans-under_footnotes">
        <p className="plans-under_footnote">
          <span className="plans-under_footnote-stars">*</span>
          Plus taxes & fees. With Auto Pay and paper-free billing. $10/mo more without Auto Pay.
          <Tooltip />
        </p>
        <p className="plans-under_footnote">
          <span className="plans-under_footnote-stars">**</span>
          After 30 GB mobile hotspot, speeds up to 3 MB on 5G Ultra Wideband, and 600 K when on 5G/4G networks.
        </p>
      </div>
      <div className="plans-under_all-include">
        <AllPlansIncludeWithModal plans={ allPlansInclude } />
      </div>
      {
        showReviewLinks &&
        <div className="plans-under-info">
          Please review our
          { ' ' }
          <i>important plan information</i>
          {' '}
          (
          <span className="underlined">
            https://www.verizon.com/support/important-plan-information/
          </span>
          )
          { ' ' }
          and
          {' '}
          <i>important broadband information</i>
          {' '}
          (
          <span className="underlined">
            https://www.verizon.com/support/broadband-services/
          </span>
          )
          .
        </div>
      }
    </div>
  );
};
