import React, { Fragment } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import VerizonSlider from '../common/verizonSlider';
import render_raw_html from '../../../common/render_raw_html';
import { determineValue, defaultNA } from "../helpers";

const ValuesSlider = ({ data, staticText, total, filtered }) => {
  if (!data) {
    return null;
  }

  const issueTitle = (item) => {
    const label = (item.label || "").toLowerCase();
    if (label === "none") {
      return (<b>no issues</b>);
    }
    return (<><b>{ label }</b> issues</>);
  };

  const filtersPresent = !!filtered && !!filtered.length;

  return (
    <div
      className={
        classnames(
          "percent-report",
          { '-many-answers': data.answers.length > 8 }
        )
      }
    >
      <VerizonSlider>
        {
          map(data.answers, (item, index) => {
            return (
              <div
                className="percent-report_list"
                key={ `percent-report-${index}` }
              >
                <div
                  className={
                    classnames(
                      'percent-report_value',
                      { '-with-filters': filtersPresent }
                    )
                  }
                >
                  <div className="main-value">
                    { determineValue(item, total) }
                  </div>
                  {
                    filtersPresent &&
                    <div className="filtered-values">
                      {
                        map(filtered, (filteredItem, filteredIndex) => {
                          const filteredResult = determineValue(
                            filteredItem?.answers[index],
                            total
                          );
                          return (
                            <Fragment key={ `filtered${filteredIndex}` }>
                              {
                                filteredResult !== defaultNA &&
                                <div>{filteredResult}</div>
                              }
                            </Fragment>
                          );
                        })
                      }
                    </div>
                  }
                </div>
                {
                  staticText ? (
                    <div
                      className="percent-report_name"
                      { ...render_raw_html(staticText) }
                    />
                  ) : (
                    <div className="percent-report_name" >
                      Experienced { issueTitle(item) } this month
                    </div>
                  )
                }
              </div>
            );
          })
        }
      </VerizonSlider>
    </div>
  );
};

export default ValuesSlider;
