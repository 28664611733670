import React  from 'react';
import cn from "classnames";
import SvgIcon from "../../../../common/components/svg-icon";
import renderRawHtml from "../../../../common/render_raw_html";
import Wysiwyg from "../../../../common/components/wysiwyg";
import { classNameError, errorsText } from '../common/helpers';
import { projectSetupCustomizeQualifyMessageClick } from "../../../common/google-analytics/events";

const CustomQualified = ({ title, hint, hintClass, model, setModel, errors, gaTrackingId, projectId }) => {
  const handleOnChangeQualifiedText = (newValue) => {
    setModel({ ...model, qualifiedText: newValue });
  };

  const handleOnChangeCustomQualified = (event) => {
    projectSetupCustomizeQualifyMessageClick(gaTrackingId, { project_id: projectId || "new" });
    setModel({ ...model, customQualified: event.target.checked });
  };

  return (
    <div className="form_section">
      <div className="form_section-title -grid">
        <div className="form_section-title-item -grid">
          <div className="toggle">
            <input name="project[custom_qualified]" type="hidden" value="0" autoComplete="off" />
            <input
              className="hide"
              type="checkbox"
              checked={ model.customQualified }
              onChange={ handleOnChangeCustomQualified }
              name="project[custom_qualified]"
              value="1"
              id="project_custom_qualified"
            />
            <label className="form-switch" htmlFor="project_custom_qualified">Custom qualified</label>
          </div>

          <h3>{title}</h3>
        </div>

        <div className="form_section-title-item">
          <div className="info-tooltip">
            <div className="info-tooltip_control">
              <SvgIcon name="i-info" />
            </div>
            <div className={ cn("info-tooltip_drop", hintClass) }>
              <div className="info-tooltip_drop-inner"  { ...renderRawHtml(hint) } />
            </div>
          </div>
        </div>
      </div>

      {model.customQualified &&
      <div className={ cn("form-group qualified_text-editor", classNameError(errors, 'qualified_text')) }>
        <Wysiwyg
          customPicker
          toolbarClassName="wysiwyg-menu"
          editorClassName="wysiwyg-textarea"
          placeholder="Add"
          enableIndent
          enableAlign
          value={ model.qualifiedText }
          onChange={ handleOnChangeQualifiedText }
        />
        <div className="form-error">{ errorsText(errors, 'qualified_text') }</div>
      </div>}
    </div>
  );
};

export default CustomQualified;
