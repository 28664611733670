import React, { useEffect, useState } from 'react';
import Notification from "../common/notification";
import { inOneOfAllowedMessages } from '../common/navigation';
import HiddenFields from "./HiddenFields";
import Form from "./Form";
import { WithDataContext } from "./data-context";

const Index = ({ options, data, error }) => {
  const [ notificationObject, setNotificationObject ] = useState(null);

  const submitForm = (ev) => {
    if (
      !ev.data ||
      typeof (ev.data) !== "object" ||
      !inOneOfAllowedMessages(ev.data)
    ) {
      return;
    }
    window.document.getElementById("shelf-shot-cart").submit();
  };

  useEffect(() => {
    window.addEventListener("message", submitForm);
    return () => {
      window.removeEventListener('message', submitForm);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setNotificationObject({ message: error, success: false });
    }
  }, []);

  return (
    <WithDataContext data={ data } options={ options }>
      <HiddenFields />
      <Form />
      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
    </WithDataContext>
  );
};

export default Index;
