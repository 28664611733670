import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import classnames from 'classnames';
import { baseContext } from "../contexts";
import FilesListSidebar from "../files/filesListSidebar";
import ReportsListSidebar from '../files/reportsListSidebar';

const Sidebar = ({ permissions, logoutClick }) => {
  const { getRecollectiveLink, isPreview, showPulseData, limitedAccess } = useContext(baseContext);

  const classNameFunction = ({ isActive }) => (
    classnames('sidebar_list-link', { '-active': isActive })
  );

  const filesEditLink = (
    <NavLink to="/editor/files" className={ classNameFunction }>
      Documents
    </NavLink>
  );

  return (
    <div className="sidebar">
      <div className="sidebar_body">
        <div className="sidebar_section">
          <h5 className="sidebar_section-title">Home</h5>
          <ul className="sidebar_list">
            <li className="sidebar_list-item">
              <NavLink to="/" className={ classNameFunction } exact={ 'true' }>
                Main Dashboard
              </NavLink>
            </li>
          </ul>
        </div>
        {
          !isPreview &&
          <>
            <div className="sidebar_section">
              <h5 className="sidebar_section-title">Explore</h5>
              <ul className="sidebar_list">
                {
                  showPulseData &&
                  <li className="sidebar_list-item">
                    <NavLink to="/pulse" className={ classNameFunction }>
                      Pulse Survey
                    </NavLink>
                  </li>
                }
                <li className="sidebar_list-item">
                  <a href={ getRecollectiveLink() } className="sidebar_list-link" target="_blank" rel="noreferrer">
                    VOC
                  </a>
                </li>
                {
                  !limitedAccess &&
                  <li className="sidebar_list-item">
                    <NavLink to="/customer_spotlights" className={ classNameFunction }>
                      Customer Spotlight
                    </NavLink>
                  </li>
                }
              </ul>
            </div>
            {
              !limitedAccess &&
              <div className="sidebar_section">
                <h5 className="sidebar_section-title">Collaborate</h5>
                <ul className="sidebar_list">
                  <li className="sidebar_list-item">
                    <NavLink to="/whiteboard" className={ classNameFunction }>
                      Team Whiteboard
                    </NavLink>
                  </li>
                </ul>
              </div>
            }
            <div className="sidebar_section">
              <h5 className="sidebar_section-title">Exports</h5>
              <ul className="sidebar_list">
                <ReportsListSidebar />
                { !limitedAccess && <FilesListSidebar /> }
              </ul>
            </div>
          </>
        }
        {
          !permissions.admin && permissions.can_edit && !isPreview &&
          <>
            <hr className="sidebar_section-divider" />
            <div className="sidebar_section -double-space">
              <h5 className="sidebar_section-title">For editors</h5>
              <ul className="sidebar_list">
                <li className="sidebar_list-item">{ filesEditLink }</li>
              </ul>
            </div>
          </>
        }
        {
          permissions.admin && !isPreview &&
          <>
            <hr className="sidebar_section-divider" />
            <div className="sidebar_section -double-space">
              <h5 className="sidebar_section-title">For admins</h5>
              <ul className="sidebar_list">
                <li className="sidebar_list-item">
                  <NavLink to="/admin/pulses" className={ classNameFunction }>
                    Pulse Surveys
                  </NavLink>
                </li>
                <li className="sidebar_list-item">{ filesEditLink }</li>
                <li className="sidebar_list-item">
                  <NavLink to="/admin/users" className={ classNameFunction }>
                    Users
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
        }
        {
          logoutClick && !isPreview &&
          <>
            <hr className="sidebar_section-divider" />
            <div className="sidebar_section -double-space">
              <button
                className="sidebar_list-link button-link"
                onClick={ logoutClick }
              >
                Log out
              </button>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Sidebar;
