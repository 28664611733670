
import React, { useContext, useState } from 'react';
import Lines from '../lines.js';
import Landing from './landing';
import ChooseLinePlan from './chooseLinePlan';
import Checkout from './checkout';
import SelectPerk from './selectPerk';
import { RouteContext, LinesDataContext } from './contexts';
import Statistic from '../../statistic';

const Route = ({ path, isDefault, children }) => {
  const { path: currentPath } = useContext(RouteContext);
  if (isDefault && !currentPath) return children;
  return path === currentPath ? children : null;
};

const Routes = ({ onLinesCountChange, onDone }) => {
  const [linesData, setLinesData] = useState([]);
  const [selectedPerks, setSelectedPerks] = useState([]);
  const [filledLines, setFilledLines] = useState(0);
  const { setPath } = useContext(RouteContext);
  return <LinesDataContext.Provider value={{ linesData, filledLines, selectedPerks }}>
    <Route path="/" isDefault>
      <Lines onContinue={(linesCount) => {
        onLinesCountChange(linesCount);
        setPath('/choose-line-plan');
        setLinesData(Array(linesCount).fill(null));
        setSelectedPerks(Array(linesCount).fill([]));
        Statistic.updateValue('lines.0.timeSpentStart', new Date())
      }} />
    </Route>
    <Route path="/landing">
      <Landing onContinue={() => {
        setPath('/choose-line-plan');
        Statistic.updateValue('lines.0.timeSpentStart', new Date())
      }} />
    </Route>
    <Route path="/choose-line-plan">
      <ChooseLinePlan
        onContinue={(planData) => {
          const data = [...linesData];
          data[filledLines] = planData;
          setLinesData(data);
          if (filledLines < linesData.length) setFilledLines(filledLines + 1);
          setPath(planData.perkCount === 0 ? '/checkout' : '/select-perk');
      }} />
    </Route>
    <Route path="/select-perk">
      <SelectPerk
        plan={linesData[filledLines - 1]}
        onContinue={(perks) => {
          const data = [...selectedPerks];
          data[filledLines - 1] = perks;
          setSelectedPerks(data);
          setPath('/checkout');
        }}
        onBack={() => {
          const data = [...linesData];
          const prevLineIdx = filledLines - 1;
          data[prevLineIdx] = null;
          setLinesData(data);
          const perks = [...selectedPerks];
          perks[prevLineIdx] = [];
          setSelectedPerks(perks);
          setFilledLines(prevLineIdx);
          setPath('/choose-line-plan')
        }}/>
    </Route>
    <Route path="/checkout">
      <Checkout
        onContinue={() => {
          Statistic.updateValue(`lines.${filledLines - 1}.timeSpentEnd`, new Date())
          if (filledLines < linesData.length) {
            setPath('/choose-line-plan');
            Statistic.updateValue(`lines.${filledLines}.timeSpentStart`, new Date())
          }
          else {
            const count = linesData.reduce((acc, { price }) => acc + price, 0)
            Statistic.updateValue('total', count)
            onDone();
          }
        }}
        onBack={() => {
          const data = [...linesData];
          const prevLineIndex = filledLines - 1;
          data[prevLineIndex] = null;
          setLinesData(data);
          setFilledLines(prevLineIndex);
          const perks = [...selectedPerks];
          perks[prevLineIndex] = [];
          setSelectedPerks(perks);
          setFilledLines(prevLineIndex);
          setPath('/choose-line-plan');
        }} />
    </Route>
  </LinesDataContext.Provider>;
}

export default Routes;