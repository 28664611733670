import React, { useState, useEffect } from 'react';
import { StateContext } from '../common/contexts';
import { cell4 } from './data';
import { Router,  initialState } from './router';

const Cell1 = () => {
  const [ path, updatePath ] = useState(initialState);

  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = `${main.className} -bau`;
  }, []);
  return (
    <StateContext.Provider value={ [ path, updatePath ] }>
      <Router data={ cell4 } />
    </StateContext.Provider>
  );
};

export default Cell1;
