import React from 'react';
import remoteFiles from '../../../../common/remote_files';

const Footer = () => {
  const currentYear = (new Date()).getFullYear();
  const {
    footerLogoAma, footerLogoArf, footerLogoEsomar,
    footerLogoInsights, footerLogoWbenc
  } = remoteFiles.express.landing;
  return (
    <div className="wrapper_item">
      <footer className="footer">
        <div className="container">
          <div className="footer_grid">
            <div className="footer_item -rights">
              <div className="footer_author-rights">
                © { currentYear } Buzzback LLC All rights reserved.
              </div>
            </div>
            <div className="footer_item">
              <ul className="footer-menu">
                <li className="footer-menu_item">
                  <img
                    className="footer-menu_img -wbenc"
                    src={ footerLogoWbenc }
                    alt="wbenc"
                  />
                </li>
                <li className="footer-menu_item">
                  <img
                    className="footer-menu_img -arf"
                    src={ footerLogoArf }
                    alt="arf"
                  />
                </li>
                <li className="footer-menu_item">
                  <img
                    className="footer-menu_img -ama"
                    src={ footerLogoAma }
                    alt="ama"
                  />
                </li>
                <li className="footer-menu_item">
                  <img
                    className="footer-menu_img -esomar"
                    src={ footerLogoEsomar }
                    alt="esomar"
                  />
                </li>
                <li className="footer-menu_item">
                  <img
                    className="footer-menu_img -insights"
                    src={ footerLogoInsights }
                    alt="insights"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
