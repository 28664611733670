import React, { useContext, useState } from "react";
import { map, find, filter, each, includes, remove, isEmpty, cloneDeep, sortBy } from "lodash";
import { PlanDataProvider, ModalContext } from "../common/contexts";
import PlanPerk from "../common/planPerk";
import { Footer } from "../common/footer";
import {
  openTermsModal, openConflictModal, findConflicted,
  calculatePerksSavings, calculatePerksSum,
  getDefaultVariant, findPerkVariantInTheOtherLine
} from "../common/helper";
import { increaseVariableForCurrentLine } from '../common/statistics';
import { LineTotal } from "./line_total";

export const SelectPerks = ({
  selectedPlan,
  initialPerks,
  initVariantsHash,
  onSelect
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const {
    PERK_PRICE, getConflictMessages, getDoubleAddConflictMessages, allPerksFlatten
  } = useContext(PlanDataProvider);

  const [ selectedPerks, setSelectedPerks ] = useState(() => (initialPerks || selectedPlan.perks || []));
  const [ variantsSelected, setVariantsSelected ] = useState(() => {
    if (!isEmpty(initVariantsHash)) {
      return cloneDeep(initVariantsHash);
    }

    const resultingHash = {};
    each(
      filter(selectedPerks, (el) => el.variants),
      (perk) => {
        resultingHash[perk.id] = getDefaultVariant(perk).id;
      }
    );
    return resultingHash;
  });

  const price = calculatePerksSum(selectedPlan?.price, selectedPerks, variantsSelected);
  const save = calculatePerksSavings(selectedPerks, variantsSelected);

  const isPerkChecked = (perkId) => !!find(selectedPerks, (perk) => perk.id === perkId);

  const handleContinue = () => {
    onSelect({ plan: selectedPlan, perks: selectedPerks, variantsHash: variantsSelected });
  };

  const checkAndDisplayDoubleAddPopup = (perk, variantId) => {
    const doubleAddConflicted = findPerkVariantInTheOtherLine(perk, variantId);
    if (doubleAddConflicted.found) {
      const conflictHash = getDoubleAddConflictMessages(
        (() => closeModal()), 'selectPerks', perk, doubleAddConflicted.variant
      );
      openConflictModal(conflictHash, openModal);
    }
  };

  const conflictCallback = (perkConflictName, perkVariantConflictName) => {
    let perkToTurn;
    let variantToTurn;

    if (perkConflictName) {
      const listSelectedPerks = [ ...selectedPerks ];
      const variantsHash = { ...variantsSelected };
      // Perks delete/set
      const conflictPerks = filter(
        allPerksFlatten,
        (item) => (
          item.conflictName === perkConflictName ||
            includes(item.cantStand || [], perkConflictName)
        )
      );
      each(conflictPerks, (perk) => {
        remove(listSelectedPerks, (el) => el.id === perk.id);
        delete variantsHash[perk.id];
      });
      perkToTurn = find(conflictPerks, (el) => el.conflictName === perkConflictName);
      listSelectedPerks.push(perkToTurn);
      setSelectedPerks(listSelectedPerks);

      if (perkVariantConflictName) {
        variantToTurn = find(
          perkToTurn.variants || [],
          (el) => el.conflictName === perkVariantConflictName
        );
        variantsHash[perkToTurn.id] = variantToTurn.id;
      }
      setVariantsSelected(variantsHash);
    }
    closeModal();

    if (perkToTurn) {
      checkAndDisplayDoubleAddPopup(perkToTurn, variantToTurn?.id);
    }
  };

  const handleVariantChange = (perk, variant) => {
    setVariantsSelected({ ...variantsSelected, ...{ [perk.id]: variant.id } });
    checkAndDisplayDoubleAddPopup(perk, variant.id);
  };

  const onPerkChange = (perk, fromDetails) => {
    const checked = isPerkChecked(perk.id);
    const conflicted = findConflicted(perk, selectedPerks);
    if (!checked && conflicted.length > 1) {
      const conflictedPerk = conflicted[0];
      let innerKey;
      let variant;
      if (conflictedPerk.variants) {
        variant = find(
          conflictedPerk.variants,
          (el) => el.id === variantsSelected[conflictedPerk.id]
        );
        innerKey = variant.conflictName;
      }
      const conflictMessages = getConflictMessages(conflictCallback, perk, conflictedPerk, variant);
      let hashKey = conflictMessages[fromDetails ? 'perkDetails' : 'perks'][perk.conflictName];
      if (innerKey) {
        hashKey = hashKey[innerKey];
      }
      openConflictModal(hashKey, openModal);
    } else {
      const list = [ ...selectedPerks ];
      if (checked) {
        increaseVariableForCurrentLine(`perks${perk.statisticsName}TurnedOff`);
        remove(list, (el) => el.id === perk.id);
        if (perk.variants) {
          delete variantsSelected[perk.id];
          setVariantsSelected({ ...variantsSelected });
        }
      } else {
        increaseVariableForCurrentLine(`perks${perk.statisticsName}TurnedOn`);
        list.push(perk);
        if (perk.variants) {
          handleVariantChange(perk, getDefaultVariant(perk));
        } else {
          checkAndDisplayDoubleAddPopup(perk);
        }
      }
      setSelectedPerks(list);
    }
  };

  const handleTermsConditionClick = () => {
    increaseVariableForCurrentLine("perksSummaryTermsClick");
    const sortedPerks = sortBy(selectedPerks, (perk) => perk.id);
    openTermsModal({ perks: sortedPerks, openModal });
  };

  return (
    <div className="perks -footer-sticky">
      <div className="content_title-wrapper">
        <h2 className="content_title">Choose your perks.</h2>
        <div className="content_title-note">
          Save on every one. Just ${PERK_PRICE}/mo each.
        </div>
      </div>
      <div className="perks_grid">
        {
          map(allPerksFlatten, (perk, idx) => (
            <div className="perks_grid-item" key={ `perks-group-perk-${idx}` }>
              <PlanPerk
                perk={ perk }
                showDetailsModal
                className="-in-list"
                showPrice
                isPerkChecked={ isPerkChecked }
                onPerkChange={ onPerkChange }
                price={ price }
                save={ save }
                variantsSelected={ variantsSelected }
                onVariantChange={ handleVariantChange }
              />
            </div>
          ))
        }
      </div>

      <Footer className="-sticky -wide-mobile">
        <div className="perks_footer-grid">
          <div className="perks_footer-grid-item">
            <LineTotal price={ price } save={ save } />
          </div>
          <div className="perks_footer-grid-item">
            <div className="perks_footer-submit">
              <div className="perks_footer-submit-terms">
                {
                  !!selectedPerks.length &&
                  <>
                    By continuing, you agree to selected perk
                    { " " }
                    <button
                      className="button-link -inherit"
                      onClick={ handleTermsConditionClick }
                    >
                      Terms & Conditions
                    </button>
                  </>
                }
              </div>
              <div className="perks_footer-submit-button">
                <button
                  className="button"
                  disabled={ selectedPlan === null }
                  onClick={ handleContinue }
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </div>);
};
