import React, { useState, useCallback } from "react";
import { Header } from '../common/header';
import { Footer } from '../common/footer';

const MIN = 1;
const MAX = 5;

const Lines = ({ nextStep }) => {
  const [ linesCount, setLinesCount ] = useState(1);

  const [ zipCode, setZipCode ] = useState("");

  const checkMin = useCallback((value) => MIN > value, []);

  const checkMax = useCallback((value) => MAX < value, []);

  const getValue = useCallback((value) => {
    if (checkMin(value)) {
      return MIN;
    }
    if (checkMax(value)) {
      return MAX;
    }
    return value;
  }, [ checkMax, checkMin ]);

  const update = (count) => () => {
    const next = getValue(linesCount + count);
    setLinesCount(next);
  };

  const handleZipCode = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/i, '');
    setZipCode(val);
  };

  const continueDisabled = !zipCode || zipCode.length < 5;

  const handleNext = (event) => {
    nextStep(event, { linesCount, zipCode });
  };

  return (
    <div className="wrapper -footer-sticky -wide">
      <Header />
      <div className="content">
        <div className="container">
          <div className="lines">
            <h2 className="lines_title content_title">
              How many lines do you need?
            </h2>

            <div className="lines_counter counter">
              <button className="counter_button -minus" disabled={ checkMin(linesCount - 1) } onClick={ update(-1) } />
              <span className="counter_value">{ linesCount }</span>
              <button className="counter_button -plus" disabled={ checkMax(linesCount + 1) } onClick={ update(1) } />
            </div>

            <div className="lines_form form">
              <div className="form_group">
                <label className="form_label">
                  What is your ZIP code?
                </label>
                <div className="form_control-wrapper">
                  <input
                    type="text" placeholder="" size="5"
                    className="form_control -zip"
                    value={ zipCode }
                    onChange={ handleZipCode }
                    maxLength={ 5 }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="-sticky -wide -center">
        <button
          className="button lines_button"
          disabled={ continueDisabled }
          onClick={ handleNext }
        >
          Continue
        </button>
      </Footer>
    </div>
  );
};

export default Lines;

