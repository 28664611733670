import React, { useContext, useState, useEffect } from "react";
import { includes } from "lodash";
import { StateContext, LineDataContext, SetContext, PlanDataProvider, BackContext } from "../common/contexts";

import {
  setLinesCount,
  setZipCode,
  setCurrentLine,
  getCurrentLine,
  setPlan,
  sendData,
  updateHashByKey
} from "../common/statistics";
import Page from '../common/page';
import Loader from '../common/loader';
import OrderPage from '../common/order';
import { scrollTop } from '../common/helper';
import Lines  from './lines';
import OverviewPage from './overview_page';
import Fork from "./fork";
import { allPerksFlatten } from "./plans_data";

const states = [
  "Lines",
  "Overview",
  "Order",
  "Fork"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = () => {
  const [ path, updatePath ] = useContext(StateContext);
  const { plans_prebuild, plans_construct, getForkList, perks = allPerksFlatten } = useContext(PlanDataProvider);
  const [ planStartTime, setPlanStartTime ] = useState(null);
  const { delayAnimation } = useContext(SetContext);
  const [ overviewOptions, setOverviewOptions ] = useState({});
  const [ loading, setLoading ] = useState(false);

  const updateOverviewOptions = (buildYourOwnFork, editFromOrder) => (
    setOverviewOptions({ buildYourOwnFork, editFromOrder })
  );

  const buildYourOwnClick = () => {
    updateOverviewOptions(true);
  };

  const handleNavigate = (path, cb) => {
    scrollTop();
    updatePath(path);
    cb && cb();
  };

  const handleLinesNextStep = (event, values) => {
    const { linesCount, zipCode } = values;
    setPlanStartTime(new Date());
    setLinesCount(linesCount, perks);
    setZipCode(zipCode);
    setCurrentLine(1);
    handleNavigate("Fork");
  };

  const updatePlanData = (planObject, line, timeAfterStart) => {
    setPlan(planObject, line, allPerksFlatten);
    updateHashByKey(`lines.${line}.planSelectTime`, timeAfterStart);
  };

  const handlePlanSelect = (planObject) => {
    const timeAfterStart = Math.ceil(
      new Date(new Date() - planStartTime - delayAnimation).getTime() / 1000
    );
    updatePlanData(planObject, getCurrentLine(), timeAfterStart);
    handleNavigate("Order");
  };

  const handlePlanReselect = (clickedLine, pickNew) => {
    setPlanStartTime(new Date());
    setCurrentLine(clickedLine);
    if (pickNew) {
      updateOverviewOptions(false);
      handleNavigate("Fork");
    } else {
      updateOverviewOptions(false, true);
      handleNavigate("Overview");
    }
  };

  const { getBackPage, backPageHash, addViewedPage } = useContext(BackContext);
  useEffect(() => {
    const backPage = getBackPage();
    if (includes([ "Order", "Fork" ], backPage)) {
      handleNavigate(backPage);
    }
  }, [ backPageHash ]);

  useEffect(() => {
    if (includes([ "Order", "Fork" ], path)) {
      addViewedPage(path, path === "Order");
    }
  }, [ path ]);

  const data = { setLoading, buildYourOwnClick };

  return (
    <LineDataContext.Provider value={ data }>
      <Route path="Lines">
        <Lines nextStep={ handleLinesNextStep } />
      </Route>

      <Route path="Fork">
        <Page>
          <Fork
            getForkList={ getForkList }
            onPrebuildPlansSelect={
              () => {
                handleNavigate(
                  "Overview",
                  () => updateOverviewOptions(false)
                );
              }
            }
            onOwnPlanSelect={
              () => {
                handleNavigate(
                  "Overview",
                  () => buildYourOwnClick()
                );
              }
            }
          />
        </Page>
      </Route>

      <Route path="Overview">
        <Page>
          <OverviewPage
            onPlanSelect={ handlePlanSelect }
            overviewOptions={ overviewOptions }
          />
        </Page>
      </Route>

      <Route path="Order">
        <Page>
          <OrderPage
            plans={ [ ...plans_prebuild, ...plans_construct ] }
            perks={ perks }
            nextStep={ () => {
              sendData();
              updatePath("Reset");
            } }
            onReselectPlan={ handlePlanReselect }
          />
        </Page>
      </Route>

      { loading && <Loader /> }
    </LineDataContext.Provider>
  );
};

export { Router, initialState };
