import React, { useEffect, useState } from "react";
import Modal from "../../../../../common/components/modal";
import UploadButton from "../../../../../common/components/upload_button";

const ProductEditor = ({
  editorMode, closeModal, saveCallback,
  initValue, cartWithPrices, handleDeleteProduct
}) => {
  const [ product, setProduct ] = useState({ ...initValue });

  const [ metaData, setMetaData ] = useState({});
  const onUploaded = (files) => {
    const [ file ] = files;
    const { cacheSrc: url, metaData } = file;
    setMetaData({ url, metaData });
  };
  const onError = () => {};
  const onInitUploading = () => {};

  const handleOnChangeName = (event) => {
    product.name = event.target.value;
    setProduct({ ...product });
  };

  const handleOnChangePrice = (event) => {
    product.price = event.target.value;
    setProduct({ ...product });
  };

  const productIsNotValid = () => (
    product.name.trim() === '' ||
      product.product_image_data.trim() === '' ||
      (cartWithPrices && !parseFloat(product.price))
  );

  useEffect(() => {
    if (metaData.url) {
      product.product_image_data = metaData.metaData;
      product.product_image_src = metaData.url;
      setProduct({ ...product });
    }
  }, [ metaData ]);

  const handleSave = (product) => () => {
    product.price = parseFloat(product.price);
    saveCallback(product);
  };

  return (
    <Modal
      showCross
      modalSize="sm"
      backClassName="-full-screen"
      customZIndex={ 1063 }
      onClose={ closeModal }
    >
      <div className="modal_header">
        <h3 className="modal_title">
          {editorMode === 'new' && <span>Upload additional product</span>}
          {editorMode !== 'new' && <span>Edit product</span>}
        </h3>
      </div>

      <div className="modal_body">
        <div className="form_section">
          <div className="form_grid">
            <div className="form_grid-item -grow">
              <div className="form-group">
                <input
                  placeholder="Product name"
                  className="form-field -block -js-product-name"
                  type="text"
                  value={ product.name }
                  onChange={ handleOnChangeName }
                  disabled={ editorMode === 'target' }
                />
              </div>
            </div>

            {
              cartWithPrices &&
              <div className="form_grid-item">
                <div className="form-group">
                  <input
                    placeholder="Price"
                    className="form-field -block"
                    type="text"
                    value={ product.price || '' }
                    onChange={ handleOnChangePrice }
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="form_section -js-product-image">
          {
            editorMode === 'target' &&
            <div className="upload-click-area">
              <div
                className="image"
                style={ product.product_image_src ? { backgroundImage: `url("${product.product_image_src}")` } : {} }
              />
            </div>
          }
          {
            editorMode !== 'target' &&
            <UploadButton
              className="upload-click-area -pointer"
              allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
              onInit={ onInitUploading }
              onUploaded={ onUploaded }
              onError={ onError }
            >
              {
                !product.product_image_src &&
                <span>Click to upload product image</span>
              }
              {
                product.product_image_src &&
                <>
                  <div
                    className="image"
                    style={ product.product_image_src ? { backgroundImage: `url("${product.product_image_src}")` } : {} }
                  />
                  <div className="hint">Click to upload product image</div>
                </>
              }
            </UploadButton>
          }
        </div>
      </div>
      <div className="modal_footer -no-border-top">
        <button
          className="button modal_button -darkest-grey"
          onClick={ closeModal }
        >
          Cancel
        </button>

        <button
          className="button modal_button -js-add-product"
          onClick={ handleSave(product) }
          disabled={ productIsNotValid() }
        >
          {editorMode === 'new' && <span>Add Product</span>}
          {editorMode !== 'new' && <span>Save</span>}
        </button>

        {
          editorMode === 'edit' &&
          <button
            className="button modal_button -warning -right -js-del-product"
            onClick={ handleDeleteProduct }
          >
            Delete
          </button>
        }
      </div>
    </Modal>
  );
};

export default ProductEditor;
