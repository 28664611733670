import { addClass } from '../../../../common/dom_functions';

const steps = ({
  setForceOpenFilterDropDown, setForceOpenButton,
  openTourModal, setForceCFTab, setForceMarkupCFTab
}) => (
  () => {
    const openFilters = () => (setForceOpenFilterDropDown(true));
    const closeFilters = () => (setForceOpenFilterDropDown(false));
    const openButtonDropdown = (name) => {
      setForceOpenButton(name, true);
    };
    const closeButtonDropdown = (name) => {
      setForceOpenButton(name, false);
    };
    const setHeatmap = () => {
      setForceCFTab('heatmap');
    };
    const setWordCloud = () => {
      setForceCFTab('wordcloud');
    };
    const setMarkups = () => {
      setForceCFTab('markups');
    };
    const setSelectReaction = () => {
      setForceMarkupCFTab('select');
    };
    const setCreateRegion = () => {
      setForceMarkupCFTab('create');
    };

    return [
      {
        id: 1,
        element: null,
        elementKey: "projectHeader",
        elementKlass: "tour-header",
        modal: false,
        pos: "bottom-left",
        parent: ".tour-parent-header",
        callback: null
      },
      {
        id: 2,
        element: ".-custom-filters-body .chosen-container",
        elementKey: null,
        modal: false,
        pos: "top-center",
        callback: () => {
          openFilters();
        }
      },
      {
        id: 3,
        elementKey: "projectComparisonExport",
        modal: false,
        pos: "left-top",
        parent: ".dropdown",
        callback: () => {
          closeFilters();
          openButtonDropdown('projectComparisonExport');
        }
      },
      {
        id: 4,
        elementKey: "projectFirstComparisonItem",
        elementChildren: true,
        modal: false,
        pos: "right-top",
        parent: "th",
        callback: () => {
          closeButtonDropdown('projectComparisonExport');
        }
      },
      {
        id: 5,
        elementKey: "projectModalTitle",
        modal: true,
        pos: "bottom-left",
        callback: () => {
          openTourModal();
        }
      },
      {
        id: 6,
        elementKey: "projectModalTable",
        modal: false,
        pos: "top-center",
        callback: null
      },
      {
        id: 7,
        elementKey: "projectModalReasonsLeft",
        modal: false,
        pos: "right-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 8,
        elementKey: "projectModalReasonsRight",
        modal: false,
        pos: "left-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 9,
        elementKey: "projectModalReasonsSwitch",
        modal: false,
        pos: "left-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 10,
        elementKey: "projectTbExport",
        modal: false,
        pos: "left-top",
        parent: ".dropdown",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
          openButtonDropdown('projectTbExport');
        }
      },
      {
        id: 11,
        elementKey: "projectModalRadioTab",
        modal: false,
        pos: "bottom-left",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard, .comparisons_plate.-margin, .concept-report_tab.wordcloud",
            "hide-in-tour-modal"
          );
          setHeatmap();
        }
      },
      {
        id: 12,
        elementKey: "projectModalRadioTab",
        modal: false,
        pos: "bottom-center",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard, .comparisons_plate.-margin, .concept-report_tab.wordcloud",
            "hide-in-tour-modal"
          );
          setWordCloud();
        }
      },
      {
        id: 13,
        elementKey: "projectModalCFLeft",
        modal: false,
        pos: "right-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 14,
        elementKey: "projectModalCFRight",
        modal: false,
        pos: "left-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 15,
        elementKey: "projectModalCFSwitch",
        modal: false,
        pos: "left-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 16,
        elementKey: "projectModalRadioTab",
        modal: false,
        pos: "bottom-right",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
          setMarkups();
        }
      },
      {
        id: 17,
        elementKey: "projectModalSelectReaction",
        modal: false,
        pos: "top-left",
        parent: ".markups_grid",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
          setSelectReaction();
        }
      },
      {
        id: 18,
        elementKey: "projectModalCreateRegion",
        modal: false,
        pos: "top-left",
        parent: ".markups_grid",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
          setCreateRegion();
        }
      },
      {
        id: 19,
        elementKey: "projectCFExport",
        modal: false,
        pos: "left-top",
        parent: ".dropdown",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
          openButtonDropdown('projectCFExport');
        }
      }
    ];
  }
);

export default steps;
