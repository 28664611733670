const pricing = [
  [ 70, 60, 45, 35, 30 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 90, 80, 65, 55, 50 ]
];

export const savingsTitles = {
  A: 'Fios internet savings',
  B: 'Home internet savings',
  C: 'Home internet savings',
  D: 'Home internet savings',
  E: 'Home internet savings',
  F: 'Home internet savings'
};

export const savingFeatures = {
  A: {
    title: 'Up to $30 off Fios Home Internet',
    description: 'Get up to $30 off/mo on Fios Gigabit Connection Home Internet.'
  },
  B: {
    title: 'Up to $30 off Fios Home Internet',
    description: 'Get up to $30 off/mo on Fios Gigabit Connection Home Internet.'
  },
  C: {
    title: 'Up to 50% off Home Internet',
    description: 'Get up to $30 off/mo on Fios Gigabit Connection Home Internet.<br/>OR<br/>Get up to 50% off your 5G Home or LTE Home Internet plan (as low as $25/mo with AutoPay).'
  },
  D: {
    title: 'Up to 50% off 5G Home or LTE Home Internet',
    description: 'Get up to 50% off your 5G Home or LTE Home Internet plan (as low as $25/mo with AutoPay).'
  },
  E: {
    title: 'Up to $35/mo off 5G Home or LTE Home Internet',
    description: 'Get up to $35/mo off your 5G Home or LTE Home Internet plan (as low as $25/mo with AutoPay).'
  },
  F: {
    title: 'Home Internet as low as $25/mo (with AutoPay)',
    description: 'Get 5G Home or LTE Home Internet for $35/mo where available (as low as $25/mo with AutoPay).'
  }
};

const allPlansInclude = [
  {
    id: 1,
    title: 'Unlimited talk & text',
    description: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
  },
  {
    id: 2,
    title: '4G LTE',
    description: 'Our 4G LTE network provides the high speeds and reliability you\'ve come to expect. In times of congestion, your data may be temporarily slower than other traffic.'
  },
  {
    id: 3,
    title: 'Verizon Up rewards',
    description: 'With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.'
  },
  {
    id: 4,
    title: 'Mexico & Canada talk, text, & data',
    description: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.'
  },
  {
    id: 5,
    title: 'International texting',
    description: 'Unlimited texting from the US to over 200 countries and territories worldwide.'
  },
  {
    id: 6,
    title: 'Call Filter spam blocker',
    description: 'With Verizon Call Filter from Verizon, screen incoming calls and filter spam. Fine-tune your settings to automatically block high-risk calls by risk level. Upgrade to Call Filter Plus for $2.99/mo for one line or $7.99/mo for 3+ lines.'
  }
];

const Nationwide5GStart = {
  title: '5G Nationwide',
  description: 'Get unlimited access to our 5G Nationwide network. When combined with 5G Ultra Wideband, you’ll get our fastest performance. Requires a 5G-capable device inside the 5G Nationwide coverage area. When network is experiencing heavy traffic, your data may be temporarily slowed.'
};

const Nationwide5G = {
  title: '5G Nationwide',
  description: 'Get unlimited access to our 5G Nationwide network. When combined with 5G Ultra Wideband, you’ll get our best performance.<br/><br/>5G Nationwide available in 2,700+ cities. Requires a 5G-capable device inside the 5G Nationwide coverage area.'
};

const UnlimitedData = {
  title: 'Unlimited data',
  description: 'Get unlimited data from the world\'s most reliable network.  When the network is experiencing heavy traffic, your data may be temporarily slowed.'
};

const UltraWideband5G = {
  title: '5G Ultra Wideband',
  description: '5G Ultra Wideband is the fastest 5G network access we offer. Download apps, games, entire playlists and TV series in seconds. You\'ll also get 5G Ultra Wideband mobile hotspot, crystal-clear 4K Ultra High Definition video streaming when activated, and exclusive access to 5G experiences in partnership with Snap, Niantic, Riot Games eSports Championships, and LiveNation (coming in 2022). For full details, visit our <u>5G experiences page</u>.<br/><br/>5G Ultra Wideband available only in parts of select cities and access requires a 5G Ultra Wideband-capable device inside the 5G Ultra Wideband coverage area. Depending on location, uploading is performed over 5G Ultra Wideband or 4G LTE.'
};

const UnlimitedPremiumNetworkAccess = {
  title: 'Unlimited Premium Network Access',
  description: 'Get unlimited high-speed data that isn\'t slowed based on how much data you use. You\'ll also get high-definition 720p video streaming on 5G Nationwide / 4G LTE when you activate it in your plan settings.'
};

const MobileHotspotData25 = {
  title: '<span style="font-family: \'NHaasGroteskDisplay75\', sans-serif;">25 GB</span> premium mobile hotspot data, then unlimited lower-speed data',
  description: 'Get high-speed 5G/4G hotspot data that your other devices can use. Your smartphone becomes a Wi-Fi connection for devices  like tablets, laptops and more.<br/><br/>After exceeding high-speed allowance, you can still use hotspot at lower speeds of 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G Nationwide / 4G LTE for the rest of the month. 5G access requires a 5G-capable device.'
};

const MobileHotspotData50 = {
  title: '<span style="font-family: \'NHaasGroteskDisplay75\', sans-serif;">50 GB</span> premium mobile hotspot data, then unlimited lower-speed data',
  description: 'Get high-speed 5G/4G hotspot data that your other devices can use. Your smartphone becomes a Wi-Fi connection for devices  like tablets, laptops and more.<br/><br/>After exceeding high-speed allowance, you can still use hotspot at lower speeds of 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G Nationwide / 4G LTE for the rest of the month. 5G access requires a 5G-capable device.'
};

const CloudStorage600 = {
  iconName: 'cloud',
  title: 'Verizon Cloud 600 GB',
  description: 'Wirelessly back up and sync your contacts, photos, videos, messages & documents to the Verizon Cloud. 600 GB included with this plan, or upgrade to 2 TB for an additional $9/mo or Unlimited for an additional $14/mo.'
};

const huluDisneyESPN = {
  title: 'Hulu, Disney+, ESPN+',
  hideTitle: true,
  iconName: 'disney-bundle',
  description: 'Get the best in movies, shows, and sports with Disney+, Hulu, and ESPN+ included. There’s something for everyone with epic stories, tons of TV, and live sports. One subscription can be shared with everyone on your account. A $13.99/mo value, included on us for as long as you stay on an eligible plan.'
};

const AppleArcadeOrGooglePlay = {
  title: 'Apple Arcade or Google Play Pass',
  hideTitle: true,
  iconName: 'arcade-gpp',
  description: 'Enjoy unlimited access to 180+ incredibly fun games across all your Apple devices, with no ads and no in-app purchases. A $4.99/mo value, included on us for as long as you stay on an eligible plan.<br/><br/>Google Play Pass is your ticket to hundreds of awesome games and apps, completely free of ads and in-app purchases. Explore a curated collection with favorites like Stardew Valley and the Monument Valley series to award-winning indies like LIMBO and Dead Cells. A $4.99/mo value.'
};

const AppleMusic6 = {
  title: 'Try Apple Music for 6 months on us',
  description: 'With Apple Music, you can play 75 million songs ad-free, online or off. Immerse yourself in spatial audio with Dolby Atmos. And unlock amazing definition with lossless audio, at no extra cost. A $9.99/mo value, included on us for 6 months. Cancel anytime.'
};

const AppleMusic = {
  title: 'Apple Music',
  iconName: 'music',
  hideTitle: true,
  description: 'With Apple Music, you can play 75 million songs ad-free, online or off. Immerse yourself in spatial audio with Dolby Atmos. And unlock amazing definition with lossless audio, at no extra cost. A $9.99/mo value.'
};

const TravelPass = {
  iconName: 'travel-pass',
  title: '1 TravelPass day per month',
  description: 'Get 1 TravelPass® day each month, accumulating up to 12 days each year. TravelPass days give you unlimited talk, text and data in more than 185 countries for 24 hours, included each month on us for as long as you stay on this plan. TravelPass® days expire 12 months after issue date.'
};

const UpTo50off = {
  iconName: 'wearable',
  title: 'Up to 50% off a watch, tablet, hotspot, or Hum plan',
  description: 'Get up to 50% off one eligible plan for a qualifying smartwatch, tablet, hotspot, or Hum in-car Wi-Fi device.<br/><br/>Eligible plans and discounts: 50% off Unlimited and Unlimited Plus for tablets; Essential and Plus plans for mobile hotspot devices; Unlimited for HumX; Unlimited + In Car WiFi for Hum+; and Unlimited and Number Share Unlimited for smartwatches. $20 off Premium and Pro plans for mobile hotspot devices.'
};

const TryPerks6 = {
  title: 'Try Disney+, Apple Arcade or Google Play Pass, and Apple Music for 6 months on us',
  description: '<b>Disney+</b><br/>All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic. First 6 months on us, then $7.99/mo plus tax after offer ends. One subscription can be shared with everyone on your account. Cancel anytime.<br/><br/><b>Apple Music</b><br/>With Apple Music, you can play 75 million songs ad-free, online or off. Immerse yourself in spatial audio with Dolby Atmos. And unlock amazing definition with lossless audio. A $9.99/mo value.<br/><br/><b>Apple Arcade or Google Play Pass</b><br/>Enjoy unlimited access to 180+ incredibly fun games across all your Apple devices, with no ads and no in-app purchases. After offer ends, Apple bills $4.99/mo plus tax. Cancel anytime.<br/><br/>Google Play Pass is your ticket to hundreds of awesome games and apps, completely free of ads and in-app purchases. Explore a curated collection with favorites like Stardew Valley and the Monument Valley series to award-winning indies like LIMBO and Dead Cells. $4.99/mo plus tax after offer ends. Cancel anytime.'
};

const startUnlimited = {
  id: 1,
  name: "5G Start",
  description: "Just the basics.",
  featuresTitle: "5G access",
  features: [
    {
      title: '&mdash;'
    },
    Nationwide5GStart,
    UnlimitedData
  ],
  perksTitle: 'Perks to try, on us',
  perks: [
    TryPerks6
  ]
};

const playMoreUnlimited = {
  id: 2,
  name: "5G Play More",
  description: "For all your entertainment needs.",
  featuresTitle: "5G total access",
  features: [
    UltraWideband5G,
    Nationwide5G,
    UnlimitedPremiumNetworkAccess,
    MobileHotspotData25
  ],
  perksTitle: 'Entertainment on us',
  perksNote: 'An $18.98/mo value',
  perks: [
    huluDisneyESPN,
    AppleArcadeOrGooglePlay
  ],
  other: [
    AppleMusic6
  ]
};

const doMoreUnlimited = {
  id: 3,
  name: "5G Do More",
  description: "Staying mobile, even around the world.",
  featuresTitle: "5G total access",
  features: [
    UltraWideband5G,
    Nationwide5G,
    UnlimitedPremiumNetworkAccess,
    MobileHotspotData25
  ],
  perksTitle: 'Connectivity on us',
  perksNote: 'Up to a $35.99/mo value',
  perks: [
    UpTo50off,
    TravelPass,
    CloudStorage600
  ],
  other: [
    TryPerks6
  ]
};

const getMoreUnlimited = {
  id: 4,
  name: "5G Get More",
  description: "Fully loaded, plus music.",
  featuresTitle: "5G total access",
  features: [
    UltraWideband5G,
    Nationwide5G,
    UnlimitedPremiumNetworkAccess,
    MobileHotspotData50
  ],
  perksTitle: 'Everything on us',
  perksNote: 'Up to a $52.99/mo value',
  perks: [
    huluDisneyESPN,
    AppleArcadeOrGooglePlay,
    AppleMusic,
    UpTo50off,
    TravelPass,
    CloudStorage600
  ]
};

const plans = [
  startUnlimited,
  playMoreUnlimited,
  doMoreUnlimited,
  getMoreUnlimited
];

export { plans, allPlansInclude, pricing };
