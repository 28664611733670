import React, { useContext } from 'react';
import cn from "classnames";
import { LaunchContext } from '../contexts';
import { projectPreviewReportTourClick } from '../../../common/google-analytics/events';

const InitialPreview = () => {
  const {
    surveyPath, previewReportingPath, setShowPaymentForm, textLaunch,
    projectId, gaId, showPreviewReporting, textKlassModifiersContent, noColor,
    textKlassModifiersSecondaryButton, textIntroCustom
  } = useContext(LaunchContext);
  const handlePayAndLaunchClick = () => {
    setShowPaymentForm(true);
  };
  const handlePreviewReportingClick = () => {
    projectPreviewReportTourClick(gaId, { project_id: projectId });
  };
  return (
    <div className={ cn("content_body", textKlassModifiersContent) }>
      <div className="form">
        <div className="form-intro">
          <div className="form-intro_content">
            <div className="form-intro_section">
              <div className="form-intro_section-inner -text-center -launch">
                <div className="form-finish_icon">
                  <svg className="icon">
                    <use xlinkHref={ noColor ? "#svg_survey-sorry-nocolor" : "#svg_survey-sorry" } />
                  </svg>
                </div>
                <div className="form-intro_title -with-margin-10">
                  Ready for Launch!
                </div>
                <div className="form-intro_section-inner -center -text-center">
                  <div className="form-intro_text -gray">
                    {
                      textIntroCustom ? (
                        <>{ textIntroCustom }</>
                      ) : (
                        <>
                          We suggest you preview your project before you pay and launch.
                          {' '}
                          You may leave this page if you need to and come back to it later.
                          {' '}
                          Your setup details will be saved.
                        </>
                      )
                    }
                  </div>
                  <div className="form-intro_text -gray -with-margin-10">
                    Remember you cannot edit after you launch.
                  </div>
                </div>
                <div className="form-intro_button -preview-row">
                  <a
                    className={
                      cn(
                        "button -secondary -white-back",
                        showPreviewReporting ? '-block' : textKlassModifiersSecondaryButton,
                      )
                    }
                    href={ surveyPath }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview Project
                  </a>
                  {showPreviewReporting &&
                    <a
                      className={ cn("button -secondary -block -white-back", textKlassModifiersSecondaryButton) }
                      href={ previewReportingPath }
                      target="_blank"
                      rel="noreferrer"
                      onClick={ handlePreviewReportingClick }
                    >
                      Preview Reporting
                    </a>}
                </div>
                <div className="form-intro_or-row">or</div>
                <div className="form-intro_button -no-top-margin -btn-md">
                  <button className="button" onClick={ handlePayAndLaunchClick }>
                    { textLaunch }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialPreview;
