import React, { useState, useEffect, useRef, useContext } from 'react';
import { StateContext, PlanDataProvider, SetContext } from '../common/contexts';
import { CONCEPT_A } from '../concept_a/plans_data';
import { Router, initialState } from './router';
import { checkIfCanStart, defineStartPage } from './helper';

const {
  plans_prebuild,
  plans_construct,
  concept_a_perk_groups,
  allPlansIncludeConcept,
  cores,
  PERK_PRICE,
  allPerksFlatten
} = CONCEPT_A;

const PlansBuilderAUx = () => {
  const timerRef = useRef(null);
  const { taskId } = useContext(SetContext);
  const [ path, setPath ] = useState(
    defineStartPage({
      taskId,
      predefinedPlans: plans_prebuild,
      perkPrice: PERK_PRICE,
      initialState
    })
  );
  const [ available ] = useState(() => checkIfCanStart(taskId));
  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = `${main.className} -a`;
  }, []);
  const updatePath = (value) => {
    if (path === value) {
      setPath("Reset");
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setPath(value);
        timerRef.current = null;
      }, 0);
    } else {
      clearTimeout(timerRef.current);
      setPath(value);
    }
  };
  return (
    <>
      {
        available &&
        <PlanDataProvider.Provider
          value={ {
            plans_prebuild,
            plans_construct,
            perks: concept_a_perk_groups,
            allPlansInclude: allPlansIncludeConcept,
            cores,
            PERK_PRICE,
            allPerksFlatten
          } }
        >
          <StateContext.Provider value={ [ path, updatePath ] }>
            <Router />
          </StateContext.Provider>
        </PlanDataProvider.Provider>
      }
    </>

  );
};

export default PlansBuilderAUx;
