import React, { useContext, useRef } from 'react';
import { map } from "lodash";
import { IndexData } from "../../common/index-context";

const HorBar2xTable = ({ q, greenField, blueField }) => {
  const { getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback ] = getTourUseEffectArgs('highlightedRow', tourElement);

  const onRef = (el, r) => {
    if (!r.highlight || !!tourElement.current) {
      return;
    }
    tourElement.current = el;
    callback();
  };

  return (
    <div  className="comparisons_table -with-border -hb2t">
      <table className="table table-comparison">
        <thead>
          <tr>
            <th className="table-comparison_name-cell">
              <div className={ "table-comparison_title" }>
                {q.title}
              </div>
            </th>
            <th />
            <th className="table-comparison_info">
              <div className="status-circle -small -color-green" />
              <span>{q[`${greenField}_title`]}</span>
              <div className="status-circle -small -color-blue">
                <span className="status-circle_text" />
              </div>
              <span>{q[`${blueField}_title`]}</span>
            </th>
          </tr>
        </thead>


        {map(q.rows, (r, index) => (
          <tbody className={ r.highlight ? '-highlight' : '' } key={ index } ref={ (el) => { onRef(el, r); } }>
            <tr className={ r.highlight ? '-highlight-top' : '' }>
              <td className="table-comparison_bold-text" rowSpan={ 2 }>
                { r.title }
              </td>
              <td className="table-comparison_percent -green -collapse-bottom">
                {r[`${greenField}_percent`]}%
              </td>
              <td className="-half-width -collapse-bottom">
                <div className="status-bar -no-max-limit -green">
                  <div className="status-bar_inner" style={ { 'width': `${r[`${greenField}_percent`]}%` } } />
                </div>
              </td>
            </tr>
            <tr className={ r.highlight ? '-highlight-bottom' : '' }>
              <td className="table-comparison_percent -blue -collapse-top">
                {r[`${blueField}_percent`]}%
              </td>
              <td className="-half-width -collapse-top">
                <div className="status-bar -no-max-limit -blue">
                  <div className="status-bar_inner" style={ { 'width': `${r[`${blueField}_percent`]}%` } } />
                </div>
              </td>
            </tr>
          </tbody>
        ))}


      </table>
    </div>
  );
};

export default HorBar2xTable;
