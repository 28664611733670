import React from 'react';
import SignIn from '../components/sign_in';

const App = ({ activated }) => {
  const handleRestoreClick = () => {
    window.location.href = '/?forgot=1';
  };
  return (
    <SignIn
      activated={ activated }
      onRestorePasswordClick={ handleRestoreClick }
    />
  );
};

export default App;
