import React, { useEffect, useState } from 'react';
import { map, keys, last } from "lodash";
import cn from "classnames";
import Loader from "../../../../../../images/icons/loader.gif";

const PropsToggle = ({ attrName, prefix, options, labelClass, currentValue, onChange, withCounter, count, local }) => {
  const [ val, setVal ] = useState(currentValue);
  const [ isLoading, setIsLoading ] = useState(false);
  const lastOption = last(keys(options));
  useEffect(() => {
    if (currentValue) {
      setIsLoading(false);
      setVal(currentValue);
    }
  }, [ currentValue ]);

  const onChangeMode = (value) => {
    if (value === "Custom Comparison" && !count) {
      return;
    }
    if (!local) {
      setIsLoading(true);
    }
    onChange(attrName, value);
  };

  return (
    <div className="radio-tabs -grow -block -gray-bg">
      {
        !isLoading &&
        map(options, (option, index) => (
          <label htmlFor={ `${prefix}-${option}` } className={ `radio-tabs_item ${labelClass}` } key={ `${option}-${index}` }>
            <input
              className="fixed"
              id={ `${prefix}-${option}` }
              name={ `${prefix}-${option}` }
              checked={ val === option }
              onChange={ () => {
                onChangeMode(option);
              }
              }
              type="radio"
            />
            <span
              className={
                cn("radio-tabs_label -width-1-1 -centered", { '-sm-padding': withCounter && !!count && index === lastOption },
                  { '-disabled': withCounter && !count && index === lastOption })
              }
            >
              <div className="info-tooltip -range">
                <span className={ cn({ '-with-pointer': !withCounter || !!count || index !== lastOption }) }>{option}</span>
                {
                  withCounter && !count && index === lastOption &&
                  <div className="info-tooltip_drop -bottom-center">
                    <div className="info-tooltip_drop-inner -md-centered">
                      Select concepts below to calculate a custom average. Will not impact the database.
                    </div>
                  </div>
                }
              </div>
              {
                withCounter && !!count && index === lastOption &&
                <div className="concepts-database_selected_num">{ count }</div>
              }
            </span>
          </label>
        ))
      }
      {
        isLoading &&
        <div className="concepts-database_loader">
          <img width="24px" height="24px" src={ Loader } />
        </div>
      }
    </div>
  );
};

export default PropsToggle;
