import React, { useState, useEffect, useContext } from 'react';
import actionCable from 'actioncable';
import { map, each } from 'lodash';
import { getGroupName, withPrefix } from '../helpers';
import { baseContext, reportsContext } from '../contexts';
import {
  loadProjects,
  selectForOverview
} from './api';
import SurveyModal from "./surveyModal";

const AdminPulses = () => {
  const [ clickedSurvey, setClickedSurvey ] = useState(null);
  const [ openModal, setOpenModal ] = useState(false);
  const {
    pulseSurveyXlsxTemplatePath,
    pulseSurveyCsvTemplatePath,
    mediaKind, verizonType, showPulseData,
    combine5gAndLte
  } = useContext(baseContext);
  const [ projects, setProjects ] = useState([]);
  const [ , setCable ] = useState(null);
  const [ , setChannel ] = useState(null);
  const { refresh } = useContext(reportsContext);

  const handleNewSurvey = () => {
    setOpenModal(true);
    setClickedSurvey(null);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onChangeHandle = (e, value) => {
    if (!value.imported || value.archived) {
      return;
    }
    const checked = e.target.checked;
    selectForOverview(mediaKind, verizonType, value.id, () => {
      const array = [ ...projects ];
      each(array, (el) => {
        el.selected = el.id === value.id && checked;
      });
      setProjects(array);
      refresh();
    });
  };

  const clickLineHandle = (event, survey) => {
    if (event.target.tagName.toLowerCase() !== "td") {
      return;
    }
    setOpenModal(true);
    setClickedSurvey(survey);
  };

  const actionAfterSave = () => {
    loadProjects(mediaKind, verizonType, combine5gAndLte, (data) => {
      setProjects(data);
    });
  };

  const createSubscription = () => {
    const cable = actionCable.createConsumer();
    setCable(cable);
    const channel = cable.subscriptions.create(
      { channel: 'PulseChannel' },
      {
        connected: () => {
          console.log('PulseChannel connected');
        },
        disconnected: () => {
          let subscription;
          setChannel((oldChannel) => {
            subscription = oldChannel;
            return subscription;
          });
          subscription.unsubscribe();
          console.log("Connection was lost. If you don't see last changes, please, refresh page.");
        },
        received: (data) => {
          setProjects(data.items);
        }
      }
    );
    setChannel(channel);

    return channel;
  };

  useEffect(() => {
    loadProjects(mediaKind, verizonType, combine5gAndLte, (data) => {
      setProjects(data);
    });
    const channel = createSubscription();

    return () => {
      channel.unsubscribe();
    };
  }, [ mediaKind ]);

  const calcImportStatus = (project) => {
    if (project.import_failed) {
      return 'Wrong format';
    }

    return project.imported ? 'Ready' : 'Pending';
  };

  return (
    <div className="admin-pulses section">
      <div className="section_top -grid">
        <div className="section_top-item">
          <h4 className="section_title">
            {withPrefix(verizonType, mediaKind, 'Pulse Surveys')}
          </h4>
          {
            showPulseData &&
            <p>
              You may download the supported file structures for reference:
              { ' ' }
              {
                !!pulseSurveyXlsxTemplatePath &&
                <>
                  <a href={ pulseSurveyXlsxTemplatePath }>XLSX format</a>
                  { ' ' }
                  or
                  { ' ' }
                </>
              }
              <a href={ pulseSurveyCsvTemplatePath }>CSV format</a>
              { ' ' }
              (columns separator is comma)
            </p>
          }
        </div>
        <div className="section_top-item">
          <button className="button -sm -upper" onClick={ handleNewSurvey }>
            Upload survey
          </button>
        </div>
      </div>
      <div className="section_body">
        <table className="table -striped -pointer">
          <thead>
            <tr>
              <th>Date</th>
              <th>Display group</th>
              <th>Added</th>
              <th>Status</th>
              <th>Selected for overview</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              map(projects, (value, index) => (
                <tr
                  className="table_tr-clickable"
                  key={ `AdminProject${value.id}${index}` }
                  onClick={ (event) => { clickLineHandle(event, value); } }
                >
                  <td>{value.overview_date}</td>
                  <td>{getGroupName(value.archived)}</td>
                  <td>{value.added_date}</td>
                  <td>{calcImportStatus(value)}</td>
                  <td>
                    {
                      value.imported &&
                      <div className="toggle table_control-over-line">
                        <input
                          name="selected"
                          type="radio"
                          id={ `survey_id_${value.id}` }
                          value={ `survey_id_${value.id}` }
                          checked={ value.selected }
                          onChange={ (event) => onChangeHandle(event, value) }
                        />
                        <label htmlFor={ `survey_id_${value.id}` } className="toggle_label" />
                      </div>
                    }
                  </td>
                  <td>
                    {
                      value.imported &&
                      <a
                        href={ `/verizon/verizon5g/${value.id}` }
                        className="table_control-over-line"
                        onClick={ (event) => event.stopPropagation() }
                        rel="noreferrer"
                        target="_blank"
                      >
                        Open Preview
                      </a>
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <SurveyModal
          open={ openModal }
          handleClose={ handleClose }
          clickedSurvey={ clickedSurvey }
          actionAfterSave={ actionAfterSave }
        />
      </div>
    </div>
  );
};

export default AdminPulses;
