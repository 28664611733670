import React, { useState, useEffect } from 'react';
import { filter, map } from "lodash";
import Notification from "../common/notification";
import { initConditionList, filteredQuestionsForFields } from '../common/custom_questions/helper';
import ScreeningQuestion from '../common/custom_questions/components/ScreeningQuestion';
import { inOneOfAllowedMessages } from '../common/navigation';
import QuestionsOrderModal from "../common/questions-order-modal";
import Header from "./components/Header";
import HiddenFields from './components/HiddenFields';
import FormHeader from "./components/FormHeader";
import Intro from "./components/Intro";

const App = ({ data, options, error, grid_data }) => {
  const initScreenerQuestions = () => (
    map(data.screener_questions, (q) => initConditionList(q, grid_data[q.id]))
  );

  const [ notificationObject, setNotificationObject ] = useState(null);
  const [ screenerQuestions, setScreenerQuestions ] = useState(initScreenerQuestions);
  const [ questionsOrderData, setQuestionsOrderData ] = useState(null);

  const numberLimit = options.number_limit;
  const hasNumberLimit = !!numberLimit;
  const filteredList = filter(screenerQuestions, (q) => !q._destroy);
  const emptyListQuestions = !filteredList.length;
  const showAddQuestion = !emptyListQuestions &&
    (!hasNumberLimit || (hasNumberLimit && filteredList.length < numberLimit));
  const projectLaunched = options.project_launched;

  const addQuestion = (event) => {
    event.preventDefault();

    if (hasNumberLimit && filteredList.length >= numberLimit) {
      setNotificationObject({
        message: `You can add only ${numberLimit} questions at most`,
        success: false
      });
      return;
    }
    setScreenerQuestions(
      [
        ...screenerQuestions,
        {
          text: "",
          answers: [ "" ],
          kind: "",
          condition: {},
          condition_list: [],
          answers_order: "specific",
          multi_condition: 1,
          quotas: {},
          manage_quota: false,
          scales: [ "" ],
          position: screenerQuestions.length
        }
      ]
    );
  };

  const submitForm = (ev) => {
    if (
      !ev.data ||
      typeof (ev.data) !== "object" ||
      !inOneOfAllowedMessages(ev.data)
    ) {
      return;
    }
    window.document.getElementById("question_form").submit();
  };

  useEffect(() => {
    window.addEventListener("message", submitForm);
    return () => {
      window.removeEventListener('message', submitForm);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setNotificationObject({ message: error, success: false });
    }
  }, []);

  const onOpenQuestionsOrderModal = (event) => {
    event.preventDefault();
    setQuestionsOrderData(screenerQuestions);
  };

  return (
    <>
      <HiddenFields questions={ filteredQuestionsForFields(screenerQuestions) } options={ options } />
      <div className="content -relative -grey-background">
        <Header
          title={ options.project_title }
          emptyListQuestions={ emptyListQuestions }
          showAddQuestion={ showAddQuestion }
          addQuestion={ addQuestion }
        />
        <div className="content_body">
          <div className="form -max-screener-and-custom-metrics-width">
            {
              emptyListQuestions &&
              <Intro
                addQuestion={ addQuestion }
                hasNumberLimit={ hasNumberLimit }
                numberLimit={ numberLimit }
              />
            }
            {
              !emptyListQuestions &&
              <>
                <FormHeader
                  hasNumberLimit={ hasNumberLimit }
                  numberLimit={ numberLimit }
                />
                {
                  filteredList.length > 1 && !projectLaunched &&
                  <div className="form_section-title-item -order-button">
                    {
                      <button
                        className="button -secondary -white-back -blue"
                        onClick={ onOpenQuestionsOrderModal }
                      >
                        Change order
                      </button>
                    }
                  </div>
                }
                <div className="form-group">
                  {
                    map(screenerQuestions, (question, index) => (
                      <ScreeningQuestion
                        key={ `Question${index}-${question.name}` }
                        options={ options }
                        question={ question }
                        screenerQuestions={ screenerQuestions }
                        setScreenerQuestions={ setScreenerQuestions }
                        questionIndex={ index }
                        gridData={ grid_data }
                        needQuota
                        needLogicEdit
                        needOther
                        needNone
                        needRows
                      />
                    ))
                  }
                </div>
              </>
            }
          </div>
        </div>
      </div>

      {
        !!questionsOrderData &&
        <QuestionsOrderModal
          questions={ questionsOrderData }
          setQuestionsOrderData={ setQuestionsOrderData }
          onSave={ setScreenerQuestions }
          title="Screener Questions - Change Order"
        />
      }

      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
    </>
  );
};

export default App;
