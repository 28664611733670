import React from "react";

const Working = ({ stubText }) => {
  return (
    <div className="middle">
      <span>{stubText}</span>
    </div>
  );
};

export default Working;
