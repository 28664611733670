import React, { useEffect, useState, useRef } from 'react';
import Http from '../../../common/http';
import Header from './components/app-index/header';
import PlateOptions from "./components/app-index/plate-options";
import Projects from "./components/app-index/projects";
import { IndexData, BaseContext } from "./components/app-index/contexts";
import { getProjects } from './requests';
import NoProjects from './components/app-index/no-projects';
import BeforeLoadedProjects from './components/app-index/before-loaded-projects';

const App = ({
  mode, sort, dir, path, is_demo_user, new_mp_path, new_project_path,
  per_page, is_bb_admin, buzzback_user, client_id, projects_allowance,
  google_analytics_id
}) => {
  const [ loadedFirstTime, setLoadedFirstTime ] = useState(false);
  const [ searchDelayed, setSearchDelayed ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ q, setQ ] = useState("");
  const [ total, setTotal ] = useState(0);
  const [ page, setPage ] = useState(1);
  const [ stateMode, setMode ] = useState(mode);
  const [ stateSort, setSort ] = useState(sort);
  const [ stateDir, setDir ] = useState(dir);
  const [ requestCounter, setRequestCounter ] = useState(0);
  const [ projects, setProjects ] = useState([]);
  const [ clients, setClients ] = useState([]);

  const loadProjects = (text) => {
    setLoading(true);
    getProjects((typeof text === 'string' ? text : q), page,
      (data) => {
        setTotal(data.total);
        setProjects(data.projects);
        setClients(data.clients);
        setLoading(false);
        setLoadedFirstTime(true);
        setSearchDelayed(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const saveSettings = () => {
    const rc = requestCounter + 1;
    setRequestCounter(rc);

    const params = {
      q,
      page,
      mode: stateMode,
      sort: stateSort,
      dir: stateDir,
      request_id: rc
    };

    setLoading(true);

    Http.post(
      path.settings,
      params
    ).then(
      (http) => {
        if (http.data.request_id == rc) {
          setTotal(http.data.total);
          setProjects(http.data.projects);
          setClients(http.data.clients);
        }

        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      });
  };

  const firstModeUpdate = useRef(true);
  useEffect(() => {
    if (firstModeUpdate.current) {
      firstModeUpdate.current = false;
    } else {
      saveSettings();
    }
  }, [ stateMode ]);

  useEffect(() => {
    loadProjects(q);
  }, [ page ]);

  const firstSortDirUpdate = useRef(true);
  useEffect(() => {
    if (firstSortDirUpdate.current) {
      firstSortDirUpdate.current = false;
    } else {
      saveSettings();
    }
  }, [ stateSort, stateDir ]);

  const contextValue = {
    projects,
    setProjects,
    clients,
    setClients,
    sort: stateSort,
    setSort,
    dir: stateDir,
    setDir,
    loadProjects,
    clientId: client_id,
    demoUser: is_demo_user,
    setLoading,
    gaTrackingId: google_analytics_id
  };

  const baseContextValue = {
    projectsAllowance: projects_allowance,
    buzzbackUser: buzzback_user,
    demoUser: is_demo_user,
    admin: is_bb_admin
  };

  const hasProjectsOrBlankSearch = (
    !!projects.length || !!q || searchDelayed
  );

  return (
    <BaseContext.Provider value={ baseContextValue }>
      {
        loadedFirstTime && hasProjectsOrBlankSearch &&
        <div className="content -grey-background">
          <IndexData.Provider value={ contextValue }>
            <Header
              isDemoUser={ is_demo_user }
              newProjectPath={ new_project_path }
              newMpPath={ new_mp_path }
              setQ={ setQ }
              setSearchDelayed={ setSearchDelayed }
              loadProjects={ loadProjects }
            />

            <PlateOptions
              mode={ stateMode }
              setMode={ setMode }
              sort={ stateSort }
              dir={ stateDir }
              page={ page }
              setPage={ setPage }
              total={ total }
              perPage={ per_page }
            />

            <Projects
              loading={ loading }
              isAdmin={ is_bb_admin }
              projects={ projects }
              mode={ stateMode }
            />
          </IndexData.Provider>
        </div>
      }
      {
        loadedFirstTime && !(hasProjectsOrBlankSearch) &&
        <NoProjects
          newProjectPath={ new_project_path }
          newMpPath={ new_mp_path }
        />
      }
      {
        !loadedFirstTime &&
        <BeforeLoadedProjects />
      }
    </BaseContext.Provider>
  );
};

export default App;
