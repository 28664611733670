import React, { useRef, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import Modal from '../../../../../common/components/modal';
import { IndexData } from "../../../common/index-context";
import SwitchModal from "../../../common/components/entity-modal/switch-modal";
import HeaderLinks from "../../../common/components/header-links";
import TopFilter from "../../../common/filters/app";
import ExpressLanguageSwitcher from '../../../common/components/express-language-switcher';
import ConceptTabs from "../../../common/components/concept-tabs";
import ConceptTabHeatmap from "../../../common/components/concept-tab-heatmap";
import ConceptTabWordcloud from "../../../common/components/concept-tab-wordcloud";
import ConceptTabMarkup from "../../../common/components/concept-tab-markup/index";
import ConceptFocusHeader from "../../../common/components/concept-focus-header";
import ReasonsForPreference from "../../../common/components/entity-modal/reasons-for-preference";
import Header from "../../../common/components/entity-modal/header";
import { WithMarkupData } from "../../../common/components/concept-tab-markup/markups-logic";
import Averages from "../../../common/averages";
import WarningMessage from "../../../common/components/warning-message";
import TableConcept from "./table-concept";

const EntityModal = () => {
  const {
    init, data, current, setCurrentConceptWithUrl, concepts, loadData, loadComments,
    wcLoading, loadWords, comments, settings, selectedTool, loadCurrent,
    loading, showSwitchModal, selectedFocusConcept,
    tourData, getForceCFTab
  } = useContext(IndexData);

  const reports = [
    { title: 'Individual Package Report', name: 'package_scorecard', kinds: [ 'pptx', 'xlsx' ] },
    { title: 'Package Verbatims Report', name: 'package_individual_verbatims', kinds: [ 'xlsx' ] }
  ];

  const prefReports = [
    { title: 'Word Cloud Report', name: 'package_wordcloud', kinds: [ 'pptx' ] }
  ];

  const demographics = data.filter.demographics;

  const forceTab = getForceCFTab();

  const [ tab, setTab ] = useState(settings.tab);
  useEffect(() => {
    setTab(settings.tab);
  }, [ settings ]);

  const stateTab = forceTab || tab;

  const wordCloudHeaderHtml = (reaction, n) => {
    const pq = [ data.questions.concept_focus_title_positive, data.questions.concept_focus_description_positive ].join(' ');
    const nq = [ data.questions.concept_focus_title_negative, data.questions.concept_focus_description_negative ].join(' ');

    return (
      <ConceptFocusHeader
        reaction={ reaction }
        positiveQuestion={ pq }
        negativeQuestion={ nq }
        n={ n }
      />
    );
  };

  const mcRef = useRef(null);

  return (
    <>
      <Modal
        modal
        modalSize="lg"
        modalClassName={
          classnames("-scorecard", { 'tour-mode': !!tourData })
        }
        className="concept-report h100"
        mcRef={ mcRef }
      >
        <Header singular="package" />
        <Averages
          statTesting
          scorecard
          statTestingItemTitle="concept"
          data={ data }
          init={ init }
          loadData={ loadData }
        />

        <div className="modal-filters-header">
          <TopFilter
            statTesting
            scorecard
            data={ data }
            loadData={ loadData }
          >
            <HeaderLinks
              className="-no-min-width"
              exportUrl={ init.urls.export }
              reports={ reports }
              i18n={ init.i18n }
              data={ data }
              conceptId={ current.id }
            />
          </TopFilter>

          <ExpressLanguageSwitcher
            description={ data.translations.language_switcher_description }
            showLanguageSwitcher={ data.show_language_switcher }
          />
        </div>

        <WarningMessage
          data={ data }
          buzzbackUser={ init.isInternal }
          itemName={ init.project.item_name }
        />

        <div className="modal_body -scrollable concept-report_body">
          <div className="modal-data">
            { loading && <div className="page-loader -inner-loader" /> }
            <TableConcept data={ data } current={ current } />

            <ReasonsForPreference
              preferenceReasons={ data.preference_reasons }
              tbEnabled={ data.tb_enabled }
              current={ current }
              reports={ prefReports }
              init={ init }
              data={ data }
              loadCurrent={ loadCurrent }
              loadComments={ loadComments }
              isAdmin={ init.isAdmin }
            />

            {
              data.cf_enabled &&
              <>
                <WithMarkupData
                  tool={ selectedTool }
                  allComments={ comments }
                  focusConcept={ selectedFocusConcept(selectedTool) }
                >
                  <ConceptTabs
                    selectedConceptId={ current.id }
                    selectedTool={ selectedTool }
                    demographics={ demographics }
                    download={ init.i18n.download }
                    loadData={ loadData }
                    loadComments={ loadComments }
                    tab={ stateTab }
                    setTab={ setTab }
                    mcRef={ mcRef }
                  />
                  {
                    stateTab === 'markups' &&
                    <ConceptTabMarkup current={ current } />
                  }
                </WithMarkupData>

                {
                  stateTab === 'heatmap' &&
                  <ConceptTabHeatmap
                    current={ current }
                    comments={ comments }
                  />
                }

                {
                  stateTab === 'wordcloud' &&
                  <ConceptTabWordcloud
                    current={ current }
                    wcFilter={ demographics }
                    customTitle={ wordCloudHeaderHtml }
                    pinCount={ 1 }
                    pinTitle={ data.translations.wordcloud_pin }
                    unpinTitle={ data.translations.wordcloud_unpin }
                    answerHelp={ data.translations.wordcloud_concept_focus_hint }
                    answerHelpFor={ 0 }
                    isAdmin={ init.isAdmin }
                    wcLoading={ wcLoading }
                    loadWords={ loadWords }
                    loadComments={ loadComments }
                  />
                }

              </>
            }
          </div>
        </div>
      </Modal>
      {
        showSwitchModal &&
        <SwitchModal
          concepts={ concepts }
          current={ current }
          setCurrentWithUrl={ setCurrentConceptWithUrl }
          singular="package"
        />
      }
    </>
  );
};

export default EntityModal;
