import React, { useContext } from 'react';
import { AppContext } from '../contexts';

const Info = () => {
  const { project } = useContext(AppContext);
  const { panel_service_title, remote_status, completes_count } = project;

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full edit-info-item-external">
          <h3 className="form_section-title">
            Panel Service Information
          </h3>
          <div className="form-group">
            <div className="info-panel">
              <div className="edit-info-tag">
                <div className="-key">Panel Service</div>
                <div className="-value">{ panel_service_title }</div>
              </div>
              <div className="edit-info-tag">
                <div className="-key">Remote Status</div>
                <div className="-value">{ remote_status || 'N/A' }</div>
              </div>
              <div className="edit-info-tag">
                <div className="-key">Number of Completes</div>
                <div className="-value">{ completes_count }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
