import React, { useLayoutEffect } from 'react';
import { each, filter } from 'lodash';
import DesktopBAU from '../bau/desktop';
import { BAU } from '../common/plans_data';

const Cell4 = () => {
  useLayoutEffect(() => {
    each(
      filter(BAU.plans, (plan) => (plan.id !== 1)),
      (plan) => {
        plan.taxPhrase = 'Plus taxes. Fees excluded.';
      }
    );
  }, []);

  return (<DesktopBAU />);
};

export default Cell4;
