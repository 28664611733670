import React, { useContext } from 'react';
import classnames from "classnames";
import { baseContext } from "../../common/contexts";

const FooterButton = ({ options, nextStep, className, customName }) => {
  const { translations } = useContext(baseContext);
  const customText = customName ? customName : null;
  const customTranslationText = translations.custom_next ? translations.custom_next : null;
  return (
    <button
      className={ classnames('button survey-footer_button', className) }
      type="button"
      aria-label={ options.disabled ? translations.next_button_disabled_text : translations.next_button_text }
      onClick={ nextStep }
      { ...options }
    >
      {
        !customText && !customTranslationText &&
        <svg className="svg-next_icon" aria-hidden>
          <use xlinkHref="#svg_long-arrow" />
        </svg>
      }
      { !!customText && customText }
      { !!customTranslationText && customTranslationText }
    </button>
  );
};

export default FooterButton;
