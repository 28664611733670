import React, { Fragment, useState } from 'react';
import { map, keys, filter, each, includes, values, sum, compact, remove } from 'lodash';
import renderRawHtml from '../../../../../common/render_raw_html';
import InputNumber from '../../../common/components/inputNumber';

const questionIsKids = (question) => (
  includes([ 'CHILDREN IN HH', 'GAMING HOURS' ], question.title)
);

const addKidsAnswersToSurveyData = (result) => {
  const answers = result.answers;
  const surveyData = {};
  const regExp = new RegExp(`^([^_]+)_(\\d+)answer_(.+)$`);
  each(
    filter(answers, (item) => (typeof item === 'string' && item.match(regExp))),
    (answer) => {
      const matchResult = answer.match(regExp);
      surveyData[`${matchResult[1]}_${matchResult[2]}answer`] = matchResult[3];
      remove(result.answers, (item) => item === matchResult[0]);
    }
  );
  return surveyData;
};

const KidsRow = ({ id, text, updateValue, max }) => {
  const [ value, setValue ] = useState(null);
  const handleUpdateValue = (newValue) => {
    setValue(newValue);
    updateValue(id, newValue);
  };
  return (
    <div className="kids-row row-text-input">
      <div className="kids-label" { ...renderRawHtml(text) } aria-hidden />
      <InputNumber
        className="kids-input form-field"
        value={ value }
        onUpdate={ handleUpdateValue }
        maxLength={ 3 }
        checkNumberRules
        ariaLabel={ text }
        max={ max }
      />
    </div>
  );
};

const Kids = ({ question, updateValues, updateDisabled }) => {
  const { max_row, max, max_validation_message } = question;
  const [ itemValues, setItemValues ] = useState({});

  const maxSumIsValid = (valuesHash = itemValues) => {
    if (!max) {
      return true;
    }
    const sumValue = sum(
      map(compact(values(valuesHash)), (text) => parseInt(text))
    );
    return sumValue <= max;
  };

  const handleSetValue = (id, value) => {
    const newItemValues = { ...itemValues, ...{ [id]: value } };
    setItemValues(newItemValues);
    const goodKeys = filter(
      keys(newItemValues),
      (item) => !!newItemValues[item] && parseInt(newItemValues[item]) >= 0
    );
    const selectedKeys = filter(
      goodKeys,
      (item) => parseInt(newItemValues[item]) > 0
    );
    const goodValues = {};
    if (goodKeys.length === question.answers.length) {
      goodValues[`${question.name}_0`] = true;
      if (selectedKeys.length) {
        each(selectedKeys, (item) => {
          goodValues[item] = true;
          goodValues[`${item}answer_${newItemValues[item]}`] = true;
        });
        goodValues[`${question.name}_with`] = true;
      } else {
        goodValues[`${question.name}_without`] = true;
      }
    }
    updateDisabled(!maxSumIsValid(newItemValues));
    updateValues(goodValues);
  };

  return (
    <>
      {
        max_validation_message &&
        <div className="multi-error-message">
          { !maxSumIsValid() && <span { ...renderRawHtml(max_validation_message) } /> }
        </div>
      }
      <div className="survey-options js-select" role="group" aria-label={ question.text }>
        {
          map(question.answers, (answer) => (
            <Fragment key={ answer.id }>
              <KidsRow
                id={ answer.id }
                text={ answer.label }
                updateValue={ handleSetValue }
                max={ max_row }
              />
            </Fragment>
          ))
        }
      </div>
    </>
  );
};

export { Kids, questionIsKids, addKidsAnswersToSurveyData };
