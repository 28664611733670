import React, { useState, useContext } from 'react';
import { map, values, find, flatten } from 'lodash';
import classnames from 'classnames';
import FooterButton from '../../footer_button.js';
import { findOrInitLoop } from '../../../../common/logic/loop';
import ZoomImage from '../../zoom_image';
import renderRawHtml from '../../../../../../common/render_raw_html';
import { checkOpenEnds } from '../openEnd';
import { fraudContext } from '../../../../common/contexts';
import { plainText } from "../../../../../../common/string_utils";
import Question from './question';

const SetOfQuestions = ({
  concept, questions, result, nextStep, children,
  forceImage, questionNotice,
  underTextHtml, productDescriptionComponent, customConceptView, customConceptViewClassName,
  noText, customText
}) => {
  let header;
  if (productDescriptionComponent) {
    header = (
      <div className="survey-header">
        <div className="survey-header_title">
          <h3 className="survey-name-label -normal" { ...renderRawHtml(concept.name) } />
          { productDescriptionComponent }
        </div>
      </div>
    );
  } else {
    header = children;
  }

  const [ disabledHash, setDisabledHash ] = useState({});
  const [ answers, setAnswers ] = useState({});
  const { setFraudProcessing } = useContext(fraudContext);

  const disabledOption = () => {
    const hash = {};
    const disabledArray = values(disabledHash);
    if (disabledArray.length !== questions.length || find(disabledArray, (item) => item === true)) {
      hash.disabled = 'disabled';
    }
    return hash;
  };

  const processAnswers = () => {
    const resAnswers = flatten(values(answers));
    findOrInitLoop(concept.confirmit_concept_id, result.loops, resAnswers);
    setAnswers({});
    checkOpenEnds(result, nextStep, setFraudProcessing);
  };

  const selectAnswer = (index, disabled, values, otherAnswer) => {
    setDisabledHash({ ...disabledHash, ...{ [index]: disabled } });
    setAnswers({ ...answers, ...{ [index]: values } });
    if (!result.otherAnswers) {
      result.otherAnswers = {};
    }
    const destQuestionName = questions[index].name;
    if (!result.otherAnswers[destQuestionName]) {
      result.otherAnswers[destQuestionName] = {};
    }
    if (result.otherAnswers[destQuestionName][concept.id]) {
      delete result.otherAnswers[destQuestionName][concept.id];
    }
    if (otherAnswer) {
      result.otherAnswers[destQuestionName][concept.id] = otherAnswer;
    }
    const questionInstance = questions[index];
    if (questionInstance?.onSelect) {
      questionInstance.onSelect(values);
    }
  };

  return (
    <>
      { header }
      <div className="survey-layout_content">
        <div className="survey-layout_container">
          <div className="survey-options -metric">
            {
              !!customConceptView &&
              <div
                className={
                  classnames(
                    "survey-options_image-plate -custom-concept",
                    customConceptViewClassName
                  )
                }
              >
                { customConceptView }
              </div>
            }
            {
              ((!header || forceImage) && !!concept.url) &&
              <div className="survey-options_image-plate">
                <ZoomImage
                  className="survey-options_image"
                  src={ concept.url }
                  altText={ plainText(concept.notes) }
                >
                  <img alt={ plainText(concept.notes) } src={ concept.url } />
                </ZoomImage>
              </div>
            }
            <div className="survey-options_questions">
              {
                map(questions, (question, index) => (
                  <div className={ classnames(question.wrapperClassName) } key={ question.name }>
                    <Question
                      customText={ customText }
                      noText={ noText }
                      question={ question }
                      custom={ question.custom }
                      questionsCount={ questions.length }
                      questionNotice={ questionNotice }
                      underTextHtml={ underTextHtml }
                      selectAnswer={
                        (disabled, values, otherAnswer) => {
                          selectAnswer(index, disabled, values, otherAnswer);
                        }
                      }
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton
          nextStep={ () => { processAnswers(); } }
          options={ disabledOption() }
        />
      </div>
    </>
  );
};

export default SetOfQuestions;
