import React from 'react';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import StartButton from '../../common/components/start_button.js';
import ZoomImage from '../../common/components/zoom_image';

const ProductIntro = ({ nextStep, imageUrl, description, task }) => {
  return (
    <div className="survey-layout_content -start">
      <div
        className={
          classnames('survey-start', '-hero', { "-no-image": !imageUrl })
        }
      >
        {
          !!imageUrl &&
          <ZoomImage
            className="survey-start-hero-img"
            src={ imageUrl }
            alt="Hero Image"
          >
            <img
              className="survey-start-hero-img"
              src={ imageUrl }
              alt="Hero Image"
            />
          </ZoomImage>
        }
        <h3
          className="survey-start_title -left-align -bolder -low-margin-bottom"
          { ...renderRawHtml(description) }
        />
        {
          task &&
          <div
            className="survey-start_description -left-align custom-title"
            { ...renderRawHtml(task) }
          />
        }
        <StartButton nextStep={ nextStep } />
      </div>
    </div>
  );
};

export default ProductIntro;
