import React from "react";
import Modal from "../../../../../common/components/modal";

const Confirm = ({ closeModal, action }) => {
  const handleAction = () => {
    action();
    closeModal();
  };

  return (
    <Modal
      modalSize="sm"
      modalClassName="-left-0 -clean-shelf"
      backClassName="-full-screen"
      customZIndex={ 1066 }
      modalDisplay="flex"
    >
      <div className="modal_header -no-padding">
        <h3 className="modal-title-sm">Shelf Clean</h3>
      </div>
      <div className="modal_body">
        <div className="form_section">
          <div className="form_section-text -center">
            All products will be removed from the shelf.
          </div>
        </div>
      </div>
      <div className="modal_footer -no-border-top -center">
        <button className="button modal_button -warning" onClick={ closeModal }>
          Cancel
        </button>
        <button className="button modal_button" onClick={ handleAction }>
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default Confirm;
