import React, {useState, useEffect, useContext} from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import {baseContext} from "../../common/contexts";

const ModalWindow = ({ style, className, children }) => {
  const body = document.body || document.querySelector("body");
  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = className;
    element.setAttribute("style", style);
    body.appendChild(element);
    body.classList.add('modal-open');
    return () => {
      body.removeChild(element);
      body.classList.remove('modal-open');
    };
  }, []);

  return createPortal(children, element);
};

const TouchModal = ({ onClose, children, customClickEventName, className }) => {
  const paramsHash = {
    [`${customClickEventName || 'onClick'}`]: (event) => {
      event.preventDefault();
      event.stopPropagation();
      onClose();
    }
  };
  const { translations } = useContext(baseContext);

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  })

  return (
    <ModalWindow
      className="modal -base-modal-survey js-selectable-list-image-modal fade in"
      style={ "z-index: 1040" }
    >
      <div
        className={
          classnames(
            "modal -base-modal-survey js-selectable-list-image-modal fade in",
            { [className]: !!className }
          )
        }
        style={ { zIndex: 1050 } }
      >
        <div
          className="modal-backdrop fade in -no-transparency"
          { ...paramsHash }
        />
        <div className="modal-dialog">
          <div className="modal-content">
            <button type="button"
                    className="button-close close fa fa-window-close"
                    onClick={onClose}
                    aria-label={translations.close_button} />
            <div className="modal-body">{ children }</div>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};


export default TouchModal;
