import React, { useState } from 'react';
import Field from '../../common/form/field';
import Button from '../../common/form/button';
import Modal from '../../../../common/components/modal';
import { sendSignUpRequest } from '../requests';
import Phone from '../../common/form/phone';

const SignUp = ({ onClose }) => {
  const [ companyName, updateCompanyName ] = useState("");
  const [ companyNameError, updateCompanyNameError ] = useState(null);
  const [ firstName, updateFirstName ] = useState("");
  const [ firstNameError, updateFirstNameError ] = useState(null);
  const [ lastName, updateLastName ] = useState("");
  const [ lastNameError, updateLastNameError ] = useState(null);
  const [ email, updateEmail ] = useState("");
  const [ emailError, updateEmailError ] = useState(null);
  const [ password, updatePassword ] = useState("");
  const [ passwordError, updatePasswordError ] = useState(null);
  const [ passwordConfirmation, updatePasswordConfirmation ] = useState("");
  const [ passwordConfirmationError, updatePasswordConfirmationError ] = useState(null);
  const [ phone, updatePhone ] = useState("");
  const [ noCodePhone, updateNoCodePhone ] = useState("");
  const [ phoneError, updatePhoneError ] = useState(null);

  const getError = (errors, name) => (errors[name] ? errors[name][0] : null);

  const onPhoneUpdate = (value, noCodeValue) => {
    updatePhone(value);
    updateNoCodePhone(noCodeValue);
  };

  const handleCreate = (event) => {
    event.preventDefault();
    sendSignUpRequest(
      {
        clients: [ { name: companyName } ],
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        password_confirmation: passwordConfirmation,
        phone: noCodePhone ? phone : ""
      },
      (response) => {
        if (response.response.ok) {
          updateCompanyNameError(null);
          updateFirstNameError(null);
          updateLastNameError(null);
          updateEmailError(null);
          updatePasswordError(null);
          updatePasswordConfirmationError(null);
          updatePhoneError(null);
          window.location.href = response.data.redirect_url;
        } else {
          const errors = response.data.errors || {};
          updateCompanyNameError(getError(errors, "clients.name"));
          updateFirstNameError(getError(errors, "first_name"));
          updateLastNameError(getError(errors, "last_name"));
          updateEmailError(getError(errors, "email"));
          updatePasswordError(getError(errors, "password"));
          updatePasswordConfirmationError(
            getError(errors, "password_confirmation")
          );
          updatePhoneError(getError(errors, "phone"));
        }
      }
    );
  };

  return (
    <Modal
      modal
      modalSize="sm"
      showCross={ !!onClose }
      onClose={ onClose }
    >
      <div className="modal_header">
        <div className="modal_title">
          Sign up
        </div>
      </div>
      <div className="modal_body">
        <form className="form" autoComplete="off">
          <div className="form_section">
            <Field
              title="Company name"
              type="text"
              value={ companyName }
              onChange={ updateCompanyName }
              error={ companyNameError }
            />
            <Field
              title="First name"
              type="text"
              value={ firstName }
              onChange={ updateFirstName }
              error={ firstNameError }
            />
            <Field
              title="Last name"
              type="text"
              value={ lastName }
              onChange={ updateLastName }
              error={ lastNameError }
            />
            <Field
              title="Email"
              type="text"
              value={ email }
              onChange={ updateEmail }
              error={ emailError }
            />
            <Field
              title="Password"
              type="password"
              value={ password }
              onChange={ updatePassword }
              hint="minimum is 8 characters"
              error={ passwordError }
            />
            <Field
              title="Password confirmation"
              type="password"
              value={ passwordConfirmation }
              onChange={ updatePasswordConfirmation }
              error={ passwordConfirmationError }
            />
            <Phone
              title="Contact phone (optional)"
              value={ phone }
              onChange={ onPhoneUpdate }
              error={ phoneError }
            />
          </div>
          <div className="form_section">
            <div className="form_buttons">
              <Button onClick={ handleCreate } />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SignUp;
