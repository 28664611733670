import React, { Fragment } from "react";
import { isEmpty, map } from "lodash";
import {
  HiddenBaseFields, HiddenQuestionAnswers, StepField,
  HiddenFieldsConditions, HiddenGridItemFields
} from "../../common/custom_questions/components/HiddenFields";


const ImagesHiddenFields = ({ question, images }) => {
  if (isEmpty(images) && images != null) {
    return (
      <input type="hidden" name={ `project[custom_metrics][][answer_images]` } value="" />
    );
  }

  return (
    <>
      {
        map(images, (image_data, key) => (
          <input
            key={ `image${key}` }
            type="hidden"
            name={ `project[custom_metrics][][answer_images][${key}]` }
            value={ !question.as_point_scale ? image_data?.meta_data : '' }
          />
        ))
      }
    </>
  );
};

const HiddenFields = ({ questions, options, updated, conceptsCountEnabled, conceptsCountValue }) => {
  return (
    <>
      <input type="hidden" name="custom_metrics_updated" id="custom_metrics_updated" value={ updated } />
      {
        conceptsCountEnabled &&
        <input
          type="hidden"
          name="project[concepts_count_for_custom_metrics]"
          id="concepts_count_for_custom_metrics"
          value={ conceptsCountValue }
        />
      }
      <StepField options={ options } />
      {
        map(questions, (question, index) => {
          return (
            <Fragment key={ `question${question.id}${index}` }>
              <HiddenBaseFields question={ question } metricType="custom_metrics" />
              <input type="hidden" name="project[custom_metrics][][as_point_scale]" value={ question.as_point_scale || false } />
              <HiddenQuestionAnswers question={ question } metricType="custom_metrics" />
              <HiddenFieldsConditions question={ question } metricType="custom_metrics" />
              {question.kind === "Grid" && <HiddenGridItemFields question={ question } metricType="custom_metrics" />}
              {!question.as_point_scale && <ImagesHiddenFields question={ question } images={ question.answer_images } />}
            </Fragment>
          );
        })
      }
    </>
  );
};

export default HiddenFields;
