import React, { useState } from 'react';
import classnames from 'classnames';
import Field from '../../common/form/field';
import Button from '../../common/form/button';
import Modal from '../../../../common/components/modal';
import { sendForgotPasswordRequest } from '../requests';

const ForgotPassword = ({ onClose, onBackToSignIn }) => {
  const [ email, setEmail ] = useState("");
  const [ sent, setSent ] = useState(false);
  const [ emailError, setEmailError ] = useState(null);

  const handleRestore = (event) => {
    event.preventDefault();

    sendForgotPasswordRequest(email, (response) => {
      const error = (response.data || {})['email'];
      if (error) {
        setEmailError((response.data || {})['email']);
      } else {
        setEmailError(null);
        setSent(true);
      }
    });
  };

  const handleBackToSignIn = (event) => {
    event.preventDefault();
    if (onBackToSignIn) {
      onBackToSignIn(event);
    }
  };

  return (
    <Modal
      modal
      modalSize="sm"
      showCross={ !!onClose }
      onClose={ onClose }
    >
      <div className="modal_header">
        <div className="modal_title">
          Retrieve Password
        </div>
      </div>
      <div className="modal_body">
        <form className="form">
          <div className="form_section">
            {
              sent &&
              <div>
                Further instructions have been sent to "{ email }"
              </div>
            }
            {
              !sent &&
              <div>
                Enter your email to receive instructions
              </div>
            }
          </div>
          {
            !sent &&
            <div className="form_section">
              <Field
                type="text"
                value={ email }
                onChange={ setEmail }
                error={ emailError }
              />
            </div>
          }
          <div className="form_section">
            <div className="form_buttons -grid">
              {
                !sent &&
                <div className="form_buttons-item -grow">
                  <Button
                    title="Send Me!"
                    onClick={ handleRestore }
                  />
                </div>
              }
              {
                !onClose &&
                <div className={ classnames("form_buttons-item", { '-grow': sent }) }>
                  <a
                    href="#"
                    className="button -sm -block form_button"
                    onClick={ handleBackToSignIn }
                  >
                    Go Back
                  </a>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ForgotPassword;
