import React, { useState } from 'react';
import { map, find } from "lodash";
import FivePointScale from '../../../common/components/metrics/five_point_scale';
import PreIntro from '../../../common/components/pre_intro';
import OpenEnd from '../../../common/components/metrics/openEnd';
import { answerToQuestionInLoop } from '../../../common/logic/loop';
import ShelfFindTime from '../../../common/components/metrics/shelf/find_time';
import ShelfCart from '../../../common/components/metrics/shelf/cart';
import ShelfPreference from '../../../common/components/metrics/shelf/shelf_preference';

const Shelf = ({
  concept, translations, result,
  step, nextStep, fivePointQuestions, openEndQuestions,
  data
}) => {
  const [ foundInnerStep, setFoundInnerStep ] = useState(1);
  const exerciseTask = translations.
    express_shelf_find_time_task.replace("%product_name%", concept.product_name);

  const foundText = () => (
    translations.express_shelf_correct_found.
      replace("%product_name%", concept.product_name)
  );

  const scoreQuestion = (metricName) => (
    find(
      map(
        fivePointQuestions,
        (item) => {
          item.text = item.text.replace("%product_name%", concept.product_name);
          return item;
        }
      ),
      (item) => (item.dm_name === metricName)
    )
  );

  const easeToFind = scoreQuestion("ease_to_find");
  const givenAnswerToEaseToFind = () => (
    answerToQuestionInLoop(
      concept, easeToFind, result.loops, true
    )
  );

  const openEnds = () => (
    map(
      openEndQuestions,
      (item) => {
        item.text = item.text.replace(
          "%product_name%",
          concept.product_name
        ).replace(
          "%selected_ease_to_find_answer%",
          givenAnswerToEaseToFind().label.toLowerCase()
        );
        return item;
      }
    )
  );

  const differentiating = scoreQuestion("differentiating");

  const nextFoundInnerStep = (shelfResult) => {
    if (shelfResult && !shelfResult.found) {
      nextStep();
    }
    const newStep = foundInnerStep + 1;
    const maxNumberOfSteps = 5;
    newStep > maxNumberOfSteps ? nextStep() : setFoundInnerStep(newStep);
  };

  const optionsForShelfFindTime = {
    concept,
    result,
    shelfUrl: concept.shelf_url,
    shelfWidth: concept.shelf_image_width,
    shelfHeight: concept.shelf_image_height,
    area: concept.shelf_area,
    viewOnlyTask: translations.express_shelf_review,
    exerciseTask,
    cantFind: translations.express_shelf_can_not_find_button,
    wantToGiveUp: translations.express_shelf_give_up,
    wouldToContinue: translations.express_shelf_give_up_no_button,
    cantFindProduct: translations.express_shelf_give_up_yes_button,
    fail: translations.express_shelf_wrong_found
  };

  const cartExercise = data.cart_exercise;
  const cartData = concept.cart_exercise_data;
  const optionsForShelfCart = {
    concept,
    result,
    shelfUrl: cartData?.shelf_image_url,
    products: cartData?.products_with_areas,
    productsUniq: cartData?.products_uniq,
    withPrices: data.cart_options?.with_prices,
    budgetLimit: data.cart_options?.budget_limit,
    viewOnlyTask: translations.express_shelf_review,
    noBudgetTask: translations.express_shelf_cart_exercise,
    withBudgetTask: translations.express_shelf_cart_exercise_with_budget,
    buttonEmptyCart: translations.express_shelf_cart_empty,
    cartEmptyContent: translations.express_shelf_cart_empty_content,
    cartContentTitle: translations.express_shelf_cart_title,
    cartOneItemName: translations.express_shelf_cart_item,
    cartManyItemsName: translations.express_shelf_cart_items,
    currency: translations.express_shelf_cart_currency,
    maxBudgetTitle: translations.express_shelf_cart_max_budget_title,
    cartTotalTitle: translations.express_shelt_cart_total_title,
    customEnlarge: translations.express_shelf_cart_custom_enlarge,
    productDelete: translations.express_shelf_cart_product_delete,
    cartComplete: translations.express_shelf_cart_complete
  };

  return (
    <>
      {
        step === 1 &&
        <PreIntro text={ translations.express_shelf_intro } nextStep={ nextStep } />
      }
      {
        step === 2 && cartExercise &&
        <ShelfCart
          viewOnly
          { ...optionsForShelfCart }
          nextStep={ nextStep }
        />
      }
      {
        step === 2 && !cartExercise &&
        <ShelfFindTime
          viewOnly
          { ...optionsForShelfFindTime }
          nextStep={ nextStep }
        />
      }
      {
        step === 3 &&
        <ShelfPreference
          bigger
          concept={ concept }
          products={ cartData.products_uniq }
          result={ result }
          title={ translations.eye_catch_title }
          noEyeCatchText={ translations.no_eye_catch_text }
          firstEyeCatchText={ translations.first_eye_catch_text }
          secondEyeCatchText={ translations.second_eye_catch_text }
          thirdEyeCatchText={ translations.third_eye_catch_text }
          nextStep={ nextStep }
        />
      }
      {
        step === 4 &&
        <PreIntro
          text={ translations.express_shelf_cart_intro }
          nextStep={ nextStep }
        />
      }
      {
        step === 5 &&
        <ShelfCart
          { ...optionsForShelfCart }
          nextStep={ nextStep }
        />
      }
      {
        step === 6 &&
        <PreIntro text={ translations.express_shelf_find_time_intro } nextStep={ nextStep } />
      }
      {
        step === 7 && foundInnerStep === 1 &&
        <ShelfFindTime
          { ...optionsForShelfFindTime }
          nextStep={ nextFoundInnerStep }
        />
      }
      {
        step === 7 && foundInnerStep === 2 &&
        <PreIntro text={ foundText() } nextStep={ nextFoundInnerStep } />
      }
      {
        step === 7 && foundInnerStep === 3 &&
        <FivePointScale
          concept={ concept }
          questions={ [ easeToFind ] }
          result={ result }
          nextStep={ nextFoundInnerStep }
        >
          <>
          </>
        </FivePointScale>
      }
      {
        step === 7 && foundInnerStep === 4 &&
        <OpenEnd
          question={ openEnds()[0] }
          concept={ concept }
          note={ translations.followup_note }
          placeholder={ translations.followup_placeholder }
          result={ result }
          nextStep={ nextFoundInnerStep }
        />
      }
      {
        step === 7 && foundInnerStep === 5 &&
        <FivePointScale
          concept={ concept }
          questions={ [ differentiating ] }
          result={ result }
          nextStep={ nextFoundInnerStep }
        >
          <>
          </>
        </FivePointScale>
      }
    </>
  );
};

export default Shelf;
