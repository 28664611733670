import { getLinesCount, increaseVariableForCurrentLine } from '../common/statistics';
import { calculatePerksSum, calculatePerksSavings, capitalize } from '../common/helper';
// import { imageUrl } from "../../plans_builder/common/helper";

const allPlansIncludeConcept = [
  {
    id: 1,
    title: 'Unlimited talk, text & data',
    description: 'Stay in touch and never worry about overage charges with unlimited talk, text and data on the network more people rely on.'
  },
  {
    id: 2,
    title: 'Mexico & Canada talk, text & data',
    description: 'Talk, text and use unlimited data when traveling in Mexico and Canada. After 2GB/day get unlimited 3G data speeds. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.'
  },
  {
    id: 3,
    title: '5G and 4G LTE',
    description: "Get unlimited access to our 5G and 4G LTE networks which provide the high speeds and reliability you've come to expect. Requires a 5G-capable device inside the 5G coverage area."
  },
  {
    id: 4,
    title: 'International texting',
    description: 'Unlimited texting from the US to over 200 countries and territories worldwide.'
  }
];

const fiveGUltraWideBand = {
  title: "5G Ultra Wideband",
  description: [
    "Our fastest 5G network experience, up to 10X faster than 4G LTE. No matter how much you use.",
    "Comparison based on analysis by Verizon of Ookla® Speedtest Intelligence® data for Verizon 5G UWB median download speeds vs Verizon 4G LTE median download speeds, Q4 2022. Explore our current <span class='underlined'>5G and 4G LTE coverage maps</span> (https://www.verizon.com/coverage-map/).",
    "Get access to the fastest speeds we offer with 5G Ultra Wideband. Combined with Verizon's 5G network, you get reliable coast to coast coverage. Download apps, games, entire playlists and TV series in seconds. You'll also get 5G Ultra Wideband mobile hotspot and crystal-clear 4K Ultra High Definition video streaming when activated on a capable device. Plus, access experiences from our exclusive 5G partners: Snapchat, Live Nation, Riot Games and Niantic. For full details, visit the <span class='underlined'>5G experience page</span> (https://www.verizon.com/5g/).",
    "5G Ultra Wideband available in select areas and access requires a 5G Ultra Wideband-capable device inside the 5G Ultra Wideband coverage area."
  ],
  icon: 'tower'
};

const fiveG = {
  titleForPrebuild: "5G",
  title: "Our reliable, 5G",
  titleForShort: "Our reliable, fast 5G",
  titleForCompare: "5G",
  titleForDescription: "5G",
  description: [
    "Reliable 5G performance.",
    "Get unlimited access to our 5G network. When combined with 5G Ultra Wideband, you’ll get our fastest performance. When network is experiencing heavy traffic, your data may be temporarily slowed.",
    "Requires a 5G-capable device inside Verizon's 5G coverage area."
  ],
  icon: 'tower'
};

const mobileHotspot30 = {
  title: '30 GB premium mobile hotspot data',
  titleForPrebuild: '30 GB premium mobile hotspot data**',
  titleForShort: '30 GB premium mobile hotspot data, unlimited lower speeds**',
  titleForCompare: '30 GB premium mobile hotspot data',
  description: [
    "Get high-speed 5G/4G hotspot data that your other devices can use. Your smartphone becomes a Wi-Fi connection for devices like tablets, laptops and more.",
    "After exceeding 30 GB/mo of 5G Ultra Wideband, 5G, or 4G LTE Mobile Hotspot data, you can still use hotspot at lower speeds of 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G / 4G LTE for the rest of the month. 5G access requires a 5G-capable device."
  ],
  icon: 'wifi'
};

const upToDevicePlan = {
  title: 'Up to 50% off a watch, tablet, hotspot or Hum plan',
  titleForCompare: 'Up to 50% off',
  description: 'Get up to 50% off one eligible plan for a qualifying smartwatch, tablet, hotspot, or Hum in-car Wi-Fi device. Eligible plans and discounts: 50% off Unlimited and Unlimited Plus for tablets; Essential and Plus plans for mobile hotspot devices; Unlimited for HumX; Unlimited + In Car WiFi for Hum+; and Unlimited and Number Share Unlimited for smartwatches. $20 off Premium and Pro plans for mobile hotspot devices.',
  icon: 'watch'
};

const yearPriceGuarantee3 = {
  title: '3-year Price Guarantee',
  titleForCompare: '3 years',
  description: 'Applies to the then current base monthly rate charged by Verizon for your talk, text, and data; excludes taxes, fees, surcharges, additional plan discounts or promotions, and third-party services. Price guarantee is void if any of the lines are upgraded, canceled, or moved to an ineligible plan. Plan perks, taxes, fees, and surcharges are subject to change.',
  icon: 'lock'
};
const homeInternet25 = {
  title: 'Home internet starting at $25/mo',
  titleForCompare: 'Starting at $25/mo',
  description: 'Get up to 50% off your Verizon 5G Home or LTE Home internet plan, or get Fios internet and save with the Mobile + Home Discount. Both as low as $25/mo when enrolled in Auto Pay, where service is available.'
};
const homeInternet40 = {
  title: 'Home internet starting at $40/mo',
  titleForCompare: 'Starting at $40/mo',
  description: 'Get up to 20% off your Verizon 5G Home or LTE Home Internet plan, or get Fios internet and save with the Mobile + Home Discount. Both as low as $40/mo when enrolled in Auto Pay, where service is available.'
};
const newDevice800 = {
  title: 'Save up to $800 on your new device',
  description: 'Up to $799.99 on device payment or at retail price required. Less up to $800 trade-in/promo credit applied over 36 months; promo credits end if eligibility requirements are no longer met. 0% APR. Trade-in conditions apply. Unlimited Plus plan required for new lines. Taxes and fees may apply.'
};

const PERK_PRICE = 10;
const conceptBauPricing = [
  [ 80, 70, 55, 45, 42 ],
  [ 80, 70, 55, 45, 42 ],
  [ 65, 55, 40, 30, 27 ],
  [ 80, 70, 55, 45, 42 ],
  [ 65, 55, 40, 30, 27 ]
];

const getForkList = (startPopularPlans, startOwnPlan) => ([
  {
    title: "Start with our most popular plans.",
    description: "Customize it to make it yours.",
    clickStart: startPopularPlans
  },
  {
    title: "Start your own plan.",
    description: "Pick what you want in two simple steps.",
    clickStart: startOwnPlan
  }
]);

const premiumCore = {
  id: 1,
  title: 'Unlimited Plus',
  note: 'Unlimited',
  description: 'Our fastest 5G speeds, up to 10x faster than 4G LTE. No matter how much you use.',
  modalDescription: 'Our fastest 5G speeds, up to 10x faster than 4G LTE. No matter how much you use.',
  perks: [
    fiveGUltraWideBand,
    mobileHotspot30,
    upToDevicePlan,
    yearPriceGuarantee3
  ],
  perksForPrebuild: [
    fiveGUltraWideBand,
    mobileHotspot30
  ],
  descriptions: [
    fiveGUltraWideBand,
    mobileHotspot30,
    homeInternet25,
    newDevice800,
    upToDevicePlan,
    yearPriceGuarantee3,
    allPlansIncludeConcept[0],
    allPlansIncludeConcept[1],
    allPlansIncludeConcept[2],
    allPlansIncludeConcept[3]
  ],
  compare: {
    data: fiveGUltraWideBand,
    mobileHotspot: mobileHotspot30,
    homeInternet: homeInternet25,
    deviceOffers: newDevice800,
    connectedDevice: upToDevicePlan,
    priceGuarantee: yearPriceGuarantee3
  }
};

const standardCore = {
  id: 2,
  title: 'Unlimited Welcome',
  note: 'Unlimited',
  description: 'Our reliable, fast 5G.',
  modalDescription: 'Our reliable, fast 5G.',
  perks: [
    fiveG
  ],
  perksForPrebuild: [
    fiveG
  ],
  descriptions: [
    fiveG,
    homeInternet40,
    allPlansIncludeConcept[0],
    allPlansIncludeConcept[1],
    allPlansIncludeConcept[2],
    allPlansIncludeConcept[3]
  ],
  compare: {
    data: fiveG,
    homeInternet: homeInternet40
  }
};

const disneyPlusDetails = {
  include: [
    {
      title: "Disney+ Premium (No Ads)",
      img: "disney-part-1.jpg",
      description: "Disney+ is the streaming home of Disney, Pixar, Marvel, Star Wars, National Geographic, and more. From new releases to your favorite classics and exclusive Originals, there’s something for everyone."
    },
    {
      title: "Hulu (With Ads)",
      img: "disney-part-2.jpg",
      description: "Hulu saves you from boredom with the freshest originals, and all-time favorite shows, hit movies, live sports and news, all in one place. Hulu is home to the greatest networks and studios like FX, ABC, Searchlight and 20th Century."
    },
    {
      title: "ESPN+ (With Ads)",
      img: "disney-part-3.jpg",
      imgBorder: true,
      description: "Stream thousands of live events from the best leagues and biggest tournaments in the world, access the full 30 for 30 library, discover exclusive originals, and more."
    }
  ],
  banner: "disney-banner.jpg",
  important:
    {
      text: [
        "Must be 18 years of age or older to enroll.",
        "Access content from each service separately.",
        "Location data is required to watch certain content.",
        "Enrolling in Disney Bundle may affect existing subscriptions to Disney+, Hulu and ESPN+. Review the Terms & Conditions for instruction.",
        "<b>EXISTING DISNEY+, HULU OR ESPN+ subscribers</b>: Offer will not automatically replace existing subscription(s).",
        "After enrolling in Disney Bundle, you will need to complete account setup separately for each service.",
        "<b>Managing subscriptions may be required to avoid multiple subscriptions and corresponding charges.</b>",
        "For existing Hulu subscribers, you may need to manage your subscription to complete account setup.",
        "$4.99/mo perk savings based on the current $14.99/mo that Verizon Disney Bundle Subscribers pay for the bundle less the $10/mo Disney perk added to myPlan.",
        "+play credit is not eligible to be used toward enrollment in Disney Bundle.",
        "Subject to Disney+ and ESPN+ Subscriber Agreement (<span class='underlined'>https://www.disneyplus.com/en-gb/legal/subscriber-agreement</span>).",
        "Subject to Hulu Subscriber Agreement (<span class='underlined'>https://www.hulu.com/subscriber_agreement</span>).",
        "© 2023 Disney and its related entities. © 2023 & TM Lucasfilm Ltd."
      ],
      type: 'list'
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “Disney Bundle” plan perk, which includes access to Disney+ (No Ads), Hulu (With Ads), and ESPN+ (With Ads).",
    "One offer per line.",
    "$4.99/mo perk savings based on the current $14.99/mo that Verizon Disney Bundle Subscribers pay for the bundle less the $10/mo Disney perk added to Welcome Unlimited, Unlimited Welcome or Unlimited phone plans.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line. Access to the Disney Bundle streaming services begins upon activating each of the three services: Disney+ (No Ads), Hulu (With Ads), and ESPN+ (With Ads). When activating the Disney Bundle streaming services, you must activate Disney+ (No Ads) prior to activating Hulu (With Ads) and ESPN+ (With Ads).",
    "If you already have a Disney Bundle, Disney+, Hulu, or ESPN+ subscription, it may be necessary to manage your plan to avoid multiple subscriptions. It is your responsibility to manage your subscriptions. By purchasing the Disney Bundle perk, you may lose any free trials, discounts, or promotional pricing associated with your current Disney Bundle, Disney+, ESPN+, and/or Hulu subscriptions.",
    "If you have an existing subscription to Disney+ or the Disney Bundle through Verizon, switch all eligible lines to Unlimited Welcome or Unlimited Plus phone plans and enroll in the Disney Bundle perk, your existing subscription to Disney+ or the Disney Bundle will be replaced. If you have an existing subscription to Disney+ or the Disney Bundle through Verizon and switch all eligible lines to Unlimited Welcome or Unlimited Plus phone plans and do not enroll in the Disney Bundle plan perk, your existing subscription to Disney+ or the Disney Bundle will be canceled effective immediately. If lines on your account remain eligible for the Disney+ 6-month offer or the Disney Bundle Inclusion offer, those lines will continue to receive the offer until you switch to an ineligible plan. Offers may end without notice.",
    "If you have an active existing subscription to the Disney Bundle, Disney+, and/or ESPN+ that are billed directly by Disney or ESPN+, as applicable, your existing subscription(s) will be paused after you activate your Disney Bundle perk with Disney+. If you cancel Disney Bundle through Verizon, your previous subscription(s) billed directly by Disney will resume.",
    "If you have an existing Hulu (With Ads), Hulu (No Ads), Hulu + Live TV, Hulu (No Ads) + Live TV, or Disney Bundle subscription, and are billed by Hulu, the Disney Bundle perk will not automatically replace that subscription. After successfully enrolling and completing account setup of all three streaming services that are part of the Disney Bundle perk, existing Hulu (With Ads) and Hulu (No Ads) subscribers billed directly by Hulu will see a credit from Hulu on their billing statement for an amount equal to the then-current retail price of Hulu (With Ads), for as long as they maintain an eligible subscription through Verizon. Unless otherwise directed, note that in order to receive any credit, existing subscribers must complete Hulu account setup through the link provided by Disney+. Note that existing Hulu (With Ads) and Hulu (No Ads) subscribers billed directly by Hulu may need to manage add-ons in order to complete Hulu account setup. Subscribers to one of Hulu’s Live TV plans or the Disney Bundle billed directly by Hulu will need to cancel or switch their base plan to the Hulu (With Ads) or Hulu (No Ads) plan in order to complete Hulu account setup. Annual Hulu subscribers will need to cancel their Hulu subscription in order to complete Hulu account setup; cancellation will forfeit the remainder of the annual subscription period and will not be entitled to a refund. If you currently have a Hulu subscription billed through a third party, you will need to manage your account and wait until the end of your billing period in order to complete Hulu account setup; you will continue to be billed for your existing Hulu subscription until you manage your account. If you do not have an existing Hulu subscription at the time you enroll in the Disney Bundle plan perk, you will not be able to switch your plan or purchase add-ons offered by Hulu while enrolled.",
    "For any existing Hulu subscriber, no refunds will be provided for subscription fees already paid to Hulu. Review your previous provider’s terms and conditions regarding cancellation.",
    "If your subscription to the Disney Bundle, Disney+, Hulu and/or ESPN+ is through a third-party like Apple, Google, Amazon, Roku or others, the Disney Bundle perk will not automatically replace or pause that subscription. After you enroll and complete account setup, you will be sent an email with information about how to manage your existing subscription. You will continue to be billed for your existing subscription through the third-party until you follow the instructions in the email.",
    "To ensure that your existing subscription(s) are paused or credited, or that you receive a notification to manage your existing subscription(s), in each case as applicable pursuant to these terms and conditions, you must use the same email when activating your Disney Bundle plan perk subscription that you used to purchase your existing subscription(s).",
    "You can cancel Disney Bundle at any time through My Verizon Online or the My Verizon App. Offer may end without notice.",
    "Use of the Disney+ service is subject to Disney+ and ESPN+ Subscriber Agreement. Use of the Hulu service is subject to Hulu Subscriber Agreement."

  ],
  faq: [
    {
      title: "What is the Disney Bundle?",
      description: "The Disney Bundle is the \"Something for Everyone\" streaming solution from The Walt Disney Company. With the Disney Bundle through Verizon, you get access to thousands of movies, hit series and exclusive live sports events with subscriptions to Disney+ (No Ads), Hulu (With Ads) and ESPN+ (With Ads)."
    },
    {
      title: "I have a Disney Bundle, Disney+ and/or ESPN+ subscription already through Disney. What do I need to do?",
      description: "If you have an active existing subscription to the Disney Bundle, Disney+, and/or ESPN+ that are billed directly by Disney or ESPN+, as applicable, your existing subscription(s) will be paused after you activate your Disney Bundle perk with Disney+. If you cancel Disney Bundle through Verizon, your previous subscription(s) billed directly by Disney will resume."
    },
    {
      title: "I have a Disney Bundle or Disney+ subscription already through Verizon. What do I need to do?",
      description: "If you have an existing subscription to Disney+ or the Disney Bundle through Verizon, switch all eligible lines to Unlimited Welcome or Unlimited Plus phone plans and enroll in the Disney Bundle perk, your existing subscription to Disney+ or the Disney Bundle will be replaced. If you have an existing subscription to Disney+ or the Disney Bundle through Verizon and switch all eligible lines to Unlimited Welcome or Unlimited Plus plans and do not enroll in the Disney Bundle plan perk, your existing subscription to Disney+ or the Disney Bundle will be canceled effective immediately. If lines on your account remain eligible for the Disney+ 6-month offer or the Disney Bundle Inclusion offer, those lines will continue to receive the offer until you switch to an ineligible plan. Offers may end without notice."
    },
    {
      title: "I have a Disney+, Hulu, ESPN+, or a Disney Bundle subscription already through a third-party. What do I need to do?",
      description: "If billed through a third-party like Apple, Google, Amazon, Roku or others this Verizon subscription will not automatically replace that subscription. After you enroll and complete the account setup, you will be sent an email with information about how to manage your third-party billed subscription. You will continue to be billed for your third-party billed subscription to the Disney Bundle until you follow the instructions in the email."
    },
    {
      title: "I have a Hulu subscription already through Hulu. What do I need to do?",
      description: "If you have an existing Hulu (With Ads), Hulu (No Ads), Hulu + Live TV, Hulu (No Ads) + Live TV, or Disney Bundle subscription, and are billed by Hulu, the Disney Bundle perk will not automatically replace that subscription. After successfully enrolling and completing account setup of all three streaming services that are part of the Disney Bundle perk, existing Hulu (With Ads) and Hulu (No Ads) subscribers billed directly by Hulu will see a credit from Hulu on their billing statement for an amount equal to the then-current retail price of Hulu (With Ads), for as long as they maintain an eligible subscription through Verizon. Unless otherwise directed, note that in order to receive any credit, existing subscribers must complete Hulu account setup through the link provided by Disney+. Note that existing Hulu (With Ads) and Hulu (No Ads) subscribers billed directly by Hulu may need to manage add-ons in order to complete Hulu account setup. Subscribers to one of Hulu’s Live TV plans or the Disney Bundle billed directly by Hulu will need to cancel or switch their base plan to the Hulu (With Ads) or Hulu (No Ads) plan in order to complete Hulu account setup. Annual Hulu subscribers will need to cancel their Hulu subscription in order to complete Hulu account setup; cancellation will forfeit the remainder of the annual subscription period and will not be entitled to a refund. If you currently have a Hulu subscription billed through a third party, you will need to manage your account and wait until the end of your billing period in order to complete Hulu account setup; you will continue to be billed for your existing Hulu subscription until you manage your account. If you do not have an existing Hulu subscription at the time you enroll in the Disney Bundle plan perk, you will not be able to switch your plan or purchase add-ons offered by Hulu while enrolled."
    },
    {
      title: "Who can I contact if I have questions about the Disney Bundle through Verizon?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for the Disney Bundle on your Verizon bill, or redeeming the perk.",
        "Contact:",
        "<span class='underlined'>http://help.disneyplus.com</span> for questions about Disney+.",
        "<span class='underlined'>http://help.espnplus.com</span> for questions about ESPN+.",
        "<span class='underlined'>https://help.hulu.com</span> for questions about Hulu.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/disney-bundle-perk-faqs</span>."
      ]
    }
  ]
};

const mobileHotspot100Details = {
  include: [
    {
      title: "100 GB Mobile Hotspot",
      description: "With this perk, you get 100 GB of mobile hotspot data. Mobile hotspots allow you to create a secure, reliable Wi-Fi connection. You can then use your personal, private hotspot connection on any Wi-Fi-enabled device, such as a laptop, tablet or smartphone. You can create a mobile hotspot on most smartphones andt by using a dedicated device and mobile hotspot data plan you can allow more devices to be connected to your secure, private network at one time. Using data specifically allotted to your mobile hotspot device won’t then detract from your smartphone’s data plan."
    }
  ],
  important:
    {
      type: "list",
      text: [
        "All of Verizon’s currently available smartphones and basic phones are compatible with Mobile Hotspot.",
        "Allows your smartphone to become a 5G / 4G Wi-Fi connection for devices like tablets, laptops and more.",
        "100 GB Mobile Hotspot will stack on top of any existing Mobile Hotspot data included with your Verizon mobile plan.",
        "100 GB Mobile Hotspot data can only be used within your current billing cycle. Unused data does not carry over to the next billing cycle.",
        "Welcome 1.0 lines are not eligible to enroll in 100 GB Mobile Hotspot.",
        "Billing begins upon enrollment."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on an Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “100 GB Mobile Hotspot” plan perk.",
    "One offer per line per billing cycle.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "100 GB Mobile Hotspot is stackable with existing plan allowances.",
    "Welcome 1.0 lines are not eligible to enroll in 100 GB Mobile Hotspot.",
    "Upon full usage of 100 GB Mobile Hotspot, mobile hotspot speeds will be reduced to up to 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G Nationwide / 4G LTE for the remainder of your monthly billing cycle.",
    "You can cancel 100 GB Mobile Hotspot at any time through My Verizon Online or the My Verizon App. After cancellation, you will still have access to the remainder of your current 100 GB allotment for the remainder of your then current billing cycle. You will no longer receive additional allotments thereafter. Offer may end without notice.",
    "Use of 100 GB Mobile Hotspot is subject to Important Plan Information.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/hotspot-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is Mobile Hotspot?",
      description: "A Mobile Hotspot lets you share your Verizon network connection with other devices so they can access the internet. Devices connect to your Mobile Hotspot using Wi-Fi. While devices are connected, you're charged for any data they use according to your monthly data plan."
    },
    {
      title: "Which Verizon mobile devices are compatible with Mobile Hotspot?",
      description: "All of our currently available smartphones and basic phones are compatible with Mobile Hotspot features or apps. If you have an older device, refer to the User Guide on your Device Support page to be sure Mobile Hotspot is compatible."
    },
    {
      title: "Do I need to download a Mobile Hotspot app to my device to use 100 GB Mobile Hotspot?",
      description: "No, Mobile Hotspot comes preinstalled on compatible devices. Depending on your device, it may be in your device's App menu or Settings. It may also be named something different, like Personal Hotspot (iOS), Internet Sharing (Windows) or Portable Wi-Fi Hotspot (Google Nexus devices)."
    },
    {
      title: "What if I already have Mobile Hotspot data on my line and enroll in 100 GB Mobile Hotspot?",
      description: "Mobile Hotspot data is included in many Verizon mobile plans. 100 GB Mobile Hotspot will stack on top of any existing Mobile Hotspot data included with your Verizon mobile plan."
    },
    {
      title: "How long do I have to use 100 GB Mobile Hotspot?",
      description: "You have full access to 100 GB of Mobile Hotspot data for the full length of your current billing cycle."
    },
    {
      title: "Who can I contact if I have questions about 100 GB Mobile Hotspot?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for 100 GB Mobile Hotspot on your Verizon bill, or redeeming the perk.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/hotspot-perk-faqs</span>. "
      ]
    }
  ]
};

const appleOneDetails = {
  include: [
    {
      title: "Apple Music",
      img: "apple-one-part-1.jpg",
      description: "Listen to 100+ million songs, ad-free, across all your devices. Hear sound all around with Spatial Audio. And try Apple Music Classical, featuring the world’s largest classical music catalog."
    },
    {
      title: "Apple TV+",
      img: "apple-one-part-2.jpg",
      description: "Watch award-winning Apple Originals series and films, ad-free. Episodes, shows or movies — there’s something new every week."
    },
    {
      title: "Apple Arcade",
      img: "apple-one-part-3.jpg",
      description: "Enjoy unlimited access to hundreds of incredibly fun games, including Arcade Originals, App Store Greats and Timeless Classics with no ads and no in-app purchases."
    },
    {
      title: "iCloud+",
      img: "apple-one-part-4.jpg",
      imgBorder: true,
      description: "Get the storage you need for all the things you love and an even more powerful, connected experience across your devices."
    }
  ],
  includeForVariant: [
    {
      title: "Apple Music",
      values: [ true, true ]
    },
    {
      title: "Apple TV+",
      values: [ true, true ]
    },
    {
      title: "Apple Arcade",
      values: [ true, true ]
    },
    {
      title: "iCloud+",
      values: [ "50 GB", "200 GB" ]
    },
    {
      title: "Shareable",
      values: [ false, "With up to 5 other people" ]
    }
  ],
  banner: "apple-one-banner.jpg",
  important:
    {
      type: "list",
      text: [
        "Must have an iTunes/Apple Media Services account and an iPhone with iOS 14.7 or later to activate.",
        "With Apple TV+ and Apple Arcade, you can share with up to five other people.",
        "Enrolling in Apple One may affect existing subscriptions to Apple One & other Apple services. Review the Terms & Conditions for instruction.",
        "+play credit is not eligible to be used toward enrollment in Apple One.",
        "Subject to Apple Media Services Terms of Use and Apple Privacy Policy."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “Apple One” plan perk.",
    "One offer per line.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "If you have an active paid Apple One individual subscription purchased directly through Apple, your Apple One individual or family subscription through Verizon will automatically suspend your existing subscription. If you cancel Apple One individual subscription through Verizon, your paid Apple One individual subscription purchased directly through Apple will resume.",
    "If you have an active paid Apple One family subscription purchased directly through Apple, your Apple One individual subscription through Verizon and your Apple One family subscription through Apple will run at the same time. An Apple One family subscription through Verizon will automatically suspend your existing Apple One family subscription through Apple. If you cancel your Apple One family subscription through Verizon, your paid Apple One family subscription purchased directly through Apple will resume.",
    "If you have an active paid Apple One premier subscription purchased directly through Apple, your Apple One individual or family subscription through Verizon and your Apple One premier subscription through Apple will run at the same time.",
    "If you would like to cancel your paid subscription to Apple One purchased directly through Apple, you will need to cancel with Apple.",
    "You can cancel Apple One at any time through My Verizon Online or the My Verizon App. Offer may end without notice.",
    "Apple One plan perk cannot be combined with Apple Music Family plan perk on the same line.",
    "Use of Apple One service is subject to Apple Media Services Terms of Use and Apple Privacy Policy. Requires Apple ID and iOS 14.7 or later to activate the offer. Compatible products and services required. By accepting, Apple and Verizon will share information necessary to activate and maintain your Apple One subscription.",
    "Apple, iPhone, iPad and Apple One are registered trademarks of Apple Inc.",
    "iOS is a trademark or registered trademark of Cisco in the US and other countries and is used under license.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/apple-one-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is Apple One?",
      description: [
        "Apple One bundles together Apple Music®, Apple TV+,® Apple Arcade®, and iCloud+℠ with up to 200 GB of storage. Plus, it's all ad-free.",
        "Apple One is available as an individual or family subscription through Verizon."
      ]
    },
    {
      title: "I have an Apple One individual subscription through Apple already. What do I need to do?",
      description: "If you have an active paid Apple One individual subscription through Apple, your Verizon subscription will suspend your existing subscription. If you cancel your Apple One individual subscription through Verizon, your paid Apple One individual subscription with Apple will resume."
    },
    {
      title: "I have an Apple One family subscription through Apple already. What do I need to do?",
      description: [
        "If you have an active paid Apple One family subscription through Apple, your Apple One individual subscription through Verizon will run at the same time.",
        "If you enroll in an Apple One family subscription through Verizon, your Apple One family subscription through Apple will be suspended. If you cancel your Verizon subscription, your paid Apple One family subscription through Apple will resume."
      ]
    },
    {
      title: "I have an Apple One premier subscription through Apple already. What do I need to do?",
      description: "If you have an active paid Apple One premier subscription through Apple, your Apple One individual or family subscription through Verizon will run at the same time."
    },
    {
      title: "Who can I contact if I have questions about Apple One?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for Apple One on your Verizon bill, or redeeming the perk.",
        "You can contact Apple customer care for questions about Apple One and its services (i.e., Apple Music, Apple TV+, Apple Arcade, and iCloud+).",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/apple-one-perk-faqs</span>."
      ]
    }
  ]
};

const walmartDetails = {
  include: [
    {
      description: [
        "Walmart+ is the membership that can help you save even more time & money when you shop at Walmart.",
        "As a member, you’ll get free grocery delivery from stores with a minimum order of $35, a Paramount+ subscription included, up to 10¢ off per gallon in fuel discounts at Exxon, Mobil, Walmart & Murphy stations and free shipping with no order minimum. Plus, more member exclusive benefits!"
      ]
    }
  ],
  banner: "",
  important:
    {
      type: "list",
      text: [
        "Free delivery of fresh groceries & more from your Walmart store: $35 order min. Restrictions apply.",
        "Paramount+: Paramount+ Essential plan, separate registration required.",
        "Fuel: Fuel discount varies by location & station, subject to change.",
        "Free shipping: Excludes most Marketplace items, freight & certain location surcharges.",
        "Enrolling in Walmart+ Membership may affect existing subscriptions to Walmart+. Review the Terms & Conditions for instruction.",
        "+play credit is not eligible to be used toward enrollment in Walmart+ Membership.",
        "Subject to Walmart+ Membership Terms of Use & Walmart.com Terms of Use"
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “Walmart+ Membership” plan perk.",
    "One offer per line.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "If you have an active paid subscription to Walmart+ purchased directly through Walmart, Walmart+ Membership through Verizon will automatically cancel your existing subscription.",
    "By purchasing the Walmart+ Membership perk through Verizon, you will lose any free trials, discounts, or promotional pricing associated with your current Walmart+ subscription.",
    "If you want to sign up for another promotion, you will need to cancel your existing Walmart+ Membership through Verizon and wait until the end of the monthly subscription period before signing up for a new promotion.",
    "You can cancel Walmart+ Membership at any time through My Verizon Online or the My Verizon App. Offer may end without notice.",
    "Use of the Walmart+ service is subject to <span class='underlined'>Walmart+ Membership Terms of Use</span> & <span class='underlined'>Walmart.com Terms of Use</span> (<span class='underlined'>https://www.walmart.com/help/article/walmart-terms-of-use/de696dfa1dd4423bb1005668dd19b845</span>)",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/walmart-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is Walmart+?",
      description: "Walmart+ is a membership from Walmart that gives you access to lots of benefits that will help you save time and money."
    },
    {
      title: "What is included with Walmart+?",
      description: "Walmart+ includes free delivery from your Walmart store, free shipping with no order minimum from Walmart.com, member prices on fuel at select stations, and so much more. See <span class='underlined'>Walmart+ Terms of Use</span> (<span class='underlined'>https://www.walmart.com/help/article/walmart-terms-of-use/de696dfa1dd4423bb1005668dd19b845</span>)."
    },
    {
      title: "Do I need to download the Walmart app to use Walmart+?",
      description: "Some benefits of the Walmart+ membership are only available through the Walmart app."
    },
    {
      title: "I have Walmart+ already. What do I do?",
      description: [
        "If you have an active paid subscription to Walmart+ through Walmart, your Verizon subscription will automatically cancel your existing subscription.",
        "By purchasing the Walmart+ Membership perk through Verizon, you will lose any free trials, discounts, or promotional pricing associated with your current Walmart+ subscription."
      ]
    },
    {
      title: "Who can I contact if I have questions about Walmart+ Membership?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for Walmart+ Membership on your Verizon bill, or redeeming the perk.",
        "You can contact Walmart customer support (<span class='underlined'>https://corporate.walmart.com/about/contact</span>) for questions about Walmart+ service.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/walmart-perk-faqs</span>."
      ]
    }
  ]
};

const appleMusicDetails = {
  include: [
    {
      title: "Apple Music",
      img: "apple-one-part-1.jpg",
      description: "Apple Music gives you access to over 100 million songs and 30,000 playlists, ad-free. Listen online or off, across all your devices, and hear sound all around in Spatial Audio with dynamic head tracking. With an Apple Music family subscription, up to six people can enjoy all the features and the full catalog of Apple Music, including their own personal music library and music recommendations based on what they love to listen to."
    }
  ],
  banner: "",
  important:
    {
      type: "list",
      text: [
        "Apple Music is already on your iPhone, iPad, Apple Watch, Apple TV 4 K, Mac, and HomePod.",
        "Listen with CarPlay or online at <span class='underlined'>music.apple.com.</span>",
        "Also available on Windows and Android devices, Sonos speakers, Amazon Echo, Google Nest, smart TVs, and more.",
        "Must have an iTunes/Apple Media Services account and an iPhone with iOS 14.7 or later or Apple Music app 4.1.1 or later on Android to activate.",
        "Share with up to five (5) other people.",
        "Enrolling in Apple Music Family may affect existing subscriptions to Apple Music. Review the Terms & Conditions for instruction.",
        "+play credit is not eligible to be used toward enrollment in Apple Music.",
        "Subject to Apple Media Services Terms of Use and Apple Privacy Policy."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “Apple Music Family” plan perk.",
    "One offer per line.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "If you have an active paid Apple Music Family subscription purchased directly through Apple, Apple Music Family through Verizon will automatically suspend your existing subscription. If you cancel Apple Music Family through Verizon, your paid Apple Music Family subscription purchased directly through Apple will resume.",
    "If you would like to cancel your paid subscription to Apple Music Family purchased directly through Apple, you will need to cancel with Apple.",
    "You can cancel Apple Music Family through Verizon at any time through My Verizon Online or the My Verizon App. Offer may end without notice.",
    "Apple Music Family plan perk cannot be combined with Apple One plan perk on the same line.",
    "Use of Apple Music Family service is subject to Apple Media Services Terms of Use and Apple Privacy Policy. Requires an Apple ID and an iPhone with iOS 14.7 or later or Apple Music app 4.1.1 or later on Android to activate. Compatible products and services required. By accepting, Apple and Verizon will share information necessary to activate and maintain your Apple One subscription.",
    "Apple, iPhone, iPad and Apple Music are registered trademarks of Apple Inc.",
    "iOS is a trademark or registered trademark of Cisco in the US and other countries and is used under license.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/apple-music-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is Apple Music?",
      description: "Apple Music is a streaming music service that lets you stream over 100 million songs ad-free. You can also download music to listen to offline, watch music videos and create playlists of your favorite songs."
    },
    {
      title: "Can I use my Apple Music subscription from Verizon on other devices besides my smartphone?",
      description: "Yes, if you log in to iTunes® or the Music app with the Apple ID that 's getting Apple Music from Verizon, you can access your saved music and playlists."
    },
    {
      title: "Can I get Apple Music if I have an Android smartphone?",
      description: "Yes, Android smartphones are eligible for Apple Music. Just download the Apple Music app onto your smartphone if you don't have it already."
    },
    {
      title: "Can I listen to Apple Music on my smartwatch?",
      description: "Yes, you can enjoy Apple Music on your smartwatch when it's connected through Wi-Fi, or when using cellular service."
    },
    {
      title: "I have Apple Music family subscription already. What do I do?",
      description: "If you enroll in an Apple Music Family subscription through Verizon, your Apple Music Family subscription through Apple will be suspended. If you cancel your Verizon subscription, your paid Apple Music Family subscription through Apple will resume."
    },
    {
      title: "Who can I contact if I have questions about Apple Music family subscription?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for Apple Music Family on your Verizon bill, or redeeming the perk.",
        "You can contact Apple customer care for questions about Apple Music Family.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/apple-music-perk-faqs</span>."
      ]
    }
  ]
};

const smartwatchDetails = {
  include: [
    {
      title: "Unlimited Smartwatch data plan discount",
      description: "Stay connected, even when your phone stays home. Plus, you can track runs, bike rides and more—all while streaming music."
    },
    {
      title: "Care Smart app with Get Help - Professional Monitoring discount",
      img: "smartwatch-part.jpeg",
      description: [
        "Professional Monitoring puts you immediately in touch with a 24/7 Get Help agent whenever you tap the Get Help button or when your smartwatch reports a fall. Get Help - Professional Monitoring available exclusively on select smartwatch models."
      ]
    }
  ],
  banner: "",
  important:
    {
      type: "list",
      text: [
        "Care Smart Get Help - Professional Monitoring available exclusively from Verizon for Apple Watch Series 4 and later.",
        "Smartwatch Data & Safety provides a full discount on a smartwatch data plan and a full discount on a subscription to Get Help - Professional Monitoring.",
        "If canceling Smartwatch Data & Safety you will lose your discount. Without the discount, you will be charged $10 for your smartwatch data plan.",
        "Care Smart Get Help - Professional Monitoring will be canceled along with cancellation of Smartwatch Data & Safety. Your smartwatch data plan must be canceled separately."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “Smartwatch Data & Safety” plan perk.",
    "One offer per line.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "Smartwatch Data & Safety includes full discount access to a smartwatch data plan and full discount to a subscription to Care Smart with Get Help - Professional Monitoring. Smartwatch not included.",
    "Get Help - Professional Monitoring is only compatible with Apple Watch Series 4 or later and Samsung Galaxy Watch4 or later. Your Apple Watch or Galaxy Watch must be connected and enrolled in Get Help - Professional Monitoring in order to receive the discount.",
    "Setting up Care Smart on an Apple Watch with a Number Share line requires an iPhone with iOS 9 or later. Setting up Care Smart on Android-powered watches with a Number Share line requires a smartphone with Android 5.0 or later. If the watch is on a standalone line, both iOS 9 or later and Android 5.0 or later can be used for set up. Once Care Smart is set up, the watch user’s Trust Circle contacts may use the Care Smart app on either iOS or Android-powered smartphones.",
    "You can cancel Smartwatch Data & Safety at any time through My Verizon Online or the My Verizon App. Upon cancellation, you will lose your discount. Get Help - Professional Monitoring will be canceled. Your smartwatch data plan will continue for $10/mo. until separately canceled. Offer may end without notice.",
    "Use of Smartwatch Data Plan is subject to Important Plan Information. Use of Care Smart and Get Help Professional Monitoring is subject to Care Smart Terms & Conditions.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/data-safety-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is a connected device plan?",
      description: "Connected device plans are monthly data plans for your non-phone devices (i.e., mobile hotspot devices, USB modems, tablets, laptops, smartwatches, security cameras, in-car Wi-Fi, and more)."
    },
    {
      title: "What is Care Smart and Get Help - Professional Monitoring?",
      description: [
        "Care Smart is a communications and location service for eligible smartwatches. The Care Smart app helps caregivers monitor and locate the paired watches from their smartphones. Caregivers can use the Care Smart app with any Android™ phone or iPhone®, on any network.",
        "Get Help - Professional Monitoring notifies a call center that's staffed 24/7, 365 days per year*: Available exclusively for Apple Watch Series 4 or later and Samsung Galaxy Watch4 or later.",
        "Puts you immediately in touch with a 24/7 Get Help agent whenever you tap the Get Help button or when your smartwatch reports a fall.**",
        "Get Help Agent will help you through the situation and stay on the line until you feel safe. If needed, the agent will bridge the call with emergency services or a personal caregiver. The agent will confirm your watch’s reported location.",
        "Caregivers also receive an incident summary notification in the Care Smart app when the Get Help agent closes your case.",
        "The number of contacts in a Care Smart Trust Circle is unlimited.",
        "Trust Circle contacts can keep up on your watch’s location. They can set up a number of location alerts that can be set for time of day or when your watch moves in or out of a particular location.",
        "&nbsp;",
        "* Agents at WiMacTel Inc. dba viiz Communications (ViiZ Communications) support all assistance calls made in connection with the Get Help - Professional Monitoring service.- A ViiZ Communications agent is also responsible for calling the user's watch after their watch detects a fall. A ViiZ Communications agent provides voice-call response to the watch user in the event of a fall alert or when the user taps the Get Help button on their watch face or, specifically for Apple Watch, makes an assistance call with Siri. ViiZ Communications is an independent company and not a Verizon affiliate or agent.",
        "** If your Apple watch detects the wearer is unresponsive for about a minute after a hard fall, the watch automatically calls emergency services.",
        "*** If your Samsung watch detects the wearer is unresponsive for about a minute after a hard fall, SOS message will be sent to Professional Monitoring center, once you set in the Galaxy wearable app."
      ]
    },
    {
      title: "Is Care Smart available for Android?",
      description: [
        "Care Smart is available for Android.",
        "Get Help - Professional Monitoring is only available for Apple Watch Series 4 or later and Samsung Galaxy Watch4 or later."
      ]
    },
    {
      title: "What kind of smartwatch do I need to use Smartwatch Data & Safety discounts?",
      description: "You must have an Apple Watch Series 4 or later or a Samsung Galaxy Watch4 or later and it must be connected and enrolled in Get Help - Professional Monitoring in order to receive the discount."
    },
    {
      title: "Can I use Smartwatch Data and Safety discounts if I am already enrolled in Get Help - Professional Monitoring?",
      description: "Yes, you will receive the discount for Get Help - Professional Monitoring once you complete setup for your smartwatch after purchasing Smartwatch Data and Safety."
    },
    {
      title: "What happens when I cancel Smartwatch Data & Safety?",
      description: "You can cancel Smartwatch Data & Safety at any time through My Verizon Online or the My Verizon App. Upon cancellation, you will lose your discount. Get Help - Professional Monitoring will be canceled. Your smartwatch data plan will continue for $10/mo. until separately canceled."
    },
    {
      title: "Who can I contact if I have questions about Smartwatch Data & Safety?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for Smartwatch Data & Safety on your Verizon bill, or redeeming the perk.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/data-safety-perk-faqs</span>."
      ]
    }
  ]
};

const plusPlayDetails = {
  include: [
    {
      description: "Get $15 to spend each month on your favorite +play subscriptions for just $10. Get Netflix, Xbox Game Pass, HBO Max, Paramount, MasterClass, and more - for less!"
    }
  ],
  banner: "",
  important:
    {
      type: "list",
      text: [
        "+play Monthly Credit value expires at the end of each month.",
        "All lines on your account are eligible for the +play Monthly Credit. Value will be accumulated for use on +play.",
        "Services currently not eligible for use with the +play Monthly Credit: Disney Bundle, Disney+, Apple One, Apple Music, Apple Arcade, Discovery+, Google Play Pass, Walmart+, Xbox All Access, YouTube TV.",
        "Unused credit does not carry over to the next monthly cycle."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the “+play Monthly Credit” for $10 plan perk.",
    "One offer per line.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "+play Monthly Credit is a credit that can be used toward eligible services in the +play storefront for/with a +play account.",
    "One +play Monthly Credit may be purchased for each line on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan on a recurring monthly basis. +play Monthly Credit must be purchased by the Account Owner or an Account Manager. Billing begins upon purchase of the +play Monthly Credit and will auto-renew monthly until canceled by the Account Owner / Manager. Charges for the +play Monthly Credit will appear on the account’s Verizon Wireless bill.",
    "+play Monthly Credit is available in your +play account for one month from the date of purchase, after which the full or remaining unused portion of the +play Monthly Credit expires.",
    "On the perk enrollment date, Verizon will apply a $15 credit to the +play account for each +play Monthly Credit on its perk enrollment date. Each credit will be applied to the first/next subscription that comes due in the +play account, with any remaining balance applied toward each successively due subscription until no balance remains. Any unused portion of any credit will expire if not used within one month.",
    "Your +play credit has no cash value, is non-negotiable, and cannot be redeemed either in whole or in part for cash.",
    "+play Monthly Credits may be used toward any +play service, except the following: Apple One, Apple Music, Apple Arcade, Disney Bundle, Disney+, Discovery+, ESPN+, Google Play Pass, Hulu, Hulu + Live TV, Walmart+, YouTube TV, and Xbox All Access subscriptions.",
    "You can cancel +play Monthly Credit at any time through My Verizon Online or the My Verizon App. After cancellation, you will still have access to your most recent +play Monthly Credit until its expiration. Offer may end without notice.",
    "Use of +play is subject to +play Terms & Conditions.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/entertainment-play-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is +play?",
      description: "+play lets you discover, purchase and manage your digital subscriptions in one place. You get a complete view of subscriptions you have, what you're paying for and other available services."
    },
    {
      title: "What are +play credits? How do I get +play credits?",
      description: "A +play credit can be used to make purchases in +play. You can get a +play credit worth $15/month for $10/month with each mobile line on your account that has a Welcome Unlimited, Unlimited Welcome or Unlimited Plus mobile phone plan."
    },
    {
      title: "What subscriptions can I get through +play? Are there restrictions on what +play credits can be used on?",
      description: [
        "You can view a complete list of available subscriptions on our +play content provider page.",
        "Services currently not eligible for use with the +play Monthly Credit are Disney Bundle, Disney+, Apple One, Apple Music, Apple Arcade, Discovery+, Google Play Pass, Walmart+, Xbox All Access, YouTube TV."
      ]
    },
    {
      title: "What happens to my subscriptions using +play credits if I unenroll in +play Monthly Credit?",
      description: "Your existing subscriptions through +play will continue at the then current in-market rate and will be charged on the payment method you have on file with +play."
    },
    {
      title: "Where can I see my current +play credit balance?",
      description: "Your current +play credit balance can be found in the BIlling section of your +play account."
    },
    {
      title: "Who can I contact if I have questions about +play Monthly Credit?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for +play Monthly Credit on your Verizon bill, or redeeming the perk.",
        "For help with a service you pay for through +play, contact the content provider. They can resolve service-related issues.",
        "For help with a +play purchase/billing issue, open Chat via +play in the bottom right of your +play screen.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/entertainment-play-perk-faqs</span>."
      ]
    }
  ]
};

const travelPassDetails = {
  include: [
    {
      description: "Three TravelPass Days (each a 24-hour session) are included each month."
    }
  ],
  banner: "",
  important:
    {
      type: "list",
      text: [
        "Unlimited talk, text and data in 210+ countries and destinations.",
        "After the first 2 GB high speed data, you get unlimited 3G data to use for the remainder of the TravelPass session.",
        "Accrue up to 36 TravelPass Days with this perk.",
        "TravelPass Days expire 12 months after receiving them with this perk.",
        "Can only be used on the line enrolled in 3 TravelPass Days. Non-transferable."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “3 TravelPass Days” plan perk.",
    "One offer per line per billing cycle.",
    "Billing begins upon enrollment. Enrollment and access begins upon activation of the line.",
    "3 TravelPass Days provides 3 TravelPass® days per month enrolled up to an accrual of 36 TravelPass® days. TravelPass® days expire 12 months after the date of accrual.",
    "3 Travel Pass Days may be used in conjunction with individually purchased TravelPass® days. Individually purchased TravelPass® days are not subject to 3 TravelPass Days accrual limitations.",
    "TravelPass® days associated with 3 Travel Pass Days may only be used on the line that is enrolled in 3 TravelPass Days and are non-transferable.",
    "A 5G or 4G World device with HD voice is required to use TravelPass® days. International data will be reduced to 3G speeds after using 2 GB/day.",
    "You can cancel your enrollment in 3 TravelPass Days at any time through My Verizon Online or the My Verizon App. After cancellation, you will still have access to your accrued TravelPass® days. You will no longer receive additional TravelPass® days thereafter. Offer may end without notice.",
    "Use of 3 TravelPass Days is subject to International Service Terms & Conditions & Important Plan Information.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/travelpass-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is TravelPass?",
      description: [
        "TravelPass lets you use your domestic talk, text and data* in 210+ countries and destinations outside the US. You can call within the country you're visiting and you can call back to the US.",
        "* High speed data applies for the first 2 GB/day with 3G reduced speeds thereafter. If more than 50% of your talk, text or data usage in a 60-day period is in Canada or Mexico, use of those services in those countries may be removed or limited."
      ]
    },
    {
      title: "How does TravelPass work and when will my 3 TravelPass Days be used?",
      description: [
        "You're only charged for the days that you use your device in a TravelPass country. A 24-hour TravelPass session starts automatically when you make or receive a phone call, send a text or use data.",
        "Your device must have cellular data or data roaming turned on to use TravelPass."
      ]
    },
    {
      title: "Will I use up more than 1 of my TravelPass days if I travel to multiple countries on the same day?",
      description: "If you travel to 2 countries within a single 24-hour session or have a layover in a different country on your way to your final destination, you're still only charged for 1 session."
    },
    {
      title: "Do TravelPass days expire?",
      description: "3 TravelPass Days provides 3 TravelPass days per month enrolled, up to an accrual of 36 TravelPass days. TravelPass days obtained from 3TravelPass Days expire 12 months after the date of accrual."
    },
    {
      title: "I have TravelPass days on lines on my account already. What do I need to do?",
      description: "Any lines that already have TravelPass days on that line keep their TravelPass days until they expire. This applies to TravelPass days earned from 5G Do More and 5G Get More Unlimited phone plans, Verizon Visa Card and Verizon Up Rewards."
    },
    {
      title: "Who can I contact if I have questions about 3 TravelPass Days?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for3 TravelPass Days on your Verizon bill, or redeeming the perk.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/travelpass-perk-faqs</span>."
      ]
    }
  ]
};

const cloudStorageDetails = {
  include: [
    {
      title: "2 TB Cloud Storage",
      img: "cloudstorage-part.jpeg",
      description: [
        "2 TB Verizon Cloud gives you space for your digital world, so you can easily backup and access your photos, videos and documents securely and privately. Keep your content handy on your mobile devices and access it when you need it from your smartphone/tablet and computer.",
        "You can invite up to 4 members to join and share in the 2 TB storage space. Each member creates their own Cloud profile for their content at no additional charge."
      ]
    }
  ],
  banner: "",
  important:
    {
      type: "list",
      text: [
        "Utilize automatic backup and restore for your files in case your phone or PC is ever damaged, lost or stolen.",
        "Download Verizon Cloud mobile app and desktop app on unlimited eligible devices: iOS, Android, Mac, and Windows.",
        "Share with up to 4 other people.",
        "Individually purchased Verizon Cloud 600 GB and 2 TB storage will be canceled upon enrolling in 2 TB Cloud Storage.",
        "If enrolled in individually purchased Unlimited Verizon Cloud Storage, 2 TB Cloud storage will not be available for purchase.",
        "If moving to a non-qualifying plan, you will lose your perk discount and will be billed $14.99/mo for Verizon Cloud 2 TB until canceled.",
        "If canceling 2 TB Cloud Storage, your data will immediately become unavailable for use. Any content wishing to be saved should be downloaded prior to canceling the service."
      ]
    },
  terms: [
    "If you are (1) a legal resident of the 50 United States and D.C. and (2) 18 years of age or older, on a Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plan, you are eligible to purchase the $10 “2 TB Cloud Storage” plan perk.",
    "One offer per line.",
    "Billing begins upon enrollment. Access and enrollment begins upon activation of the line.",
    "2 TB Cloud Storage through Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plans may not be stacked with individually purchased Verizon Cloud storage. Individually purchased Verizon Cloud 600 GB and 2 TB storage will be canceled upon enrolling in 2 TB Cloud Storage through Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plans. If enrolled in individually purchased Verizon Cloud Unlimited storage, 2 TB Cloud storage will not be available for purchase. 2 TB Cloud Storage will need to be canceled prior to enrolling in the individually available Verizon Cloud Unlimited.",
    "2 TB Cloud Storage can be shared by up to 5 users for backup of all compatible mobile devices and computers. Verizon Cloud app download required for each user. If you would like to purchase a different tier of Verizon Cloud you may do so via My Verizon Online, My Verizon App, or the Verizon Cloud app.",
    "You can cancel 2 TB Cloud Storage through Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plans at any time through My Verizon Online or the My Verizon App. After cancellation your data will immediately become unavailable for use. You are responsible for downloading any Verizon Cloud content that you wish to save prior to canceling the service. Offer may end without notice.",
    "If you switch from Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plans to a non-qualifying plan, you will lose your perk discount and will be billed $14.99/mo for Verizon Cloud 2 TB until canceled. Cancel anytime through My Verizon Online or the My Verizon App.",
    "Use of 2 TB Cloud Storage is subject to Verizon Cloud Terms & Conditions.",
    "Terms & conditions: <span class='underlined'>https://www.verizon.com/support/cloud-storage-perk-legal</span>."
  ],
  faq: [
    {
      title: "What is Verizon Cloud?",
      description: [
        "Verizon Cloud is a service that wirelessly stores your files in the cloud. Files are encrypted during transfer and stored securely. You can use them anytime on all your devices: phones, computers and tablets.",
        "Verizon Cloud can automatically back up your files, making it easy to upgrade devices and restore files after damage, theft or loss."
      ]
    },
    {
      title: "I have Verizon Cloud 600 GB already. What do I need to do?",
      description: "Your individually purchased Verizon Cloud 600 GB will be canceled upon enrolling in 2 TB Cloud Storage."
    },
    {
      title: "I have Verizon Cloud 2 TB already. What do I need to do?",
      description: "Your individually purchased Verizon Cloud 2 TB will be canceled upon enrolling in 2 TB Cloud Storage."
    },
    {
      title: "I have Verizon Cloud Unlimited already. What do I need to do?",
      description: "2 TB Cloud Storage is not available for purchase if already enrolled in individually purchased Verizon Cloud Unlimited. If you would like to enroll in 2 TB Cloud Storage, you will first need to cancel your subscription to Verizon Cloud Unlimited."
    },
    {
      title: "What happens to my 2 TB Cloud Storage if I move to a non-qualifying plan?",
      description: "If you switch from Welcome Unlimited, Unlimited Welcome or Unlimited Plus phone plans to a non-qualifying plan, you will lose your perk discount and will be billed $14.99/mo for Verizon Cloud 2 TB until canceled. Cancel anytime through My Verizon Online or the My Verizon App."
    },
    {
      title: "What happens if I cancel 2 TB Cloud Storage?",
      description: "If you cancel 2 TB Cloud Storage your data will immediately become unavailable for use. You are responsible for downloading any Verizon Cloud content that you wish to save prior to canceling the service."
    },
    {
      title: "Who can I contact if I have questions about 2 TB Cloud Storage?",
      description: [
        "You can contact Verizon customer support (<span class='underlined'>https://www.verizon.com/support/contact-us</span>) for questions about perk eligibility, charges for 2 TB Cloud Storage on your Verizon bill, or redeeming the perk.",
        "All FAQs: <span class='underlined'>https://www.verizon.com/support/cloud-storage-perk-faqs</span>."
      ]
    }
  ]
};

const disneyPlus = {
  id: 1,
  name: 'disney',
  title: 'Disney Bundle',
  cardTitle: 'Disney Bundle',
  price: PERK_PRICE,
  save: 4.99,
  img: 'disney.jpg',
  icon: 'disney-white',
  theme: 'dark',
  details: disneyPlusDetails,
  statisticsName: 'Disney',
  doubleAddConflictName: 'disney'
};
const mobileHotspot100 = {
  id: 2,
  name: 'hotspot',
  title: '100 GB Mobile Hotspot',
  cardTitle: '100 GB Mobile<br/>Hotspot',
  price: PERK_PRICE,
  save: 35.00,
  img: 'hotspot.jpg',
  imgHor: 'hotspot-hor.jpg',
  imgVert: 'hotspot-vert.jpg',
  theme: 'dark',
  details: mobileHotspot100Details,
  statisticsName: 'Hotspot'
};
const appleOne = {
  id: 3,
  name: 'apple-one',
  title: 'Apple One',
  cardTitle: 'Apple One',
  variants: [
    {
      id: 1,
      title: "Individual",
      price: 10.00,
      save: 6.95,
      conflictName: 'individual',
      default: true,
      statisticsName: 'AppleOneIndividual',
      doubleAddConflictWithVariantId: 2,
      doubleAddItemName: 'individual'
    },
    {
      id: 2,
      title: "Family",
      price: 20.00,
      save: 2.95,
      note: "Share with up to 5 other people.",
      conflictName: 'family',
      statisticsName: 'AppleOneFamily',
      doubleAddConflictName: 'family',
      doubleAddConflictWithVariantId: 1
    }
  ],
  img: 'apple-one.jpg',
  icon: 'apple-one',
  details: appleOneDetails,
  conflictName: 'one',
  statisticsName: 'AppleOne',
  doubleAddConflictName: 'appleOne'
};
const walmart = {
  id: 4,
  name: 'walmart',
  title: 'Walmart+ Membership',
  cardTitle: 'Walmart+<br/>Membership',
  price: PERK_PRICE,
  save: 2.95,
  img: 'walmart.jpg',
  icon: 'walmart',
  details: walmartDetails,
  statisticsName: 'Walmart',
  doubleAddConflictName: 'walmart'
};

const appleMusic = {
  id: 5,
  name: 'apple-music',
  title: 'Apple Music Family',
  cardTitle: 'Apple Music<br/>Family',
  price: PERK_PRICE,
  save: 6.99,
  img: 'apple-music.jpg',
  icon: 'apple-music',
  details: appleMusicDetails,
  cantStand: [ appleOne.conflictName ],
  conflictName: 'music',
  statisticsName: 'AppleMusic',
  doubleAddConflictName: 'appleMusic'
};
appleOne.cantStand = [ appleMusic.conflictName ];


const smartwatch = {
  id: 6,
  name: 'smartwatch',
  title: 'Smartwatch Data & Safety',
  cardTitle: 'Smartwatch<br/>Data & Safety',
  price: PERK_PRICE,
  save: 10.00,
  img: 'smartwatch.jpg',
  theme: 'dark',
  details: smartwatchDetails,
  statisticsName: 'SmartWatch'
};
const plusPlay = {
  id: 7,
  name: 'plus-play',
  title: '+play Monthly Credit',
  cardTitle: '+play Monthly<br/>Credit',
  price: PERK_PRICE,
  save: 5.00,
  img: 'plus-play.jpg',
  theme: 'dark',
  details: plusPlayDetails,
  statisticsName: 'PlusPlay'
};
const travelPass = {
  id: 8,
  name: 'travel-pass',
  title: '3 TravelPass Days',
  cardTitle: '3 TravelPass<br/>Days',
  price: PERK_PRICE,
  save: 20.00,
  img: 'travel-pass.jpg',
  theme: 'dark',
  details: travelPassDetails,
  statisticsName: 'TravelPass'
};
const cloudStorage = {
  id: 9,
  name: 'cloud-storage',
  title: '2 TB Cloud Storage',
  cardTitle: '2 TB Cloud<br/>Storage',
  price: PERK_PRICE,
  save: 4.99,
  img: 'cloud-storage.jpg',
  imgHor: 'cloud-storage-hor.jpg',
  theme: 'dark',
  details: cloudStorageDetails,
  statisticsName: 'CloudStorage',
  doubleAddConflictName: 'cloudStorage'
};


const getDoubleAddConflictMessages = (callback, kind, perk, variant, plan = null, customizing = false) => {
  const handleSelectPerksOnClick = (perkDoubleAddConflictName, variantDoubleAddConflictName) => {
    callback(perkDoubleAddConflictName, variantDoubleAddConflictName);
  };

  const handlePopularPlansOnClick = (buttonType, perkDoubleAddConflictName, variantDoubleAddConflictName) => {
    if (buttonType === 'customize') {
      increaseVariableForCurrentLine(
        [
          "popular",
          plan?.statisticsName,
          capitalize(perkDoubleAddConflictName),
          "DoubleSelected",
          "CustomizeInForm"
        ].join('')
      );
      callback('customize', perkDoubleAddConflictName, variantDoubleAddConflictName);
    } else if (buttonType === 'keep') {
      increaseVariableForCurrentLine(
        [
          "popular",
          plan?.statisticsName,
          capitalize(perkDoubleAddConflictName),
          "DoubleSelected",
          "KeepInForm"
        ].join('')
      );
      callback('keep', perkDoubleAddConflictName, variantDoubleAddConflictName);
    }
  };

  const baseTitle = "You’ve already added this perk to another line.";
  const titleDescriptions = {
    disney: { title: baseTitle, descriptions: [ "The Disney Bundle can be shared on up to 4 devices at the same time." ] },
    appleOneFamily: { title: baseTitle, descriptions: [ "The Apple One - Family Subscription can be shared with up to 5 other people." ] },
    walmart: { title: baseTitle, descriptions: [ "Walmart+ membership can be shared within the same household." ] },
    appleMusic: { title: baseTitle, descriptions: [ "The Apple Music family subscription can be shared with up to 5 other people." ] },
    cloudStorage: { title: baseTitle, descriptions: [ "2 TB Cloud Storage can be shared with up to 5 people, each with a unique profile. Add users under settings in the Verizon Cloud app." ] }
  };

  const inSelectedPerks = {
    selectPerks: {
      disney: {
        ...titleDescriptions.disney,
        buttons: [
          {
            title: "Got it",
            kind: 'button',
            handleClick: () => {
              handleSelectPerksOnClick('disney');
            }
          }
        ]
      },
      appleOne: {
        individual: {
          ...titleDescriptions.appleOneFamily,
          buttons: [
            {
              title: "Got it",
              kind: 'button',
              handleClick: () => {
                handleSelectPerksOnClick('appleOne', 'individual');
              }
            }
          ]
        },
        family: {
          ...titleDescriptions.appleOneFamily,
          buttons: [
            {
              title: "Got it",
              kind: 'button',
              handleClick: () => {
                handleSelectPerksOnClick('appleOne', 'family');
              }
            }
          ]
        }
      },
      walmart: {
        ...titleDescriptions.walmart,
        buttons: [
          {
            title: "Got it",
            kind: 'button',
            handleClick: () => {
              handleSelectPerksOnClick('walmart');
            }
          }
        ]
      },
      appleMusic: {
        ...titleDescriptions.appleMusic,
        buttons: [
          {
            title: "Got it",
            kind: 'button',
            handleClick: () => {
              handleSelectPerksOnClick('appleMusic');
            }
          }
        ]
      },
      cloudStorage: {
        ...titleDescriptions.cloudStorage,
        buttons: [
          {
            title: "Got it",
            kind: 'button',
            handleClick: () => {
              handleSelectPerksOnClick('cloudStorage');
            }
          }
        ]
      }
    }
  };

  const inPopularPlans = {
    popularPlans: {
      disney: {
        ...titleDescriptions.disney,
        buttons: [
          {
            title: "Customize plan",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('customize', 'disney');
            }
          },
          {
            title: "Keep perk and continue",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('keep', 'disney');
            }
          }
        ]
      },
      appleOne: {
        individual: {
          ...titleDescriptions.appleOneFamily,
          buttons: [
            {
              title: "Customize plan",
              kind: 'button',
              handleClick: () => {
                handlePopularPlansOnClick('customize', 'appleOne', 'individual');
              }
            },
            {
              title: "Keep perk and continue",
              kind: 'button',
              handleClick: () => {
                handlePopularPlansOnClick('keep', 'appleOne', 'individual');
              }
            }
          ]
        },
        family: {
          ...titleDescriptions.appleOneFamily,
          buttons: [
            {
              title: "Customize plan",
              kind: 'button',
              handleClick: () => {
                handlePopularPlansOnClick('customize', 'appleOne', 'family');
              }
            },
            {
              title: "Keep perk and continue",
              kind: 'button',
              handleClick: () => {
                handlePopularPlansOnClick('keep', 'appleOne', 'family');
              }
            }
          ]
        }
      },
      walmart: {
        ...titleDescriptions.walmart,
        buttons: [
          {
            title: "Customize plan",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('customize', 'walmart');
            }
          },
          {
            title: "Keep perk and continue",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('keep', 'walmart');
            }
          }
        ]
      },
      appleMusic: {
        ...titleDescriptions.appleMusic,
        buttons: [
          {
            title: "Customize plan",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('customize', 'appleMusic');
            }
          },
          {
            title: "Keep perk and continue",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('keep', 'appleMusic');
            }
          }
        ]
      },
      cloudStorage: {
        ...titleDescriptions.cloudStorage,
        buttons: [
          {
            title: "Customize plan",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('customize', 'cloudStorage');
            }
          },
          {
            title: "Keep perk and continue",
            kind: 'button',
            handleClick: () => {
              handlePopularPlansOnClick('keep', 'cloudStorage');
            }
          }
        ]
      }
    }
  };
  const allHash = { ...inSelectedPerks, ...inPopularPlans };
  if (!perk?.doubleAddConflictName) {
    return null;
  }

  if (kind === 'selectPerks') {
    increaseVariableForCurrentLine(
      [
        "perks",
        capitalize(perk.doubleAddConflictName),
        (variant ? capitalize(variant.doubleAddItemName || variant.doubleAddConflictName) : ''),
        "DoubleSelected"
      ].join('')
    );
  } else if (kind === 'popularPlans') {
    increaseVariableForCurrentLine(
      [
        "popular",
        plan?.statisticsName,
        capitalize(perk.doubleAddConflictName),
        "DoubleSelected",
        (customizing ? "CustomizePlanClick" : "SelectPlanClick")
      ].join('')
    );
  }

  let result = allHash[kind][perk.doubleAddConflictName];
  if (result && variant) {
    result = result[variant.doubleAddItemName || variant.doubleAddConflictName];
  }
  return result;
};

const getConflictMessages = (callback, perk, conflictedPerk, variant) => {
  increaseVariableForCurrentLine(`perks${(variant || conflictedPerk).statisticsName}Selected${perk.statisticsName}TryFormError`);

  const forPerksList = {
    music: {
      individual: {
        title: "Choose either Apple One or Apple Music Family.",
        buttons: [
          {
            title: "Apple One - Family Subscription",
            kind: 'button',
            handleClick: () => {
              increaseVariableForCurrentLine("perksAppleOneIndividualSelectedAppleMusicTryAppleOneFamilyChosen");
              callback('one', 'family');
            }
          },
          {
            title: "Apple One - Individual Subscription",
            kind: 'button',
            handleClick: () => {
              increaseVariableForCurrentLine("perksAppleOneIndividualSelectedAppleMusicTryAppleOneIndividualChosen");
              callback();
            }
          },
          {
            title: "Switch to Apple Music family",
            kind: 'link',
            handleClick: () => {
              increaseVariableForCurrentLine("perksAppleOneIndividualSelectedAppleMusicTryAppleMusicChosen");
              callback('music');
            }
          }
        ],
        descriptions: [
          "We're sorry but these two perks can't be added on the same line.",
          "The good news Apple Music is included with Apple One. Add the Apple One - Family Subscription for $20/mo, and share it with up to five other people. With this, you'll also get Apple TV+, Apple Arcade and 200 GB of iCloud+.",
          "You can get Apple One - Family Subscription and share it with up to five other people or stick with Apple One - Individual Subscription."
        ]
      },
      family: {
        title: "Choose either Apple One or Apple Music Family.",
        buttons: [
          {
            title: "Got it",
            kind: 'button',
            handleClick: () => {
              callback();
            }
          }
        ],
        descriptions: [
          "Apple Music is included with your Apple One perk.",
          "You'll get access to Apple Music through your family subscription to Apple One, shareable with up to 5 other people."
        ]
      }
    },
    one: {
      title: "Choose either Apple One or Apple Music Family.",
      buttons: [
        {
          title: "Apple Music Family",
          kind: 'button',
          handleClick: () => {
            increaseVariableForCurrentLine("perksAppleMusicSelectedAppleOneTryAppleMusicChosen");
            callback();
          }
        },
        {
          title: "Apple One - Family Subscription",
          kind: 'button',
          handleClick: () => {
            increaseVariableForCurrentLine("perksAppleMusicSelectedAppleOneTryAppleOneFamilyChosen");
            callback('one', 'family');
          }
        }
      ],
      descriptions: [
        "We're sorry but these two perks can't be added on the same line.",
        "Apple One includes Apple Music, plus Apple TV+, Apple Arcade and 200 GB of iCloud+. For $20/mo, add Apple One - Family Subscription, and share it with up to 5 other people."
      ]
    }
  };

  const forPerksDetails = {
    music: forPerksList.music,
    one: {
      title: "Choose either Apple One or Apple Music Family.",
      buttons: [
        {
          title: "Got it",
          kind: 'button',
          handleClick: () => {
            callback();
          }
        }
      ],
      descriptions: [
        "We're sorry but these two perks can't be added on the same line.",
        "The good news is you'll get Apple Music with Apple One. Add Apple One - Family Subscription for $20/mo, and share it with up to 5 other people. You'll also get Apple TV+, Apple Arcade and 200 GB of iCloud+.",
        "Select Apple One - Family Subscription for everything Apple or stick with Apple Music Family."
      ]
    }
  };

  return { perks: forPerksList, perkDetails: forPerksDetails };
};


const allPerksFlatten = [
  disneyPlus, mobileHotspot100, appleOne, walmart,
  appleMusic, smartwatch, plusPlay, travelPass, cloudStorage

];


const entertainmentPlan = {
  id: 1,
  title: "If you love movies, series &&nbsp;more",
  name: "Built for entertainment",
  core: premiumCore,
  perks: [
    disneyPlus,
    mobileHotspot100
  ],
  promo: {
    is: true,
    title: "Best Value: Save up to $800 on your new device"
  },
  statisticsName: 'Movie'
};

const applePlan = {
  id: 2,
  title: "If you love your Apple favorites, all together",
  name: 'Built for Apple',
  core: premiumCore,
  perks: [
    appleOne
  ],
  promo: {
    is: true,
    title: "Best Value: Save up to $800 on your new device"
  },
  statisticsName: 'Apple',
  specialKlassTitle: '-special-desktop-max-width'
};

const shoppingPlan = {
  id: 3,
  title: "If you love easy shopping, savings, &&nbsp;more",
  name: 'Built for shopping',
  core: standardCore,
  perks: [
    walmart
  ],
  promo: {
    is: false,
    title: "Not eligible for new phone offers"
  },
  statisticsName: 'Shopping'
};

const premium = {
  id: 4,
  name: premiumCore.title,
  core: premiumCore,
  perks: [],
  promo: {
    is: true,
    title: "Best Value: Save up to $800 on your new device"
  },
  defaultForBuildYourOwn: true,
  statisticsName: 'UnlimitedPlus'
};

const standard = {
  id: 5,
  name: standardCore.title,
  core: standardCore,
  perks: [],
  promo: {
    is: false,
    title: "Not eligible for new phone offers"
  },
  statisticsName: 'UnlimitedWelcome'
};

const compareList = [
  {
    id: "data",
    title: "Data"
  },  {
    id: "mobileHotspot",
    title: "Mobile Hotspot"
  },
  {
    id: "homeInternet",
    title: "Home Internet"
  },
  {
    id: "deviceOffers",
    title: "Device Offers"
  },
  {
    id: "connectedDevice",
    title: "Connected Device Data Plan"
  },
  {
    id: "priceGuarantee",
    title: "Price Guarantee"
  }
];

const concept_plans_prebuild = [
  entertainmentPlan,
  applePlan,
  shoppingPlan
];
const concept_plans_construct = [
  premium,
  standard
];


const CONCEPT_BAU = {
  allPerksFlatten,
  PERK_PRICE,
  plans_prebuild: concept_plans_prebuild.map((el) => ({
    ...el,
    priceTitle: (variantsHash = {}) => (
      calculatePerksSum(conceptBauPricing[el.id - 1][getLinesCount() - 1], el.perks, variantsHash)
    ),
    saveTitle: (variantsHash = {}) => (
      calculatePerksSavings(el.perks, variantsHash)
    ),
    get price() {
      return conceptBauPricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  plans_construct: concept_plans_construct.map((el) => ({
    ...el,
    get priceTitle() {
      return conceptBauPricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return conceptBauPricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  allPlansIncludeConcept,
  getForkList,
  compareList,
  getConflictMessages,
  getDoubleAddConflictMessages
};

export {
  CONCEPT_BAU,
  concept_plans_prebuild, concept_plans_construct,
  allPlansIncludeConcept,
  allPerksFlatten,
  PERK_PRICE
};
