import React, { useState } from 'react';
import Header from './components/app-edit/header';
import PlateOptions from './components/app-edit/plate-options';
import Projects from './components/app-edit/projects';
import EditForm from './components/app-edit/edit-form';
import { IndexData } from './components/app-edit/contexts';
import { getMlProjects } from './requests';

const App = ({
  id, title, product_category, projects, countries, express_text,
  product_category_id, update_path, product_categories,
  platform_id, projects_path, project_is_shared,
  buzzback_admin, buzzback_user, demo_user
}) => {
  const [ stateTitle, setTitle ] = useState(title);
  const [ stateProjectNumber, setProjectNumber ] = useState(platform_id);
  const [ productCategory, setProductCategory ] = useState(product_category);
  const [ mode, setMode ] = useState('cards');
  const [ showEdit, setShowEdit ] = useState(false);
  const [ stateProjects, setProjects ] = useState(projects);

  const onEditClose = () => {
    setShowEdit(false);
  };
  const onOpenEdit = () => {
    setShowEdit(true);
  };

  const loadProjects = () => {
    getMlProjects(id, (data) => {
      setTitle(data.title);
      setProjectNumber(data.platform_id);
      setProductCategory(data.product_category);
      setProjects(data.projects);
    });
  };

  const contextData = {
    admin: buzzback_admin,
    buzzbackUser: buzzback_user,
    demoUser: demo_user,
    projectIsShared: project_is_shared,
    loadProjects
  };

  return (
    <IndexData.Provider value={ contextData }>
      <div className="content -grey-background -ml-projects">
        <Header
          title={ stateTitle }
          projectNumber={ stateProjectNumber }
          expressText={ express_text }
          productCategory={ productCategory }
          backUrl={ projects_path }
          onOpenEdit={ onOpenEdit }
        />
        <PlateOptions mode={ mode } setMode={ setMode } />
        <Projects projects={ stateProjects } mode={ mode } />
      </div>
      {
        showEdit &&
        <EditForm
          title={ stateTitle }
          projectNumber={ stateProjectNumber }
          categoryId={ product_category_id }
          updatePath={ update_path }
          categories={ product_categories }
          projects={ stateProjects }
          countries={ countries }
          onClose={ onEditClose }
        />
      }
    </IndexData.Provider>
  );
};

export default App;
