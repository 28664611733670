import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import {
  sectionTitleFromData, itemsFromData, isAvailable, editedTitle, defaultItemName
} from './helper';
import NSizeIncreaseText from './nSizeIncreaseText';

const SurveyCompleteTarget = ({
  completeTargetData, titleClassName, className,
  completeTarget, setCompleteTarget,
  totalN, conceptN, textItemName = defaultItemName
}) => {
  const [ available, setAvailable ] = useState(
    () => isAvailable(completeTargetData, totalN, conceptN)
  );
  const [ title, setTitle ] = useState(() => sectionTitleFromData(completeTargetData));
  const [ optionsList, setOptionsList ] = useState(() => itemsFromData(completeTargetData));
  useEffect(() => {
    setTitle(sectionTitleFromData(completeTargetData));
    setOptionsList(itemsFromData(completeTargetData));
    setAvailable(isAvailable(completeTargetData, totalN, conceptN));
  }, [ completeTargetData ]);

  const handleChangeCompleteTarget = (event) => {
    setCompleteTarget(event.target.value);
  };
  const uiTitle = (rawTitle) => (editedTitle(rawTitle, textItemName));
  const showNote = (!!totalN && !!conceptN);
  const nSizeIncreaseTextComponent = (
    <NSizeIncreaseText
      className={ available ? null : 'form_section -separate-message' }
      nSize={ totalN }
      completeTarget={ completeTarget }
      completeTargetData={ completeTargetData }
    />
  );
  return (
    <>
      {
        available &&
        <div className={ classnames("form_section -target-tab", className) }>
          <div className="form_section-tabs">
            <div className={ classnames("form_section-text -label", titleClassName) }>{ title }</div>
            <div className="radio-tabs -borderer -gray-bg">
              {
                map(optionsList, (title, name) => (
                  <label className="radio-tabs_item" htmlFor={ `tab_input_${name}` } key={ name }>
                    <input
                      id={ `tab_input_${name}` }
                      type="radio"
                      value={ name }
                      onChange={ handleChangeCompleteTarget }
                      checked={ completeTarget === name }
                    />
                    <span className="radio-tabs_label">{ uiTitle(title) }</span>
                  </label>
                ))
              }
            </div>
            {
              showNote &&
              <>
                <div className="selected-number-message -width-500">
                  Total N size = { totalN }. Each { textItemName } N size = { conceptN }.
                  <br />
                </div>
                { nSizeIncreaseTextComponent }
              </>
            }
          </div>
        </div>
      }
      { !available && nSizeIncreaseTextComponent }
    </>
  );
};

export default SurveyCompleteTarget;
