import remoteFiles from '../../../common/remote_files';
const {
  AthleticJpg, MetCon3Jpg, FirestarterJpg, MetCon3FinishersJpg,
  BestAbsEverJpg, StackedLowerBodyJpg, DanceFinisherJpg,
  IsometricJpg, LegsAndCoreJpg, GerardPreviewJpg
} = remoteFiles.equinox;

const videos = {
  forYou: [
    {
      imgUrl: AthleticJpg,
      title: "Athletic Conditioning",
      duration: "45:00"
    },
    {
      imgUrl: MetCon3Jpg,
      title: "MetCon3: Strong and Steady",
      duration: "30:00"
    },
    {
      imgUrl: FirestarterJpg,
      title: "Firestarter",
      duration: "30:00"
    }
  ],
  equinox: [
    {
      imgUrl: MetCon3FinishersJpg,
      title: "MetCon3: Core Finishers",
      description: "Intermediate with Gerard T.",
      category: "HIIT",
      duration: "45:00"
    },
    {
      imgUrl: BestAbsEverJpg,
      title: "Best Abs Ever: Lift and Glide",
      description: "Beginner with Or Artizi",
      category: "Sculpt",
      duration: "30:00"
    },
    {
      imgUrl: StackedLowerBodyJpg,
      title: "Stacked! Lower Body Basics",
      description: "Beginner with Or Artizi",
      category: "Sculpt",
      duration: "20:00"
    },
    {
      imgUrl: AthleticJpg,
      title: "Tabata: Bodyweight x Mobility",
      description: "Intermediate with Colleen C.",
      category: "HIIT",
      duration: "45:00"
    },
    {
      imgUrl: MetCon3Jpg,
      title: "Athletic Conditioning: 3 Minu...",
      description: "Beginner with Harrison M.",
      category: "HIIT",
      duration: "30:00"
    },
    {
      imgUrl: DanceFinisherJpg,
      title: "Cardio Sculpt: Dance Finisher",
      description: "Beginner with Khaleah L.",
      category: "Sculpt",
      duration: "45:00"
    },
    {
      imgUrl: IsometricJpg,
      title: "Cardio Sculpt: Isometric Cha...",
      description: "Intermediate with Daigi T.",
      category: "Sculpt",
      duration: "45:00"
    },
    {
      imgUrl: LegsAndCoreJpg,
      title: "Cardio Sculpt: Legs and Core",
      description: "Intermediate with Daigi T.",
      category: "Scuplt",
      duration: "20:00"
    },
    {
      imgUrl: GerardPreviewJpg,
      title: "The Pump is Real",
      description: "Beginner with Harrison M.",
      category: "HIIT",
      duration: "25:00"
    }
  ]
};

export default videos;
