import React, { useContext, useState, useEffect } from 'react';
import { LaunchContext, StripeContext, CreditsContext } from '../../contexts';
import StripeForm from './stripeComponents/stripeForm';
import StripeLaunchButton from './stripeComponents/stripeLaunchButton';
import CreditsForm from './creditsComponents/creditsForm';
import CreditsLaunchButton from './creditsComponents/creditsLaunchButton';
import PaymentTypesTab from './paymentTypesTab';

const RealPaymentForm = () => {
  const {
    payedProjects, setLoading, quotaService, creditsInfo, projectPrice, stripeKey
  } = useContext(LaunchContext);
  const { credits, rate } = creditsInfo;
  const [ paymentType, setPaymentType ] = useState(
    () => (credits ? 'by_credits' : 'by_credit_card')
  );
  const [ paymentTypes ] = useState(
    () => ({ by_credits: 'By Credits', by_credit_card: 'By Credit Card' })
  );
  const [ stripeInstance ] = useState(
    () => {
      try {
        return Stripe(stripeKey);
      } catch (e) {
        return null;
      }
    }
  );
  const [ stripeElementsError, setStripeElementsError ] = useState(null);
  const [ stripeTokenError, setStripeTokenError ] = useState(null);
  const [ cardNumberStripeObject, setCardNumberStripeObject ] = useState(null);
  const [ cardHolder, setCardHolder ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ city, setCity ] = useState('');
  const [ state, setState ] = useState('');
  const [ zipCode, setZipCode ] = useState('');

  useEffect(() => {
    if (payedProjects.length) {
      quotaService.checkStatus(
        () => {
          setLoading(true);
        },
        () => {
          setLoading(false);
        },
        true
      );
    }
  }, []);

  const priceInCredits = rate * projectPrice;
  const enoughCredits = (paymentType === 'by_credits' && credits >= priceInCredits);
  const byCredits = paymentType === 'by_credits';
  const byCard = paymentType === 'by_credit_card';

  const stripeContextValue = {
    stripeInstance, cardNumberStripeObject, setCardNumberStripeObject,
    address, setAddress, city, setCity, state, setState, zipCode, setZipCode,
    cardHolder, setCardHolder, paymentType,
    stripeElementsError, setStripeElementsError, stripeTokenError, setStripeTokenError
  };
  const creditsContextValue = { priceInCredits, enoughCredits, credits, paymentType };

  return (
    <CreditsContext.Provider value={ creditsContextValue }>
      <StripeContext.Provider value={ stripeContextValue }>
        {
          !!credits &&
          <PaymentTypesTab
            paymentTypes={ paymentTypes }
            paymentType={ paymentType }
            setPaymentType={ setPaymentType }
          />
        }
        { byCredits && <CreditsForm /> }
        {
          stripeInstance ? (
            <StripeForm hide={ !byCard } />
          ) : (
            byCard &&
            <div className="no-stripe-message -margin-top">
              Stripe is not available at the moment, please try to reload the page.
            </div>
          )
        }

        <footer className="form-footer -fixed">
          <div className="footer-inner">
            { byCredits && <CreditsLaunchButton /> }
            { byCard && <StripeLaunchButton forceDisable={ !stripeInstance } /> }
            <p>Remember you won’t be able to edit your project <br /> after you pay and launch.</p>
          </div>
        </footer>
      </StripeContext.Provider>
    </CreditsContext.Provider>
  );
};

export default RealPaymentForm;
