import React, { useState, useEffect, useContext } from 'react';
import { filter, map, find, includes } from 'lodash';
import { baseContext } from '../contexts';
import { loadSpotlights } from '../overviewSpotlights/api';
import {
  customerSpotlightNote, setUrlState, getUrlState, spotlightId,
  withPrefix, displayGroups, getGroupName
} from '../helpers';
import DisplayGroupSelector from "../common/display-group-selector";
import CustomerSpotlight from './customerSpotlight';

const Index = () => {
  const [ list, setList ] = useState([]);
  const [ inProgress, setInProgress ] = useState(true);
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ expandedId, setExpandedId ] = useState(null);
  const [ expandedObj, setExpandedObj ] = useState(null);

  const [ allData, setAllData ] = useState([]);
  const [ currentGroup, setCurrentGroup ] = useState(0);
  const [ groups, setGroups ] = useState(displayGroups);

  const clearSpotlightState = () => {
    setUrlState();
  };

  const onGroupChange = (newGroup) => {
    clearSpotlightState();
    setGroupData(newGroup, allData);
  };

  const setGroupData = (newGroup, list) => {
    setCurrentGroup(newGroup);
    const archived = newGroup === displayGroups[1];
    const currentData = filter(list, (el) => (el.archived === archived));
    setList(currentData);
  };

  const defineInitialGroup = (list) => {
    const foundActive = find(list, (item) => (item.selected));
    return displayGroups[foundActive?.archived ? 1 : 0];
  };

  const spotlightIdInUrl = () => {
    const urlState = getUrlState();
    return urlState[spotlightId] ? parseInt(urlState[spotlightId]) : null;
  };

  useEffect(() => {
    setInProgress(true);
    loadSpotlights(mediaKind, verizonType, (list, currentGroup, availableGroups) => {
      setGroups(includes(availableGroups, true) ? displayGroups : [ false ]);
      setAllData(list);

      const matchedId = spotlightIdInUrl();
      const record = matchedId ?
        find(list, (el) => (el.id === matchedId)) :
        null;
      if (record) {
        setGroupData(getGroupName(record.archived), list);
        setExpandedId(matchedId);
      } else {
        setGroupData(defineInitialGroup(list), list);
      }
      setInProgress(false);
    });
  }, [ mediaKind ]);

  useEffect(() => {
    if (expandedObj) {
      const matchedId = spotlightIdInUrl();

      if (expandedObj.current) {
        const box = expandedObj.current.getBoundingClientRect();
        document.querySelector('html').scrollTop = box.top;
        if (expandedObj.id !== matchedId) {
          setUrlState({ [spotlightId]: expandedObj.id });
        }
      } else if (expandedObj.action) {
        clearSpotlightState();
      }
    }
  }, [ expandedObj ]);

  const itemExpanded = (item) => (expandedId === item.id);

  return (
    <>
      { inProgress && <div className="iframe-loader" /> }
      {
        !inProgress &&
        <>
          <div className="section_top -grid">
            <div className="section_top-item">
              <h4 className="section_title">
                { withPrefix(verizonType, mediaKind, 'Customer Spotlight') }
              </h4>
            </div>
          </div>
          <DisplayGroupSelector
            withSection
            groups={ groups }
            currentGroup={ currentGroup }
            onChange={ onGroupChange }
          />
          { customerSpotlightNote }
          {
            !list.length &&
            <div className="spotlights-blank">
              There are no customers at the moment.
            </div>
          }
          {
            !!list.length &&
            map(list, (item) => (
              <CustomerSpotlight
                key={ `Spotlight${item.id}` }
                item={ item }
                expanded={ itemExpanded(item) }
                setExpandedObj={ setExpandedObj }
              />
            ))
          }
        </>
      }
    </>
  );
};

export default Index;
