import React, { useContext } from 'react';
import { values } from 'lodash';
import { onPasteDetected, prepareParams, sendToFraudCheck } from '../../../common/logic/fraud_check';
import { GeneralContext } from '../../contexts';
import { fraudContext } from '../../../common/contexts';
import { plainText } from "../../../../../common/string_utils";

const resultOpenEndsTextKey = 'openEndTexts';
const resultOpenEndsActionKey = 'openEndCheckAction';

const checkOpenEnds = (result, nextStep, setFraudProcessing) => {
  const texts = values(result[resultOpenEndsTextKey] || {});
  if (texts.length) {
    result[resultOpenEndsActionKey](
      texts, () => {
        delete result[resultOpenEndsTextKey];
        delete result[resultOpenEndsActionKey];
        nextStep();
      },
      setFraudProcessing
    );
  } else {
    nextStep();
  }
};

const OpenEnd = ({ question, updateText }) => {
  const { respondentId, projectId, antiFraud } = useContext(fraudContext);
  const { result } = useContext(GeneralContext);
  const handleUpdateAnswer = (event) => {
    const text = event.target.value;
    if (!result[resultOpenEndsTextKey]) {
      result[resultOpenEndsTextKey] = {};
    }
    result[resultOpenEndsTextKey][question.name] = text;
    result[resultOpenEndsActionKey] = (texts, nextStep, setFraudProcessing) => {
      const fraudParams = prepareParams(
        { antiFraud, projectId, respondentId },
        texts
      );
      sendToFraudCheck(fraudParams, nextStep, setFraudProcessing);
    };

    updateText(text);
  };

  return (
    <textarea
      className="js-text form-field -open-end"
      onChange={ handleUpdateAnswer }
      onPaste={ () => { onPasteDetected(result); } }
      aria-label={ plainText(question.text) }
    />
  );
};

export { OpenEnd, checkOpenEnds };
