import React from "react";
import WorkoutCarousel from "./WorkoutCarousel";

const WorkoutsCompilation = ({ compilations }) => (
  <div className="pt-5">
    {compilations.map((compilation, i) => (
      <div key={i} className="mb-5">
        <h3 className="mx-4">{compilation.title}</h3>
        <WorkoutCarousel workouts={compilation.workouts} className="px-4" />
      </div>
    ))}
  </div>
);

export default WorkoutsCompilation;
