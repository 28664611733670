import React from 'react';
import BaseView from './baseView';
import SpecialView from './specialView';

const ActiveReport = ({ overview }) => {
  const specialView = overview.data?.special_view;
  return (
    <>
      {
        !specialView &&
        <BaseView overview={ overview } />
      }
      {
        specialView &&
        <SpecialView overview={ overview } />
      }
    </>

  );
};

export default ActiveReport;
