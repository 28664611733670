import React from 'react';
import GreenRed from '../../../../../express/researcher/verizon_customer_case_v2024/components/greenRed';
import CommonTrs from './commonTrs';
import TrGray from './trGray';
import TrData from './trData';

const Unhappy = ({ pids, data, vccCustomerFirst }) => {
  const cData = data.unhappy_data;

  const colSpan = pids.length + 1;

  return (
    <>
      <div className="comparisons-table_wrapper">
        <table className="table comparisons-table">
          <tbody>
            <CommonTrs pids={ pids } data={ data } />

            <TrGray
              colSpan={ colSpan }
              text="Post-Exposure to Unhappy Path Scenario"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Ease of Experience"
              hint='("Little" or "No" effort)'
              metric="vcc_deviation_effort"
              format="plus"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text={ vccCustomerFirst }
              hint='(Agree "Completely" or "Somewhat")'
              metric="vcc_deviation_values"
              format="plus"
            />

            <TrGray
              colSpan={ colSpan }
              text="Customer Case Scores"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Happy Path Scenario"
              metric="happy_path"
              format="number"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Unhappy Path Scenario"
              metric="unhappy_path"
              format="number"
            />

            <TrGray
              colSpan={ colSpan }
              text="Risk"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Risk Level"
              hint="This is the effect on Ease and Values Me scores of the Unhappy Path."
              metric="risk"
              format="tag"
              forceNA={ (pid) => (cData && !cData[pid].has_highlighting) }
            />

          </tbody>
        </table>
      </div>
      <GreenRed arrows={ data.arrows_mode } text={ data.arrows_label_text } />
    </>
  );
};

export default Unhappy;
