import React from 'react';

const FormHeader = ({ hasNumberLimit, numberLimit }) => {
  return (
    <div className="form-header">
      <div className="form-header_grid">
        <div className="form-header_grid-item -grow">
          <div className="form-question_type-text">
            <span className="bolder">
              Screener questions allow you to determine the kinds of respondents you want to participate.
            </span>
          </div>
          <div className="form-question_type-text">
            For example, you may be looking for category or brand users.
            {hasNumberLimit && <span> You can add up to { numberLimit } screening questions to qualify your respondents.</span>}
            {!hasNumberLimit && <span> You have no limits in adding of screening questions to qualify your respondents.</span>}
          </div>
          <div className="form-question_type-text">
            Adding screener questions may impact cost and timing.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
