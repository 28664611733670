import React, { useState, useContext, useEffect, Fragment } from 'react';
import { map } from 'lodash';
import renderRawHtml from '../../../../../common/render_raw_html';
import listData from '../../../common/types/list_data';
import { BaseContext } from '../../contexts';

const SolutionsHeaderItem = ({ className, onClick, projectsAllowance }) => {
  const [ list ] = useState(() => (listData()));
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const { setOpenRemoteReadMore } = useContext(BaseContext);

  const onSolutionsHandle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
    setOpenDropdown(true);
  };

  const handleSolutionItemClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenRemoteReadMore({ item });
  };

  useEffect(() => {
    const eventHandler = () => {
      setOpenDropdown(false);
    };
    document.addEventListener('click', eventHandler);
    return  () => {
      document.addEventListener('click', eventHandler);
    };
  }, []);

  return (
    <li className="menu_item">
      <div className="dropdown open">
        <a
          className={ className }
          href="#"
          onClick={ onSolutionsHandle }
        >
          Express Solutions
        </a>
        {
          openDropdown &&
          <ul className="dropdown_menu -plane-in-modal -home">
            {
              map(list, (item, index) => (
                <Fragment key={ `SolutionItem${index}` }>
                  {
                    (!projectsAllowance || projectsAllowance[item.express]) &&
                    <li className="dropdown_item">
                      <div
                        className="dropdown_link menu_link"
                        onClick={
                          (event) => { handleSolutionItemClick(event, item); }
                        }
                        { ...renderRawHtml(item.landingTitle) }
                      />
                    </li>
                  }
                </Fragment>
              ))
            }
          </ul>
        }
      </div>
    </li>
  );
};

export default SolutionsHeaderItem;
