import React from 'react';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import StartButton from '../../../common/components/start_button.js';
import ZoomImage from "../../../common/components/zoom_image";

const NoVideoIntro = ({ conceptIndex, translations, src, nextStep }) => {
  const introText = () => {
    return !conceptIndex ? translations.express_no_video_intro_first : translations.express_no_video_intro_next;
  };

  return (
    <div className="survey-layout_content -start">
      <div className="survey-start -concept">
        <div className="survey-start_description" { ...renderRawHtml(introText()) }  />
        <div>
          <ZoomImage className="survey-start-hero-img" src={ src }>
            <img src={ src } />
          </ZoomImage>
        </div>
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default NoVideoIntro;
