import React from 'react';
import FivePointScale from '../../../common/components/metrics/five_point_scale';
import Markup from '../../../common/components/metrics/markup';
import AttributeRatings from '../../../common/components/metrics/attribute_ratings';
import ThoughtBubble from "../../../common/components/metrics/thought_bubble";
import { defaultScorings } from "../../../common/logic/eligible";
import VideoIntro from './video-intro';
import NoVideoIntro from './no-video-intro';

const Concept = ({ data, conceptIndex, concept, index, nextStep, result }) => {
  const {
    markup_tool, attribute_ratings1, attribute_ratings2,
    translations, language_code
  } = data;

  const scorings = defaultScorings(data.scorings);

  const tbKlass = data.tb_klass;

  const maleSelected = () => (result.answers.findIndex("S3_1") > -1);

  const tbTitle = () => (
    maleSelected() ?
      translations.thought_bubble_title_man :
      translations.thought_bubble_title_woman
  );

  return (
    <>
      {
        index === 1 && data.video_enabled &&
        <VideoIntro
          conceptIndex={ conceptIndex }
          translations={ translations }
          imageSrc={ concept.url }
          videoSrc={ concept.video_url }
          result={ result }
          nextStep={ nextStep }
          languageCode={ language_code }
        />
      }
      {
        index === 1 && !data.video_enabled &&
        <NoVideoIntro
          conceptIndex={ conceptIndex }
          translations={ translations }
          src={ concept.url }
          nextStep={ nextStep }
        />
      }
      {
        index === 2 &&
        <ThoughtBubble
          concept={ concept }
          tbKlass={ tbKlass }
          title={ tbTitle() }
          description={ translations.thought_bubble_description }
          showMan={ maleSelected() }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 3 &&
        <FivePointScale
          concept={ concept }
          questions={ scorings }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 4 &&
        <Markup
          conceptIds={ [ concept.focus_concept_id ] }
          markupTool={ markup_tool }
          nextStep={ nextStep }
          result={ result }
        />
      }
      {
        index === 5 &&
        <AttributeRatings
          title={ translations.attribute_ratings1_title }
          concept={ concept }
          questions={ attribute_ratings1 }
          result={ result }
          nextStep={ nextStep }
          languageCode={ language_code }
        />
      }
      {
        index === 6 &&
        <AttributeRatings
          title={ translations.attribute_ratings2_title }
          concept={ concept }
          questions={ attribute_ratings2 }
          result={ result }
          nextStep={ nextStep }
          languageCode={ language_code }
        />
      }
    </>
  );
};

export default Concept;
