const selected_1 = [
  // 1
  {
    tvConnections: 1,
    recomended: false,
    STBCount: 1,
    ownDevicesCount: 0,
    price: 0
  },
  {
    tvConnections: 3,
    recomended: true,
    STBCount: 1,
    ownDevicesCount: Infinity,
    price: 20
  }
];

const selected_2 = [
  // 1
  {
    tvConnections: 2,
    recomended: false,
    STBCount: 1,
    ownDevicesCount: 1,
    price: 12
  },
  {
    tvConnections: 3,
    recomended: true,
    STBCount: 1,
    ownDevicesCount: Infinity,
    price: 20
  },
  // 2
  {
    tvConnections: 2,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: 0,
    price: 12
  },
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: Infinity,
    price: 32
  }
];

const selected_3 = [
  //1
  {
    tvConnections: 3,
    recomended: false,
    STBCount: 1,
    ownDevicesCount: 2,
    price: 24
  },
  {
    tvConnections: 3,
    recomended: true,
    STBCount: 1,
    ownDevicesCount: Infinity,
    price: 20
  },
  // 2
  {
    tvConnections: 3,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: 1,
    price: 24
  },
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: Infinity,
    price: 32
  },
  // 3
  {
    tvConnections: 3,
    recomended: false,
    STBCount: 3,
    ownDevicesCount: 0,
    price: 24
  },
  {
    tvConnections: 5,
    recomended: false,
    STBCount: 3,
    ownDevicesCount: Infinity,
    price: 44
  }
];

const selected_4 = [
  // 1
  {
    tvConnections: 3,
    recomended: false,
    STBCount: 1,
    ownDevicesCount: Infinity,
    price: 20
  },
  // 2
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: 2,
    price: 30
  },
  {
    tvConnections: 4,
    recomended: true,
    STBCount: 2,
    ownDevicesCount: Infinity,
    price: 32
  },
  // 3
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 3,
    ownDevicesCount: 1,
    price: 30
  },
  {
    tvConnections: 5,
    recomended: false,
    STBCount: 3,
    ownDevicesCount: Infinity,
    price: 44
  },
  // 4
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 4,
    ownDevicesCount: 0,
    price: 30
  },
  {
    tvConnections: 6,
    recomended: false,
    STBCount: 4,
    ownDevicesCount: Infinity,
    price: 50
  }
];

const selected_5 = [
  // 1
  {
    tvConnections: 3,
    recomended: false,
    STBCount: 1,
    ownDevicesCount: Infinity,
    price: 20
  },
  // 2
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: Infinity,
    price: 32
  },
  // 3
  {
    tvConnections: 5,
    recomended: false,
    STBCount: 3,
    ownDevicesCount: 2,
    price: 36
  },
  {
    tvConnections: 5,
    recomended: true,
    STBCount: 3,
    ownDevicesCount: Infinity,
    price: 44
  },
  // 4
  {
    tvConnections: 5,
    recomended: false,
    STBCount: 4,
    ownDevicesCount: 1,
    price: 36
  },
  {
    tvConnections: 6,
    recomended: false,
    STBCount: 4,
    ownDevicesCount: Infinity,
    price: 50
  },
  // 5
  {
    tvConnections: 5,
    recomended: false,
    STBCount: 5,
    ownDevicesCount: 0,
    price: 36
  },
  {
    tvConnections: 7,
    recomended: false,
    STBCount: 5,
    ownDevicesCount: Infinity,
    price: 56
  }
];

const selected_6 = [
  // 1
  {
    tvConnections: 3,
    recomended: false,
    STBCount: 1,
    ownDevicesCount: Infinity,
    price: 20
  },
  // 2
  {
    tvConnections: 4,
    recomended: false,
    STBCount: 2,
    ownDevicesCount: Infinity,
    price: 32
  },
  // 3
  {
    tvConnections: 5,
    recomended: false,
    STBCount: 3,
    ownDevicesCount: Infinity,
    price: 44
  },
  // 4
  {
    tvConnections: 6,
    recomended: false,
    STBCount: 4,
    ownDevicesCount: 2,
    price: 42
  },
  {
    tvConnections: 6,
    recomended: true,
    STBCount: 4,
    ownDevicesCount: Infinity,
    price: 50
  },
  // 5
  {
    tvConnections: 6,
    recomended: false,
    STBCount: 5,
    ownDevicesCount: 1,
    price: 42
  },
  {
    tvConnections: 7,
    recomended: false,
    STBCount: 5,
    ownDevicesCount: Infinity,
    price: 56
  },
  // 6
  {
    tvConnections: 6,
    recomended: false,
    STBCount: 6,
    ownDevicesCount: 0,
    price: 42
  },
  {
    tvConnections: 8,
    recomended: false,
    STBCount: 6,
    ownDevicesCount: Infinity,
    price: 62
  }
];

const invariants = [
  selected_1,
  selected_2,
  selected_3,
  selected_4,
  selected_5,
  selected_6
];

export const getInvariantByDeviceCount = count => invariants[count-1] || [];
