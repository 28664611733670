import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import ExpressOpenEndCard from '../../common/components/express-open-end-card';
import { IndexData } from '../../common/index-context';
import HorBarTable from "./hor-bar-table";

const TabCustom = ({ data, conceptType }) => {
  const metrics = conceptType ? data.survey_metrics : data.custom_questions;
  const { isAdmin, loadOpenEndCard, loadComments } = useContext(IndexData);
  const handleLoadOpenEndCard = (question) => {
    loadOpenEndCard(question, 'open_end_card', question.open_end_card);
  };
  return (
    <>
      {
        map(metrics, (question, index) => (
          <Fragment key={ index }>
            <HorBarTable
              q={ question }
              colorClass="-green"
              percentColorClass="-black"
              reportView={ data.report_view?.customMetrics?.[question.name] }
              custom
            />
            {
              !!question.open_end &&
              <ExpressOpenEndCard
                className="comparisons_table"
                metric={ question.open_end_card.metric }
                cardData={ question.open_end_card.data }
                mode="editing"
                filter={ data.filter.demographics }
                customTitle={ (<h3>{question.open_end_card.metric.title}</h3>) }
                pinCount={ 3 }
                pinTitle={ 'Pin' }
                unpinTitle={ 'Unpin' }
                isAdmin={ isAdmin }
                loadWords={ () => { handleLoadOpenEndCard(question); } }
                loadComments={ loadComments }
                tourName={ `custom-open-end-${question.name}` }
              />
            }
          </Fragment>
        ))
      }
    </>
  );
};

export default TabCustom;
