import React from "react";

const SummaryEditorFeedbackPlate = ({ title, modifier, children }) => {
  return (
    <div className={`summary-editor_feedback-plate ${modifier || ''}`}>
      <div className="summary-editor_feedback-plate-item -title">
        <div className="summary-editor_feedback-plate-title">
          { title }
        </div>
      </div>
      <div className="summary-editor_feedback-plate-item -buttons">
        <div className="summary-editor_feedback-plate-buttons">
          { children }
        </div>
      </div>
    </div>
  );
}

export default SummaryEditorFeedbackPlate;