import React from 'react';
import classnames from 'classnames';
import { subPulseChanges } from '../helpers';

const NSize = ({ comaAtStart, verizonType, value, low, forceHideNsize }) => {
  const displayN = subPulseChanges(verizonType) && !forceHideNsize;
  return (
    <>
      {
        displayN &&
        <>
          {
            comaAtStart && ','
          }
          <div className={ classnames('n-size-overview', { '-error': low }) }>
            n = {value}
          </div>
          {
            low &&
            <div className="info-tooltip -error">
              <div className="info-tooltip_control">
                <svg className="icon -i-info">
                  <use xlinkHref="#svg_i-info" />
                </svg>
              </div>
              <div className="info-tooltip_drop -bottom-center">
                <div className="info-tooltip_drop-inner">
                  Caution: small sample size.
                </div>
              </div>
            </div>
          }
        </>
      }
    </>
  );
};

export default NSize;
