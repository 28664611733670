import remoteFiles from '../../../common/remote_files';
const {
  EquinoxJpg, SoulCycleJpg, PureYogaJpg
} = remoteFiles.equinox;

const brands = {
  eqx: {
    id: "equinox",
    title: "Equinox",
    icon: "eqx-logo",
    color: "gray",
    imgUrl: EquinoxJpg
  },
  pr: {
    id: "pr",
    title: "Precision run",
    icon: "pr-logo",
    color: "light-mint"
  },
  headstrong: {
    id: "headstrong",
    title: "Headstrong",
    icon: "headstrong-logo",
    color: "light-purple"
  },
  pureYoga: {
    id: "pure-yoga",
    title: "Pure Yoga",
    icon: "pure-yoga-logo",
    color: "dark-purple",
    imgUrl: PureYogaJpg
  },
  soulcycle: {
    id: "soulcycle",
    title: "Soulcycle",
    icon: "soulcycle-logo",
    color: "yellow",
    imgUrl: SoulCycleJpg
  }
};

export default brands;
