import { each, find, map, includes } from 'lodash';

const fixPreviousAnswersTemplates = (text, metrics, result, currentAnswers) => {
  let newText = text;
  const results = newText.match(/%answer=[^%]+%/g);
  each(
    results || [],
    (template) => {
      const templateResult = template.match(/=([^%]+)/);
      const templateQuestionTitle = templateResult[1];
      const templateQuestion = find(
        metrics,
        (item) => item.title === templateQuestionTitle
      );
      if (templateQuestion) {
        const answerId = find(
          currentAnswers,
          (item) => {
            if (typeof item !== 'string') {
              return false;
            }
            return item.match(new RegExp(`${templateQuestion.name}_`));
          }
        );
        const foundAnswer = find(
          templateQuestion.answers,
          (el) => el.id === answerId
        );
        if (foundAnswer) {
          newText = newText.replace(template, (foundAnswer.label || '').toLowerCase());
        }
      }
    }
  );
  return newText;
};

const fixAdditionalByPreviousAnswersTemplate = (text, metrics, result, translations, currentAnswers) => {
  let newText = text;
  const results = newText.match(/%if=answer[^%]+%/g);
  each(
    results || [],
    (template) => {
      const templateResult = template.match(/=answer([^=]+)=([^?]+)\?([^:]+):((.)*)%/);
      const templateQuestionAnswers = templateResult[1].split(',');
      const templateQuestionTitle = templateResult[2];
      const templatePositiveKey = templateResult[3];
      const templateNegativeKey = templateResult[4];
      const positiveText = translations[templatePositiveKey] || templatePositiveKey;
      const negativeText = translations[templateNegativeKey] || templateNegativeKey;

      const templateQuestion = find(
        metrics,
        (item) => item.title === templateQuestionTitle
      );
      if (templateQuestion) {
        const templateAnswerIds = map(
          templateQuestionAnswers,
          (item) => `${templateQuestion.name}_${item}`
        );
        const selected = !!find(
          currentAnswers,
          (item) => {
            if (typeof item !== 'string') {
              return false;
            }
            return includes(templateAnswerIds, item);
          }
        );
        newText = newText.replace(
          template,
          ((selected ? positiveText : negativeText) || '').toLowerCase()
        );
      }
    }
  );
  return newText;
};

const fixAdditionalByTagTemplate = (text, metrics, result, translations) => {
  let newText = text;
  const results = newText.match(/%if=tag=[^%]+%/g);
  each(
    results || [],
    (template) => {
      const templateResult = template.match(/=tag=([^?]+)\?([^:]+):((.)*)%/);
      const templateTagName = templateResult[1];
      const templatePositiveKey = templateResult[2];
      const templateNegativeKey = templateResult[3];
      const positiveText = translations[templatePositiveKey] || templatePositiveKey;
      const negativeText = translations[templateNegativeKey] || templateNegativeKey;
      newText = newText.replace(
        template,
        (
          (
            includes(result.tags || [], templateTagName) ? positiveText : negativeText
          ) || ''
        ).toLowerCase()
      );
    }
  );
  return newText;
};

const fixQuestionTextTemplates = (question, metrics, result, translations, placeForAnswers) => {
  const currentAnswers = placeForAnswers ?
    placeForAnswers.answers :
    (result.loops || [])[0]?.answers || [];
  let newText = fixPreviousAnswersTemplates(question.text, metrics, result, currentAnswers);
  newText = fixAdditionalByPreviousAnswersTemplate(newText, metrics, result, translations, currentAnswers);
  newText = fixAdditionalByTagTemplate(newText, metrics, result, translations);
  return newText;
};

export { fixQuestionTextTemplates };
