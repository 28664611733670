import React from "react";
import user from "../mocks/user";
import Avatar from "./Avatar";
import Icon from "./Icon";
import WeekCalendar from "./WeekCalendar";

const ScheduleHeader = () => {
  return (
    <div className="schedule-header">
      <div className="schedule-header_main">
        <div className="schedule-header_user">
          <Avatar {...user} />
        </div>
        <h4 className="schedule-header_title">
          November
          <Icon name="dropdown" className="schedule-header_dropdown-icon" />
        </h4>
        <div className="schedule-header_action">
          <Icon name="edit" className="schedule-header_edit-icon" />
          Edit
        </div>
      </div>
      <div className="schedule-header_calendar">
        <WeekCalendar />
      </div>
    </div>
  );
};

export default ScheduleHeader;
