import React, { useContext, useEffect, useState } from 'react';
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { IndexData } from "../../common/index-context";
import TopFilter from '../../common/filters/app';
import ComparisonsLegend from "../../common/components/comparisons-legend";
import ComparisonsIntro from "../../common/components/comparisons-intro";
import Averages from "../../common/averages";
import WarningMessage from "../../common/components/warning-message";
import SummaryPanel from "../../common/summary-panel/index-project";
import Tabs from "../../brand_snapshot/components/tabs";
import TabCustom from "../../brand_snapshot/components/tab-custom";
import ExpressLanguageSwitcher from '../../common/components/express-language-switcher';
import ConceptsTable from "./concepts-table/index";

const Comparisons = () => {
  const {
    data, init, loadData, loading,
    getForceOpenFilterDropDown
  } = useContext(IndexData);
  const forceDropDown = getForceOpenFilterDropDown();

  const showCustom = !!data.survey_metrics?.length;
  const { search } = useLocation();
  const [ tab, setTab ] = useState('');

  useEffect(() => {
    const initShowCustom = true;
    const query = new URLSearchParams(search);
    let initTab = query.get('tab') || 'base';

    if (!initShowCustom && initTab === 'custom') {
      initTab = 'base';
    }

    setTab(initTab);
  }, []);

  return (
    <div className="content_body">
      <div className="comparisons">
        <ComparisonsIntro plural="concepts" />

        <Averages
          statTesting
          scorecard={ false }
          statTestingItemTitle="concept"
          data={ data }
          init={ init }
          loadData={ loadData }
        />

        <TopFilter
          data={ data }
          loadData={ loadData }
          forceDropDown={ forceDropDown }
        />
        <WarningMessage
          data={ data }
          buzzbackUser={ init.isInternal }
          itemName={ init.project.item_name }
        />

        <SummaryPanel />
        {
          showCustom &&
          <div className="comparisons_metrics_tabs">
            <Tabs
              tab={ tab }
              setTab={ setTab }
              showCustom={ showCustom }
              baseTitle={ 'base' }
              survey
            />
          </div>
        }

        <div
          className={
            classnames(
              "comparisons_table table-wrap -concept-comparison -relative",
              { '-without-margin': data.not_enough_data || data.not_enough_brands },
              { '-viewport': tab === "base" }
            )
          }
        >
          {
            (tab === "custom" && data.has_survey_open_metrics) &&
            <div className="comparisons_tabs">
              <ExpressLanguageSwitcher
                description={ data.translations.language_switcher_description }
                showLanguageSwitcher={ data.show_language_switcher }
              />
            </div>
          }
          { loading && <div className="page-loader -inner-loader" /> }
          { tab === "base" && <ConceptsTable /> }
          { tab === "custom" && <TabCustom data={ data } conceptType /> }
        </div>

        {
          tab === "base" &&
          <ComparisonsLegend
            statTesting={ data.stat_testing }
            averageMode={ data.average_mode }
          />
        }
      </div>
    </div>
  );
};

export default Comparisons;
