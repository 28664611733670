import React, { useContext, useEffect, useRef } from 'react';
import ExpressOpenEndCard from "../../common/components/express-open-end-card";
import { IndexData } from "../../common/index-context";
import ExpressSlider from "../../../../common/components/express_slider";
import UBA from "./unaided-brand-awareness";
import HorBar2xTable from "./hor-bar-2x-table";
import TwoColTable from "./two-col-table";
import AVS from "./a-v-s";
import NPS from "./n-p-s";
import AttrRatings from "./attr-ratings";

const TabBrand = ({ data }) => {
  const { isAdmin, loadCurrent, loadComments, getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs('Attributes', tourElement);
  useEffect(callback, condition);

  return (
    <>
      {data.aided_brand_awareness_usage &&
        <HorBar2xTable
          q={ data.aided_brand_awareness_usage }
          greenField="aware"
          blueField="usage"
        />}

      {data.unaided_brand_awareness &&
      <UBA
        metric={ data.unaided_brand_awareness.metric }
        data={ data.unaided_brand_awareness.data }
        filter={ data.filter }
        loadWords={ loadCurrent }
        loadComments
        projectId={ data.project_id }
        info={ data.info }
      />}


      {data.thought_bubble &&
      <ExpressOpenEndCard
        className="comparisons_table"
        metric={ data.thought_bubble.metric }
        cardData={ data.thought_bubble.data }
        mode="editing"
        filter={ data.filter.demographics }
        customTitle={ (<h3>Thought Bubble</h3>) }
        pinCount={ 3 }
        pinTitle={ 'Pin' }
        unpinTitle={ 'Unpin' }
        isAdmin={ isAdmin }
        loadWords={ loadCurrent }
        loadComments={ loadComments }
        tourName={ 'thought-bubble' }
      />}

      {data.preference_not_using &&
      <ExpressOpenEndCard
        className="comparisons_table"
        metric={ data.preference_not_using.metric }
        cardData={ data.preference_not_using.data }
        mode="editing"
        filter={ data.filter.demographics }
        customTitle={ (<h3>Reasons for not using</h3>) }
        pinCount={ 3 }
        pinTitle={ 'Pin' }
        unpinTitle={ 'Unpin' }
        isAdmin={ isAdmin }
        loadWords={ loadCurrent }
        loadComments={ loadComments }
        tourName={ 'reasons-for-not-using' }
      />}

      { (data.brand_appeal || data.brand_value || data.brand_satisfaction || data.nps) &&
      <div className="report-plate_section -first">
        <div className="report-plate_row">
          { (data.brand_appeal || data.brand_value || data.brand_satisfaction) &&
          <AVS data={ [ data.brand_appeal, data.brand_value, { ...data.brand_satisfaction, hint: `among Brand Users (n=${data.n_users})` } ] } />
          }
          { data.nps &&
          <NPS data={ data.nps } n={ data.n_users } />
          }
        </div>
      </div>}

      {data.importance_performance &&
        <div className="report-plate_section -js-importance-performance" ref={ tourElement }>
          <ExpressSlider>
            <div className="report-plate -no-padding">
              <AttrRatings data={ data.importance_performance } />
            </div>
            <TwoColTable
              q={ data.importance_performance }
              firstField="importance"
              firstSubTitle="among Total"
              secondField="performance"
              secondSubTitle={ `among Brand Users (n=${data.n_users})` }
            />
          </ExpressSlider>
        </div>}

      {data.usage_satisfaction &&
        <TwoColTable
          q={ data.usage_satisfaction }
          firstField="usage"
          secondField="satisfaction"
          secondSubTitle="Top 2 Box"
          tourKey="UsSat"
        >
          { data.usage_satisfaction.satisfaction &&
            <>
              <sup>*</sup> Among those who use the product
            </>}
        </TwoColTable>}
    </>
  );
};

export default TabBrand;
