import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import SvgIcon from "../../../../../common/components/svg-icon";

const VideoIntro = ({
  conceptIndex, translations, imageSrc, videoSrc, result, nextStep,
  languageCode
}) => {
  const [ showImage, setShowImage ] = useState(true);
  const [ loading, setLoading ] = useState(false);
  const [ playing, setPlaying ] = useState(false);
  const [ hasProblem, setHasProblem ] = useState(false);
  const [ boxMode, setBoxMode ] = useState('none');
  const languageCodeWithValue = languageCode || 'en';
  const lowerFont = languageCodeWithValue !== 'en';
  const muchLowerFont = lowerFont && translations.video_able_no.length > 37;

  const vidRef = useRef(null);
  const playVideo = () => {
    setShowImage(false);
    setPlaying(true);
    setBoxMode('none');
    setLoading(true);
  };

  const handleError = () => {
    setPlaying(false);
    setBoxMode('not_loading');
    setLoading(false);
    vidRef.current.pause();
  };

  const handleEnded = () => {
    setPlaying(false);
    setBoxMode('able');
  };

  const handleClick = () => {
    if (vidRef.current.paused) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  };

  const handlePlaying = () => {
    setLoading(false);
  };

  const reloadVideo = () => {
    setHasProblem(true);
    vidRef.current.load();
    playVideo();
  };

  const handleSkip = () => {
    result['video']['skipped'] = true;
    nextStep();
  };

  const introText = () => {
    return !conceptIndex ? translations.express_video_intro_first : translations.express_video_intro_next;
  };

  useEffect(() => {
    if (playing) {
      vidRef.current.play();
    }
  }, [ playing ]);

  return (
    <>

      {
        showImage &&
        <div className="survey-layout_content -start">
          <div className="survey-start -concept">
            <div
              className="survey-start_description"
              { ...renderRawHtml(introText()) }
            />
            <div className="video-round-corners" onClick={ playVideo }>
              <div className="play-button-wrapper">
                <SvgIcon name="play-in-circle" />
              </div>
              <img src={ imageSrc } />
            </div>
          </div>
        </div>
      }

      {
        !showImage &&
        <div className="black-background">
          <div className="video">
            <div className="gray-box" >
              <video
                className={
                  classnames({
                    "hidden": boxMode === 'not_loading',
                    "finished": boxMode === 'able'
                  })
                }
                ref={ vidRef }
                src={ videoSrc }
                onEnded={ handleEnded }
                onClick={ handleClick }
                onError={ handleError }
                onPlaying={ handlePlaying }
              />
            </div>
            {
              boxMode === 'none' &&
              <div className="not-loading-button-wrapper">
                <button className="button" onClick={ handleError }>
                  { translations.video_not_loading }
                </button>
              </div>
            }
          </div>

          { loading && <div className="page-loader" /> }

          {
            boxMode === 'able' &&
            <div className={ "message-box" }>
              <h5>{translations.video_able}</h5>
              <p>{translations.video_able_desc}</p>
              <div className="buttons -finished">
                <button
                  className={
                    classnames(
                      "button -secondary -can-wrap",
                      {
                        '-lower-font': lowerFont && !muchLowerFont,
                        '-much-lower-font': muchLowerFont
                      }
                    )
                  }
                  onClick={ playVideo }
                >
                  {translations.video_able_no}
                </button>
                <button
                  className="button -can-wrap"
                  onClick={ () => { nextStep(); } }
                >
                  {translations.video_able_yes}
                </button>
              </div>
            </div>
          }
          {
            boxMode === 'not_loading' &&
            <div className={ "message-box" }>
              <h5>{translations.video_not_loading}</h5>
              <p>{translations.video_not_loading_desc}</p>
              <div className="buttons">
                <button
                  className="button js-reload"
                  onClick={ reloadVideo }
                >
                  {translations.video_not_loading_reload}
                </button>
                {
                  hasProblem &&
                  <button
                    className="button -secondary js-skip"
                    onClick={ handleSkip }
                  >
                    {translations.video_not_loading_skip}
                  </button>
                }
              </div>
            </div>
          }
        </div>
      }
    </>
  );
};
export default VideoIntro;
