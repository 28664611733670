import React, { useContext, useState, Fragment } from "react";
import { map, countBy } from "lodash";
import SvgIcon from "../../../../../common/components/svg-icon";
import { DataContext } from "../../data-context";
import ProductEditor from "./ProductEditor";
import ProductCard from "./ProductCard";

const ProductsColumn = ({ coords, onDelete }) => {
  const {
    shelves, currentShelfIndex, addProduct, updateProduct,
    deleteProduct, setCurrentProductIndex, cartWithPrices,
    notDestroyed
  } = useContext(DataContext);
  const shelf = shelves[currentShelfIndex];
  const products = notDestroyed(shelf.cart_products);

  const [ showEditor, setShowEditor ] = useState(false);
  const [ editorMode, setEditorMode ] = useState('');
  const [ initValue, setInitValue ] = useState({});

  const openModalNew = () => {
    setEditorMode('new');
    setInitValue({
      name: '',
      product_image_data: '',
      places: []
    });
    setShowEditor(true);
  };

  const openModalEdit = (product, index) => {
    setEditorMode(product.target ? 'target' : 'edit');
    setInitValue({ ...product });
    setCurrentProductIndex(index);
    setShowEditor(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowEditor(false);
  };

  const handleDeleteProduct = (event) => {
    closeModal(event);
    const index = deleteProduct();
    onDelete(index);
  };

  const handleSaveProduct = (changedProduct) => {
    // to render only once https://react.dev/blog/2022/03/08/react-18-upgrade-guide
    setTimeout(() => {
      if (editorMode === 'new') {
        addProduct(changedProduct);
      } else if (editorMode === 'edit') {
        updateProduct(changedProduct);
      } else if (editorMode === 'target') {
        updateProduct({ ...initValue, price: changedProduct.price });
      }
      setShowEditor(false);
    }, 100);
  };

  const counts = countBy(coords, (rect) => (rect.productIndex));

  return (
    <>
      <div className="products-column">
        <div className="product -new">
          <div className="image" onClick={ openModalNew }>
            <SvgIcon name="plus-circle" />
          </div>
          <div className="label">
            Add Product
          </div>
        </div>
        <hr />
        {
          map(products, (product, index) => (
            <Fragment key={ `product${index}` }>
              {
                <ProductCard
                  product={ product }
                  usesCount={ counts[index] }
                  cartWithPrices={ cartWithPrices }
                  canDrag={ !!shelf.shelf_image_src }
                  openModalEdit={ () => { openModalEdit(product, index); } }
                />
              }
            </Fragment>
          ))
        }
      </div>
      {
        showEditor &&
        <ProductEditor
          editorMode={ editorMode }
          initValue={ initValue }
          saveCallback={ handleSaveProduct }
          closeModal={ closeModal }
          cartWithPrices={ cartWithPrices }
          handleDeleteProduct={ handleDeleteProduct }
        />
      }
    </>
  );
};

export default ProductsColumn;
