import React from 'react';
import { map, unset } from "lodash";
import SvgIcon from '../../../../../common/components/svg-icon';

const CaseComparisonList = ({ selectedForComparison, setSelectedForComparison, data, selected,
  total, setShowList }) => {
  const hideConcept = (key) => {
    if (selected <= 2) {
      return;
    }
    selectedForComparison[key].show = false;
    setSelectedForComparison({ ...selectedForComparison });
  };

  const showConcept = (key) => {
    selectedForComparison[key].show = true;
    setSelectedForComparison({ ...selectedForComparison });
  };

  const removeConcept = (key) => {
    unset(selectedForComparison, key);
    setSelectedForComparison({ ...selectedForComparison });
  };

  return (
    <div className="case-comparison">
      <div
        className="case-comparison_backdrop"
        onClick={ () => {setShowList(false);} }
      />
      <div className="case-comparison_plate">
        <div
          className="case-comparison_close"
          onClick={ () => {setShowList(false);} }
        >
          <SvgIcon name="i-close-sm" className="-pointer" />
        </div>
        <div className="case-comparison_plate-top">
          <h2>Case Comparison</h2>
          <div className="case-comparison_notes">Showing {selected} of {total} selected items.</div>
        </div>
        <div className="case-comparison_plate-body">
          <ul className="case-comparison_list">
            {map(selectedForComparison, (hash, key) => (
              <li key={ key }>
                <div className="case-comparison_grid">
                  <div className="case-comparison_grid-item -first">
                    {hash.show && selected > 2 &&
                    <SvgIcon
                      name="i-eye-v2-open"
                      className="-pointer"
                      onClick={ () => {hideConcept(key);} }
                    />}
                    {
                      !hash.show &&
                      <SvgIcon
                        name="i-eye-v2-closed"
                        className="-pointer"
                        onClick={ () => {showConcept(key);}
                        }
                      />
                    }
                  </div>
                  <div className="case-comparison_grid-item -flex">
                    {data.scorecard_data[key].name}
                  </div>
                  <div className="case-comparison_grid-item -last">
                    { selected > 2 &&
                    <div
                      className="case-comparison_button-delete"
                      onClick={ () => { removeConcept(key); } }
                    >
                      <SvgIcon name="i-close-sm" />
                    </div>
                    }
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CaseComparisonList;
