import React, { useContext } from 'react';
import cn from "classnames";
import { baseContext } from "../context";

const SortableTableHeader = ({ title, onOrderChange, orderTitle }) => {
  const {
    order, orderBy
  } = useContext(baseContext);

  return (
    <th
      className={ cn("table-comparison_name-cell -nowrap -clickable", { '-ordered': orderBy === orderTitle }) }
      onClick={ () => onOrderChange(orderTitle) }
    >
      {
        orderBy === orderTitle &&
        <a
          className={ cn("concepts-database_sort-link", { '-asc': order === "asc", '-desc': order === "desc" }) }
          onClick={ () => onOrderChange(orderTitle) }
        >
          { title }
        </a>
      }
      {
        orderBy !== orderTitle &&
        title
      }
    </th>
  );
};

export default SortableTableHeader;
