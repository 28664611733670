import { map } from 'lodash';
import {
  plans as commonPlans,
  allPlansInclude,
  pricing,
  savingsTitles,
  savingFeatures
} from "../unlimited_common/plans_data";
import { getLinesCount } from "../unlimited_common/statistics";

let conceptName = null;

export const setConceptName = (name) => {
  conceptName = name;
};

const plans = map(commonPlans, (commonPlan) => {
  return {
    ...commonPlan,
    get price() { return pricing[commonPlan.id - 1][getLinesCount() - 1]; },
    get savingsTitle() { return savingsTitles[conceptName]; },
    get savings() { return [ savingFeatures[conceptName] ]; }
  };
});

export { plans, allPlansInclude };
