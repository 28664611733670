import React, { useContext, useState } from 'react';
import Header from "./common/Header";
import TagTypes from "./common/TagTypes";
import TagsByCategory from "./TagsByCategory";
import TagsOneLevel from "./TagsOneLevel";
import { DataContext } from "./withData";
import ImagesModal from "./common/ImagesModal";

const IndexInner = () => {
  const { brandData, customData, modalInfo } = useContext(DataContext);
  const [ tab, setTab ] = useState('express_category');

  return (
    <>
      <div className="content -relative -grey-background">
        <Header />
        <TagTypes tab={ tab } setTab={ setTab } />
        {tab === 'express_category' && <TagsByCategory />}
        {tab === 'express_brand' &&
        <TagsOneLevel
          tags={ brandData }
          header="Brand Tags"
          tagType="express_brand"
        />}
        {tab === 'express_custom' &&
        <TagsOneLevel
          tags={ customData }
          header="Custom Tags"
          tagType="express_custom"
        />}
      </div>

      {modalInfo.id && <ImagesModal />}
    </>
  );
};

export default IndexInner;
