import React, { useContext, useLayoutEffect, useState } from 'react';
import { find, cloneDeep, remove, map, keys, includes, each, values } from 'lodash';
import { GeneralContext } from '../../../contexts';
import QuestionsGroup from '../../QuestionsGroup';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';
import { verizonCustomer, findByName } from '../../withTagsAndAnswersUpdating';
import Intro from '../../intro';
import renderRawHtml from '../../../../../../common/render_raw_html';

const QualifiedDemographics = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    qualifiedDemographicMetrics, result, translations
  } = useContext(GeneralContext);

  const metricIndex = metricGroupStep - 1;

  const prepareCurrentQuestions = () => {
    if (metricIndex >= 0) {
      const question = qualifiedDemographicMetrics[metricIndex];
      question.text = fixQuestionTextTemplates(
        question, qualifiedDemographicMetrics, result, translations, result
      );
      return [ question ];
    }
    return [];
  };

  const canSeeUnlimitedPlanQuestion = () => {
    if (qualifiedDemographicMetrics[metricIndex].title === 'TYPE OF VZ UNLIMITED PLAN') {
      const dataQuestion = find(
        qualifiedDemographicMetrics,
        (item) => item.title === 'DATA USERS'
      ) || {};
      const unlimitedUser = !!find(
        result.answers,
        (answer) => {
          if (typeof answer === 'string') {
            return answer === `${dataQuestion.name}_3`;
          }
          return false;
        }
      );
      return (unlimitedUser && verizonCustomer(result));
    }

    return true;
  };

  const canSeeWhoOnPlanQuestion = () => {
    const currentQ = qualifiedDemographicMetrics[metricIndex];
    if (currentQ.title === 'WHO ON PLAN') {
      const howManyLinesName = find(
        qualifiedDemographicMetrics, (item) => item.title === 'HOW MANY LINES'
      ).name;

      const answerIsOneLine = !!find(
        result.answers,
        (item) => {
          if (typeof item === 'string') {
            return item === `${howManyLinesName}_1`;
          }
          return false;
        }
      );
      if (answerIsOneLine) {
        result.answers.push(`${currentQ.name}_1_1`);
        return false;
      }
    }

    return true;
  };

  const updateAnswersForWhoOnPlanQuestion = () => {
    const currentQ = qualifiedDemographicMetrics[metricIndex];
    if (currentQ.title === 'WHO ON PLAN') {
      const linesQuestion = find(
        qualifiedDemographicMetrics,
        (item) => item.title === 'HOW MANY LINES'
      ) || {};
      const answer = findByName(result.answers, linesQuestion.name);
      if (answer) {
        const linesN = parseInt(answer.split('_')[1]);
        currentQ.detailed_answers = cloneDeep(currentQ.answers);
        for (let i = linesN + 1; i <= currentQ.answers.length; i++) {
          const answerLine = find(
            currentQ.detailed_answers,
            (item) => item.id === `${currentQ.name}_${i}`
          );
          remove(currentQ.detailed_answers, (item) => item === answerLine);
        }
      }
    }
  };

  const updateLogicForWhoOnPlanQuestion = () => {
    const currentQ = qualifiedDemographicMetrics[metricIndex];
    if (currentQ.title === 'WHO ON PLAN') {
      // Myself [must be chosen one time across all lines]
      // Spouse/partner [can only be chosen once across all lines]
      // Child(ren) under 5 [cannot be chosen as line 1]
      // Child(ren) 5-10 [cannot be chosen as line 1]
      // Child(ren) 11-17 [cannot be chosen as line 1]
      currentQ.validationObject = { showWrapper: true };

      const rowId = `${currentQ.name}_1`;
      currentQ.disabledItems = { '3': rowId, '4': rowId, '5': rowId };
      currentQ.disabledCallback = (newHash) => (
        !includes(values(newHash || {}), '1')
      );
      currentQ.onSelectAnswer = (newHash, addedHash) => {
        const possibleAnswers = map(
          [ ...keys(Array(10)) ],
          (item) => (`${currentQ.name}_${parseInt(item) + 1}`)
        );
        const newKey = keys(addedHash)[0];
        if (includes(possibleAnswers, newKey) && includes([ '1', '2' ], addedHash[newKey])) {
          each(possibleAnswers, (item) => {
            if (item !== newKey && newHash[item] === addedHash[newKey]) {
              delete newHash[item];
            }
          });
        }

        // Validation Show
        const maxAnswersCount = currentQ.detailed_answers.length;
        const currentAnswersCount = keys(newHash).length;
        if (maxAnswersCount === currentAnswersCount && currentQ.disabledCallback(newHash)) {
          currentQ.validationObject.message = translations.who_on_plan_validation_message;
        } else {
          delete currentQ.validationObject.message;
        }
      };
    }
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  useLayoutEffect(() => {
    if (metricIndex >= 0) {
      if (canSeeWhoOnPlanQuestion()) {
        updateAnswersForWhoOnPlanQuestion();
        updateLogicForWhoOnPlanQuestion();
      } else {
        nextStep();
      }

      if (canSeeUnlimitedPlanQuestion()) {
        setCurrentQuestions(prepareCurrentQuestions());
      } else {
        nextStep();
      }
    }
  }, [ metricGroupStep ]);

  const nextStep = () => {
    if (metricIndex < qualifiedDemographicMetrics.length - 1) {
      setMetricGroupStep(metricGroupStep + 1);
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  return (
    <>
      {
        !metricGroupStep &&
        <Intro nextStep={ nextStep }>
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.qualified_demo_intro) } />
            </div>
          </>
        </Intro>
      }
      {
        metricIndex >= 0 && !!currentQuestions[0] &&
        <QuestionsGroup
          key={ currentQuestions[0].name }
          group={ currentQuestions }
          nextGroup={ nextStep }
          result={ result }
        />
      }
    </>
  );
};

export default QualifiedDemographics;
