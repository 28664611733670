import React, { useContext, useState, useEffect } from 'react';
import { keys } from 'lodash';
import { AppContext } from '../contexts';
import { checkRequest } from '../requests';
import EditPanelServiceCommon from './panelService/common/editPanelServiceCommon';

const EditPanelService = () => {
  const {
    project, setProject, globalDisabled, setGlobalDisabled,
    setNotificationObject, sendRequest, paths
  } = useContext(AppContext);

  const {
    panel_service_data, production, paused,
    stopped, completes_count, required_nsize,
    prodege_quotas_creation_jid
  } = project;
  const updatePath = paths.panel_service_data_update;
  const checkPath = paths.panel_service_check_bg;
  const successMessage = 'Panel Service Settings have been updated.';
  const failMessage = 'Panel Service Settings have been failed to update.';

  const [ errors, setErrors ] = useState(null);
  const [ panelServiceData, setPanelServiceData ] = useState(panel_service_data);

  const runCheckRequest = () => {
    checkRequest(checkPath, (response) => {
      if (response.data.in_progress) {
        setTimeout(() => {
          runCheckRequest();
        }, 5000
        );
      } else {
        let message, success;
        if (response.data.state === 'completed') {
          setProject(response.data.projects);
          message = successMessage;
          success = true;
        } else if (response.data.state === 'failed') {
          message = failMessage;
          success = false;
        }
        setNotificationObject({ message, success });
        setGlobalDisabled(false);
      }
    });
  };

  const runCheckRequestWithDisabling = () => {
    setGlobalDisabled(true);
    runCheckRequest();
  };

  useEffect(() => {
    if (prodege_quotas_creation_jid) {
      runCheckRequestWithDisabling();
    }
  }, []);

  useEffect(() => {
    setPanelServiceData({ ...project.panel_service_data });
  }, [ project ]);

  const handleUpdate = (event, params, options) => {
    const { setProdegeCreateNew, isCustom } = options;
    const prodegeConfirmationMessage = [
      'Do you really want to setup Prodege project',
      'with the current settings?'
    ].join(' ');
    const customProviderConfirmationMessage = [
      'Do you really want to set Custom Provider with the current settings?'
    ].join(' ');
    const message = isCustom ? customProviderConfirmationMessage : prodegeConfirmationMessage;
    if (window.confirm(message)) {
      sendRequest(updatePath, params,
        (response) => {
          setProject(response.data.projects);
          if (response.data.jid) {
            setProdegeCreateNew(false);
            runCheckRequestWithDisabling();
          } else {
            setNotificationObject({
              message: 'Panel Service Settings have been updated.', success: true
            });
          }
        },
        (response) => {
          const receivedErrors = response.data.error || {};
          setErrors(receivedErrors);
          const firstError = (receivedErrors[keys(receivedErrors)[0]] || [])[0] || 'Something went wrong.';
          setNotificationObject({ message: firstError, success: false });
        }
      );
    }
  };

  return (
    <EditPanelServiceCommon
      panelServiceData={ panelServiceData }
      production={ production }
      completesCount={ completes_count }
      numberOfTargetCompletes={ required_nsize }
      paused={ paused }
      stopped={ stopped }
      onUpdate={ handleUpdate }
      globalDisabled={ globalDisabled }
      inputErrors={ errors }
    />
  );
};

export default EditPanelService;
