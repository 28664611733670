import React, { useContext } from 'react';
import { baseContext } from "../../common/contexts";

const StartButton = ({ nextStep, disabled = false }) => {
  const { translations } = useContext(baseContext);
  const customTranslationText = translations.custom_next ? translations.custom_next : null;
  return (
    <button
      className="button survey-start_button"
      type="button"
      aria-label={ translations.next_button_text }
      disabled={ disabled }
      onClick={ () => { nextStep(); } }
    >
      {
        !customTranslationText &&
        <svg className="svg-next_icon" aria-hidden>
          <use xlinkHref="#svg_long-arrow" />
        </svg>
      }
      { !!customTranslationText && customTranslationText }
    </button>
  );
};

export default StartButton;
