import React, { useState, useContext, useRef } from 'react';
import Header from '../common/header';
import Card from '../common/card';
import QuantPortal from '../common/portal';
import PlanDetailsModal from '../common/planDetailsModal';
import PlanShortDescription from '../common/planShortDescription';
import CardPlanTitle from '../common/cardPlanTitle';
import { PerkList, SelectablePerkList } from '../common/perkList';

import {
  plansIncludesChoose,
  planDetailsShort,
  allPlansInclude,
  planList,
  perkList,
  getLargePlanDescription,
  getShortPlanDescription
} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';

const renderAllIncludeDescription = (item, idx) => {
  const { title, text, showText } = item;
  const [showTextState, setShowTextState] = useState(typeof showText !== 'undefined' ? showText: true);

  const toggleClickHandle = () => {
    setShowTextState(!showTextState);
  };
  return <div key={idx} className="plans-include-details-group">
    <h6 className={`plans-include-details-subtitle toggle-text_title`} onClick={toggleClickHandle}>
      <svg className={ `icon -dropdown toggle-text_dropdown-icon ${showTextState ? '-up': '-down'}` }>
        <use xlinkHref={ `#svg_arrow-down` }/>
      </svg>
      {
        title.split('\n').map((value, idx) => <span key={idx}>{value}</span>)
      }
    </h6>
    {
      showTextState ? text.split('\n').map((value, idx) => <p key={idx} className="plans-include-details-value" dangerouslySetInnerHTML={{__html: value}}/>) : ''
    }
  </div>;
}

const PlansInsideModal = ({ open, handleClose }) => {
  const { title, description } = allPlansInclude;
  return <QuantPortal title={title} open={open} handleClose={handleClose}>
    <div className="plans-include-details-modal">
      {description.map(renderAllIncludeDescription)}
    </div>
  </QuantPortal>;
};

const renderPlans = (includes, idx) => {
  const { text, icon } = includes;
  return <li className="plans-include-details-list-item" key={idx}>
    {
      icon && <svg className={ `icon -${ icon } plans-include-details-list-item-icon` }>
        <use xlinkHref={ `#svg_${ icon }` }/>
      </svg>
    }
    <div className="plans-include-details-list-item-text">{text}</div>
  </li>;
}

const PlansInclude = () => {
  const [open, setOpen] = useState(false);
  const { filledLines } = useContext(LinesDataContext);
  const handleOpen = () => {
    Statistic.updateValue(`lines.${filledLines}.viewDetailsCount`);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <div className="plans-include-details">
    <h6 className="plans-include-details-title">
      All plans include
    </h6>
    <ul className="plans-include-details-list">
      {plansIncludesChoose.map(renderPlans)}
    </ul>
    <div className="plans-include-details-button">
      <button type="button" className="details-link" onClick={handleOpen}>View details</button>
    </div>
    <PlansInsideModal open={open} handleClose={handleClose}/>
  </div>
};

const PlanCard = ({ plan, onSelect, onCustomize, onShowDetails, onSeeAllPerks, isChosen }) => {
  const { id, name, price, description, perkCount } = plan;

  return <Card className={`-choose-plan ${isChosen ? '-active -no-sticky-header' : ''}`}>
    <CardPlanTitle className="-choose-plan" name={name} price={price} tipInTwoLines>
      {!isChosen && (
        <div className="plan-card-title-button">
          {perkCount > 0 ? (
            <button onClick={() => onCustomize(plan)} className="button -bordered -full-width" type="button">Customize this plan</button>
          ) : (
            <button onClick={() => onSelect(plan)} className="button -bordered -full-width" type="button">Choose plan</button>
          )}
        </div>
      )}
      <div className="plan-card-title-perks">
        {perkCount > 0 && (
          <>
            <h6 className="plan-card-title-perks-name">{perkCount} Bonus perk{perkCount > 1 && 's'} of your choice</h6>
            {!isChosen && (
              <button type="button" className="details-link plan-card-title-perks-link" onClick={() => onSeeAllPerks(id)}>See all</button>
            )}
          </>
        )}
      </div>
    </CardPlanTitle>

    <div className="choose-plan-card-body">
      <PlanShortDescription description={description} />
      <div className="details-link-wrapper">
        <button type="button" className="details-link -add" onClick={() => onShowDetails(id)}>See plan details</button>
      </div>
    </div>
  </Card>
};

const renderPlansList = ({ onSelect, onCustomize, onShowDetails, onSeeAllPerks, planCustomId, animationEnded }) => (plan, idx) => {
  const showDetails = (id) => onShowDetails(id, idx);
  const seeAllPerks = (id) => onSeeAllPerks(id, idx);
  return (
    (planCustomId === null || planCustomId === plan.id || !animationEnded) && (
      <li key={plan.id}
          className={`choose-plan-card-item -add ${(planCustomId !== null && planCustomId !== plan.id) ? '-hide':''}`}>
        <PlanCard plan={plan} onSelect={onSelect} onCustomize={onCustomize}
                  onShowDetails={showDetails} onSeeAllPerks={seeAllPerks}
                  isChosen={planCustomId === plan.id} />
      </li>
    )
  );
}

const PlansList = (props) => <ul className="choose-plan-card-list -add">
  { planDetailsShort.map(renderPlansList(props)) }
</ul>;

const ChooseLinePlan = ({ onContinue }) => {
  const { filledLines } = useContext(LinesDataContext);
  const [planId, setPlanId] = useState(null);
  const [planCustomId, setPlanCustomId] = useState(null);
  const planPerkCount = getShortPlanDescription(planCustomId) ? getShortPlanDescription(planCustomId).perkCount : 0;
  const [selectedPerks, setSelectedPerks] = useState(Array(planPerkCount).fill(void 0));

  const [animationEnded, setAnimationEnded] = useState(false);
  const perksListRef = useRef();
  const handeClese = () => { setPlanId(null); };

  const planCustomIndex = planList.findIndex(planListItem => planListItem.id === planCustomId);

  const handleOpenDetails = (id, idx) => {
    if (planCustomId) {
      Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsInCustomizationCount`);
    }
    else {
      Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsCount`);
    }
    setPlanId(id);
  };
  const handleSeeAllPerks = (id, idx) => {
    perksListRef.current.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    });
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PerksListInListCount`);
  };

  const handleSelectPlan = (plan, fromModal) => {
    const { id, name, price } = plan;
    const idx = planList.findIndex(planItem => planItem.id === id);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanName`, name);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPrice`, price);
    if (fromModal) {
      Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsSelected`);
      Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "Popup");
    }
    else{
      Statistic.updateValue(`lines.${filledLines}.plans.${idx}.Selected`);
      Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "List");
    }
    onContinue(plan, []);
  };

  const handleCustomize = (plan, fromModal) => {
    const { id, name, price } = plan;
    Statistic.updateValue(`lines.${filledLines}.selectedPlanName`, name);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPrice`, price);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "List");
    if (fromModal) {
      Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "Popup");
    }
    else{
      Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "List");
    }
    document.querySelector('html').scrollTop = 0;
    setPlanCustomId(id);
    setSelectedPerks(Array(plan.perkCount).fill(void 0));
    const timer = setTimeout(() => {
      setAnimationEnded(true);
      // this time value must be equal $desktop-animation-time
    }, 800);
  };

  const handleSelectPerk = (perk, isNew, perkIdx) => {
    const list = [...selectedPerks];
    const emptyIndex = list.findIndex(el => el === void 0);
    if (isNew) {
      list[emptyIndex] = perk;
      Statistic.updateValue(`lines.${filledLines}.plans.${planCustomIndex}.perks.${perkIdx}.AddedInCustomizationCount`);
      const perkCounter = emptyIndex === 1 ? 'Second' : 'First';
      Statistic.updateValue(`lines.${filledLines}.selectedPlanPerk${perkCounter}Selected`, perk.id);
    }
    else {
      const perkIdx = list.findIndex(el => el === perk);
      list[perkIdx] = void 0;
      Statistic.updateValue(`lines.${filledLines}.plans.${planCustomIndex}.perks.${perkIdx}.RemovedInCustomizationCount`);
      const perkCounter = perkIdx === 1 ? 'Second' : 'First';
      Statistic.updateValue(`lines.${filledLines}.selectedPlanPerk${perkCounter}Selected`, 0);
    }
    setSelectedPerks(list);
  };

  const onDetailsPerksClick = (perkIdx) => {
    Statistic.updateValue(`lines.${filledLines}.plans.${planCustomIndex}.perks.${perkIdx}.DetailsInCustomizationCount`);
  };

  const handleResetPerks = () => {
    setPlanCustomId(null);
    setAnimationEnded(false);
    setSelectedPerks([]);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPerkFirstSelected`, 0);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPerkSecondSelected`, 0);
  };

  const handleDetailsPerksClick = (idx) => {
    Statistic.updateValue(`lines.${filledLines}.perks.${idx}.PlansListDetailsCount`);
  };

  const handleContinue = () => {
    onContinue(getShortPlanDescription(planCustomId), selectedPerks);
    Statistic.updateValue(`lines.${filledLines}.plans.${planCustomIndex}.Selected`);
  };

  return <div className="layout">
    <Header/>
    <h6 className="choose-line-plane-title">Choose a plan for Line {filledLines + 1}.</h6>
    <div className="choose-line-plane content -add-plans">
      <div className="choose-line-plane-bar">
        {planCustomId ? (
          <div className="choose-line-plane-back" onClick={handleResetPerks}>
            <h6 className="plans-include-back-title">
              <svg className={ `icon -dropdown -back`}>
                <use xlinkHref={ `#svg_arrow-down` }/>
              </svg>
              <span>Back</span>
            </h6>
          </div>
        ) : (
          <PlansInclude/>
        )}
      </div>
      <div className="choose-line-plane-wrapper">
        <PlansList onSelect={handleSelectPlan} onCustomize={handleCustomize}
                   onShowDetails={handleOpenDetails} onSeeAllPerks={handleSeeAllPerks} planCustomId={planCustomId} animationEnded={animationEnded} />

        {planId && (
          <PlanDetailsModal
            planId={planId}
            open={!!planId}
            onSelect={handleSelectPlan}
            onCustomize={handleCustomize}
            handleClose={handeClese}
            planList={planList}
            getPlanLong={getLargePlanDescription}
            getPlanShort={getShortPlanDescription}
            filledLines={filledLines}
            buttonSelectName="Choose plan"
          />
        )}


        {planCustomId && <div className="choose-line-plane-choose-perks">
          <h4 className="choose-line-plane-choose-perks-title">Choose your bonus perk:</h4>
          <div className="choose-line-plane-choose-perks-descr">
            <p>
              One free bonus perk included with Get More Unlimited.
            </p>
          </div>

          <SelectablePerkList
            list={perkList}
            cols={2}
            onSelect={handleSelectPerk}
            maxSelectCount={getShortPlanDescription(planCustomId).perkCount}
            selectedList={selectedPerks.filter(Boolean)}
            onDetailsClick={onDetailsPerksClick}
            filledLines={filledLines}
            planIndex={planCustomIndex} />

          <div className="choose-line-plane-choose-perks-button">
            <button
              className="button"
              disabled={selectedPerks.filter(Boolean).length !== getShortPlanDescription(planCustomId).perkCount}
              onClick={() => handleContinue()}>
              Continue
            </button>
          </div>

        </div>}
      </div>

    </div>
    {planCustomId === null && (
      <div className="choose-line-plane content -perks">
        <h6 className="choose-line-section-title">Available bonus perks:</h6>
        <div ref={perksListRef}>
          <PerkList list={perkList} onDetailsClick={handleDetailsPerksClick} cols={3} filledLines={filledLines} />
        </div>
      </div>
    )}

  </div>;
};

export default ChooseLinePlan;