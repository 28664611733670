import React, { useContext } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../common/render_raw_html';
import { LineDataContext, SetContext, PageContext } from '../../common/contexts';
import { Header } from '../../common/header';
import { Subheader } from '../../common/subheader';
import { AllPlansInclude } from '../../common/allPlansIncluded';
import CharacteristicList from '../../common/characteristicList';

const OverviewPlan = ({
  plan, onPlanSelect
}) => {
  const { delayAnimation } = useContext(SetContext);
  const {
    getClickedSelectButton,
    setClickedSelectButton
  } = useContext(PageContext);
  const { setLoading } = useContext(LineDataContext);
  const clickedSelectButton = getClickedSelectButton();

  const clickSelectButton = (event, plan) => {
    setClickedSelectButton(plan.id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setClickedSelectButton(null);
      onPlanSelect(event, plan);
    }, delayAnimation);
  };

  return (
    <div className="overview-list_item -bau">
      {plan.rateLabel && (
        <div className="overview-list_top-label">{plan.rateLabel}</div>
      )}
      <div className="plan-short -sticky">
        <h2 className="plan-short_title ng-binding" { ...renderRawHtml(plan.name) } />
        <div className="plan-short_description" { ...renderRawHtml(plan.description) } />
        <div className="plan-short_pricing">
          <div className="plan-short_pricing-grid">
            <h2 className="plan-short_pricing-value">${ plan.price }</h2>
            <div className="plan-short_pricing-hint">
              <div className="plan-short_pricing-hint-line">/line per month</div>
              <div className="plan-short_pricing-hint-line">{ plan.taxPhrase }</div>
            </div>
          </div>
        </div>
        <button
          className={
            classnames(
              'button -bordered -full-width plan-select-button',
              { '-clicked': clickedSelectButton }
            )
          }
          onClick={ (event) => { clickSelectButton(event, plan); } }
          disabled={ clickedSelectButton && clickedSelectButton !== plan.id }
        >
          Select
        </button>
      </div>
      <div className="plan-features">
        <CharacteristicList
          title={ plan.featuresTitle }
          list={ plan.features }
        />
        {plan.savings?.length > 0 && (
          <CharacteristicList
            title={ plan.savingsTitle }
            list={ plan.savings }
            onCheck
          />
        )}
        <CharacteristicList
          title={ plan.perksTitle }
          list={ plan.perks }
          note={ plan.perksNote }
          overTitle={ plan.id !== 1 ? 'Plan perks' : null }
          specialClassList={ plan.id === 1 ? '-first-col-1' : null }
          boxClassNames="-minus10"
          highlighted={ plan.id !== 1 }
        />
        {
          plan.other?.length > 0 &&
          <CharacteristicList
            list={ plan.other }
          />
        }
      </div>
    </div>
  );
};

const OverviewPage = ({ updatedCurrentLine, onPlanSelect, toCompare }) => {
  const { plans } = useContext(LineDataContext);

  return (
    <div className="content">
      <Header />
      <Subheader currentLine={ updatedCurrentLine } />
      <div className="tabs">
        <div className="tab -active">Overview</div>
        <div className="tab" onClick={ () => {toCompare();} }>Compare</div>
      </div>
      <div className="overview-page -bau">
        <div className="inner-content -base -percent">
          <AllPlansInclude className="-bau" />
          <div className="overview-list">
            {
              map(plans, (plan) => {
                return (
                  <OverviewPlan
                    key={ `overviewPlanItem${plan.id}` }
                    plan={ plan }
                    onPlanSelect={ onPlanSelect }
                  />
                );
              })
            }
          </div>
          <footer className="footer">
            <span className="footer_item">Important plan information</span>
            <span className="footer_item">Important broadband information</span>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
