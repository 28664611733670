import React, { useEffect, useState, Fragment } from 'react';
import { map, remove, keys, each, assign } from 'lodash';
import Tag from './tag.js';
import Http from '../../../../common/http.js';
import { sendMessage } from '../../../../common/messaging.js';

const remoteMessage = () => { sendMessage({ needUpdate: true }); };

const Index = (props) => {

  const tagsLimit = 10;

  const subgroups = props.subgroups;
  const tags = props.tags || [];

  const [data, updateData] = useState({ tags: tags });

  const urls = (kind, id) => {
    const hash = {
      post: `/researcher/concept_confirmits/verizon/reports/${props.concept_id}/settings`,
      put: `/researcher/concept_confirmits/verizon/reports/${props.concept_id}/settings/:id`,
      del: `/researcher/concept_confirmits/verizon/reports/${props.concept_id}/settings/:id`
    };
    return hash[kind].replace(/:id/, id);
  };

  const onDelete = (event, tag) => {
    event.preventDefault();
    Http.del(urls('del', tag.id), {}).then(
      (response) => {
        remove(data.tags, tag);
        updateData({ tags: data.tags });
        remoteMessage();
      },
      (reject) => {
        console.log('Something went wrong');
      }
    );
  };

  const onSave = (event, tag, callback) => {
    if (!tag.id){
      Http.post(urls('post'),{
        title: tag.title, options: tag.options
      }).then(
        (response) => {
          assign(tag, response.data);
          data.tags.push(tag);
          callback && callback();
          updateData({ tags: data.tags });
          remoteMessage();
        },
        (reject) => {
          callback && callback();
          console.log('Something went wrong');
        }
      );
    }
    else {
      Http.put(urls('put', tag.id),{
        title: tag.title, options: tag.options
      }).then(
        (response) => {
          assign(tag, response.data);
          callback && callback();
          updateData({ tags: data.tags });
          remoteMessage();
        },
        (reject) => {
          callback && callback();
          console.log('Something went wrong');
        }
      );
    }
  };

  return (
    <div className="combined-tags">
      <div className="host">
        <div className="tags">
          {
            (
              <ul className="tag-list">
                {
                  map(data.tags, (tag, index) => {
                    return(
                      <Tag
                        key={ `tag${index}` }
                        tag={ tag }
                        onDelete={ onDelete }
                        onSave={ onSave }
                        subgroups={ subgroups }
                      />
                    )
                  })
                }
                {
                  data.tags.length < tagsLimit &&
                  <Tag
                    tag={ null }
                    onDelete={ onDelete }
                    onSave={ onSave }
                    subgroups={ subgroups }
                  />
                }
              </ul>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Index;