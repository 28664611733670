import React from "react";
import BrandInstructors from "../../../components/BrandInstructors";
import Button from "../../../components/Button";
import EquinoxIntro from "../../../components/EquinoxIntro";
import Icon from "../../../components/Icon";
import Layout from "../../../components/Layout";

const FilterButton = () => (
  <div className="equinox_filter">
    <Button>
      <Icon name="filter" className="mr-2" />
      Filter
    </Button>
  </div>
);

const Equinox = () => (
  <Layout FloatButton={<FilterButton />}>
    <EquinoxIntro />
    <BrandInstructors />
  </Layout>
);

export default Equinox;
