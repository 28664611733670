import React, { useState, useContext } from 'react';
import { each, filter, includes, map, pick } from 'lodash';
import PortalModal from "../common/portal";
import TextField from "../common/portal/textField";
import SelectField from "../common/portal/selectField";
import CheckboxField from "../common/portal/checkboxField";
import { baseContext } from "../contexts";
import { updateUser, deleteUser } from './api';

const fieldsCheckBlank = [ "password", "password_confirmation" ];
const fields = [
  "email",
  "first_name",
  "last_name",
  "recollective_link",
  "role",
  "password",
  "password_confirmation",
  "disabled"
];
const hash = {};

const EditUser = ({ user, onClose, onSave, onDelete }) => {
  each(fields, (field) => {
    [ hash[field], hash[`set_${field}`] ] = useState(user[field] == undefined ? "" : user[field]);
    [ hash[`error_${field}`], hash[`set_error_${field}`] ] = useState(null);
  });
  const [ error, setError ] = useState(null);
  const [ deleteInProgress, setDeleteInProgress ] = useState(false);
  const [ saveInProgress, setSaveInProgress ] = useState(false);
  const { getCurrentUser, availableRoles, allRoles, verizonType } = useContext(baseContext);

  const editMyself = (getCurrentUser().id === user.id);

  const clearErrors = () => {
    each(fields, (field) => {
      hash[`set_error_${field}`](null);
    });
    setError(null);
  };

  const handleClose = () => {
    clearErrors();
    onClose();
  };
  const saveCallback = (event) => {
    setSaveInProgress(true);
    const params = pick(hash, fields);
    let checkResult = true;
    each(fieldsCheckBlank, (item) => { checkResult = checkResult && !hash[item]; });
    if (checkResult) { each(fieldsCheckBlank, (item) => { delete params[item]; }); }
    updateUser(user.id, params, (response) => {
      setSaveInProgress(false);
      if (response.response.ok) {
        clearErrors();
        onSave(response.data.user);
      } else {
        setError(response.data.error);
        each(fields, (field) => {
          const fieldErrors = response.data.errors[field];
          if (fieldErrors) { hash[`set_error_${field}`](fieldErrors[0]); } else { hash[`set_error_${field}`](null); }
        });
      }
    });
  };

  const deleteCallback = (event) => {
    if (window.confirm("Do you really want to delete this user?")) {
      setDeleteInProgress(true);
      deleteUser(user.id, () => {
        setDeleteInProgress(false);
        clearErrors();
        onDelete();
      });
    }
  };

  const selectedRolesAvailable = includes(map(availableRoles, (el) => el.name), hash.role);

  const selectedRolesList = (
    selectedRolesAvailable ?
      availableRoles :
      filter(allRoles, (el) => el.name === hash.role)
  );

  const handleSetRole = (value) => {
    hash.set_role(value);
  };

  return (
    <PortalModal
      className="-sm"
      title="Edit User"
      open
      handleClose={ handleClose }
      saveCallback={ saveCallback }
      deleteItem={ deleteCallback }
      showDelete={ !editMyself }
      buttonName={ saveInProgress ? "Updating..." : "Update" }
      disabledSave={ deleteInProgress || saveInProgress }
      labelDelete={ deleteInProgress ? "Deleting..." : "Delete" }
      disabledDelete={ deleteInProgress || saveInProgress }
      error={ error }
    >
      <div className="survey-form">
        <TextField error={ hash.error_email } label="Email" value={ hash.email } onChange={ hash.set_email } />
        <TextField error={ hash.error_first_name } label="First Name" value={ hash.first_name } onChange={ hash.set_first_name } />
        <TextField error={ hash.error_last_name } label="Last Name" value={ hash.last_name } onChange={ hash.set_last_name } />
        { verizonType === 'verizon5g' && <TextField error={ hash.error_recollective_link } label="Recollective Link" value={ hash.recollective_link } onChange={ hash.set_recollective_link } /> }
        <SelectField
          error={ hash.error_role }
          label="Role"
          value={ hash.role }
          options={ selectedRolesList }
          onChange={ handleSetRole }
          placeholder="Select role"
          disabled={ !selectedRolesAvailable }
        />
        <TextField error={ hash.error_password } label="New Password" value={ hash.password } onChange={ hash.set_password } />
        <TextField error={ hash.error_password_confirmation } label="New Password Confirmation" value={ hash.password_confirmation } onChange={ hash.set_password_confirmation } />
        <CheckboxField error={ hash.error_disabled } label="Suspended" checked={ hash.disabled } onChange={ hash.set_disabled } />
      </div>
    </PortalModal>
  );
};

export default EditUser;
