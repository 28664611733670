import React, { useContext } from 'react';
import { PanelServiceContext } from '../../contexts';

const FooterButtons = ({
  onUpdate, onCancel
}) => {
  const { inputsDisabled, anyChanges } = useContext(PanelServiceContext);
  const handleUpdate = (event) => {
    onUpdate(event);
  };

  const handleCancel = (event) => {
    onCancel(event);
  };

  return (
    <footer className="form-footer">
      <div className="update-buttons -no-margin">
        <button
          disabled={ !anyChanges() || inputsDisabled() }
          className="button survey-flow-button"
          onClick={ handleUpdate }
        >
          Update Settings
        </button>
        <button
          disabled={ !anyChanges() || inputsDisabled() }
          className="button -secondary -with-border survey-flow-button"
          onClick={ handleCancel }
        >
          Cancel
        </button>
      </div>
    </footer>
  );
};

export default FooterButtons;
