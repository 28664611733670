import React, { Fragment } from 'react';
import { isEmpty, map } from "lodash";
import { filteredQuestionsForFields } from '../../common/custom_questions/helper';
import OpenEndHiddenFields from '../../common/custom_questions/components/openEnd/openEndHiddenFields';

const HiddenFields = ({ options, cpf, cai, bpa, cpc, singles, bpu, ps, model, customQuestions }) => {
  const fixedBpa = { ...bpa, enabled: cai.enabled };
  const filteredCustomQuestions = filteredQuestionsForFields(customQuestions);

  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ '1' } />
      <input type="hidden" name="project[tb_enabled]" value={ model.tb.toString() } />
      <input type="hidden" name="project[preference_reasons]" value={ model.reasons.toString() } />

      <CommonMetricFields metric={ cpf } />
      <input type="hidden" name="project[express_metrics][][custom]" value={ cpf.custom } />
      {map(cpf.grid_items, (item, index) => (
        <CommonGridItemFields key={ index } item={ item } index={ index } />
      ))}

      <CommonMetricFields metric={ cai } />
      {map(cai.grid_items, (item, index) => (
        <CommonGridItemFields key={ index } item={ item } index={ index } />
      ))}
      <CommonMetricFields metric={ fixedBpa } />

      <CommonMetricFields metric={ cpc } />
      <input type="hidden" name="project[express_metrics][][other_answer]" value={ cpc.other_answer.toString() } />
      <input type="hidden" name="project[express_metrics][][other_answer_text]" value={ cpc.other_answer_text || '' } />
      {map(cpc.grid_items, (item, index) => (
        <CommonGridItemFields key={ index } item={ item } index={ index } />
      ))}

      {map(singles, (bx, index) => (
        <CommonMetricFields key={ index } metric={ bx } />
      ))}

      <CommonMetricFields metric={ bpu } />
      <input type="hidden" name="project[express_metrics][][other_answer]" value={ bpu.other_answer || 'false' } />
      <input type="hidden" name="project[express_metrics][][other_answer_text]" value={ bpu.other_answer_text || '' } />

      {map(bpu.grid_items, (item, index) => (
        <CommonGridItemFields key={ index } item={ item } index={ index } />
      ))}
      <CommonMetricFields metric={ ps } />

      {map(filteredCustomQuestions, (question, qi) => (
        <Fragment key={ qi }>
          <input type="hidden" name="project[custom_metrics][][id]" value={ question.id } />
          <input type="hidden" name="project[custom_metrics][][kind]" value={ question.kind } />
          <input type="hidden" name="project[custom_metrics][][text]" value={ question.text } />
          <input type="hidden" name="project[custom_metrics][][answers_order]" value={ question.answers_order || '' } />
          <input type="hidden" name="project[custom_metrics][][none_answer]" value={ question.kind === "Grid" ? 'false' : question.none_answer || 'false' } />
          <input type="hidden" name="project[custom_metrics][][none_answer_text]" value={ question.kind === "Grid" ? '' : question.none_answer_text || '' } />
          <input type="hidden" name="project[custom_metrics][][other_answer]" value={ question.kind === "Grid" ? 'false' : question.other_answer || 'false' } />
          <input type="hidden" name="project[custom_metrics][][other_answer_text]" value={ question.kind === "Grid" ? '' : question.other_answer_text || '' } />
          <input type="hidden" name="project[custom_metrics][][target_group]" value={ question.target_group || '' } />
          <input type="hidden" name="project[custom_metrics][][survey_location]" value={ question.survey_location || '' } />
          <input type="hidden" name="project[custom_metrics][][_destroy]" value={ question._destroy || '' } />
          <input type="hidden" name="project[custom_metrics][][as_point_scale]" value={ question.as_point_scale } />
          <input type="hidden" name="project[custom_metrics][][position]" value={ `${question.position}` || '' } />
          {
            question.answers.map((answer, ai) => (
              <input key={ ai } type="hidden" name="project[custom_metrics][][answers][]" value={ answer || '' } />
            ))
          }
          { question.kind === "Grid" &&  <GridItemFields question={ question } /> }
          <ImagesHiddenFields question={ question } images={ question.answer_images } />
          <OpenEndHiddenFields question={ question } relation="custom_metrics" />
        </Fragment>
      ))}
    </>
  );
};

const CommonMetricFields = ({ metric }) => {
  return (
    <>
      <input type="hidden" name="project[express_metrics][][id]" value={ metric.id || '' } />
      <input type="hidden" name="project[express_metrics][][kind]" value={ metric.kind } />
      <input type="hidden" name="project[express_metrics][][enabled]" value={ metric.enabled.toString() } />
      <input type="hidden" name="project[express_metrics][][default_metric_id]" value={ metric.default_metric_id } />
    </>
  );
};

const GridItemFields = ({ question }) => (
  <>
    {
      map(question.scales, (item, index) => (
        <input
          key={ `grid_item${index}` }
          type="hidden"
          name={ `project[custom_metrics][][scales][${index}]` }
          value={ item || '' }
        />
      ))
    }
  </>
);

const CommonGridItemFields = ({ item, index }) => {
  return (
    <Fragment key={ index }>
      <input type="hidden" name={ `project[express_metrics][][grid_items][][id]` } value={ item.id || '' } />
      <input type="hidden" name={ `project[express_metrics][][grid_items][][enabled]` } value={ item.enabled.toString() } />
      <input type="hidden" name={ `project[express_metrics][][grid_items][][editable]` } value={ item.editable.toString() } />
      <input type="hidden" name={ `project[express_metrics][][grid_items][][question]` } value={ item.question || '' } />
      <input type="hidden" name={ `project[express_metrics][][grid_items][][position]` } value={ index } />
      <input type="hidden" name={ `project[express_metrics][][grid_items][][_destroy]` } value={ item._destroy || '' } />
    </Fragment>
  );
};

const ImagesHiddenFields = ({ question, images }) => {
  if (isEmpty(images) && images != null) {
    return (
      <input type="hidden" name={ `project[custom_metrics][][answer_images]` } value="" />
    );
  }

  return (
    <>
      {
        map(images, (image_data, key) => (
          <input
            key={ `image${key}` }
            type="hidden"
            name={ `project[custom_metrics][][answer_images][${key}]` }
            value={ !question.as_point_scale ? image_data?.meta_data : '' }
          />
        ))
      }
    </>
  );
};

export default HiddenFields;
