import React, { Fragment } from 'react';
import { map } from "lodash";
import { Header } from "../common/header";
import { Subheader } from "../common/subheader";
import { increaseVariableForCurrentLine } from '../common/statistics';

const Fork = ({ getForkList, onPrebuildPlansSelect, onOwnPlanSelect  }) => {
  const prebuildPlansSelect = () => {
    increaseVariableForCurrentLine('forkSelectPopular');
    onPrebuildPlansSelect();
  };
  const ownPlanSelect = () => {
    increaseVariableForCurrentLine('forkSelectBuildOwn');
    onOwnPlanSelect();
  };
  const forkList = getForkList(prebuildPlansSelect, ownPlanSelect);

  return (
    <div className="wrapper">
      <Header />
      <Subheader />
      <div className="content">
        <div className="container">
          <div className="fork">
            <h2 className="fork_title content_title">
              Get exactly what you want.
            </h2>
            <ul className="fork_grid">
              {
                map(forkList, (fork, key) => (
                  <Fragment key={ `fork-${key}` }>
                    <li className="fork_grid-item">
                      <div className="plate -black -button">
                        <div className="fork_item">
                          <h3 className="fork_item-title">{fork.title}</h3>
                          <div className="fork_item-descr">{fork.description}</div>
                          <div className="fork_item-button">
                            <button
                              className="plate_button-full"
                              onClick={ () => fork.clickStart() }
                            >
                              <svg className={ `icon -arrow plate_button-full-icon` }>
                                <use xlinkHref={ `#svg_arrow` } />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </Fragment>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fork;
