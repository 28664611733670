import Http from '../../../common/http';

const sendApplyChanges = (
  { userId, email, visibilityGroups, sortingField, sortingOrder },
  goodCallback, badCallback) => {
  Http.post(
    `/verizon_customer_case_v2024/api/users/visibility_groups`,
    {
      user_id: userId,
      email,
      visibility_groups: visibilityGroups,
      sorting_field: sortingField,
      sorting_order: sortingOrder
    }
  ).then(
    (http) => {
      if (http.response.ok) {
        if (http.data.errors) {
          badCallback && badCallback(http);
        } else {
          goodCallback && goodCallback(http);
        }
      }
    },
    (reject) => {
      badCallback && badCallback(reject);
    });
};

const sendListUsers = ({ sortingField, sortingOrder }, goodCallback, badCallback) => {
  Http.get(
    '/verizon_customer_case_v2024/api/users',
    { sorting_field: sortingField, sorting_order: sortingOrder }
  ).then(
    (http) => {
      if (http.response.ok) {
        goodCallback && goodCallback(http);
      } else {
        badCallback && badCallback(http);
      }
    },
    (reject) => {
      badCallback && badCallback(reject);
    }
  );
};

export { sendApplyChanges, sendListUsers };
