import React from 'react';
import { map } from 'lodash';
import { determineValue } from "../helpers";

const ValuesList = ({ data, total }) => {
  if (!data) {
    return null;
  }

  return (
    <div className="percent-report">
      <ul className="percents-list">
        {
          map(data.answers, (item, index) => (
            <li key={ `percent-list-${index}` }>
              <div className="item-list-left-side" >{ item.text }</div>
              <div className="item-list-right-side">{ determineValue(item, total) }</div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default ValuesList;
