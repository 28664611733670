import React from "react";
import Screening from '../../common/components/screening';
import AttributeRatings from "../../common/components/metrics/attribute_ratings";
import renderRawHtml from "../../../../common/render_raw_html";

const CategoryQuestions = ({ question, nextStep, result, languageCode }) => {
  if (question.default_name === "category_attribute_importance") {
    return (
      <AttributeRatings
        concept={ { confirmit_concept_id: '1' } }
        questions={ [ question ] }
        result={ result }
        nextStep={ nextStep }
        languageCode={ languageCode }
      >
        <div className="survey-header">
          <div className="survey-header_title">
            <div className="survey-options_question-title" { ...renderRawHtml(question.text) } />
          </div>
        </div>
      </AttributeRatings>
    );
  }

  return (
    <Screening
      question={ question }
      nextStep={ nextStep }
      result={ result.loops[0] }
    />
  );
};

export default CategoryQuestions;
