import Http from "../../../common/http";

const loadPosts = (mediaKind, verizonType, page, callback) => {
  Http.get(
    `/verizon/whiteboard.json?page=${page}&media_kind=${mediaKind}&verizon_type=${verizonType}`
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const addPost = (payload, callback) => {
  Http.post(
    '/verizon/whiteboard.json',
    { post: payload }
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { loadPosts, addPost };
