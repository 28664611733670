import React from 'react';
import DesktopBAU from './bau/desktop';
import MobileBAU from './bau/mobile';
import ConceptA from './concept_a';
import ConceptB from './concept_b';
import ConceptC from './concept_c';
import ConceptD from './concept_d';
import ConceptE from './concept_e';
import ConceptAUX from './concept_a_ux';
import { SetContext } from './common/contexts';

// conceptName = 'BAU' | 'CELL1' | 'CELL2' | 'CELL3' | 'CELL4' | 'CELL5' | 'CELL1UX'
const App = ({ conceptName, mobile }) => {
  if (mobile) {
    switch (conceptName) {
      case 'BAU': return <MobileBAU />;
      case 'CELL1': return <ConceptA />;
      case 'CELL2': return <ConceptB />;
      case 'CELL3': return <ConceptC />;
      case 'CELL4': return <ConceptD />;
      case 'CELL5': return <ConceptE />;
      case 'CELL1UX': return <ConceptAUX />;
      default: return null;
    }
  } else {
    switch (conceptName) {
      case 'BAU': return <DesktopBAU />;
      case 'CELL1': return <ConceptA />;
      case 'CELL2': return <ConceptB />;
      case 'CELL3': return <ConceptC />;
      case 'CELL4': return <ConceptD />;
      case 'CELL5': return <ConceptE />;
      default: return null;
    }
  }
};

const AppProvider = (params) => {
  const contextValue = Object.freeze({
    delayAnimation: 500,
    mobile: params.mobile,
    buildYourOwnFirst: params.buildFirst,
    taskId: params.taskId,
    successUrl: params.successUrl,
    failUrl: params.failUrl
  });

  return (
    <SetContext.Provider value={ contextValue }>
      <App { ...params } />
    </SetContext.Provider>
  );
};

export default AppProvider;
