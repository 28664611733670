import React, { useContext } from 'react';
import { FrameWrapper } from '../../../common/components/frame_wrapper';
import TextField from '../common/portal/textField';
import Wysiwyg from '../../../common/components/wysiwyg';
import UploadButton from '../common/uploadButton';
import { baseContext } from '../contexts';
import { uploadFromPdf, iFramePreviewUrl } from './api';

const ArticleEdit = ({
  type,
  title,
  content,
  setTopic,
  setContent,
  kind,
  editMode,
  setEditMode,
  uploads,
  setUploads,
  uploadingInProgress,
  setUploadingInProgress
}) => {
  const { mediaKind } = useContext(baseContext);

  const handleChangeItemTitle = (event, index) => {
    const array = [ ...content ];
    array[index].title = event.target.value;
    setContent(array);
  };

  const handleChangeItemHtml = (html, index) => {
    const array = [ ...content ];
    array[index].html = html;
    setContent(array);
  };

  const handleDelete = (index) => {
    const array = [ ...content ];
    array[index]._destroy = '1';
    setContent(array);
  };

  const handleAdd = () => {
    const newItem = {
      title: '',
      html: '',
      kind
    };
    const array = [ ...content ];
    array.push(newItem);
    setContent(array);
  };

  const toggleMode = () => {
    const mode = editMode === 'manual' ? 'upload' : 'manual';
    setEditMode(mode);
  };

  const handleUpload = (file) => {
    const payload = {
      aws_filepath: file.awsKey,
      media_kind: mediaKind
    };
    uploadFromPdf(payload, () => {
      console.log('File uploaded');
    });
  };

  const handleUploadInit = () => {
    setUploadingInProgress(true);
    setUploads({});
  };

  return (
    <div className="article-edit">
      <div className="tabs">
        <ul className="tabs_list">
          <li className="tabs_item">
            <button
              className={ `tabs_button ${editMode === 'upload' ? '-active' : ''}` }
              onClick={ () => toggleMode() }
              type="button"
            >
              Upload pdf
            </button>
          </li>
          <li className="tabs_item">
            <button
              className={ `tabs_button ${editMode === 'manual' ? '-active' : ''}` }
              onClick={ () => toggleMode() }
              type="button"
            >
              Manual editing
            </button>
          </li>
        </ul>
      </div>
      <TextField label="Topic" value={ title } onChange={ setTopic } />

      {editMode === 'manual' && (
        <div className="form-group">
          <div className="form-group_label">Items</div>
          <div className="form-group_control">
            {type === 'list' && content && (
              <>
                <ul className="article-edit_list">
                  {content.map((value, index) => {
                    if (value._destroy) {
                      return null;
                    }
                    return (
                      <li className="article-edit_list-item" key={ `li-${index}` }>
                        <div className="article-edit_list-item-grid">
                          <div className="article-edit_list-item-col -control">
                            <div className="article-edit_list-item-control">
                              <textarea
                                className="form-control -light"
                                onChange={ (event) => {
                                  handleChangeItemHtml(event.target.value, index);
                                } }
                                value={ value.html }
                              />
                            </div>
                          </div>
                          <div className="article-edit_list-item-col -button">
                            <button
                              className="button -sm -upper"
                              onClick={ () => handleDelete(index) }
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="article-edit_list-button">
                  <button className="button -sm -upper" onClick={ handleAdd } type="button">
                    Add
                  </button>
                </div>
              </>
            )}
            {type === 'grid' && content && (
              <>
                <ul className="article-edit_list">
                  {content.map((value, index) => {
                    if (value._destroy) {
                      return null;
                    }
                    return (
                      <li className="article-edit_list-item -bordered" key={ `li-${index}` }>
                        <div className="article-edit_list-item-grid">
                          <div className="article-edit_list-item-col -control">
                            <div className="article-edit_list-item-control">
                              <input
                                type="text"
                                className="form-control -light"
                                onChange={ (event) => {
                                  handleChangeItemTitle(event, index);
                                } }
                                value={ value.title }
                                placeholder="Enter item title"
                              />
                            </div>
                            <div className="article-edit_list-item-control">
                              <Wysiwyg
                                editorClassName="middle-height"
                                placeholder="Enter item description"
                                value={ value.html }
                                onChange={ (html) => handleChangeItemHtml(html, index) }
                                enableAlign
                              />
                            </div>
                          </div>
                          <div className="article-edit_list-item-col -button">
                            <button
                              className="button -sm -upper"
                              onClick={ () => handleDelete(index) }
                              type="button"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="article-edit_list-button">
                  <button className="button -sm -upper" onClick={ handleAdd } type="button">
                    Add
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {editMode === 'upload' && (
        <>
          <div className="form-group">
            <div className="form-group_label -upper">PDF file</div>
            <div className="form-group_control -button">
              <UploadButton
                className={ `button ${uploadingInProgress ? 'hide' : '-sm -upper'}` }
                needPreview={ false }
                allowedTypes={ [ '.pdf' ] }
                filenameOnBottom={ false }
                multiple={ false }
                onUploaded={ (files) => handleUpload(files[0]) }
                onInit={ handleUploadInit }
              >
                Upload pdf
              </UploadButton>
              {uploadingInProgress && (
                <button className="button -sm -upper loader -loading-blink" disabled="disabled">
                  Processing...
                </button>
              )}
            </div>
          </div>
          {uploads.html_upload && (
            <div className="form-group">
              <div className="form-group_label -upper">Preview</div>
              <FrameWrapper
                src={ iFramePreviewUrl(uploads.html_upload.url, uploads.base_css_upload.url, uploads.css_upload.url) }
                loadCallback={ () => setUploadingInProgress(false) }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ArticleEdit;
