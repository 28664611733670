import React, { useContext } from 'react';
import { isEmpty, filter, map } from "lodash";
import { IndexData, selectGroup } from "../../../common/index-context";
import Th from '../../../common/components/concepts-table/th';
import TrNAvg from '../../../common/components/concepts-table/tr-n-avg';
import TrFivePointScales from '../../../common/components/concepts-table/tr-five-point-scales';
import TrAttributeRatings from "../../../common/components/concepts-table/tr-attribute-ratings";
import TrPreference from "../../../common/components/concepts-table/tr-preference";
import AverageModeSelector from "../../../common/components/mode_selector";
import TrShelfWrapper from '../../../common/components/concepts-table/tr-shelf-wrapper';
import TrCustomMetrics from '../../../common/components/concepts-table/tr-custom-metrics';
import TrLeftRight from "./tr-left-right";

const ConceptsTable = () => {
  const { data, concepts } = useContext(IndexData);
  const checkedConcepts = filter(concepts, (el) => el.checked);
  const alphabet = data.alphabet;

  const fivePointScales = selectGroup(data, 'five_point_scale');
  const customMetrics = selectGroup(data, 'custom_metric');
  const attributeRatings = selectGroup(data, 'package_mc_attribute_ratings');
  const preference = selectGroup(data, 'preference');

  const shelfPresent = data.shelf_enabled || data.cart_exercise;

  return (
    <table className="table table-comparison -stick-header -stick-average -stick-first-column">
      <thead>
        <tr>
          <th className="table-comparison_name-cell">
            Package
          </th>
          {
            map(checkedConcepts, (concept, i) => (
              <Th
                key={ i }
                concept={ concept }
                alphabet={ alphabet }
                index={ i }
              />
            ))
          }
          <th className="table-comparison_average-cell -concept-list -word-wrap">
            <AverageModeSelector showLabel />
          </th>
        </tr>
      </thead>

      <tbody>
        <TrNAvg data={ data } checkedConcepts={ checkedConcepts } />

        {
          !isEmpty(fivePointScales) &&
          <TrFivePointScales
            data={ data }
            checkedConcepts={ checkedConcepts }
            fivePointScales={ fivePointScales }
          />
        }
        {
          !isEmpty(customMetrics) &&
          <TrCustomMetrics
            data={ data }
            checkedConcepts={ checkedConcepts }
            customMetrics={ customMetrics }
          />
        }

        { data.left_right && <TrLeftRight groupName="package_attributes" />}

        {
          !isEmpty(attributeRatings) &&
          <TrAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ attributeRatings }
            groupName="package_attribute_ratings"
          />
        }
        {
          !isEmpty(preference) &&
          <TrPreference
            data={ data }
            checkedConcepts={ checkedConcepts }
            preference={ preference }
          />
        }
        {
          shelfPresent && <TrShelfWrapper data={ data } checkedConcepts={ checkedConcepts } />
        }
      </tbody>
    </table>
  );
};

export default ConceptsTable;
