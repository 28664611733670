import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { WithIndexContext } from '../common/index-context';
import AppInner from "./app-inner";

const App = (init) => {
  return (
    <BrowserRouter>
      <WithIndexContext
        init={ init }
        userGetConceptId={
          (current) => (current.open_end_card.data.concept_id)
        }
      >
        <AppInner />
      </WithIndexContext>
    </BrowserRouter>
  );
};

export default App;
