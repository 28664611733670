import { map } from 'lodash';
import { plans as commonPlans, allPlansInclude, pricing } from "../unlimited_common/plans_data";
import { getLinesCount } from "../unlimited_common/statistics";

const plans = map(commonPlans, (commonPlan) => {
  const plan = {
    ...commonPlan,
    get price() { return pricing[plan.id - 1][getLinesCount() - 1]; }
  };
  // for all except Start Unlimited
  if (plan.id != 1)
    plan.special = "1 month free on us across all streaming video subscription services within the Verizon Entertainment platform anytime.<br/>This offer is valid at the account level.";
  return plan;
});

const popupCopy = "<p>Imagine a new platform created by " +
  "<span class='-bold'>Verizon</span> that lets you " +
  "<span class='-bold'>purchase and manage subscriptions</span> " +
  "for all of your paid online entertainment services " +
  "<span class='-bold'>through a single website</span>.</p> " +

  "<p>The Verizon platform offers subscriptions for a " +
  "<span class='-bold'>wide variety</span> of paid online entertainment services, including:</p> " +
  "<ul> " +
  "<li><span class='-bold'>Paid online entertainment video services</span> (e.g. Netflix, Paramount+)</li> " +
  "<li><span class='-bold'>Paid online gaming services</span> (e.g. Xbox Game Pass, PlayStation Plus)</li> " +
  "<li><span class='-bold'>Paid online wellness services</span> (e.g. Peloton, Calm)</li> " +
  "</ul> " +

  "<p>Through the Verizon platform, you can easily " +
  "<span class='-bold'>purchase new subscriptions</span> " +
  "and <span class='-bold'>re-subscribe to services you previously subscribed to</span>.</p> " +

  "<p>By subscribing through the Verizon platform, you will be able to access " +
  "<span class='-bold'>special limited time promotions / discounts</span> " +
  "(e.g. 20% off of the monthly subscription price for 6 months). In addition, using the Verizon platform will allow you to seamlessly:</p> " +
  "<ul> " +
  "<li><span class='-bold'>Pay for all service subscriptions through a unified bill</span></li> " +
  "<li><span class='-bold'>Track and manage</span> " +
  "(e.g. make account changes, such as upgrades, cancellations, etc.) " +
  "<span class='-bold'>all service accounts in one place</span>, " +
  "with the ability to control who on your account can make changes or purchases</li> " +
  "<li><span class='-bold'>Discover</span> where to watch the latest releases or your favorite shows, " +
  "using our comprehensive search function</li> " +
  "</ul> "+

  "<p>Additionally, Verizon Wireless subscribers on qualifying unlimited plans " +
  "will be entitled to <span class='-bold'>exclusive benefits</span> " +
  "within the platform (e.g. 1 month free on us across all streaming video subscription services anytime)." +
  "</p>";

export { plans, allPlansInclude, popupCopy };