import React from 'react';

const Section4 = () => {
  return (
    <section className="examples">
      <div className="container">
        <div className="examples_title">
          <h3>Test Anything – Ideal for a Wide Range of Stimuli</h3>
        </div>
        <ul className="examples_list">
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-1">
                <svg className="icon -services">
                  <use xlinkHref="#svg_services" />
                </svg>
              </div>
              <div className="examples_item-text">
                Products & Services
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-2">
                <svg className="icon -logos">
                  <use xlinkHref="#svg_logos" />
                </svg>
              </div>
              <div className="examples_item-text">
                Logos
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-3">
                <svg className="icon -benefits">
                  <use xlinkHref="#svg_benefits" />
                </svg>
              </div>
              <div className="examples_item-text">
                Features & Benefits
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-4">
                <svg className="icon -package">
                  <use xlinkHref="#svg_package" />
                </svg>
              </div>
              <div className="examples_item-text">
                Packaging
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-3">
                <svg className="icon -images">
                  <use xlinkHref="#svg_images" />
                </svg>
              </div>
              <div className="examples_item-text">
                Ads & Images
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-4">
                <svg className="icon -ux">
                  <use xlinkHref="#svg_ux" />
                </svg>
              </div>
              <div className="examples_item-text">
                User experience
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-2">
                <svg className="icon -tag">
                  <use xlinkHref="#svg_tag" />
                </svg>
              </div>
              <div className="examples_item-text">
                Names
              </div>
            </div>
          </li>
          <li className="examples_list-item">
            <div className="examples_item">
              <div className="examples_item-icon circle-icon -color-1">
                <svg className="icon -claims">
                  <use xlinkHref="#svg_claims" />
                </svg>
              </div>
              <div className="examples_item-text">
                Claims
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Section4;
