import React, { useContext } from 'react';
import ChoosePlan from './choosePlan';
import Landing from './landing';
import About from './about';
import { RouteContext, DataContext } from './contexts';
import { sendData } from "./statistic";
import { scrollTop } from "./helpers";

const Route = ({ path, isDefault, children }) => {
  const { path: currentPath } = useContext(RouteContext);
  if (isDefault && !currentPath) {return children;}
  return path === currentPath ? children : null;
};

const Routes = ({ conceptName, startTimeApp, onDone, btnPlansAvailable }) => {
  const { setPath } = useContext(RouteContext);

  return (
    <DataContext.Provider value={ { conceptName, startTimeApp, btnPlansAvailable  } }>
      <Route path="/" isDefault>
        <Landing
          conceptName={ conceptName }
          onContinue={ () => {
            setPath('/choose-plan');
            scrollTop();
          } }
        />
      </Route>

      <Route path="/choose-plan">
        <ChoosePlan
          onContinue={ () => {
            sendData();
            onDone();
          } }
        />
      </Route>

      <Route path="/about">
        <About
          onContinue={ () => {
            setPath('/choose-plan');
            scrollTop();
          } }
        />
      </Route>

    </DataContext.Provider>
  );
};

export default Routes;
