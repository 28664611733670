import {
  initExpandStatisticsPlans, updateExpandStatisticsPlans,
  initExpandStatisticsPerks, updateExpandStatisticsPerks
} from './desktop/common/expandStatistics';

const perkReducer = (perks) => perks.reduce((dict, details) => {
  const perk = Object
    .keys(details)
    .filter(key => key !== 'id')
    .reduce((dict, key) => ({
      ...dict,
      [`perk${details.id}${key}`]: details[key]
    }), Object.create(null));
  return { ...dict, ...perk };
}, Object.create(null));

class LandingDetailsCounter {
  constructor(id) {
    this.id = id
    // Number of times, see perk details link is clicked for m perk on landing page
    this.LandingDetailsCount = 0;

    // (Desktop only) number of times, m perk was clicked in the details popup on landing page
    this.LandingDetailsCountInPopup = 0;

    initExpandStatisticsPerks(this, true);
  }
}

class PlansListDetailsCounter {
  constructor(id) {
    this.id = id
    // Number of times, see perk details link is clicked for m perk on landing page
    // Number of times, see perk details link is clicked for m perk on plans list page
    this.PlansListDetailsCount = 0;

    // (Desktop only) number of times, m perk was clicked in the details popup on landing page
    // (Desktop only) number of times, m perk was clicked in the details popup on plans list page
    this.PlansListDetailsCountInPopup = 0;
    initExpandStatisticsPerks(this, false);
  }
}

class PerkDetails {
  constructor(id) {
    this.id = id;
    // perk details were clicked
    this.DetailsInCustomizationCount = 0;
    // perk was added
    this.AddedInCustomizationCount = 0;
    // perk was removed
    this.RemovedInCustomizationCount = 0;
  }
}
class PlanCounter {
  constructor(id, perkIds) {
    this.id = id;
    // number of times, "See plan details" was clicked for plan n.
    this.PlanDetailsCount = 0;
    // number of times, plan n was selected in plan details (for desktop only).
    this.PlanDetailsSelected = 0;
    // number of times, plan n details tab was clicked in popup (for desktop only).
    this.PlanDetailsClickedInPopup = 0;
    // number of times "Choose plan" was clicked on plans list
    this.Selected = 0;
    // plan n - number of times, view perks list is clicked in plans list
    this.PerksListInListCount = 0;
    // (Mobile only) plan n - number of times, plan features link was clicked in plan customization.
    this.FeaturesInCustomizationCount = 0;
    // plan n - number of times, "See plan details" was clicked in plan customization.
    this.PlanDetailsInCustomizationCount = 0;

    this.perks = perkIds.map(id => new PerkDetails(id));
    initExpandStatisticsPlans(this);
  }

  align() {
    const perksStatistic = {};
    const { perks, ...rest } = this;

    perks.map((perk) =>
      Object.keys(perk)
            .reduce((counter, key) => {
              if (key === 'id') return counter;
              return { ...counter, [`Perk${perk.id}${key}`]: perk[key] }
            },
            Object.create(null)))
        .forEach(el => Object.assign(perksStatistic, el));

    return {
      ...rest,
      ...perksStatistic
    };
  }
}

class LineDetails {
  constructor(plans, perkIds) {
    // number of times, "View details" link is clicked for this line.
    this.viewDetailsCount = 0;
    // number of times, plan was re-selected for this line.
    this.changePlanCount = 0;
    // selected plan name at the end of the line
    this.selectedPlanName = '';
    // selected plan price at the end
    this.selectedPlanPrice = 0;
    // Number of times, "Features and Perks" item was clicked on checkout for selected plan.
    this.selectedPlanFeaturesCount = 0;
    // Number of times, "See plan details" link was clicked on checkout for selected plan.
    this.selectedPlanPlanDetailsCount = 0;
    // selected plan source at the end of the line (can be "List" or "Popup")
    this.selectedPlanSource = 'List';
    // Count of time user spend to select plan
    this.timeSpentStart = 0;

    this.timeSpentEnd = 0;
    // Number of times, “See perk details” link was clicked on checkout for selected plan.
    this.selectedPlanPerkDetailsCount = 0;
    // Selected Perks ids per line
    this.selectedPlanPerkFirstSelected = 0;
    this.selectedPlanPerkSecondSelected = 0;
    // Selected plan perks list in Flex concetps
    this.selectedPlanPerksBundle = ''; // [ENTERTAINMENT,PRODUCTIVITY]
    // PlanCounter list
    this.plans = [...plans];

    this.perks = perkIds.map(id => new PlansListDetailsCounter(id));
  }

  align() {
    const { plans, timeSpentStart, timeSpentEnd, perks, ...rest  } = this;

    const flatStatistic = {
      ...perkReducer(perks),
      timeSpent: Math.round(
        ((timeSpentEnd ? timeSpentEnd.getTime() : 0)
        - (timeSpentStart ? timeSpentStart.getTime() : 0))
       / 1000),
    };

    plans
    .map((plan) => plan.align())
    .map((plan) =>
      Object.keys(plan)
            .reduce((planCounter, key) => {
              if (key === 'id') return planCounter;
              if (key === 'perksStatistic') {
                const perksStatistic = plan['perksStatistic']
                const perks = Object.keys(perksStatistic)
                .reduce((perkCounter, key) => ({
                  ...perkCounter,
                  [`plan${plan.id}${key}`]: perksStatistic[key],
                }), Object.create(null));
                return {
                  ...planCounter,
                  ...perks
                };
              }
              return {
                ...planCounter,
                [`plan${plan.id}${key}`]: plan[key],
              };
            },
            Object.create(null)))
        .forEach(el => Object.assign(flatStatistic, rest, el));

    return flatStatistic;
  }

}

class StatisticCounter {
  constructor(linesCount, planIds, perkIds) {
    // total price of all selected plans for all lines.
    this.total = 0;
    // total number of selected lines.
    this.linesCount = linesCount;

    this.lines = Array(linesCount)
      .fill(0)
      .map(() => planIds.map((id) => new PlanCounter(id, perkIds)))
      .map((plansStatistic) => new LineDetails(plansStatistic, perkIds))

    this.perks = perkIds.map(id => new LandingDetailsCounter(id));
  }

  align() {
    const { lines, perks, ...rest } = this;

    const flatStatistic = {
      ...perkReducer(perks),
    };

    lines
      .map((el, idx) => ({ [`line${idx + 1}`]: el.align() }))
      .forEach((el) => Object.assign(flatStatistic, rest, el));

    return flatStatistic;
  }

  update(pathTo, value) {
    if (pathTo.length === 0) return;

    const path = pathTo.split('.');
    const fieldName = path.pop();
    let location;

    try {
      location = path.reduce((location, key) => {
        if (location[key] === undefined) {
          console.log(pathTo);
          console.log(this);
          throw new Error('Path not found');
        }
        return location[key];
      }, this);
    }
    catch (err) {
      console.error(err);
    }

    const fieldType = typeof location[fieldName];

    if (fieldType === 'string'
      || fieldType === 'number'
      || location[fieldName] instanceof Date) {
      if (value === undefined) location[fieldName] += 1;
      else location[fieldName] = value;
    }
    else {
      console.error('Please check your path, value you trying to set is\'t primitive');
    }
    // console.log(`${pathTo} now is ${location[fieldName]}`)
    // console.log(this);
    // console.log(this.align());
  }
}

class MailBox {
  static send(result) {
    (window.parent || window).postMessage({
      kind: 'finishVerizonQuant',
      result
    }, "*");
  }
}

let statistic = null;

export default class Statistic {
  static updateValue(pathTo, value) {
    if (value === null) console.error('No statistic instance!');
    else statistic.update(pathTo, value);
  }

  static updateExpandValuePlans(lineIndex, planIndex, title, actionOpen){
    updateExpandStatisticsPlans(lineIndex, planIndex, title, actionOpen, this.updateValue);
  }

  static updateExpandValuePerks(isLanding, lineIndex, perkIndex, title, actionOpen){
    updateExpandStatisticsPerks(isLanding, lineIndex, perkIndex, title, actionOpen, this.updateValue);
  }

  static create(linesCount, planIds = [], perkIds = []) {
    if (!linesCount) return;
    statistic = new StatisticCounter(linesCount, planIds, perkIds);
  }

  static send() {
    const value = statistic ? statistic.align() : null;
    if (value === null) console.error('No statistic instance!');
    else MailBox.send(value);
  }
}