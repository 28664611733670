import React, { useState, useRef, useContext } from 'react';
import { some, each } from "lodash";
import classnames from 'classnames';
import renderRawHtml from "../../../../common/render_raw_html";
import { fraudContext } from "../../common/contexts";
import { GeneralContext } from '../contexts';
import FooterButton from './footer_button';
import Intro from './intro';
import Question from './Question';
import { questionIsZip, checkZipCode } from './screening/zip';
import { checkOpenEnds } from './metrics/openEnd';

const QuestionsGroup = ({ group, nextGroup, result }) => {
  const [ disabledFlags, setDisabledFlags ] = useState(() => {
    const flags = {};
    each(group, (q) => {
      flags[q.name] = false;
    });
    return flags;
  });
  const { setFraudProcessing } = useContext(fraudContext);
  const { setLoading } = useContext(GeneralContext);

  const setDisabled = (v, qname) => {
    disabledFlags[qname] = v;
    setDisabledFlags({ ...disabledFlags });
  };

  const hasDisabledFlag = some(group, (q) => (disabledFlags[q.name]));

  const callbacksRef = useRef([]);

  const runCallbacks = () => {
    each(callbacksRef.current, (callback) => {
      callback();
    });
    checkOpenEnds(
      result,
      () => {
        checkZipCode(result, nextGroup, setLoading);
      },
      setFraudProcessing
    );
  };

  const open = group[0].kind === 'Open' && !questionIsZip(group[0]);

  return (
    <>
      { group[0].name === "Intro" && <Intro description={ group[0].text } nextStep={ nextGroup } /> }
      {
        group[0].name !== "Intro" &&
        <>
          <div className="survey-layout_content">
            <div className={ classnames("survey-question-group", { '-open': open }) }>
              {
                group.map((question, i) => {
                  return (
                    <div
                      key={ question.name }
                      className={ `survey-question-group-item ${group.length > 1 ? '-multi' : ''}` }
                    >
                      <h2
                        className="survey-question-group-item_title"
                        { ...renderRawHtml(question.text) }
                      />
                      {
                        question.note &&
                        <div className="survey-question-note" { ...renderRawHtml(question.note) } />
                      }
                      <Question
                        ref={ (el) => callbacksRef.current[i] = el }
                        question={ question }
                        result={ result }
                        setDisabled={ (v) => { setDisabled(v, question.name); } }
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className="survey-footer">
            <FooterButton
              nextStep={ () => { runCallbacks(); } }
              options={ { disabled: hasDisabledFlag } }
            />
          </div>
        </>
      }
    </>
  );
};

export default QuestionsGroup;
