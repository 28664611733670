import React from 'react';
import StickyTip from '../flex-common/stickyTip';
import BestPerks from '../flex-common/bestPerks';
import Card from '../flex-common/card';
import { plansIncludes, planList } from './plansData';

const renderPlansList = ({ id, name, price, shortDescription }) => <li key={id} className="billing-plans-list-item">
  <Card>
    <div className="billing-plan-title">
      <span>{name}</span>
      <span>${price}</span>
    </div>
    <p className="billing-plan-description">{shortDescription}</p>
  </Card>
</li>

const BillingPlansList = () => {
  return <div className="billing-plans">
    <h6 className="billing-plans-title">Plans built for your needs</h6>
    <ul className="billing-plans-list">
      {planList.map(renderPlansList)}
    </ul>
  </div>;
}

const renderPlans = (includes, idx) => <li className="all-plans-include-item" key={idx}>{includes}</li>

const PlansInclude = () => {
  return <div className="all-plans-include">
    <div className="hr" />
    <h6 className="all-plans-include-title">
      All plans include:
    </h6>
    <ul className="all-plans-include-list">
      {plansIncludes.map(renderPlans)}
    </ul>
    <div className="hr" />
  </div>;
}

const Landing = ({ onContinue }) => {
  return <div className="layout">
    <StickyTip/>
    <div className="landing content -bau has-sticky-tip">
      <BestPerks/>

      <div>
        <BillingPlansList/>
        <PlansInclude/>
      </div>

      <footer>
        <button onClick={onContinue} className="button" type="button">Get started</button>
      </footer>
    </div>
  </div>
};

export default Landing;
