import React, { useState, useContext } from 'react';
import Header from '../flex-common/header';
import QuantPortal from '../flex-common/portal';
import PlanDetailsModal from '../flex-common/planDetatailsModal';
import {
  plansIncludes,
  planDetailsShort,
  allPlansInclude,
  getLargePlanDescription
} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';
import { PlanCard } from '../flex-common/planCard';
import PlanShortDescription, { SelectablePerksPlanDescription } from '../flex-common/planShortDescription';
import { find } from 'lodash';

const PlansInsideModal = ({ open, handleClose }) => {
  const { title, paragraphs } = allPlansInclude;
  return <QuantPortal title={title} open={open} handleClose={handleClose}>
    {paragraphs.map(({title, text}, idx) => <div key={idx} className="plans-include-details-group">
      <h6 className="plans-include-details-subtitle">{title}</h6>
      <p className="plans-include-details-value">{text}</p>
    </div>)}
  </QuantPortal>;
};

const renderPlans = (includes, idx) =>
  <li className="plans-include-details-list-item" key={idx}>{includes}</li>;

const PlansInclude = () => {
  const [open, setOpen] = useState(false);
  const { filledLines } = useContext(LinesDataContext);
  const handleOpen = () => {
    Statistic.updateValue(`lines.${filledLines}.viewDetailsCount`);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return <div>
    <div className="plans-include-details-head">
      <h6 className="plans-include-details-title">
        All plans include
      </h6>
      <button type="button" className="details-link" onClick={handleOpen}>View details</button>
    </div>
    <ul className="plans-include-details-list">
      {plansIncludes.map(renderPlans)}
    </ul>
    <PlansInsideModal open={open} handleClose={handleClose}/>
  </div>
};

const renderPlanDescription = (description, selectable, selectedIdx, onSelectedChange) => () =>
  selectable
    ? <SelectablePerksPlanDescription
        description={description}
        selectedIdx={selectedIdx}
        onSelectedChange={onSelectedChange}
        replaceDescriptionTitles={{ 'Perks': 'Select your perks' }} />
    : <PlanShortDescription description={description} />;

const renderPlansList = ({ selectedPerksIdxs, onChangePerk, onSelect, onShowDetails }) => (plan, idx) => {
  const showDetails = (id) => onShowDetails(id, idx);
  const select = () => onSelect(plan, idx);
  return <li key={plan.id} className="choose-plan-card-item">
    <PlanCard
      className="-flex -rounded -short-header"
      plan={plan}
      bigPlusTaxes={true}
      isAcardionStatic={true}
      renderChildrenInAccordion={false}
      handleOpenChange={() =>{}}
      renderDescription={renderPlanDescription(plan.description, plan.selectablePerks, selectedPerksIdxs[idx], (perkIdx) => onChangePerk(idx, perkIdx))}>
      <div className="select-button-wrapper">
        <button onClick={select} className="button -bordered" type="button">
          Select
        </button>
      </div>
      <div className="hr" style={{ marginTop: 20, marginLeft: -20, width: `calc(100% + 40px)` }} />
      <button type="button" className="details-link" onClick={() => showDetails(plan.id)}>See plan details</button>
    </PlanCard>
  </li>
}

const PlansList = ({list, ...rest}) => <ul className="choose-plan-card-list">
  { list.map(renderPlansList(rest)) }
</ul>;

const PlanDetailsModalWrapper = ({ planId, handleClose }) => {
  const plan = getLargePlanDescription(planId);
  return plan
    ? <PlanDetailsModal
        plan={plan}
        open={!!planId}
        handleClose={handleClose} />
    : null
};

const ChooseLinePlan = ({ onContinue }) => {
  const { filledLines } = useContext(LinesDataContext);
  const initiaPerksSelection = planDetailsShort.map(({ selectablePerks }) => selectablePerks ? 0 : void 0);
  const [selectedPerksIdxs, setSelectedPerksIdxs] = useState(initiaPerksSelection); // position - Plan idx, value on position - perk article idx on plan
  const selectPlanPerk = (planIdx, perkIdx) => {
    const perks = [...selectedPerksIdxs];
    perks[planIdx] = perkIdx;
    setSelectedPerksIdxs(perks);
  };
  const [planId, setPlanId] = useState(null);
  const handeClese = () => { setPlanId(null); };
  const handleOpen = (id, idx) => {
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsCount`);
    setPlanId(id);
  };
  const handleSelect = (plan, idx) => {
    const { name, price } = plan;
    Statistic.updateValue(`lines.${filledLines}.selectedPlanName`, name);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPrice`, price);
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.Selected`);

    if (plan.selectablePerks){
      const selectedPerkIdsPerPlan = [...selectedPerksIdxs],
        selectedPerkIdForPlan = selectedPerkIdsPerPlan[idx],
        itemWithArticles = find(plan.description, (item) => item.title == "Perks"),
        selectedArticleItem = (!!itemWithArticles && !!itemWithArticles.articles ?
          itemWithArticles.articles[selectedPerkIdForPlan] :
          null
        );
      Statistic.updateValue(`lines.${filledLines}.selectedPlanPerksBundle`, (!!selectedArticleItem ? selectedArticleItem.title : ''));
    }
    else { Statistic.updateValue(`lines.${filledLines}.selectedPlanPerksBundle`, ''); }
    onContinue(plan, selectedPerksIdxs[idx]);
  };
  return <div className="layout">
    <Header/>
    <h6 className="choose-line-plane-title -flex">Select a plan for Line {filledLines + 1}</h6>
    <div className="choose-line-plane content">
      <PlansInclude/>
      <PlansList
        list={planDetailsShort}
        selectedPerksIdxs={selectedPerksIdxs}
        onChangePerk={selectPlanPerk}
        onSelect={handleSelect}
        onShowDetails={handleOpen}/>
      <PlanDetailsModalWrapper planId={planId} handleClose={handeClese} />
    </div>
  </div>;
};


export default ChooseLinePlan;