import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import HintInPopup from '../../../common/hint-in-popup';
import Loader from "../../../../../../../images/icons/loader.gif";
import { rotate, afterHalf } from '../helpers';
import { destroyProject } from '../../requests';
import { IndexData } from './contexts';

const ProjectAsCard = ({ project }) => {
  const [ loading, setLoading ] = useState(false);
  const { admin, demoUser, projectIsShared, loadProjects } = useContext(IndexData);

  const canDelete = (admin || (!demoUser && !projectIsShared));
  const langPostfix = project.more_than_one_lang ? ` (${project.language_title})` : "";

  return (
    <div className={ classnames("projects_item", { "-copying": project.copying }) }>
      <div className="project-preview projects_item-inner">
        {
          project.base &&
          <HintInPopup
            className="-star"
            dropClassName="-bottom-right"
            hintText="Base project. Can't be removed"
          >
            <svg className="icon -i-star"><use xlinkHref="#svg_i-star" /></svg>
          </HintInPopup>
        }
        {
          !project.base && !project.copying && canDelete &&
          <a
            className="delete"
            onClick={ () => {
              destroyProject(
                `${project.title} - ${project.country_name}${langPostfix}`,
                project.destroy_path,
                project.status === "Unpublished",
                setLoading,
                loadProjects
              );
            } }
          >
            <svg className="icon -i-delete">
              <use xlinkHref="#svg_i-delete" />
            </svg>
          </a>
        }
        {
          loading &&
          <div className="dropdown -actions-menu">
            <img width="24px" height="24px" src={ Loader } />
          </div>
        }

        <div className="project-preview_line">
          <div className="project-preview_title">
            <span className="-js-project-title">
              {`${project.country_name}${langPostfix}`}
            </span>
          </div>
          {
            admin &&
            <div className="project-preview_line-client">{project.client_name}</div>
          }
        </div>

        <div className="project-date-time">
          <HintInPopup
            hintText={ project.created_at_hint }
            dropClassName="-bottom-center -date"
          >
            { project.created_at_text }
          </HintInPopup>
        </div>

        {
          project.copying &&
          <div className="project-preview_line">
            <div className="project-preview_grid">
              <div className="project-preview_grid-item">
                Project is copying...
              </div>
            </div>
          </div>
        }
        {
          !project.copying &&
          <div className="project-preview_line">
            <div className="project-preview_grid -top">
              <div className="project-preview_grid-item">
                {
                  (!project.remote_respondents || project.force_edit_project) &&
                  <div className="project-preview_link-wrap">
                    <a className="project-preview_link" href={ project.edit_path }>
                      {
                        project.remote_respondents ?
                          <>Edit Project</> :
                          <>Project Setup</>
                      }
                    </a>
                    {
                      project.remote_respondents &&
                      <span className="project-preview_link-addition">&nbsp;(Limited)</span>
                    }
                  </div>
                }
                <div className="project-preview_link-wrap">
                  <a className="project-preview_link" href={ project.report_path }>
                    Report
                  </a>
                </div>
                {
                  (project.can_review_respondents) &&
                  <div className="project-preview_link-wrap">
                    <a className="project-preview_link" href={ project.review_respondents_path }>
                      Review Respondents
                    </a>
                  </div>
                }
                {
                  (project.can_manage_concept_tags) &&
                  <div className="project-preview_link-wrap">
                    <a className="project-preview_link" href={ project.concept_tags_path }>
                      Concept Tags
                    </a>
                  </div>
                }
                {
                  !!project.can_edit_survey_flow &&
                  <div className="project-preview_link-wrap">
                    <a
                      className="project-preview_link"
                      href={ project.edit_survey_flow }
                    >
                      Edit Survey Flow
                    </a>
                  </div>
                }
                {
                  project.can_preview &&
                  <div className="project-preview_link-wrap">
                    <a
                      className="project-preview_link"
                      target="blank"
                      href={ project.respondent_survey_path }
                    >
                      Preview
                    </a>
                  </div>
                }
              </div>

              {
                !!project.required_nsize &&
                <div className="project-preview_grid-item">
                  <div className={ classnames('project-preview_chart', { '-after-half': afterHalf(project) }) }>
                    <div className="project-preview_chart-part -static" />
                    <div
                      className="project-preview_chart-part -rotated"
                      style={ { transform: rotate(project) } }
                    />
                    <div className="project-preview_chart-start-point" />
                  </div>
                </div>
              }
            </div>
          </div>
        }

        <div className="project-preview_line">
          <div className="project-preview_grid">
            <div className="project-preview_grid-item -status-and-sharing">
              <div className={ classnames('project-preview_status', `-${project.status_name}`) }>
                { project.status }
              </div>
            </div>
            <div className="project-preview_grid-item">
              <div className="project-preview_count">
                <svg className="icon -project-user project-preview_count-icon">
                  <use xlinkHref="#svg_project-user" />
                </svg>
                <span>{project.respondent_count}</span>
                {
                  !!project.required_nsize &&
                  <span className="project-preview_count-of"> of { project.required_nsize }</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAsCard;
