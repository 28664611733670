import React from 'react';
import BBPagination from "../../../../../common/components/pagination";

const PlateOptions = ({ total, perPage, page, setPage }) => {
  return (
    <BBPagination
      totalRecords={ total }
      perPage={ perPage }
      current={ page }
      pageClicked={ (p) => { setPage(p); } }
      className="projects_pagination"
    />
  );
};

export default PlateOptions;
