import React, { useContext, useEffect } from 'react';
import Header from "../common/components/flavor-header/index";
import { IndexData } from '../common/index-context';
import { WithLangData } from '../common/lang-data';
import CustomizeQuestionsModal from "../common/components/customize-questions-modal/customize-questions-modal";
import Comparisons from "./components/comparisons";
import EntityModal from "./components/entity-modal/index";

const AppInner = () => {
  const {
    init, data, loadData, openClaimAfterReload,
    customizedQuestionData, showEntityModal
  } = useContext(IndexData);

  useEffect(() => {
    loadData(null, null, null, null, {}, openClaimAfterReload);
  }, []);

  const reports = [
    { title: 'Items Report', name: 'concept_comparison_flavor_maxdif', kinds: [ 'pptx', 'xlsx' ] },
    { title: 'All Items Verbatims Report', name: 'flavor_maxdif_all_verbatims', kinds: [ 'xlsx' ] }
  ];
  if (init.isInternal) {
    reports.push({ title: 'Disqualified Respondents Report', name: 'disqualified', kinds: [ 'xlsx' ] });
    reports.push({ title: 'All Respondents Answers', name: 'flavor_maxdif_all_respondents', kinds: [ 'xlsx' ] });
    reports.push({ title: 'Quota Details', name: 'quotas_details', kinds: [ 'xlsx' ] });
  }

  return (
    <div className="content -loading-wrapper tour-parent-header">
      <Header reports={ reports } />
      <WithLangData
        langCode={ data.language_code }
        langName={ data.language_name }
      >
        <Comparisons />
        { showEntityModal && <EntityModal /> }
        { customizedQuestionData && <CustomizeQuestionsModal /> }
      </WithLangData>
    </div>
  );
};

export default AppInner;
