import React from 'react';
import { createPortal } from 'react-dom';
import VerizonStripe from './verizonStripe';

const AttachedToId = ({ id, children }) => {
  const idElement = document.querySelector(`#${id}`);
  return idElement ? createPortal(children, idElement) : null;
};

const VerizonStripeToId = ({ id }) => (
  <AttachedToId id={ id }><VerizonStripe /></AttachedToId>
);

export default VerizonStripeToId;
