import React, { useState } from 'react';
import Field from '../common/form/field';
import Phone from '../common/form/phone';
import Notification from "../common/notification";
import { profileUpdateClick } from "../../common/google-analytics/events";
import { sendProfileUpdateRequest } from './requests';

const App = ({ user, init }) => {
  const [ firstName, updateFirstName ] = useState(user.first_name);
  const [ firstNameError, updateFirstNameError ] = useState(null);
  const [ lastName, updateLastName ] = useState(user.last_name);
  const [ lastNameError, updateLastNameError ] = useState(null);
  const [ email, updateEmail ] = useState(user.email);
  const [ emailError, updateEmailError ] = useState(null);
  const [ currentPassword, updateCurrentPassword ] = useState("");
  const [ currentPasswordError, updateCurrentPasswordError ] = useState(null);
  const [ newPassword, updateNewPassword ] = useState("");
  const [ newPasswordError, updateNewPasswordError ] = useState(null);
  const [ phone, updatePhone ] = useState(user.phone);
  const [ noCodePhone, updateNoCodePhone ] = useState(user.phone);
  const [ phoneError, updatePhoneError ] = useState(null);

  const [ notificationObject, updateNotificationObject ] = useState(null);

  const getError = (errors, name) => (errors[name] ? errors[name][0] : null);

  const onPhoneUpdate = (value, noCodeValue) => {
    updatePhone(value);
    updateNoCodePhone(noCodeValue);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    profileUpdateClick(init.google_analytics_id);
    sendProfileUpdateRequest(
      {
        email,
        first_name: firstName,
        last_name: lastName,
        current_password: currentPassword,
        new_password: newPassword,
        phone: noCodePhone ? phone : ""
      },
      (response) => {
        if (response.response.ok) {
          updateCurrentPassword("");
          updateNewPassword("");
          updateFirstNameError(null);
          updateLastNameError(null);
          updateEmailError(null);
          updateCurrentPasswordError(null);
          updateNewPasswordError(null);
          updatePhoneError(null);
          updateNotificationObject(
            { message: response.data.success, success: true }
          );
        } else {
          const errors = response.data.errors || {};
          updateFirstNameError(getError(errors, "first_name"));
          updateLastNameError(getError(errors, "last_name"));
          updateEmailError(getError(errors, "email"));
          updateCurrentPasswordError(
            getError(errors, "current_password")
          );
          updateNewPasswordError(getError(errors, "password"));
          updatePhoneError(getError(errors, "phone"));
          updateNotificationObject(
            { message: response.data.error, success: false }
          );
        }
      }
    );
  };

  return (
    <div className="content">
      <div className="content_top">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item">
              <h1 className="header_title">
                Edit Profile
              </h1>
            </div>
          </div>
        </header>
      </div>
      <div className="content_body">
        <form className="form" autoComplete="off">
          <div className="form_section">
            <div className="form_group-line">
              <Field
                title="First name"
                type="text"
                value={ firstName }
                onChange={ updateFirstName }
                error={ firstNameError }
              />
            </div>
            <div className="form_group-line">
              <Field
                title="Last name"
                type="text"
                value={ lastName }
                onChange={ updateLastName }
                error={ lastNameError }
              />
            </div>
            <div className="form_group-line">
              <Field
                title="Email"
                type="text"
                value={ email }
                onChange={ updateEmail }
                error={ emailError }
              />
            </div>
            <div className="form_group-line">
              <Phone
                title="Contact phone (optional)"
                value={ phone }
                onChange={ onPhoneUpdate }
                error={ phoneError }
              />
            </div>
            <div className="form_group-line">
              <Field
                title="Current password"
                type="password"
                value={ currentPassword }
                onChange={ updateCurrentPassword }
                error={ currentPasswordError }
              />
            </div>
            <div className="form_group-line">
              <Field
                title="New password"
                type="password"
                value={ newPassword }
                onChange={ updateNewPassword }
                error={ newPasswordError }
              />
            </div>
          </div>
          <div className="form_section">
            <div className="form_buttons">
              <button
                className="button form_button"
                onClick={ (event) => { handleUpdate(event); } }
              >
                Update
              </button>
            </div>
          </div>
        </form>
        <Notification
          messageObject={ notificationObject }
          onDeactivate={ () => { updateNotificationObject(null); } }
          interval={ 5 }
        />
      </div>
    </div>
  );
};

export default App;
