import { addClass, removeClass } from '../../../../common/dom_functions';

const steps = ({ setForceOpenFilterDropDown, getCustomTourFunctions, setForceOpenButton }) => (
  () => {
    const openFilters = () => (setForceOpenFilterDropDown(true));
    const closeFilters = () => (setForceOpenFilterDropDown(false));
    const openButtonDropdown = (name) => {
      setForceOpenButton(name, true);
    };

    return [
      {
        id: 1,
        element: null,
        elementKey: "projectHeader",
        elementKlass: "tour-header",
        modal: false,
        pos: "bottom-left",
        parent: ".tour-parent-header",
        callback: null
      },
      {
        id: 2,
        element: ".-custom-filters-body .chosen-container",
        elementKey: null,
        modal: false,
        pos: "top-center",
        callback: () => {
          openFilters();
        }
      },
      {
        id: 3,
        elementKey: "categoryTab",
        modal: false,
        pos: "top-center",
        parent: ".comparisons_tabs .radio-tabs",
        callback: () => {
          closeFilters();
        }
      },
      {
        id: 4,
        elementKey: "brandTab",
        modal: false,
        pos: "top-center",
        parent: ".comparisons_tabs .radio-tabs",
        callback: () => {
          const ff = getCustomTourFunctions();
          ff.setTab('brand');
        }
      },
      {
        id: 5,
        elementKey: "highlightedRow",
        modal: false,
        pos: "top-center",
        parent: ".comparisons_table",
        callback: () => {}
      },
      {
        id: 6,
        elementKey: "ubaText",
        modal: false,
        pos: "bottom-center",
        callback: () => {
          addClass(".-hb2t, .tour-header, #top-filters-body, .comparisons_tabs", "-tour-hide");
        }
      },
      {
        id: 7,
        elementKey: "ubaTags",
        modal: false,
        pos: "bottom-center",
        callback: () => {}
      },
      {
        id: 8,
        elementKey: "ubaRight",
        modal: false,
        pos: "left-top",
        callback: () => {}
      },
      {
        id: 9,
        elementKey: "AVS",
        modal: false,
        pos: "right-middle",
        callback: () => {
          addClass(".-wordcloud", "-tour-hide");
        }
      },
      {
        id: 10,
        elementKey: "NPS",
        modal: false,
        pos: "left-middle",
        callback: () => {}
      },
      {
        id: 11,
        elementKey: "Attributes",
        modal: false,
        pos: "top-right",
        callback: () => {
          addClass(".report-plate_section.-first", "-tour-hide");
        }
      },
      {
        id: 12,
        elementKey: "UsSat",
        modal: false,
        pos: "top-center",
        callback: () => {
          addClass(".logic-operator-between-plus, .-js-filter", "-tour-hide");
        }
      },
      {
        id: 13,
        elementKey: "customTab",
        modal: false,
        pos: "top-center",
        parent: ".comparisons_tabs .radio-tabs",
        callback: () => {
          removeClass(".tour-header, #top-filters-body, .comparisons_tabs, .logic-operator-between-plus, .-js-filter", "-tour-hide");
          const ff = getCustomTourFunctions();
          ff.setTab('custom');
        }
      },
      {
        id: 14,
        elementKey: "reportExports",
        modal: false,
        pos: "left-top",
        parent: ".dropdown",
        callback: () => {
          openButtonDropdown('reportExports');
        }
      }
    ];
  }
);

export default steps;
