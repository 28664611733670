import React from 'react';
import { map } from "lodash";

export const PerkVariantsTable = ({ perk }) => {
  return (
    <div className="perk-variants-table">
      <div className="perk-variants-table_row -head">
        <div className="perk-variants-table_col -head -name" />
        {
          map(perk.variants, (variant, idx) => (
            <div className="perk-variants-table_col -head" key={ idx }>
              <div className="perk-variants-table_title">
                {variant.title}
              </div>
              <div className="perk-variants-table_note">
                ${variant.price}/mo
              </div>
            </div>
          ))
        }
      </div>
      {
        map(perk.details.includeForVariant, (item, idx) => (
          <div className="perk-variants-table_row" key={ idx }>
            <div className="perk-variants-table_col -name">
              {item.title}
            </div>
            {
              map(item.values, (value, idx) => (
                <div className="perk-variants-table_col" key={ idx }>
                  { !!value && value.length && value }
                  {
                    !!value && !value.length &&
                    <svg className="icon -checkmark-alt perk-variants-table_icon">
                      <use xlinkHref="#svg_checkmark-alt" />
                    </svg>
                  }
                  { !value && "—" }
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
};
