import React, { useContext, useEffect, useRef } from 'react';
import cn from "classnames";
import { IndexData } from "../../common/index-context";

const Tabs = ({ tab, setTab, showCategory, showCustom, baseTitle = 'brand', survey }) => {
  const { getTourUseEffectArgs, getCustomTourFunctions, setCustomTourFunctions } = useContext(IndexData);
  const tourElementCtg = useRef(null);
  const tourElementBrd = useRef(null);
  const tourElementCus = useRef(null);
  const [ callbackCtg, conditionCtg ] = getTourUseEffectArgs('categoryTab', tourElementCtg);
  const [ callbackBrd, conditionBrd ] = getTourUseEffectArgs('brandTab', tourElementBrd);
  const [ callbackCus, conditionCus ] = getTourUseEffectArgs('customTab', tourElementCus);
  useEffect(callbackCtg, conditionCtg);
  useEffect(callbackBrd, conditionBrd);
  useEffect(callbackCus, conditionCus);

  const onChangeTab = (tabName) => {
    setTab(tabName);
    window.history.replaceState(null, null, `${window.location.pathname}?tab=${tabName}`);
  };

  useEffect(() => {
    setCustomTourFunctions({ ...getCustomTourFunctions(), setTab, 'A': 'B' });
  }, [ setTab ]);

  return (
    <div className="comparisons_tabs">
      <div className={ cn("radio-tabs -borderer -gray-bg", { "-survey": survey }) }>
        {showCategory &&
        <label className="radio-tabs_item" ref={ tourElementCtg }>
          <input
            type="radio"
            checked={ tab === "category" }
            onChange={ () => {onChangeTab("category");} }
          />
          <div className="radio-tabs_label">Category</div>
        </label>}

        <label className="radio-tabs_item" ref={ tourElementBrd }>
          <input
            type="radio"
            checked={ tab === baseTitle }
            onChange={ () => {onChangeTab(baseTitle);} }
          />
          <div className={ cn("radio-tabs_label -capitalize", { "-survey": survey }) }>{ baseTitle === "base" ? "Base Metrics" : baseTitle }</div>
        </label>

        {showCustom &&
        <label className="radio-tabs_item" ref={ tourElementCus }>
          <input
            type="radio"
            checked={ tab === "custom" }
            onChange={ () => {onChangeTab("custom");} }
          />
          <div className={ cn("radio-tabs_label -capitalize", { "-survey": survey }) }>{ survey ? "Custom Metrics" : "Custom Questions" }</div>
        </label>}
      </div>
    </div>
  );
};

export default Tabs;
