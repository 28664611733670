import { each } from 'lodash';
import Http from "../../../common/http";

const loadProjects = (mediaKind, verizonType, mediaKinds, callback) => {
  const params = [
    `media_kind=${mediaKind}`,
    `verizon_type=${verizonType}`
  ];
  each(mediaKinds || [], (item) => {
    params.push(`project[media_kinds][]=${item}`);
  });
  Http.get(
    `/verizon/projects.json?${params.join('&')}`
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const createProject = (payload, callback) => {
  Http.post(
    `/verizon/projects.json`,
    { project: payload }
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const updateProject = (payload, callback) => {
  Http.put(
    `/verizon/projects/${payload.id}.json`,
    { project: payload }
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const deleteProject = (payload, callback) => {
  Http.del(
    `/verizon/projects/${payload.id}.json`
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const selectForOverview = (mediaKind, verizonType, projectId, callback) => {
  Http.post(
    `/verizon/projects/${projectId}/select_for_overview.json`,
    { media_kind: mediaKind, verizon_type: verizonType }
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { loadProjects, createProject, updateProject, deleteProject, selectForOverview };
