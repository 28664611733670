import React from 'react';
import { map } from 'lodash';

const PaymentTypesTab = ({ paymentTypes, paymentType, setPaymentType }) => {
  const handleChangePaymentType = (event) => {
    setPaymentType(event.target.value);
  };
  return (
    <div className="form_section -payments-tab">
      <div className="form_section-tabs">
        <div className="form_section-text -label">
          Payment Options
        </div>
        <div className="radio-tabs -borderer -gray-bg">
          {
            map(paymentTypes, (title, name) => (
              <label className="radio-tabs_item" htmlFor={ `tab_input_${name}` } key={ name }>
                <input
                  id={ `tab_input_${name}` }
                  type="radio"
                  value={ name }
                  onChange={ handleChangePaymentType }
                  checked={ paymentType === name }
                />
                <div className="radio-tabs_label">{ title }</div>
              </label>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default PaymentTypesTab;
