import React, { useContext } from 'react';
import { isEmpty, filter, map } from "lodash";
import { IndexData, selectGroup } from "../../../common/index-context";
import Th from '../../../common/components/concepts-table/th';
import TrNAvg from '../../../common/components/concepts-table/tr-n-avg';
import TrFivePointScales from '../../../common/components/concepts-table/tr-five-point-scales';
import TrAttributeRatings from "../../../common/components/concepts-table/tr-attribute-ratings";
import AverageModeSelector from "../../../common/components/mode_selector";
import TrCustomMetrics from "../../../common/components/concepts-table/tr-custom-metrics";
import TrCombinedTools from './tr-combined-tools';

const ConceptsTable = () => {
  const { data, concepts } = useContext(IndexData);
  const checkedConcepts = filter(concepts, (el) => el.checked);
  const alphabet = data.alphabet;

  const combinedTools = selectGroup(data, 'combined_tool');
  const fivePointScales = selectGroup(data, 'five_point_scale');
  const customMetrics = selectGroup(data, 'custom_metric');
  const attributeRatings = selectGroup(data, 'attribute_ratings');

  return (
    <table className="table table-comparison -stick-header -stick-average -stick-first-column">
      <thead>
        <tr>
          <th className="table-comparison_name-cell">
            Concept
          </th>
          {
            map(checkedConcepts, (concept, i) => (
              <Th
                key={ i }
                index={ i }
                concept={ concept }
                alphabet={ alphabet }
              />
            ))
          }
          <th className="table-comparison_average-cell -concept-list -word-wrap">
            <AverageModeSelector showLabel />
          </th>
        </tr>
      </thead>

      <tbody>
        <TrNAvg data={ data } checkedConcepts={ checkedConcepts } />

        {
          !isEmpty(combinedTools) &&
          <TrCombinedTools
            data={ data }
            checkedConcepts={ checkedConcepts }
            combinedTools={ combinedTools }
          />
        }

        {
          !isEmpty(fivePointScales) &&
          <TrFivePointScales
            data={ data }
            checkedConcepts={ checkedConcepts }
            fivePointScales={ fivePointScales }
            nFields={ { f: "five_pt_scale_count", avg: "five_pt_scale_avg_count" } }
          />
        }
        {
          !isEmpty(customMetrics) &&
          <TrCustomMetrics
            data={ data }
            checkedConcepts={ checkedConcepts }
            customMetrics={ customMetrics }
            nFields={
              data.custom_metrics_display_n_size ?
                { f: "custom_metrics_count", avg: "custom_metrics_avg_count" } :
                null
            }
          />
        }
        {
          !isEmpty(attributeRatings) &&
          <TrAttributeRatings
            data={ data }
            checkedConcepts={ checkedConcepts }
            attributeRatings={ attributeRatings }
            groupName="attribute_ratings"
          />
        }
      </tbody>
    </table>
  );
};

export default ConceptsTable;
