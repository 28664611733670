import React, { useState, useContext } from 'react';
import { getInvariantByDeviceCount } from '../data/how-to-connect.a';

export const DataStorageContext = React.createContext({});

const cache = { plan: null, tvConnection: null, setTopBox: null, ownDevice: null };
const updateCache = (key, value) => { cache[key] = value; };
const getCache = (key) => cache[key];

const useCheckout = (isDataFilled, data, total, statistic, onNext) => {
  if (!isDataFilled) return () => {};
  const { 
    fiosTVData,
    tvConnectionsData,
    howToConnectVariantData,
    howToConnectOptionData
  } = data;
  
  const hash = {
    ...statistic,
    total,
    planName: fiosTVData.title,
    planPrice: fiosTVData.price,
    tvConnectionsNum: tvConnectionsData.count,
    setTopBoxNum: howToConnectVariantData.count,
    setTopBoxPrice: howToConnectVariantData.price,
    ownStreamingDevicesType: howToConnectOptionData
      ? howToConnectOptionData.count === Infinity ? 'unlimited' : 'streaming'
      : null,
    ownStreamingDevicesPrice: howToConnectOptionData 
      ? howToConnectOptionData.price 
      : 0,
  };
  return () => {
    (window.parent || window).postMessage({
      kind: 'finishVerizonTv',
      result: hash
    }, "*");
    onNext();
  }
}

const useStatistic = (initial) => {
  const [statistic, setStatistic] = useState(initial);

  const updateCounterOf = (key) => {
    setStatistic((prevValue) => ({...prevValue, [key]: prevValue[key] + 1 }) );
  };

  return [statistic, updateCounterOf];
}

export const useDataStorage = (onNext) => {
  const [statistic, updateCounterOf] = useStatistic({
    changePlan: 0,
    changeTvConnection: 0,
    changeSetTopBox: 0,
    changeOwnDevices: 0,
    changeSetTopBoxAfterOwnDeviceSelected: 0
  });

  const [fiosTVData, setFiosTVData] = useState(null);
  const updateFiosTVData = (data) => { 
    setTVConnectionsData(null);
    setHowToConnectVariantData(null);
    setHowToConnectOptionData(null);
    setFiosTVData(data);
  };
  const [tvConnectionsData, setTVConnectionsData] = useState(null);
  const updateTVConnectionsData = (data) => { 
    setHowToConnectVariantData(null);
    setHowToConnectOptionData(null);
    setTVConnectionsData(data);
  };
  const [howToConnectVariantData, setHowToConnectVariantData] = useState(null);
  const updateHowToConnectVariantData = (data) => { 
    setHowToConnectOptionData(null);
    setHowToConnectVariantData(data);
  };

  const [howToConnectOptionData, setHowToConnectOptionData] = useState(null);
  const updateHowToConnectOptionData = (data) => { 
    setHowToConnectOptionData(data);
  };

  const tvConnectionPrice = tvConnectionsData ? tvConnectionsData.price : 0;
  const variantDataPrice = howToConnectVariantData ? howToConnectVariantData.price : 0

  const total = (fiosTVData ? fiosTVData.price : 0) +
    (howToConnectVariantData && howToConnectOptionData ? variantDataPrice : tvConnectionPrice) +
    (howToConnectOptionData ? howToConnectOptionData.price : 0);

  const data = {
    fiosTVData,
    tvConnectionsData,
    howToConnectVariantData,
    howToConnectOptionData,
  };

  const variantList = getInvariantByDeviceCount(tvConnectionsData ? tvConnectionsData.count : -1);
  const isOptionValid = variantList[variantList.length - 1] === howToConnectVariantData
    ? true
    : howToConnectOptionData;
  const isDataFilled = !!(fiosTVData && tvConnectionsData && howToConnectVariantData && isOptionValid);

  const onCheckout = useCheckout(isDataFilled, data, total, statistic, onNext);
    
  return {
    total,
    data,
    onCheckout,
    isDataFilled,
    updateCounterOf,
    updateFiosTVData,
    updateTVConnectionsData,
    updateHowToConnectVariantData,
    updateHowToConnectOptionData
  }
};

export const useInvariants = () => {
  const { 
    data: { howToConnectVariantData, howToConnectOptionData, tvConnectionsData },
    updateHowToConnectVariantData,
    updateHowToConnectOptionData,
    updateCounterOf
  } = useContext(DataStorageContext);

  const tvConnectionsCount = tvConnectionsData ? tvConnectionsData.count : 1
  const invariants = getInvariantByDeviceCount(tvConnectionsCount);

  const updateVariant = (variant) => {
    const Stb = getCache('setTopBox'), ownDevice = getCache('ownDevice');
    if (!!Stb && Stb != variant){
      updateCounterOf('changeSetTopBox');
      if (!!ownDevice) updateCounterOf('changeSetTopBoxAfterOwnDeviceSelected');
    }
    updateCache('setTopBox', variant);
    updateHowToConnectVariantData(variant);
  };

  const updateOption = (option) => {
    const data = option === howToConnectOptionData ? null : option;
    const ownDevice = getCache('ownDevice');

    if (!!ownDevice && !!option && ownDevice != option) updateCounterOf('changeOwnDevices');
    if (!!option) updateCache('ownDevice', option);
    updateHowToConnectOptionData(data);
  };

  return {
    variant: howToConnectVariantData,
    option: howToConnectOptionData,
    tvConnectionsCount,
    invariants,
    updateVariant,
    updateOption
  }
};

export const useTVConnections = (onNext) => {
  const { 
    data: { tvConnectionsData },
    updateTVConnectionsData,
    updateCounterOf,
  } = useContext(DataStorageContext);
  const handleActiveChange = (data) => {
    const tvConnection = getCache('tvConnection');
    if (!!tvConnection && tvConnection !== data) updateCounterOf('changeTvConnection');
    updateCache('tvConnection', data);
    updateTVConnectionsData(data);
    onNext();
  };

  const title = tvConnectionsData === null
    ? 'How many TV connections?'
    : `${tvConnectionsData.count} ${tvConnectionsData.count === 1 ? 'TV' : 'TVs'}`;
  
  const chipText = tvConnectionsData === null
    ? 'Required'
    : 'Added';

  const chipClassName = tvConnectionsData === null
    ? '-red'
    : '-green';

  return {
    activeConnection: tvConnectionsData,
    handleActiveChange,
    title,
    chipText,
    chipClassName,
  }
};

export const useFiosTV = (onNext) => {
  const { 
    data: { fiosTVData },
    updateCounterOf,
    updateFiosTVData
  } = useContext(DataStorageContext);
  const dataFilled = !!fiosTVData;
  const sectionTitle = dataFilled ? fiosTVData.title : 'More Fios TV';
  const chipTitle = dataFilled ? 'Added' : 'Required';
  const chipClassName = dataFilled ? '-green' : '-red';
  const handleChangePlan = (plan) => {
    const cachePlan = getCache('plan');
    if (!!cachePlan && cachePlan !== plan) updateCounterOf('changePlan');
    updateCache('plan', plan);
    updateFiosTVData(plan);
    onNext();
  };

  return {
    plan: fiosTVData,
    sectionTitle,
    chipTitle,
    chipClassName,
    handleChangePlan
  }
};

const getInitialState = () => ({
  fiosTV: false,
  tvConnections: false,
  howToConnect: false
});

export const useAccordionState = () => {
  const [open, setOpen] = useState(
    () => ({...getInitialState(), fiosTV: true})
  );
  
  const { data } = useContext(DataStorageContext);

  const handleOpen = (stepName) => { setOpen((prevState) => ({
    ...getInitialState(),
    [stepName]: true
  })) };

  return { open, handleOpen, data };
}

export const useHowToConnect = () => {
  const { 
    data: { tvConnectionsData, howToConnectVariantData, howToConnectOptionData }    
  } = useContext(DataStorageContext);
  const tvDataFilled = !!tvConnectionsData;
  const variantList = getInvariantByDeviceCount(tvConnectionsData ? tvConnectionsData.count : -1);
  const isOptionValid = variantList[variantList.length - 1] === howToConnectVariantData 
    ? true 
    : howToConnectOptionData;
  const selfFilled = !!howToConnectVariantData && isOptionValid;
  const sectionTitle = 'Choose how to connect your TVs';
  const chipText = tvDataFilled ? selfFilled ? 'Added' : 'Required' : 'Conditional';
  const chipClassName = selfFilled ? '-green' : '-red';
  
  return {
    sectionTitle,
    chipText,
    chipClassName,
  }
}