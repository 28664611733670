import React, { useContext } from 'react';
import { map } from "lodash";
import { baseContext } from "../context";
import SvgIcon from "../../../../common/components/svg-icon";
import Concept from "./concept";

const Index = () => {
  const {
    data, projectTitle,
    projectStatusText, projectStatusName,
    projectCategory, projectInternal,
    projectTarget
  } = useContext(baseContext);

  const conceptsData = data.concepts_data;
  return (
    <div className="concept-tags">
      <div className="concept-tags_content -body-scroll">
        <div className="concept-tags_header">
          <div className="concept-tags_header-title">
            <h1>{ "Concept Tags -" }</h1>
            <h1>{ projectTitle }</h1>
          </div>
          <div className="concept-tags_filter-legend">
            <div className={ `concept-tags_project-preview_status -${projectStatusName}` }>
              { projectStatusText }
            </div>
            {projectCategory &&
              <div className="concept-tags_badge">
                <SvgIcon name="i-check" />
                {projectCategory}
              </div>}
            <div className="concept-tags_badge">
              <SvgIcon name="i-check" />
              {projectInternal}
            </div>
            <div className="concept-tags_badge">
              <SvgIcon name="i-check" />
              {projectTarget}
            </div>
          </div>
        </div>
        {
          <>
            <div className="content_body -no-padding-top">
              <ul className="concept-tags_list">
                {
                  map(conceptsData, (concept, index) => (
                    <li
                      className="concept-tags_item"
                      key={ `${concept.id}-${index}` }
                      id={ `${concept.id}-${index}` }
                    >
                      <Concept
                        concept={ concept }
                        bulkMode={ !index && conceptsData.length > 1 }
                      />
                    </li>
                  ))
                }
              </ul>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Index;
