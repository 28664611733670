import React, { useEffect, useState } from 'react';
import { filter, map, each, findIndex, sortBy, cloneDeep, includes } from "lodash";
import pluralize from "pluralize";
import cn from "classnames";
import Modal from '../../../common/components/modal';
import SvgIcon from '../../../common/components/svg-icon';
import Tabs from "./components/tabs";
import ConceptItem from "./components/conceptItem";
import SearchField from "./components/searchField";
import ExpandCollapseItem from "./components/expandCollapseItem";

const App = ({ modalOpened, onClose, concepts, onSubmit }) => {
  const [ tab, setTab ] = useState("all");
  const [ searchText, setSearchText ] = useState("");
  const [ conceptList, updateConceptList ] = useState(() => (cloneDeep(concepts)));
  const [ filteredConceptList, updateFilteredConceptList ] = useState(conceptList);
  const [ selectAll, setSelectAll ] = useState(false);
  const [ showSelected, setShowSelected ] = useState(true);
  const [ showUnselected, setShowUnselected ] = useState(true);
  const onSearchFieldChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredList = (value, conceptsScope) => {
    const testValue = value.trim();
    return (
      testValue.trim() ?
        filter(
          conceptsScope,
          (concept) => (
            includes((concept.name || '').toLowerCase(), testValue.toLowerCase())
          )
        ) :
        conceptsScope
    );
  };

  const selectedCount = (list) => (filter(list, (concept) => (!!concept.selected)).length);
  const selectedConcepts = () => (filter(conceptList, (concept) => (!!concept.selected)));
  const unselectedConcepts = () => (filter(conceptList, (concept) => (!concept.selected)));

  const onSelectedChange = (id, value) => {
    const index = findIndex(conceptList, (el) => (el.id === id));
    const concept = conceptList.splice(index, 1);
    concept[0].selected = value;
    const conceptsScope = [ ...conceptList ];
    const newList = [ ...conceptsScope, ...concept ];
    updateConceptList(sortBy(newList, (item) => item.name.toLowerCase()));
    if (searchText.length && selectAll && !selectedCount(filteredConceptList)) {
      setSelectAll(!selectAll);
    }
    if (searchText.length && !selectAll && value) {
      setSelectAll(!selectAll);
    }
  };

  const handleOnChange = () => {
    setSelectAll(!selectAll);
    switchSelected(!selectAll);
  };

  const switchSelected = (value) => {
    const filtered = filteredList(searchText, conceptList);
    const conceptIds = map(filtered, (concept) => (concept.id));
    let conceptsScope = [ ...conceptList ];
    each(conceptIds, (id) => {
      const index = findIndex(conceptsScope, (el) => (el.id === id));
      const concept = conceptsScope.splice(index, 1);
      concept[0].selected = value;
      conceptsScope = [ ...conceptsScope, ...concept ];
    });
    updateConceptList(sortBy(conceptsScope, (item) => item.name.toLowerCase()));
    updateFilteredConceptList(filteredList(searchText, sortBy(conceptsScope, (item) => item.name.toLowerCase())));
  };

  const onSearchFieldClear = () => {
    setSearchText("");
  };

  const onRunExport = (event) => {
    let conceptIds = [];
    if (tab === "all") {
      conceptIds = map(conceptList, (el) => (el.id));
    } else {
      conceptIds = map(selectedConcepts(), (el) => (el.id));
    }
    onSubmit(event, conceptIds);
  };

  const groupSelectText = () => {
    const number = selectAll ? selectedCount(filteredConceptList) : filteredConceptList.length;
    return `${selectAll ? 'Unselect' : 'Select'} ${number} ${pluralize('concept', filteredConceptList.length)}`;
  };

  useEffect(() => {
    const filtered = filteredList(searchText, conceptList);
    updateFilteredConceptList(filteredList(searchText, conceptList));
    const selectedNum = selectedCount(filtered);
    if (filtered.length && selectedNum && selectedCount(filtered)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [ searchText ]);

  const disabledButton = (tab === "select" && selectedConcepts().length === 0);

  const expandedSelected = showSelected && !!selectedConcepts().length;
  const expandedUnselected = showUnselected && !!unselectedConcepts().length;
  const noExpand = tab !== 'select' || (!searchText && !expandedSelected && !expandedUnselected);

  return (
    <>
      {
        modalOpened &&
        <Modal
          modalSize="sm2"
          modalClassName={ cn("dialog-select-backdrop", { '-no-expand': noExpand }) }
          modalContentClass="-border-radius"
          modal
        >
          <div className="dialog-function-part">
            <div className="dialog-select-header-section">
              <div className="modal-header -auto-height">
                <span
                  className="modal_close icon-link -no-text -gray"
                  title="Close"
                  role="button"
                  onClick={ onClose }
                >
                  <SvgIcon className="icon-link_icon" name="i-close" />
                  <span className="icon-link_text">Close</span>
                </span>

                <h3 className="report-modal-title">Export all concepts side-by-side (current settings)</h3>
              </div>

              <Tabs tab={ tab } setTab={ setTab } />

              {
                tab === "all" &&
                <span className="export_info_text">
                  { `${conceptList.length} ${pluralize('concepts', conceptList.length)} will be exported. This can take a long time.` }
                </span>
              }
              {
                tab === "select" &&
                <SearchField
                  searchText={ searchText }
                  onSearchFieldChange={ onSearchFieldChange }
                  onSearchFieldClear={ onSearchFieldClear }
                />
              }
            </div>

            {
              tab === "select" && !searchText &&
              <>
                <div className="concepts-area">
                  <div
                    className={
                      cn(
                        "section -first",
                        { '-expanded': expandedSelected },
                        { '-collapsed': !showSelected }
                      )
                    }
                  >
                    <ExpandCollapseItem
                      show={ showSelected }
                      updateShowFunction={ setShowSelected }
                      text={ `${selectedConcepts().length} Selected` }
                      selected={ !!selectedConcepts().length }
                      clearSelected={ () => { switchSelected(false); } }
                    />
                  </div>
                  <div className="concepts-section">
                    {
                      showSelected &&
                    map(selectedConcepts(), (concept) => (
                      <ConceptItem
                        key={ `concept${concept.id}` }
                        concept={ concept }
                        checked={ concept.selected }
                        onChange={ onSelectedChange }
                      />
                    ))
                    }
                  </div>
                  <div
                    className={
                      cn(
                        "section -last",
                        { '-expanded': expandedUnselected },
                        { '-collapsed': !showUnselected },
                        { '-without-top-border': !expandedSelected }
                      )
                    }
                  >
                    <ExpandCollapseItem
                      show={ showUnselected }
                      updateShowFunction={ setShowUnselected }
                      text={ `${unselectedConcepts().length} Unselected` }
                    />
                  </div>
                  <div className="concepts-section">
                    {
                      showUnselected &&
                    map(unselectedConcepts(), (concept) => (
                      <ConceptItem
                        key={ `concept${concept.id}` }
                        concept={ concept }
                        checked={ concept.selected }
                        onChange={ onSelectedChange }
                      />
                    ))
                    }
                  </div>
                </div>
              </>
            }
            {
              tab === "select" && !!searchText &&
              <div className="concepts-area">
                {
                  !!filteredConceptList.length &&
                  <>
                    <div className="section -first -expanded-special">
                      <div className="selected-checkbox -no-borders -no-padding">
                        <input
                          id={ 'group-select' }
                          type="checkbox"
                          checked={ selectAll }
                          onChange={ () => {} }
                        />
                        <label
                          className="checkbox-label -bold -with-text"
                          htmlFor={ "group-select" }
                          onClick={ handleOnChange }
                        >
                          { groupSelectText() }
                        </label>
                      </div>
                    </div>
                    <div className="concepts-section">
                      {
                        filteredConceptList.length &&
                      map(filteredConceptList, (concept) => (
                        <ConceptItem
                          key={ `filtered-concept${concept.id}` }
                          concept={ concept }
                          checked={ concept.selected }
                          onChange={ onSelectedChange }
                        />
                      ))
                      }
                    </div></>
                }
                {
                  !filteredConceptList.length &&
                  <div className="not-found-text">No concepts found</div>
                }
              </div>
            }
          </div>
          <div className="button-section">
            <button
              className={
                cn(
                  "export-submit_button",
                  { '-disabled': disabledButton }
                )
              }
              onClick={ onRunExport }
              disabled={ disabledButton }
            >
              {
                (
                  tab === "select" ?
                    <>
                      {
                        (
                          selectedConcepts().length > 0 ?
                            `Export ${selectedConcepts().length} ${pluralize('concept', selectedConcepts().length)}` :
                            'Export'
                        )
                      }
                    </>
                    :
                    "Continue"
                )
              }
            </button>
          </div>
        </Modal>
      }
    </>
  );
};

export default App;
