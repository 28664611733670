import React, { useState, useEffect, useRef } from 'react';
import { map, each, isEmpty, keys, find, trim }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { getAgeOptions, getAgeAnswerId } from '../../common/logic/age.js';
import FooterButton from './footer_button.js';
import Grid from "./metrics/grid";
import CommonHeader from "./commonHeader";
import { SingleOutput } from './singleOutput';
import { MultiOutput } from './multiOutput';


const Screening = ({
  question, nextStep, result
}) => {
  const { kids, two_columns, placeholder } = question;

  const questionText = question.text;
  const customScreening = question.custom_screening;
  const single = question.kind === 'Single';
  const multi = question.kind === 'Multi';
  const grid = question.kind === 'Grid';
  const needDropDown = question.need_dropdown;
  const isAge = question.is_age;
  const answers = question.detailed_answers || question.answers;
  const answerImages = question.answer_images;

  const [ radioAnswer, updateRadioAnswer ] = useState(null);
  const [ dropDownAnswer, updateDropDownAnswer ] = useState(null);
  const [ disabled, updateDisabled ] = useState(true);
  const [ checkboxAnswers, updateCheckboxAnswers ] = useState({});
  const [ gridAnswers, updateGridAnswers ] = useState({});
  const [ otherAnswer, setOtherAnswer ] = useState('');


  const isOther = (id) => ((id || '').match(/_-2/));
  const isRadioOtherSelected = () => (isOther(radioAnswer));
  const isCheckboxOtherSelected = () => (
    find(keys(checkboxAnswers), (item) => isOther(item))
  );

  const getImage = (id) => (
    answerImages?.find((item) => item?.id === id)?.image_url
  );

  const updateDisabledByKind = (kind) => {
    let newDisabled;
    let otherSelected;
    let enableWhenPresent;

    if (kind === 'radio') {
      otherSelected = isRadioOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = !radioAnswer || !enableWhenPresent;
    } else if (kind === 'checkbox') {
      otherSelected = isCheckboxOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = isEmpty(checkboxAnswers) || !enableWhenPresent;
    } else if (kind === 'grid') {
      newDisabled = isEmpty(gridAnswers) || keys(gridAnswers).length < answers.length;
    } else {
      newDisabled = !dropDownAnswer;
    }
    updateDisabled(newDisabled);
  };

  const updateOtherText = (event) => {
    setOtherAnswer(event.target.value);
  };

  useEffect(() => {
    updateDisabledByKind('checkbox');
  }, [ checkboxAnswers ]);
  useEffect(() => {
    updateDisabledByKind('dropDown');
  }, [ dropDownAnswer ]);
  useEffect(() => {
    updateDisabledByKind('radio');
  }, [ radioAnswer ]);
  useEffect(() => {
    updateDisabledByKind('grid');
  }, [ gridAnswers ]);

  useEffect(() => {
    if (multi) {
      updateDisabledByKind('checkbox');
    } else {
      updateDisabledByKind('radio');
    }
  }, [ otherAnswer ]);

  const updateCheckbox = (value) => {
    updateCheckboxAnswers({ ...value });
  };
  const updateDropDown = (value) => {
    updateDropDownAnswer(value);
  };
  const updateRadio = (value) => {
    updateRadioAnswer(value);
  };

  const saveOtherAnswer = () => {
    const shouldSaveOther = multi ?
      isCheckboxOtherSelected() : isRadioOtherSelected();
    if (shouldSaveOther) {
      if (!result.otherAnswers) {
        result.otherAnswers = {};
      }
      result.otherAnswers[question.name] = otherAnswer;
    }
  };

  const nextQuestion = () => {
    let realId, realAnswer;

    if (multi) {
      each(keys(checkboxAnswers), (key) => {
        if (checkboxAnswers[key]) {
          result.answers.push(key);
        }
      });
      updateCheckbox({});
    } else if (needDropDown && isAge) {
      result.answers.push(getAgeAnswerId(question, dropDownAnswer));
      result.selectedAge = dropDownAnswer;
      updateDropDown(null);
    } else if (needDropDown && !isAge) {
      realId = find(answers, (el) => el.id === dropDownAnswer) || {};
      realAnswer = realId.mapped_id || realId.id || dropDownAnswer;
      result.answers.push(realAnswer);
      updateDropDown(null);
    } else if (grid) {
      each(keys(gridAnswers), (key) => {
        const answer = `${key}_${gridAnswers[key]}`;
        result.answers.push(answer);
      });
      updateGridAnswers(null);
    } else {
      realId = find(answers, (el) => el.id === radioAnswer) || {};
      realAnswer = realId.mapped_id || realId.id || radioAnswer;
      result.answers.push(realAnswer);
      updateRadio(null);
    }
    saveOtherAnswer();
    setOtherAnswer('');
    nextStep();
  };

  const dropDownChange = (event) => {
    updateDropDown(event.target.value);
  };

  const withDropDown = () => (
    <div className="survey-options js-select">
      <select
        className="form-field"
        onChange={ (event) => { dropDownChange(event); } }
      >
        <option value="">{ placeholder }</option>
        {
          isAge &&
          map(getAgeOptions(question), (answer) => (
            <option value={ answer } key={ answer }>
              { answer }
            </option>
          ))
        }
        {
          !isAge &&
          map(answers, (answer) => (
            <option value={ answer.id } key={ answer.id }>
              { answer.label }
            </option>
          ))
        }
      </select>
    </div>
  );

  const withoutDropDown = () => (
    <div
      className={ classnames("survey-options", { "-screening": question.special_view, "-grid": grid }) }
    >
      { single &&
        <SingleOutput
          answers={answers}
          question={question}
          radioAnswer={radioAnswer}
          updateRadio={updateRadio}
          otherAnswer={otherAnswer}
          updateOtherText={updateOtherText}
        />
      }
      { multi &&
        <MultiOutput
          answers={answers}
          question={question}
          checkboxAnswers={checkboxAnswers}
          updateCheckbox={updateCheckbox}
          otherAnswer={otherAnswer}
          updateOtherText={updateOtherText}
        />
      }
      {
        grid &&
        <Grid
          question={ question }
          gridAnswers={ gridAnswers }
          updateGridAnswers={ updateGridAnswers }
          updateStateAndSend={ () => updateDisabledByKind("grid") }
        />
      }
    </div>
  );

  return (
    <>
      {/*if we want to show title in the top of screen*/}
      {/*<CommonHeader text={ questionText } />*/}

      <div className="survey-layout_content">
        <div className="survey-question-group">
          <div className="survey-question-group-item">
            <h1 className="survey-question-group-item_title" { ...renderRawHtml(questionText) } />
            <div
              className={
                classnames(
                  "survey-layout_container",
                  {
                    "-kids": kids,
                    "-two-columns": two_columns
                  }
                )
              }
            >
              { needDropDown && withDropDown() }
              { !needDropDown && withoutDropDown() }
            </div>
          </div>
        </div>
      </div>
      <div className="survey-footer">
        <FooterButton
          nextStep={ () => { nextQuestion(); } }
          options={ { disabled } }
        />
      </div>
    </>
  );
};

export default Screening;
