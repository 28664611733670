import React, { useContext, Fragment } from "react";
import { StateContext, LineDataContext } from "../../common/contexts";
import {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  setHashData, getHashData,
  getSelectedPlans, getTotal,
  setPlan, sendData
} from "../statistics";
import Lines from '../../common/lines';
import Landing from '../../common/landing';
import { unlimitedPlans, unlimitedAllPlansInclude } from "../plans_data/unlimited";
import { sharedPlans } from "../plans_data/shared";
import { prepaidPlans, prepaidAllPlansInclude } from "../plans_data/prepaid";
import UnlimitedPage from '../../common/unlimited_page';
import SharedPage from '../../common/shared_page';
import PrepaidPage from '../../common/prepaid_page';
import Checkout from '../../common/checkout';

const scrollTop = () => {
  document.querySelector('html').scrollTop = 0;
};

const states = [
  "Lines",
  "Landing",
  "Unlimited",
  "Metered",
  "Prepaid",
  "Checkout"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return(
    <Fragment>{ path == currentState ? children : null }</Fragment>
  );
};

const Router = ({ excludeShared }) => {
  const [ _, updatePath ] = useContext(StateContext);
  const data = {
    unlimited: { unlimitedPlans: unlimitedPlans, allPlansInclude: unlimitedAllPlansInclude },
    shared: { sharedPlans: sharedPlans },
    prepaid: { prepaidPlans: prepaidPlans, allPlansInclude: prepaidAllPlansInclude }
  }
  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Lines">
        <Lines
          nextStep={
            (event, linesCount) => {
              updatePath("Landing");
              setLinesCount(linesCount);
              setCurrentLine(1);
            }
          }
        />
      </Route>

      <Route path="Landing">
        <Landing
          excludeShared={excludeShared}
          nextStep={(event, planType) => {
            scrollTop();
            switch (planType){
              case 'unlimited':
                setHashData("startedFrom", "Unlimited");
                updatePath("Unlimited");
                break;
              case 'shared':
                setHashData("startedFrom", "Shared");
                updatePath("Shared");
                break;
              case 'prepaid':
                setHashData("startedFrom", "Prepaid");
                updatePath("Prepaid");
                break;
              default:
                updatePath("Unlimited");
            }
          }}
        />
      </Route>

      <Route path="Unlimited">
        <UnlimitedPage
          currentLine={ getCurrentLine() }
          onChangePage={ () => {
            scrollTop();
            updatePath("Landing");
          }}
          showChangePage={ getCurrentLine() < 2 }
          onPlanSelect={(event, planObject, source) => {
            scrollTop();
            setPlan(planObject, getCurrentLine(), source, "Unlimited");
            updatePath("Checkout");
          }}
        />
      </Route>
      <Route path="Shared">
        <SharedPage
          currentLine={ getCurrentLine() }
          onChangePage={ () => {
            scrollTop();
            updatePath("Landing");
          }}
          showChangePage={ getCurrentLine() < 2 }
          onPlanSelect={(event, planObject, source) => {
            scrollTop();
            setPlan(planObject, getCurrentLine(), source, "Shared");
            sendData();
            updatePath("");
          }}
        />
      </Route>
      <Route path="Prepaid">
        <PrepaidPage
          currentLine={ getCurrentLine() }
          onChangePage={ () => {
            scrollTop();
            updatePath("Landing");
          }}
          showChangePage={ getCurrentLine() < 2 }
          onPlanSelect={(event, planObject, source) => {
            scrollTop();
            setPlan(planObject, getCurrentLine(), source, "Prepaid");
            updatePath("Checkout");
          }}
        />
      </Route>

      <Route path="Checkout">
        <Checkout
          currentLine={ getCurrentLine() }
          selectedLines={ getLinesCount() }
          selectedPlans={ getSelectedPlans() }
          total={ getTotal() }
          nextStep={ (currentLine, isFinal) => {
            if (isFinal){
              sendData();
              updatePath("");
            }
            else{
              scrollTop();
              setCurrentLine(currentLine + 1);
              updatePath(getHashData("path")) }
          }}
        />
      </Route>

    </LineDataContext.Provider>
  );
};

export { Router, initialState };