import React, { useContext, useState, useCallback, useRef } from 'react';
import SmoothScroll from 'smooth-scroll';
import { map, min } from 'lodash';
import { Header } from '../common/header';
import { Tabs, Tab, TabContext } from '../common/tabs';
import { PlanDataProvider } from '../common/contexts';
import {
  getCurrentLine, setHashData, getHashData,
  getLinesCount, setHashDataForTemplatePlan,
  getCustomBuiltDataFromCurrentLine, increaseVariable
} from '../common/statistics';
import Modal from '../common/modal';
import AllPlansIncludeWithModal from '../common/allPlansIncludedWithModal';
import ButtonMore from '../common/buttonMore';
import renderRawHtml from '../common/render_raw_html';
import { preparePopupData, scrollTop } from '../common/helper';
import PlanPerk from '../common/planPerk';

const statisticPlansKey = {
  6: 'planCustomizePlanEntertainmentClickedCount',
  7: 'planCustomizePlanGamingClickedCount',
  8: 'planCustomizePlanShoppingClickedCount'
};

const OurBestPlans = ({
  plans, corePlans, openDetailsModal, onSelect,
  onCustomize, onNavigate, onSeeAllPlansClick
}) => {
  const { setTab } = useContext(TabContext);
  const navigate = () => setTab('buildYourOwn');
  const onBuildYouOwn = () => {
    const count = getHashData(`lines.${getCurrentLine()}.planBuildOwnLinkClickedCount`);
    setHashData(`lines.${getCurrentLine()}.planBuildOwnLinkClickedCount`, count + 1);
    if (onNavigate) {
      onNavigate();
    }
    navigate();
  };

  const onCustomizeCurrentPlan = (plan) => () => {
    const key = statisticPlansKey[plan.id];
    if (key) {
      const count = getHashData(`lines.${getCurrentLine()}.${key}`);
      setHashData(`lines.${getCurrentLine()}.${key}`, count + 1);
    }
    onCustomize(plan);
    setTimeout(navigate, 0);
  };

  const minimumPrice = min(map(corePlans, (el) => el.priceTitle));

  return (<div className="best-plans">
    <div className="best-plans_grid">
      {map(plans, (plan, idx) => (<div className="best-plans_grid-item" key={ `best-plans-${idx}` }>
        <div className="plate -border">
          <div className="best-plan">
            <div className="best-plan_top">
              <div className="best-plan_top-grid">
                <div className="best-plan_top-grid-item">
                  <h4 className="best-plan_title" { ...renderRawHtml(plan.title) } />
                  {plan.popular && <div className="best-plan_popular-label">Most popular</div>}
                </div>
                <div className="best-plan_top-grid-item">
                  <div className="best-plan_price">${ plan.priceTitle }/mo</div>
                  <div className="best-plan_price-note">+ taxes & fees</div>
                </div>
              </div>
            </div>
            <div className="best-plan_content">
              <div className="plate -gray">
                <div className="best-plan_content-section">
                  <h4 className="best-plan_content-section-title">
                    { plan.core.title }
                  </h4>
                  <div className="best-plan_content-section-descr">
                    { plan.core.description }
                  </div>
                </div>
                <div className="best-plan_content-section">
                  <h4 className="best-plan_content-section-title">
                    { plan.core.subtitle }
                  </h4>
                  <ul className="best-plan_core-features">
                    {
                      map(plan.core.perks, (perk, key) => (
                        <li className="best-plan_core-feature-item" key={ `best-plans-core-perks-${key}` }>
                          <div className="best-plan_core-feature-icon">
                            <svg className={ `icon -${perk.icon}` }>
                              <use xlinkHref={ `#svg_${perk.icon}` } />
                            </svg>
                          </div>
                          <div>{perk.title}</div>
                        </li>)
                      )
                    }
                  </ul>
                  <div className="best-plan_more">
                    <ButtonMore
                      onClickMore={
                        () => openDetailsModal({
                          title: plan.core.title,
                          description: plan.core.description,
                          descriptions: map(plan.core.descriptions, ({ title, description }) => ({ title, description }))
                        }
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {
                !!plan?.core?.includedTitle &&
                <div className="build-own-plan_perk-placeholder -prebuilt">
                  {
                    !!plan.core.includedTitle &&
                    <div className="title" { ...renderRawHtml(plan.core.includedTitle) } />
                  }
                  {
                    !!plan.core.includedDescription &&
                    <div className="description" { ...renderRawHtml(plan.core.includedDescription) } />
                  }
                </div>
              }
              <div className="best-plan_perks">
                { map(plan.perks, (perk, key) => (
                  <div className="best-plan_perks-item" key={ `best-plans-perks-${key}` }>
                    <div className="plate -gray">
                      <PlanPerk perk={ perk } openDetailsModal={ openDetailsModal }  />
                    </div>
                  </div>
                )) }
              </div>
            </div>
            <div className="best-plan_buttons">
              <div className="best-plan_button">
                <button className="button -bordered -full-width" onClick={ () => onSelect(plan) }>Select</button>
              </div>
              <div className="best-plan_button">
                <button className="button-link -md" onClick={ onCustomizeCurrentPlan(plan) }>
                  Customize this plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>)) }
    </div>
    <div className="best-plans_grid -center">
      <div className="best-plans_grid-item -width-1-3">
        <div className="plate -border -button" onClick={ onBuildYouOwn }>
          <div className="best-plans_button-grid">
            <div className="best-plans_button-grid-item -text">
              <h4 className="plate_title best-plans_button-title">Want to build your own?</h4>
              <div className="best-plans_button-descr">
                <b>Starting at ${minimumPrice}</b>, you can customize your own plan.
              </div>
            </div>
            <div className="best-plans_button-grid-item">
              <svg className={ `icon -arrow best-plans_button-icon` }>
                <use xlinkHref={ `#svg_arrow` } />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="back-button_wrapper">
      <div className="plate -border -button" onClick={ onSeeAllPlansClick }>
        <div className="back-button">
          See all plans
          <svg className="icon -more">
            <use xlinkHref={ `#svg_caret` } />
          </svg>
        </div>
      </div>
    </div>
  </div>);
};

const BuildYourOwnPlan = ({
  perkGroups,
  plans,
  initialCore,
  initialPerks,
  openDetailsModal,
  onSelect,
  onSeeAllPlansClick,
  scrollToTabHead
}) => {
  const [ selectedPlan, setSelectedPlan ] = useState(() => {
    return plans.find((plan) => plan.core.id === initialCore?.id) || null;
  });
  const [ selectedPerks, setSelectedPerks ] = useState(initialPerks || []);
  const [ selectPerkStep, setSelectPerkStep ] = useState(false);
  const [ temporarySelectedPerks, setTemporarySelectedPerks ] = useState([]);

  const isPerkChecked = useCallback(
    (perkId) => selectedPerks.some((perk) => perk.id === perkId),
    [ selectedPerks ]
  );

  const isCoreChecked = useCallback(
    (coreId) => selectedPlan?.core.id === coreId,
    [ selectedPlan?.core.id ]
  );

  const onPerkClick = (perk) => () => {
    setSelectedPerks([ perk ]);
  };

  const onCoreChange = (plan) => () => {
    setSelectedPlan(plan);
  };

  const onContinue = () => {
    onSelect({ plan: selectedPlan, perks: selectedPerks });
  };

  const classToToggle = "change-perk-step";
  const bodyEl = document.body || document.querySelector('body');

  const toggleStep = () => {
    setSelectPerkStep(!selectPerkStep);
    bodyEl.classList.toggle(classToToggle);
  };

  const handleSelectAddOn = () => {
    if (bodyEl.classList.contains(classToToggle)) {
      bodyEl.classList.remove(classToToggle);
    }
    onContinue();
  };

  const handleChangeAddon = () => {
    setTemporarySelectedPerks(selectedPerks);
    increaseVariable("planClickChangeAddOnCount", getCurrentLine());
    scrollTop();
    toggleStep();
  };

  const handleBackClick = () => {
    setSelectedPerks(temporarySelectedPerks);
    toggleStep();
    scrollToTabHead();
  };

  const handleToChooseAddOn = () => {
    setTemporarySelectedPerks(selectedPerks);
    scrollTop();
    toggleStep();
  };

  const planPrice = (plan) => {
    const perkPrice = selectedPerks.length ? (selectedPerks[0].priceValue || 0) : 0;
    return (plan?.price || 0) + perkPrice;
  };

  return (<div className="build-own">
    <div className="build-own_sections">
      {
        !selectPerkStep ? (
          <div className="build-own_section">
            <div className="build-own_section-title-block">
              <h3 className="build-own_section-title">Start with a network option.</h3>
            </div>
            <div className="build-own_grid">
              {
                map(plans, (plan, key) => (
                  <div className="build-own_grid-item" key={ `build-own-core-perks-${key}` }>
                    <label className={ `plate -border -label ${isCoreChecked(plan.core.id) ? '-active' : ''}` }>
                      <div className="radio-plate">
                        <div className="radio -in-plate">
                          <input name="core" type="radio" checked={ isCoreChecked(plan.core.id) } onChange={ onCoreChange(plan) } />
                          <div className="radio_icon" />
                        </div>
                        <div className="build-own-plan">
                          <div className="build-own-plan_top">
                            <div className="build-own-plan_top-grid">
                              <div className="build-own-plan_top-grid-item">
                                <h4 className="build-own-plan_title">{plan.name}</h4>
                              </div>
                              <div className="build-own-plan_top-grid-item">
                                <div className="build-own-plan_price">${ planPrice(plan) }/mo</div>
                              </div>
                            </div>
                            <div className="build-own-plan_descr">{ plan.core.shortDescription}</div>
                          </div>
                          <div className="build-own-plan_content">
                            <h4 className="build-own-plan_content-title">
                              { plan.core.subtitle }
                            </h4>
                            <ul className="build-own-plan_features">
                              {
                                map(plan.core.perks, (perk, key) => (
                                  <li className="build-own-plan_feature-item" key={ `build-own-perks-${key}` }>
                                    <div className="build-own-plan_feature-icon">
                                      <svg className={ `icon -${perk.icon}` }>
                                        <use xlinkHref={ `#svg_${perk.icon}` } />
                                      </svg>
                                    </div>
                                    <div>{perk.title}</div>
                                  </li>
                                ))
                              }
                            </ul>
                            <div className="build-own-plan_more">
                              <ButtonMore
                                onClickMore={
                                  () => openDetailsModal({
                                    title: plan.core.title,
                                    description: plan.core.description,
                                    descriptions: map(plan.core.descriptions, ({ title, description }) => ({ title, description }))
                                  }
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="build-own-plan_bottom">
                            {
                              selectedPerks.length > 0 ? (
                                map(selectedPerks, (perk, key) => (
                                  <div className="build-own-plan_perk" key={ `perk-${key}` }>
                                    {
                                      perk.noPerk ? (
                                        <div className="plan-perk">
                                          <h4 className="plan-perk_title">Included add-on declined</h4>
                                          <div className="plan-perk_descr">
                                            This plan’s monthly price has been changed to reflect the ${perk.price}/mo off from declining the included add-on.
                                          </div>
                                          <div className="build-own-plan_perk-link -button">
                                            <button className="button -bordered -sm" onClick={ handleChangeAddon }>
                                              Change add-on
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <PlanPerk
                                          perk={ perk }
                                          openDetailsModal={ openDetailsModal }
                                          onChangeAddon={ handleChangeAddon }
                                        />
                                      )
                                    }
                                  </div>
                                ))
                              ) : (
                                <>
                                  {
                                    !!plan?.core?.includedTitle &&
                                    <div className="build-own-plan_perk-placeholder">
                                      {
                                        !!plan.core.includedTitle &&
                                        <div className="title" { ...renderRawHtml(plan.core.includedTitle) } />
                                      }
                                      {
                                        !!plan.core.includedDescription &&
                                        <div className="description" { ...renderRawHtml(plan.core.includedDescription) } />
                                      }
                                    </div>
                                  }
                                </>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                ))
              }
            </div>
            <div className="back-button_wrapper">
              <div className="plate -border -button" onClick={ onSeeAllPlansClick }>
                <div className="back-button">
                  See all plans
                  <svg className="icon -more">
                    <use xlinkHref={ `#svg_caret` } />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="build-own_section">
            <div className="build-own_back" >
              <div className="build-own_back-link" onClick={ handleBackClick }>
                <svg className="icon -back">
                  <use xlinkHref={ `#svg_caret` } />
                </svg>
                Back
              </div>
            </div>
            <div className="build-own_section-title-block">
              <h3 className="build-own_section-title">Select your included add-on.</h3>
            </div>

            <div className="build-own_perks-groups">
              {
                map(perkGroups, ({ title, perks }, key) => (<div className="build-own_perks-group" key={ `perks-group-${key}` }>
                  <h4 className="build-own_perks-group-title">{title}</h4>
                  <div className="build-own_grid">
                    {
                      map(perks, (perk, idx) => (
                        <div className="build-own_grid-item" key={ `perks-group-perk-${idx}` }>
                          <label className={ `plate -border -label ${isPerkChecked(perk.id) ? '-active' : ''}` }>
                            <div className="radio-plate">
                              <div className="radio -in-plate">
                                <input type="radio" name="perks" checked={ isPerkChecked(perk.id) } onChange={ onPerkClick(perk) } />
                                <div className="radio_icon" />
                              </div>
                              <PlanPerk perk={ perk } openDetailsModal={ openDetailsModal } />
                            </div>
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </div>
                ))
              }
            </div>
          </div>
        )}
    </div>

    <div className="build-own_footer">
      <div className="build-own_footer-grid">
        <div className="build-own_footer-grid-item">
          <div className="build-own_footer-line-sticky">Line {getCurrentLine()}</div>
          <div className="build-own_footer-price">
            <span className="build-own_footer-price-num">${planPrice(selectedPlan)}</span>/mo
          </div>
          <div className="build-own_footer-note">Plus taxes & fees.</div>
        </div>
        <div className="build-own_footer-grid-item">
          {
            !selectPerkStep ? (
              <button
                className="button"
                disabled={ selectedPlan === null }
                onClick={ selectedPerks.length === 0 ? handleToChooseAddOn : onContinue }
              >
                { selectedPerks.length === 0 ? 'Choose your add-on' : 'Continue' }
              </button>
            ) : (
              <button
                className="button"
                disabled={ selectedPerks.length === 0 }
                onClick={ handleSelectAddOn }
              >
                Continue
              </button>
            )}
        </div>
      </div>
    </div>
  </div>);
};

const OverviewPage = ({ onPlanSelect, onSeeAllPlans, buildYourOwnFirst = false }) => {
  const { allPlansInclude, plans_prebuild,  plans_construct, perks, allPerksFlatten } = useContext(PlanDataProvider);
  const getLinesData = () => (getCustomBuiltDataFromCurrentLine(plans_prebuild, plans_construct, allPerksFlatten));
  const [ modalData, setModalData ] = useState(null);
  const [ initialPredefinedPlan, setInitialPredefinedPlan ] = useState(() => (getLinesData().templatePlan));
  const [ initialCore, setInitialCore ] = useState(() => (getLinesData().corePlan));
  const [ initialPerks, setInitialPerks ] = useState(() => (getLinesData().perks));
  const [ initialTabName ] = useState(() => {
    if (initialCore) {
      return "buildYourOwn";
    } else if (getLinesData().prebuildPlan) {
      return "bestPlans";
    }
    return null;
  });
  const [ fromWelcomeToBasic, setFromWelcomeToBasic ] = useState(null);
  const [ smoothScroll ] = useState(() => (new SmoothScroll()));
  const headRef = useRef(null);
  const closeModal = () => {
    setFromWelcomeToBasic(null);
    setModalData(null);
  };
  const closeToBaseModalAndUpdateStatistic = () => {
    const line = getCurrentLine();
    const key = `lines.${line}.planBaseFromWelcomePopupCancelClickCount`;
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    closeModal();
  };
  const openModal = ({ title, description, descriptions }) => {
    setModalData(preparePopupData(title, description, descriptions));
  };

  const onBestPlanSelect = (plan) => {
    const { planId } = getHashData('lines.1');
    const linesCount = getLinesCount();
    if (linesCount !== 1 && planId === 5) {
      setFromWelcomeToBasic(plan);
    } else {
      onPlanSelect(plan);
    }
  };

  const onCustomPlanSelect = ({ plan, perks }) => {
    const { planId } = getHashData('lines.1');
    const line = getCurrentLine();
    setHashData(`lines.${line}.planCustomBuilt`, true);
    setHashDataForTemplatePlan(line, initialPredefinedPlan, plan, perks);
    const selectedPlan = { ...plan, perks };
    if (planId === 5) {
      setFromWelcomeToBasic(selectedPlan);
    } else {
      onPlanSelect(selectedPlan);
    }
  };

  const onCustomize = (plan) => {
    const { core, perks } = plan;
    setInitialPredefinedPlan(plan);
    setInitialCore(core);
    setInitialPerks(perks);
    scrollToTabHead();
  };

  const resetInitial = () => {
    setInitialPredefinedPlan(null);
    setInitialCore(null);
    setInitialPerks(null);
  };

  const onBestPlansClick = () => {
    const count = getHashData(`lines.${getCurrentLine()}.planOurBestPlansTabClickedCount`);
    setHashData(`lines.${getCurrentLine()}.planOurBestPlansTabClickedCount`, count + 1);
    resetInitial();
  };

  const onBuildYourOwnClick = () => {
    const count = getHashData(`lines.${getCurrentLine()}.planBuildYourOwnTabClickedCount`);
    setHashData(`lines.${getCurrentLine()}.planBuildYourOwnTabClickedCount`, count + 1);
  };

  const scrollToTabHead = () => {
    const scrollOptions = { speed: 300, offset: 20 };
    smoothScroll.animateScroll(headRef.current, null, scrollOptions);
  };

  const onConfirm = (key) => () => {
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    onPlanSelect(fromWelcomeToBasic, false, true);
    closeModal();
  };

  const onSeeAllPlansClick = () => {
    const currentLine = getCurrentLine();
    const key = `lines.${currentLine}.planClickSeeAllPlansNonWelcomePlansCount`;
    const value = getHashData(key);
    setHashData(key, value + 1);
    onSeeAllPlans();
  };

  return (<div className="content">
    <Header />
    <div className="builder">
      <div className="inner-content -percent-desktop">
        <div className="builder_top">
          <div className="builder_top-col">
            <h2 className="builder_title">Pick your perfect plan.</h2>
            <div className="builder_subtitle">
              Choose from our best plans or build your own to suit your needs.
            </div>
          </div>
          <div className="builder_top-col">
            <AllPlansIncludeWithModal plans={ allPlansInclude } openDetailsModal={ openModal } />
          </div>
        </div>
        <Tabs ref={ headRef } initialTabName={ initialTabName }>
          <Tab title="Our best plans" name="bestPlans" weight={ 2 } onClick={ onBestPlansClick }>
            <OurBestPlans
              plans={ plans_prebuild }
              corePlans={ plans_construct }
              openDetailsModal={ openModal }
              onSelect={ onBestPlanSelect }
              onCustomize={ onCustomize }
              onNavigate={ scrollToTabHead }
              onSeeAllPlansClick={ onSeeAllPlansClick }
            />
          </Tab>
          <Tab title="Build your own" name="buildYourOwn" weight={ buildYourOwnFirst ? 1 : 3 } onClick={ onBuildYourOwnClick }>
            <BuildYourOwnPlan
              initialCore={ initialCore }
              initialPerks={ initialPerks }
              perkGroups={ perks }
              plans={ plans_construct }
              openDetailsModal={ openModal }
              onSelect={ onCustomPlanSelect }
              scrollToTabHead={ scrollToTabHead }
              onSeeAllPlansClick={ onSeeAllPlansClick }
            />
          </Tab>
        </Tabs>
      </div>
    </div>
    {!!fromWelcomeToBasic && <Modal title="You'll need to choose new plans for each of your phone lines." handleClose={ closeToBaseModalAndUpdateStatistic }>
      <div className="modal_inner">
        <div className="modal_empty-content" />
        <div className="modal-actions">
          <button
            className="button"
            onClick={ onConfirm(`lines.${getCurrentLine()}.planBaseFromWelcomePopupContinueClickCount`) }
          >
            Continue
          </button>
          <button className="button -invert" onClick={ closeToBaseModalAndUpdateStatistic }>Cancel</button>
        </div>
      </div>
    </Modal>}
    {modalData && <Modal title={ modalData.title } handleClose={ closeModal } showFooterButton>
      <div className="modal_content-inner" { ...renderRawHtml(modalData.description) } />
    </Modal>}
  </div>);
};

export default OverviewPage;
