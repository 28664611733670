import React from 'react';
import remoteFiles from '../../../common/remote_files';

const AboutStory = () => {
  const { aboutStory } = remoteFiles.visibleCom;
  return (
    <section className="about-story">
      <div className="about-story_text">
        <h3 className="about-story_title">
          Our Story
        </h3>
        <div className="about-story_p">
          It started with an idea: Make wireless service simple and accessible to everyone.
          To us, that meant one plan, with multiple ways to save, on the Verizon network—no strings attached.
          Stay connected to what matters most.
        </div>
      </div>
      <div
        className="about-story_image"
        style={ { backgroundImage: `url(${aboutStory})` } }
      />
    </section>
  );
};

export default AboutStory;
