import React, { createContext, useContext, useState, useLayoutEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { filter, findIndex } from "lodash";
import runPreload from '../logic/preload.js';
import Forward from '../logic/forward.js';
import saveUserData from '../logic/save_user_data.js';
import PageLoader from '../../common/components/page_loader.js';
import Intro from '../../common/components/intro.js';
import Screening from '../../common/components/screening.js';
import Qualified from '../../common/components/qualified.js';
import CustomQualified from '../../common/components/custom_qualified.js';
import Markup from '../../common/components/metrics/markup.js';
import { fraudContext } from "../../common/contexts";
import { LastStepsComponents } from '../../common/logic/last_steps';
import CustomMetricsInLocation from "../../common/components/metrics/custom_metrics_in_location";
import { customMetricsByLocation } from "../../common/logic/eligible";
import WithOpenEnd from '../../common/components/metrics/customMetricsOpenEnd/withOpenEnd';
import IntroFivePoint from './intro_five_point.js';
import Swipe from './swipe.js';
import Concept from './concept.js';

const resultContext = createContext(
  {
    survey_status: '',
    answers: [],
    loops: []
  }
);

const Index = ({
  data, updateStep, updateConceptStep, updateToolIndex, updateConceptIndex,
  updateQuestionIndex, qIndex, tIndex, cIndex, step, conceptStep,
  changeLoadingState, loading
}) => {
  const {
    concepts, save_url, tools, five_pt_concepts, scorings,
    demographics, optional_demographics,
    screenings, translations, qualified_custom_text,
    markup_tool, attribute_ratings
  } = data;

  useLayoutEffect(() => {
    runPreload(scorings, attribute_ratings, concepts, changeLoadingState);
  }, []);

  const result = useContext(resultContext);
  const { projectId, antiFraud, fraudProcessing, gaTrackingId } = useContext(fraudContext);

  const saveRequest = (status) => {
    saveUserData(save_url, status, result, data);
  };

  const [ forwardEntity ] = useState(new Forward({
    updateStep,
    runSave: (status) => saveRequest(status),
    updateToolIndex,
    updateConceptStep,
    updateConceptIndex,
    updateQuestionIndex
  }));

  const currentQuestion = (questions) => (questions[qIndex]);
  const currentSwipeTool = () => (tools[tIndex]);
  const currentConcept = () => (five_pt_concepts[cIndex]);
  const goForward = () => {
    forwardEntity.forward(step, data, result, projectId, antiFraud, gaTrackingId);
  };
  const goNextQuestion = (questions) => {
    forwardEntity.nextQuestion(
      qIndex, questions, result, goForward
    );
  };
  const goNextTool = () => {
    forwardEntity.nextTool(tIndex, tools, goForward);
  };
  const goNextConcept = () => {
    forwardEntity.nextConceptStep(
      conceptStep, cIndex, currentConcept(), data, goForward
    );
  };

  const conceptsForCustom = useMemo(() => {
    return filter(concepts, (el1) => {
      return findIndex(data.custom_metrics_concepts, (el2) => {
        return el1.confirmit_concept_id === el2.confirmit_concept_id;
      }) > -1;
    });
  });

  return (
    <>
      <PageLoader loading={ loading || fraudProcessing } />
      <section className="survey-layout">
        {
          !step &&
          <Intro
            task={ translations.base_intro }
            nextStep={ goForward }
          />
        }
        {
          step === 2 &&
          <Screening
            question={ currentQuestion(demographics) }
            nextStep={ () => { goNextQuestion(demographics); } }
            result={ result }
          />
        }
        {
          step === 3 &&
          <Screening
            question={ currentQuestion(optional_demographics) }
            nextStep={ () => { goNextQuestion(optional_demographics); } }
            result={ result }
          />
        }
        {
          step === 4 &&
          <Screening
            question={ currentQuestion(screenings) }
            nextStep={ () => { goNextQuestion(screenings); } }
            result={ result }
          />
        }
        {
          step === 5 && !qualified_custom_text &&
          <Qualified
            title={ translations.express_qualify_title }
            descriptionTop={ translations.express_qualify_description_top }
            descriptionBottom={ translations.express_qualify_description_bottom }
            descriptionFooter={ translations.express_qualify_description_footer }
            nextStep={ goForward }
          />
        }
        {
          step === 5 && !!qualified_custom_text &&
          <CustomQualified
            content={ qualified_custom_text }
            nextStep={ goForward }
          />
        }
        {
          step === 6 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "at_the_start")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "at_the_start")); } }
            result={ result }
            mainResult={ result }
          />
        }
        {
          step === 7 &&
          <CustomMetricsInLocation
            location="at_the_start"
            concepts={ conceptsForCustom }
            data={ data }
            goForward={ goForward }
            result={ result }
          />
        }

        {
          step === 8 &&
          <Swipe tool={ currentSwipeTool() } nextStep={ () => { goNextTool(); } } />
        }
        {
          step === 9 &&
          <IntroFivePoint
            textTop={ translations.five_point_first_intro_top }
            textBottom={ translations.five_point_first_intro_bottom }
            nextStep={ goForward }
          />
        }
        {
          step === 10 &&
          <Concept
            concept={ currentConcept() }
            data={ data }
            nextStep={ () => { goNextConcept(); } }
            index={ conceptStep }
            conceptIndex={ cIndex }
            result={ result }
          />
        }
        {
          step === 11 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "default")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "default")); } }
            result={ result }
            mainResult={ result }
          />
        }
        {
          step === 12 &&
          <CustomMetricsInLocation
            location="default"
            concepts={ conceptsForCustom }
            data={ data }
            goForward={ goForward }
            result={ result }
          />
        }
        {
          step === 13 &&
          <CustomMetricsInLocation
            location="at_the_end"
            concepts={ conceptsForCustom }
            data={ data }
            goForward={ goForward }
            result={ result }
          />
        }
        {
          step === 14 &&
          <Markup
            conceptIds={ markup_tool.concept_ids }
            markupTool={ markup_tool }
            nextStep={ goForward }
            result={ result }
          />
        }
        {
          step === 15 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "at_the_end")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "at_the_end")); } }
            result={ result }
            mainResult={ result }
          />
        }
        <LastStepsComponents
          step={ step }
          lastStepsFrom={ 16 }
          translations={ translations }
          data={ data }
          nextStep={ goForward }
        />
      </section>
    </>
  );
};


const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    updateStep: (step) => { return dispatch({ type: "UPDATE_STEP", value: step }); },
    changeLoadingState: (value) => { return dispatch({ type: "UPDATE_LOADING", value }); },
    updateConceptStep: (step) => { return dispatch({ type: "UPDATE_CONCEPT_STEP", value: step }); },
    updateConceptIndex: (cIndex) => { return dispatch({ type: "UPDATE_CONCEPT_INDEX", value: cIndex }); },
    updateToolIndex: (tIndex) => { return dispatch({ type: "UPDATE_TOOL_INDEX", value: tIndex }); },
    updateQuestionIndex: (qIndex) => { return dispatch({ type: "UPDATE_QUESTION_INDEX", value: qIndex }); }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
