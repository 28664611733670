import Http from '../../../common/http';

class CreateQuotaService {
  constructor({ checkStatusPath, isLaunched, setSuccessMessage, setErrorMessage }) {
    this.url = checkStatusPath;
    this.timeout = 3000;
    this.intervalId = null;
    this.isLaunched = isLaunched;
    this.setSuccessMessage = setSuccessMessage;
    this.setErrorMessage = setErrorMessage;
  }

  stopChecking() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  checkStatus(onStart, onFinish, init) {
    if (this.isLaunched) {
      return;
    }
    if (this.intervalId) {
      return;
    }
    onStart && onStart();
    const initMessage = init ?
      "Working on creating quotas..." :
      "Charge has been succeeded. Working on creating quotas now...";
    this.setSuccessMessage(initMessage);

    this.intervalId = setInterval(
      () => {
        this.checkFunction(onFinish);
      },
      this.timeout
    );
  }

  checkFunction(onFinish) {
    Http.get(this.url).then(
      (response) => {
        const redirectPath = response.data.redirect_path;
        if (redirectPath) {
          this.stopChecking();
          window.location.replace(redirectPath);
          this.setSuccessMessage('Project has been launched!');
          onFinish && onFinish(true);
        }
      },
      (response) => {
        this.stopChecking();
        const message = response.data.error || 'Something went wrong with create quotas request';
        this.setErrorMessage(message);
        onFinish && onFinish(false);
      }
    );
  }
}

export default CreateQuotaService;
