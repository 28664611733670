import { baseFakeData } from '../../common/baseFakeData';
export const fakeData = {
  ...baseFakeData,
  ...{
    email: "jordandoe@gmail.com",
    phone: "+1 123-456-7890",
    phoneConfirmation: "123456",
    firstName: "Jordan",
    lastName: "Doe",
    address: { primary: "123 Sesame Street", secondary: "New York, NY 10000" },
    billingAddress: "123 Sesame Street<br/>New York, NY 10000",
    manualAddress: "123 Sesame Street",
    manualCity: "New York",
    manualState: "NY",
    manualZipCode: "10000",
    birthDate: "01-01-2000",
    ssn: "123-45-6789",
    cardNumber: "1234 1234 1234 1234",
    cardExpiration: "01/30",
    cardCvv: "999",
    scanCardNumber: "1234 1234 1234 1234",
    scanCardExpiration: "01/30",
    scanCardCvv: "999",
    cardPin: '1234'
  }
};
