import React, { useState, useContext } from 'react';
import classnames from "classnames";
import { map, filter, find } from 'lodash';
import { shareProject, revokeProjectFromSharing } from '../../requests';
import { IndexData } from "./contexts";

const ProjectSharingButtons = ({ mode, project }) => {
  const [ loading, setLoading ] = useState(false);

  const data = useContext(IndexData);

  const sharedWith = () => {
    return filter(data.clients, (el) => {
      return project.shared_for_ids.findIndex(el.id) > -1;
    });
  };

  const notShared = () => {
    return filter(data.clients, (el) => {
      return project.shared_for_ids.findIndex(el.id) === -1;
    });
  };

  const shareFor = (event, client) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    shareProject(project.share_path, client.id,
      () => {
        const p = find(data.projects, (el) => (el.id == project.id));
        p.shared_for_ids.push(client.id);
        data.setProjects([ ...data.projects ]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const revokeFrom = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    revokeProjectFromSharing(project.revoke_path, client.id,
      () => {
        const p = find(data.projects, (el) => (el.id == project.id));
        p.shared_for_ids.remove(client.id);
        data.setProjects([ ...data.projects ]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className={ classnames("sharing-buttons", { '-center-side': mode === 'list' }) }>
      {
        !!sharedWith().length &&
        <div className="dropdown sharing-item">
          <button
            className="button -clients-button revoke" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false"
          >
            <svg className="icon -eye"><use xlinkHref="#svg_eye" /></svg>
            <span>{sharedWith().length}</span>
          </button>
          <div className={ classnames("dropdown_container", { '-cards': mode === 'cards', '-list': mode === 'list' }) }>
            <div className="dropdown_inset">
              <div className="dropdown_content">
                <ul>
                  <li className="-title"><span>Shared with</span></li>
                  {
                    map(sharedWith(), (client, i) => (
                      <li key={ i } className="-with-padding">
                        <div className="name">{ client.name }</div>
                        <button className="button" onClick={ (e) => {revokeFrom(e, client);} }>
                          <svg className="icon -i-delete"><use xlinkHref="#svg_i-delete" /></svg>
                        </button>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      }

      {
        !!notShared().length &&
        <div className="dropdown sharing-item">
          <button
            className="button -clients-button share" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false"
          >
            <svg className="icon -share"><use xlinkHref="#svg_share" /></svg>
          </button>
          <div className={ classnames("dropdown_container", { '-cards': mode === 'cards', '-list': mode === 'list' }) }>
            <div className="dropdown_inset">
              <div className="dropdown_content">
                <ul>
                  <li className="-title"><span>Share with</span></li>
                  {
                    map(notShared(), (client, i) => (
                      <li key={ i }><a onClick={ (e) => shareFor(e, client) }>{ client.name }</a></li>
                    ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default ProjectSharingButtons;
