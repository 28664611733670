import Http from '../../../common/http';

const sendRequest = (path, params, goodCallback, badCallback) => {
  Http.post(path, params).then(
    (response) => {
      goodCallback(response);
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback();
    }
  );
};

const checkRequest = (path, goodCallback, badCallback) => {
  Http.get(path).then(
    (response) => {
      goodCallback(response);
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback();
    }
  );
};

export { sendRequest, checkRequest };
