import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const ScreenerOnEnglish = ({ model, setModel }) => {
  const handleOnChange = (event) => {
    setModel({ ...model, screenerOnEnglish: event.target.checked });
  };

  return (
    <div className="form_section" >
      <div className="form_section-title -grid">
        <div className="form_section-title-item -grid">
          <div className="toggle">
            <input name="project[screener_on_english]" type="hidden" value="0" autoComplete="off" />
            <input
              className="hide"
              type="checkbox"
              checked={ model.screenerOnEnglish }
              onChange={ handleOnChange }
              name="project[screener_on_english]"
              value="1"
              id="project_screener_on_english"
            />
            <label className="form-switch" htmlFor="project_screener_on_english">screenerOnEnglish</label>
          </div>
          <h3>Force screener questions to display in English</h3>
        </div>

        <div className="form_section-title-item">
          <div className="info-tooltip">
            <div className="info-tooltip_control">
              <SvgIcon name="i-info" />
            </div>

            <div className="info-tooltip_drop">
              <div className="info-tooltip_drop-inner">
                If the language is not English, it will display intro,
                {' '}
                demographics and optional demographics in English.
                {' '}
                Custom screener questions will be displayed in language they entered.
                {' '}
                Brand Screening questions will be displayed in project's language.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenerOnEnglish;
