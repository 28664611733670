import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html';
import Loader from "../../../../../../../images/icons/loader.gif";
import HintInPopup from '../../../common/hint-in-popup';
import { destroyProject } from '../../requests';
import { IndexData } from './contexts';

const ProjectAsTableRow = ({ project }) => {
  const [ loading, setLoading ] = useState(false);
  const { admin, demoUser, projectIsShared, loadProjects } = useContext(IndexData);
  const canDelete = (admin || (!demoUser && !projectIsShared));
  const langPostfix = project.more_than_one_lang ? ` (${project.language_title})` : "";

  return (
    <tr className={ classnames("body-row", { "-copying": project.copying }) }>
      <td>
        {
          project.base &&
          <HintInPopup
            className="-star"
            dropClassName="-bottom-right"
            hintText="Base project. Can't be removed"
          >
            <svg className="icon -i-star">
              <use xlinkHref="#svg_i-star" />
            </svg>
          </HintInPopup>
        }
        {
          !project.base && !project.copying && canDelete &&
          <a
            className="delete"
            onClick={ () => {
              destroyProject(
                `${project.title} - ${project.country_name}${langPostfix}`,
                project.destroy_path,
                project.status === "Unpublished",
                setLoading,
                loadProjects
              );
            } }
          >
            <svg className="icon -i-delete">
              <use xlinkHref="#svg_i-delete" />
            </svg>
          </a>
        }
        {
          loading &&
          <div className="dropdown -actions-menu">
            <img width="24px" height="24px" src={ Loader } />
          </div>
        }
      </td>
      <td className="project-title">
        { `${project.country_name}${langPostfix}` }
        { project.copying && " (copying)" }
      </td>
      { admin && <td className="project-client">{project.client_name}</td> }
      <td className="project-created_at" { ...renderRawHtml(project.created_at_hint) } />
      <td className="project-in_field_at">
        { project.in_field_at_hint ? project.in_field_at_hint : <span>&mdash;</span> }
      </td>
      <td className="project-closed_at">
        { project.closed_at_hint ? project.closed_at_hint : <span>&mdash;</span>  }
      </td>
      <td className="project-status">
        <div className={ classnames('project-preview_status', `-${project.status_name}`) }>
          { project.status }
        </div>
      </td>
      <td className="project-completes">
        { project.respondent_count ? project.respondent_count : <span>&mdash;</span> }
      </td>
      <td className="project-target">
        { project.required_nsize ? project.required_nsize : <span>&mdash;</span> }
      </td>
      <td className="project-actions">
        <div className="dropdown header_button -right-sided -no-min-width">
          <span
            className="dropdown_toggle button"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg className="icon -vertical-vector">
              <use xlinkHref="#svg_vertical-vector" />
            </svg>
          </span>
          <div className="dropdown_container -corner-right -to-right-bottom">
            <div className="dropdown_inset">
              <div className="dropdown_content">
                <ul>
                  {
                    (!project.remote_respondents || project.force_edit_project) &&
                    <li>
                      <a className="project-preview_link" href={ project.edit_path }>
                        {
                          project.remote_respondents ?
                            <>Edit Project</> :
                            <>Project Setup</>
                        }
                      </a>
                      {
                        project.remote_respondents &&
                        <span className="project-preview_link-addition">&nbsp;(Limited)</span>
                      }
                    </li>
                  }
                  <li>
                    <a className="project-preview_link" href={ project.report_path }>
                      Report
                    </a>
                  </li>
                  {
                    (project.can_review_respondents) &&
                    <li>
                      <a className="project-preview_link" href={ project.review_respondents_path }>
                        Review Respondents
                      </a>
                    </li>
                  }
                  {
                    (project.can_manage_concept_tags) &&
                    <li>
                      <a className="project-preview_link" href={ project.concept_tags_path }>
                        Concept Tags
                      </a>
                    </li>
                  }
                  {
                    !!project.can_edit_survey_flow &&
                    <li>
                      <a
                        className="project-preview_link"
                        href={ project.edit_survey_flow }
                      >
                        Edit Survey Flow
                      </a>
                    </li>
                  }
                  {
                    project.can_preview &&
                    <li>
                      <a
                        className="project-preview_link"
                        target="blank"
                        href={ project.respondent_survey_path }
                      >
                        Preview
                      </a>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ProjectAsTableRow;
