import React, { useRef, useState } from 'react';
import { isEmpty } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import SliderRadioButtons from "../common/sliderRadioButtons";
import RadioButtons from "../common/radioButtons";
import PageLoader from "../../common/PageLoader";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import HiddenFields from "./HiddenFields";
import PDFUploader from "./PDFUploader";


const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    projectId: data.id,
    conceptsMode: data.concepts_mode || '',
    questionsShown: data.verizon_express_setting.questions_shown || '',
    conceptType: data.verizon_express_setting.concept_type || '',
    actionWord: data.verizon_express_setting.action_word || '',
    priced: data.verizon_express_setting.priced || '',
    payFrequency: data.verizon_express_setting.pay_frequency || '',
    cit: data.verizon_express_setting.cit || '',
    processDescription: data.verizon_express_setting.process_description || '',
    feeAndPolicy: data.verizon_express_setting.fee_and_policy || '',
    askAwareness: data.verizon_express_setting.ask_awareness || '',
    existingAwareness: data.verizon_express_setting.existing_awareness || '',
    awarenessVerb: data.verizon_express_setting.awareness_verb || '',
    awarenessEstimate: data.verizon_express_setting.awareness_estimate || '',
    expressImages: data.express_images || [],
    errors
  });

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  // console.log('data', data);
  // console.log(options);
  // console.log(errors);

  return (
    <WithSubmit formId="form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header title="Adding Concepts (CAT)" />
        <div className="content_body">
          <div className="form">
            {!!options.jid && <PageLoader options={ options } />}

            <PDFUploader
              title="Concept"
              model={ model }
              setModel={ setModel }
              errors={ errors }
            />

            <div className="form_section">
              <SliderRadioButtons
                title="Project Type"
                initValue={ model.conceptsMode }
                options={ options.concepts_mode_options }
                onChange={ (v) => {setModel({ ...model, conceptsMode: v });} }
                errors={ errors['concepts_mode'] }
                disabled
                modifier="-full-width"
              />
            </div>

            <RadioButtons
              id="questionsShown"
              title="Questions Shown"
              initValue={ model.questionsShown }
              options={ options.questions_shown_options }
              onChange={ (v) => {setModel({ ...model, questionsShown: v });} }
              errors={ errors['verizon_express_setting.questions_shown'] }
            />

            <RadioButtons
              id="conceptType"
              title="Concept Type"
              initValue={ model.conceptType }
              options={ options.concept_type_options }
              onChange={ (v) => {setModel({ ...model, conceptType: v });} }
              errors={ errors['verizon_express_setting.concept_type'] }
            />

            <RadioButtons
              id="actionWord"
              title="Action Word"
              initValue={ model.actionWord }
              options={ options.action_word_options }
              onChange={ (v) => {setModel({ ...model, actionWord: v });} }
              errors={ errors['verizon_express_setting.action_word'] }
            />

            <RadioButtons
              id="priced"
              title="Priced"
              initValue={ model.priced }
              options={ options.priced_options }
              onChange={ (v) => {setModel({ ...model, priced: v });} }
              errors={ errors['verizon_express_setting.priced'] }
            />

            <RadioButtons
              id="payFrequency"
              title="Pay Frequency"
              initValue={ model.payFrequency }
              options={ options.pay_frequency_options }
              onChange={ (v) => {setModel({ ...model, payFrequency: v });} }
              errors={ errors['verizon_express_setting.pay_frequency'] }
            />

            <RadioButtons
              id="cit"
              title="Customer Impact Test"
              initValue={ model.cit }
              options={ options.cit_options }
              onChange={ (v) => {setModel({ ...model, cit: v });} }
              errors={ errors['verizon_express_setting.cit'] }
            />

            <RadioButtons
              id="processDescription"
              title="Process Description"
              initValue={ model.processDescription }
              options={ options.process_description_options }
              onChange={ (v) => {setModel({ ...model, processDescription: v });} }
              errors={ errors['verizon_express_setting.process_description'] }
            />

            <RadioButtons
              id="feeAndPolicy"
              title="Fee & Policy - Overall Appeal – Idea Only"
              initValue={ model.feeAndPolicy }
              options={ options.fee_and_policy_options }
              onChange={ (v) => {setModel({ ...model, feeAndPolicy: v });} }
              errors={ errors['verizon_express_setting.fee_and_policy'] }
            />

            <RadioButtons
              id="askAwareness"
              title="Ask Awareness Question"
              initValue={ model.askAwareness }
              options={ options.ask_awareness_options }
              onChange={ (v) => {setModel({ ...model, askAwareness: v });} }
              errors={ errors['verizon_express_setting.ask_awareness'] }
            />

            <FieldHeaderWithText
              id="project-existingAwareness"
              title="Existing Product/Service for Awareness"
              errors={ errors['verizon_express_setting.existing_awareness'] }
              initValue={ model.existingAwareness }
              onChange={ (v) => {setModel({ ...model, existingAwareness: v });} }
            />

            <RadioButtons
              id="awarenessVerb"
              title="Awareness Usage Verb"
              initValue={ model.awarenessVerb }
              options={ options.awareness_verb_options }
              onChange={ (v) => {setModel({ ...model, awarenessVerb: v });} }
              errors={ errors['verizon_express_setting.awareness_verb'] }
            />

            <FieldHeaderWithText
              id="project-awarenessEstimate"
              title="Awareness Estimate"
              errors={ errors['verizon_express_setting.awareness_estimate'] }
              initValue={ model.awarenessEstimate }
              onChange={ (v) => {setModel({ ...model, awarenessEstimate: v });} }
            />
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
