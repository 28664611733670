import React, { useContext } from 'react';
import { filter } from 'lodash';
import Select from "react-select";
import { IndexData } from "../../common/index-context";
import { OpenEndContext } from '../contexts';

const FiltersDropdown = ({ options }) => {
  const { selectedOptions, setSelectedOptions, selectedBsg, loadData } = useContext(IndexData);
  const { setChallengesSummary } = useContext(OpenEndContext);
  const exists = (data) => filter(data, (item) => {
    return item.value === 0;
  });

  const cleared = (options) => {
    return !exists(options).length;
  };

  const onApplyFilter = (data) => {
    const filters = { selected_bsg: selectedBsg, selected_sub_groups: data };
    loadData(filters, null, null, null, { fromUrl: true }, (data) => {
      setChallengesSummary(data?.challenges?.summary);
    });
  };

  const handleSelect = (data, selected) => {
    setSelectedOptions(data);
    onApplyFilter(data);
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      placeholder="Select filter"
      value={ selectedOptions }
      onChange={ handleSelect }
      isSearchable
      isMulti
      isClearable={ cleared(selectedOptions) }
    />
  );
};

export default FiltersDropdown;
