import React, { useContext } from 'react';
import { baseContext } from "../context";

const ConceptsTableLegend = () => {
  const { statTesting, conceptGroup } = useContext(baseContext);

  return (
    (conceptGroup === "included" || conceptGroup === "custom") &&
    <>
      {
        statTesting === "average" &&
        <div className="comparisons_legend-grid -left">
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-above">
              Green
            </div>
            <div className="comparisons_legend-text">
              Statistically higher than average
            </div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-neutral">
              Gray
            </div>
            <div className="comparisons_legend-text">
              Statistically at parity with average
            </div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-below">
              Red
            </div>
            <div className="comparisons_legend-text">
              Statistically lower than average
            </div>
          </div>
        </div>
      }
      {
        statTesting === "quintiles" &&
        <div className="comparisons_legend-grid -left">
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-above" />
            <div className="comparisons_legend-text">
              Top 20%
            </div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-top_40" />
            <div className="comparisons_legend-text">
              Top 40%
            </div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-middle_20" />
            <div className="comparisons_legend-text">
              Middle 20%
            </div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-bottom_40" />
            <div className="comparisons_legend-text">
              Bottom 40%
            </div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label -color-bottom_20" />
            <div className="comparisons_legend-text">
              Bottom 20%
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ConceptsTableLegend;
