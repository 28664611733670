import React, { useState, useContext } from 'react';
import Header from '../common/header';
import PlanDetailsModal from '../common/planDetailsModal';
import { Perk } from '../common/perkList';
import PlanPerksModal from '../common/planPerksModal';
import { EmptyCard, PlanCard } from '../common/planCard';
import {getShortPlanDescription, getLargePlanDescription, planList, perkList} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';

const renderSelectedPlans = (selectedPerks, handleShowPerkDetails, handleShowDetails, handleOpenChange, setClickedLine) =>
  (plan, idx) => {
  return <li key={idx} className="cards-item">
    <h6 className="cards-item-title">Line {idx + 1}</h6>
    {
      plan === null
        ? <EmptyCard/>
        : <PlanCard
            headerClassName="-checkout"
            accordionTitle={`Included features ${plan.perkCount > 0 ? '& bonus perk' : '& perks'}${plan.perkCount > 1 ? 's' : ''}`}
            plan={plan}
            renderChildrenInAccordion={true}
            handleOpenChange={() => handleOpenChange(idx)}
            onShowDetails={() => handleShowDetails(plan.id, idx)}
            setClickedLine={() => setClickedLine(idx)}
          >

            {selectedPerks[idx] && selectedPerks[idx].length > 0 && (
              <ul className="perk-list">
                {selectedPerks[idx].map(perk => <li className="perk-list-item" key={perk.id}>
                  <Perk perk={perk} onDetailsClick={() => handleShowPerkDetails(perk, idx)} />
                </li>)}
              </ul>
            )}

        </PlanCard>
    }
  </li>
}

const Checkout = ({ onContinue, onBack }) => {
  const { filledLines, linesData, selectedPerks } = useContext(LinesDataContext);

  const total = linesData
    .filter(Boolean)
    .reduce((acc, { price }) => price + acc, 0);

  const [planId, setPlanId] = useState(null);

  const [clickedLineIndex, setClickedLineIndex] = useState(null);

  const handleShowDetails = (id, idxLine) => {
    Statistic.updateValue(`lines.${idxLine}.selectedPlanPlanDetailsCount`);
    setPlanId(id);
  };
  const handleCloseDetails = () => { setPlanId(null); };

  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerkFirstSelected`, 0);
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerkSecondSelected`, 0);
    Statistic.updateValue(`lines.${filledLines - 1}.changePlanCount`);
    onBack();
  }

  const [showPerk, setShowPerk] = useState(null);

  const handleShowPerkDetails = (perk, idxLine) => {
    setClickedLineIndex(idxLine);
    Statistic.updateValue(`lines.${idxLine}.selectedPlanPerkDetailsCount`);
    setShowPerk(perk);
  };

  const handleHideShowPerkDetails = () => {
    setShowPerk(null);
  };

  const setClickedLine = (idx) => {
    setClickedLineIndex(idx);
  };

  const handleOpenChange = (idxLine) => {
    Statistic.updateValue(`lines.${idxLine}.selectedPlanFeaturesCount`);
  };

  return <div className="layout checkout">
    <Header/>
    <div className="checkout-header -no-margin">
      <h6 className="checkout-title">You chose a plan for Line {filledLines}</h6>
      <div className="checkout-total">Total: ${total}</div>
    </div>
    <div className="content">
      <div className="checkout-back" onClick={handleBack}>
        <h6 className="checkout-back-title">
          <svg className={ `icon -dropdown -back`}>
            <use xlinkHref={ `#svg_arrow-down` }/>
          </svg>
          <span>Back</span>
        </h6>
      </div>
      <ul className="cards-list -add">
        {linesData.map(renderSelectedPlans(
          selectedPerks,
          handleShowPerkDetails,
          handleShowDetails,
          handleOpenChange,
          setClickedLine
        ))}
      </ul>
    </div>
    <footer className="checkout-footer">
      <button className="button" type="button" onClick={onContinue}>Continue</button>
    </footer>

    {planId && (
        <PlanDetailsModal
          planId={planId}
          open={!!planId}
          handleClose={handleCloseDetails}
          planList={planList}
          getPlanLong={getLargePlanDescription}
          getPlanShort={getShortPlanDescription}
          filledLines={filledLines - 1}
          buttonSelectName="Choose plan"
          clickedLineIndex={clickedLineIndex}
        />
    )}

    <PlanPerksModal
      open={!!showPerk}
      handleClose={handleHideShowPerkDetails}
      perk={showPerk}
      perksList={perkList}
      filledLines={filledLines}
      isCheckout={true}
      clickedLineIndex={clickedLineIndex}
    />

  </div>;
};

export default Checkout;