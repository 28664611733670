import React, { Fragment, useContext } from 'react';
import { filter, map, find, includes, sortBy } from "lodash";
import cn from "classnames";
import Popup from "reactjs-popup";
import { IndexData } from "../../common/index-context";

const HorBarTable = ({ q, colorClass, percentColorClass, custom }) => {
  const { setCustomizedQuestionData, reportView } = useContext(IndexData);
  const FilteredRows = (rows) => (filter(rows, (row) => !row.skip_ui));
  const boxTitle = (title) => {
    const boxSize = q.top3_mode ? 3 : 2;
    if (title === "Top 2 Box") {
      return `Top ${boxSize} Box`;
    } else if (title === "Bottom 2 Box") {
      return `Bottom ${boxSize} Box`;
    }

    return title;
  };

  const setCustomizeQuestionData = () => {
    const modalData = {
      id: q.id,
      title: q.title,
      asPointScale: q.five_point_metric,
      grid: q.sub_kind === 'grid',
      answers: getAnswers(q.five_point_metric),
      columns: getColumns(),
      brand: true
    };
    setCustomizedQuestionData(modalData);
  };

  const getAnswers = (asPoint = false) => {
    const filtered_answers = filter(q.rows, (item) => (item.code > 0));
    const answers =  map(filtered_answers, (item) => ({ index: item.code, text: item.title, image: item.image_url }));
    if (asPoint) {
      return filter(answers, (answer) => !includes([ 0, answers.length - 1, answers.length ], answer.index));
    }
    return answers;
  };
  const getColumns = () => {
    if (columnsOrderChanged()) {
      return map(columnsOrder(), (index) => ({ index, text: q.columns[index - 1] }));
    }
    return map(q.columns, (item, index) => ({ index: index + 1, text: item }));
  };

  const columnsOrderChanged = () => (reportView?.customMetrics?.[q.id] && !!reportView.customMetrics[q.id]?.custom_columns_order);
  const columnsOrder = () => (sortBy(q.columns_keys, (item) => (reportView?.customMetrics?.[q.id]?.custom_columns_order.indexOf(item))));
  const findRecord = (data, index) => (find(data, { code: index }));

  const trigger = (values) => (
    <div
      className={ cn("table-comparison_concept -no-left-padding -concept-card", `js-${values.id}`) }
    >
      <div className="concept-preview table-comparison_concept-preview">
        <div
          className="concept-preview_thumb -dark -less-rounded"
          style={ { 'backgroundImage': `url(${values.image_url})` } }
        />
      </div>
    </div>
  );

  return (
    <div  className="comparisons_table -with-border">
      <table className="table table-comparison">
        <thead>
          <tr>
            <th className="table-comparison_name-cell">
              <div className={ "table-comparison_title" }>
                {reportView?.customMetrics?.[q.id]?.custom_title || q.title}
              </div>
              <div>
                {
                  custom &&
                  <button
                    className="button -secondary -customize button"
                    type="button"
                    onClick={ () => { setCustomizeQuestionData(); } }
                  >
                    Edit title and responses
                  </button>
                }
              </div>
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {
            !q.grid &&
            map(FilteredRows(q.rows), (row, index) => (
              <tr
                key={ index }
                className={ cn("", { "-top2-box": row.title === "Top 2 Box", "-bottom2-box": row.title === "Bottom 2 Box" }) }
              >
                <>
                  <td className="table-comparison_bold-text">
                    <div className="table-comparison-grid-col-view">
                      <div className="table-comparison-grid-col-view-item">
                        { boxTitle(row.title) }
                      </div>
                      {
                        !!row.image_url &&
                        <div className="table-comparison-grid-col-view-item">
                          <Popup
                            trigger={ trigger(row) }
                            position="top center"
                            on={ [ 'hover', 'focus' ] }
                            className="th-image-popup"
                            mouseEnterDelay={ 1 }
                            mouseLeaveDelay={ 1 }
                            offsetY={ -200 }
                          >
                            <div className="-contains-image in">
                              <div className="tooltip-inner">
                                <img className="concept-preview_pic-big" src={ row.image_url } />
                              </div>
                            </div>
                          </Popup>
                        </div>
                      }
                    </div>
                  </td>
                </>
                <td className={ cn("table-comparison_percent", percentColorClass || colorClass) }>
                  {row.percent}%
                </td>
                <td className="-half-width">
                  <div className={ cn("status-bar -no-max-limit", colorClass) }>
                    <div className="status-bar_inner" style={ { 'width': `${row.percent}%` } } />
                  </div>
                </td>
              </tr>
            ))}
          {
            q.grid &&
            map(FilteredRows(q.rows), (row, index) => (
              <Fragment key={ `grid-${index}` }>
                <tr
                  key={ index }
                  // className={ cn("", { "-top2-box": row.title === "Top 2 Box", "-bottom2-box": row.title === "Bottom 2 Box" }) }
                >
                  <td className="table-comparison_name-cell">
                    <div className={ "table-comparison_title" }>
                      {row.title}
                    </div>
                  </td>
                </tr>
                {
                  columnsOrderChanged() &&
                  map(columnsOrder(), (col, index) => (
                    <tr
                      key={ index }
                      className={ cn("", { "-top2-box": findRecord(row.percent, col).title === "Top 2 Box", "-bottom2-box": findRecord(row.percent, col).title === "Bottom 2 Box" }) }
                    >
                      <td className="table-comparison_bold-text">
                        { findRecord(row.percent, col).title }
                      </td>
                      <td className={ cn("table-comparison_percent", percentColorClass || colorClass) }>
                        {findRecord(row.percent, col).percent}%
                      </td>
                      <td className="-half-width">
                        <div className={ cn("status-bar -no-max-limit", colorClass) }>
                          <div className="status-bar_inner" style={ { 'width': `${findRecord(row.percent, col).percent}%` } } />
                        </div>
                      </td>
                    </tr>
                  ))
                }
                {
                  !columnsOrderChanged() &&
                  map(FilteredRows(row.percent), (data, index) => (
                    <tr
                      key={ index }
                      // className={ cn("", { "-top2-box": data.title === "Top 2 Box", "-bottom2-box": data.title === "Bottom 2 Box" }) }
                    >
                      <td className="table-comparison_bold-text">
                        { data.title }
                      </td>
                      <td className={ cn("table-comparison_percent", percentColorClass || colorClass) }>
                        {data.percent}%
                      </td>
                      <td className="-half-width">
                        <div className={ cn("status-bar -no-max-limit", colorClass) }>
                          <div className="status-bar_inner" style={ { 'width': `${data.percent}%` } } />
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default HorBarTable;
