import React from "react";
import cn from "classnames";
import IconButton from "../../components/IconButton";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import ScheduleHeader from "../../components/ScheduleHeader";
import WeekSchedule from "../../components/WeekSchedule";
import schedulePeriods from "../../mocks/schedulePeriods";

const AddButton = () => (
  <IconButton align="end">
    <Icon name="thin-plus" />
  </IconButton>
);

const Schedule = () => (
  <Layout
    className="-bg-extra-dark-gray"
    HeaderComponent={<ScheduleHeader />}
    FloatButton={<AddButton />}
  >
    {schedulePeriods.map((period, i) => (
      <WeekSchedule
        key={i}
        {...period}
        className={cn(i === schedulePeriods.length - 1 && "pb-5")}
      />
    ))}
  </Layout>
);

export default Schedule;
