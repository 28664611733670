import React from 'react';
import remoteFiles from '../../../common/remote_files';

const LandingHelp = () => {
  const { landingHelp1, landingHelp2, landingHelp3 } = remoteFiles.visibleCom;
  const stepsList = [
    {
      title: 'Shop the latest phones.',
      backgroundImage: landingHelp1
    },
    {
      title: 'Love your current phone? Bring it with you.',
      backgroundImage: landingHelp2
    },
    {
      title: 'We have some pretty sweet deals on the phones you love.',
      backgroundImage: landingHelp3
    }
  ];

  return (
    <section className="landing-help">
      <div className="landing-help_text">
        <h3 className="landing-help_title">
          Need a new phone with your new service? We can help.
        </h3>
      </div>
      <ul className="landing-help_steps">
        {
          stepsList.map((step, index) => (
            <li className="landing-help_step" key={ `step-${index}` }>
              <div className="landing-help_step-plate">
                <h4 className="landing-help_step-title">{step.title}</h4>
              </div>
              <div
                className="landing-help_step-image"
                style={ { backgroundImage: `url(${step.backgroundImage})` } }
              />
            </li>
          ))
        }
      </ul>
    </section>
  );
};

export default LandingHelp;
