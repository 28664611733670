import React, { useContext } from 'react';
import PlanList from "./common/planList";
import Header from "./common/header";
import Footer from "./common/footer";
import PlatePinned from "./common/platePinned";
import LandingHero from "./landing/landingHero";
import LandingHowWorks from "./landing/landingHowWorks";
import LandingMap from "./landing/landingMap";
import LandingHelp from "./landing/landingHelp";
import LandingReview from "./landing/landingReview";
import { DataContext } from "./contexts";

const Landing = ({ onContinue, conceptName }) => {
  const { btnPlansAvailable } = useContext(DataContext);
  const firstSentence = conceptName === 'BASE' ?
    'Get unlimited data, powered by Verizon, for as little as $25/mo—without needing to join a family plan.' :
    'Get unlimited data, powered by Verizon, for $30/mo all-in—without needing to join a family plan.';
  const waysToSave = conceptName === 'BASE';
  return (
    <div className="layout ">
      <Header clickableDroppedMenu />
      <LandingHero firstSentence={ firstSentence } />
      <LandingHowWorks waysToSave={ waysToSave } />
      <PlanList startTime={ null } />
      <LandingMap />
      <LandingHelp />
      <LandingReview />
      <Footer />
      {
        btnPlansAvailable &&
        <PlatePinned onContinue={ onContinue } />
      }
    </div>
  );
};

export default Landing;
