import React, { useContext } from 'react';
import BaseApp from '../base_app';
import { IndexData } from '../../common/index-context';
import steps from './steps';

const App = ({ projectId, redirectPath, texts }) => {
  const {
    setForceOpenFilterDropDown, setForceOpenButton,
    openTourModal, setForceCFTab, setForceMarkupCFTab
  } = useContext(IndexData);

  const stepsFunc = steps({
    setForceOpenFilterDropDown,
    setForceOpenButton,
    openTourModal,
    setForceCFTab,
    setForceMarkupCFTab
  });

  return (
    <BaseApp
      projectId={ projectId }
      redirectPath={ redirectPath }
      texts={ texts }
      steps={ stepsFunc }
      tourName="package_mc"
    />
  );
};


export default App;
