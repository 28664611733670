import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import classnames from "classnames";
import renderRawHtml from '../../../common/render_raw_html';
import { customerSpotlightNote, spotlightId, subPulseChanges } from '../helpers';
import Video from '../customerSpotlight/video';
import { baseContext, QualitativeContext } from '../contexts';
import Edit from './edit';

const OverviewSpotlights = ({ title, editable, list }) => {
  const [ openModal, setOpenModal ] = useState(false);
  const [ itemToEdit, setItemToEdit ] = useState({});

  const handleEdit = (item = {}) => {
    setItemToEdit(item);
    setOpenModal(true);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleLearnMore = (id) => {
    navigate(`/customer_spotlights#${spotlightId}=${id}`);
  };
  const { verizonType, limitedAccess } = useContext(baseContext);
  const { fontClass } = useContext(QualitativeContext);
  const showVideo = subPulseChanges(verizonType);

  return (
    <div className="section">
      <div className="section_top -grid -no-bottom-margin">
        <div className="section_top-item">
          <h4 className={ classnames("section_title", fontClass) }>
            { title }
          </h4>
        </div>
        {
          editable &&
          <div className="section_top-item">
            <button className="button -sm -upper" onClick={ () => handleEdit() }>
              Add Spotlight
            </button>
          </div>
        }
      </div>
      { customerSpotlightNote }

      <div className="section_body">
        <ul className="customers">
          {
            map(list, (row, index) => (
              <li className="customers_item" key={ `li-${index}` }>
                <div className="customer">
                  {
                    editable &&
                    <button className="customer_close" onClick={ () => handleEdit(row) }>
                      <svg className="icon customer_edit-icon">
                        <use xlinkHref={ `#svg_edit` } />
                      </svg>
                    </button>
                  }
                  <div className="customer_grid">
                    {
                      row.avatar_url &&
                      <div className="customer_avatar">
                        <img src={ row.avatar_url } alt={ row.name } />
                      </div>
                    }
                    <div className="customer_info">
                      <div>
                        <div className="customer_name">{ row.name }</div>
                        {
                          showVideo && !!row.video_url &&
                          <Video
                            name={ row.name }
                            videoUrl={ row.video_url }
                            className="-overview"
                          />
                        }
                        <div className="customer_text" { ...renderRawHtml(row.brief_overview) } />
                      </div>
                      {
                        !limitedAccess &&
                        <div className="customer_button">
                          <button
                            className="button -sm -secondary -bordered"
                            onClick={ () => { handleLearnMore(row.id); } }
                          >
                            Learn More
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
        {
          openModal &&
          <Edit
            handleClose={ handleClose }
            row={ itemToEdit }
            isNew={ !itemToEdit.id }
          />
        }
      </div>
    </div>
  );
};

export default OverviewSpotlights;
