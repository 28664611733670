import React, { useState } from 'react';
import { map } from 'lodash';
import SliderRadioButtons
  from '../../../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';
import CommonTrs from './commonTrs';
import TrGray from "./trGray";

const Challenges = ({ pids, data }) => {
  const cData = data.challenges_data;

  const [ filter, setFilter ] = useState('high');

  const options = [
    { value: 'high', title: 'Some to High Effort' },
    { value: 'low', title: 'Little to No Effort' }
  ];

  const handleChangeFilter = (newVal) => {
    setFilter(newVal);
  };

  const texts = {
    all: null,
    high: 'Unaided, top themes among respondents who said the experience would require at least some effort.',
    low: 'Unaided, top themes among respondents who said the experience would require a little or no effort.'
  };

  return (
    <div className="comparisons-table_wrapper">
      <table className="table comparisons-table">
        <tbody>
          <CommonTrs pids={ pids } data={ data } />

          <TrGray
            colSpan={ pids.length + 1 }
            text="Customer Challenges with the Experience"
          />

          <tr>
            <th className="th col-name -sticky-left -with-tabs">
              <div className="bold -with-margin">
                { "How much effort do you think this would take?" }
              </div>
              <SliderRadioButtons
                initValue={ filter }
                options={ options }
                onChange={ handleChangeFilter }
                modificator={`-gray-bg -bordered -in-table -full-width -items-${options.length}`}
              />
            </th>
            {map(pids, (pid) => (
              <td className="col-data -center" key={ pid }>
                ({cData[pid][`n_${filter}`]})
              </td>
            ))}
          </tr>

          <tr>
            <td className="th col-name -sticky-left -top">
              <div className="note">
                {texts[filter]}
              </div>
            </td>
            {map(pids, (pid) => (
              <td key={ pid } className="col-data -top">
                {map(cData[pid][`ai_summary_${filter}`], (record, index) => (
                  <div key={ index } className="paragraph">
                    <b>{record.name}:</b> {record.long_summary}
                  </div>
                ))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Challenges;
