import React from 'react';
import ConceptA from './concept_a';
import ConceptUnlimitedBAU from './concept_unlimited_bau';
import ConceptUnlimited0 from './concept_unlimited_0';
import ConceptUnlimited1 from './concept_unlimited_1';
import ConceptUnlimited2 from './concept_unlimited_2';
import ConceptUnlimited3 from './concept_unlimited_3';
import ConceptUnlimited4 from './concept_unlimited_4';
import { SetContext } from "./common/contexts";

const App = ({ conceptName, set }) => {
  return(
    <SetContext.Provider value={{ set: set }}>
      { conceptName == "A" && <ConceptA /> }
      { conceptName == "B" && <ConceptA excludeShared={ true } /> }
      { conceptName == "BAU" && <ConceptUnlimitedBAU/> }
      { conceptName == "CELL1" && <ConceptUnlimited0/> }
      { conceptName == "CELL2" && <ConceptUnlimited1/> }
      { conceptName == "CELL3" && <ConceptUnlimited2/> }
      { conceptName == "CELL4" && <ConceptUnlimited3/> }
      { conceptName == "CELL5" && <ConceptUnlimited4/> }
    </SetContext.Provider>
  )
};

export default App;