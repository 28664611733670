import React, { useState, useContext } from 'react';
import classnames from "classnames";
import Wysiwyg from '../../../common/components/wysiwyg';
import { baseContext } from "../contexts";
import { addPost } from "./api";

const Editor = ({ parentId, addPostToState }) => {
  const { mediaKind, getCurrentUser, verizonType } = useContext(baseContext);
  const user = getCurrentUser();
  const [ message, setMessage ] = useState('');
  const handleChange = (value) => {
    setMessage(value);
  };

  const callback = (data) => {
    addPostToState(data, parentId);
    setMessage('');
  };
  const onAddClick = () => {
    addPost({ message, parent_id: parentId, media_kind: mediaKind, verizon_type: verizonType }, callback);
  };

  return (
    <>
      <div className={ classnames("post editor", { "reply": parentId }) }>
        <div className="avatar">
          <div className="data-letters" style={ { background: user.color } }>
            { user.letters }
          </div>
        </div>
        <div className="username">
          <span className="bold">{user.name}</span>
        </div>
        <div className={ classnames("message", { "-reply": parentId }) }>
          <Wysiwyg
            placeholder="Enter message..."
            editorClassName="middle-height"
            value={ message }
            onChange={ handleChange }
            enableAlign
          />
          <div className="message_panel">
            <button
              className="button -sm -bordered"
              onClick={ onAddClick }
              disabled={ !message }
            >
              <svg className="icon message_panel_button-send">
                <use xlinkHref={ `#svg_send` } />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editor;
