import React, {useState} from 'react';
import pluralize from "pluralize";
import { filter, map, findIndex, orderBy } from "lodash";

const ClientRow = ({ client, subCandidates, onAddSub, onAddCredits, onRemoveCredits }) => {
  const [ credits, setCredits ] = useState("");

  const onCreditsChange = (event) => {
    setCredits(event.target.value);
  };

  return (
    <div className="client-label-row">
      <div className="flex-left">
        <div className="client-title">{ client.name }</div>
        {
          !!client.subclients.length &&
          <div className="subclients-number -clients-button -no-hover">
            { client.subclients.length } { pluralize("sub-account", client.subclients.length) }
          </div>
        }
        <div className="subclients-add">
          <div className="dropdown header_button">
            <button
              className="button -clients-button"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg className="icon -i-add">
                <use xlinkHref="#svg_i-add" />
              </svg>
            </button>
            <div className="dropdown_container">
              <div className="dropdown_inset">
                <div className="dropdown_content">
                  <ul>
                    <li className="-title">
                      <span>Add sub-account</span>
                    </li>
                    {
                      map(subCandidates, (subClient) => (
                        <li key={ `${client.id}-${subClient.id}` }>
                          <a className="project-preview_link" onClick={ () => onAddSub(client, subClient) }>
                            { subClient.name }
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-right">
        <div className="client-credits">{ `Credits: ${client.credits}` }</div>
        <div className="clients-credits-modify">
          <input
            className="form-field"
            value={ credits }
            onChange={ onCreditsChange }
          />
          <button
            className="button -add"
            onClick={ () => onAddCredits(client, parseInt(credits), setCredits) }
          >
            Add Credits
          </button>
          <button
            className="button -remove"
            onClick={ () => onRemoveCredits(client, parseInt(credits), setCredits) }
          >
            Remove Credits
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientRow;
