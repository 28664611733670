import React, { useState, useContext, useRef } from "react";
import classnames from "classnames";
import { baseContext } from "../../contexts";
import { checkStatus, createExport } from '../api';

const ComparisonExport = ({ archived, filter }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ inProgress, setInProgress ] = useState(false);
  const [ , setTimeoutId ] = useState(null);
  const downloadLink = useRef(null);

  const interval = 5000;
  const waitingForExportToFinish = (id, callback) => {
    checkStatus(mediaKind, id, (data) => {
      if (data.count_in_progress) {
        setTimeoutId((oldTimeoutId) => {
          clearTimeout(oldTimeoutId);
          return setTimeout(() => {
            waitingForExportToFinish(id, callback);
          }, interval);
        });
      } else {
        callback(data);
      }
    });
  };
  const onExportClick = () => {
    setInProgress(true);
    createExport(mediaKind, verizonType, null, 'pptx', 'comparison', [], { archived, filter }, (data) => {
      waitingForExportToFinish(data.id, (result) => {
        const resultData = result.user_downloads[0];
        setInProgress(false);
        if (resultData.status === "finished") {
          downloadLink.current.setAttribute("href", resultData.s3_filename);
          downloadLink.current.setAttribute("download", resultData.subtitle);
          downloadLink.current.click();
        }
      });
    });
  };
  return (
    <div className="pulses_button">
      <a ref={ downloadLink } href="" style={ { display: "none" } } />
      <button
        className={ classnames("button -sm -bordered", { "loader -loading-blink": inProgress }) }
        onClick={ onExportClick }
        disabled={ inProgress }
      >
        { inProgress && "Processing..." }
        { !inProgress && "Export" }
      </button>
    </div>
  );
};

export default ComparisonExport;
