import React, { useState, useEffect, useContext } from 'react';
import {map, uniq, filter, find, includes} from "lodash";
import { baseContext } from "../contexts";
import {
  withPrefix, getUrlState, setUrlState,
  createFilterFromPrecode,
  getPrecodeFromFilter, getFiltersFromDBFilters, displayGroups
} from '../helpers';
import ComparisonExport from '../exports/comparisonExport';
import Dropdown from '../common/dropdown';
import DisplayGroupSelector from "../common/display-group-selector";
import { loadTableData } from "./api";
import List from './list';

const filterUrl = 'filter', displayGroupUrl = "display_group";

const Pulse = () => {
  const [ inProgress, setInProgress ] = useState(true);
  const [ allData, setAllData ] = useState([]);
  const [ currentData, setCurrentData ] = useState([]);
  const [ groups, setGroups ] = useState(displayGroups);
  const [ filtersHash, setFiltersHash ] = useState({});
  const [ currentGroup, setCurrentGroup ] = useState(() => {
    try {
      const displayGroupFromUrl = getUrlState()[displayGroupUrl];
      return displayGroupFromUrl ? displayGroupFromUrl : null;
    } catch {
      return null;
    }
  });
  const [ currentFilter, setCurrentFilter ] = useState(() => (
    getUrlState()[filterUrl] || null
  ));
  const { mediaKind, verizonType } = useContext(baseContext);

  const onGroupChange = (newGroup) => {
    setGroupData(newGroup, allData);
  };

  const onFilterChange = (newPrecode) => {
    setCurrentFilter(
      createFilterFromPrecode(filtersHash.name, newPrecode)
    );
  };

  const defineInitialGroup = (data) => {
    const foundActive = find(data, (item) => (item.selected));
    return foundActive && foundActive.archived ? displayGroups[1] : displayGroups[0];
  };

  const setGroupData = (newGroup, allData) => {
    setCurrentGroup(newGroup);
    const archived = newGroup === displayGroups[1];
    const currentData = filter(allData, (el) => (el.archived === archived));
    setCurrentData(currentData);
  };

  useEffect(() => {
    setInProgress(true);
    loadTableData(mediaKind, verizonType, currentFilter, (projects) => {
      const allGroups = uniq(map(projects, (el) => (el.archived)));
      setGroups(includes(allGroups, true) ? displayGroups : [ false ]);
      setFiltersHash(getFiltersFromDBFilters(projects));
      setAllData(projects);
      setGroupData(
        currentGroup || defineInitialGroup(projects),
        projects
      );
      setInProgress(false);
    });
  }, [ currentFilter ]);

  useEffect(() => {
    setUrlState({ [displayGroupUrl]: currentGroup, [filterUrl]: currentFilter });
  }, [ currentGroup, currentFilter ]);

  return (
    <>
      {
        !allData.length && !inProgress &&
        <p>No Pulse Surveys found</p>
      }
      {
        !currentData.length && inProgress &&
        <div className="iframe-loader" />
      }
      {
        !!allData.length &&
        <div className="pulses section">
          <div className="section_top -grid">
            <div className="section_top-item">
              <h4 className="section_title">
                { withPrefix(verizonType, mediaKind, 'Customer Experience Over Time') }
              </h4>
            </div>
            <div className="section_top-item">
              <div className="active-report_logo-bb" />
            </div>
          </div>
          <Dropdown
            selectedValue={ getPrecodeFromFilter(currentFilter) }
            list={ filtersHash.answers }
            onChange={ onFilterChange }
          />
          <DisplayGroupSelector
            withSection
            groups={ groups }
            currentGroup={ currentGroup }
            onChange={ onGroupChange }
          />
          <div className="section_body">
            <List currentData={ currentData } />
          </div>
          {
            !!currentData.length &&
            <ComparisonExport
              archived={ currentGroup === displayGroups[1] }
              filter={ currentFilter }
            />
          }
        </div>
      }
    </>
  );
};

export default Pulse;
