import { each } from 'lodash';

const dragWidth = 60;
const dragHeight = 60;
const zIndex = 2000;

const dragDropFunctions = () => {
  const body = document.body || document.querySelector("body");
  let productInstance;
  let mouseHold = false;
  let imageIsLoaded = false;
  let newElement = null;

  const dragStart = (product) => (event) => {
    if (!imageIsLoaded) {
      return;
    }
    productInstance = product;
    mouseHold = true;
  };

  const dragMove = (event) => {
    if (mouseHold) {
      if (!newElement) {
        const newChildElement =  document.createElement("div");
        newChildElement.innerHTML = productInstance.name;
        newChildElement.className = "drag-drop-element-name";
        newElement = document.createElement("div");
        newElement.appendChild(newChildElement);
        newElement.className = "drag-drop-element";
        body.appendChild(newElement);
      }
      const { clientX, clientY } = event;
      const left = clientX - dragWidth / 2;
      const top = clientY - dragHeight / 2;
      newElement.setAttribute(
        'style',
        [
          `width: ${dragWidth}px`,
          `height: ${dragHeight}px`,
          `z-index: ${zIndex}`,
          `top: ${top}px`,
          `left: ${left}px`
        ].join("; ")
      );
    }
  };

  const dragFinish = (shelfElement, onAdd) => (event) => {
    mouseHold = false;
    if (!newElement) {
      return;
    }

    const { left, top, width, height } = shelfElement?.getBoundingClientRect() || {};
    if (width && height) {
      const { clientX, clientY } = event;
      const rectLeft = clientX - dragWidth / 2;
      const rectTop = clientY - dragHeight / 2;
      const rectRight = rectLeft + dragWidth;
      const rectBottom = rectTop + dragHeight;
      if (
        rectLeft >= left && rectRight <= (left + width) &&
        rectTop >= top && rectBottom <= (top + height)
      ) {
        onAdd({
          left: rectLeft - left,
          top: rectTop - top,
          width: dragWidth,
          height: dragHeight,
          imageWidth: width,
          imageHeight: height
        }, productInstance);
      }
    }
    productInstance = null;
    body.removeChild(newElement);
    newElement = null;
  };

  const createDragDropForProduct = (productElement, product, canDrag) => {
    const dragStartWithProduct = dragStart(product);
    if (productElement && canDrag) {
      each([ 'touchstart', 'mousedown' ], (eventName) => {
        productElement.addEventListener(eventName, dragStartWithProduct);
      });
    }
    return () => {
      if (!productElement || !canDrag) {
        return;
      }
      each([ 'touchstart', 'mousedown' ], (eventName) => {
        productElement.removeEventListener(eventName, dragStartWithProduct);
      });
    };
  };

  const createDragDropForShelf = (shelfElement, onAdd, dimensions) => {
    const { width, height } = dimensions;
    imageIsLoaded = !!width && !!height;
    const dragFinishWithShelf = dragFinish(shelfElement, onAdd);
    if (shelfElement && imageIsLoaded) {
      each([ 'touchmove', 'mousemove' ], (eventName) => {
        body.addEventListener(eventName, dragMove);
      });
      each([ 'touchend', 'mouseup' ], (eventName) => {
        body.addEventListener(eventName, dragFinishWithShelf);
      });
    }
    return () => {
      if (!shelfElement) {
        return;
      }
      each([ 'touchmove', 'mousemove' ], (eventName) => {
        body.removeEventListener(eventName, dragMove);
      });
      each([ 'touchend', 'mouseup' ], (eventName) => {
        body.removeEventListener(eventName, dragFinishWithShelf);
      });
    };
  };

  return {
    createDragDropForProduct,
    createDragDropForShelf
  };
};

export default dragDropFunctions;
