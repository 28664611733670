import React from "react";
import cn from "classnames";
import Icon from "./Icon";

const ScheduleCard = ({
  title,
  coach,
  category,
  brand,
  duration,
  added,
  checked,
  imgUrl,
  className,
}) => {
  return (
    <div className={cn("schedule-card", className)}>
      <div className="schedule-card_indicator">
        {checked && (
          <div className="schedule-card_checked-indicator">
            <Icon name="checkmark" />
          </div>
        )}
        {!checked && added && (
          <div className="schedule-card_added-indicator"></div>
        )}
      </div>
      <div className="schedule-card_body">
        <div className="schedule-card_preview">
          <img src={imgUrl} className="schedule-card_bg" />
          <div
            className={
              added ? "schedule-card_gradient" : "schedule-card_overlay"
            }
          />
          {added && (
            <div className="schedule-card_added">
              Added&nbsp;
              <Icon name="plus" />
            </div>
          )}
          {added && <div className="schedule-card_duration">{duration}</div>}
          {!added && <div className="schedule-card_add-button">Add</div>}
        </div>
        <div className="schedule-card_content">
          <div className="schedule-card_title">
            {added ? title : "Nothing Planned"}
          </div>
          {added && (
            <div className="schedule-card_text">
              With {coach}
              <br />
              {category} by {brand}
            </div>
          )}
          {!added && (
            <div className="schedule-card_text">
              Add a class and make the most of your day
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
