import React, { useContext, useState } from 'react';
import classnames from "classnames";
import { map } from 'lodash';
import { FrameWrapper } from '../../../common/components/frame_wrapper';
import TextField from '../common/portal/textField';
import UploadButton from '../common/uploadButton';
import { baseContext, installationsContext, reportsContext } from '../contexts';
import PortalModal from '../common/portal';
import { convertPdf, makeUpdate, iFramePreviewUrl } from './api';

const InstallationsModal = ({ handleClose }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const {
    getTitle, getPpt, getPdf,
    setTitle, setSlides, setPpt, setPdf
  } = useContext(installationsContext);
  const { refresh } = useContext(reportsContext);
  const [ tempTitle, setTempTitle ] = useState(getTitle());
  const [ tempSlides, setTempSlides ] = useState([]);
  const [ saving, setSaving ] = useState(false);

  const currentTitle = getTitle();
  const [ pptUploading, setPptUploading ] = useState(false);
  const [ pptMetaData, setPptMetaData ] = useState(getPpt().metaData);
  const [ pptFilename, setPptFilename ] = useState(getPpt().filename);

  const [ pdfMetaData, setPdfMetaData ] = useState(getPdf().metaData);
  const [ pdfFilename, setPdfFilename ] = useState(getPdf().filename);
  const [ pdfUploading, setPdfUploading ] = useState(false);

  const handleUploadInit = (uploadingFunc) => () => {
    uploadingFunc(true);
  };

  const handleUploadPpt = (file) => {
    setPptMetaData(file.metaData);
    setPptFilename(file.filename);
    setPptUploading(false);
  };

  const handleUploadPdf = (file) => {
    setPdfMetaData(file.metaData);
    setPdfFilename(file.filename);
    convertPdf(mediaKind, verizonType, file.awsKey, (result, items) => {
      setPdfUploading(false);
      if (result === 'complete') {
        setTempSlides(map(JSON.parse(items), (item) => (
          {
            htmlUrl: item.html_upload.url,
            baseCssUrl: item.base_css_upload.url,
            cssUrl: item.css_upload.url
          }
        )));
      }
    });
  };

  const saveCallback = () => {
    setSaving(true);
    const params = {
      installation_title: tempTitle,
      ppt_report_data: pptMetaData,
      ppt_report_filename: pptFilename,
      installation_pdf_data: pdfMetaData,
      installation_pdf_filename: pdfFilename
    };

    if (tempSlides.length) {
      params.base_css_url = tempSlides[0].baseCssUrl;
      params.css_url = tempSlides[0].cssUrl;
      params.slides = map(tempSlides, (slide) => ({ html_url: slide.htmlUrl }));
    }

    makeUpdate(mediaKind, verizonType, params, (result, data) => {
      setSaving(false);
      if (result === 'complete') {
        const overviewData = JSON.parse(data);
        setTitle(overviewData.installation_title);
        setSlides(overviewData.installation_slides);
        setPpt(
          overviewData.ppt_report_url,
          overviewData.ppt_report_data,
          overviewData.ppt_report_filename
        );
        setPdf(
          overviewData.installation_pdf_url,
          overviewData.installation_pdf_data,
          overviewData.installation_pdf_filename
        );
        refresh();
        handleClose();
      }
    });
  };

  return (
    <PortalModal
      title={ currentTitle }
      open
      handleClose={ handleClose }
      saveCallback={ saveCallback }
      disabledSave={ !pptMetaData || !pdfMetaData || pptUploading || pdfUploading || saving }
      buttonName={ saving ? "Saving..." : "Save" }
    >
      <div className="article-edit">
        <TextField label="Topic" value={ tempTitle } onChange={ setTempTitle } />
        <>
          <div className="form-group">
            <div className="form-group_control -button">
              <UploadButton
                className={ classnames("button -sm -upper", { "loader -loading-blink": pptUploading }) }
                allowedTypes={ [ '.ppt', '.pptx' ] }
                filenameOnBottom
                initialList={ pptFilename ? [ pptFilename ] : null }
                onUploaded={ (files) => handleUploadPpt(files[0]) }
                onInit={ handleUploadInit(setPptUploading) }
                disabled={ saving }
                customProcessingLabel="Uploading PPT report..."
              >
                Upload PPT report
              </UploadButton>
            </div>
            <div className="form-group_control -button">
              <UploadButton
                className={ classnames("button -sm -upper", { "loader -loading-blink": pdfUploading }) }
                allowedTypes={ [ '.pdf' ] }
                onUploaded={ (files) => handleUploadPdf(files[0]) }
                filenameOnBottom
                initialList={ pdfFilename ? [ pdfFilename ] : null }
                onInit={ handleUploadInit(setPdfUploading) }
                disabled={ pdfUploading || saving }
                customProcessingLabel="Uploading Pdf document..."
              >
                { pdfUploading ? "Processing Pdf document..." : "Upload Pdf document" }
              </UploadButton>
            </div>
          </div>
          {
            !!tempSlides.length && !!tempSlides[0].htmlUrl && !!tempSlides[0].baseCssUrl && !!tempSlides[0].cssUrl &&
            <div className="form-group">
              <div className="form-group_label -upper">First Slide Preview</div>
              <FrameWrapper
                src={ iFramePreviewUrl(tempSlides[0].htmlUrl, tempSlides[0].baseCssUrl, tempSlides[0].cssUrl, verizonType) }
                loadCallback={ () => { setPdfUploading(false); } }
              />
            </div>
          }
        </>
      </div>
    </PortalModal>
  );
};

export default InstallationsModal;
