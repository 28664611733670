import { cloneDeep, each, find } from 'lodash';
import { getLinesCount } from '../common/statistics';
import {
  concept_plans_prebuild,
  concept_a_perk_groups, allPlansIncludeConcept,
  yearPriceGuarantee5, earlyPhoneUpgrades, fiveGNationWide,
  allPerksFlatten
} from '../concept_a/plans_data';
import {
  getForkList, welcomeUnlimited, concept_plans_construct
} from '../concept_c/plans_data';

const concept_d_pricing = [
  [],
  [],
  [],
  [],
  [ 65, 55, 40, 30, 25 ],
  [ 85, 75, 60, 50, 45 ],
  [ 85, 75, 60, 50, 45 ],
  [ 85, 75, 60, 50, 45 ],
  [ 85, 75, 60, 50, 45 ],
  [ 75, 65, 50, 40, 35 ]
];

const noPerk = {
  id: 0,
  title: 'I don’t want an included add-on',
  shortDescription: 'By choosing this option, you will not recieve an included add-on, and your plan’s monthly price will drop by $5.',
  description: '',
  orderTitle: 'Add-on declined (-$5/mo)',
  icon: null,
  price: 5,
  priceValue: -5,
  noPerk: true
};

const conceptDallPerksFlatten = cloneDeep(allPerksFlatten);
conceptDallPerksFlatten.unshift(noPerk);

const conceptDPerkGroups = cloneDeep(concept_a_perk_groups);
conceptDPerkGroups.unshift({ title: null, perks: [ noPerk ] });

const conceptDplansConstruct = cloneDeep(concept_plans_construct);
const [ premium, standard ] = conceptDplansConstruct;
each([ premium, standard ], (planObj) => {
  planObj.core.includedTitle = 'Includes 1 add-on of your choice';
  planObj.core.includedDescription = '(Or remove add-on for a $5 discount)';
});

const conceptDplansPrebuild = cloneDeep(concept_plans_prebuild);
const [ entertainmentPlan, gamingPlan, shoppingPlan ] = conceptDplansPrebuild;
each([ entertainmentPlan, gamingPlan, shoppingPlan ], (planObj) => {
  const corePlan = find([ premium, standard ], (item) => item.core.id === planObj.core.id).core;
  planObj.core.includedTitle = corePlan.includedTitle;
  planObj.core.includedDescription = corePlan.includedDescription;
});
const [ disneyPlusPerk ] = entertainmentPlan.perks;
entertainmentPlan.perks = [ disneyPlusPerk ];

const CONCEPT_D = {
  allPerksFlatten: conceptDallPerksFlatten,
  welcomePlans: [ welcomeUnlimited ],
  plans_prebuild: conceptDplansPrebuild.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  plans_construct: conceptDplansConstruct.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  concept_perk_groups: conceptDPerkGroups,
  allPlansIncludeConcept,
  getForkList
};

export {
  CONCEPT_D,
  conceptDplansPrebuild as plans_prebuild,
  conceptDplansConstruct as plans_construct,
  conceptDPerkGroups as concept_perk_groups,
  allPlansIncludeConcept,
  fiveGNationWide,
  yearPriceGuarantee5,
  earlyPhoneUpgrades,
  conceptDallPerksFlatten as allPerksFlatten
};
