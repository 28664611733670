import React, { useContext, useState } from "react";
import { StateContext, LineDataContext, SetContext, PlanDataProvider } from "../common/contexts";
// eslint-disable-next-line import/no-named-as-default
import { Lines } from '../common/lines';
import {
  setLinesCount,
  setCurrentLine,
  getCurrentLine,
  setHashData,
  setPlan,
  sendData
} from "../common/statistics";
import Page from '../common/page';
import Loader from '../common/loader';
import OrderPage from '../common/order';
import { scrollTop } from '../common/helper';
import OverviewPage from './overview_page';

const states = [
  "Lines",
  "Overview",
  "Order"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = () => {
  const [ , updatePath ] = useContext(StateContext);
  const { perks, plans_prebuild, plans_construct, PERK_PRICE } = useContext(PlanDataProvider);
  const [ planStartTime, setPlanStartTime ] = useState(null);
  const { delayAnimation, buildYourOwnFirst } = useContext(SetContext);
  const [ loading, setLoading ] = useState(false);
  const data = {
    setLoading
  };
  // this state is need to update value in subheader after plan select slick with delay animation

  const handleLinesNextStep = (event, linesCount) => {
    setPlanStartTime(new Date());
    updatePath("Order");
    setLinesCount(linesCount);
    setCurrentLine(1);
  };

  const updatePlanData = (planObject, line, timeAfterStart) => {
    const price = planObject.price + (planObject.perks.length * PERK_PRICE);
    setPlan({ ...planObject, price }, line);
    setHashData(`lines.${line}.planSelectTime`, timeAfterStart);
    setHashData(`lines.${line}.planPerkIds`, planObject.perks.map(({ id }) => id));
    setPlanStartTime(new Date());
  };
  const handlePlanSelect = (planObject) => {
    const timeAfterStart = Math.ceil(
      new Date(new Date() - planStartTime - delayAnimation).getTime() / 1000
    );

    updatePlanData(planObject, getCurrentLine(), timeAfterStart);
    updatePath("Order");
    scrollTop();
  };

  const handlePlanReselect = (clickedLine) => {
    setPlanStartTime(new Date());
    updatePath("Overview");
    setCurrentLine(clickedLine);
  };

  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Lines">
        <Lines nextStep={ handleLinesNextStep } />
      </Route>

      <Route path="Overview">
        <Page>
          <OverviewPage onPlanSelect={ handlePlanSelect } buildYourOwnFirst={ buildYourOwnFirst } />
        </Page>
      </Route>

      <Route path="Order">
        <Page>
          <OrderPage
            perkPrice={ PERK_PRICE }
            plans={ [ ...plans_prebuild, ...plans_construct ] }
            perks={ perks.map((group) => group.perks).reduce((acc, list) => [ ...acc, ...list ], []) }
            nextStep={ () => {
              sendData();
              updatePath("Reset");
            } }
            reselectPlan={ handlePlanReselect }
          />
        </Page>
      </Route>

      { loading && <Loader /> }
    </LineDataContext.Provider>
  );
};

export { Router, initialState };
