const statusKlass = (statusText) => {
  return `-${statusText.replace(/\s+/, '-').toLowerCase()}`;
};

const rotate = (p) => {
  let f = p.respondent_count / p.required_nsize;
  if (f > 1) {
    f = 1;
  }
  return `rotate(${f * 360}deg)`;
};

const afterHalf = (p) => {
  return p.respondent_count / p.required_nsize > 0.5;
};

const canDelete = (isAdmin, project, clientId) => (
  isAdmin ||
  parseInt(project.client_id) === parseInt(clientId)
);

export { statusKlass, rotate, afterHalf, canDelete };
