import { map } from 'lodash';
import { getLinesCount } from "../statistics";

const pricing = [
  [ 70, 60, 45, 35, 30 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 90, 80, 65, 55, 50 ]
];

const allPlansInclude = [
  "Unlimited Talk & Text in US",
  "Talk & Text to Mexico and Canada, plus data and roaming",
  "Verizon Up Rewards"
];

const startUnlimited = {
  id: 1,
  name: "Start Unlimited",
  title: "Start<br/>Unlimited",
  description: "Get all the basics with unlimited talk, text and data. And never worry about overage charges again.",
  features: [
    {
      title: "&nbsp;",
      description: "",
      before: "Plan Features"
    },
    { title: "5G Nationwide", description: "Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device." },
    { title: "&nbsp;", description: "" },
    { title: "&nbsp;", description: "" },
    { title: "4G LTE Data", description: "Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic." },
    { title: "&nbsp;", description: "", big: true }
  ],
  perks: [
    {
      title: "Disney+<br/>6 months on us",
      description: "All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic, including The Mandalorian, The Avengers, Mulan, and Hamilton. First 6 months on us, then $7.99/mo + taxes after. One subscription can be shared with everyone on your account. Cancel anytime. (For NM residents, Disney+ ends automatically after 6 mos. Add'l terms apply. One offer per eligible Verizon account.)",
      icon: "",
      before: "Plan Perks"
    },
    { title: "discovery+<br/>6 months on us", description: "discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti.  First 6 months on us, then $6.99/month + taxes after.", icon: "" },
    { title: "Apple Arcade<br/>6 months on us", description: "Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 6 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Google Play Pass<br/>6 months on us", description: "Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 6 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Apple Music <br/>6 months on us", description: "Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)", icon: "" },
    { title: "&nbsp;", description: "", icon: "", bigger: true },
    { title: "&nbsp;", description: "", icon: "", bigger: true }
  ]
};

const playMoreUnlimited = {
  id: 2,
  name: "Play More Unlimited",
  title: "Play More<br/>Unlimited",
  description: "Our best for music and video streaming, with millions of songs, HD and premium data — all included.",
  features: [
    {
      title: "5G Ultra Wideband",
      description: "Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today's 4G networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. 5G Ultra Wideband available only in parts of select cities; requires a 5G Ultra Wideband-capable device.",
      before: "5G Total Access"
    },
    { title: "5G Nationwide", description: "Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device." },
    {
      title: "Premium Network Access",
      description: "Get access to unlimited 5G Nationwide / 4G LTE premium data per month. 5G access requires a 5G-capable device. Plus 720p HD streaming when you activate it in your plan settings.",
      before: "Plan Features"
    },
    { title: "4G LTE Data", description: "Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic." },
    { title: "Unlimited mobile hotspot 15 GB of 5G Nationwide / 4G LTE Data", description: "Your smartphone becomes a Wi-Fi connection for other devices. Get up to 15 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area" }
  ],
  perks: [
    {
      title: "Disney+, Hulu, ESPN+<br/>included",
      description: "Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places. One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.",
      icon: "Plan Perks",
      before: "Plan Perks"
    },
    { title: "discovery+<br/>12 months on us", description: "discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti. First 12 months on us, then $6.99/month + taxes after.", icon: "" },
    { title: "Apple Arcade<br/>12 months on us", description: "Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 12 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Google Play Pass<br/>12 months on us", description: "Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 12 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Apple Music<br/>12 months on us", description: "Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 12 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 12 mos.)", icon: "" },
    { title: "&nbsp;", description: "", icon: "", bigger: true },
    { title: "&nbsp;", description: "", icon: "", bigger: true }
  ]
};

const doMoreUnlimited = {
  id: 3,
  name: "Do More Unlimited",
  title: "Do More<br/>Unlimited",
  description: "When productivity is your top priority, get it all done with premium data and a discount on a connected device plan.",
  features: [
    {
      title: "5G Ultra Wideband",
      description: "Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today's 4G networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. 5G Ultra Wideband available only in parts of select cities; requires a 5G Ultra Wideband-capable device.",
      before: "5G Total Access"
    },
    { title: "5G Nationwide", description: "Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device." },
    {
      title: "Premium Network Access",
      description: "Get access to unlimited 5G Nationwide / 4G LTE premium data per month. 5G access requires a 5G-capable device. Plus 720p HD streaming when you activate it in your plan settings.",
      before: "Plan Features"
    },
    { title: "4G LTE Data", description: "Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic." },
    { title: "Unlimited mobile hotspot 15 GB of 5G Nationwide / 4G LTE Data", description: "Your smartphone becomes a Wi-Fi connection for other devices. Get up to 15 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area" }
  ],
  perks: [
    {
      title: "Disney+<br/>6 months on us",
      description: "All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic, including The Mandalorian, The Avengers, Mulan, and Hamilton. First 6 months on us, then $7.99/mo + taxes after. One subscription can be shared with everyone on your account. Cancel anytime. (For NM residents, Disney+ ends automatically after 6 mos. Add'l terms apply. One offer per eligible Verizon account.)",
      icon: "",
      before: "Plan Perks"
    },
    { title: "discovery+<br/>6 months on us", description: "discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti.  First 6 months on us, then $6.99/month + taxes after.", icon: "" },
    { title: "Apple Arcade<br/>6 months on us", description: "Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 6 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Google Play Pass<br/>6 months on us", description: "Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 6 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Apple Music<br/>6 months on us", description: "Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)", icon: "" },
    { title: "600 GB of Verizon Cloud storage", description: "Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.", icon: "" },
    { title: "50% off Unlimited connected device plan", description: "Get 50% off an Unlimited connected device plan. (not the hardware). Up to $15/month value. (Jetpack is a separate device that lets you share your Verizon wireless network connection with other devices. Hum turns almost any car into a smarter, safer, more connected car; check your vehicle health, get 24/7 pinpoint road assistance, and create a WiFi connection from your car.)", icon: "" },
  ]
};

const getMoreUnlimited = {
  id: 4,
  name: "Get More Unlimited",
  title: "Get More <br/>Unlimited",
  description: "The very best of our Unlimited plans gives you our ultimate in performance with extra features.",
  features: [
    {
      title: "5G Ultra Wideband",
      description: "Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today's 4G networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. 5G Ultra Wideband available only in parts of select cities; requires a 5G Ultra Wideband-capable device.",
      before: "5G Total Access"
    },
    { title: "5G Nationwide", description: "Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device." },
    {
      title: "Premium Network Access",
      description: "Get access to unlimited 5G Nationwide / 4G LTE premium data per month. 5G access requires a 5G-capable device. Plus 720p HD streaming when you activate it in your plan settings.",
      before: "Plan Features"
    },
    { title: "4G LTE Data", description: "Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic." },
    { title: "Unlimited mobile hotspot 30 GB of 5G Nationwide / 4G LTE Data", description: "Your smartphone becomes a Wi-Fi connection for other devices. Get up to 30 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area" }
  ],
  perks: [
    {
      title: "Disney+, Hulu, ESPN+ included",
      description: "Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places. One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.",
      icon: "",
      before: "Plan Perks"
    },
    { title: "discovery+<br/>12 months on us", description: "discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti. First 12 months on us, then $6.99/month + taxes after.", icon: "" },
    { title: "Apple Arcade<br/>12 months on us", description: "Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 12 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Google Play Pass<br/>12 months on us", description: "Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 12 months on us, then $4.99/month + taxes after.", icon: "" },
    { title: "Apple Music<br/>included", description: "Apple Music subscription; allows you to listen to 70 million songs ad-free, or download your favorite tracks and play them offline. A $9.99/month value. Each line on an eligible plan gets a separate subscription.", icon: "" },
    { title: "600 GB of Verizon Cloud storage", description: "Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.", icon: "" },
    { title: "50% off Unlimited connected device plan", description: "Get 50% off an Unlimited connected device plan. (not the hardware). Up to $15/month value. (Jetpack is a separate device that lets you share your Verizon wireless network connection with other devices. Hum turns almost any car into a smarter, safer, more connected car; check your vehicle health, get 24/7 pinpoint road assistance, and create a WiFi connection from your car.)", icon: "" },
  ]
};

const unlimitedPlans = map([
  startUnlimited,
  playMoreUnlimited,
  doMoreUnlimited,
  getMoreUnlimited
], (el) =>
  ({
    ...el,
    get price() { return pricing[el.id - 1][getLinesCount() - 1]; }
  })
);

export { unlimitedPlans, allPlansInclude as unlimitedAllPlansInclude };