import React from 'react';
import Select from "react-select";

const FiltersItemDropdown = ({ options, selectedOptions, setSelectedOptions, disabled, menuPlacement, maxMenuHeight }) => {
  const handleSelect = (data) => {
    setSelectedOptions([ data ]);
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      placeholder="All"
      value={ selectedOptions }
      onChange={ handleSelect }
      isSearchable
      isDisabled={ disabled }
      menuPlacement={ menuPlacement }
      maxMenuHeight={ maxMenuHeight }
    />
  );
};

export default FiltersItemDropdown;
