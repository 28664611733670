import React from "react";
import cn from "classnames";
import categories from "../mocks/categories";
import CategoryCard from "./CategoryCard";

const CategoriesList = ({ className }) => {
  return (
    <div className={cn("categories-list", className)}>
      {Object.values(categories).map((category) => (
        <CategoryCard {...category} key={category.title} />
      ))}
    </div>
  );
};

export default CategoriesList;
