import React from 'react';

const Reports = () => {

    return (
      <div className="reports plate">
          <h4 className="plate_title">
            Reports
          </h4>
          <ul className="template_list">
              {[...Array(7)].map((value, index) => {
                  return (
                    <li className="template_list-item" key={index}>
                        <div className="template_list-date">7 days ago</div>
                        <div className="template_list-title">
                            <a className="template_list-title-link">Pulse Survey Wave 01/21</a>
                        </div>
                        <div className="template_list-attachments">
                            <div className="template_list-attachment">
                                <a className="template_list-attachment-link">+ report.pdf</a>
                            </div>
                        </div>
                    </li>
                  );
              })}
          </ul>
      </div>
    )
}

export default Reports;