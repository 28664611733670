import React, { useState, useEffect, useRef } from 'react';
import { each } from 'lodash';
import { statisticsBackPageKey, increaseVariableForCurrentLine } from '../common/statistics';
import { StateContext, PlanDataProvider, BackContext } from '../common/contexts';
import WithModalContext from '../components/with_modal_context';
import { CONCEPT_BAU } from './plans_data';
import { Router, initialState } from './router';

const {
  plans_prebuild,
  plans_construct,
  allPlansIncludeConcept,
  cores,
  PERK_PRICE,
  getForkList,
  compareList,
  getConflictMessages,
  getDoubleAddConflictMessages,
  allPerksFlatten
} = CONCEPT_BAU;

const PlansBuilderA = () => {
  const timerRef = useRef(null);
  const [ path, setPath ] = useState(initialState);
  const [ backPageHash, setBackPageHash ] = useState({});
  const [ viewedPages, setViewedPages ] = useState([]);

  const getPreviousPage = () => (viewedPages.length ? viewedPages[viewedPages.length - 1] : null);
  const getBackPage = () => (backPageHash.name);
  const showBackButton = () => (viewedPages.length > 1);
  const handleBackPageClick = () => {
    if (!showBackButton()) {
      return;
    }
    increaseVariableForCurrentLine(statisticsBackPageKey[viewedPages[viewedPages.length - 1]]);
    const newViewedPages = [ ...viewedPages ];
    const backPageName = newViewedPages[newViewedPages.length - 2];
    newViewedPages.splice(newViewedPages.length - 2, 2);
    setViewedPages(newViewedPages);
    setBackPageHash({ name: backPageName });
  };
  const addViewedPage = (pageName, clearOther) => {
    const newViewedPages = [ ...(clearOther ? [] : viewedPages) ];
    if (typeof pageName === 'object') {
      each(pageName || [], (pageNameItem) => {
        newViewedPages.push(pageNameItem);
      });
    } else {
      newViewedPages.push(pageName);
    }
    setViewedPages([ ...newViewedPages ]);
    if (getBackPage()) {
      setBackPageHash({});
    }
  };

  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = `${main.className} -a`;
  }, []);
  const updatePath = (value) => {
    if (path === value) {
      setPath("Reset");
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setPath(value);
        timerRef.current = null;
      }, 0);
    } else {
      clearTimeout(timerRef.current);
      setPath(value);
    }
  };

  const dataValue = {
    plans_prebuild,
    plans_construct,
    allPlansInclude: allPlansIncludeConcept,
    cores,
    PERK_PRICE,
    getForkList,
    compareList,
    getConflictMessages,
    getDoubleAddConflictMessages,
    allPerksFlatten
  };

  const backValue = {
    backPageHash, getBackPage, handleBackPageClick,
    showBackButton, addViewedPage, getPreviousPage
  };

  return (
    <PlanDataProvider.Provider value={ dataValue }>
      <StateContext.Provider value={ [ path, updatePath ] }>
        <WithModalContext>
          <BackContext.Provider value={ backValue }>
            <Router />
          </BackContext.Provider>
        </WithModalContext>
      </StateContext.Provider>
    </PlanDataProvider.Provider>
  );
};

export default PlansBuilderA;
