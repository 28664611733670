import React, { useEffect, useState, Fragment } from 'react';
import { map } from "lodash";
import ReactTooltip from "react-tooltip";
import { deltas } from "./intersections";

const AttrRatings = ({ data }) => {
  const legend = {
    leftBottom: {
      colorClass: 'low-low',
      positionClass: 'left-bottom',
      title: 'Low Priority',
      hint: 'Low Importance + Low Performance'
    },
    rightBottom: {
      colorClass: 'high-low',
      positionClass: 'right-bottom',
      title: 'Improve First',
      hint: 'High Importance + Low Performance'
    },
    leftTop: {
      colorClass: 'low-high',
      positionClass: 'left-top',
      title: 'Examine Cost/Benefits',
      hint: 'Low Importance + High Performance'
    },
    rightTop: {
      colorClass: 'high-high',
      positionClass: 'right-top',
      title: 'Maintain',
      hint: 'High Importance + High Performance'
    }
  };

  const dotColor = (dot) => {
    if (dot.importance_percent < data.importance_avg) {
      if (dot.performance_percent < data.performance_avg) {
        return "low-low";
      }
      return "low-high";
    }
    if (dot.performance_percent < data.performance_avg) {
      return "high-low";
    }
    return "high-high";
  };

  const [ dotsGroups, setDotsGroups ] = useState({});

  useEffect(() => {
    if (data) {
      const coords = map(data.rows, (r) => {
        if (r.importance_percent > 90) {
          return [
            correctCoords(r.importance_percent, data.scale_importance_start) - r.title.length,
            correctCoords(r.performance_percent, data.scale_performance_start) - 3,
            correctCoords(r.importance_percent, data.scale_importance_start),
            correctCoords(r.performance_percent, data.scale_performance_start)
          ];
        }
        return [
          correctCoords(r.importance_percent, data.scale_importance_start),
          correctCoords(r.performance_percent, data.scale_performance_start) - 3,
          correctCoords(r.importance_percent, data.scale_importance_start) + r.title.length,
          correctCoords(r.performance_percent, data.scale_performance_start)
        ];
      });
      const pointsCoords = map(data.rows, (r) => {
        return [
          correctCoords(r.importance_percent, data.scale_importance_start),
          correctCoords(r.performance_percent, data.scale_performance_start)
        ];
      });
      const dd = deltas(coords, pointsCoords);
      setDotsGroups(dd);
    }
  }, [ data ]);

  const xScale = Array.from({ length: (10 - data.scale_importance_start / 10) + 1 }, (_, i) => data.scale_importance_start / 10 + (i));
  const yScale = Array.from({ length: (10 - data.scale_performance_start / 10) + 1 }, (_, i) => data.scale_performance_start / 10 + (i));

  const correctCoords = (value, minValue) => {
    const val = (value - minValue) / (100 - minValue);
    return Math.round(val * 100);
  };

  const ToolTipText = ({ dot }) => {
    return (
      <div className="attr-ratings_graph-data-dot-info">
        <div className="attr-ratings_graph-data-dot-info-title">
          {dot.title}
        </div>
        <div className="attr-ratings_graph-data-dot-info-values">
          Importance — {dot.importance_percent}%
          <br />
          Performance — {dot.performance_percent}%
        </div>
      </div>
    );
  };

  const tooltipText = (code) => {
    if (code === null) {
      return null;
    }

    const [ kind, index ] = code.split('-');

    if (kind === 'dot') {
      const i = parseInt(index);
      if (i in dotsGroups.indexToGroup) {
        const dots = dotsGroups.groups[ dotsGroups.indexToGroup[i] ];
        return (
          <>
            {map(dots, (di) => {
              const dot = data.rows[di];
              return (<ToolTipText key={ di } dot={ dot } />);
            })}
          </>
        );
      }
    }

    const dot = data.rows[parseInt(index)];
    return (<ToolTipText dot={ dot } />);
  };

  const groupOffset = (dot, i) => {
    if (!dotsGroups.indexToGroup) {
      return {};
    }

    if (i in dotsGroups.indexToGroup) {
      const gi = dotsGroups.indexToGroup[i];
      const shifts = dotsGroups.shifts[ gi ];
      const firstDot = data.rows[gi];
      const baseY = firstDot.performance_percent > 90 ? shifts[1] : shifts[3];

      return {
        x: firstDot.importance_percent > 90 ? shifts[0] : shifts[2],
        y: baseY + ((baseY > 90 ? -1 : 1) * (dotsGroups.indexToPos[i] * 4))
      };
    }

    return {
      x: correctCoords(dot.importance_percent, data.scale_importance_start),
      y: correctCoords(dot.performance_percent, data.scale_performance_start)
    };
  };

  return (
    <>
      <div className="attr-ratings">
        <div className="attr-ratings-content">
          {data && (
            <>
              <div className="attr-ratings_title">Attribute Ratings</div>

              <div className="attr-ratings_legend">
                {map(legend, (item, index) => (
                  <div className={ `attr-ratings_legend-item -${item.positionClass}` } key={ index }>
                    {item && (
                      <>
                        <div className={ `attr-ratings_legend-title attr-ratings_color -${item.colorClass}` }>
                          { item.title }
                        </div>
                        <div className="attr-ratings_legend-hint">
                          { item.hint }
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div className="attr-ratings_graph">
                <div className="attr-ratings_graph-grid">

                  <div className={ `attr-ratings_graph-grid-lines -horizontal` }>
                    {yScale.map((valueLine) => (
                      <div
                        key={ `line-h-${valueLine}` }
                        className="attr-ratings_graph-grid-line"
                        style={ { 'bottom': `${correctCoords(valueLine * 10, data.scale_performance_start)}%` } }
                      >
                        <div className="attr-ratings_graph-grid-line-label">{valueLine * 10}%</div>
                      </div>
                    ))}
                  </div>

                  <div className={ `attr-ratings_graph-grid-lines -vertical` }>
                    {xScale.map((valueLine) => (
                      <div
                        key={ `line-h-${valueLine}` }
                        className="attr-ratings_graph-grid-line"
                        style={ { 'left': `${correctCoords(valueLine * 10, data.scale_importance_start)}%` } }
                      >
                        <div className="attr-ratings_graph-grid-line-label">{valueLine * 10}%</div>
                      </div>
                    ))}
                  </div>

                  <div className="attr-ratings_graph-grid-labels">
                    <div className="attr-ratings_graph-grid-label -vert">{data.performance_title} (Top 2 Box)</div>
                    <div className="attr-ratings_graph-grid-label -hor">{data.importance_title} (Top 2 Box)</div>
                  </div>
                </div>

                <div className="attr-ratings_graph-data">
                  <div className="attr-ratings_graph-data-avgs">
                    <div
                      className="attr-ratings_graph-data-avg -vert"
                      style={ { 'left': `${correctCoords(Math.round(data.importance_avg), data.scale_importance_start)}%` } }
                    >
                      <div className="attr-ratings_graph-data-avg-label">
                        AVG { Math.round(data.importance_avg) }%
                      </div>
                    </div>
                    <div
                      className={ `attr-ratings_graph-data-avg -hor` }
                      style={ { 'bottom': `${correctCoords(Math.round(data.performance_avg), data.scale_performance_start)}%` } }
                    >
                      <div className={ `attr-ratings_graph-data-avg-label` }>
                        AVG { Math.round(data.performance_avg) }%
                      </div>
                    </div>
                  </div>

                  <div className="attr-ratings_graph-data-dots">
                    {data.rows.map((dot, index) => {
                      const shifts = groupOffset(dot, index);
                      return (
                        <Fragment key={ `dot-${index}` }>
                          <div
                            data-for="tooltip-content"
                            data-tip={ `dot-${index}` }
                            className={ `attr-ratings_graph-data-dot attr-ratings_color -${dotColor(dot)}` }
                            style={ { 'left': `${correctCoords(dot.importance_percent, data.scale_importance_start)}%`,
                              'bottom': `${correctCoords(dot.performance_percent, data.scale_performance_start)}%` } }
                          />
                          <div
                            className={ `attr-ratings_graph-data-dot-label ${dot.importance_percent > 90 ? '-to-left' : ''}` }
                            style={ { 'left': `${shifts.x}%`, 'bottom': `${shifts.y}%` } }
                          >
                            <span data-for="tooltip-content" data-tip={ `label-${index}` }>{dot.title}</span>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ReactTooltip
        id="tooltip-content"
        place="bottom"
        type="dark"
        effect="solid"
        delayShow={ 0 }
        getContent={ tooltipText }
      />
    </>
  );
};

export default AttrRatings;
