import React, { Fragment, useContext } from 'react';
import cn from "classnames";
import { map } from 'lodash';
import { swipeTimesByTarget } from "../../logic/helpers";
import { getMetricKind, getMetricTitle, IndexData } from "../../../common/index-context";
import StatusCircle from "../../../common/components/status-circle";
import { showedAverageValue, n0, averageCount } from '../../../common/helpers';


const TrCombinedTools = ({ data, checkedConcepts, combinedTools }) => {
  const { sort, averageMode, init } = useContext(IndexData);

  return (
    <>
      <tr className="-highlighted">
        <td
          colSpan={ checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1 }
        >
          Swipe ({getMetricKind(data, 'combined_tool')})
        </td>
        {
          checkedConcepts.length > 3 &&
          <td colSpan={ checkedConcepts.length + 1 - 3 } />
        }
        <td className="table-comparison_average-cell -highlighted" />
      </tr>

      <tr>
        <td className="table-comparison_name-cell">(N)</td>
        {
          map(checkedConcepts, (concept, i) => (
            <td key={ i }>
              <span>({ concept.swipe_count })</span>
            </td>
          ))
        }
        <td className="table-comparison_average-cell">
          ({ averageCount(n0(data.swipe_avg_count), averageMode, init.isInternal, data.view) })
        </td>
      </tr>

      {
        map(combinedTools, (values, title) => (
          <Fragment key={ title }>
            <tr className="time-addition-start">
              <td className="table-comparison_name-cell">
                <span className={ cn({ 'sort-link -current': title == sort }) }>
                  {getMetricTitle(data, title)}
                </span>
              </td>
              {
                map(checkedConcepts, (concept, i) => (
                  <td key={ i }>
                    <StatusCircle box="top2" values={ values[concept.id] } />
                  </td>
                ))
              }

              <td className="table-comparison_average-cell">
                { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
              </td>
            </tr>

            <tr>
              <td className="time-addition-title">
                (Avg swipe time)
              </td>
              {
                map(checkedConcepts, (concept, i) => {
                  const temp = swipeTimesByTarget(data, title)[concept.id];
                  const v = temp ? Math.round(temp.value) : '-';
                  return (<td key={ i }>{v} sec</td>);
                })
              }
              <td className="table-comparison_average-cell">
                {averageCount(n0(swipeTimesByTarget(data, title).avg_value), averageMode, init.isInternal, data.view)} sec
              </td>
            </tr>
          </Fragment>
        ))
      }
    </>
  );
};

export default TrCombinedTools;
