import { each, sum, map } from 'lodash';

const runPreload = (concepts, fields, dispatchChangeLoadingState) => {
  if (!!concepts && !!concepts.length) {
    const numToLoad = sum(
      map(concepts, (item) =>
        sum(
          map(fields, (field) => item[field] ? 1 : 0)
        )
      )
    );
    let currentlyLoaded = 0;
    each(concepts, (concept) => {
      each(fields, (field) => {
        if (concept[field]) {
          const img = new Image();
          img.onload = function () {
            currentlyLoaded += 1;
            if (currentlyLoaded === numToLoad) {
              dispatchChangeLoadingState(false);
            }
          };
          img.src = concept[field];
          concept[`${field}_object`] = img;
        }
      });
    });
  } else {
    dispatchChangeLoadingState(false);
  }
};

export default runPreload;
