import { fromPairs, map, each, flatten, values, find } from 'lodash';
import { calculateQuotasForQuestion } from '../../common/logic/calculate_quotas';

const defaultAnswersOrder = 'specific';
const countryAnswersOrder = 'alphabetical';

const prepareQuestion = (
  q, languageMapping, languageCodeMapping, defaultLanguageCode
) => {
  const list = [];

  each(q.answers, (v, k) => {
    const languageCodes = q.is_country ?
      languageMapping[v] :
      [ (defaultLanguageCode || 'en') ];

    each(languageCodes, (code) => {
      const lastItem = list[list.length - 1];

      list.push({
        key: k,
        item: v,
        languageCode: code,
        languageTitle: (
          languageCodes.length > 1 ?
            languageCodeMapping[code.split('_')[0]] :
            null
        ),
        addToServer: !lastItem || (lastItem.item !== v)
      });
    });
  });
  q.preparedAnswers = list;
  return q;
};

const initExistingBaseDemoQuestions = (currentList, initList) => {
  return map(currentList, (item) => {
    const qInit = find(initList, (el) => el.title === item.title) || {};
    item.uiTitle = () => (qInit.custom_title || qInit.title);
    item.initialData = qInit;
    item.availableToManageQuotas = qInit.available_to_manage_quota;
    item.toggleManageQuota = true;
    item.noneQualify = '';
    item.answers_order = (
      item.is_country ? countryAnswersOrder : defaultAnswersOrder
    );
    return item;
  });
};

const optionalFromInitToDatabaseQuestion = (qInit, conditionValues) => {
  const result =  {
    kind: qInit.kind,
    title: qInit.title,
    uiTitle: () => (qInit.custom_title || qInit.title),
    answers: fromPairs(map(qInit.answers, (item, index) => [ `${index + 1}`, item.title ])),
    condition: fromPairs(map(qInit.answers, (item, index) => [ `${index + 1}`, "is" ])),
    quotas: {},
    available: true,
    availableToManageQuotas: qInit.available_to_manage_quota,
    toggleManageQuota: true,
    manage_quota: false,
    initialData: qInit,
    isCustom: qInit.custom,
    breaks: qInit.breaks || [],
    none_answer: qInit.update_none_answer || false,
    noneQualify: 'is_not',
    canPickNoneAnswer: qInit.can_pick_none_answer,
    customPickNoneMessage: qInit.custom_pick_none_message,
    customNoneMessage: qInit.custom_none_message || '',
    cantStand: qInit.cant_stand || [],
    quotaMappingTitle: qInit.quota_mapping_title,
    quotaMappingList: qInit.quota_mapping_list,
    quotaMappingAnswers: qInit.quota_mapping_answers,
    remote_name: qInit.remote_name,
    answers_order: defaultAnswersOrder
  };

  each(result.breaks, (el) => {
    calculateQuotasForQuestion(el, {}, conditionValues);
  });
  if (result.manage_quota) {
    calculateQuotasForQuestion(result, {}, conditionValues);
  }
  return result;
};

const initExistingOptionalQuestions = (currentList, initHash) => {
  const initList = flatten(values(initHash));

  return map(currentList, (item) => {
    const qInit = find(initList, (el) => el.title === item.title) || {};
    item.uiTitle = () => (qInit.custom_title || qInit.title);
    item.customTitle = qInit.custom_title;
    item.initialData = qInit;
    item.availableToManageQuotas = qInit.available_to_manage_quota;
    item.toggleManageQuota = true;
    item.isCustom = qInit.custom;
    item.customNoneMessage = qInit.custom_none_message || '';
    item.canPickNoneAnswer = qInit.can_pick_none_answer;
    item.customPickNoneMessage = qInit.custom_pick_none_message;
    item.cantStand = qInit.cant_stand || [];
    item.quotaMappingTitle = qInit.quota_mapping_title;
    item.quotaMappingList = qInit.quota_mapping_list;
    item.quotaMappingAnswers = qInit.quota_mapping_answers;
    item.answers_order = defaultAnswersOrder;

    if (!item.noneQualify) {
      item.noneQualify = 'is_not';
      if (item.condition && item.condition['-1']) {
        item.noneQualify = item.condition['-1'];
      }
    }
    if (item.condition) {
      delete item.condition['-1'];
    }

    if (typeof item.breaks === 'string') {
      item.breaks = JSON.parse(item.breaks);
    }
    return item;
  });
};

export {
  prepareQuestion,
  optionalFromInitToDatabaseQuestion,
  initExistingOptionalQuestions,
  initExistingBaseDemoQuestions
};
