import React, { useContext, useEffect, useRef } from 'react';
import { find, map, includes, isEmpty, keys } from "lodash";
import { baseContext } from "../context";
import Http from "../../../../common/http";
import downloadStarts from "../../common/download-messaging";
import { reviewRespondentsExportClick } from "../../../common/google-analytics/events";
import ReviewRespondent from "./review-respondent";

const Index = () => {
  const { data, filters, currentFilter, setCurrentFilter, projectId, gaTrackingId } = useContext(baseContext);
  const containerRef = useRef();

  useEffect(() => {
    if (data) {
      setCurrentFilter(filters[data.filter]);
    }
  }, [ data ]);

  const findCount = (id) => {
    return keys(filters[id].scope).length || 0;
  };

  const changeFilter = (id) => {
    setCurrentFilter(filters[id]);
    const el = containerRef.current;
    if (el) {
      el.scrollTop = 0;
    }
  };

  const showLegend = (scope) => find(scope, (el) => includes([ 3, 4 ], el.banned));
  const noOpenEnds = isEmpty(data.row_list);

  const exportReport = (event, params = { current_all_as_hash: {} }) => {
    event.preventDefault();
    event.stopPropagation();
    const url = `/express/api/projects/${projectId}/export?report=respondents_info&kind=xlsx`;
    reviewRespondentsExportClick(gaTrackingId, { project_id: projectId });

    Http.post(url, params).then(
      (http) => {
        if (http.response.status === 200) {
          downloadStarts(event, 'We have started to prepare your report for download. Click here to access it.');
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  return (
    <div className="review-respondents">
      <div className="content -body-scroll">
        <div className="content_top">
          <header className="header">
            <div className="header_grid">
              <div className="header_grid-item -grow">
                <h1 className="header_title">Review Respondents</h1>
              </div>
              <div className="header_grid-item">
                <button className="button" onClick={ exportReport } disabled={ noOpenEnds }>
                  Export
                </button>
              </div>
            </div>
          </header>
        </div>
        {
          noOpenEnds &&
          <div className="content_body -no-padding-top">
            There are no open-end metrics in the project.
          </div>
        }
        {
          !noOpenEnds &&
          <>
            <div className="content_part">
              <div className="review-respondents_filter">
                <ul className="review-respondents_filter-list">
                  {map(filters, (filter, index) => (
                    <li className="review-respondents_filter-item" key={ index }>
                      <button
                        className={ `button-filter -${filters[index].color ? filters[index].color : 'default'}${currentFilter.id === index ? ' -selected' : ''}` }
                        onClick={ () => changeFilter(index) }
                      >
                        {filter.title} = {findCount(filter.id)}
                      </button>
                    </li>
                  ))
                  }
                </ul>
                {showLegend(currentFilter.scope) && (
                  <div className="review-respondents_filter-legend">
                    <div className="review-respondents_filter-legend-letter">A</div>
                    <div className="review-respondents_filter-legend-text">
                      * Blocked automatically
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="content_body -no-padding-top" ref={ containerRef }>
              <ul className="review-respondents_list">
                {map(currentFilter.scope, (respondent, index) => (
                  <li className="review-respondents_item" key={ `respondent-${respondent.resp_id}-${index}` }>
                    <ReviewRespondent
                      respondent={ respondent }
                      rows={ data.row_list }
                    />
                  </li>
                ))
                }
              </ul>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Index;
