import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { map, compact, filter } from 'lodash';
import Http from "../../../../../common/http";

const customStyles = {
  container: () => ({
    padding: 0,
    minHeight: '38px',
    background: '#fff',
    border: '1px solid #e5e5e5',
    borderRadius: '18px',
    transition: 'border-color .3s,color .3s,background .3s,background-color .3s',
    position: 'relative',
    boxSizing: 'border-box'
  }),
  control: (provided) => {
    return { ...provided, backgroundColor: 'none', borderWidth: 0, boxShadow: 'none' };
  },
  input: (provided) => {
    return { ...provided, backgroundColor: 'none', borderWidth: 0 };
  },
  placeholder: (provided) => {
    return { ...provided, fontSize: '14px', fontWeight: 'normal' };
  },
  option: (provided) => {
    return { ...provided, fontSize: '14px', fontWeight: 'normal' };
  },
  multiValue: (provided) => {
    return { ...provided, backgroundColor: 'none', fontWeight: 'normal', fontSize: '14px', border: '1px solid #e5e5e5', borderRadius: '15px', margin: '2px' };
  },
  multiValueRemove: (provided) => {
    return { ...provided, ':hover': { color: '#c5c6c6' }, color: '#c5c6c6' };
  },
  clearIndicator: (provided) => {
    return { ...provided, color: '#e5e5e5' };
  }
};

const TagsInputBrand = ({ initTags, onTagsChange, placeholder, projectId, searchKeywords }) => {
  const [ value, setValue ] = useState([]);

  const loadKeywords = (q, resolve) => {
    const url = `/express/api/text_answers/keywords`;
    const params = {
      filter: filter.demographics || [],
      project_id: projectId,
      q
    };

    return Http.post(
      url,
      params
    ).then(
      (http) => {
        if (http.response.status === 200) {
          resolve(map(http.data, (el) => ({ value: el.text, label: el.text })));
        } else {
          resolve([]);
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      loadKeywords(inputValue, resolve);
    });
  };

  const onChange = (values) => {
    const newValues = filter(values, (el) => (el.value));
    const tags = map(newValues, (v) => v.value);
    onTagsChange(tags);
    setValue(newValues);
  };

  useEffect(() => {
    const exTags = compact(initTags);
    setValue(map(exTags, (t) => ({ value: t, label: (t.text || t) })));
  }, [ initTags ]);

  return (
    <AsyncSelect
      id="synonym-select"
      isMulti
      defaultOptions={ searchKeywords }
      loadOptions={ promiseOptions }
      onChange={ onChange }
      value={ value }

      placeholder={ placeholder || "Filter By Keywords" }
      noOptionsMessage={ () => ("Type query") }
      styles={ customStyles }
    />
  );
};

export default TagsInputBrand;
