import { find } from 'lodash';
import { capitalizeFirstLetter } from '../../../launch/helper';

const defaultItemName = 'item';

const isAvailable = (completeTargetData, totalN, conceptN) => (
  completeTargetData.available && (!totalN || !conceptN || totalN !== conceptN)
);
const sectionTitleFromData = (completeTargetData) => (completeTargetData.options.title);
const itemsFromData = (completeTargetData) => (completeTargetData.options.items);
const editedTitle = (rawTitle, textItemName) => (
  rawTitle.replace(/%item%/, capitalizeFirstLetter(textItemName || defaultItemName))
);
const itemTitleByValue = (completeTargetData, itemValue, textItemName) => (
  editedTitle(
    find(
      completeTargetData.options.items,
      (title, name) => (name === itemValue)
    ),
    textItemName
  )
);

const calculateNewNSize = (completeTarget, completeTargetData, nSize) => {
  const availableByType = completeTargetData.available_by_type;
  const increaseHash = completeTargetData.n_size_increase_data;
  const exactQuotaQuestions = completeTargetData.exact_quota_questions;
  const conditionKey = [];
  if (exactQuotaQuestions.length) {
    conditionKey.push('min_quota');
  }
  if (availableByType && completeTarget === 'concept') {
    conditionKey.push('concept_size');
  }
  if (!conditionKey.length) {
    conditionKey.push('other');
  }
  return Math.ceil(increaseHash[conditionKey.join('_and_')] * nSize);
};

export {
  defaultItemName, isAvailable, sectionTitleFromData, itemsFromData, editedTitle,
  itemTitleByValue, calculateNewNSize
};
