const sections = [
  {
    title: "Durations",
    items: ["Under 15 min", "15 min - 30 min", "30-45+ min"],
  },
  {
    title: "Equipment",
    items: ["None", "Dumbbells", "Bike"],
  },
  {
    title: "Body Focus",
    items: ["Full", "Core", "Legs"],
  },
];

export default sections;
