import React, { useState } from 'react';
import Routes from './routes';
import { setLinesCount, planIds, perkIds } from './plansData';
import Statistic from '../../statistic'
import { RouteContext } from './contexts';

const Index = () => {
  const [path, setPath] = useState('/');
  const [isDone, setDone] = useState(false);
  const onLinesCountChange = (linesCount) => {
    setLinesCount(linesCount)
    Statistic.create(linesCount, planIds, perkIds);
  };
  const handleDone = () => {
    Statistic.send();
    setDone(true);
  };
  return !isDone
    ? <RouteContext.Provider value={{ path, setPath }}>
        <Routes onLinesCountChange={onLinesCountChange} onDone={handleDone} />
      </RouteContext.Provider>
    : null;
};

export default Index;
