import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';

const Success = ({ text }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start -sm">
        <svg className="survey-start_icon -sorry" aria-hidden={true}>
          <use xlinkHref="#svg_survey-sorry-nocolor" />
        </svg>
        <h1 className="survey-start_title -sm" { ...renderRawHtml(text) } />
      </div>
    </div>
  );
};

export default Success;
