import React, { useContext } from 'react';
import GoogleRecaptcha from '../components/google_recaptcha';
import Success from '../components/success';
import Fail from '../components/fail';
import { baseContext } from "../../common/contexts";
import {
  lastStepsLogic,
  completeActionNum as completeActionNumBase,
  completeComponentNum as completeComponentNumBase,
  screenedActionNum as screenedActionNumBase,
  screenedComponentNum as screenedComponentNumBase
} from '../../common/logic/last_steps';

const completeActionNum = completeActionNumBase;
const completeComponentNum = completeComponentNumBase;
const screenedActionNum = screenedActionNumBase;
const screenedComponentNum = screenedComponentNumBase;

const LastStepsComponents = ({ step, lastStepsFrom, data, nextStep }) => {
  const { translations } = useContext(baseContext);
  return (
    <>
      { step === lastStepsFrom && <GoogleRecaptcha loadingOnly /> }
      {
        step === (lastStepsFrom + 1) &&
        <GoogleRecaptcha
          projectId={ data.project_id }
          respondentId={ data.respondent_id }
          nextStep={ nextStep }
        />
      }
      { step === (lastStepsFrom + 2) && <GoogleRecaptcha loadingOnly /> }
      { step === completeComponentNum && <Success text={ translations.survey_passed } /> }
      { step === screenedComponentNum && <Fail text={ translations.survey_disqualified } /> }
    </>
  );
};

export {
  completeActionNum, completeComponentNum,
  screenedActionNum, screenedComponentNum,
  LastStepsComponents, lastStepsLogic
};
