import { map } from 'lodash';
import { getLinesCount } from "../statistics";

const pricing = [
  [ 35, 35, 35, 35, 35 ],
  [ 45, 45, 45, 45, 45 ],
  [ 60, 60, 60, 60, 60 ],
  [ 70, 70, 70, 70, 70 ]
];

const allPlansInclude = [
  "Unlimited talk and text in the US",
  "Unlimited text to 200 international destinations"
];

const five = {
  id: 1,
  name: "5GB",
  title: "5<span class=\"name-part\">GB</span>",
  landingTitle: "5<span class=\"name-part\">GB</span>",
  description: "Enjoy the data you need to stay connected with your friends and family.",
  features: [
    { title: "5G Nationwide", description: "", popupIcon: true },
    { title: "Mobile Hotspot", description: "", big: true },
    { title: "&nbsp;", description: "", big: true }
  ]
};

const fifteen = {
  id: 2,
  name: "15GB",
  title: "15<span class=\"name-part\">GB</span>",
  landingTitle: "15<span class=\"name-part\">GB</span>",
  description: "Get more data for online streaming, posting on social and sharing with your family.",
  features: [
    { title: "5G Nationwide", description: "", popupIcon: true },
    { title: "Mobile Hotspot", description: "", big: true },
    { title: "Includes talk to Mexico and Canada", description: "" },
  ]
};

const unlimited = {
  id: 3,
  name: "Prepaid Unlimited",
  title: "Prepaid Unlimited",
  landingTitle: "Unlimited",
  description: "Get the freedom of unlimited talk, text and data without any overage charges.",
  features: [
    { title: "5G Nationwide", description: "", popupIcon: true },
    { title: "Add Mobile Hotspot for $5/mo", description: "", big: true },
    { title: "Includes talk, text, and data to and from Mexico and Canada", description: "" },
  ]
};

const unlimitedPlus = {
  id: 4,
  name: "Prepaid Unlimited Plus",
  title: "Prepaid Unlimited Plus",
  landingTitle: "Unlimited Plus",
  description: "Experience the unprecedented speed and performance of unlimited 5G Ultra Wideband.",
  features: [
    { title: "5G Nationwide", description: "", popupIcon: true },
    { title: "Includes unlimited 5G Ultra Wideband Mobile Hotspot", description: "", popupIcon: true },
    { title: "Includes talk, text, and data to and from Mexico and Canada", description: "" },
  ]
};

const prepaidPlans = map([
  five, fifteen, unlimited, unlimitedPlus
], (el) =>
  ({
    ...el,
    get price() { return pricing[el.id - 1][getLinesCount() - 1]; }
  })
);

export { prepaidPlans, allPlansInclude as prepaidAllPlansInclude };