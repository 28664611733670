import React from 'react';

const Circle = ({ color, percent, width }) => {
  // Calculations for the circle for the 0,0, 36, 36 viewport
  // radius = circumference / 2π
  // radius = 100 / ( 3,14159 * 2 ) = 15.9155
  // diameter = 15,9155 * 2 = 31.831
  //x: 18 # (36/2)
  // y: 2.0845 # ((36–31.831) / 2)
  return (
    <path
      d="M18 2.084
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831
              "
      fill="none"
      stroke={ color }
      strokeWidth={ width }
      strokeDasharray={ `${percent}, 100` }
    />
  );
};

export default Circle;
