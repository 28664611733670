import React, { useContext, useState, useEffect } from "react";
import { baseContext, filesContext, reportsContext } from "../../contexts";
import { loadAdminFiles } from "../api";
import { getGroupName, withPrefix } from "../../helpers";
import { filesPlaceToShow } from '../helper';
import FileModal from "./fileModal";

const FilesEditor = () => {
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ fileToEdit, setFileToEdit ] = useState(null);
  const { mediaKind, verizonType, combine5gAndLte } = useContext(baseContext);
  const filesContextData = useContext(filesContext);
  const filesRefresh = filesContextData.refresh;
  const reportsContextData = useContext(reportsContext);
  const reportsRefresh = reportsContextData.refresh;
  const [ files, setFiles ] = useState([]);
  const [ filePlaces ] = useState(() => ({
    current: filesPlaceToShow.current(verizonType, mediaKind, combine5gAndLte),
    archived: filesPlaceToShow.archived(verizonType, mediaKind, combine5gAndLte),
    additional: filesPlaceToShow.additional(verizonType, mediaKind, combine5gAndLte)
  }));

  useEffect(() => {
    loadAdminFiles(mediaKind, verizonType, combine5gAndLte, (data) => {
      setFiles(data);
    });
  }, []);

  const openModal = (event, user) => {
    if (event.target.href) {
      return;
    }
    setFileToEdit(user);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleSave = (data) => {
    handleClose();
    filesRefresh();
    reportsRefresh();
    setFiles(data);
  };
  const handleDelete = (data) => {
    handleClose();
    filesRefresh();
    reportsRefresh();
    setFiles(data);
  };

  const placeToShow = (file) => {
    switch (file.target) {
      case "archive":
        return filePlaces.archived;
      case "current":
        return filePlaces.current;
      default:
        return filePlaces.additional;
    }
  };

  return (
    <>
      {
        modalOpen &&
        <FileModal
          file={ fileToEdit }
          onClose={ handleClose }
          onSave={ handleSave }
          onDelete={ handleDelete }
        />
      }
      <div className="pulse section">
        <div className="section_top -grid">
          <div className="section_top-item">
            <h4 className="section_title">
              { withPrefix(verizonType, mediaKind) } Documents
            </h4>
          </div>
          <div className="section_top-item">
            <button className="button -sm -upper" onClick={ (event) => { openModal(event, null); } }>New Document</button>
          </div>
        </div>
        <div className="section_body">
          <table className="table -striped">
            <thead>
              <tr>
                <th>Link Title</th>
                <th>Hidden</th>
                <th>Display group</th>
                <th>Place to Show</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              {
                files.map((file) => {
                  return (
                    <tr
                      className="table_tr-clickable -pointer"
                      key={ `EditDocument${file.id}` }
                      onClick={ (event) => { openModal(event, file); } }
                    >
                      <td>{ file.title }</td>
                      <td>{ file.hidden ? "Hidden" : "-" }</td>
                      <td>{ getGroupName(file.archived) }</td>
                      <td>{ placeToShow(file) }</td>
                      <td>
                        <a
                          href={ file.attachment_url }
                          className="table_control-over-line"
                        >
                          { file.title }
                        </a>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FilesEditor;
