import React, { useEffect, useState, useContext } from 'react';
import { map, isEmpty } from "lodash";
import Loader from "../../../../../../../images/icons/loader.gif";
import { baseContext } from "../../context";

const RadioFilter = ({ attrName, prefix, options, labelClass, currentValue, onUpdate }) => {
  const [ val, setVal ] = useState(currentValue || {});
  const [ isLoading, setIsLoading ] = useState(false);
  const { error } = useContext(baseContext);

  useEffect(() => {
    if (isLoading && !isEmpty(currentValue)) {
      setIsLoading(false);
    }
    setVal(currentValue);
  }, [ currentValue ]);

  const onChange = (value) => {
    setIsLoading(true);
    const params = {};
    params[attrName] = value.id;
    onUpdate(params);
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [ error ]);

  return (
    <div className="radio-tabs -grow -block -gray-bg">
      {
        !isLoading &&
        map(options, (option, index) => (
          <label htmlFor={ `${prefix}-${option.id}` } className={ `radio-tabs_item ${labelClass}` } key={ `${option.id}-${index}` }>
            <input
              className="fixed"
              id={ `${prefix}-${option.id}` }
              checked={ val.id === option.id }
              onChange={ () => {onChange(option);} }
              type="radio"
            />
            <span className="radio-tabs_label -width-1-1 -centered">
              <span>{option.label}</span>
            </span>
          </label>
        ))
      }
      {
        isLoading &&
        <div className="concepts-database_loader">
          <img width="24px" height="24px" src={ Loader } />
        </div>
      }
    </div>
  );
};

export default RadioFilter;
