import React from 'react';
import StickyTip from '../common/stickyTip';
import BestPerks from '../common/bestPerks';
import Card from '../common/card';
import { PerkList } from '../common/perkList';
import { planList, perkList } from './plansData';
import Statistic from '../../statistic';

const renderPlansList = ({ id, name, price, shortDescription, perkCount }) =>
  <li key={id} className="billing-plans-list-item">
    <Card>
      <div className="billing-plan-title">
        <span>{name}</span>
        <span>${price}</span>
      </div>
      <p className="billing-plan-description">{shortDescription}</p>
      {perkCount > 0 && <p className="billing-plan-perks-text">
        {perkCount} Bonus perk{perkCount > 1 && 's'} of your choice
      </p>}
    </Card>
  </li>

const BillingPlansList = () => {
  return <div className="billing-plans">
    <h6 className="billing-plans-title -step">
      Step 1:<br/>
      Choose a plan.
    </h6>
    <ul className="billing-plans-list">
      {planList.map(renderPlansList)}
    </ul>
  </div>;
}

const PlansInclude = () => {
  const handleDetailsClick = (idx) => {
    Statistic.updateValue(`perks.${idx}.LandingDetailsCount`);
  };
  return <div>
    <h6 className="billing-plans-title -step">
      Step 2:<br/>
      For Play More<br/>or Get More Unlimited, pick your bonus perks.
    </h6>
    <div className="perk-wrapper">
      <PerkList list={perkList} onDetailsClick={handleDetailsClick} />
    </div>
  </div>
}

const Landing = ({ onContinue }) => {
  return <div className="layout">
    <StickyTip/>
    <div className="landing content -bau has-sticky-tip">
      <BestPerks
        titleOnCenter
        text="Customize our best unlimited plans with bonus perks." />

      <div>
        <BillingPlansList/>
        <div className="hr" style={{ marginTop: 0, marginBottom: '24px' }} />
        <PlansInclude/>
      </div>

      <footer>
        <button onClick={onContinue} className="button" type="button">Get started</button>
      </footer>
    </div>
  </div>
};

export default Landing;
