import React, { useEffect, useState } from 'react';
import { filter, map, findIndex, sortBy } from "lodash";
import Notification from "../common/notification";
import {
  creditsAdd,
  creditsAddSubAccount,
  creditsRemove,
  creditsRemoveSubAccount
} from "../../common/google-analytics/events";
import { addSub, deleteSub, getClients, addCredits, removeCredits } from './requests';
import ClientRow from "./components/client-row";
import SubClient from "./components/subclient";

const App = ({ google_analytics_id }) => {
  const [ clients, setClients ] = useState();
  const [ notificationObject, setNotificationObject ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  useEffect(() => {
    getClients(
      (response) => {
        if (response.response.ok) {
          setClients(response.data.clients);
        }
        setLoading(false);
      }
    );
  }, [ ]);

  const onAddSub = (main, sub) => {
    setLoading(true);
    creditsAddSubAccount(google_analytics_id, { main_client_id: main.id, sub_client_id: sub.id });
    addSub(
      main.id,
      sub.id,
      (response) => {
        if (response.response.ok) {
          const index = findIndex(clients, (el) => (el.id === sub.id));
          const deleted = clients.splice(index, 1);
          const allClients = [ ...clients ];
          const mainIndex = findIndex(clients, (el) => (el.id === main.id));
          allClients[mainIndex].subclients = sortBy([ ...allClients[mainIndex].subclients, deleted[0] ], (item) => item.name);
          setClients(sortBy(allClients, (item) => item.name.toLowerCase()));
        }
        setLoading(false);
      }
    );
  };

  const onDeleteSub = (main, sub) => {
    setLoading(true);
    creditsRemoveSubAccount(google_analytics_id, { main_client_id: main.id, sub_client_id: sub.id });
    deleteSub(
      main.id,
      sub.id,
      (response) => {
        if (response.response.ok) {
          const index = findIndex(main.subclients, (el) => (el.id === sub.id));
          const deleted = main.subclients.splice(index, 1);
          deleted[0].subclients = [];
          const newSub = [ ...clients, ...deleted ];
          setClients(sortBy(newSub, (item) => item.name.toLowerCase()));
        }
        setLoading(false);
      }
    );
  };

  const onAddCredits = (client, amount, callback) => {
    setLoading(true);
    creditsAdd(google_analytics_id,  { main_client_id: client.id });
    addCredits(
      client,
      amount,
      (response) => {
        if (response.response.ok) {
          const index = findIndex(clients, (el) => (el.id === client.id));
          const deleted = clients.splice(index, 1);
          deleted[0].credits += amount;
          const newSub = [ ...clients, ...deleted ];
          setClients(sortBy(newSub, (item) => item.name.toLowerCase()));
          callback("");
        } else {
          setNotificationObject({ message: response.data.errors.amount, success: false });
        }
        setLoading(false);
      },
      (response) => {
        setNotificationObject({ message: response.data.errors.amount, success: false });
        setLoading(false);
      }
    );
  };

  const onRemoveCredits = (client, amount, callback) => {
    setLoading(true);
    creditsRemove(google_analytics_id, { main_client_id: client.id });
    removeCredits(
      client,
      amount,
      (response) => {
        if (response.response.ok) {
          const index = findIndex(clients, (el) => (el.id === client.id));
          const deleted = clients.splice(index, 1);
          deleted[0].credits -= amount;
          const newSub = [ ...clients, ...deleted ];
          setClients(sortBy(newSub, (item) => item.name.toLowerCase()));
          callback("");
        } else {
          setNotificationObject({ message: response.data.errors.amount, success: false });
        }
        setLoading(false);
      },
      (response) => {
        setNotificationObject({ message: response.data.errors.amount, success: false });
        setLoading(false);
      }
    );
  };

  const subCandidates = (current) => {
    return filter(clients, (client) => (client.id != current.id && client.subclients.length == 0));
  };

  return (
    <>
      <div className="content -grey-background">
        {
          loading && <div className="page-loader -inner-loader" />
        }
        <div className="content_top -sticky -white">
          <header className="header">
            <div className="header_grid">
              <div className="header_grid-item -grow">
                <h1 className="header_title">Clients</h1>
              </div>
            </div>
          </header>
        </div>
        <div className="content_body -relative">
          {
            map(clients, (client) => (
              <div className="client"  key={ `client-${client.id}` }>
                <ClientRow
                  client={ client }
                  subCandidates={ subCandidates(client) }
                  onAddSub={ onAddSub }
                  onAddCredits={ onAddCredits }
                  onRemoveCredits={ onRemoveCredits }
                />
                <div className="subclients-row">
                  <ul>
                    {
                      !client.subclients.length &&
                      <li>No sub-accounts</li>
                    }
                    {
                      !!client.subclients.length &&
                      map(client.subclients, (subClient) => (
                        <SubClient
                          key={ `subClient-${subClient.id}` }
                          client={ client }
                          subClient={ subClient }
                          onDelSub={ onDeleteSub }
                        />
                      ))
                    }
                  </ul>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
    </>
  );
};

export default App;
