import React from "react";
import cn from "classnames";
import ScheduleCard from "./ScheduleCard";

const WeekSchedule = ({ title, days, className }) => {
  return (
    <div className={cn("week-schedule", className)}>
      <div className="week-schedule_period">{title}</div>
      {days.map(({ title: dayTitle, activities }, i) => (
        <div className="week-schedule_day" key={i}>
          <h3 className="week-schedule_day-title">{dayTitle}</h3>
          {activities.map((activitiy, j) => (
            <ScheduleCard {...activitiy} key={j} className="mb-3" />
          ))}
        </div>
      ))}
    </div>
  );
};

export default WeekSchedule;
