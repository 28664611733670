import React, { createContext, useState } from "react";
import { map, flatten, each, findIndex, filter, assign, remove, forEach, find } from "lodash";
import dragDropFunctions from './components/dragDropFunctions';

const DataContext = createContext({});

const WithDataContext = ({ data, options, children }) => {
  const [ cartExercise, setCartExercise ] = useState(data.cart_exercise);
  const [ cartWithPrices, setCartWithPrices ] = useState(data.cart_with_prices);
  const [ cartRanking, setCartRanking ] = useState(data.cart_ranking);
  const [ cartWithLimit, setCartWithLimit ] = useState(data.cart_with_limit);
  const [ cartLimit, setCartLimit ] = useState(data.cart_limit || '');
  const [ shelves, setShelves ] = useState(flatten(map(data.express_images, (el) => (el.cart_shelves))));
  const [ currentShelfIndex, setCurrentShelfIndex ] = useState(-1);
  const [ currentProductIndex, setCurrentProductIndex ] = useState(-1);
  const [ shelfUpdated, setShelfUpdated ] = useState({});
  const [ dragDropFunctionsInstance ] = useState(() => dragDropFunctions());
  const { createDragDropForProduct, createDragDropForShelf } = dragDropFunctionsInstance;

  const onShelfUpdated = (index) => {
    setShelfUpdated({ index });
  };
  const notDestroyed = (list) => filter(list, (item) => !item._destroy);

  const addProduct = (product) => {
    const shelf = shelves[currentShelfIndex];
    shelf.cart_products.push(product);
    setShelves([ ...shelves ]);
  };

  const updateProduct = (product) => {
    const shelf = shelves[currentShelfIndex];
    const currentProduct = notDestroyed(shelf.cart_products)[currentProductIndex];
    assign(currentProduct, product);
    setShelves([ ...shelves ]);
  };

  const deleteProduct = () => {
    const shelf = shelves[currentShelfIndex];
    const currentProduct = notDestroyed(shelf.cart_products)[currentProductIndex];
    each(currentProduct.places, (place) => {
      if (place.id) {
        place._destroy = '1';
      } else {
        place._removeNow = '1';
      }
    });
    each(
      filter(currentProduct.places, (el) => (el._removeNow)),
      (place) => { remove(currentProduct.places, (item) => item === place); }
    );
    if (currentProduct.id) {
      assign(currentProduct, { _destroy: '1' });
    } else {
      remove(shelf.cart_products, (el) => el === currentProduct);
    }

    setCurrentProductIndex(-1);
    setShelves([ ...shelves ]);
    return currentProductIndex;
  };

  const getAvailableProducts = (shelf) => (notDestroyed(shelf.cart_products));

  const getProductName = (shelf, area) => {
    const product = getAvailableProducts(shelf)[area.productIndex];
    return product?.name;
  };

  const getShelfPlaces = (someShelf) => {
    const placesCoords = [];
    const shelf = someShelf || shelves[currentShelfIndex];
    each(getAvailableProducts(shelf), (product, productIndex) => {
      each(notDestroyed(product.places), (place) => {
        placesCoords.push({
          id: place.id,
          left: place.area_left,
          top: place.area_top,
          width: place.area_width,
          height: place.area_height,
          imageWidth: place.image_width,
          imageHeight: place.image_height,
          productIndex
        });
      });
    });
    return placesCoords;
  };

  const syncCoords = (coords) => {
    const shelf = shelves[currentShelfIndex];
    const byProductIndex = {};

    each(coords, (rect) => {
      if (!byProductIndex[rect.productIndex]) {
        byProductIndex[rect.productIndex] = [];
      }
      byProductIndex[rect.productIndex].push(rect);
    });

    each(notDestroyed(shelf.cart_products), (product, productIndex) => {
      const newPlaces = [];
      const subCoords = byProductIndex[productIndex] || [];

      each(product.places, (place) => {
        if (place.id) {
          const placeSubIndex = findIndex(subCoords, (el) => (el.id === place.id));
          if (placeSubIndex === -1) {
            place._destroy = '1';
            newPlaces.push(place);
          } else if (placeSubIndex > -1) {
            place.area_left = subCoords[placeSubIndex].left;
            place.area_top = subCoords[placeSubIndex].top;
            place.area_width = subCoords[placeSubIndex].width;
            place.area_height = subCoords[placeSubIndex].height;
            place.image_width = subCoords[placeSubIndex].imageWidth;
            place.image_height = subCoords[placeSubIndex].imageHeight;
            newPlaces.push(place);
          }
        }
      });

      // eslint-disable-next-line lodash/prefer-filter
      each(subCoords, (rect) => {
        if (!rect.id) {
          newPlaces.push({
            area_left: rect.left,
            area_top: rect.top,
            area_width: rect.width,
            area_height: rect.height,
            image_width: rect.imageWidth,
            image_height: rect.imageHeight
          });
        }
      });
      product.places = newPlaces;
    });
    onShelfUpdated(currentShelfIndex);
    setShelves([ ...shelves ]);
  };

  const deleteAllPlaces = () => {
    const shelf = shelves[currentShelfIndex];

    each(shelf.cart_products, (product) => {
      each(product.places, (place) => {
        if (place.id) {
          place._destroy = '1';
        } else {
          place._removeNow = '1';
        }
      });
      each(
        filter(product.places, (el) => el._removeNow),
        (place) => { remove(product.places, (item) => item === place); }
      );
    });
    setShelves([ ...shelves ]);
  };

  const copyPrevShelf = (shelfIndex) => {
    const prev = shelves[shelfIndex - 1];
    const shelf = shelves[shelfIndex];

    // delete old data
    shelf.cart_products = filter(shelf.cart_products, (product) => {
      if (!product.target && !!product.id) {
        product._destroy = '1';
      }
      if (product.id) {
        product.places = filter(product.places, (place) => {
          if (place.id) {
            place._destroy = '1';
          }
          return !!place.id;
        });
      } else if (product.target) {
        product.places = [];
      }
      return product.target || !product.target && !!product.id;
    });

    //copy from prev
    shelf.shelf_image_data = prev.shelf_image_data;
    shelf.shelf_image_src = prev.shelf_image_src;
    // eslint-disable-next-line lodash/prefer-filter
    forEach(prev.cart_products, (product) => {
      if (!product._destroy) {
        let newProduct;
        if (product.target) {
          newProduct = find(shelf.cart_products, (el) => (el.target));
          newProduct.price = product.price;
        } else {
          newProduct = {
            id: null,
            name: product.name,
            price: product.price,
            image_id: null,
            target: false,
            product_image_data: product.product_image_data,
            product_image_src: product.product_image_src,
            places: []
          };
          shelf.cart_products.push(newProduct);
        }
        forEach(product.places, (place) => {
          newProduct.places.push({ ...place, id: null });
        });
      }
    });

    setShelves([ ...shelves ]);
    onShelfUpdated(shelfIndex);
  };

  const dd = {
    options,
    cartExercise,
    setCartExercise,
    cartWithPrices,
    setCartWithPrices,
    cartWithLimit,
    setCartWithLimit,
    cartLimit,
    setCartLimit,
    cartRanking,
    setCartRanking,
    shelves,
    setShelves,
    currentShelfIndex,
    setCurrentShelfIndex,
    addProduct,
    updateProduct,
    deleteProduct,
    currentProductIndex,
    setCurrentProductIndex,
    getAvailableProducts,
    getProductName,
    getShelfPlaces,
    syncCoords,
    deleteAllPlaces,
    shelfUpdated,
    notDestroyed,
    createDragDropForProduct,
    createDragDropForShelf,
    copyPrevShelf
  };


  return (
    <DataContext.Provider value={ dd }>
      { children }
    </DataContext.Provider>
  );
};

const placeRect = (rect, currentWidth) => {
  const k = currentWidth / rect.iw;
  return { x: rect.x * k, y: rect.y * k, w: rect.w * k, h: rect.h * k };
};

export { WithDataContext, DataContext, placeRect };
