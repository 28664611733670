import React from 'react';
import remoteFiles from '../../../common/remote_files';

const AboutSwitch = () => {
  const { aboutSwitch } = remoteFiles.visibleCom;
  return (
    <section
      className="about-switch"
      style={ { backgroundImage: `url(${aboutSwitch})` } }
    >
      <div className="about-switch_block">
        <h1 className="about-switch_title">
          Ready to switch?
        </h1>
        <div className="about-switch_text">
          We make it easier to walk away from your current carrier.
        </div>
        <div className="about-switch_buttons">
          <div className="about-switch_button">
            <button className="button -full-width">Bring your phone</button>
          </div>
          <div className="about-switch_button">
            <button className="button -full-width -bordered">Buy a new one</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSwitch;
