import React, { useContext, Fragment, useEffect } from "react";
import { StateContext, LineDataContext, SetContext } from "../common/contexts";
import Lines from '../common/lines';
import Checkout from '../common/checkout';
import {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  getHashData,
  getSelectedPlans, getTotal,
  setPlan, sendData, setSetValue
} from "./statistics";
import UnlimitedPage from './unlimited_page';

const scrollTop = () => {
  document.querySelector('html').scrollTop = 0;
};

const states = [
  "Lines",
  "Unlimited",
  "Checkout"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <Fragment>{ path === currentState ? children : null }</Fragment>
  );
};

const Router = ({ plans, allPlansInclude, popupCopy }) => {
  const [ , updatePath ] = useContext(StateContext);
  const { set } = useContext(SetContext);
  const data = {
    plans,
    allPlansInclude,
    popupCopy
  };
  useEffect(() => {
    setSetValue(set);
  }, []);
  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Lines">
        <Lines
          nextStep={
            (event, linesCount) => {
              updatePath("Unlimited");
              setLinesCount(linesCount);
              setCurrentLine(1);
            }
          }
        />
      </Route>

      <Route path="Unlimited">
        <UnlimitedPage
          currentLine={ getCurrentLine() }
          showChangePage={ false }
          onPlanSelect={ (event, planObject, source) => {
            scrollTop();
            setPlan(planObject, getCurrentLine(), source, "Unlimited");
            updatePath("Checkout");
          } }
        />
      </Route>

      <Route path="Checkout">
        <Checkout
          currentLine={ getCurrentLine() }
          selectedLines={ getLinesCount() }
          selectedPlans={ getSelectedPlans() }
          total={ getTotal() }
          nextStep={ (currentLine, isFinal) => {
            if (isFinal) {
              sendData();
              updatePath("");
            } else {
              scrollTop();
              setCurrentLine(currentLine + 1);
              updatePath(getHashData("path"));
            }
          } }
        />
      </Route>

    </LineDataContext.Provider>
  );
};

export { Router, initialState };
