import React from 'react';

const Video = () => {

    return (
      <div className="videos plate">
          <h4 className="plate_title">
              Video
          </h4>
          It will be soon
      </div>
    )
}

export default Video;