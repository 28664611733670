import React from 'react';

const BeforeLoadedProjects = () => {
  return (
    <div className="content -express">
      <div className="content_top -sticky -express -white">
        <header className=".header">
          <div className="header_grid">
            <div className="header_grid-item -grow">
              <h1 className="header_title" >Projects</h1>
            </div>
          </div>
        </header>
      </div>
      <div className="content_body -express -before-loaded">
        <div className="page-loader -inner-loader -transparent -fit-half-height" />
      </div>
    </div>
  );
};

export default BeforeLoadedProjects;
