import { routes } from "./routes";

export const FOR_YOU_COUNTER_KEY = "forYou";
export const EXPLORE_COUNTER_KEY = "explore";
export const SCHEDULE_COUNTER_KEY = "schedule";
export const ACTIVITY_COUNTER_KEY = "activity";
export const BRAND_COUNTER_KEY = "browseByBrand";
export const BRAND_DETAILS_COUNTER_KEY = "brandDetailsClass";
export const BRAND_DETAILS_BACK_COUNTER_KEY = "brandDetailsBack";
export const CLASS_DETAILS_BACK_COUNTER_KEY = "classDetailsBack";
export const START_BUTTON_KEY = "startButton";
export const SKIP_BUTTON_KEY = "skipButton";

const counterKeys = [
  FOR_YOU_COUNTER_KEY,
  EXPLORE_COUNTER_KEY,
  SCHEDULE_COUNTER_KEY,
  ACTIVITY_COUNTER_KEY,
  BRAND_COUNTER_KEY,
  BRAND_DETAILS_COUNTER_KEY,
  BRAND_DETAILS_BACK_COUNTER_KEY,
  CLASS_DETAILS_BACK_COUNTER_KEY,
  START_BUTTON_KEY,
  SKIP_BUTTON_KEY,
];

const finishData = {
  nav: {
    forYou: 0, // <общее количество нажатий на навигационную кнопку>,
    explore: 0, // <общее количество нажатий на навигационную кнопку>,
    schedule: 0, // <общее количество нажатий на навигационную кнопку>,
    activity: 0, // <общее количество нажатий на навигационную кнопку>
  },
  browseByBrand: 0, //  <количество нажатий на Equinox>,
  brandDetails: {
    base: 0, // <количество нажатий на pump is real: Bigger Legs из Brand Details>,
    back: 0, // <количество нажатий назад из Brand Details>,
    nav: {
      forYou: 0, // <количество нажатий на For You в навигации  из Brand Details>,
      schedule: 0, // <количество нажатий на Schedule в навигации  из Brand Details>,
      activity: 0, // <количество нажатий на Activity в навигации  из Brand Details>
    },
  },
  klassDetailsBack: 0, // <количество нажатий назад из Class Details>,
  skipped: null, // null - нажал на финальную кнопку на Class Details экране, “For You”, “Explore”, “Brand Details”, “Schedule”, “Activity” - на какой странице нажал на кнопку выхода>
};

const highlightTimeouts = {};

const highlightActionElements = () => {
  counterKeys.forEach((key) => {
    const element = document.querySelector(`[data-counter-key=${key}]`);
    if (element) {
      clearTimeout(highlightTimeouts[key]);
      element.classList.add("-highlight");
      highlightTimeouts[key] = setTimeout(() => {
        element.classList.remove("-highlight");
      }, 500);
    }
  });
};

const isBrandDetailsPage = () => {
  return window.location.pathname.includes("/brand");
};

const getCurrentPage = () => {
  const path = window.location.pathname;
  const route = routes.find((route) => route.path === path);
  return route ? route.title : null;
};

const sendResult = (result) => {
  (window.parent || window).postMessage({ kind: "finishSurvey", result }, "*");
};

window.addEventListener("mousedown", (event) => {
  const sourceElement = event.target.closest("[data-counter-key]");

  if (!sourceElement) {
    highlightActionElements();
    return;
  }

  const sourceId = sourceElement.getAttribute("data-counter-key");

  switch (sourceId) {
    case START_BUTTON_KEY:
      sendResult({ ...finishData, skipped: null });
      break;

    case SKIP_BUTTON_KEY:
      const page = getCurrentPage();
      sendResult({ ...finishData, skipped: page });
      break;

    case FOR_YOU_COUNTER_KEY:
      finishData.nav.forYou++;
      if (isBrandDetailsPage()) {
        finishData.brandDetails.nav.forYou++;
      }
      break;

    case EXPLORE_COUNTER_KEY:
      finishData.nav.explore++;
      break;

    case SCHEDULE_COUNTER_KEY:
      finishData.nav.schedule++;
      if (isBrandDetailsPage()) {
        finishData.brandDetails.nav.schedule++;
      }
      break;

    case ACTIVITY_COUNTER_KEY:
      finishData.nav.activity++;
      if (isBrandDetailsPage()) {
        finishData.brandDetails.nav.activity++;
      }
      break;

    case BRAND_COUNTER_KEY:
      finishData.browseByBrand++;
      break;

    case BRAND_DETAILS_COUNTER_KEY:
      finishData.brandDetails.base++;
      break;

    case BRAND_DETAILS_BACK_COUNTER_KEY:
      finishData.brandDetails.back++;
      break;

    case CLASS_DETAILS_BACK_COUNTER_KEY:
      finishData.klassDetailsBack++;
      break;
  }
});
