import Http from "../../common/http";

const loadOverview = (mediaKind, verizonType, callback) => {
  Http.get(
    `/verizon/overview.json?media_kind=${mediaKind}&verizon_type=${verizonType}`
  ).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const handleLogout = () => {
  Http.del(
    '/users/sign_out'
  ).then(
    (response) => {
      window.location = '/';
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { handleLogout, loadOverview };
