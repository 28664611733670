import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from "lodash";

const TextInput = ({ attrName, currentValue, onUpdate }) => {
  const [ text, setText ] = useState(currentValue || '');

  useEffect(() => {
    setText(currentValue);
  }, [ currentValue ]);

  const onChange = (value) => {
    const params = {};
    params[attrName] = value;
    onUpdate(params);
  };

  const defferedUpdate = useCallback(debounce((value) => {
    onChange(value);
  }, 500), []);

  const onTextChange = (event) => {
    setText(event.target.value);
    defferedUpdate(event.target.value);
  };

  return (
    <input
      className="form-field -block"
      type="text"
      placeholder="Type something"
      value={ text }
      onChange={ onTextChange }
    />
  );
};

export default TextInput;
