import React from "react";
import { map } from "lodash";
import { determineValue, defaultNA } from "../helpers";

const Issues = ({ has8, has9, currentData }) => {
  const issuesTotal = (item) => (item.data.issues.total);

  let issuesNames;
  if (has9) {
    issuesNames = () => map(has9.data.issues.answers, (answer) => answer.label);
  } else if (has8) {
    issuesNames = () => map(has8.data.issues.answers, (answer) => answer.label);
  } else {
    issuesNames = () => map(currentData[0].data.issues.answers, (answer) => answer.label);
  }

  const determineByValueByIndex = (value, index) => {
    if ((has9 && value.data.issues.answers.length === 8 && index !== 1) ||
      ((!has9 && has8 && value.data.issues.answers.length !== 8 && index !== 1))) {
      return valueByCorrectedIndex(value, index, 1);
    } else if (has9 && value.data.issues.answers.length === 7 && index !== 1 && index !== 2) {
      return valueByCorrectedIndex(value, index, 2);
    } else if ((!has9 && has8 && value.data.issues.answers.length === 8) || (has9 && value.data.issues.answers.length === 9) ||
      (!has9 && !has8 && value.data.issues.answers.length !== 9 && value.data.issues.answers.length !== 8)) {
      return determineValue(value.data.issues.answers[index], issuesTotal(value));
    }
    return defaultNA;
  };

  const valueByCorrectedIndex = (value, index, step) => {
    const correctedIndex = index === 0 ? 0 : index - step;
    return determineValue(value.data.issues.answers[correctedIndex], issuesTotal(value));
  };

  return (
    <tbody className="table-pulses_section -issues">
      <tr className="table-pulses_head">
        <td className="table-pulses_col -first -head">
          <div className="table-pulses_head-inner">
            <h6 className="table-pulses_title">Issues Experienced</h6>
          </div>
        </td>
        {
          map(currentData, (value) => (
            <td className="table-pulses_col -head" key={ `line1-name-${value.id}` }>
              <div className="table-pulses_head-inner -number" >(n={ issuesTotal(value) })</div>
            </td>
          ))
        }
      </tr>

      {
        map(issuesNames(), (value, index) => (
          <tr className="table-pulses_tr" key={ `line2-issue-${index}` }>
            <td className="table-pulses_col -first">
              { value }
            </td>
            {
              currentData.map((value) => (
                <td className="table-pulses_col -center" key={ `line2-issue-${index}-value-${value.id}` }>
                  <div className="table-pulses_text -md">
                    { determineByValueByIndex(value, index) }
                  </div>
                </td>
              ))
            }
          </tr>
        ))
      }
    </tbody>
  );
};

export default Issues;
