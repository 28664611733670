const selected_1 = [
  {
    count: 1,
    price: 0,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 20,
        connect: 4,
        tvConnections: 3,
      }
    ]
  }
];

const selected_2 = [
  {
    count: 1,
    price: 0,
    showOptions: true,
    options: [
      {
        count: 1,
        price: 12,
        priceToShow: null,
        tvConnections: 2,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 8,
        connect: 4,
        tvConnections: 3,        
      }
    ]
  },
  {
    count: 2,
    price: 12,
    showOptions: false,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 20,
        connect: 4,
        tvConnections: 4,
      }
    ]
  },
];

const selected_3 = [
  {
    count: 1,
    price: 0,
    showOptions: true,
    options: [
      {
        count: 2,
        price: 24,
        priceToShow: null,
        tvConnections: 3,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: -4,
        connect: 4,
        tvConnections: 3,
      }
    ]
  },
  {
    count: 2,
    price: 12,
    showOptions: true,
    options: [
      {
        count: 1,
        price: 12,
        priceToShow: null,
        tvConnections: 3,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 8,
        connect: 4,
        tvConnections: 4,
      }
    ]
  },
  {
    count: 3,
    price: 24,
    showOptions: false,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 20,
        connect: 4,
        tvConnections: 5,
      }
    ]
  },
];

const selected_4 = [
  {
    count: 1,
    price: 0,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: -10,
        connect: 4,
        tvConnections: 3,
      }
    ]
  },
  {
    count: 2,
    price: 12,
    showOptions: true,
    options: [
      {
        count: 2,
        price: 18,
        priceToShow: null,
        tvConnections: 4,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 2,
        connect: 4,
        tvConnections: 4,
      }
    ]
  },
  {
    count: 3,
    price: 24,
    showOptions: true,
    options: [
      {
        count: 1,
        price: 6,
        priceToShow: null,
        connect: 4,
        tvConnections: 4,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 14,
        connect: 4,
        tvConnections: 5,
      }
    ]
  },
  {
    count: 4,
    price: 30,
    showOptions: false,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 20,
        connect: 4,
        tvConnections: 6,
      }
    ]
  },
];

const selected_5 = [
  {
    count: 1,
    price: 0,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: -16,
        connect: 4,
        tvConnections: 3,
      }
    ]
  },
  {
    count: 2,
    price: 12,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: -4,
        connect: 4,
        tvConnections: 4,
      }
    ]
  },
  {
    count: 3,
    price: 24,
    showOptions: true,
    options: [
      {
        count: 2,
        price: 12,
        priceToShow: null,
        connect: 4,
        tvConnections: 5,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 8,
        connect: 4,
        tvConnections: 5,
      }
    ]
  },
  {
    count: 4,
    price: 30,
    showOptions: true,
    options: [
      {
        count: 1,
        price: 6,
        priceToShow: null,
        tvConnections: 5,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 14,
        connect: 4,
        tvConnections: 6,
      }
    ]
  },
  {
    count: 5,
    price: 36,
    showOptions: false,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 20,
        connect: 4,
        tvConnections: 7,
      }
    ]
  },
];

const selected_6 = [
  {
    count: 1,
    price: 0,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: -22,
        connect: 4,
        tvConnections: 3,
      }
    ]
  },
  {
    count: 2,
    price: 12,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: -10,
        connect: 4,
        tvConnections: 4,
      }
    ]
  },
  {
    count: 3,
    price: 24,
    showOptions: true,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 2,
        connect: 4,
        tvConnections: 5,
      }
    ]
  },
  {
    count: 4,
    price: 30,
    showOptions: true,
    options: [
      {
        count: 2,
        price: 12,
        priceToShow: null,
        tvConnections: 6,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 8,
        connect: 4,
        tvConnections: 6,
      }
    ]
  },
  {
    count: 5,
    price: 36,
    showOptions: true,
    options: [
      {
        count: 1,
        price: 6,
        priceToShow: null,
        tvConnections: 6,
      },
      {
        count: Infinity,
        price: 20,
        priceToShow: 14,
        connect: 4,
        tvConnections: 7,
      }
    ]
  },
  {
    count: 6,
    price: 42,
    showOptions: false,
    options: [
      {
        count: Infinity,
        price: 20,
        priceToShow: 20,
        connect: 4,
        tvConnections: 8,
      }
    ]
  },
];

const invariants = [
  selected_1,
  selected_2,
  selected_3,
  selected_4,
  selected_5,
  selected_6,
];

export const getInvariantByDeviceCount = count => invariants[count-1] || [];