import React, { useContext, useState } from 'react';
import { compact } from 'lodash';
import { StateContext, LineDataContext } from '../common/contexts';
import {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  getSelectedPlans, getTotal,
  setPlan, sendData, setHashData,
  getSelectedPlanIdByLine, getSelectedPerksIdByLine,
  updateLinePrices
} from '../common/statistics';
import Page from '../common/page';
import Lines from '../common/lines';
import Overview from '../common/overview';
import { AllPlansInclude } from '../common/allPlansInclude';
import { priceForLine } from './data';

const states = [
  "Overview"
];
const initialState = states[0];

setLinesCount(1);

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = ({ data }) => {
  const [ , updatePath ] = useContext(StateContext);

  const [ updatedSelectedPlans, setUpdatedSelectedPlans ] = useState(getSelectedPlans());

  const handleChangeLineCount = (linesCount) => {
    setLinesCount(linesCount);
    updateLinePrices(priceForLine);
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  const handlePerksSelect = (perksArray, plan) => {
    setHashData(`lines.${getCurrentLine()}.planSelectedPerks`, compact(perksArray));
    setHashData(`lines.${getCurrentLine()}.planPerksPrice`, plan ? plan.perksPrice : []);
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  const handlePlanSelect = (event, planObject, perksArray) => {
    const { getPlanPriceByLine } = data;
    setPlan(planObject, getCurrentLine(), getPlanPriceByLine(planObject));
    handlePerksSelect(perksArray, planObject);
  };

  const handleLineSelect = (clickedLine) => {
    setCurrentLine(clickedLine);
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Overview">
        <Page>

          <div className="layout">
            <div className="layout_sidebar">

              <Lines
                linesCount={ getLinesCount() }
                selectedPlans={ updatedSelectedPlans }
                total={ getTotal() }
                currentLine={ getCurrentLine() }
                changeLineCount={ handleChangeLineCount }
                selectLine={ handleLineSelect }
                finishStep={ () => {
                  sendData();
                  updatePath("");
                } }
              />

            </div>
            <div className="layout_content" id="content">
              <Overview
                currentLine={ getCurrentLine() }
                selectedPlanIdByLine={ getSelectedPlanIdByLine(getCurrentLine()) }
                selectedPerksIdByLine={ getSelectedPerksIdByLine(getCurrentLine()) }
                onPlanSelect={ handlePlanSelect }
                onPerkSelectOuter={ (perksArray, plan) => {
                  handlePerksSelect(perksArray, plan);
                } }
                customHeader={
                  <div className="overview_section-header">
                    <h3 className="overview_section-title -with-note">
                      Choose your network
                    </h3>
                    <div className="choose-note">
                      { data.plansUpNote }
                    </div>
                    <div className="overview_section-description">
                      <AllPlansInclude customTitle={ "All networks include:" } />
                    </div>
                  </div>
                }
                keepPerksWhenPlanReselect
              />
            </div>
          </div>


        </Page>
      </Route>


    </LineDataContext.Provider>
  );
};

export { Router, initialState };
