import React from 'react';
import { map } from 'lodash';
import cn from "classnames";
import { runExport } from '../../../../../express/common/sidebar/requests';
import {
  classByReportKind,
  reportKindsArray,
  reportKindTitle,
  kindByIndex
} from '../../../../../express/researcher/common/headerLinksHelper';
import { userDownloadCCPlatform } from '../../../../common/helpers';
import DropdownButton from './dropdown-button';

const HeaderLinks = ({ reports, filter, sort, loading }) => {
  const startExport = (event, name, kind, reportTitle, formatTitle) => {
    runExport(
      event,
      '/express/api/projects/database_report',
      {
        name,
        report_format: kind,
        filter,
        sort,
        report_title: reportTitle,
        format_title: formatTitle,
        platform: userDownloadCCPlatform
      }
    );
  };

  return (
    <DropdownButton
      listClassName="-to-right-bottom"
      buttonName="Export"
      loading={ loading }
    >
      <>
        {
          map(reports, (item, index) => (
            <li key={ index }>
              <span>{ item.title }</span>
              <span>
                {
                  map(reportKindsArray(item), (kind, kindIndex) => (
                    <a
                      key={ `${index}${kindIndex}` }
                      className={ cn("-export", classByReportKind(item, kindIndex)) }
                      onClick={
                        (event) => {
                          startExport(
                            event,
                            item.name,
                            kindByIndex(item, kindIndex),
                            item.title,
                            reportKindTitle(item, kindIndex)
                          );
                        }
                      }
                    >
                      { reportKindTitle(item, kindIndex) }
                    </a>
                  ))
                }
              </span>
            </li>
          ))
        }
      </>
    </DropdownButton>
  );
};

export default HeaderLinks;
