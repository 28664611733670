import React, { useContext, useState, useLayoutEffect, useRef } from "react";
import { map, compact } from "lodash";
import { PlanDataProvider } from "../common/contexts";
import renderRawHtml from '../common/render_raw_html';
import { Accordion } from "../common/accordion";
import { prepareText, scrollInModal } from '../common/helper';
import { increaseVariableForCurrentLine } from '../common/statistics';

const ComparePlanHead = ({
  plan, onPlanSelect
}) => {
  const handlePlanSelect = () => {
    increaseVariableForCurrentLine(`coreCompare${plan.statisticsName}Click`);
    onPlanSelect(plan);
  };
  return (
    <div className="compare_table-cell -head">
      <div className="compare_head">
        <h2 className="compare_head-title" { ...renderRawHtml(plan.name) } />
        <div className="compare_head-price">
          ${ plan.price }/mo*
        </div>
        <div className="compare_head-button">
          <button
            className="button -bordered -inherit -sm"
            onClick={ handlePlanSelect }
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
};

const ComparePlanRow = ({ selectedRowRef, row, plans, isOpen, btnOnClick }) => {
  const descriptionItems = isOpen ?
    compact(
      map(plans, (planItem) => {
        const plan = planItem.core;
        const value = plan?.compare[row.id] || null;
        return (value?.titleForCompare || value?.title) ? { ...value, ...{ planId: plan.id } } : null;
      })
    )
    : [];
  return (
    // it's need to divide row to 2 rows to show values and descriptions
    <div className="compare_table-row-wrapper">
      <div className="compare_table-row -top" key={ `comparePlanRow-${row.id}-line1` } ref={ isOpen ? selectedRowRef : null }>
        <div className={ `compare_table-cell -name` } >
          {row.title}
        </div>
        {
          map(plans, (planItem) => {
            const plan = planItem.core;
            const value = plan?.compare[row.id] || null;
            return (
              <div className={ `compare_table-cell` } key={ `comparePlanItemTop-${plan.id}` } >
                {value?.titleForCompare || value?.title || "—"}
              </div>
            );
          })
        }
        <div className={ `compare_table-cell -control -full-row` } >
          <div className={ `compare_feature-control` } onClick={ btnOnClick }>
            <span className={ `compare_feature-caret ${isOpen ? "-open" : ""}` }>
              <svg className="icon -caret">
                <use xlinkHref="#svg_caret" />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className="compare_table-row -bottom" key={ `comparePlanRow-${row.id}-line2` }>
        {
          isOpen && (
            <div className="compare_table-sections">
              {
                map(descriptionItems, (value) => (
                  <div className="compare_table-section" key={ `comparePlanItemBottom-${value.planId}` } >
                    <div className="compare_feature-info">
                      {
                        descriptionItems.length > 1 &&
                        <h5 className="compare_feature-title" { ...renderRawHtml(value?.titleForCompare || value?.title) } />
                      }
                      <div className="compare_feature-description">
                        { prepareText({ texts: value?.description, type: value?.type }) }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export const Compare = ({ plans, onSelect, modalRef }) => {
  const { compareList, allPlansInclude } = useContext(PlanDataProvider);
  const [ currentIdx, setCurrentIdx ] = useState(-1);
  const handleShowDescription = (idx) => {
    setCurrentIdx(currentIdx !== idx ? idx : -1);
  };
  const selectedRowRef = useRef(null);
  const headerRef = useRef(null);

  useLayoutEffect(() => {
    const baseEl = modalRef?.current;
    const headerEl = headerRef?.current;
    const selectedRowEl = selectedRowRef?.current;
    if (currentIdx > -1 && baseEl && headerEl && selectedRowEl) {
      scrollInModal(baseEl, headerEl, selectedRowEl);
    }
  }, [ currentIdx, modalRef?.current, headerRef?.current, selectedRowRef?.current ]);

  return (
    <div className="compare">
      <div className="compare_table">
        <div className="compare_table-row -head -sticky" ref={ headerRef }>
          <div className="compare_table-cell -name" />
          {
            map(plans, (plan) => (
              <ComparePlanHead
                key={ `comparePlanItem${plan.id}` }
                plan={ plan }
                onPlanSelect={ onSelect }
              />
            ))
          }
        </div>
        {
          map(compareList, (compareRow, index) => (
            <ComparePlanRow
              selectedRowRef={ selectedRowRef }
              row={ compareRow } key={ index } plans={ plans }
              isOpen={ index === currentIdx }
              btnOnClick={ () => handleShowDescription(index) }
            />
          ))
        }
      </div>
      <div className="compare_all-plans">
        <h3 className="compare_all-plans-title">
          All plans include
        </h3>
        <Accordion list={ allPlansInclude } className="-btn-full" />
      </div>
      <div className="compare_footnote">
        * Plus taxes & fees. With Auto Pay and paper-free billing. $10/mo more without Auto Pay.
      </div>
    </div>
  );
};
