import React from "react";
import Button from "./Button";

const InstructorProfile = ({
  imgUrl,
  firstName,
  lastName,
  company,
  bio,
  bordered,
}) => (
  <div className="instructor-profile">
    <div className={`instructor-profile_avatar ${bordered ? "-bordered" : ""}`}>
      <img className="instructor-profile_image" src={imgUrl} />
      <div className="instructor-profile_button">
        <Button size="small">Following</Button>
      </div>
    </div>
    <h3 className="instructor-profile_name">
      {firstName} {lastName}
    </h3>
    {company && <div className="instructor-profile_company">{company}</div>}
    {bio && <div className="instructor-profile_bio">{bio}</div>}
  </div>
);

export default InstructorProfile;
