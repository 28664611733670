import { each } from 'lodash';
import Http from "../../../common/http";

const loadReports = (mediaKind, verizonType, mediaKinds, callback) => {
  const params = [
    `media_kind=${mediaKind}`,
    `verizon_type=${verizonType}`,
    'project[sort_direction]=asc'
  ];
  each(mediaKinds || [], (item) => {
    params.push(`project[media_kinds][]=${item}`);
  });
  Http.get(`/verizon/projects.json?${params.join('&')}`).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const loadFiles = (mediaKind, verizonType, with_hidden, target, mediaKinds, callback) => {
  const params = [
    `media_kind=${mediaKind}`,
    `verizon_type=${verizonType}`
  ];
  each(mediaKinds || [], (item) => {
    params.push(`media_kinds[]=${item}`);
  });
  if (with_hidden) {
    params.push("with_hidden=true");
  }
  if (target) {
    params.push(`target=${target}`);
  }
  Http.get(
    `/verizon/files.json?${params.join("&")}`
  ).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const loadAdminFiles = (mediaKind, verizonType, mediaKinds, callback) => {
  loadFiles(mediaKind, verizonType, true, null, mediaKinds, callback);
};

const loadBasicDocuments = (mediaKind, verizonType, mediaKinds, callback) => {
  loadFiles(mediaKind, verizonType, false, "documents", mediaKinds, callback);
};

const loadArchivedDocuments = (mediaKind, verizonType, mediaKinds, callback) => {
  loadFiles(mediaKind, verizonType, false, "archive", mediaKinds, callback);
};

const loadAdditionalCurrentMonthDocuments = (mediaKind, verizonType, mediaKinds, callback) => {
  loadFiles(mediaKind, verizonType, false, "current", mediaKinds, callback);
};

const fileDefaultTarget = "documents";

const createFile = (mediaKind, item, mediaKinds, callback) => {
  const { title, metaData, hidden, filename, target, verizonType, archived } = item;
  const params = {
    media_kind: mediaKind,
    instance: {
      title,
      attachment_data: metaData,
      hidden,
      filename,
      target,
      archived,
      verizon_type: verizonType
    }
  };
  if (mediaKinds) {
    params.media_kinds = mediaKinds;
  }

  Http.post("/verizon/files.json", params).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const updateFile = (mediaKind, item, mediaKinds, callback) => {
  const { id, title, metaData, hidden, filename, target, archived } = item;
  const params = {
    media_kind: mediaKind,
    instance: {
      title,
      attachment_data: metaData,
      hidden,
      filename,
      target,
      archived
    }
  };
  if (mediaKinds) {
    params.media_kinds = mediaKinds;
  }

  Http.put(`/verizon/files/${id}.json`, params).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const destroyFile = (mediaKind, verizonType, id, mediaKinds, callback) => {
  const params = { media_kind: mediaKind, verizon_type: verizonType };
  if (mediaKinds) {
    params.media_kinds = mediaKinds;
  }
  Http.del(`/verizon/files/${id}.json`, params).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const updateFileName = (mediaKind, verizonType, kind, id, newFileName, mediaKinds, callback, badCallback) => {
  let url;
  const params = { media_kind: mediaKind, verizon_type: verizonType };
  if (mediaKinds) {
    params.media_kinds = mediaKinds;
  }
  if (kind === 'project') {
    url = `/verizon/projects/${id}.json`;
    params.project = { ppt_report_filename: newFileName };
  } else {
    url = `/verizon/files/${id}.json`;
    params.instance = { title: newFileName };
  }
  Http.put(url, params).then(
    (response) => {
      if (response.response.ok) {
        callback && callback(response);
      } else {
        badCallback && badCallback(response);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback(reject);
    }
  );
};

export {
  loadReports, loadAdminFiles, loadBasicDocuments,
  loadArchivedDocuments, loadAdditionalCurrentMonthDocuments,
  createFile, updateFile, destroyFile, fileDefaultTarget,
  updateFileName
};
