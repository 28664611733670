import React, { useState, useEffect, useRef } from 'react';
import { StateContext, PlanDataProvider } from '../common/contexts';
import { Router, initialState } from './router';
import { CONCEPT_C } from './plans_data';

const {
  plans_prebuild,
  plans_construct,
  concept_c_perk_groups,
  allPlansIncludeConcept,
  welcomePlans,
  cores,
  PERK_PRICE,
  getForkList,
  allPerksFlatten
} = CONCEPT_C;

const PlansBuilderC = () => {
  const timerRef = useRef(null);
  const [ path, setPath ] = useState(initialState);
  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = `${main.className} -c`;
  }, []);
  const updatePath = (value) => {
    if (path === value) {
      setPath("Reset");
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setPath(value);
        timerRef.current = null;
      }, 0);
    } else {
      clearTimeout(timerRef.current);
      setPath(value);
    }
  };
  return (
    <PlanDataProvider.Provider
      value={ {
        plans_prebuild,
        plans_construct,
        perks: concept_c_perk_groups,
        allPlansInclude: allPlansIncludeConcept,
        cores,
        PERK_PRICE,
        getForkList,
        allPerksFlatten
      } }
    >
      <StateContext.Provider value={ [ path, updatePath ] }>
        <Router welcomePlans={ welcomePlans } />
      </StateContext.Provider>
    </PlanDataProvider.Provider>
  );
};

export default PlansBuilderC;
