import React, { useState, useEffect } from 'react';
import { filter, map } from "lodash";
import Notification from "../../common/notification";
import { initConditionList, filteredQuestionsForFields } from '../../common/custom_questions/helper';
import ScreeningQuestion from '../../common/custom_questions/components/ScreeningQuestion';
import { inOneOfAllowedMessages } from '../../common/navigation';
import Header from "../../screener_question/components/Header";
import HiddenFields from '../../screener_question/components/HiddenFields';
import FormHeader from "../../screener_question/components/FormHeader";
import Intro from "../../screener_question/components/Intro";
import { PlatformContext } from '../../common/custom_questions/contexts.js';


const valueContextBase = {
  platformName: 'verizon'
};

const App = ({ data, options, error, grid_data }) => {
  const initScreenerQuestions = () => (
    map(data.screener_questions, (q) => initConditionList(q, grid_data[q.id]))
  );

  const [ notificationObject, setNotificationObject ] = useState(null);
  const [ screenerQuestions, setScreenerQuestions ] = useState(initScreenerQuestions);

  const numberLimit = options.number_limit;
  const hasNumberLimit = !!numberLimit;
  const filteredList = filter(screenerQuestions, (q) => !q._destroy);
  const emptyListQuestions = !filteredList.length;
  const showAddQuestion = !emptyListQuestions &&
    (!hasNumberLimit || (hasNumberLimit && filteredList.length < numberLimit));

  const addQuestion = (event) => {
    event.preventDefault();

    if (hasNumberLimit && filteredList.length >= numberLimit) {
      setNotificationObject({
        message: `You can add only ${numberLimit} questions at most`,
        success: false
      });
      return;
    }
    setScreenerQuestions(
      [
        ...screenerQuestions,
        {
          text: "",
          answers: [ "" ],
          kind: "",
          condition: {},
          condition_list: [],
          answers_order: "specific",
          multi_condition: 1,
          quotas: {},
          manage_quota: false,
          scales: [ "" ]
        }
      ]
    );
  };

  const submitForm = (ev) => {
    if (
      !ev.data ||
      typeof (ev.data) !== "object" ||
      !inOneOfAllowedMessages(ev.data)
    ) {
      return;
    }
    window.document.getElementById("question_form").submit();
  };

  useEffect(() => {
    window.addEventListener("message", submitForm);
    return () => {
      window.removeEventListener('message', submitForm);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setNotificationObject({ message: error, success: false });
    }
  }, []);

  return (
    <>
      <HiddenFields questions={ filteredQuestionsForFields(screenerQuestions) } options={ options } />
      <div className="content -relative -grey-background">
        <PlatformContext.Provider value={ valueContextBase }>
          <Header
            title={ options.project_title }
            emptyListQuestions={ emptyListQuestions }
            showAddQuestion={ showAddQuestion }
            addQuestion={ addQuestion }
          />
          <div className="content_body">
            <div className="form -max-screener-and-custom-metrics-width">
              {
                emptyListQuestions &&
                <Intro
                  addQuestion={ addQuestion }
                  hasNumberLimit={ hasNumberLimit }
                  numberLimit={ numberLimit }
                />
              }
              {
                !emptyListQuestions &&
                <>
                  <FormHeader
                    hasNumberLimit={ hasNumberLimit }
                    numberLimit={ numberLimit }
                  />
                  <div className="form-group">
                    {
                      map(screenerQuestions, (question, index) => (
                        <ScreeningQuestion
                          key={ `Question${index}` }
                          options={ options }
                          question={ question }
                          screenerQuestions={ screenerQuestions }
                          setScreenerQuestions={ setScreenerQuestions }
                          questionIndex={ index }
                          gridData={ grid_data }
                          needQuota
                          needLogicEdit
                          needOther
                          needNone
                          needRows
                          deleteButtonModifier='-va-delete'
                        />
                      ))
                    }
                  </div>
                </>
              }
            </div>
          </div>
        </PlatformContext.Provider>
      </div>

      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
    </>
  );
};

export default App;
