import React from 'react';
import { map, keys } from "lodash";
import SvgIcon from '../../../../../common/components/svg-icon';

const CaseComparisonManager = ({
  selectedForComparison, toggleSelectedForComparison, closePopup
}) => {
  const removeConcept = (event, projectId) => {
    if ((numberInComparison) === 1) {
      closePopup(event);
    }
    toggleSelectedForComparison({ id: projectId });
  };
  const numberInComparison = keys(selectedForComparison).length;
  const handleClosePopup = (event) => {
    closePopup(event);
  };
  return (
    <div className="case-comparison">
      <div className="case-comparison_backdrop" onClick={ handleClosePopup } />
      <div className="case-comparison_plate">
        <div className="case-comparison_close" onClick={ handleClosePopup }>
          <SvgIcon name="i-close-sm" className="-pointer" />
        </div>
        <div className="case-comparison_plate-top">
          <h2>Case Comparison</h2>
          <div className="case-comparison_notes">
            {numberInComparison} item{ numberInComparison > 1 ? 's' : '' } selected for comparison.
          </div>
        </div>
        <div className="case-comparison_plate-body">
          <ul className="case-comparison_list">
            {
              map(selectedForComparison, (projectData, projectId) => (
                <li key={ projectId }>
                  <div className="case-comparison_grid">
                    <div className="case-comparison_grid-item -flex">{ projectData.caseName }</div>
                    <div className="case-comparison_grid-item -last">
                      <div
                        className="case-comparison_button-delete"
                        onClick={ (event) => { removeConcept(event, parseInt(projectId)); } }
                      >
                        <SvgIcon name="i-close-sm" />
                      </div>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CaseComparisonManager;
