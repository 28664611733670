import {
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible
} from '../../common/logic/eligible.js';
import BaseForward from '../../common/logic/base_forward';
import { respondentQualified } from "../../../common/google-analytics/events";

class Forward extends BaseForward {
  constructor(options) {
    super(options);
  }

  forward(step, data, result, customs, projectId, antiFraud, gaTrackingId) {
    let newStep = step + 1, needForward = false;

    switch (newStep) {
      case 1: //demographics
        if (!data.demographics.length) {
          needForward = true;
        }
        break;
      case 2: //optional demographics
        if (demographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.optional_demographics.length) {
          needForward = true;
        }
        break;
      case 3: //screening
        if (optionalDemographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.screenings.length) {
          needForward = true;
        }
        break;
      case 4: //brand_screenings
        if (!data.brand_screenings.length) {
          needForward = true;
        } else if (screeningsNotEligible(data, result.answers, gaTrackingId)) {
          newStep = this.screenedActionNum;
          needForward = true;
        }
        break;
      case 5: // Qualified
        if (screeningsNotEligible(data, result.answers, gaTrackingId, 'brand_screenings')) {
          newStep = this.brandScreenedActionNum;
          needForward = true;
        }
        break;
      case 6: // category_questions
        respondentQualified(gaTrackingId, { project_id: projectId, respondent_id: data.respondent_id });
        if (!data.category_questions.length) {
          needForward = true;
        }
        break;
      case 7: // cqAnyAwareness
        if (!customs.cqAnyAwareness.length) {
          needForward = true;
        }
        break;
      case 8: //brand_aware == true
        if (!result.brand_aware) {
          newStep = 40;
          needForward = true;
        }
        break;
      case 9: //brand_aware_questions
        if (!data.brand_aware_questions.length) {
          needForward = true;
        }
        break;
      case 10: // cqAwareAll
        if (!customs.cqAwareAll.length) {
          needForward = true;
        }
        break;
      case 11:
        if (result.brand_user) {
          newStep = 20;
          needForward = true;
        } else {
          newStep = 30;
          needForward = true;
        }
        break;
      case 21: //brand_user_questions
        if (!data.brand_user_questions.length) {
          needForward = true;
        }
        break;
      case 22: //cqAwareUser
        if (!customs.cqAwareUser.length) {
          needForward = true;
        }
        break;
      case 31: //brand_no_user_questions
        if (!data.brand_no_user_questions.length) {
          needForward = true;
        }
        break;
      case 32: //brand_no_user_questions
        if (!customs.cqAwareNonUser.length) {
          needForward = true;
        }
        break;
      case 41:
        if (!customs.cqNotAware.length) {
          needForward = true;
        }
        break;
      case 23: // After user questions
      case 33: // After no user question
      case 42: // After all
        if (!customs.cqAtTheEnd.length) {
          needForward = true;
        }
        newStep = 51;
        break;
      case 51:
        if (!customs.cqAtTheEnd.length) {
          needForward = true;
        }
        break;
    }

    [ newStep, needForward ] = this.checkLastSteps(
      this, newStep, needForward, 52, data, result, projectId, antiFraud
    );
    if (!newStep) {
      return;
    }

    if (needForward) {
      this.forward(newStep, data, result, customs, projectId, antiFraud);
    } else {
      this.updateStep(newStep);
    }
  }
}
export default Forward;
