import React, { useContext } from 'react';
import { InnerAccordion, accordionTitleFactory } from '../common/accordion';
import CompatibleTip from '../common/compatible-tip';
import HowToConnectPopup from '../common/how-to-connect-popup';
import { useSplitedInvariants, DataStorageContext, invariantsFilters } from './hooks';
import { updateCache, getCache } from './cache_store';

const InvariantBox = ({ selected, recomended, onClick, children }) => {
  const className = [
    'how-to-connect-box-invariant',
    '-invariant-b',
    recomended ? '-recomended' : '',
    selected ? '-active': ''
  ].join(' ').trim();

  return <div className={className} onClick={onClick}>{children}</div>
};

const InvariantTitle = ({ children }) =>
  <p className="how-to-connect-box-invariant-title -invariant-b">{children}</p>;

const InvariantPrice = ({ price }) =>
  <p className="how-to-connect-box-invariant-price">
    {price > 0 ? `$${price}` : price === 0 ? 'Included' : `-$${Math.abs(price)}`}
  </p>;

const Invariant = ({ invariant, onDetailsClick }) => {
  const { STBCount, ownDevicesCount, tvConnections, price } = invariant;
  return <>
    <div className="how-to-connect-box-invariant-title-wrapper">
      <InvariantTitle>
        {`${STBCount} set-top ${STBCount === 1 ? 'box' : 'boxes'}`}
      </InvariantTitle>
      {ownDevicesCount > 0 && <InvariantTitle>
        {
          ownDevicesCount === Infinity 
            ? 'Unlimited Fios TV Home App connections'
            : `${ownDevicesCount} Fios TV Home App ${ownDevicesCount === 1 ? 'connection' : 'connections'}`

        }
      </InvariantTitle>}
    </div>
    <InvariantPrice price={price} />
    <ul className="how-to-connect-option-list">
      {ownDevicesCount === 0 &&
        <li className="how-to-connect-option-list-item">
          Access on {STBCount} STB(s)
        </li>
      }
      {ownDevicesCount > 0 &&
        <li className="how-to-connect-option-list-item">
          Access on {STBCount} STB(s) and certain Apple or Fire TV devices
        </li>
      }
      {ownDevicesCount > 0 && ownDevicesCount !== Infinity &&
        <li className="how-to-connect-option-list-item">
          Log in and watch on {ownDevicesCount} of your Connected TV device(s)
        </li>
      }
      {ownDevicesCount === Infinity &&
        <li className="how-to-connect-option-list-item">
          Log in and watch on ALL of your Connected TV Devices (Up to 2 streams at one time)
        </li>
      }
      {ownDevicesCount > 0 &&
        <li className="how-to-connect-option-list-item">
          Watch on up to {tvConnections} TVs at any one time
        </li>
      }
    </ul>
    <div className="how-to-connect-box-invariant-details-wrapper -has-right-arrow"
      onClick={(event) => {
        if (!!onDetailsClick){
          event.stopPropagation();
          onDetailsClick(ownDevicesCount > 0);
        }
      }}>
      <InvariantTitle>Details</InvariantTitle>
    </div>
  </>
};

const InvariantsFiltersList = ({ current, onChange }) => {
  return <ul className="how-to-connect-filters">
    {
      Object
        .keys(invariantsFilters)
        .map(key => {
          const className = ['button', current !== key ? '-bordered' : ''].join(' ').trim();
          return <li className="how-to-connect-filters-item" key={key}>
              <button onClick={() => onChange(key)} type="button" className={className}>
              {invariantsFilters[key]}
            </button>
          </li>
        })
    }
  </ul>
};

const renderInvariants = (variant, updateVariant, onDetailsClick) => (invariant, idx) =>
  <li className="how-to-connect-invariant-list-item -invariant-b" key={idx}>
    <InvariantBox
      recomended={invariant.recomended}
      selected={variant === invariant}
      onClick={() => updateVariant(invariant)}>
      <Invariant invariant={invariant} onDetailsClick={onDetailsClick} />
    </InvariantBox>
  </li>

const Invatiants = () => {
  const { 
    variant,
    updateVariant,
    filterName,
    updateFilterName,
    isOptionsOpen,
    toggleOptionsOpen,
    isPopupOpen,
    updatePopupOpen,
    updateCounterOf,
    splitedInvariants: [streaming, unlimited],
  } = useSplitedInvariants();

  const openPopup = (hasOwnDevices) => updatePopupOpen({ showBoth: hasOwnDevices });
  const closePopup = () => updatePopupOpen(null);

  const selectVariant = (value) => {
    const box = getCache('box');
    if (!!box && box !== value) updateCounterOf('changeConnectionTypePrimary');
    if (!!value) updateCache('box', value);
    updateVariant(value);
  };
  const selectOption = (value) => {
    const box = getCache('box');
    if (!!box && box !== value) updateCounterOf('changeConnectionTypeSecondary');
    if (!!value) updateCache('box', value);
    updateVariant(value);
  };
  
  return <>
    <InvariantsFiltersList current={filterName} onChange={updateFilterName} />
    <h6 className="how-to-connect-title">Select connection type</h6>
    <ul className="how-to-connect-invariant-list">
      {streaming.map(renderInvariants(variant, selectVariant, openPopup))}
    </ul>
    {unlimited.length > 0 && <div className="how-to-connect-title-wrapper" onClick={() => toggleOptionsOpen()}>
      <h6 className="how-to-connect-title -has-right-arrow">
        {isOptionsOpen ? 'See fewer options' : 'See additional options'}
      </h6>
    </div>}
    {isOptionsOpen && <ul className="how-to-connect-invariant-list">
      {unlimited.map(renderInvariants(variant, selectOption, openPopup))}
    </ul>}
    <HowToConnectPopup opened={isPopupOpen} onClose={closePopup} showBoth={ isPopupOpen && isPopupOpen.showBoth } />
  </>
}

const HowToConnect = ({ open, canOpen, onChange }) => {
  const { 
    data: { tvConnectionsData, howToConnectVariantData }
  } = useContext(DataStorageContext);
  const tvDataFilled = !!tvConnectionsData;
  const selfFilled = !!howToConnectVariantData;

  const chipText = tvDataFilled ? selfFilled ? 'Added' : 'Required' : 'Conditional';
  const chpClassName = selfFilled ? '-green' : '-red';
  return <InnerAccordion 
    open={open}
    canOpen={canOpen}
    onChange={onChange}
    TitleComponent={accordionTitleFactory('Choose how to connect your TVs', chipText, chpClassName)}>
    <div className="compatible-tip-wrapper">
      <CompatibleTip/>
    </div>
    <Invatiants />
  </InnerAccordion>;
};

export default HowToConnect;