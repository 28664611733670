import React from 'react';

const InfoText = () => {
  return (
    <div className="form_section -max-screener-and-custom-metrics-width">
      <div className="form_section-hint-over">
        Respondents will answer questions about the Target Category in general. Then, they will be asked specifically about the Target Brand.
      </div>
    </div>
  );
};

export default InfoText;
