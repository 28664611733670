import React, { Fragment } from 'react';
import { map, filter, isEmpty } from "lodash";

const HiddenFields = ({ options, model }) => {
  const qs = filter(model.questions, (q) => (q.kind === 'Multi'));

  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="project[brand_name_timeframe]" id="project_brand_name_timeframe" value={ model.brandNameTimeframe || '' } />
      {map(qs, (q, index) => (
        <Fragment key={ index }>
          <input type="hidden" name="project[database_questions][][id]" value={ q.id || '' } />
          <input type="hidden" name="project[database_questions][][none_answer]" value={ q.none_answer || 'false' } />
          <input type="hidden" name="project[database_questions][][none_answer_text]" value={ q.none_answer_text || '' } />
          <input type="hidden" name="project[database_questions][][other_answer]" value={ q.other_answer || 'false' } />
          <input type="hidden" name="project[database_questions][][other_answer_text]" value={ q.other_answer_text || '' } />
          <input type="hidden" name="project[database_questions][][brand_users_only]" value={ q.brand_users_only || false } />
          {
            map(q.answers, (answer, key) => (
              <input key={ key } type="hidden" name={ `project[database_questions][][answers][${key}]` } value={ answer || '' } />
            ))
          }
          <ImagesHiddenFields images={ q.answer_images } />
        </Fragment>
      ))}
    </>
  );
};

const ImagesHiddenFields = ({ images }) => {
  if (isEmpty(images) && images != null) {
    return (
      <input type="hidden" name={ `project[database_questions][][answer_images]` } value="" />
    );
  }

  return (
    <>
      {
        map(images, (image_data, key) => (
          <input key={ `image${key}` } type="hidden" name={ `project[database_questions][][answer_images][${key}]` } value={ image_data || '' } />
        ))
      }
    </>
  );
};

export default HiddenFields;
