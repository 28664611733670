import React from "react";

export const PromoLabel = ({ promo }) => {
  return (
    <div className={ `promo-label -in-plate ${promo.is ? '-is' : ''}` }>
      <svg className={ `promo-label_icon icon -${promo.is ? 'label' : 'none'}` }>
        <use xlinkHref={ `#svg_${promo.is ? 'label' : 'none'}` } />
      </svg>
      <div className="promo-label_title">
        {promo.title}
      </div>
    </div>
  );
};
