import React, { useState, useEffect, useContext } from 'react';
import InputNumber from '../../../common/components/inputNumber';
import { zipCodeRequest } from '../../logic/requests';
import { GeneralContext } from '../../contexts';

const questionIsZip = (question) => (question.title === 'ZIP Code');
const maxLength = 5;
const resultZipCodeActionKey = 'zipCodeCheckAction';
const resultZipCodeProcessedKey = 'zipCodeProcessedResult';

const checkZipCode = (result, nextStep, setLoading) => {
  if (result[resultZipCodeActionKey]) {
    setLoading(true);
    result[resultZipCodeActionKey](
      (response) => {
        setLoading(false);
        result[resultZipCodeProcessedKey] = response.data;
        delete result[resultZipCodeActionKey];
        nextStep();
      },
      () => {
        setLoading(false);
        result[resultZipCodeProcessedKey] = {};
        delete result[resultZipCodeActionKey];
        nextStep();
      },
    );
  } else {
    nextStep();
  }
};

const Zip = ({ question, updateValue }) => {
  const [ value, setValue ] = useState(null);
  const { result, paths } = useContext(GeneralContext);
  const findZipCodeUrl = paths.find_zip_code_url;

  useEffect(() => {
    if (value?.length === maxLength) {
      result[resultZipCodeActionKey] = (goodCallback, badCallback) => {
        zipCodeRequest(findZipCodeUrl, value, goodCallback, badCallback);
      };
      updateValue({ [question.name]: value });
    } else {
      updateValue(null);
    }
  }, [ value ]);

  return (
    <div className="survey-options js-select">
      <InputNumber
        className="zip-input form-field"
        value={ value }
        onUpdate={ setValue }
        maxLength={ maxLength }
        ariaLabel={ question.text }
      />
    </div>
  );
};

export { Zip, questionIsZip, checkZipCode, resultZipCodeProcessedKey };
