import React, { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '../../../contexts';
import FivePointScale from '../five_point_scale';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ItemView from '../../ItemView';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';
import Stimuli from '../../stimuli';
import Intro from '../../intro';

const FullConcept = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    concept, fullConceptMetrics, result, translations, branded
  } = useContext(GeneralContext);

  const fullConceptData = concept.full_concept_data;

  const metricIndex = metricGroupStep - 2;

  const prepareCurrentQuestions = () => {
    if (metricIndex >= 0) {
      const question = fullConceptMetrics[metricIndex];
      question.text = fixQuestionTextTemplates(question, fullConceptMetrics, result, translations);
      return [ question ];
    }
    return [];
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  useEffect(() => {
    setCurrentQuestions(prepareCurrentQuestions());
  }, [ metricGroupStep ]);

  const nextStep = () => {
    if (metricIndex < fullConceptMetrics.length - 1) {
      setMetricGroupStep(metricGroupStep + 1);
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  return (
    <>
      {
        !metricGroupStep &&
        <Intro nextStep={ nextStep }>
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.full_concept_intro) } />
            </div>
          </>
        </Intro>
      }
      {
        !!metricGroupStep && metricIndex < 0 &&
        <>
          <ItemView nextStep={ nextStep } startClassName={ '-transparent' }>
            <div className="survey-start_description -left-aligned">
              <Stimuli
                ideaName={ fullConceptData.idea_name }
                customerBenefit={ fullConceptData.customer_benefit }
                reasonsToBelieve={ fullConceptData.reasons_to_believe }
                processLeadInOptions={ fullConceptData.process_lead_in_options }
                processLeadIn={ fullConceptData.process_lead_in }
                processLeadInOther={ fullConceptData.process_lead_in_other }
                processStepsKind={ fullConceptData.process_steps_kind }
                steps={ fullConceptData.steps }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
                // zoomImage
              />
            </div>
          </ItemView>
        </>
      }
      {
        metricIndex >= 0 && !!currentQuestions[0] &&
        <>
          <FivePointScale
            key={ currentQuestions[0].name }
            customConceptViewClassName={ '-transparent' }
            customConceptView={
              <Stimuli
                imageClassName="-less-height"
                ideaName={ fullConceptData.idea_name }
                customerBenefit={ fullConceptData.customer_benefit }
                reasonsToBelieve={ fullConceptData.reasons_to_believe }
                processLeadInOptions={ fullConceptData.process_lead_in_options }
                processLeadIn={ fullConceptData.process_lead_in }
                processLeadInOther={ fullConceptData.process_lead_in_other }
                processStepsKind={ fullConceptData.process_steps_kind }
                steps={ fullConceptData.steps }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
                // zoomImage
              />
            }
            concept={ concept }
            questions={ currentQuestions }
            result={ result }
            nextStep={ nextStep }
          >
            <></>
          </FivePointScale>
        </>
      }
    </>
  );
};

export default FullConcept;
