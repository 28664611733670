import React, { useContext, useState, useEffect } from 'react';
import { GeneralContext } from '../../../contexts';
import FivePointScale from '../five_point_scale';
import renderRawHtml from '../../../../../../common/render_raw_html';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';

const PreExposure = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    concept, preExposures, result, translations
  } = useContext(GeneralContext);

  const prepareCurrentQuestions = () => {
    const question = preExposures[metricGroupStep];
    question.text = fixQuestionTextTemplates(question, preExposures, result, translations);
    return [ question ];
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  useEffect(() => {
    setCurrentQuestions(prepareCurrentQuestions());
  }, [ metricGroupStep ]);

  const nextStep = () => {
    if (metricGroupStep < preExposures.length - 1) {
      setMetricGroupStep(metricGroupStep + 1);
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  return (
    <>
      <FivePointScale
        key={ currentQuestions[0].name }
        concept={ concept }
        questions={ currentQuestions }
        result={ result }
        nextStep={ nextStep }
        questionNotice={ currentQuestions[0].note }
      >
        <></>
      </FivePointScale>
    </>
  );
};

export default PreExposure;
