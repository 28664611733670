import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import EditableInput from '../common/editableInput';

const QuotaTestInputNSize = () => {
  const {
    getTestNSize, setTestNSize, inputsDisabled, getErrorByField
  } = useContext(PanelServiceContext);
  const testNSize = getTestNSize();
  const disabled = inputsDisabled();
  const error = getErrorByField('panel_service_test_n_size');
  return (
    <EditableInput
      title="Quota Test N Size"
      value={ testNSize }
      onChange={ setTestNSize }
      disabled={ disabled }
      error={ error }
    >
      It defines the N size for calculating the quotas in the<b>test environment</b>.
      <br />
      If value is positive integer - it will turn on the quotas checking
      {' '}
      and quotas redirects.
      <br />
      It is allowed to enter 0 and leave blank - in this case quota check and
      {' '}
      quota redirects will not be called.
    </EditableInput>
  );
};

export default QuotaTestInputNSize;
