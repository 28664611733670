import React from "react";

const Card = ({ children }) => <div className="card">{children}</div>;

Card.Body = ({ children }) => <div className="card-body">{children}</div>;

Card.Img = ({ ...props }) => <img className="card-img" {...props} />;

Card.ImgOverlay = ({ src, className, children }) => (
  <div className={`card-img-overlay ${className || ""}`}>
    <img className="card-img-overlay_bg" src={src} />
    <div className="card-img-overlay_content">{children}</div>
  </div>
);

export default Card;
