import React, { useState, useContext, useRef } from "react";
import classnames from 'classnames';
import { baseContext } from "../../contexts";
import { createExport, checkStatus } from '../api';

const RespLevelDataExport = ({ projectId, projectDate, children }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ open, setOpen ] = useState(false);
  const [ inProgress, setInProgress ] = useState(false);
  const [ , setTimeoutId ] = useState(null);
  const downloadLink = useRef(null);

  const interval = 5000;
  const waitingForExportToFinish = (id, callback) => {
    checkStatus(mediaKind, id, (data) => {
      if (data.count_in_progress) {
        setTimeoutId((oldTimeoutId) => {
          clearTimeout(oldTimeoutId);
          return setTimeout(() => {
            waitingForExportToFinish(id, callback);
          }, interval);
        });
      } else {
        callback(data);
      }
    });
  };

  const getExportFile = (openEndIds, callback) => {
    setInProgress(true);
    createExport(mediaKind, verizonType, projectId, 'xlsx', 'overview_comparison', openEndIds, null, (data) => {
      waitingForExportToFinish(data.id, (result) => {
        const resultData = result.user_downloads[0];
        setInProgress(false);
        if (resultData.status === "finished") {
          downloadLink.current.setAttribute("href", resultData.s3_filename);
          downloadLink.current.setAttribute("download", resultData.subtitle);
          downloadLink.current.click();
          callback && callback();
        }
      });
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (inProgress) {
      return;
    }
    getExportFile();
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a
        href="#"
        className={
          classnames(
            "button -sm -bordered",
            { "loader -loading-blink": inProgress }
          )
        }
        onClick={ handleClick }
      >
        {inProgress && "Processing..."}
        { !inProgress && (children || "Export") }
      </a>
      {
        open &&
        <Popup
          onClose={ onClose }
          openEnds={ [] }
          projectDate={ projectDate }
          inProgress={ inProgress }
          getExportFile={ getExportFile }
        />
      }
      <a ref={ downloadLink } href="" style={ { display: "none" } } />
    </>
  );
};

export default RespLevelDataExport;
