import React from "react";
import { map } from "lodash";
import classnames from 'classnames';
import { prepareText } from "../common/helper";

export const BaseDetails = ({ data = {} }) => {
  const { plate, listDetails, description, option, buttonsBottom } = data;
  return (
    <div className={ `base-details ${buttonsBottom ? '-with-buttons-bottom' : ''}` }>
      <div className="base-details_content">
        {
          plate &&
          <div className="plate -black base-details_plate">
            { plate.note && <div className="base-details_plate-note">{plate.note}</div> }
            { plate.title && <h4 className="base-details_plate-title">{plate.title}</h4> }
            {
              plate.description &&
              <div className="base-details_plate-description">
                {plate.description}
              </div>
            }
          </div>
        }
        {
          description &&
          <div className="base-details_description">
            {prepareText({ texts: description })}
          </div>
        }
        {
          listDetails &&
          <ul className="base-details_list">
            {map(listDetails, (item, idx) => (
              <li className="base-details_list-item" key={ idx }>
                {
                  (item.titleForDescription || item.title) &&
                  <h5 className={ `base-details_list-item-title ${option?.listTitle ? `-${option.listTitle}` : ""}` }>
                    { item.titleForDescription || item.title }
                  </h5>
                }
                {
                  item.description &&
                  <div className="base-details_list-item-description">
                    {prepareText({ texts: item.description, type: item.type })}
                  </div>
                }
              </li>
            ))}
          </ul>
        }
      </div>
      {
        buttonsBottom &&
        <div className="base-details_buttons">
          {
            map(buttonsBottom, (button, idx) => (
              <div className="base-details_button" key={ idx }>
                <button
                  className={
                    classnames(
                      "button -full-width",
                      button.className,
                      {
                        "-bordered": !!idx && button.kind === 'button',
                        "-link": button.kind === 'link'
                      },
                    )
                  }
                  onClick={ button.handleClick }
                >
                  { button.title }
                </button>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
};
