import React, { Fragment, useContext, useState } from 'react';
import { map } from 'lodash';
import renderRawHtml from "../../../../common/render_raw_html";
import lineName from '../../common/line_name';
import { LineDataContext } from '../../common/contexts';
import Popup from '../../common/popup';
import classnames from 'classnames';

const noBreaks = (text) => (text.replace(/<br\/>/, ' ').replace(/\s+/, ' '));

const FeaturesList = ({ planId, title, features }) => {
  return(
    <div className="plan-features-group">
      <div className="plan-feature-header">{ title }</div>
      {
        map(features, (feature, index) => {
          return (
            <Fragment key={ `${title}Feature${planId}${index}` }>
              <div className="plan-feature-title"  {...renderRawHtml(noBreaks(feature.title))}>
              </div>
              {
                feature.note &&
                <div className="plan-feature-note"  {...renderRawHtml(noBreaks(feature.note))}>
                </div>
              }
            </Fragment>
          )
        })
      }
    </div>
  )
}

const UnlimitedPlan = ({ planObject, onPlanSelect }) => {
  return (
    <div className="box -flex-col">
      <div className="description-box">
        <h2 className="title ng-binding" {...renderRawHtml(planObject.title)}>
        </h2>
        <div className="text-16 description" {...renderRawHtml(planObject.description)}>
        </div>
        <div className="box-pricing">
          <div>
            <div className="price">
              <h2 className="big-value">${ planObject.price }</h2>
              <div className="value-hint">
                <div className="text-10">Per line per month.</div>
                <div className="text-10">Plus taxes & fees.</div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="select-billing-plan"
          onClick={ (event) => { onPlanSelect(event, planObject) } }
        >
          Select plan
        </button>
      </div>
      <div className="plan-features">
        {
          planObject.totalAccess && !!planObject.totalAccess.length &&
          <FeaturesList planId={planObject.id} title="5G Total Access" features={ planObject.totalAccess }/>
        }
        {
          planObject.features && !!planObject.features.length &&
          <FeaturesList planId={planObject.id} title="Plan Features" features={ planObject.features }/>
        }
        {
          planObject.perks && !!planObject.perks.length &&
          <FeaturesList planId={planObject.id} title="Plan Perks" features={ planObject.perks }/>
        }
      </div>
      {
        planObject.special &&
        <div className="feature-special" {...renderRawHtml(planObject.special)}>
        </div>
      }
    </div>
  )
};

const UnlimitedPage = ({ currentLine, onPlanSelect, onChangePage, showChangePage }) => {
  const [showPopup, updateShowPopup] = useState(false);
  const { plans, allPlansInclude, popupCopy } = useContext(LineDataContext);
  const openPopup = (event) => {
    event.preventDefault();
    updateShowPopup(true);
  }
  return(
    <div className="content">
      <div className="header"></div>
      <div className="unlimited-page unlimited-bau">
        <div className="inner-content">
          <div className="top-description">
            <div className="ad-left">
              <h2>Let’s choose a plan for your { lineName(currentLine) } line.</h2>
              <span className="text-20">
                Enroll in paper-free billing and Auto Pay after account creation to save an additional $10/mo on these plans.
                <svg className="info"><use xlinkHref="#svg_tooltip"/></svg>
              </span>
            </div>
            <div className="ad-right">
              <h4>All Unlimited plans include</h4>
              <span className="text-14">
                {
                  map(allPlansInclude, (feature, index) => {
                    return(
                      <Fragment key={ `allFeaturesUnlimited${index}` }>
                        { !!index && <br/> }
                        { feature }
                      </Fragment>
                    )
                  })
                }
              </span>
            </div>
          </div>
          {
            showChangePage &&
            <div className="split-button-content">
              <button className="select-billing-plan" onClick={ onChangePage }>See our other plans<span>&gt;</span></button>
            </div>
          }
          <ul className="plans-view-selector"></ul>
          <div className="inner-content -list-view">
            <div className={ classnames("list_view", { "-no-bottom-padding": !!popupCopy }) }>
              {
                map(plans, (planObject) => {
                  return(
                    <UnlimitedPlan
                      key={ `unlimitedPlanItem${planObject.id}` }
                      planObject={ planObject }
                      onPlanSelect={ onPlanSelect }
                    />
                  )
                })
              }
            </div>
            {
              popupCopy &&
              <div className="view-details-section">
                <a href="#" onClick={ openPopup }>View details about Entertainment Hub</a>
              </div>
            }
          </div>
        </div>
      </div>
      {
        showPopup &&
        <Popup onClose={ () => { updateShowPopup(false) } }>
          <Fragment>
            <h2 className="popup-header">Entertainment Hub</h2>
            <div className="popup-body -unlimited-1" {...renderRawHtml(popupCopy)}>
            </div>
          </Fragment>
        </Popup>
      }
    </div>
  );
};

export default UnlimitedPage;