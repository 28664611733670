import React, {useState} from 'react';
import pluralize from "pluralize";
import { filter, map, findIndex, orderBy } from "lodash";

const SubClient = ({ client, subClient, onDelSub }) => {
  return (
    <li className="subclient-item" key={ `sub-${client.id}-${subClient.id}` }>
      <div className="name">{ subClient.name }</div>
      <button
        className="button"
        onClick={ () => onDelSub(client, subClient) }
      >
        <svg className="icon -i-delete">
          <use xlinkHref="#svg_i-delete" />
        </svg>
      </button>
    </li>
  );
};

export default SubClient;
