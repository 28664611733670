import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { map, each, isEmpty, keys, find, trim }  from 'lodash';
import classnames from 'classnames';
import { getAgeOptions, getAgeAnswerId } from '../../common/logic/age';
import Grid from './metrics/grid';
import { Zip, questionIsZip } from './screening/zip';
import { Kids, questionIsKids } from './screening/kids';
import { SingleOutput } from './singleOutput';
import { MultiOutput } from './multiOutput';
import { OpenEnd } from './metrics/openEnd';

const Question = forwardRef((props, ref) => {
  const { question, result, setDisabled } = props;
  const [ customDisabled, setCustomDisabled ] = useState(false);

  const { kids, two_columns, placeholder } = question;
  const single = question.kind === 'Single';
  const multi = question.kind === 'Multi';
  // const single = true;
  // const multi = false;
  const grid = question.kind === 'Grid';
  const open = question.kind === 'Open';
  const needDropDown = question.need_dropdown;
  const isAge = question.is_age;
  const answers = question.detailed_answers || question.answers;

  const [ radioAnswer, updateRadioAnswer ] = useState(null);
  const [ dropDownAnswer, updateDropDownAnswer ] = useState(null);
  const [ checkboxAnswers, updateCheckboxAnswers ] = useState({});
  const [ gridAnswers, updateGridAnswers ] = useState({});
  const [ otherAnswer, setOtherAnswer ] = useState('');
  const [ openAnswer, setOpenAnswer ] = useState('');

  const isOther = (id) => ((id || '').match(/_-2/));
  const isRadioOtherSelected = () => (isOther(radioAnswer));
  const isCheckboxOtherSelected = () => (
    find(keys(checkboxAnswers), (item) => isOther(item))
  );

  const updateDisabledByKind = (kind, customDisabledValue) => {
    let newDisabled;
    let otherSelected;
    let enableWhenPresent;

    if (kind === 'radio') {
      otherSelected = isRadioOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = !radioAnswer || !enableWhenPresent;
    } else if (kind === 'checkbox') {
      otherSelected = isCheckboxOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = isEmpty(checkboxAnswers) || !enableWhenPresent;
    } else if (kind === 'grid') {
      newDisabled = isEmpty(gridAnswers) || keys(gridAnswers).length < answers.length;
    } else if (kind === 'open') {
      newDisabled = isEmpty(openAnswer);
    } else if (kind === 'custom') {
      setCustomDisabled(customDisabledValue);
      return;
    } else {
      newDisabled = !dropDownAnswer;
    }
    setDisabled(newDisabled || customDisabled);
  };

  const updateOtherText = (event) => {
    setOtherAnswer(event.target.value);
  };

  useEffect(() => {
    updateDisabledByKind('checkbox');
  }, [ checkboxAnswers ]);

  useEffect(() => {
    updateDisabledByKind('dropDown');
  }, [ dropDownAnswer ]);

  useEffect(() => {
    updateDisabledByKind('radio');
  }, [ radioAnswer ]);

  useEffect(() => {
    updateDisabledByKind('grid');
  }, [ gridAnswers ]);

  useEffect(() => {
    if (multi) {
      updateDisabledByKind('checkbox');
    } else {
      updateDisabledByKind('radio');
    }
  }, [ otherAnswer ]);

  useEffect(() => {
    updateDisabledByKind('open');
  }, [ openAnswer ]);

  const updateCheckbox = (value) => {
    updateCheckboxAnswers({ ...value });
  };
  const updateDropDown = (value) => {
    updateDropDownAnswer(value);
  };
  const updateRadio = (value) => {
    updateRadioAnswer(value);
  };

  const saveOtherAnswer = () => {
    const shouldSaveOther = multi ?
      isCheckboxOtherSelected() : isRadioOtherSelected();
    if (shouldSaveOther) {
      if (!result.otherAnswers) {
        result.otherAnswers = {};
      }
      result.otherAnswers[question.name] = otherAnswer;
    }
  };

  const copyData = () => {
    let realId, realAnswer;

    if (multi) {
      each(keys(checkboxAnswers), (key) => {
        if (checkboxAnswers[key]) {
          result.answers.push(key);
        }
      });
      updateCheckbox({});
    } else if (needDropDown && isAge) {
      result.answers.push(getAgeAnswerId(question, dropDownAnswer));
      result.selectedAge = dropDownAnswer;
      updateDropDown(null);
    } else if (needDropDown && !isAge) {
      realId = find(answers, (el) => el.id === dropDownAnswer) || {};
      realAnswer = realId.mapped_id || realId.id || dropDownAnswer;
      result.answers.push(realAnswer);
      updateDropDown(null);
    } else if (grid) {
      each(keys(gridAnswers), (key) => {
        const answer = `${key}_${gridAnswers[key]}`;
        result.answers.push(answer);
      });
      updateGridAnswers(null);
    } else if (questionIsZip(question)) {
      result.answers.push(openAnswer);
      setOpenAnswer(null);
    } else if (open && !questionIsZip(question)) {
      result.answers.push({ [question.name]: openAnswer });
      setOpenAnswer(null);
    } else {
      realId = find(answers, (el) => el.id === radioAnswer) || {};
      realAnswer = realId.mapped_id || realId.id || radioAnswer;
      result.answers.push(realAnswer);
      updateRadio(null);
    }
    saveOtherAnswer();
    setOtherAnswer('');
  };

  useImperativeHandle(ref, () => {
    return copyData;
  });

  const dropDownChange = (event) => {
    updateDropDown(event.target.value);
  };

  const withDropDown = () => (
    <div className="survey-options js-select">
      <select
        className="form-field"
        onChange={ (event) => { dropDownChange(event); } }
        aria-label={ question.text }
      >
        <option value="">{ placeholder }</option>
        {
          isAge &&
          map(getAgeOptions(question), (answer) => (
            <option value={ answer } key={ answer }>
              { answer }
            </option>
          ))
        }
        {
          !isAge &&
          map(answers, (answer) => (
            <option value={ answer.id } key={ answer.id }>
              { answer.label }
            </option>
          ))
        }
      </select>
    </div>
  );

  return (
    <div
      className={ classnames("survey-layout_container", { "-kids": kids, "-two-columns": two_columns }) }
    >
      { needDropDown && withDropDown() }
      {
        !needDropDown &&
          <div
            className={
              classnames(
                "survey-options",
                {
                  "-screening": question.special_view && !questionIsKids(question),
                  "-grid": grid && !questionIsKids(question),
                  "-max-width": question.name === "SO92" || question.name === "SO94" || question.name === "SO16"
                },
              )
            }
          >
            { single &&
              <SingleOutput
                answers={ answers }
                question={ question }
                radioAnswer={ radioAnswer }
                updateRadio={ updateRadio }
                otherAnswer={ otherAnswer }
                updateOtherText={ updateOtherText }
              />
            }
            {
              multi && questionIsKids(question) &&
              <Kids
                question={ question }
                updateValues={ updateCheckbox }
                updateDisabled={
                  (disabledValue) => updateDisabledByKind("custom", disabledValue)
                }
              />
            }
            { multi && !questionIsKids(question) &&
            <MultiOutput
              answers={ answers }
              question={ question }
              checkboxAnswers={ checkboxAnswers }
              updateCheckbox={ updateCheckbox }
              otherAnswer={ otherAnswer }
              updateOtherText={ updateOtherText }
            />
            }
            {
              grid &&
              <Grid
                question={ question }
                gridAnswers={ gridAnswers }
                updateGridAnswers={ updateGridAnswers }
                updateDisabled={
                  (disabledValue) => updateDisabledByKind("custom", disabledValue)
                }
              />
            }
            { open && questionIsZip(question) && <Zip question={ question } updateValue={ setOpenAnswer } /> }
            { open && !questionIsZip(question) && <OpenEnd question={ question } updateText={ setOpenAnswer } /> }
          </div>
      }
    </div>
  );
});

export default Question;
