import React from 'react';

const Header = ({ selected, total, setShowList }) => {
  return (
    <>
      <h1 className="comparisons_title">Case Comparison</h1>
      <div className="comparisons_notes">
        <span>
          Showing {selected} of {total} selected items.
        </span> <span className="link -light-blue" onClick={ () => { setShowList(true);} }>
          Set Cases for Comparison
        </span>
      </div>
    </>
  );
};

export default Header;
