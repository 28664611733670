import React, { useState } from 'react';
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import remoteFiles from "../../../../common/remote_files";
import SelectInText from "../../common/details/SelectInText";
import CheckBox from "../../common/details/CheckBox";
import BuzzbackProjectives from "../../common/details/BuzzbackProjectives";
import HiddenFields from "./HiddenFields";

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    tbEnabled: data.tb_enabled,
    cfEnabled: data.cf_enabled,
    diagnosticsNum: data.diagnostics_num
  });

  const [ tbQuestion ] = useState(() => (
    options.thought_bubble_question.replace(/%{claim}/, '[claim]')
  ));

  return (
    <WithSubmit
      formId="details_form"
      errors={ errors }
      options={ options }
    >
      <HiddenFields
        options={ options }
        model={ model }
      />
      <div className="content -relative -grey-background">
        <Header title={ options.project_title } />
        <div className="content_body">
          <div className="form">
            <div className="form_section -sm-space -padding-bottom">
              <div className="form_group -relative">
                <BuzzbackProjectives namesText="claims" />

                <SelectInText
                  initValue={ model.diagnosticsNum }
                  onChange={ (v) => { setModel({ ...model, diagnosticsNum: v }); } }
                  options={ options.diagnostics_options }
                  namesText="claims"
                  nameText="claim"
                />

                <div className="form_metrics -md">
                  <CheckBox
                    initValue={ model.cfEnabled }
                    onChange={ (v) => { setModel({ ...model, cfEnabled: v }); } }
                    inputId="id-cf"
                    text="Concept Focus"
                    infoText={
                      <>
                        With Concept Focus, respondents markup elements of the claim
                        {' '}
                        that resonate and explain why.
                      </>
                    }
                    img={ remoteFiles.express.steps.claim.conceptFocus }
                  />
                  <CheckBox
                    initValue={ model.tbEnabled }
                    onChange={ (v) => { setModel({ ...model, tbEnabled: v }); } }
                    className="-border-bottom"
                    inputId="id-tb"
                    text="Thought Bubble"
                    infoText={
                      <>
                        With Thought Bubble, respondents will dissociate and provide words
                        {' '}
                        to describe the claim. Sample question text:
                        {' '}
                        "{tbQuestion}"
                      </>
                    }
                    img={ remoteFiles.express.steps.claim.thoughtBubble }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
