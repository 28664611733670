import React from 'react';
import remoteFiles from '../../../common/remote_files';

const AboutHero = () => {
  const { aboutHero } = remoteFiles.visibleCom;
  return (
    <section className="about-hero">
      <div className="about-hero_text">
        <h1 className="about-hero_title">
          Committed to connection
        </h1>
        <div className="about-hero_p">
          We’re reimagining what wireless should be: simple, accessible, and inclusive.
        </div>
      </div>
      <div
        className="about-hero_image"
        style={ { backgroundImage: `url(${aboutHero})` } }
      />

    </section>
  );
};

export default AboutHero;
