import React, { useContext } from "react";
import { DataContext } from "../data-context";

const CheckBoxes = () => {
  const {
    cartWithPrices, setCartWithPrices, cartLimit,
    setCartLimit, cartWithLimit, setCartWithLimit,
    cartRanking, setCartRanking, options
  } = useContext(DataContext);

  const handleCartWithPricesChange = (event) => {
    const value = event.target.checked;
    setCartWithPrices(value);
  };

  const handleCartWithLimitChange = (event) => {
    const value = event.target.checked;
    setCartWithLimit(value);
  };

  const handleCartLimitChange = (event) => {
    const value = event.target.value;
    setCartLimit(value);
  };

  const handleCartRankingChange = (event) => {
    const value = event.target.checked;
    setCartRanking(value);
  };

  return (
    <div className="form_group -relative">
      {options.show_ranking &&
        <div className="form_section -section-relative -margin-5-between">
          <div className="form_section-title">
            <div className="form_section-title-item">
              <div className="checkbox">
                <input
                  id="cart-ranking"
                  type="checkbox"
                  checked={ cartRanking }
                  onChange={ handleCartRankingChange }
                />
                <label className="checkbox_label" htmlFor="cart-ranking">
                  Include "Ranking" question
                </label>
              </div>
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
                </div>
                <div className="info-tooltip_drop -bottom-center">
                  <div className="info-tooltip_drop-inner">
                    "Ranking" question will be shown right before the Cart exercise
                    {' '}
                    if this checkbox is checked and at least 3 products
                    {' '}
                    (including the package) are created and tagged on a shelf.
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>}

      <div className="form_section -section-relative -margin-5-between">
        <div className="form_section-title -grid">
          <div className="form_section-title-item -third">
            <div className="checkbox">
              <input
                id="cart-with-prices"
                type="checkbox"
                checked={ cartWithPrices }
                onChange={ handleCartWithPricesChange }
              />
              <label className="checkbox_label" htmlFor="cart-with-prices">
                Product with prices
              </label>
            </div>
          </div>

          <div className="form_section-title-item">
            <div className="checkbox">
              <input
                id="cart-limit"
                type="checkbox"
                checked={ cartWithLimit }
                onChange={ handleCartWithLimitChange }
                disabled={ !cartWithPrices }
              />
              <label className="checkbox_label -empty" htmlFor="cart-limit">
                &nbsp;
              </label>
            </div>
          </div>

          <div className="form_section-title-item">
            <input
              type="text"
              className="form-field -block"
              placeholder="Set spending limit, $"
              value={ cartLimit }
              onChange={ handleCartLimitChange }
              disabled={ !cartWithPrices || !cartWithLimit }
            />
          </div>

          <div className="form_section-title-item">
            <div className="info-tooltip">
              <div className="info-tooltip_control">
                <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
              </div>
              <div className="info-tooltip_drop -bottom-center">
                <div className="info-tooltip_drop-inner">
                  Set spending limit in local currency.
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default CheckBoxes;
