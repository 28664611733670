import React from 'react';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html';
import ZoomImage from '../zoom_image';

const ProcessView = ({ processKind, process, zoomImage, imageClassName }) => {
  return (
    <>
      {
        processKind === 'text' &&
        <div className="cit-process" { ...renderRawHtml(process.text) } />
      }
      {
        processKind === 'images' &&
        <div className="cit-process -image">
          {
            zoomImage ? (
              <ZoomImage
                className={ classnames("radio-plate_part-image", imageClassName) }
                popupClassName="-custom-image-in-modal"
                src={ process.url }
                altText={ process.notes }
              >
                <img src={ process.url } alt={ process.notes } />
              </ZoomImage>
            ) : (
              <img className={ imageClassName } src={ process.url } alt={ process.notes } />
            )
          }
        </div>
      }
    </>
  );
};

export default ProcessView;
