import React, { useContext, forwardRef } from 'react';
import { round } from "lodash";
import classnames from 'classnames';
import { IndexData } from "../../common/index-context";
import GreenRed from "./greenRed";

// eslint-disable-next-line react/display-name
const BenchmarksNumbers = forwardRef((props, ref) => {
  const { data } = useContext(IndexData);

  return (
    <>
      {
        data.benchmarks &&
        <>
          <h2 className="comparisons_section-title">{`Benchmarks Among ${data.segment_full_text}`}</h2>
          <table className="table scorecard-table" ref={ ref }>
            <tbody>
              <TableHeaderRow />
              <HeaderRow text="Customer Case Score" />
              <DataRow
                text="Customer Case Score"
                benchmarkData={ data.benchmarks.happy }
                needPercents={ false }
              />
              <HeaderRow text="Opportunity" />
              <DataRow
                text="Category Need"
                hint="“Absolutely” or “Very much” needed"
                benchmarkData={ data.benchmarks.vcc_need }
              />
              <DataRow
                text="Satisfaction with Category Solutions"
                hint="“Extremely” or “Very” satisfied"
                benchmarkData={ data.benchmarks.vcc_satisfaction }
              />

              <HeaderRow text="Idea Only" />
              <DataRow
                text="Appeal"
                hint="“Extremely” or “Very” appealing"
                benchmarkData={ data.benchmarks.vcc_appeal }
              />
              <DataRow
                text="Difference"
                hint="Agree “Completely” or “Somewhat”"
                benchmarkData={ data.benchmarks.vcc_difference }
              />

              <HeaderRow text="Full Idea" />
              <DataRow
                text={ data.branded_texts.vcc_customer_first }
                hint="Agree “Completely” or “Somewhat”"
                benchmarkData={ data.benchmarks.vcc_customer_first }
              />
              <DataRow
                text="Created with My Needs in Mind"
                hint="Agree “Completely” or “Somewhat”"
                benchmarkData={ data.benchmarks.vcc_needs }
              />
              <DataRow
                text="Delights Me"
                hint="Feel “Delighted” on a scale of Delighted!, Happy, Neutral, Upset, Angry"
                benchmarkData={ data.benchmarks.vcc_delight }
              />
              <DataRow
                text="Experience is Worthwhile"
                hint="Agree “Completely” or “Somewhat”"
                benchmarkData={ data.benchmarks.vcc_worthwhile }
              />

              <EmptyRow />
              <TableHeaderRow />
              <HeaderRow text="Process - Other KPIs" />

              {data.branded !== "unbranded" &&
                <>
                  <DataRow
                    text={ data.branded_texts.vcc_opinion }
                    second="Improve"
                    benchmarkData={ data.benchmarks.vcc_opinion.code3 }
                    colorLeft={ data.benchmarks.vcc_opinion.code3.color_left }
                    colorRight={ data.benchmarks.vcc_opinion.code3.color_right }
                  />
                  <DataRow
                    second="No Impact"
                    benchmarkData={ data.benchmarks.vcc_opinion.code2 }
                    colorLeft={ data.benchmarks.vcc_opinion.code2.color_left }
                    colorRight={ data.benchmarks.vcc_opinion.code2.color_right }
                  />
                  <DataRow
                    second="Worsens"
                    benchmarkData={ data.benchmarks.vcc_opinion.code1 }
                    colorLeft={ data.benchmarks.vcc_opinion.code1.color_left }
                    colorRight={ data.benchmarks.vcc_opinion.code1.color_right }
                  />
                </>}

              <DataRow
                text="Simple & Intuitive"
                hint="Agree “Completely” or “Somewhat”"
                benchmarkData={ data.benchmarks.vcc_simple }
              />
              <DataRow
                text="Honest No Surprises"
                hint="Agree “Completely” or “Somewhat”"
                benchmarkData={ data.benchmarks.vcc_honest }
              />
              <DataRow
                text="Process Clarity"
                hint="“Very” or “Somewhat” clear and understandable"
                benchmarkData={ data.benchmarks.vcc_process_clarity }
              />

              <HeaderRow text="Process - Ease / Effort" />
              <DataRow
                text="Experience Ease / Effort"
                second="No effort/a little effort"
                benchmarkData={ data.benchmarks.vcc_effort.code45 }
                colorLeft={ data.benchmarks.vcc_effort.code45.color_left }
                colorRight={ data.benchmarks.vcc_effort.code45.color_right }
              />
              <DataRow
                second="Some effort"
                benchmarkData={ data.benchmarks.vcc_effort.code3 }
                colorLeft={ data.benchmarks.vcc_effort.code3.color_left }
                colorRight={ data.benchmarks.vcc_effort.code3.color_right }
              />
              <DataRow
                second="Moderate effort"
                benchmarkData={ data.benchmarks.vcc_effort.code2 }
                colorLeft={ data.benchmarks.vcc_effort.code2.color_left }
                colorRight={ data.benchmarks.vcc_effort.code2.color_right }
              />
              <DataRow
                second="A lot of effort"
                benchmarkData={ data.benchmarks.vcc_effort.code1 }
                colorLeft={ data.benchmarks.vcc_effort.code1.color_left }
                colorRight={ data.benchmarks.vcc_effort.code1.color_right }
              />
            </tbody>
          </table>
          <div className="table_legend">
            <GreenRed />
          </div>
        </>
      }
    </>
  );
});

const DataRow = ({
  text, hint,
  second, third,
  benchmarkData,
  colorLeft, colorRight, tdModifier = "",
  needPercents = true
}) => {
  const average = benchmarkData.average;
  const sd = benchmarkData.sd;
  const sdMinus = benchmarkData.sd_minus;
  const sdPlus = benchmarkData.sd_plus;
  return (
    <tr>
      <td className={ tdModifier } colSpan={ !second ? 2 : 1 }>
        <div className="bold">{text}</div>
        <div className="note">{hint}</div>
      </td>
      {!!second && <td className={ tdModifier }>{second}</td>}
      <td className={ tdModifier }>{third}</td>
      <td className={ `${tdModifier} -right` } >
        <div
          className={
            classnames(
              { 'bold': colorLeft !== 'none' },
              { 'red': !colorLeft },
              { 'green': colorLeft === 'inverse' }
            )
          }
        >
          { round(sdMinus || (average - sd)) }{ needPercents ? '%' : '' }
        </div>
      </td>
      <td className={ `${tdModifier} -right` }>{ round(average) }{ needPercents ? '%' : '' }</td>
      <td className={ `${tdModifier} -right` }>
        <div
          className={
            classnames(
              { 'bold': colorRight !== 'none' },
              { 'green': !colorRight },
              { 'red': colorRight === 'inverse' }
            )
          }
        >
          { round(sdPlus || (average + sd)) }{ needPercents ? '%' : '' }
        </div>
      </td>
    </tr>
  );
};


const HeaderRow = ({ text }) => {
  return (
    <tr>
      <th className="th-section" colSpan={ 6 }><b>{text}</b></th>
    </tr>
  );
};

const EmptyRow = () => (
  <tr>
    <td className="td-empty" colSpan={ 6 } />
  </tr>
);

const TableHeaderRow = () => {
  return (
    <tr className="tr-head">
      <th />
      <th />
      <th />
      <th className="min-width -right -top">
        <b>Below</b>
        <br />
        (-1SD)
      </th>
      <th className="min-width -right -top">
        <b>Average</b>
      </th>
      <th className="min-width -right -top">
        <b>Above</b>
        <br />
        (+1SD)
      </th>
    </tr>
  );
};

export { BenchmarksNumbers, TableHeaderRow, HeaderRow, DataRow };
