import React, { useContext } from 'react';
import { each, filter, includes, map } from 'lodash';
import EditQuota from '../../common/edit_quota';
import { ListContext } from '../contexts/list_context';
import { ConditionContext } from "../contexts/condition_context";

const EditDemoQuota = (props) => {
  const { question } = props;
  const contextValue = useContext(ListContext);
  const conditionValues = useContext(ConditionContext);
  const quotaDisallowedOptions = contextValue.quotaDisallowedOptions || {};
  const platformName = contextValue.platformName || '';

  const createList = () => (
    map(
      question.answers,
      (v, k) => {
        const newHash = {
          answer: v,
          selected: question.condition[`${k}`] === conditionValues.selected,
          quota: question.quotas[`${k}`]
        };
        if (includes(quotaDisallowedOptions[question.title], v)) {
          newHash.disabled = true;
          newHash.quota = null;
        }
        return newHash;
      })
  );

  const convertOnSave = (list) => {
    const noItemsSelected = filter(
      list,
      (el) => !el.selected
    ).length === list.length;
    question.answers = {};
    question.condition = {};
    question.quotas = {};
    each(list, (item) => {
      const k = `${item.id + 1}`;
      question.answers[k] = item.answer;
      if (noItemsSelected) {
        item.selected = true;
      }

      if (item.selected) {
        question.condition[k] = conditionValues.selected;
        if (!item.disabled) {
          question.quotas[k] = `${item.quota || ''}`;
        }
      } else {
        question.condition[k] = conditionValues.notSelected;
      }
    });
  };

  return (
    <EditQuota
      createList={ createList }
      convertOnSave={ convertOnSave }
      platformName={ platformName }
      { ...props }
    />
  );
};

export default EditDemoQuota;
