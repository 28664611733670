import React, { useState, useContext } from 'react';

export const DataStorageContext = React.createContext({});

const cache = { plan: null, tvConnection: null };
const updateCache = (key, value) => { cache[key] = value; };
const getCache = (key) => cache[key];

const useCheckout = (isDataFilled, data, total, statistic, onNext) => {
  if (!isDataFilled) return () => {};
  const { fiosTVData, tvConnectionsData } = data;
  const hash = {
    ...statistic,
    total,
    planName: fiosTVData.title,
    planPrice: fiosTVData.price,
    tvConnectionsNum: tvConnectionsData.count,
    tvConnectionPrice: tvConnectionsData.price
  };
  return () => {
    (window.parent || window).postMessage({
      kind: 'finishVerizonTv',
      result: hash
    }, "*");
    onNext();
  }
};

const useStatistic = (initial) => {
  const [statistic, setStatistic] = useState(initial);

  const updateCounterOf = (key) => {
    setStatistic((prevValue) => ({...prevValue, [key]: prevValue[key] + 1 }) );
  };

  return [statistic, updateCounterOf];
};

export const useDataStorage = (onNext) => {
  const [statistic, updateCounterOf] = useStatistic({
    changePlan: 0,
    changeTvConnection: 0
  });
  const [fiosTVData, setFiosTVData] = useState(null);
  const updateFiosTVData = (data) => {
    const cachedPlan = getCache('plan');
    if (!!cachedPlan && cachedPlan !== data) updateCounterOf('changePlan');
    updateCache('plan', data);
    setTVConnectionsData(null);
    setFiosTVData(data);
  };
  const [tvConnectionsData, setTVConnectionsData] = useState(null);
  const updateTVConnectionsData = (data) => {
    const cachedTV = getCache('tvConnection');
    if (!!cachedTV && cachedTV !== data) updateCounterOf('changeTvConnection');
    updateCache('tvConnection', data);
    setTVConnectionsData(data);
  };

  const total = (fiosTVData ? fiosTVData.price : 0) +
    (tvConnectionsData ? tvConnectionsData.price : 0);

  const data = {
    fiosTVData,
    tvConnectionsData,
  };

  const isDataFilled = !!(fiosTVData && tvConnectionsData);

  const onCheckout = useCheckout(isDataFilled, data, total, statistic, onNext);
    
  return {
    total,
    data,
    onCheckout,
    isDataFilled,
    updateCounterOf,
    updateFiosTVData,
    updateTVConnectionsData,
  }
};

export const useTVConnections = (onNext) => {
  const { 
    data: { tvConnectionsData },
    updateTVConnectionsData
  } = useContext(DataStorageContext);
  const handleActiveChange = (data) => {
    updateTVConnectionsData(data)
    onNext();
  };

  const title = tvConnectionsData === null
    ? 'How many TV connections?'
    : `${tvConnectionsData.count} ${tvConnectionsData.count === 1 ? 'TV' : 'TVs'}`;
  
  const chipText = tvConnectionsData === null
    ? 'Required'
    : 'Added';

  const chipClassName = tvConnectionsData === null
    ? '-red'
    : '-green';

  return {
    activeConnection: tvConnectionsData,
    handleActiveChange,
    title,
    chipText,
    chipClassName,
  }
};

export const useFiosTV = (onNext) => {
  const { 
    data: { fiosTVData }, 
    updateFiosTVData
  } = useContext(DataStorageContext);
  const dataFilled = !!fiosTVData;
  const sectionTitle = dataFilled ? fiosTVData.title : 'More Fios TV';
  const chipTitle = dataFilled ? 'Added' : 'Required';
  const chipClassName = dataFilled ? '-green' : '-red';
  const handleChangePlan = (plan) => { 
    updateFiosTVData(plan);
    onNext();
  };

  return {
    plan: fiosTVData,
    sectionTitle,
    chipTitle,
    chipClassName,
    handleChangePlan
  }
};

const getInitialState = () => ({
  fiosTV: false,
  tvConnections: false,
});

export const useAccordionState = () => {
  const [open, setOpen] = useState(
    () => ({...getInitialState(), fiosTV: true})
  );

  const { data } = useContext(DataStorageContext);
  
  const handleOpen = (stepName) => { setOpen((prevState) => ({
    ...getInitialState(),
    [stepName]: true
  })) };

  return { open, handleOpen, data };
}