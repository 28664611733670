import React from 'react';
import cn from "classnames";
import { map, remove, filter } from "lodash";
import Wysiwyg from "../../../../common/components/wysiwyg";
import SvgIcon from "../../../../common/components/svg-icon";

const TextsList = ({ model, setModel }) => {
  const getEmptyStep = () => {
    return { id: '', notes: '', kind: 'text', errors: {} };
  };

  const handleOnChangeNotes = (newHtml, step) => {
    step.notes = newHtml;
    setModel({ ...model, steps: [ ...model.steps ] });
  };

  const addNewStep = () => {
    setModel({ ...model, steps: [ ...model.steps, getEmptyStep() ] });
  };

  const deleteStep = (step) => {
    if (step.id) {
      step._destroy = '1';
    } else {
      remove(model.steps, (el) => (el === step));
    }
    setModel({ ...model, steps: [ ...model.steps ] });
  };

  const filteredSteps = filter(model.steps, (step) => (!step._destroy && step.kind === 'text'));

  return (
    <>
      {!filteredSteps.length &&
        <div className="form_section">
          <button
            id="-js-add"
            className="button"
            onClick={ addNewStep }
          >
            Add Step
          </button>
        </div>}

      {map(filteredSteps, (step, index) => (
        <div className="form_section -flexible" key={ index }>
          <div className={ cn("form_grid-item -grow -js-process-step", { 'has-error': !!step.errors.notes }) }>
            <Wysiwyg
              customPicker
              toolbarClassName="wysiwyg-menu"
              editorClassName="wysiwyg-textarea"
              placeholder="Add Text"
              enableIndent
              value={ step.notes }
              onChange={ (newHtml) => {handleOnChangeNotes(newHtml, step);} }
            />
          </div>
          <div className="form_grid-item -two-icons">
            <button
              type="button"
              className="button -secondary -circle -delete-color form-question_grid-button -dark-grey -list-right-margin"
              onClick={ () => { deleteStep(step); } }
            >
              <SvgIcon name="i-delete" />
            </button>
            {index === filteredSteps.length - 1 &&
              <button
                type="button"
                className="button -circle form-question_grid-button"
                onClick={ addNewStep }
              >
                <SvgIcon name="i-add" />
              </button>
            }
          </div>
        </div>
      ))}
    </>
  );
};

export default TextsList;
