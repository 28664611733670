import React from "react";
import PulseExport from "../pulseExport";
import RespLevelDataExport from "../respLevelDataExport";

const DownloadPulseReport = ({ projectId, openEnds, projectDate }) => {
  return (
    <>
      <PulseExport
        projectId={ projectId }
        openEnds={ openEnds }
        projectDate={ projectDate }
      >
        Other Feedback
      </PulseExport>
      <PulseExport
        projectId={ projectId }
        projectDate={ projectDate }
      >
        Download Report (PPT)
      </PulseExport>
      <RespLevelDataExport
        projectId={ projectId }
        projectDate={ projectDate }
      >
        Download Report (XLSX)
      </RespLevelDataExport>
    </>
  );
};

export default DownloadPulseReport;
