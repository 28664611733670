import React, { useContext } from 'react';
import { StripeContext, LaunchContext } from '../../../contexts';

const StripeLaunchButton = ({ forceDisable }) => {
  const {
    loading, setLoading, setErrorMessage, textLaunch, sendData
  } = useContext(LaunchContext);
  const {
    stripeElementsError, stripeTokenError, setStripeTokenError,
    stripeInstance, cardNumberStripeObject, cardHolder, address, city, state, zipCode,
    paymentType
  } = useContext(StripeContext);

  const handleLaunchByCard = () => {
    setLoading(true);
    const tokenData = {
      name: cardHolder,
      address_line1: address,
      address_city: city,
      address_state: state,
      address_zip: zipCode
    };

    stripeInstance.createToken(cardNumberStripeObject, tokenData).then(
      (result) => {
        if (result.error) {
          setStripeTokenError(result.error.message);
          setErrorMessage(result.error.message);
          setLoading(false);
        } else {
          setStripeTokenError(null);
          sendData({ token: result.token, paymentType  });
        }
      }
    );
  };

  return (
    <button
      className="button"
      onClick={ handleLaunchByCard }
      disabled={ forceDisable || loading || !!stripeElementsError || !!stripeTokenError }
    >
      { textLaunch }
    </button>
  );
};

export default StripeLaunchButton;
