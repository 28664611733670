import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";
import { projectSetupForceMarketplaceClick } from "../../../common/google-analytics/events";

const ForceMarketPlace = ({ model, setModel, gaTrackingId, projectId }) => {
  const handleOnChange = (event) => {
    projectSetupForceMarketplaceClick(gaTrackingId, { project_id: projectId || "new" });
    setModel({ ...model, mpOption: event.target.checked });
  };

  return (
    <div className="form_section" >
      <div className="form_section-title -grid">
        <div className="form_section-title-item -grid">
          <div className="toggle">
            <input name="project[mp_option]" type="hidden" value="0" autoComplete="off" />
            <input
              className="hide"
              type="checkbox"
              checked={ model.mpOption }
              onChange={ handleOnChange }
              name="project[mp_option]"
              value="1"
              id="project_mp_option"
            />
            <label className="form-switch" htmlFor="project_mp_option">Mp option</label>
          </div>
          <h3>Force MarketPlace for Quotas</h3>
        </div>

        <div className="form_section-title-item">
          <div className="info-tooltip">
            <div className="info-tooltip_control">
              <SvgIcon name="i-info" />
            </div>

            <div className="info-tooltip_drop">
              <div className="info-tooltip_drop-inner">
                This is a strong test function for buzzback admins only,
                that allows system to ask Prodege about marketplace respondents.
                Recommended to use for other than USA and Canada countries.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForceMarketPlace;
