import React, { useEffect, useState, Fragment } from 'react';
import Modal from '../../../../common/components/modal.js';
import { cloneDeep, keys, each } from 'lodash';
import Filters from "./filters.js";

const initialFormData = () => { return { id: null, title: "", options: [] } };

const Tag = (props) => {
  const tag = props.tag,
    onDelete = props.onDelete,
    onTopSave = props.onSave,
    subgroups = props.subgroups;

  const [disabled, setDisabled] = useState(false);
  const [showModal, setOpenModal] = useState(false);
  const [tempForm, setTempForm] = useState(initialFormData());

  const onClose = (event) => {
    if (!!event) event.preventDefault();
    setTempForm(initialFormData());
    setOpenModal(false);
  };

  const onSave = (event, formData) => {
    let receivedTag = formData.currentTag || {};
    each(keys(initialFormData()), (key) => { receivedTag[key] = formData[key]; });
    onTopSave(event, receivedTag, onClose);
  };

  const saveIsDisabled = (formData) => {
    if (!!formData.title && !!formData.options.length) return;
    return "disabled";
  };

  const openModal = (event, tag) => {
    event.preventDefault();
    const formData = !!tag ? cloneDeep(tag) : initialFormData(),
          formDataForState = {...formData, edit: !!tag, currentTag: tag };

    setTempForm(formDataForState);
    setDisabled(saveIsDisabled(formDataForState));
    setOpenModal(true);
  };

  const onTitleUpdate = (event, formData) => {
    const formDataForState = { ...formData, title: event.target.value };
    setTempForm(formDataForState);
    setDisabled(saveIsDisabled(formDataForState));
  };

  const onFiltersUpdate = (event, formData) => {
    setDisabled(saveIsDisabled(formData));
  };

  return (
    <li className={ !!tag ? 'tag-item' : 'tag-item-null' }>
      {
        !!tag &&
        <Fragment>
          <span>{ tag.title }</span>
          <a className="edit-button" href="#" onClick={ (event) => { openModal(event, tag) } }>
            <svg className="icon-link_icon icon -i-comment">
              <use xlinkHref="#svg_i-comment" />
            </svg>
          </a>
          <a className="remove-button" href="#" onClick={ (event) => { onDelete(event, tag); } }>x</a>
        </Fragment>
      }
      {
        !tag &&
        <button onClick={ (event) => { openModal(event, null) } }>
          <svg className="icon-link_icon icon -i-plus-big">
            <use xlinkHref="#svg_i-plus-big" />
          </svg>
          Add Subgroup
        </button>
      }
      {
        showModal &&
        <Modal showCross={ true } onClose={ onClose } modal={ true }>
          <h3>{ tempForm.edit ? "Edit Subgroup" : "Add Subgroup" }</h3>
          <div className="title-section">
            <label>Subgroup Title</label>
            <input
              type="text"
              value={ tempForm.title }
              onChange={ (event) => { onTitleUpdate(event, tempForm) } }
              maxLength="255"
            />
          </div>

          <div className="options-section">
            <label>Subgroup Filters</label>
            <Filters
              subgroups={ subgroups }
              options={ tempForm.options }
              onUpdate={ (event) => { onFiltersUpdate(event, tempForm) } }
            />
          </div>

          <div className="normal-popup-buttons">
            <button
              className="button"
              type="button"
              onClick={ (event) => { onSave(event, tempForm) } }
              disabled={ disabled }
            >
              { tempForm.edit ? "Save" : "Create" }
            </button>
            <button
              className="button -secondary"
              type="button"
              onClick={ onClose }
            >
              Cancel
            </button>
          </div>
        </Modal>
      }
    </li>
  )
};

export default Tag;