import React from 'react';

const Parahraph = ({ paragraph }) => {
  const { title, text, icon, iconPath, iconClassName } = paragraph;

  return <div className="article-value-wrapper">
    {
      icon && <svg className={ `icon -${ icon } ${iconClassName}` }>
          <use xlinkHref={ `#svg_${ icon }` }/>
      </svg>
    }
    {
      iconPath && <img className={`icon ${iconClassName}`} src={iconPath} />
    }
    <h6 className="article-subtitle">
      {
        title.split('\n').map((value, idx) => <span key={idx} dangerouslySetInnerHTML={{__html: value}} />)
      }
    </h6>
    {
      text.split('\n').map((value, idx) => <p key={idx} className="article-value" dangerouslySetInnerHTML={{__html: value}}/>)
    }
  </div>
};

export default Parahraph;