import React, { useContext } from 'react';
import { map } from "lodash";
import { baseContext } from "../context";
import { keyTitle, uiTitle } from "../helpers";
import Metric from "./metric";

const Metrics = ({ title }) => {
  const { metricData, metricTitles, brandsAndConcepts } = useContext(baseContext);

  return (
    <div className="concepts-database_plate">
      <div className="concepts-database_plate-body">
        <div className="concepts-database_metrics_header">
          { title }
        </div>
        <div className="concepts-database_metrics_container">
          {
            map(metricTitles[title], (obj) => {
              const key = keyTitle(obj);
              return (
                <Metric
                  key={ `keyMetric-${key}` }
                  title={ uiTitle(obj) }
                  data={ metricData[key] }
                  counts={ brandsAndConcepts[key] }
                />
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Metrics;
