import React from 'react';

const LetsStart = () => {
  return (
    <div className="express-intro">
      <div className="express-intro_text">
        <h2 className="express-intro_title -ml">Let’s start</h2>
        <div className="express-intro_hint -ml">
          <p>
            This is a Multi-Language project that lets you have one or
            { ' ' }
            more similar projects targeted for one or
            { ' ' }
            many markets all in one place.
            { ' ' }
            Below is a list of available projects to create the base one.
            { ' ' }
            Project for the other markets will be available to choose
            { ' ' }
            from the "Settings & Languages" form.
          </p>
          <p>
            <b>
              Please take into account that project's concepts and
              { ' ' }
              questions are recommended to be shown in the
              { ' ' }
              language of selected country.
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LetsStart;
