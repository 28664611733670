import React, { useState, useContext } from 'react';
import { map } from 'lodash';
import Modal from '../../../../../common/components/modal';
import Http from '../../../../../common/http';
import CountrySelector from './country-selector';
import { IndexData } from './contexts';

const EditForm = ({
  projectNumber, title, categoryId, updatePath, categories,
  projects, countries,
  onClose
}) => {
  const [ loading, setLoading ] = useState(false);
  const [ stateProjectNumber, setProjectNumber ] = useState(projectNumber);
  const [ stateTitle, setTitle ] = useState(title);
  const [ stateCategoryId, setCategoryId ] = useState(categoryId);
  const [ errors, setErrors ] = useState({});
  const [ countryNames, setCountryNames ] = useState([]);
  const { loadProjects, buzzbackUser } = useContext(IndexData);

  const onCountryChange = (values) => {
    setCountryNames(values);
  };

  const handleContinue = () => {
    setLoading(true);

    Http.put(updatePath, {
      project: {
        platform_id: stateProjectNumber,
        title: stateTitle,
        product_category_id: stateCategoryId,
        country_names: countryNames
      }
    }).then(
      (http) => {
        if (http.response.status === 200) {
          loadProjects();
          setLoading(false);
          onClose();
        } else if (http.response.status === 422) {
          setErrors(http.data.errors);
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      }
    );
  };

  return (
    <Modal modalSize="sm" showCross onClose={ onClose }>
      <div className="modal_body">
        <h2>Settings & Languages ("{ title }")</h2>

        <div className="form_section">
          <div className="form_grid">
            {
              buzzbackUser &&
              <div className="form_grid-item -width-full">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Buzzback project number</h3>
                  </div>
                  <div className="form_section-title-item">
                    <div className="info-tooltip">
                      <div className="info-tooltip_control">
                        <svg className="icon -i-info">
                          <use xlinkHref="#svg_i-info" />
                        </svg>
                      </div>
                      <div className="info-tooltip_drop">
                        <div className="info-tooltip_drop-inner">
                          Enter the buzzback job number for this project for tracking purposes.
                          <br />
                          It will be applied to all sub-projects.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    maxLength={ 250 }
                    placeholder="Please name your project"
                    className="form-field -block"
                    type="text"
                    value={ stateProjectNumber }
                    onChange={ (e) => setProjectNumber(e.currentTarget.value) }
                  />
                  {
                    errors.platform_id && errors.platform_id.length > 0 &&
                    <div className="form-error">{ errors.platform_id.join('; ') }</div>
                  }
                </div>
              </div>
            }
          </div>

          <div className="form_grid">
            <div className="form_grid-item -width-full">
              <h3 className="form_section-title">Project Name</h3>
              <div className="form-group">
                <input
                  placeholder="Please name your project"
                  className="form-field -block"
                  type="text"
                  value={ stateTitle }
                  onChange={ (e) => setTitle(e.currentTarget.value) }
                />
                {
                  errors.title && errors.title.length > 0 &&
                  <div className="form-error">{ errors.title.join('; ') }</div>
                }
              </div>
            </div>
          </div>

          <div className="form_grid">
            <div className="form_grid-item -width-full">
              <div className="form_section-title -grid">
                <div className="form_section-title-item">
                  <h3>Project Category</h3>
                </div>
                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <svg className="icon -i-info">
                        <use xlinkHref="#svg_i-info" />
                      </svg>
                    </div>
                    <div className="info-tooltip_drop">
                      <div className="info-tooltip_drop-inner">The selected category will be the same for all sub-projects.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <select
                  className="form-field -block"
                  value={ stateCategoryId }
                  onChange={ (e) => setCategoryId(e.currentTarget.value) }
                >
                  <option value="">Please select one</option>
                  {
                    map(categories, (pc) => (
                      <option key={ `Category${pc.id}` } value={ pc.id }>
                        { pc.name }
                      </option>
                    ))
                  }
                </select>
                {
                  errors.product_category_id && errors.product_category_id.length > 0 &&
                  <div className="form-error">{ errors.product_category_id.join('; ') }</div>
                }
              </div>
            </div>
          </div>
          <CountrySelector
            projects={ projects }
            countries={ countries }
            onChange={ onCountryChange }
          />
        </div>
      </div>
      <div className="modal_footer -ml">
        <button className="button modal_button -darkest-grey" onClick={ onClose }>
          Cancel
        </button>
        <button className="button modal_button" disabled={ loading } onClick={ handleContinue }>
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default EditForm;
