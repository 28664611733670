import React, { Fragment } from 'react';

const HiddenFields = ({ data, options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ options.generate } />
      <input type="hidden" name="express" id="express" value={ data.express } />
      <input type="hidden" name="project[concepts_mode]" id="project_concepts_mode" value={ model.conceptsMode } />
      <input type="hidden" name="project[verizon_express_setting][questions_shown]" id="project_questions_shown" value={ model.questionsShown } />
      <input type="hidden" name="project[verizon_express_setting][concept_type]" id="project_concept_type" value={ model.conceptType } />
      <input type="hidden" name="project[verizon_express_setting][action_word]" id="project_action_word" value={ model.actionWord } />
      <input type="hidden" name="project[verizon_express_setting][priced]" id="project_priced" value={ model.priced } />
      <input type="hidden" name="project[verizon_express_setting][pay_frequency]" id="project_pay_frequency" value={ model.payFrequency } />
      <input type="hidden" name="project[verizon_express_setting][cit]" id="project_cit" value={ model.cit } />
      <input type="hidden" name="project[verizon_express_setting][process_description]" id="project_process_description" value={ model.processDescription } />
      <input type="hidden" name="project[verizon_express_setting][fee_and_policy]" id="project_fee_and_policy" value={ model.feeAndPolicy } />
      <input type="hidden" name="project[verizon_express_setting][ask_awareness]" id="project_ask_awareness" value={ model.askAwareness } />
      <input type="hidden" name="project[verizon_express_setting][existing_awareness]" id="project_existing_awareness" value={ model.existingAwareness } />
      <input type="hidden" name="project[verizon_express_setting][awareness_verb]" id="project_awareness_verb" value={ model.awarenessVerb } />
      <input type="hidden" name="project[verizon_express_setting][awareness_estimate]" id="project_awareness_estimate" value={ model.awarenessEstimate } />
      {model.expressImages.map((image, index) => (
        <Fragment key={ index }>
          <input type="hidden" name="project[express_images][][id]" id="project_express_images_id" value={ image.id || "" } />
          <input type="hidden" name="project[express_images][][name]" id="project_express_images_name" value={ image.name || "" } />
          <input type="hidden" name="project[express_images][][image_data]" id="project_express_images_image_data" value={ image.image_data || "" } />
          <input type="hidden" name="project[express_images][][source_data]" id="project_express_images_source_data" value={ image.source_data || "" } />
          <input type="hidden" name="project[express_images][][s3_folder]" id="project_express_images_s3_folder" value={ image.s3_folder || "" } />
          <input type="hidden" name="project[express_images][][base_css_file_name]" id="project_express_images_base_css_file_name" value={ image.base_css_file_name || "" } />
          <input type="hidden" name="project[express_images][][unique_css_file_name]" id="project_express_images_unique_css_file_name" value={ image.unique_css_file_name || "" } />
          <input type="hidden" name="project[express_images][][html_file_name]" id="project_express_images_html_file_name" value={ image.html_file_name || "" } />
          <input type="hidden" name="project[express_images][][pdf_with_text]" id="project_express_images_pdf_with_text" value={ image.pdf_with_text || 'false'  } />
          <input type="hidden" name="project[express_images][][prefix]" id="project_express_images_prefix" value={ image.prefix || "" } />
          <input type="hidden" name="project[express_images][][notes]" id="project_express_images_notes" value={ image.notes || "" } />
          <input type="hidden" name="project[express_images][][_destroy]" id="project_express_images_destroy" value={ image._destroy || "" } />
        </Fragment>
      ))}
    </>
  );
};

export default HiddenFields;
