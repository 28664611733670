import React from 'react';
import { map } from "lodash";

const HiddenFields = ({ options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="project[diagnostics_num]" id="project_diagnostics_num" value={ model.diagnosticsNum || '' } />
      <input type="hidden" name="project[cf_enabled]" id="project_cf_enabled" value={ model.cfEnabled || 'false' } />
      <input type="hidden" name="project[tb_enabled]" id="project_tb_enabled" value={ model.tbEnabled || 'false' } />
      <input type="hidden" name="project[survey_descriptor]" id="project_survey_descriptor" value={ model.surveyDescriptor || '' } />
      <input type="hidden" name="project[brand_name]" id="project_brand_name" value={ model.brandName || '' } />
      {map(model.surveyDescriptorSettings, (value, name) => {
        return (
          <input key={ name } type="hidden" name={ `project[survey_descriptor_settings][${name}]` } value={ value } />
        );
      })}
    </>
  );
};

export default HiddenFields;
