import React, { useState } from 'react';
import TextField from "../../common/controls/textField";
import PasswordField from "../../common/controls/passwordField";
import CheckboxField from "../../common/controls/checkboxField";
import Layout from "../layout";
import { signInRequest } from '../api';

const LinkForgot = ({ onForgotClick }) => {
  const handleForgotClick = (event) => {
    event.preventDefault();
    onForgotClick(event);
  };
  return (
    <a
      href="#"
      className="form-link -with-label"
      onClick={ handleForgotClick }
    >
      Forgot my password
    </a>
  );
};

const SignIn = ({ onForgotClick }) => {
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ remember, setRemember ] = useState('');

  const handleLogin = () => {
    setLoading(true);
    setError(false);
    signInRequest({
      email,
      password,
      remember_me: remember
    }, (data, ok) => {
      setLoading(false);
      if (ok) {
        window.location.href = data.redirect_url;
      } else {
        setError(true);
      }
    });
  };

  return (
    <Layout>
      <div className="auth-box_form">
        {
          error &&
          <div className="portal-error">
            <h3>Invalid email or password</h3>
          </div>
        }
        <TextField
          label="Email or Username"
          value={ email }
          onChange={ setEmail }
        />
        <PasswordField
          label="Password"
          value={ password }
          onChange={ setPassword }
          addToLabel={ <LinkForgot onForgotClick={ onForgotClick } /> }
        />
        <div className="form-buttons">
          <div className="form-grid">
            <div className="form-grid_item">
              <button
                className="button"
                onClick={ handleLogin }
                disabled={ loading }
              >
                Sign In
              </button>
            </div>
            <div className="form-grid_item">
              <CheckboxField
                label="Remember Me"
                classNameLabel="-upper -right"
                checked={ remember }
                onChange={ setRemember }
                disabled={ loading }
              />
            </div>
          </div>
        </div>
        {/*<div className="form-help">*/}
        {/*  <div className="form-help_grid">*/}
        {/*    <div className="form-help_grid-item -icon">*/}
        {/*      <svg className="icon form-help_icon">*/}
        {/*        <use xlinkHref={ `#svg_alert` } />*/}
        {/*      </svg>*/}
        {/*    </div>*/}
        {/*    <div className="form-help_grid-item">*/}
        {/*      <div className="form-help_text">*/}
        {/*        Automatic login will be enabled in this browser.*/}
        {/*        { ' ' }*/}
        {/*        Logout to remove the "Remember Me" browser cookie at any time.*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Layout>
  );
};

export default SignIn;
