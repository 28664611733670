import { filter } from "lodash";

const selectStylesMulti = () => (
  {
    control: (styles) => ({ ...styles, borderRadius: 20, fontSize: "13px" }),
    option: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93" }),
    menu: (provided) => ({ ...provided, fontSize: "13px", zIndex: 9999, color: "#787F93" }),
    multiValue: (styles, { data }) => (
      {
        ...styles, fontSize: "13px", borderRadius: 70, color: "#787F93",
        background: data.value === 0 ? "#FFF" : "#EFF4F5",
        paddingRight: data.value === 0 ? "5px" : 0
      }
    ),
    multiValueLabel: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93", whiteSpace: "normal" }),
    valueContainer: (styles) => ({ ...styles, fontSize: "13px", paddingLeft: "5px" }),
    multiValueRemove: (styles, state) => {
      return state.data.value === 0 ? { ...styles, display: 'none' } : styles;
    }
  }
);

const selectStylesSingle = () => (
  {
    control: (styles) => ({ ...styles, borderRadius: 20, fontSize: "13px" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "13px",
        color: "#787F93",
        backgroundColor: isSelected ? "#DADADA" : '#fff'
      };
    },
    menu: (provided) => ({ ...provided, fontSize: "13px", zIndex: 9999, color: "#787F93" }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
    valueContainer: (styles) => ({ ...styles, fontSize: "13px", paddingLeft: "5px" })
  }
);

const keyTitle = (obj) => (typeof obj === 'object' ? obj.key_title : obj);
const uiTitle = (obj) => (typeof obj === 'object' ? obj.ui_title : obj);

const categoryFirstLayerTags = (tagsList) => filter(tagsList, (tag) => tag.tag_type === 1 && !tag.parent_id);
const categorySecondLayerTags = (tagsList) => filter(tagsList, (tag) => tag.parent_id && tag.tag_type === 1);
const brandTags = (tagsList) => filter(tagsList, (tag) => tag.tag_type === 2);
const customTags = (tagsList) => filter(tagsList, (tag) => tag.tag_type === 3);

export {
  selectStylesMulti, selectStylesSingle, keyTitle, uiTitle,
  categoryFirstLayerTags, categorySecondLayerTags, brandTags, customTags
};
