import React from 'react';
import ConceptBAU from "./concept_bau";
import { SetContext } from './common/contexts';

// conceptName = 'BAU'
const App = ({ conceptName, mobile }) => {
  if (mobile) {
    switch (conceptName) {
      case 'BAU': return <ConceptBAU />;
      default: return null;
    }
  } else {
    switch (conceptName) {
      case 'BAU': return <ConceptBAU />;
      default: return null;
    }
  }
};

const AppProvider = (params) => {
  const contextValue = Object.freeze({
    delayAnimation: 500,
    mobile: params.mobile,
    buildYourOwnFirst: params.buildFirst,
    taskId: params.taskId
  });

  return (
    <SetContext.Provider value={ contextValue }>
      <App { ...params } />
    </SetContext.Provider>
  );
};

export default AppProvider;
