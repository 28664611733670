import React from 'react';
import { map } from "lodash";
import Post from "./post";

const Replies = ({ post, openReplies }) => {
  return (
    openReplies &&
    <div className="replies">
      {
        map(post.replies, (reply) => (
          <Post key={ reply.id } post={ reply } isReply />
        ))
      }
    </div>
  );
};

export default Replies;
