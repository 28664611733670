import React, { createContext, useState } from "react";
import { findIndex, sortBy, forEach, filter, map } from 'lodash';
import Http from "../../../common/http";

const DataContext = createContext({});

const WithDataContext = ({ data, children }) => {
  const [ categoryData, setCategoryData ] = useState(data.express_category);
  const [ brandData, setBrandData ] = useState(data.express_brand);
  const [ customData, setCustomData ] = useState(data.express_custom);
  const [ modalInfo, setModalInfo ] = useState({});
  const [ modalLoading, setModalLoading ] = useState(false);
  const [ conceptImages, setConceptImages ] = useState([]);

  const addTag = (tagType, productCategoryId, parentId, text, callback) => {
    Http.post('/express/api/tags',
      {
        tag_type: tagType,
        product_category_id: productCategoryId,
        parent_id: parentId,
        tag: text
      }
    ).then((http) => {
      if (http.response.status === 200) {
        if (tagType === 'express_category') {
          const pcIndex = findIndex(categoryData, (el) => (el.product_category_id === productCategoryId));

          if (pcIndex > -1) {
            const categoryTags = categoryData[pcIndex].tags;

            if (!parentId) {
              categoryTags.push(http.data);
              categoryData[pcIndex].tags = sortBy(categoryTags, (el) => el.label);
            } else {
              const parentIndex = findIndex(categoryTags, (el) => (el.value === parentId));
              if (parentIndex > -1) {
                const childrenTags = categoryTags[parentIndex].children;
                childrenTags.push(http.data);
                categoryTags[parentIndex].children = sortBy(childrenTags, (el) => el.label);
              }
            }
            setCategoryData([ ...categoryData ]);
          }
        }

        if (tagType === 'express_brand') {
          brandData.push(http.data);
          const newBrandData = sortBy(brandData, (el) => el.label);
          setBrandData([ ...newBrandData ]);
        }

        if (tagType === 'express_custom') {
          customData.push(http.data);
          const newCustomData = sortBy(customData, (el) => el.label);
          setCustomData([ ...newCustomData ]);
        }

        if (callback) {
          callback(http.data);
        }
      }
    },
    (reject) => {
      console.log('addTag: something went wrong', reject);
    });
  };

  const deleteTag = (tagType, id, callback) => {
    Http.del(
      `/express/api/tags/${id}`,
    ).then((http) => {
      if (http.response.status === 200) {
        if (tagType === 'express_category') {
          forEach(categoryData, (productCategory) => {
            productCategory.tags = filter(productCategory.tags, (tagObj) => tagObj.value !== id);
            forEach(productCategory.tags, (tag) => {
              tag.children = filter(tag.children, (tagObj) => tagObj.value !== id);
            });
          });
          setCategoryData([ ...categoryData ]);
        }

        if (tagType === 'express_brand') {
          const newBrandData = filter(brandData, (tagObj) => tagObj.value !== id);
          setBrandData([ ...newBrandData ]);
        }

        if (tagType === 'express_custom') {
          const newCustomData = filter(customData, (tagObj) => tagObj.value !== id);
          setCustomData([ ...newCustomData ]);
        }

        if (callback) {
          callback(http.data);
        }
      }
    },
    (reject) => {
      console.log('deleteTag: something went wrong', reject);
    });
  };

  const updateTag = (tagType, id, text, callback) => {
    Http.put(
      `/express/api/tags/${id}`,
      {
        tag: text
      }
    ).then((http) => {
      if (http.response.status === 200) {
        if (tagType === 'express_category') {
          forEach(categoryData, (productCategory) => {
            forEach(productCategory.tags, (parentTag) => {
              if (parentTag.value === id) {
                parentTag.label = text;
              }
              // eslint-disable-next-line lodash/prefer-filter
              forEach(parentTag.children, (childTag) => {
                if (childTag.value === id) {
                  childTag.label = text;
                }
              });
              parentTag.children = sortBy(parentTag.children, (el) => el.label);
            });
            productCategory.tags = sortBy(productCategory.tags, (el) => el.label);
          });
          setCategoryData([ ...categoryData ]);
        }

        if (tagType === 'express_brand') {
          // eslint-disable-next-line lodash/prefer-filter
          forEach(brandData, (tagObj) => {
            if (tagObj.value === id) {
              tagObj.label = text;
            }
          });
          const sortedBrandData = sortBy(brandData, (el) => el.label);
          setBrandData([ ...sortedBrandData ]);
        }

        if (tagType === 'express_custom') {
          // eslint-disable-next-line lodash/prefer-filter
          forEach(customData, (tagObj) => {
            if (tagObj.value === id) {
              tagObj.label = text;
            }
          });
          const sortedCustomData = sortBy(customData, (el) => el.label);
          setCustomData([ ...sortedCustomData ]);
        }

        if (callback) {
          callback(http.data);
        }
      }
    },
    (reject) => {
      console.log('updateTag: something went wrong', reject);
    });
  };

  const showModal = (info) => {
    if (info.id !== modalInfo.id || info.type !== modalInfo.type) {
      setModalInfo(info);
      setModalLoading(true);
      let params = map(info.kinds || [], (kind) => (
        `kinds[]=${kind}`
      ));
      if (info.type) {
        params.push(`type=${info.type}`);
      }
      if (params) {
        params = `?${params.join('&')}`;
      }

      Http.get(
        `/express/api/tags/${info.id}/concepts${params}`,
      ).then((http) => {
        if (http.response.status === 200) {
          setConceptImages(http.data);
          setModalLoading(false);
        }
      },
      (reject) => {
        console.log('showModal: something went wrong', reject);
      });
    }
  };

  const hideModal = () => {
    setModalInfo({});
  };

  const clearImageTag = (conceptId, tagId, callback) => {
    const url = `/express/api/tags/${tagId}/clear`;
    Http.post(
      url, { concept_id: conceptId }
    ).then((http) => {
      if (http.response.status === 200) {
        const newConceptImages = filter(conceptImages, (el) => (el.concept_id !== conceptId));
        setConceptImages(newConceptImages);

        let found = false;

        forEach(categoryData, (productCategory) => {
          forEach(productCategory.tags, (parentTag) => {
            if (parentTag.value === tagId) {
              parentTag.count = http.data.count;
              parentTag.children =  http.data.children;
              found = true;
            }

            // eslint-disable-next-line lodash/prefer-filter
            forEach(parentTag.children, (childTag) => {
              if (childTag.value === tagId) {
                childTag.count = http.data.count;
                found = true;
              }
            });
          });
        });

        if (found) {
          setCategoryData([ ...categoryData ]);
        }

        if (!found) {
          // eslint-disable-next-line lodash/prefer-filter
          forEach(brandData, (tagObj) => {
            if (tagObj.value === tagId) {
              tagObj.count = http.data.count;
              found = true;
            }
          });
          if (found) {
            setBrandData([ ...brandData ]);
          }
        }

        if (!found) {
          // eslint-disable-next-line lodash/prefer-filter
          forEach(customData, (tagObj) => {
            if (tagObj.value === tagId) {
              tagObj.count = http.data.count;
              found = true;
            }
          });
          if (found) {
            setCustomData([ ...customData ]);
          }
        }

        if (callback) {
          callback(http.data);
        }
      }
    },
    (reject) => {
      console.log('addTag: something went wrong', reject);
    });
  };

  const dataValue = {
    categoryData,
    brandData,
    customData,
    addTag,
    deleteTag,
    updateTag,
    showModal,
    hideModal,
    modalInfo,
    modalLoading,
    conceptImages,
    clearImageTag
  };

  return (
    <DataContext.Provider value={ dataValue }>
      { children }
    </DataContext.Provider>
  );
};

export { WithDataContext, DataContext };
