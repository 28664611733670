import React from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import { calculateNewNSize } from './helper';

const NSizeIncreaseText = ({ className, nSize, completeTarget, completeTargetData }) => {
  const exactQuotaQuestions = completeTargetData.exact_quota_questions;
  const availableByUser = completeTargetData.available_by_user;
  const newNSize = calculateNewNSize(completeTarget, completeTargetData, nSize);
  const changedNSize = newNSize !== nSize;
  const exactQuotaPresent = !!exactQuotaQuestions.length;
  const showMessage = availableByUser && (changedNSize || exactQuotaPresent);
  return (
    <>
      {
        showMessage &&
        <div className={ classnames("selected-number-message -width-500", className) }>
          {
            changedNSize &&
            <>
              Note: for the Panel Service manual setup,
              {' '}
              we recommend to set total N = {newNSize}.
            </>
          }
          {
            exactQuotaPresent &&
            <>
              { changedNSize && <br /> }
              Note: project will not close unless reaching the minimal quota N size for
              {' '}
              the following questions:
              <ul>
                {
                  map(exactQuotaQuestions, (item, index) => (
                    <li key={ index }>{ item }</li>
                  ))
                }
              </ul>
            </>
          }
        </div>
      }
    </>
  );
};

export default NSizeIncreaseText;
