import React, { useContext } from 'react';
import { find, filter, findIndex } from 'lodash';
import SvgIcon from "../../../../../common/components/svg-icon";
import { baseContext } from "../../context";
import { updateParams } from '../../requests';
import RadioFilter from "./radio-filter";
import MultiSelectFilter from "./multi-select-filter";
import ExpressTags from "./express-tags";
import TextInput from "./text-input";

const ConceptsFilters = ({ onClose }) => {
  const {
    consts, data, paramsId, setData, setError, setTableLoading, itemsName
  } = useContext(baseContext);

  const incidenceRangeSelected = filter(consts.incidence_ranges, (range) => {
    return findIndex(data.concept_filters.incidence_ranges, (key) => {
      return key === range.value;
    }) > -1;
  });

  const yearsSelected = filter(data.years, (year) => {
    return findIndex(data.concept_filters.years, (key) => {
      return key === year.value;
    }) > -1;
  });

  const currentPriced = find(consts.priced, (el) => el.id === data.concept_filters.priced) || {};
  const currentInternal = find(consts.client_vs_internal, (el) => el.id === data.concept_filters.client_vs_internal) || {};
  const currentStimuliType = find(consts.stimuli_types, (el) => el.id === data.concept_filters.stimuli_type) || {};
  const currentProductType = find(consts.product_types, (el) => el.id === data.concept_filters.product_type) || {};
  const currentTarget = find(consts.targets, (el) => el.id === data.concept_filters.target) || {};

  const handleUpdateFilter = (params) => {
    setTableLoading(true);
    updateParams(paramsId, params, setData, setError);
  };

  return (
    <div className="concepts-database_filters">
      <div className="close-icon" onClick={ onClose }>
        <SvgIcon name="i-close" />
      </div>

      <h3>{itemsName} Filter</h3>

      <ul className="concept-tags_prop-list">

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.priced}
          </div>
          <div className="concept-tags_select_container -grow">
            <RadioFilter
              attrName="priced"
              labelClass="-width-1-2"
              prefix="priced"
              options={ consts.priced }
              currentValue={ currentPriced }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.client_vs_internal}
          </div>
          <div className="concept-tags_select_container -grow">
            <RadioFilter
              attrName="client_vs_internal"
              labelClass="-width-1-2"
              options={ consts.client_vs_internal }
              currentValue={ currentInternal }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.stimuli_type}
          </div>
          <div className="concept-tags_select_container -grow">
            <RadioFilter
              attrName="stimuli_type"
              labelClass="-width-1-3"
              options={ consts.stimuli_types }
              currentValue={ currentStimuliType }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.product_type}
          </div>
          <div className="concept-tags_select_container -grow">
            <RadioFilter
              attrName="product_type"
              labelClass="-width-1-4"
              options={ consts.product_types }
              currentValue={ currentProductType }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.target}
          </div>
          <div className="concept-tags_select_container -grow">
            <RadioFilter
              attrName="target"
              labelClass="-width-1-2"
              options={ consts.targets }
              currentValue={ currentTarget }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.incidence_ranges}
          </div>
          <div className="concept-tags_select_container -grow">
            <MultiSelectFilter
              attrName="incidence_ranges"
              options={ consts.incidence_ranges }
              selectedValues={ incidenceRangeSelected }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <ExpressTags onUpdate={ handleUpdateFilter } />

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.years}
          </div>
          <div className="concept-tags_select_container -grow">
            <MultiSelectFilter
              attrName="years"
              options={ data.years }
              selectedValues={ yearsSelected }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.concept_name}
          </div>
          <div className="concept-tags_select_container -grow">
            <TextInput
              attrName="concept_name"
              currentValue={ data.concept_filters.concept_name }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            {consts.filter_titles.notes}
          </div>
          <div className="concept-tags_select_container -grow">
            <TextInput
              attrName="notes"
              currentValue={ data.concept_filters.notes }
              onUpdate={ handleUpdateFilter }
            />
          </div>
        </li>

      </ul>
    </div>
  );
};

export default ConceptsFilters;
