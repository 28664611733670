import { values, find, map, findIndex, includes } from 'lodash';
import { baseFakeData } from '../common/baseFakeData';
import {
  allPossibleSteps, homeScreenSteps, allPossibleSeenScreens, incentiveScreens, findStepName
} from '../common/helpers/stepsHelper';

const initialData = () => ({
  startTime: Date.now(),
  fullSetup: false,
  closeAppClick: false,
  interruptAppNoClick: false,
  interruptAppYesClick: false,
  welcomeSkipClick: false,
  email: '',
  phone: '',
  phoneResendClick: false,
  phoneClosedConfirmationPopup: false,
  phoneBackClick: false,
  passkeyBackClick: false,
  passkeySkip: false,
  passkeyHowItWorksClick: false,
  passkeyCloseSignInPopup: false,
  passwordBackClick: false,
  passwordSaveAllow: false,
  firstName: '',
  lastName: '',
  manualAddress: '',
  manualCity: '',
  manualZipCode: '',
  personalInfoManualAddressClick: false,
  personalInfoOpenTerms: false,
  personalInfoBackClick: false,
  faceIdShown: false,
  faceIdAllow: false,
  introSkip: false,
  introOpenTerms: false,
  introBonusTermsClick: false,
  benefitsShown: false,
  benefitsSkip: false,
  benefitsOpenTerms: false,
  intentCategoriesSkip: false,
  intentAddCardSkip: false,
  intentCardScanClick: false,
  cashbackCategoriesSkip: false,
  cashbackCategoriesValidationShown: false,
  sendNotificationsShown: false,
  sendNotificationsSkip: false,
  baseNotificationsShown: false,
  baseNotificationsAllow: false,
  contactSyncShown: false,
  contactSyncSkip: false,
  accessContactsShown: false,
  accessContactsAllow: false,
  idCheckReviewTermsShown: false,
  idCheckAgreeButtonClick: false,
  idCheckScrollToBottomClick: false,
  idCheckReviewTermsCloseClick: false,
  date: '',
  idCheckSkip: false,
  idCheckOpenSkipModal: false,
  idCheckBackClick: false,
  idCheckShowTerms: false,
  cashbackCategorySkip: false,
  autoReloadCashbackNextClick: false,
  autoReloadCashbackCloseClick: false,
  autoReloadTermsApply: false,
  autoReloadAddCardClick: false,
  oneTimeAddCardClick: false,
  oneTimeFormClick: false,
  autoReloadFormClick: false,
  autoReloadCardScanClick: false,
  oneTimeCardScanClick: false,
  autoReloadAutomaticallyAddEdit: false,
  autoReloadBalanceEdit: false,
  oneTimeAmountEdit: false,
  oneTimePredefinedValueClick: false,
  autoReload: false,
  oneTimeSend: false,
  autoReloadSkipSetupCard: false,
  oneTimeSkipSetupCard: false,
  cardToWalletStart: false,
  cardToWalletSkip: false,
  cardToWalletUseDefault: false,
  cardIssued: false,
  cartToWalletNativeSkip: false,
  pinCreationSkip: false,
  homeScreenShown: false,
  autoReloadSeen: false,
  oneTimeSeen: false,
  seenScreens: []
});

const finishingData = (existingData) => {
  const hash = {
    ...existingData,
    totalTime: Math.floor((Date.now() - existingData.startTime) / 1000),
    intentCategoriesIds: map(existingData.intentCategories || [], (intentCategoryName) => {
      const foundEl = find(
        baseFakeData.interestsList, (el) => (el.id === intentCategoryName)
      );
      return foundEl?.innerIndex;
    }),
    cashbackCategoriesIds: map(existingData.cashbackCategories || [], (cashbackCategoryName) => {
      const foundEl = find(
        baseFakeData.cashBackCategoriesList, (el) => (el.id === cashbackCategoryName)
      );
      return foundEl?.innerIndex;
    }),
    cashbackCategoryId: find(
      baseFakeData.cashBackCategoriesListShort,
      (el) => (el.id === existingData.cashbackCategory)
    )?.innerIndex
  };

  if (existingData.interruptAppTopStep) {
    hash.interruptedStepId = findIndex(
      allPossibleSteps,
      (stepEl) => {
        let generatedId = findStepName(
          existingData.interruptAppTopStep, existingData.interruptAppInnerStep
        );
        if (existingData.interruptAppTopStep === 'ppe_autoreload') {
          generatedId = findStepName(
            generatedId, existingData.oneTimeSelected ? 'oneTime' : 'autoReload'
          );
        }
        return stepEl === generatedId;
      }
    ) + 1;
  } else {
    hash.interruptedStepId = null;
  }

  if (existingData.manualState) {
    existingData.manualStateId = findIndex(baseFakeData.usStates, (el) => (
      el.value === existingData.manualState
    )) + 1;
  } else {
    existingData.manualStateId = null;
  }

  if (existingData.address) {
    hash.address = values(existingData.address).join(', ');
  } else {
    hash.address = '';
  }
  if (existingData.billingAddress) {
    hash.billingAddress = values(existingData.billingAddress).join(', ');
  } else {
    hash.billingAddress = '';
  }

  if (existingData.homeScreenFrom) {
    hash.homeScreenFromId = findIndex(
      homeScreenSteps, (stepEl) => (stepEl === existingData.homeScreenFrom)
    ) + 1;
  } else {
    hash.homeScreenFromId = null;
  }

  hash.seenScreensIds = map(
    existingData.seenScreens,
    (screenName) => (
      findIndex(allPossibleSeenScreens, (el) => el === screenName) + 1
    )
  );
  hash.seenIncentiveScreens = !!find(
    incentiveScreens, (el) => includes(existingData.seenScreens, el)
  );

  return hash;
};

const sendData = (customData = {}) => {
  const postData = {
    result: customData || {},
    kind: 'finishStimuli'
  };

  (window.parent || window).postMessage(postData, "*");
};

export { initialData, finishingData, sendData };
