export const noPlan = {
  id: 0,
  name: '',
  concept: '',
  price: 0,
  bundle: false,
  options: []
};

const noCommitment = {
  id: 1,
  name: 'Paid in advance, no commitment',
  concept: 'BASE',
  price: 25,
  comment: 'when you take advantage of Party Pay',
  bundle: false,
  options:
    [
      'One line of service',
      {
        title: 'Powered by Verizon, 5G included',
        description: 'Service on Verizon’s 4G LTE, 5G Nationwide, or Ultra Wideband Networks. 5G capped at 200 Mbps. Requires 5G-capable device inside 5G coverage area. 5G Ultra Wideband available in parts of select cities. 5G Nationwide available in 2,700+ cities.'
      },
      'Unlimited minutes and messages',
      {
        title: 'Unlimited data',
        description: 'In times of traffic, your data may be temporarily slower than other traffic.'
      },
      {
        title: 'All the hotspot usage you can handle',
        description: 'Mobile hotspot with unlimited data at speeds up to 5 Mbps'
      },
      'Calling & texting to Canada & Mexico'
    ]
};

const basePlan = {
  id: 2,
  name: 'Base Plan',
  concept: 'A',
  price: 30,
  bundle: false,
  options:
    [ 'One line of service',
      'Unlimited data, messages and minutes',
      {
        title: 'Verizon’s 5G Nationwide / 4G LTE network',
        description: '<p>Unlimited access to Verizon’s 5G Nationwide network. When combined with 5G Ultra Wideband,' +
          'you’ll get our best performance.</p><p>5G Nationwide available in 2,700+ cities. Requires a 5G-capable device inside the 5G Nationwide coverage area.</p>'
      },
      {
        title: 'Unlimited mobile wifi hotspot',
        description: 'Mobile hotspot with unlimited data at speeds up to 5 Mbps.'
      },
      {
        title: 'Unlimited calling and texting to Canada and Mexico',
        description: 'Unlimited talk and text from the US to Canada, Mexico, the US Virgin Islands, and Puerto Rico.'
      },
      'Spam protection'
    ]
};

const premiumPlan = {
  id: 3,
  name: 'Premium Plan',
  concept: 'A',
  price: 45,
  bundle: false,
  options:
    [ 'One line of service',
      'Unlimited data, messages and minutes',
      {
        title: 'Verizon’s 5G Nationwide / 4G LTE network',
        description: '<p>Unlimited access to Verizon’s 5G Nationwide network. When combined with 5G Ultra Wideband,' +
          'you’ll get our best performance.</p><p>5G Nationwide available in 2,700+ cities. Requires a 5G-capable device inside the 5G Nationwide coverage area.</p>'
      },
      {
        title: 'Unlimited mobile wifi hotspot',
        description: 'Mobile hotspot with unlimited data at speeds up to 5 Mbps.'
      },
      {
        title: 'Unlimited calling and texting to Canada and Mexico',
        description: 'Unlimited talk and text from the US to Canada, Mexico, the US Virgin Islands, and Puerto Rico.'
      },
      'Spam protection'
    ],
  additionOptions: [
    {
      title: 'Verizon’s 5G Ultra Wideband network',
      description: '<p>5G Ultra Wideband is the fastest 5G network access we offer. Download apps, games, entire playlists and TV series in seconds.</p>' +
        '<p>5G Ultra Wideband available in select areas and access requires a 5G Ultra Wideband-capable device inside the 5G Ultra Wideband coverage area.</p>'
    },
    {
      title: 'Premium network access',
      description: 'Get 50 GB of 5G / 4G LTE premium data per month. In times of congestion, your smartphone and mobile hotspot data may be temporarily slower than other traffic after exceeding 50 GB/mo.'
    },
    {
      title: 'Unlimited calling to 30+ countries and texting to 200+ countries',
      description: {
        textBefore: [
          'Unlimited texting from the US to 200+ countries.'
        ],
        textWithLink: {
          textBefore: 'Unlimited calling from the US to 30+ countries. ',
          linkName: 'Click here',
          textAfter: ' for detailed country list.'
        },
        hiddenText: [
          'International Talk Countries: Aland Islands, Austria, Belgium, Bermuda, Bhutan, Brazil, Brunei, Canada, Chatham Island, China, Denmark, England, Finland, France, Germany, Guam, Guernsey, Hong Kong, Hungary, Isle of Man, Israel, Jersey, South Korea, Malaysia, Martinique, Mexico, Mongolia, Netherlands, New Zealand, Northern Ireland, Northern Mariana Islands, Reunion, Romania, Saipan, Scotland, Singapore, Spain, Sweden, Thailand, United Kingdom, Wales'
        ]
      }
    },
    {
      title: 'Talk, text and data to and from Mexico and Canada',
      description: 'Data speeds are reduced to 2G after 0.5 GB/day.'
    }
  ]
};

const basicPlan = {
  id: 4,
  name: 'Base Plan',
  concept: 'B',
  price: 30,
  bundle: false,
  options:
    [ 'One line of service',
      'Unlimited data, messages and minutes',
      {
        title: 'Verizon’s 5G Nationwide / 4G LTE network',
        description: '<p>Unlimited access to Verizon’s 5G Nationwide network. When combined with 5G Ultra Wideband,' +
          'you’ll get our best performance.</p><p>5G Nationwide available in 2,700+ cities. Requires a 5G-capable device inside the 5G Nationwide coverage area.</p>'
      },
      {
        title: 'Unlimited mobile wifi hotspot',
        description: 'Mobile hotspot with unlimited data at speeds up to 5 Mbps.'
      },
      {
        title: 'Unlimited calling and texting to Canada and Mexico',
        description: 'Unlimited talk and text from the US to Canada, Mexico, the US Virgin Islands, and Puerto Rico.'
      },
      'Spam protection'
    ]
};

const plusPlan = {
  id: 5,
  name: 'Unlock Additional Premium Features',
  concept: 'B',
  price: 15,
  // comment: 'Unlock Additional Premium Features',
  bundle: true,
  // popular: true,
  comment: ' ',
  options: [
    {
      title: 'Verizon’s 5G Ultra Wideband network',
      description: '<p>5G Ultra Wideband is the fastest 5G network access we offer. Download apps, games, entire playlists and TV series in seconds.</p>' +
        '<p>5G Ultra Wideband available in select areas and access requires a 5G Ultra Wideband-capable device inside the 5G Ultra Wideband coverage area.</p>'
    },
    {
      title: 'Premium network access',
      description: 'Get 50 GB of 5G / 4G LTE premium data per month. In times of congestion, your smartphone and mobile hotspot data may be temporarily slower than other traffic after exceeding 50 GB/mo.'
    },
    {
      title: 'Unlimited calling to 30+ countries and texting to 200+ countries',
      description: {
        textBefore: [
          'Unlimited texting from the US to 200+ countries.'
        ],
        textWithLink: {
          textBefore: 'Unlimited calling from the US to 30+ countries. ',
          linkName: 'Click here',
          textAfter: ' for detailed country list.'
        },
        hiddenText: [
          'International Talk Countries: Aland Islands, Austria, Belgium, Bermuda, Bhutan, Brazil, Brunei, Canada, Chatham Island, China, Denmark, England, Finland, France, Germany, Guam, Guernsey, Hong Kong, Hungary, Isle of Man, Israel, Jersey, South Korea, Malaysia, Martinique, Mexico, Mongolia, Netherlands, New Zealand, Northern Ireland, Northern Mariana Islands, Reunion, Romania, Saipan, Scotland, Singapore, Spain, Sweden, Thailand, United Kingdom, Wales'
        ]
      }
    },
    {
      title: 'Talk, text and data to and from Mexico and Canada',
      description: 'Data speeds are reduced to 2G after 0.5 GB/day.'
    }
  ]
};


export const planList = [
  noCommitment,
  basePlan,
  premiumPlan,
  basicPlan,
  plusPlan
];


export const planIds = planList.map(({ id }) => id);
