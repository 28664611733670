import React from 'react';
import { filter } from 'lodash';
import Select from "react-select";

const FiltersDropdown = ({ selectedOptions, setSelectedOptions, onFilterApply, options }) => {
  const exists = (data) => filter(data, (item) => {
    return item.value === 0;
  });

  const cleared = (options) => {
    return !exists(options).length;
  };

  const handleSelect = (data, _selected) => {
    setSelectedOptions(data);
    onFilterApply(data);
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      placeholder="Select filter"
      value={ selectedOptions }
      onChange={ handleSelect }
      isSearchable
      isMulti
      isClearable={ cleared(selectedOptions) }
    />
  );
};

export default FiltersDropdown;
