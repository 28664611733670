import React from 'react';
import ForgotPassword from '../components/forgot_password';

const App = () => {
  const handleBackToSignIn = () => {
    window.location.href = '/?sign=in';
  };
  return (
    <ForgotPassword
      onBackToSignIn={ handleBackToSignIn }
    />
  );
};

export default App;
