import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import Notification from '../common/notification';
import Http from '../../../common/http';
import { sendMessageOnSizeChange } from '../common/project_price/helper';
import { LaunchContext } from './contexts';
import Header from './components/header';
import InitialPreview from './components/initialPreview';
import PayedData from './components/payedData';
import PayForm from './components/payForm';
import CreateQuotaService from './createQuotaService';
import { hasPayformParam, setUrlParams } from './helper';

const Launch = ({
  title, need_to_pay, launch_data, initial_panel_service_data,
  survey_path, preview_reporting_path, charge_path, stripe_key,
  text, show_preview_reporting, no_color
}) => {
  const launchData = launch_data;
  const nSizes = launchData.nsizes;
  const nSizeKind = launchData.nsize_kind;
  const defaultNSize = launchData.default_nsize || "100N";
  const completeTargetData = launchData.complete_target;
  const canSelectRespondentsData = launchData.can_select_respondents_number;
  const initialPanelServiceData = initial_panel_service_data;
  const projectId = launchData.project_id;
  const gaId = launchData.google_analytics_id;
  const setSuccessMessage = (message) => (setMessageObject({ message, success: true }));
  const setErrorMessage = (message) => (setMessageObject({ message, success: false }));
  const [ payedProjects, setPayedProjects ] = useState(launchData.payed_projects || []);
  const [ creditsInfo, setCreditsInfo ] = useState(
    launchData.credits_info || { credits: 0, rate: 1 }
  );
  const [ showPaymentForm, setShowPaymentForm ] = useState(hasPayformParam);
  const [ messageObject, setMessageObject ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ nSize, setNSize ] = useState(defaultNSize);
  const [ completeTarget, setCompleteTarget ] = useState(completeTargetData.value);
  const [ panelServiceDataInit ] = useState(initialPanelServiceData);
  const [ panelServiceData, setPanelServiceData ] = useState(initialPanelServiceData);
  const [ errors, setErrors ] = useState(null);
  const [ quotaService ] = useState(
    () => (
      new CreateQuotaService(
        {
          checkStatusPath: launchData.check_launch_path,
          isLaunched: launchData.is_launched,
          setSuccessMessage, setErrorMessage
        }
      )
    )
  );

  const sizeData = find(nSizes, (el) => (el.id === nSize));
  const projectPrice = sizeData.price;

  useEffect(() => {
    setUrlParams(showPaymentForm);
  }, [ showPaymentForm ]);

  useEffect(() => {
    sendMessageOnSizeChange(nSize);
  }, [ nSize ]);

  const runFail = (response) => {
    const specificErrors = response?.data?.specific_errors;
    let message = (response?.data?.errors || [])[0] || 'Something went wrong with payment request';
    if ((specificErrors?.base || [])[0]) {
      message = specificErrors.base[0];
    }
    setErrors(specificErrors);
    setErrorMessage(message);
    setLoading(false);
  };

  const sendData = ({
    token = null, saveOnly = false, paymentType = 'by_credit_card'
  }) => {
    setLoading(true);
    const params = {
      ...{
        card_form: {
          token_id: token?.id,
          nsize: nSize,
          payment_type: paymentType
        },
        save_only: saveOnly
      },
      ...panelServiceData.data,
      ...{ complete_target: completeTarget }
    };

    Http.post(charge_path, params).then(
      (response) => {
        if (response.response.ok) {
          if (saveOnly) {
            setSuccessMessage(response.data.message);
            setLoading(false);
          } else {
            setPayedProjects(response.data.result.payed_projects);
            setCreditsInfo(response.data.result.credits_info);
            setCompleteTarget(response.data.result.complete_target.value);
            quotaService.checkStatus(
              null,
              () => {
                setLoading(false);
              },
              false
            );
          }
          setErrors(null);
        } else {
          runFail(response);
        }
      },
      (response) => {
        runFail(response);
      }
    );
  };

  const baseValue = {
    showPaymentForm, setShowPaymentForm, title, loading, setLoading, payedProjects,
    setPayedProjects, panelServiceDataInit, panelServiceData, setPanelServiceData,
    errors, setErrors, quotaService, setMessageObject, setSuccessMessage, setErrorMessage,
    initialPanelServiceData, creditsInfo, setCreditsInfo, sendData, nSize, setNSize, nSizes,
    nSizeKind, sizeData, defaultNSize, projectPrice, launchData, text, canSelectRespondentsData,
    completeTarget, setCompleteTarget, completeTargetData, projectId, gaId,
    surveyPath: survey_path,
    previewReportingPath: preview_reporting_path,
    needToPay: need_to_pay,
    stripeKey: stripe_key,
    states: launchData.states,
    textLaunch: text.launch,
    textLaunchAlmost: text.launch_almost,
    textItemName: text.item_name,
    textKlassModifiersMain: text.klass_modifiers_main,
    textKlassModifiersContent: text.klass_modifiers_content,
    textKlassModifiersSecondaryButton: text.klass_modifiers_secondary_button,
    textIntroCustom: text.intro_custom,
    showPreviewReporting: show_preview_reporting,
    noColor: no_color
  };

  return (
    <LaunchContext.Provider value={ baseValue }>
      <div className="content -grey-background">
        <Header />
        { !!payedProjects.length && <PayedData /> }
        {
          !payedProjects.length &&
          <>
            {
              showPaymentForm ? (
                <PayForm />
              ) : (
                <InitialPreview />
              )
            }
          </>
        }
      </div>
      <Notification
        messageObject={ messageObject }
        interval={ 10 }
        onDeactivate={ () => { setMessageObject(null); } }
      />
    </LaunchContext.Provider>
  );
};

export default Launch;
