import React from 'react';
import FivePointScale from '../../common/components/metrics/five_point_scale.js';
import AttributeRatings from "../../common/components/metrics/attribute_ratings";
import { defaultScorings } from '../../common/logic/eligible';
import MiddleFivePoint from './concept/middle_five_point.js';

const Concept = ({
  concept, index, data, result, nextStep, conceptIndex
}) => {
  const {
    translations, attribute_ratings, language_code,
    custom_metrics_concepts, custom_metrics_at_the_end
  } = data;

  const middleText = conceptIndex ?
    translations.five_point_middle_next_idea :
    translations.five_point_middle_first_idea;

  const scorings = defaultScorings(data.scorings);

  return (
    <>
      {
        !index &&
        <MiddleFivePoint text={ middleText } nextStep={ () => { nextStep(); } } />
      }
      {
        index === 1 &&
        <FivePointScale
          concept={ concept }
          questions={ scorings }
          customMetricConcepts={ custom_metrics_concepts }
          result={ result }
          onlyFirstPage={ custom_metrics_at_the_end }
          nextStep={ () => { nextStep(); } }
        />
      }
      {
        index === 2 &&
        <AttributeRatings
          title={ translations.attribute_ratings_title }
          concept={ concept }
          questions={ attribute_ratings }
          result={ result }
          nextStep={ () => { nextStep(); } }
          languageCode={ language_code }
        />
      }
    </>
  );
};

export default Concept;
