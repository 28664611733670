import React, { useState, useContext } from 'react';
import pluralize from "pluralize";
import classnames from "classnames";
import { formatTimeAgo } from "../../../common/dateUtils";
import { baseContext } from "../contexts";
import renderRawHtml from "../../../common/render_raw_html";
import Replies from "./replies";
import Editor from "./editor";

const Post = ({ post, isReply, addPostToState }) => {
  const { mediaKind, getCurrentUser } = useContext(baseContext);
  const user = getCurrentUser();

  const [ openReplies, setOpenReplies ] = useState(false);

  const toggleOpenReplies = () => {
    setOpenReplies(!openReplies);
  };

  const showRepliesSection = !!post.replies && !isReply;

  return (
    <>
      <div className={ classnames("post", { "reply": isReply }) }>
        <div className="avatar">
          <div className="data-letters" style={ { background: post.user.color } }>
            { post.user.letters }
          </div>
        </div>
        <div className="username">
          <span className="bold">{ post.user.name }</span>,
          { " " }
          { formatTimeAgo(post.created_at) }
        </div>
        <div
          className={ classnames("message", { "-reply": isReply }) }
          { ...renderRawHtml(post.message) }
        />
        {
          showRepliesSection &&
          <div className="replies-toggle">
            {
              !post.replies.length &&
              <span className="reply-text" onClick={ toggleOpenReplies }>
                Reply
              </span>
            }
            {
              post.replies.length > 0 &&
              <span className="reply-text" onClick={ toggleOpenReplies }>
                { post.replies.length } { pluralize('reply', post.replies.length) }
              </span>
            }
          </div>
        }
      </div>
      {
        showRepliesSection &&
        <Replies post={ post } openReplies={ openReplies } />
      }
      {
        openReplies &&
        <Editor
          user={ user }
          parentId={ post.id }
          mediaKind={ mediaKind }
          addPostToState={ addPostToState }
        />
      }
    </>
  );
};

export default Post;
