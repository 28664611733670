import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { map } from "lodash";
import Loader from "../../../../../../../images/icons/loader.gif";
import { destroyProject } from '../../requests';
import { statusKlass, canDelete } from '../helpers';
import { IndexData, BaseContext } from "./contexts";
import CopyProject from './copy-project';
import ProjectSharingButtons from './project-sharing-buttons';
import ProjectDemoShare from './project-demo-share';

const MlProjectAsTableRow = ({ project, isAdmin, mode }) => {
  const [ loading, setLoading ] = useState(false);
  const [ showCopyForm, setShowCopyForm ] = useState(false);
  const handleOpenCopyForm = () => {
    setShowCopyForm(true);
  };
  const handleCloseCopyForm = () => {
    setShowCopyForm(false);
  };
  const { loadProjects, clientId, demoUser } = useContext(IndexData);
  const { buzzbackUser } = useContext(BaseContext);

  return (
    <tr className={ classnames("body-row", { "-copying": project.copying }) }>
      <td className="project-title">
        { project.title }
        {
          buzzbackUser && !!project.projectNumber &&
          <em>({ project.projectNumber })</em>
        }
        { project.copying && " (copying)" }
        <div className="project-country-codes-line">
          {
            map(project.ml_country_codes, (code, i) => (
              <div key={ i } className="country-code">{code}</div>
            ))
          }
        </div>
      </td>
      <td className="project-type">{project.express_text}</td>
      {
        isAdmin &&
        <td className="project-client">{project.client_name}</td>
      }
      <td className="project-created_at">{project.created_at_hint}</td>
      <td className="project-in_field_at">
        { project.in_field_at_hint && project.in_field_at_hint }
        { !project.in_field_at_hint && <span>&mdash;</span> }
      </td>
      <td className="project-closed_at">
        { project.closed_at_hint && project.closed_at_hint }
        { !project.closed_at_hint && <span>&mdash;</span> }
      </td>
      <td className="project-status">
        <div className={ classnames("project-preview_status", `-${project.status_name}`) }>
          { project.status }
        </div>
      </td>
      <td className="project-completes" />
      <td className="project-target" />
      {
        project.show_sharing &&
        <td className="project-share">
          <ProjectDemoShare project={ project } mode={ mode } />
          <ProjectSharingButtons project={ project } mode={ mode } />
        </td>
      }
      <td className="project-actions">
        {
          loading &&
          <div className="dropdown header_button -right-sided -no-min-width">
            <img width="20px" height="20px" src={ Loader } />
          </div>
        }
        {
          !loading &&
          <div className="dropdown header_button -right-sided -no-min-width">
            <span
              className="dropdown_toggle button" data-toggle="dropdown" role="button" aria-haspopup="true"
              aria-expanded="false"
            >
              <svg className="icon -vertical-vector"><use xlinkHref="#svg_vertical-vector" /></svg>
            </span>
            <div className="dropdown_container -corner-right -to-right-bottom">
              <div className="dropdown_inset">
                <div className="dropdown_content">
                  <ul>
                    <li>
                      <a className="project-preview_link" href={ project.ml_project_path }>Go to projects</a>
                    </li>
                    {
                      !demoUser &&
                      <>
                        <li>
                          <a className="project-preview_link" onClick={ handleOpenCopyForm }>
                            Copy Project
                          </a>
                        </li>
                        {
                          canDelete(isAdmin, project, clientId) &&
                          <li className="-red">
                            <a
                              className="project-preview_link"
                              onClick={ () => {
                                destroyProject(
                                  project.title,
                                  project.destroy_path,
                                  project.status === "Unpublished",
                                  setLoading,
                                  loadProjects
                                );
                              } }
                            >
                              Delete Project
                            </a>
                          </li>
                        }
                      </>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
        {
          showCopyForm &&
          <CopyProject project={ project } onClose={ handleCloseCopyForm } />
        }
      </td>
    </tr>
  );
};

export default MlProjectAsTableRow;
