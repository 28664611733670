import React from "react";
import cn from "classnames";
import InfoToolTip from '../../common/infoToolTip';
import Checkbox from "../../common/Checkbox";

const Field = ({ bool, label, text, onChange, className }) => {
  return (
    <div className={ cn("form_metrics-item", className) }>
      <Checkbox label={ label } bool={ bool } onChange={ onChange } />
      <InfoToolTip text={ text || label } />
    </div>
  );
};

export default Field;
