import Http from "../../../common/http";
import { reviewRespondentsBanClick, reviewRespondentsReviewClick } from "../../common/google-analytics/events";

export const reviewRespondent = (respondentId, projectId, filter, setData, payload, gaTrackingId) => {
  const url = `/express/api/projects/${projectId}/respondents/${respondentId}/review`;
  reviewRespondentsReviewClick(gaTrackingId, { project_id: projectId });

  Http.post(url, { ...payload, filter }).then(
    (response) => {
      setData(response.data.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export const banRespondent = (respondentId, projectId, filter, setData, payload, gaTrackingId) => {
  const url = `/express/api/projects/${projectId}/respondents/${respondentId}/ban`;
  reviewRespondentsBanClick(gaTrackingId, { project_id: projectId  });

  Http.post(url, { ...payload, filter }).then(
    (response) => {
      setData(response.data.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export default { reviewRespondent, banRespondent };