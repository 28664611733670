import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../data-context";
import ImageWithMarkups from '../../../common/image_with_markups';
import ShelfHeader from "./ShelfHeader";
import ShelfEditor from "./ShelfEditor";

const Shelf = ({ shelfIndex, image }) => {
  const [ showEditor, setShowEditor ] = useState(false);
  const {
    setCurrentShelfIndex, shelves, getShelfPlaces,
    getProductName, shelfUpdated, copyPrevShelf
  } = useContext(DataContext);
  const shelf = shelves[shelfIndex];
  const [ forceReinit, setForceReinit ] = useState({});
  const [ areas, updateAreas ] = useState(() => (getShelfPlaces(shelf)));

  const openModal = (event) => {
    event.preventDefault();
    setShowEditor(true);
    setCurrentShelfIndex(shelfIndex);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowEditor(false);
    setCurrentShelfIndex(-1);
  };

  const handleGetProductName = (area) => (getProductName(shelf, area));

  const copyShelf = () => {
    if (!!shelf.shelf_image_src && !window.confirm('This shelf data will be overridden. Continue?')) {
      return;
    }
    copyPrevShelf(shelfIndex);
  };

  useEffect(() => {
    if (shelfUpdated?.index === shelfIndex) {
      updateAreas(getShelfPlaces(shelf));
      setForceReinit({});
    }
  }, [ shelfUpdated ]);

  return (
    <div>
      <ShelfHeader image={ image } />
      <div className="products-greed-item-image-wrapper">
        {
          shelf.shelf_image_src &&
          <ImageWithMarkups
            rectClassName="-rounded"
            src={ shelf.shelf_image_src }
            initialAreas={ areas }
            forceReinit={ forceReinit }
            getProductName={ handleGetProductName }
            viewOnly
          />
        }
        <div className="products-greed-item-actions">
          <button className="products-greed-item-button -primary" onClick={ openModal }>
            Edit Shelf
          </button>
          {shelfIndex > 0 && shelves[shelfIndex - 1].shelf_image_src &&
            <button
              type="button"
              onClick={ () => { copyShelf(); } }
              className="products-greed-item-button -action -js-prev"
            >
              Use previous shelf data
            </button>}
        </div>
      </div>
      { showEditor && <ShelfEditor closeModal={ closeModal } /> }
    </div>
  );
};

export default Shelf;
