import React, { useContext, useEffect, useRef } from 'react';
import { map, findIndex } from "lodash";
import renderRawHtml from "../../../../common/render_raw_html";
import SvgIcon from "../../../../common/components/svg-icon";
import { IndexData } from "../../common/index-context";

const TwoColTable = ({ q, firstField, firstSubTitle, secondField, secondSubTitle, children, tourKey }) => {
  const { getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs(tourKey, tourElement);
  useEffect(callback, condition);

  const showLegend = findIndex(q.rows, (r) => (!!r[`arrow`])) > -1;
  const showSecondField = q[secondField];

  return (
    <div  className="comparisons_table -with-border -gray-bg" ref={ tourElement }>
      <table className="table table-comparison">
        <thead>
          <tr>
            <th className="table-comparison_name-cell">
              <div className={ "table-comparison_title" }>
                {q.title}
              </div>
            </th>
            <th className="table-comparison_name-cell">
              <div className={ "table-comparison_title" }>
                {q[`${firstField}_title`]}
              </div>
              {firstSubTitle}
            </th>
            {showSecondField &&
              <th className="table-comparison_name-cell">
                <div className={ "table-comparison_title" } { ...renderRawHtml(q[`${secondField}_title`]) } />
                {secondSubTitle}
              </th>}
          </tr>
        </thead>
        <tbody>
          {map(q.rows, (r, index) => (
            <tr key={ index }>
              <td className="table-comparison_bold-text">
                { r.title }
              </td>
              <td className="table-comparison_bold-text -third-width">
                <div className="-fixed-width">
                  {r[`${firstField}_percent`]}%
                </div>
              </td>
              {showSecondField &&
                <td className="table-comparison_bold-text -third-width">
                  <div className="-fixed-width">
                    {r[`${secondField}_percent`]}%
                  </div>
                  {r[`arrow`] === 'red' && <SvgIcon name="arrow-pointer-down" className="icon-link_icon" />}
                  {r[`arrow`] === 'green' && <SvgIcon name="arrow-pointer-up" className="icon-link_icon" />}
                </td>}
            </tr>
          ))}

          <tr>
            <th colSpan={ 3 } className="-hint">
              {showLegend &&
              <>
                <SvgIcon name="arrow-pointer-up" className="icon-link_icon" />
                &nbsp;
                Statistically higher than Attribute Importance
                &nbsp;
                &nbsp;
                &nbsp;
                <SvgIcon name="arrow-pointer-down" className="icon-link_icon" />
                &nbsp;
                Statistically lower than Attribute Importance
                <br />
              </>}
              {children}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TwoColTable;
