import React, { useContext, useState } from "react";
import { StateContext, LineDataContext, SetContext } from "../../common/contexts";
// eslint-disable-next-line import/no-named-as-default
import { Lines } from '../../common/lines';
import {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  setHashData,
  setPlan, sendData
} from "../../common/statistics";
import Page from '../../common/page';
import Loader from '../../common/loader';
import OrderPage from '../../common/order';
import { ScrollButtonProvider } from "../scrollButton";
import { scrollTop } from '../../common/helper';
import OverviewPage from './overview_page';

const states = [
  "Lines",
  "Overview",
  "Order"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = ({ plans, allPlansInclude, headerButtons, welcomePlans, welcomePlanId }) => {
  const [ , updatePath ] = useContext(StateContext);
  const [ planStartTime, setPlanStartTime ] = useState(null);
  const { delayAnimation } = useContext(SetContext);
  const [ loading, setLoading ] = useState(false);
  const data = {
    welcomePlanId,
    plans,
    welcomePlans,
    headerButtons,
    allPlansInclude,
    setLoading
  };
  // this state is need to update value in subheader after plan select slick with delay animation

  const handleLinesNextStep = (event, linesCount) => {
    setPlanStartTime(new Date());
    updatePath("Order");
    setLinesCount(linesCount);
    setCurrentLine(1);
  };

  const updatePlanData = (planObject, line, timeAfterStart) => {
    setHashData(`lines.${line}.planSelectTime`, timeAfterStart);
    setPlanStartTime(new Date());

    setPlan(planObject, line);
  };
  const handlePlanSelect = (planObject, setPlanToAllLines, clearAllOtherLines) => {
    const timeAfterStart = Math.ceil(
      new Date(new Date() - planStartTime - delayAnimation).getTime() / 1000
    );
    if (setPlanToAllLines) {
      const lineList = Array(getLinesCount()).fill(1).map((el, idx) => el + idx);
      for (const line of lineList) {
        updatePlanData(planObject, line, timeAfterStart);
      }
    } else if (clearAllOtherLines) {
      const lineList = Array(getLinesCount()).fill(1).map((el, idx) => el + idx);
      for (const line of lineList) {
        if (line === getCurrentLine()) {
          updatePlanData(planObject, line, timeAfterStart);
        } else {
          const noPlan = {
            id: null,
            name: '',
            price: 0
          };
          updatePlanData(noPlan, line);
        }
      }
    } else {
      updatePlanData(planObject, getCurrentLine(), timeAfterStart);
    }
    updatePath("Order");
    scrollTop();
  };

  const handlePlanReselect = (clickedLine) => {
    setPlanStartTime(new Date());
    updatePath("Overview");
    setCurrentLine(clickedLine);
  };

  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Lines">
        <Lines
          nextStep={ handleLinesNextStep }
          customTitle={ <>How many phone lines do&nbsp;you&nbsp;need?</> }
          customButtonName="Begin building my plans"
        />
      </Route>

      <Route path="Overview">
        <Page>
          <ScrollButtonProvider>
            <OverviewPage
              currentLine={ getCurrentLine() }
              linesCount={ getLinesCount() }
              onPlanSelect={ (planObject, setPlanToAllLines = false, clearAllOtherLines = false) => {
                handlePlanSelect(planObject, setPlanToAllLines, clearAllOtherLines);
              } }
            />
          </ScrollButtonProvider>
        </Page>
      </Route>

      <Route path="Order">
        <Page>
          <OrderPage
            plans={ [ ...plans, ...welcomePlans ] }
            perks={ [] }
            nextStep={ () => {
              sendData();
              updatePath("Reset");
            } }
            reselectPlan={ handlePlanReselect }
            customTitle="Let’s build your order."
          />
        </Page>
      </Route>

      { loading && <Loader /> }
    </LineDataContext.Provider>
  );
};

export { Router, initialState };
