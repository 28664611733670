import React from 'react';
import { map, each, compact, filter, find } from 'lodash';

const defaultNA = "N/A";
const displayGroups = [ "Active", "Archived" ];
let combinedDashboardForTitle = false;
let showFullTitle = true;
const setCombinedDashboardForTitle = (combine5gAndLte) => {
  combinedDashboardForTitle = !!combine5gAndLte;
  showFullTitle = !combinedDashboardForTitle;
};

const withPrefix = (
  verizonType, mediaKind, title, shortened = false,
  forceFullTitle = false, forceOnlyTitle = false
) => {
  if ((!showFullTitle && !forceFullTitle) || forceOnlyTitle) {
    return title || '';
  }

  const createTitle = (subDashboardName) => {
    const fullTypeName = verizonType === 'verizon5g_b2b' ? 'Business' : 'Home';
    const shortTypeName = verizonType === 'verizon5g_b2b' ? 'B' : 'H';
    const name = shortened ? `${subDashboardName}${shortTypeName}` : `${subDashboardName} ${fullTypeName}`;
    return `${name} ${title || ''}`.trim();
  };

  if (combinedDashboardForTitle) {
    return createTitle('FWA');
  } else if (mediaKind === 'v5g') {
    return createTitle('5G');
  } else if (mediaKind === 'lte') {
    return createTitle('LTE');
  }
  return title;
};

const stripHtml = (val) => ((val || "").replace(/(<([^>]+)>)/gi, "").replace(/[\n\r\t]/, ""));

const generateId = () => `${Date.now()}_${Math.round(Math.random() * 10000)}`;

const customerSpotlightNote = (
  <div className="customer-spotlight-note">
    For research purposes only.
    { ' ' }
    Content of customer testimonials (video and respondent verbatims)
    { ' ' }
    cannot be used for marketing purposes.
  </div>
);

const determineValue = (item, total) => {
  if (!item) {
    return "";
  }
  if (item.showNA) {
    return defaultNA;
  }

  return item.percent_show ? item.percent_value : `${item.value} of ${total}`;
};

const spotlightId = 'spotlight_id';

const setUrlState = (hash) => {
  const string = compact(
    map(hash || {}, (value, key) => (value ? `${key}=${value}` : null))
  ).join('&');
  window.history.replaceState(null, null, `#${string}`);
};

const getUrlState = () => {
  const list = window.location.hash.replace("#", '').split('&');
  const result = {};
  each(list, (item) => {
    const itemMatched = (item || '').match(/^([^=]+)=(.+)$/);
    if (itemMatched) {
      result[itemMatched[1]] = itemMatched[2];
    }
  });
  return result;
};

const createFilterFromPrecode = (name, precode) => (
  precode ? `${name}=${precode}` : null
);

const getPrecodeFromFilter = (filter) => (
  filter ? filter.split('=')[1] : null
);

const getFiltersFromDBFilters = (projects) => {
  const allFilters = { title: null, name: null, answers: [] };
  each(
    filter(projects, (el) => (!!el.filters)),
    (project) => {
      const firstFilter = project.filters[0];
      allFilters.name = firstFilter.name;
      allFilters.title = firstFilter.title;
      each(firstFilter.answers, (answer) => {
        const existingAnswer = find(
          allFilters.answers,
          (item) => (item.value === answer.precode)
        );
        if (!existingAnswer) {
          allFilters.answers.push({
            title: answer.value,
            value: answer.precode
          });
        }
      });
    }
  );
  return allFilters;
};

const getGroupName = (archived) => (archived ? displayGroups[1] : displayGroups[0]);

const subPulseChanges = (verizonType) => (verizonType === 'verizon5g');

export {
  defaultNA, displayGroups,
  withPrefix, generateId, stripHtml, determineValue,
  customerSpotlightNote, spotlightId,
  setUrlState, getUrlState,
  createFilterFromPrecode, getPrecodeFromFilter, getFiltersFromDBFilters,
  getGroupName,
  subPulseChanges,
  setCombinedDashboardForTitle
};
