import React, { useState } from 'react';
import { filter } from "lodash";
import { baseContext } from "./context";
import Index from "./components/index";

const App = (props) => {
  const [ data, setData ] = useState(props.data);
  const [ globalLoading, setGlobalLoading ] = useState(false);
  const projectTitle = props.project_title;
  const projectStatusText = props.project_status_text;
  const projectStatusName = props.project_status_name;
  const projectCategory = props.project_category;
  const projectInternal = props.project_internal;
  const projectTarget = props.project_target;
  const categoryTags = filter(data.tags.category, (tag) => !tag.parent_id);
  const categorySubTags = filter(data.tags.category, (tag) => tag.parent_id);
  const brandTags = data.tags.brand;
  const customTags = data.tags.custom;

  const baseContextValue = {
    data,
    setData,
    globalLoading,
    setGlobalLoading,
    projectTitle,
    projectStatusText,
    projectStatusName,
    projectCategory,
    projectInternal,
    projectTarget,
    projectId: props.project_id,
    categoryTags,
    categorySubTags,
    brandTags,
    customTags
  };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <Index { ...props } />
    </baseContext.Provider>
  );
};

export default App;
