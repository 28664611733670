import React, { useContext } from 'react';
import cn from "classnames";
import { map } from 'lodash';
import { getMetricTitle, IndexData } from "../../../common/index-context";
import OverviewStatusBar from "../../../common/components/overview-modal/overview-status-bar";
import { biggerThan, showedAverageValue } from "../../../common/helpers";


const TrOverviewCombinedTools = ({ data, checkedConcepts, combinedTools }) => {
  const { sort, averageMode, init } = useContext(IndexData);

  return (
    <>
      {
        map(combinedTools, (values, title) => {
          const avgValue = values?.avg?.top2 ? values.avg.top2 : '0%';
          return (
            <tr key={ title }>
              <td className="table-comparison_name-cell table-concept-overview_td-title">
                <span className={ cn({ 'sort-link -current': title === sort }) }>
                  {getMetricTitle(data, title)}
                </span>
              </td>
              <td className="table-concept-overview_avg-col">
                {
                  !!avgValue && ((init.isInternal && data.view !== "client") || averageMode !== 'platform') &&
                  <div className="table-concept-overview_avg-inner">
                    <span
                      className="table-concept-overview_avg-value"
                      style={ { 'bottom': avgValue } }
                    >
                      <span
                        className={
                          cn(
                            'table-concept-overview_avg-value-text',
                            { '-big': biggerThan(avgValue, 75) }
                          )
                        }
                      >
                        { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
                      </span>
                    </span>
                  </div>
                }
              </td>
              {
                map(checkedConcepts, (concept, i) => (
                  <td
                    className={
                      cn(
                        "table-concept-overview_td-chart",
                        {
                          "-first": !i,
                          "-last": i === checkedConcepts.length - 1
                        }
                      )
                    }
                    key={ i }
                  >
                    <OverviewStatusBar values={ values[concept.id] } box="top2" />
                  </td>
                ))
              }
            </tr>
          );
        })
      }
    </>
  );
};

export default TrOverviewCombinedTools;
