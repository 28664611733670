import React, { createContext, useContext } from 'react';
import classnames from 'classnames';

const TdOpenReportContext = createContext({});

const TdOpenReport = (props) => {
  const { project } = useContext(TdOpenReportContext);
  const { children, className } = props;
  const openReportRow = () => {
    window.location.href = project.report_path;
  };
  const newClassName = classnames(className, '-pointer');
  return (
    <td { ...props } { ...{ onClick: openReportRow, className: newClassName } }>
      {children}
    </td>
  );
};

export { TdOpenReport, TdOpenReportContext };
