import React, { Fragment } from "react";
import { map } from "lodash";
import {
  getQuotasForQuestion
} from "../../common/custom_questions/helper";
import {
  HiddenBaseFields, HiddenQuestionAnswers, StepField,
  HiddenFieldsConditions, HiddenGridItemFields
} from "../../common/custom_questions/components/HiddenFields";
import QuotaModeHiddenFields from '../../common/quotaMode/components/quotaModeHiddenFields';

const HiddenFieldsQuotas = ({ question }) => (
  <>
    {
      question.kind !== "Grid" &&
      map(getQuotasForQuestion(question), (quota, index) => (
        <input
          key={ `quota${index}` }
          type="hidden"
          name={ `project[screener_questions][][quotas][${index}]` }
          value={ quota || '' }
        />
      ))
    }
    {
      question.kind === "Grid" &&
      map(getQuotasForQuestion(question), (quota, index) => (
        <input
          key={ `quota${index}` }
          type="hidden"
          name={ `project[screener_questions][][quotas][${index}]` }
          value={ JSON.stringify(quota || '') }
        />
      ))
    }
  </>
);

const HiddenFields = ({ questions, options }) => (
  <>
    <StepField options={ options } />
    {
      map(questions, (question, index) => (
        <Fragment key={ `question${question.id}${index}` }>
          <HiddenBaseFields question={ question } metricType="screener_questions" />
          <input
            type="hidden"
            name="project[screener_questions][][manage_quota]"
            value={ question.manage_quota || false }
          />
          <HiddenQuestionAnswers question={ question } metricType="screener_questions" />
          <HiddenFieldsConditions question={ question } metricType="screener_questions" />
          <HiddenFieldsQuotas question={ question } />
          {
            question.kind === "Grid" &&
            <HiddenGridItemFields question={ question } metricType="screener_questions" />
          }
          <QuotaModeHiddenFields question={ question } relation="screener_questions" />
        </Fragment>
      ))
    }
  </>
);

export default HiddenFields;
