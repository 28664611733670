import React, { createContext, useRef, useContext, useState } from 'react';
import SmoothScroll from 'smooth-scroll';
import { getCurrentLine, getHashData, setHashData } from '../common/statistics';
import { headerButtons } from './plans_data';

export const REF_NAMES = Object.freeze({
  Unlimited: 'planUnlimitedSection',
  Welcome: 'planWelcomeSection'
});

export const REF_NAMES_LIST = Object.values(REF_NAMES);

const checkRefName = (name) => REF_NAMES_LIST.indexOf(name) !== -1;

const initialRefState = {
  [REF_NAMES.Unlimited]: null,
  [REF_NAMES.Welcome]: null
};

const initialContextState = {
  scrollTargetRefs: initialRefState,
  setRef: () => {},
  scrollTo: () => {},
  updateStatistic: () => {}
};

export const ScrollButtonContext = createContext(initialContextState);

export const ScrollButtonProvider = ({ children }) => {
  const [ smoothScroll ] = useState(() => (new SmoothScroll()));
  const scrollTargetRefs = useRef(initialRefState);
  const setRef = (name) => (ref) => {
    const nameExist = checkRefName(name);
    if (nameExist && ref !== void 0) {
      scrollTargetRefs.current = {
        ...scrollTargetRefs.current,
        [name]: ref
      };
    }
  };

  const scrollTo = (name) => {
    const nameExist = checkRefName(name);
    if (nameExist) {
      const targetRef = scrollTargetRefs.current[name];
      if (!targetRef) {
        return;
      }
      // https://github.com/cferdinandi/smooth-scroll
      const scrollOptions = { speed: 300, offset: 20 };
      smoothScroll.animateScroll(targetRef, null, scrollOptions);
    }
  };

  const updateStatistic = (name) => {
    const line = getCurrentLine();
    const key = `lines.${line}.${name}ClickCount`;
    const count = getHashData(key);
    setHashData(`lines.${line}.${name}ClickCount`, count ? count + 1 : 1);
  };

  const context = {
    scrollTargetRefs,
    setRef,
    scrollTo,
    updateStatistic
  };

  return (<ScrollButtonContext.Provider value={ context }>
    {children}
  </ScrollButtonContext.Provider>);
};

export const ScrollButton = ({ name }) => {
  const { scrollTo, updateStatistic } = useContext(ScrollButtonContext);

  const onClick = () => {
    scrollTo(name);
    updateStatistic(name);
  };

  if (!checkRefName(name)) {
    return null;
  }

  const { title, subtitle } = headerButtons[name] || {};
  return (<button className="scroll-button" onClick={ onClick }>
    <div className="scroll-button_title">{title}</div>
    <div className="scroll-button_subtitle">{subtitle}</div>
  </button>);
};


