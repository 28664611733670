import { compact, each, map } from 'lodash';

const showFormParam = 'payform';

const getUrlState = () => {
  const list = window.location.hash.replace("#", '').split('&');
  const result = {};
  each(list, (item) => {
    const itemMatched = (item || '').match(/^([^=]+)=(.+)$/);
    if (itemMatched) {
      result[itemMatched[1]] = itemMatched[2];
    }
  });
  return result;
};
const setUrlState = (hash) => {
  const string = compact(
    map(hash || {}, (value, key) => (value ? `${key}=${value}` : null))
  ).join('&');
  window.history.replaceState(null, null, (string ? `#${string}` : ''));
};

const hasPayformParam = () => (getUrlState()[showFormParam] === '1');

const setUrlParams = (showPaymentForm) => {
  const urlStateHash = {};
  if (showPaymentForm) {
    urlStateHash[showFormParam] = 1;
  }
  setUrlState(urlStateHash);
};

const capitalizeFirstLetter = (text) => (text[0].toUpperCase() + text.slice(1));

const stripeCardClassByBrand = {
  'visa': 'pf-visa',
  'mastercard': 'pf-mastercard',
  'amex': 'pf-american-express',
  'discover': 'pf-discover',
  'diners': 'pf-diners',
  'jcb': 'pf-jcb',
  'unknown': 'pf-credit-card'
};

const stripeBrandClass = (brand) => {
  let pfClass = 'pf-credit-card';
  if (brand in stripeCardClassByBrand) {
    pfClass = stripeCardClassByBrand[brand];
  }
  return [ 'pf', pfClass ].join();
};

export {
  showFormParam, getUrlState, setUrlState, hasPayformParam, setUrlParams,
  capitalizeFirstLetter, stripeBrandClass
};
