import React, { useState } from 'react';
import Card from './card';
import CardPlanTitle from './cardPlanTitle';
import PlanShortDescription, { SelectablePerksPlanDescription} from './planShortDescription';

const AccordionView = ({ isStatic, handleOpenChange, title, children }) => {
  const [open, setOpen] = useState(isStatic);
  const handleChange = () => {
    handleOpenChange();
    setOpen(!open);
  };
  return <div className="accordion" style={!open ? { marginBottom: '-20px' } : {}}>
    {!isStatic && <button onClick={handleChange} className="accordion-button">
      <span className={`accordion-button-text ${open ? '-open' : ''}`}>{title}</span>
    </button>}
    {open && children}
  </div>
};

export const EmptyCard = () => <div className="empty-card">
  <span className="empty-card-text">Not chosen yet</span>
</div>;

export const PlanCard = (props) => {
  const {
    plan: {
      name, price,
    },
    bigPlusTaxes,
    isAcardionStatic,
    accordionTitle,
    handleOpenChange,
    renderChildrenInAccordion,
    cardSubtitle,
    className,
    renderDescription,
    children,
    bundleSubtitle
  } = props;
  return <Card className={className}>
    <CardPlanTitle name={name} price={price} subtitle={cardSubtitle} bigPlusTaxes={bigPlusTaxes} bundleSubtitle={bundleSubtitle} />
    <div className="hr -secondary" style={{ marginBottom: 15 }}/>
    <AccordionView
      isStatic={isAcardionStatic}
      handleOpenChange={handleOpenChange}
      title={accordionTitle}>
      <div className="cards-item-description">
        {renderDescription()}
      </div>
      {renderChildrenInAccordion && children}
    </AccordionView>
    {!renderChildrenInAccordion && children}
  </Card>;
};