import React from 'react';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import StartButton from '../../../common/components/start_button.js';
import ZoomImage from '../../../common/components/zoom_image';

const Intro = ({ text, url, nextStep }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start -concept">
        <div className="survey-start_description" { ...renderRawHtml(text) }  />
        <div>
          <ZoomImage
            className="survey-start-hero-img"
            src={ url }
            alt="Hero Image"
          >
            <img src={ url } alt="Hero Image" />
          </ZoomImage>
        </div>
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};
export default Intro;
