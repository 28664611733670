import React from 'react';
import Landing from '../common/landing';
import { InnerAccordion, accordionTitleFactory } from '../common/accordion';
import { TVPlans } from '../data';
import { useFiosTV } from './hooks';

const FiosTV = ({ open, canOpen, onChange, onNext }) => {
  const {
    plan,
    chipTitle,
    sectionTitle,
    chipClassName,
    handleChangePlan
  } = useFiosTV(onNext);
  return <InnerAccordion 
    open={open}
    canOpen={canOpen}
    onChange={onChange}
    TitleComponent={accordionTitleFactory(sectionTitle, chipTitle, chipClassName)}>
    <Landing
      plans={TVPlans}
      selected={plan}
      onChange={handleChangePlan}
   />
  </InnerAccordion>;
};

export default FiosTV