import React, { useContext, useEffect, useRef } from 'react';
import { IndexData } from "../../common/index-context";
import { comparisonReportTourClick } from "../../../common/google-analytics/events";

const NInfo = ({ n }) => {
  const { init, getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs('projectHeader', tourElement);
  useEffect(callback, condition);

  const onTourClick = () => {
    comparisonReportTourClick(init.google_analytics_id, { project_id: init.project.id });
  };

  return (
    <div className="comparisons_intro-grid" ref={ tourElement }>
      <div className="comparisons_intro-grid-item -fix">
        <div className="comparisons_intro-plate">N = {n}</div>
      </div>
      <div className="comparisons_intro-grid-item">
        <div className="comparisons_intro-text">
          Creating reports is easy. See results by Category Landscape, Brand Evaluation, and Custom Questions (if applicable).
          <br />
          Click to view <a className="link" href={ init.urls.tour } onClick={ onTourClick }>examples of reporting pages</a> you can create.
        </div>
      </div>
    </div>
  );
};

export default NInfo;
