import React, { Fragment, useState, useLayoutEffect, useContext } from 'react';
import { filter, each, map, includes, compact, find, isEmpty } from 'lodash';
import { calculateQuotasForQuestion } from '../../common/logic/calculate_quotas';
import { prepareQuestion } from '../logic/questions_transition.js';
import { ListContext } from '../contexts/list_context.js';
import { ItemContext } from '../contexts/item_context.js';
import QuotaModeLocalState from '../../common/quotaMode/components/quotaModeLocalState';
import QuotaModeHiddenFields from '../../common/quotaMode/components/quotaModeHiddenFields';
import { ConditionContext } from "../contexts/condition_context";
import BreaksElementHeader from './breaks_element_header.js';
import DemoElementHeader from './demo_element_header.js';

const ElementWrapper = ({ question, readOnly, elementClass, showText }) => {
  const listContextValue = useContext(ListContext);
  const {
    defaultLanguageCode, languageMapping,
    languageCodeMapping, quotaDisallowedOptions,
    relationName
  } = useContext(ListContext);

  const conditionValues = useContext(ConditionContext);

  const [ , updateState ] = useState({});

  const basePrepareQuestion = (q) => {
    return prepareQuestion(q, languageMapping, languageCodeMapping, defaultLanguageCode);
  };

  const q = basePrepareQuestion(question),
    breaks = q.breaks || [],
    onCountryChange = listContextValue.onCountryChange(q);

  each(breaks, (el) => {
    basePrepareQuestion(el);
  });

  const isConditionPresent = (q) => {
    return !!q.condition && !!filter(q.condition || {}, (k, v) => k === conditionValues.selected).length;
  };

  const noChecks = (q) => {
    const filtered = filter(q.condition || {}, (k, v) => {
      return k === conditionValues.selected;
    });
    return filtered.length === map(q.answers, (k, v) => k).length || !filtered.length;
  };

  const setAllConditions = (q) => {
    each(q.answers, (v, k) => {
      q.condition[k] = conditionValues.selected;
    });
    calculateQuotasForQuestion(q, quotaDisallowedOptions, conditionValues);
  };

  const setFirstCondition = (q) => {
    const key = compact(
      map(q.answers, (key, item) => {
        return includes(q.excluded, key) ? null : item;
      })
    )[0];

    if (q.condition[key] === conditionValues.selected) {
      q.condition[key] = conditionValues.notSelected;
    } else {
      q.condition[key] = conditionValues.selected;
    }

    if (q.is_country) {
      const preparedAnswer = find(q.preparedAnswers, (item) => item.key === key);
      q.language_code = preparedAnswer ? preparedAnswer.languageCode : defaultLanguageCode;
      if (onCountryChange) {
        onCountryChange();
      }
    }
  };

  useLayoutEffect(() => {
    if (q.single) {
      if (noChecks(q)) {
        setFirstCondition(q);
      }
    } else if (!isConditionPresent(q)) {
      setAllConditions(q);
    } else if (isConditionPresent(q) && isEmpty(q.quotas)) {
      calculateQuotasForQuestion(q, quotaDisallowedOptions, conditionValues);
    }
  }, [ q ]);

  const itemContextValue = {
    onQuestionChange: (event, question) => {
      updateState({});
    }
  };

  return (
    <QuotaModeLocalState question={ question } takeGlobalValue>
      <ItemContext.Provider value={ itemContextValue }>
        {!q._destroy && !breaks.length && (
          <DemoElementHeader
            readOnly={ readOnly }
            showText={ showText }
            question={ q }
            elementClass={ elementClass }
            conditionValues={ conditionValues }
          />
        )}
        {!q._destroy && !!breaks.length && <BreaksElementHeader question={ q } />}
        <input type="hidden" name={ `project[${relationName}][][id]` } value={ q.id || '' } />
        <input type="hidden" name={ `project[${relationName}][][kind]` } value={ q.kind || '' } />
        <input type="hidden" name={ `project[${relationName}][][name]` } value={ q.name || '' } />
        <input type="hidden" name={ `project[${relationName}][][title]` } value={ q.title || '' } />
        <input type="hidden" name={ `project[${relationName}][][text]` } value={ q.text || '' } />
        <input type="hidden" name={ `project[${relationName}][][position]` } value={ `${q.position}` || '' } />
        <input
          type="hidden"
          name={ `project[${relationName}][][language_code]` }
          value={ q.language_code || '' }
        />
        <input type="hidden" name={ `project[${relationName}][][available]` } value={ q.available } />
        <input
          type="hidden"
          name={ `project[${relationName}][][none_answer]` }
          value={ q.none_answer || false }
        />
        <input
          type="hidden"
          name={ `project[${relationName}][][none_answer_text]` }
          value={ q.none_answer_text || '' }
        />
        <input
          type="hidden"
          name={ `project[${relationName}][][other_answer]` }
          value={ q.other_answer || false }
        />
        <input
          type="hidden"
          name={ `project[${relationName}][][other_answer_text]` }
          value={ q.other_answer_text || '' }
        />
        <input
          type="hidden"
          name={ `project[${relationName}][][manage_quota]` }
          value={ q.manage_quota || false }
        />
        <input
          type="hidden"
          name={ `project[${relationName}][][display_dedicated_page]` }
          value={ q.display_dedicated_page || false }
        />
        <input
          type="hidden"
          name={ `project[${relationName}][][breaks]` }
          value={ JSON.stringify(q.breaks) }
        />
        <input type="hidden" name={ `project[${relationName}][][_destroy]` } value={ q._destroy || '' } />
        {
          map(q.preparedAnswers, (answer, index) => (
            <Fragment key={ `${relationName}-input-${index}` }>
              {
                answer.addToServer &&
                <input
                  type="hidden"
                  name={ `project[${relationName}][][answers][${answer.key}]` }
                  value={ answer.item || '' }
                />
              }
              {
                answer.addToServer &&
                <input
                  type="hidden"
                  name={ `project[${relationName}][][condition][${answer.key}]` }
                  value={ q.condition[answer.key] || '' }
                />
              }
              {
                q.quotas[answer.key] && answer.addToServer &&
                <input
                  type="hidden"
                  name={ `project[${relationName}][][quotas][${answer.key}]` }
                  value={ q.quotas[answer.key] || '' }
                />
              }
            </Fragment>
          ))
        }
        {
          q.none_answer &&
          <input
            type="hidden"
            name={ `project[${relationName}][][condition][-1]` }
            value={ q.noneQualify }
          />
        }
        {
          q.other_answer &&
          <input
            type="hidden"
            name={ `project[${relationName}][][condition][-2]` }
            value={ q.otherQualify }
          />
        }
        <input
          type="hidden"
          name={ `project[${relationName}][][answers_order]` }
          value={ q.answers_order }
        />
        <QuotaModeHiddenFields relation={ relationName } takeGlobalValue />
      </ItemContext.Provider>
    </QuotaModeLocalState>
  );
};

export default ElementWrapper;
