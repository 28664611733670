import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import Icon from "./Icon";
import { CLASS_DETAILS_BACK_COUNTER_KEY } from "../counter";

const WorkoutHeader = ({ className }) => {
  return (
    <div className={cn("workout-header", className)}>
      <Link
        to="/explore/brand/equinox"
        className="workout-header_back"
        data-counter-key={CLASS_DETAILS_BACK_COUNTER_KEY}
      >
        <Icon name="back" />
      </Link>
      <div className="workout-header_actions">
        <div className="workout-header_add-button">
          <Icon name="calendar" className="mr-2" />
          Add
        </div>
        <div className="workout-header_bookmark-button">
          <Icon name="bookmark" />
        </div>
      </div>
    </div>
  );
};

export default WorkoutHeader;
