import React, { Fragment } from "react";
import { map } from "lodash";
import Checkbox from "../../common/Checkbox";

const DefaultCheckboxes = ({ gridItems, onChange }) => {
  return (
    <>
      {
        map(gridItems, (item, index) => (
          <Fragment key={ index }>
            {!item.editable &&
              <div className="form_metrics-item -compact">
                <Checkbox
                  label={ item.question }
                  bool={ item.enabled }
                  onChange={ (newBool) => onChange(newBool, index) }
                />
              </div>}
          </Fragment>
        ))
      }
    </>
  );
};

export default DefaultCheckboxes;
