import React from 'react';
import BaseIntroSetup from '../../../common/screens/baseIntroSetup';

const IntroSetup = (props) => (
  <BaseIntroSetup
    title={ <>Get set up to earn cash back and use PayPal in stores</> }
    notificationText={ <>Your PayPal account is ready.</> }
    { ...props }
  />
);

export default IntroSetup;
