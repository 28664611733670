import React, { useContext } from 'react';
import cn from 'classnames';
import { IndexData } from "../../common/index-context";
import renderRawHtml from '../../../../common/render_raw_html';
import GreenRed from './greenRed';
import Arrow from './arrow';
import Checkbox from "../components/checkbox";
import { baseContext } from "../../../respondent/common/contexts";

const TextHintValue = ({ text, hint, percent, color, arrow }) => {
  const localColor = arrow ? 'octarine' : color;

  return (
    <div className="comparisons_plate-row">
      <div className="texts">
        <div className="title -big">{text}</div>
        {!!hint && <div className="note -sm">{hint}</div> }
      </div>
      <div className={ cn({ "value -big": localColor !== '', "value -big -normal-font": localColor === 'black' }, `-${localColor}`) }>
        {percent}
        {arrow && <Arrow color={ arrow } />}
      </div>
    </div>
  );
};

const XappyPath = ({ text, metric }) => {
  const color = metric.arrow ? 'black' : metric.color;

  return (
    <div className="comparisons_grid-item">
      <div className={ cn("value -lg", `-${color}`) }>
        {metric.percent_value}
        {!!metric.arrow && <Arrow color={ metric.arrow } />}
      </div>
      <div className="note -sm">
        {text}
      </div>
    </div>
  );
};

const UnhappyPath = () => {
  const { data } = useContext(IndexData);
  const { model, setModel } = useContext(baseContext);

  return (
    <>
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Unhappy Path Impact</h2>
        <Checkbox
          key={ `unhappy-report` }
          title="Add to Report"
          value={ model.unhappy }
          paramName="unhappy"
          onChange={ (v) => { setModel({ ...model, unhappy: v }); } }
        />
      </div>
      <div className="comparisons_plates">
        { data.unhappy_path &&
          <div className="comparisons_plates-grid">
            <div className="comparisons_plates-grid-item">
              <div className="comparisons_plate -full-height -flex-vertical">
                <h3 className="comparisons_plate-title -margin-bottom-md -bordered">
                  Post Exposure to Unhappy Path Scenario:
                </h3>
                <div className="comparisons_grid  -space-md">
                  <div className="comparisons_grid-item">
                    <TextHintValue
                      text="Ease of Experience"
                      hint="“Little” or “No” effort"
                      percent={ data.unhappy_path.vcc_deviation_effort.percent_value }
                      color={ data.unhappy_path.vcc_deviation_effort.color }
                      arrow={ data.unhappy_path.vcc_deviation_effort.arrow }
                    />
                  </div>
                  <div className="comparisons_grid-item">
                    <TextHintValue
                      text={ data.branded_texts.vcc_customer_first }
                      hint="Agree “Completely” or “Somewhat”"
                      percent={ data.unhappy_path.vcc_deviation_values.percent_value }
                      color={ data.unhappy_path.vcc_deviation_values.color }
                      arrow={ data.unhappy_path.vcc_deviation_values.arrow }
                    />
                  </div>
                </div>

                <hr />

                <div className="comparisons_plate-row">

                  <div className="comparisons_grid -space-md">
                    <div className="comparisons_grid-item">
                      <div className="texts">
                        <div className="title -big">Customer Case Scores</div>
                      </div>
                    </div>
                    <div className="comparisons_grid-item">
                      <div className="texts">
                        <div className="title -big">Risk Level</div>
                        <div className="note -sm">
                          This is the effect on Ease and Values Me scores of the Unhappy Path.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="comparisons_plate-row -space-md">

                  <div className="comparisons_grid -space-md">

                    <XappyPath text="Happy Path" metric={ data.unhappy_path.happy_path } />

                    <XappyPath text="Unhappy Path" metric={ data.unhappy_path.unhappy_path } />

                    <div className="comparisons_grid-item -half">
                      {!data.arrows_mode &&
                        <div className={ cn("value -lg", `-${data.unhappy_path.risk.color}`) }>
                          {data.unhappy_path.risk.text}
                        </div>}
                      {!!data.arrows_mode && <div className={ cn("value -lg", `-black`) }>N/A</div>}
                    </div>
                  </div>
                </div>
                <hr />

                <div className="comparisons_plate-spacer" />

                <div className="comparisons_plate-legend">
                  <GreenRed arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
                </div>
              </div>
            </div>

            <div className="comparisons_plates-grid-item">
              <div className="comparisons_plate -full-height -flex-vertical">
                <h4 className="comparisons_plate-title -sm -no-transform -bordered">
                  Why did we ask customers about an Unhappy Path?
                  <div className="comparisons_plate-note -small-margin-top -black">
                    This analysis tells us whether there is a significant risk to overall customer
                    satisfaction if the experience doesn’t work as intended.
                  </div>
                </h4>
                <h3 className="comparisons_plate-title -margin-bottom-md">Unhappy Path Scenario:</h3>
                <div className="comparisons_plate-text" { ...renderRawHtml(data.unhappy_path.html) } />
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default UnhappyPath;
