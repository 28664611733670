import React from "react";

const AdHeader = ({ title, productUrl }) => {
  return (
    <div className="products-greed-item-header">
      <span className="products-greed-item-title">
        {title}
      </span>
      <div className="products-greed-item-modal-wrapper">

        <div className="info-tooltip">
          <span className="products-greed-item-show-modal">
            Show Ad
          </span>
          <div className="info-tooltip_drop -left">
            <div className="info-tooltip_drop-inner">
              <div className="popup-title -no-description">{title}</div>
              <img src={ productUrl } width={ 260 } />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default AdHeader;
