import Http from '../../../../common/http';

const zipCodeRequest = (url, zipCode, goodCallback, badCallback) => {
  Http.get(`${url}?zip_code=${zipCode}`).then(
    (response) => {
      if (response.response.ok) {
        goodCallback && goodCallback(response);
      } else {
        badCallback && badCallback(response);
      }
    },
    (response) => {
      console.log('something went wrong', response);
      badCallback && badCallback(response);
    });
};
export { zipCodeRequest };
