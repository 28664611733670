import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Modal from '../../../../../common/components/modal';
import { IndexData } from "../../../common/index-context";
import SwitchModal from "../../../common/components/entity-modal/switch-modal";
import HeaderLinks from "../../../common/components/header-links";
import TopFilter from "../../../common/filters/app";
import ExpressLanguageSwitcher from '../../../common/components/express-language-switcher';
import Header from "../../../common/components/entity-modal/header";
import ExpressOpenEndCard from "../../../common/components/express-open-end-card";
import ConceptTabs from "../../../common/components/concept-tabs";
import ConceptTabHeatmap from "../../../common/components/concept-tab-heatmap";
import ConceptTabWordcloud from "../../../common/components/concept-tab-wordcloud";
import ConceptFocusHeader from "../../../common/components/concept-focus-header";
import ConceptTabMarkup from "../../../common/components/concept-tab-markup";
import { WithMarkupData } from "../../../common/components/concept-tab-markup/markups-logic";

const CustomTbTitleTag = ({ question, name, n }) => {
  const text = question.replace(/%{claim}/, name);
  return (
    <div className="thought-bubble-header">
      <h3> Thought Bubble </h3>
      <p>N={n}, {text}</p>
    </div>
  );
};

const EntityModal = () => {
  const {
    init, data, current, setCurrentClaimWithUrl, loadData,
    loadComments, settings, loadClaim, loading,
    showSwitchModal, comments, getConceptId,
    wcLoading, loadWords, selectedFocusConcept,
    tourData, getForceCFTab
  } = useContext(IndexData);

  const reports = [
    { title: 'Claim Details Report', name: 'claim_details', kinds: [ 'pptx' ] },
    { title: 'Claim Verbatims Report', name: 'claim_individual_verbatims', kinds: [ 'xlsx' ] }
  ];

  const demographics = data.filter.demographics;

  const forceTab = getForceCFTab();

  const [ tab, setTab ] = useState(settings.tab);
  useEffect(() => {
    setTab(settings.tab);
  }, [ settings ]);

  const stateTab = forceTab || tab;

  const selectedTool = data.tools[0];

  const wordCloudHeaderHtml = (reaction, n) => {
    const pq = [ data.questions.concept_focus_title_positive, data.questions.concept_focus_description_positive ].join(' ');
    const nq = [ data.questions.concept_focus_title_negative, data.questions.concept_focus_description_negative ].join(' ');

    return (
      <ConceptFocusHeader
        reaction={ reaction }
        positiveQuestion={ pq }
        negativeQuestion={ nq }
        n={ n }
      />
    );
  };

  const mcRef = useRef(null);

  return (
    <>
      <Modal
        modal
        modalSize="lg"
        modalClassName={
          classnames("-scorecard", { 'tour-mode': !!tourData })
        }
        className="concept-report h100"
        mcRef={ mcRef }
      >
        <Header singular="claim" titleField="label" />
        <div className="modal-filters-header" >
          <TopFilter
            scorecard
            data={ data }
            loadData={ loadData }
          >
            <HeaderLinks
              className="-no-min-width"
              exportUrl={ init.urls.export }
              reports={ reports }
              i18n={ init.i18n }
              data={ data }
              claimId={ current.id }
            />
          </TopFilter>
          <ExpressLanguageSwitcher
            description={ data.translations.language_switcher_description }
            showLanguageSwitcher={ data.show_language_switcher }
          />
        </div>

        <div className="modal_body -scrollable concept-report_body">
          <div className="claims-for-loader">
            { loading && <div className="page-loader -inner-loader" /> }
            { !!data.tb_enabled &&
              <div className="concept-report_content">
                <ExpressOpenEndCard
                  metric={ current.open_end_card.metric }
                  cardData={ current.open_end_card.data }
                  mode="editing"
                  filter={ demographics }
                  customTitle={
                    <CustomTbTitleTag
                      question={ data.questions.claims_thought_bubble_question }
                      name={ current.text }
                      n={ current.respondents_count }
                    />
                  }
                  pinCount={ 1 }
                  answerHelp={ data.translations.wordcloud_hint }
                  pinTitle={ data.translations.wordcloud_pin }
                  unpinTitle={ data.translations.wordcloud_unpin }
                  isAdmin={ init.isAdmin }
                  loadWords={ loadClaim }
                  loadComments={ loadComments }
                  tourName={ 'projectModalTB' }
                />
              </div>}

            { !!data.cf_enabled &&
              <>
                <WithMarkupData
                  tool={ selectedTool }
                  allComments={ comments }
                  focusConcept={ selectedFocusConcept(selectedTool) }
                >
                  <ConceptTabs
                    selectedId={ current.id }
                    selectedConceptId={ getConceptId() }
                    selectedTool={ selectedTool }
                    demographics={ demographics }
                    download={ init.i18n.download }
                    loadData={ loadData }
                    loadComments={ loadComments }
                    tab={ stateTab }
                    setTab={ setTab }
                    mcRef={ mcRef }
                  />
                  {
                    stateTab === 'markups' &&
                    <ConceptTabMarkup
                      imageField="focus_concept_image_url"
                      current={ current }
                    />
                  }
                </WithMarkupData>

                {
                  stateTab === 'heatmap' &&
                  <ConceptTabHeatmap
                    imageField="focus_concept_image_url"
                    current={ current }
                    comments={ comments }
                  />
                }
                {
                  stateTab === 'wordcloud' &&
                  <ConceptTabWordcloud
                    current={ current }
                    wcFilter={ demographics }
                    customTitle={ wordCloudHeaderHtml }
                    pinCount={ 1 }
                    pinTitle={ data.translations.wordcloud_pin }
                    unpinTitle={ data.translations.wordcloud_unpin }
                    answerHelp={ data.translations.wordcloud_concept_focus_hint }
                    answerHelpFor={ 0 }
                    isAdmin={ init.isAdmin }
                    wcLoading={ wcLoading }
                    loadWords={ loadWords }
                    loadComments={ loadComments }
                  />
                }
              </>}
            { !data.tb_enabled && !data.cf_enabled && <div className="info-text">No Open-ends Selected</div> }
          </div>
        </div>
      </Modal>
      {
        showSwitchModal &&
        <SwitchModal
          concepts={ data.analytics }
          current={ current }
          titleField="label"
          setCurrentWithUrl={ setCurrentClaimWithUrl }
          singular="claim"
        />
      }
    </>
  );
};

export default EntityModal;
