import { getLinesCount } from "../common/statistics";
import {
  allPlansInclude, planFeatures,
  pricingLinesDescription,
  linesEmptyPlanTitle,
  bauPerks,
  bauPerkNote
} from "../common/common_data";

const pricing = [
  [ 0, 0, 0, 0, 0 ], [], [], [], [], [],
  [ 65, 55, 40, 30, 25 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 90, 80, 65, 55, 50 ]
];

const priceForLine = (planId) => {
  if (!planId) {
    return null;
  }
  return pricing[planId - 1][getLinesCount() - 1];
};

const planA = {
  id: 7,
  name: "Plan A",
  features: [
    { ...planFeatures.FiveGNationwide, ...{ klass: "-down-shifting -more" } },
    planFeatures.UnlimitedData
  ],
  perks: [
    bauPerks.allOnSixMonth
  ]
};

const planB = {
  id: 8,
  name: "Plan B",
  letter: "B",
  features: [
    { ...planFeatures.FiveGUltraWideband, ...{ klass: "-ultra -more" } },
    planFeatures.FiveGNationwide,
    { ...planFeatures.FiftyGbPremiumNetworkAccess, ...{ klass: "-height-align-50 -more" } },
    { ...planFeatures.TwentyFiveGbPremiumHotSpotData, ...{ klass: "-height-align-25 -more" } },
    { ...planFeatures.PlusGet800ToNextPhone, ...{ klass: "-towards" } }
  ],
  perksNote: bauPerkNote(19),
  perks: [
    bauPerks.huluDisneyEspn,
    bauPerks.appleArcadeOrGooglePlay,
    bauPerks.fiftyPercentOffHomeInternet,
    bauPerks.appleMusicOnSixMonths
  ]
};

const planC = {
  id: 9,
  name: "Plan C",
  perksNote: bauPerkNote(36),
  features: [
    { ...planFeatures.FiveGUltraWideband, ...{ klass: "-ultra -more" } },
    planFeatures.FiveGNationwide,
    { ...planFeatures.FiftyGbPremiumNetworkAccess, ...{ klass: "-height-align-50 -more" } },
    { ...planFeatures.TwentyFiveGbPremiumHotSpotData, ...{ klass: "-height-align-25 -more" } },
    { ...planFeatures.PlusGet800ToNextPhone, ...{ klass: "-towards" } }
  ],
  perks: [
    bauPerks.FiftyPercentOffWatchTabletWifi,
    bauPerks.TravelPass,
    bauPerks.SixHundredGbVerizonCloud,
    bauPerks.fiftyPercentOffHomeInternet,
    bauPerks.allOnSixMonth
  ]
};

const planD = {
  id: 10,
  name: "Plan D",
  features: [
    { ...planFeatures.FiveGUltraWideband, ...{ klass: "-ultra -more" } },
    planFeatures.FiveGNationwide,
    { ...planFeatures.UnlimitedPremiumNetworkAccess, ...{ klass: "-height-align-50 -more" } },
    { ...planFeatures.FiftyGbPremiumHotSpotData, ...{ klass: "-height-align-25 -more" } },
    { ...planFeatures.PlusGet800ToNextPhone, ...{ klass: "-towards" } }
  ],
  perksNote: bauPerkNote(65),
  perks: [
    bauPerks.huluDisneyEspn,
    bauPerks.appleArcadeOrGooglePlay,
    bauPerks.FiftyPercentOffWatchTabletWifi,
    bauPerks.TravelPass,
    bauPerks.SixHundredGbVerizonCloud,
    bauPerks.AppleMusic,
    bauPerks.fiftyPercentOffHomeInternet
  ]
};

const plans = [
  planA,
  planB,
  planC,
  planD
].map((el) => ({
  ...el,
  get price() { return pricing[this.id - 1][getLinesCount() - 1]; }
}));

const cell6 = {
  plans,
  getPlanPriceByLine: (plan, currentLine) => (plan.price),
  allPlansInclude, pricing,
  linesDescription: pricingLinesDescription,
  linesEmptyPlanTitle,
  bau: true
};

export { cell6, priceForLine  };
