import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const SearchField = ({ searchText, onSearchFieldChange, onSearchFieldClear }) => {
  return (
    <div className="search-text-container">
      <input
        className="report-setting-search-field"
        type="text"
        value={ searchText }
        onChange={ (event) => { onSearchFieldChange(event); } }
        placeholder="Search by title"
      />
      {
        !!searchText &&
        <span
          className="clear-text"
          title="Close"
          role="button"
          onClick={ onSearchFieldClear }
        >
          <SvgIcon className="-sm" name="i-close" />
        </span>
      }
    </div>
  );
};

export default SearchField;
