import React, { useState } from 'react';
import SignIn from './signIn';
import ResetPassword from './resetPassword';

const SignInApp = () => {
  const [ signInState, setSignInState ] = useState(true);

  const handleForgotClick = () => {
    setSignInState(false);
  };

  const handleCancelClick = () => {
    setSignInState(true);
  };

  return (
    <>
      {
        signInState ?
          <SignIn onForgotClick={ handleForgotClick } />
          :
          <ResetPassword onCancel={ handleCancelClick } />
      }
    </>

  );
};

export default SignInApp;
