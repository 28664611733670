import React from 'react';
import Select from "react-select";
import {filter} from "lodash";

const FiltersItemMultiDropdown = ({ options, selectedOptions, setSelectedOptions, disabled, menuPlacement, maxMenuHeight }) => {
  const handleSelect = (data, selected) => {
    let newSelected = [];

    switch (selected.action) {
      case 'clear':
        setSelectedOptions([ { value: 0, label: "All" }]);
        break;
      case "remove-value":
        newSelected = filter(selectedOptions, (el) => el.value !== selected.removedValue.value) ;
        setSelectedOptions([ ...newSelected ]);
        break;
      case "select-option":
        newSelected = [selected.option];
        if (selected.option.value === 0) {
          setSelectedOptions(newSelected);
          break;
        }
        if (selectedOptions.length === 1 && selectedOptions[0].value === 0) {
          setSelectedOptions(newSelected);
          break;
        }
        selectedOptions.push(selected.option);
        setSelectedOptions([ ...selectedOptions ]);
        break;
      default:
        console.log(`Unsupported action: ${selected.action}`);
    }
  };

  const selectStyles = {
    control: (styles) => ({ ...styles, borderRadius: 20, fontSize: "13px" }),
    option: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93" }),
    menu: (provided) => ({ ...provided, fontSize: "13px", zIndex: 9999, color: "#787F93" }),
    multiValue: (styles, { data }) => (
      {
        ...styles, fontSize: "13px", borderRadius: 70, color: "#787F93",
        background: data.value === 0 ? "#FFF" : "#EFF4F5",
        paddingRight: data.value === 0 ? "5px" : 0
      }
    ),
    multiValueLabel: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93" }),
    valueContainer: (styles) => ({ ...styles, fontSize: "13px", paddingLeft: "5px" }),
    multiValueRemove: (styles, state) => {
      return state.data.value === 0 ? { ...styles, display: 'none', borderRadius: 'none' } : styles;
    }
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      placeholder="All"
      value={ selectedOptions.length === 1 && selectedOptions[0].value === 0 ? [] : selectedOptions }
      onChange={ handleSelect }
      isSearchable
      isDisabled={ disabled }
      menuPlacement={ menuPlacement }
      maxMenuHeight={ maxMenuHeight }
      isMulti={ true }
      styles={ selectStyles }
    />
  );
};

export default FiltersItemMultiDropdown;
