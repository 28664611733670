import React, { useState } from 'react';
import { filter } from "lodash";
import { baseContext } from "./context";
import Index from "./components/index";

const App = (props) => {
  const [ data, setData ] = useState(props.data);
  const bannedRespondents = filter(data.respondents, (respondent) => respondent.banned !== null);
  const reviewedRespondents = filter(data.respondents, (respondent) => respondent.reviewed);
  const notReviewedRespondents = filter(data.respondents, (respondent) =>
    !respondent.reviewed && respondent.banned === null
  );

  const filters = [
    { id: 0,
      title: "N",
      color: "default",
      scope: data.respondents
    },
    { id: 1,
      title: "To review",
      color: "default",
      scope: notReviewedRespondents
    },
    { id: 2,
      title: "Blocked",
      color: "warning",
      scope: bannedRespondents
    },
    { id: 3,
      title: "Reviewed",
      color: "success",
      scope: reviewedRespondents
    }
  ];

  const [ currentFilter, setCurrentFilter ] = useState(filters[0]);

  const baseContextValue = {
    data,
    setData,
    filters,
    currentFilter,
    setCurrentFilter,
    projectId: props.project_id,
    gaTrackingId: props.google_analytics_id
  };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <Index { ...props } />
    </baseContext.Provider>
  );
};

export default App;
