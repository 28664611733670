import React, { createContext } from "react";

const baseContext = createContext({});

const spotlightContext = createContext({});

const exportsContext = createContext({});

const filesContext = createContext({});

const reportsContext = createContext({});

const installationsContext = createContext({});

const spotlightsDetailsContext = createContext({});

const QualitativeContext = createContext({});

export {
  baseContext, spotlightContext, exportsContext,
  filesContext, reportsContext, installationsContext,
  spotlightsDetailsContext, QualitativeContext
};
