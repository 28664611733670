import React, {useEffect, useState} from "react";
import {map, cloneDeep, find, isEmpty, filter, includes} from "lodash";
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import FormGroup from "./summaryEditor/formGroup";
import SummaryEditorFeedbackPlate from "./summaryEditor/summaryEditorFeedbackPlate";
import SummaryEditorFeedbackSelect from "./summaryEditor/summaryEditorFeedbackSelect";

const themeLimit = 6;
const feedbackLimit = 3;

const emptyStr = (str) => {
  return str.trim() === '';
};

const uniqAnswers = (allAnswers, selectedAnswers) => {
  if (selectedAnswers) {
    return filter(allAnswers, (item) => {
      return (
        !includes(selectedAnswers, item.answer)
      );
    });
  }
  else return allAnswers;
}

const SummaryEditor = ({ summaryDataInit, cancelModal, saveModalData, disabled, allAnswers }) => {
  const [ summaryData, setSummaryData ] = useState(() => {
    if (isEmpty(summaryDataInit)) {
      return [ ];
    }
    return Object.values(cloneDeep(summaryDataInit));
  });

  const [ currentKey, setCurrentKey ] = useState(0);

  const currentDataItem = summaryData[currentKey];

  const [ openedAnswersListForAdd, setOpenedAnswersListForAdd ] = useState(false);
  const [ openedAnswersListForChange, setOpenedAnswersListForChange ] = useState(null);

  const [ searchStr, setSearchStr ] = useState('');

  const [ uniqAnswersList, setUniqAnswersList ] = useState(allAnswers);

  useEffect(() => {
    if (currentDataItem) {
      setUniqAnswersList(uniqAnswers(allAnswers, currentDataItem.answers));
    }
  }, [ summaryData ]);

  const newSummaryItem = {
    answers: [],
    long_summary: "",
    name: "New Theme",
    short_summary: ""
  };

  const onChangeField = (event, name) => {
    const newArr = [ ...summaryData ];
    newArr[currentKey][name] = event.target.value;
    setSummaryData(newArr);
  };

  const onChangeFieldSearch = (event) => {
    setSearchStr(event.target.value);
  };

  const deleteTheme = (key) => {
    const newArr = [ ...summaryData ];
    newArr.splice(key, 1);
    setSummaryData(newArr);
    setCurrentKey(0);
  };

  const deleteAnswer = (key) => {
    const newArr = [ ...summaryData ];
    newArr[currentKey].answers.splice(key, 1);
    setSummaryData(newArr);
  };

  const addAnswer = (newAnswer) => {
    const newArr = [ ...summaryData ];
    newArr[currentKey].answers.unshift(newAnswer);
    setSummaryData(newArr);
    setOpenedAnswersListForAdd(false);
    setSearchStr('');
  };

  const changeAnswer = (newAnswer, key) => {
    const newArr = [ ...summaryData ];
    newArr[currentKey].answers[key] = newAnswer;
    setSummaryData(newArr);
    setOpenedAnswersListForChange(null);
    setSearchStr('');
  };

  const changeCurrentTheme = (key) => {
    setCurrentKey(key);
    setOpenedAnswersListForAdd(false);
    setOpenedAnswersListForChange(null);
    setSearchStr('');
  };

  const addTheme = () => {
    setSummaryData([ ...summaryData, newSummaryItem ]);
    setCurrentKey(summaryData.length);
  };

  const toggleAnswersListForAdd = () => {
    setOpenedAnswersListForAdd(!openedAnswersListForAdd);
    setSearchStr('');
  };

  const toggleAnswersListForChange = (key) => {
    if (openedAnswersListForChange !== null) {
      setOpenedAnswersListForChange(null);
    } else {
      setOpenedAnswersListForChange(key);
    }
    setSearchStr('');
  };

  const someFieldsEmpty = (data) => {
    return find(data, (item) => emptyStr(item.name) || emptyStr(item.short_summary) || emptyStr(item.long_summary));
  };

  return (
    <Modal
      modalClassName="centered"
      dialogClassName="verizon-modifier -summary-editor"
      backDropModifier="verizon-modifier -summary-editor"
      modalSize="lg"
      modal
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title">
            AI Summary
          </h3>
          <ul className="summary-editor_tabs">
            {
              map(summaryData, (item, key) => (
                <li key={ key } className={ `summary-editor_tab` }>
                  <button
                    className={ `summary-editor_tab-button ${currentKey === key ? '-active' : ''}` }
                    onClick={ () => changeCurrentTheme(key) }
                  >
                    { item.name }
                  </button>
                </li>
              ))
            }
            {summaryData.length < themeLimit && (
              <li className={ `summary-editor_tab` }>
                <button
                  className={ `summary-editor_tab-button -add` }
                  onClick={ addTheme }
                >
                  <SvgIcon name="plus" />
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="summary-editor_body">

          {currentDataItem &&
            <>
              <div className="summary-editor_form">
                <div className="summary-editor_form-line">
                  <div className="summary-editor_form-grid">
                    <div className="summary-editor_form-grid-item">
                      <FormGroup title="Theme" name="name" currentDataItem={ currentDataItem } onChangeField={ onChangeField } />
                    </div>
                    <div className="summary-editor_form-grid-item">
                      <FormGroup title="Short Summary" name="short_summary" currentDataItem={ currentDataItem } onChangeField={ onChangeField } />
                    </div>
                  </div>
                </div>
                <div className="summary-editor_form-line">
                  <FormGroup title="Long Summary" name="long_summary" type="textarea" currentDataItem={ currentDataItem } onChangeField={ onChangeField } />
                </div>
                <div className="summary-editor_form-buttons -right">
                  <button
                    type="button"
                    className="button -branded"
                    onClick={ () => deleteTheme(currentKey) }
                  >
                    Delete Theme
                  </button>
                </div>
              </div>

              <div className="summary-editor_feedback">
                <div className="summary-editor_feedback-header">
                  <div className="summary-editor_feedback-title">Verbatims</div>
                  <div className="summary-editor_feedback-nums">
                    {currentDataItem.answers.length} / {feedbackLimit}
                  </div>
                </div>
                <div className="summary-editor_feedback-body">

                  <SummaryEditorFeedbackPlate title="Add Verbatim" modifier="-bordered">
                    <button
                      className="button -default -square"
                      type="button"
                      onClick={ toggleAnswersListForAdd }
                      disabled={ currentDataItem.answers.length >= feedbackLimit || openedAnswersListForChange !== null || uniqAnswersList.length === 0 }
                    >
                      {openedAnswersListForAdd ? (
                        <SvgIcon name="close" />
                      ) : (
                        <SvgIcon name="plus" />
                      )}

                    </button>
                  </SummaryEditorFeedbackPlate>

                  {openedAnswersListForAdd && (
                    <SummaryEditorFeedbackSelect
                      searchStr={ searchStr }
                      onChangeFieldSearch={ onChangeFieldSearch }
                      uniqAnswers={ uniqAnswersList }
                      action={ addAnswer }
                      currentDataItem={ currentDataItem }
                    />
                  )}

                  <ul className="summary-editor_feedback-select-list">
                    {
                      map(currentDataItem.answers, (answer, key) => (
                        <li key={ key } className="summary-editor_feedback-select-list-item">
                          <SummaryEditorFeedbackPlate
                            title={ answer }
                            modifier={ openedAnswersListForChange === key ? '-bordered' : '' }
                          >
                            <button
                              className="button -square"
                              type="button"
                              disabled={ (openedAnswersListForChange !== key && openedAnswersListForChange !== null) || openedAnswersListForAdd || uniqAnswersList.length === 0 }
                              onClick={ () => toggleAnswersListForChange(key) }
                            >
                              <SvgIcon name="change" />
                            </button>
                            {openedAnswersListForChange !== key && (
                              <button
                                className="button -square -branded"
                                type="button"
                                onClick={ () => deleteAnswer(key) }
                              >
                                <SvgIcon name="close" />
                              </button>
                            )}
                          </SummaryEditorFeedbackPlate>
                          {openedAnswersListForChange === key && (
                            <SummaryEditorFeedbackSelect
                              searchStr={ searchStr }
                              onChangeFieldSearch={ onChangeFieldSearch }
                              uniqAnswers={ uniqAnswersList }
                              action={ changeAnswer }
                              currentDataItem={ currentDataItem }
                              keyForChange={ key }
                            />
                          )}
                        </li>
                      ))
                    }

                  </ul>
                </div>
              </div>
            </>
          }

        </div>
        <div className="summary-editor_footer">
          <div className="summary-editor_footer-buttons -right">
            <button
              className="button -default"
              type="button"
              onClick={ cancelModal }
              disabled={ disabled }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ () => { saveModalData(summaryData); } }
              disabled={ disabled || someFieldsEmpty(summaryData) }
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SummaryEditor;
