import React, { useState, useContext } from 'react';
import Header from '../flex-common/header';
import Card from '../common/card';
import CardPlanTitle from '../common/cardPlanTitle';
import PlanShortDescriptionFlex from '../common/planShortDescriptionFlex';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';

const AccordionView = ({ title, children, handleOpenChange }) => {
  const [open, setOpen] = useState(false);
  const handleChange = () => {
    handleOpenChange();
    setOpen(!open);
  };
  return <div className="accordion">
    <button onClick={handleChange} className="accordion-button">
      <span className={`accordion-button-text ${open ? '-open' : ''}`}>{title}</span>
    </button>
    {open && children}
  </div>
}

const EmptyCard = () => <div className="empty-card -flex">
  <span className="empty-card-text">Not selected</span>
</div>;

const PlanCard = ({ plan, handleOpenChange, planPerksType }) => {
  const { id, name, price, description } = plan;

  return <Card className="-flex">
    <CardPlanTitle className="-checkout -flex" name={name} price={price} planPerksType={planPerksType} />
    <div className="choose-plan-card-body -checkout -flex">
      <AccordionView title="Included features & perks" handleOpenChange={handleOpenChange}>
        <div className="cards-item-description -full-width">
          <PlanShortDescriptionFlex description={description} withTypes={true} planPerksTypeCheckout={planPerksType} />
        </div>
      </AccordionView>
    </div>
  </Card>
};

const renderSelectedPlans = (handleOpenChange, selectedTypePerks) => (plan, idx) => <li key={idx} className="cards-item">
  <h6 className="cards-item-title">Line {idx + 1}</h6>
  {
    plan === null
      ? <EmptyCard/>
      : <PlanCard
          accordionTitle="Included features & perks"
          plan={plan}
          planPerksType={selectedTypePerks[idx]}
          handleOpenChange={() => handleOpenChange(idx)}
      />}
</li>

const Checkout = ({ selectedPlans, onContinue, onBack }) => {
  const { filledLines, selectedTypePerks } = useContext(LinesDataContext);

  const total = selectedPlans
      .filter(Boolean)
      .reduce((acc, { price }) => price + acc, 0);

  const handleOpenChange = (idxLine) => {
    Statistic.updateValue(`lines.${idxLine}.selectedPlanFeaturesCount`);
  };

  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.changePlanCount`)
    onBack();
  }

  return <div className="layout checkout">
    <Header/>
    <div className="checkout-header -no-margin">
      <h6 className="checkout-title">You selected a plan for Line {filledLines}</h6>
      <div className="checkout-total">Total: ${total}</div>
    </div>
    <div className="content">
      <div className="checkout-back" onClick={handleBack}>
        <h6 className="checkout-back-title">
          <svg className={ `icon -dropdown -back`}>
            <use xlinkHref={ `#svg_arrow-down` }/>
          </svg>
          <span>Back</span>
        </h6>
      </div>
      <ul className="cards-list -flex">
        {selectedPlans.map(renderSelectedPlans(
          handleOpenChange,
          selectedTypePerks
        ))}
      </ul>
    </div>
    <footer className="checkout-footer">
      <button className="button" type="button" onClick={onContinue}>Continue</button>
    </footer>
  </div>;
};

export default Checkout;