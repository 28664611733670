import Http from '../../../common/http';

const sendLogInRequest = (
  email, password, remember, goodCallback, badCallback
) => {
  Http.post('/express/api/users/sign_in', {
    user: {
      email,
      password,
      remember_me: remember
    }
  }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

const sendSignUpRequest = (
  user, goodCallback, badCallback
) => {
  Http.post('/express/api/users', { user }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

const sendForgotPasswordRequest = (
  email, goodCallback, badCallback
) => {
  Http.post('/express/api/users/password', {
    user: { email }
  }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

export {
  sendLogInRequest,
  sendSignUpRequest,
  sendForgotPasswordRequest
};
