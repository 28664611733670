import React from "react";
import BrandCarousel from "../../components/BrandCarousel";
import CategoriesList from "../../components/CategoriesList";
import Layout from "../../components/Layout";
import QuickSearch from "../../components/QuickSearch";
import RoutineCarousel from "../../components/RoutineCarousel";
import TrendCarousel from "../../components/TrendCarousel";
import WorkoutCarousel from "../../components/WorkoutCarousel";
import brands from "../../mocks/brands";
import compilations from "../../mocks/workoutCompilations";

const compilation = compilations.explore[0];

const carouselBrands = [
  brands.eqx,
  brands.soulcycle,
  brands.pureYoga,
  brands.eqx,
];

const Explore = () => (
  <Layout>
    <TrendCarousel />
    <h3 className="mx-3 mt-4">Browse by brand</h3>
    <BrandCarousel brands={carouselBrands} className="px-3" />
    <h3 className="mx-3 mt-5">Browse all routines</h3>
    <RoutineCarousel className="px-3" />
    <h3 className="mx-3 mt-5">Browse by category</h3>
    <CategoriesList className="mx-3" />
    <h3 className="mx-3 mt-5">{compilation.title}</h3>
    <WorkoutCarousel workouts={compilation.workouts} className="px-3" />
    <QuickSearch />
  </Layout>
);

export default Explore;
