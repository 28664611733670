import React, { useState } from 'react';
import Modal from '../common/modal';
import { ModalContext } from '../common/contexts';
import { BaseDetails } from './base_details';

const WithModalContext = ({ children }) => {
  const [ modalData, setModalData ] = useState(null);
  const closeModal = () => setModalData(null);
  const openModal = ({ title, data, showFooterButton }) => {
    setModalData({ title, data, showFooterButton });
  };
  const modalValue = { closeModal, openModal };
  return (
    <ModalContext.Provider value={ modalValue }>
      { children }
      {
        !!modalData &&
        <Modal
          title={ modalData.title }
          handleClose={ closeModal }
          showFooterButton={ modalData.showFooterButton }
        >
          <BaseDetails data={ modalData.data } />
        </Modal>
      }
    </ModalContext.Provider>
  );
};
export default WithModalContext;
