import React, { useState, useEffect } from "react";
import { StateContext } from "../common/contexts";
import { Router,  initialState } from '../unlimited_common/router';
import { plans, popupCopy, allPlansInclude } from "./plans_data";

const ConceptUnlimited0 = () => {
  const [path, updatePath] = useState(initialState);
  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = main.className + " unlimited-bau-header";
  }, []);
  return(
    <div className="top-split-div">
      <StateContext.Provider value={ [path, updatePath] }>
        <Router plans={ plans } allPlansInclude={ allPlansInclude } popupCopy={popupCopy}/>
      </StateContext.Provider>
    </div>
  )
};

export default ConceptUnlimited0;