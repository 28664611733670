import React, { useState, useContext } from 'react';
import PortalModal from "../../common/portal";
import TextField from "../../common/portal/textField";
import CheckboxField from "../../common/portal/checkboxField";
import { createFile, updateFile, destroyFile, fileDefaultTarget } from '../api';
import { baseContext } from "../../contexts";
import UploadButton from "../../common/uploadButton";
import RadioSet from '../../common/portal/RadioSet';
import { displayGroups, getGroupName } from "../../helpers";
import { filesPlaceToShow } from '../helper';

const FileModal = ({ file, onClose, onSave, onDelete }) => {
  const { mediaKind, allGroups, verizonType, combine5gAndLte } = useContext(baseContext);
  const [ deleteInProgress, setDeleteInProgress ] = useState(false);
  const [ saveInProgress, setSaveInProgress ] = useState(false);
  const [ title, setTitle ] = useState(file ? file.title : "");
  const [ urls ] = useState(file ? [ file.filename ] : []);
  const [ metaData, setMetaData ] = useState(file ? file.attachment_data : "");
  const [ filename, setFilename ] = useState(file ? file.filename : "");
  const [ hidden, setHidden ] = useState(file ? file.hidden : false);
  const [ target, setTarget ] = useState(file ? file.target : fileDefaultTarget);
  const [ archived, setArchived ] = useState(file ? file.archived : false);
  const [ filePlaces ] = useState(() => ({
    current: filesPlaceToShow.current(verizonType, mediaKind, combine5gAndLte),
    archived: filesPlaceToShow.archived(verizonType, mediaKind, combine5gAndLte),
    additional: filesPlaceToShow.additional(verizonType, mediaKind, combine5gAndLte)
  }));

  const formTitle = () => {
    return file ? "Edit Document" : "New Document";
  };

  const saveButtonName = () => {
    if (file) {
      return saveInProgress ? "Updating..." : "Update";
    }
    return saveInProgress ? "Creating..." : "Create";
  };

  const onCreateFile = (callback) => {
    setSaveInProgress(true);
    createFile(
      mediaKind,
      { title, metaData, hidden, filename, target, verizonType, archived },
      combine5gAndLte,
      (data) => { callback(data); }
    );
  };

  const onUpdateFile = (callback) => {
    setSaveInProgress(true);
    updateFile(
      mediaKind,
      { id: file.id, title, metaData, hidden, filename, target, archived },
      combine5gAndLte,
      (data) => { callback(data); }
    );
  };

  const saveCallback = () => {
    setSaveInProgress(true);
    const callback = (data) => {
      setSaveInProgress(false);
      onSave(data);
    };
    file ? onUpdateFile(callback) : onCreateFile(callback);
  };

  const deleteCallback = () => {
    if (window.confirm("Do you really want to delete this file?")) {
      setDeleteInProgress(true);
      destroyFile(
        mediaKind, verizonType, file.id, combine5gAndLte,
        (data) => {
          setDeleteInProgress(false);
          onDelete(data);
        }
      );
    }
  };

  const handleUpload = (files) => {
    const file = files[0];
    setFilename(file.filename);
    setMetaData(file.metaData);
  };

  return (
    <PortalModal
      className="-sm"
      title={ formTitle() }
      open
      handleClose={ onClose }
      saveCallback={ saveCallback }
      deleteItem={ deleteCallback }
      showDelete={ !!file }
      buttonName={ saveButtonName() }
      disabledSave={ deleteInProgress || saveInProgress || !title || !metaData }
      labelDelete={ deleteInProgress ? "Deleting..." : "Delete" }
      disabledDelete={ deleteInProgress || saveInProgress }
    >
      <div className="survey-form">
        <TextField label="Link Title" value={ title } onChange={ setTitle } />
        <RadioSet
          label="Display group:"
          value={ getGroupName(archived) }
          onChange={ (name) => { setArchived(name === displayGroups[1]); } }
          fields={ allGroups }
        />
        <RadioSet
          label="Place to show:"
          value={ target }
          onChange={ setTarget }
          fields={
            {
              documents: filePlaces.additional,
              archive: filePlaces.archived,
              current: filePlaces.current
            }
          }
        />
        <CheckboxField label="Hidden" checked={ hidden } onChange={ setHidden } />
        <div className="form-group">
          <div className="form-group_control -button">
            <UploadButton
              className="button -sm -upper"
              needPreview={ false }
              filenameOnBottom
              onUploaded={ handleUpload }
              initialList={ urls }
            >
              Upload file
            </UploadButton>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default FileModal;
