import React, { useContext } from 'react';
import classnames from "classnames";
import {
  allProjectsSortClientClick,
  allProjectsSortClosedAtClick, allProjectsSortCompletesClick,
  allProjectsSortCreatedAtClick,
  allProjectsSortInFieldAtClick, allProjectsSortStatusClick, allProjectsSortTargetCompletesClick,
  allProjectsSortTitleClick,
  allProjectsSortTypeClick
} from "../../../../common/google-analytics/events";
import { IndexData } from "./contexts";

const SortableHeader = ({ className, title, field }) => {
  const data = useContext(IndexData);

  const classes = () => {
    return {
      '-asc': field == data.sort && data.dir == 'asc',
      '-desc': field == data.sort && data.dir == 'desc'
    };
  };

  const events = {
    title: allProjectsSortTitleClick,
    express: allProjectsSortTypeClick,
    client_name: allProjectsSortClientClick,
    created_at: allProjectsSortCreatedAtClick,
    in_field_at: allProjectsSortInFieldAtClick,
    closed_at: allProjectsSortClosedAtClick,
    status_text: allProjectsSortStatusClick,
    respondents_count: allProjectsSortCompletesClick,
    required_nsize: allProjectsSortTargetCompletesClick
  };

  const change = (e) => {
    e.preventDefault();
    events[field](data.gaTrackingId);

    if (data.sort != field || !data.dir || data.dir === 'desc') {
      data.setDir('asc');
    } else if (data.dir === 'asc') {
      data.setDir('desc');
    }

    data.setSort(field);
  };

  return (
    <th className={ className }>
      <a
        href="#"
        className={ classnames("sort-link", classes()) }
        onClick={ change }
      >
        { title }
      </a>
    </th>
  );
};

export default SortableHeader;
