import React from 'react';
import { round } from "lodash";

const svgSize = 310;
const outerRadius = 100;
const donutWidth = outerRadius * 0.666;
const paddingBottom = -5;
const redLevel = 33.333;
const greenLevel = 66.666;

const getXY = (angle, radius) => {
  const x = Math.cos((angle * Math.PI) / 180);
  const y = Math.sin((angle * Math.PI) / 180);
  const coordX = x * radius + svgSize / 2;
  const coordY = y * -radius + svgSize / 2 + paddingBottom;
  return [ coordX, coordY ];
};

const getCoordFromDegrees = (angle, radius) => {
  return getXY(angle, radius).join(' ');
};

const percentToDegrees = (percent) => {
  return percent * 1.8;
};

const getSliceCommands = (percentFrom, percentTo, radius, borderSize) => {
  const degreesFrom = percentToDegrees(percentFrom);
  const degreesTo = percentToDegrees(percentTo);
  const innerRadius = radius - borderSize;

  const commands = [];
  commands.push(`M ${getCoordFromDegrees(degreesFrom, radius)}`);
  commands.push(`A ${radius} ${radius} 0 0 0 ${getCoordFromDegrees(degreesTo, radius)}`);
  commands.push(`L ${getCoordFromDegrees(degreesTo, innerRadius)}`);
  commands.push(`A ${innerRadius} ${innerRadius} 0 0 1 ${getCoordFromDegrees(degreesFrom, innerRadius)}`);
  return commands.join(' ');
};

const angle = (red, green, v) => {
  if (v < red) {
    return redLevel * v / red;
  }

  if (v >= green) {
    return greenLevel + (100.0 - greenLevel) * (v - green) / (100.0 - green);
  }

  return redLevel + (greenLevel - redLevel) * (v - red) / (green - red);
};

const HalfDonut = ({ red, green, value, color }) => {
  const normValue = angle(red, green, value);

  const [ textX, textY ] = getXY(percentToDegrees(100 - normValue), 120);

  const [ centerX, centerY ] = getXY(0, 0);
  const [ x0, y0 ] = getXY(percentToDegrees(100 - normValue) - 0.25, 65);
  const [ x1, y1 ] = getXY(percentToDegrees(100 - normValue) + 0.25, 65);
  const [ x2, y2 ] = getXY(percentToDegrees(100 - normValue) + 35, 5);
  const [ x3, y3 ] = getXY(percentToDegrees(100 - normValue) - 35, 5);

  return (
    <svg viewBox={ `0 0 ${svgSize} 160` }>
      <path className="red" d={ getSliceCommands(100 - redLevel + 0.2, 100, outerRadius, donutWidth) } />
      <path className="orange" d={ getSliceCommands(100 - greenLevel + 0.2, 100 - redLevel - 0.2, outerRadius, donutWidth) } />
      <path className="green" d={ getSliceCommands(0, 100 - greenLevel - 0.2, outerRadius, donutWidth) } />

      <text className={ color } fontFamily="Arial" fontSize="28px" fontWeight="bold" x={ textX - 10 } y={ textY }>{round(value)}</text>

      <text
        fill="white" fontFamily="Arial" fontWeight="bold" fontSize="10px"
        x={ svgSize / 2 - outerRadius + 4 }
        y={ svgSize / 2 + paddingBottom - 5 }
      >
        Below Norm
      </text>

      <text
        fill="white" fontFamily="Arial" fontWeight="bold" fontSize="10px"
        x={ svgSize / 2 - 28 }
        y={ svgSize / 2 - outerRadius + 30 }
      >
        Within Norm
      </text>

      <text
        fill="white" fontFamily="Arial" fontWeight="bold" fontSize="10px"
        x={ svgSize / 2 + outerRadius - donutWidth + 3 }
        y={ svgSize / 2 + paddingBottom - 5 }
      >
        Above Norm
      </text>

      <circle fill="black" cx={ centerX } cy={ centerY } r={ 8 } />
      <circle fill="white" cx={ centerX } cy={ centerY } r={ 5 } />

      <path fill="black" d={ `M ${x0} ${y0} L ${x1} ${y1} L ${x2} ${y2} L ${x3} ${y3}` } />
    </svg>
  );
};

export default HalfDonut;
