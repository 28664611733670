import React from 'react';

const CustomQuestionsHeader = ({ max, onAdd, disabled }) => {
  const handleOnClick = (event) => {
    event.preventDefault();
    onAdd();
  };

  return (
    <>
      <div className="form_section-title -grid-between">
        <div className="form_section-title-item">
          <h3>Custom Questions</h3>
          <div className="form-question_type-text">
            Add up to {max} custom questions
          </div>
        </div>
        <div className="form_section-title-item">
          <button
            type="button"
            className="button -js-add-question"
            onClick={ handleOnClick }
            disabled={ disabled }
          >
            Add Custom Question
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomQuestionsHeader;
