import React from "react";
import { sumAndFormatCheckFractional } from '../common/helper';

export const LineTotal = ({ price, save }) => {
  return (
    <div className="line-total">
      <div className="line-total_note">
        Plan total
      </div>
      <div className="line-total_list">
        <div className="line-total_item">
          ${price}/mo
        </div>
        {
          !!save &&
          <div className="line-total_item -accent">
            ${sumAndFormatCheckFractional(save)}/mo savings
          </div>
        }
      </div>
    </div>
  );
};
