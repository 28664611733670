import React, { useContext, useState } from 'react';
import SvgIcon from "../../../../../common/components/svg-icon";
import { baseContext } from "../../context";
import TopFilter from "../../../../researcher/common/filters/app";
import { updateParams } from "../../requests";

const DataFilters = ({ setFilterIsOpen }) => {
  const { consts, data, paramsId, setData, setError, setTableLoading } = useContext(baseContext);

  const [ dataStub, setDataStub ] = useState(
    {
      filter: data.data_filters,
      filter_options: consts.filter_options
    }
  );

  const loadData = (newFilter) => {
    dataStub.filter = newFilter;
    setDataStub({ ...dataStub });
    setTableLoading(true);
    updateParams(paramsId, { data_filters: JSON.stringify(dataStub.filter) }, setData, setError);
  };

  const onClose = () => {
    dataStub.filter.demographics = [];
    setDataStub({ ...dataStub });
    setFilterIsOpen(false);
    setTableLoading(true);
    updateParams(paramsId, { data_filters: JSON.stringify(dataStub.filter) }, setData, setError);
  };

  return (
    <div className="concepts-database_filters">
      <div className="close-icon" onClick={ onClose }>
        <SvgIcon name="i-close" />
      </div>

      <h3>Data Filter</h3>

      <TopFilter
        data={ dataStub }
        loadData={ loadData }
      />
    </div>
  );
};

export default DataFilters;
