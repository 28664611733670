import React from 'react';
import remoteFiles from '../../../common/remote_files';

const LandingMap = () => {
  const { landingMap } = remoteFiles.visibleCom;
  return (
    <section className="landing-map">
      <div className="landing-map_text">
        <h3 className="landing-map_title">
          We’ve got you covered.
        </h3>
        <div className="landing-map_p">
          With over 99% of the U.S. population covered, there aren’t many places Verizon's Networks haven’t been...yet.
        </div>
      </div>
      <div
        className="landing-map_image"
        style={ { backgroundImage: `url(${landingMap})` } }
      />
    </section>
  );
};

export default LandingMap;
