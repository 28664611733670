import React, {
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import { map } from "lodash";
import UploadButton from "../../../common/components/upload_button";
import { sendDisableNextButton, sendEnableNextButton } from '../common/services/angular_messaging';
import Notification from '../common/notification';
import ImageWithMarkups from '../common/image_with_markups';
import { noop } from '../common/image_with_markups/helper';
import { globalContext } from './contexts';
import HowItWorks from './howItWorks';

const HiddenInputs = ({ id, conceptId, metaData, area }) => {
  const {
    left, top, width, height,
    imageWidth, imageHeight
  } = area;
  return (
    <div className="products-greed-item-hidden-inputs">
      <input type="hidden" name="project[express_images][][id]" value={ id } />
      <input type="hidden" name="project[express_images][][concept_id]" value={ conceptId } />
      <input type="hidden" name="project[express_images][][shelf_data]" value={ metaData } />
      <input type="hidden" name="project[express_images][][image_width]" value={ Math.round(imageWidth || 0) } />
      <input type="hidden" name="project[express_images][][image_height]" value={ Math.round(imageHeight || 0) } />
      <input type="hidden" name="project[express_images][][area_left]" value={ Math.round(left || 0) } />
      <input type="hidden" name="project[express_images][][area_top]" value={ Math.round(top || 0) } />
      <input type="hidden" name="project[express_images][][area_height]" value={ Math.round(height || 0) } />
      <input type="hidden" name="project[express_images][][area_width]" value={ Math.round(width || 0) } />
    </div>
  );
};

const PackagePopup = ({ title, url }) => {
  return (<div className="tooltip -express-tooltip-preview fade top in -shelf-concept" role="tooltip">
    <div className="tooltip-arrow" />
    <div className="tooltip-inner">
      <div className="popup-title -no-description">{title}</div>
      <div className="popup-image">
        <img src={ url } alt="undefined" />
      </div>
    </div>
  </div>);
};

const PackageHeader = (props) => {
  const { title, productName, productUrl } = props;
  const modalButtonRef = useRef(null);
  const [ popupVisible, setPopupVisible ] = useState(false);

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (modalButtonRef && modalButtonRef.current) {
      modalButtonRef.current.addEventListener('mouseenter', showPopup);
      modalButtonRef.current.addEventListener('touchstart', showPopup);
      modalButtonRef.current.addEventListener('mouseleave', hidePopup);
      modalButtonRef.current.addEventListener('touchend', hidePopup);
    }
    return () => {
      if (modalButtonRef && modalButtonRef.current) {
        modalButtonRef.current.removeEventListener('mouseenter', showPopup);
        modalButtonRef.current.removeEventListener('touchstart', showPopup);
        modalButtonRef.current.removeEventListener('mouseleave', hidePopup);
        modalButtonRef.current.removeEventListener('touchend', hidePopup);
      }
    };
  }, [ showPopup, hidePopup ]);

  return (
    <div className="products-greed-item-header">
      <span className="products-greed-item-title">
        { title }
      </span>
      <div className="products-greed-item-modal-wrapper">
        <span
          ref={ modalButtonRef }
          onContextMenu={ noop }
          className="products-greed-item-show-modal"
        >
          Target package preview
        </span>
        {
          popupVisible &&
          <PackagePopup
            title={ productName }
            url={ productUrl }
          />
        }
      </div>
    </div>
  );
};

const Package = ({ data, imageData, updateImage, previousImageData }) => {
  const [ area, setArea ] = useState(data.areas[0]);
  const [ loading, setLoading ] = useState(false);
  const [ uploading, setUploading ] = useState(false);
  const { increaseGlobalLoading, decreaseGlobalLoading } = useContext(globalContext);
  const [ notificationObject, updateNotificationObject ] = useState("");

  const onInitUploading = () => {
    increaseGlobalLoading();
    setUploading(true);
  };
  const onUploaded = (files) => {
    const [ file ] = files;
    const { previewSrc: url, metaData } = file;
    updateImage(url, metaData);
    decreaseGlobalLoading();
    setUploading(false);
  };
  const onError = (args) => {
    updateNotificationObject({
      message: "Can not upload an image",
      success: false
    });
    decreaseGlobalLoading();
    setUploading(false);
  };
  const handleDeleteImage = () => {
    data.areas = [ {} ];
    setArea({});
    updateImage(null, null);
  };

  const handleSelectionChange = (newArea) => {
    setArea(newArea);
  };
  return (
    <div>
      <PackageHeader
        title={ data.concept_name }
        productName={ data.product_name }
        productUrl={ data.concept_url }
      />
      <div className="products-greed-item-image-wrapper">
        {
          imageData.url &&
          <div
            className="image-delete"
            title="Delete Image"
            onClick={ handleDeleteImage }
          />
        }

        {
          imageData.url ?
            <ImageWithMarkups
              initialAreas={ data.areas } // left, top, height, width, imageWidth, imageHeight
              src={ imageData.url }
              loading={ loading }
              updateLoading={ setLoading }
              onSelectionChange={ handleSelectionChange }
              oneArea
            />
            :
            <span className="products-greed-item-add-image-tip">
              Add shelf image
            </span>
        }
        <HiddenInputs
          id={ data.id || 'null' }
          conceptId={ data.concept_id || 'null' }
          metaData={ imageData.metaData || 'null' }
          area={ area }
        />
        <div className="products-greed-item-actions">
          <UploadButton
            className="products-greed-item-button -primary"
            needPreview
            thumbnailWidth={ 1024 }
            onInit={ onInitUploading }
            onUploaded={ onUploaded }
            onError={ onError }
          >
            Upload Image
          </UploadButton>
          {
            previousImageData &&
            previousImageData.url !== imageData.url &&
            !loading && !uploading &&
            <button
              type="button"
              onClick={ () => { updateImage(previousImageData.url, previousImageData.metaData); } }
              className="products-greed-item-button -action"
            >
              Use previous image
            </button>
          }
        </div>
      </div>
      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { updateNotificationObject(null); } }
        interval={ 10 }
      />
    </div>
  );
};

const DataWrapper = ({ data }) => {
  const [ imagesData, setImagesData ] = useState(
    map(data, (el) => ({ url: el.url, metaData: el.meta_data }))
  );

  const updateImageData = (index) => (url, metaData) => {
    setImagesData((oldList) => {
      oldList[index].url = url;
      oldList[index].metaData = metaData;
      return [ ...oldList ];
    });
  };

  return (
    <>
      <ul className="products-greed-list">
        {
          map(data, (el, index) => (
            <li key={ `Package${index}` } className="products-greed-item">
              <Package
                data={ el }
                imageData={ imagesData[index] }
                updateImage={ updateImageData(index) }
                previousImageData={ index && imagesData[index - 1].url ? imagesData[index - 1] : null }
              />
            </li>
          ))
        }
      </ul>
    </>
  );
};

const Index = ({ shelfInit, data, projectId }) => {
  const [ shelf, setShelf ] = useState(shelfInit);
  const [ globalLoadingNum, setGlobalLoadingNum ] = useState(0);

  const handleShelfChange = (event) => {
    const value = event.target.checked;
    setShelf(value);
  };

  useEffect(() => {
    if (globalLoadingNum) {
      sendDisableNextButton(projectId);
    } else {
      sendEnableNextButton(projectId);
    }
  }, [ globalLoadingNum ]);

  const globalContextValue = {
    decreaseGlobalLoading: () => {
      setGlobalLoadingNum((oldVal) => {
        return oldVal ? oldVal - 1 : 0;
      });
    },
    increaseGlobalLoading: () => {
      setGlobalLoadingNum((oldVal) => {
        return oldVal + 1;
      });
    }
  };

  return (
    <globalContext.Provider value={ globalContextValue }>
      <div className="form -bigger-font">
        <input type="hidden" name="project[shelf]" value={ shelf } />
        <div className="form_group -relative">
          <div className="form_section -section-relative">
            <div className="form_section-title -grid">
              <div className="form_section-title-item">
                <h3>Shelf Set Up for including Find Time exercise</h3>
              </div>
              <div className="form_section-title-item">
                <div className="info-tooltip">
                  <div className="info-tooltip_control">
                    <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
                  </div>
                  <div className="info-tooltip_drop -right">
                    <div className="info-tooltip_drop-inner">
                      In order to use the Find Time exercise,
                      { ' ' }
                      you must upload an image of the package on a shelf
                      { ' ' }
                      with other products. If testing multiple packages,
                      { ' ' }
                      only the test package should differ between the images.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form_section-title -grid">
            <div className="form_section-title-item -grid">
              <div className="toggle">
                <input
                  className="hide"
                  type="checkbox"
                  id="include"
                  checked={ shelf }
                  onChange={ (e) => handleShelfChange(e) }
                />
                <label className="form-switch" htmlFor="include" />
                <label className="form-switch-text" htmlFor="include">
                  Include a Find Time exercise
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="form_group -relative">
          {
            !shelf &&
            <div className="shelf-text">
              The Shelf Exercise is optional.
              { ' ' }
              If you want to ask Find Time for each package,
              { ' ' }
              you will need to add a shelf image for each design below.
              { ' ' }
              Respondents will be asked to locate a specific package from the shelf.
            </div>
          }
          {
            shelf &&
            <>
              <div className="shelf-text">
                Shelf Image guidelines:
                <ul>
                  <li>Recommended dimensions: 1000px X 1000px and higher</li>
                  <li>Image orientation can be portrait or landscape</li>
                  <li>Aspect ratio can be square</li>
                  <li>In local language</li>
                </ul>
              </div>
              <div className="shelf-how-it-works"><HowItWorks /></div>
              <div className="products-greed -wrapper -with-min-width">
                <DataWrapper data={ data } />
              </div>
            </>

          }
        </div>
      </div>
    </globalContext.Provider>
  );
};

export default Index;
