import React from 'react';
import PackageMc from './package_mc';
import Swipe from './swipe';
import ConceptsTest from './concepts_test';
import Naming from './naming';
import Package from './package';
import FlavorsSequentialTurf from './flavors_sequential_turf';
import Claim from './claim';
import Adcept from './adcept';
import BrandSnapshot from './brand_snapshot';

const TourApp = ({ express, redirectPath, texts, isTurf, isMaxDiff }) => {
  const props = { redirectPath, texts };
  return (
    <>
      { express === 'adcept' && <Adcept { ...props } /> }
      { express === 'swipe' && <Swipe { ...props } /> }
      { express === 'claim' && <Claim { ...props } /> }
      { express === 'concepts_test' && <ConceptsTest { ...props } /> }
      { express === 'naming' && <Naming { ...props } /> }
      { (express === 'package') && <Package { ...props } /> }
      { (express === 'package_mc') && <PackageMc { ...props } /> }
      {
        express === 'flavor' && !isMaxDiff && !isTurf &&
        <Naming { ...props } />
      }
      {
        express === 'flavor' && !isMaxDiff && isTurf &&
        <FlavorsSequentialTurf { ...props } />
      }
      {
        express === 'flavor' && isMaxDiff &&
        <Claim { ...props } />
      }
      { express === 'brand_snapshot' && <BrandSnapshot { ...props } /> }
    </>
  );
};

export default TourApp;
