import React, { useState, useContext } from 'react';
import { trim } from 'lodash';
import Modal from '../../../../../common/components/modal';
import { copyProject } from '../../requests';
import { IndexData } from "./contexts";

const CopyProject = ({ project, onClose }) => {
  const { id, title, copy_path } = project;
  const { loadProjects } = useContext(IndexData);
  const [ value, setValue ] = useState("");
  const [ error, setError ] = useState(null);

  const checkInvalid = () => {
    let tempError = null;
    if (trim(value) === trim(title)) {
      tempError = "New project name is the same as the previous one.";
    } else if (!trim(value)) {
      tempError = "New project name can not be blank";
    }
    setError(tempError);
    return tempError;
  };

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  const handleMakeCopy = () => {
    if (!checkInvalid()) {
      copyProject(id, value, copy_path, () => {
        loadProjects();
        onClose();
      });
    }
  };

  return (
    <Modal
      dialogClassName="-centered-form"
      showCross
      onClose={ onClose }
    >
      <h2>Copy "{ title }"</h2>
      <div className="copy-form">
        <div className="label">New project name</div>
        <div className="form-group">
          <input
            type="text"
            className="form-field -block"
            value={ value }
            onChange={ handleOnChange }
          />
          {
            !!error &&
            <div className="error">{ error }</div>
          }
        </div>
      </div>
      <div className="buttons">
        <button className="button -cancel" onClick={ onClose }>Cancel</button>
        <button className="button" onClick={ handleMakeCopy }>Start</button>
      </div>
    </Modal>
  );
};

export default CopyProject;
