import React, { useState } from "react";
import { map } from "lodash";

const Timeframe = ({ values, initValue, onChange }) => {
  const [ timeframeVal, setTimeframeVal ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setTimeframeVal(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="form_section -js-timeframe">
      <div className="form_grid">
        <div className="form_grid-item -width-full">
          <div className="form_section-title-item">
            <h3 className="colored-header">Timeframe of Usage</h3>
            <div className="form_section-text">
              { "Select the timeframe of Category and Brand usage - we will ask respondents which category/brand they've used in this timeframe." }
            </div>
          </div>

          <div className="form_section-tabs">
            <div className="radio-tabs -borderer -gray-bg">
              {map(values, (value, key) => (
                <label key={ key } id={ `label-${key}` } className="radio-tabs_item">
                  <input
                    id={ `brand_name_timeframe_${key}` }
                    type="radio"
                    checked={ timeframeVal === key }
                    value={ key }
                    onChange={ handleOnChange }
                  />
                  <div className="radio-tabs_label">{value}</div>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeframe;
