import React from 'react';

const AboutPillars = () => {
  const pillarsList = [
    { title: 'Racial Equity',
      text: 'Amplifying the voices and ideas of those held back by the barriers of racism.',
      color: 'lilac'
    },
    { title: 'Mental Wellness',
      text: 'Normalizing mental health by encouraging open dialogue and removing ' +
        'obstacles to getting help and support.',
      color: 'mint'
    },
    { title: 'Civic Action',
      text: 'Engage with community minded individuals to lead, give back, and inspire change.',
      color: 'peach'
    }
  ];

  return (
    <section className="about-pillars">
      <h3 className="about-pillars_title">
        Our Pillars
      </h3>
      <div className="about-pillars_text">
        These values helped us to develop the three key pillars that drive
        our <span className="about-pillars_text-accent">Visible Connect Social Good</span> initiative:
      </div>
      <ul className="about-pillars_list">
        {pillarsList.map((pillar, index) => {
          return (
            <li className="about-pillars_item" key={ `step-${index}` }>
              <div className={ `about-pillars_item-plate -${pillar.color}` }>
                <div className="about-pillars_item-icon">
                  <svg className={ `icon -star` }>
                    <use xlinkHref={ `#svg_star` } />
                  </svg>
                </div>
                <div className="about-pillars_item-title">{pillar.title}</div>
                <div className="about-pillars_item-text">{pillar.text}</div>
              </div>
            </li>
          );
        })}
      </ul>

    </section>
  );
};

export default AboutPillars;
