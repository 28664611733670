import React, { useState, useEffect } from 'react';
import { map }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../../common/render_raw_html';

const mobileTrigger = 767;

const Grid = ({
  question, gridAnswers, updateGridAnswers,
  updateDisabled, alwaysMobileView
}) => {
  const [ isMobile, setIsMobile ] = useState(alwaysMobileView);
  const rows = question.detailed_answers || question.answers;
  const disabledItems = question.disabledItems || {};
  const onSelectAnswer = question.onSelectAnswer;
  const disabledCallback = question.disabledCallback;
  const validationObject = question.validationObject || {};

  useEffect(() => {
    const handleResizeEvent = () => {
      setIsMobile(alwaysMobileView || window.innerWidth <= mobileTrigger);
    };
    handleResizeEvent();
    addEventListener("resize", handleResizeEvent);
    return () => {
      removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  const selectGridAnswer = (event, row) => {
    const added = { [row.id]: event.target.value };
    const newAnswers = { ...gridAnswers, ...added };
    onSelectAnswer && onSelectAnswer(newAnswers, added);
    updateDisabled && updateDisabled(disabledCallback ? disabledCallback(newAnswers) : false);
    updateGridAnswers(newAnswers);
  };

  const gridItemChecked = (row, scale) => {
    const rowId = row?.id ? (gridAnswers || {})[row.id] : null;

    return parseInt(rowId) === parseInt(scale?.id);
  };

  const renderInnerScales = (row, scale) => {
    return (
      <td key={ `${row.id}-${scale.id}` }>
        <div>
          <div className="radio">
            <input
              type="radio"
              id={ `${row.id}-${scale.id}` }
              name={ row.id }
              value={ scale.id }
              onChange={ (event) => { selectGridAnswer(event, row); } }
              checked={ gridItemChecked(row, scale) }
              disabled={ disabledItems[scale.id] === row.id }
              aria-label={ `${row.label} ${scale.label}` }
            />
            <label htmlFor={ `${row.id}-${scale.id}` } className="radio_label -no-text">
              <span className="radio_label_hidden-text" aria-hidden>{`${row.label} ${scale.label}`}</span>
            </label>
          </div>
        </div>
      </td>
    );
  };

  const renderMobileInnerScales = (row, scale) => {
    return (
      <div className="survey-options_rating-col -custom-fix" key={ `${row.id}-${scale.id}` }>
        <div className="survey-options_rating-col-header -show-mobile">
          <div aria-hidden className="survey-options_rating-legend" { ...renderRawHtml(scale.label) } />
          {/*<div className="survey-options_rating-value">{ scale.id }</div>*/}
        </div>
        <div className="radio">
          <input
            type="radio"
            name={ row.id }
            value={ scale.id }
            id={ `${row.id}-${scale.id}` }
            onChange={ (event) => { selectGridAnswer(event, row); } }
            checked={ gridItemChecked(row, scale) }
            disabled={ disabledItems[scale.id] === row.id }
            aria-label={ `${scale.label}` }
          />
          <label htmlFor={ `${row.id}-${scale.id}` } className="radio_label -no-text">
            <span className="radio_label_hidden-text" aria-hidden>{`${scale.label}`}</span>
          </label>
        </div>
      </div>
    );
  };

  const renderRow = (question, row) => {
    return (
      // <tr key={ row.id } role="radiogroup">
      <tr key={ row.id }>
        <th>
          <div className="survey-options_row-name">
            <div { ...renderRawHtml(row.label) } />
          </div>
        </th>
        { map(question.scales, (scale) => renderInnerScales(row, scale)) }
      </tr>
    );
  };

  const renderMobileRow = (question, row) => {
    return (
      <div className="survey-options_rating-grid-row" key={ row.id } role="radiogroup" aria-label={ row.label }>
        <div className="survey-options_rating-col -name">
          <h4 className="survey-options_rating-name" { ...renderRawHtml(row.label) } />
        </div>
        { map(question.scales, (scale) => renderMobileInnerScales(row, scale)) }
      </div>
    );
  };

  const renderTopScale = (scale) => (
    <th className={ classnames({ '-custom_columns': question.custom_columns }) } key={ scale.id }>
      <div
        className="survey-options_rating-legend"
        { ...renderRawHtml(scale.label) }
      />
      {/*<div className="survey-options_scale-value">{ scale.id }</div>*/}
    </th>
  );

  const renderMobileTopScale = (scale) => (
    <div
      className={
        classnames(
          "survey-options_rating-col -custom-fix",
          { '-custom_columns': question.custom_columns }
        )
      }
      key={ scale.id }
    >
      <div
        className="survey-options_rating-legend"
        { ...renderRawHtml(scale.label) }
      />
      {/*<div className="survey-options_rating-value">{ scale.id }</div>*/}
    </div>
  );

  const renderQuestion = (question) => {
    return (
      <>
        <thead>
          <tr>
            <th />
            { map(question.scales, (scale) => renderTopScale(scale)) }
          </tr>
        </thead>
        <tbody>
          { map(rows, (row) => renderRow(question, row)) }
        </tbody>
      </>
    );
  };

  const renderMobileQuestion = (question) => {
    return (
      <div
        className={
          classnames(
            "survey-options_rating",
            { '-force-mobile': alwaysMobileView }
          )
        }
        key={ question.name }
      >
        <div className="survey-options_rating-header -hide-mobile">
          <div className="survey-options_rating-grid-row">
            <div className="survey-options_rating-col -name" />
            { map(question.scales, (scale) => renderMobileTopScale(scale)) }
          </div>
        </div>
        <div className="survey-options_rating-grid">
          { map(rows, (row) => renderMobileRow(question, row)) }
        </div>
      </div>
    );
  };

  const gridOutput = () => (
    <div className="survey-options_table-wrapper">
      <table className="survey-options-table" aria-label="Please select one option for each table column">
        {
          renderQuestion(question)
        }
      </table>
    </div>
  );

  const gridMobileOutput = () => (
    <ul className="survey-options_list">
      {
        renderMobileQuestion(question)
      }
    </ul>
  );

  return (
    <>
      {
        validationObject.showWrapper &&
        <div className="multi-error-message -center">
          {
            !!validationObject.message &&
            <span { ...renderRawHtml(validationObject.message) } />
          }
        </div>
      }
      { !isMobile && gridOutput() }
      { isMobile && gridMobileOutput() }
    </>
  );
};

export default Grid;
