import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import NoteMessage from '../noteMessage';
import Checkbox from '../../../../common/form/checkbox';
import renderRawHtml from '../../../../../../common/render_raw_html';

const MainPanelSection = () => {
  const {
    production, getProdegeProjectId, getProdegeProjectIdForRecreate,
    getProdegeCreateNew, setProdegeCreateNew, inputsDisabled,
    completesCount, numberOfTargetCompletes
  } = useContext(PanelServiceContext);
  const prodegeProjectId = getProdegeProjectId();
  const prodegeProjectIdForRecreate = getProdegeProjectIdForRecreate();
  const prodegeCreateNew = getProdegeCreateNew();
  const showCheckbox = !!prodegeProjectId || !!prodegeProjectIdForRecreate;
  const onlyNew = !prodegeProjectId && !prodegeProjectIdForRecreate;
  const clearedButPreviouslyCreatedProject = !prodegeProjectId && !!prodegeProjectIdForRecreate;
  const projectIdIsSetup = !!prodegeProjectId;
  const disabled = inputsDisabled();
  const createNewMessage = [
    'or create a new one and launch it',
    '(if checkbox below is checked).<br/>',
    'When creating a new Prodege project, N size for quotas will be equal to',
    '[number of target completes] - [number of completes] =',
    `${numberOfTargetCompletes} - ${completesCount} =`,
    `${numberOfTargetCompletes - completesCount}`
  ].join(' ');
  return (
    <>
      {
        production &&
        <>
          <NoteMessage>
            {
              showCheckbox &&
              <>
                <>
                  { prodegeProjectId ? 'Current' : 'Previously created' } project in
                  {' '}
                  Prodege has the following
                  {' '}
                  ID = { prodegeProjectId || prodegeProjectIdForRecreate }.
                </>
                <br />
              </>
            }
            {
              !disabled &&
                <>
                  {
                    onlyNew &&
                    <>
                      No Prodege projects exists, we will setup a new one.
                    </>
                  }
                  {
                    clearedButPreviouslyCreatedProject &&
                    <>
                      System can switch to already existing Prodege project
                      { ' ' }
                      <span { ...renderRawHtml(createNewMessage) } />
                    </>
                  }
                  {
                    projectIdIsSetup &&
                    <>
                      Updating the settings will check/update the existing Prodege project
                      { ' ' }
                      (if the issue appeared while creation)
                      { ' ' }
                      <span { ...renderRawHtml(createNewMessage) } />
                    </>
                  }
                </>
            }
          </NoteMessage>
          {
            showCheckbox &&
              <Checkbox
                title="Create brand new Prodege project"
                value={ prodegeCreateNew }
                onChange={ setProdegeCreateNew }
                className={ '-no-before-top' }
                disabled={ disabled }
              />
          }
        </>
      }
    </>
  );
};
export default MainPanelSection;
