import remoteFiles from '../../../common/remote_files';
const { AndrewPng } = remoteFiles.equinox;
import brands from "./brands";

const instructors = [
  {
    imgUrl: AndrewPng,
    firstName: "Andrew",
    lastName: "Slane",
    brand: brands.eqx,
    bio:
      "Andrew was born in Glasgow, Scottland, grew up in South Florida, before finally settling..."
  },
  {
    imgUrl: AndrewPng,
    firstName: "Andrew",
    lastName: "Slane",
    brand: brands.eqx,
    bio:
      "Andrew was born in Glasgow, Scottland, grew up in South Florida, before finally settling..."
  },
  {
    imgUrl: AndrewPng,
    firstName: "Andrew",
    lastName: "Slane",
    brand: brands.eqx,
    bio:
      "Andrew was born in Glasgow, Scottland, grew up in South Florida, before finally settling..."
  }
];

export default instructors;
