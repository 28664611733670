import React, { useRef, useContext, useEffect } from "react";
import classnames from "classnames";
import { DataContext } from '../../data-context';

const ProductCard = ({
  product, usesCount, cartWithPrices, canDrag, openModalEdit
}) => {
  const { createDragDropForProduct } = useContext(DataContext);
  const productRef = useRef();
  useEffect(
    () => {
      return createDragDropForProduct(productRef.current, product, canDrag);
    },
    [ canDrag, product ]
  );
  return (
    <>
      <div
        className={ classnames("product", { '-with-price': cartWithPrices }) }
        onClick={ openModalEdit }
      >
        <div
          className="image"
          ref={ productRef }
        >
          <img src={ product.product_image_src } draggable="false" />
        </div>
        <div className="label">
          {product.name}
        </div>
        {cartWithPrices &&
        <div className={ classnames("price", { '-zero': !product.price }) }>
          {product.price ? `$${product.price.toFixed(2)}` : '$0.00'}
        </div>}
        {usesCount &&
        <div className="count">
          {usesCount}
        </div>}
      </div>
      {product.target && <hr />}
    </>
  );
};

export default ProductCard;
