import React, { useState, useContext } from 'react';
import Header from '../flex-common/header';
import PlanDetailsModal from '../flex-common/planDetatailsModal';
import { getLargePlanDescription } from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';
import { PlanCard, EmptyCard } from '../flex-common/planCard';
import PlanShortDescription from '../flex-common/planShortDescription';


const PlanDetailsModalWrapper = ({ planId, handleClose }) => {
  const plan = getLargePlanDescription(planId);

  return plan
    ? <PlanDetailsModal
        plan={plan}
        open={!!planId}
        handleClose={handleClose} />
    : null
};

const renderPlanDescription = (description) => () =>
  <PlanShortDescription description={description} />;

const renderSelectedPlans = (handleOpenChange) => (plan, idx) =>
  <li key={idx} className="cards-item">
    <h6 className="cards-item-title">Line {idx + 1}</h6>
    {
      plan === null
        ? <EmptyCard/>
        : <PlanCard
            className="-flex"
            accordionTitle="Included features & perks"
            plan={plan}
            bigPlusTaxes={false}
            replaceDescriptionTitles={{ 'All perks included': 'Perks' }}
            handleOpenChange={handleOpenChange(idx)}
            renderDescription={renderPlanDescription(plan.description)}/>}
  </li>

const Checkout = ({ onContinue, onBack }) => {
  const { filledLines, linesData } = useContext(LinesDataContext);

  const total = linesData
    .filter(Boolean)
    .reduce((acc, { price }) => price + acc, 0);

  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.changePlanCount`)
    onBack();
  };

  const handleOpenChange = (lineIdx) => () => {
    Statistic.updateValue(`lines.${lineIdx}.selectedPlanFeaturesCount`);
  };

  return <div className="checkout">
    <Header/>
    <div className="content">
      <div className="checkout-total-wrapper">
        <button className="select-perk-back" onClick={handleBack}>Back</button>
        <p className="checkout-total">Total: ${total}</p>
      </div>
      <h6 className="checkout-title">You chose a plan for Line {filledLines}</h6>
      <ul className="cards-list">
        {linesData.map(renderSelectedPlans(handleOpenChange))}
      </ul>
    </div>
    <footer className="checkout-footer">
      <button className="button" type="button" onClick={onContinue}>Continue</button>
    </footer>
  </div>;
};

export default Checkout;