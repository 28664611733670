import React from 'react';
import Circle from './circle';

const CircleChart = ({
  multi, basePercent, secondPercent, thirdPercent
}) => {
  const baseColor = "#000";
  const secondColor = "#D52B1E";
  const thirdColor = "#EF9894";

  const baseY = 21.5;
  let baseX;
  let secondRadius, secondX, secondY, secondMarginX, secondMarginY;
  let thirdRadius, thirdX, thirdY, thirdMarginX, thirdMarginY;

  if (`${basePercent}`.match(/^\d$/)) {
    baseX = 15;
  } else if (`${basePercent}`.match(/^\d{3}$/)) {
    baseX = 9.5;
  } else {
    baseX = 12.5;
  }

  const angleToRad = (angle) => (angle * (Math.PI / 180));
  const percentToAngle = (percent) => ((percent * (360 / 100)) - 90) ;

  if (multi) {
    secondMarginX = secondPercent > 9 ? -1.5 : -1;
    secondMarginY = 1;
    secondRadius = 12.6;
    if (secondPercent > 33 && secondPercent < 80) {
      secondMarginX -= 0.5;
      secondMarginY += 0.5;
    }
    if (secondPercent > 94) {
      secondRadius = 10.2;
      secondMarginX -= 3;
    }
    secondX = secondRadius * Math.cos(angleToRad(percentToAngle(secondPercent + 2.5)));
    secondY = secondRadius * Math.sin(angleToRad(percentToAngle(secondPercent + 2.5)));

    thirdMarginX = thirdPercent > 9 ? -1.5 : -1;
    thirdMarginY = 1;
    thirdRadius = 16.5;
    if (thirdPercent > 33 && thirdPercent < 80) {
      thirdMarginX -= 0.5;
      thirdMarginY += 0.5;
    }
    if (thirdPercent > 94) {
      thirdRadius = 18;
      thirdMarginX -= 3;
    }
    thirdX = thirdRadius * Math.cos(angleToRad(percentToAngle(thirdPercent + 2.5)));
    thirdY = thirdRadius * Math.sin(angleToRad(percentToAngle(thirdPercent + 2.5)));
  }

  return (
    <svg width={ 180 } height={ 180 } viewBox="0 0 38 39">
      {
        multi &&
        <g transform="translate(1, 1)">
          <text
            className="base-percent-text"
            x={ baseX }
            y={ baseY }
            style={ { fill: baseColor } }
          >
            { basePercent }
          </text>
          <g transform="scale(0.6, 0.6) translate(12, 12)">
            <Circle
              color={ baseColor }
              percent={ basePercent }
              width={ 4 }
            />
          </g>
          <g transform="scale(0.83, 0.83) translate(3.7, 3.7)">
            <Circle
              color={ secondColor }
              percent={ secondPercent }
              width={ 1.5 }
            />
          </g>
          <Circle
            color={ thirdColor }
            percent={ thirdPercent }
            width={ 1.1 }
          />
          <g transform="translate(18, 18)">
            <text
              className="second-percent-text"
              x={ secondX + secondMarginX }
              y={ secondY + secondMarginY }
              style={ { fill: secondColor } }
            >
              { secondPercent }
            </text>
            <text
              className="third-percent-text"
              x={ thirdX + thirdMarginX }
              y={ thirdY + thirdMarginY }
              style={ { fill: thirdColor } }
            >
              { thirdPercent }
            </text>
          </g>
        </g>
      }
      {
        !multi &&
        <>
          <text
            className="base-percent-text"
            x={ baseX }
            y={ baseY }
            style={ { fill: baseColor } }
          >
            { basePercent }
          </text>
          <Circle
            color={ baseColor }
            percent={ basePercent }
            width={ 4 }
          />
        </>
      }
    </svg>
  );
};

export default CircleChart;
