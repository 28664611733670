import { find } from "lodash";

const swipeTimesByTarget = (data, target) => (
  find(data.analytics, (value) => (
    value.group_name === 'express_swipe_time' &&
    value['target_metric'] === target
  )) || {}
);

export { swipeTimesByTarget };
