const weekDays = [
  {
    weekDay: "Sun",
    monthDay: 7,
    marked: false,
    active: false,
  },
  {
    weekDay: "Mon",
    monthDay: 8,
    marked: true,
    active: true,
  },
  {
    weekDay: "Tue",
    monthDay: 9,
    marked: false,
    active: false,
  },
  {
    weekDay: "Wed",
    monthDay: 10,
    marked: true,
    active: false,
  },
  {
    weekDay: "Thu",
    monthDay: 11,
    marked: false,
    active: false,
  },
  {
    weekDay: "Fri",
    monthDay: 12,
    marked: true,
    active: false,
  },
  {
    weekDay: "Sat",
    monthDay: 13,
    marked: true,
    active: false,
  },
];

export default weekDays;
