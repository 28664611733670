import React, { useContext } from 'react';
import { map } from 'lodash';
import { NavLink } from "react-router-dom";
import { withPrefix } from '../../helpers';
import { baseContext } from "../../contexts";
import ValuesSlider from '../valuesSlider';
import ValuesList from '../valuesList';
import DownloadPulseReport from "../../exports/downloadPulseReport";
import OverviewTitle from '../../common/overview-title';

const BaseView = ({ overview }) => {
  const { mediaKind, isPreview, verizonType } = useContext(baseContext);
  const projectId = overview.id;
  const projectDate = overview.overview_date;
  const openEnds = overview.data.open_ends;

  const lastReportList = [
    {
      title: 'Satisfaction',
      type: 'slider',
      total: overview.data?.satisfaction?.top2box?.total || 0,
      data: overview.data ? overview.data.satisfaction.top2box : null,
      staticText: () =>
        `Are <b>satisfied</b> with their <span class="as-is">${withPrefix(
          verizonType,
          mediaKind
        )}</span> Internet`
    },
    {
      title: 'Issues Experienced',
      type: 'slider',
      total: overview.data?.issues?.total || 0,
      data: overview.data ? overview.data.issues : null
    },
    {
      title: 'Worth the Price',
      total: overview.data?.values_me?.total || 0,
      type: 'list',
      data: overview.data ? overview.data.values_me : null
    },
    {
      title: 'Planning to Stay',
      total: overview.data?.staying?.total || 0,
      type: 'list',
      data: overview.data ? overview.data.staying : null
    }
  ];

  return (
    <OverviewTitle overview={ overview } forceHideNsize>
      <div className="section_body">
        <div className="active-report_grid">
          {
            map(lastReportList, (item, index) => (
              <div className="active-report_grid-col" key={ `report-item-${index}` }>
                <div className="active-report_item">
                  <div className="active-report_item-header">
                    <div className="left-side">
                      { item.title }
                    </div>
                    <div className="right-side">
                      (n={ item.total })
                    </div>
                  </div>
                  {
                    item.type === 'slider' ?
                      <ValuesSlider
                        total={ item.total }
                        data={ item.data }
                        staticText={ item.staticText && item.staticText() }
                      />
                      :
                      <ValuesList
                        total={ item.total }
                        data={ item.data }
                      />
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="section_footer">
        {
          !isPreview &&
          <NavLink to="/pulse" className="button -sm -bordered">
            Explore More
          </NavLink>
        }
        <DownloadPulseReport
          projectId={ projectId }
          openEnds={ openEnds }
          projectDate={ projectDate }
        />
      </div>
    </OverviewTitle>
  );
};

export default BaseView;
