import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout";
import WorkoutCarousel from "../../../components/WorkoutCarousel";
import WorkoutHeader from "../../../components/WorkoutHeader";
import WorkoutVideo from "../../../components/WorkoutVideo";
import compilations from "../../../mocks/workoutCompilations";

const workout = compilations.pumpIsReal[0].workouts[0];

const WorkoutButton = () => (
  <div className="workout-page_button-wrapper">
    <button className="workout-page_start-button">
      Begin class
    </button>
  </div>
);

const PumpIsReal = () => (
  <Layout showNavigation={false} showSkipButton={true} FloatButton={<WorkoutButton />}>
    <div className="workout-page">
      <WorkoutHeader className="workout-page_header" />
      <WorkoutVideo workout={workout} />
      <h3 className="mx-4 mt-5">Overview</h3>
      <div className="workout-page_text mx-4">
        Push your legs to new limits with this full body athletic conditioning
        class. Breathe and keep moving through a range of bodyweight movements
        that increase strength and stamina.
      </div>
      <h3 className="mx-4 mt-5">Related classes</h3>
      <WorkoutCarousel
        workouts={compilations.athleticConditions[0].workouts}
        className="px-4"
      />
    </div>
  </Layout>
);

export default PumpIsReal;
