import React, { useEffect, useState, useContext } from 'react';
import { map } from "lodash";
import { baseContext, exportsContext } from "../../contexts";
import { loadList } from "../api";
import PortalModal from "../../common/portal";
import { withPrefix } from "../../helpers";

const Modal = ({ onClose, list }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  return (
    <PortalModal
      className="-sm"
      title={ `${withPrefix(verizonType, mediaKind)} Exports` }
      open
      handleClose={ onClose }
      hideFooter
    >
      <>
        <ul className="exports-list">
          {
            map(list, (item, index) => (
              <li key={ `Exports${index}` }>
                {
                  item.status === "finished" &&
                  <>
                    <div className="completed">
                      <a href={ item.s3_filename }>({ item.format }) Download report for { item.subtitle }</a>
                      <span className="date">{ item.created_at }</span>
                    </div>
                    <div className="icon-col">
                      <a href={ item.s3_filename }>
                        <svg className="icon -process-completed">
                          <use xlinkHref="#svg_process-download" />
                        </svg>
                      </a>
                    </div>
                  </>
                }
                {
                  item.status === "in_progress" &&
                  <>
                    <div className="in-progress">
                      <span className="in-progress">({ item.format }) Generating { item.subtitle }...</span>
                      <span className="date">{ item.created_at }</span>
                    </div>
                    <div className="icon-col">
                      <svg className="icon -process-pending">
                        <use xlinkHref="#svg_process-pending" />
                      </svg>
                    </div>
                  </>
                }
                {
                  item.status === "failed" &&
                  <>
                    <div className="failed">
                      <span className="failed">({ item.format }) Failed to generate { item.subtitle }</span>
                      <span className="date">{ item.created_at }</span>
                    </div>
                    <div className="icon-col">
                      <svg className="icon -process-fail">
                        <use xlinkHref="#svg_process-fail" />
                      </svg>
                    </div>
                  </>
                }
              </li>
            ))
          }
        </ul>
      </>
    </PortalModal>
  );
};

const FilesExport = () => {
  const { getRefresh, getOpen } = useContext(exportsContext);
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ opened, setOpened ] = useState(false);

  const [ filesList, setFilesList ] = useState([]);
  const [ pendingNum, setPendingNum ] = useState(false);
  const [ timeoutId, setTimeoutId ] = useState(null);

  const interval = 5000;

  const getList = (callback) => {
    loadList(mediaKind, (data) => {
      setPendingNum(data.count_in_progress);
      setFilesList(data.user_downloads);
      callback && callback();
      if (data.count_in_progress) {
        setTimeoutId((oldTimeoutId) => {
          clearTimeout(oldTimeoutId);
          return setTimeout(() => { getList(); }, interval);
        });
      }
    });
  };

  useEffect(() => { getList(); }, [ getRefresh(), mediaKind ]);
  useEffect(() => { setOpened(getOpen().open); }, [ getOpen() ]);

  const handleClick = (event) => {
    event.preventDefault();
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <>
      <h5 className="sidebar_section-title">
        { `${withPrefix(verizonType, mediaKind)} Exports` }
      </h5>
      <ul className="sidebar_list">
        <li className="sidebar_list-item">
          <a
            href="#"
            className="sidebar_list-link -export"
            onClick={ handleClick }
          >
            <span>
              Exports List
            </span>
            {
              !!pendingNum &&
              <>
                <span className="n-in-progress">
                  ({ pendingNum } in progress)
                </span>
              </>
            }
          </a>
        </li>
      </ul>
      {
        opened &&
        <Modal onClose={ handleClose } list={ filesList } />
      }
    </>
  );
};

export default FilesExport;
