import React, { useState, useContext } from 'react';
import TypesModal from '../../../common/types/types-modal';
import { BaseContext } from './contexts';

const debounceTimeout = 650;
const Header = ({
  isDemoUser, newMpPath, newProjectPath, setQ, loadProjects,
  setSearchDelayed
}) => {
  const { projectsAllowance } = useContext(BaseContext);
  const [ debounceHash ] = useState({ timeoutId: null });
  const [ showCreate, setShowCreate ] = useState(false);

  const debouncedQChange = (value) => {
    clearTimeout(debounceHash.timeoutId);
    debounceHash.timeoutId = setTimeout(() => {
      loadProjects(value);
    }, debounceTimeout);
  };

  const onQChange = (e) => {
    setSearchDelayed(true);
    const value = e.currentTarget.value;
    setQ(value);
    debouncedQChange(value);
  };

  const handleOnCreate = (projectType) => {
    window.location.href = `${newProjectPath}?express=${projectType}`;
  };

  const handleCloseModal = () => {
    setShowCreate(false);
  };

  const handleShowTypesList = () => {
    setShowCreate(true);
  };

  return (
    <div className="content_top -sticky -white">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item -grow">
            <h1 className="header_title">Projects</h1>
          </div>

          <div className="header_grid-item">
            <div className="form-field-with-icon">
              <input
                type="search"
                className="form-field -global-search form-field-with-icon_input"
                placeholder="Type a project or a concept name"
                onChange={ onQChange }
              />
              <svg
                className="form-field-with-icon_icon icon -i-search -global-search"
              >
                <use xlinkHref="#svg_i-search" />
              </svg>
            </div>
          </div>

          {
            !isDemoUser &&
            <div className="header_grid-item -gl-buttons">
              <button
                className="-gl-button -left"
                onClick={ handleShowTypesList }
              >
                Create Project
              </button>
              <a className="-gl-button -right" href={ newMpPath }>
                Create Multi-Language Project
              </a>
            </div>
          }
        </div>
      </header>
      {
        showCreate &&
        <TypesModal
          projectsAllowance={ projectsAllowance }
          onCreate={ handleOnCreate }
          onClose={ handleCloseModal }
        />
      }
    </div>
  );
};

export default Header;
