import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import { swipeTimesByTarget } from "../../logic/helpers";
import { getMetricKind, getMetricTitle, IndexData } from "../../../common/index-context";
import StatusBar from "../../../common/components/status-bar";
import StatusCircle from "../../../common/components/status-circle";
import { n0, showedAverageValue } from '../../../common/helpers';

const TableCombinedTools = ({ data, current, combinedTools }) => {
  const { averageMode, init } = useContext(IndexData);
  return (
    <table className="table table-comparison">
      <thead>
        <tr>
          <th className="table-comparison_name-cell" colSpan="3">
            <div className="table-comparison_title">
              Swipe ({ getMetricKind(data, 'combined_tool') }),
              { ' ' }
              N={ current.swipe_count }
            </div>
          </th>
          <th className="table-comparison_average-cell">Avg</th>
        </tr>
      </thead>
      <tbody>
        {
          map(combinedTools, (values, title) => {
            const tool = swipeTimesByTarget(data, title);
            const conceptData = tool[current.id];
            const v = conceptData ? n0(conceptData.value) : '-';
            const av = n0(tool.avg_value);

            return (
              <Fragment key={ title }>
                <tr className="time-addition-start">
                  <td className="table-comparison_name-cell -nowrap">
                    { getMetricTitle(data, title) }
                  </td>
                  <td className="-max-width">
                    <StatusBar values={ values[current.id] } statTesting={ data.stat_testing } box={ "top2" } />
                  </td>
                  <td>
                    <StatusCircle values={ values[current.id] } noLetters box={ "top2" } />
                  </td>
                  <td className="table-comparison_average-cell">
                    { showedAverageValue(values, averageMode, false, init.isInternal, data.view) }
                  </td>
                </tr>

                <tr>
                  <td className="time-addition-title -nowrap">
                    (Avg swipe time)
                  </td>
                  <td className="-max-width" />
                  <td>
                    { v } sec
                  </td>
                  <td className="table-comparison_average-cell">
                    { av } sec
                  </td>
                </tr>
              </Fragment>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default TableCombinedTools;
