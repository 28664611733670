import React, { useState, useEffect } from "react";
import { Routes } from "./routes";

import "./counter";

export const AppContext = React.createContext();

const App = () => {
  const [canSkip, setCanSkip] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCanSkip(true);
    }, 45000);
  }, []);

  return (
    <AppContext.Provider value={{ canSkip }}>
      <Routes />
    </AppContext.Provider>
  );
};

export default App;
