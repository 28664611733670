import React, { useState, useLayoutEffect, useRef } from 'react';
import { each, keys } from 'lodash';
import { BaseContext } from './contexts';
import Header from './components/header';
import Footer from './components/footer';
import Body from './components/body';

const App = ({ activated, signIn, signUp, blockedEmail, projectsAllowance }) => {
  const [ sectionRefs, setSectionRefs ] = useState({});
  const [ signUpOpen, setSignUpOpen ] = useState(signUp);
  const [ openRemoteReadMore, setOpenRemoteReadMore ] = useState({});
  const headerRef = useRef(null);

  const currentHtmlElement = () => (
    document.querySelector('html')
  );

  const setScrollTop = (val) => {
    currentHtmlElement().scrollTop = val;
  };

  const currentScrollTop = () => (
    currentHtmlElement().scrollTop
  );

  const contextValue = {
    activated, signIn, blockedEmail, projectsAllowance,
    getSignUpOpen: () => (signUpOpen), setSignUpOpen,
    getSectionRefs: () => (sectionRefs), setSectionRefs,
    setScrollTop, currentScrollTop, headerRef,
    getOpenRemoteReadMore: () => (openRemoteReadMore),
    setOpenRemoteReadMore
  };

  const findCurrentStateRefs = () => {
    let currentRefs;
    setSectionRefs((oldRefs) => {
      currentRefs = oldRefs;
      return oldRefs;
    });
    return currentRefs;
  };

  const defineRefsHighlighting = () => {
    const currentRefs = findCurrentStateRefs();
    const headerEl = headerRef.current;
    const clientHeight = currentHtmlElement().clientHeight;
    const headerObj = headerEl.getBoundingClientRect();
    let previousHighlight = false;
    each(keys(currentRefs), (key) => {
      const item = currentRefs[key];
      const obj = item.el.getBoundingClientRect();
      item.highlight = (
        obj.top + (headerObj.height * 2) <= clientHeight &&
        obj.top + obj.height >= headerObj.height &&
        !previousHighlight
      );
      previousHighlight = item.highlight;
    });
    setSectionRefs({ ...currentRefs });
  };

  useLayoutEffect(() => {
    window.onscroll = () => {
      defineRefsHighlighting();
    };
  }, []);

  return (
    <BaseContext.Provider value={ contextValue }>
      <Header />
      <Body />
      <Footer />
    </BaseContext.Provider>
  );
};

export default App;
