import React from 'react';
import { map } from "lodash";

const Tabs = ({ tab, setTab }) => {
  const tabs = {
    all: "Export All",
    select: "Select to export"
  };
  const onChangeTab = (tabName) => {
    setTab(tabName);
  };

  return (
    <div className="comparisons-tabs">
      <div className="radio-tabs -borderer -gray-bg">
        {
          map(tabs, (el, k) => (
            <label className="radio-tabs_item" key={ `conceptCountOption${k}` }>
              <input
                type="radio"
                checked={ tab === k }
                onChange={ () => { onChangeTab(k); } }
              />
              <div className="radio-tabs_label -capitalize">{ el }</div>
            </label>
          ))
        }
      </div>
    </div>
  );
};

export default Tabs;
