import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const ExpandCollapseItem = ({ show, updateShowFunction, text, selected, clearSelected }) => {
  return (
    <>
      <div className="section-title-icons">
        {show && <SvgIcon name="i-squared-minus" className="-v-middle -pointer" onClick={ () => { updateShowFunction(false); } } />}
        {!show && <SvgIcon name="i-squared-plus"  className="-v-middle -pointer" onClick={ () => { updateShowFunction(true); } } />}
      </div>
      <div className="section-title">
        { text }
      </div>
      {
        selected &&
        <div className="clear_selected" onClick={ clearSelected }>
          Clear selected
        </div>
      }
    </>
  );
};

export default ExpandCollapseItem;
