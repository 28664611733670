import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html';

const CommonHeader = ({ text }) => {
  return (
    <div className="survey-header">
      <h1 className="survey-header_title" { ...renderRawHtml(text) } />
    </div>
  );
};

export default CommonHeader;
