import React from 'react';

const HiddenFields = ({ data, options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="express" id="express" value={ data.express } />

      <input type="hidden" name="project[client_id]" id="project_client_id" value={ options.client_id } />
      <input type="hidden" name="project[qualified_text]" id="project_qualified_text" value={ model.qualifiedText } />
      <input type="hidden" name="project[platform_id]" id="project_platform_id" value={ model.platformId } />
      <input type="hidden" name="project[internal]" id="project_internal" value={ model.internal } />
      <input type="hidden" name="project[note]" id="project_note" value={ model.note } />
      <input type="hidden" name="project[title]" id="project_title" value={ model.title } />
    </>
  );
};

export default HiddenFields;
