import React from "react";
import instructors from "../mocks/instructors";
import videos from "../mocks/videos";
import Button from "./Button";
import Icon from "./Icon";
import InstructorProfile from "./InstructorProfile";
import VideosList from "./VideosList";

const BrandInstructors = () => {
  return (
    <div className="brand-instructors">
      <div className="brand-instructors_header">
        <h3 className="brand-instructors_title">Meet the instructors</h3>
        <Button variant="link" className="brand-instructors_all">
          View all
        </Button>
      </div>
      <div className="brand-instructors_profile">
        <div className="brand-instructors_bg">
          <Icon name="eq" className="brand-instructors_logo" />
        </div>
        <InstructorProfile {...instructors[0]} bordered />
      </div>
      <h3 className="brand-instructors_classes-title">All Equinox classes</h3>
      <div className="brand-instructors_videos">
        <VideosList videos={videos.equinox} showViewAll={false} />
      </div>
    </div>
  );
};

export default BrandInstructors;
