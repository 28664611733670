import React from 'react';
import { WithDataContext } from "./withData";
import IndexInner from "./IndexInner";

const Index = (data) => {
  return (
    <WithDataContext data={ data }>
      <IndexInner />
    </WithDataContext>
  );
};

export default Index;
