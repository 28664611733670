import Http from '../../../common/http';

const checkStatusTimeout = 3000;
const checkStatus = (mediaKind, verizonType, jid, callback) => {
  Http.get(`/verizon/installations/check_status/${jid}.json?media_kind=${mediaKind}&verizon_type=${verizonType}`)
    .then(
      (response) => {
        const { result, data } = response.data;
        if (result === 'in_progress') {
          setTimeout(
            () => { checkStatus(mediaKind, verizonType, jid, callback); },
            checkStatusTimeout
          );
        } else {
          callback && callback(result, data);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
};

const convertPdf = (mediaKind, verizonType, awsFilePath, callback) => {
  Http.post('/verizon/installations/convert_pdf.json', {
    aws_filepath: awsFilePath,
    media_kind: mediaKind,
    verizon_type: verizonType
  }).then(
    ({ data }) => {
      checkStatus(mediaKind, verizonType, data.jid, callback);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const makeUpdate = (mediaKind, verizonType, payload, callback) => {
  Http.put(
    '/verizon/installations.json',
    { ...{ media_kind: mediaKind, verizon_type: verizonType }, ...payload }
  ).then(
    ({ data }) => {
      checkStatus(mediaKind, verizonType, data.jid, callback);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const iFramePreviewUrl = (htmlUrl, baseCssUrl, cssUrl, verizonType) => (
  `${window.location.href}/preview_installations?html_url=${htmlUrl}&css_urls[]=${baseCssUrl}&css_urls[]=${cssUrl}&verizon_type=${verizonType}`
);

const iFrameResultUrl = (kind, slideId, verizonType) => (
  `${window.location.href}/preview_installations?kind=${kind}&slide_id=${slideId}&verizon_type=${verizonType}`
);

export {
  convertPdf,
  makeUpdate,
  iFramePreviewUrl,
  iFrameResultUrl
};
