import React from "react";
import cn from "classnames";

const RoutineCarousel = ({ className }) => {
  return (
    <div className="routine-carousel">
      <div className={cn("routine-carousel_list", className)}>
        {[1, 2].map((i) => (
          <div className="routine-carousel_item" key={i}>
            <div className="routine-carousel_item-overlay"></div>
            <div className="routine-carousel_item-content">
              <div className="routine-carousel_item-routine">_ROUTINE</div>
              <div className="routine-carousel_item-title">HIIT and Yoga</div>
              <div className="routine-carousel_item-period">
                Three active days a week
              </div>
              <div className="routine-carousel_categories">
                <div className="routine-carousel_category">
                  <div className="routine-carousel_category-dot -bg-orange"></div>
                  <div className="routine-carousel_category-title">HIIT</div>
                </div>
                <div className="routine-carousel_category">
                  <div className="routine-carousel_category-dot -bg-purple"></div>
                  <div className="routine-carousel_category-title">
                    Meditation
                  </div>
                </div>
                <div className="routine-carousel_category">
                  <div className="routine-carousel_category-dot -bg-extra-light-red"></div>
                  <div className="routine-carousel_category-title">Yoga</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoutineCarousel;
