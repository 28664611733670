import { each } from 'lodash';

const resultHash = {
  planSelected: null,
  plansTime: 0,
  landingTime: 0,
  aboutUsClicked: false
};

const setHashData = (path, value) => {
  const pathItems = path.split('.');
  let tempItem = resultHash;
  each(pathItems, (pathEl, index) => {
    if (index !== pathItems.length - 1) {
      if (!tempItem[pathEl]) {
        tempItem[pathEl] = {};
      }
      tempItem = tempItem[pathEl];
    } else { tempItem[pathEl] = value; }
  });
};

const sendData = () => {
  const finalData = {
    ...resultHash
  };
  (window.parent || window).postMessage({
    kind: 'finishPlans',
    result: finalData
  }, "*");
};

export {
  setHashData,
  sendData
};
