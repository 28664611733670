import Http from "../../../common/http";

const signInRequest = (userParams, callback) => {
  Http.post(
    "/express/api/users/sign_in",
    { user: userParams }
  ).then(
    (response) => {
      callback && callback(response.data, response.response.ok);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const passwordResetRequest = (email, callback) => {
  Http.post("/express/api/users/password", { user: { email } }).then(
    (response) => {
      callback && callback(response.data, response.response.ok);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { signInRequest, passwordResetRequest };
