import React, { useContext } from 'react';
import { map } from 'lodash';
import ProjectAsTableRow from './project-as-table-row';
import { IndexData } from './contexts';

const ProjectsAsTable = ({ projects }) => {
  const { admin } = useContext(IndexData);
  return (
    <div className="projects_list_table">
      <table className="table">
        <tbody>
          <tr>
            <th />
            <th>Title</th>
            { admin && <th>Client</th>  }
            <th>Created&nbsp;At</th>
            <th>In&nbsp;Field&nbsp;At</th>
            <th>Closed&nbsp;At</th>
            <th>Status</th>
            <th>Completes</th>
            <th>Target Completes</th>
            <th />
          </tr>
          {
            map(projects, (project, index) => (
              <ProjectAsTableRow
                key={ `SubProjectRow${index}` }
                project={ project }
              />
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default ProjectsAsTable;
