import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import Whiteboard from "./whiteboard";
import AdminPulses from "./adminPulses";
import AdminUsers from "./adminUsers";
import Pulse from "./pulse";
import { handleLogout } from "./api";
import MainDashboard from "./mainDashboard";
import { baseContext, exportsContext, filesContext, reportsContext } from "./contexts";
import FilesEditor from "./files/filesEditor";
import CustomerSpotlight from "./customerSpotlight";
import { setCombinedDashboardForTitle } from './helpers';

const App = ({ data }) => {
  const {
    permissions, user, mediaKind,
    verizonType, allGroups, availableRoles, allRoles,
    pulseSurveyXlsxTemplatePath,
    pulseSurveyCsvTemplatePath,
    recollectiveDefaultLink,
    discussioLink,
    extensionsForImport,
    showPulseData,
    oneSubDashboard,
    combine5gAndLte
  } = data;

  setCombinedDashboardForTitle(combine5gAndLte);

  const basepath = `/verizon/${verizonType}`;

  const isPreview = [ 'v5g', 'lte' ].indexOf(mediaKind) === -1;
  const canEdit = permissions.can_edit || permissions.admin;
  const limitedAccess = permissions.limited_access;

  const [ currentUser, updateCurrentUser ] = useState(user);
  const [ exportRefresh, updateExportRefresh ] = useState({});
  const [ exportOpen, updateExportOpen ] = useState({ open: false });
  const [ filesRefresh, updateFilesRefresh ] = useState({});
  const [ reportsRefresh, updateReportsRefresh ] = useState({});

  const getRecollectiveLink = () => (
    verizonType === 'verizon5g' ?
      (currentUser.recollective_link || recollectiveDefaultLink) :
      discussioLink
  );

  const baseInfo = {
    getCurrentUser: () => (currentUser),
    permissions,
    canEdit,
    limitedAccess,
    availableRoles,
    allRoles,
    getRecollectiveLink,
    updateCurrentUser,
    mediaKind,
    verizonType,
    pulseSurveyXlsxTemplatePath,
    pulseSurveyCsvTemplatePath,
    isPreview,
    allGroups,
    extensionsForImport,
    showPulseData,
    oneSubDashboard,
    combine5gAndLte
  };


  const exportsInfo = {
    getRefresh: () => (exportRefresh),
    refresh: () => { updateExportRefresh({}); },
    getOpen: () => (exportOpen),
    updateOpen: (val) => { updateExportOpen({ open: val }); }
  };

  const filesInfo = {
    getRefresh: () => (filesRefresh),
    refresh: () => { updateFilesRefresh({}); }
  };

  const reportsInfo = {
    getRefresh: () => (reportsRefresh),
    refresh: () => { updateReportsRefresh({}); }
  };

  const currentDate = new Date;
  const currentYear = currentDate.getFullYear();

  return (
    <baseContext.Provider value={ baseInfo }>
      <exportsContext.Provider value={ exportsInfo }>
        <filesContext.Provider value={ filesInfo }>
          <reportsContext.Provider value={ reportsInfo }>
            <BrowserRouter basename={ `${basepath}/${mediaKind}` }>
              <div className="wrapper">
                { isPreview && <div className="preview-warning">PREVIEW</div> }
                <Header basepath={ basepath } />
                <div className="layout">
                  <Sidebar permissions={ permissions } logoutClick={ handleLogout } />
                  <div className="content-wrapper">
                    <div className="content">
                      <div className="dashboard">
                        <Routes>
                          <Route path="/customer_spotlights" element={ <CustomerSpotlight /> } />
                          <Route path="/whiteboard" element={ <Whiteboard /> } />
                          <Route path="/pulse" element={ <Pulse /> } />
                          <Route path="/editor/files" element={ <FilesEditor /> } />
                          <Route path="/admin/pulses" element={ <AdminPulses /> } />
                          <Route path="/admin/users" element={ <AdminUsers /> } />
                          <Route path="/" element={ <MainDashboard canEdit={ canEdit } /> } />
                        </Routes>
                      </div>
                    </div>
                    <div className="copyright">
                      © { currentYear } buzzback LLC All rights reserved
                    </div>
                  </div>
                </div>
              </div>
            </BrowserRouter>
          </reportsContext.Provider>
        </filesContext.Provider>
      </exportsContext.Provider>
    </baseContext.Provider>
  );
};

export default App;
