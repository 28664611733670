import React from "react";
import classnames from 'classnames';
import Arrow from './arrow';

const GreenRed = ({ arrows, text, blackText }) => {
  return (
    <div className={ classnames("comparisons_legend", { 'black-text': blackText }) }>
      <div className="comparisons_legend-grid">
        {!arrows &&
          <>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label green">Green</div>
              <div className="comparisons_legend-text">Better than average</div>
            </div>
            <div className="comparisons_legend-grid-item">
              <div className="comparisons_legend-label red">Red</div>
              <div className="comparisons_legend-text">Worse than average</div>
            </div>
          </>}

        {arrows &&
          <>
            <div className="comparisons_legend-grid-item">
              <Arrow color="green" />
              <div className="comparisons_legend-text">Meaningfully better than {text} for this case</div>
            </div>
            <div className="comparisons_legend-grid-item">
              <Arrow color="red" />
              <div className="comparisons_legend-text">Meaningfully worse than {text} for this case</div>
            </div>
          </>}

      </div>
    </div>
  );
};

export default GreenRed;
