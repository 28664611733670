import remoteFiles from '../../../common/remote_files';
const {
  GerardPreviewLargeJpg, GerardPreviewJpg, GerardPng, ChelseaPreviewJpg,
  ChelseaPng, KateyPreviewJpg, KateyPng, TanyshaPreviewJpg, TanyshaPng,
  AndrewPreviewJpg, AndrewPng, BreannaPreviewJpg, BreannaPng,
  ElizabethPreviewJpg, ElizabethPng, KileyPreviewJpg, KileyPng,
  DavidPreviewJpg, DavidPng, CollenPreviewJpg, CollenPng
} = remoteFiles.equinox;

import brands from "./brands";
import categories from "./categories";

const forYou = [
  {
    title: "Because you want to improve Strength",
    workouts: [
      {
        imgUrl: GerardPreviewJpg,
        title: "Stacked! Upper Body",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "45:00",
        coach: {
          imgUrl: GerardPng,
          firstName: "Gerard",
          lastName: "T."
        }
      },
      {
        imgUrl: GerardPreviewJpg,
        title: "Stacked! Upper Body",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "45:00",
        coach: {
          imgUrl: GerardPng,
          firstName: "Gerard",
          lastName: "T."
        }
      }
    ]
  },
  {
    title: "Saved Classes",
    workouts: [
      {
        imgUrl: ChelseaPreviewJpg,
        title: "El Cuatro",
        level: "Intermediate",
        category: categories.running,
        brand: brands.pr,
        duration: "30:00",
        coach: {
          imgUrl: ChelseaPng,
          firstName: "Chelsea",
          lastName: "Aguiar"
        }
      },
      {
        imgUrl: ChelseaPreviewJpg,
        title: "El Cuatro",
        level: "Intermediate",
        category: categories.running,
        brand: brands.pr,
        duration: "30:00",
        coach: {
          imgUrl: ChelseaPng,
          firstName: "Chelsea",
          lastName: "Aguiar"
        }
      }
    ]
  },
  {
    title: "Because you want to improve Mental Wellness",
    workouts: [
      {
        imgUrl: KateyPreviewJpg,
        title: "Rise: Fulfill Your Potential",
        level: "Beginner",
        category: categories.meditation,
        brand: brands.headstrong,
        duration: "10:00",
        coach: {
          imgUrl: KateyPng,
          firstName: "Katey",
          lastName: "Lewis"
        }
      },
      {
        imgUrl: KateyPreviewJpg,
        title: "Rise: Fulfill Your Potential",
        level: "Beginner",
        category: categories.meditation,
        brand: brands.headstrong,
        duration: "10:00",
        coach: {
          imgUrl: KateyPng,
          firstName: "Katey",
          lastName: "Lewis"
        }
      }
    ]
  },
  {
    title: "Try new workout types",
    workouts: [
      {
        imgUrl: TanyshaPreviewJpg,
        title: "El Cuatro",
        level: "Intermediate",
        category: categories.cycling,
        brand: brands.soulcycle,
        duration: "45:00",
        coach: {
          imgUrl: TanyshaPng,
          firstName: "Tanysha",
          lastName: "T."
        }
      },
      {
        imgUrl: TanyshaPreviewJpg,
        title: "El Cuatro",
        level: "Intermediate",
        category: categories.cycling,
        brand: brands.soulcycle,
        duration: "45:00",
        coach: {
          imgUrl: TanyshaPng,
          firstName: "Tanysha",
          lastName: "T."
        }
      }
    ]
  },
  {
    title: "Because you want to improve Flexibilty",
    workouts: [
      {
        imgUrl: AndrewPreviewJpg,
        title: "Best Abs Ever: Obliques",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "45:00",
        coach: {
          imgUrl: AndrewPng,
          firstName: "Andrew",
          lastName: "S."
        }
      },
      {
        imgUrl: AndrewPreviewJpg,
        title: "Best Abs Ever: Obliques",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "45:00",
        coach: {
          imgUrl: AndrewPng,
          firstName: "Andrew",
          lastName: "S."
        }
      }
    ]
  },
  {
    title: "Latest in Sculpt",
    workouts: [
      {
        imgUrl: BreannaPreviewJpg,
        title: "Best Abs Ever: Obliques",
        level: "Intermediate",
        category: categories.sculpt,
        brand: brands.eqx,
        duration: "30:00",
        coach: {
          imgUrl: BreannaPng,
          firstName: "Breanna",
          lastName: "C."
        }
      },
      {
        imgUrl: BreannaPreviewJpg,
        title: "Best Abs Ever: Obliques",
        level: "Intermediate",
        category: categories.sculpt,
        brand: brands.eqx,
        duration: "30:00",
        coach: {
          imgUrl: BreannaPng,
          firstName: "Breanna",
          lastName: "C."
        }
      }
    ]
  },
  {
    title: "Try new workout types",
    workouts: [
      {
        imgUrl: ElizabethPreviewJpg,
        title: "El Cuatro",
        level: "Intermediate",
        category: categories.running,
        brand: brands.pr,
        duration: "30:00",
        coach: {
          imgUrl: ElizabethPng,
          firstName: "Elizabeth",
          lastName: "C."
        }
      },
      {
        imgUrl: ElizabethPreviewJpg,
        title: "El Cuatro",
        level: "Intermediate",
        category: categories.running,
        brand: brands.pr,
        duration: "30:00",
        coach: {
          imgUrl: ElizabethPng,
          firstName: "Elizabeth",
          lastName: "T."
        }
      }
    ]
  },
  {
    title: "More classes with no equipment needed",
    workouts: [
      {
        imgUrl: KileyPreviewJpg,
        title: "Upper Body Strength",
        level: "Intermediate",
        category: categories.yoga,
        brand: brands.pureYoga,
        duration: "20:00",
        coach: {
          imgUrl: KileyPng,
          firstName: "Kiley",
          lastName: "Holiday"
        }
      },
      {
        imgUrl: KileyPreviewJpg,
        title: "Upper Body Strength",
        level: "Intermediate",
        category: categories.yoga,
        brand: brands.pureYoga,
        duration: "20:00",
        coach: {
          imgUrl: KileyPng,
          firstName: "Kiley",
          lastName: "Holiday"
        }
      }
    ]
  }
];

const explore = [
  {
    title: "See what’s new on Variis",
    workouts: [
      {
        imgUrl: DavidPreviewJpg,
        title: "Windburn",
        level: "Intermediate",
        category: categories.running,
        brand: brands.pr,
        duration: "25:00",
        coach: {
          imgUrl: DavidPng,
          firstName: "David",
          lastName: "Siik"
        }
      },
      {
        imgUrl: DavidPreviewJpg,
        title: "Windburn",
        level: "Intermediate",
        category: categories.running,
        brand: brands.pr,
        duration: "25:00",
        coach: {
          imgUrl: DavidPng,
          firstName: "David",
          lastName: "Silk"
        }
      }
    ]
  }
];

const equinox = [
  {
    title: "",
    workouts: [
      {
        imgUrl: GerardPreviewJpg,
        title: "The Pump is Real: Bigger Legs",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "45:00",
        coach: {
          imgUrl: GerardPng,
          firstName: "Gerard",
          lastName: "T."
        }
      }
    ]
  }
];

const pumpIsReal = [
  {
    title: "",
    workouts: [
      {
        imgUrl: GerardPreviewLargeJpg,
        title: "The Pump is Real:\n Bigger Legs",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "45:00",
        coach: {
          imgUrl: GerardPng,
          firstName: "Gerard",
          lastName: "T."
        }
      }
    ]
  }
];

const athleticConditions = [
  {
    title: "",
    workouts: [
      {
        imgUrl: CollenPreviewJpg,
        title: "Athletic Conditioning",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "30:00",
        coach: {
          imgUrl: CollenPng,
          firstName: "Colleen",
          lastName: "C."
        }
      },
      {
        imgUrl: CollenPreviewJpg,
        title: "Athletic Conditioning",
        level: "Advanced",
        category: categories.hiit,
        brand: brands.eqx,
        duration: "30:00",
        coach: {
          imgUrl: CollenPng,
          firstName: "Colleen",
          lastName: "C."
        }
      }
    ]
  }
];

const compilations = {
  forYou,
  explore,
  pumpIsReal,
  equinox,
  athleticConditions
};

export default compilations;
