import React, { createContext, useContext, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { filter, findIndex, chunk, each, zip, sortBy, lowerCase, flatten, compact, find } from "lodash";
import Forward from '../logic/forward';
import saveUserData from '../logic/save_user_data';
import PageLoader from '../../common/components/page_loader';
import Intro from '../../common/components/intro';
import Screening from '../../common/components/screening';
import Qualified from '../../common/components/qualified';
import CustomQualified from '../../common/components/custom_qualified';
import GroupIntro from "../../common/components/group_intro";
import PreferenceReasons from "../../common/components/metrics/preference_reasons";
import { fraudContext } from "../../common/contexts";
import { LastStepsComponents } from '../../common/logic/last_steps';
import WithOpenEnd from '../../common/components/metrics/customMetricsOpenEnd/withOpenEnd';
import BrandScreening from './BrandScreening';
import CategoryQuestions from './CategoryQuestions';
import BrandAware from './BrandAware';
import BrandUserQuestions from './BrandUserQuestions';

const resultContext = createContext(
  {
    answers: [],
    loops: [ { confirmit_concept_id: "1", answers: [] } ],
    thought_bubbles: [],
    unaided_brands: [],
    brand_aware: false,
    brand_user: false
  }
);

const Index = ({
  updateStep, updateQuestionIndex, data, qIndex, step, loading, changeLoadingState
}) => {
  const {
    qualified_custom_text, demographics, optional_demographics, screenings, translations, brand_screenings,
    category_questions, language_code, brand_aware_questions, brand_user_questions, brand_no_user_questions,
    custom_questions
  } = data;

  const result = useContext(resultContext);
  const { projectId, antiFraud, fraudProcessing, gaTrackingId } = useContext(fraudContext);

  const cqAnyAwareness = filter(custom_questions, (el) => (el.target_group === 'all' && el.survey_location === 'default'));
  const cqAwareAll = filter(custom_questions, (el) => (el.target_group === 'brand_aware' && el.survey_location === 'default'));
  const cqNotAware = filter(custom_questions, (el) => (el.target_group === 'brand_non_aware' && el.survey_location === 'default'));
  const cqAwareNonUser = filter(custom_questions, (el) => (el.target_group === 'brand_non_users' && el.survey_location === 'default'));
  const cqAwareUser = filter(custom_questions, (el) => (el.target_group === 'brand_users' && el.survey_location === 'default'));
  const cqAtTheEnd = filter(custom_questions, (el) => {
    const any = el.target_group === 'all';
    const brandAware = result.brand_aware && el.target_group === 'brand_aware';
    const brandNonAware = !result.brand_aware && el.target_group === 'brand_non_aware';
    const brandUsers = result.brand_user && el.target_group === 'brand_users';
    const brandNonUsers = !result.brand_user && el.target_group === 'brand_non_users';

    return (any || brandAware || brandNonAware || brandUsers || brandNonUsers) && el.survey_location === 'at_the_end';
  });

  const customs = { cqAnyAwareness, cqNotAware, cqAwareAll, cqAwareNonUser, cqAwareUser, cqAtTheEnd };

  useLayoutEffect(() => {
    changeLoadingState(false);
  }, []);

  const saveRequest = (status) => {
    saveUserData(data.save_url, status, result, data);
  };

  const checkGroup = (name) => (result[name] ? name : null);

  const determineBrandGroup = () => {
    const defaultValue = 'category_user';
    const availableGroups = [ 'brand_user', 'brand_aware' ];
    let currentIndex = 0;
    let groupValue;
    while (currentIndex < availableGroups.length && !(groupValue = checkGroup(availableGroups[currentIndex]))) {
      currentIndex++;
    }
    return groupValue ? groupValue : defaultValue;
  };

  const currentQuestion = (questions) => (questions[qIndex]);

  const [ forwardEntity ] = useState(new Forward({
    updateStep,
    runSave: (status) => saveRequest(status),
    updateQuestionIndex
  }));
  const goForward = () => {
    forwardEntity.forward(step, data, result, customs, projectId, antiFraud, gaTrackingId);
  };
  const goNextQuestion = (questions) => {
    if (questions[qIndex].name === 'BRAND_F3') {
      questions[qIndex + 1].answers = copySelectedF3(questions, qIndex, result); //BRAND_F4
      addOtherToF5(questions[qIndex + 2], result);
      result.brand_aware = hasBrand(questions, questions[qIndex].name, result.answers);
    }

    if (questions[qIndex].name === 'BRAND_F4') {
      result.brand_user = hasBrand(questions, questions[qIndex].name, result.answers);
      result.brand_group = determineBrandGroup();
    }

    if (questions[qIndex].default_name === 'brand_product_usage') {
      const ps = find(questions, (el) => (el.default_name === 'product_satisfaction'));
      if (ps) {
        ps.answers = copySelectedBPU(questions[qIndex], result.loops[0], ps.name);
      }
    }

    forwardEntity.nextQuestion(
      qIndex, questions, result, goForward, step
    );
  };

  return (
    <>
      <PageLoader loading={ loading || fraudProcessing } />
      <section className="survey-layout">
        {
          !step &&
          <Intro
            task={ translations.base_intro }
            nextStep={ goForward }
          />
        }
        {
          step === 1 &&
          <Screening
            question={ currentQuestion(demographics) }
            nextStep={ () => { goNextQuestion(demographics); } }
            result={ result }
          />
        }
        {
          step === 2 &&
          <Screening
            question={ currentQuestion(optional_demographics) }
            nextStep={ () => { goNextQuestion(optional_demographics); } }
            result={ result }
          />
        }
        {
          step === 3 &&
          <Screening
            question={ currentQuestion(screenings) }
            nextStep={ () => { goNextQuestion(screenings); } }
            result={ result }
          />
        }
        {
          step === 4 &&
          <BrandScreening
            question={ currentQuestion(brand_screenings) }
            nextStep={ () => { goNextQuestion(brand_screenings); } }
            result={ result }
          />
        }
        {
          step === 5 && !qualified_custom_text &&
          <Qualified
            title={ translations.express_qualify_title }
            description={ translations.express_qualify_description }
            descriptionBottom={ translations.express_qualify_footer }
            nextStep={ goForward }
          />
        }
        {
          step === 5 && !!qualified_custom_text &&
          <CustomQualified
            reactWysiwig
            content={ qualified_custom_text }
            nextStep={ goForward }
          />
        }
        {
          step === 6 &&
          <CategoryQuestions
            question={ currentQuestion(category_questions) }
            nextStep={ () => { goNextQuestion(category_questions); } }
            result={ result }
            languageCode={ language_code }
          />
        }
        {
          step === 7 &&
          <WithOpenEnd
            question={ currentQuestion(cqAnyAwareness) }
            nextStep={ () => { goNextQuestion(cqAnyAwareness); } }
            mainResult={ result }
            result={ result.loops[0] }
          />
        }
        {
          step === 8 &&
          <GroupIntro
            title={ translations.brand_aware_intro_title }
            description={ translations.brand_aware_intro_text }
            nextStep={ goForward }
          />
        }
        {
          step === 9 &&
          <BrandAware
            question={ currentQuestion(brand_aware_questions) }
            nextStep={ () => { goNextQuestion(brand_aware_questions); } }
            result={ result }
            translations={ translations }
          />
        }
        {
          step === 10 &&
          <WithOpenEnd
            question={ currentQuestion(cqAwareAll) }
            nextStep={ () => { goNextQuestion(cqAwareAll); } }
            mainResult={ result }
            result={ result.loops[0] }
          />
        }
        {
          step === 21 &&
          <BrandUserQuestions
            question={ currentQuestion(brand_user_questions) }
            nextStep={ () => { goNextQuestion(brand_user_questions); } }
            result={ result }
            languageCode={ language_code }
          />
        }
        {
          step === 22 &&
          <WithOpenEnd
            question={ currentQuestion(cqAwareUser) }
            nextStep={ () => { goNextQuestion(cqAwareUser); } }
            mainResult={ result }
            result={ result.loops[0] }
          />
        }
        {
          step === 31 &&
          <PreferenceReasons
            concept={ currentQuestion(brand_no_user_questions).concept }
            note={ translations.reasons_for_not_using }
            noteClassName="survey-options_question -one-question survey-options_question-title -center"
            result={ result }
            nextStep={ goForward }
          />
        }
        {
          step === 32 &&
          <WithOpenEnd
            question={ currentQuestion(cqAwareNonUser) }
            nextStep={ () => { goNextQuestion(cqAwareNonUser); } }
            mainResult={ result }
            result={ result.loops[0] }
          />
        }
        {
          step === 41 &&
          <WithOpenEnd
            question={ currentQuestion(cqNotAware) }
            nextStep={ () => { goNextQuestion(cqNotAware); } }
            mainResult={ result }
            result={ result.loops[0] }
          />
        }
        {
          step === 51 &&
          <WithOpenEnd
            question={ currentQuestion(cqAtTheEnd) }
            nextStep={ () => { goNextQuestion(cqAtTheEnd); } }
            mainResult={ result }
            result={ result.loops[0] }
          />
        }
        <LastStepsComponents
          step={ step }
          lastStepsFrom={ 52 }
          translations={ translations }
          data={ data }
          nextStep={ goForward }
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    updateStep: (step) => { return dispatch({ type: "UPDATE_STEP", value: step }); },
    changeLoadingState: (value) => { return dispatch({ type: "UPDATE_LOADING", value }); },
    updateQuestionIndex: (qIndex) => { return dispatch({ type: "UPDATE_QUESTION_INDEX", value: qIndex }); }
  };
};

const copySelectedF3 = (questions, qIndex, result) => {
  const selectedAnswers = filter(questions[qIndex].answers, (a) => {
    return (!a.id.match(/_-1/) && !a.id.match(/_-2/)) && findIndex(result.answers, (el) => (el === a.id)) > -1;
  });

  const selected = [];
  // eslint-disable-next-line lodash/prefer-map
  each(selectedAnswers, (el) => {
    selected.unshift({
      id: el.id.replace('F3', 'F4'),
      label: el.label,
      eligible: el.eligible,
      target: el.target
    });
  });

  const other = result.otherAnswers && result.otherAnswers['BRAND_F3'];
  if (other) {
    selected.unshift({
      id: 'BRAND_F4_99',
      label: other,
      eligible: ''
    });
  }

  const sorted = sortBy(selected, (el) => (lowerCase(el.label)));
  const parts = chunk(sorted, Math.ceil(selected.length / 2));
  const zipped = compact(flatten(zip(parts[0], parts[1])));
  return zipped.concat(questions[qIndex + 1].answers);
};

const addOtherToF5 = (brandF5, result) => {
  const other = result.otherAnswers && result.otherAnswers['BRAND_F3'];
  if (other) {
    brandF5.answers.unshift({
      id: 'BRAND_F5_99',
      label: other,
      eligible: true
    });
  }
};

const copySelectedBPU = (source, result, dName) => {
  const selectedAnswers = filter(source.answers, (a) => {
    return !a.id.match(/_-2/) && findIndex(result.answers, (el) => (el === a.id)) > -1;
  });

  const selected = [];
  // eslint-disable-next-line lodash/prefer-map
  each(selectedAnswers, (el) => {
    selected.push({
      id: el.id.replace(source.name, dName),
      label: el.label
    });
  });

  const other = result.otherAnswers && result.otherAnswers[source.name] ;
  if (other) {
    selected.push({
      id: `${dName}_99`,
      label: other
    });
  }

  return selected;
};

const hasBrand = (brandScreenings, qName, answers) => {
  const brandQuestion = find(brandScreenings, (q) => (q.name === qName));
  const targetAnswer = find(brandQuestion.answers, (a) => (a.target));
  return !!targetAnswer && !!find(answers, (a) => (a === targetAnswer.id));
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
