import React, { useState } from 'react';
import cn from "classnames";
import UploadButton from "../../../../common/components/upload_button";
import SvgIcon from "../../../../common/components/svg-icon";

const ImageUpload = ({ className, title, imageObject, onUpload, onDelete, error }) => {
  const [ loading, setLoading ] = useState(false);
  const { imageSrc } = imageObject;

  const handleInit = () => {
    setLoading(true);
  };

  const handleUploaded = (files) => {
    const [ file ] = files;
    setLoading(false);
    onUpload({ imageData: file.metaData, imageSrc: file.previewSrc });
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div className={ cn("black-border", className, { 'has-error': !!error }) }>
      <div className="-middle">
        {
          <UploadButton
            className="link form-concept_upload-btn"
            allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
            onInit={ handleInit }
            onUploaded={ handleUploaded }
            needPreview
            customProcessingTitle="Uploading..."
          >
            {
              !!imageSrc &&
              <img src={ imageSrc } alt="Uploaded Image" />
            }
            {
              !imageSrc &&
              <>
                <div className="upload-text-main">{title}</div>
                <div className="upload-text-secondary">Click to upload</div>
              </>
            }
          </UploadButton>
        }
      </div>
      {
        !loading && !!imageSrc &&
        <div className="delete-btn" onClick={ handleDelete }>
          <SvgIcon name="i-delete" />
        </div>
      }
    </div>
  );
};

export default ImageUpload;
