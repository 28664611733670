import React, { useRef, useState } from 'react';
import { isEmpty } from "lodash";
import cn from "classnames";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import SliderRadioButtons from "../common/sliderRadioButtons";
import PageLoader from "../../common/PageLoader";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import Select from "../common/select";
import Wysiwyg from "../../../../common/components/wysiwyg";
import Stimuli from '../../../respondent/verizon_customer_case_v2024/components/stimuli';
import { baseContext } from '../../../respondent/common/contexts';
import HiddenFields from "./hiddenFields";
import TextsList from "./textsList";
import ImagesList from "./imagesList";
import ImageUpload from './imageUpload';

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState(() => {
    return {
      projectId: data.id,
      ideaName: data.verizon_express_setting.idea_name || '',
      customerBenefit: data.verizon_express_setting.customer_benefit || '',
      reasonsToBelieve: data.verizon_express_setting.reasons_to_believe || '',
      processLeadIn: data.verizon_express_setting.process_lead_in || '',
      processLeadInOther: data.verizon_express_setting.process_lead_in_other || '',
      includeDeviation: data.verizon_express_setting.include_deviation || false,
      deviationScenario: data.verizon_express_setting.deviation_scenario || '',
      processSteps: data.verizon_express_setting.process_steps || '',
      additionalImageData: data.verizon_express_setting.additional_image_data || '',
      additionalImageSrc: data.verizon_express_setting.additional_image_src || '',
      additionalImageNotes: data.verizon_express_setting.additional_image_notes || '',
      steps: data.verizon_process_steps || [],
      branded: data.verizon_express_setting.branded,
      errors
    };
  });

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  const handleAdditionalImageUpload = (imageObject) => {
    setModel(
      {
        ...model,
        additionalImageData: imageObject.imageData,
        additionalImageSrc: imageObject.imageSrc
      }
    );
  };
  const handleAdditionalImageDelete = () => {
    setModel(
      {
        ...model,
        additionalImageData: null, additionalImageSrc: null
      }
    );
  };

  const baseContextValue = { translations: options.translations };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <WithSubmit formId="form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
        <HiddenFields data={ data } options={ options } model={ model } />
        <div className="content -relative -grey-background">
          <Header title="Adding Concepts" />
          <div className="content_body">
            <div className="form">
              {!!options.jid && <PageLoader options={ options } />}

              <div className="form_section -js-idea-name">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Idea Name</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.idea_name'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add idea Name"
                    enableIndent
                    value={ model.ideaName }
                    onChange={ (newHtml) => {setModel({ ...model, ideaName: newHtml });} }
                  />
                </div>
              </div>

              <div className="form_section -js-benefit">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Customer Benefit</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.customer_benefit'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add Text"
                    enableIndent
                    value={ model.customerBenefit }
                    onChange={ (newHtml) => {setModel({ ...model, customerBenefit: newHtml });} }
                  />
                </div>
              </div>

              <div className="form_section -js-reasons">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Reasons to Believe</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.reasons_to_believe'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add Text"
                    enableIndent
                    value={ model.reasonsToBelieve }
                    onChange={ (newHtml) => {setModel({ ...model, reasonsToBelieve: newHtml });} }
                  />
                </div>
              </div>

              <Select
                id="project-ProcessLeadIn"
                title="Process Lead In"
                initValue={ model.processLeadIn }
                options={ options.process_lead_in_options }
                onChange={ (v) => {setModel({ ...model, processLeadIn: v });} }
                errors={ errors['verizon_express_setting.process_lead_in'] }
              />
              {
                model.processLeadIn === 'other' &&
                <div className="form_section -js-project-lead-in-other">
                  <div className="form_section-title -grid">
                    <div className="form_section-title-item">
                      <h3>Please specify "Process Lead In", and include a colon at the end</h3>
                    </div>
                  </div>
                  <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.process_lead_in_other'] }) }>
                    <Wysiwyg
                      customPicker
                      toolbarClassName="wysiwyg-menu"
                      editorClassName="wysiwyg-textarea"
                      placeholder='Enter custom "Process Lead In"'
                      enableIndent
                      value={ model.processLeadInOther }
                      onChange={ (newHtml) => {setModel({ ...model, processLeadInOther: newHtml });} }
                    />
                  </div>
                </div>
              }

              <div className="form_section">
                <SliderRadioButtons
                  title="Process Steps"
                  initValue={ model.processSteps }
                  options={ options.process_steps_options }
                  onChange={ (v) => {setModel({ ...model, processSteps: v });} }
                  errors={ errors['process_steps'] }
                  modifier="-full-width"
                />
              </div>

              {
                model.processSteps === 'text' &&
                <TextsList
                  model={ model }
                  setModel={ setModel }
                />
              }

              {
                model.processSteps === 'images' &&
                <ImagesList
                  model={ model }
                  setModel={ setModel }
                />
              }

              <div className="form_section -js-reasons">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Optional Image</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.additional_image_data'] }) }>
                  <ImageUpload
                    title="Optional Image is blank"
                    imageObject={
                      {
                        imageData: model.additionalImageData,
                        imageSrc: model.additionalImageSrc
                      }
                    }
                    onUpload={ handleAdditionalImageUpload }
                    onDelete={ handleAdditionalImageDelete }
                  />
                </div>
                <FieldHeaderWithText
                  id="project-OptionalImageNotes"
                  title="Optional Image Alt Text"
                  errors={ errors['verizon_express_setting.additional_image_notes'] }
                  initValue={ model.additionalImageNotes }
                  onChange={ (v) => { setModel({ ...model, additionalImageNotes: v }); } }
                />
              </div>

              {
                model.includeDeviation &&
                <div className="form_section -js-deviation">
                  <div className="form_section-title -grid">
                    <div className="form_section-title-item">
                      <h3>Deviation Scenario / Unhappy Path</h3>
                    </div>
                  </div>
                  <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.deviation_scenario'] }) }>
                    <Wysiwyg
                      customPicker
                      toolbarClassName="wysiwyg-menu"
                      editorClassName="wysiwyg-textarea"
                      placeholder="Add Text"
                      enableIndent
                      value={ model.deviationScenario }
                      onChange={ (newHtml) => {setModel({ ...model, deviationScenario: newHtml });} }
                    />
                  </div>
                </div>
              }

              <div className="stimuli-preview">
                <h3>Stimuli Preview</h3>
                <Stimuli
                  ideaName={ model.ideaName }
                  customerBenefit={ model.customerBenefit }
                  reasonsToBelieve={ model.reasonsToBelieve }
                  processLeadInOptions={ options.process_lead_in_options }
                  processLeadIn={ model.processLeadIn }
                  processLeadInOther={ model.processLeadInOther }
                  processStepsKind={ model.processSteps }
                  additionalImageUrl={ model.additionalImageSrc }
                  additionalImageNotes={ model.additionalImageNotes }
                  branded={ model.branded }
                  steps={ model.steps }
                />
              </div>
            </div>
          </div>
        </div>
      </WithSubmit>
    </baseContext.Provider>
  );
};

export default App;
