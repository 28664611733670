import React, { useEffect, useState } from 'react';
import { filter, map } from 'lodash';
import Select from 'react-select/creatable';
import { selectStylesMulti } from "../../helpers";

const MultiSelectFilter = ({ attrName, options, selectedValues, onUpdate }) => {
  const [ selectedOptions, setSelectedOptions ] = useState(selectedValues);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDisabled, setIsDisabled ] = useState(false);

  useEffect(() => {
    if (selectedValues) {
      setSelectedOptions(selectedValues);
      setIsLoading(false);
      setIsDisabled(false);
    }
  }, [ selectedValues ]);

  const onChange = (newValues) => {
    setIsLoading(true);
    setIsDisabled(true);
    const params = {};
    params[attrName] = map(newValues, (el) => el.value).join(',');
    onUpdate(params);
  };

  const handleSelect = (data, selected) => {
    let newSelected = [];

    switch (selected.action) {
      case 'clear':
        setSelectedOptions(newSelected);
        onChange(newSelected);
        break;
      case "remove-value":
        newSelected = filter(selectedOptions, (el) => el.value !== selected.removedValue.value) ;
        setSelectedOptions([ ...newSelected ]);
        onChange(newSelected);
        break;
      case "select-option":
        selectedOptions.push(selected.option);
        setSelectedOptions([ ...selectedOptions ]);
        onChange(selectedOptions);
        break;
      default:
        console.log(`Unsupported action: ${selected.action}`);
    }
  };

  return (
    <div className="react-select-wrapper">
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={ options }
        value={ selectedOptions }
        onChange={ handleSelect }
        isLoading={ isLoading }
        isDisabled={ isDisabled }
        isMulti
        isClearable
        styles={ selectStylesMulti() }
        autosize={ false }
        maxMenuHeight={ 150 }
        isValidNewOption={ () => false }
      />
    </div>
  );
};

export default MultiSelectFilter;
