import React, { useState, useContext } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import VerizonSlider from '../common/verizonSlider';
import { FrameWrapper } from '../../../common/components/frame_wrapper';
import { withPrefix } from '../helpers';
import { baseContext, installationsContext, QualitativeContext } from '../contexts';
import EditButton from '../common/edit_button';
import InstallationsModal from './installationsModal';
import { iFrameResultUrl } from './api';

const Installations = ({ editable }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ openModal, setOpenModal ] = useState(false);
  const { getTitle, getSlides, getPpt } = useContext(installationsContext);
  const { fontClass } = useContext(QualitativeContext);
  const title = getTitle();
  const slides = getSlides();
  const pptUrl = getPpt().url;

  const editHandle = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      {
        (!!slides.length || (!slides.length && editable)) &&
        <div className="section">
          <div className="section_top">
            <h4 className={ classnames("section_title", fontClass) }>
              {withPrefix(verizonType, mediaKind, title)}
            </h4>
            {
              editable &&
              <>
                <EditButton onEdit={ editHandle } />
                {
                  openModal &&
                  <InstallationsModal handleClose={ handleClose } />
                }
              </>
            }
          </div>
          <div className="section_body">
            <div className="article">
              <div className="article_content -installation-slides">
                {
                  !!slides.length &&
                  <VerizonSlider>
                    {
                      map(slides, (slide, index) => (
                        <FrameWrapper key={ `Slide${slide.id}${index}` } src={ iFrameResultUrl(mediaKind, slide.id, verizonType) } />
                      ))
                    }
                  </VerizonSlider>
                }
              </div>
            </div>
          </div>
          {
            pptUrl &&
            <div className="section_footer">
              <a href={ pptUrl } className="button -sm -bordered">
                Download report
              </a>
            </div>
          }
        </div>
      }
    </>
  );
};

export default Installations;
