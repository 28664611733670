import React, { useState, useContext } from 'react';
import { map, each, filter, capitalize } from 'lodash';
import Modal from '../../../../common/components/modal.js';
import { invalidQuotas, totalQuotaSum } from '../../common/logic/quotas_validation';
import QuotaModeSwitcher from '../../common/quotaMode/components/quotaModeSwitcher';
import { QuotaModeLocalContext } from '../../common/quotaMode/contexts';
import { ConditionContext } from "../contexts/condition_context";

const BreaksEditQuota = ({ question, breaks, showCross, linkTitle, title, onUpdate, platformName }) => {
  const [ showForm, updateShowForm ] = useState(false);
  const [ errors, updateErrors ] = useState([]);
  const conditionValues = useContext(ConditionContext);
  const { forceChangeGlobalValueToLocal } = useContext(QuotaModeLocalContext);

  const openModal = (event) => {
    event.preventDefault();
    each(breaks, (q) => {
      q.quotasAnswers = filter(map(q.answers, (v, k) => {
        return {
          key: k,
          answer: v,
          selected: q.condition[`${k}`] === conditionValues.selected,
          quota: q.quotas[`${k}`]
        };
      }), (el) => el.selected);
    });
    updateShowForm(true);
  };

  const onClose = (event) => {
    event.preventDefault();
    updateShowForm(false);
    updateErrors([]);
  };

  const fromArrayToQuestions = () => {
    each(breaks, (q) => {
      q.quotas = {};
      each(q.quotasAnswers, (item) => {
        q.quotas[`${item.key}`] = `${item.quota}`;
      });
      delete q.quotasAnswers;
    });
  };

  const valid = () => {
    const newErrors = [];
    each(breaks, (q) => {
      const quotaMessages = invalidQuotas(q, q.quotasAnswers);
      if (quotaMessages) {
        newErrors.push(`${q.title}: ${quotaMessages[0]}`);
      }
    });

    if (newErrors.length) {
      updateErrors(newErrors);
    } else {
      updateErrors([]);
    }

    return !newErrors.length;
  };

  const onSave = (event) => {
    event.preventDefault();
    if (valid()) {
      forceChangeGlobalValueToLocal();
      fromArrayToQuestions();
      if (onUpdate) {
        onUpdate(event, question);
      }
      updateShowForm(false);
    }
  };

  const changeText = (event, item, field) => {
    item[field] = event.target.value;
    updateErrors([]);
  };

  const renderItem = (item, index) => {
    return (
      <div key={ `listItem-${index}` } className="quota-item -bigger">
        <label>{ capitalize(item.answer) }</label>
        <input
          type="text"
          className={ `form-field${item.highlightQuota ? ' has-error' : ''}` }
          value={ item.quota || "" }
          onChange={ (event) => { changeText(event, item, 'quota'); } }
          maxLength="3"
        />
      </div>
    );
  };

  return (
    <>
      <a
        className="quotas"
        href="#"
        onClick={ (event) => openModal(event) }
      >
        { linkTitle }
      </a>
      {
        showForm &&
        <Modal
          className="-quota -min-width"
          dialogClassName={`-fit-window -min-width ${platformName ? platformName + '-modifier':''}`}
          backDropModifier={platformName ? platformName + '-modifier':''}
          showCross={ showCross }
          onClose={ (event) => { onClose(event); } }
        >
          <>
            <h2 className="normal-popup-title">
              { question.title } &rarr; { title }
            </h2>
            {
              !errors.length &&
              <div className="normal-popop-description">
                Please note your percent cannot be set to 0% and the sum of all options per question should equal 100%.
              </div>
            }
            {
              !!errors.length &&
              <div className="quota-error-message">
                {
                  map(errors, (error, index) => (
                    <div key={ `break-error-${index}` }>{ error }</div>
                  ))
                }
              </div>
            }
            <QuotaModeSwitcher />
            <div className="question-list-header -row -with-top-margin">
              {
                map(breaks, (q, topIndex) => (
                  <div
                    key={ `header-${q.title}${topIndex}` }
                    className="question-item"
                  >
                    <h3>
                      { q.title },
                      { ' ' }
                      % (Total: { totalQuotaSum(q.quotasAnswers) }%)
                    </h3>
                  </div>
                ))
              }
            </div>
            <div className="question-list -row">
              {
                map(breaks, (q, topIndex) => (
                  <div
                    key={ `row-${q.title}${topIndex}` }
                    className="question-item"
                  >
                    <div className="quotas-list">
                      {
                        map(
                          q.quotasAnswers,
                          (item, deepIndex) => renderItem(item, deepIndex)
                        )
                      }
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="normal-popup-buttons">
              <button
                className="button"
                type="button"
                onClick={ (event) => { onSave(event); } }
              >
                Save
              </button>
              <button
                className="button -secondary"
                type="button"
                onClick={ (event) => { onClose(event); } }
              >
                Cancel
              </button>
            </div>
          </>
        </Modal>
      }
    </>
  );
};

export default BreaksEditQuota;
