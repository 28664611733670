import React, { useState, Fragment } from 'react';
import { map } from 'lodash';
import Header from "./common/header";

const Lines = ({ onContinue, customHeader }) => {

  const [selectedLine, selectLine] = useState(null);

  const onLineClick = (event, num) => {
    event.preventDefault();
    selectLine(num);
  };

  return (
    <Fragment>
      <div className="layout">
        {
          !!customHeader ? customHeader : <Header/>
        }
        <div className="content -paddings">
          <h2 className="line-question">How many lines do you need?</h2>
          <div className="line-note">You’ll need a line per device.</div>
          <div className="start_counts -billing">
            {
              map([1, 2, 3, 4, 5], (num, index) => {
                return (
                  <h2
                    key={ `line${index}` }
                    className={ `start_counts-item${selectedLine === num ? ' -selected' : ''}` }
                    onClick={ (event) => { onLineClick(event, num) } }
                  >
                    { num }
                  </h2>
                )
              })
            }
            </div>
        </div>
        <div className="footer -paddings">
          <button onClick={() => onContinue(selectedLine)} className="button -billing -lines" disabled={ selectedLine ? '' : 'disabled' }>
            Continue
          </button>
        </div>
      </div>
    </Fragment>
  )
};

export default Lines;
