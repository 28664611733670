import React from 'react';

const TrGray = ({ colSpan, text, hint, modifier }) => {
  const mainColSpan = colSpan > 1 ? 2 : 1;
  const tailColSpan = colSpan > 1 ? (colSpan - 1) : 0;
  return (
    <tr>
      <th colSpan={ mainColSpan } className={`th-section -sticky-left ${modifier ? modifier:''}`}>
        <b>{text}</b>
        {!!hint &&
          <>
            <br />
            {hint}
          </>
        }
      </th>
      {!!tailColSpan &&
        <th colSpan={ tailColSpan } className={`th-section ${modifier ? modifier:''}`} />
      }
    </tr>
  );
};

export default TrGray;
