import React from 'react';

const CardPlanTitle = ({ name, price, subtitle, bigPlusTaxes, bundleSubtitle }) => {
  return <>
    <h6 className="choose-plan-card-title">{name}</h6>
    {subtitle && <h6 className="choose-plan-card-subtitle">{subtitle}</h6>}
    {bundleSubtitle && <h6 className="plan-short-description-subtitle -checkout-title">{bundleSubtitle}</h6>}
    <div className="choose-plan-card-subtitle">
      <span className="choose-plan-card-price">${price}</span>
      {
        bigPlusTaxes ?
          <span className="choose-plan-card-price-tip">/line per month Plus taxes & fees.</span>
          :
          <span className="choose-plan-card-price-tip">/line per month plus taxes & fees.</span>
      }
    </div>
  </>
};

export default CardPlanTitle;