import React, { useContext } from 'react';
import HeaderLinks from "../../../common/components/header-links";
import { IndexData } from "../../../common/index-context";
import renderRawHtml from '../../../../../common/render_raw_html';
import BrandComparisonModal from "../brand-comparison-modal";

const Header = ({ reports }) => {
  const { init, data, brandComparisonModalData } = useContext(IndexData);

  return (
    <>
      <div className="content_top">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item -grow">
              <h1 className="header_title" { ...renderRawHtml(init.project.title) } />
            </div>
            <div className="header_grid-item">
              <HeaderLinks
                className="-right-sided -no-min-width"
                exportUrl={ init.urls.export }
                reports={ reports }
                i18n={ init.i18n }
                data={ data }
                tourName="reportExports"
              />
            </div>
          </div>
        </header>
      </div>
      { !!brandComparisonModalData && <BrandComparisonModal /> }
    </>
  );
};

export default Header;
