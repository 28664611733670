import React, { useRef, useState } from 'react';
import { isEmpty } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import PageLoader from "../../common/PageLoader";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import Select from "../common/select";
import Toggle from '../../common/toggle';
import HiddenFields from "./hiddenFields";

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState(() => {
    return {
      projectId: data.id,
      caseID: data.verizon_express_setting.case_id || '',
      conceptTitle: data.verizon_express_setting.concept_title || '',
      conceptType: data.verizon_express_setting.concept_type || '',
      actionWord: data.verizon_express_setting.action_word || '',
      processDescription: data.verizon_express_setting.process_description || '',
      category: data.verizon_express_setting.category || '',
      includeDeviation: data.verizon_express_setting.include_deviation || '',
      errors
    };
  });

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  return (
    <WithSubmit formId="form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header title="Concept Details" />
        <div className="content_body">
          <div className="form">
            {!!options.jid && <PageLoader options={ options } />}

            <FieldHeaderWithText
              id="project-CaseID"
              title="Case ID"
              errors={ errors['verizon_express_setting.case_id'] }
              initValue={ model.caseID }
              onChange={ (v) => { setModel({ ...model, caseID: v }); } }
            />

            <FieldHeaderWithText
              id="project-ConceptTitle"
              title="Case Name"
              errors={ errors['verizon_express_setting.concept_title'] }
              initValue={ model.conceptTitle }
              onChange={ (v) => { setModel({ ...model, conceptTitle: v }); } }
            />

            <Select
              id="project-ConceptType"
              title="Concept Type"
              initValue={ model.conceptType }
              options={ options.concept_type_options }
              onChange={ (v) => { setModel({ ...model, conceptType: v }); } }
              errors={ errors['verizon_express_setting.concept_type'] }
            />

            <Select
              id="project-ActionWord"
              title="Action Word"
              initValue={ model.actionWord }
              options={ options.action_word_options }
              onChange={ (v) => {setModel({ ...model, actionWord: v });} }
              errors={ errors['verizon_express_setting.action_word'] }
            />

            <Select
              id="project-ProcessDescription"
              title="Process Description"
              initValue={ model.processDescription }
              options={ options.process_description_options }
              onChange={ (v) => {setModel({ ...model, processDescription: v });} }
              errors={ errors['verizon_express_setting.process_description'] }
            />

            <FieldHeaderWithText
              id="project-Category"
              title="Category"
              errors={ errors['verizon_express_setting.category'] }
              initValue={ model.category }
              onChange={ (v) => { setModel({ ...model, category: v }); } }
            />
            <div className="form_section">
              <Toggle
                className={ '-brand-user-only' }
                checked={ model.includeDeviation }
                hint={ <>This will add or remove deviation section.</> }
                onChange={ (v) => { setModel({ ...model, includeDeviation: v }); } }
              >
                Include Deviation Scenario / Unhappy Path
              </Toggle>
            </div>
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
