import React from 'react';
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";
import Wysiwyg from "../../../../common/components/wysiwyg";

const Descriptor = ({ model, setModel, descriptorOptions, errors, options }) => {
  const handleOnChangeSurveyDescriptor = (event) => {
    setModel({ ...model, surveyDescriptor: event.target.value });
  };

  const handleOnChangeBrandName = (event) => {
    setModel({ ...model, brandName: event.target.value });
  };

  const handleOnChangeSwitcher = (event) => {
    model.surveyDescriptorSettings[customTextSwitcherField()] = event.target.checked;
    setModel({ ...model, surveyDescriptorSettings: model.surveyDescriptorSettings });
  };

  const handleOnChangePosition = (event) => {
    model.surveyDescriptorSettings[customTextPositionField()] = event.target.value;
    setModel({ ...model, surveyDescriptorSettings: model.surveyDescriptorSettings });
  };

  const handleOnChangeText = (html) => {
    model.surveyDescriptorSettings[customTextField()] = html;
    setModel({ ...model, surveyDescriptorSettings: model.surveyDescriptorSettings });
  };

  const descriptorHasError = !!errors.survey_descriptor;
  const brandNameHasError = !!errors.brand_name;

  const translationText = () => {
    return options.translations[`survey_copy_maxdiff_groups_desktop_${model.surveyDescriptor}`];
  };

  const customTextSwitcherField = () => {
    return options.custom_text_fields[model.surveyDescriptor]['switcher'];
  };

  const customTextPositionField = () => {
    return options.custom_text_fields[model.surveyDescriptor]['position'];
  };

  const customTextField = () => {
    return options.custom_text_fields[model.surveyDescriptor]['text'];
  };

  return (
    <div className="form_section -padding-bottom">
      <div className="form_grid">
        <div className="form_grid-item -width-1-2">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>MaxDiff Descriptor</h3>
            </div>
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>
                <div className="info-tooltip_drop">
                  <div className="info-tooltip_drop-inner">
                    Choose which text you would like to use for the MaxDiff exercise.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={ cn("form-group", { 'has-error': descriptorHasError }) }>
            <div className="form_section">
              <select
                id="survey-descriptor"
                className="form-field -block"
                value={ model.surveyDescriptor }
                onChange={ handleOnChangeSurveyDescriptor }
              >
                {map(descriptorOptions, (option) => (
                  <option key={ option[1] } value={ option[1] }>{option[0]}</option>
                ))}
              </select>
            </div>

            <div className="form_section">
              <div className="form_section-title -grid">
                <div className="form_section-title-item -grid">
                  <div className="toggle">
                    <input
                      type="checkbox"
                      name="custom_text_switcher"
                      id="custom_text_switcher"
                      checked={ model.surveyDescriptorSettings[customTextSwitcherField()] }
                      onChange={ handleOnChangeSwitcher }
                      className="hide"
                    />
                    <label className="form-switch" htmlFor="custom_text_switcher" />
                  </div>
                  <h3>Custom Text for MaxDiff groups question</h3>
                </div>

                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <SvgIcon name="i-info" />
                    </div>
                    <div className="info-tooltip_drop">
                      <div className="info-tooltip_drop-inner">
                        Template Text:
                        <br />
                        <b>{ translationText() }</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {model.surveyDescriptorSettings[customTextSwitcherField()] &&
                <>
                  <div className="form-group" />
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Enter Custom Text"
                    enableIndent
                    value={ model.surveyDescriptorSettings[customTextField()] }
                    onChange={ handleOnChangeText }
                  />
                  <select
                    id="settings-position"
                    className="form-field -block -with-top-margin"
                    value={ model.surveyDescriptorSettings[customTextPositionField()] }
                    onChange={ handleOnChangePosition }
                  >
                    {map(options.custom_text_positions, (option) => (
                      <option key={ option.name } value={ option.name }>{option.text}</option>
                    ))}
                  </select>
                </>}

            </div>
          </div>
        </div>

        {model.surveyDescriptor === 'favour' &&
          <div className="form_grid-item -width-1-2">
            <div className="form_section-title -grid">
              <div className="form_section-title-item">
                <h3>Product Brand</h3>
              </div>
              <div className="form_section-title-item">
                <div className="info-tooltip">
                  <div className="info-tooltip_control">
                    <SvgIcon name="i-info" />
                  </div>
                  <div className="info-tooltip_drop">
                    <div className="info-tooltip_drop-inner">
                      Brand name that will be shown to respondent.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={ cn("form-group", { 'has-error': brandNameHasError }) }>
              <input
                placeholder="Please enter brand name"
                className="form-field -block"
                type="text"
                value={ model.brandName }
                onChange={ handleOnChangeBrandName }
              />
              {brandNameHasError &&
                <div className="form-error">
                  {errors.brand_name.join('; ')}
                </div>}
            </div>
          </div>}
      </div>
    </div>
  );
};

export default Descriptor;
