import React, { useState, useEffect, useContext } from 'react';
import { map, compact } from "lodash";
import TagsInput from "../../../common/components/tags-input";
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import renderRawHtml from "../../../../../common/render_raw_html";
import SentimentList from '../../../common/components/express-open-end-card/sentiment-list';
import { ExchangeData } from "../../../common/components/express-open-end-card/exchange-data";

const RightPanel = ({ data, metric, filter, answerHelp, isAdmin, setLoading, projectId }) => {
  const [ afterFilterIds, setAfterFilterIds ] = useState([]);
  const [ initTags, setInitTags ] = useState([]);

  const loadKeywords = (q, resolve) => {
    const url = `/express/api/text_answers/keywords`;
    const params = {
      filter: filter.demographics || [],
      project_id: projectId,
      q
    };

    return Http.post(
      url,
      params
    ).then(
      (http) => {
        if (http.response.status === 200) {
          const variants = map(http.data, (el) => ({ value: el.text, label: el.text }));
          if (q) {
            variants.unshift({ value: q, label: q });
          }
          resolve(variants);
        } else {
          resolve([]);
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const filterAnswers = (tags) => {
    if (tags.length > 0) {
      setLoading(true);

      const q = tags.join('|');
      Http.post(
        '/express/api/text_answers/filter',
        {
          project_id: projectId,
          q,
          filter: (filter.demographics  || [])
        }
      ).then(
        (http) => {
          if (http.response.status === 200) {
            setAfterFilterIds(map(http.data, (el) => el.id));
          }
          setLoading(false);
        },
        (reject) => {console.log('something went wrong', reject);}
      );
    } else {
      setAfterFilterIds(map(data.all_neutral_answers, (el) => el.id));
    }
  };

  const lcKey = `openEndsSection${data.id}`;

  const onTagsChange = (tags) => {
    window.localStorage.setItem(lcKey, JSON.stringify(tags));
    filterAnswers(tags);
  };

  useEffect(() => {
    const parsedTags = compact(JSON.parse(window.localStorage.getItem(lcKey) || '[]'));
    setInitTags(parsedTags);
    setAfterFilterIds(map(data.all_neutral_answers, (el) => el.id));
    filterAnswers(parsedTags);
  }, [ data ]);

  const { message, setMessage } = useContext(ExchangeData);

  return (
    <div className="sentiments">
      <div className="sentiments_header">
        <div className="tile-header -no-border -nowrap-in-standalone">
          <div className="tile-header_main">
            <TagsInput
              initTags={ initTags }
              loadKeywords={ loadKeywords }
              onTagsChange={ onTagsChange }
              autoTag={ message }
              setAutoTag={ setMessage }
            />
          </div>
        </div>
      </div>

      <div className="sentiments_content">
        <div className="sentiments_content-main">
          {answerHelp && <div className="sentiments-help">
            <div className="info-tooltip_control -help-on -help-top">
              <span>
                <SvgIcon name="i-info" />
                <span className="-help -margin-top -right">
                  <span className="inner" { ...renderRawHtml(answerHelp) } />
                </span>
              </span>
            </div>
          </div>}

          <div className="sentiments_content-scrollable">
            <SentimentList data={ data } metric={ metric } isAdmin={ isAdmin } afterFilterIds={ afterFilterIds } />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;
