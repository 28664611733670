import React, { useState, useRef, useContext } from 'react';
import classnames from 'classnames';
import { baseContext } from '../../common/contexts';
import renderRawHtml from '../../../../common/render_raw_html';
import TouchModal from './touch_modal';

const ZoomImage = ({
  className, src, children, withHeadWrapper, hideCopy,
  additionalContent, customNote, altText, popupClassName
}) => {
  const [ clicked, setClicked ] = useState(false);
  const imgEl = useRef(null);
  const { translations } = useContext(baseContext);
  const touchClassName = popupClassName || "-image-in-modal";

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setClicked(true);
  };

  const hint = customNote || translations.image_in_modal_hint;

  const mainContent = (
    additionalContent ?
      (
        <div className="with-additional-content">
          <div className="image-content">
            <div className="prev-force-pointer" />
            <div className="zoom-image-wrapper">
              <img
                className={ classnames("zoom-image-src -force-pointer", className) }
                src={ src }
                ref={ imgEl }
                alt={ altText }
              />
              <button
                className="zoom-image-button"
                onClick={ handleClick }
                aria-hidden
                // add aria-label only for WAVE tool
                aria-label="Zoom image"
              />
            </div>
            {
              !hideCopy &&
              <div
                aria-hidden
                className={ classnames("image-enlarge-text", className) }
                { ...renderRawHtml(hint) }
              />
            }
          </div>
          <div className="additional-content">{ additionalContent }</div>
        </div>
      )
      :
      (
        <>
          <div className="prev-force-pointer" />

          <div className="zoom-image-wrapper">
            <img
              className={ classnames("zoom-image-src -force-pointer", className) }
              src={ src }
              ref={ imgEl }
              alt={ altText }
            />
            <button
              className="zoom-image-button"
              onClick={ (event) => { handleClick(event); } }
              aria-hidden
              // add aria-label only for WAVE tool
              aria-label="Zoom image"
            />
          </div>
          {
            !hideCopy &&
            <div
              aria-hidden
              className={ classnames("image-enlarge-text", className) }
              { ...renderRawHtml(hint) }
            />
          }
        </>
      )
  );

  return (
    <>
      {
        withHeadWrapper ?
          <div className="wrapper-white-back">{ mainContent }</div>
          :
          <>{ mainContent }</>
      }
      {
        clicked &&
        <TouchModal
          className={ touchClassName }
          sourceElement={ imgEl.current }
          onClose={ () => { setClicked(false); } }
        >
          { children }
        </TouchModal>
      }
    </>
  );
};

export default ZoomImage;
