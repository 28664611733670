import React, { useState, useEffect, useRef, useContext } from 'react';
import { map } from 'lodash';
import Modal from '../../../../../common/components/modal';
import Http from '../../../../../common/http';
import { IndexData } from './contexts';

const CreateForm = ({
  createPath, categories, countries,
  selectedType, selectedTitle, onClose
}) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [ loading, setLoading ] = useState(false);
  const [ projectNumber, setProjectNumber ] = useState('');
  const [ title, setTitle ] = useState('');
  const [ categoryId, setCategoryId ] = useState('');
  const [ countryName, setCountryName ] = useState('');
  const [ errors, setErrors ] = useState({});
  const { buzzbackUser } = useContext(IndexData);

  const lCodeByName = (name) => {
    const index = countries.findIndex((v) => v.name === name);
    return countries[index].language_code;
  };

  const onContinue = () => {
    setLoading(true);

    Http.post(createPath, {
      project: {
        platform_id: projectNumber,
        title,
        product_category_id: categoryId,
        country_name: countryName,
        language_code: lCodeByName(countryName),
        express: selectedType
      }
    }).then(
      (http) => {
        if (http.response.status === 200) {
          window.location.href = http.data.url;
        } else if (http.response.status === 422) {
          if (isMounted.current) {
            setErrors(http.data.errors);
            setLoading(false);
          }
        } else if (isMounted.current) {
          setLoading(false);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
        if (isMounted.current) {
          setLoading(false);
        }
      }
    );
  };

  return (
    <Modal dialogClassName="-centered-form" modalSize="sm" showCross onClose={ onClose }>
      <div className="modal_body">
        <h2>Create Multi Language Project ({ selectedTitle })</h2>

        <div className="form_section">
          {
            buzzbackUser &&
            <div className="form_grid">
              <div className="form_grid-item -width-full">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Buzzback project number</h3>
                  </div>
                  <div className="form_section-title-item">
                    <div className="info-tooltip">
                      <div className="info-tooltip_control">
                        <svg className="icon -i-info">
                          <use xlinkHref="#svg_i-info" />
                        </svg>
                      </div>
                      <div className="info-tooltip_drop">
                        <div className="info-tooltip_drop-inner">
                          Enter the buzzback job number for this project for tracking purposes.
                          <br />
                          It will be applied to all sub-projects.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    maxLength={ 250 }
                    placeholder="Enter project number"
                    className="form-field -block"
                    type="text"
                    value={ projectNumber }
                    onChange={ (e) => setProjectNumber(e.currentTarget.value) }
                  />
                  {
                    errors.platform_id && errors.platform_id.length > 0 &&
                    <div className="form-error">{ errors.platform_id.join('; ') }</div>
                  }
                </div>
              </div>
            </div>
          }

          <div className="form_grid">
            <div className="form_grid-item -width-full">
              <h3 className="form_section-title">Project Name</h3>
              <div className="form-group">
                <input
                  placeholder="Please name your project"
                  className="form-field -block"
                  type="text"
                  value={ title }
                  onChange={ (e) => setTitle(e.currentTarget.value) }
                />
                {
                  errors.title && errors.title.length > 0 &&
                  <div className="form-error">{ errors.title.join('; ') }</div>
                }
              </div>
            </div>
          </div>

          <div className="form_grid">
            <div className="form_grid-item -width-full">
              <div className="form_section-title -grid">
                <div className="form_section-title-item">
                  <h3>Project Category</h3>
                </div>
                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <svg className="icon -i-info">
                        <use xlinkHref="#svg_i-info" />
                      </svg>
                    </div>
                    <div className="info-tooltip_drop">
                      <div className="info-tooltip_drop-inner">The selected category will be the same for all sub-projects.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <select
                  className="form-field -block"
                  value={ categoryId }
                  onChange={ (e) => setCategoryId(e.currentTarget.value) }
                >
                  <option value="">Please select one</option>
                  {
                    map(categories, (pc, index) => (
                      <option key={ `Category${pc.id}${index}` } value={ pc.id }>
                        {pc.name}
                      </option>
                    ))
                  }
                </select>
                {
                  errors.product_category_id && errors.product_category_id.length > 0 &&
                  <div className="form-error">{ errors.product_category_id.join('; ') }</div>
                }
              </div>
            </div>
          </div>

          <div className="form_grid">
            <div className="form_grid-item -width-full">
              <div className="form_section-title -grid">
                <div className="form_section-title-item">
                  <h3>Base Country</h3>
                </div>
                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <svg className="icon -i-info">
                        <use xlinkHref="#svg_i-info" />
                      </svg>
                    </div>
                    <div className="info-tooltip_drop">
                      <div className="info-tooltip_drop-inner">Country for a base project.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <select
                  className="form-field -block"
                  value={ countryName }
                  onChange={ (e) => setCountryName(e.currentTarget.value) }
                >
                  <option value="">Please select one</option>
                  {
                    map(countries, (c, index) => (
                      <option key={ `Country${c.id}${index}` } value={ c.name }>
                        { c.name }
                      </option>
                    ))
                  }
                </select>
                {
                  errors.country_name && errors.country_name.length > 0 &&
                  <div className="form-error">{errors.country_name.join('; ')}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer -ml">
        <button className="button modal_button -darkest-grey" onClick={ onClose }>
          Cancel
        </button>
        <button className="button modal_button" disabled={ loading } onClick={ onContinue }>
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default CreateForm;
