import React from 'react';
import Slider from "react-slick";
import remoteFiles from '../../../common/remote_files';

const AboutValues = () => {
  const {
    aboutValue1, aboutValue2, aboutValue3,
    aboutValue4, aboutValue5, aboutValue6
  } = remoteFiles.visibleCom;
  const valuesList = [
    {
      title: 'Transparency',
      text: 'Honesty is the most important part of a relationship. Here you won’t find bs, fine-print, or corporate politics. ' +
        'Just member-centered decision making. ',
      backgroundImage: aboutValue1
    },

    {
      title: 'Connection',
      text: 'Access is fundamental. We keep costs low because no one should have to trade quality for price to access essential connections.',
      backgroundImage: aboutValue2
    },

    {
      title: 'Community',
      text: 'Bringing people together is the core of what we do. And because that’s our focus, our members are our ' +
        'greatest champions in growing our Visible community.',
      backgroundImage: aboutValue3
    },

    {
      title: 'Innovation',
      text: 'Ideas come from curiosity, and we’re a team of really curious minds. Our approach to innovation means ' +
        'we don’t look to other companies on how to do it—we look beyond them.',
      backgroundImage: aboutValue4
    },

    {
      title: 'Impact',
      text: 'Our resolution is to create an impactful change by ensuring all are seen, heard, and ' +
        'empowered—regardless of race, gender, ethnicity, sexual orientation, or socio-economic status. ',
      backgroundImage: aboutValue5
    },

    {
      title: 'Fun',
      text: 'We’ve made fun a core part of who we are and how we work, while still taking our goal of providing simple, ' +
        'accessible, and inclusive wireless service seriously.',
      backgroundImage: aboutValue6
    }

  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className="about-values">
      <h3 className="about-values_title">
        Our Values
      </h3>

      <div className="about-values_list-wrapper">
        <Slider className="about-values_list" { ...settings }>
          {
            valuesList.map((value, index) => (
              <li className="about-values_list-item" key={ `value-${index}` }>
                <div
                  className="about-values_list-image"
                  style={ { backgroundImage: `url(${value.backgroundImage})` } }
                />
                <h4 className="landing-how-works_step-title">{value.title}</h4>
                <p className="landing-how-works_step-text">{value.text}</p>
              </li>
            ))
          }
        </Slider>
      </div>


    </section>
  );
};

export default AboutValues;
