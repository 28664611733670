import React, { useState } from 'react';
import { generateId } from "../../../../verizon/verizon-5g/helpers";

const Checkbox = ({ title, value, paramName, onChange, disabled, className='' }) => {
  const [ id ] = useState(generateId());
  const [ val, setVal ] = useState(value);
  const handleOnChange = () => {
    if (disabled) {
      return;
    }
    setVal(!val);
    onChange(!val, paramName);
  };

  return (
    <div className={ `vcc_checkbox ${className}` }>
      <input
        id={ id }
        type="checkbox"
        disabled={ disabled }
        checked={ val }
        onChange={ () => {} }
      />
      <label
        className="vcc_checkbox_label"
        htmlFor={ id }
        onClick={ handleOnChange }
      >
        { title }
      </label>
    </div>
  );
};

export default Checkbox;
