import React from "react";
import sections from "../mocks/quickSearchSections";
import SimpleCarousel from "./SimpleCarousel";

const QuickSearch = () => {
  return (
    <div className="quick-search my-5">
      <h3 className="mx-3 my-0">Quick search</h3>
      {sections.map(({ title, items }, i) => (
        <div key={i}>
          <h4 className="mx-3 mt-3">{title}</h4>
          <SimpleCarousel className="px-3" items={items} />
        </div>
      ))}
    </div>
  );
};

export default QuickSearch;
