import React, { Fragment, useContext, useState, useEffect } from 'react';
import { each, map, cloneDeep, values, flatten, isEmpty } from 'lodash';
import classnames from 'classnames';
import { AppContext } from '../../contexts';

const EditQuotas = () => {
  const {
    project, setProject, globalDisabled, setNotificationObject, sendRequest
  } = useContext(AppContext);
  const customQuotas = project.custom_quotas;
  const available = customQuotas?.available;
  const updatePath = customQuotas?.paths?.update;
  const defaultItems = () => {
    const elements = cloneDeep(customQuotas?.items || []);
    const resultingHash = {};
    let header = null;
    each(elements, (row) => {
      if (row.header) {
        header = row.header;
      }
      if (!resultingHash[header]) {
        resultingHash[header] = [];
      }
      resultingHash[header].push(row);
    });
    return resultingHash;
  };
  const [ editMode, setEditMode ] = useState(false);
  const [ items, setItems ] = useState(defaultItems);
  const [ errors, setErrors ] = useState({});
  useEffect(() => {
    setItems(defaultItems());
  }, [ customQuotas ]);

  const generateCustomQuotasHash = () => {
    const resultingHash = {};
    each(flatten(values(items)), (qItem) => {
      resultingHash[qItem.tech_name] = qItem.value;
    });
    return resultingHash;
  };

  const handleClearQuotas = () => {
    sendRequest(updatePath, { clear_custom_quotas: true }, (response) => {
      setProject(response.data.projects);
      setNotificationObject({ message: 'Quotas are set to default values.', success: true });
    }, (response) => {
      setNotificationObject({
        message: response?.data?.error || `Something went wrong, please try again later.`,
        success: false
      });
    });
  };

  const sendUpdateQuotasRequest = () => {
    sendRequest(updatePath, { custom_quotas: generateCustomQuotasHash() }, (response) => {
      setProject(response.data.projects);
      setNotificationObject({ message: 'Quotas have been updated.', success: true });
      setEditMode(false);
    }, (response) => {
      setNotificationObject({
        message: response?.data?.error || `Something went wrong, please try again later.`,
        success: false
      });
      setEditMode(false);
    });
  };

  const inputQuotasValid = () => {
    const errorsHash = {};
    each(flatten(values(items)), (row) => {
      if (!`${row.value}`.match(/^\d+$/)) {
        errorsHash[row.tech_name] = true;
      }
    });
    setErrors(errorsHash);
    return isEmpty(errorsHash);
  };

  const handleUpdateQuotas = () => {
    if (inputQuotasValid()) {
      sendUpdateQuotasRequest();
    } else {
      setNotificationObject({
        message: 'Some quotas values are invalid.',
        success: false
      });
    }
  };

  const handleEditMode = () => {
    setEditMode(true);
  };

  const handleCancelUpdate = () => {
    setItems(defaultItems());
    setEditMode(false);
  };

  const disableButtons = globalDisabled;

  const handleQValueChange = (event, row) => {
    row.value = event.target.value;
    setItems({ ...items });
  };

  return (
    <>
      {
        available ? (
          <div className="form_section quota-edit-section">
            <div className="form_grid">
              <div className="form_grid-item -width-full edit-info-item-external edit-size-form">
                <h3 className="form_section-title">
                  Update Quotas
                </h3>
                <div className="form-group">
                  {
                    map(items, (rows, header) => {
                      return (
                        <Fragment key={ header }>
                          <h4>{ header }</h4>
                          <table className="quota-table text-font">
                            <thead>
                              <tr className="quota-titles">
                                <th>Quota Title</th>
                                <th>Current N</th>
                                <th>Target N</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                map(rows, (row) => {
                                  const qTitle = row.titles.join(' + ');
                                  const qValue = row.value;
                                  const qCurrent = row.current_n;
                                  const hasError = errors[row.tech_name];
                                  return (
                                    <tr className="quota-row" key={ `${header}${qTitle}` }>
                                      <td>{ qTitle }</td>
                                      <td>{ qCurrent }</td>
                                      <td>
                                        {
                                          editMode ? (
                                            <input
                                              className={
                                                classnames(
                                                  "form-field -block",
                                                  { 'has-error': hasError }
                                                )
                                              }
                                              value={ qValue }
                                              onChange={ (event) => { handleQValueChange(event, row); } }
                                              maxLength={ 5 }
                                              disabled={ globalDisabled }
                                            />
                                          ) : (
                                            qValue
                                          )
                                        }
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </Fragment>
                      );
                    })
                  }
                </div>
                <footer className="form-footer">
                  <div className="update-buttons">
                    {
                      editMode ? (
                        <>
                          <button
                            disabled={ disableButtons }
                            className="button survey-flow-button"
                            onClick={ handleUpdateQuotas }
                          >
                            Update
                          </button>
                          <button
                            disabled={ disableButtons }
                            className="button -secondary -with-border survey-flow-button"
                            onClick={ handleCancelUpdate }
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            disabled={ disableButtons }
                            className="button survey-flow-button"
                            onClick={ handleEditMode }
                          >
                            Set Custom Quotas
                          </button>
                          <button
                            disabled={ disableButtons }
                            className="button -secondary -with-border survey-flow-button"
                            onClick={ handleClearQuotas }
                          >
                            Set to Default
                          </button>
                        </>
                      )
                    }
                  </div>
                </footer>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
};

export default EditQuotas;
