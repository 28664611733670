import React from 'react';
import Main from '../common/main';
import ConceptA from './concept-a';
import { useDataStorage, DataStorageContext } from './hooks';

const Concept = ({ onNext }) => {
  const { 
    total,
    isDataFilled,
    onCheckout,
    ...rest
  } = useDataStorage(onNext);
  return <Main total={total} disableCheckout={!isDataFilled} onCheckout={onCheckout}>
    <DataStorageContext.Provider value={rest}>
      <ConceptA />
    </DataStorageContext.Provider>
  </Main>
}

export default Concept;