import React, { useState } from "react";
import { map } from "lodash";
import cn from "classnames";

const SliderRadioButtons = ({ initValue, options, onChange, disabled, code, modificator }) => {
  const [ val, setVal ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setVal(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className={ cn("radio-tabs", modificator) }>
      {map(options, (option) => (
        <label className="radio-tabs_item" key={ `project-${option.value}` }>
          <input
            id={ `project${code || ''}-${option.value}` }
            type="radio"
            checked={ val === option.value }
            onChange={ handleOnChange }
            value={ option.value }
            disabled={ !!disabled }
          />
          <div className="radio-tabs_label">{option.title}</div>
        </label>
      ))}
    </div>
  );
};

export default SliderRadioButtons;
