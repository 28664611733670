import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const Intro = ({ addQuestion, hasNumberLimit, numberLimit }) => {
  return (
    <div className="form-intro">
      <div className="form-intro_content">
        <div className="form-intro_section">
          <div className="form-intro_section-inner -center -text-center">
            <SvgIcon name="question" />
            <div className="form-intro_title -with-margin-10">
              Custom Metrics allow you to determine<br />
              the kinds of consumers you want to participate.
            </div>
            <div className="form-intro_section-inner -center -text-center">
              <div className="form-intro_text -gray">
                For example, you may be looking for category users of your product, or brand users.<br />
                You may add up to { numberLimit } screening questions to qualify your participants.
              </div>
            </div>
            <div className="form-intro_button">
              <button className="button" onClick={ addQuestion }>
                Add Question
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
