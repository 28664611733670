import React from 'react';
import { map } from 'lodash';
import GreenRed from '../../../../../express/researcher/verizon_customer_case_v2024/components/greenRed';
import CommonTrs from './commonTrs';
import TrGray from './trGray';
import TrData from './trData';

const Diagnostics = ({ pids, data, brandedTexts }) => {
  const cData = data.diagnostics_data;

  const colSpan = pids.length + 1;

  return (
    <>
      <div className="comparisons-table_wrapper">
        <table className="table comparisons-table">
          <tbody>
            <CommonTrs pids={ pids } data={ data } />

            <TrGray
              colSpan={ colSpan }
              text="&apos;Big Idea&apos; Diagnostics"
              modifier="-black"
            />

            <tr>
              <th className="th th-section col-name -sticky-left -no-zindex">
                <b>Category</b>
              </th>
              {map(pids, (pid) => (
                <th key={ pid } className="col-data -center th th-section">{data.scorecard_data[pid].category}</th>
              ))}
            </tr>

            <TrData
              pids={ pids }
              data={ cData }
              text="Category Need"
              hint='("Absolutely" or "Very much" needed)'
              metric="vcc_need"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Satisfaction with Category Solutions"
              hint='("Extremely" or "Very" satisfied)'
              metric="vcc_satisfaction"
            />

            <TrGray
              colSpan={ colSpan }
              text={ brandedTexts.solution }
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Appeal"
              hint='("Extremely" or "Very" appealing)'
              metric="vcc_appeal"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Difference"
              hint='(Agree "Completely" or "Somewhat")'
              metric="vcc_difference"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Experience is Worthwhile"
              hint='(Agree "Completely" or "Somewhat")'
              metric="vcc_worthwhile"
            />

            <TrGray
              colSpan={ colSpan }
              text="Experience Diagnostics"
              modifier="-black"
            />

            <TrGray
              colSpan={ colSpan }
              text="Experience  Ease / Effort"
              hint="(Full Scale)"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="No Effort / A little effort"
              hint="(Ease KPI)"
              metric="vcc_effort_code45"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Some Effort"
              metric="vcc_effort_code3"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Moderate Effort"
              metric="vcc_effort_code2"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="A lot of effort"
              metric="vcc_effort_code1"
            />

            <TrGray
              colSpan={ colSpan }
              text="Additional Diagnostics"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Simple & Intuitive"
              hint='(Agree "Completely" or "Somewhat")'
              metric="vcc_simple"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Honest & No Surprises"
              hint='(Agree "Completely" or "Somewhat")'
              metric="vcc_honest"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="Clarity"
              hint="(“Very” or “Somewhat” clear and understandable)"
              metric="vcc_process_clarity"
            />

            <TrGray
              colSpan={ colSpan }
              text="Experience Impact"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text={ brandedTexts.vccOpinionCode3 }
              metric="vcc_opinion_code3"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text="No Impact"
              metric="vcc_opinion_code2"
            />

            <TrData
              pids={ pids }
              data={ cData }
              text={ brandedTexts.vccOpinionCode1 }
              metric="vcc_opinion_code1"
            />
          </tbody>
        </table>
      </div>
      <GreenRed arrows={ data.arrows_mode } text={ data.arrows_label_text } />
    </>
  );
};


export default Diagnostics;
