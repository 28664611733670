import React, { useState } from 'react';

const Checkbox = ({ title, value, paramName, onChange }) => {
  const id = `${title}`;
  const [ val, setVal ] = useState(value);
  const handleOnChange = () => {
    setVal(!val);
    onChange(!val, paramName);
  };

  return (
    <div className="concept-tags_checkbox">
      <input
        id={ id }
        type="checkbox"
        checked={ val }
      />
      <label
        className="checkbox_label"
        htmlFor={ id }
        onClick={ handleOnChange }
      >
        { title }
      </label>
    </div>
  );
};

export default Checkbox;
