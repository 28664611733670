import React, { useState } from 'react';
import { find } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import { convertDottedErrors } from "../common/helpers";
import remoteFiles from '../../../../common/remote_files';
import Toggle from '../../common/toggle';
import InfoText from "./InfoText";
import HiddenFields from "./HiddenFields";
import Timeframe from "./Timeframe";
import Answers from "./Answers";
import AnswersHeader from "./AnswersHeader";

const App = ({ data, options, errors, images }) => {
  const [ model, setModel ] = useState({
    brandNameTimeframe: data.brand_name_timeframe,
    questions: data.brand_screening_questions
  });

  const qF1 = find(model.questions, (q) => (q.name === 'BRAND_F1'));
  const qF2 = find(model.questions, (q) => (q.name === 'BRAND_F2'));
  const qF3 = find(model.questions, (q) => (q.name === 'BRAND_F3'));
  const qF4 = find(model.questions, (q) => (q.name === 'BRAND_F4'));
  const qF5 = find(model.questions, (q) => (q.name === 'BRAND_F5'));

  const [ answerImages, setAnswerImages ] = useState(images);
  const [ brandUsersOnly, setBrandUsersOnly ] = useState(() => (qF4.brand_users_only));
  const handleToggleBrandUsersOnly = (value) => {
    qF4.brand_users_only = value;
    setBrandUsersOnly(value);
  };

  const {
    brandScreening41, brandScreening42, brandScreening43,
    brandScreening44, brandScreening45
  } = remoteFiles.express.steps.brandSnapshot;

  const convertedErrors = convertDottedErrors(errors);
  const handleChangeAnswers = (q, answers, bNone, tNone, bOther, tOther) => {
    q.answers = answers;
    if (bNone !== undefined) {
      q.none_answer = bNone;
    }
    if (tNone !== undefined) {
      q.none_answer_text = tNone;
    }
    if (bOther !== undefined) {
      q.other_answer = bOther;
    }
    if (tOther !== undefined) {
      q.other_answer_text = tOther;
    }
    setModel({ ...model });
  };

  const handleChangeAnswersImages = (q, imageData, imageSrc) => {
    q.answer_images = imageData;
    setAnswerImages(imageSrc);
    setModel({ ...model });
  };

  return (
    <WithSubmit formId="form" errors={ errors }>
      <HiddenFields options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header title={ options.project_title } />
        <div className="content_body">
          <div className="form">
            <InfoText />

            <Timeframe
              values={ options.timeframe_values }
              initValue={ model.brandNameTimeframe }
              onChange={ (v) => { setModel({ ...model, brandNameTimeframe: v }); } }
            />

            <AnswersHeader
              title={ qF1.title }
              text={ `Respondents must be category users to qualify for the survey. Input up to ${options.max_cu} other categories.` }
              infoText="This question will be used for screening purposes."
              img={ brandScreening41 }
            />
            <Answers
              question={ qF1 }
              disabledIndex={ "1" }
              placeholder="Add Category"
              onChange={ (answers, bNone, tNone, bOther, tOther) => {handleChangeAnswers(qF1, answers, bNone, tNone, bOther, tOther);} }
              errors={ convertedErrors.database_questions && convertedErrors.database_questions[qF1.id] }
              max={ options.max_cu + 1 }
              sections={ { 0: 'Target category', 1: 'Competitors' } }
              options={ options }
              showNone
              showOther
            />
            <hr />
            <AnswersHeader
              title={ qF2.title }
              text="Respondents will list up to 10 top-of-mind brands in the category."
              img={ brandScreening42 }
            />
            <hr />
            <AnswersHeader
              title={ qF3.title }
              text={ `Input up to ${options.max_aba} other brands in the category. Respondents will answer Brand Awareness (unaided/aided), Brand Usage, and Brand Rejection. To qualify, they must either use the Target Brand or be non-rejectors of using it.` }
              infoText="Only respondents aware of the Target Brand will be shown brand-specific questions."
              img={ brandScreening43 }
            />
            <Answers
              question={ qF3 }
              disabledIndex={ "1" }
              placeholder="Add Brand"
              onChange={ (answers) => {handleChangeAnswers(qF3, answers);} }
              onChangeAnswersImages={ (imageData, imageSrc) => { handleChangeAnswersImages(qF3, imageData, imageSrc);} }
              errors={ convertedErrors.database_questions && convertedErrors.database_questions[qF3.id] }
              max={ options.max_aba + 1 }
              sections={ { 0: 'Target brand', 1: 'Competitors' } }
              images={ answerImages }
              withImages
            />

            <hr />
            <AnswersHeader
              title={ qF4.title }
              text="Respondents will be tagged as Brand Users or Brand Non-Users for reporting."
              img={ brandScreening44 }
            >
              <Toggle
                className={ '-brand-user-only' }
                onChange={ handleToggleBrandUsersOnly }
                checked={ brandUsersOnly }
                hint={ <>Only allow to pass the exercise by only target brand users.</> }
              >
                Brand Users Only
              </Toggle>
            </AnswersHeader>
            <hr />
            <AnswersHeader
              title={ qF5.title }
              text="Respondents cannot be rejectors of the target brand."
              img={ brandScreening45 }
            />
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
