import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";

const DonutChart = ({
  high, low, highColor, lowColor, customWidth, customHeight, customRingPart, colorOnly,
  titles, neutralColor, textDefaultColor
}) => {
  const ref = useRef(null);

  const width = customWidth || 300;
  const height = customHeight || 300;
  const ringPart = customRingPart || 0.3;
  const radius = Math.min(width, height) / 2;
  const keyFont = radius *  0.15;
  const percFont = radius * 0.22;
  const percTopMargin = 6;

  const draw = () => {
    const grayColor = neutralColor;
    const total = high + low;
    const list = [
      {
        key: (titles?.length || 0) > 1 ? titles[1] : 'high effort',
        textColor: highColor,
        textDefaultColor,
        color: colorOnly === 'all' || colorOnly === 'high' ? highColor : grayColor,
        value: high
      },
      {
        key: (titles?.length || 0) > 2 ? titles[2] : 'low effort',
        textColor: lowColor,
        textDefaultColor,
        color: colorOnly === 'all' || colorOnly === 'low' ? lowColor : grayColor,
        value: low
      }
    ];
    const data = total > 0 ? list : [ { key: 'n/a', color: grayColor, value: 1 } ];

    const svg = d3.select(ref.current);
    svg.select('svg').remove();

    const arc = d3.arc()
      .outerRadius(radius)
      .innerRadius(radius * (1 - ringPart));

    const pie = d3.pie()
      .startAngle(-Math.PI)
      .endAngle(Math.PI)
      .padAngle(0.01)
      .value((d) => d.value)
      .sort(null);

    const firstG = svg.append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    const chart = firstG.selectAll(".arc")
      .data(pie(data))
      .enter();

    chart.append("g")
      .attr("class", "arc")
      .append("path")
      .attr("d", arc)
      .style("fill", (d) => d.data.color);

    if (total > 0) {
      const text = chart.append("text")
        .attr("transform", (d, i) => `translate(0, ${2.1 * (0.4 + i * 1.2 - 1.6) * percFont})`)
        .attr("text-anchor", "middle");

      text.append('tspan')
        .attr("x", 0)
        .attr("dy", `${percFont}px`)
        .style("fill", (d) => d.data.textColor)
        .style("font-size", () => `${keyFont}px`)
        .text((d) => d.data.key);

      text.append('tspan')
        .attr("x", 0)
        .attr("dy", `${percFont + percTopMargin}px`)
        .style("fill", (d) => d.data.textDefaultColor)
        .style("font-size", () => `${percFont}px`)
        .text((d) => d.data.value ? `${d.data.value}%` : '0%');
    }
  };

  useEffect(() => {
    if (ref.current) {
      draw();
    }
  }, [ high, low, colorOnly, ref.current ]);

  return (<div className="donut-chart" ref={ ref } />);
};

export default DonutChart;
