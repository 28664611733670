import React, { useContext, useState, useEffect } from 'react';
import { LaunchContext } from '../../contexts';
import InfoToolTip from '../../../common/infoToolTip';
import ModalDialog from '../modalDialog';

const TestCard = () => {
  const {
    payedProjects, loading, setLoading, quotaService, sendData, textLaunch, textKlassModifiersMain
  } = useContext(LaunchContext);
  const [ showModal, setShowModal ] = useState(false);

  useEffect(() => {
    if (payedProjects.length) {
      quotaService.checkStatus(
        () => {
          setLoading(true);
        },
        () => {
          setLoading(false);
        },
        true
      );
    }
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLaunch = () => {
    handleClose();
    sendData({ saveOnly: false });
  };

  const handleSaveSettings = () => {
    sendData({ saveOnly: true });
  };

  return (
    <>
      <div className="form_section" />
      <footer className="form-footer -fixed">
        <div className="footer-inner">
          <div className="buttons-grid">
            <div className="button-grid-item">
              <button
                disabled={ loading }
                className="button -secondary -with-border -va-bordered"
                onClick={ handleSaveSettings }
              >
                Save Provider Settings
              </button>
              <InfoToolTip
                className="-force-margin"
                remote
                text={ <>It will save all form data except selected Nsize.</> }
                position="top-right"
              />
            </div>
            <div className="button-grid-item">
              <button className="button" onClick={ handleShowModal } disabled={ loading }>
                { textLaunch }
              </button>
              <InfoToolTip
                className="-force-margin"
                remote
                text={
                  <>
                    It will save all form data before launch.
                    <br />
                    Remember you won’t be able to edit your project
                    {' '}
                    after you pay and launch.
                  </>
                }
                position="top-right"
              />
            </div>
          </div>
        </div>
      </footer>
      {
        showModal &&
        <ModalDialog
          onLaunch={ handleLaunch }
          onClose={ handleClose }
          className={ textKlassModifiersMain }
          backDropModifier={ textKlassModifiersMain }
        >
          <>
            Hit "Ready to Launch" button if you are ready for respondents
            {' '}
            to start coming through the study.
          </>
        </ModalDialog>
      }
    </>
  );
};

export default TestCard;
