import React from 'react';

const ExpressIcon = ({ express }) => {
  return (
    <>
      {
        express === 'swipe' &&
        <svg className="project-preview_title-icon icon -menu-project">
          <use xlinkHref="#svg_menu-project" />
        </svg>
      }
      {
        express === 'claim' &&
        <svg className="project-preview_title-icon icon -menu-project-claims">
          <use xlinkHref="#svg_menu-project-claims" />
        </svg>
      }
      {
        express === 'concepts_test' &&
        <svg className="project-preview_title-icon icon -menu-project-concepts_test">
          <use xlinkHref="#svg_menu-project-concepts_test" />
        </svg>
      }
      {
        express === 'naming' &&
        <svg className="project-preview_title-icon icon -menu-project-naming">
          <use xlinkHref="#svg_menu-project-naming" />
        </svg>
      }
      {
        express === 'flavor' &&
        <svg className="project-preview_title-icon icon -menu-project-flavor">
          <use xlinkHref="#svg_menu-project-flavor" />
        </svg>
      }
      {
        (express === 'package' || express === 'package_mc') &&
        <svg className="project-preview_title-icon icon -menu-project-package">
          <use xlinkHref="#svg_menu-project-package" />
        </svg>
      }
      {
        express === 'adcept' &&
        <svg className="project-preview_title-icon icon -menu-project-adcept">
          <use xlinkHref="#svg_menu-project-adcept" />
        </svg>
      }
      {
        express === 'brand_snapshot' &&
        <svg className="project-preview_title-icon icon -menu-project-brand_snapshot">
          <use xlinkHref="#svg_menu-project-brand_snapshot" />
        </svg>
      }
    </>
  );
};

export default ExpressIcon;
