import React from 'react';
import cn from "classnames";
import Modal from '../../../../common/components/modal';

const ModalDialog = ({ children, onLaunch, onClose, className, backDropModifier }) => (
  <Modal
    dialogClassName={ cn("-fit-window -min-width", className) }
    backDropModifier={ backDropModifier }
    showCross
    onClose={ onClose }
  >
    <div className="normal-popup">
      <div className="close" onClick={ onClose } />
      <h2 className="normal-popup-title">
        You're going to launch your project.
      </h2>
      <div className="normal-popop-description">
        { children }
      </div>
      <div className="normal-popup-buttons">
        <button className="button" type="button" onClick={ onLaunch }>
          Ready to Launch
        </button>
        <button className="button -secondary" type="button" onClick={ onClose }>
          Cancel
        </button>
      </div>
    </div>
  </Modal>
);

export default ModalDialog;
