import React from 'react';
import { map, includes } from 'lodash';
import classnames from 'classnames';
import Arrow from '../../../../../express/researcher/verizon_customer_case_v2024/components/arrow';

const TrData = ({ className, pids, data, text, hint, metric, forceNA, format = 'percent' }) => {
  return (
    <tr>
      <th className={ classnames("th col-name -sticky-left -no-zindex", className) }>
        <div className="bold">{text}</div>
        {!!hint && <div className="note">{hint}</div>}
      </th>
      {map(pids, (pid) => (
        <td key={ pid } className={ classnames("col-data -center", className) }>
          {!(data[pid].no_data || (forceNA && forceNA(pid))) &&
            <>
              <TrDataContent format={ format } data={ data[pid][metric] } />
              {!!data[pid][metric].arrow && <Arrow color={ data[pid][metric].arrow } />}
            </>}
          {(data[pid].no_data || (forceNA && forceNA(pid))) && <div className="note">N/A</div>}
        </td>
      ))}
    </tr>
  );
};

const TrDataContent = ({ format, data }) => {
  const color = data.arrow ? 'black' : data.color;
  return (
    <>
      {format === 'percent' &&
      <b className={ `${color}` }>
        {data.percent_text}
      </b>}
      {format === 'number' &&
        <b className={ `${color}` }>
          {data.percent_value}
        </b>}
      {format === 'plus' &&
        <b className={ `${color}` }>
          {plus(data.percent_value)}
        </b>}
      {format === 'tag' &&
        <b className={ `table_status-label -${color}` }>
          {data.text}
        </b>}
    </>
  );
};

const plus = (v) => {
  if (v === "0" || v === "N/A" || includes(v, "-")) {
    return v;
  }
  return `+${v}`;
};

export default TrData;
