import React from 'react';
import { map } from 'lodash';
import ProjectAsCard from './project-as-card';
import ProjectsAsTable from './projects-as-table';

const Projects = ({ projects, mode }) => {
  return (
    <div className="content_body -relative -projects">
      {
        mode === 'cards' &&
        <div className="projects_list">
          {
            map(projects, (project, index) => (
              <ProjectAsCard key={ `SubProjectCard${index}` } project={ project } />
            ))
          }
        </div>
      }
      {
        mode === 'list' &&
        <ProjectsAsTable projects={ projects } />
      }
    </div>
  );
};

export default Projects;
