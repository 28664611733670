import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import EditableInput from '../common/editableInput';

const ScreenedInput = () => {
  const {
    getScreenedUrl, setScreenedUrl, inputsDisabled, getErrorByField
  } = useContext(PanelServiceContext);
  const screenedUrl = getScreenedUrl();
  const disabled = inputsDisabled();
  const error = getErrorByField('panel_service_screened_url');
  return (
    <EditableInput
      title="Screened Url"
      value={ screenedUrl }
      onChange={ setScreenedUrl }
      disabled={ disabled }
      error={ error }
    >
      Respondents will be redirected to this url after their disqualification.
      {' '}
      Please make sure it includes <b>"%respid%"</b>
      {' '}
      - system will automatically replace this template with the
      {' '}
      actual respondent id.
      <br />
      For example: <b>https://test.provider?respid=%respid%</b>.
    </EditableInput>
  );
};

export default ScreenedInput;
