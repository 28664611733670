import { cloneDeep, each } from 'lodash';
import { getLinesCount } from '../common/statistics';
import {
  concept_a_perk_groups, allPlansIncludeConcept,
  yearPriceGuarantee5, earlyPhoneUpgrades, fiveGNationWide,
  allPerksFlatten, PERK_PRICE
} from '../concept_a/plans_data';
import {
  getForkList, welcomeUnlimited
} from '../concept_c/plans_data';
import { plans_prebuild, plans_construct } from '../concept_d/plans_data';

const concept_d_pricing = [
  [],
  [],
  [],
  [],
  [ 65, 55, 40, 30, 25 ],
  [ 85, 75, 60, 50, 45 ],
  [ 85, 75, 60, 50, 45 ],
  [ 85, 75, 60, 50, 45 ],
  [ 85, 75, 60, 50, 45 ],
  [ 70, 60, 45, 35, 30 ]
];

const includedPerkOrderText = '(included)';

const conceptEPlansConstruct = cloneDeep(plans_construct);
const [ premiumUnlimited, standardUnlimited ] = conceptEPlansConstruct;
standardUnlimited.noPerks = true;
each([ premiumUnlimited, standardUnlimited ], (planObj) => {
  planObj.core.includedDescription = null;
});

const conceptEplansPrebuild = cloneDeep(plans_prebuild);
const [ entertainmentPlan, gamingPlan, shoppingPlan ] = conceptEplansPrebuild;
each([ entertainmentPlan, gamingPlan, shoppingPlan ], (planObj) => {
  planObj.core.includedDescription = null;
});

const CONCEPT_E = {
  allPerksFlatten,
  welcomePlans: [ welcomeUnlimited ],
  plans_prebuild: conceptEplansPrebuild.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  plans_construct: conceptEPlansConstruct.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_d_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  concept_perk_groups: concept_a_perk_groups,
  allPlansIncludeConcept,
  getForkList,
  PERK_PRICE,
  includedPerkOrderText
};

export {
  CONCEPT_E,
  conceptEplansPrebuild as plans_prebuild,
  conceptEPlansConstruct as plans_construct,
  concept_a_perk_groups,
  allPlansIncludeConcept,
  fiveGNationWide,
  yearPriceGuarantee5,
  earlyPhoneUpgrades,
  allPerksFlatten
};
