import React, { useContext } from 'react';
import { filter, find, findIndex, flatten, forEach } from "lodash";
import TagsDropdown from "../../../../researcher/concept-tags/components/tags_dropdown";
import { baseContext } from "../../context";

const ExpressTags = ({ onUpdate }) => {
  const { consts, data } = useContext(baseContext);

  const categoryTags = find(consts.tags.express_category, (elm) => elm.name === data.current_product_category).tags;

  const selectedFirstLayerTags = filter(categoryTags, (tag) => (
    findIndex(data.concept_filters.express_category_tags, (id) => id === tag.value) > -1
  ));

  const secondLayerTagsSuggestions = flatten(selectedFirstLayerTags.map((elm) => elm.children));
  const selectedSecondLayerTags = filter(secondLayerTagsSuggestions, (tag) => (
    findIndex(data.concept_filters.express_category_tags, (id) => id === tag.value) > -1
  ));

  const selectedBrandTags = filter(consts.tags.express_brand, (tag) => (
    findIndex(data.concept_filters.express_brand_tags, (id) => id === tag.value) > -1
  ));

  const selectedCustomTags = filter(consts.tags.express_custom, (tag) => (
    findIndex(data.concept_filters.express_custom_tags, (id) => id === tag.value) > -1
  ));

  const onTagSelect = (tagId, attrName) => {
    const params = {};
    params[attrName] = [ ...data.concept_filters[attrName], tagId ].join(',');
    onUpdate(params);
  };

  const onTagsClear = (values, attrName) => {
    const ids = [];
    if (attrName === 'express_category_tags') {
      let removedChilds = [];
      forEach(selectedFirstLayerTags, (catTag) => {
        const removed = findIndex(values, (delTag) => catTag.value === delTag.value) > -1;
        if (!removed) {
          ids.push(catTag.value);
        } else {
          removedChilds = [ ...removedChilds, ...catTag.children ];
        }
      });

      forEach(selectedSecondLayerTags, (subTag) => {
        const removed = findIndex(values, (delTag) => subTag.value === delTag.value) > -1;
        const removedParent = findIndex(removedChilds, (delTag) => subTag.value === delTag.value) > -1;
        if (!removed && !removedParent) {
          ids.push(subTag.value);
        }
      });
    } else {
      forEach(data.concept_filters[attrName], (anyTagId) => {
        const removed = findIndex(values, (delTag) => anyTagId === delTag.value) > -1;
        if (!removed) {
          ids.push(anyTagId);
        }
      });
    }

    const params = {};
    params[attrName] = ids.join(',');
    onUpdate(params);
  };

  return (
    <>
      <li className="concept-tags_prop-item">
        <div className="concept-tags_prop-title">
          { consts.filter_titles.express_category_tags}
        </div>
        <div className="concept-tags_select_container">
          <TagsDropdown
            tags={ categoryTags }
            selectedTags={ selectedFirstLayerTags }
            tagType="express_category"
            onSelectTag={ (tagObj) => { onTagSelect(tagObj, 'express_category_tags');} }
            onClearTags={ (tagObj) => { onTagsClear(tagObj, 'express_category_tags');} }
            allowCreate={ false }
          />
        </div>
        <div className="concept-tags_select_container">
          <TagsDropdown
            tags={ secondLayerTagsSuggestions }
            selectedTags={ selectedSecondLayerTags }
            tagType="express_category"
            disabled={ !selectedFirstLayerTags.length }
            onSelectTag={ (tagObj) => { onTagSelect(tagObj, 'express_category_tags');} }
            onClearTags={ (tagObj) => { onTagsClear(tagObj, 'express_category_tags');} }
            allowCreate={ false }
            childMode
          />
        </div>
      </li>

      <li className="concept-tags_prop-item">
        <div className="concept-tags_prop-title">
          {consts.filter_titles.express_brand_tags}
        </div>
        <div className="concept-tags_select_container -grow">
          <TagsDropdown
            tags={ consts.tags.express_brand }
            selectedTags={ selectedBrandTags }
            tagType="express_brand"
            onSelectTag={ (tagObj) => {onTagSelect(tagObj, 'express_brand_tags');} }
            onClearTags={ (tagObj) => {onTagsClear(tagObj, 'express_brand_tags');} }
            allowCreate={ false }
          />
        </div>
      </li>

      <li className="concept-tags_prop-item">
        <div className="concept-tags_prop-title">
          {consts.filter_titles.express_custom_tags}
        </div>
        <div className="concept-tags_select_container -grow">
          <TagsDropdown
            tags={ consts.tags.express_custom }
            selectedTags={ selectedCustomTags }
            tagType="express_brand"
            onSelectTag={ (tagObj) => {onTagSelect(tagObj, 'express_custom_tags');} }
            onClearTags={ (tagObj) => {onTagsClear(tagObj, 'express_custom_tags');} }
            allowCreate={ false }
          />
        </div>
      </li>

    </>
  );
};

export default ExpressTags;
