import React, { useContext, useRef } from "react";
import { map } from "lodash";
import cn from "classnames";
import { IndexData } from "../../../common/index-context";
import SvgIcon from "../../../../../common/components/svg-icon";
import SortableHeader from "../../../common/components/concepts-table/sortable-header";
import { n0 } from "../../../common/helpers";

const Index = () => {
  const {
    data, sort, openClaimModal,
    getTourUseEffectArgs
  } = useContext(IndexData);

  const openScorecardModal = (claim) => {
    openClaimModal(data, claim);
  };

  const tourElement = useRef(null);
  const [ callback ] = getTourUseEffectArgs(
    "projectFirstComparisonClaim",
    tourElement
  );

  const onRef = (el, index) => {
    if (!!index || !!tourElement.current) {
      return;
    }
    tourElement.current = el;
    callback();
  };

  return (
    <table className="table table-comparison -stick-header -stick-average">
      <thead>
        {
          !!sort &&
          <tr>
            <th className="table-comparison_name-cell -with-tooltip">
              <h3 className="table-comparison_cell-title -inline">
                Item
              </h3>
              <div className="info-tooltip table-comparison_cell-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>
                <div className="info-tooltip_drop -claims-help-fixed">
                  <div className="info-tooltip_drop-inner -sm">
                    See how the items performed on the MaxDiff exercise.
                  </div>
                </div>
              </div>
            </th>
            <SortableHeader
              className="table-comparison_name-cell -text-right"
              title="Negative Score"
              field="negative_score"
            />
            <SortableHeader
              className="table-comparison_name-cell -text-center"
              title="Net Score"
              field="net_score"
            />
            <SortableHeader
              className="table-comparison_name-cell"
              title="Positive Score"
              field="positive_score"
            />
          </tr>}
      </thead>
      <tbody>
        {
          map(data.analytics, (claim, index) => (
            <tr
              key={ claim.id }
              className={ cn("-clickable", `js-cl-${claim.id}`) }
              onClick={ () => {openScorecardModal(claim);} }
            >
              <td className="table-comparison_name-cell">
                <div
                  className="table-comparison_cell-link"
                  ref={ (el) => { onRef(el, index); } }
                >
                  <a className="link -decorated -normal-weight">
                    { claim.label }
                  </a>
                </div>
              </td>
              <td>
                <div className="status-bar -color-below -with-right-text -to-right">
                  <div
                    className="status-bar_inner -to-right"
                    style={ { 'width': `${claim.negative_score * (-100)}%` } }
                  />
                  <div className="status-bar_text">
                    { n0(claim.negative_score * 100) }%
                  </div>
                </div>
              </td>
              <td>
                <div className="table-comparison_cell-value-move">
                  { n0(claim.net_score * 100) }%
                  {claim.significance && <SvgIcon name="arrow-top" />}
                </div>
              </td>
              <td>
                <div className="status-bar -color-above -with-left-text">
                  <div
                    className="status-bar_inner"
                    style={ { 'width': `${claim.positive_score * 100}%` } }
                  />
                  <div className="status-bar_text">
                    { n0(claim.positive_score * 100) }%
                  </div>
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default Index;
