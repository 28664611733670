import React from 'react';
import OnboardingBauApp from './bau/onboardingApp';
import ReboardingBauApp from './bau/reboardingApp';
import ReboardingSecondBauApp from './bau/reboardingSecondApp';

const PaypalEverywhereApp = ({ conceptName }) => {
  switch (conceptName) {
    case 'BAU-ONBOARDING':
      return (<OnboardingBauApp />);
    case 'BAU-REBOARDING':
      return (<ReboardingBauApp />);
    case 'BAU-REBOARDING-SECOND':
      return (<ReboardingSecondBauApp />);
    default:
      return (<>Incorrect Concept</>);
  }
};

export default PaypalEverywhereApp;
