import Http from "../../../common/http";

export const updateParams = (id, payload, goodCallback, badCallback) => {
  const url = `/express/api/concepts_database/${id}/update_filter`;

  sendPostRequest(url, payload, goodCallback, badCallback);
};

export const updateConcept = (id, payload, goodCallback, badCallback) => {
  const url = `/express/api/concepts_database/${id}/update_concept`;

  sendPostRequest(url, payload, goodCallback, badCallback);
};

export const updateImage = (id, payload, goodCallback, badCallback) => {
  const url = `/express/api/concepts_database/${id}/update_express_image`;

  sendPostRequest(url, payload, goodCallback, badCallback);
};

const sendPostRequest = (url, payload, goodCallback, badCallback) => {
  Http.post(url, { ...payload }).then(
    (response) => {
      if (response.response.ok) {
        goodCallback && goodCallback(response.data);
      } else {
        badCallback && badCallback(response);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback && badCallback(reject);
    }
  );
};

export default { updateParams, updateConcept, updateImage };
