import React, { useContext, useState } from 'react';
import { StateContext, LineDataContext } from '../common/contexts';
import {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  getSelectedPlans, getTotal,
  setPlan, sendData,
  getSelectedPlanIdByLine,
  updateLinePrices, removeHashLine
} from '../common/statistics';
import Page from '../common/page';
import Lines from '../common/lines';
import Overview from '../common/bau/overview';
import { priceForLine } from './data';

const states = [
  "Overview"
];
const initialState = states[0];

setLinesCount(1);

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = ({ data }) => {
  const [ , updatePath ] = useContext(StateContext);

  const [ updatedSelectedPlans, setUpdatedSelectedPlans ] = useState(getSelectedPlans());

  const handleChangeLineCount = (linesCount) => {
    setLinesCount(linesCount);
    updateLinePrices(priceForLine);
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  const handleRemoveLine = (lineNumber) => {
    removeHashLine(lineNumber);
    updateLinePrices(priceForLine);
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  const handlePlanSelect = (event, planObject) => {
    const { getPlanPriceByLine } = data;
    const line = getCurrentLine();
    setPlan(planObject, getCurrentLine(), getPlanPriceByLine(planObject, line));
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  const handleLineSelect = (clickedLine) => {
    setCurrentLine(clickedLine);
    setUpdatedSelectedPlans(getSelectedPlans());
  };

  return (
    <LineDataContext.Provider value={ data }>
      <Route path="Overview">
        <Page>
          <div className="layout">
            <div className="layout_sidebar">
              <Lines
                linesCount={ getLinesCount() }
                selectedPlans={ updatedSelectedPlans }
                total={ getTotal() }
                selectLine={ handleLineSelect }
                currentLine={ getCurrentLine() }
                changeLineCount={ handleChangeLineCount }
                removeLine={ handleRemoveLine }
                finishStep={ () => {
                  sendData();
                  updatePath("");
                } }
              />
            </div>
            <div className="layout_content" id="content">
              <Overview
                currentLine={ getCurrentLine() }
                selectedPlanIdByLine={ getSelectedPlanIdByLine(getCurrentLine()) }
                onPlanSelect={ (event, planObject) => {
                  handlePlanSelect(event, planObject);
                } }
              />
            </div>
          </div>
        </Page>
      </Route>
    </LineDataContext.Provider>
  );
};

export { Router, initialState };
