import React from 'react';
import Cell1 from './cell1';
import Cell2 from './cell2';
import Cell3 from './cell3';
import Cell4 from './cell4';
import Cell5 from './cell5';
import Cell6 from './cell6';
import Cell7 from './cell7';
import Cell8 from './cell8';
import Cell9 from './cell9';
import { SetContext } from './common/contexts';

// conceptName = 'Cell1' | 'Cell2' .. 'Cell9'
const App = ({ conceptName }) => {
  const contextValue = {
    delayAnimation: 500,
    maxLines: 5
  };
  const Concept = () => {
    switch (conceptName) {
      case 'CELL1': return <Cell1 />;
      case 'CELL2': return <Cell2 />;
      case 'CELL3': return <Cell3 />;
      case 'CELL4': return <Cell4 />;
      case 'CELL5': return <Cell5 />;
      case 'CELL6': return <Cell6 />;
      case 'CELL7': return <Cell7 />;
      case 'CELL8': return <Cell8 />;
      case 'CELL9': return <Cell9 />;
      default: return null;
    }
  };

  return (
    <SetContext.Provider value={ contextValue }>
      <Concept />
    </SetContext.Provider>
  );
};

export default App;
