import React from 'react';

const TextDescription = ({ text }) => <li className="plan-short-description-value">
  {text.split('\n').map((value, idx) => <p key={idx} dangerouslySetInnerHTML={{__html: value}}/>)}
</li>;

const ComplexDescription = ({ text, icon, iconPath, iconClassName }) => <>
  {
    (icon || iconPath) && <li className="plan-short-description-icon">
      {
        icon && <svg className={ ['icon', icon ? `-${icon}` : void 0, iconClassName].filter(Boolean).join(' ').trim() }>
          <use xlinkHref={ `#svg_${ icon }` }/>
        </svg>
      }
      {
        iconPath && <img className={`icon ${iconClassName}`} src={iconPath} />
      }
    </li>
  }
  <TextDescription text={text} />
</>;

const renderParagraph = (element, idx) =>
  <ComplexDescription
    key={idx}
    text={element.title}
    icon={element.icon}
    iconPath={element.iconPath}
    iconClassName={element.iconClassName} />;

const renderParagraphList = (paragraphs) =>
  <ul className="plan-short-description-list">
    {paragraphs.map(renderParagraph)}
  </ul>;

const DefaultArticleTitle = ({title}) =>
  <h6 className="plan-short-description-subtitle">{title}</h6>

const SelectableArticleTitle = ({title, value, selectedIdx, onChange}) =>
  <label>
    <input type="radio"
      className="plan-short-description-selecor"
      value={value}
      name="perk-list-selector"
      checked={value === selectedIdx}
      onChange={() => onChange(value)} />
    <DefaultArticleTitle title={title}/>
  </label>

const Article = ({ article, className, Title = () => <DefaultArticleTitle title={article.title}/>}) => {
  const { paragraphs } = article;
  const wrapperClassName = [
    'plan-short-description-article',
     className
  ].filter(Boolean).join(' ').trim();
  return <div className={wrapperClassName}>
    <Title />
    <ul className="plan-short-description-list">
      {paragraphs.map(renderParagraph)}
    </ul>
  </div>;
};

const renderArticle = (article, idx) =>
  <Article article={article} key={idx} />;

const renderSelectableArticle = (selectedIdx, onChange) => (article, idx) =>
  <Article
    className="-selectable"
    key={idx}
    article={article}
    Title={() => <SelectableArticleTitle title={article.title}
    value={idx}
    selectedIdx={selectedIdx}
    onChange={onChange}/>} />;

const renderArticleByIdx = (idx) => (articles) => {
  const article = articles[idx];
  return article ? <Article article={article} /> : null;
};

const renderArticleList = (articles) => articles.map(renderArticle)

const renderSelectableArticleList = (selectedIdx, onChange) => (articles) => {
  return articles.map(renderSelectableArticle(selectedIdx, onChange))
};

const renderDescription = (
  replaceDescriptionTitles = {},
  paragraphRender = renderParagraphList,
  articleRender = renderArticleList) => (info, idx) => {
  const { title, paragraphs = [], articles = [] } = info;
  return <div key={idx} className="choose-plan-card">
    <h6 className="plan-short-description-title">
      {replaceDescriptionTitles[title] || title}
    </h6>
    {paragraphs.length > 0 && paragraphRender(paragraphs)}
    {articles.length > 0 && articleRender(articles)}
  </div>
};

const PlanShortDescription = ({ description, replaceDescriptionTitles }) =>
  description.map(renderDescription(replaceDescriptionTitles));

export const SelectedPerksPlanDescription = ({ description, selectedIdx, replaceDescriptionTitles }) => {
  return description.map(renderDescription(replaceDescriptionTitles, void 0, renderArticleByIdx(selectedIdx)));
};

export const SelectablePerksPlanDescription = ({ description, selectedIdx, onSelectedChange, replaceDescriptionTitles }) =>
  description.map(renderDescription(replaceDescriptionTitles, void 0, renderSelectableArticleList(selectedIdx, onSelectedChange)))

export default PlanShortDescription;
