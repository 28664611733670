import React, { useState } from 'react';
import { filter, map } from "lodash";
import { initConditionList, filteredQuestionsForFields } from '../common/custom_questions/helper';
import ScreeningQuestion from '../common/custom_questions/components/ScreeningQuestion';
import { HighLevelContext } from '../common/custom_questions/contexts';
import ConceptsCountChanger from '../common/custom_questions/components/ConceptsCountChanger';
import QuestionsOrderModal from "../common/questions-order-modal";
import HiddenFields from './components/HiddenFields';

const App = ({ data, options, grid_data, images, metricsHasChanges, platform }) => {
  const initScreenerQuestions = () => (
    map(data.custom_metrics, (q) => initConditionList(q, grid_data[q.id], images[q.id]))
  );
  const [ metricQuestions, setMetricQuestions ] = useState(initScreenerQuestions);
  const [ initialJSON ] = useState(() => {
    return JSON.stringify(metricQuestions);
  });

  const [ conceptsCountValue, setConceptsCountValue ] = useState(() => (
    data.concepts_count_for_custom_metrics || options.custom_metrics_concepts_number_data.max
  ));
  const numberLimit = options.number_limit;
  const hasNumberLimit = !!numberLimit;
  const filteredList = filter(metricQuestions, (q) => !q._destroy);
  const emptyListQuestions = !filteredList.length;
  const addQuestionDisabled = (hasNumberLimit && filteredList.length >= numberLimit);
  const [ questionsOrderData, setQuestionsOrderData ] = useState(null);

  const conceptsCountData = {
    ...options.custom_metrics_concepts_number_data,
    ...{
      value: conceptsCountValue,
      setValue: (value) => {
        setConceptsCountValue(value);
      }
    }
  };
  conceptsCountData.display = !!filteredList.length && conceptsCountData.enabled;
  conceptsCountData.disableDedicatedPage = (
    parseInt(conceptsCountData.max, 10) !== parseInt(conceptsCountData.value, 10)
  );

  const updateList = (newValue) => {
    setMetricQuestions(newValue);
  };

  const addQuestion = (event) => {
    event.preventDefault();
    updateList(
      [
        ...metricQuestions,
        {
          text: "",
          answers: [ "" ],
          kind: "",
          condition: {},
          condition_list: [],
          answers_order: "specific",
          multi_condition: 1,
          quotas: {},
          manage_quota: false,
          scales: [ "" ],
          survey_location: "default",
          position: filteredList.length,
          tmpIndex: metricQuestions.length
        }
      ]
    );
  };

  const highLevelContextValue = { conceptsCountData };

  const [ initialValue ] = useState(() => {
    return highLevelContextValue.conceptsCountData.value;
  });

  const isUpdated = () => {
    const s1 = initialJSON;
    const s2 = JSON.stringify(metricQuestions);
    const n1 = parseInt(initialValue);
    const n2 = parseInt(highLevelContextValue.conceptsCountData.value);

    const regExp = /(\\n|\\r|\\t)/g;

    const result = s1.replace(regExp, '') !== s2.replace(regExp, '') || (n1 || 0) !== (n2 || 0);
    if (metricsHasChanges) {
      metricsHasChanges.current = result;
    }

    return result;
  };

  const onOpenQuestionsOrderModal = (event) => {
    event.preventDefault();
    setQuestionsOrderData(metricQuestions);
  };

  return (
    <HighLevelContext.Provider value={ highLevelContextValue }>
      <div className="custom-metric-questions -max-screener-and-custom-metrics-width">
        <HiddenFields
          questions={ filteredQuestionsForFields(metricQuestions) }
          options={ options }
          updated={ isUpdated() }
          conceptsCountEnabled={ conceptsCountData.enabled }
          conceptsCountValue={ conceptsCountValue }
        />
        <div className="form_section-title -grid-between">
          <div className="form_section-title-item -custom-metrics">
            <h3>Custom Questions</h3>
            <div className="form-question_type-text">
              You can create up to {numberLimit} custom metric questions for the survey.
              <ConceptsCountChanger />
            </div>
          </div>
          <div className="form_section-title-item">
            {
              <button className="button" onClick={ addQuestion } disabled={ addQuestionDisabled }>
                Add Custom Metric Question
              </button>
            }
          </div>
        </div>
        {
          filteredList.length > 1 &&
          <div className="form_section-title-item -order-button">
            {
              <button className="button -secondary -white-back -blue" onClick={ onOpenQuestionsOrderModal } >
                Change order
              </button>
            }
          </div>
        }
        {
          !emptyListQuestions &&
          <>
            <div className="form-items-list">
              {
                map(metricQuestions, (question, index) => (
                  <ScreeningQuestion
                    key={ `Question${index}-${question.name}-${question.tmpIndex}` }
                    options={ options }
                    question={ question }
                    screenerQuestions={ metricQuestions }
                    setScreenerQuestions={ updateList }
                    questionIndex={ index }
                    gridData={ grid_data }
                    images={ question.answer_images }
                    withImages
                    needOther
                    needNone
                    needFivePtScaleToggle
                    needDedicatedPage
                    needLocation
                    deleteButtonModifier={ platform === "verizon" ? '-va-delete' : '' }
                  />
                ))
              }
            </div>
          </>
        }
      </div>
      {
        !!questionsOrderData &&
        <QuestionsOrderModal
          questions={ questionsOrderData }
          setQuestionsOrderData={ setQuestionsOrderData }
          onSave={ setMetricQuestions }
        />
      }
    </HighLevelContext.Provider>
  );
};

export default App;
