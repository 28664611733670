import React from "react";
import Screening from '../../common/components/screening';
import MultiText from '../../common/components/multi_text';

const BrandScreening = ({ question, nextStep, result }) => {
  return (
    <>
      {
        question.is_multi_text ?
          <MultiText
            question={ question }
            nextStep={ nextStep }
            result={ result }
            resultField="unaided_brands"
          />
          :
          <Screening
            question={ question }
            nextStep={ nextStep }
            result={ result }
          />
      }
    </>
  );
};

export default BrandScreening;
