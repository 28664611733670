import React, { useContext } from "react";
import cn from "classnames";
import Navigation from "./Navigation";
import { AppContext } from "../app";
import IconButton from "./IconButton";
import { SKIP_BUTTON_KEY } from "../counter";
import Icon from "./Icon";
import { Link } from "react-router-dom";

const SkipButton = ({ ...props }) => (
  <IconButton {...props} className="workout-page_start-button -fullwidth mt-3">
    <Link to="/finish">
      Press here to take the survey
    </Link>
  </IconButton>
);

const Layout = ({
  children,
  showNavigation = true,
  showSkipButton = true,
  HeaderComponent = null,
  FloatButton = null,
  className,
}) => {
  const { canSkip } = useContext(AppContext);

  return (
    <div className={cn("layout", className)}>
      {HeaderComponent && (
        <div className="layout_header">{HeaderComponent}</div>
      )}
      <div
        className={cn("layout_content", canSkip && showSkipButton && "pb-5")}
      >
        {children}
      </div>
      <div className={ cn("layout_float-buttons", !showNavigation && '-no-navigation') } >
        {FloatButton}
        {canSkip && showSkipButton && (
          <SkipButton data-counter-key={SKIP_BUTTON_KEY} />
        )}
      </div>
      {showNavigation && (
        <div className="layout_navigation">
          <Navigation />
        </div>
      )}
    </div>
  );
};

export default Layout;
