import React from "react";
import Avatar from "./Avatar";

const UserInfo = ({ user }) => (
  <div className="user-info">
    <div className="user-info_avatar">
      <Avatar {...user} />
    </div>
    <div className="user-info_text">
      <h2>
        {user.firstName} - <br />
        You’re here. Let’s do this.
      </h2>
    </div>
  </div>
);

export default UserInfo;
