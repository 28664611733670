import React, { useContext, useEffect, useState } from 'react';
import {
  flatten, values, includes, findIndex, startsWith, map, find, cloneDeep, remove,
  compact, each, filter, sum
} from 'lodash';
import { GeneralContext } from '../contexts';
import { resultZipCodeProcessedKey } from './screening/zip';

const indexOfPrefix = (answers, prefix) => (
  findIndex(answers, (item) => {
    return startsWith(item, prefix);
  })
);

const findByName = (answers, name) => (
  find(
    answers,
    (item) => {
      if (typeof item !== 'string') {
        return null;
      }
      return item.match(new RegExp(`^${name}_`));
    }
  )
);

const includedAtLeastOne = (answers, items) => {
  let index = 0;
  let included = false;
  while (!included && index < items.length) {
    included = includes(answers, items[index]);
    index += 1;
  }
  return included;
};

const fiosReside = (result) => includes([ 1, 3 ], result?.zipCodeTagId);
const prepaid = (result) => includes(result.tags || [], 'prepaid');
const postpaid = (result) => includes(result.tags || [], 'postpaid');
const verizonCustomer = (result) => includes(result.tags || [], 'vzw_customer');

const WithTagsAndAnswersUpdating = ({ children }) => {
  const {
    demographics, filteredGroup, result, screenersTitleNameMatching, groupIndex, step,
    metricGroupStep, branded
  } = useContext(GeneralContext);
  const [ zipCodeTagsAndId, setZipCodeTagsAndId ] = useState(null);

  const getZipCodeTagsAndId = () => {
    if (zipCodeTagsAndId) {
      return zipCodeTagsAndId;
    }

    if (!result[resultZipCodeProcessedKey]) {
      return [ [], null ];
    }
    const { tag, tag_id, region } = result[resultZipCodeProcessedKey];
    const regionTag = region ? `region_${region}` : null;

    const foundResult = [ compact([ tag, regionTag ]), tag_id ];

    setZipCodeTagsAndId(foundResult);
    return foundResult;
  };

  const updatePrepaidTags = () => {
    const tags = [ 'prepaid' ];
    const prepaidConfirmationCode = screenersTitleNameMatching['Prepaid Confirmation'];
    const prepaidConfirmationDone = includes(result.answers, `${prepaidConfirmationCode}_1`);
    if (prepaidConfirmationDone) {
      return tags;
    }

    const wirelessServiceUseCode = screenersTitleNameMatching['Wireless Service Use'];
    const wirelessServiceUseNames = map(
      [ 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 19, 20, 21, 22 ],
      (num) => (`${wirelessServiceUseCode}_${num}`)
    );
    return includedAtLeastOne(result.answers, wirelessServiceUseNames) ? tags : [];
  };

  const updatePostpaidTags = () => {
    const tags = [ 'postpaid' ];
    const prepaidConfirmationCode = screenersTitleNameMatching['Prepaid Confirmation'];
    const prepaidConfirmationDone = includes(result.answers, `${prepaidConfirmationCode}_2`);
    if (prepaidConfirmationDone) {
      return tags;
    }

    const postpaidOrPrepaidServiceCode = screenersTitleNameMatching['Postpaid or Prepaid Service'];
    return includes(result.answers, `${postpaidOrPrepaidServiceCode}_1`) ? tags : [];
  };

  const findIfCustomer = () => {
    const wirelessServiceUseCode = screenersTitleNameMatching['Wireless Service Use'];

    const w1 = branded === 'branded' && includes(result.answers, `${wirelessServiceUseCode}_18`);

    const w2 = branded === 'branded_att' && includes(result.answers, `${wirelessServiceUseCode}_2`);

    const w3 = branded === 'branded_tmobile' && (
      includes(result.answers, `${wirelessServiceUseCode}_7`) ||
      includes(result.answers, `${wirelessServiceUseCode}_14`)
    );

    if (w1 || w2 || w3) {
      return true;
    }

    const homeInternetProviderCode = screenersTitleNameMatching['Home Internet Provider'];

    const h1 = branded === 'branded' && fiosReside(result) && includes(result.answers, `${homeInternetProviderCode}_21`);

    const h2 = branded === 'branded_att' && (
      includes(result.answers, `${homeInternetProviderCode}_3`) ||
      includes(result.answers, `${homeInternetProviderCode}_4`)
    );

    const h3 = branded === 'branded_tmobile' && includes(result.answers, `${homeInternetProviderCode}_26`);

    if (h1 || h2 || h3) {
      return true;
    }

    const homeInternetProviderNames = map([ 22, 23 ], (num) => (`${homeInternetProviderCode}_${num}`));

    return branded === 'branded' && includedAtLeastOne(result.answers, homeInternetProviderNames);
  };

  const updateCustomerTags = (tag) => {
    const tags = [ tag ];
    return findIfCustomer() ? tags : [];
  };

  const updateProspectTags = (tag, checkedBranded) => {
    const tags = [ tag ];
    return !findIfCustomer(checkedBranded) ? tags : [];
  };

  const whoOnPlanAnswersPresence = (answers) => {
    const whoOnPlanCode = screenersTitleNameMatching['WHO ON PLAN'];
    return !!find(
      result.answers,
      (el) => {
        if (typeof el === 'string') {
          return el.match(new RegExp(`^${whoOnPlanCode}_\\d+_[${answers}]`));
        }
        return false;
      }
    );
  };

  const updateParentsChildFrom5To17Tags = () => {
    const tags = [ 'parents_child_from5_to17' ];

    const childrenInHHCode = screenersTitleNameMatching['CHILDREN IN HH'];
    const foundItem = !!find(
      result.answers,
      (el) => {
        if (typeof el === 'string') {
          return el.match(new RegExp(`^${childrenInHHCode}_[23]`));
        }
        return false;
      }
    );

    return (foundItem ? tags : []);
  };

  const updateParentsChildFrom5To17OnPlanTags = () => {
    const tags = [ 'parents_child_from5_to17_on_plan' ];
    return (whoOnPlanAnswersPresence('45') ? tags : []);
  };

  const updateParentsChildLess18OnPlanTags = () => {
    const tags = [ 'parents_child_l18_on_plan' ];
    return (whoOnPlanAnswersPresence('345') ? tags : []);
  };

  const updateParentsChildGreaterEqual18OnPlanTags = () => {
    const tags = [ 'parents_child_ge18_on_plan' ];
    return (whoOnPlanAnswersPresence('6') ? tags : []);
  };

  const updateMultiLinesTags = () => {
    const tags = [ 'multi_lines_plan' ];
    const howManyLinesCode = screenersTitleNameMatching['HOW MANY LINES'];
    const howManyLinesDone = find(
      result.answers,
      (el) => {
        if (typeof el === 'string') {
          return (
            el.match(new RegExp(`^${howManyLinesCode}`)) &&
              !el.match(new RegExp(`^${howManyLinesCode}_1$`))
          );
        }
        return false;
      }
    );
    return (howManyLinesDone ? tags : []);
  };

  const updateSportFansTags = () => {
    const tags = [];
    const fandomCode = screenersTitleNameMatching['FANDOM'];
    const likes = '45';

    // Base Fandom tag
    const fandomAllDone = find(
      result.answers,
      (el) => {
        if (typeof el === 'string') {
          return (
            el.match(new RegExp(`^${fandomCode}_1_[${likes}]`)) ||
            el.match(new RegExp(`^${fandomCode}_2_[${likes}]`)) ||
            el.match(new RegExp(`^${fandomCode}_3_[${likes}]`)) ||
            el.match(new RegExp(`^${fandomCode}_4_[${likes}]`)) ||
            el.match(new RegExp(`^${fandomCode}_5_[${likes}]`))
          );
        }
        return false;
      }
    );
    if (fandomAllDone) {
      tags.push('sport_fans');
    }

    // SubTags
    each(
      { nfl: '1', nba: '2', nhl: '3', nascar: '4' },
      (answerKey, subName) => {
        const fandomNFLDone = find(
          result.answers,
          (el) => {
            if (typeof el === 'string') {
              return el.match(new RegExp(`^${fandomCode}_${answerKey}_[${likes}]`));
            }
            return false;
          }
        );
        if (fandomNFLDone) {
          tags.push(`sport_fans_${subName}`);
        }
      }
    );
    return tags;
  };

  const updateGamersTags = () => {
    const tags = [ 'gamers' ];
    const gamersCode = screenersTitleNameMatching['GAMING HOURS'];
    const gamersAnswers = filter(
      result.answers,
      (el) => {
        if (typeof el === 'string') {
          return (
            el.match(new RegExp(`^${gamersCode}_\\d+answer_`))
          );
        }
        return false;
      }
    );
    const hoursSum = sum(
      map(
        gamersAnswers,
        (el) => {
          const matches = el.match(new RegExp(`^${gamersCode}_\\d+answer_(\\d+)`));
          return parseInt(matches[1]);
        }
      )
    );

    return (hoursSum >= 10 ? tags : []);
  };

  const findEthnicityTags = () => {
    const questionHispanic = find(demographics, (item) => (item.title === 'Hispanic'));
    const questionHispanicName = questionHispanic.name;
    const questionEthnicity = find(demographics, (item) => (item.title === "Ethnicity"));
    const questionEthnicityName = questionEthnicity.name;

    const nonHispanicBlack = (
      !!find(
        result.answers,
        (item) => (typeof item === 'string' && item === `${questionEthnicityName}_2`)
      ) && !find(
        result.answers,
        (item) => (typeof item === 'string' && item === `${questionHispanicName}_1`)
      )
    );

    const hispanicLatinx = !!find(
      result.answers,
      (item) => (typeof item === 'string' && item === `${questionHispanicName}_1`)
    );

    const nonHispanicWhite = (
      filter(
        result.answers,
        (item) => (
          typeof item === 'string' &&
          item.match(new RegExp(`${questionEthnicityName}_`))
        )
      ).length === 1 && !!find(
        result.answers,
        (item) => (typeof item === 'string' && item === `${questionEthnicityName}_1`)
      ) && !find(
        result.answers,
        (item) => (typeof item === 'string' && item === `${questionHispanicName}_1`)
      )
    );

    const other = (
      !nonHispanicBlack && !hispanicLatinx && !nonHispanicWhite && !find(
        result.answers,
        (item) => (typeof item === 'string' && item === `${questionEthnicityName}_-1`)
      )
    );

    return compact(
      [
        (nonHispanicBlack ? 'ethnicityNonHispanicBlack' : null),
        (hispanicLatinx ? 'ethnicityHispanicLatinx' : null),
        (nonHispanicWhite ? 'ethnicityNonHispanicWhite' : null),
        (other ? 'ethnicityOther' : null)
      ]
    );
  };

  const updateTags = () => {
    const [ zipCodeTags, zipCodeTagId ] = getZipCodeTagsAndId();
    const tagsHash = {
      zip: zipCodeTags,
      prepaid: updatePrepaidTags(),
      postpaid: updatePostpaidTags(),
      parents_child_from5_to17: updateParentsChildFrom5To17Tags(),
      parents_child_from5_to17_on_plan: updateParentsChildFrom5To17OnPlanTags(),
      parents_child_l18_on_plan: updateParentsChildLess18OnPlanTags(),
      parents_child_ge18_on_plan: updateParentsChildGreaterEqual18OnPlanTags(),
      multi_lines_plan: updateMultiLinesTags(),
      sport_fans: updateSportFansTags(),
      gamers: updateGamersTags(),
      ethnicity: findEthnicityTags()
    };

    if (branded === 'branded') {
      tagsHash['vzw_customer'] = updateCustomerTags('vzw_customer');
      tagsHash['vzw_prospect'] = updateProspectTags('vzw_prospect');
    }

    if (branded === 'branded_att') {
      tagsHash['att_customer'] = updateCustomerTags('att_customer');
      tagsHash['att_prospect'] = updateProspectTags('att_prospect');
    }

    if (branded === 'branded_tmobile') {
      tagsHash['tmobile_customer'] = updateCustomerTags('tmobile_customer');
      tagsHash['tmobile_prospect'] = updateProspectTags('tmobile_prospect');
    }

    result.tags = flatten(values(tagsHash));
    result.zipCodeTagId = zipCodeTagId;
  };

  const updateEthnicityAnswer = () => {
    const ethnicityTitle = "Ethnicity";
    const previousQuestion = groupIndex > 0 ? (filteredGroup(groupIndex - 1) || [])[0] : null;
    if (previousQuestion?.title === ethnicityTitle) {
      const questionEthnicity = find(demographics, (item) => (item.title === ethnicityTitle));
      const questionEthnicityName = questionEthnicity.name;
      each(findEthnicityTags(), (tagName) => {
        result.answers.push(`${questionEthnicityName}s_${tagName}`);
      });
    }
  };

  const updateScreenerAnswers = () => {
    const question = find(demographics, (item) => (item.title === 'Home Internet Provider'));
    question.detailed_answers = cloneDeep(question.answers);
    if (!fiosReside(result)) {
      const answer = find(
        question.detailed_answers,
        (item) => (item.id === `${question.name}_21`)
      );
      remove(question.detailed_answers, (item) => item === answer);
    }
  };

  useEffect(() => {
    updateScreenerAnswers();
  }, [ zipCodeTagsAndId ]);

  useEffect(() => {
    updateEthnicityAnswer();
  }, [ groupIndex ]);

  useEffect(() => {
    updateTags();
  }, [ step, groupIndex, metricGroupStep ]);

  return (children);
};

export {
  WithTagsAndAnswersUpdating,
  indexOfPrefix, findByName,
  fiosReside, prepaid, postpaid, verizonCustomer
};
