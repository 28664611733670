import React, { useContext } from 'react';
import { LaunchContext } from '../../contexts';
import EditPanelServiceCommon
  from '../../../edit_survey_flow/components/panelService/common/editPanelServiceCommon';
import { AppContext } from '../../../edit_survey_flow/contexts';
import NSizeSelector from '../nSizeSelector';
import SurveyCompleteTarget from '../../../edit_survey_flow/components/surveyCompleteTarget';
import TestCard from './testCard';
import RealPaymentForm from './realPaymentForm';

const PayForm = () => {
  const {
    loading, needToPay, panelServiceDataInit, setPanelServiceData, errors,
    setMessageObject, completeTarget, setCompleteTarget, completeTargetData,
    sizeData, textItemName, textLaunchAlmost
  } = useContext(LaunchContext);
  const handleGetUpdates = (panelServiceHash) => {
    setPanelServiceData(panelServiceHash);
  };
  const totalN = (sizeData.total || parseInt(sizeData.text));
  return (
    <div className="content_body launch-content">
      { loading && <div className="page-loader -inner-loader" /> }
      <div className="form form-pay">
        <div className="form_section">
          <div className="form_section-text">{ textLaunchAlmost }</div>
        </div>
        <NSizeSelector />
        <SurveyCompleteTarget
          titleClassName="input-item-note-payment"
          completeTargetData={ completeTargetData }
          completeTarget={ completeTarget }
          setCompleteTarget={ setCompleteTarget }
          totalN={ totalN }
          conceptN={ sizeData.nsize }
          textItemName={ textItemName }
        />
        {
          needToPay ? (
            <RealPaymentForm />
          ) : (
            <>
              <div className="form_section" id="panel-service-change-app">
                <AppContext.Provider value={ { setNotificationObject: setMessageObject } }>
                  <EditPanelServiceCommon
                    panelServiceData={ panelServiceDataInit }
                    inputErrors={ errors }
                    getUpdates
                    onGetUpdates={ handleGetUpdates }
                  />
                </AppContext.Provider>
              </div>
              <TestCard />
            </>
          )
        }
      </div>
    </div>
  );
};

export default PayForm;
