import React, { useContext } from "react";
import { map, min, each } from "lodash";
import classnames from 'classnames';
import renderRawHtml from "../common/render_raw_html";
import PlanPerk from "../common/planPerk";
import { openCoreModal, openTermsModal, fixedView, getDefaultVariant } from "../common/helper";
import { ModalContext } from '../common/contexts';
import Slider from '../common/common_slider';
import { increaseVariableForCurrentLine } from '../common/statistics';
import { PlansUnder } from "./plans_under";
import { PromoLabel } from "./promo_label";

const BestPlanRender = ({ plan, onSelect, onCustomizeCurrentPlan }) => {
  const { openModal } = useContext(ModalContext);
  const handleOpenTermsConditions = () => {
    increaseVariableForCurrentLine(`popular${plan.statisticsName}TermsClick`);
    openTermsModal({ perks: plan.perks, openModal });
  };
  const findVariantsHash = () => {
    const variantsHash = {};
    each(plan.perks || [], (perk) => {
      const defaultVariant = getDefaultVariant(perk);
      if (defaultVariant) {
        variantsHash[perk.id] = defaultVariant.id;
      }
    });
    return variantsHash;
  };

  const handleSelectPlan = () => {
    increaseVariableForCurrentLine(`popular${plan.statisticsName}SelectClick`);
    onSelect(plan, findVariantsHash());
  };
  const handleCustomizeCurrentPlan = () => {
    increaseVariableForCurrentLine(`popular${plan.statisticsName}CustomizeClick`);
    onCustomizeCurrentPlan(plan, findVariantsHash());
  };
  const handleReviewDetailsClick = () => {
    increaseVariableForCurrentLine(`popular${plan.statisticsName}CoreDetailsClick`);
    openCoreModal({ plan: plan.core, openModal });
  };

  return (
    <div className={ classnames('plate -black -md', { '-with-label': plan.promo }) }>
      { plan.promo && <PromoLabel promo={ plan.promo } /> }
      <div className="best-plan">
        <div className="best-plan_grid">
          <div className="best-plan_top">
            <div className="best-plan_top-grid">
              <div className="best-plan_top-grid-item">
                <h4
                  className={ classnames("best-plan_title", plan.specialKlassTitle) }
                  { ...renderRawHtml(plan.title) }
                />
              </div>
              <div className="best-plan_top-grid-item -price">
                <div className="best-plan_price"><strong>${ plan.priceTitle() }</strong>/mo*</div>
                <div className="best-plan_price-save">Save ${fixedView(plan.saveTitle())}/mo</div>
              </div>
            </div>
          </div>
          <div className="best-plan_bottom">
            <div className="best-plan_terms">
              By continuing, you agree to selected perk
              {' '}
              <button
                className="button-link -inherit"
                onClick={ handleOpenTermsConditions }
              >
                Terms & Conditions
              </button>.
            </div>
            <div className="best-plan_buttons">
              <div className="best-plan_button">
                <button
                  className="button -invert -full-width -sm-mob"
                  onClick={ handleSelectPlan }
                >
                  Select
                </button>
              </div>
              <div className="best-plan_button">
                <button
                  className="button -bordered -invert -full-width -sm-mob"
                  onClick={ handleCustomizeCurrentPlan }
                >
                  Customize
                </button>
              </div>
            </div>
          </div>

          <div className="best-plan_content">
            <div className="best-plan_core">
              <div className="plate -gray-dark -sm-mob">
                <h4 className="best-plan_core-title">
                  { plan.core.title }
                </h4>
                <ul className="best-plan_core-features">
                  {
                    map(plan.core.perksForPrebuild, (perk, key) => (
                      <li className="best-plan_core-feature-item" key={ `best-plan_core-feature-item-${key}` }>
                        { perk.titleForPrebuild || perk.title }
                      </li>
                    ))
                  }
                </ul>
                <div className="best-plan_core-more">
                  <button
                    className="button-link -sm"
                    onClick={ handleReviewDetailsClick }
                  >
                    Review details
                  </button>
                </div>
              </div>
            </div>
            <div className="best-plan_perks">
              <div className="best-plan_perks-grid">
                {
                  map(plan.perks, (perk, key) => (
                    <div className="best-plan_perks-item" key={ `best-plans-perks-${key}` }>
                      <PlanPerk
                        perk={ perk }
                        showDetailsModal
                        showNote
                        hideTotalInDetails
                        popularPlan={ plan }
                        className={
                          classnames('-in-prebuild', { '-single-in-list': plan.perks.length === 1 })
                        }
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StartOwnRender = ({ minimumPrice, onBuildYourOwn }) => {
  const handleOnStartBuilding = () => {
    increaseVariableForCurrentLine('popularSelectBuildOwn');
    onBuildYourOwn();
  };
  return (
    <div className="plate -black -md">
      <div className="best-plan -start-own">
        <div className="best-plan_grid">

          <div className="best-plan_top">
            <div className="best-plan_top-grid">
              <div className="best-plan_top-grid-item">
                <h4 className="best-plan_title">
                  Start your own plan
                </h4>
                <div className="best-plan_descr">
                  <p>
                    Starting at ${minimumPrice}/mo.
                  </p>
                  <p>
                    Pick what you want in two simple steps
                  </p>
                </div>
              </div>

            </div>
          </div>
          <div className="best-plan_bottom">
            <div className="best-plan_buttons">
              <div className="best-plan_button -half">
                <button className="button -invert -full-width" onClick={ handleOnStartBuilding }>
                  Start building
                </button>
              </div>
            </div>
          </div>

          <div className="best-plan_content">
            <div className="best-plan_placeholder -top">
              <div className="plate -gray-dark">
                <div className="best-plan_placeholder-num">1</div>
                <h4 className="best-plan_placeholder-title">
                  Choose your Unlimited
                </h4>
              </div>
            </div>
            <div className="best-plan_placeholder">
              <div className="plate -gray-dark">
                <div className="best-plan_placeholder-num">2</div>
                <h4 className="best-plan_placeholder-title">
                  Select your Perks
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OurBestPlans = ({
  plans, onSelect, onCustomize, onNavigate, allPlansInclude, corePlans,
  onBuild
}) => {
  const handleBuildYourOwn = () => {
    if (onNavigate) {
      onNavigate();
    }
    onBuild();
  };

  const minimumPrice = min(map(corePlans, (el) => el.price));

  const handleCustomizeCurrentPlan = (plan) => {
    onCustomize(plan);
  };

  return (
    <div className="best-plans">
      <h2 className="content_title best-plans_title">Explore popular plans.</h2>
      <div className="best-plans_wrapper">

        <Slider className="best-plans_grid">
          {
            map(plans, (plan, idx) => (
              <div className="best-plans_grid-item" key={ `best-plans-${idx}` } >
                <BestPlanRender
                  plan={ plan }
                  onSelect={ onSelect }
                  onCustomizeCurrentPlan={ handleCustomizeCurrentPlan }
                />
              </div>
            ))
          }
          <div className="best-plans_grid-item best-plans_subtitle-wrapper">
            <h3 className="content_subtitle best-plans_subtitle -inside-item">Not seeing what you like?</h3>
            <StartOwnRender minimumPrice={ minimumPrice } onBuildYourOwn={ handleBuildYourOwn } />
          </div>
        </Slider>
      </div>
      <PlansUnder allPlansInclude={ allPlansInclude } />
    </div>
  );
};
