import React from "react";
import cn from "classnames";
import Icon from "./Icon";
import { Link } from "react-router-dom";
import { BRAND_COUNTER_KEY } from "../counter";

const BrandCarousel = ({ brands, className }) => {
  return (
    <div className="brand-carousel">
      <div className={cn("brand-carousel_list", className)}>
        {brands.map((brand, i) => (
          <Link
            className="brand-carousel_item"
            key={i}
            to={`/explore/brand/${brand.id}`}
            data-counter-key={brand.id === "equinox" ? BRAND_COUNTER_KEY : null}
          >
            <div className="brand-carousel_item-wrapper">
              <img src={brand.imgUrl} className="brand-carousel_item-image" />
            </div>
            <Icon
              name={brand.icon}
              className="-medium brand-carousel_item-logo"
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BrandCarousel;
