import React, {} from 'react';
import {getMetricKind, getMetricTitle} from "../../../common/index-context";
import {map} from "lodash";
import StatusBar from "../../../common/components/status-bar";
import StatusCircle from "../../../common/components/status-circle";

const TableFivePointsScales = ({data, current, fivePointScales}) => {
  return (
    <table className="table table-comparison">
      <thead>
        <tr>
          <th className="table-comparison_name-cell" colSpan="3">
            <div className="table-comparison_title">
              { getMetricKind(data, 'five_point_scale') } (Top 2 Box), N={ current.five_pt_scale_count }
            </div>
          </th>
          <th className="table-comparison_average-cell">Avg</th>
        </tr>
      </thead>
      <tbody>
        {map(fivePointScales, (values, title)=>{
          return (
            <tr key={title}>
              <td className="table-comparison_name-cell">
                { getMetricTitle(data, title) }
              </td>
              <td className="-max-width">
                <StatusBar values={values[current.id]} box="top2" statTesting={data.stat_testing}/>
              </td>
              <td>
                <StatusCircle values={values[current.id]} box="top2" noLetters={true}/>
              </td>
              <td className="table-comparison_average-cell">
                { values.avg.top2 }
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
};

export default TableFivePointsScales;
