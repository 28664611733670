import React from "react";
import cn from "classnames";
import { n0 } from "../../../common/helpers";

const RankingPerformers = ({ data, current }) => {
  const info = data.ranking[current.id];
  const products = info.products;

  return (
    <table className="table table-comparison -margins">
      <thead>
        <tr>
          <th className="table-comparison_cell-header">
            { data.titles.rank_performers }
          </th>
          <th className="table-comparison_bold-text">
            { data.titles.rank1 }
          </th>
          <th>
            { data.titles.rank2 }
          </th>
          <th>
            { data.titles.rank3 }
          </th>
        </tr>
      </thead>
      <tbody>
        {info.sorted_ids.map((id) => (
          <tr key={ id }>
            <td className={ cn({ "table-comparison_name-cell": !products[id].target, "table-comparison_bold-text": products[id].target }) }>
              {products[id].name} {products[id].target && <span>({data.titles.main})</span>}
            </td>
            <td><b>{ n0(products[id].p1) }%</b></td>
            <td>{ n0(products[id].p2) }%</td>
            <td>{ n0(products[id].p3) }%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RankingPerformers;
