import { includes, map, find } from 'lodash';
import {
  setCurrentLine, setHashData, getHashData,
  setLinesCount, setPlan
} from '../common/statistics';

const checkIfCanStart = (taskId) => (
  includes([ 1, 2, 3 ], parseInt(taskId))
);

const defineStartPage = ({ taskId, predefinedPlans, perkPrice, initialState }) => {
  if (includes([ 1, 3 ], parseInt(taskId))) {
    return () => (initialState);
  }
  return () => {
    setLinesCount(1);
    setCurrentLine(1);
    const planObject = predefinedPlans[0];
    const price = planObject.price + (planObject.perks.length * perkPrice);
    setPlan({ ...planObject, price }, 1);
    setHashData('lines.1.planPerkIds', map(planObject.perks, ({ id }) => id));
    return "Order";
  };
};

const validTask1 = (predefinedPlans, constructPlans) => {
  const lineData = getHashData('lines.1');
  return !!find(predefinedPlans, ({ id }) => id === lineData.planId);
};

const validTask2 = (predefinedPlans, constructPlans) => {
  const lineData = getHashData('lines.1');
  if (!lineData.planChangeClickedCount) {
    return false;
  }
  if (!lineData.planBuildYourOwnTemplatePlanId) {
    return false;
  }
  return true;
};

const validTask3 = (predefinedPlans, constructPlans) => {
  const lineData = getHashData('lines.1');
  return !!find(constructPlans, ({ id }) => id === lineData.planId);
};

const validTask = (taskId, predefinedPlans, constructPlans) => {
  switch (taskId) {
    case 1:
      return validTask1(predefinedPlans, constructPlans);
    case 2:
      return validTask2(predefinedPlans, constructPlans);
    default:
      return validTask3(predefinedPlans, constructPlans);
  }
};

const getRedirectUrl = ({
  successUrl, failUrl, taskId,
  predefinedPlans, constructPlans
}) => (
  validTask(parseInt(taskId), predefinedPlans, constructPlans) ?
    successUrl :
    failUrl
);

export {
  checkIfCanStart, defineStartPage, getRedirectUrl
};
