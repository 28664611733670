import React, { Fragment } from 'react';
import CharacteristicList from '../../common/characteristicList';
import {
  getHashData,
  setHashData,
  getCurrentLine
} from '../../common/statistics';

export const ChoosePerkPack = ({ perkPacks, selected, onSelectPack }) => {
  const selectPack = (id) => {
    onSelectPack(id);
    const currentLine = getCurrentLine();
    const counter = getHashData(`list.${currentLine}.perkChanged`);
    setHashData(`lines.${currentLine}.perkChanged`, counter ? counter + 1 : 1);
  };

  let title = '';
  const pack = perkPacks.find(({ id }) => id === selected);
  if (pack && !title) {
    title = `'${pack.name}' selected`;
  }
  return (<div className="plan-features_group">
    <div className="plan-features_header">Choose your perks</div>
    <div className="plan-features_selected">{title}</div>
    {
      perkPacks.map((pack, idx) => {
        return (
          <Fragment key={ `${selected}-${idx}` }>
            {idx > 0 && <div className="plan-features_perks-pack_separator">or</div>}
            <div className={ `plan-features_perks-pack ${pack.id === selected ? '-selected' : ''}`.trim() }>
              <label>
                <input
                  className="plan-features_perks-pack_input"
                  checked={ pack.id === selected ? 'checked' : false  }
                  type="radio"
                  name="perks-pack"
                  onChange={ () => selectPack(pack.id) }
                />
                <div className={ `plan-features_perks-pack_title ${pack.id === selected ? '-checked' : ''}`.trim() }>
                  {pack.name}
                </div>
              </label>
              <CharacteristicList
                title={ pack.perksTitle }
                list={ pack.perks }
                note={ pack.perksNote }
                specialClassList={ pack.id === 1 ? '-first-col-2' : null }
                highlighted={ pack.id !== 1 }
              />
            </div>
          </Fragment>
        );
      })
    }
  </div>);
};
