
import React, { useContext, useState } from 'react';
import Header from '../flex-common/header';
import Lines from '../lines.js';
import ChooseLinePlan from './chooseLinePlan';
import Checkout from './checkout';
import { RouteContext, LinesDataContext } from './contexts';
import Statistic from '../../statistic';

const Route = ({ path, isDefault, children }) => {
  const { path: currentPath } = useContext(RouteContext);
  if (isDefault && !currentPath) return children;
  return path === currentPath ? children : null;
};

const scrollTop = () => {
  document.querySelector('html').scrollTop = 0;
};

const Routes = ({ onLinesCountChange, onDone }) => {
  const [linesData, setLinesData] = useState([]);
  const [filledLines, setFilledLines] = useState(0);
  const { setPath } = useContext(RouteContext);

  return <LinesDataContext.Provider value={{ linesData, filledLines }}>
    <Route path="/" isDefault>
      <Lines
        customHeader={ <Header/> }
        onContinue={(linesCount) => {
          onLinesCountChange(linesCount);
          setPath('/choose-line-plan');
          setLinesData(Array(linesCount).fill(null));
          Statistic.updateValue('lines.0.timeSpentStart', new Date());
          scrollTop();
        }}
      />
    </Route>
    <Route path="/choose-line-plan">
      <ChooseLinePlan
        onContinue={(planData) => {
          const data = [...linesData];
          data[filledLines] = planData;
          setLinesData(data);
          if (filledLines < linesData.length) setFilledLines(filledLines + 1);
          setPath('/checkout');
          scrollTop();
        }} />
    </Route>
    <Route path="/checkout">
      <Checkout
        selectedPlans={linesData}
        onContinue={() => {
          Statistic.updateValue(`lines.${filledLines - 1}.timeSpentEnd`, new Date());
          if (filledLines < linesData.length) {
            setPath('/choose-line-plan');
            Statistic.updateValue(`lines.${filledLines}.timeSpentStart`, new Date());
            scrollTop();
          }
          else {
            const count = linesData.reduce((acc, { price }) => acc + price, 0)
            Statistic.updateValue('total', count)
            onDone();
          }
        }}
        onBack={() => {
          const data = [...linesData];
          data[filledLines - 1] = null;
          setLinesData(data);
          setFilledLines(filledLines - 1);
          setPath('/choose-line-plan');
          scrollTop();
        }} />
    </Route>
  </LinesDataContext.Provider>;
}

export default Routes;