import React, { useState, useEffect, useContext } from 'react';
import { assign } from 'lodash';
import { baseContext } from "../contexts";
import { loadUsers } from './api';
import EditUser from "./editUser";
import NewUser from "./newUser";

const AdminUsers = () => {
  const { getCurrentUser, updateCurrentUser, verizonType } = useContext(baseContext);
  const [ modalIsOpen, setModalOpen ] = useState(false);
  const [ userToEdit, setCurrentUser ] = useState(null);
  const [ users, setUsers ] = useState([]);

  const getUsersList = () => {
    loadUsers((data) => {
      setUsers(data);
    });
  };
  useEffect(() => {
    getUsersList();
  }, []);

  const openModal = (event, user) => {
    if (event.target.href) {
      return;
    }
    setCurrentUser(user);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const saveModal = (newUser) => {
    // to render only once
    setTimeout(() => {
      closeModal();
      getUsersList();
      if (newUser.id === getCurrentUser().id) {
        updateCurrentUser(assign({}, getCurrentUser(), newUser));
      }
    }, 100);
  };
  const deleteModal = () => {
    closeModal();
    getUsersList();
  };

  return (
    <>
      {
        userToEdit && modalIsOpen &&
        <EditUser user={ userToEdit } onClose={ closeModal } onSave={ saveModal } onDelete={ deleteModal } />
      }
      {
        !userToEdit && modalIsOpen &&
        <NewUser onClose={ closeModal } onSave={ saveModal } />
      }
      <div className="pulse section">
        <div className="section_top -grid">
          <div className="section_top-item">
            <h4 className="section_title">Users</h4>
          </div>
          <div className="section_top-item">
            <button className="button -sm -upper" onClick={ (event) => { openModal(event, null); } }>New User</button>
          </div>
        </div>
        <div className="section_body">
          <table className="table -striped">
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Role</th>
                <th>Access</th>
                { verizonType === 'verizon5g' && <th>Recollective URL</th>}
              </tr>
            </thead>
            <tbody>
              {
                users.map((user) => (
                  <tr
                    className="table_tr-clickable -pointer"
                    key={ `User${user.id}` }
                    onClick={ (event) => { openModal(event, user); } }
                  >
                    <td>{ user.email }</td>
                    <td>{ user.first_name } { user.last_name }</td>
                    <td>{ user.role_humanized }</td>
                    <td>{ user.disabled ? 'Suspended' : 'Live' }</td>
                    <td>
                      {
                        verizonType === 'verizon5g' && !!user.recollective_link &&
                          <a
                            href={ user.recollective_link }
                            className="table_control-over-line"
                            target="_blank"
                          >
                            { user.recollective_link }
                          </a>
                      }
                      { verizonType === 'verizon5g' && !user.recollective_link && "-" }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
