import React, { useContext, useState } from 'react';
import { find, keys } from "lodash";
import { baseContext } from "../context";
import { updateParams } from "../requests";
import SvgIcon from "../../../../common/components/svg-icon";
import SelectDropdown from "./select_dropdown";
import PropsToggle from "./props_toggle";
import ConceptsFilters from "./concepts-filters/index";

const Settings = () => {
  const {
    consts, data, setData, setError, paramsId,
    globalLoading, brandsCount,
    productCategories, countries,
    selectedConcepts, conceptGroup, conceptView, box,
    conceptsCount, projectsCount, sinceYear,
    setGlobalLoading, currentCountry, currentProjectType,
    currentProductCategory, setDataFilterIsOpen,
    itemName, itemsName
  } = useContext(baseContext);

  const settingsDynamicItemName = conceptsCount === 1 ? itemName : itemsName;

  const projectTypes = consts.project_types;

  const selectedCountry = find(countries, (item) => item.label === currentCountry);
  const selectedProjectType = find(projectTypes, (item) => item.value === currentProjectType);
  const selectedProductCategory = find(productCategories, (item) => item.label === currentProductCategory);

  const conceptGroups = { ...consts.concept_groups };
  conceptGroups.for_approval = `${itemsName} for Approval`;

  const boxOptions = {
    top2: "TOP 2 BOX",
    top: "TOP BOX"
  };

  const defaultSettings = {
    priced: '',
    client_vs_internal: '',
    stimuli_type: '',
    product_type: '',
    target: '',
    incidence_ranges: '',
    express_category_tags: '',
    express_brand_tags: '',
    express_custom_tags: '',
    years: '',
    concept_name: '',
    notes: ''
  };

  const baseFilters = { ...defaultSettings, ...{ selected_concepts: [] } };

  const onFilterUpdate = (params) => {
    setGlobalLoading(true);
    updateParams(paramsId, params, setData, setError);
  };

  const onTopFilterUpdate = (params) => {
    onFilterUpdate({
      ...params,
      ...baseFilters,
      ...{ page: 1, data_filters: JSON.stringify({ demographics: [] }) }
    });
  };

  const onCountrySelect = (option) => {
    const payload = {};
    payload.country = option.label;
    payload.concept_group = 'included';
    setFilterIsOpen(false);
    setDataFilterIsOpen(false);
    onTopFilterUpdate(payload);
  };

  const onProjectTypeSelect = (option) => {
    const payload = {};
    payload.project_type = option.value;
    payload.concept_group = 'included';
    setFilterIsOpen(false);
    setDataFilterIsOpen(false);
    onTopFilterUpdate(payload);
  };

  const onProductCategorySelect = (option) => {
    const payload = {};
    payload.product_category = option.label;
    payload.concept_group = 'included';
    setFilterIsOpen(false);
    setDataFilterIsOpen(false);
    onTopFilterUpdate(payload);
  };

  const onConceptGroupChange = (attrName, value) => {
    const keyValue = find(keys(conceptGroups), (key) => conceptGroups[key] === value);
    const payload = {};
    payload.concept_group = keyValue;
    onFilterUpdate(payload);
  };

  const onBoxChange = (attrName, value) => {
    const keyValue = find(keys(boxOptions), (key) => boxOptions[key] === value);
    const payload = {};
    payload.box = keyValue;
    onFilterUpdate(payload);
  };

  const conceptFilterHasValues = () => {
    return !!data.concept_filters.priced ||
      data.concept_filters.client_vs_internal !== '' ||
      !!data.concept_filters.stimuli_type ||
      !!data.concept_filters.product_type ||
      !!data.concept_filters.target ||
      data.concept_filters.incidence_ranges.length > 0 ||
      data.concept_filters.express_category_tags.length > 0 ||
      data.concept_filters.express_brand_tags.length > 0 ||
      data.concept_filters.express_custom_tags.length > 0 ||
      data.concept_filters.years.length > 0 ||
      !!data.concept_filters.concept_name ||
      !!data.concept_filters.notes;
  };

  const [ filterIsOpen, setFilterIsOpen ] = useState(conceptFilterHasValues);

  const onFilterClose = () => {
    onFilterUpdate(defaultSettings);
    setFilterIsOpen(false);
  };

  const onFilterClick = () => {
    if (!conceptFilterHasValues() && filterIsOpen) {
      setFilterIsOpen(false);
    } else {
      setFilterIsOpen(true);
    }
  };

  const canUseFilters = !!selectedCountry && !!selectedProjectType && !!selectedProductCategory;

  const showBoxOptions = conceptGroup === 'included' || conceptGroup === 'custom' || conceptView === 'data';

  return (
    <div className="concepts-database_plate">
      {
        globalLoading && <div className="page-loader -inner-loader" />
      }
      <div className="concepts-database_plate-body">
        <div className="concepts-database_select_container">
          <div className="concepts-database_select_element">
            <SelectDropdown
              placeholder="Select Country"
              options={ countries }
              selectedOpts={ selectedCountry }
              tagType="express_category"
              onSelectOption={ onCountrySelect }
            />
          </div>
          <div className="concepts-database_select_element">
            <SelectDropdown
              placeholder="Select Project Type"
              options={ projectTypes }
              selectedOpts={ selectedProjectType }
              tagType="express_category"
              onSelectOption={ onProjectTypeSelect }
            />
          </div>
          <div className="concepts-database_select_element">
            <SelectDropdown
              placeholder="Select Product Category"
              options={ productCategories }
              selectedOpts={ selectedProductCategory }
              tagType="express_category"
              onSelectOption={ onProductCategorySelect }
            />
          </div>
          <div className="concepts-database_btn_element">
            <button
              className="concepts-database_filter_button"
              onClick={ onFilterClick }
              disabled={ !selectedProductCategory }
            >
              <SvgIcon className="icon-link_icon" name="i-sliders" />
              <span className="icon-link_text">Filters</span>
            </button>
          </div>
        </div>
        {filterIsOpen && canUseFilters && <ConceptsFilters onClose={ onFilterClose } />}
        {
          (currentCountry && currentProjectType && currentProductCategory) &&
          <>
            <div className="concepts-database_info_block">
              { conceptsCount }
              {' '}
              {settingsDynamicItemName}
              {' '}
              included – across
              {' '}
              {projectsCount}
              {' '}
              Projects,
              {' '}
              {brandsCount}
              {' '}
              Brands
              {' '}
              { sinceYear ? ` – since ${sinceYear}` : "" }
            </div>
            <div className="concepts-database_toggle_container">
              <div className="concepts-database_four_options_toggle">
                <PropsToggle
                  attrName="conceptsGroup"
                  prefix="conceptsGroup"
                  options={ conceptGroups }
                  labelClass="-width-1-4"
                  currentValue={ conceptGroups[conceptGroup] }
                  onChange={ onConceptGroupChange }
                  withCounter
                  count={ selectedConcepts.length }
                />
              </div>
              <div className="concepts-database_top2box_toggle">
                {showBoxOptions &&
                  <PropsToggle
                    attrName="calc"
                    prefix="calc"
                    options={ boxOptions }
                    labelClass="-width-1-2"
                    currentValue={ boxOptions[box] }
                    onChange={ onBoxChange }
                  />}
              </div>
            </div>
          </>
        }
        {
          (!currentCountry || !currentProjectType || !currentProductCategory) &&
          <>
            <div className="concepts-database_icon -lg -top-margin">
              <SvgIcon name="i-no-data" />
            </div>
            <div className="concepts-database_no-data-text">
              { `Select the project type and category to access Concepts Database` }
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Settings;
