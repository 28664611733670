import remoteFiles from '../../../common/remote_files';
const {
  cyclingPng, HIITPng, runningPng, strengthPng, sculptPng,
  stretchRecoveryPng, meditationPng, yogaPng, walkingPng
} = remoteFiles.equinox;

const categories = {
  cycling: {
    title: "Cycling",
    color: "light-red",
    imgUrl: cyclingPng
  },
  hiit: {
    title: "HIIT",
    color: "orange",
    imgUrl: HIITPng
  },
  running: {
    title: "Running",
    color: "light-orange",
    imgUrl: runningPng
  },
  strength: {
    title: "Strength",
    color: "ochre",
    imgUrl: strengthPng
  },
  sculpt: {
    title: "Sculpt",
    color: "mint",
    imgUrl: sculptPng
  },
  stretchRecovery: {
    title: "Stretch and Recovery",
    color: "blue",
    imgUrl: stretchRecoveryPng
  },
  meditation: {
    title: "Meditation",
    color: "purple",
    imgUrl: meditationPng
  },
  yoga: {
    title: "Yoga",
    color: "extra-light-red",
    imgUrl: yogaPng
  },
  walking: {
    title: "Walking",
    color: "grayish-purple",
    imgUrl: walkingPng
  }
};

export default categories;
