import React from "react";
import cn from "classnames";

const SimpleCarousel = ({ items, className }) => {
  return (
    <div className="simple-carousel">
      <div className={cn("simple-carousel_list", className)}>
        {items.map((text, i) => (
          <div className="simple-carousel_item" key={i}>
            {text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimpleCarousel;
