import React from "react";

const HiddenInputs = ({id, conceptId, metaData}) => {
  return (
    <div className="products-greed-item-hidden-inputs">
      <input type="hidden" name="project[express_images][][id]" value={ id } />
      <input type="hidden" name="project[express_images][][concept_id]" value={ conceptId } />
      <input type="hidden" name="project[express_images][][video_data]" value={ metaData } />
    </div>
  )
}

export default HiddenInputs;