import React, { useEffect, useState } from 'react';
import { filter, map } from "lodash";
import { initConditionList, filteredQuestionsForFields } from '../common/custom_questions/helper';
import ScreeningQuestion from '../common/custom_questions/components/ScreeningQuestion';
import { HighLevelContext, QuestionsContext } from '../common/custom_questions/contexts';
import { inOneOfAllowedMessages } from "../common/navigation";
import Notification from "../common/notification";
import QuestionsOrderModal from "../common/questions-order-modal";
import Intro from "./components/Intro";
import HiddenFields from './components/HiddenFields';

const App = ({ data, options, grid_data, images, error }) => {
  const initScreenerQuestions = () => (
    map(data.custom_metrics, (q) => initConditionList(q, grid_data[q.id], images[q.id]))
  );
  const [ metricQuestions, setMetricQuestions ] = useState(initScreenerQuestions);
  const [ questionsOrderData, setQuestionsOrderData ] = useState(null);
  const [ initialJSON ] = useState(() => {
    return JSON.stringify(metricQuestions);
  });

  const [ notificationObject, setNotificationObject ] = useState(null);

  const [ conceptsCountValue, setConceptsCountValue ] = useState(() => (
    data.concepts_count_for_custom_metrics || options.custom_metrics_concepts_number_data.max
  ));
  const numberLimit = options.number_limit;
  const hasNumberLimit = !!numberLimit;
  const filteredList = filter(metricQuestions, (q) => !q._destroy);
  const emptyListQuestions = !filteredList.length;
  const addQuestionDisabled = (hasNumberLimit && filteredList.length >= numberLimit);

  const conceptsCountData = {
    ...options.custom_metrics_concepts_number_data,
    ...{
      value: conceptsCountValue,
      setValue: (value) => {
        setConceptsCountValue(value);
      }
    }
  };
  conceptsCountData.display = !!filteredList.length && conceptsCountData.enabled;
  conceptsCountData.disableDedicatedPage = (
    parseInt(conceptsCountData.max, 10) !== parseInt(conceptsCountData.value, 10)
  );

  const updateList = (newValue) => {
    setMetricQuestions(newValue);
  };

  const addQuestion = (event) => {
    event.preventDefault();
    updateList(
      [
        ...metricQuestions,
        {
          text: "",
          answers: [ "" ],
          kind: "",
          condition: {},
          condition_list: [],
          answers_order: "specific",
          multi_condition: 1,
          quotas: {},
          manage_quota: false,
          scales: [ "" ],
          survey_location: "default",
          position: filteredList.length,
          tmpIndex: metricQuestions.length
        }
      ]
    );
  };

  const submitForm = (ev) => {
    if (
      !ev.data ||
      typeof (ev.data) !== "object" ||
      !inOneOfAllowedMessages(ev.data) ||
      questionsOrderData
    ) {
      return;
    }
    window.document.getElementById("survey_form").submit();
  };

  useEffect(() => {
    window.addEventListener("message", submitForm);
    return () => {
      window.removeEventListener('message', submitForm);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setNotificationObject({ message: error, success: false });
    }
  }, []);

  const highLevelContextValue = { conceptsCountData };

  const [ initialValue ] = useState(() => {
    return highLevelContextValue.conceptsCountData.value;
  });

  const isUpdated = () => {
    const s1 = initialJSON;
    const s2 = JSON.stringify(metricQuestions);
    const n1 = parseInt(initialValue);
    const n2 = parseInt(highLevelContextValue.conceptsCountData.value);

    const regExp = /(\\n|\\r|\\t)/g;

    return s1.replace(regExp, '') !== s2.replace(regExp, '') || (n1 || 0) !== (n2 || 0);
  };

  const onOpenQuestionsOrderModal = (event) => {
    event.preventDefault();
    setQuestionsOrderData(metricQuestions);
  };

  const questionsContextValue = { options, allowReasons: options.allow_reasons };

  return (
    <HighLevelContext.Provider value={ highLevelContextValue }>
      <div className="custom-metric-questions -max-screener-and-custom-metrics-width">
        <HiddenFields
          questions={ filteredQuestionsForFields(metricQuestions) }
          options={ options }
          updated={ isUpdated() }
          conceptsCountEnabled={ conceptsCountData.enabled }
          conceptsCountValue={ conceptsCountValue }
        />
        {
          emptyListQuestions &&
          <Intro
            addQuestion={ addQuestion }
            hasNumberLimit={ hasNumberLimit }
            numberLimit={ numberLimit }
          />
        }
        {
          !emptyListQuestions &&
          <>
            <div className="form_section-title -grid-between">
              <div className="form_section-title-item -custom-metrics">
                <h3>Custom Metrics</h3>
                <div className="form-question_type-text">
                  You can create up to {numberLimit} custom metric questions for the survey.
                </div>
              </div>
              <div className="form_section-title-item">
                {
                  <button className="button" onClick={ addQuestion } disabled={ addQuestionDisabled }>
                    Add Custom Metric Question
                  </button>
                }
              </div>
            </div>
            {
              filteredList.length > 1 &&
              <div className="form_section-title-item -order-button">
                {
                  <button className="button -secondary -white-back -blue" onClick={ onOpenQuestionsOrderModal } >
                    Change order
                  </button>
                }
              </div>
            }
            <QuestionsContext.Provider value={ questionsContextValue }>
              <div className="form-items-list">
                {
                  map(metricQuestions, (question, index) => (
                    <ScreeningQuestion
                      key={ `Question${index}-${question.name}-${question.tmpIndex}` }
                      options={ options }
                      question={ question }
                      screenerQuestions={ metricQuestions }
                      setScreenerQuestions={ updateList }
                      questionIndex={ index }
                      gridData={ grid_data }
                      images={ question.answer_images }
                      withImages
                      needOther
                      needNone
                      needFivePtScaleToggle
                      needLocation
                    />
                  ))
                }
              </div>
            </QuestionsContext.Provider>
          </>
        }
      </div>

      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
      {
        !!questionsOrderData &&
        <QuestionsOrderModal
          questions={ questionsOrderData }
          setQuestionsOrderData={ setQuestionsOrderData }
          onSave={ setMetricQuestions }
        />
      }
    </HighLevelContext.Provider>
  );
};

export default App;
