import React from 'react';

const DetailsAndConditions = () => (
  <div className="form-pay_hint">
    <a href="https://stripe.com/checkout/legal" target="_blank" rel="noreferrer">
      Details and conditions
    </a>
    <br />
    <span>of the service.</span>
  </div>
);

export default DetailsAndConditions;
