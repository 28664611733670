import React from "react";
import Avatar from "./Avatar";
import BrandUnderlineLogo from "./BrandUnderlineLogo";

const WorkoutVideo = ({ workout }) => {
  return (
    <div className="workout-video">
      <div className="workout-video_bg">
        <img src={workout.imgUrl} className="workout-video_image" />
      </div>
      <h2 className="workout-video_title">{workout.title}</h2>
      <div className="workout-video_row">
        <div className="workout-video_info">
          <div className="workout-video_level">{workout.level}</div>
          <div className={`workout-video_dot -bg-${workout.category.color}`} />
          <div className="workout-video_category">{workout.category.title}</div>
        </div>
        <div className="workout-video_duration">{workout.duration}</div>
      </div>
      <div className="workout-video_divider"></div>
      <div className="workout-video_row">
        <div className="workout-video_coach">
          <div className="workout-video_avatar">
            <Avatar {...workout.coach} size="small" />
          </div>
          <div className="workout-video_coach-name">
            {workout.coach.firstName} {workout.coach.lastName}
          </div>
        </div>
        <div className="workout-video_brand">
          <BrandUnderlineLogo {...workout.brand} />
        </div>
      </div>
    </div>
  );
};

export default WorkoutVideo;
