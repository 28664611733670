import AppleMusicUrl from "../../../../../../../images/quant/apple-music.png";

let linesCount = 0;
export const setLinesCount = count => { linesCount = count - 1; };
const prices = [
  [70, 80, 90],
  [60, 70, 80],
  [45, 55, 65],
  [35, 45, 55],
  [30, 40, 50],
];

const getPriceById = (id) => prices[linesCount][id - 1];

// Features
const FiveGNationwide = {
  title: '5G Nationwide',
  text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
};

const FiveGUltraWideband = {
  title: '5G Ultra Wideband',
  text: 'Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today\'s 4G networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. <strong>5G Ultra Wideband available only in parts of select cities</strong>;\nrequires a 5G Ultra Wideband-capable device.'
};

const UnlimitedPremiumNetworkAccess = {
  title: 'Unlimited Premium Network Access',
  text: 'Get access to unlimited 5G Nationwide / 4G LTE  premium  data  per month. 5G access requires a 5G-capable device. Plus  720p HD streaming when you activate it in your plan settings.'
};

const UnlimitedMobileHotspot25 = {
  title: 'Unlimited mobile hotspot <span style="white-space: nowrap">(25 GB high-speed data)</span>',
  text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 25 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area'
};

const UnlimitedMobileHotspot50 = {
  title: 'Unlimited mobile hotspot <span style="white-space: nowrap">(50 GB high-speed data)</span>',
  text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 50 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.'
};

const VerizonCloudStorage600 = {
  title: '600 GB of Verizon Cloud storage',
  text: 'Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.'
};

// Perks
const DisneyPlus6 = {
  title: 'Disney+ 6 months on us',
  text: 'All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic, including The Mandalorian, The Avengers, Mulan, and Hamilton. First 6 months on us, then $7.99/mo + taxes after. One subscription can be shared with everyone on your account. Cancel anytime. (For NM residents, Disney+ ends automatically after 6 mos. Add\'l terms apply. One offer per eligible Verizon account.)',
  icon: 'Disney'
};

const DisneyPlusHuluESPN = {
  title: 'Disney+, Hulu, ESPN+ included',
  text: 'Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places. One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.',
  icon: 'Disney_Bundle',
};

const ArcadeOrGooglePlay6 = {
  title: 'Apple Arcade or Google Play Pass 6&nbsp;months on us',
  text: 'Choice between:\n• Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 6 months on us, then $4.99/month + taxes after.\nOR\n• Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 6 months on us, then $4.99/month + taxes after.',
  icon: 'Apple_Arcade_Google_Play_Pass_Black',
};

const ArcadeOrGooglePlay = {
  title: 'Apple Arcade or Google Play Pass included',
  text: 'Choice between:\n• Apple Arcade subscription included. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. A $4.99/month value.\nOR\n• Google Play Pass subscription included along with Play Points Gold Status. Your pass to hundreds of games and apps in one subscription. Available on Android. A $4.99/month value.',
  icon: 'Apple_Arcade_Google_Play_Pass_Black'
};

const AppleMusic6 = {
  title: 'Apple Music 6 months on us',
  text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
  iconPath: AppleMusicUrl,
  iconClassName: '-apple-music'
};

const AppleMusic = {
  title: 'Apple Music included',
  text: 'Apple Music subscription; allows you to listen to 70 million songs ad-free, or download your favorite tracks and play them offline. A $9.99/month value. Each line on an eligible plan gets a separate subscription.',
  iconPath: AppleMusicUrl,
  iconClassName: '-apple-music'
};

const OneTravelPass = {
  title: '1 TravelPass day per month',
  text: 'TravelPass lets you use your domestic talk, text and data in more than 185 countries outside the US for a set daily fee. You can call within the country you\'re visiting and you can call back to the US. One Verizon TravelPass (good for one day) is included each month; you can store them in the "bank" and use them when you travel. Each TravelPass expires 12 months from issue date.'
};

const FiftyPercentOffPlan = {
  title: '50% off a plan for a watch, tablet, Hum, or hotspot',
  text: 'Get 50% off an Unlimited connected device plan. (not the hardware). Up to $15/month value. (Jetpack is a separate device that lets you share your Verizon wireless network connection with other devices. Hum turns almost any car into a smarter, safer, more connected car; check your vehicle health, get 24/7 pinpoint road assistance, and create a WiFi connection from your car.)'
};

export const allPlansInclude = {
  title: 'All plans include',
  paragraphs: [
    {
      title: 'Unlimited talk, text, & data',
      text: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
    },
    {
      title:'4G LTE',
      text: 'Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic.'
    },
    {
      title: 'Mexico & Canada talk, text, & data',
      text: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.'
    },
    {
      title: 'International texting',
      text: 'Unlimited texting from the US to over 200 countries and territories worldwide.'
    },
    {
      title: 'Call Filter',
      text: 'Call Filter is a service that helps you take control of your calls and gives you the tools to manage those calls as you choose. You can screen incoming calls, block (send directly to voicemail) spam numbers by risk-level, and report numbers as spam. Numbers considered risky will be labeled as potential spam.'
    },
    {
      title: 'Verizon Up rewards',
      text: 'With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.'
    }
  ]
};

export const plansIncludes = allPlansInclude.paragraphs.map(el => el.title);

const startUnlimitedBase = {
  id: 1,
  name: 'Start Unlimited',
};

const flexUnlimitedBase = {
  id: 2,
  name: 'Flex Unlimited',
  selectablePerks: true,
};

const totalUnlimitedBase = {
  id: 3,
  name: 'Total Unlimited',
}

export const planList = [
  startUnlimitedBase,
  flexUnlimitedBase,
  totalUnlimitedBase
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

export const planIds = planList.map(({ id }) => id);

const startUnlimitedShort = {
  ...startUnlimitedBase,
  description: [
    {
      title: 'Features',
      paragraphs: [FiveGNationwide]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: 'ENTERTAINMENT',
          paragraphs: [
            { ...DisneyPlus6, icon: null },
            { ...ArcadeOrGooglePlay6, icon: null },
            { ...AppleMusic6, iconClassName: null, iconPath: null },
          ]
        }
      ]
    }
  ]
};

const flexUnlimitedShort = {
  ...flexUnlimitedBase,
  description: [
    {
      title: 'Features',
      paragraphs: [
        FiveGUltraWideband,
        FiveGNationwide,
        UnlimitedPremiumNetworkAccess,
        UnlimitedMobileHotspot25,
        VerizonCloudStorage600
      ]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: 'ENTERTAINMENT',
          paragraphs: [
            DisneyPlusHuluESPN,
            ArcadeOrGooglePlay,
            { ...AppleMusic6, iconPath: null, iconClassName: null }
          ]
        },
        {
          title: 'PRODUCTIVITY',
          paragraphs: [
            OneTravelPass,
            FiftyPercentOffPlan,
            { ...DisneyPlus6, icon: null },
            { ...ArcadeOrGooglePlay6, icon: null },
            { ...AppleMusic6, iconPath: null, iconClassName: null }
          ],
        }
      ]
    }
  ]
};

const totalUnlimitedShort = {
  ...totalUnlimitedBase,
  description: [
    {
      title : 'Features',
      paragraphs: [
        FiveGUltraWideband,
        FiveGNationwide,
        UnlimitedPremiumNetworkAccess,
        UnlimitedMobileHotspot50,
        VerizonCloudStorage600,
      ]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: 'ENTERTAINMENT',
          paragraphs: [
            DisneyPlusHuluESPN,
            ArcadeOrGooglePlay,
            AppleMusic,
          ]
        },
        {
          title: 'PRODUCTIVITY',
          paragraphs: [
            OneTravelPass,
            FiftyPercentOffPlan,
          ]
        }
      ]
    }
  ]
};

export const planDetailsShort = [
  startUnlimitedShort,
  flexUnlimitedShort,
  totalUnlimitedShort
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

const planShortDetailsDictionary = planDetailsShort.reduce((dictionary, element) => ({
  ...dictionary,
  [element.id]: element
}), Object.create(null));

export const getShortPlanDescription = (id) => planShortDetailsDictionary[id] || null;

const startUnlimitedLarge = {
  ...startUnlimitedBase,
  description: [
    {
      title: 'Features',
      paragraphs: [
        FiveGNationwide
      ]
    },
    {
      title: 'Perks',
      paragraphs: [
        DisneyPlus6,
        ArcadeOrGooglePlay6,
        AppleMusic6,
      ]
    },
    allPlansInclude,
  ]
};

const flexMoreUnlimitedLarge = {
  ...flexUnlimitedBase,
  description: [
    {
      title: 'Features',
      paragraphs: [
        FiveGUltraWideband,
        FiveGNationwide,
        UnlimitedPremiumNetworkAccess,
        UnlimitedMobileHotspot25,
        VerizonCloudStorage600
      ]
    },
    {
      title: 'Entertainment Perks',
      paragraphs: [
        DisneyPlusHuluESPN,
        ArcadeOrGooglePlay,
        AppleMusic6,
      ]
    },
    {
      title: 'Productivity Perks',
      paragraphs: [
        OneTravelPass,
        FiftyPercentOffPlan,
        DisneyPlus6,
        ArcadeOrGooglePlay6,
        AppleMusic6,
      ],
      showOrBefore: true
    },
    allPlansInclude
  ]
};

const totalUnlimitedLarge = {
  ...totalUnlimitedBase,
  description: [
    {
      title : 'Features',
      paragraphs: [
        FiveGUltraWideband,
        FiveGNationwide,
        UnlimitedPremiumNetworkAccess,
        UnlimitedMobileHotspot50,
        VerizonCloudStorage600,
      ]
    },
    {
      title: 'Perks',
      paragraphs: [
        DisneyPlusHuluESPN,
        ArcadeOrGooglePlay,
        AppleMusic,
        OneTravelPass,
        FiftyPercentOffPlan,
      ]
    },
    allPlansInclude
  ]
};

export const planDetailsLarge = [
  startUnlimitedLarge,
  flexMoreUnlimitedLarge,
  totalUnlimitedLarge,
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

const planLargeDetailsDictionary = planDetailsLarge.reduce((dictionary, element) =>
  ({
    ...dictionary,
    [element.id]: element,
  }), Object.create(null));

export const getLargePlanDescription = (id) => planLargeDetailsDictionary[id] || null;
