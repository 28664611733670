import React, { useContext } from 'react';
import { map } from 'lodash';
import { DataContext } from "./withData";
import NewTagLine from "./common/NewTagLine";
import TagWithChildren from "./common/TagWithChildren";
import Counters from './common/Counters';

const TagsByCategory = () => {
  const { categoryData, showModal } = useContext(DataContext);

  const handleOpenImages = (category, kinds, type) => {
    showModal({ id: category.product_category_id, type, kinds, category: category.name });
  };

  return (
    <div className="form_section-tabs -max-50vw">
      {
        map(categoryData, (category) => (
          <div key={ category.product_category_id } className="form_section">
            <div className="form_section-title -grid">
              <h2 className="form_grid-item -grow -no-padding">{ category.name }</h2>
              <div className="form_section-text">
                <Counters
                  countsHash={ category.count }
                  onClick={ (kinds, type) => handleOpenImages(category, kinds, type) }
                />
              </div>
            </div>

            <NewTagLine
              className="form_section-line"
              tagType="express_category"
              productCategoryId={ category.product_category_id }
            />

            {
              map(category.tags, (tagObj) => (
                <TagWithChildren key={ tagObj.value } tagObj={ tagObj } productCategory={ category } />
              ))
            }
          </div>
        ))
      }
    </div>
  );
};

export default TagsByCategory;
