import React from "react";

const CategoryCard = ({ imgUrl, title, color }) => {
  return (
    <div className="category-card">
      <img className="category-card_image" src={imgUrl} />
      <div className="category-card_title">{title}</div>
      <div className={`category-card_line -bg-${color}`}></div>
    </div>
  );
};

export default CategoryCard;
