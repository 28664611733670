import React, { useState } from 'react';
import DemographicsApp from "../../demographics/app";
import Header from "../../common/header";
import { errorsText } from "../common/helpers";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import NumberAndDescription from "../common/NumberAndDescription";
import WithSubmit from "../../common/WithSubmit";
import HiddenFields from "./HiddenFields";
import ProjectCategory from "./ProjectCategory";
import CustomQualified from "./CustomQualified";
import ForceMarketPlace from "./ForceMarketPlace";
import ScreenerOnEnglish from './ScreenerOnEnglish';

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    platformId: data.platform_id || '',
    note: data.note || '',
    title: data.title || '',
    categoryId: (typeof data.product_category_ids === 'string') ? data.product_category_ids : data.product_category_ids[0] || '',
    customQualified: data.custom_qualified,
    qualifiedText: data.qualified_text,
    screenerOnEnglish: data.screener_on_english,
    mpOption: data.mp_option,
    internal: data.internal || false,
    errors
  });

  return (
    <WithSubmit formId="setup_form" errors={ errors }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header
          title={ options.project_title }
          description={ 'Understand brand awareness, perceptions, sourcing, and usage among category users. Include up to 3 custom questions.' }
        />
        <div className="content_body">
          <div className="form">
            {
              options.show_project_number &&
              <NumberAndDescription
                initPlatformId={ model.platformId }
                platformIdField={ 'platformId' }
                initInternal={ model.internal }
                internalField={ 'internal' }
                initNote={ model.note }
                noteField={ 'note' }
                onChange={ (field, value) => {setModel({ ...model, ...{ [field]: value } });} }
              />
            }

            {
              !data.multilang &&
              <>
                <FieldHeaderWithText
                  id="project_title"
                  title="Project Name"
                  placeholder="Please name your project"
                  errors={ errorsText(errors, 'title') }
                  initValue={ model.title }
                  onChange={ (v) => {setModel({ ...model, title: v });} }
                />

                <ProjectCategory
                  options={ options.product_categories_options }
                  model={ model }
                  setModel={ setModel }
                  errors={ errors }
                />
              </>
            }
            <CustomQualified
              title={ options.qualify_message_title }
              hint={ options.qualify_message_hint }
              hintClass="-big"
              model={ model }
              setModel={ setModel }
              errors={ errors }
              gaTrackingId={ options.google_analytics_id }
              projectId={ options.project_id }
            />

            {
              options.show_screener_on_english_option &&
              <ScreenerOnEnglish model={ model } setModel={ setModel } />
            }
            {
              options.show_mp_option &&
              <ForceMarketPlace
                model={ model }
                setModel={ setModel }
                gaTrackingId={ options.google_analytics_id }
                projectId={ options.project_id }
              />
            }

            <div className="form_section" id="demographics-react">
              <DemographicsApp data={ data } options={ options } />
            </div>
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
