import React, { useState } from 'react';
import ConceptA from './concept-a';
import ConceptB from './concept-b';
import ConceptC from './concept-c';

const App = ({ conceptName }) => {
  const [ concept, setConcept ] = useState(conceptName);
  const destroy = () => {
    setConcept(null);
  };
  switch (concept) {
    case 'A': return <ConceptA onNext={ destroy } />;
    case 'B': return <ConceptB onNext={ destroy } />;
    case 'C': return <ConceptC onNext={ destroy } />;
  }
  return null;
};

export default App;
