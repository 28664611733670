import React, { useContext } from 'react';
import { baseContext } from '../../../common/contexts';
import renderRawHtml from '../../../../../common/render_raw_html';
import SvgIcon from '../../../../../common/components/svg-icon';

const VerizonStripe = () => {
  const { translations } = useContext(baseContext);
  return (
    <div className="verizon-branded-stripe">
      <div className="logo" aria-hidden>
        <SvgIcon name="verizon-long-branded" className="-verizon-logo" />
      </div>
      <div
        aria-label="Privacy information"
        className="text"
        { ...renderRawHtml(translations.verizon_branded_text) }
      />
    </div>
  );
};

export default VerizonStripe;
