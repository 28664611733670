import React, { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import ActiveReport from '../activeReport';
import Article from '../article';
import Installations from '../installations';
import OverviewSpotlights from '../overviewSpotlights';
import { withPrefix, subPulseChanges } from '../helpers';
import { loadOverview } from '../api';
import { spotlightContext, baseContext, installationsContext, QualitativeContext } from '../contexts';
import OverviewTitle from '../common/overview-title';

const MainDashboard = ({ canEdit }) => {
  const [ inProgress, setInProgress ] = useState(true);
  const [ overview, setOverview ] = useState({ data: {} });

  const [ installationsTitle, setInstallationsTitle ] = useState('');
  const [ installationsSlides, setInstallationsSlides ] = useState([]);
  const [ installationsPpt, setInstallationsPpt ] = useState({});
  const [ installationsPdf, setInstallationsPdf ] = useState({});

  const [ summaries, setSummaries ] = useState([]);
  const [ spotlights, setSpotlights ] = useState([]);
  const [ summariesTopic, setSummariesTopic ] = useState('');
  const [ summariesMode, setSummariesMode ] = useState('manual');
  const [ summaryPdfUploaded, setSummaryPdfUploaded ] = useState(false);
  const { mediaKind, verizonType, showPulseData } = useContext(baseContext);

  useEffect(() => {
    loadOverview(mediaKind, verizonType, (overviewData) => {
      setInProgress(false);
      setOverview(overviewData);
      setInstallationsTitle(overviewData.installation_title);
      setInstallationsSlides(overviewData.installation_slides);
      setInstallationsPpt({
        url: overviewData.ppt_report_url,
        metaData: overviewData.ppt_report_data,
        filename: overviewData.ppt_report_filename
      });
      setInstallationsPdf({
        url: overviewData.installation_pdf_url,
        metaData: overviewData.installation_pdf_data,
        filename: overviewData.installation_pdf_filename
      });

      setSummaries(overviewData.summaries);
      setSpotlights(overviewData.spotlights);
      setSummariesTopic(overviewData.summary_title);
      setSummariesMode(overviewData.summary_mode);
      setSummaryPdfUploaded(overviewData.summary_pdf_uploaded);
    });
  }, []);

  const overviewInstallations = {
    getTitle: () => installationsTitle,
    setTitle: (value) => { setInstallationsTitle(value); },
    getSlides: () => installationsSlides,
    setSlides: (value) => { setInstallationsSlides(value); },
    getPpt: () => installationsPpt,
    setPpt: (url, metaData, filename) => { setInstallationsPpt({ url, metaData, filename }); },
    getPdf: () => installationsPdf,
    setPdf: (url, metaData, filename) => { setInstallationsPdf({ url, metaData, filename }); }
  };

  const articleSummaries = {
    title: summariesTopic,
    list: summaries,
    mode: summariesMode,
    pdfUploaded: summaryPdfUploaded
  };

  const topPosition = subPulseChanges(verizonType);

  const summaryComponent = (
    <Article
      editable={ canEdit }
      kind="summary"
      type="grid"
      content={ articleSummaries }
      setContent={ setSummaries }
      setTopic={ setSummariesTopic }
      setMode={ setSummariesMode }
      setPdfUploaded={ setSummaryPdfUploaded }
    />
  );

  const qualitativeValue = {
    fontClass: (showPulseData ? "" : "-reduce-no-pulse")
  };

  return (
    <QualitativeContext.Provider value={ qualitativeValue }>
      { inProgress && <div className="iframe-loader" /> }
      {
        !inProgress && isEmpty(overview.data) &&
        <h1>
          No Pulse Survey were selected for the Overview page, please ask administrator to do this.
        </h1>
      }
      {
        !inProgress && !isEmpty(overview.data) &&
        <>
          {
            showPulseData ?
              (<ActiveReport overview={ overview } />)
              :
              (<OverviewTitle overview={ overview } forceHideNsize />)
          }
          { topPosition && summaryComponent }
          <installationsContext.Provider value={ overviewInstallations }>
            <Installations editable={ canEdit } />
          </installationsContext.Provider>
          <spotlightContext.Provider value={ { updateOverviewSpotlights: setSpotlights } }>
            <OverviewSpotlights
              editable={ canEdit }
              title={ withPrefix(verizonType, mediaKind, 'Customer Spotlight') }
              list={ spotlights }
            />
          </spotlightContext.Provider>
          { !topPosition && summaryComponent }
        </>
      }
    </QualitativeContext.Provider>
  );
};

export default MainDashboard;
