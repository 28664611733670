import React, { useContext } from 'react';
import { map } from "lodash";
import { LaunchContext } from '../contexts';
import { formatDateTime } from '../../../../common/dateUtils';

const PayedData = () => {
  const { loading, payedProjects } = useContext(LaunchContext);
  return (
    <div className="content_body launch-content">
      { loading && <div className="page-loader -inner-loader" /> }
      <div className="form form-payed">
        <div className="form_section">
          <h3 className="form_section-title">Payed.</h3>
          <ul className="form_section-list -no-bullet">
            {
              map(payedProjects, (payment, index) => (
                <li key={ index }>
                  {formatDateTime(payment.date)}: N = { payment.nsize }, ${ payment.amount / 100 }
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PayedData;
