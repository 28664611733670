import React, { useContext, useEffect, useRef } from 'react';
import { isEmpty } from "lodash";
import cn from 'classnames';
import TableFivePointsScales from "../../../common/components/entity-modal/table-five-points-scales";
import TableAttributeRatings from "../../../common/components/entity-modal/table-attribute-ratings";
import { selectGroup, IndexData } from "../../../common/index-context";
import ComparisonsLegend from "../../../common/components/comparisons-legend";
import TableCustomMetrics from "../../../common/components/entity-modal/table-custom_metrics";
import TableCombinedTools from "./table-combined-tools";

const TableConcept = ({ data, current }) => {
  const combinedTools = selectGroup(data, 'combined_tool');
  const fivePointScales = selectGroup(data, 'five_point_scale');
  const attributeRatings = selectGroup(data, 'attribute_ratings');
  const customMetrics = selectGroup(data, 'custom_metric');

  const combinedToolsPresent = !isEmpty(combinedTools);
  const fivePointScalesPresent = !isEmpty(fivePointScales);
  const attributeRatingsPresent = !isEmpty(attributeRatings);
  const customMetricsPresent = !isEmpty(customMetrics);

  const tourElement = useRef(null);
  const { getTourUseEffectArgs, averageMode } = useContext(IndexData);
  const [ callback, condition ] = getTourUseEffectArgs(
    "projectModalTable",
    tourElement
  );
  useEffect(callback, condition);

  return (
    <>
      <div className="full-modal-table" ref={ tourElement }>
        <div className="concept-report_tables comparisons_grid">
          {
            combinedToolsPresent &&
              <div
                className={
                  cn(
                    "comparisons_col -grow",
                    {
                      '-grow': !fivePointScalesPresent,
                      '-width-50': fivePointScalesPresent
                    }
                  )
                }
              >
                <TableCombinedTools
                  data={ data }
                  current={ current }
                  combinedTools={ combinedTools }
                />
              </div>
          }

          {
            fivePointScalesPresent &&
              <div
                className={
                  cn(
                    "comparisons_col",
                    {
                      '-grow': !combinedToolsPresent,
                      '-width-50': combinedToolsPresent
                    })
                }
              >
                <TableFivePointsScales
                  data={ data }
                  current={ current }
                  fivePointScales={ fivePointScales }
                  groupName="five_point_scale"
                  withBar
                />
              </div>
          }
        </div>
        {
          customMetricsPresent &&
          <div className="concept-report_tables comparisons_grid">
            <div className="comparisons_col -grow">
              <TableCustomMetrics
                data={ data }
                current={ current }
                customMetrics={ customMetrics }
                groupName="custom_metrics"
                className="-long-first-cell"
                withN={ data.custom_metrics_display_n_size }
              />
            </div>
          </div>
        }
        {
          attributeRatingsPresent &&
            <div className="concept-report_tables comparisons_grid">
              <div className="comparisons_col -grow">
                <TableAttributeRatings
                  data={ data }
                  current={ current }
                  attributeRatings={ attributeRatings }
                  groupName="attribute_ratings"
                />
              </div>
            </div>
        }
      </div>
      <ComparisonsLegend
        forModal
        averageMode={ averageMode }
        statTesting={ data.stat_testing }
      />
    </>
  );
};

export default TableConcept;
