import React, {useContext} from 'react';
import {IndexData} from "../contexts";
import classnames from "classnames";

const sortableHeader = ({ className, title, field }) => {
  const {
    sortParam, order, setSortParam,
    setOrder, loadProjects
  } = useContext(IndexData);

  const change = (e) => {
    e.preventDefault();
    let orderDir = 'asc';
    if (sortParam != field || !order || order === 'desc') {
      setOrder('asc');
      orderDir = "asc"
    } else if (order === 'asc') {
      setOrder('desc');
      orderDir = "desc"
    }

    setSortParam(field);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("sort", field);
    searchParams.set("order", orderDir);
    loadProjects("", searchParams.toString());
  };

  const classes = () => {
    return {
      '-asc': field == sortParam && order == 'asc',
      '-desc': field == sortParam && order == 'desc'
    };
  };

  return (
    <th className={ className }>
      <a
        href="#"
        className={ classnames("sort-link", classes()) }
        onClick={ change }
      >
        { title }
      </a>
    </th>
  );
};

export default sortableHeader;
