import React, { useContext, useEffect, useRef, useState } from 'react';
import { map, includes, each, filter, lowerCase, concat } from "lodash";
import cn from "classnames";
import Popup from "reactjs-popup";
import { baseContext } from "../context";
import { bulkUpdateAttrs, clearTags, createTag, selectTag, updateAttrs, updateNotes } from "../requests";
import {
  categoryFirstLayerTags, categorySecondLayerTags,
  brandTags as listBrandTags, customTags as listCustomTags
} from '../../../admin/concepts-database/helpers';
import TagsDropdown from "./tags_dropdown";
import PropsToggle from "./props_toggle";
import Checkbox from "./checkbox";

const Concept = ({ concept, bulkMode = false }) => {
  const {
    projectId, setData, categoryTags,
    categorySubTags, brandTags, customTags,
    globalLoading, setGlobalLoading
  } = useContext(baseContext);

  const containerRef = useRef();

  const selectedFirstLayerTags = categoryFirstLayerTags(concept.selected_tags);
  const selectedFirstLayerTagsIds = map(selectedFirstLayerTags, (tag) => tag.value);
  const selectedSecondLayerTags = categorySecondLayerTags(concept.selected_tags);
  const secondLayerTagsSuggestions = filter(categorySubTags, (tag) => includes(selectedFirstLayerTagsIds, tag.parent_id));
  const selectedBrandTags = listBrandTags(concept.selected_tags);
  const selectedCustomTags = listCustomTags(concept.selected_tags);

  const priced_texts = {
    0: 'Priced',
    1: 'Unpriced'
  };

  const stimuli_types = {
    0: 'Static',
    1: 'Dynamic',
    2: 'Video'
  };

  const product_types = {
    0: 'Product',
    1: 'Service',
    2: 'Promo',
    3: 'Fees'
  };

  const [ bulkPayload, updateBulkPayload ] = useState(
    [ "priced", "stimuli_type", "product_type", "express_category", "express_brand", "express_custom", "notes" ]
  );
  const [ extendedBulkMode, setExtendedBulkMode ] = useState(false);
  const [ notes, setNotes ] = useState(concept.notes || '');
  const [ currentTimeout, setCurrentTimeout ] = useState(null);
  useEffect(() => {
    if (concept) {
      setGlobalLoading(false);
    }
  }, [ concept ]);

  useEffect(() => {
    setNotes(concept.notes || '');
  }, [ concept ]);

  const onAttrChange = (attrName, value) => {
    const payload = {};
    payload[attrName] = lowerCase(value);
    updateAttrs(projectId, concept.id, setData, payload);
  };

  const onTagSelect = (tagId) => {
    const payload = { tag_id: tagId };
    selectTag(projectId, concept.id, setData, payload);
  };

  const onTagsClear = (values) => {
    const tagIds = map(values, (tag) => tag.value);
    const subTags = map(filter(selectedSecondLayerTags, (tag) =>
      includes(tagIds, tag.parent_id)), (tag) => tag.value
    );
    tagIds.push(...subTags);
    const payload = { tag_ids: tagIds };
    clearTags(projectId, concept.id, setData, payload);
  };

  const onTagCreate = (value, tag_type, childMode = false) => {
    const payload = { tag: value, tag_type };
    if (childMode) {
      payload.parent_id = selectedFirstLayerTags[0].value;
    }
    createTag(projectId, concept.id, setData, payload);
  };

  const handleCheckbox = (value, paramName) => {
    if (value) {
      updateBulkPayload((bulkPayload) => [ ...bulkPayload, paramName ]);
    } else {
      updateBulkPayload(filter(bulkPayload, (item) => item !== paramName));
    }
  };

  const assignBulkParams = (payload, param) => {
    switch (param) {
      case "priced":
      case "stimuli_type":
      case "product_type":
        payload[param] = concept[param];
        break;
      case "notes":
        payload[param] = containerRef.current.value;
        break;
      case "express_category":
        if (payload.tag_ids) {
          payload.tag_ids.push(...map(concat(selectedFirstLayerTags, selectedSecondLayerTags), (tag) => tag.value));
        } else {
          payload.tag_ids = map(concat(selectedFirstLayerTags, selectedSecondLayerTags), (tag) => tag.value);
        }
        break;
      case "express_brand":
        if (payload.tag_ids) {
          payload.tag_ids.push(...map(selectedBrandTags, (tag) => tag.value));
        } else {
          payload.tag_ids = map(selectedBrandTags, (tag) => tag.value);
        }
        break;
      case "express_custom":
        if (payload.tag_ids) {
          payload.tag_ids.push(...map(selectedCustomTags, (tag) => tag.value));
        } else {
          payload.tag_ids = map(selectedCustomTags, (tag) => tag.value);
        }
        break;
    }
  };

  const onBulkUpdate = () => {
    const payload = {};
    each(bulkPayload, (item) =>
      assignBulkParams(payload, item)
    );
    payload.tag_types = filter(bulkPayload, (param) => includes(param, "express"));

    if (extendedBulkMode) {
      updateBulkPayload([]);
    }

    setGlobalLoading(true);
    bulkUpdateAttrs(projectId, concept.id, setData, payload);
  };

  const onExtendedModeClick = () => {
    updateBulkPayload([]);
    setExtendedBulkMode(true);
  };

  const handleNotesChange = () => {
    const newValue = containerRef.current.value;
    setNotes(newValue);
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }

    const timeOut = setTimeout(() => {
      const payload = { notes: newValue };
      updateNotes(projectId, concept.id, payload);
    }, 500);
    setCurrentTimeout(timeOut);
  };

  const trigger = (
    <div
      className={ cn("table-comparison_concept -concept-card", `js-${concept.id}`) }
    >
      <div className="concept-preview table-comparison_concept-preview">
        <div
          className="concept-preview_thumb -dark -less-rounded"
          style={ { 'backgroundImage': `url(${concept.image_url})` } }
        />
      </div>
    </div>
  );

  return (
    <div className={ cn("concept-tags_plate", { '-bulk': bulkMode }) }>
      <div className="concept-tags_plate-header">
        <div className="concept-tags_concept-image">
          <Popup
            trigger={ trigger }
            position="top center"
            on={ [ 'hover', 'focus' ] }
            className="th-image-popup"
            mouseEnterDelay={ 1 }
            mouseLeaveDelay={ 1 }
            offsetY={ -200 }
          >
            <div className="-contains-image in">
              <div className="tooltip-inner">
                <img className="concept-preview_pic-big" src={ concept.image_url } />
              </div>
            </div>
          </Popup>
        </div>
        <div className="concept-tags_prop-title -concept_title">
          {concept.name}
        </div>
      </div>
      {
        globalLoading && <div className="page-loader -inner-loader" />
      }
      <div className={ cn("concept-tags_plate-body", { '-with_bottom_radius': !bulkMode }) }>
        <ul className="concept-tags_prop-list">
          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Priced / Unpriced
            </div>
            <div className="concept-tags_select_container -grow">
              <PropsToggle
                attrName="priced"
                labelClass="-width-1-2"
                prefix={ concept.id }
                options={ [ "Priced", "Unpriced" ] }
                currentValue={ priced_texts[concept.priced] }
                onChange={ onAttrChange }
              />
            </div>
          </li>
          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Stimuli Type
            </div>
            <div className="concept-tags_select_container -grow">
              <PropsToggle
                attrName="stimuli_type"
                labelClass="-width-1-3"
                prefix={ concept.id }
                options={ [ "Static", "Dynamic", "Video" ] }
                currentValue={ stimuli_types[concept.stimuli_type] }
                onChange={ onAttrChange }
              />
            </div>
          </li>
          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Product Type
            </div>
            <div className="concept-tags_select_container -grow">
              <PropsToggle
                attrName="product_type"
                labelClass="-width-1-4"
                prefix={ concept.id }
                options={ [ "Product", "Service", "Promo", "Fees" ] }
                currentValue={ product_types[concept.product_type] }
                onChange={ onAttrChange }
              />
            </div>
          </li>

          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Sub Category Tags
            </div>
            <div className="concept-tags_select_container">
              <TagsDropdown
                tags={ categoryTags }
                selectedTags={ selectedFirstLayerTags }
                tagType="express_category"
                onSelectTag={ onTagSelect }
                onClearTags={ onTagsClear }
                onCreateTag={ onTagCreate }
              />
            </div>
            <div className="concept-tags_select_container">
              <TagsDropdown
                tags={ secondLayerTagsSuggestions }
                selectedTags={ selectedSecondLayerTags }
                tagType="express_category"
                disabled={ selectedFirstLayerTags.length === 0 }
                onSelectTag={ onTagSelect }
                onClearTags={ onTagsClear }
                onCreateTag={ onTagCreate }
                childMode
              />
            </div>
          </li>
          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Brand
            </div>
            <div className="concept-tags_select_container -grow">
              <TagsDropdown
                id={ `${concept.id}-brand` }
                key={ `${concept.id}-brand` }
                tags={ brandTags }
                selectedTags={ selectedBrandTags }
                tagType="express_brand"
                onSelectTag={ onTagSelect }
                onClearTags={ onTagsClear }
                onCreateTag={ onTagCreate }
                bulkMode={ bulkMode }
              />
            </div>
          </li>
          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Custom Tags
            </div>
            <div className="concept-tags_select_container -grow">
              <TagsDropdown
                tags={ customTags }
                selectedTags={ selectedCustomTags }
                tagType="express_custom"
                onSelectTag={ onTagSelect }
                onClearTags={ onTagsClear }
                onCreateTag={ onTagCreate }
                bulkMode={ bulkMode }
              />
            </div>
          </li>
          <li className="concept-tags_prop-item">
            <div className="concept-tags_prop-title">
              Notes
            </div>
            <div className="concept-tags_select_container -grow">
              <textarea
                ref={ containerRef }
                key={ `${concept.id}` }
                className="form-field concept-tags_notes"
                onChange={ handleNotesChange }
                value={ notes }
              />
            </div>
          </li>
        </ul>
      </div>
      {
        bulkMode && !extendedBulkMode &&
        <>
          <div className="concept-tags_plate-footer">
            <div className="concept-tags_prop-title -footer">
              Bulk Update
            </div>
            <div className="concept-tags_select_container">
              <button
                className={ cn("concept-tags_submit_button", { '-disabled': bulkPayload.length === 0 }) }
                onClick={ onBulkUpdate }
              >
                Apply to all concepts below
              </button>
              <button
                className="concept-tags_submit_button -gray"
                onClick={ onExtendedModeClick }
              >
                Select parameters to update
              </button>
            </div>
          </div>
        </>
      }
      {
        bulkMode && extendedBulkMode &&
        <>
          <div className="concept-tags_plate-footer">
            <div className="concept-tags_prop-title -footer">
              Bulk Update
            </div>
            <div className="concept-tags_select_container">
              <div className="concept-tags_checkbox_block">
                <Checkbox
                  key={ `priced-${bulkPayload.length}` }
                  title="Priced / Unpriced"
                  value={ includes(bulkPayload, "priced") }
                  paramName="priced"
                  onChange={ handleCheckbox }
                />
                <Checkbox
                  key={ `category-${bulkPayload.length}` }
                  title="Sub Category Tags"
                  value={ includes(bulkPayload, "express_category") }
                  paramName="express_category"
                  onChange={ handleCheckbox }
                />
                <Checkbox
                  key={ `notes-${bulkPayload.length}` }
                  title="Notes"
                  value={ includes(bulkPayload, "notes") }
                  paramName="notes"
                  onChange={ handleCheckbox }
                />
              </div>
              <div className="concept-tags_checkbox_block">
                <Checkbox
                  key={ `stimuli-${bulkPayload.length}` }
                  title="Stimuli Type"
                  value={ includes(bulkPayload, "stimuli_type") }
                  paramName="stimuli_type"
                  onChange={ handleCheckbox }
                />
                <Checkbox
                  key={ `brand-${bulkPayload.length}` }
                  title="Brand"
                  value={ includes(bulkPayload, "express_brand") }
                  paramName="express_brand"
                  onChange={ handleCheckbox }
                />
              </div>
              <div className="concept-tags_checkbox_block">
                <Checkbox
                  key={ `product-${bulkPayload.length}` }
                  title="Product Type"
                  value={ includes(bulkPayload, "product_type") }
                  paramName="product_type"
                  onChange={ handleCheckbox }
                />
                <Checkbox
                  key={ `custom-${bulkPayload.length}` }
                  title="Custom Tags"
                  value={ includes(bulkPayload, "express_custom") }
                  paramName="express_custom"
                  onChange={ handleCheckbox }
                />
              </div>

              <button
                className={ cn("concept-tags_submit_button -separate", { '-disabled': bulkPayload.length === 0 }) }
                onClick={ onBulkUpdate }
                disabled={ bulkPayload.length === 0 }
              >
                Apply selected to all concepts below
              </button>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Concept;
