import React, { useState } from 'react';
import { find } from 'lodash';
import Notification from '../common/notification';
import { AppContext } from './contexts';
import EditStatus from './components/editStatus';
import EditSize from './components/editSize';
import EditQuotas from './components/editQuotas';
import EditPanelService from './components/editPanelService';
import Info from './components/info';
import { sendRequest } from './requests';

const App = ({ project, availableStatuses, paths }) => {
  const [ projectData, setProjectData ] = useState(project);
  const [ globalDisabled, setGlobalDisabled ] = useState(false);
  const [ notificationObject, setNotificationObject ] = useState(null);
  const { title, multilang, language_title  } = projectData;
  const handleSaveRequest = (path, params, goodCallback, badCallback) => {
    setGlobalDisabled(true);
    sendRequest(path, params, (response) => {
      setGlobalDisabled(false);
      if (response.response.ok) {
        goodCallback(response);
      } else {
        badCallback && badCallback(response);
      }
    }, (response) => {
      setGlobalDisabled(false);
      badCallback && badCallback(response);
    });
  };

  const baseValue = {
    project: projectData,
    setProject: (projects) => {
      const item = find(projects, (el) => el.id === projectData.id);
      setProjectData({ ...item });
    },
    globalDisabled, setGlobalDisabled,
    setNotificationObject,
    availableStatuses,
    sendRequest: handleSaveRequest,
    paths
  };

  return (
    <AppContext.Provider value={ baseValue }>
      <div className="content edit-survey-flow -grey-background">
        <div className="content_top -sticky -gray">
          <header className="header">
            <h1 className="header_title">
              Edit Survey Flow for
              {' '}
              {title}
              {' '}
              { multilang && <span>({language_title})</span> }
            </h1>
          </header>
        </div>
        <div className="content_body">
          {
            globalDisabled &&
            <div className="page-loader -inner-loader -transparent -fit-half-height" />
          }
          <Notification
            messageObject={ notificationObject }
            onDeactivate={ () => { setNotificationObject(null); } }
            interval={ 120 }
          />
          <Info />
          <EditSize />
          <EditQuotas />
          <EditStatus />
          <EditPanelService />
        </div>
      </div>
    </AppContext.Provider>
  );
};

export default App;
