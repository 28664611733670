import React from 'react';
import QuantPortal from './portal';
import Parahraph from './paragraph'
import classnames from 'classnames';

const renderParagraph = (parahraph, idx) => <Parahraph key={idx} paragraph={parahraph} />

const PlanDetailsDescription = ({ title, paragraphs, showOrBefore }) => (
  <div className={ classnames("plan-details", { '-or-before': showOrBefore }) }>
      <h6 className="plan-details-title">{title}</h6>
      <div className="hr -secondary" />
      {paragraphs.map(renderParagraph)}
  </div>
);

const renderPlanDetailsDesciption = ({ title, paragraphs, showOrBefore }, idx) =>
  <PlanDetailsDescription key={idx} title={title} paragraphs={paragraphs} showOrBefore={showOrBefore} />

const PlanDetailsModal = ({ open, handleClose, plan }) => {
  if (!open) return null;
  const { name, description } = plan;

  return <QuantPortal title={name} open={open} handleClose={handleClose}>
    {description.map(renderPlanDetailsDesciption)}
  </QuantPortal>
};

export default PlanDetailsModal;