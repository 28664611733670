import { addClass } from "../../../../common/dom_functions";

const steps = ({
  setForceOpenFilterDropDown, setForceOpenButton,
  openTourModal, closeTourModal, openTurfModal
}) => (
  () => {
    const openFilters = () => (setForceOpenFilterDropDown(true));
    const closeFilters = () => (setForceOpenFilterDropDown(false));
    const openButtonDropdown = (name) => {
      setForceOpenButton(name, true);
    };
    const closeButtonDropdown = (name) => {
      setForceOpenButton(name, false);
    };

    return [
      {
        id: 1,
        element: null,
        elementKey: "projectHeader",
        elementKlass: "tour-header",
        modal: false,
        pos: "bottom-left",
        parent: ".tour-parent-header",
        callback: null
      },
      {
        id: 2,
        element: ".-custom-filters-body .chosen-container",
        elementKey: null,
        modal: false,
        pos: "top-center",
        callback: () => {
          openFilters();
        }
      },
      {
        id: 3,
        elementKey: "projectComparisonExport",
        modal: false,
        pos: "left-top",
        parent: ".dropdown",
        callback: () => {
          closeFilters();
          openButtonDropdown('projectComparisonExport');
        }
      },
      {
        id: 4,
        elementKey: "projectFirstComparisonItem",
        elementChildren: true,
        modal: false,
        pos: "right-top",
        parent: "th",
        callback: () => {
          closeButtonDropdown('projectComparisonExport');
        }
      },
      {
        id: 5,
        elementKey: "projectModalTitle",
        modal: true,
        pos: "bottom-left",
        callback: () => {
          openTourModal();
        }
      },
      {
        id: 6,
        elementKey: "projectModalTable",
        modal: false,
        pos: "top-center",
        callback: null
      },
      {
        id: 7,
        elementKey: "projectModalTBLeft",
        modal: false,
        pos: "right-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 8,
        elementKey: "projectModalTBRight",
        modal: false,
        pos: "left-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 9,
        elementKey: "projectModalTBSwitch",
        modal: false,
        pos: "left-top",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
        }
      },
      {
        id: 10,
        elementKey: "projectTbExport",
        modal: false,
        pos: "left-top",
        parent: ".dropdown",
        callback: () => {
          addClass(
            ".concept-report_filter, .concept-report_tables, .comparisons_legend.-scorecard",
            "hide-in-tour-modal"
          );
          openButtonDropdown('projectTbExport');
        }
      },
      {
        id: 11,
        elementKey: "projectTURFButton",
        modal: false,
        pos: "bottom-center",
        callback: () => {
          closeTourModal();
        }
      },
      {
        id: 12,
        elementKey: "projectTURFModalCombinationsDropdown",
        modal: false,
        pos: "bottom-center",
        callback: () => {
          openTurfModal();
        }
      },
      {
        id: 13,
        elementKey: "projectTURFModalTable",
        modal: false,
        pos: "top-center",
        callback: null
      },
      {
        id: 14,
        elementKey: "projectTURFModalExport",
        modal: false,
        pos: "left-top",
        callback: () => {
          openButtonDropdown('projectTURFModalExport');
        }
      }
    ];
  }
);

export default steps;
