import React, { useContext } from "react";
import { DataContext } from "../data-context";

const EnableButton = () => {
  const { cartExercise, setCartExercise } = useContext(DataContext);

  const handleCartExerciseOnChange = (event) => {
    const value = event.target.checked;
    setCartExercise(value);
  };

  return (
    <>
      <div className="form_group -relative">
        <div className="form_section -section-relative">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>Shelf Cart exercise</h3>
            </div>
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
                </div>
                <div className="info-tooltip_drop -right">
                  <div className="info-tooltip_drop-inner">
                    In order to include a Shelf Shot Cart exercise,
                    {' '}
                    turn the exercise on and create shelf for each package.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form_section-title -grid">
          <div className="form_section-title-item -grid">
            <div className="toggle">
              <input
                className="hide"
                type="checkbox"
                id="include"
                checked={ cartExercise }
                onChange={ (e) => handleCartExerciseOnChange(e) }
              />
              <label className="form-switch" htmlFor="include" />
              <label className="form-switch-text" htmlFor="include">
                Include a Cart exercise
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="form_group -relative">
        {
          cartExercise &&
          <div className="shelf-text">
            Please upload one static shelf image for each
            { ' ' }
            package and select the product within the image.
            <br />
            If testing multiple packages, the other products on the shelf
            { ' ' }
            should be the same across all images.
          </div>
        }
        {
          cartExercise &&
          <>
            <div className="shelf-text">
              Shelf Image guidelines:
              <ul className="text -margin-bottom-10">
                <li>Recommended dimensions: 1000px X 1000px and higher</li>
                <li>Image orientation can be portrait or landscape</li>
                <li>Aspect ratio can be square</li>
                <li>In local language</li>
              </ul>
            </div>
          </>
        }
      </div>
    </>
  );
};

export default EnableButton;
