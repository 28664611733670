import { each, sumBy, values, map } from 'lodash';

const resultHash = {
  linesCount: 0,
  path: "",
  startedFrom: "",
  lines: {},
  get total(){ return sumBy(values(this.lines), (item) => item.planPrice || 0)  }
};

const setHashData = (path, value) => {
  const pathItems = path.split('.');
  let tempItem = resultHash;
  each(pathItems, (pathEl, index) => {
    if (index != pathItems.length - 1){
      if (!tempItem[pathEl]){ tempItem[pathEl] = {} }
      tempItem = tempItem[pathEl];
    }
    else{ tempItem[pathEl] = value; }
  });
};

const getHashData = (path) => {
  const pathItems = path.split('.');
  let tempItem = resultHash, result = null;
  each(pathItems, (pathEl, index) => {
    if (index == pathItems.length - 1){
      result = tempItem[pathEl];
      return false;
    }
    else if (!pathItems[pathEl]) {
      result = null;
      return false;
    }
    else {
      tempItem = pathItems[pathEl];
    }
  });
  return result;
};

let currentLine = 1;
const setCurrentLine = (val) => { currentLine = val; }, getCurrentLine = () => currentLine;

const getSetValue = () => getHashData("setValue");

const setSetValue = (value) => {
  setHashData('setValue', value);
  setCurrentLine(1);
};

const setLinesCount = (value) => {
  setHashData('linesCount', value);
  setCurrentLine(1);
};
const getLinesCount = () => getHashData('linesCount');

const setPlan = (plan, line, source, path) => {
  setHashData(`lines.${line}.planTitle`, plan.name);
  setHashData(`lines.${line}.planPrice`, plan.price);
  setHashData(`lines.${line}.planId`, plan.id);
  setHashData(`lines.${line}.planSource`, source || "List");
  setHashData("path", path);
};

const getSelectedPlans = () => {
  return map(values(resultHash.lines), (item) =>
    ({
      id: item.planId,
      title: item.planTitle,
      price: item.planPrice
    })
  );
}
const getTotal = () => resultHash.total;
const sendData = () => {
  const finalData = {
    ...resultHash,
    lines: values(resultHash.lines),
    set: getSetValue()
  };
  (window.parent || window).postMessage({
    kind: 'finishMetered',
    result: finalData
  }, "*");
};

export {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  setHashData, getHashData,
  setPlan,
  getSelectedPlans, getTotal,
  sendData,
  setSetValue
};