import React, { useContext, useEffect, useState } from "react";
import { baseContext } from "../contexts";
import PortalModal from "../common/portal";
import TextField from "../common/portal/textField";
import UploadButton from "../common/uploadButton";
import RadioSet from "../common/portal/RadioSet";
import { displayGroups, getGroupName } from "../helpers";
import { createProject, deleteProject, updateProject } from "./api";

const SurveyModal = ({ open, handleClose, clickedSurvey, actionAfterSave }) => {
  const {
    mediaKind, allGroups, verizonType, extensionsForImport, showPulseData
  } = useContext(baseContext);
  const [ deleteInProgress, setDeleteInProgress ] = useState(false);
  const [ saveInProgress, setSaveInProgress ] = useState(false);
  const [ title, setTitle ] = useState('');
  const [ date, setDate ] = useState('');
  const [ awsFilepath, setAwsFilepath ] = useState('');
  const [ awsSourceFilename, setAwsSourceFilename ] = useState('');
  const [ archived, setArchived ] = useState(false);

  const handleSetDate = (value) => {
    setTitle(value);
    setDate(value);
  };

  const handleDeleteItem = () => {
    if (
      clickedSurvey &&
      window.confirm(
        `Do you really want to delete "${clickedSurvey.overview_date}"?`
      )
    ) {
      setDeleteInProgress(true);
      deleteProject(clickedSurvey, () => {
        setDeleteInProgress(false);
        handleClose();
        actionAfterSave();
      });
    }
  };

  const handleUpload = (file) => {
    setAwsFilepath(file.awsKey);
    setAwsSourceFilename(file.filename);
  };

  const saveCallback = () => {
    setSaveInProgress(true);
    if (clickedSurvey) {
      const payload = {
        id: clickedSurvey.id,
        title,
        overview_date: date,
        archived,
        media_kind: mediaKind,
        aws_filepath: awsFilepath,
        source_filename: awsSourceFilename,
        verizon_type: verizonType
      };
      updateProject(payload, () => {
        setSaveInProgress(false);
        handleClose();
        setAwsFilepath('');
        setAwsSourceFilename('');
        actionAfterSave();
      });
    } else {
      const payload = {
        title,
        archived,
        overview_date: date,
        media_kind: mediaKind,
        aws_filepath: awsFilepath,
        source_filename: awsSourceFilename,
        verizon_type: verizonType
      };
      createProject(payload, () => {
        setSaveInProgress(false);
        handleClose();
        setAwsFilepath('');
        setAwsSourceFilename('');
        actionAfterSave();
      });
    }
  };

  const needsUpload = showPulseData ? (!clickedSurvey && !awsFilepath.length) : false;

  useEffect(() => {
    if (open) {
      setTitle(clickedSurvey ? clickedSurvey.overview_date : '');
      setDate(clickedSurvey ? clickedSurvey.overview_date : '');
      setArchived(clickedSurvey ? clickedSurvey.archived : false);
      setAwsFilepath('');
      setAwsSourceFilename('');
    }
  }, [ open ]);

  const allowedToUploadFile = showPulseData ? (
    !clickedSurvey ||
    (
      !clickedSurvey.selected &&
      (clickedSurvey.import_failed || clickedSurvey.imported)
    )
  ) : false;

  return (
    <PortalModal
      className="-sm"
      title={ clickedSurvey ? 'Edit survey' : 'New survey' }
      deleteItem={ handleDeleteItem }
      showDelete={ !!clickedSurvey }
      open={ open }
      handleClose={ handleClose }
      saveCallback={ saveCallback }
      disabledSave={ !title || !date || needsUpload || deleteInProgress || saveInProgress }
      labelDelete={ deleteInProgress ? 'Deleting...' : null }
      disabledDelete={
        (clickedSurvey && clickedSurvey.selected) || deleteInProgress || saveInProgress
      }
      buttonName={ saveInProgress ? 'Saving...' : null }
    >
      <div className="survey-form">
        <TextField label="Date" value={ date } onChange={ handleSetDate } />

        <RadioSet
          label="Display Group"
          value={ getGroupName(archived) }
          onChange={ (name) => { setArchived(name === displayGroups[1]); } }
          fields={ allGroups }
        />

        {
          allowedToUploadFile &&
          <div className="form-group">
            <div className="form-group_control -button">
              <UploadButton
                className="button -sm -upper"
                needPreview={ false }
                allowedTypes={ extensionsForImport }
                filenameOnBottom
                multiple={ false }
                onUploaded={ (files) => handleUpload(files[0]) }
                withId={ 'pulse-survey-upload' }
              >
                Upload file
              </UploadButton>
            </div>
          </div>
        }
      </div>
    </PortalModal>
  );
};

export default SurveyModal;
