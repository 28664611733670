import React, { useState, useEffect } from 'react';
import { map } from "lodash";
import { sendDisableNextButton, sendEnableNextButton } from '../common/services/angular_messaging';
import { globalContext } from './contexts';
import EnableButton from "./enable-button";
import DataWrapper from "./data-wrapper";


const Index = ({ init, data, projectId }) => {
  const [ videoAd, setVideoAd ] = useState(init);
  const [ globalLoadingNum, setGlobalLoadingNum ] = useState(0);
  const [ videosData, setVideosData ] = useState(
    map(data, (el) => ({ url: el.url, metaData: el.meta_data }))
  );

  const handleVideoAdChange = (event) => {
    const value = event.target.checked;
    setVideoAd(value);
  };

  useEffect(() => {
    if (videoAd && globalLoadingNum > 0) {
      sendDisableNextButton(projectId);
    } else {
      sendEnableNextButton(projectId);
    }
  }, [ globalLoadingNum, videoAd ]);

  const globalContextValue = {
    decreaseGlobalLoading: () => {
      setGlobalLoadingNum((oldVal) => {
        return oldVal ? oldVal - 1 : 0;
      });
    },
    increaseGlobalLoading: () => {
      setGlobalLoadingNum((oldVal) => {
        return oldVal + 1;
      });
    }
  };

  return (
    <globalContext.Provider value={ globalContextValue }>
      <div className="form">
        <input type="hidden" name="project[video_ad]" value={ videoAd } />
        <EnableButton videoAd={ videoAd } handleVideoAdChange={ handleVideoAdChange } />
        {
          videoAd &&
          <>
            <div className="products-greed -wrapper">
              <DataWrapper data={ data } videosData={ videosData } setVideosData={ setVideosData } />
            </div>
          </>
        }
      </div>
    </globalContext.Provider>
  );
};

export default Index;
