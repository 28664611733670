import React, { useRef, useState } from 'react';
import cn from "classnames";
import UploadButton from "../../../../common/components/upload_button";
import Http from "../../../../common/http";
import SvgIcon from "../../../../common/components/svg-icon";

const ImagesPdf = ({ mainText, projectId, step, className, onConverted, onDelete, error }) => {
  const [ converting, setConverting ] = useState(false);

  const refStep = useRef(step);
  refStep.current = step;

  const watchConvertStatus = (jid, sourceData) => {
    Http.get(
      `/express/api/transformer/${jid}`
    ).then(
      (response) => {
        if (response.data.status === 'failed') {
          setConverting(false);
        } else if (response.data.status === 'complete') {
          const dd = response.data.state.output;

          refStep.current.image_data = dd.image_data;
          refStep.current.image_src = dd.image_src;
          refStep.current.source_data = sourceData;
          refStep.current.s3_folder = dd.s3_folder;
          refStep.current.base_css_file_name = dd.base_css_file_name;
          refStep.current.unique_css_file_name = dd.unique_css_file_name;
          refStep.current.html_file_name = dd.html_file_name;
          refStep.current.prefix = dd.prefix;
          refStep.current.notes = dd.text;

          onConverted(refStep.current);

          setConverting(false);
        } else {
          setTimeout(() => {
            watchConvertStatus(jid, sourceData);
          }, 3000);
        }
      },
      () => {
        setConverting(false);
      }
    );
  };

  const onUploaded = (files) => {
    const [ file ] = files;
    const mimeType = file.origin.type;

    if (mimeType === "application/pdf") {
      setConverting(true);
      Http.post(
        '/express/api/transformer',
        { project_id: projectId, source_data: file['metaData'] }
      ).then(
        (response) => {
          watchConvertStatus(response.data.jid, file['metaData']);
        },
        () => {}
      );
    }
  };

  const handleDelete = () => {
    refStep.current.image_data = null;
    refStep.current.image_src = null;
    refStep.current.source_data = null;
    refStep.current.s3_folder = null;
    refStep.current.base_css_file_name = null;
    refStep.current.unique_css_file_name = null;
    refStep.current.html_file_name = null;
    refStep.current.prefix = null;
    refStep.current.notes = null;

    onDelete(refStep.current);
  };

  return (
    <div className={ cn("black-border", className, { 'has-error': !!error }) }>
      <div className="-middle">
        {!converting &&
          <UploadButton
            className="link form-concept_upload-btn"
            allowedTypes={ [ 'application/pdf' ] }
            onUploaded={ (newData) => { onUploaded(newData);} }
            needPreview
          >
            {refStep.current.image_src &&
              <img src={ refStep.current.image_src } />}

            {!refStep.current.image_src &&
              <>
                <div className="upload-text-main">{mainText}</div>
                <div className="upload-text-secondary">Click to upload</div>
              </>}
          </UploadButton>
        }
        {converting &&
          <button disabled type="button" className="upload-button-processing link" >
            Converting...
          </button>}
      </div>
      {!converting && refStep.current.image_src &&
        <div className="delete-btn" onClick={ handleDelete }>
          <SvgIcon name="i-delete" />
        </div>}
    </div>
  );
};

export default ImagesPdf;
