import Http from '../../../common/http';

const bulkUpdate = (payload, callback) => {
  Http.post('/verizon/bullet_points/bulk_update.json', payload).then(
    (response) => {
      callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const uploadFromPdf = (payload, callback) => {
  Http.post('/verizon/bullet_points/upload_from_pdf.json', payload).then(
    () => {
      callback();
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const iFramePreviewUrl = (htmlUrl, baseCssUrl, cssUrl) => (
  `${window.location.href}/preview_bullet_points?html_url=${htmlUrl}&css_urls[]=${baseCssUrl}&css_urls[]=${cssUrl}`
);

const iFrameResultUrl = (kind) => (
  `${window.location.href}/preview_bullet_points?kind=${kind}`
);

export { bulkUpdate, uploadFromPdf, iFrameResultUrl, iFramePreviewUrl };
