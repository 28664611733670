import { itemTitleByValue, calculateNewNSize } from '../surveyCompleteTarget/helper';

const generateConfirmationMessage = ({
  completeTargetData, statusName,
  newSizeValue, oldSizeValue,
  newTargetValue, oldTargetValue
}) => {
  const baseConfirmationMessage = 'Do you really want to';
  const basePartConfirmationItems = [];
  const notesPartConfirmation = [];
  if (newSizeValue !== oldSizeValue) {
    basePartConfirmationItems.push(`set ${newSizeValue} for target completes`);
    if (statusName === 'closed') {
      notesPartConfirmation.push(
        'Project will get "In Field" status, but it should be re-opened manually in the Panel Service.'
      );
    }
  }
  if (newTargetValue !== oldTargetValue) {
    const completeTargetTitle = itemTitleByValue(completeTargetData, newTargetValue);
    basePartConfirmationItems.push(
      `set "${completeTargetTitle}" for complete target`
    );
  }
  const basePartConfirmation = [
    `${baseConfirmationMessage} ${basePartConfirmationItems.join(' and ')}?`
  ];
  return [ ...basePartConfirmation, ...notesPartConfirmation ];
};

const generateSuccessMessage = ({
  completeTargetData, statusName, panelService,
  newSizeValue, oldSizeValue,
  newTargetValue, oldTargetValue
}) => {
  const closed = statusName === 'closed';
  const prodege = panelService === 'prodege';
  const baseMessage = 'Updated';
  const basePartMessageItems = [];
  const notesPartMessage = [];
  const recommendedNSize = calculateNewNSize(newTargetValue, completeTargetData, newSizeValue);
  const serviceName = prodege ? 'Prodege dashboard' : 'Custom Panel Service';
  if (newSizeValue !== oldSizeValue) {
    basePartMessageItems.push(`the target number to ${newSizeValue}`);
  }
  if (newTargetValue !== oldTargetValue) {
    const completeTargetTitle = itemTitleByValue(completeTargetData, newTargetValue);
    basePartMessageItems.push(`the complete target to "${completeTargetTitle}"`);
  }
  const baseIncreaseQuotasText = `change the quotas in the ${serviceName} to ${recommendedNSize}`;
  if (closed) {
    notesPartMessage.push(`Please re-open the project and ${baseIncreaseQuotasText}.`);
  } else {
    notesPartMessage.push(`Please ${baseIncreaseQuotasText}.`);
  }
  const basePartMessage = [
    `${baseMessage} ${basePartMessageItems.join(' and ')}.`
  ];
  return [ ...basePartMessage, ...notesPartMessage ].join(' ');
};

export { generateConfirmationMessage, generateSuccessMessage };
