import React from 'react';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import FooterButton from './footer_button';

const ItemView = ({ nextStep, headerText, children, startClassName }) => {
  return (
    <>
      {
        !!headerText &&
        <div className="survey-header">
          <div className="survey-header_title" { ...renderRawHtml(headerText) } />
        </div>
      }
      <div className="survey-layout_content -start">
        <div className={ classnames("survey-start -wide", startClassName) }>
          { children }
        </div>
      </div>
      <div className="survey-footer"><FooterButton nextStep={ nextStep } options={ {} } /></div>
    </>
  );
};

export default ItemView;
