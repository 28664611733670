import React, { Fragment } from 'react';
import { map } from "lodash";
import { getCurrentLine, getHashData, setHashData } from '../common/statistics';
import { Header } from "../common/header";

const Fork = ({ onPrebuildPlansSelect, onWelcomePlanSelect, getForkList }) => {
  const onExploreNonWelcomePlans = () => {
    const currentLine = getCurrentLine();
    const key = `lines.${currentLine}.planClickExploreNonWelcomePlansCount`;
    const value = getHashData(key);
    setHashData(key, value + 1);
    onPrebuildPlansSelect();
  };
  const onExploreWelcomePlans = () => {
    const currentLine = getCurrentLine();
    const key = `lines.${currentLine}.planClickExploreWelcomePlanCount`;
    const value = getHashData(key);
    setHashData(key, value + 1);
    onWelcomePlanSelect();
  };
  const forkList = getForkList(onExploreNonWelcomePlans, onExploreWelcomePlans);

  return (<div className="content">
    <Header />
    <div className="fork">
      <div className="inner-content -percent-desktop">
        <div className="fork_top">
          <h2 className="fork_title">Which type of plan are you looking for?</h2>
        </div>
        <ul className="fork_grid">
          {
            map(forkList, (fork, key) => (
              <Fragment key={ `fork-${key}` }>

                {key > 0 && (
                  <li className="fork_grid-item -separator">
                    <div className="fork_grid-or">or</div>
                  </li>
                )}

                <li className="fork_grid-item">
                  <div className="plate -border">
                    <div className="fork_item">
                      <h4 className="fork_item-title">{fork.title}</h4>
                      <div className="fork_item-descr">{fork.description}</div>
                      <ul className="fork_item-features">
                        {
                          map(fork.features, (feature, key) => (
                            <li className="fork_item-feature" key={ `fork-feature-${key}` }>
                              <div className="fork_item-feature-icon">
                                <svg className={ `icon -${feature.icon}` }>
                                  <use xlinkHref={ `#svg_${feature.icon}` } />
                                </svg>
                              </div>
                              <div>{feature.title}</div>
                            </li>)
                          )
                        }
                      </ul>
                      {fork.images && (
                        <ul className="fork_item-images">
                          {
                            map(fork.images, (image, key) => (
                              <li className="fork_item-image" key={ `fork_image-${key}` }>
                                <img src={ image } className="fork_item-image-src" />
                              </li>)
                            )
                          }
                        </ul>
                      )}
                      <div className="fork_item-bottom">
                        <button
                          className="button -bordered -full-width"
                          onClick={ () => fork.clickExplore() }
                        >
                          { fork.exploreButtonName }
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </Fragment>)
            )
          }
        </ul>
      </div>
    </div>
  </div>);
};

export default Fork;
