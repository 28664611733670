import React, { useContext } from 'react';
import { map, includes, join, isArray } from "lodash";
import { baseContext } from "../context";
import { reviewRespondent, banRespondent } from "../requests";

const ButtonStatus = ({
  color = 'success', children,
  respondentState, onClick, autoBanned,
  payload, respondentId
}) => {
  const { currentFilter, setData, projectId, gaTrackingId } = useContext(baseContext);

  const handleClick = (e) => {
    e.preventDefault();
    onClick(respondentId, projectId, currentFilter.id, setData, payload, gaTrackingId);
  };

  return (
    <button
      className={
        `button -${color} 
        ${respondentState ? '-not-clickable' : '-bordered'}
        ${autoBanned && respondentState ? '-lighter' : ''}`
      }
      onClick={ handleClick }
    >{children}
      {
        autoBanned && respondentState &&
        <span className="button_icon-letter">A</span>
      }
    </button>
  );
};

const ReviewRespondent = ({ respondent, rows }) => {
  const bannedBySwear = includes([ 0, 3 ], respondent.banned);
  const bannedByGarbage = includes([ 1, 4 ], respondent.banned);
  const autoBanned = includes([ 3, 4 ], respondent.banned);

  const respondentState = () => {
    switch (true) {
      case respondent.banned !== null:
        return 'blocked';
      case respondent.reviewed:
        return 'reviewed';
    }
    return 'all';
  };

  const prepareText = (text) => {
    return isArray(text) ? join(text, "\n") : text;
  };

  const nullifyCheck = (item) => {
    if (isArray(item)) {
      return !item.some((el) => !!el);
    }
    return item === null;
  };

  return (
    <div className={ `review-respondents_plate -${respondentState()}` }>
      {
        respondent.paste_detected &&
        <div className="paste-detected">Passed Captcha after copy/paste action.</div>
      }
      <div className="review-respondents_plate-body">
        <ul className="review-respondents_openends-list">
          {map(rows, (text, key) => (
            <li className="review-respondents_openends-item" key={ `openends-${key}` }>
              <div className="review-respondents_openends-title">
                {text}
              </div>
              <div className="review-respondents_openends-text">
                {nullifyCheck(respondent[key]) ? '-' : prepareText(respondent[key])}
              </div>
            </li>
          ))
          }
        </ul>
      </div>
      <div className="review-respondents_plate-footer">
        <div className="review-respondents_plate-footer-item">
          <ButtonStatus
            name="reviewed"
            color="success"
            respondentId={ respondent.resp_id }
            respondentState={ respondent.reviewed }
            onClick={ reviewRespondent }
            payload={ {} }
          >
            { respondent.reviewed ? "Reviewed" : "Mark as Reviewed"}
          </ButtonStatus>
        </div>
        <div className="review-respondents_plate-footer-item -separator-left">
          <div className="review-respondents_plate-footer-text">
            Block because of
          </div>
        </div>
        <div className="review-respondents_plate-footer-item">
          <ButtonStatus
            name="swear"
            color="warning"
            respondentId={ respondent.resp_id }
            respondentState={ bannedBySwear }
            autoBanned={ autoBanned }
            onClick={ banRespondent }
            payload={ { ban_reason: "swear_words" } }
          >
            Swear Words
          </ButtonStatus>
        </div>
        <div className="review-respondents_plate-footer-item">
          <ButtonStatus
            name="garbage"
            color="warning"
            respondentId={ respondent.resp_id }
            respondentState={ bannedByGarbage }
            autoBanned={ autoBanned }
            onClick={ banRespondent }
            payload={ { ban_reason: "garbage_words" } }
          >
            Garbage
          </ButtonStatus>
        </div>
        <div className="review-respondents_plate-footer-item -space" />
        <div className="review-respondents_plate-footer-item">
          <div className="review-respondents_plate-footer-label">
            { respondent.resp_id }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewRespondent;
