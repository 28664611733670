import React from 'react';
import remoteFiles from '../../../../../common/remote_files';

const Section1 = () => {
  const {
    heroScreen12x, heroScreen22x, heroTablet2x
  } = remoteFiles.express.landing;
  const tabletBackground = {
    style: { backgroundImage: `url(${heroTablet2x})` }
  };
  return (
    <section className="hero">
      <div className="hero_bg">
        <svg className="icon -logo-bg hero_bg-image">
          <use xlinkHref="#svg_logo-bg" />
        </svg>
      </div>
      <div className="container">
        <div className="hero_content">
          <h1 className="hero_title">
            Ready.
            <br />
            Set.
            <br />
            <div className="hero_title_colored">Grow.</div>
          </h1>
          <div className="hero_images">
            <div className="hero_image -back">
              <div className="hero_image-tablet" { ...tabletBackground }>
                <div
                  className="hero_image-screen"
                  style={ { backgroundImage: `url(${heroScreen12x})` } }
                />
              </div>
            </div>
            <div className="hero_image -front">
              <div className="hero_image-tablet" { ...tabletBackground }>
                <div
                  className="hero_image-screen"
                  style={ { backgroundImage: `url(${heroScreen22x})` } }
                />
              </div>
            </div>
          </div>
          <p className="hero_text">
            Consumers spend 80% of their time on mobile devices.
            { ' ' }
            Your idea testing needs to change.
            { ' ' }
            Now there’s a better way to test & learn.
            { ' ' }
            Easy. Intuitive & Fast.
          </p>
          <div className="hero_button-wrapper">
            <button className="button -md hero_button">
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
