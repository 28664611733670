import React from "react";
import { filter } from "lodash";
import cn from "classnames";
import InfoToolTip from '../../common/infoToolTip';
import AnswerAdditionalLine from "../common/AnswerAdditionalLine";
import EditableGridItems from "../common/EditableGridItems";
import Checkbox from "../../common/Checkbox";
import DefaultCheckboxes from "./DefaultCheckboxes";

const GridWithAdditional = ({ label, hint, model, setModel, max, placeholder, countAll, showOther, otherSpecify }) => {
  const handleOnChangeEnabled = (newBool) => {
    setModel({ ...model, enabled: newBool });
  };

  const handleOnChangeGridItem = (newBool, index) => {
    model.grid_items[index].enabled = newBool;
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleOnDeleteEditableGridItem = (index) => {
    if (model.grid_items[index].id) {
      model.grid_items[index]._destroy = '1';
    } else {
      model.grid_items.splice(index, 1);
    }
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleOnChangeEditableGridItem = (newText, index) => {
    model.grid_items[index].question = newText;
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleOnAddEditableGridItem = (newText) => {
    model.grid_items.push({
      id: null,
      question: newText,
      enabled: true,
      editable: true
    });

    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleChangeOther = (newBool, newText) => {
    setModel({ ...model, other_answer: newBool, other_answer_text: newText });
  };

  const itemsCount = filter(model.grid_items, (item) => {
    return (item.enabled && !item._destroy && !!countAll) || (item.enabled && item.editable && !item._destroy);
  }).length;
  const showAddNewItem = itemsCount < max;

  return (
    <div className={ cn("form_metrics-item", model.default_metric_name) }>
      <Checkbox label={ label } bool={ model.enabled } onChange={ handleOnChangeEnabled } />
      <InfoToolTip>{hint}</InfoToolTip>
      {model.enabled &&
      <div className="form_metrics -inner">
        <DefaultCheckboxes gridItems={ model.grid_items } onChange={ handleOnChangeGridItem } />
        <ul className="form-question_answers">
          <EditableGridItems
            gridItems={ model.grid_items }
            onChange={ handleOnChangeEditableGridItem }
            onDelete={ handleOnDeleteEditableGridItem }
            showAddNewItem={ showAddNewItem }
            newItemPlaceholder={ placeholder(max - itemsCount) }
            onAdd={ handleOnAddEditableGridItem }
          />
          {showOther &&
          <AnswerAdditionalLine
            title={ otherSpecify }
            bool={ model.other_answer }
            text={ model.other_answer_text }
            onChange={ handleChangeOther }
          />}
        </ul>
      </div>}
    </div>
  );
};

export default GridWithAdditional;
