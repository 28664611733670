import React, { useEffect, useState } from 'react';
import { filter, concat } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';
import { selectStylesMulti } from "../helpers";

const TagsDropdown = ({ tagType, parentId, tags, selectedOptions, setSelectedOptions, disabled }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  useEffect(() => {
    if (selectedOptions) {
      setIsLoading(false);
    }
  }, [ selectedOptions ]);
  const exists = (data) => filter(data, (item) => !item.value);

  const cleared = (options) => (!exists(options).length);

  const handleSelect = (data, selected) => {
    setIsLoading(true);
    switch (selected.action) {
      case 'clear':
        setSelectedOptions([]);
        break;
      case "remove-value":
        setSelectedOptions(filter(selectedOptions, (item) => item.value !== selected.removedValue.value));
        break;
      case "select-option":
        setSelectedOptions(concat(selectedOptions, [ selected.option ]));
        break;
      default:
        console.log(`Unsupported action: ${selected.action}`);
    }
  };

  const createOption = (label) => ({
    id: label,
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    tag_type: tagType,
    parent_id: parentId || null
  });

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setSelectedOptions((prev) => [ ...prev, newOption ]);
    }, 1000);
  };

  const onKeyDown = (e) => {
    // catch the code of the key pressed
    if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => `${option.label}`,
    trim: true
  };

  return (
    <div className="react-select-wrapper">
      <CreatableSelect
        className="react-select-container"
        classNamePrefix="react-select"
        options={ tags }
        placeholder="Select or Create"
        value={ selectedOptions }
        onChange={ handleSelect }
        onCreateOption={ handleCreate }
        isLoading={ isLoading }
        isDisabled={ disabled }
        isSearchable
        isMulti
        isClearable={ cleared(selectedOptions) }
        styles={ selectStylesMulti() }
        autosize={ false }
        maxMenuHeight={ 150 }
        filterOption={ createFilter(filterConfig) }
        onKeyDown={ onKeyDown }
      />
    </div>
  );
};

export default TagsDropdown;
