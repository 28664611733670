import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import { each } from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import { BaseContext } from '../contexts';
import SignIn from './sign_in';
import SignUp from './sign_up';
import BlockedAccount from './blocked_account';
import ForgotPassword from './forgot_password';
import SolutionsHeaderItem from './sections/solutions_header_item';

const Header = () => {
  const {
    activated, signIn, blockedEmail, getSectionRefs,
    headerRef, getSignUpOpen, setSignUpOpen,
    setScrollTop, currentScrollTop, projectsAllowance
  } = useContext(BaseContext);
  const [ activatedState, setActivatedState ] = useState(activated);
  const [ signInOpen, setSignInOpen ] = useState(signIn);
  const [ restorePasswordOpen, setRestorePasswordOpen ] = useState(false);
  const [ highlighting, setHighlighting ] = useState({});
  const [ showMobileMenu, setShowMobileMenu ] = useState(false);

  const sectionRefs = getSectionRefs();
  useEffect(() => {
    smoothscroll.polyfill(); // For animated scrolls in safari and others
    const newHighlighting = {};
    each(
      [ 'solutions', 'benefits', 'testimonials' ],
      (item) => {
        newHighlighting[item] = (sectionRefs[item] || {}).highlight;
      });
    setHighlighting(newHighlighting);
  }, [ sectionRefs ]);

  const signUpOpen = getSignUpOpen();
  const handleSignInOpen = () => {
    setShowMobileMenu(false);
    setSignInOpen(true);
  };
  const handleRestorePasswordClick = () => {
    setSignInOpen(false);
    setRestorePasswordOpen(true);
  };
  const handleSignInClose = () => {
    setSignInOpen(false);
    setActivatedState(false);
  };
  const handleSignUpOpen = () => {
    setShowMobileMenu(false);
    setSignUpOpen(true);
  };
  const handleSignUpClose = () => {
    setSignUpOpen(false);
  };
  const handleRestorePasswordClose = () => {
    setRestorePasswordOpen(false);
  };

  const onMenuClickHandle = (event, kind) => {
    event.preventDefault();
    setShowMobileMenu(false);
    const headerObj = headerRef.current.getBoundingClientRect();
    const currentTop = currentScrollTop();

    setScrollTop(0);
    const sectionObj = sectionRefs[kind].el.getBoundingClientRect();
    setScrollTop(currentTop);

    // In Safari sometimes it doesn't switch to benefits,
    // so 1 more pixel is needed for headerObj.height
    const newTop = sectionObj.top - (headerObj.height - 1);
    if (newTop !== currentTop) {
      window.scroll({
        top: newTop,
        behavior: 'smooth'
      });
    }
  };

  const onSolutionsHandle = (event) => {
    onMenuClickHandle(event, 'solutions');
  };

  const handleMobileMenuClick = (event) => {
    event.preventDefault();
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <div className="wrapper_item" ref={ headerRef }>
        <header
          className={ classnames("header -sticky js-header-expandable", { '-expanded': showMobileMenu }) }
        >
          <div className="container">
            <div className="header_grid">
              <div className="header_item -grow">
                <a className="logo" href="/">
                  <svg className="icon -logo">
                    <use xlinkHref="#svg_logo" />
                  </svg>
                </a>
              </div>
              <div className="header_item -page-menu">
                <ul className="menu -header -primary js-header-menu">
                  <SolutionsHeaderItem
                    projectsAllowance={ projectsAllowance }
                    className={ classnames("menu_link dropdown_toggle js-menu-link -propogate", { "-active": highlighting.solutions }) }
                    onClick={ onSolutionsHandle }
                  />
                  <li className="menu_item">
                    <a
                      className={ classnames("menu_link js-menu-link", { "-active": highlighting.benefits }) }
                      onClick={ (event) => { onMenuClickHandle(event, 'benefits'); } }
                    >Key Benefits
                    </a>
                  </li>
                  <li className="menu_item">
                    <a
                      className={ classnames("menu_link js-menu-link", { "-active": highlighting.testimonials }) }
                      onClick={ (event) => { onMenuClickHandle(event, 'testimonials'); } }
                    >Testimonials
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header_item -account-menu">
                <button
                  className="button -transparent header_button"
                  onClick={ handleSignInOpen }
                >
                  Log In
                </button>
                <button
                  className="button header_button"
                  onClick={ handleSignUpOpen }
                >
                  Sign Up
                </button>
              </div>
              <div className="header_item -toggle">
                <div className="header_toggle" onClick={ handleMobileMenuClick }>
                  <div className="header_toggle-icon" />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="header_placeholder" />
      </div>
      {
        signInOpen &&
        <SignIn
          activated={ activatedState }
          onRestorePasswordClick={ handleRestorePasswordClick }
          onClose={ handleSignInClose }
        />
      }
      {
        signUpOpen &&
        <SignUp onClose={ handleSignUpClose } />
      }
      {
        !!blockedEmail &&
        <BlockedAccount email={ blockedEmail } />
      }
      {
        restorePasswordOpen &&
        <ForgotPassword
          onClose={ handleRestorePasswordClose }
        />
      }
    </>
  );
};

export default Header;
