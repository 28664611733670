import AppleMusicUrl from "../../../../../../../images/quant/apple-music.png";

let linesCount = 1;
export const setLinesCount = count => { linesCount = count; };
const prices = [
  [70, 85, 0, 95],
  [60, 75, 0, 85],
  [45, 60, 0, 70],
  [35, 50, 0, 60],
  [30, 45, 0, 55]
];

const getPriceById = (id) => prices[linesCount - 1][id - 1];

const startUnlimitedBase = {
  id: 1,
  name: 'Start Unlimited',
  shortDescription: 'An unlimited plan with the essentials.',
  perkCount: 0,
};

const playMoreUnlimitedBase = {
  id: 2,
  name: 'Play More Unlimited',
  shortDescription: 'A plan with data and more.',
  perkCount: 1,
};

const getMoreUnlimitedBase = {
  id: 4,
  name: 'Get More Unlimited',
  shortDescription: 'Unlimited with data and entertainment',
  perkCount: 2,
}

export const planList = [
  startUnlimitedBase,
  playMoreUnlimitedBase,
  getMoreUnlimitedBase
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

export const planIds = planList.map(({ id }) => id);

export const plansIncludes = [
  'Unlimited talk, text, & data',
  'Mexico & Canada talk, text, & data',
  'International texting',
];

const startUnlimitedShort = {
  ...startUnlimitedBase,
  description: [
    ['5G features', ['5G Nationwide']],
    ['Plan features', ['4G LTE', 'Call Filter spam blocker']],
    ['Plan rewards', ['Verizon Up']],
    ['Plan perks', [
      'Disney+ 6 months on us',
      'Apple Arcade or Google Play Pass\n6 months on us',
      'discovery+ 6 months on us',
      'Apple Music 6 months on us'
    ]],
  ]
};

const playMoreUnlimitedShort = {
  ...playMoreUnlimitedBase,
  description: [
    ['5G total access', [
      '5G Ultra Wideband',
      '5G Nationwide'
    ]],
    ['Plan features', [
      'Premium Network Access',
      '4G LTE',
      'Unlimited mobile hotspot\n(25 GB high-speed data)',
      'Call Filter Plus spam blocker',
    ]],
    ['Plan rewards', [
      'Verizon Up',
      '$5 Verizon Dollars per month'
    ]],
    ['Plan perks', [
      {
        icon: 'Disney_Bundle',
        text: 'Disney+, Hulu, ESPN+ included'
      },
      {
        icon: 'Apple_Arcade_Google_Play_Pass',
        text: 'Apple Arcade or Google Play Pass included'
      },
      'discovery+ 6 months on us',
      'Apple Music 6 months on us'
    ]],
  ]
};

const getMoreUnlimitedShort = {
  ...getMoreUnlimitedBase,
  description: [
    ['5G total access', [
      '5G Ultra Wideband',
      '5G Nationwide'
    ]],
    ['Plan features', [
      'Unlimited Premium Network Access',
      '4G LTE',
      'Unlimited mobile hotspot\n(50 GB high-speed data)',
      'Call Filter Plus spam blocker',
      '600 GB of Verizon Cloud storage'
    ]],
    ['Plan rewards', [
      'Verizon Up',
      '$10 Verizon Dollars per month',
    ]],
    ['Plan perks', [
      {
        icon: 'Disney_Bundle',
        text: 'Disney+, Hulu, ESPN+ included'
      },
      {
        icon: 'Apple_Arcade_Google_Play_Pass',
        text: 'Apple Arcade or Google Play Pass included'
      },
      'discovery+ 12 months on us',
      'Apple Music 6 months on us',
    ]],
  ]
};

export const planDetailsShort = [
  startUnlimitedShort,
  playMoreUnlimitedShort,
  getMoreUnlimitedShort
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

const planShortDetailsDictionary = planDetailsShort.reduce((dictionary, element) => ({
  ...dictionary,
  [element.id]: element
}), Object.create(null));

export const getShortPlanDescription = (id) => planShortDetailsDictionary[id] || null;

export const allPlansInclude = {
  title: 'All plans include',
  description: [
    [
      'Unlimited talk, text, & data',
      'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
    ],
    [
      'Mexico & Canada talk, text, & data',
      'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.'
    ],
    [
      'International texting',
      'Unlimited texting from the US to over 200 countries and territories worldwide.'
    ],
  ]
};

const startUnlimitedLarge = {
  ...startUnlimitedBase,
  description: [
    { title: '5G features',
      articles: [
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        }
      ]
    },
    {
      title: 'Plan features',
      articles: [
        {
          title: '4G LTE',
          text: 'Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic.'
        },
        {
          title: 'Call Filter spam blocker',
          text: 'Call Filter is a service that helps you take control of your calls and gives you the tools to manage those calls as you choose. You can screen incoming calls, block (send directly to voicemail) spam numbers by risk-level, and report numbers as spam. Numbers considered risky will be labeled as potential spam.'
        },
        {
          title: 'Unlimited talk, text, & data',
          text: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
        },
        {
          title: 'Mexico & Canada talk, text, & data',
          text: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.',
        },
        {
          title: 'International texting',
          text: 'Unlimited texting from the US to over 200 countries and territories worldwide.​​'
        }
      ]
    },
    {
      title: 'Plan rewards',
      articles: [
        {
          title: 'Verizon Up',
          text: 'With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.​'
        }
      ]
    },
    {
      title: 'Plan perks',
      articles: [
        {
          title: 'Disney+ 6 months on us',
          text: 'All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic, including The Mandalorian, The Avengers, Mulan, and Hamilton. First 6 months on us, then $7.99/mo + taxes after. One subscription can be shared with everyone on your account. Cancel anytime. (For NM residents, Disney+ ends automatically after 6 mos. Add\'l terms apply. One offer per eligible Verizon account.)',
          icon: 'Disney'
        },
        {
          title: 'Apple Arcade or Google Play Pass 6 months on us',
          text: 'Choice between:\n• Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 6 months on us, then $4.99/month + taxes after.\nOR\n• Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 6 months on us, then $4.99/month + taxes after.',
          icon: 'Apple_Arcade_Google_Play_Pass'
        },
        {
          title: 'discovery+ 6 months on us',
          text: 'discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti.  First 6 months on us, then $6.99/month + taxes after.',
          icon: 'discovery'
        },
        {
          title: 'Apple Music 6 months on us',
          iconPath: AppleMusicUrl,
          text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
          iconClassName: '-apple-music'
        }
      ]
    }
  ]
}

const playMoreUnlimitedLarge = {
  ...playMoreUnlimitedBase,
  description: [
    {
      title: '5G total access',
      articles: [
        {
          title: '5G Ultra Wideband',
          text: 'Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today\'s 4G  networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance.\nIncludes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. <strong>5G Ultra Wideband available only in parts of select cities;</strong>\nrequires a 5G Ultra Wideband-capable device.'
        },
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        }
      ]
    },
    {
      title: 'Plan features',
      articles: [
        {
          title: 'Premium Network Access',
          text: 'Get access to 100 GB of 5G Nationwide / 4G LTE  premium  data  per month. 5G access requires a 5G-capable device. Plus  720p HD streaming when you activate it in your plan settings.  In times of congestion, your data may be temporarily slower than other traffic after exceeding 100 GB/mo/line.'
        },
        {
          title: '4G LTE',
          text: 'Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic.'
        },
        {
          title: 'Unlimited mobile hotspot (25 GB high-speed data)',
          text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 25 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.'
        },
        {
          title: 'Call Filter Plus spam blocker',
          text: 'Call Filter Plus is a service that helps you take control of your calls and gives you the tools to manage those calls as you choose. You can screen your incoming calls for spam, block spam calls and more. Plus you can see the caller’s Name for unknown numbers; Create your custom block list so that calls from specific, unwanted numbers are automatically forwarded to voicemail. Create your custom spam list so you can be alerted when specific numbers attempt to call you in the future. View the risk-level of incoming calls: potential spam, robocaller or fraud. Use Spam lookup to search our spam database to see if a number has already been identified as spam. $3/mo value.'
        },
        {
          title: 'Unlimited talk, text, & data',
          text: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
        },
        {
          title: 'Mexico & Canada talk, text, & data',
          text: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.',
        },
        {
          title: 'International texting',
          text: 'Unlimited texting from the US to over 200 countries and territories worldwide.​​'
        }
      ]
    },
    {
      title: 'Plan rewards',
      articles: [
        {
          title: 'Verizon Up',
          text: 'With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.​'
        },
        {
          title: '$5 Verizon Dollars per month',
          text: 'Get money back into your Verizon Dollar bank which you can use toward any device purchase. More Verizon Dollars can be banked through Verizon Up rewards, if you subscribe to Verizon home Internet services and with the Verizon Visa Card. Verizon Dollars for Verizon Visa cardholders can be used toward any Verizon purchase, including to pay your bill, and even other benefits like travel.'
        }
      ]
    },
    {
      title: 'Plan perks',
      articles: [
        {
          title: 'Disney+, Hulu, ESPN+ included',
          text: 'Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places.   One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.',
          icon: 'Disney_Bundle'
        },
        {
          title: 'Apple Arcade or Google Play Pass included',
          text: 'Choice between:\n• Apple Arcade subscription included. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. A $4.99/month value.\nOR\n• Google Play Pass subscription included along with Play Points Gold Status. Your pass to hundreds of games and apps in one subscription. Available on Android. A $4.99/month value.',
          icon: 'Apple_Arcade_Google_Play_Pass'
        },
        {
          title: 'discovery+ 6 months on us',
          text: 'discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti.  First 6 months on us, then $6.99/month + taxes after.',
          icon: 'discovery'
        },
        {
          title: 'Apple Music 6 months on us',
          iconPath: AppleMusicUrl,
          text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
          iconClassName: '-apple-music'
        },

      ]
    }
  ]
};

const getMoreUnlimitedLarge = {
  ...getMoreUnlimitedBase,
  description: [
    {
      title: '5G total access',
      articles: [
        {
          title: '5G Ultra Wideband',
          text: 'Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today\'s 4G  networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance.\nIncludes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. <strong>5G Ultra Wideband available only in parts of select cities;</strong>\nrequires a 5G Ultra Wideband-capable device.'
        },
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        }
      ]
    },
    {
      title: 'Plan features',
      articles: [
        {
          title: 'Unlimited Premium Network Access',
          text: 'Get access to unlimited 5G Nationwide / 4G LTE  premium  data  per month. 5G access requires a 5G-capable device. Plus  720p HD streaming when you activate it in your plan settings.'
        },
        {
          title: '4G LTE',
          text: 'Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic.'
        },
        {
          title: 'Unlimited mobile hotspot (50 GB high-speed data)',
          text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 50 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.'
        },
        {
          title: 'Call Filter Plus spam blocker',
          text: 'Call Filter Plus is a service that helps you take control of your calls and gives you the tools to manage those calls as you choose. You can screen your incoming calls for spam, block spam calls and more. Plus you can see the caller’s Name for unknown numbers; Create your custom block list so that calls from specific, unwanted numbers are automatically forwarded to voicemail. Create your custom spam list so you can be alerted when specific numbers attempt to call you in the future. View the risk-level of incoming calls: potential spam, robocaller or fraud. Use Spam lookup to search our spam database to see if a number has already been identified as spam. $3/mo value.'
        },
        {
          title: '600 GB of Verizon Cloud storage​',
          text: 'Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.'
        },
        {
          title: 'Unlimited talk, text, & data',
          text: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
        },
        {
          title: 'Mexico & Canada talk, text, & data',
          text: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.',
        },
        {
          title: 'International texting',
          text: 'Unlimited texting from the US to over 200 countries and territories worldwide.​​'
        }
      ]
    },
    {
      title: 'Plan rewards',
      articles: [
        {
          title: 'Verizon Up',
          text: 'With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.​'
        },
        {
          title: '$10 Verizon Dollars per month',
          text: 'Get money back into your Verizon Dollar bank which you can use toward any device purchase. More Verizon Dollars can be banked through Verizon Up rewards, if you subscribe to Verizon home Internet services and with the Verizon Visa Card. Verizon Dollars for Verizon Visa cardholders can be used toward any Verizon purchase, including to pay your bill, and even other benefits like travel.'
        },
      ]
    },
    {
      title: 'Plan perks',
      articles: [
        {
          title: 'Disney+, Hulu, ESPN+ included',
          text: 'Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places. One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.',
          icon: 'Disney_Bundle'
        },
        {
          title: 'Apple Arcade or Google Play Pass included',
          text: 'Choice between:\n• Apple Arcade subscription included. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. A $4.99/month value.\nOR\n• Google Play Pass subscription included along with Play Points Gold Status. Your pass to hundreds of games and apps in one subscription. Available on Android. A $4.99/month value.',
          icon: 'Apple_Arcade_Google_Play_Pass'
        },
        {
          title: 'discovery+ 12 months on us',
          text: 'discovery+ gives you instant, unlimited access to more than 55,000 episodes of 2,500+ current and classic shows from the most beloved TV brands including HGTV, Food Network, TLC, ID, Animal Planet, Discovery Channel - and personalities, plus exclusive originals, all in one place. Shows include Property Brothers, Diners, Drive-Ins and Dives, 90 Day Fiance, Serengeti.  First 12 months on us, then $6.99/month + taxes after.',
          icon: 'discovery'
        },
        {
          title: 'Apple Music 6 months on us',
          iconPath: AppleMusicUrl,
          text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
          iconClassName: '-apple-music'
        }
      ]
    }
  ]
};

export const planDetailsLarge = [
  startUnlimitedLarge,
  playMoreUnlimitedLarge,
  getMoreUnlimitedLarge,
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

const planLargeDetailsDictionary = planDetailsLarge.reduce((dictionary, element) =>
  ({
    ...dictionary,
    [element.id]: element,
  }), Object.create(null));

export const getLargePlanDescription = (id) => planLargeDetailsDictionary[id] || null;


const moreHotspotPerk = {
  id: 1,
  title: 'More hotspot',
  articles: [
    {
      title: '25 GB additional mobile hotspot data',
      text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 25 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.'
    }
  ]
};

const planDiscountPerk = {
  id: 2,
  title: 'Plan discount',
  articles: [
    {
      title: '50% off a plan for a watch, tablet, Hum, or hotspot',
      text: 'Get 50% off an Unlimited connected device plan. (not the hardware). Up to $15/month value. (Jetpack is a separate device that lets you share your Verizon wireless network connection with other devices. Hum turns almost any car into a smarter, safer, more connected car; check your vehicle health, get 24/7 pinpoint road assistance, and create a WiFi connection from your car.)'
    }
  ]
};

const musicPerk = {
  id: 3,
  title: 'Music',
  articles: [
    {
      title: 'Apple Music included',
      text: 'Apple Music subscription; allows you to listen to 70 million songs ad-free, or download your favorite tracks and play them offline. A $9.99/month value. Each line on an eligible plan gets a separate subscription.',
      iconPath: AppleMusicUrl,
      iconClassName: '-apple-music -perk'
    }
  ]
};

const safeFamilyPerk = {
  id: 4,
  title: 'Safe family',
  articles: [
    {
      title: 'Smart Family Premium (parental controls)',
      text: 'Use it to choose your child\'s contacts, set content filters to block inappropriate content, limit access to certain applications, track your child’s location, and control screen time. A $9.99/month value.'
    },
    {
      title: 'Digital Secure included',
      text: 'Digital Secure is an all-in-one suite of privacy and security tools for your mobile devices and home computers. Safeguard your internet connection and personal data with anti-virus, anti-malware and identity theft protection. App download is required. $2.50 / line or $5 /account.'
    }
  ]
};

const globalPerk = {
  id: 5,
  title: 'Global',
  articles: [
    {
      title: '1 TravelPass day per month',
      text: 'TravelPass lets you use your domestic talk, text and data in more than 185 countries outside the US for a set daily fee. You can call within the country you\'re visiting and you can call back to the US. One Verizon TravelPass (good for one day) is included each month; you can store them in the "bank" and use them when you travel. Each TravelPass expires 12 months from issue date.'
    },
    {
      title: 'Global Choice international calling',
      text: 'Select 1 of 26 countries and get up to 180 minutes per month included in your plan allowance. Countries include China, Brazil, Dominican Republic, Japan, Pakistan and more. After your allowance, you’ll get discounted rates to your selected country. Plus, you’ll get discounted rates to 220+ other countries.'
    }
  ]
};

const storagePerk = {
  id: 6,
  title: 'Storage',
  articles: [
    {
      title: '2 TB of Verizon Cloud storage',
      text: 'Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. Up to 5 users. Unlimited devices. 2 TB of Cloud storage. $15 /mo value.'
    }
  ]
};

export const perkList = [
  moreHotspotPerk,
  planDiscountPerk,
  musicPerk,
  safeFamilyPerk,
  globalPerk,
  storagePerk,
];

export const perkIds = perkList.map(({ id }) => id);