import React from "react";
import cn from "classnames";

const IconButton = ({ children, align = "center", className, ...props }) => {
  return (
    <div className={cn("icon-button_wrapper", `-${align}`)}>
      <div className={cn("icon-button", className)} {...props}>
        {children}
      </div>
    </div>
  );
};

export default IconButton;
