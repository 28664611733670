import React, { useState } from 'react';
import Header from "../../common/header";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import WithSubmit from "../../common/WithSubmit";
import { errorsText } from "../common/helpers";
import HiddenFields from "./HiddenFields";
import BrandType from "./BrandType";

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    brandName: data.brand_name,
    brandNameCategory: data.brand_name_category,
    brandNameType: data.brand_name_type,
    errors
  });

  return (
    <WithSubmit formId="config_form" errors={ errors }>
      <HiddenFields options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header title={ options.project_title } />
        <div className="content_body">
          <div className="form">
            <FieldHeaderWithText
              id="project_brand_name"
              title="Target Brand"
              hint="Respondents will evaluate this brand throughout the study. List the brand as you would like it to appear to respondents."
              placeholder="Enter target brand"
              errors={ errorsText(errors, 'brand_name') }
              initValue={ model.brandName }
              onChange={ (v) => { setModel({ ...model, brandName: v }); } }
            />

            <FieldHeaderWithText
              id="project_brand_name_category"
              title="What category best describes this brand? "
              hint="Respondents must be category users to qualify. Describe the category as you would like it to appear to respondents."
              placeholder="Enter target brand category"
              errors={ errorsText(errors, 'brand_name_category') }
              initValue={ model.brandNameCategory }
              onChange={ (v) => { setModel({ ...model, brandNameCategory: v }); } }
            />

            <BrandType
              initValue={ model.brandNameType }
              onChange={ (v) => { setModel({ ...model, brandNameType: v }); } }
            />
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
