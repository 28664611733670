import React from 'react';
import Select from "react-select";

const CountriesDropdown = ({ onUpdate, selectedOptions, setSelectedOptions, countriesList, disabled }) => {
  const handleSelect = (data) => {
    setSelectedOptions([ data ]);
    onUpdate(data);
  };

  const selectStyles = {
    control: (styles) => ({ ...styles, borderRadius: 20, fontSize: "14px" }),
    option: (styles, { isSelected, isFocused }) => (
      { ...styles, fontSize: "14px", color: "#1F2542", backgroundColor: isSelected || isFocused ? "#DADADA" : "#FFF" }
    ),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, fontSize: "14px", zIndex: 9999, color: "#787F93" }),
    multiValue: (styles, { data }) => (
      {
        ...styles, fontSize: "14px", borderRadius: 70, color: "#787F93",
        background: data.value === 0 ? "#FFF" : "#EFF4F5",
        paddingRight: data.value === 0 ? "5px" : 0
      }
    ),
    multiValueLabel: (styles) => ({ ...styles, fontSize: "14px", color: "#787F93" }),
    valueContainer: (styles) => ({ ...styles, fontSize: "14px", paddingLeft: "12px" }),
    multiValueRemove: (styles, state) => {
      return state.data.value === 0 ? { ...styles, display: 'none' } : styles;
    }
    // indicatorSeparator: (styles) => ({ ...styles, display: 'none' })
  };

  return (
    <div className="react-select-wrapper -countries-dropdown">
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={ countriesList() }
        value={ selectedOptions }
        onChange={ handleSelect }
        isSearchable
        styles={ selectStyles }
        isDisabled={ disabled }
        menuPortalTarget={ document.body }
        menuPosition={ 'fixed' }
      />
    </div>
  );
};

export default CountriesDropdown;
