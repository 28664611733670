import React, { useContext, useRef, useState } from 'react';
import { isEmpty, map, find, keys } from "lodash";
import { baseContext } from "../context";
import Http from "../../../../common/http";
import Settings from "./settings";
import Metrics from "./metrics";
import ConceptsTable from "./concepts-table";
import ConceptsTableLegend from "./concepts-table-legend";
import CopyLink from './copy-link';

const Index = () => {
  const {
    projectsPath,
    conceptGroup, conceptsData, conceptView, data,
    currentCountry, currentProjectType, currentProductCategory,
    metricTitles, metricData
  } = useContext(baseContext);

  const canExport = !!currentCountry && !!currentProjectType && !!currentProductCategory;

  const [ inProgress, setInProgress ] = useState(false);
  const downloadLink = useRef(null);

  const interval = 5000;

  const startExport = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const url = `/express/api/concepts_database/${data.params_id}/report`;

    Http.post(url).then(
      (http) => {
        if (http.response.status === 200) {
          setTimeout(() => {
            getUserDownloads(http.data.id);
          }, interval);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const getUserDownloads = (udId) => {
    const url = '/dashboard/user_downloads.json';

    Http.get(url).then(
      (http) => {
        if (http.response.status === 200) {
          const ud = find(http.data.user_downloads, (el) => el.id === udId);
          if (ud && ud.status !== 'finished') {
            setTimeout(() => {
              getUserDownloads(udId);
            }, interval);
          } else if (ud) {
            setInProgress(false);
            downloadLink.current.setAttribute("href", ud.s3_filename);
            downloadLink.current.click();
          } else {
            setInProgress(false);
          }
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const exportReport = (event) => {
    setInProgress(true);
    startExport(event);
  };

  return (
    <div className="concept-tags">
      <div className="concept-tags_content -body-scroll -with-bottom-padding -with-right-limits">
        <div className="concepts-database_header">
          <div className="concept-tags_header-title -no-max-width">
            <h1>
              Concepts Database
              <CopyLink />
            </h1>
            <a className="concepts-database_link" href={ projectsPath }>
              Go Back to All Projects
            </a>
          </div>
          <a ref={ downloadLink } href="" style={ { display: "none" } } />
          <button
            className="button concept-tags_header-button -last"
            type="button"
            disabled={ !canExport || inProgress }
            onClick={ (event) => { exportReport(event); } }
          >
            { inProgress && "Processing..." }
            { !inProgress && "Export" }
          </button>
        </div>
        <Settings />
        {
          (conceptGroup === "included" || conceptGroup === "custom") && !isEmpty(metricData) &&
          (map(keys(metricTitles), (title) => (
            <Metrics
              key={ `metrics-${title}` }
              title={ title }
            />
          )))
        }
        <ConceptsTable />
        {
          (!isEmpty(conceptsData) && conceptView === "data") &&
          <>
            <ConceptsTableLegend />
          </>
        }
      </div>
    </div>
  );
};

export default Index;
