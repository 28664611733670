import React, { createContext } from 'react';

const IndexData = createContext({
  projects: [],
  setProjects: null,
  clients: [],
  setClients: null,
  sort: 'created_at',
  setSort: null,
  dir: 'desc',
  setDir: null,
  loadProjects: null
});

const BaseContext = createContext({});

export { IndexData, BaseContext };
