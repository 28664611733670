import React from "react";
import Screening from '../../common/components/screening';
import ThoughtBubble from "../../common/components/metrics/thought_bubble";

const BrandAware = ({ question, nextStep, result, translations }) => {
  const maleSelected = () => (result.answers.findIndex("S3_1") > -1);

  if (question.default_name === 'thought_bubble') {
    return (
      <ThoughtBubble
        concept={ question.concept }
        tbKlass={ question.concept.klass }
        title={ translations.thought_bubble_title }
        description={ translations.thought_bubble_description }
        showMan={ maleSelected() }
        result={ result }
        nextStep={ () => { nextStep(); } }
      />);
  }

  return (
    <Screening
      question={ question }
      nextStep={ nextStep }
      result={ result.loops[0] }
    />
  );
};

export default BrandAware;
