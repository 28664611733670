import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { map, keys } from 'lodash';
import renderRawHtml from '../../../../common/render_raw_html.js';
import LangPic from '../../../../../../images/survey/language_preselection.png';

const Index = ({ data }) => {
  const [ buttonDisabled, updateButtonDisabled ] = useState(true);
  const [ lang, setLang ] = useState(null);
  const questionText = lang ?
    data[lang].question :
    data[keys(data)[0]].question;

  const updateRadio = (value) => {
    setLang(value);
  };

  const radioChange = (event) => {
    updateRadio(event.target.value);
  };

  const handleNext = () => {
    window.location.href = data[lang].url;
  };

  useEffect(() => {
    updateButtonDisabled(!lang);
  }, [ lang ]);

  return (
    <>
      <section className="survey-layout">
        <div className="survey-layout_content -start">
          <div className="survey-start">
            <div className="express-steps_item-img">
              <img src={ LangPic } alt="" />
            </div>
            <h3 className="survey-start_title" { ...renderRawHtml(questionText) } />
            <div
              className={
                classnames(
                  "survey-layout_container",
                  "-one-question",
                  "-grid"
                )
              }
            >
              <div className="survey-options">
                <ul className="survey-options_list">
                  {
                    map(data, (value, key) => (
                      <li key={ key }>
                        <label className="radio">
                          <input
                            type="radio"
                            name={ 'text' }
                            value={ key }
                            onChange={ radioChange }
                          />
                          <div
                            className="radio_label"
                            { ...renderRawHtml(value.option) }
                          />
                        </label>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <button
              className="button survey-start_button"
              type="button"
              disabled={ buttonDisabled }
              onClick={ handleNext }
            >
              <svg className="svg-next_icon">
                <use xlinkHref="#svg_long-arrow" />
              </svg>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
