const baseDefaultState = {
  loading: true,
  step: 0,
  qIndex: 0
};

const baseState = (state = baseDefaultState, action) => {
  switch (action.type) {
    case "UPDATE_LOADING":
      state.loading = action.value;
      return { ...state };
    case "NEXT_STEP":
      state.step += 1;
      return { ...state };
    case "UPDATE_STEP":
      state.step = action.value;
      return { ...state };
    case "UPDATE_QUESTION_INDEX":
      state.qIndex = action.value;
      return { ...state };
    default:
      return state;
  }
};

export default baseState;
