import React from 'react';
import FiosTV from './fios-tv';
import TVConnections from './tv-connections';
import HowToConnect from './how-to-connect';
import { useAccordionState } from './hooks';

const ConceptB = () => {
  const { 
    open: { fiosTV, tvConnections, howToConnect },
    data: { fiosTVData, tvConnectionsData }, 
    handleOpen
  } = useAccordionState();

  const catFioTVOpen = true;

  const canTVConnectionsOpen = !!fiosTVData;

  const canHowToConnectOpen = canTVConnectionsOpen && !!tvConnectionsData

  return <div className="inner-accordion-list">
    <FiosTV
      open={fiosTV}
      canOpen={catFioTVOpen}
      onChange={() => handleOpen('fiosTV')}
      onNext={() => handleOpen('tvConnections')} />
    <TVConnections
      open={tvConnections}
      canOpen={canTVConnectionsOpen}
      onChange={() => handleOpen('tvConnections')}
      onNext={() => handleOpen('howToConnect')} />
    <HowToConnect
      canOpen={canHowToConnectOpen}
      open={howToConnect}
      canOpen={!!(fiosTVData && tvConnectionsData) || howToConnect}
      onChange={() => handleOpen('howToConnect')} />
  </div>;
}

export default ConceptB;