import React, { useContext, useRef, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import classnames from 'classnames';
import { IndexData } from "../../common/index-context";
import TopFilter from "../../common/filters/app";
import ClaimsExplanation from "../../common/components/claims-explanation";
import ClaimsLegend from "../../common/components/claims-legend";
import SummaryPanel from "../../common/summary-panel/index-project";
import TabCustom from "../../brand_snapshot/components/tab-custom";
import Tabs from "../../brand_snapshot/components/tabs";
import { comparisonReportTourClick } from "../../../common/google-analytics/events";
import ExpressLanguageSwitcher from '../../common/components/express-language-switcher';
import ConceptsTable from "./concepts-table/index";

const Comparisons = () => {
  const {
    init, data, loadData, loading,
    getForceOpenFilterDropDown, getTourUseEffectArgs
  } = useContext(IndexData);

  const baseQuestion = () => {
    return data.questions && data.questions.claims_groups_desktop;
  };

  const forceDropDown = getForceOpenFilterDropDown();

  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs(
    "projectHeader",
    tourElement
  );
  useEffect(callback, condition);

  const showCustom = !!data.survey_metrics?.length;
  const { search } = useLocation();
  const [ tab, setTab ] = useState('');

  useEffect(() => {
    const initShowCustom = true;
    const query = new URLSearchParams(search);
    let initTab = query.get('tab') || 'base';

    if (!initShowCustom && initTab === 'custom') {
      initTab = 'base';
    }

    setTab(initTab);
  }, []);

  const onTourClick = () => {
    comparisonReportTourClick(init.google_analytics_id, { project_id: init.project.id });
  };

  return (
    <div className="content_body">
      <div className="comparisons">
        <div
          className="comparisons_intro-text"
          ref={ tourElement }
        >
          Create reports easily. You can compare claims performance against each other.
          <br />
          Click to view
          {' '}
          <a className="link" href={ init.urls.tour } onClick={ onTourClick }>
            the examples of reporting pages
          </a>
          {' '}
          you can create.
        </div>
        <TopFilter
          scorecard
          data={ data }
          loadData={ loadData }
          forceDropDown={ forceDropDown }
        />

        <SummaryPanel />
        {
          showCustom &&
          <div className="comparisons_metrics_tabs">
            <Tabs
              tab={ tab }
              setTab={ setTab }
              showCustom={ showCustom }
              baseTitle={ 'base' }
              survey
            />
          </div>
        }
        { tab === 'base' && <ClaimsExplanation n={ data.respondents_count } singular="claim" /> }

        <div
          className={
            classnames(
              "comparisons_table table-wrap -concept-comparison -claims -relative",
              { '-viewport': tab === "base" }
            )
          }
        >
          {
            (tab === "custom" && data.has_survey_open_metrics) &&
            <div className="comparisons_tabs">
              <ExpressLanguageSwitcher
                description={ data.translations.language_switcher_description }
                showLanguageSwitcher={ data.show_language_switcher }
              />
            </div>
          }
          { loading && <div className="page-loader -inner-loader" /> }
          { tab === "base" && <ConceptsTable /> }
          { tab === "custom" && <TabCustom data={ data } conceptType /> }
        </div>

        { tab === "base" && <ClaimsLegend q={ baseQuestion() } singular="claim" plural="claims" /> }
      </div>
    </div>
  );
};

export default Comparisons;
