import React, {useState} from 'react';
import Routes from './routes';
import { setLinesCount, planIds } from './plansData';
import Statistic from '../../statistic';
import { RouteContext } from './contexts';

const Index = () => {
  const [path, setPath] = useState('/');
  const [isDone, setDone] = useState(false);
  const onLinesCountChange = (linesCount) => {
    Statistic.create(linesCount, planIds);
    setLinesCount(linesCount);
  };
  const handleDone = () => {
    Statistic.send();
    setDone(true);
  };
  return !isDone
      ? <RouteContext.Provider value={{ path, setPath }}>
        <Routes onLinesCountChange={onLinesCountChange} onDone={handleDone} />
      </RouteContext.Provider>
      : null;
};

export default Index;
