import React, { useEffect, useState, useRef, useContext } from 'react';
import actionCable from 'actioncable';
import { Mutex } from 'async-mutex';
import { map } from "lodash";
import { baseContext } from "../contexts";
import { withPrefix } from '../helpers';
import Post from "./post";
import Editor from "./editor";
import { loadPosts } from "./api";

const mutex = new Mutex();

const Whiteboard = () => {
  const { mediaKind, getCurrentUser, verizonType } = useContext(baseContext);
  const user = getCurrentUser();
  const [ inProgress, setInProgress ] = useState(false);
  const [ moreInProgress, setMoreInProgress ] = useState(false);
  const [ posts, setPosts ] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ hasMore, setHasMore ] = useState(false);
  const [ , setCable ] = useState(null);
  const [ , setChannel ] = useState(null);

  const postsRef = useRef();
  postsRef.current = posts;

  const localLoadPosts = (newPage, callback) => {
    setHasMore(false);
    loadPosts(mediaKind, verizonType, newPage, (data) => {
      const { items, has_more } = data;
      const newPosts = newPage === 1 ? [] : [ ...posts ];

      items.forEach((el) => {
        const i = posts.findIndex((subEl) => (subEl.id === el.id));
        if (i === -1) {
          newPosts.push(el);
        }
      });

      setPosts(newPosts);

      setPage(newPage);
      setHasMore(has_more);
      callback && callback();
    });
  };

  const addPostToState = (addedPost, parentId) => {
    mutex
      .acquire()
      .then((release) => {
        if (!parentId) {
          setPosts([ addedPost, ...postsRef.current ]);
        } else {
          const newPosts = [ ...postsRef.current ];
          map(newPosts, (post) => {
            if (post.id === parentId) {
              post.replies.push(addedPost);
            }
            return post;
          });
          setPosts(newPosts);
        }

        release();
      });
  };

  const createSubscription = () => {
    const cb = actionCable.createConsumer();
    setCable(cb);
    const channel = cb.subscriptions.create(
      { channel: "WhiteboardChannel" },
      {
        connected: () => { console.log('WhiteboardChannel connected');},
        disconnected: () => {
          let subscription;
          setChannel((oldChannel) => {
            subscription = oldChannel;
            return subscription;
          });
          subscription.unsubscribe();
          console.log('Connection was lost. If you don\'t see last changes, please, refresh page.');
        },
        received: (data) => {
          if (data.post.user.id !== user.id && data.post.media_kind === mediaKind) {
            addPostToState(data.post, data.parent_id);
          }
        }
      },
    );
    setChannel(channel);
    return channel;
  };

  useEffect(() => {
    setInProgress(true);
    localLoadPosts(1, () => {
      setInProgress(false);
    });
    const channel = createSubscription();
    return () => {
      channel.unsubscribe();
    };
  }, []);

  const handleLoadMore = (event) => {
    event.preventDefault();
    setMoreInProgress(true);
    localLoadPosts(page + 1, () => {
      setMoreInProgress(false);
    });
  };

  return (
    <>
      { inProgress && <div className="iframe-loader" /> }
      {
        !inProgress &&
        <div className="whiteboard section">
          <div className="section_top">
            <h4 className="section_title">
              {withPrefix(verizonType, mediaKind)} Team Whiteboard
            </h4>
          </div>
          <Editor parentId={ null } addPostToState={ addPostToState } />
          {
            map(posts, (post) => (
              <Post
                key={ post.id }
                post={ post }
                isReply={ false }
                addPostToState={ addPostToState }
              />
            ))
          }
          {
            moreInProgress &&
            <div className="whiteboard_load-more">
              <div className="iframe-loader -inside-area" />
            </div>
          }
          {
            hasMore && !moreInProgress &&
            <div className="whiteboard_load-more">
              <button
                className="button -sm -upper"
                onClick={ handleLoadMore }
              >
                Get older messages
              </button>
            </div>
          }
        </div>
      }
    </>
  );
};

export default Whiteboard;
