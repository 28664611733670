import React, { useState, useEffect, useContext } from 'react';
import actionCable from 'actioncable';
import { cloneDeep, filter } from 'lodash';
import classnames from 'classnames';
import { FrameWrapper } from '../../../common/components/frame_wrapper';
import PortalModal from '../common/portal';
import { withPrefix } from '../helpers';
import { baseContext, QualitativeContext } from '../contexts';
import render_raw_html from '../../../common/render_raw_html';
import EditButton from '../common/edit_button';
import ArticleEdit from './edit';
import { bulkUpdate, iFrameResultUrl } from './api';

const ArticleEditModal = ({
  content,
  setTopic,
  setContent,
  setMode,
  type,
  handleClose,
  kind,
  setPdfUploaded
}) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ contentItemsDynamic, setContentItemsDynamic ] = useState([]);
  const [ tempTopic, setTempTopic ] = useState('');
  const [ tempEditMode, setTempEditMode ] = useState('manual');
  const [ uploads, setUploads ] = useState({});
  const [ uploadingInProgress, setUploadingInProgress ] = useState(false);
  const [ , setCable ] = useState(null);
  const [ , setChannel ] = useState(null);
  const [ saving, setSaving ] = useState(false);

  const saveCallback = () => {
    const payload = {
      media_kind: mediaKind,
      bullet_points: contentItemsDynamic,
      kind
    };

    payload[`${kind}_title`] = tempTopic;
    payload[`${kind}_mode`] = tempEditMode;
    payload['verizon_type'] = verizonType;

    if (tempEditMode !== 'manual') {
      setPdfUploaded(false);
      payload['html_upload'] = uploads.html_upload.id;
      payload['base_css_upload'] = uploads.base_css_upload.id;
      payload['css_upload'] = uploads.css_upload.id;
    }
    setSaving(true);

    bulkUpdate(payload, (data) => {
      setContent(filter(data.bullet_points, (el) => el.kind === kind));
      if (setTopic) {
        setTopic(data[`${kind}_title`]);
      }
      if (tempEditMode !== 'manual') {
        setPdfUploaded(true);
      }
      setMode(data[`${kind}_mode`]);
      setSaving(false);
      handleClose();
    });
  };

  const createSubscription = () => {
    const cable = actionCable.createConsumer();
    setCable(cable);
    const channel = cable.subscriptions.create(
      { channel: 'UploadsChannel' },
      {
        connected: () => {
          console.log('UploadsChannel connected');
        },
        disconnected: () => {
          let subscription;
          setChannel((oldChannel) => {
            subscription = oldChannel;
            return subscription;
          });
          subscription.unsubscribe();
          console.log("Connection was lost. If you don't see last changes, please, refresh page.");
        },
        received: (data) => {
          setUploads(data.items);
        }
      }
    );
    setChannel(channel);

    return channel;
  };

  useEffect(() => {
    setContentItemsDynamic(cloneDeep(content.list));
    setTempTopic(content.title);
    setTempEditMode(content.mode);
    setUploads({});
    setUploadingInProgress(false);

    const channel = createSubscription();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  const pdfNotUploaded = tempEditMode !== 'manual' && (!uploads.html_upload || uploadingInProgress);

  return (
    <PortalModal
      title={ content.title }
      open
      handleClose={ handleClose }
      saveCallback={ saveCallback }
      buttonName={ saving ? "Saving..." : "Save" }
      disabledSave={ pdfNotUploaded || saving }
    >
      <ArticleEdit
        type={ type }
        title={ tempTopic }
        content={ contentItemsDynamic }
        setTopic={ setTempTopic }
        editMode={ tempEditMode }
        setEditMode={ setTempEditMode }
        setContent={ setContentItemsDynamic }
        kind={ kind }
        uploads={ uploads }
        setUploads={ setUploads }
        uploadingInProgress={ uploadingInProgress }
        setUploadingInProgress={ setUploadingInProgress }
      />
    </PortalModal>
  );
};

const Article = ({
  editable,
  type,
  content,
  setContent,
  kind,
  setTopic,
  setMode,
  setPdfUploaded,
  showButton,
  onButtonClick
}) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const { fontClass } = useContext(QualitativeContext);
  const [ openModal, setOpenModal ] = useState(false);
  const isManualMode = content.mode === 'manual';

  const editHandle = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="section">
      <div className="section_top">
        <h4 className={ classnames("section_title", fontClass) }>
          {withPrefix(verizonType, mediaKind, content.title)}
        </h4>
        {
          editable &&
          <>
            <EditButton onEdit={ editHandle } />
            {
              openModal &&
              <ArticleEditModal
                content={ content }
                setTopic={ setTopic }
                setContent={ setContent }
                setMode={ setMode }
                type={ type }
                kind={ kind }
                buttonName="Done"
                handleClose={ handleClose }
                setPdfUploaded={ setPdfUploaded }
              />
            }
          </>
        }
      </div>
      <div className="section_body">
        <div className="article">
          {type === 'list' && <h4 className="article_title">Top Notable Insights</h4>}
          <div className="article_content">
            {type === 'list' && isManualMode && content.list && (
              <ol>
                {content.list.map((value, index) => {
                  return (
                    <li key={ `li-${index}` }>
                      <span { ...render_raw_html(value.html) } />
                    </li>
                  );
                })}
              </ol>
            )}

            {type === 'grid' && isManualMode && content.list && (
              <div className="article_grid">
                {content.list.map((value, index) => {
                  return (
                    <div className="article_grid-col" key={ `li-${index}` }>
                      <div
                        className="article_grid-col-title"
                        { ...render_raw_html(value.title) }
                      />
                      <div
                        className="article_grid-col-text"
                        { ...render_raw_html(value.html) }
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {!isManualMode && content.pdfUploaded && (
              <FrameWrapper src={ iFrameResultUrl(kind) } />
            )}
          </div>
        </div>
      </div>
      {
        showButton &&
        <div className="section_footer">
          <a href="#" className="button -sm -bordered" onClick={ onButtonClick }>
            Learn More
          </a>
        </div>
      }
    </div>
  );
};

export default Article;
