import React from 'react';

const DiagnosticsText = () => {
  return (
    <>
      <div className="form_section-title -grid">
        <div className="form_section-title-item">
          <h3>Diagnostics</h3>
        </div>
      </div>
      <div className="form_section-text -margin-bottom">
        These unaided questions will provide additional insight into associations with the Target Brand.
      </div>
    </>
  );
};

export default DiagnosticsText;
