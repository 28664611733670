import React, { useContext, useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { baseContext } from "../context";
import { selectStylesSingle } from "../helpers";

const SelectDropdown = ({ placeholder, onSelectOption, options, selectedOpts }) => {
  const [ selectedOptions, setSelectedOptions ] = useState(selectedOpts);
  const [ isLoading, setIsLoading ] = useState(false);
  const { setGlobalLoading } = useContext(baseContext);

  useEffect(() => {
    if (selectedOpts) {
      setSelectedOptions(selectedOpts);
      setIsLoading(false);
    }
  }, [ selectedOpts ]);

  const handleSelect = (data, selected) => {
    setGlobalLoading(true);
    switch (selected.action) {
      case "remove-value":
        onSelectOption(null);
        break;
      case "select-option":
        setSelectedOptions(data);
        onSelectOption(data);
        break;
      default:
        console.log(`Unsupported action: ${selected.action}`);
    }
  };

  const onKeyDown = (e) => {
    // catch the code of the key pressed
    if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => `${option.label}`,
    trim: true
  };

  return (
    <div className="react-select-wrapper">
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={ options }
        placeholder={ placeholder }
        value={ selectedOptions }
        onChange={ handleSelect }
        isLoading={ isLoading }
        // isDisabled={ disabled }
        isSearchable
        styles={ selectStylesSingle() }
        autosize={ false }
        maxMenuHeight={ 150 }
        filterOption={ createFilter(filterConfig) }
        onKeyDown={ onKeyDown }
        components={ { IndicatorSeparator: () => null } }
        getOptionValue={ (option) => option.label }
      />
    </div>
  );
};

export default SelectDropdown;
