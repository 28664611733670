import React, { useContext, useEffect, useRef } from 'react';
import { map, toPairs } from "lodash";
import { IndexData } from "../../common/index-context";
import SvgIcon from "../../../../common/components/svg-icon";

const NPS = ({ data, n  }) => {
  const { getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs('NPS', tourElement);
  useEffect(callback, condition);

  const legend = {
    promoters: "Promoters (9-10)",
    passives: "Passives (7-8)",
    detractors: "Detractors (0-6)"
  };

  return (
    <div className="report-plate_col" ref={ tourElement }>
      <div className="report-plate">
        <div className="nps_graph">

          {data && (
            <>
              <div className="nps_title">
                {data.title}: {data.percent}% &nbsp;
                <div className="info-tooltip">
                  <div className="info-tooltip_control">
                    <SvgIcon name="i-info" />
                  </div>

                  <div className="info-tooltip_drop">
                    <div className="info-tooltip_drop-inner">
                      NPS stands for Net Promoter Score. This is a standard score used for determining your customer&apos;s likelihood to recommend a brand. It is used to measure satisfaction with the Brand experience.
                    </div>
                  </div>
                </div>
              </div>

              <div className="nps_data">
                <ul className="nps_data-list">
                  {map(toPairs(legend), ([ key ], index) => (
                    (data[key].percent > 0) && (
                      <li key={ `${index}` } className={ `nps_data-item nps_color -${key}` }  style={ { 'height': `${data[key].percent}%` } }>
                        <div className={ `nps_data-value ${data[key].percent < 10 ? '-small' : ''}` }>{data[key].percent}%</div>
                      </li>
                    )
                  ))}
                </ul>
              </div>

              <div className="nps_hint">
                <div className="nps_hint-title"> Likelihood to Recommend</div>
                <div className="nps_hint-text">among Brand Users (n={n})</div>
              </div>

              <div className="nps_legend">
                <ul className="nps_legend-list">
                  {map(toPairs(legend), ([ key, value ], index) => (
                    <li className="nps_legend-item" key={ `${index}` }>
                      <div className={ `nps_legend-circle nps_color -${key}` } />
                      <div className={ `nps_legend-value` }>{value}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NPS;
