import React, { useContext, useEffect, useState } from "react";
import { remove, each, filter, cloneDeep } from 'lodash';
import { flushSync } from 'react-dom';
import Modal from "../../../../../common/components/modal";
import ProductsColumn from  "../products-column/index";
import { DataContext } from "../../data-context";
import UploadButton from "../../../../../common/components/upload_button";
import ImageWithMarkups from '../../../common/image_with_markups';
import Confirm from "./Confirm";

const ShelfEditor = ({ closeModal }) => {
  const {
    currentShelfIndex, setCurrentShelfIndex,
    shelves, setShelves, syncCoords,
    getAvailableProducts, getProductName,
    getShelfPlaces, deleteAllPlaces,
    createDragDropForShelf
  } = useContext(DataContext);
  const shelf = shelves[currentShelfIndex];

  const [ coords, setCoords ] = useState(() => (getShelfPlaces()));
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ metaData, setMetaData ] = useState({});
  const [ forceReinit, setForceReinit ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const onError = () => {
    setLoading(false);
  };
  const onInitUploading = () => {
    setLoading(true);
  };
  const onUploaded = (files) => {
    const [ file ] = files;
    const { cacheSrc: url, metaData } = file;
    setMetaData({ url, metaData });
    setLoading(false);
    setForceReinit({});
  };

  const syncCoordsAndCloseModal = (event) => {
    syncCoords(coords);
    closeModal(event);
  };

  const handleDeleteAllPlaces = () => {
    deleteAllPlaces();
    setCoords([]);
    setForceReinit({});
  };

  useEffect(() => {
    if (metaData.url) {
      shelf.shelf_image_data = metaData.metaData;
      shelf.shelf_image_src = metaData.url;
      setShelves([ ...shelves ]);
    }
  }, [ metaData ]);

  const handleUpdateSelection = (area, index) => {
    setCoords((oldCoords) => {
      const currentCoords = oldCoords[index] || {};
      oldCoords[index] = { ...currentCoords, ...area };
      return [ ...oldCoords ];
    });
  };

  const handleAddSelection = (area, product) => {
    let tempShelfIndex;
    setCurrentShelfIndex((oldVal) => {
      tempShelfIndex = oldVal;
      return oldVal;
    });
    let tempShelves;
    flushSync(() => {
      setShelves((oldVal) => {
        tempShelves = oldVal;
        return oldVal;
      });
    });
    const products = getAvailableProducts(tempShelves[tempShelfIndex]);
    const productIndex = products.findIndex(product);
    setCoords((oldCoords) => {
      return [
        ...oldCoords,
        ...[ { ...area, ...{ productIndex } } ]
      ];
    });
    setForceReinit({});
  };

  const handleDeleteSelection = (index) => {
    setCoords((oldCoords) => {
      remove(oldCoords, (coord) => coord === oldCoords[index]);
      return [ ...oldCoords ];
    });
    setForceReinit({});
  };

  const handleGetProductName = (area) => (getProductName(shelf, area));

  const handleDeleteProduct = (productIndex) => {
    setCoords((oldCoords) => {
      const areas = cloneDeep(
        filter(oldCoords, (area) => (area.productIndex !== productIndex))
      );
      each(
        filter(areas, (el) => el.productIndex > productIndex),
        (el) => {
          el.productIndex -= 1;
        }
      );
      return [ ...areas ];
    });
    setForceReinit({});
  };

  return (
    <>
      <Modal
        modal
        modalSize="lg"
        className="modal-shelf-editor"
        modalClassName="-full-screen -with-inner-scrollbar"
      >
        <div className="left-panel">
          <ProductsColumn coords={ coords } onDelete={ handleDeleteProduct } />
        </div>
        <div className="right-panel">
          <div className="right-top">
            {
              shelf.shelf_image_src &&
              <UploadButton
                className="button"
                allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                onInit={ onInitUploading }
                onUploaded={ onUploaded }
                onError={ onError }
              >
                <span>{ shelf.shelf_image_src ? 'Update' : 'Upload' } Shelf</span>
              </UploadButton>
            }

            <button
              className="button -clean -warning"
              onClick={ () => { setShowConfirm(true); } }
              disabled={ !coords.length }
            >
              Clean Shelf
            </button>

            <button
              className="button -done"
              onClick={ syncCoordsAndCloseModal }
              disabled={ loading }
            >
              Done
            </button>
          </div>
          <div  className="right-main">
            {
              !!shelf.shelf_image_src &&
              <ImageWithMarkups
                rectClassName="-rounded"
                pointClassName="-rounded"
                rectHoverClassName="-on-hover"
                src={ shelf.shelf_image_src }
                onSelectionChange={ handleUpdateSelection }
                onSelectionAdd={ handleAddSelection }
                initialAreas={ coords }
                forceReinit={ forceReinit }
                getProductName={ handleGetProductName }
                withDelete
                onDelete={ handleDeleteSelection }
                imageUseEffectFunction={ createDragDropForShelf }
              />
            }
            {
              !shelf.shelf_image_src &&
              <UploadButton
                className="hint"
                allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                onInit={ onInitUploading }
                onUploaded={ onUploaded }
                onError={ onError }
              >
                Click to upload shelf image
              </UploadButton>
            }
          </div>
        </div>
      </Modal>
      {
        showConfirm &&
        <Confirm
          closeModal={ () => { setShowConfirm(false); } }
          action={ handleDeleteAllPlaces }
        />
      }
    </>
  );
};

export default ShelfEditor;
