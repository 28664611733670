import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../common/render_raw_html';
import { LineDataContext, SetContext, PageContext, PlanDataProvider } from '../common/contexts';
import { Header } from '../common/header';
import Modal from '../common/modal';
import ButtonMore from '../common/buttonMore';
import { getCurrentLine, getHashData, getLinesCount, setHashData } from '../common/statistics';
import AllPlansIncludeWithModal from "../common/allPlansIncludedWithModal";
import { preparePopupData } from '../common/helper';

const OverviewPlan = ({
  plan,
  onPlanSelect,
  onLearnMore,
  instantSelect
}) => {
  const { delayAnimation } = useContext(SetContext);
  const {
    getClickedSelectButton,
    setClickedSelectButton
  } = useContext(PageContext);
  const { setLoading } = useContext(LineDataContext);
  const clickedSelectButton = getClickedSelectButton();

  const clickSelectButton = (plan) => {
    setClickedSelectButton(plan.id);
    const select = () => {
      if (!instantSelect) {
        setLoading(false);
      }
      setClickedSelectButton(null);
      onPlanSelect(plan);
    };
    if (!instantSelect) {
      setLoading(true);
    }
    instantSelect ? select() : setTimeout(select, delayAnimation);
  };

  return (
    <div className="best-plans_grid-item -single">

      <div className="plate -border">
        <div className="best-plan">
          <div className="best-plan_top -single">
            <div className="best-plan_top-grid">
              <div className="best-plan_top-grid-item">
                <h4 className="best-plan_title" { ...renderRawHtml(plan.name) } />
              </div>
              <div className="best-plan_top-grid-item">
                <div className="best-plan_price">${ plan.price }/mo</div>
                <div className="best-plan_price-note">+ taxes & fees</div>
              </div>
            </div>
          </div>
          <div className="best-plan_content">
            <div className="plate -gray">
              {
                map(plan.features, (feature, key) => (
                  <div className="best-plan_content-section" key={ `feature-${key}` }>
                    <h4 className="best-plan_content-section-title">
                      { feature.title }
                    </h4>
                    <div className="best-plan_content-section-descr" { ...renderRawHtml(feature.subtitle) } />
                  </div>)
                )
              }
              <div className="best-plan_content-section">
                <h4 className="best-plan_content-section-title">
                  { plan.perksTitle }
                </h4>
                {
                  map(plan.perks, (perk, key) => (
                    <div className="best-plan_content-section-descr" key={ `perk-${key}` } { ...renderRawHtml(perk.featuresListTitle) } />
                  ))
                }
                <div className="best-plan_more">
                  <ButtonMore onClickMore={ () => onLearnMore(plan) } />
                </div>
              </div>
            </div>
          </div>
          <div className="best-plan_buttons">
            <div className="best-plan_button">
              <button
                className={
                  classnames(
                    'button -full-width plan-select-button -invert',
                    { '-clicked': clickedSelectButton }
                  )
                }
                onClick={ () => { clickSelectButton(plan); } }
                disabled={ clickedSelectButton && clickedSelectButton !== plan.id }
              >
                Select
              </button>
            </div>
            <div className="best-plan_button-note">
              This plan will be applied to all phone lines on your account.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WelcomeOverview = ({ plans, onPlanSelect, onSeeAllPlans }) => {
  const { allPlansInclude } = useContext(PlanDataProvider);
  const [ modalData, setModalData ] = useState(null);
  const [ selectedModalPlan, setSelectedModalPlan ] = useState(null);

  const closeModal = () => {
    setSelectedModalPlan(null);
    setModalData(null);
  };

  const openModal = ({ title, description, descriptions }) => {
    setModalData(preparePopupData(title, description, descriptions));
  };

  const closeModalAndUpdateStatistic = () => {
    const line = getCurrentLine();
    const key = `lines.${line}.planWelcomePopupCancelClickCount`;
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    closeModal();
  };

  const onConfirm = (key) => () => {
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    onPlanSelect({ ...selectedModalPlan, perks: [] }, true, false);
    closeModal();
  };

  const onSelect = (plan) => {
    const linesCount = getLinesCount();
    const isWelcomePlan = getHashData('lines.1').planId === 5;
    const selectedPlan = { ...plan, perks: [] };
    if (linesCount === 1 || isWelcomePlan) {
      onPlanSelect({ ...plan, perks: [] }, isWelcomePlan, false);
    } else {
      setSelectedModalPlan(selectedPlan);
    }
  };

  const onSeeAllPlansClick = () => {
    const currentLine = getCurrentLine();
    const key = `lines.${currentLine}.planClickSeeAllPlansWelcomePlanCount`;
    const value = getHashData(key);
    setHashData(key, value + 1);
    onSeeAllPlans();
  };

  const handleLearnMore = (plan) => {
    openModal({
      title: plan.name,
      descriptions: [ ...(plan.features || []), ...(plan.perks || []) ]
    });
  };

  return (
    <div className="content">
      <Header />
      <div className="builder">
        <div className="inner-content -percent-desktop">
          <div className="builder_top -welcome">
            <div className="builder_top-col">
              <h2 className="builder_title">An entry-level unlimited plan for all your lines.</h2>
              <div className="builder_subtitle">
                Enjoy reliable 5G performance with Welcome Unlimited.
              </div>
            </div>
            <div className="builder_top-col">
              <AllPlansIncludeWithModal plans={ allPlansInclude } openDetailsModal={ openModal } />
            </div>
          </div>

          <div className="best-plans">
            <div className="best-plans_grid -center">
              {
                map(plans, (plan) => {
                  return (
                    <OverviewPlan
                      key={ `overviewPlanItem${plan.id}` }
                      plan={ plan }
                      onLearnMore={ handleLearnMore }
                      onPlanSelect={ onSelect }
                      instantSelect
                    />
                  );
                })
              }
            </div>

            <div className="back-button_wrapper">
              <div className="plate -border -button" onClick={ onSeeAllPlansClick }>
                <div className="back-button">
                  See all plans
                  <svg className="icon -more">
                    <use xlinkHref={ `#svg_caret` } />
                  </svg>
                </div>
              </div>
            </div>
          </div>

        </div>

        {!!selectedModalPlan && <Modal title="This plan will be applied to all of your phone lines." handleClose={ closeModalAndUpdateStatistic }>
          <div className="modal_inner">
            <div className="modal_empty-content" />
            <div className="modal-actions">
              <button
                className="button"
                onClick={ onConfirm(`lines.${getCurrentLine()}.planWelcomePopupContinueClickCount`) }
              >
                Continue
              </button>
              <button className="button -invert" onClick={ closeModalAndUpdateStatistic }>Cancel</button>
            </div>
          </div>
        </Modal>}
        {modalData && <Modal title={ modalData.title } handleClose={ closeModal } showFooterButton>
          <div className="modal_content-inner" { ...renderRawHtml(modalData.description) } />
        </Modal>}
      </div>
    </div>
  );
};

export default WelcomeOverview;

