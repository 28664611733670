import React, { useContext, useEffect, useRef } from 'react';
import { isEmpty } from "lodash";
import cn from 'classnames';
import { selectGroup, IndexData } from "../../../common/index-context";
import TableFivePointsScales from "../../../common/components/entity-modal/table-five-points-scales";
import TableAttributeRatings from "../../../common/components/entity-modal/table-attribute-ratings";
import TablePreference from "../../../common/components/entity-modal/table-preference";
import ComparisonsLegend from "../../../common/components/comparisons-legend";
import TableCustomMetrics from '../../../common/components/entity-modal/table-custom_metrics';

const TableConcept = ({ data, current }) => {
  const fivePointScales = selectGroup(data, 'five_point_scale');
  const fivePointScalesPresent = !isEmpty(fivePointScales);
  const customMetrics = selectGroup(data, 'custom_metric');
  const customMetricsPresent = !isEmpty(customMetrics);
  const preference = selectGroup(data, 'preference');
  const preferencePresent = !isEmpty(preference);
  const attributeRatings = selectGroup(data, 'attribute_ratings');
  const attributeRatingsPresent = !isEmpty(attributeRatings);

  const fpAcstAp = fivePointScalesPresent || customMetricsPresent || preferencePresent;

  const tourElement = useRef(null);
  const { getTourUseEffectArgs, averageMode } = useContext(IndexData);
  const [ callback, condition ] = getTourUseEffectArgs(
    "projectModalTable",
    tourElement
  );
  useEffect(callback, condition);

  return (
    <>
      <div
        className="concept-report_tables comparisons_grid"
        ref={ tourElement }
      >
        {
          fpAcstAp &&
          <div className="comparisons_col -grow">
            {
              fivePointScalesPresent &&
              <TableFivePointsScales
                data={ data }
                current={ current }
                fivePointScales={ fivePointScales }
                groupName="five_point_scale"
                className="-long-first-cell"
                withN={ false }
              />
            }
            {
              customMetricsPresent &&
              <TableCustomMetrics
                data={ data }
                current={ current }
                customMetrics={ customMetrics }
                groupName="custom_metrics"
                className="-long-first-cell"
              />
            }
            {
              preferencePresent &&
              <TablePreference
                data={ data }
                current={ current }
                preference={ preference }
              />
            }
          </div>
        }
        {
          attributeRatingsPresent &&
          <div
            className={
              cn("comparisons_col", { '-grow': !fpAcstAp, '-width-50': fpAcstAp })
            }
          >
            <TableAttributeRatings
              data={ data }
              current={ current }
              attributeRatings={ attributeRatings }
              groupName="attribute_ratings"
            />
          </div>
        }
      </div>
      <ComparisonsLegend
        forModal
        averageMode={ averageMode }
        statTesting={ data.stat_testing }
      />
    </>
  );
};

export default TableConcept;
