import React from "react";
import InstructorsCompilation from "../../components/InstructorsCompilation";
import UserInfo from "../../components/UserInfo";
import WorkoutsCompilation from "../../components/WorkoutsCompilation";
import compilations from "../../mocks/workoutCompilations";
import instructors from "../../mocks/instructors";
import VideosList from "../../components/VideosList";
import videos from "../../mocks/videos";
import Layout from "../../components/Layout";
import user from "../../mocks/user";

const ForYou = () => (
  <Layout>
    <UserInfo user={user} />
    <WorkoutsCompilation compilations={compilations.forYou} />
    <InstructorsCompilation instructors={instructors} />
    <VideosList videos={videos.forYou} />
  </Layout>
);

export default ForYou;
