import React, { useState, useRef } from 'react';
import { map, toPairs, fromPairs, forEach, filter } from "lodash";
import EditableGridItems from "../common/EditableGridItems";
import AnswerAdditionalLine from "../common/AnswerAdditionalLine";

const Answers = ({
  question, placeholder, disabledIndex, onChange, errors, max,
  sections, onChangeAnswersImages, withImages, images, options, showNone, showOther
}) => {
  const answersToGridItems = () => {
    return map(toPairs(question.answers), ([ key, question ]) => {
      const itemErrors = errors?.answers && errors.answers[key];
      return {
        id: key,
        question,
        enabled: key !== disabledIndex,
        errors: itemErrors ? { question: itemErrors } : null,
        editable: true,
        imageData: getImageData(key)?.meta_data,
        imageSrc: getImageData(key)?.url
      };
    });
  };

  const getImageData = (key) => {
    if (question.answer_images?.[key] !== undefined) {
      return images?.[key];
    }
    return null;
  };

  const gridItemsToAnswers = () => {
    return fromPairs(map(gridItems, (el) => ([ el.id, el.question ])));
  };

  const gridItemsToAnswersImages = () => {
    return fromPairs(map(filter(gridItems, (el) => el.imageData), (el) => ([ el.id, el.imageData ])));
  };

  const gridItemsToAnswersSrc = () => {
    return fromPairs(map(filter(gridItems, (el) => el.imageSrc), (el) => ([ el.id, { url: el.imageSrc } ])));
  };

  const [ gridItems, setGridItems ] = useState(answersToGridItems);

  const handleOnDeleteEditableGridItem = (index) => {
    gridItems.splice(index, 1);
    forEach(gridItems, (el, i) => {
      el.id = (i + 1).toString();
    });
    setGridItems([ ...gridItems ]);
    if (onChangeAnswersImages) {
      onChangeAnswersImages(gridItemsToAnswersImages(), gridItemsToAnswersSrc());
    }
    onChange(gridItemsToAnswers());
  };

  const handleOnDeleteEditableGridItemImage = (index) => {
    gridItems[index].imageData = null;
    gridItems[index].imageSrc = null;
    setGridItems([ ...gridItems ]);
    onChangeAnswersImages(gridItemsToAnswersImages(), gridItemsToAnswersSrc());
    onChange(gridItemsToAnswers());
  };

  const handleOnAddEditableGridItem = (newText) => {
    gridItems.push({
      id: null,
      question: newText,
      enabled: true,
      editable: true,
      imageData: null,
      imageSrc: null
    });

    forEach(gridItems, (el, i) => {
      el.id = (i + 1).toString();
    });

    setGridItems([ ...gridItems ]);
    onChange(gridItemsToAnswers());
  };

  const handleOnChangeEditableGridItem = (newText, index) => {
    gridItems[index].question = newText;
    setGridItems([ ...gridItems ]);
    onChange(gridItemsToAnswers());
  };

  const handleOnChangeEditableGridItemImage = (newData, index) => {
    gridItems[index].imageData = newData.metaData;
    gridItems[index].imageSrc = newData.url;
    setGridItems([ ...gridItems ]);
    onChangeAnswersImages(gridItemsToAnswersImages(), gridItemsToAnswersSrc());
  };

  //onImageDataChange is used by external component and should use handleOnChangeEditableGridItemImage through reference
  //or there will be bugs because of memorized old references on gridItems
  const funcRef = useRef();
  funcRef.current = handleOnChangeEditableGridItemImage;

  const showAddNewItem = gridItems.length < max;

  const handleChangeAdditionalLine = (bNone, tNone, bOther, tOther) => {
    onChange(question.answers, bNone, tNone, bOther, tOther);
  };

  const onChangeOther = (newBool, newText) => {
    handleChangeAdditionalLine(question.none_answer, question.none_answer_text, newBool, newText);
  };

  const onChangeNone = (newBool, newText) => {
    handleChangeAdditionalLine(newBool, newText, question.other_answer, question.other_answer_text);
  };

  return (
    <div className="form_section -sm-space -js-question">
      <ul className="form-question_answers">
        <EditableGridItems
          gridItems={ gridItems }
          onChange={ handleOnChangeEditableGridItem }
          onDelete={ handleOnDeleteEditableGridItem }
          onImageDelete={ handleOnDeleteEditableGridItemImage }
          showAddNewItem={ showAddNewItem }
          newItemPlaceholder={ placeholder }
          onAdd={ handleOnAddEditableGridItem }
          sections={ sections }
          withImages={ withImages }
          onImageDataChange={ (newData, index) => { funcRef.current(newData, index); } }
        />
        {showOther &&
          <AnswerAdditionalLine
            title={ options.other_specify }
            bool={ question.other_answer }
            text={ question.other_answer_text }
            onChange={ onChangeOther }
          />}
        {showNone &&
          <AnswerAdditionalLine
            title={ options.none_of_the_above }
            bool={ question.none_answer }
            text={ question.none_answer_text }
            onChange={ onChangeNone }
          />}
      </ul>
    </div>
  );
};

export default Answers;
