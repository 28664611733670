import React, { useState, useContext } from 'react';
import Header from '../common/header';
import PlanDetailsModal from '../common/planDetatailsModal';
import { getLargePlanDescription } from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';
import { Perk } from '../common/perkList';
import PlanPerksModal from '../common/planPerksModal';
import { EmptyCard, PlanCard } from '../common/planCard';

const hrStyles = { marginBottom: 20, marginTop: 20 };

const PlanDetailsModalWrapper = ({ planId, handleClose }) => {
  const plan = getLargePlanDescription(planId);

  return plan
    ? <PlanDetailsModal
        plan={{ ...plan, name: `${plan.name} details` }}
        open={!!planId}
        handleClose={handleClose} />
    : null
};

const renderSelectedPlans = (selectedPerks, onPerkDetailsClick, onShowDetails, handleOpenChange) =>
   (plan, idx) => <li key={idx} className="cards-item">
      <h6 className="cards-item-title">Line {idx + 1}</h6>
      {
        plan === null
          ? <EmptyCard/>
          : <PlanCard
              accordionTitle={`Included features & ${plan.perkCount === 0 ? 'perks' : `bonus perk${plan.perkCount > 1 ? 's' : ''}`}`}
              plan={plan}
              renderChildrenInAccordion={true}
              handleOpenChange={handleOpenChange(idx)}
              onShowDetails={onShowDetails(idx)}>
              {selectedPerks[idx].length > 0 && <>
                <div className="hr" style={hrStyles} />
                <ul className="perk-list">
                  {selectedPerks[idx].map(perk => <li className="perk-list-item" key={perk.id}>
                    <Perk perk={perk} onDetailsClick={() => onPerkDetailsClick(perk, idx)} />
                  </li>)}
                </ul>
              </>}
            </PlanCard>
      }
    </li>

const Checkout = ({ onContinue, onBack }) => {
  const { filledLines, linesData, selectedPerks } = useContext(LinesDataContext);

  const total = linesData
    .filter(Boolean)
    .reduce((acc, { price }) => price + acc, 0);

  const [planId, setPlanId] = useState(null);

  const handleShowDetails = (lineIdx) => (id) => {
    Statistic.updateValue(`lines.${lineIdx}.selectedPlanPlanDetailsCount`)
    setPlanId(id);
  };
  const handleCloseDetails = () => { setPlanId(null); };

  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerkFirstSelected`, 0);
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerkSecondSelected`, 0);
    Statistic.updateValue(`lines.${filledLines - 1}.changePlanCount`);
    onBack();
  };

  const [showPerk, setShowPerk] = useState(null);

  const handleShowPerkDetails = (perk, lineIdx) => {
    Statistic.updateValue(`lines.${lineIdx}.selectedPlanPerkDetailsCount`);
    setShowPerk({ name: `${perk.title} details`, description: [perk] });
  };

  const handleHideShowPerkDetails = () => {
    setShowPerk(null);
  };

  const handleOpenChange = (lineIdx) => () => {
    Statistic.updateValue(`lines.${lineIdx}.selectedPlanFeaturesCount`);
  };

  return <div className="checkout">
    <Header/>
    <div className="content">
      <div className="checkout-total-wrapper">
        <button className="select-perk-back" onClick={handleBack}>Back</button>
        <p className="checkout-total">Total: ${total}</p>
      </div>
      <h6 className="checkout-title">You chose a plan for Line {filledLines}</h6>
      <ul className="cards-list">
        {linesData.map(renderSelectedPlans(
          selectedPerks,
          handleShowPerkDetails,
          handleShowDetails,
          handleOpenChange))}
      </ul>
    </div>
    <footer className="checkout-footer">
      <button className="button" type="button" onClick={onContinue}>Continue</button>
    </footer>
    <PlanDetailsModalWrapper planId={planId} handleClose={handleCloseDetails} />
    <PlanPerksModal
      open={!!showPerk}
      handleClose={handleHideShowPerkDetails}
      plan={showPerk} />
  </div>;
};

export default Checkout;