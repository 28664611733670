import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import ExpressUrl from './expressUrl';
import NoteMessage from './../noteMessage';
import CompleteInput from './completeInput';
import ScreenedInput from './screenedInput';
import QuotaScreenedInput from './quotaScreenedInput';
import QuotaTestInputNSize from './quotaTestInputNSize';

const CustomPanelSection = () => {
  const { production, showTestNSize } = useContext(PanelServiceContext);
  return (
    <>
      <div className="custom-panel-fields -no-max-limit">
        {
          !production &&
          <NoteMessage>
            {
              showTestNSize ? (
                <>
                  Project is still in the test mode, but previewing project now turns
                  {' '}
                  on the respondent's redirections to the manually entered Complete Url,
                  {' '}
                  Screened Url and Quota Url (if Quota Test N Size value is present).
                </>
              ) : (
                <>
                  Project is still in the test mode, but previewing project now turns on
                  {' '}
                  the respondent's redirections to the manually entered
                  {' '}
                  Complete Url and Screened Url.</>
              )
            }
          </NoteMessage>
        }
        <ExpressUrl />
        <CompleteInput />
        <ScreenedInput />
        <QuotaScreenedInput />
        { !production && showTestNSize && <QuotaTestInputNSize /> }
      </div>
    </>
  );
};

export default CustomPanelSection;
