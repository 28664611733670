import React from 'react';
import renderRawHtml from '../../../../../common/render_raw_html.js';
import StartButton from '../../../common/components/start_button.js';

const MiddleFivePoint = ({ nextStep, text }) => (
  <div className="survey-layout_content -start">
    <div className="survey-start">
      <div className="survey-start_description" { ...renderRawHtml(text) } />
      <StartButton nextStep={ () => { nextStep(); } } />
    </div>
  </div>
);

export default MiddleFivePoint;
