import React, { useState } from 'react';
import { find, map, shuffle, compact } from 'lodash';
import FivePointScale from '../../../common/components/metrics/five_point_scale';
import Markup from '../../../common/components/metrics/markup';
import AttributesList from '../../../common/components/metrics/attributes_list';
import AttributeRatings from '../../../common/components/metrics/attribute_ratings';
import Shelf from '../shelf';
import ThoughtBubble from "../../../common/components/metrics/thought_bubble";
import ZoomImage from '../../../common/components/zoom_image';
import renderRawHtml from '../../../../../common/render_raw_html';
import remoteFiles from '../../../../../common/remote_files';
import { defaultScorings } from "../../../common/logic/eligible";
import Intro from './intro';

const Concept = ({
  data, concept, index,
  conceptIndex, nextStep, result,
  shelfStep, nextShelfStep
}) => {
  const {
    markup_tool, package_attributes,
    imagery_communication, hidden_metrics, translations, shelf_enabled,
    language_code, hidden_open_ends, tb_klass
  } = data;

  const scorings = defaultScorings(data.scorings);
  const custom_metrics = defaultScorings(data.custom_metrics);

  const getDistinguishableScoring = () => (
    find(scorings, (item) => (item.dm_name === 'uniqueness'))
  );
  const [ distinguishableText ] = useState(() => {
    const question = getDistinguishableScoring();
    return question?.text;
  });

  const introText = () => {
    let text;
    if (shelf_enabled) {
      if (concept.shelf_exercise) {
        text = translations.express_after_shelf_intro;
      } else {
        text = translations.express_no_shelf_intro_next;
      }
    } else if (conceptIndex) {
      text = translations.express_no_shelf_intro_next;
    } else {
      text = translations.express_no_shelf_intro_first;
    }

    return text.replace("%product_name%", concept.product_name);
  };

  const tbTitle = () => (
    translations
      .thought_bubble_title_base
      .replace('%product_name%', concept.product_name)
  );

  const attributesTitle = () => (
    translations.attributes_title.replace("%product_name%", concept.product_name)
  );

  const purchaseInterest = find(scorings, (item) => (item.dm_name === 'purchase_interest'));
  const likelihoodToPurchase = find(scorings, (item) => (item.dm_name === 'likelihood_to_purchase'));
  const packageLiking = find(scorings, (item) => (item.dm_name === 'appeal'));
  const uniqueness = find(scorings, (item) => (item.dm_name === 'package_mc_uniqueness'));
  const brandFit = find(scorings, (item) => (item.dm_name === 'brand_fit'));

  const getDistinguishingUrls = () => {
    let urls;
    if (concept.cart_products) {
      urls = compact(
        map(concept.cart_products, (product) => (
          !product.target && product.url
        ))
      );
    } else {
      const { image1, image2, image3 } = remoteFiles.express.packageMc;
      urls = [ image1, image2, image3 ];
    }
    return shuffle(urls);
  };
  const getDistinguishingQuestions = () => {
    const packageDistinguishable = getDistinguishableScoring();
    if (packageDistinguishable) {
      packageDistinguishable.text = distinguishableText.replace(
        /%product_name%/, concept.product_name
      );
    }
    return packageDistinguishable;
  };

  const distinguishingData = {
    urls: getDistinguishingUrls(),
    questions: [ getDistinguishingQuestions() ]
  };

  return (
    <>
      {
        index === 1 &&
        <Shelf
          data={ data }
          concept={ concept }
          fivePointQuestions={ hidden_metrics }
          openEndQuestions={ hidden_open_ends }
          translations={ translations }
          result={ result }
          step={ shelfStep }
          nextStep={ nextShelfStep }
        />
      }
      {
        index === 2 &&
        <Intro text={ introText() } url={ concept.url } nextStep={ nextStep } />
      }
      {
        index === 3 &&
        <ThoughtBubble
          concept={ concept }
          tbKlass={ tb_klass }
          title={ tbTitle() }
          description={ translations.thought_bubble_description }
          customBgClass="package-mc"
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 4 &&
        <FivePointScale
          concept={ concept }
          questions={ [ purchaseInterest ] }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 5 &&
        <FivePointScale
          concept={ concept }
          questions={ [ likelihoodToPurchase ] }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 6 &&
        <FivePointScale
          concept={ concept }
          questions={ [ packageLiking ] }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 7 &&
        <FivePointScale
          concept={ concept }
          questions={ [ uniqueness ] }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 8 &&
        <Markup
          conceptIds={ [ concept.focus_concept_id ] }
          markupTool={ markup_tool }
          nextStep={ nextStep }
          result={ result }
        />
      }
      {
        index === 9 &&
        <AttributesList
          title={ attributesTitle() }
          orCopy={ translations.attributes_or }
          concept={ concept }
          questions={ package_attributes }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 10 &&
        <AttributeRatings
          title={ translations.attribute_ratings_title }
          concept={ concept }
          questions={ imagery_communication }
          result={ result }
          nextStep={ nextStep }
          languageCode={ language_code }
        />
      }
      {
        index === 11 &&
        <FivePointScale
          concept={ concept }
          questions={ [ brandFit ] }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 12 &&
        <FivePointScale
          concept={ concept }
          questions={ custom_metrics }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 13 &&
        <FivePointScale
          concept={ concept }
          questions={ distinguishingData.questions }
          result={ result }
          nextStep={ nextStep }
          underTextHtml={
            <div className="base-all-images-in-row">
              <div className="all-images-in-row">
                {
                  map(distinguishingData.urls, (url, index) => (
                    <div
                      className="all-images-in-row-image"
                      key={ `AllConcepts${index}` }
                    >
                      <ZoomImage hideCopy src={ url }>
                        <img src={ url } />
                      </ZoomImage>
                    </div>
                  ))
                }

              </div>
              <div
                className="image-enlarge-text"
                { ...renderRawHtml(translations.image_in_modal_hint) }
              />
            </div>
          }
        />
      }
    </>
  );
};

export default Concept;
