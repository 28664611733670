import React, { useState, useContext } from 'react';
import PortalModal from "../common/portal";
import TextField from "../common/portal/textField";
import SelectField from "../common/portal/selectField";
import CheckboxField from "../common/portal/checkboxField";
import { createUser } from './api'
import { each, pick, includes } from 'lodash';
import { baseContext } from "../contexts";

const fieldsBoolean = [ "disabled" ]
const fields = [
  "email",
  "first_name",
  "last_name",
  "recollective_link",
  "role",
  "password",
  "password_confirmation",
  "disabled"
]
const hash = {};

const NewUser = ({ onClose, onSave }) => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  each(fields, (field) => {
    [hash[field], hash[`set_${field}`]] = useState(includes(fieldsBoolean, field) ? false : "");
    [hash[`error_${field}`], hash[`set_error_${field}`]] = useState(null);
  });
  const [ error, setError ] = useState(null);
  const { availableRoles } = useContext(baseContext);

  const clearFieldsAndErrors = () => {
    each(fields, (field) => {
      hash[ `set_${field}`](includes(fieldsBoolean, field) ? false : "")
      hash[`set_error_${field}`](null)
    });
    setError(null);
  };

  const handleClose = () => {
    clearFieldsAndErrors();
    onClose();
  };
  const saveCallback = (event) => {
    setSaveInProgress(true);
    const params = pick(hash, fields);
    createUser(params, (response) => {
      setSaveInProgress(false);
      if (response.response.ok){
        clearFieldsAndErrors();
        onSave(response.data.user);
      }
      else {
        setError(response.data.error);
        each(fields, (field) => {
          const fieldErrors = response.data.errors[field];
          if (!!fieldErrors){ hash[`set_error_${field}`](fieldErrors[0]); }
          else{ hash[`set_error_${field}`](null); }
        });
      }
    });
  };

  return(
    <PortalModal
      className="-sm"
      title="Create User"
      open={ true }
      handleClose={ handleClose }
      saveCallback={ saveCallback }
      buttonName={ saveInProgress ? "Creating..." : "Create" }
      disabledSave={ saveInProgress }
      error={ error }
    >
      <div className="survey-form">
        <TextField error={ hash.error_email } label="Email" value={ hash.email } onChange={ hash.set_email } />
        <TextField error={ hash.error_first_name } label="First Name" value={ hash.first_name } onChange={ hash.set_first_name } />
        <TextField error={ hash.error_last_name } label="Last Name" value={ hash.last_name } onChange={ hash.set_last_name } />
        <TextField error={ hash.error_recollective_link } label="Recollective Link" value={ hash.recollective_link } onChange={ hash.set_recollective_link } />
        <SelectField
          error={ hash.error_role }
          label="Role"
          value={ hash.role }
          options={ availableRoles }
          onChange={ hash.set_role }
          placeholder="Select role"
        />
        <TextField error={ hash.error_password } label="New Password" value={ hash.password } onChange={ hash.set_password } />
        <TextField error={ hash.error_password_confirmation } label="New Password Confirmation" value={ hash.password_confirmation } onChange={ hash.set_password_confirmation } />
        <CheckboxField error={ hash.error_disabled } label="Suspended" checked={ hash.disabled } onChange={ hash.set_disabled } />
      </div>
    </PortalModal>
  )

};

export default NewUser;