import React, { Fragment } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import SortableHeader from "./sortable-header";
import ProjectAsTableRow from "./project-as-table-row";
import MlProjectAsTableRow from "./ml-project-as-table-row";
import ProjectAsCard from "./project-as-card";
import MlProjectAsCard from "./ml-project-as-card";

const Projects = ({ loading, isAdmin, projects, mode }) => {
  return (
    <div className="content_body -relative -projects">
      {
        loading &&
        <div className="page-loader -inner-loader -transparent -fit-half-height" />
      }
      {
        projects.length > 0 &&
        <div className="projects">
          {
            mode === 'cards' &&
            <div className={ classnames("projects_list", { '-admin': isAdmin }) }>
              {
                map(projects, (project, index) => (
                  <Fragment key={ `ProjectCardItem${project.id}${index}` }>
                    {
                      project.multilang ?
                        <MlProjectAsCard project={ project } isAdmin={ isAdmin } mode={ mode } />
                        :
                        <ProjectAsCard project={ project } isAdmin={ isAdmin } mode={ mode } />
                    }
                  </Fragment>
                ))
              }
            </div>
          }

          {
            mode === 'list' &&
            <div className="projects_list_table">
              <table className="table">
                <tbody>
                  <tr>
                    <SortableHeader className="project-title-header" title="Title" field="title" />
                    <SortableHeader className="project-type-header" title="Type" field="express" />
                    {
                      isAdmin &&
                      <SortableHeader className="project-client-header" title="Client" field="client_name" />
                    }
                    <SortableHeader className="project-created_at-header" title="Created At" field="created_at" />
                    <SortableHeader className="project-in_field_at-header" title="In Field At" field="in_field_at" />
                    <SortableHeader className="project-closed_at-header" title="Closed At" field="closed_at" />
                    <SortableHeader className="project-status-header" title="Status" field="status_text" />
                    <SortableHeader className="project-completes-header" title="Completes" field="respondents_count" />
                    <SortableHeader className="project-target-header" title="Target Completes" field="required_nsize" />
                    {
                      isAdmin &&
                      <th className="project-share-header" />
                    }
                    <th className="project-actions-header" />
                  </tr>
                  {
                    map(projects, (project, index) => (
                      <Fragment key={ `ProjectListItem${project.id}${index}` }>
                        {
                          project.multilang ?
                            <MlProjectAsTableRow project={ project } isAdmin={ isAdmin } mode={ mode } />
                            :
                            <ProjectAsTableRow project={ project } isAdmin={ isAdmin } mode={ mode } />
                        }
                      </Fragment>
                    ))
                  }
                </tbody>
              </table>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default Projects;
