import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { map, isEmpty } from 'lodash';
import { StripeContext, LaunchContext } from '../../../contexts';
import { capitalizeFirstLetter, stripeBrandClass } from '../../../helper';

const StripeForm = ({ hide }) => {
  const defaultBrandClassNames = 'pf pf-credit-card';
  const [ brandClassName, setBrandClassName ] = useState(defaultBrandClassNames);
  const [ elementsWithErrorStorage ] = useState({});

  const props = useContext(StripeContext);
  const {
    stripeInstance, setCardNumberStripeObject,
    cardHolder, address, city, state, zipCode,
    setStripeElementsError, setStripeTokenError
  } = props;
  const { states, setErrorMessage } = useContext(LaunchContext);

  const handleChange = (event, field) => {
    props[`set${capitalizeFirstLetter(field)}`](event.target.value);
  };

  const refCardNumber = useRef(null);
  const refExpirationDate = useRef(null);
  const refCvv = useRef(null);

  const stripeStyle = { invalid: { color: '#FF6900' } };

  const updateBrandIcon = (brand) => {
    if (!brand) {
      return;
    }
    setBrandClassName(stripeBrandClass(brand));
  };

  useEffect(() => {
    const onStripeElementChange = (event) => {
      updateBrandIcon(event.brand);
      const result = elementsWithErrorStorage;
      if (event.error) {
        result[event.elementType] = true;
        setStripeElementsError({ ...result });
        setErrorMessage(event.error?.message);
      } else {
        delete result[event.elementType];
        if (isEmpty(result)) {
          setStripeElementsError(null);
          setStripeTokenError(null);
        } else {
          setStripeElementsError({ ...result });
        }
      }
    };
    const elements = stripeInstance.elements();
    const cardNumberElement = elements.create('cardNumber', { style: stripeStyle });
    cardNumberElement.mount(refCardNumber.current);
    cardNumberElement.on('change', onStripeElementChange);
    setCardNumberStripeObject(cardNumberElement);

    const cardExpirationElement = elements.create('cardExpiry', { style: stripeStyle });
    cardExpirationElement.mount(refExpirationDate.current);
    cardExpirationElement.on('change', onStripeElementChange);

    const cardCvcElement = elements.create('cardCvc', { style: stripeStyle });
    cardCvcElement.mount(refCvv.current);
    cardCvcElement.on('change', onStripeElementChange);
  }, []);

  return (
    <>
      <div className={ classNames("form_section", { '-hide': hide }) }>
        <div className="form_grid">
          <div className="form_grid-item">
            <div className="form-pay_container">
              <div className="form-card">
                <div className="form_group-line">
                  <div className="form-group">
                    <label className="form-label">Card number</label>
                    <div
                      ref={ refCardNumber }
                      className={
                        classNames(
                          "form-field -block",
                          { 'has-error': elementsWithErrorStorage['cardNumber'] }
                        )
                      }
                    />
                    <span className="brand"><i className={ brandClassName } /></span>
                  </div>
                </div>
                <div className="form_group-line">
                  <div className="form-group">
                    <label className="form-label">Name on Card</label>
                    <input
                      className="form-field -block"
                      value={ cardHolder }
                      onChange={ (event) => handleChange(event, 'cardHolder') }
                    />
                  </div>
                </div>
                <div className="form_group-line">
                  <div className="form_grid">
                    <div className="form_grid-item -grow">
                      <div className="form-group">
                        <label className="form-label">Expiration date</label>
                        <div
                          ref={ refExpirationDate }
                          className={
                            classNames(
                              "form-field -block",
                              { 'has-error': elementsWithErrorStorage['cardExpiry'] }
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="form_grid-item -width-1-3">
                      <div className="form-group">
                        <label className="form-label">CVV/CVC</label>
                        <div
                          ref={ refCvv }
                          className={
                            classNames(
                              "form-field -block",
                              { 'has-error': elementsWithErrorStorage['cardCvc'] }
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classNames("form_section", { '-hide': hide }) }>
        <h3 className="form_section-title">Billing address</h3>
        <div className="form-pay_container">
          <div className="form_group-line">
            <div className="form-group">
              <label className="form-label">Address:</label>
              <input
                className="form-field -block"
                value={ address }
                onChange={ (event) => handleChange(event, 'address') }
              />
            </div>
          </div>
          <div className="form_group-line">
            <div className="form-group">
              <label className="form-label">City:</label>
              <input
                className="form-field -block"
                value={ city }
                onChange={ (event) => handleChange(event, 'city') }
              />
            </div>
          </div>
          <div className="form_group-line">
            <div className="form_grid">
              <div className="form_grid-item -grow">
                <div className="form-group">
                  <label className="form-label">State:</label>
                  <select
                    className="form-field -block"
                    value={ state }
                    onChange={ (event) => handleChange(event, 'state') }
                  >
                    {
                      map(states, (option) => (
                        <option key={ option.code } value={ option.code }>{ option.name }</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="form_grid-item -width-1-3">
                <div className="form-group">
                  <label className="form-label">Zip Code</label>
                  <input
                    maxLength={ 15 }
                    className="form-field -block"
                    value={ zipCode }
                    onChange={ (event) => handleChange(event, 'zipCode') }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StripeForm;
