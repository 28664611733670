import React, { useState, useRef } from 'react';
import cn from "classnames";
import UploadButton from "../../../../common/components/upload_button";
import SvgIcon from "../../../../common/components/svg-icon";
import Http from '../../../../common/http';

const PDFUploader = ({ title, model, setModel, errors }) => {
  const [ uploading, setUploading ] = useState(false);
  const [ converting, setConverting ] = useState(false);

  const onInitUploading = () => {
    setUploading(true);
  };

  const refModel = useRef(model);
  refModel.current = model;

  const watchConvertStatus = (jid, sourceData) => {
    Http.get(
      `/express/api/transformer/${jid}`
    ).then(
      (response) => {
        if (response.data.status === 'failed') {
          setConverting(false);
        } else if (response.data.status === 'complete') {
          const dd = response.data.state.output;

          refModel.current.expressImages[0].image_data = dd.image_data;
          refModel.current.expressImages[0].image_src = dd.image_src;
          refModel.current.expressImages[0].source_data = sourceData;
          refModel.current.expressImages[0].s3_folder = dd.s3_folder;
          refModel.current.expressImages[0].base_css_file_name = dd.base_css_file_name;
          refModel.current.expressImages[0].unique_css_file_name = dd.unique_css_file_name;
          refModel.current.expressImages[0].html_file_name = dd.html_file_name;
          refModel.current.expressImages[0].pdf_with_text = dd.pdf_with_text;
          refModel.current.expressImages[0].prefix = dd.prefix;
          refModel.current.expressImages[0].notes = dd.text;

          setModel({ ...refModel.current, expressImages: refModel.current.expressImages });

          setConverting(false);
        } else {
          setTimeout(() => {
            watchConvertStatus(jid, sourceData);
          }, 3000);
        }
      },
      () => {
        setConverting(false);
      }
    );
  };

  const onUploaded = (files) => {
    setUploading(false);
    const [ file ] = files;
    const mimeType = file.origin.type;

    if (mimeType === "application/pdf") {
      setConverting(true);
      Http.post(
        '/express/api/transformer',
        { project_id: model.projectId, source_data: file['metaData'] }
      ).then(
        (response) => {
          watchConvertStatus(response.data.jid, file['metaData']);
        },
        () => {}
      );
    }
  };

  const onChangeText = (event) => {
    model.expressImages[0].name = event.target.value;
    setModel({ ...model, expressImages: model.expressImages });
  };

  const onImageDelete = (event) => {
    event.stopPropagation();
    model.expressImages[0].image_data = null;
    model.expressImages[0].image_src = null;
    model.expressImages[0].source_data = null;
    model.expressImages[0].s3_folder = null;
    model.expressImages[0].base_css_file_name = null;
    model.expressImages[0].unique_css_file_name = null;
    model.expressImages[0].html_file_name = null;
    model.expressImages[0].pdf_with_text = false;
    model.expressImages[0].prefix = null;
    model.expressImages[0].notes = null;

    setModel({ ...model, expressImages: model.expressImages });
  };

  return (
    <>
      <div className="form_section">

        <div className="form_section-title">
          <h3>{title}</h3>
        </div>

        {!!model.expressImages.length && model.expressImages.map((image, index) => {
          return (
            <div className="form_grid -spaces-t-b" key={ index }>
              <div className={ cn("form_grid-item") }>
                <div className={ cn("form_grid-item-inner", { "has-error": !!errors['express_images.html_file_name'] }) }>
                  {
                    converting &&
                    <button disabled type="button" className="upload-button-processing link" >
                      Converting...
                    </button>
                  }
                  {
                    !converting &&
                    <UploadButton
                      className="link form-concept_upload-btn"
                      allowedTypes={ [ 'application/pdf' ] }
                      onUploaded={ (newData) => {
                        onUploaded(newData);
                      } }
                      onInit={ onInitUploading }
                      needPreview
                    >
                      {!image.image_src && <SvgIcon name="i-no-image-current-color" />}
                      {image.image_src &&
                        <div className="form-flavor_image">
                          <img src={ image.image_src } alt="image" />
                          <div
                            className="form-flavor_image_delete" onClick={ (event) => {
                              onImageDelete(event);
                            } }
                          >
                            <a className="link -delete" title="Delete">
                              <SvgIcon name="i-delete" />
                            </a>
                          </div>
                        </div>}
                    </UploadButton>
                  }
                </div>
              </div>
              <div className="form_grid-item -grow">
                <textarea
                  id="pdf-text"
                  className={ cn("form-field -block", { "has-error": !!errors['express_images.name'] }) }
                  value={ model.expressImages[0].name || "" }
                  onChange={ (event) => { onChangeText(event);} }
                  disabled={ uploading || converting }
                  maxLength={ 250 }
                />
              </div>
            </div>
          );
        })}

        {!model.expressImages.length &&
          <div className="form_grid">
            <div className="form_grid-item">
              Upload concept
            </div>
          </div>}

      </div>
    </>
  );
};

export default PDFUploader;
