import React, { useEffect, useState } from 'react';
import { filter, flatten, upperFirst } from 'lodash';
import pluralize from 'pluralize';
import Notification from '../common/notification';
import { baseContext } from "./context";
import Index from "./components/index";

const App = (props) => {
  const { consts, project_id } = props;
  const [ data, setData ] = useState(props.data);
  const [ globalLoading, setGlobalLoading ] = useState(true);
  const [ tableLoading, setTableLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ notificationObject, setNotificationObject ] = useState(null);
  const [ selectedConcepts, setSelectedConcepts ] = useState(data.selected_concepts);
  const currentProjectType = data.current_project_type;
  const calcItem = (itemCount) => (
    upperFirst(pluralize(consts.item_names[currentProjectType] || 'concept', itemCount))
  );
  const calcOneItem = () => (calcItem(1));
  const calcManyItems = () => (calcItem(2));
  const [ itemName, setItemName ] = useState(calcOneItem);
  const [ itemsName, setItemsName ] = useState(calcManyItems);
  useEffect(() => {
    if (currentProjectType) {
      setItemName(calcOneItem());
      setItemsName(calcManyItems());
    }
  }, [ currentProjectType ]);

  const productCategories = consts.product_categories;
  const countries = consts.countries;
  const projectsPath = consts.projects_path;
  const conceptsData = data.concepts_data;
  const currentCountry = data.current_country;
  const currentProductCategory = data.current_product_category;
  const conceptGroup = data.concept_group;
  const conceptView = data.concept_view;
  const metricTitles = data.metric_titles;
  const metricData = data.all_as_hash;
  const box = data.box;
  const paramsId = data.params_id;
  const isAdmin = data.is_admin;
  const conceptsCount = data.concepts_count;
  const projectsCount = data.projects_count;
  const brandsCount = data.brands_count;
  const statTesting = data.stat_testing;
  const orderBy = data.order_by;
  const order = data.order;
  const sinceYear = data.since_year;
  const allBrandTags = data.brand_tags;
  const allCustomTags = data.custom_tags;
  const allConceptsIds = data.all_concepts_ids;
  const brandsAndConcepts = data.brands_and_concepts_count;
  const page = data.page;

  const [ dataFilterIsOpen, setDataFilterIsOpen ] = useState(() => {
    return flatten(data.data_filters.demographics).length > 0;
  });

  useEffect(() => {
    if (data) {
      setGlobalLoading(false);
      setTableLoading(false);
      setSelectedConcepts(data.selected_concepts);
    }
  }, [ data ]);

  useEffect(() => {
    if (error) {
      setNotificationObject({ message: "Something went wrong", success: false });
      setGlobalLoading(false);
      setTableLoading(false);
    }
  }, [ error ]);

  const categoryTags = filter(data.category_tags, (tag) => !tag.parent_id);
  const categorySubTags = filter(data.category_tags, (tag) => tag.parent_id);

  const baseContextValue = {
    data,
    consts,
    setData,
    setError,
    error,
    globalLoading,
    setGlobalLoading,
    tableLoading,
    setTableLoading,
    productCategories,
    countries,
    conceptsData,
    conceptGroup,
    box,
    paramsId,
    conceptView,
    isAdmin,
    selectedConcepts,
    setSelectedConcepts,
    conceptsCount,
    projectsCount,
    brandsCount,
    statTesting,
    projectId: project_id,
    order,
    orderBy,
    sinceYear,
    categoryTags,
    categorySubTags,
    allBrandTags,
    allCustomTags,
    allConceptsIds,
    currentCountry,
    currentProjectType,
    currentProductCategory,
    page,
    projectsPath,
    metricTitles,
    metricData,
    dataFilterIsOpen,
    setDataFilterIsOpen,
    brandsAndConcepts,
    itemName,
    itemsName
  };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <Index { ...props } />
      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { setNotificationObject(null); } }
        interval={ 10 }
      />
    </baseContext.Provider>
  );
};

export default App;
