import React, { useContext } from 'react';
import { round } from "lodash";
import cn from "classnames";
import { IndexData } from "../../common/index-context";
import renderRawHtml from "../../../../common/render_raw_html";

const TableRow = ({ text, record }) => {
  return (
    <div className="comparisons_plate-row">
      <div className="texts">
        {text}
      </div>
      <div className={ cn("normal-percent", record.color) }>
        {round(record.value) > 0 ? `+${round(record.value)}` : round(record.value)}
      </div>
    </div>
  );
};

const DeviationScenario = () => {
  const { data } = useContext(IndexData);

  return (
    <>
      <div id="deviation-scenario" className="anchor-point" />
      <h2 className="comparisons_section-title">Deviation Scenario</h2>
      {data.deviation_scenario &&
        <div className="comparisons_plates">
          <div className="comparisons_plates-grid">
            <div className="comparisons_plates-grid-item">
              <div className="comparisons_plate -full-height">
                <h3 className="comparisons_plate-title">Deviation</h3>
                <div { ...renderRawHtml(data.deviation_scenario.html) } />
              </div>
            </div>
            <div className="comparisons_plates-grid-item">
              <div className="comparisons_plate -full-height">
                <h3 className="comparisons_plate-title">Post Exposure to Deviation Scenario</h3>

                <div className="comparisons_plate-row">
                  <div className="texts black-bg">
                    <b>Ease / Effort</b>
                  </div>
                </div>

                <TableRow
                  text="No effort/a little effort (Ease Rating)"
                  record={ data.deviation_scenario.vcc_deviation_effort.values.code45 }
                />

                <TableRow
                  text="Some effort"
                  record={ data.deviation_scenario.vcc_deviation_effort.values.code3 }
                />

                <TableRow
                  text="Moderate effort"
                  record={ data.deviation_scenario.vcc_deviation_effort.values.code2 }
                />

                <TableRow
                  text="A lot of effort"
                  record={ data.deviation_scenario.vcc_deviation_effort.values.code1 }
                />

                <div className="comparisons_plate-row">
                  <div className="texts black-bg">
                    <b>Values Me</b>
                  </div>
                </div>

                <TableRow
                  text="Agree completely/somewhat"
                  record={ data.deviation_scenario.vcc_deviation_values }
                />

                <hr />
                <div className="comparisons_legend">
                  <div className="comparisons_legend-grid">
                    <div className="comparisons_legend-grid-item">
                      <div className="comparisons_legend-label green">Green</div>
                      <div className="comparisons_legend-text">Positive metrics that are greater than Avg + 1SD</div>
                    </div>
                  </div>
                  <div className="comparisons_legend-grid">
                    <div className="comparisons_legend-grid-item">
                      <div className="comparisons_legend-label red">Red</div>
                      <div className="comparisons_legend-text">
                        Positive Metrics that are less than Avg - 1SD or Negative Metrics that are higher than Avg + 1SD
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default DeviationScenario;
