import React from 'react';
import Intro from '../../../common/components/naming_intro.js';
import ThoughtBubble from '../../../common/components/metrics/thought_bubble.js';
import FivePointScale from '../../../common/components/metrics/five_point_scale.js';
import AttributeRatings from '../../../common/components/metrics/attribute_ratings.js';
import ProductLink from '../../../common/components/product_link.js';
import { defaultScorings } from "../../../common/logic/eligible";

const Concept = ({
  data, concept, index,
  conceptIndex, nextStep, result, linkText,
  productUrl, productText, forceImage
}) => {
  const { show_task, attribute_ratings, translations, language_code, tb_klass } = data;

  const scorings = defaultScorings(data.scorings);

  const introText = () => (
    conceptIndex ?
      translations.intro_sequential_next :
      translations.intro_sequential_first
  );
  const tb_title = () => (
    translations.thought_bubble_title.replace('%currentFlavor%', concept.name)
  );
  const maleSelected = () => (result.answers.findIndex("S3_1") > -1);

  const productDescriptionComponent = (
    <>
      {
        show_task &&
        <ProductLink linkText={ linkText } productUrl={ productUrl } productText={ productText } />
      }
    </>
  );

  return (
    <>
      {
        !index &&
        <Intro
          name={ concept.name }
          imageUrl={ concept.url }
          text={ introText() }
          nextStep={ () => { nextStep(); } }
        />
      }
      {
        index === 1 &&
        <ThoughtBubble
          concept={ concept }
          tbKlass={ tb_klass }
          title={ tb_title() }
          description={ translations.thought_bubble_description }
          showMan={ maleSelected() }
          result={ result }
          nextStep={ () => { nextStep(); } }
        />
      }
      {
        index === 2 &&
        <FivePointScale
          concept={ concept }
          questions={ scorings }
          result={ result }
          nextStep={ () => { nextStep(); } }
          forceImage={ forceImage }
          productDescriptionComponent={ productDescriptionComponent }
        />
      }
      {
        index === 3 &&
        <AttributeRatings
          title={ translations.attribute_ratings_title }
          concept={ concept }
          questions={ attribute_ratings }
          result={ result }
          nextStep={ () => { nextStep(); } }
          languageCode={ language_code }
          forceImage={ forceImage }
          productDescriptionComponent={ productDescriptionComponent }
        />
      }
    </>
  );
};

export default Concept;
