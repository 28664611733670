import React from "react";

const Switchbox = ({ id, label, bool, onChange }) => {
  return (
    <div className="toggle">
      <input
        id={ id }
        className="hide"
        type="checkbox"
        checked={ bool }
        onChange={ (event) => { onChange(event.target.checked); } }
      />
      <label className="form-switch" htmlFor={ id } />
      <label className="form-switch-text" htmlFor={ id }>
        {label}
      </label>
    </div>
  );
};

export default Switchbox;
