import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { map } from 'lodash';
import HintInPopup from '../../../common/hint-in-popup';
import { destroyProject } from '../../requests';
import Loader from '../../../../../../../images/icons/loader.gif';
import { canDelete } from '../helpers';
import ExpressIcon from "./express-icon";
import { IndexData, BaseContext } from "./contexts";
import CopyProject from './copy-project';
import ProjectSharingButtons from './project-sharing-buttons';
import ProjectDemoShare from './project-demo-share';

const MlProjectAsCard = ({ project, isAdmin, mode }) => {
  const [ loading, setLoading ] = useState(false);
  const { loadProjects, clientId, demoUser } = useContext(IndexData);
  const { buzzbackUser } = useContext(BaseContext);
  const [ showCopyForm, setShowCopyForm ] = useState(false);
  const handleOpenCopyForm = () => {
    setShowCopyForm(true);
  };
  const handleCloseCopyForm = () => {
    setShowCopyForm(false);
  };
  return (
    <div className={ classnames("projects_item", { "-copying": project.copying }) }>
      <div className="project-preview projects_item-inner -ml">
        <div className="project-preview_line">
          <div className="project-preview_title">
            <ExpressIcon express={ project.express } />
            <span className="-js-project-title">
              { project.title }
              {
                buzzbackUser && !!project.projectNumber &&
                <em>({ project.projectNumber })</em>
              }
            </span>
          </div>
          {
            isAdmin &&
            <div className="project-preview_line-client">
              Client: { project.client_name }
            </div>
          }
        </div>

        <div className="project-date-time">
          <HintInPopup
            hintText={ project.created_at_hint }
            dropClassName="-bottom-center -date"
          >
            { project.created_at_text } • {project.express_text}
          </HintInPopup>
        </div>

        <div className="project-preview_line">
          <div className="project-preview_grid -top">
            <div className="project-preview_grid-item">
              <div className="project-preview_link-wrap">
                <a className="project-preview_link" href={ project.ml_project_path }>
                  Go to projects
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="project-country-codes">
          {
            project.show_sharing &&
            <ProjectSharingButtons project={ project } mode={ mode } />
          }
          {
            project.show_demo &&
            <ProjectDemoShare project={ project } mode={ mode } />
          }
          {
            map(project.ml_country_codes, (code, index) => (
              <div key={ `CountryCode${index}` } className="country-code">
                { code }
              </div>
            ))
          }
        </div>

        {
          !demoUser &&
          <div className="dropdown -actions-menu">
            <svg
              className="icon -vertical-vector"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <use xlinkHref="#svg_vertical-vector" />
            </svg>

            <div className="dropdown_container -corner-right">
              <div className="dropdown_inset">
                <div className="dropdown_content">
                  <ul>
                    <li>
                      <a onClick={ handleOpenCopyForm }>Copy Project</a>
                    </li>
                    {
                      canDelete(isAdmin, project, clientId) &&
                      <li className="-red">
                        <a
                          onClick={ () => {
                            destroyProject(
                              project.title,
                              project.destroy_path,
                              project.status === "Unpublished",
                              setLoading,
                              loadProjects
                            );
                          } }
                        >
                          Delete Project
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
        {
          loading &&
          <div className="dropdown -actions-menu">
            <img width="24px" height="24px" src={ Loader } />
          </div>
        }
        {
          showCopyForm &&
          <CopyProject project={ project } onClose={ handleCloseCopyForm } />
        }
      </div>
    </div>
  );
};

export default MlProjectAsCard;
