import React, { useContext, useState } from 'react';
import { filter, each, map, includes } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { calculateQuotasForQuestion } from '../../common/logic/calculate_quotas';
import { ListContext } from '../contexts/list_context.js';
import { ConditionContext } from "../contexts/condition_context";
import CountriesDropdown from "./countries_dropdown";

const DemoElementContent = ({ question, onChange, readOnly }) => {
  const contextValue = useContext(ListContext);
  const conditionValues = useContext(ConditionContext);
  const { defaultLanguageCode, quotaDisallowedOptions } = contextValue;
  const allLabel = contextValue.allLabel(question);
  const onCountryChange = contextValue.onCountryChange(question);

  const selectedCountry = () => {
    const foundData = filter(question.preparedAnswers, (answer) =>
      (question.condition[answer.key] === "is" && question.language_code === answer.languageCode))[0] || question.preparedAnswers[0];
    const selectedItem = {};
    if (foundData) {
      selectedItem.value = `${foundData.key}-${foundData.languageCode}`;
      selectedItem.key = foundData.key;
      selectedItem.label = foundData.languageTitle ? `${foundData.item} (${foundData.languageTitle})` : foundData.item;
      selectedItem.title = foundData.languageTitle;
      selectedItem.languageCode = foundData.languageCode;
    }
    return selectedItem;
  };
  const [ selectedCountries, setSelectedCountries ] = useState(selectedCountry());


  const noChecks = () => {
    const filtered = filter(question.condition || {}, (v, k) => {
      return v === conditionValues.selected && k !== '-1' && k !== '-2';
    });

    const qAnswers = map(question.answers, (v, k) => v);

    return filtered.length === qAnswers.length || !filtered.length;
  };

  const isSelected = (key) => {
    return !noChecks() && question.condition[key] === conditionValues.selected;
  };

  const singleCheck = (answer) => {
    return isSelected(answer.key) && (!question.is_country || question.language_code === answer.languageCode);
  };

  const excluded = (item) => {
    return includes(question.excluded, item);
  };

  const setCondition = (event, key, item, languageCode) => {
    event.preventDefault();
    event.stopPropagation();

    if (readOnly) {
      return;
    }
    if (excluded(item)) {
      return;
    }

    if (noChecks() || question.single) {
      // question.condition = {};
      each(question.answers, (v, k) => {
        if (k !== '-1' &&  k !== '-2') {
          question.condition[k] = conditionValues.notSelected;
          question.quotas[k] = '';
        }
      });
    }

    if (question.condition[key] === conditionValues.selected) {
      question.condition[key] = conditionValues.notSelected;
    } else {
      question.condition[key] = conditionValues.selected;
    }
    if (noChecks()) {
      setAllConditions(event);
    }
    question.language_code = languageCode || defaultLanguageCode;
    if (question.manage_quota) {
      calculateQuotasForQuestion(question, quotaDisallowedOptions, conditionValues);
    }
    if (onCountryChange) {
      onCountryChange();
    }
    onChange(event, question);
  };

  const setCountry = (data) => {
    question.condition = {};
    question.condition[data.key] = 'is';
    question.language_code = data.languageCode || defaultLanguageCode;
    if (onCountryChange) {
      onCountryChange();
    }
    onChange(event, question);
  };

  const setAllConditions = (event) => {
    event.preventDefault();
    event.stopPropagation();

    each(question.answers, (v, k) => {
      question.condition[k] = conditionValues.selected;
    });
    if (question.manage_quota) {
      calculateQuotasForQuestion(question, quotaDisallowedOptions, conditionValues);
    }
    onChange(event, question);
  };

  const answerKlasses = (answer) => {
    const list = [ 'checkbox-multi_item' ];
    if (excluded(answer.item)) {
      list.push('-disabled');
    }
    return list.join(' ');
  };

  const renderAnswer = (answer, index) => {
    const answerToShow = answer.languageTitle ?
      `${answer.item} (${answer.languageTitle})` :
      answer.item;

    return (
      <div className={ answerKlasses(answer) } key={ `demoAnswer-${index}` }>
        <input
          disabled={ readOnly }
          type="checkbox"
          checked={ singleCheck(answer) }
          id={ `id-${question.title}-${question.name}-${answer.key}-${index}` }
          onChange={ () => {} }
        />
        <label
          className="checkbox-multi_label"
          htmlFor={ `id-${question.title}-${question.name}-${answer.key}-${index}` }
          onClick={ (event) => {
            setCondition(event, answer.key, answer.item, answer.languageCode);
          } }
          { ...renderRawHtml(answerToShow) }
        />
      </div>
    );
  };

  const countriesList = () => {
    const filteredList = filter(question.preparedAnswers, (answer) => (!excluded(answer.item)));
    return map(filteredList, (country) => {
      return {
        value: `${country.key}-${country.languageCode}`,
        key: country.key,
        label: country.languageTitle ? `${country.item} (${country.languageTitle})` : country.item,
        title: country.languageTitle,
        languageCode: country.languageCode
      };
    });
  };

  return (
    <div className={ classnames('checkbox-multi', { '-read-only': readOnly }) }>
      {
        !question.single && !(question.kind && question.kind === 'Open') &&
        <div className="checkbox-multi_item">
          <input
            type="checkbox"
            checked={ noChecks() }
            id={ `id-${question.title}-${question.name}-0` }
            onChange={ () => {} }
          />
          <label
            className="checkbox-multi_label"
            htmlFor={ `id-${question.title}-${question.name}-0` }
            onClick={ (event) => {
              setAllConditions(event);
            } }
            { ...renderRawHtml(allLabel) }
          />
        </div>
      }
      {
        question.is_country &&
        <CountriesDropdown
          onUpdate={ setCountry }
          countriesList={ countriesList }
          selectedOptions={ selectedCountries }
          setSelectedOptions={ setSelectedCountries }
          disabled={ readOnly }
        />
      }
      {
        !question.is_country &&
        map(question.preparedAnswers, (answer, index) => {
          return renderAnswer(answer, index);
        })
      }
    </div>
  );
};

export default DemoElementContent;
