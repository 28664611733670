import React from "react";

const EnableButton = ({ videoAd, handleVideoAdChange }) => {
  return (
    <div className="form_group -relative">
      <div className="form_section -section-relative">
        <div className="form_section-title -grid">
          <div className="form_section-title-item">
            <h3>Video Ad</h3>
          </div>
          <div className="form_section-title-item">
            <div className="info-tooltip">
              <div className="info-tooltip_control">
                <svg className="icon -i-info"><use xlinkHref="#svg_i-info" /></svg>
              </div>
              <div className="info-tooltip_drop -right">
                <div className="info-tooltip_drop-inner">
                  To include a video ad, turn the exercise on and upload your video for each ad.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form_section-title -grid">
        <div className="form_section-title-item -grid">
          <div className="toggle">
            <input
              className="hide"
              type="checkbox"
              id="include"
              checked={ videoAd }
              onChange={ (e) => handleVideoAdChange(e) }
            />
            <label className="form-switch" htmlFor="include" />
            <label className="form-switch-text" htmlFor="include">
              Add a video ad
            </label>
          </div>
        </div>
      </div>
      {videoAd && <div className="form_section-title -grid">
        <div className="form_section-title-item -grid">
          For each video, you can upload the video and also static images for follow-up questions [optional].
          <br />
          We recommend video files smaller than 1GB, maximum, to ensure optimal respondent experience.
          <br />
          If you experience issues while uploading the video file, please turn off your antivirus or browser’s plugins.
        </div>
      </div>}
    </div>
  );
};

export default EnableButton;
