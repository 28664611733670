import { min, map } from 'lodash';
import {
  allPlansInclude, planFeatures,
  linesEmptyPlanTitle,
  bauPerks,
  bauPerkNote
} from "../common/common_data";

const pricing = [
  [ 0, 0, 0, 0, 0 ], [], [], [], [], [],
  [ 65, 65, 5, 5, 5 ],
  [ 75, 75, 15, 15, 15 ],
  [ 75, 75, 15, 15, 15 ],
  [ 85, 85, 25, 25, 25 ]
];

const priceForLine = (planId, lineIndex) => {
  if (!planId) {
    return null;
  }
  return pricing[planId - 1][lineIndex];
};

const planA = {
  id: 7,
  name: "Plan A",
  features: [
    { ...planFeatures.FiveGNationwide, ...{ klass: "-down-shifting" } },
    planFeatures.UnlimitedData
  ],
  perks: [
    bauPerks.allOnSixMonth
  ]
};

const planB = {
  id: 8,
  name: "Plan B",
  letter: "B",
  features: [
    { ...planFeatures.FiveGUltraWideband, ...{ klass: "-ultra" } },
    planFeatures.FiveGNationwide,
    { ...planFeatures.FiftyGbPremiumNetworkAccess, ...{ klass: "-height-align-50" } },
    { ...planFeatures.TwentyFiveGbPremiumHotSpotData, ...{ klass: "-height-align-25" } }
  ],
  perksNote: bauPerkNote(19),
  perks: [
    bauPerks.huluDisneyEspn,
    bauPerks.appleArcadeOrGooglePlay,
    bauPerks.fiftyPercentOffHomeInternet,
    bauPerks.appleMusicOnSixMonths
  ]
};

const planC = {
  id: 9,
  name: "Plan C",
  perksNote: bauPerkNote(36),
  features: [
    { ...planFeatures.FiveGUltraWideband, ...{ klass: "-ultra" } },
    planFeatures.FiveGNationwide,
    { ...planFeatures.FiftyGbPremiumNetworkAccess, ...{ klass: "-height-align-50" } },
    { ...planFeatures.TwentyFiveGbPremiumHotSpotData, ...{ klass: "-height-align-25" } }
  ],
  perks: [
    bauPerks.FiftyPercentOffWatchTabletWifi,
    bauPerks.TravelPass,
    bauPerks.SixHundredGbVerizonCloud,
    bauPerks.fiftyPercentOffHomeInternet,
    bauPerks.allOnSixMonth
  ]
};

const planD = {
  id: 10,
  name: "Plan D",
  features: [
    { ...planFeatures.FiveGUltraWideband, ...{ klass: "-ultra" } },
    planFeatures.FiveGNationwide,
    { ...planFeatures.UnlimitedPremiumNetworkAccess, ...{ klass: "-height-align-50" } },
    { ...planFeatures.FiftyGbPremiumHotSpotData, ...{ klass: "-height-align-25" } }
  ],
  perksNote: bauPerkNote(65),
  perks: [
    bauPerks.huluDisneyEspn,
    bauPerks.appleArcadeOrGooglePlay,
    bauPerks.FiftyPercentOffWatchTabletWifi,
    bauPerks.TravelPass,
    bauPerks.SixHundredGbVerizonCloud,
    bauPerks.AppleMusic,
    bauPerks.fiftyPercentOffHomeInternet
  ]
};

const plans = [ planA, planB, planC, planD ];

const cell7 = {
  plans,
  getPlanPriceByLine: (plan, currentLine) => (
    pricing[plan.id - 1][currentLine - 1]
  ),
  allPlansInclude, pricing,
  specialPricingMinPricePerLine: map(pricing[0], (item, index) => (
    min(map(plans, (plan) => (pricing[plan.id - 1][index])))
  )),
  linesEmptyPlanTitle,
  bau: true
};
cell7.linesDescriptionKlass = 'bolder';
cell7.linesDescription = `Third line starting as low as $${cell7.specialPricingMinPricePerLine[2]}/mo.`;

export { cell7, priceForLine  };
