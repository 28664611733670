import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts';
import { calculationService } from '../../../common/project_price/helper';
import SurveyCompleteTarget from '../surveyCompleteTarget';
import { isAvailable } from '../surveyCompleteTarget/helper';
import { generateConfirmationMessage, generateSuccessMessage } from './helper';

const EditSize = () => {
  const {
    project, setProject, globalDisabled, setNotificationObject,
    sendRequest, paths
  } = useContext(AppContext);
  const { required_nsize, status_name, complete_target } = project;
  const completeTargetData = project.complete_target_data;
  const projectData = completeTargetData.project_data;
  const priceData = completeTargetData.price_data;
  const updatePath = paths.size_update_path;
  const baseTotalN = completeTargetData.current_numbers?.n_size;
  const baseConceptN = completeTargetData.current_numbers?.n_size_concept;
  const [ targetNumber, setTargetNumber ] = useState(required_nsize || 0);
  const [ completeTarget, setCompleteTarget ] = useState(complete_target);
  const [ priceService ] = useState(
    () => (calculationService(projectData, priceData, targetNumber))
  );
  const defineConceptNSize = (total) => (priceService.conceptSize(total));
  const [ totalN, setTotalN ] = useState(baseTotalN);
  const [ conceptN, setConceptN ] = useState(
    () => (baseConceptN || defineConceptNSize(baseTotalN))
  );
  useEffect(() => {
    setTargetNumber(required_nsize);
  }, [ required_nsize ]);

  useEffect(() => {
    setCompleteTarget(complete_target);
  }, [ complete_target ]);

  useEffect(() => {
    setTotalN(baseTotalN);
  }, [ baseTotalN ]);

  useEffect(() => {
    setConceptN(baseConceptN || defineConceptNSize(baseTotalN));
  }, [ baseConceptN ]);

  const handleTargetChange = (event) => {
    const newValue = parseInt(event.target.value || '0', 10);
    setTargetNumber(newValue);
    setTotalN(newValue);
    setConceptN(defineConceptNSize(newValue));
  };

  const handleCancelSetTarget = () => {
    setTargetNumber(required_nsize);
    setCompleteTarget(complete_target);
    setTotalN(baseTotalN);
    setConceptN(baseConceptN || defineConceptNSize(baseTotalN));
  };

  const handleUpdateTarget = () => {
    const confirmationMessage = generateConfirmationMessage({
      completeTargetData, statusName: status_name,
      newSizeValue: targetNumber, oldSizeValue: required_nsize,
      newTargetValue: completeTarget, oldTargetValue: complete_target
    });
    if (window.confirm(confirmationMessage.join(' '))) {
      const params = { nsize: targetNumber, complete_target: completeTarget };
      sendRequest(updatePath, params, (response) => {
        setProject(response.data.projects);
        const successMessage = generateSuccessMessage({
          completeTargetData,
          statusName: project.status_name, panelService: project.panel_service,
          newSizeValue: targetNumber, oldSizeValue: required_nsize,
          newTargetValue: completeTarget, oldTargetValue: complete_target
        });
        setNotificationObject({ message: successMessage, success: true });
      }, (response) => {
        setNotificationObject({
          message: response?.data?.error || `Something went wrong, please try again later.`,
          success: false
        });
      });
    }
  };
  const disableButtons = globalDisabled || !targetNumber || (
    required_nsize === targetNumber && completeTarget === complete_target
  );

  const completeTargetAvailable = isAvailable(completeTargetData, baseTotalN, baseConceptN);

  const Buttons = (
    <div className="update-buttons">
      <button
        disabled={ disableButtons }
        className="button survey-flow-button"
        onClick={ handleUpdateTarget }
      >
        Update
      </button>
      <button
        disabled={ disableButtons }
        className="button -secondary -with-border survey-flow-button"
        onClick={ handleCancelSetTarget }
      >
        Cancel
      </button>
    </div>
  );

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full edit-info-item-external edit-size-form">
          <h3 className="form_section-title">
            Express Target Completes
          </h3>
          <div className="form-group">
            <div className="input-with-button">
              <div className="input-item">
                <div className="input-item-note">Total number of respondents</div>
                <input
                  className="form-field -block"
                  value={ targetNumber }
                  onChange={ handleTargetChange }
                  maxLength={ 6 }
                  disabled={ globalDisabled }
                />
              </div>
              { !completeTargetAvailable && <>{ Buttons }</>}
            </div>
          </div>
          <SurveyCompleteTarget
            titleClassName="input-item-note"
            className="-no-bottom-margin"
            completeTargetData={ completeTargetData }
            completeTarget={ completeTarget }
            setCompleteTarget={ setCompleteTarget }
            totalN={ totalN }
            conceptN={ conceptN }
          />
          { completeTargetAvailable && <footer className="form-footer">{ Buttons }</footer> }
        </div>
      </div>
    </div>
  );
};

export default EditSize;
