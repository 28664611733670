import React from 'react';

const BeforeLoadedProjects = () => {
  return (
    <div className="content -express verizon-modifier">
      <div className="content_body -express -before-loaded">
        <div className="page-loader -inner-loader -transparent -fit-half-height" />
      </div>
    </div>
  );
};

export default BeforeLoadedProjects;
