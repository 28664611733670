import React, { useRef, useState } from 'react';
import { map, find, findIndex, pullAt } from "lodash";
import ImagesPDF from "./imagesPdf";

const ImagesList = ({ model, setModel }) => {
  const getEmptyStep = (kind) => {
    return { id: '', notes: '', kind, errors: {} };
  };

  const [ fullStep, setFullStep ] = useState(() => {
    return find(model.steps, (step) => (!step._destroy && step.kind === 'pdf_full')) || getEmptyStep('pdf_full');
  });

  const [ newStep, setNewStep ] = useState(() => {
    return getEmptyStep('pdf_step');
  });

  const refSteps = useRef(model.steps);
  refSteps.current = model.steps;

  const onFullConverted = (newStep) => {
    const index = findIndex(refSteps.current, (step) => (!step._destroy && step.kind === 'pdf_full'));

    if (index === -1) {
      setModel({ ...model, steps: [ newStep, ...refSteps.current ] });
    } else {
      refSteps.current[index] = newStep;
      setModel({ ...model, steps: [ ...refSteps.current ] });
    }
    setFullStep(newStep);
  };

  const onNewStepConverted = (newStep) => {
    setModel({ ...model, steps: [ ...refSteps.current, newStep ] });
    setNewStep(getEmptyStep('pdf_step'));
  };

  const onStepConverted = (newStep, step) => {
    const index = findIndex(refSteps.current, (el) => (el === step));
    refSteps.current[index] = newStep;
    setModel({ ...model, steps: [ ...refSteps.current ] });
  };

  const onStepDeleted = (newStep, step) => {
    const index = findIndex(refSteps.current, (el) => (el === step));
    if (refSteps.current[index].id) {
      refSteps.current[index]._destroy = '1';
    } else {
      pullAt(refSteps.current, index);
    }
    setModel({ ...model, steps: [ ...refSteps.current ] });
  };

  return (
    <div className="form_section -black-boxes">
      <ImagesPDF
        mainText="Full Process"
        projectId={ model.projectId }
        step={ fullStep }
        className="-first"
        onConverted={ onFullConverted }
        onDelete={ onFullConverted }
      />

      {map(model.steps, (step, index) => {
        if (!!step._destroy || step.kind !== 'pdf_step') {
          return null;
        }

        return (
          <ImagesPDF
            key={ index }
            mainText="Process Step"
            projectId={ model.projectId }
            step={ step }
            className="-half"
            onConverted={ (newStep) => {onStepConverted(newStep, step);} }
            onDelete={ (newStep) => {onStepDeleted(newStep, step);} }
          />
        );
      })}

      <ImagesPDF
        mainText="Process Step"
        projectId={ model.projectId }
        step={ newStep }
        className="-half"
        onConverted={ onNewStepConverted }
        onDelete={ () => {} }
      />

    </div>
  );
};

export default ImagesList;
