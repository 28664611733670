import React, { useContext } from 'react';
import { filter, map, join, find, concat, isEmpty, flatten, values } from "lodash";
import cn from "classnames";
import Popup from "reactjs-popup";
import { updateConcept, updateParams } from "../requests";
import { baseContext } from "../context";
import SvgIcon from "../../../../common/components/svg-icon";
import StatusCircle from "../../../researcher/common/components/status-circle";
import { brandTags, categoryFirstLayerTags, categorySecondLayerTags, customTags, keyTitle } from "../helpers";

const ConceptsTableDataRow = ({ concept, index, loadingElement, onRowClick }) => {
  const {
    setData, setError, paramsId, selectedConcepts, conceptGroup,
    conceptView, box, isAdmin, tableLoading, setTableLoading,
    setSelectedConcepts, metricTitles, metricData, consts
  } = useContext(baseContext);

  const conceptSelected = find(selectedConcepts, (item) => item === concept.id);

  const onDbAverageChange = (event, attrName) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event.target.checked && !window.confirm(`Do you really want to exclude "${concept.name}" concept?`)) {
      return;
    }
    const payload = { concept_id: concept.id };
    payload[attrName] = event.target.checked ? "included" : "excluded";
    if (!event.target.checked) {
      payload['selected_concepts'] = filter(selectedConcepts, (item) => item !== concept.id);
      onConceptSelectedChange(event, false);
    }
    setTableLoading(true);
    updateConcept(paramsId, payload, setData, setError);
  };

  const onSiteWideChange = (event, attrName) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = { concept_id: concept.id };
    payload[attrName] = event.target.checked;
    setTableLoading(true);
    updateConcept(paramsId, payload, setData, setError);
  };

  const onConceptSelectedChange = (event, value, stopLoader = false) => {
    event.preventDefault();
    event.stopPropagation();

    if (stopLoader) {
      setTableLoading(false);
    }
    const payload = {};
    if (value) {
      payload.selected_concepts = concat(selectedConcepts, [ concept.id ]);
    } else {
      payload.selected_concepts = filter(selectedConcepts, (item) => item !== concept.id);
    }

    if (conceptGroup === 'included') {
      payload.no_calc = true;
      updateParams(paramsId, payload, () => setSelectedConcepts(payload.selected_concepts), setError);
    } else {
      if (isEmpty(payload.selected_concepts)) {
        payload.concept_group = "included";
      }
      setTableLoading(true);
      updateParams(paramsId, payload, setData, setError);
    }
  };

  const onDbAvgChange = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = { concept_id: concept.id, include_db_average: value };
    setTableLoading(true);
    updateConcept(paramsId, payload, setData, setError);
  };

  const selectedFirstLayerTagsLabels = map(categoryFirstLayerTags(concept.selected_tags), (tag) => tag.label);
  const selectedSecondLayerTagsLabels = map(categorySecondLayerTags(concept.selected_tags), (tag) => tag.label);
  const selectedBrandTagsLabels = map(brandTags(concept.selected_tags), (tag) => tag.label);
  const selectedCustomTagsLabels = join(map(customTags(concept.selected_tags), (tag) => tag.label), ", ");

  const handleClickLink = (event) => {
    event.stopPropagation();
  };

  const handleLabelClick = (event) => {
    event.stopPropagation();
  };

  const trigger = (
    <div
      className={ cn("table-comparison_concept -concept-card", `js-${concept.id}`) }
    >
      <div className="concept-preview table-comparison_concept-preview">
        <div
          className="concept-preview_thumb -sm -dark -less-rounded"
          style={ { 'backgroundImage': `url(${concept.image_url})` } }
        />
      </div>
    </div>
  );

  const onRowClickLocal = (event, concept) => {
    if (!tableLoading) {
      onRowClick(event, concept);
    }
  };

  return (
    <tr
      key={ `${concept.name}-${index}` }
      className={ cn("-highlighted -clickable", { '-not-relative-position': loadingElement }) }
      onClick={ (event) => { onRowClickLocal(event, concept); } }
    >
      {
        (conceptGroup === "included" || conceptGroup === "custom") &&
        <td>
          <div className="concepts-database_icon">
            <div className="info-tooltip -range">
              <SvgIcon
                name={ conceptSelected ? "i-star-blue" : "i-star-gray" }
                onClick={ (event) => onConceptSelectedChange(event, !conceptSelected) }
              />
              <div className="info-tooltip_drop -right">
                <div className="info-tooltip_drop-inner -tiny">
                  {
                    conceptSelected ?
                      <>Remove from Custom Comparison</> :
                      <>Add to Custom Comparison</>
                  }
                </div>
              </div>
            </div>
          </div>
        </td>
      }
      <td className={ cn({ '-not-relative-position': loadingElement }) }>
        <div className="concept-report_header-item concept-preview">
          <Popup
            trigger={ trigger }
            position="right"
            on={ [ 'hover', 'focus' ] }
            className="th-image-popup"
            mouseEnterDelay={ 1 }
            mouseLeaveDelay={ 1 }
            offsetY={ -100 }
          >
            <div className="-contains-image in">
              <div className="tooltip-inner">
                <img className="concept-preview_pic-big" src={ concept.image_url } />
              </div>
            </div>
          </Popup>
        </div>
        { !!loadingElement && loadingElement }
      </td>
      <td className="-clickable">
        { concept.name }
      </td>
      <td>
        {
          concept.include_db_average === "for_approval" &&
          <>
            <div className="concepts-database_table_cell_flex">
              <button
                className="concept-database_button -include"
                onClick={ (event) => onDbAvgChange(event, "included") }
              >
                Include
              </button>
              <button
                className="concept-database_button -exclude"
                onClick={ (event) => onDbAvgChange(event, "excluded") }
              >
                Exclude
              </button>
            </div>
          </>
        }
        {
          concept.include_db_average !== "for_approval" &&
          <label
            className="form-switch-group -no-border switch-sentiments"
            onClick={ handleLabelClick }
          >
            <input
              type="checkbox"
              checked={ concept.include_db_average === "included" }
              onChange={ (event) => onDbAverageChange(event, "include_db_average") }
              className="hide"
            />
            <span className="form-switch" />
            <div className="concepts-database_table-text">
              { concept.include_db_average === "included" ? "Yes" : "No" }
            </div>
          </label>
        }
      </td>
      {
        isAdmin &&
        <td>
          <label
            className="form-switch-group -no-border switch-sentiments"
            onClick={ handleLabelClick }
          >
            <input
              type="checkbox"
              checked={ concept.include_site_wide }
              onChange={ (event) => onSiteWideChange(event, "include_site_wide") }
              className="hide"
            />
            <span className="form-switch" />
            <div className="concepts-database_table-text">
              { concept.include_site_wide ? "Yes" : "No" }
            </div>
          </label>
        </td>
      }
      <td>
        <a
          className="concepts-database_link"
          href={ concept.project_path }
          target="_blank" rel="noreferrer"
          onClick={ handleClickLink }
        >
          { concept.export_title }
        </a>
      </td>
      <td>
        { join(selectedFirstLayerTagsLabels, ", ") }
      </td>
      <td>
        { join(selectedSecondLayerTagsLabels, ", ") }
      </td>
      <td>
        { join(selectedBrandTagsLabels, ", ") }
      </td>
      <td>
        { concept.internal ? "Internal" : "Client" }
      </td>
      <td>
        { consts.priced_texts[concept.priced] }
      </td>
      <td>
        { consts.stimuli_types_texts[concept.stimuli_type] }
      </td>
      <td>
        { consts.product_types_texts[concept.product_type] }
      </td>
      <td>
        { concept.year }
      </td>
      <td>
        { concept.target }
      </td>
      {
        conceptView === "data" &&
        map(flatten(values(metricTitles)), (obj) => {
          const key = keyTitle(obj);
          return (
            <td className="-centered" key={ key }>
              {
                (metricData?.[key]?.[concept.id] && metricData[key][concept.id].in_calc === true) ?
                  <StatusCircle
                    values={ metricData?.[key]?.[concept.id] }
                    noLetters
                    box={ box }
                    hideCustom={ conceptGroup === 'excluded' || conceptGroup === "for_approval" }
                  /> :
                  "N/A"
              }
            </td>
          );
        })
      }
      <td>
        { concept.respondents_count }
      </td>
      <td>
        { consts.incidence_ranges_texts[concept.incidence_range] }
      </td>
      <td>
        { selectedCustomTagsLabels }
      </td>
      <td>
        { concept.notes ? concept.notes : "-" }
      </td>
    </tr>
  );
};

export default ConceptsTableDataRow;
