import React from 'react';
import { find, filter, findIndex } from "lodash";
import CheckboxMultiLang from "./checkbox-multilang";

const CountrySelector = ({ projects, countries, onChange }) => {
  const baseProject = find(projects, (el) => (el.base));
  const notUsed = filter(countries, (country) => (
    findIndex(projects,
      (project) => (project.country_name === country.real_name && project.language_code === country.language_code)
    ) === -1
  ));

  return (
    <div className="field-gray-bg">
      <h5>Add Country</h5>
      <div className="smaller-text">
        You can select multiple countries and all details will be copied from the
        { ' ' }
        base project
        { ' ' }
        <b>
          <a href={ baseProject.edit_path }>
            { baseProject.country_name }
          </a>
        </b>
      </div>
      <CheckboxMultiLang objects={ notUsed } selectedNames={ [] } onChange={ onChange } />
    </div>
  );
};

export default CountrySelector;
