import React, { useContext } from 'react';
import { find, map } from 'lodash';
import { withPrefix, determineValue } from "../helpers";
import { baseContext } from "../contexts";
import Issues from "./issues";

const List = ({ currentData }) => {
  const { mediaKind, verizonType } = useContext(baseContext);

  if (currentData.length === 0) {
    return (
      <p>No Pulse Surveys found</p>
    );
  }

  const satisfactionTotal = (item) => (item.data.satisfaction.top2box.total);
  const valuesTotal = (item) => (item.data.values_me.total);
  const stayingTotal = (item) => (item.data.staying.total);

  const valuesMeNames = () => map(currentData[0].data.values_me.answers, (answer) => answer.text);
  const stayingNames = () => map(currentData[0].data.staying.answers, (answer) => answer.text);

  const has8Issues = find(currentData, (el) => (el.data.issues.answers.length === 8));
  const has9Issues = find(currentData, (el) => (el.data.issues.answers.length === 9));
  const has10Issues = find(currentData, (el) => (el.data.issues.answers.length === 10));

  const planningTitle = has8Issues || has9Issues || has10Issues ? "Intent to Stay" : "Planning to Stay";

  return (
    <div className="pulses_wrapper-table -full">
      <table className="table-pulses">
        <thead>
          <tr>
            <th className="table-pulses_col -first" />
            <th className="table-pulses_col" colSpan={ currentData.length } />
          </tr>
        </thead>
        <tbody className="table-pulses_section -satisfaction">
          <tr className="table-pulses_head">
            <td className="table-pulses_col -first -head">
              <div className="table-pulses_head-inner">
                <h6 className="table-pulses_title">Satisfaction</h6>
              </div>
            </td>
            {
              map(currentData, (value) => (
                <td className="table-pulses_col -head" key={ `line1-name-${value.id}` }>
                  <div className="table-pulses_head-inner">
                    <div className="table-pulses_title -center">
                      { value.overview_date }
                    </div>
                    <div className="table-pulses_subtitle">
                      (n={ satisfactionTotal(value) })
                    </div>
                  </div>
                </td>
              ))
            }
          </tr>
          <tr className="table-pulses_tr">
            <td className="table-pulses_col -first">
              <b>Satisfied</b> with their { withPrefix(verizonType, mediaKind) } Internet (T2B)
            </td>
            {
              map(currentData, (value) => (
                <td className="table-pulses_col -center" key={ `line1-value-${value.id}` }>
                  <div className="table-pulses_text -big">
                    { determineValue(value.data.satisfaction.top2box.answers[0], satisfactionTotal(value)) }
                  </div>
                </td>
              ))
            }
          </tr>
        </tbody>

        <Issues has8={ has8Issues } has9={ has9Issues } currentData={ currentData } />

        <tbody className="table-pulses_section -issues">
          <tr className="table-pulses_head">
            <td className="table-pulses_col -first -head">
              <div className="table-pulses_head-inner">
                <h6 className="table-pulses_title">Worth the Price</h6>
              </div>
            </td>
            {
              map(currentData, (value) => (
                <td className="table-pulses_col -head" key={ `line2-name-${value.id}` }>
                  <div className="table-pulses_head-inner -number" >(n={ valuesTotal(value) })</div>
                </td>
              ))
            }
          </tr>

          {
            map(valuesMeNames(), (name, index) => (
              <tr className="table-pulses_tr" key={ `line2-values-me-${index}` }>
                <td className="table-pulses_col -first">{ name }</td>
                {
                  currentData.map((value) => (
                    <td className="table-pulses_col -center" key={ `line2-values_me-${index}-value-${value.id}` }>
                      <div className="table-pulses_text -md">
                        { determineValue(value.data.values_me.answers[index], valuesTotal(value)) }
                      </div>
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>

        <tbody className="table-pulses_section -issues">
          <tr className="table-pulses_head">
            <td className="table-pulses_col -first -head">
              <div className="table-pulses_head-inner">
                <h6 className="table-pulses_title">{planningTitle}</h6>
              </div>
            </td>
            {
              map(currentData, (value) => (
                <td className="table-pulses_col -head" key={ `line3-name-${value.id}` }>
                  <div className="table-pulses_head-inner -number" >(n={ stayingTotal(value) })</div>
                </td>
              ))
            }
          </tr>

          {
            map(stayingNames(), (name, index) => (
              <tr className="table-pulses_tr" key={ `line3-staying-${index}` }>
                <td className="table-pulses_col -first">{ name }</td>
                {
                  currentData.map((value) => (
                    <td className="table-pulses_col -center" key={ `line3-staying-${index}-value-${value.id}` }>
                      <div className="table-pulses_text -md">
                        { determineValue(value.data.staying.answers[index], stayingTotal(value)) }
                      </div>
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default List;
