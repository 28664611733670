import React from "react";
import Icon from "./Icon";

const BrandUnderlineLogo = ({ icon, color }) => (
  <div className="brand-underline-logo">
    <Icon name={icon} />
    <div className={`brand-underline-logo_bar -bg-${color}`} />
  </div>
);

export default BrandUnderlineLogo;
