import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import ReadOnlyInput from '../common/readOnlyInput';

const ExpressUrl = () => {
  const { expressUrl } = useContext(PanelServiceContext);

  return (
    <ReadOnlyInput
      title="Survey Url"
      inputContent={ expressUrl }
      copyButtonTitle="Copy Url"
    >
      This is the main survey url which should be provided to the external provider.
      {' '}
      External provider should automatically replace <b>"%respid%"</b>
      {' '}
      value with the actual respondent id.
    </ReadOnlyInput>
  );
};

export default ExpressUrl;
