import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { IndexData } from '../common/index-context';
import TopFilter from "../common/filters/app";
import ExpressLanguageSwitcher from '../common/components/express-language-switcher';
import { WithLangData } from '../common/lang-data';
import CustomizeQuestionsModal from "../common/components/customize-questions-modal/customize-questions-modal";
import Header from "./components/header/index";
import Tabs from "./components/tabs";
import NInfo from "./components/n-info";
import TabCategory from "./components/tab-category";
import TabBrand from "./components/tab-brand";
import TabCustom from "./components/tab-custom";

const AppInner = () => {
  const { init, data, loadData, getForceOpenFilterDropDown, loading, customizedQuestionData } = useContext(IndexData);

  const showCategory = !!data.category_purchase_frequency || !!data.category_purchasing_channels;
  const showCustom = !!data.custom_questions;
  const [ tab, setTab ] = useState('');

  const { search } = useLocation();

  useEffect(() => {
    loadData(null, null, null, null, {}, (d) => {
      const initShowCategory = !!d.category_purchase_frequency && !!d.category_purchasing_channels;
      const initShowCustom = !!d.custom_questions;
      const query = new URLSearchParams(search);
      let initTab = query.get('tab') || 'category';

      if (!initShowCategory && initTab === 'category' || !initShowCustom && initTab === 'custom') {
        initTab = 'brand';
      }

      setTab(initTab);
    });
  }, []);

  const reports = [
    {
      title: 'Brand Snapshot Exports',
      name: 'brand_snapshot_export',
      kinds: [ 'pptx', 'xlsx' ],
      showComparisonOptions: true
    }
  ];
  if (init.isInternal) {
    reports.push({ title: 'Disqualified Respondents Report', name: 'disqualified', kinds: [ 'xlsx' ] });
    reports.push({ title: 'All Respondents Answers', name: 'brand_snapshot_all_respondents', kinds: [ 'xlsx' ] });
    reports.push({ title: 'Quota Details', name: 'quotas_details', kinds: [ 'xlsx' ] });
  }

  const forceDropDown = getForceOpenFilterDropDown();

  return (
    <div className="content -loading-wrapper tour-parent-header">
      {data.project_id && <Header reports={ reports } />}
      <div className="content_body">
        {loading && <div className="page-loader -inner-loader" />}
        <div className="comparisons">
          {!data.project_id && !loading && <> Project isn&apos;t ready </>}
          {
            data.project_id &&
            <>
              <NInfo n={ data.n } init={ init } />
              <TopFilter
                projectId={ init.projectId }
                data={ data }
                loadData={ loadData }
                forceDropDown={ forceDropDown }
                classNameTop="-js-filter"
              />
              {(showCategory || showCustom) &&
              <Tabs
                tab={ tab }
                setTab={ setTab }
                showCategory={ showCategory }
                showCustom={ showCustom }
              />}
              { customizedQuestionData && <CustomizeQuestionsModal brand /> }
              <WithLangData langCode={ data.language_code } langName={ data.language_name }>
                {
                  (tab === "brand" || (tab === 'custom' && data.has_survey_open_metrics)) &&
                  <div className="comparisons_tabs">
                    <ExpressLanguageSwitcher
                      description={ data.translations.language_switcher_description }
                      showLanguageSwitcher={ data.show_language_switcher }
                    />
                  </div>
                }
                {tab === "category" && <TabCategory data={ data } /> }
                {tab === "brand" && <TabBrand data={ data } /> }
                {tab === "custom" && <TabCustom data={ data } /> }
              </WithLangData>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default AppInner;
