import Http from '../../../common/http';

const getClients = (
  goodCallback, badCallback
) => {
  Http.get('/express/api/clients', {}).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

const addSub = (
  mainId, subId, goodCallback, badCallback
) => {
  Http.post(`/express/api/clients/${mainId}/add_subclient`, { sub_id: subId }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

const deleteSub = (
  mainId, subId, goodCallback, badCallback
) => {
  Http.post(`/express/api/clients/${mainId}/delete_subclient`, { sub_id: subId }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

const addCredits = (
  client, amount, goodCallback, badCallback
) => {
  Http.post(`/express/api/clients/${client.id}/add_credits`, { amount }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

const removeCredits = (
  client, amount, goodCallback, badCallback
) => {
  Http.post(`/express/api/clients/${client.id}/delete_credits`, { amount }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

export { getClients, addSub, deleteSub, addCredits, removeCredits };
