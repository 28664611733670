import React, { useContext } from 'react';
import classnames from 'classnames';
import BBPagination from "../../../../../common/components/pagination";
import { allProjectsGridViewClick, allProjectsListViewClick } from "../../../../common/google-analytics/events";
import { IndexData } from "./contexts";

const sortingTitles = {
  title: {
    asc: 'title ascending',
    desc: 'title descending'
  },
  created_at: {
    asc: 'created date ascending',
    desc: 'newly added'
  },
  express: {
    asc: 'type ascending',
    desc: 'type descending'
  },
  closed_at: {
    asc: 'closed date ascending',
    desc: 'closed date descending'
  },
  in_field_at: {
    asc: 'in field date ascending',
    desc: 'in field date descending'
  },
  status_text: {
    asc: 'status ascending',
    desc: 'status descending'
  },
  respondents_count: {
    asc: 'completes ascending',
    desc: 'completes descending'
  },
  required_nsize: {
    asc: 'target completes ascending',
    desc: 'target completes descending'
  },
  client_name: {
    asc: 'client name ascending',
    desc: 'client name descending'
  }
};

const PlateOptions = ({ mode, setMode, sort, dir, total, perPage, page, setPage }) => {
  const { gaTrackingId } = useContext(IndexData);
  const currentSorting = () => {
    if (!!sort && !!dir && !!sortingTitles[sort] && !!sortingTitles[sort][dir]) {
      return sortingTitles[sort][dir];
    } else if (!!sort && !!dir) {
      return `${sort} ${dir}`;
    }

    return sortingTitles.created_at.desc;
  };

  const changeMode = (mode) => {
    if (mode === "list") {
      allProjectsListViewClick(gaTrackingId);
    } else {
      allProjectsGridViewClick(gaTrackingId);
    }
    setMode(mode);
  };

  return (
    <div className="row-plate-options">
      <div className="static-text">
        Sort by: { currentSorting() }
      </div>

      {
        total > perPage &&
        <div className="projects_pagination">
          <BBPagination
            totalRecords={ total }
            perPage={ perPage }
            current={ page }
            pageClicked={ (p) => { setPage(p); } }
            className="projects_pagination"
          />
        </div>
      }

      <div className="mode-buttons">
        <input id="projects-view-cards" type="radio" value="cards" />
        <label
          htmlFor="projects-view-cards"
          className={ classnames({ '-active': mode === 'cards' }) }
          onClick={ () => { changeMode('cards'); } }
        >
          <svg className="icon -burger">
            <use xlinkHref="#svg_squares" />
          </svg>
          Grid
        </label>
        <input id="projects-view-list" type="radio" value="list" />
        <label
          htmlFor="projects-view-list"
          className={ classnames({ '-active': mode === 'list' }) }
          onClick={ () => {
            changeMode('list');
          } }
        >
          <svg className="icon -burger">
            <use xlinkHref="#svg_burger" />
          </svg>
          List
        </label>
      </div>
    </div>
  );
};

export default PlateOptions;
