import React, { useContext } from 'react';
import { map } from 'lodash';
import { IndexData } from "../../common/index-context";
import Stimuli from '../../../respondent/verizon_customer_case_v2024/components/stimuli';
import Checkbox from "../components/checkbox";
import { baseContext } from "../../../respondent/common/contexts";

const StudyDetails = () => {
  const { data } = useContext(IndexData);
  const { model, setModel } = useContext(baseContext);
  const stimuliData = data?.methodology?.stimuli_data;

  return (
    <>
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Case Description</h2>
        <Checkbox
          key={ `study_details-report` }
          title="Add to Report"
          value={ model.case_description }
          paramName="case_description"
          onChange={ (v) => { setModel({ ...model, case_description: v }); } }
        />
      </div>
      {data.methodology &&
        <div className="comparisons_plates">
          <div className="comparisons_plates-grid">
            <div className="comparisons_plates-grid-item -no-right-padding">
              <div className="comparisons_plate -full-height">
                <h3 className="comparisons_plate-title -bordered">What Respondents Reacted To:</h3>
                <div className="stimuli-preview">
                  <Stimuli
                    ideaName={ stimuliData.idea_name }
                    customerBenefit={ stimuliData.customer_benefit }
                    reasonsToBelieve={ stimuliData.reasons_to_believe }
                    processLeadInOptions={ stimuliData.process_lead_in_options }
                    processLeadIn={ stimuliData.process_lead_in }
                    processLeadInOther={ stimuliData.process_lead_in_other }
                    processStepsKind={ stimuliData.process_steps_kind }
                    steps={ stimuliData.steps }
                    additionalImageUrl={ stimuliData.additional_image_url }
                    additionalImageNotes={ stimuliData.additional_image_notes }
                    branded={ stimuliData.branded }
                  />
                </div>
              </div>
            </div>
            <div className="comparisons_plates-grid-item -one-third -no-left-padding">
              <div className="comparisons_plate -full-height -bordered">
                <h3 className="comparisons_plate-title -bordered">Study Details:</h3>

                <div className="comparisons_details">
                  <div className="comparisons_details-item">
                    <h4 className="comparisons_details-name">
                      Project Lead
                    </h4>
                    <div className="comparisons_details-value">
                      { data.methodology.project_lead }
                      {
                        !!data.methodology.cmi_leads.length &&
                        <>
                          <br />
                          CMI: { data.methodology.cmi_leads.join(', ') }
                        </>
                      }
                    </div>
                  </div>
                  <div className="comparisons_details-item">
                    <h4 className="comparisons_details-name">
                      Field Dates
                    </h4>
                    <div className="comparisons_details-value">
                      {data.methodology.in_field_at || 'N/A'} – {data.methodology.closed_at || 'N/A'}
                    </div>
                  </div>
                  <div className="comparisons_details-item">
                    <h4 className="comparisons_details-name">
                      Methodology
                    </h4>
                    <div className="comparisons_details-value">
                      {map(data.methodology.methodology_text_items, (text, index) => (
                        <p key={ index }>{ text }</p>
                      ))}
                    </div>
                  </div>
                  <div className="comparisons_details-item">
                    <h4 className="comparisons_details-name">
                      Sample Qualification
                    </h4>
                    <div className="comparisons_details-value">
                      <div className="sample-texts">
                        {
                          map(data.methodology.sample_qualification_text_items, (text, index) => (
                            <p key={ index }>{ text }</p>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="comparisons_details-item">
                    <h4 className="comparisons_details-name">
                      Journey Stage
                    </h4>
                    <div className="comparisons_details-value">
                      { data.journey_stage }
                    </div>
                  </div>
                  <div className="comparisons_details-item">
                    <h4 className="comparisons_details-name">
                      Case ID
                    </h4>
                    <div className="comparisons_details-value">
                      { data.case_id }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default StudyDetails;
