import React, { useContext } from 'react';
import { filter, map } from 'lodash';
import classnames from "classnames";
import StatusCircle from "../../../common/components/status-circle";
import { getMetricKind, IndexData } from "../../../common/index-context";
import { hideCustom, showedAverageValue } from '../../../common/helpers';

const Header = ({ text, checkedConcepts }) => {
  return (
    <tr className="-highlighted">
      <td
        colSpan={ checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1 }
      >
        { text }
      </td>
      {
        checkedConcepts.length > 3 &&
          <td colSpan={ checkedConcepts.length + 1 - 3 } />
      }
      <td className="table-comparison_average-cell -highlighted" />
    </tr>
  );
};

const ValuesRow = ({ title, checkedConcepts, data, init, averageMode, sort }) => {
  const metric = data.analytics[title];

  if (!metric) {
    return (null);
  }

  return (
    <tr>
      <td className="table-comparison_name-cell">
        <span className={ classnames({ 'sort-link -current': title === sort }) }>
          {title}
        </span>
      </td>

      {
        map(checkedConcepts, (concept, index) => {
          return (
            <td key={ `lr-${concept.id}-${index}` }>
              <StatusCircle
                values={ metric[concept.id] }
                box="top2"
                hideCustom={ hideCustom(metric[concept.id].avg_hide_for_custom, averageMode) }
              />
            </td>
          );
        })
      }

      <td className="table-comparison_average-cell">
        { showedAverageValue(metric, averageMode, false, init.isInternal, data.view) }
      </td>
    </tr>
  );
};

const TrLeftRight = ({ groupName }) => {
  const { init, data, concepts, averageMode, sort } = useContext(IndexData);

  const checkedConcepts = filter(concepts, (el) => el.checked);

  return (
    <>
      <Header text={ getMetricKind(data, groupName) } checkedConcepts={ checkedConcepts } />

      {
        map(data.left_right_answers, (answerInfo) => (
          <ValuesRow
            key={ answerInfo.key }
            title={ answerInfo.title }
            checkedConcepts={ checkedConcepts }
            data={ data }
            init={ init }
            sort={ sort }
            averageMode={ averageMode }
          />
        ))
      }
    </>
  );
};

export default TrLeftRight;
