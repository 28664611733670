import React, { useState, useRef, useEffect } from 'react';
import { find } from "lodash";
import classnames from 'classnames';
import LoadingPage from "../common/screens/loadingPage";
import Benefits from "../common/screens/benefits";
import IdCheck from "../common/screens/idCheck";
import PPECashbackCategory from "../common/screens/ppeCashbackCategory";
import PPEAutoReload from "../common/screens/ppeAutoreload";
import { GlobalContext } from '../common/contexts/appContexts';
import Splash from '../common/screens/splash';
import CardToWallet from '../common/screens/cardToWallet';
import Celebration from '../common/screens/celebration';
import CloseFollowUp from '../common/components/closeFollowUp';
import Welcome from '../common/screens/welcome';
import SignInSignUp from '../common/screens/signInSignUp';
import AppInterface from '../common/screens/appInterface';
import { addSeenScreenNames } from '../common/helpers/stepsHelper';
import CompletedModal from "../common/components/completedModal";
import IntroSetup from './reboarding/components/introSetup';
import { fakeData } from './reboarding/fakeData';
import { initialData, sendData, finishingData } from './statistics';

const ReboardingBauApp = () => {
  const [ stepsHistory, setStepsHistory ] = useState([]);
  const [ baseInnerSteps, setBaseInnerSteps ] = useState([]);
  const [ innerStep, setInnerStep ] = useState(null);
  const [ showFollowUpModal, setShowFollowUpModal ] = useState(false);
  const [ showCompletedModal, setShowCompletedModal ] = useState(false);

  const currentDataRef = useRef({
    ...initialData(),
    ...{
      firstName: fakeData.firstName,
      lastName: fakeData.lastName,
      address: fakeData.address
    }
  });

  const mergeData = (newHash) => {
    currentDataRef.current = { ...currentDataRef.current, ...newHash };
  };

  const checkIfAllSetup = () => (
    currentDataRef.current.cashbackCategoryChosen &&
      currentDataRef.current.autoReload &&
      currentDataRef.current.cardIssued &&
      currentDataRef.current.pinSet
  );

  const goAway = (addition = {}) => {
    mergeData(addition || {});
    setShowCompletedModal(true);
    setStep(null);
  };

  const [ stepsOrderList ] = useState(() => (
    [
      {
        step: 'splash',
        nextStep: (previousStep) => ('welcome'),
        skipStep: null
      },
      {
        step: 'welcome',
        nextStep: (previousStep) => ('sign_in-sign_up'),
        skipStep: null
      },
      {
        step: 'sign_in-sign_up',
        nextStep: (previousStep) => ('intro_setup'),
        skipStep: null
      },
      {
        step: 'intro_setup',
        nextStep: (previousStep) => ('id_check'),
        skipStep: () => ('benefits')
      },
      {
        step: 'benefits',
        nextStep: (previousStep) => ('id_check'),
        skipStep: () => ('app_interface')
      },
      {
        step: 'id_check',
        nextStep: (previousStep) => ('ppe_loading'),
        skipStep: () => () => ('app_interface')
      },
      {
        step: 'ppe_loading',
        nextStep: (previousStep) => ('ppe_cashback_category'),
        skipStep: null
      },
      {
        step: 'ppe_cashback_category',
        nextStep: (previousStep) => ('ppe_autoreload'),
        skipStep: () => ('ppe_autoreload')
      },
      {
        step: 'ppe_autoreload',
        nextStep: (previousStep) => ('card_to_wallet'),
        skipStep: () => ('card_to_wallet')
      },
      {
        step: 'card_to_wallet',
        nextStep: (previousStep) => (checkIfAllSetup() ? 'full_celebration' : 'low_celebration'),
        skipStep: () => (checkIfAllSetup() ? 'full_celebration' : 'low_celebration')
      },
      {
        step: 'low_celebration',
        nextStep: (previousStep) => {
          goAway();
          return null;
        },
        skipStep: null
      },
      {
        step: 'full_celebration',
        nextStep: (previousStep) => {
          goAway({ fullSetup: true });
          return null;
        },
        skipStep: null
      },
      {
        step: 'app_interface',
        nextStep: (previousStep) => {
          goAway({
            homeScreenShown: true,
            homeScreenFrom: previousStep
          });
          return null;
        },
        skipStep: null
      }
    ]
  ));
  const [ step, setStep ] = useState(stepsOrderList[0].step);

  useEffect(() => {
    addSeenScreenNames(currentDataRef.current, step, innerStep);
  }, [ step, innerStep ]);

  const goForward = () => {
    const currentStepInfo = find(stepsOrderList, (stepsItem) => (stepsItem.step === step));
    if (currentStepInfo?.nextStep) {
      const previousStep = stepsHistory.length ? stepsHistory[stepsHistory.length - 1] : null;
      stepsHistory.push(currentStepInfo.step);
      setStepsHistory([ ...stepsHistory ]);
      setStep(currentStepInfo?.nextStep(previousStep));
      return;
    }
    setStep(null);
  };

  const goBack = () => {
    if (stepsHistory.length) {
      setStep(stepsHistory[stepsHistory.length - 1]);
      stepsHistory.splice(stepsHistory.length - 1, 1);
      setStepsHistory([ ...stepsHistory ]);
      return;
    }
    setStep(null);
  };

  const goSkip = () => {
    const currentStepInfo = find(stepsOrderList, (stepsItem) => (stepsItem.step === step));
    if (currentStepInfo?.skipStep) {
      stepsHistory.push(currentStepInfo.step);
      setStepsHistory([ ...stepsHistory ]);
      setStep(currentStepInfo?.skipStep());
      return;
    }

    setStep(null);
  };

  const handleInterruptApp = () => {
    goAway({
      interruptAppYesClick: true,
      interruptAppTopStep: step,
      interruptAppInnerStep: innerStep
    });
    setShowFollowUpModal(false);
  };

  const handleInterruptAppSkip = () => {
    mergeData({ interruptAppNoClick: true });
    setShowFollowUpModal(false);
  };

  const handleFinishStimuli = () => {
    sendData(finishingData(currentDataRef.current));
    setShowCompletedModal(false);
  };

  const globalContextValue = {
    fakeData,
    nextStep: goForward,
    prevStep: goBack,
    skipStep: goSkip,
    currentData: (currentDataRef.current),
    closeFullApp: () => {
      mergeData({ closeAppClick: true });
      setShowFollowUpModal(true);
    },
    mergeData, innerStep, setInnerStep,
    baseInnerSteps, setBaseInnerSteps
  };
  return (
    <GlobalContext.Provider value={ globalContextValue }>
      <div className={ classnames("container", { "-whole-white": !step }) }>
        { step === 'splash' && <Splash /> }
        { step === 'welcome' && <Welcome reboarding /> }
        { step === 'sign_in-sign_up' && <SignInSignUp /> }
        {
          step === 'intro_setup' &&
          <IntroSetup headerStep={ 0 } numberOfHeaderSteps={ 0 } />
        }
        { step === 'benefits' && <Benefits headerStep={ 0 } numberOfHeaderSteps={ 0 } /> }
        { step === 'id_check' && <IdCheck forceBack headerStep={ 1 } numberOfHeaderSteps={ 1 } /> }
        {
          step === 'ppe_loading' &&
          <LoadingPage
            title="Getting you ready to earn cash back and pay in stores..."
            duration={ 10000 }
            delay={ 500 }
          />
        }
        {
          step === 'ppe_cashback_category' &&
          <PPECashbackCategory headerStep={ 1 } numberOfHeaderSteps={ 1 } />
        }
        {
          step === 'ppe_autoreload' &&
          <PPEAutoReload headerStep={ 1 } numberOfHeaderSteps={ 1 } />
        }
        {
          step === 'card_to_wallet' &&
          <CardToWallet skipNotifications headerStep={ 1 } numberOfHeaderSteps={ 1 } />
        }
        {
          step === 'full_celebration' &&
          <Celebration
            title={ `All set, ${currentDataRef.current.firstName}! You’re ready to earn cash back, pay in stores, and more.` }
            text={ `Plus, you got your ${fakeData.cashBackBonus} cash back bonus. Happy spending.` }
          />
        }
        {
          step === 'low_celebration' &&
          <Celebration
            title={ `All set, ${currentDataRef.current.firstName}!` }
            text="You’re ready to pay in stores, earn cash back, and more. Time to explore."
          />
        }
        { step === 'app_interface' && <AppInterface /> }
        {
          showFollowUpModal &&
          <CloseFollowUp onCloseYes={ handleInterruptApp } onCloseNo={ handleInterruptAppSkip } />
        }
        {
          showCompletedModal &&
          <CompletedModal returnSurvey={ handleFinishStimuli } />
        }
      </div>
    </GlobalContext.Provider>
  );
};

export default ReboardingBauApp;
