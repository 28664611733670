import { each } from 'lodash';
import { getLinesCount } from "../common/statistics";
import {
  allPlansInclude, planFeatures,
  perkTitles, perkFeatures, pricingLinesDescription
} from '../common/common_data';

const pricing = [
  [ 0, 0, 0, 0, 0 ], [], [], [],
  [ 65, 55, 40, 30, 25 ],
  [ 75, 65, 50, 40, 35 ]
];

const priceForLine = (planId) => {
  if (!planId) {
    return null;
  }
  return pricing[planId - 1][getLinesCount() - 1];
};

const perkHeader = (plan, selectedPerks) => {
  if (!plan) {
    return "Choose your optional perks for $10/mo. each";
  }
  const nextPerkToSelect = selectedPerks.indexOf(null);
  if (nextPerkToSelect !== -1) {
    return `Choose your optional perks for $${plan.perksPrice[nextPerkToSelect]}/mo. each`;
  }
  return `Choose your optional perks for $${plan.perksPrice[plan.perksPrice.length - 1]}/mo. each`;
};
const perkHeaderNote = (plan) => {
  const perksCount = plan ? plan.perksCount : 2;
  let numInText;
  switch (perksCount) {
    case 1:
      numInText = 'one perk';
      break;
    case 2:
      numInText = 'two perks';
      break;
    default:
      numInText = plan.perksCount;
  }
  return `Add up to ${numInText} to your line.`;
};

const planA = {
  id: 5,
  name: "Unlimited 5G",
  pricePlateKlass: '-little-margin',
  features: [
    { ...planFeatures.Unlimited5G, ...{ title: null } }
  ],
  perksHeaderNote: "Add up to two perks to your line.",
  perksCount: 2,
  perksPrice: [ 10, 10 ],
  noSelectButton: true
};

const planB = {
  id: 6,
  name: "Unlimited 5G Ultra",
  pricePlateKlass: '-little-margin',
  features: [
    { ...planFeatures.Unlimited5GUltra, ...{ title: null } },
    planFeatures.PlusGet800ToNewPhone
  ],
  perksCount: 2,
  perksPrice: [ 10, 10 ],
  noSelectButton: true
};

const entertainmentPerk = {
  id: 1,
  list: [
    perkFeatures.DisneyBundle
  ]
};

const entertainmentStorePerk = {
  id: 2,
  list: [
    perkFeatures.EntertainmentStore12
  ]
};

const musicPerk = {
  id: 3,
  list: [
    perkFeatures.AppleMusic
  ]
};

const gamingPerk = {
  id: 4,
  list: [
    perkFeatures.GameXBox,
    perkFeatures.GameArcade
  ]
};

const globalCallingPerk = {
  id: 5,
  list: [
    perkFeatures.GlobalCalling
  ]
};

const internationalRoamingPerk = {
  id: 6,
  list: [
    perkFeatures.Roaming
  ]
};

const workAnywherePerk = {
  id: 7,
  list: [
    perkFeatures.AnyWhereMobile,
    perkFeatures.AnyWhereWatch
  ]
};

const securityPerk = {
  id: 8,
  list: [
    perkFeatures.SecurityMCAfee,
    perkFeatures.SecurityRing,
    perkFeatures.SecurityVerizon
  ]
};

const homeInternetPerk = {
  id: 9,
  list: [
    perkFeatures.HomeInternet25
  ]
};

const plans = [
  planA,
  planB
].map((el) => ({
  ...el,
  get price() { return pricing[this.id - 1][getLinesCount() - 1]; }
}));

const perkList = [
  entertainmentPerk,
  entertainmentStorePerk,
  musicPerk,
  gamingPerk,
  globalCallingPerk,
  internationalRoamingPerk,
  workAnywherePerk,
  securityPerk,
  homeInternetPerk
];
each(perkList, (item, index) => {
  item.title = perkTitles[index];
});

const cell9 = {
  plansUpNote: "Choose between access to our 5G or 5G Ultra networks",
  plans,
  getPlanPriceByLine: (plan, currentLine) => (plan.price),
  allPlansInclude, pricing,
  perks: perkList,
  perksSectionTitleDynamic: perkHeader,
  perksSectionTextDynamic: perkHeaderNote,
  perksNoRestriction: true,
  linesDescription: pricingLinesDescription,
  linesEmptyPlanTitle: 'Select network'
};

export { cell9, priceForLine  };
