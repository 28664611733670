import { getLinesCount } from '../common/statistics';

const allPlansIncludeConcept = [
  {
    id: 1,
    title: 'Unlimited talk & text',
    description: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.'
  },
  {
    id: 2,
    title: '5G Nationwide / 4G LTE',
    description: "Get unlimited access to our 5G Nationwide / 4G LTE network which provides the high speeds and reliability you've come to expect. On 5G Start plan and Welcome Unlimited plan, your data may be temporarily slower than other traffic in times of congestion."
  },
  {
    id: 4,
    title: 'Mexico & Canada talk, text & data',
    description: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.'
  },
  {
    id: 5,
    title: 'International texting',
    description: 'Unlimited texting from the US to over 200 countries and territories worldwide.'
  }
];

const fiveGUltraWideBand = {
  title: "5G Ultra Wideband",
  description: "5G Ultra Wideband is the fastest 5G network access we offer. Download apps, games, entire playlists and TV series in seconds. You'll also get 5G Ultra Wideband mobile hotspot and crystal-clear 4K Ultra High Definition video streaming when activated on a capable device. Plus access experiences from our exclusive 5G partners: Snapchat, Live Nation, Riot Games, and Niantic, launching throughout 2022. For full details, visit the <u>5G experience page</u> <span class=\"verizon-description-link\">(https://www.verizon.com/5g/)</span>.<br/><br/>5G Ultra Wideband available only in parts of select cities and access requires a 5G Ultra Wideband-capable device inside the 5G Ultra Wideband coverage area. Depending on location, uploading is performed over 5G Ultra Wideband or 4G LTE."
};

const fiveGNationWide = {
  title: "5G Nationwide",
  description: "Get unlimited access to our 5G Nationwide network. When combined with 5G Ultra Wideband, you’ll get our fastest performance. Requires a 5G-capable device inside the 5G Nationwide coverage area. When network is experiencing heavy traffic, your data may be temporarily slowed."
};

const towardsNewPhone800 = {
  title: 'Get up to $800 towards a new phone',
  description: 'Trade-in your old device and get up to $800 towards the purchase of a new phone.',
  className: '-towardsNewPhone800',
  icon: 'label'
};

const earlyPhoneUpgrades = {
  title: 'Early phone upgrades',
  description: 'Trade in your phone after 18 months to qualify for our best trade-in promotions towards the purchase of a new phone.',
  className: '-earlyPhoneUpgrades',
  icon: 'phone'
};

const verizonHomeInternet25 = {
  title: 'Verizon Home Internet for $25/mo',
  description: 'Get up to 50% off your Verizon 5G Home or LTE Home internet plan, or get Fios internet and save with the Mobile + Home Discount. Both as low as $25/mo when enrolled in Auto Pay, where service is available.',
  className: '-verizonHomeInternet25',
  icon: 'home-wifi'
};

const verizonHomeInternet25Description = {
  ...verizonHomeInternet25,
  title: "Verizon Home Internet for $25 a month"
};

const mobileHotspot25 = {
  title: '25 GB mobile hotspot',
  description: 'Get high-speed 5G/4G hotspot data that your other devices can use. Your smartphone becomes a Wi-Fi connection for devices like tablets, laptops, and more.<br/><br/>During times of congestion, 5G Nationwide / 4G LTE hotspot data may be temporarily slower than other traffic after 25 GB/mo of 5G Nationwide / 4G LTE smartphone data. After exceeding 25 GB/mo of 5G Ultra Wideband, 5G Nationwide, or 4G LTE data, you can still use hotspot at lower speeds of 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G Nationwide / 4G LTE for the rest of the month. 5G access requires a 5G-capable device.',
  className: '-mobileHotspot25',
  icon: 'mobile-signal'
};

const yearPriceGuarantee5 = {
  title: '5-year price guarantee',
  description: 'Your plan price is guaranteed not to increase for the next five years.',
  className: '5-year price guarantee',
  icon: 'dollar-in'
};

const PERK_PRICE = 10;
const concept_a_pricing = [
  [],
  [],
  [],
  [],
  [],
  [ 75, 65, 50, 40, 35 ],
  [ 75, 65, 50, 40, 35 ],
  [ 75, 65, 50, 40, 35 ],
  [ 75, 65, 50, 40, 35 ],
  [ 65, 55, 40, 30, 25 ]
];

const premiumCore = {
  id: 1,
  title: 'Premium Unlimited',
  description: 'Our fastest 5G network experience that never slows you down.',
  descriptions: [
    fiveGUltraWideBand,
    towardsNewPhone800,
    earlyPhoneUpgrades,
    verizonHomeInternet25Description,
    mobileHotspot25,
    yearPriceGuarantee5
  ],
  shortDescription: 'Our fastest 5G network experience that never slows you down.',
  subtitle: 'Premium savings',
  perks: [
    towardsNewPhone800,
    earlyPhoneUpgrades,
    verizonHomeInternet25,
    mobileHotspot25,
    yearPriceGuarantee5
  ]
};

const standardCore = {
  id: 2,
  title: 'Standard Unlimited',
  description: 'Reliable 5G performance for daily use.',
  descriptions: [
    fiveGNationWide,
    earlyPhoneUpgrades,
    yearPriceGuarantee5
  ],
  shortDescription: 'Reliable 5G performance for daily use.',
  subtitle: 'Basic savings',
  perks: [
    earlyPhoneUpgrades,
    yearPriceGuarantee5
  ]
};

const disneyPlus = {
  id: 1,
  title: 'Disney+',
  shortDescription: 'Includes Disney+, Hulu, and ESPN+.<br/>',
  description: '$14 monthly value<br/><br/>Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places.<br/><br/>One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices.',
  price: 14,
  icon: 'disney_plus.png'
};
const appleOne = {
  id: 2,
  title: 'AppleOne',
  shortDescription: 'Apple Music, Apple Arcade, Apple TV+, and iCloud+ all with HD streaming.',
  description: '$15 monthly value<br/><br/>Apple Music, Apple Arcade, Apple TV+, and iCloud+ all with HD streaming.<br/><br/>Everything Apple, all in one place. Enjoy access to Apple Music, Apple TV+, Apple Arcade, and Apple iCloud+ with up to three other people.<br/><br/>You\'ll also get high-definition 720p video streaming.',
  price: 15,
  icon: 'apple-one.png'
};
const playMonthlyCredit = {
  id: 3,
  title: '+play monthly credit',
  shortDescription: '$15/mo to spend at your discretion in Verizon\'s +play streaming store.',
  description: '$15 monthly value<br/><br/>$15/mo to spend at your discretion in Verizon\'s +play streaming store.<br/>+play allows you to discover, purchase and manage your favorite subscriptions across entertainment, audio, gaming, fitness, music, lifestyle and more – all in one place.',
  price: 15,
  icon: 'plus-play.jpg'
};
const gaming = {
  id: 4,
  title: 'Gaming',
  shortDescription: 'Apple Arcade or Google Play Pass + Xbox Game Pass + Premium Streaming. ',
  description: '$25 monthly value<br/><br/>Apple Arcade or Google Play Pass + Xbox Game Pass + Premium Streaming.<br/><br/>Choice between:<ul class="gaming-popup-list"><li>Apple Arcade subscription included. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. A $4.99/month value.</li><li class="no-style">OR</li><li>Google Play Pass subscription included along with Play Points Gold Status. Your pass to hundreds of games and apps in one subscription. Available on Android. A $4.99/month value.</li><li class="no-style">AND</li><li>Xbox Game Pass, which Includes access to over 100 high-quality games for console, PC, and Android mobile devices from the cloud (Beta), all the benefits of Xbox Live Gold, and an EA Play membership. $14.99/month value</li><li>Premium streaming supports streaming up to 1080p HD on smartphones and tablets, and up to 4k when mirrored to larger screens using a cable.</li></ul>',
  price: 25,
  icon: 'gaming.jpg'
};
const internationalRoaming = {
  id: 5,
  title: 'International roaming',
  shortDescription: '10 GB high-speed data in 210 countries.',
  description: '$20 monthly value<br/><br/>10 GB high-speed data in 210 countries. Get 10 GB of the highest speed internet possible when abroad in 210+ countries. (Service must be maintained for a 6-month consecutive period, and cannot be swapped with other available services.)',
  price: 20,
  icon: 'int-travel.jpg'
};
const secureAndStore = {
  id: 6,
  title: 'Secure & store',
  shortDescription: '600 GB of Verizon Cloud storage, & our award winning Mobile Secure.',
  description: '$12 monthly value<br/><br/>600 GB of Verizon Cloud storage, & our award winning Mobile Secure.<br/>Wirelessly back up and sync your contacts, photos, videos, messages & documents to the Verizon Cloud. 600GB included with this plan.<br/><br/>Mobile Secure includes Digital Secure, Call Filter, and Tech Coach. With the Digital Secure app, you can protect your data, avoid risky websites and receive ID theft alerts. With Call Filter, get spam and robocall control. Tech Coach expert support provides personalized tech support for your mobile device and virtually everything it connects to.',
  price: 12,
  icon: 'secure-store.jpg'
};
const homeSecurity = {
  id: 7,
  title: 'AARP & home security',
  shortDescription: 'AARP membership, roadside assistance & Ring Protect Plus.',
  description: '$16 monthly value<br/><br/>AARP membership, roadside assistance & Ring Protect Plus.<br/><br/>AARP provides access to hundreds of benefits that help you live your best life. From health and financial tools, volunteering opportunities, travel and restaurant discounts, to everything in between.<br/><br/>Verizon Roadside Assistance, provides 24/7 support across America and Canada.<br/><br/>Ring Protect Plus includes video recording for one doorbell or camera.',
  price: 16,
  icon: 'aarp-security.jpg'
};
const walmart = {
  id: 8,
  title: 'Walmart+',
  shortDescription: 'Annual membership and $0.20/gal discount at Walmart fuel stations.',
  description: '$15 monthly value<br/><br/>Annual membership and $0.20/gal discount at Walmart fuel stations.<br/><br/>As a Walmart+ member, you’ll enjoy benefits including free delivery from your store, member prices on fuel at select stations, and use of mobile scan & go, which enables you to scan items as you shop in-store, streamlining your checkout process.<br/><br/>Also get a $0.20/gal discount at any Walmart fuel station.',
  price: 15,
  icon: 'walmart.jpg'
};
const mobileHotspot100 = {
  id: 9,
  title: '100 GB mobile hotspot',
  shortDescription: '100 GB of extra mobile hotspot, and 50% off a connected device plan.',
  description: '$20 monthly value<br/><br/>100 GB of extra mobile hotspot, and 50% off a connected device plan.<br/><br/>Get an additional 100 GB of high-speed 5G/4G hotspot data that your other devices can use. Your smartphone becomes a Wi-Fi connection for devices like tablets, laptops, and more.<br/><br/>After exceeding 100 GB/mo of 5G Ultra Wideband, 5G Nationwide, or 4G LTE data, you can still use hotspot at lower speeds of 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G Nationwide / 4G LTE for the rest of the month. 5G access requires a 5G-capable device.<br/><br/>Get up to 50% off one eligible plan for a qualifying smartwatch, tablet, hotspot, or Hum in-car Wi-Fi device. Eligible plans and discounts: 50% off Unlimited and Unlimited Plus for tablets; Essential and Plus plans for mobile hotspot devices; Unlimited for HumX; Unlimited + In Car WiFi for Hum+; and Unlimited and Number Share Unlimited for smartwatches. $20 off Premium and Pro plans for mobile hotspot devices.',
  price: 20,
  icon: 'mobile-hotspot.jpg'
};

const allPerksFlatten = [
  disneyPlus, appleOne,
  playMonthlyCredit, gaming,
  internationalRoaming, secureAndStore,
  homeSecurity, walmart,
  mobileHotspot100
];

const entertainmentPlan = {
  id: 6,
  title: "Built for <b>entertainment</b>",
  name: "Built for entertainment",
  popular: true,
  core: premiumCore,
  perks: [
    disneyPlus,
    mobileHotspot100
  ]
};

const gamingPlan = {
  id: 7,
  title: "Built for <b>gaming</b>",
  name: 'Built for gaming',
  popular: false,
  core: premiumCore,
  perks: [
    gaming
  ]
};

const shoppingPlan = {
  id: 8,
  title: "Built for <b>shopping</b>",
  name: 'Built for shopping',
  popular: false,
  core: premiumCore,
  perks: [
    walmart
  ]
};

const premium = {
  id: 9,
  name: 'Premium Unlimited',
  popular: false,
  core: premiumCore,
  perks: []
};

const standard = {
  id: 10,
  name: 'Standard Unlimited',
  popular: false,
  core: standardCore,
  perks: []
};


const concept_plans_prebuild = [
  entertainmentPlan,
  gamingPlan,
  shoppingPlan
];
const concept_plans_construct = [
  premium,
  standard
];
const concept_a_perk_groups = [
  {
    title: 'Entertainment',
    perks: [
      disneyPlus,
      appleOne,
      playMonthlyCredit,
      gaming
    ]
  },
  {
    title: 'International',
    perks: [
      internationalRoaming
    ]
  },
  {
    title: 'Home & security',
    perks: [
      secureAndStore,
      homeSecurity
    ]
  },
  {
    title: 'Shopping & connection on the go',
    perks: [
      walmart,
      mobileHotspot100
    ]
  }
];

const CONCEPT_A = {
  allPerksFlatten,
  PERK_PRICE,
  plans_prebuild: concept_plans_prebuild.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_a_pricing[el.id - 1][getLinesCount() - 1] + (el.perks.length * PERK_PRICE);
    },
    get price() {
      return concept_a_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  plans_construct: concept_plans_construct.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_a_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_a_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  concept_a_perk_groups,
  allPlansIncludeConcept
};

export {
  CONCEPT_A,
  concept_plans_prebuild, concept_plans_construct,
  concept_a_perk_groups,
  allPlansIncludeConcept,
  fiveGNationWide,
  yearPriceGuarantee5,
  earlyPhoneUpgrades,
  allPerksFlatten,
  PERK_PRICE
};
