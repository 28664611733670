import React from "react";
import { NavLink } from "react-router-dom";
import classnames from 'classnames';
import {
  FOR_YOU_COUNTER_KEY,
  EXPLORE_COUNTER_KEY,
  SCHEDULE_COUNTER_KEY,
  ACTIVITY_COUNTER_KEY
} from "../counter";
import Icon from "./Icon";

const routes = [
  {
    path: "/for-you",
    key: FOR_YOU_COUNTER_KEY,
    icon: "for-you",
    title: "For You"
  },
  {
    path: "/explore",
    key: EXPLORE_COUNTER_KEY,
    icon: "world",
    title: "Explore"
  },
  {
    path: "/schedule",
    key: SCHEDULE_COUNTER_KEY,
    icon: "schedule",
    title: "Schedule"
  },
  {
    path: "/activity",
    key: ACTIVITY_COUNTER_KEY,
    icon: "activity",
    title: "Activity"
  }
];

const Navigation = () => (
  <nav className="navigation">
    {routes.map((route) => (
      <NavLink
        className={
          ({ isActive }) => (
            classnames('navigation_link', { '-active': isActive }
            )
          )
        }
        data-counter-key={ route.key }
        key={ route.key }
        to={ route.path }
      >
        <Icon name={ route.icon } />
        <div className="navigation_title">{route.title}</div>
      </NavLink>
    ))}
  </nav>
);

export default Navigation;
