import React, { useContext, useState } from "react";
import { sumBy } from "lodash";
import { StateContext, LineDataContext, SetContext, PlanDataProvider } from "../common/contexts";
// eslint-disable-next-line import/no-named-as-default
import { Lines } from '../common/lines';
import {
  setLinesCount,
  setCurrentLine,
  getCurrentLine,
  setHashData,
  setPlan,
  sendData,
  getLinesCount,
  createLine,
  anyLineContainsHybridPlan
} from "../common/statistics";
import Page from '../common/page';
import Loader from '../common/loader';
import OrderPage from '../common/order';
import WelcomeOverview from "../concept_c/welcome_overview";
import Fork from "../concept_c/fork";
import { scrollTop } from '../common/helper';
import OverviewPage from './overview_page';

const states = [
  "Lines",
  "Overview",
  "Order",
  "Fork",
  "WelcomeOverview"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = ({ welcomePlans }) => {
  const [ , updatePath ] = useContext(StateContext);
  const {
    perks, plans_prebuild, plans_construct,
    allPlansInclude, getForkList
  } = useContext(PlanDataProvider);
  const [ planStartTime, setPlanStartTime ] = useState(null);
  const { delayAnimation, buildYourOwnFirst } = useContext(SetContext);
  const [ loading, setLoading ] = useState(false);
  const data = {
    setLoading,
    allPlansInclude
  };
  // this state is need to update value in subheader after plan select slick with delay animation

  const handleLinesNextStep = (event, linesCount) => {
    setPlanStartTime(new Date());
    updatePath("Order");
    setLinesCount(linesCount);
    setCurrentLine(1);
  };

  const updatePlanData = (planObject, line, timeAfterStart) => {
    const price = planObject.price + sumBy(planObject.perks, (item) => item.priceValue || 0);
    setPlan({ ...planObject, price }, line);
    setHashData(`lines.${line}.planSelectTime`, timeAfterStart);
    setHashData(`lines.${line}.planPerkIds`, planObject.perks.map(({ id }) => id));
    setPlanStartTime(new Date());
  };

  const handlePlanSelect = (planObject, setPlanToAllLines, clearAllOtherLines) => {
    const timeAfterStart = Math.ceil(
      new Date(new Date() - planStartTime - delayAnimation).getTime() / 1000
    );
    if (setPlanToAllLines) {
      const lineList = Array(getLinesCount()).fill(1).map((el, idx) => el + idx);
      for (const line of lineList) {
        updatePlanData(planObject, line, timeAfterStart);
      }
    } else if (clearAllOtherLines) {
      const lineList = Array(getLinesCount()).fill(1).map((el, idx) => el + idx);
      const currentLine = getCurrentLine();
      for (const line of lineList) {
        line === currentLine
          ? updatePlanData(planObject, line, timeAfterStart)
          : createLine(line);
      }
    } else {
      updatePlanData(planObject, getCurrentLine(), timeAfterStart);
    }
    updatePath("Order");
    scrollTop();
  };

  const handlePlanReselect = (clickedLine) => {
    scrollTop();
    const hybridPlansSelectedForLines = anyLineContainsHybridPlan(
      plans_prebuild, plans_construct
    );
    const newPath = hybridPlansSelectedForLines ? "Overview" : "Fork";
    updatePath(newPath);
    setCurrentLine(clickedLine);
  };

  const handleNavigate = (path, cb) => () => {
    scrollTop();
    updatePath(path);
    if (cb) {
      cb();
    }
  };

  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Lines">
        <Lines nextStep={ handleLinesNextStep } />
      </Route>

      <Route path="Overview">
        <Page>
          <OverviewPage
            onPlanSelect={ handlePlanSelect }
            buildYourOwnFirst={ buildYourOwnFirst }
            onSeeAllPlans={ handleNavigate("Fork") }
          />
        </Page>
      </Route>

      <Route path="WelcomeOverview">
        <Page>
          <WelcomeOverview
            plans={ welcomePlans }
            onSeeAllPlans={ handleNavigate("Fork") }
            onPlanSelect={ handlePlanSelect }
            currentLine={ getCurrentLine() }
            linesCount={ getLinesCount() }
          />
        </Page>
      </Route>

      <Route path="Fork">
        <Page>
          <Fork
            getForkList={ getForkList }
            onPrebuildPlansSelect={ handleNavigate("Overview", () => setPlanStartTime(new Date())) }
            onWelcomePlanSelect={ handleNavigate("WelcomeOverview", () => setPlanStartTime(new Date())) }
          />
        </Page>
      </Route>

      <Route path="Order">
        <Page>
          <OrderPage
            usePerksPriceValue
            plans={ [ ...plans_prebuild, ...plans_construct, ...welcomePlans ] }
            perks={ perks.map((group) => group.perks).reduce((acc, list) => [ ...acc, ...list ], []) }
            nextStep={ () => {
              sendData();
              updatePath("Reset");
            } }
            reselectPlan={ handlePlanReselect }
          />
        </Page>
      </Route>

      { loading && <Loader /> }
    </LineDataContext.Provider>
  );
};

export { Router, initialState };
