import { cloneDeep } from 'lodash';
import { getLinesCount } from '../common/statistics';
import {
  concept_plans_prebuild, concept_plans_construct,
  concept_a_perk_groups, allPlansIncludeConcept,
  yearPriceGuarantee5, earlyPhoneUpgrades, fiveGNationWide,
  allPerksFlatten
} from '../concept_a/plans_data';

import { imageUrl } from '../common/helper';

const taxPhrase = 'Plus taxes & fees.';

const PERK_PRICE = 10;
const concept_b_pricing = [
  [],
  [],
  [],
  [],
  [ 65, 55, 40, 30, 25 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 80, 70, 55, 45, 40 ],
  [ 70, 60, 45, 35, 30 ]
];

const getForkList = (onExploreNonWelcomePlans, onExploreWelcomePlans) => ([
  {
    title: "Choose from our best plans, or build your own.",
    description: "Everyone gets control over their network needs and their perks.",
    features: [
      { title: "Get up to $800 towards a new phone", icon: "checkmark" },
      { title: "Early phone upgrades", icon: "checkmark" },
      { title: "Verizon home internet for $25/mo", icon: "checkmark" }
    ],
    images: [ imageUrl('disney_plus.png'), imageUrl('walmart.jpg'), imageUrl('apple-one.png') ],
    exploreButtonName: "Explore plans",
    clickExplore: onExploreNonWelcomePlans
  },
  {
    title: "An entry-level unlimited plan for all your lines.",
    description: "Enjoy reliable 5G performance with Welcome Unlimited.",
    features: [
      { title: "Unlimited talk, text and data", icon: "checkmark" },
      { title: "Our best price for an Unlimited plan ever", icon: "checkmark" },
      { title: "Not eligible for device offers", icon: "none" }
    ],
    exploreButtonName: "Explore plan",
    clickExplore: onExploreWelcomePlans
  }
]);

const Nationwide5GStart = {
  title: '5G Nationwide',
  subtitle: 'Reliable 5G performance for daily use.',
  description: 'Get unlimited access to our 5G Nationwide network. When combined with 5G Ultra Wideband, you’ll get our fastest performance. Requires a 5G-capable device inside the 5G Nationwide coverage area. When network is experiencing heavy traffic, your data may be temporarily slowed.'
};

const TryPerks6 = {
  featuresListTitle: 'Try Disney+, Apple Arcade or Google Play Pass, and Apple Music for 6 months on&nbsp;us.',
  title: 'Try Disney+, Apple Arcade or Google Play Pass, and Apple Music for 6 months on&nbsp;us',
  description: '<b>Disney+</b><br/>All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic. First 6 months on us, then $7.99/mo plus tax after offer ends. One subscription can be shared with everyone on your account. Cancel anytime.<br/><br/><b>Apple Music</b><br/>With Apple Music, you can play 75 million songs ad-free, online or off. Immerse yourself in spatial audio with Dolby Atmos. And unlock amazing definition with lossless audio. A $9.99/mo value.<br/><br/><b>Apple Arcade or Google Play Pass</b><br/>Enjoy unlimited access to 180+ incredibly fun games across all your Apple devices, with no ads and no in-app purchases. After offer ends, Apple bills $4.99/mo plus tax. Cancel anytime.<br/><br/>Google Play Pass is your ticket to hundreds of awesome games and apps, completely free of ads and in-app purchases. Explore a curated collection with favorites like Stardew Valley and the Monument Valley series to award-winning indies like LIMBO and Dead Cells. $4.99/mo plus tax after offer ends. Cancel anytime.'
};

const welcomeUnlimited = {
  id: 5,
  name: "Welcome Unlimited",
  description: "Get the basics.",
  newPhoneText: 'Switch, keep your phone and<br/>we’ll help cover the cost.',
  newPhoneClasses: '-info-icon',
  base: true,
  featuresTitle: "Unlimited 5G",
  features: [
    Nationwide5GStart
  ],
  perksTitle: 'Perks to try, on us',
  perks: [
    TryPerks6
  ],
  taxPhrase,
  get price() { return concept_b_pricing[this.id - 1][getLinesCount() - 1]; },
  selectTip: 'This plan will be applied to all phone lines on your account.'
};

const upTowardsToNewPhone400 = {
  title: 'Get up to $400 towards a new phone',
  description: 'Trade-in your old device and get up to $400 towards the purchase of a new phone.',
  className: '-towardsNewPhone800',
  icon: 'label'
};

const mobileHotspot5 = {
  title: '5 GB mobile hotspot',
  description: 'Get high-speed 5G/4G hotspot data that your other devices can use. Your smartphone becomes a Wi-Fi connection for devices like tablets, laptops, and more.<br/><br/>During times of congestion, 5G Nationwide / 4G LTE hotspot data may be temporarily slower than other traffic after 5 GB/mo of 5G Nationwide / 4G LTE smartphone data. After exceeding 5 GB/mo of 5G Ultra Wideband, 5G Nationwide, or 4G LTE data, you can still use hotspot at lower speeds of 3 Mbps when on 5G Ultra Wideband and 600 Kbps when on 5G Nationwide / 4G LTE for the rest of the month. 5G access requires a 5G-capable device.',
  className: '-mobileHotspot25',
  icon: 'mobile-signal'
};

const concept_plans_construct_copy = cloneDeep(concept_plans_construct);

const [ , standard ] = concept_plans_construct_copy;

standard.core.descriptions = [
  fiveGNationWide,
  upTowardsToNewPhone400,
  earlyPhoneUpgrades,
  mobileHotspot5,
  yearPriceGuarantee5
];

standard.core.perks = [
  upTowardsToNewPhone400,
  earlyPhoneUpgrades,
  mobileHotspot5,
  yearPriceGuarantee5
];

const CONCEPT_C = {
  allPerksFlatten,
  PERK_PRICE,
  welcomePlans: [ welcomeUnlimited ],
  plans_prebuild: concept_plans_prebuild.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1] + (el.perks.length * PERK_PRICE);
    },
    get price() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  plans_construct: concept_plans_construct_copy.map((el) => ({
    ...el,
    get priceTitle() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1];
    },
    get price() {
      return concept_b_pricing[el.id - 1][getLinesCount() - 1];
    }
  })),
  concept_c_perk_groups: concept_a_perk_groups,
  allPlansIncludeConcept,
  getForkList
};

export {
  CONCEPT_C,
  getForkList,
  welcomeUnlimited,
  concept_plans_construct_copy as concept_plans_construct
};
