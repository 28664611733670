import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../common/render_raw_html';
import { LineDataContext, SetContext, PageContext } from '../../common/contexts';
import { Header } from '../../common/header';
import { Subheader } from '../../common/subheader';
import { BAU } from '../../common/plans_data';

const { compareList } = BAU;

const ComparePlanHead = ({
  plan, onPlanSelect
}) => {
  const { delayAnimation } = useContext(SetContext);
  const {
    getClickedSelectButton,
    setClickedSelectButton
  } = useContext(PageContext);
  const { setLoading } = useContext(LineDataContext);
  const clickedSelectButton = getClickedSelectButton();

  const clickSelectButton = (event, plan) => {
    setClickedSelectButton(plan.id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setClickedSelectButton(null);
      onPlanSelect(event, plan);
    }, delayAnimation);
  };

  return (
    <div className="compare-table_cell -head">
      <div className="plan-short">
        <h2 className="plan-short_title ng-binding" { ...renderRawHtml(plan.name) } />
        <div className="plan-short_description" { ...renderRawHtml(plan.description) } />
        <div className="plan-short_pricing">
          <div className="plan-short_pricing-grid">
            <h2 className="plan-short_pricing-value">${ plan.price }</h2>
            <div className="plan-short_pricing-hint">
              <div className="plan-short_pricing-hint-line">/line per month</div>
              <div className="plan-short_pricing-hint-line">{ plan.taxPhrase }</div>
            </div>
          </div>
        </div>
        <button
          className={
            classnames(
              'button -bordered -full-width plan-select-button',
              { '-clicked': clickedSelectButton }
            )
          }
          onClick={ (event) => { clickSelectButton(event, plan); } }
          disabled={ clickedSelectButton && clickedSelectButton !== plan.id }
        >
          Select
        </button>
      </div>
    </div>
  );
};

const ComparePlanRow = ({ row, plans, rowGroupId }) => {
  const [ open, setOpen ] = useState(false);
  const updateVisibility = () => {
    setOpen(!open);
  };

  return (
  // it's need to devide row to 2 rows for aligning in cell
    <div className="compare-table_row-wrapper">
      <div className="compare-table_row -top" key={ `comparePlanRow-${row.id}-line1` }>
        {
          map(plans, (plan, index) => {
            let planCompFeatureDescr = false;
            if (plan.compare && plan.compare[rowGroupId] && plan.compare[rowGroupId][row.id]) {
              planCompFeatureDescr = plan.compare[rowGroupId][row.id].about ? plan.compare[rowGroupId][row.id].about.description : "";
            }
            return (
              <div className={ `compare-table_cell -cell-${index}` } key={ `comparePlanItem${plan.id}` } >
                <div className="compare-feature">
                  <div className="compare-feature_name">
                    <div className={ `compare-feature_header -cell-${index}` } onClick={ updateVisibility }>
                      <span className="compare-feature_title">{row.title}</span>
                      <span className={ `compare-feature_caret ${open ? "-open" : ""}` }>
                        <svg className="icon -caret">
                          <use xlinkHref="#svg_caret" />
                        </svg>
                      </span>
                    </div>
                    {planCompFeatureDescr && open && (
                      <div className="compare-feature_description" { ...renderRawHtml(planCompFeatureDescr) } />
                    )}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="compare-table_row -bottom" key={ `comparePlanRow-${row.id}-line2` }>
        {
          map(plans, (plan, index) => {
            let planCompFeatureValue = false;
            let planCompFeatureNote = false;

            if (plan.compare && plan.compare[rowGroupId] && plan.compare[rowGroupId][row.id]) {
              planCompFeatureValue = plan.compare[rowGroupId][row.id].value;
              planCompFeatureNote = plan.compare[rowGroupId][row.id].about ? plan.compare[rowGroupId][row.id].about.note : "";
            }

            return (
              <div className={ `compare-table_cell -cell-${index}` } key={ `comparePlanItem${plan.id}` } >
                <div className="compare-feature">
                  <div className="compare-feature_value">
                    <div className="compare-feature_value_icon">
                      {planCompFeatureValue ? (
                        <svg className="icon -checkmark-alt">
                          <use xlinkHref="#svg_checkmark-alt" />
                        </svg>
                      ) : (
                        <svg className="icon -close">
                          <use xlinkHref="#svg_close" />
                        </svg>
                      )}
                    </div>
                    {planCompFeatureNote && (
                      <div className="compare-feature_value_note" { ...renderRawHtml(planCompFeatureNote) } />
                    )}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

const ComparePage = ({ updatedCurrentLine, onPlanSelect, toOverview }) => {
  const { plans } = useContext(LineDataContext);
  return (
    <div className="content">
      <Header />
      <Subheader currentLine={ updatedCurrentLine } />
      <div className="tabs">
        <div className="tab" onClick={ () => {toOverview();} }>Overview</div>
        <div className="tab -active">Compare</div>
      </div>
      <div className="compare-page -bau">
        <div className="inner-content -bau -no-pt -base -percent">
          <div className="compare-table">
            <div className="compare-table_row -head">
              {
                map(plans, (plan) => {
                  return (
                    <ComparePlanHead
                      key={ `comparePlanItem${plan.id}` }
                      plan={ plan }
                      onPlanSelect={ onPlanSelect }
                    />
                  );
                })
              }
            </div>

            {
              map(compareList, (compareRowGroup, index) => {
                return (
                  <div className="compare-table_group" key={ `comparePlanRowGroup-${compareRowGroup.id}` }>
                    <div className={ `compare-table_group-header -group-${index}` }>
                      <h4 className="compare-table_group-title">{compareRowGroup.title}</h4>
                    </div>
                    {compareRowGroup.subtitles && (
                      <div className="compare-table_row -subtitles">
                        {
                          map(plans, (plan) => {
                            let subtitle = false;
                            if (plan.compare && plan.compare[compareRowGroup.id] && plan.compare[compareRowGroup.id].subtitle) {
                              subtitle = plan.compare[compareRowGroup.id].subtitle;
                            }
                            return (
                              <div className="compare-table_cell -subtitle" key={ `comparePlanSubtitleItem${plan.id}` }>
                                {subtitle.title && <h4 className="compare-table_group-subtitle-title">{subtitle.title}</h4>}
                                {subtitle.description && <div className="compare-table_group-subtitle-description">{subtitle.description}</div>}
                              </div>
                            );
                          })
                        }
                      </div>
                    )}
                    {
                      map(compareRowGroup.list, (compareRow, indexRow) => {
                        return (
                          <ComparePlanRow row={ compareRow } key={ indexRow } plans={ plans } rowGroupId={ compareRowGroup.id } />
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePage;
