import React, { useState, useEffect } from 'react';
import cn from "classnames";

const DropdownButton = ({ headerButton, innerClassName, listClassName, buttonName, loading, children }) => {
  const [ , setIntention ] = useState(false);
  const [ opened, setOpened ] = useState(false);

  useEffect(() => {
    const body = document.body || document.querySelector('body');
    const handler = () => {
      let currentIntention = false;
      setIntention((oldIntention) => {
        currentIntention = oldIntention;
        return false;
      });
      if (!currentIntention) {
        setOpened(false);
      }
    };
    body.addEventListener('click', handler);
    return () => {
      body.removeEventListener('click', handler);
    };
  }, []);

  const openState = opened;

  const handleToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    setOpened(!opened);
  };

  return (
    <div
      className={ cn('dropdown', { 'header_button': headerButton }, { 'open': openState }) }
      onClick={ () => { setIntention(true); } }
    >
      <button
        className={ cn('dropdown_toggle button -dropdown', innerClassName) }
        onClick={ handleToggle }
      >
        { buttonName }
      </button>
      {
        openState &&
        <div className={ cn("dropdown_container -corner-right", listClassName) }>
          <div className="dropdown_inset">
            <div className="dropdown_content">
              <ul className="reports-list">
                { children }
              </ul>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default DropdownButton;
