import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { rotate, afterHalf, canDelete } from '../helpers';
import HintInPopup from '../../../common/hint-in-popup';
import Loader from '../../../../../../../images/icons/loader.gif';
import { destroyProject } from '../../requests';
import ProjectSharingButtons from "./project-sharing-buttons";
import ProjectDemoShare from './project-demo-share';
import ExpressIcon from "./express-icon";
import { IndexData, BaseContext } from "./contexts";
import CopyProject from './copy-project';

const ProjectAsCard = ({ project, isAdmin, mode  }) => {
  const [ loading, setLoading ] = useState(false);
  const [ showCopyForm, setShowCopyForm ] = useState(false);
  const { loadProjects, clientId, demoUser } = useContext(IndexData);
  const { buzzbackUser } = useContext(BaseContext);
  const handleOpenCopyForm = () => {
    setShowCopyForm(true);
  };
  const handleCloseCopyForm = () => {
    setShowCopyForm(false);
  };

  return (
    <div className={ classnames("projects_item", { "-copying": project.copying }) }>
      <div className="project-preview projects_item-inner">
        <div className="project-header">
          <div className="project-preview_line">
            <div className="project-preview_title">
              <ExpressIcon express={ project.express } />
              <span className="-js-project-title">
                { project.title }
                {
                  buzzbackUser && !!project.projectNumber &&
                  <em>({ project.projectNumber })</em>
                }
              </span>
            </div>
            {
              isAdmin &&
              <div className="project-preview_line-client">Client: {project.client_name}</div>
            }
          </div>

          <div className="project-date-time">
            <HintInPopup
              hintText={ project.created_at_hint }
              dropClassName="-bottom-center -date"
            >
              { project.created_at_text } • {project.express_text}
            </HintInPopup>
          </div>

          {
            project.copying &&
            <div className="project-preview_line">
              <div className="project-preview_grid">
                <div className="project-preview_grid-item">
                  Project is copying...
                </div>
              </div>
            </div>
          }
          {
            !project.copying &&
            <div className="project-preview_line">
              <div className="project-preview_grid">
                <div className="project-preview_grid-item">
                  {
                    (!project.remote_respondents || project.force_edit_project) &&
                    <div className="project-preview_link-wrap">
                      <a className="project-preview_link" href={ project.edit_path }>
                        {
                          project.remote_respondents ?
                            <>Edit Project</> :
                            <>Project Setup</>
                        }
                      </a>
                      {
                        project.remote_respondents &&
                        <span className="project-preview_link-addition">&nbsp;(Limited)</span>
                      }
                    </div>
                  }
                  <div className="project-preview_link-wrap">
                    <a className="project-preview_link" href={ project.report_path }>
                      Report
                    </a>
                  </div>
                  {
                    (project.can_review_respondents) &&
                    <div className="project-preview_link-wrap">
                      <a className="project-preview_link" href={ project.review_respondents_path }>
                        Review Respondents
                      </a>
                    </div>
                  }
                  {
                    (project.can_manage_concept_tags) &&
                    <div className="project-preview_link-wrap">
                      <a className="project-preview_link" href={ project.concept_tags_path }>
                        Concept Tags
                      </a>
                    </div>
                  }
                  {
                    !!project.can_edit_survey_flow &&
                    <div className="project-preview_link-wrap">
                      <a
                        className="project-preview_link"
                        href={ project.edit_survey_flow }
                      >
                        Edit Survey Flow
                      </a>
                    </div>
                  }
                  {
                    project.can_preview &&
                    <div className="project-preview_link-wrap">
                      <a
                        className="project-preview_link"
                        target="blank"
                        href={ project.survey_path }
                      >
                        Preview
                      </a>
                    </div>
                  }
                </div>
                {
                  !!project.required_nsize &&
                  <div className="project-preview_grid-item">

                    <div className={ classnames('project-preview_chart', { '-after-half': afterHalf(project) }) }>
                      <div className="project-preview_chart-part -static" />
                      <div
                        className="project-preview_chart-part -rotated"
                        style={ { transform: rotate(project) } }
                      />
                      <div className="project-preview_chart-start-point" />
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>

        <div className="project-preview_line">
          <div className="project-preview_grid">
            <div className="project-preview_grid-item -status-and-sharing">
              <div className={ classnames("project-preview_status", `-${project.status_name}`) }>
                { project.status }
              </div>
              {
                project.show_sharing &&
                <ProjectSharingButtons project={ project } mode={ mode } />
              }
              {
                project.show_demo &&
                <ProjectDemoShare project={ project } mode={ mode } />
              }
            </div>

            <div className="project-preview_grid-item">
              <div className="project-preview_count">
                <svg className="icon -project-user project-preview_count-icon">
                  <use xlinkHref="#svg_project-user" />
                </svg>
                <span>{ project.respondent_count }</span>
                {
                  !!project.required_nsize &&
                  <span className="project-preview_count-of">of { project.required_nsize }</span>
                }
              </div>
            </div>
          </div>
        </div>

        {
          !demoUser &&
          <div className="dropdown -actions-menu">
            <svg
              className="icon -vertical-vector"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <use xlinkHref="#svg_vertical-vector" />
            </svg>


            <div className="dropdown_container -corner-right">
              <div className="dropdown_inset">
                <div className="dropdown_content">
                  <ul>
                    <li>
                      <a onClick={ handleOpenCopyForm }>Copy Project</a>
                    </li>
                    {
                      canDelete(isAdmin, project, clientId) &&
                      <li className="-red">
                        <a
                          onClick={ () => {
                            destroyProject(
                              project.title,
                              project.destroy_path,
                              project.status === "Unpublished",
                              setLoading,
                              loadProjects
                            );
                          } }
                        >
                          Delete Project
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
        {
          loading &&
          <div className="dropdown -actions-menu">
            <img width="24px" height="24px" src={ Loader } />
          </div>
        }
        {
          showCopyForm &&
          <CopyProject project={ project } onClose={ handleCloseCopyForm } />
        }
      </div>
    </div>
  );
};

export default ProjectAsCard;
