import React, { useContext, useState } from "react";
import { map } from "lodash";
import classnames from "classnames";
import Slider from '../common/common_slider';
import { Accordion } from "../common/accordion";
import PlanPerk from "../common/planPerk";
import { perkImageUrl, prepareText, sumAndFormatCheckFractional } from "../common/helper";
import { PlanDataProvider } from "../common/contexts";
import { increaseVariableForCurrentLine } from '../common/statistics';
import { LineTotal } from "./line_total";
import { PerkVariantsTable } from "./perk_variants_table";

export const PerkDetails = ({
  perk,
  onPerkChange,
  isPerkChecked,
  price,
  save,
  onVariantChange,
  variantsSelected,
  hideTotalInDetails,
  popularPlan
}) => {
  const { allPerksFlatten } = useContext(PlanDataProvider);

  const [ currentPerk, setCurrentPerk ] = useState(perk);

  const details = currentPerk.details;
  const variants = currentPerk.variants ? currentPerk.variants : (
    [ { title: currentPerk.title, price: currentPerk.price, save: currentPerk.save } ]
  );

  const perkIndex = (perkId) => {
    return (
      allPerksFlatten.findIndex((item) => item.id === perkId)
    );
  };

  const customSettings = {
    initialSlide: perkIndex(currentPerk.id),
    afterChange: (index) => {
      setCurrentPerk(allPerksFlatten[index]);
    },
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: true
        }
      }
    ]
  };

  const ContentOutput = ({ content }) => (
    prepareText({ texts: content.text, type: content.type })
  );

  const DescriptionOutput = ({ description }) => (prepareText({ texts: description }));

  const handleOnSwipe = () => {
    increaseVariableForCurrentLine("perksSwipePerksInDetails");
  };

  return (
    <div className="perk-details">
      {
        !hideTotalInDetails &&
        <div className="perk-details_total">
          <LineTotal price={ price } save={ save } />
        </div>
      }

      {/*carousel with perk plates or current plate (for readonly mode) */}
      {
        onPerkChange ? (
          <div className="perk-details_carousel-wrapper">
            <Slider
              className="perk-details_carousel"
              customSettings={ customSettings }
              onSwipe={ handleOnSwipe }
            >
              {
                map(allPerksFlatten, (perk, idx) => (
                  <div className="perk-details_carousel-item" key={ `perks-details-perk-${idx}` }>
                    <PlanPerk
                      perk={ perk }
                      className={ `-in-modal` }
                      showPrice
                      isPerkChecked={ isPerkChecked }
                      onPerkChange={ onPerkChange }
                      variantsSelected={ variantsSelected }
                      onVariantChange={ onVariantChange }
                      fromDetails
                      popularPlan={ popularPlan }
                    />
                  </div>
                ))
              }
            </Slider>
          </div>
        ) : (
          <div className="perk-details_plate-wrapper">
            <PlanPerk
              perk={ perk }
              className={ `-in-modal` }
              showPrice
              isPerkChecked={ isPerkChecked }
              onPerkChange={ onPerkChange }
              variantsSelected={ variantsSelected }
              onVariantChange={ onVariantChange }
              popularPlan={ popularPlan }
            />
          </div>
        )}


      {/* includes */}
      {
        details?.include &&
        <div className="perk-details_include">
          <h4 className="perk-details_include-title perk-details_h4">
            What's included
          </h4>

          { details.includeForVariant && <PerkVariantsTable perk={ currentPerk } /> }

          <ul className="perk-details_include-list">
            {
              map(details.include, (include, idx) => (
                <li key={ `indlude-${idx}` } className="perk-details_include-list-item">
                  <div className="perk-details_include-item">
                    {
                      (!!include.img || !!include.icon || !!include.title) &&
                      <div className="perk-details_include-item-top">
                        {
                          !!include.img &&
                          <div className={ `perk-details_include-item-icon ${include.imgBorder ? '-bordered' : ''}` }>
                            <img
                              src={ perkImageUrl(include.img) }
                              className="perk-details_include-item-icon-src"
                              alt={ include.title || "" }
                            />
                          </div>
                        }
                        {
                          !!include.icon &&
                          <div
                            className={
                              classnames(
                                `perk-details_include-item-icon icon -${include.icon}`,
                                { [include.iconKlass]: include.iconKlass }
                              )
                            }
                          >
                            <svg
                              className={
                                classnames(
                                  `perk-details-description-icon icon -${include.icon}`,
                                  { [include.iconKlass]: include.iconKlass }
                                )
                              }
                            >
                              <use xlinkHref={ `#svg_${include.icon}` } />
                            </svg>
                          </div>
                        }
                        {
                          !!include.title &&
                          <div className="perk-details_include-item-title">
                            {include.title}
                          </div>
                        }
                      </div>
                    }
                    {
                      !!include.description &&
                      <div className="perk-details_include-item-content">
                        <DescriptionOutput description={ include.description } />
                      </div>
                    }
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      }

      {/* prices */}
      <div className="perk-details_prices">
        <div className="perk-details_prices-title">Verizon savings</div>
        <div className="perk-details_prices-sets">
          {
            map(variants, (variant, idx) => (
              <div className="perk-details_prices-set" key={ idx }>
                <div className="perk-details_prices-line">
                  <div className="perk-details_prices-name">
                    {variant.title}
                  </div>
                  <div className="perk-details_prices-price">
                    ${sumAndFormatCheckFractional(variant.price, variant.save)}/mo
                  </div>
                </div>
                <div className="perk-details_prices-line -bold">
                  <div className="perk-details_prices-name">
                    You pay
                  </div>
                  <div className="perk-details_prices-price">
                    ${variant.price}/mo
                  </div>
                </div>
                <div className="perk-details_prices-line -bold -accent">
                  <div className="perk-details_prices-name">
                    You save
                  </div>
                  <div className="perk-details_prices-price">
                    ${variant.save}/mo
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      {/* banner */}
      {
        details?.banner &&
        <div className="perk-details_banner">
          <img src={ perkImageUrl(details.banner) } className="perk-details_banner-src" alt={ currentPerk.title } />
          <img src={ perkImageUrl("fpo.png") } className="perk-details_banner-over" alt="" />
        </div>
      }

      {/* important info */}
      {
        details?.important &&
        <div className="perk-details_important">
          <h5 className="perk-details_important-title perk-details_h5">
            {details.important.title || `Important information`}
          </h5>
          <div className="perk-details_important-content perk-details_text">
            <ContentOutput content={ details.important } />
          </div>
        </div>
      }

      {/* terms & conditions */}
      {
        details?.terms &&
        <div className="perk-details_terms">
          <Accordion
            list={ [ { title: "Terms and conditions", description: details.terms } ] }
            className="-btn-full"
            perkId={ currentPerk?.id }
          />
        </div>
      }

      {/* questions */}
      {
        details?.faq &&
        <div className="perk-details_faq">
          <h5 className="perk-details_faq-title perk-details_h4">
            Have questions?
            <br />
            We’ve got answers.
          </h5>
          <Accordion
            list={ details.faq }
            className="-btn-full"
            perkId={ currentPerk?.id }
          />
        </div>
      }
    </div>
  );
};
