import React, {useContext, useRef} from "react";
import classnames from "classnames";
import {find, map} from "lodash";
import renderRawHtml from "../../../../common/render_raw_html";
import ZoomImage from "./zoom_image";
import { OptionLabelOutput } from "./optionLabelOutput";
import {baseContext} from "../../common/contexts";
import { plainText } from "../../../../common/string_utils";

const SingleOutput = ({ question, answers, radioAnswer, updateRadio, otherAnswer, updateOtherText }) => {

  const isOther = (id) => ((id || '').match(/_-2/));
  const isNone = (id) => ((id || '').match(/_-1/));
  const haveOther = find(answers, (answer) => isOther(answer.id));
  const withAnswerImages = question.has_images;
  const answerImages = question.answer_images;

  const otherTextRef = useRef([]);
  const { translations } = useContext(baseContext);

  const getImage = (id) => (
    answerImages?.find((item) => item?.id === id)?.image_url
  );

  const radioChange = (value) => {
    updateRadio(value);
  };

  const radioClick = (event, answerId, focusNext) => {
    // for click only by mouse (and not by keyboard) add condition 'event.clientX > 0'
    if (focusNext && isOther(answerId)) {
      setTimeout(() => {
        focusNext.focus();
      }, 0);
    }
  };

  return (
    <ul
      className={
        classnames(
          "survey-options_list",
          {"-full-width": haveOther || withAnswerImages}
        )
      }
      role="radiogroup"
      aria-label={plainText(question.text) || plainText(question.title)}
    >
      {
        map(answers, (answer, idx) => (
          <li
            key={answer.id}
            className={
              classnames(
                {'option-top-border': isNone(answer.id) || (isOther(answer.id) && withAnswerImages)},
                {'option-full-width': isOther(answer.id)}
              )
            }
          >
            <div
              className={
                classnames(
                  "option-list-inner",
                  {"-more-right-padding": withAnswerImages},
                  {"-no-left-right-padding": isOther(answer.id)}
                )
              }
            >
              {
                (!question.custom || !getImage(answer.id)) &&
                <div
                  className={
                    classnames(
                      "radio",
                      {"-full-width": isOther(answer.id) || withAnswerImages},
                    )
                  }
                >
                  <input
                    type="radio"
                    name={question.name}
                    value={answer.id}
                    id={answer.id}
                    checked={answer.id === radioAnswer}
                    onChange={() => {
                      radioChange(answer.id);
                    }}
                    onClick={(event) => {
                      radioClick(event, answer.id, otherTextRef.current[idx]);
                    }}
                  />
                  {
                    isOther(answer.id) &&
                    <label
                      className={
                        classnames(
                          "radio_label -centered",
                          {"-full-width": isOther(answer.id)}
                        )
                      }
                      htmlFor={answer.id}
                    >
                      <div className="other-text_wrapper">
                        <div className="other-text_title" {...renderRawHtml(`${answer.label}:`)} />
                        <input
                          type="text"
                          name={`${question.name}-other`}
                          ref={(el) => otherTextRef.current[idx] = el}
                          className="js-other-text form-field -other-text"
                          disabled={answer.id !== radioAnswer}
                          value={otherAnswer}
                          onChange={updateOtherText}
                          aria-label={ translations.enter_text_specify }
                          autoComplete="off"
                        />
                      </div>
                    </label>
                  }
                  {isNone(answer.id) && <OptionLabelOutput answer={answer} forLabel={answer.id}/>}
                  {
                    !isOther(answer.id) && !isNone(answer.id) &&
                    <OptionLabelOutput
                      answer={answer}
                      forLabel={answer.id}
                      withImages={withAnswerImages}
                      images={answerImages}
                    />
                  }
                </div>
              }
              {
                question.custom && getImage(answer.id) &&
                <div className="radio-plate -base" role="group">
                  <div className="radio-plate_part -no-padding -right-margin -clickable">
                    <div className="radio">
                      <input
                        type="radio"
                        name={ question.name }
                        value={answer.id}
                        id={answer.id}
                        checked={answer.id === radioAnswer}
                        aria-label={answer.label || plainText(question.text)}
                        onChange={() => {
                          radioChange(answer.id);
                        }}
                      />
                      <label htmlFor={answer.id} className="radio_label -no-text">
                        <span className="radio_label_hidden-text" aria-hidden={true}>{answer.label || plainText(question.text)}</span>
                      </label>
                    </div>
                  </div>
                  <div className="radio-plate_part-image">
                    <div className="radio-plate_part-title"
                      aria-hidden={true}
                      {...renderRawHtml(answer.label)}
                    />
                    <div className="survey-options_image-plate -fix-width">
                      <ZoomImage
                        className="radio-plate_part-image"
                        popupClassName="-custom-image-in-modal"
                        src={getImage(answer.id)}
                        altText={answer.label || plainText(question.text)}
                      >
                        <img src={getImage(answer.id)} alt={answer.label || plainText(question.text)}/>
                      </ZoomImage>
                    </div>
                  </div>
                </div>
              }
            </div>
          </li>
        ))
      }
    </ul>
  );
};

export { SingleOutput };