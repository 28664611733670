import React, { useContext, useState, useEffect } from 'react';
import { includes, find, filter, cloneDeep } from "lodash";
import { Header } from '../common/header';
import { PlanDataProvider, BackContext, ModalContext, LineDataContext } from '../common/contexts';
import {
  getCurrentLine, setHashDataForTemplatePlan, getCustomBuiltDataFromCurrentLine
} from '../common/statistics';
import {
  scrollTop,
  planIsCore,
  findPerkVariantInTheOtherLine,
  openConflictModal
} from '../common/helper';
import { Subheader } from "../common/subheader";
import { OurBestPlans } from "../components/best_plans";
import { BuildYourOwnPlan } from "../components/build_own_plan";
import { SelectPerks } from "../components/select_perks";

const OverviewPage = ({ onPlanSelect,  overviewOptions }) => {
  const { buildYourOwnFork, editFromOrder } = overviewOptions;
  const {
    allPlansInclude, plans_prebuild,  plans_construct,
    getDoubleAddConflictMessages, allPerksFlatten
  } = useContext(PlanDataProvider);
  const { buildYourOwnClick } = useContext(LineDataContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const [ defaultBuildYourOwnPlan ] = useState(() => (
    find(plans_construct || [], (planItem) => planItem.defaultForBuildYourOwn))
  );
  const [ linesData ] = useState(() => (
    getCustomBuiltDataFromCurrentLine(
      plans_prebuild, plans_construct, allPerksFlatten, null,
      { takeNoData: !editFromOrder }
    )
  ));
  const initialPlanToEdit = linesData.selectedPlan;
  const initialPerksToEdit = linesData.perks || [];
  const [ selectedPlan, setSelectedPlan ] = useState(initialPlanToEdit);
  const [ initialPerks, setInitialPerks ] = useState(initialPerksToEdit);
  const { planPerkVariants, planCustomBuilt, specialCustom, baseCustom, originalPlanId } = linesData;

  const [ currentStage, setCurrentStage ] = useState(() => {
    if (planCustomBuilt && !!selectedPlan && !specialCustom && !baseCustom) {
      return "selectPerks";
    } else if (buildYourOwnFork || planIsCore(selectedPlan, plans_construct) || specialCustom || baseCustom) {
      return "buildYourOwn";
    }
    return "bestPlans";
  });

  const handleBestPlanSelect = (plan, variantsHash) => {
    onPlanSelect({ ...plan, variantsHash, ...{ popularPlan: true } });
  };

  const handlePopularCustomize = (plan) => {
    handleCustomize(plan);
  };

  const newPlanWithTurnedOffPerk = (plan, perkDoubleAddConflictName) => {
    const newPlan = cloneDeep(plan);
    if (perkDoubleAddConflictName) {
      newPlan.perks = filter(
        newPlan.perks,
        (item) => item.doubleAddConflictName !== perkDoubleAddConflictName
      );
    }
    return newPlan;
  };

  const onCustomize = (plan, perkDoubleAddConflictName, special) => {
    scrollTop();
    const originalPlan = newPlanWithTurnedOffPerk(plan, perkDoubleAddConflictName);
    const constructPlan = find(
      plans_construct,
      (constructPlanItem) => constructPlanItem?.core?.id === plan?.core?.id
    );
    const newPlan = newPlanWithTurnedOffPerk(constructPlan, perkDoubleAddConflictName);
    newPlan.originalPlanId = originalPlan.id;
    newPlan.processedPerks = originalPlan.perks;
    if (special) {
      newPlan.specialCustom = true;
      newPlan.baseCustom = false;
    } else {
      newPlan.specialCustom = false;
      newPlan.baseCustom = true;
    }
    setSelectedPlan(newPlan);
    setCurrentStage("buildYourOwn");
  };

  const handleDoubleAddCallback = ({
    buttonTitle, plan, variantsHash,
    buttonType, perkDoubleAddConflictName
  }) => {
    closeModal();

    if (buttonType === "keep") {
      buttonTitle === "customize" ?
        handlePopularCustomize(plan) :
        handleBestPlanSelect(plan, variantsHash);
    } else {
      onCustomize(plan, perkDoubleAddConflictName, true);
    }
    scrollTop();
  };

  const handleBestPlanCheck = (buttonTitle) => (plan, variantsHash) => {
    const foundConflictedPerk = find(
      plan.perks,
      (planPerk) => {
        const variant = find(planPerk.variants || [], (item) => item.default);
        const doubleAddConflicted = findPerkVariantInTheOtherLine(planPerk, variant?.id);
        return doubleAddConflicted.found;
      }
    );

    if (foundConflictedPerk) {
      const variant = find(foundConflictedPerk.variants || [], (item) => item.default);
      const conflictHash = getDoubleAddConflictMessages(
        (buttonType, perkDoubleAddConflictName, variantDoubleAddConflictName) => {
          handleDoubleAddCallback({
            buttonTitle, plan, variantsHash,
            buttonType, perkDoubleAddConflictName, variantDoubleAddConflictName
          });
        },
        'popularPlans', foundConflictedPerk, variant, plan, (buttonTitle === "customize")
      );

      if (buttonTitle === "customize") {
        onCustomize(plan, foundConflictedPerk.doubleAddConflictName);
        return;
      }

      openConflictModal(conflictHash, openModal);
    } else if (buttonTitle === "customize") {
      onCustomize(plan);
    } else {
      handleBestPlanSelect(plan, variantsHash);
    }
  };

  const handleFinishSelectPerks = ({ plan, perks, variantsHash }) => {
    const line = getCurrentLine();
    const templatePlan = find(
      [ ...plans_prebuild, ...plans_construct ],
      (planItem) => (planItem.id === selectedPlan?.originalPlanId)
    );
    setHashDataForTemplatePlan(
      line, allPerksFlatten,
      templatePlan, !selectedPlan?.originalPlanId,
      perks, variantsHash
    );
    onPlanSelect({ ...plan, perks, variantsHash });
  };

  const handleCustomize = (plan, onlyPlan, customPerks) => {
    const { perks } = plan;
    setSelectedPlan(plan);
    let innerPerks;
    if (customPerks) {
      innerPerks = customPerks;
    } else {
      innerPerks = onlyPlan ? initialPerks : perks;
    }
    setInitialPerks(innerPerks);
    setCurrentStage("selectPerks");
    scrollTop();
  };

  const handleBuildOwnCustomize = (plan, initPerks, options) => {
    const { initSpecialCustom, initBaseCustom, initOriginalPlanId } = (options || {});
    const newPlan = cloneDeep(plan);
    newPlan.specialCustom = initSpecialCustom;
    newPlan.baseCustom = initBaseCustom;
    newPlan.processedPerks = initPerks;
    newPlan.originalPlanId = initOriginalPlanId;
    handleCustomize(newPlan, !initPerks, initPerks);
  };

  const handleBuildYourOwn = () => {
    buildYourOwnClick();
    setCurrentStage("buildYourOwn");
  };

  const { getBackPage, backPageHash, addViewedPage, getPreviousPage } = useContext(BackContext);
  useEffect(() => {
    if (currentStage) {
      const previousPage = getPreviousPage().toLowerCase();
      const previousPageIsOrder = previousPage === 'order';
      const itemsToAdd = [];
      if (previousPageIsOrder) {
        switch (currentStage) {
          case 'bestPlans':
            itemsToAdd.push('Fork');
            break;
          case 'buildYourOwn':
            itemsToAdd.push('Fork');
            break;
          case 'selectPerks':
            itemsToAdd.push('Fork');
            itemsToAdd.push('bestPlans');
            break;
        }
      }
      addViewedPage([ ...itemsToAdd, ...[ currentStage ] ]);
    }
  }, [ currentStage ]);

  useEffect(() => {
    const backPage = getBackPage();
    if (backPage === "bestPlans") {
      setSelectedPlan(initialPlanToEdit);
      setInitialPerks(initialPerksToEdit);
    }
    if (includes([ "bestPlans", "buildYourOwn", "selectPerks" ], backPage)) {
      setCurrentStage(backPage);
    }
  }, [ backPageHash ]);

  return (
    <div className={ `wrapper -footer-sticky` }>
      <Header />
      <Subheader />
      <div className="content">
        <div className="container">
          {
            currentStage === "bestPlans" &&
            <OurBestPlans
              plans={ plans_prebuild }
              corePlans={ plans_construct }
              onNavigate={ scrollTop }
              allPlansInclude={ allPlansInclude }
              onSelect={ handleBestPlanCheck("select") }
              onCustomize={ handleBestPlanCheck("customize") }
              onBuild={ handleBuildYourOwn }
            />
          }
          {
            currentStage === "buildYourOwn" &&
            <BuildYourOwnPlan
              initialPlan={ selectedPlan || defaultBuildYourOwnPlan }
              processedPerks={ selectedPlan?.processedPerks }
              specialCustom={ selectedPlan?.specialCustom || specialCustom }
              baseCustom={ selectedPlan?.baseCustom || baseCustom }
              originalPlanId={ selectedPlan?.originalPlanId || originalPlanId }
              plans={ plans_construct }
              allPlansInclude={ allPlansInclude }
              onCustomize={ handleBuildOwnCustomize }
            />
          }
          {
            currentStage === "selectPerks" &&
            <SelectPerks
              selectedPlan={ selectedPlan }
              initialPerks={ initialPerks }
              initVariantsHash={ planPerkVariants }
              onSelect={ handleFinishSelectPerks }
            />
          }
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
