import React, { useLayoutEffect } from 'react';
import { each } from 'lodash';
import DesktopBAU from '../bau/desktop';
import { BAU } from '../common/plans_data';

const Cell3 = () => {
  useLayoutEffect(() => {
    each(BAU.plans, (plan) => {
      plan.taxPhrase = 'Plus taxes. Fees excluded.';
    });
  }, []);

  return (<DesktopBAU />);
};

export default Cell3;
