import {filter, includes, map} from "lodash";
import SummaryEditorFeedbackPlate from "./summaryEditorFeedbackPlate";
import SvgIcon from "../../../../../../common/components/svg-icon";
import React from "react";

const normalizeStr = (str) => {
  return str.toLowerCase().trim().replace(/\s{2,}/g, ' ');
}

const filterAnswers = (uniqAnswers, searchStr) => {
  return filter(uniqAnswers, (item) => {
    return (
      normalizeStr(item.answer).indexOf(normalizeStr(searchStr)) >= 0
    );
  });
}

const SummaryEditorFeedbackSelect = ({ searchStr, onChangeFieldSearch, uniqAnswers, action, keyForChange = null }) => {
  return (
    <div className="summary-editor_feedback-select">
      <div className="summary-editor_feedback-select-field">
        <input
          type="text"
          className="form-field -block"
          placeholder="Search"
          value={ searchStr }
          onChange={onChangeFieldSearch}
        />
      </div>
      <ul className="summary-editor_feedback-select-list">
        {
          map(filterAnswers(uniqAnswers, searchStr), (answer, key) => (
            <li key={key} className="summary-editor_feedback-select-list-item">
              <SummaryEditorFeedbackPlate title={answer.answer}>
                <button
                  className="button -square -success"
                  type="button"
                  onClick={() => action(answer.answer, keyForChange)}
                >
                  {keyForChange !== null ? (
                    <SvgIcon name="change" />
                  ):(
                    <SvgIcon name="plus" />
                  )}
                </button>
              </SummaryEditorFeedbackPlate>
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default SummaryEditorFeedbackSelect;