import React, { useState } from "react";
import Modal from '../../../common/components/modal';
import ExpressSlider from '../../../common/components/express_slider';
import remoteFiles from '../../../common/remote_files';

const HowItWorksModal = ({ onClose }) => {
  const { image1, image2, image3 } = remoteFiles.express.howItWorks;
  return (
    <Modal showCross onClose={ onClose } dialogClassName="modal-how-it-works">
      <h2>How it works?</h2>
      <ExpressSlider>
        <div className="shelf-how-it-works-step">
          <div className="image-section">
            <img src={ image1 } alt="image1" />
          </div>
          <div className="text-section">
            Upload a shelf image by clicking the "Upload Image" button and
            { ' ' }
            choosing the image from your PC. Make sure it matches the correct
            { ' ' }
            package shown in the "Target Package Preview".
          </div>
        </div>
        <div className="shelf-how-it-works-step">
          <div className="image-section">
            <img src={ image2 } alt="image2" />
          </div>
          <div className="text-section">
            Once the shelf image has finished processing, move by dragging
            { ' ' }
            and resize the green square so that it fully covers the target package.
            { ' ' }
            This area represents where respondents will need to click in order
            { ' ' }
            to correctly identify the target package in the find time exercise.
          </div>
        </div>
        <div className="shelf-how-it-works-step">
          <div className="image-section">
            <img src={ image3 } alt="image3" />
          </div>
          <div className="text-section">
            If the shelf image is the same across multiple packages,
            { ' ' }
            you can click "Use Previous Image" and it will copy
            { ' ' }
            from the last shelf image that was uploaded.
          </div>
        </div>
      </ExpressSlider>
    </Modal>
  );
};


const HowItWorks = () => {
  const [ showModal, setShowModal ] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <a href="#" onClick={ handleClick }>How it Works?</a>
      {
        showModal &&
        <HowItWorksModal onClose={ handleClose } />
      }
    </>
  );
};

export default HowItWorks;
