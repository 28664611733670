import { map } from 'lodash';
import { plans as commonPlans, allPlansInclude, pricing } from "../unlimited_common/plans_data";
import { getLinesCount } from "../unlimited_common/statistics";

const plans = map(commonPlans, (commonPlan) => {
  return {
    ...commonPlan,
    get price() { return pricing[commonPlan.id - 1][getLinesCount() - 1]; }
  };
});

export { plans, allPlansInclude };