import React, { useContext } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import { PanelServiceContext } from '../../contexts';

const Tabs = () => {
  const { services, getServiceName, setServiceName, inputsDisabled } = useContext(PanelServiceContext);
  const serviceName = getServiceName();
  const isActive = (service) => (service.name === serviceName);
  const handleServiceSelection = (event, service) => {
    if (disabled) {
      return;
    }
    setServiceName(service.name);
  };
  const disabled = inputsDisabled();
  return (
    <div className="form_section-tabs">
      <div className="radio-tabs -borderer -gray-bg">
        {
          map(services, (service) => (
            <label
              className={ classnames("radio-tabs_item", { '-disabled': disabled }) }
              key={ `${service.name}${isActive(service)}` }
            >
              <input
                type="radio"
                value={ `${service.name}` }
                checked={ isActive(service) }
                onChange={ (event) => { handleServiceSelection(event, service); } }
              />
              <span className="radio-tabs_label">{ service.title }</span>
            </label>
          ))
        }
      </div>
    </div>
  );
};

export default Tabs;
