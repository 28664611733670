import React, { useContext, useState } from 'react';
import { map, round, each, find } from 'lodash';
import SliderRadioButtons from "../sliderRadioButtons";
import Http from "../../../../../common/http";
import SummaryPanel from '../summary-panel';
import { IndexData } from '../../../common/index-context';
import { OpenEndContext } from '../../contexts';
import DonutChart from "./donutChart";
import AnswerRow from "./answerRow";

const OpenEndFeedback = ({
  projectId, conceptId, openEndData, titles, donutCaptions,
  switchColors, entityTitle, donutTexts, questionText, slidePrefix
}) => {
  const [ innerFilter, setInnerFilter ] = useState(openEndData.filter);
  const { data, wordCloudColors } = useContext(IndexData);
  const { handleUpdateSummary, aiSummaryAvailable } = useContext(OpenEndContext);

  const options = [
    { value: 'high', title: (titles?.length || 0) > 1 ? titles[1] : 'High Effort' },
    { value: 'low', title: (titles?.length || 0) > 2 ? titles[2] : 'Low Effort' }
  ];

  const donatOptions = [
    { value: 'high', title: (donutCaptions?.length || 0) > 1 ? donutCaptions[1] : 'High Effort' },
    { value: 'low', title: (donutCaptions?.length || 0) > 2 ? donutCaptions[2] : 'Low Effort' }
  ];
  const donutTitles = [
    ((donutCaptions?.length || 0) > 0 ? donutCaptions[0] : 'All'),
    ...map(donatOptions, (el) => el.title)
  ];

  const handleChangeFilter = (v) => {
    setInnerFilter(v);
    Http.post(
      `/verizon_customer_case_v2024/api/projects/${projectId}/save_project_setting`,
      {
        name: openEndData.filter_name,
        value: v
      }
    ).then(
      (http) => {
        if (http.response.status === 200) { }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const greenColor = wordCloudColors.positive;
  const redColor = wordCloudColors.negative;
  const neutralColor = wordCloudColors.neutral;

  const highColor = switchColors ? greenColor : redColor;
  const lowColor = switchColors ? redColor : greenColor;

  const filterMetric = (customFilter) => (openEndData[`metric_${customFilter}`]);
  const filteredMetric = filterMetric(innerFilter);

  const findAndUpdateHiddenValue = (answersList, id, hide) => {
    const answerObj = find(answersList || [], (item) => item.id === id);
    if (answerObj) {
      answerObj.hidden = hide;
    }
  };

  const handleToggleVisibility = (answerObj, hide) => {
    const answerObjId = answerObj.id;
    findAndUpdateHiddenValue(openEndData.answers, answerObjId, hide);
    each(
      map(options, (item) => item.value),
      (filterValue) => {
        findAndUpdateHiddenValue(openEndData[`metric_${filterValue}`].answers, answerObjId, hide);
      }
    );
  };

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <div className="comparisons_plates-grid-item -one-third ">
            <div className="comparisons_plate -bordered -full-height">
              <h4 className="comparisons_plate-title -center -margin-bottom-md -no-transform">
                Percentage of Respondents
              </h4>
              <DonutChart
                high={ round(openEndData.stats.high) }
                highColor={ highColor }
                low={ round(openEndData.stats.low) }
                lowColor={ lowColor }
                colorOnly={ innerFilter }
                titles={ donutTitles }
                neutralColor={ neutralColor }
                textDefaultColor={ "#000" }

              />
              <hr className="-gray" />
              <div className="comparisons_intro-plate -bg-stone -center">
                <div className="comparisons_intro-plate-value">
                  N={ data.n }
                </div>
                <div className="comparisons_intro-plate-note">
                  Sample Size
                </div>
              </div>
              {
                !!donutTexts[innerFilter] &&
                <div className="comparisons_plate-note -margin-top -center -sm">
                  { donutTexts[innerFilter] }
                </div>
              }
            </div>
          </div>

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -bordered -full-height">
              <div className="sentiments">
                <h4 className="comparisons_plate-title -center -margin-bottom-md -no-transform">
                  { questionText }
                </h4>
                <div className="sentiments_header">
                  <div className="tile-header -no-border -nowrap-in-standalone">
                    <div className="tile-header_main">
                      <SliderRadioButtons
                        code={ openEndData.filter_name }
                        initValue={ innerFilter }
                        options={ options }
                        onChange={ handleChangeFilter }
                        modificator="-full-width -bordered"
                      />
                    </div>
                  </div>
                </div>
                <div className="sentiments_content">
                  <div className="sentiments_content-main">
                    <div className="sentiments_content-scrollable">
                      <ul className="sentiments-list">
                        {
                          map(filteredMetric.answers, (record) => (
                            <AnswerRow
                              key={ record.id }
                              projectId={ projectId }
                              conceptId={ conceptId }
                              record={ record }
                              switchColors={ switchColors }
                              onToggleVisibility={ handleToggleVisibility }
                            />
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        map(options, (option) => {
          const metric = filterMetric(option.value);
          return (
            <div
              key={ metric.tag }
              className="summary-panel-wrapper"
              style={ { display: option.value === innerFilter ? null : 'none' } }
            >
              <SummaryPanel
                available={ aiSummaryAvailable }
                metric={ metric }
                summaryClassName="pre"
                entityTitle={ entityTitle }
                onGotSummary={ handleUpdateSummary }
                answers={ filteredMetric.answers }
                slidePrefix={ slidePrefix }
                innerFilter={ innerFilter }
              />
            </div>
          );
        })
      }
    </>
  );
};

export default OpenEndFeedback;
