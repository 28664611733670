import React from 'react';
import { find } from 'lodash';
import FivePointScale from '../../../common/components/metrics/five_point_scale';
import Markup from '../../../common/components/metrics/markup';
import AttributeRatings from '../../../common/components/metrics/attribute_ratings';
import Shelf from '../shelf';
import { defaultScorings } from "../../../common/logic/eligible";
import Intro from './intro';

const Concept = ({
  data, concept, index,
  conceptIndex, nextStep, result,
  shelfStep, nextShelfStep
}) => {
  const {
    markup_tool, attribute_ratings, hidden_metrics, translations, shelf_enabled, language_code
  } = data;

  const scorings = defaultScorings(data.scorings);

  const introText = () => {
    let text;
    if (shelf_enabled) {
      if (concept.shelf_exercise) {
        text = translations.express_after_shelf_intro;
      } else {
        text = translations.express_no_shelf_intro_next;
      }
    } else if (conceptIndex) {
      text = translations.express_no_shelf_intro_next;
    } else {
      text = translations.express_no_shelf_intro_first;
    }

    return text.replace("%product_name%", concept.product_name);
  };

  // Hack: for german we need to have different copy
  // for uniqueness for non first concept
  if (conceptIndex > 0 && index === 3) {
    const uniquenessQ = find(scorings, (item) => item.title === 'Package Uniqueness');
    if (uniquenessQ) {
      uniquenessQ.text = translations.five_point_Package_Uniqueness_question_next;
    }
  }

  return (
    <>
      {
        index === 1 &&
        <Shelf
          data={ data }
          concept={ concept }
          fivePointQuestions={ hidden_metrics }
          translations={ translations }
          result={ result }
          step={ shelfStep }
          nextStep={ nextShelfStep }
        />
      }
      {
        index === 2 &&
        <Intro text={ introText() } url={ concept.url } nextStep={ nextStep } />
      }
      {
        index === 3 &&
        <FivePointScale
          concept={ concept }
          questions={ scorings }
          result={ result }
          nextStep={ nextStep }
        />
      }
      {
        index === 4 &&
        <Markup
          conceptIds={ [ concept.focus_concept_id ] }
          markupTool={ markup_tool }
          nextStep={ nextStep }
          result={ result }
        />
      }
      {
        index === 5 &&
        <AttributeRatings
          title={ translations.attribute_ratings_title }
          concept={ concept }
          questions={ attribute_ratings }
          result={ result }
          nextStep={ nextStep }
          languageCode={ language_code }
        />
      }
    </>
  );
};

export default Concept;
