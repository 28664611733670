import React, { useContext } from 'react';
import TypesList from '../../../common/types/types-list';
import { BaseContext } from './contexts';

const NoProjects = ({ newProjectPath, newMpPath }) => {
  const { projectsAllowance } = useContext(BaseContext);
  const handleOnCreate = (projectType) => {
    window.location.href = `${newProjectPath}?express=${projectType}`;
  };

  return (
    <div className="content -express">
      <div className="content_top -sticky -express -white">
        <header className="header">
          <div className="header_grid">
            <div className="header_grid-item -grow">
              <h1 className="header_title" >Projects</h1>
            </div>
            <div className="header_grid-item">
              <div className="header_grid-item">
                <a className="-gl-button -both" href={ newMpPath }>
                  Create Multi-Language Project
                </a>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="content_body -express">
        <div className="express-intro">
          <div className="express-intro_text">
            <h1 className="express-intro_title">Welcome to buzzback Express!</h1>
            <div className="express-intro_hint">
              In just 10 minutes, you can:
            </div>
          </div>
          <div className="express-intro_list">
            <div className="express-intro_list-item">
              <div className="express-intro_item">
                <div className="express-intro_item-icon -color-1">
                  <svg className="icon -paper">
                    <use xlinkHref="#svg_paper" />
                  </svg>
                </div>
                <div className="express-intro_item-text">
                  Create a new project
                </div>
              </div>
            </div>
            <div className="express-intro_list-item">
              <div className="express-intro_item">
                <div className="express-intro_item-icon -color-2">
                  <svg className="icon -goal">
                    <use xlinkHref="#svg_goal" />
                  </svg>
                </div>
                <div className="express-intro_item-text">
                  Set project scope
                </div>
              </div>
            </div>
            <div className="express-intro_list-item">
              <div className="express-intro_item">
                <div className="express-intro_item-icon -color-3">
                  <svg className="icon -rocket">
                    <use xlinkHref="#svg_rocket" />
                  </svg>
                </div>
                <div className="express-intro_item-text">
                  Launch and field in just a few hours!
                </div>
              </div>
            </div>
          </div>
        </div>
        <TypesList
          title="Here’s how it works"
          onCreate={ handleOnCreate }
          projectsAllowance={ projectsAllowance }
          forceShowInTypeAfterClickCreate
        />
      </div>
    </div>
  );
};

export default NoProjects;
