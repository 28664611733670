import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import EditableInput from '../common/editableInput';

const CompleteInput = () => {
  const {
    getCompleteUrl, setCompleteUrl, inputsDisabled, getErrorByField
  } = useContext(PanelServiceContext);
  const completeUrl = getCompleteUrl();
  const disabled = inputsDisabled();
  const error = getErrorByField('panel_service_complete_url');
  return (
    <EditableInput
      title="Complete Url"
      value={ completeUrl }
      onChange={ setCompleteUrl }
      disabled={ disabled }
      error={ error }
    >
      Respondents will be redirected to this url after survey completion.
      {' '}
      Please make sure it includes <b>"%respid%"</b>
      {' '}
      - system will automatically replace this template with the
      {' '}
      actual respondent id.
      <br />
      For example: <b>https://test.provider?respid=%respid%</b>.
    </EditableInput>
  );
};

export default CompleteInput;
