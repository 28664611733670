import React, { useContext, useEffect, useState } from 'react';
import { each, flatten } from 'lodash';
import { GeneralContext } from '../../../contexts';
import FivePointScale from '../five_point_scale';
import Intro from '../../intro';
import renderRawHtml from '../../../../../../common/render_raw_html';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';
import Stimuli from '../../stimuli';
import VerizonStripeToId from '../../conceptView/verizonStripeToId';

const DeviationProcess = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    concept, deviationMetrics, result, translations
  } = useContext(GeneralContext);

  const fullConceptData = concept.full_concept_data;

  const metricIndex = metricGroupStep - 1;

  const prepareCurrentQuestions = () => {
    if (metricIndex >= 0) {
      const questions = deviationMetrics[metricIndex];
      each(questions, (question, index) => {
        question.text = fixQuestionTextTemplates(
          question, flatten(deviationMetrics), result, translations
        );
        const wrapperClassName = "metric-question-initially-hidden";
        if (index) {
          question.wrapperClassName = wrapperClassName;
        } else {
          question.onSelect = (value) => {
            if (value) {
              each(
                questions,
                (question, index) => {
                  if (index) {
                    question.wrapperClassName = `${wrapperClassName} -show`;
                  }
                }
              );
            }
          };
        }
      });
      return questions;
    }
    return [];
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  const [ displayVerizonStripeInDeviation, setDisplayVerizonStripeInDeviation ] = useState(false);
  useEffect(() => {
    setCurrentQuestions(prepareCurrentQuestions());
  }, [ metricGroupStep ]);

  useEffect(() => {
    const definedResult = (fullConceptData.branded === 'branded' && metricIndex >= 0 && !!currentQuestions[0]);
    setDisplayVerizonStripeInDeviation(definedResult);
  }, [ currentQuestions ]);

  const nextStep = () => {
    if (metricIndex < deviationMetrics.length - 1) {
      setMetricGroupStep(metricGroupStep + 1);
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  return (
    <>
      {
        !metricGroupStep &&
        <Intro nextStep={ nextStep } >
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.deviation_intro) } />
            </div>
          </>
        </Intro>
      }
      {
        metricIndex >= 0 && !!currentQuestions[0] &&
        <>
          <FivePointScale
            key={ currentQuestions[0].name }
            customConceptView={
              currentQuestions[0].kind === 'Open' ? null : (
                <Stimuli
                  imageClassName="-less-height"
                  processLeadInOptions={ fullConceptData.process_lead_in_options }
                  processLeadIn={ fullConceptData.process_lead_in }
                  processLeadInOther={ fullConceptData.process_lead_in_other }
                  processStepsKind={ fullConceptData.process_steps_kind }
                  steps={ fullConceptData.steps }
                  additionalImageUrl={ fullConceptData.additional_image_url }
                  additionalImageNotes={ fullConceptData.additional_image_notes }
                  branded={ fullConceptData.branded }
                  // zoomImage
                />
              )
            }
            concept={ concept }
            questions={ currentQuestions }
            result={ result }
            nextStep={ nextStep }
          >
            <></>
          </FivePointScale>
        </>
      }
      { displayVerizonStripeInDeviation && <VerizonStripeToId id="verizon-stripe-id" /> }
    </>
  );
};

export default DeviationProcess;
