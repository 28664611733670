import React from 'react';

const HiddenFields = ({ options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="project[brand_name]" id="project_brand_name" value={ model.brandName || '' } />
      <input type="hidden" name="project[brand_name_category]" id="project_brand_name_category" value={ model.brandNameCategory || '' } />
      <input type="hidden" name="project[brand_name_type]" id="project_brand_name_type" value={ model.brandNameType || '' } />
    </>
  );
};

export default HiddenFields;
