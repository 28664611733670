import React, { useContext, Fragment } from 'react';
import cn from "classnames";
import { map } from 'lodash';
import { IndexData } from "../../../common/index-context";
import GreenRed from '../greenRed';
import renderRawHtml from "../../../../../common/render_raw_html";
import Arrow from "../arrow";
import Stimuli from "../../../../respondent/verizon_customer_case_v2024/components/stimuli";
import HalfDonut from "./halfDonut";
import Checkbox from "../../components/checkbox";
import { baseContext } from "../../../../respondent/common/contexts";

const Index = () => {
  const { data } = useContext(IndexData);
  const { model, setModel } = useContext(baseContext);
  const stimuliData = data?.methodology?.stimuli_data;

  const kpiListMain = [
    {
      title: "Ease of Experience",
      note: "&quot;Little&quot; or &quot;No&quot; effort",
      color: data.arrows_mode ? 'black' : data.kpi?.vcc_effort.color,
      value: data.kpi?.vcc_effort.percent_text,
      arrow: data.kpi?.vcc_effort.arrow
    },
    {
      title: data?.branded_texts?.vcc_customer_first,
      note: "Agree &quot;Completely&quot; or &quot;Somewhat&quot;",
      color: data.arrows_mode ? 'black' : data.kpi?.vcc_customer_first.color,
      value: data.kpi?.vcc_customer_first.percent_text,
      arrow: data.kpi?.vcc_customer_first.arrow
    },
    {
      title: "Created w/My&nbsp;Needs in Mind",
      note: "Agree &quot;Completely&quot; or &quot;Somewhat&quot;",
      color: data.arrows_mode ? 'black' : data.kpi?.vcc_needs.color,
      value: data.kpi?.vcc_needs.percent_text,
      arrow: data.kpi?.vcc_needs.arrow
    }
  ];

  const kpiListSecondary = [
    {
      title: "CX 'North Star'",
      note: "Feel &quot;Delighted&quot;",
      color: data.arrows_mode ? 'black' : data.kpi?.vcc_delight.color,
      value: data.kpi?.vcc_delight.percent_text,
      arrow: data.kpi?.vcc_delight.arrow
    }
  ];

  const kpis = { included: kpiListMain, excluded: kpiListSecondary };

  return (
    <div className="comparisons_plates">
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Scorecard</h2>
        <Checkbox
          key={ `scorecard-report` }
          title="Add to Report"
          value={ model.scorecard }
          paramName="scorecard"
          onChange={ (v) => { setModel({ ...model, scorecard: v }); } }
        />
      </div>
      <div className="comparisons_plates-grid">
        <div className="comparisons_plates-grid-item">
          <div className="comparisons_plate -full-height">

            <div className="comparisons_grid -space-lg">
              <div className="comparisons_grid-item -narrow">
                <h3 className="comparisons_plate-title -center">Recommendation</h3>
                {!data.arrows_mode && data.ease_meter &&
                  <>
                    <div className={ `comparisons_status -center -${data.ease_meter.color}` }>
                      {data.ease_meter.text}
                    </div>
                    {
                      (!!data.messages?.high_risk) &&
                        <div className="note-messages -center">
                          { data.messages?.high_risk?.text_part }
                          <br />
                          <a className="link -light-blue" href={ "#unhappy" }>{ data.messages?.high_risk?.link_part }</a>
                        </div>
                    }
                  </>}

                {data.arrows_mode && data.ease_meter &&
                  <div className={ `comparisons_status -center` }>
                    N/A
                  </div>}

                <hr />

                <h3 className="comparisons_plate-title -center">Customer Case Score</h3>
                {
                  data.has_highlighting && data.ease_meter &&
                  <>
                    <HalfDonut
                      red={ data.ease_meter.revise }
                      green={ data.ease_meter.pass }
                      value={ data.ease_meter.value }
                      color={ data.ease_meter.color }
                    />
                  </>
                }
                {
                  (data.arrows_mode || !data.has_highlighting) && data.ease_meter &&
                  <div className={ `comparisons_status -center` }>
                    { data.ease_meter.percent_value }
                    { data.arrows_mode && <Arrow color={ data.ease_meter.arrow } /> }
                  </div>
                }
              </div>

              <div className="comparisons_grid-item">
                <h3 className="comparisons_plate-title -center">Customer Case KPIs</h3>
                {
                  data.kpi &&
                  <div className="comparisons_kpi-list">
                    {
                      map(kpis, (list, klassName) => (
                        <div className={ `ccs-${klassName}` } key={ klassName }>
                          {
                            map(list, (item, index) => (
                              <Fragment key={ `${klassName}${index}` }>
                                <div className="comparisons_plate-row">
                                  <div className="texts">
                                    <div className="title -big" { ...renderRawHtml(item.title) } />
                                    <div className="note" { ...renderRawHtml(item.note) } />
                                  </div>
                                  <div className={ cn("value -big", `-${item.color}`) }>
                                    { item.value }
                                    <Arrow color={ item.arrow } />
                                  </div>
                                </div>
                                <hr className="-transparent" />
                              </Fragment>
                            ))
                          }
                        </div>
                      ))
                    }
                    <GreenRed arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        {
          data?.methodology &&
          <div className="comparisons_plates-grid-item -thirty-five">
            <div className="comparisons_plate">
              <div className="stimuli-preview">
                <Stimuli
                  ideaName={ stimuliData.idea_name }
                  customerBenefit={ stimuliData.customer_benefit }
                  reasonsToBelieve={ stimuliData.reasons_to_believe }
                  processLeadInOptions={ stimuliData.process_lead_in_options }
                  processLeadIn={ stimuliData.process_lead_in }
                  processLeadInOther={ stimuliData.process_lead_in_other }
                  processStepsKind={ stimuliData.process_steps_kind }
                  steps={ stimuliData.steps }
                  additionalImageUrl={ stimuliData.additional_image_url }
                  additionalImageNotes={ stimuliData.additional_image_notes }
                  branded={ stimuliData.branded }
                />
              </div>
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default Index;
