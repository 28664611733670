import React, { useContext } from 'react';
import { LaunchContext } from '../../contexts';
import NSizeForConcepts from './nSizeForConcepts';
import NSizeForClaims from './nSizeForClaims';

const NSizeSelector = () => {
  const { nSizeKind } = useContext(LaunchContext);
  return (
    <>
      {
        nSizeKind === 'claim' ? (
          <NSizeForClaims />
        ) : (
          <NSizeForConcepts />
        )
      }
    </>
  );
};

export default NSizeSelector;
