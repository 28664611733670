import { forEach } from "lodash";

const deltas = (xy, dots) => {
  const result = Array(xy.length).fill(0);

  const groups = {};
  const usedIndexes = {};

  for (let i1 = 0; i1 < xy.length; i1++) {
    for (let i2 = i1 + 1; i2 < xy.length; i2++) {
      if (rectIntersection(xy[i1], xy[i2])) {
        result[i2]++;

        if (!groups[i1] && !usedIndexes[i1]) {
          groups[i1] = [ i1 ];
          usedIndexes[i1] = true;
        }

        if (groups[i1]) {
          usedIndexes[i2] = true;
          groups[i1].push(i2);
        }
      }
    }
  }

  const indexToGroup = {};
  const indexToPos = {};
  const shifts = {};
  forEach(groups, (vv, k) => {
    let minX = 9999, minY = 9999, maxX = -1, maxY = -1;

    forEach(vv, (v, vi) => {
      indexToGroup[v] = parseInt(k);
      indexToPos[v] = vi;

      if (minX > dots[v][0]) {
        minX = dots[v][0];
      }
      if (minY > dots[v][1]) {
        minY = dots[v][1];
      }
      if (maxX < dots[v][0]) {
        maxX = dots[v][0];
      }
      if (maxY < dots[v][1]) {
        maxY = dots[v][1];
      }
    });

    shifts[k] = [ minX, minY, maxX, maxY ];
  });

  return {
    groups,
    indexToGroup,
    shifts,
    indexToPos
  };
};

const rectIntersection = (r1, r2) => {
  const x1 = r1[0], x2 = r1[2], x3 = r2[0], x4 = r2[2],
    y1 = r1[1], y2 = r1[3], y3 = r2[1], y4 = r2[3];
  return x4 >= x1 && x3 <= x2 && y4 >= y1 && y3 <= y2;
};

export { deltas };
