import React from 'react';
import SvgIcon from "../../../../common/components/svg-icon";

const Intro = ({ addQuestion, hasNumberLimit, numberLimit }) => {
  return (
    <div className="form-intro">
      <div className="form-intro_content">
        <div className="form-intro_section">
          <div className="form-intro_section-inner -center -text-center">
            <div className="form-intro_icon">
              <SvgIcon name="question" />
            </div>
            <div className="form-intro_title -with-margin-10">
              Screener questions allow you to determine<br />
              the kinds of respondents you want to participate.
            </div>
            <div className="form-intro_section-inner -center -text-center">
              <div className="form-intro_text -gray">
                For example, you may be looking for category or brand users.<br />
                {hasNumberLimit && <span>You can add up to { numberLimit } screening questions to qualify your respondents.</span>}
                {!hasNumberLimit && <span>You have no limits in adding of screening questions to qualify your respondents.</span>}
              </div>
              <div className="form-intro_text -gray -with-margin-10">
                Adding screener questions may impact cost and timing.
              </div>
            </div>
            <div className="form-intro_button">
              <button className="button" onClick={ addQuestion }>
                Add Question
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
