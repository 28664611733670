import React, { useState, useContext } from 'react';
import { map, each, filter, maxBy } from 'lodash';
import classnames from 'classnames';
import Modal from '../../../../common/components/modal.js';
import { invalidQuotas, totalQuotaSum } from '../../common/logic/quotas_validation';
import { getSelectedItemsAfterMappingForQuestion } from '../../common/logic/questions_mapping';
import QuotaModeSwitcher from '../../common/quotaMode/components/quotaModeSwitcher';
import { QuotaModeLocalContext } from '../../common/quotaMode/contexts';
import { ListContext } from '../contexts/list_context';
import { ConditionContext } from "../contexts/condition_context";

const MappingEditQuota = ({ question, mappingTitle, showCross, linkTitle, title, onUpdate }) => {
  const [ list, updateList ] = useState([]);
  const [ showForm, updateShowForm ] = useState(false);
  const [ errors, updateErrors ] = useState([]);
  const conditionValues = useContext(ConditionContext);
  const contextValue = useContext(ListContext);
  const platformName = contextValue.platformName || '';
  const { forceChangeGlobalValueToLocal } = useContext(QuotaModeLocalContext);

  const filteredList = (newList) => {
    return filter(newList || list, (item) => !item.disabled);
  };

  const error = (errors || [])[0];

  const openModal = (event) => {
    event.preventDefault();

    const newList = map(getSelectedItemsAfterMappingForQuestion(question, conditionValues), (el) => (
      {
        id: el.id,
        answer: el.title,
        selected: true,
        quota: question.quotas[el.id],
        baseList: el.baseAnswers
      }
    ));
    updateList(newList);
    updateShowForm(true);
  };

  const onClose = (event) => {
    event.preventDefault();
    updateShowForm(false);
    updateErrors([]);
  };

  const fromArrayToQuestion = () => {
    question.quotas = {};
    each(list, (item) => {
      question.quotas[item.id] = `${item.quota}`;
    });
  };

  const valid = () => {
    const enabledList = filteredList();
    const quotaMessages = invalidQuotas(question, enabledList);
    if (quotaMessages) {
      updateErrors(quotaMessages || []);
    } else {
      updateErrors([]);
    }
    return !quotaMessages;
  };

  const onSave = (event) => {
    event.preventDefault();
    if (valid()) {
      forceChangeGlobalValueToLocal();
      fromArrayToQuestion();
      onUpdate(event, question);
      updateShowForm(false);
    }
  };

  const changeText = (event, item, field) => {
    item[field] = event.target.value;
    updateErrors([]);
  };

  const renderListItem = (item, index) => {
    return (
      <div key={ `listItem-${index}` } className="question-list-item">
        <div className="readonly-title">{ item.answer || '' }</div>
        <input
          type="text"
          className={
            `question-list-input form-field -list-right-margin -quota -middle-item${item.highlightQuota ? ' has-error' : ''}`
          }
          value={ item.selected ? (item.quota || "") : "" }
          onChange={ (event) => { changeText(event, item, 'quota'); } }
          disabled={ !item.selected }
        />
        <div className="readonly-title -auto-width">
          <div className="title-inner-list">{ item.baseList.join(", ") }</div>
        </div>
      </div>
    );
  };

  const maxByAnswerLength = (
    (
      maxBy(
        filteredList() || [],
        (item) => (
          (item.answer || "").length)
      ) || {}
    ).answer || {}
  ).length || 0;

  return (
    <>
      <a className="quotas" href="#" onClick={ (event) => openModal(event) }>{ linkTitle }</a>
      {
        showForm &&
        <Modal
          className={ classnames("-quota", { "-long-items": maxByAnswerLength >= 20 }) }
          dialogClassName={ `-fit-window -min-width ${platformName ? `${platformName}-modifier` : ''}` }
          backDropModifier={ platformName ? `${platformName}-modifier` : '' }
          showCross={ showCross }
          onClose={ (event) => { onClose(event); } }
        >
          <>
            <h2 className="normal-popup-title">
              { question.uiTitle() } &rarr; { title }
            </h2>
            {
              !error &&
              <div className="normal-popop-description">
                Please note your percent cannot be set to 0% and
                { ' ' }
                the sum of all options should equal 100%.
              </div>
            }
            {
              error &&
              <div className="quota-error-message">
                <div>{ error }</div>
              </div>
            }
            <QuotaModeSwitcher />
            <div className="question-headers">
              <h3 className="header-item -no-checkbox">
                { mappingTitle }
              </h3>
              <h3 className="header-item -middle-item">
                Quotas, % (Total: { totalQuotaSum(list) }%)
              </h3>
              <h3 className="header-item">Include</h3>
            </div>
            <div className="question-list">
              { map(filteredList(), (item, index) => renderListItem(item, index)) }
            </div>
            <div className="normal-popup-buttons">
              <button
                className="button"
                type="button"
                onClick={ (event) => { onSave(event); } }
              >
                Save
              </button>
              <button
                className="button -secondary"
                type="button"
                onClick={ (event) => { onClose(event); } }
              >
                Cancel
              </button>
            </div>
          </>
        </Modal>
      }
    </>
  );
};

export default MappingEditQuota;
