import React from "react";

const UploadedVideo = ({src}) => {
  return (
    <div className="products-greed-item-uploaded">
      <video src={src} height="256" controls preload="metadata"/>
    </div>
  );
}

export default UploadedVideo;