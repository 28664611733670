import React, { useContext } from 'react';
import classnames from 'classnames';
import { sendListUsers } from './requests';
import { GeneralContext } from './contexts';

const SortableHeaderColumn = ({ children, colName }) => {
  const {
    setUsers, sortingField, setSortingField, sortingOrder, setSortingOrder
  } = useContext(GeneralContext);
  const defaultSortingOrder = 'asc';

  const updateSortingFieldAndOrder = (newSortingField, newSortingOrder) => {
    setSortingField(newSortingField);
    setSortingOrder(newSortingOrder);
    window.history.replaceState(
      null, "",
      `${location.pathname}?sorting_field=${newSortingField}&sorting_order=${newSortingOrder}`
    );
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newSortingField = colName;
    let newSortingOrder;
    if (sortingField === newSortingField) {
      newSortingOrder = (sortingOrder === 'asc' ? 'desc' : 'asc');
    } else {
      newSortingOrder = defaultSortingOrder;
    }
    updateSortingFieldAndOrder(newSortingField, newSortingOrder);

    sendListUsers(
      { sortingField: newSortingField, sortingOrder: newSortingOrder },
      (http) => {
        setUsers(http.data.users);
      },
      (http) => {
        console.log('something went wrong', http);
      }
    );
  };

  return (
    <th className="-verizon_header">
      <a
        href="#"
        className={
          classnames(
            "sort-link",
            { [`-${sortingOrder}`]: !!sortingOrder && sortingField === colName }
          )
        }
        onClick={ handleClick }
      >
        { children }
      </a>
    </th>
  );
};

export default SortableHeaderColumn;
