import React from 'react';
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";
import { classNameError, errorsText } from '../common/helpers';

const ProjectCategory = ({ options, model, setModel, errors }) => {
  const handleOnChange = (event) => {
    setModel({ ...model, categoryId: event.target.value });
  };

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full">
          <div className="form_section-title -grid">
            <div className="form_section-title-item">
              <h3>Product Category</h3>
            </div>
            <div className="form_section-title-item">
              <div className="info-tooltip">
                <div className="info-tooltip_control">
                  <SvgIcon name="i-info" />
                </div>

                <div className="info-tooltip_drop -right">
                  <div className="info-tooltip_drop-inner">
                    Select a category to classify your survey. Respondents will not see this.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={ cn("form-group", classNameError(errors, 'product_category_ids')) }>
            <select
              className="form-field -block"
              prompt="Select a category"
              name="project[product_category_ids]"
              id="project_product_category_ids"
              value={ model.categoryId }
              onChange={ handleOnChange }
            >
              <option value="">Please select one</option>
              {map(options, (option) => (
                <option key={ option.id } value={ option.id }>{option.name}</option>
              ))}
            </select>
            <div className="form-error">{ errorsText(errors, 'product_category_ids') }</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCategory;
