import { each } from 'lodash';
import { getLinesCount } from "../common/statistics";
import {
  allPlansInclude, planFeatures,
  perkTitles, perkFeatures,
  perksSectionTitle,
  pricingLinesDescription,
  linesEmptyPlanTitle
} from '../common/common_data';

const pricing = [
  [ 65, 55, 40, 30, 25 ],
  [ 80, 70, 55, 45, 40 ],
  [ 90, 80, 65, 55, 50 ],
  [ 75, 65, 50, 40, 35 ]
];

const priceForLine = (planId) => {
  if (!planId) {
    return null;
  }
  return pricing[planId - 1][getLinesCount() - 1];
};

const planA = {
  id: 1,
  name: "Plan A",
  features: [
    planFeatures.Unlimited5G
  ],
  perksCount: 0
};

const planB = {
  id: 4,
  name: "Plan B",
  features: [
    planFeatures.Unlimited5GUltra,
    planFeatures.Get800ToNewPhone
  ],
  perksCount: 0
};

const planC = {
  id: 2,
  name: "Plan C",
  features: [
    planFeatures.Unlimited5GUltra,
    planFeatures.Get800ToNewPhone,
    planFeatures.plusPerks(1)
  ],
  choosePerks: planFeatures.choosePerks(1),
  perksCount: 1
};

const planD = {
  id: 3,
  name: "Plan D",
  features: [
    planFeatures.Unlimited5GUltra,
    planFeatures.Get800ToNewPhone,
    planFeatures.plusPerks(2)
  ],
  choosePerks: planFeatures.choosePerks(2),
  perksCount: 2
};


const entertainmentPerk = {
  id: 1,
  list: [
    perkFeatures.DisneyBundle
  ]
};

const entertainmentStorePerk = {
  id: 2,
  list: [
    perkFeatures.EntertainmentStore
  ]
};

const musicPerk = {
  id: 3,
  list: [
    perkFeatures.AppleMusic
  ]
};

const gamingPerk = {
  id: 4,
  list: [
    perkFeatures.GameXBox,
    perkFeatures.GameArcade
  ]
};

const globalCallingPerk = {
  id: 5,
  list: [
    perkFeatures.GlobalCalling
  ]
};

const internationalRoamingPerk = {
  id: 6,
  list: [
    perkFeatures.Roaming
  ]
};

const workAnywherePerk = {
  id: 7,
  list: [
    perkFeatures.AnyWhereMobile,
    perkFeatures.AnyWhereWatch
  ]
};

const securityPerk = {
  id: 8,
  list: [
    perkFeatures.SecurityMCAfee,
    perkFeatures.SecurityRing,
    perkFeatures.SecurityVerizon
  ]
};

const homeInternetPerk = {
  id: 9,
  list: [
    perkFeatures.HomeInternet25
  ]
};

const plans = [
  planA,
  planB,
  planC,
  planD
].map((el) => ({
  ...el,
  get price() { return pricing[this.id - 1][getLinesCount() - 1]; }
}));

const perkList = [
  entertainmentPerk,
  entertainmentStorePerk,
  musicPerk,
  gamingPerk,
  globalCallingPerk,
  internationalRoamingPerk,
  workAnywherePerk,
  securityPerk,
  homeInternetPerk
];
each(perkList, (item, index) => {
  item.title = perkTitles[index];
});

const cell3 = {
  plans,
  getPlanPriceByLine: (plan, currentLine) => (plan.price),
  allPlansInclude, pricing,
  perks: perkList,
  perksSectionTitle,
  perksSectionText: 'Perks are included on us in Plan C and Plan D.',
  linesDescription: pricingLinesDescription,
  linesEmptyPlanTitle
};

export { cell3, priceForLine  };
