import React, { useContext } from 'react';
import { LaunchContext } from '../contexts';

const Header = () => {
  const { showPaymentForm, surveyPath, title } = useContext(LaunchContext);
  return (
    <div className="content_top -sticky -gray">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item -grow">
            <h1 className="header_title">{title}</h1>
          </div>
          {
            showPaymentForm &&
            <div className="header_grid-item">
              <div className="header_grid-item">
                <a className="button" href={ surveyPath } target="_blank" rel="noreferrer">
                  Preview Project
                </a>
              </div>
            </div>
          }
        </div>
      </header>
    </div>
  );
};

export default Header;
