import Http from "../../../common/http";

const loadList = (mediaKind, callback) => {
  Http.get(
    `/verizon/exports.json?media_kind=${mediaKind}`
  ).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const createExport = (mediaKind, verizonType, projectId, kind, reportName, openEndIds, filters, callback) => {
  const { archived, filter } = (filters || {});

  const params = {
    media_kind: mediaKind,
    project_id: projectId,
    kind: kind || "pptx",
    report: reportName || "test",
    open_end_ids: openEndIds,
    verizon_type: verizonType,
    archived
  };
  if (filter) {
    params.filters = [ filter ];
  }
  Http.post("/verizon/exports.json", params).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const checkStatus = (mediaKind, specificId, callback) => {
  Http.get(
    `/verizon/exports/check_status/${specificId}.json?media_kind=${mediaKind}`
  ).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { loadList, createExport, checkStatus };
