import React, { useState } from 'react';
import { map } from 'lodash';
import SliderRadioButtons
  from '../../../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';
import CommonTrs from './commonTrs';
import TrGray from "./trGray";

const Reaction = ({ pids, data }) => {
  const cData = data.reaction_data;

  const [ filter, setFilter ] = useState('low');

  const options = [
    { value: 'high', title: 'High Appeal' },
    { value: 'low', title: 'Low Appeal' }
  ];

  const handleChangeFilter = (newVal) => {
    setFilter(newVal);
  };

  return (
    <div className="comparisons-table_wrapper">
      <table className="table comparisons-table">
        <tbody>
          <CommonTrs pids={ pids } data={ data } />

          <TrGray
            colSpan={ pids.length + 1 }
            text="Reaction To The Idea"
          />

          <tr>
            <td className="col-name -sticky-left -with-tabs">
              <SliderRadioButtons
                initValue={ filter }
                options={ options }
                onChange={ handleChangeFilter }
                modificator={`-gray-bg -bordered -in-table -full-width -items-${options.length}`}
              />
            </td>
            {map(pids, (pid) => (
              <td className="col-data -center" key={ pid }>
                ({cData[pid][`n_${filter}`]})
              </td>
            ))}
          </tr>

          <tr>
            <td className="col-name -sticky-left" />
            {map(pids, (pid) => (
              <td key={ pid } className="col-data -top">
                {map(cData[pid][`ai_summary_${filter}`], (record, index) => (
                  <div key={ index } className="paragraph">
                    <b>{record.name}:</b> {record.long_summary}
                  </div>
                ))}
              </td>
            ))}
          </tr>

        </tbody>
      </table>
    </div>
  );
};

export default Reaction;
