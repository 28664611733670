import React, { useState } from 'react';
import classnames from "classnames";
import TextField from "../../common/controls/textField";
import Layout from "../layout";
import { passwordResetRequest } from '../api';

const ResetPassword = ({ onCancel }) => {
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ sent, setSent ] = useState(false);
  const [ email, setEmail ] = useState('');

  const onSend = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    passwordResetRequest(email, (data, ok) => {
      setLoading(false);
      if (!data.email) {
        setSent(true);
      }
      setError(!!data.email);
    });
  };

  const handleCancelClick = (event) => {
    event.preventDefault();
    onCancel(event);
  };

  return (
    <Layout>
      <h2 className="auth-box_title">Password Reset</h2>
      <div className="auth-box_text">
        If you cannot remember your password, you can reset it. Enter your email address below to start the process.
      </div>
      {
        error &&
        <div className="portal-error">
          <h3>Wrong email</h3>
        </div>
      }
      <div className="auth-box_form">
        {
          sent ?
            <p>Further instructions have been sent to { email }</p>
            :
            <TextField label="Email Address" value={ email } onChange={ setEmail } />
        }
        <div className="form-buttons">
          <div className={ classnames("form-grid", { "-full": sent }) }>
            {
              !sent &&
              <div className="form-grid_item">
                <button className="button" onClick={ onSend } disabled={ loading }>
                  Send
                </button>
              </div>
            }
            <div className="form-grid_item">
              <a href="#" className="button -bordered" onClick={ handleCancelClick }>
                { sent ? "Go to sign in form" : "Cancel" }
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
