import React from "react";
import cn from "classnames";
import weekDays from "../mocks/weekDays";

const WeekCalendar = () => {
  return (
    <div className="week-calendar">
      {weekDays.map(({ weekDay }) => (
        <div className="week-calendar_week-day" key={weekDay}>
          {weekDay}
        </div>
      ))}
      {weekDays.map(({ monthDay, active, marked }) => (
        <div
          className={cn("week-calendar_month-day", active && "-active")}
          key={monthDay}
        >
          {monthDay}
          {marked && <div className="week-calendar_dot"></div>}
        </div>
      ))}
    </div>
  );
};

export default WeekCalendar;
