import remoteFiles from '../../../common/remote_files';
const {
  schedule1Jpg, schedule2Jpg, schedule3Jpg, schedule4Jpg,
  schedule5Jpg, schedule6Jpg, schedule7Jpg, schedule8Jpg,
  schedule9Jpg, schedule10Jpg
} = remoteFiles.equinox;

const schedulePeriods = [
  {
    title: "Nov 7 - Nov 13",
    days: [
      {
        title: "Today’s activities",
        activities: [
          {
            title: "Athletic Conditioning",
            coach: "Colleen C.",
            category: "HIIT",
            brand: "Equinox",
            duration: "30:00",
            added: true,
            checked: true,
            imgUrl: schedule1Jpg
          }
        ]
      },
      {
        title: "Tuesday",
        activities: [
          {
            imgUrl: schedule2Jpg
          }
        ]
      },
      {
        title: "Wednesday",
        activities: [
          {
            title: "Jacob’s Ladder",
            coach: "Chelsea A.",
            category: "Running",
            brand: "Precision Run",
            duration: "30:00",
            added: true,
            checked: false,
            imgUrl: schedule3Jpg
          },
          {
            title: "Firestarter",
            coach: "Corky C.",
            category: "Running",
            brand: "Precision Run",
            duration: "30:00",
            added: true,
            checked: false,
            imgUrl: schedule4Jpg
          }
        ]
      },
      {
        title: "Thursday",
        activities: [
          {
            imgUrl: schedule5Jpg
          }
        ]
      },
      {
        title: "Friday",
        activities: [
          {
            title: "Best Abs Ever: Obliques",
            coach: "Breanna C.",
            category: "Sculpt",
            brand: "Equinox",
            duration: "30:00",
            added: true,
            checked: false,
            imgUrl: schedule6Jpg
          }
        ]
      },
      {
        title: "Saturday",
        activities: [
          {
            title: "Outdoor: Long Mile",
            coach: "Chelsea A. ",
            category: "Walking",
            brand: "Precision Run",
            duration: "60:00",
            added: true,
            checked: false,
            imgUrl: schedule7Jpg
          }
        ]
      }
    ]
  },
  {
    title: "Nov 14 - Nov 20",
    days: [
      {
        title: "Sunday",
        activities: [
          {
            title: "Wherever, Whenever: Cal...",
            coach: "Katey L.",
            category: "Meditation",
            brand: "HeadStrong",
            duration: "5:00",
            added: true,
            checked: false,
            imgUrl: schedule8Jpg
          }
        ]
      },
      {
        title: "Monday",
        activities: [
          {
            imgUrl: schedule9Jpg
          }
        ]
      },
      {
        title: "Tuesday",
        activities: [
          {
            title: "Dealer’s Choice",
            coach: "Victoria",
            category: "Endurance",
            brand: "SoulCycle",
            duration: "45:00",
            added: true,
            checked: false,
            imgUrl: schedule10Jpg
          }
        ]
      }
    ]
  }
];

export default schedulePeriods;
