import React, { useState } from 'react';
import Header from './common/header';
import PlanList from './common/planList';

const СhoosePlan = ({ onContinue }) => {
  const [ startTimePlans ] = useState(new Date());

  return (
    <div className="layout">
      <Header />
      <PlanList onContinue={ onContinue } startTimePlans={ startTimePlans } fromChoose />
    </div>);
};

export default СhoosePlan;
