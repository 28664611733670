import React, { useContext, useEffect } from 'react';
import Header from "../common/components/header/index";
import { IndexData } from '../common/index-context';
import { WithLangData } from '../common/lang-data';
import CustomizeQuestionsModal from "../common/components/customize-questions-modal/customize-questions-modal";
import Comparisons from "./components/comparisons";
import EntityModal from "./components/entity-modal/index";

const AppInner = () => {
  const {
    init, data, loadData, customizedQuestionData,
    openConceptAfterReload, showEntityModal
  } = useContext(IndexData);

  useEffect(() => {
    loadData(null, null, null, null, {}, openConceptAfterReload);
  }, []);

  const reports = [
    {
      title: 'Concept Comparison Report',
      name: 'concept_comparison_swipe',
      kinds: [ 'pptx', 'xlsx' ],
      customFontSize: true
    },
    { title: 'Individual Concept Reports', name: 'individual_concept', kinds: [ 'pptx', 'xlsx' ] },
    { title: 'All Concepts Verbatims Report', name: 'swipe_all_verbatims', kinds: [ 'xlsx' ] }
  ];
  if (init.isInternal) {
    reports.push({ title: 'Disqualified Respondents Report', name: 'disqualified', kinds: [ 'xlsx' ] });
    reports.push({ title: 'All Respondents Answers', name: 'swipe_all_respondents', kinds: [ 'xlsx' ] });
    reports.push({ title: 'Quota Details', name: 'quotas_details', kinds: [ 'xlsx' ] });
  }

  const sortGroups = [
    'combined_tool',
    'five_point_scale',
    'attribute_ratings'
  ];
  sortGroups.push({ group_name: 'custom_metric', custom: 'custom_metrics' });

  return (
    <div className="content -loading-wrapper tour-parent-header">
      <Header
        reports={ reports }
        plural="concepts"
        sortGroups={ sortGroups }
      />
      <WithLangData
        langCode={ data.language_code }
        langName={ data.language_name }
      >
        <Comparisons />
        { showEntityModal && <EntityModal /> }
        { customizedQuestionData && <CustomizeQuestionsModal /> }
      </WithLangData>
    </div>
  );
};

export default AppInner;
