import React, { useContext } from 'react';
import Header from '../flex-common/header';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';
import { PlanCard, EmptyCard } from '../flex-common/planCard';
import PlanShortDescription, { SelectedPerksPlanDescription } from '../flex-common/planShortDescription';

const renderPlanDescription = ({ description, selectablePerks }, selectedIdx) => () =>
  selectablePerks
    ? <SelectedPerksPlanDescription
        description={description}
        selectedIdx={selectedIdx}
        replaceDescriptionTitles={{ 'Perks': 'Selected perks' }} />
    : <PlanShortDescription
        description={description} />;

const renderSelectedPlans = (handleOpenChange, perkIdxs) => (plan, idx) => {
  const perkId = !!plan ? perkIdxs[idx] : null,
    perksBundleItem = !!plan && perkId != undefined ? plan.description[1].articles[perkId] : null,
    bundleSubtitle = !!perksBundleItem ? perksBundleItem.title : null;

  return (<li key={idx} className="cards-item">
    <h6 className="cards-item-title">Line {idx + 1}</h6>
    {
      plan === null
        ? <EmptyCard/>
        : <PlanCard
          className="-flex -rounded"
          accordionTitle="Included features & perks"
          plan={plan}
          bundleSubtitle={ bundleSubtitle }
          bigPlusTaxes={false}
          handleOpenChange={handleOpenChange(idx)}
          renderDescription={renderPlanDescription(plan, perkIdxs[idx])}/>
    }
  </li>)
}

const Checkout = ({ onContinue, onBack }) => {
  const { filledLines, linesData, perkIdxs } = useContext(LinesDataContext);

  const total = linesData
    .filter(Boolean)
    .reduce((acc, { price }) => price + acc, 0);

  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.changePlanCount`)
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerksBundle`)
    onBack();
  };

  const handleOpenChange = (lineIdx) => () => {
    Statistic.updateValue(`lines.${lineIdx}.selectedPlanFeaturesCount`);
  };

  return <div className="checkout">
    <Header/>
    <div className="content">
      <div className="checkout-total-wrapper">
        <button className="select-perk-back" onClick={handleBack}>Back</button>
        <p className="checkout-total">Total: ${total}</p>
      </div>
      <h6 className="checkout-title -mobile-flex">You've selected a plan for Line {filledLines}</h6>
      <ul className="cards-list">
        {linesData.map(renderSelectedPlans(handleOpenChange, perkIdxs))}
      </ul>
    </div>
    <footer className="checkout-footer">
      <button className="button" type="button" onClick={onContinue}>Continue</button>
    </footer>
  </div>;
};

export default Checkout;