import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import WorkoutCarousel from "./WorkoutCarousel";
import compilations from "../mocks/workoutCompilations";
import { BRAND_DETAILS_BACK_COUNTER_KEY } from "../counter";

const compilation = compilations.equinox[0].workouts;
const workouts = [{ ...compilation[0], id: "pump-is-real" }, compilation[0]];

const EquinoxIntro = () => (
  <div className="equinox-intro">
    <div className="equinox-intro_bg"></div>
    <Link
      className="equinox-intro_back"
      to="/explore"
      data-counter-key={BRAND_DETAILS_BACK_COUNTER_KEY}
    >
      <Icon name="back" />
    </Link>
    <div className="equinox-intro_logo">
      <Icon name="eqx-logo" className="-large" />
    </div>
    <div className="equinox-intro_classes">
      <h3 className="equinox-intro_title mx-4">It’s not fitness, it’s life</h3>
      <WorkoutCarousel workouts={workouts} className="px-4" />
    </div>
  </div>
);

export default EquinoxIntro;
