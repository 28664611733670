import React, { useLayoutEffect, useContext, useRef } from 'react';
import { BaseContext } from '../../contexts';
import Slider from '../../../../../common/slider';
import testimonialsImage from '../../../../../../../images/landing/testimonials/nestle.svg';

const Section5 = () => {
  const { setSectionRefs } = useContext(BaseContext);
  const testimonialsAnchor = useRef(null);
  useLayoutEffect(() => {
    setSectionRefs((oldHash) => ({
      ...oldHash,
      ...{ testimonials: { el: testimonialsAnchor.current } }
    }));
  }, []);

  return (
    <section className="testimonials" ref={ testimonialsAnchor }>
      <div className="testimonials_title">
        <h3>Testimonials</h3>
      </div>
      <Slider customSettings={ { dots: false, infinite: false } }>
        <div className="testimonials_carousel">
          <div className="testimonials_item">
            <img
              alt="testimonials"
              src={ testimonialsImage }
              className="testimonials_item-image -nestle"
            />
            <div className="testimonials_item-text">
              <div className="testimonials_item-quote -left">
                <svg className="icon -quote">
                  <use xlinkHref="#svg_quote" />
                </svg>
              </div>
              <div className="testimonials_item-quote -right">
                <svg className="icon -quote">
                  <use xlinkHref="#svg_quote" />
                </svg>
              </div>
              <div className="testimonials_item-text-inner">
                “This new approach helps us move faster
                { ' ' }
                and more effectively into mobile
                { ' ' }
                techniques, and it’s ideal for
                { ' ' }
                early stage screening.”
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Section5;
