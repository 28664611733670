import React from 'react';

const HiddenFields = ({ data, options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ options.generate } />
      <input type="hidden" name="express" id="express" value={ data.express } />
      <input type="hidden" name="project[verizon_express_setting][case_id]" id="project_concept_case_id" value={ model.caseID } />
      <input type="hidden" name="project[verizon_express_setting][concept_title]" id="project_concept_title" value={ model.conceptTitle } />
      <input type="hidden" name="project[verizon_express_setting][concept_type]" id="project_concept_type" value={ model.conceptType } />
      <input type="hidden" name="project[verizon_express_setting][action_word]" id="project_action_word" value={ model.actionWord } />
      <input type="hidden" name="project[verizon_express_setting][process_description]" id="project_process_description" value={ model.processDescription } />
      <input type="hidden" name="project[verizon_express_setting][category]" id="project_category" value={ model.category } />
      <input type="hidden" name="project[verizon_express_setting][include_deviation]" id="project_include_deviation" value={ model.includeDeviation } />
    </>
  );
};

export default HiddenFields;
