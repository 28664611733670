import React, { useCallback, useContext, useState, useRef, useEffect } from "react";
import { map, findIndex } from "lodash";
import cn from "classnames";
import Slider from '../common/common_slider';
import renderRawHtml from "../common/render_raw_html";
import { Footer } from "../common/footer";
import Modal from "../common/modal";
import { openCoreModal, scrollTop } from '../common/helper';
import { ModalContext } from '../common/contexts';
import { increaseVariableForCurrentLine } from '../common/statistics';
import { PlansUnder } from "./plans_under";
import { Compare } from "./compare";
import { PromoLabel } from "./promo_label";

const BuildOwnRender = ({ plan, isCoreChecked, onCoreChange }) => {
  const { openModal } = useContext(ModalContext);

  const handleCoreChange = () => {
    increaseVariableForCurrentLine(`core${plan.statisticsName}Click`);
    onCoreChange(plan);
  };

  const handleReviewDetails = () => {
    increaseVariableForCurrentLine(`core${plan.statisticsName}ReviewClick`);
    openCoreModal({ plan: plan.core, openModal });
  };

  return (
    <label
      className={
        cn(
          "plate",
          "-black",
          "-label",
          { '-active': isCoreChecked(plan.core.id) },
          { '-with-label': plan.promo },
        )
      }
    >
      { plan.promo && <PromoLabel promo={ plan.promo } /> }
      <div className="build-own-plan">
        <div className="build-own-plan_top">
          { plan.promo && <PromoLabel promo={ plan.promo } /> }
          <div className="build-own-plan_top-grid">
            <div className="build-own-plan_top-grid-item">
              <h4 className="build-own-plan_title">{plan.name}</h4>
            </div>
            <div className="build-own-plan_top-grid-item">
              <div className="radio">
                <input
                  name="core"
                  type="radio"
                  checked={ isCoreChecked(plan.core.id) }
                  onChange={ handleCoreChange }
                />
                <div className="radio_icon">
                  <svg className="icon -radio">
                    <use xlinkHref={ `#svg_radio` } />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="build-own-plan_descr" { ...renderRawHtml(plan.core.description) } />
        </div>

        <div className="build-own-plan_content">
          <ul className="build-own-plan_features">
            {
              map(plan.core.perks, (perk, key) => (
                <li className="build-own-plan_feature-item" key={ `build-own-perks-${key}` }>
                  <div className="build-own-plan_feature-icon">
                    <svg className={ `icon -${perk.icon}` }>
                      <use xlinkHref={ `#svg_${perk.icon}` } />
                    </svg>
                  </div>
                  <div>{perk.titleForShort || perk.title}</div>
                </li>
              ))
            }
          </ul>
        </div>

        <div className="build-own-plan_bottom">
          <div className="build-own-plan_bottom-grid">
            <div className="build-own-plan_bottom-grid-item">
              <div className="build-own-plan_more">
                <button
                  className="button-link -sm"
                  onClick={ handleReviewDetails }
                >Review details
                </button>
              </div>
            </div>
            <div className="build-own-plan_bottom-grid-item">
              <div className="build-own-plan_price">
                <strong>${ plan.price }</strong>/mo*
              </div>
            </div>
          </div>
        </div>
      </div>
    </label>
  );
};

export const BuildYourOwnPlan = ({
  plans,
  initialPlan,
  allPlansInclude,
  onCustomize,
  processedPerks,
  specialCustom,
  baseCustom,
  originalPlanId
}) => {
  const compareContentRef = useRef(null);
  const sliderRef = useRef(null);

  const [ selectedPlan, setSelectedPlan ] = useState(() => (initialPlan || null));
  const [ initPerks ] = useState(processedPerks);
  const [ initSpecialCustom ] = useState(specialCustom);
  const [ initBaseCustom ] = useState(baseCustom);
  const [ initOriginalPlanId ] = useState(originalPlanId);

  const [ modalCompare, setModalCompare ] = useState(null);

  const closeModalCompare = () => setModalCompare(null);
  const handleCompareClick = () => {
    increaseVariableForCurrentLine(`coreCompareClick`);
    setModalCompare(true);
  };

  const isCoreChecked = useCallback(
    (coreId) => selectedPlan?.core.id === coreId,
    [ selectedPlan?.core.id ]
  );

  const onCoreChange = (plan) => {
    setSelectedPlan(plan);
  };

  const slideToIndex = (index) => {
    try {
      sliderRef.current.slickGoTo(index);
    } catch (e) {
      console.log('slider is not launched');
    }
  };

  const planIndex = (planId) => (findIndex(plans, (item) => item.id === planId));

  const goNext = (planObject) => {
    onCustomize(planObject, initPerks, { initSpecialCustom, initBaseCustom, initOriginalPlanId });
  };

  const handleComparePlanSelect = (plan) => {
    scrollTop();
    closeModalCompare();
    setSelectedPlan(plan);
    goNext(plan);
  };

  useEffect(() => {
    if (selectedPlan) {
      slideToIndex(planIndex(selectedPlan.id));
    }
  }, [ selectedPlan ]);

  const handleContinue = () => {
    goNext(selectedPlan);
  };

  return (
    <div className="build-own">
      <h2 className="build-own_title content_title">Choose your unlimited plan.</h2>

      <div className="build-own_wrapper">
        <Slider className="build-own_grid" sliderRef={ sliderRef }>
          {
            map(plans, (plan, key) => (
              <div className="build-own_grid-item" key={ `build-own-core-perks-${key}` }>
                <BuildOwnRender
                  plan={ plan }
                  isCoreChecked={ isCoreChecked }
                  onCoreChange={ onCoreChange }
                />
              </div>
            ))
          }
        </Slider>
      </div>

      <PlansUnder allPlansInclude={ allPlansInclude } />

      <Footer className="-sticky -center">
        <div className="build-own_footer">
          <div className="build-own_footer-item">
            <button
              className="button build-own_footer-button"
              disabled={ selectedPlan === null }
              onClick={ handleContinue }
            >
              Continue
            </button>
          </div>
          <div className="build-own_footer-item">
            <button
              className="button -bordered build-own_footer-button"
              onClick={ handleCompareClick }
            >
              Compare
            </button>
          </div>
        </div>
      </Footer>
      {
        modalCompare &&
        <Modal handleClose={ closeModalCompare } classNameDialog="-dark -lg" contentRef={ compareContentRef } >
          <Compare plans={ plans } onSelect={ handleComparePlanSelect } modalRef={ compareContentRef } />
        </Modal>
      }
    </div>);
};
