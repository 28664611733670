import React, { useEffect, useState, useContext } from 'react';
import { filter, find } from "lodash";
import { baseContext, reportsContext } from "../../contexts";
import {
  loadReports, loadArchivedDocuments,
  loadAdditionalCurrentMonthDocuments
} from "../api";
import FilesModal from "../common/filesModal";
import { filesNaming } from '../helper';

const ReportsListSidebar = () => {
  const { getRefresh } = useContext(reportsContext);
  const { mediaKind, verizonType, combine5gAndLte } = useContext(baseContext);
  const [ openKind, setOpenKind ] = useState(null);
  const [ selectedProject, setSelectedProject ] = useState(null);
  const [ archiveProjectsList, setArchiveProjectsList ] = useState([]);
  const [ archiveFilesList, setArchiveFilesList ] = useState([]);
  const [ additionalCurrentFilesList, setAdditionalCurrentFilesList ] = useState([]);
  const [ fileTitles ] = useState(() => ({
    currentSidebar: filesNaming.current(true, verizonType, mediaKind, combine5gAndLte),
    currentPopup: filesNaming.current(false, verizonType, mediaKind, combine5gAndLte),
    archivedSidebar: filesNaming.archived(true, verizonType, mediaKind, combine5gAndLte),
    archivedPopup: filesNaming.archived(false, verizonType, mediaKind, combine5gAndLte)
  }));

  const refreshed = getRefresh();

  useEffect(
    () => {
      loadReports(mediaKind, verizonType, combine5gAndLte, (projectsList) => {
        const filteredList = filter(projectsList, (project) => project.ppt_report_url);
        const currentProject = find(filteredList, (project) => project.selected);
        const withoutCurrentProject = filter(filteredList, (project) => !project.selected);
        setArchiveProjectsList(withoutCurrentProject);
        setSelectedProject(currentProject);
      });

      loadAdditionalCurrentMonthDocuments(mediaKind, verizonType, combine5gAndLte, (files) => {
        setAdditionalCurrentFilesList(files);
      });

      loadArchivedDocuments(mediaKind, verizonType, combine5gAndLte, (files) => {
        setArchiveFilesList(files);
      });
    },
    [ refreshed, mediaKind ]
  );

  const handleClick = (kind) => (event) => {
    event.preventDefault();
    setOpenKind(kind);
  };

  const handleClose = () => {
    setOpenKind(null);
  };

  const canShowMonthlyReportItem = (
    !!selectedProject || (
      !!additionalCurrentFilesList &&
      !!additionalCurrentFilesList.length
    )
  );

  const mainProjectsList = selectedProject ? [ selectedProject ] : [];

  return (
    <>
      {
        canShowMonthlyReportItem &&
        <li className="sidebar_list-item">
          <a
            href="#"
            className="sidebar_list-link -inline-block"
            onClick={ handleClick("main") }
          >
            <span className="-inline-block -low-line-height">{ fileTitles.currentSidebar }</span>
          </a>
        </li>
      }
      <li className="sidebar_list-item">
        <a
          href="#"
          className="sidebar_list-link -inline-block"
          onClick={ handleClick("archive") }
        >
          <span className="-inline-block -low-line-height">{ fileTitles.archivedSidebar }</span>
        </a>
      </li>
      {
        openKind === "main" &&
        <FilesModal
          onClose={ handleClose }
          title={ fileTitles.currentPopup }
          allProjectsList={ mainProjectsList }
          allFilesList={ additionalCurrentFilesList }
        />
      }
      {
        openKind === "archive" &&
        <FilesModal
          withGroups={ !!selectedProject }
          onClose={ handleClose }
          title={ fileTitles.archivedPopup }
          activeProject={ selectedProject }
          allProjectsList={ archiveProjectsList }
          allFilesList={ archiveFilesList }
          sortByCreatedAt
        />
      }
    </>
  );
};

export default ReportsListSidebar;
