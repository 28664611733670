import React from 'react';
import Modal from '../../../../common/components/modal';

const BlockedAccount = ({ email }) => {
  return (
    <Modal modalSize="sm" modal>
      <div className="modal_header">
        <div className="modal_title">
          Last Step - Activate Your Account
        </div>
      </div>
      <div className="modal_body">
        <form className="form">
          <div className="form_section">
            <p>
              Your account is created, but not
              { ' ' }
              activated yet, we need to
              { ' ' }
              make sure that "{ email }"
              { ' ' }
              is your email.
            </p>
            <p>
              Please find the email in your email
              { ' ' }
              box and click the link to
              { ' ' }
              activate your account.
            </p>
          </div>
          <div className="form_section">
            <div className="form_buttons -grid">
              <div className="form_buttons-item -grow">
                <a
                  href="/"
                  className="button -sm -block form_button"
                >
                  Ok, got it
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default BlockedAccount;
