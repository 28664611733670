import React from "react";

const VideoPreview = ({ imgUrl, duration, title, description, category }) => (
  <div className="video-preview">
    <div className="video-preview_image-wrapper">
      <img src={imgUrl} className="video-preview_image" />
      <div className="video-preview_duration">{duration}</div>
    </div>
    <div className="video-preview_body">
      <div className="video-preview_title">{title}</div>
      {description && (
        <div className="video-preview_description">{description}</div>
      )}
      {category && <div className="video-preview_category">{category}</div>}
    </div>
  </div>
);

export default VideoPreview;
