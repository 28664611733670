import React, { useEffect, useState, useContext } from 'react';
import { baseContext, filesContext } from "../../contexts";
import { loadBasicDocuments } from "../api";
import FilesModal from '../common/filesModal';
import { filesNaming } from '../helper';

const FilesListSidebar = () => {
  const { getRefresh } = useContext(filesContext);
  const { mediaKind, verizonType, combine5gAndLte } = useContext(baseContext);
  const [ opened, setOpened ] = useState(false);
  const [ filesList, setFilesList ] = useState([]);
  const [ fileTitles ] = useState(() => ({
    additionalSidebar: filesNaming.additional(true, verizonType, mediaKind, combine5gAndLte),
    additionalPopup: filesNaming.additional(false, verizonType, mediaKind, combine5gAndLte)
  }));

  const refreshed = getRefresh();

  useEffect(
    () => {
      loadBasicDocuments(mediaKind, verizonType, combine5gAndLte, (data) => {
        setFilesList(data);
      });
    },
    [ refreshed, mediaKind ]
  );

  const handleClick = (event) => {
    event.preventDefault();
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <>
      <li className="sidebar_list-item">
        <a
          href="#"
          className="sidebar_list-link -inline-block"
          onClick={ handleClick }
        >
          <span className="-inline-block -low-line-height">{ fileTitles.additionalSidebar }</span>
        </a>
      </li>
      {
        opened &&
        <FilesModal
          withGroups
          activeProject={ { archived: false } }
          onClose={ handleClose }
          title={ fileTitles.additionalPopup }
          allProjectsList={ [] }
          allFilesList={ filesList }
          noItemsCopy={ 'There are no documents at the moment.' }
        />
      }
    </>
  );
};

export default FilesListSidebar;
