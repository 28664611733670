import React from 'react';
import QuantPortal from './portal';
import Parahraph from './paragraph';
import Article from './article';

const renderParagraphs = (paragraph, idx) => <Parahraph key={idx} paragraph={paragraph} />;

const renderArticles = (article, idx) => <Article key={idx} article={article} />

const PerkDetailsDescription = ({ paragraphs = [], articles = [] }) => <div className="plan-details">
  {paragraphs.map(renderParagraphs)}
  {articles.map(renderArticles)}
</div>

const renderPerkDetailsDesciption = ({ paragraphs, articles }, idx) =>
  <PerkDetailsDescription key={idx} articles={articles} paragraphs={paragraphs} />

const PlanPerksModal = ({ open, handleClose, plan }) => {
  if (!open) return null;
  const { name, description } = plan;

  return <QuantPortal title={name} open={open} handleClose={handleClose}>
    {description.map(renderPerkDetailsDesciption)}
  </QuantPortal>
};

export default PlanPerksModal;