import React, { useContext } from 'react';
import { IndexData } from './contexts';

const Header = ({
  title, expressText, productCategory, backUrl, onOpenEdit,
  projectNumber
}) => {
  const { buzzbackUser } = useContext(IndexData);
  return (
    <div className="content_top -sticky">
      <header className="header">
        <div className="header_grid -first-button">
          <div className="header_grid-item">
            <a className="button" href={ backUrl }>
              <svg className="icon -arrow-up-left">
                <use xlinkHref="#svg_arrow-up-left" />
              </svg>
            </a>
          </div>
          <div className="header_grid-item -grow -top-pad">
            <h1 className="header_title">
              { title }
              {
                buzzbackUser && !!projectNumber &&
                <em>({ projectNumber })</em>
              }
            </h1>
            { expressText } &bull; { productCategory }
          </div>
          <div className="header_grid-item">
            <button className="button" onClick={ onOpenEdit }>
              Settings & Languages
            </button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
