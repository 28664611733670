import React, { useContext, useEffect, useRef, useState } from 'react';
import { map, includes, filter, lowerCase } from "lodash";
import { baseContext } from "../context";
import { updateImage } from "../requests";
import { brandTags, categoryFirstLayerTags, categorySecondLayerTags, customTags } from "../helpers";
import TagsDropdown from "./tags_dropdown";
import PropsToggle from "./props_toggle";

const Concept = ({ concept, onClosePopup }) => {
  const {
    setData, setError, categoryTags, consts,
    categorySubTags, allBrandTags, allCustomTags,
    globalLoading, setGlobalLoading, paramsId
  } = useContext(baseContext);

  const containerRef = useRef();

  useEffect(() => {
    if (concept) {
      setGlobalLoading(false);
    }
  }, [ concept ]);

  const [ priced, setPriced ] = useState(consts.priced_texts[concept.priced]);
  const [ stimuliType, setStimuliType ] = useState(consts.stimuli_types_texts[concept.stimuli_type]);
  const [ productType, setProductType ] = useState(consts.product_types_texts[concept.product_type]);
  const [ flCategoryTags, setFlCategoryTags ] = useState(categoryFirstLayerTags(concept.selected_tags));
  const [ slCategoryTags, setSlCategoryTags ] = useState(categorySecondLayerTags(concept.selected_tags));
  const [ brands, setBrands ] = useState(brandTags(concept.selected_tags));
  const [ customs, setCustoms ] = useState(customTags(concept.selected_tags));

  const selectedFirstLayerTagsIds = map(flCategoryTags, (tag) => tag.value);
  const secondLayerTagsSuggestions = filter(categorySubTags, (tag) => includes(selectedFirstLayerTagsIds, tag.parent_id));

  const onDataSave = () => {
    const payload = { concept_id: concept.id };
    payload.priced = lowerCase(priced);
    payload.stimuli_type = lowerCase(stimuliType);
    payload.product_type = lowerCase(productType);
    payload.category_tags = flCategoryTags;
    payload.sub_category_tags = slCategoryTags;
    payload.brand_tags = brands;
    payload.custom_tags = customs;
    payload.notes = containerRef.current.value;
    updateImage(paramsId, payload, setData, setError);
    setGlobalLoading(true);
    onClosePopup();
  };

  const onPricedChange = (_attr, value) => {
    setPriced(value);
  };

  const onStimuliTypeChange = (_attr, value) => {
    setStimuliType(value);
  };

  const onProductTypeChange = (_attr, value) => {
    setProductType(value);
  };

  return (
    <div className="concepts-database_concept">
      {
        globalLoading && <div className="page-loader -inner-loader" />
      }
      <ul className="concept-tags_prop-list">
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Priced / Unpriced
          </div>
          <div className="concept-tags_select_container -grow">
            <PropsToggle
              attrName="priced"
              labelClass="-width-1-2"
              prefix={ concept.id }
              options={ [ "Priced", "Unpriced" ] }
              currentValue={ priced }
              onChange={ onPricedChange }
              local
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Stimuli Type
          </div>
          <div className="concept-tags_select_container -grow">
            <PropsToggle
              attrName="stimuli_type"
              labelClass="-width-1-3"
              prefix={ concept.id }
              options={ [ "Static", "Dynamic", "Video" ] }
              currentValue={ stimuliType }
              onChange={ onStimuliTypeChange }
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Product Type
          </div>
          <div className="concept-tags_select_container -grow">
            <PropsToggle
              attrName="product_type"
              labelClass="-width-1-4"
              prefix={ concept.id }
              options={ [ "Product", "Service", "Promo", "Fees" ] }
              currentValue={ productType }
              onChange={ onProductTypeChange }
            />
          </div>
        </li>

        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Sub Category #1
          </div>
          <div className="concept-tags_select_container -grow">
            <TagsDropdown
              tags={ categoryTags }
              selectedOptions={ flCategoryTags }
              setSelectedOptions={ setFlCategoryTags }
              tagType="express_category"
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Sub Category #2
          </div>
          <div className="concept-tags_select_container -grow">
            <TagsDropdown
              tags={ secondLayerTagsSuggestions }
              selectedOptions={ slCategoryTags }
              setSelectedOptions={ setSlCategoryTags }
              tagType="express_category"
              disabled={ flCategoryTags.length === 0 }
              parentId={ flCategoryTags[0]?.id }
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Brand
          </div>
          <div className="concept-tags_select_container -grow">
            <TagsDropdown
              id={ `${concept.id}-brand` }
              key={ `${concept.id}-brand` }
              tags={ allBrandTags }
              selectedOptions={ brands }
              setSelectedOptions={ setBrands }
              tagType="express_brand"
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Custom Tags
          </div>
          <div className="concept-tags_select_container -grow">
            <TagsDropdown
              tags={ allCustomTags }
              selectedOptions={ customs }
              setSelectedOptions={ setCustoms }
              tagType="express_custom"
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <div className="concept-tags_prop-title">
            Notes
          </div>
          <div className="concept-tags_select_container -grow">
            <textarea
              ref={ containerRef }
              key={ `${concept.id}-${concept.notes}` }
              className="form-field concept-tags_notes"
              defaultValue={ concept.notes }
            />
          </div>
        </li>
        <li className="concept-tags_prop-item">
          <button
            className="concept-database_submit_button -gray"
            onClick={ onClosePopup }
          >
            Cancel
          </button>
          <button
            className="concept-database_submit_button"
            onClick={ onDataSave }
          >
            Save
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Concept;
