import React, { Fragment, useContext, useState } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from "../../../../common/render_raw_html";
import { LineDataContext } from '../../common/contexts';
import Popup from '../../common/popup';
import { Header } from '../../common/header';
import { Subheader } from '../../common/subheader';
import { AllPlansInclude } from '../../common/allPlansIncluded';
import { getCurrentLine, getHashData, setHashData } from '../statistics';

const buildClassName = (...attrs) => attrs.filter(Boolean).join(' ').trim();

const Characteristic = (props) => {
  const {
    title,
    description,
    onToggle,
    onCheck,
    hideTitle = false,
    iconName = null,
    highlighted = false
  } = props;
  const [ open, setOpen ] = useState(false);
  const updateVisibility = () => {
    onToggle();
    setOpen(!open);
  };

  const titleClassName = buildClassName(
    'plan-feature-title',
    highlighted ? '-highlighted' : false,
    !hideTitle && iconName ? '-has-icon' : false,
    description && '-pointer'
  );

  const descriptionClassName = buildClassName(
    'plan-feature-description',
    highlighted ? '-highlighted' : false,
  );

  const caretClassName = buildClassName(
    'savings-characteristic_state',
    open ? '-open' : false,
  );

  const iconClassName = buildClassName(
    'savings-characteristic_icon',
    `-${iconName}`
  );

  const svgRef = `#svg_${highlighted ? 'Caret-White' : 'Caret-Black'}`;

  const titleWithCaret = title +
    (
      description ?
        (`<span class="${caretClassName}">` +
        '<svg>' +
        `<use xlink:href="${svgRef}" />` +
        '</svg>' +
        '</span>') : ''
    );

  return (<div className="savings-characteristic">
    <div className="savings-characteristic_header" onClick={ updateVisibility }>
      {
        iconName &&
        <div className={ iconClassName }>
          <svg>
            <use xlinkHref={ `#svg_${iconName}` } />
          </svg>
          {
            hideTitle &&
            <span className={ caretClassName }>
              <svg>
                <use xlinkHref={ svgRef } />
              </svg>
            </span>
          }
        </div>
      }
      {
        !hideTitle &&
        <span className={ titleClassName } { ...renderRawHtml(titleWithCaret) } />
      }
    </div>
    {
      description && open &&
      <div
        className={ descriptionClassName }
        { ...renderRawHtml(description) }
      />
    }
    {
      open && onCheck && <span onClick={ onCheck } className="plan-features-check">
        Check availability
      </span>
    }
  </div>);
};

const renderCharacteristic = ({ onToggle, onCheck, highlighted }) =>
  // eslint-disable-next-line react/display-name
  ({
    id,
    title,
    description,
    hideTitle,
    iconName
  }) =>
    (
      <Characteristic
        key={ `${title}-${id}` }
        title={ title }
        description={ description }
        hideTitle={ hideTitle }
        iconName={ iconName }
        highlighted={ highlighted }
        onToggle={ onToggle }
        onCheck={ onCheck }
      />
    );

const CharacteristicList = (props) => {
  const {
    title,
    list,
    onCheck,
    onToggle,
    note = null,
    highlighted = false
  } = props;
  const listClassName = buildClassName(
    'plan-features-group',
    highlighted ? '-highlighted' : false,
    title === 'Perks to try, on us' ? '-perks-to-try' : false,
    !title ? '-other' : false
  );

  const headerClassName = buildClassName(
    'plan-feature-header',
    highlighted ? '-highlighted' : false
  );
  const noteClassName = buildClassName(
    'plan-feature-note',
    highlighted ? '-highlighted' : false
  );
  return (
    <div className={ listClassName }>
      {
        title &&
        <div className={ headerClassName }>{ title }</div>
      }
      {
        note &&
        <div className={ noteClassName } { ...renderRawHtml(note) } />
      }
      {
        list.map(renderCharacteristic({ onToggle, onCheck, highlighted }))
      }
    </div>
  );
};

const UnlimitedPlan = ({ plan, onPlanSelect }) => {
  const updateHashByKey = (hashKey) => () => {
    const prevValue = getHashData(hashKey) || 0;
    setHashData(hashKey, prevValue + 1);
  };

  const updateToggleOpensCount = updateHashByKey(`lines.${getCurrentLine()}.plan${plan.id}SavingsClickNum`);

  const updateCheckCount = updateHashByKey(`lines.${getCurrentLine()}.plan${plan.id}CheckAvailabilityNum`);
  return (
    <div className="box -flex-col">
      <div className="description-box">
        <h2 className="title ng-binding" { ...renderRawHtml(plan.name) } />
        <div className="text-16 description" { ...renderRawHtml(plan.description) } />
        <div className="box-pricing">
          <div>
            <div className="price">
              <h2 className="big-value">${ plan.price }</h2>
              <div className="value-hint">
                <div className="text-10">/line per month</div>
                <div className="text-10">Plus taxes & fees.</div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="select-billing-plan"
          onClick={ (event) => { onPlanSelect(event, plan); } }
        >
          Select
        </button>
      </div>
      <div className="plan-features">
        <CharacteristicList
          title={ plan.featuresTitle }
          list={ plan.features }
          onToggle={ updateToggleOpensCount }
        />
        {
          plan.id !== 1 &&
            <CharacteristicList
              title={ plan.savingsTitle }
              list={ plan.savings }
              onCheck={ updateCheckCount }
              onToggle={ updateToggleOpensCount }
            />
        }
        <CharacteristicList
          title={ plan.perksTitle }
          list={ plan.perks }
          note={ plan.perksNote }
          highlighted={ plan.id !== 1 }
          onToggle={ updateToggleOpensCount }
        />
        {
          plan.other?.length > 0 &&
          <CharacteristicList
            list={ plan.other }
            onToggle={ updateToggleOpensCount }
          />
        }
      </div>
    </div>
  );
};

const UnlimitedPage = ({ onPlanSelect, onChangePage, showChangePage }) => {
  const [ startTime ] = useState(new Date());
  const [ showPopup, updateShowPopup ] = useState(false);
  const { plans, popupCopy } = useContext(LineDataContext);
  const openPopup = (event) => {
    event.preventDefault();
    updateShowPopup(true);
  };
  const onSelect = (...attrs) => {
    onPlanSelect(...attrs);
    setHashData(`lines.${getCurrentLine()}.timeToSelectPlan`, Math.ceil(new Date(new Date() - startTime).getTime() / 1000));
  };
  return (
    <div className="content">
      <Header />
      <Subheader />
      <div className="concept-tabs">
        <div className="concept-tab -active">Overview</div>
        <div className="concept-tab -disabled">Compare</div>
      </div>
      <div className="unlimited-page unlimited-bau">
        <div className="inner-content">
          <AllPlansInclude />
          {
            showChangePage &&
            <div className="split-button-content">
              <button className="select-billing-plan" onClick={ onChangePage }>See our other plans<span>&gt;</span></button>
            </div>
          }
          <div className="inner-content -list-view">
            <div className={ classnames("list_view", { "-no-bottom-padding": !!popupCopy }) }>
              {
                map(plans, (plan) => {
                  return (
                    <UnlimitedPlan
                      key={ `unlimitedPlanItem${plan.id}` }
                      plan={ plan }
                      onPlanSelect={ onSelect }
                    />
                  );
                })
              }
            </div>
            {
              popupCopy &&
              <div className="view-details-section">
                <a href="#" onClick={ openPopup }>View details about Entertainment Hub</a>
              </div>
            }
          </div>
          <footer className="savings-footer">
            <span className="savings-footer_item">Important plan information</span>
            <span className="savings-footer_item">Important broadband information</span>
          </footer>
        </div>
      </div>
      {
        showPopup &&
        <Popup onClose={ () => { updateShowPopup(false); } }>
          <Fragment>
            <h2 className="popup-header">Entertainment Hub</h2>
            <div className="popup-body -unlimited-1" { ...renderRawHtml(popupCopy) } />
          </Fragment>
        </Popup>
      }
    </div>
  );
};

export default UnlimitedPage;
