import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate
} from "react-router-dom";
import ForYou from "./forYou";
import Explore from "./explore";
import Schedule from "./schedule";
import Activity from "./activity";
import Equinox from "./brand/equinox";
import PumpIsReal from "./class/pumpIsReal";
import Finish from "./finish";

export const routes = [
  {
    path: "/for-you",
    title: "For You",
    component: ForYou,
    element: (<ForYou />)
  },
  {
    path: "/explore/brand/equinox",
    title: "Brand Details",
    component: Equinox,
    element: (<Equinox />)
  },
  {
    path: "/explore/class/pump-is-real",
    title: "Class Details",
    component: PumpIsReal,
    element: (<PumpIsReal />)
  },
  {
    path: "/explore",
    title: "Explore",
    component: Explore,
    element: (<Explore />)
  },
  {
    path: "/schedule",
    title: "Schedule",
    component: Schedule,
    element: (<Schedule />)
  },
  {
    path: "/activity",
    title: "Activity",
    component: Activity,
    element: (<Activity />)
  },
  {
    path: "/finish",
    title: "Finish",
    component: Finish,
    element: (<Finish />)
  }
];

export const Routes = () => {
  return (
    <Router>
      <Switch>
        {routes.map(({ path, element }) => (
          <Route path={ path } element={ element } key={ path } />
        ))}
        <Route path="*" element={ <Navigate replace={ 'true' } to={ routes[0].path } /> } />
      </Switch>
    </Router>
  );
};
