import React from 'react';
import Paragraph from './paragraph';

const renderParagraphs = (paragraph => <Paragraph paragraph={paragraph} />)

const Article = ({ article }) => {
  const { title, paragraphs } = article;

  return <>
    <span>{title}</span>
    {paragraphs.map(renderParagraphs)}
  </>;
}

export default Article;