import React, { useContext } from "react";
import { IndexData } from "../../common/index-context";
import ErrorMessage
  from '../../../../verizonCustomerCaseV2024/admin/projects/components/common/errorMessage';
import NoteMessage
  from '../../../../verizonCustomerCaseV2024/admin/projects/components/common/noteMessage';

const ComparisonsIntro = () => {
  const { data } = useContext(IndexData);

  return (
    <>
      <div className="comparisons_intro-grid">
        <div className="comparisons_intro-grid-item -fix">
          <div className="comparisons_intro-plate -bg-red">
            <div className="comparisons_intro-plate-value">
              N={ data.n }
            </div>
            <div className="comparisons_intro-plate-note">
              Sample Size
            </div>
          </div>
        </div>
        <div className="comparisons_intro-grid-item">
          <div className="comparisons_intro-text">
            Data shown among
            <br />
            { data.segment_full_text }
            <br />
            { data.branded_text }
            {
              !!data.messages?.low_n_size &&
              <>
                <br />
                <ErrorMessage message={ data.messages?.low_n_size?.text } />
              </>
            }
          </div>
        </div>
      </div>
      {
        (!!data.messages?.benchmarks_not_available || data.messages?.benchmarks_compare_total) &&
        <div className="comparisons_intro-messages" >
          <ErrorMessage message={ data.messages?.benchmarks_not_available?.text } />
          <div className="note-messages">
            <NoteMessage message={ data.messages?.benchmarks_compare_total?.text } />
          </div>
        </div>
      }
    </>
  );
};

export default ComparisonsIntro;
