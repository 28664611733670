import Http from "../../../common/http";

const loadUsers = (callback) => {
  Http.get("/verizon/users.json").then(
    (response) => { callback(response.data); },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const createUser = (params, callback) => {
  Http.post("/verizon/users.json", { user: params }).then(
    (response) => { callback(response); },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const updateUser = (id, params, callback) => {
  Http.put(`/verizon/users/${id}.json`, { user: params }).then(
    (response) => { callback(response); },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const deleteUser = (id, callback) => {
  Http.del(`/verizon/users/${id}.json`).then(
    (response) => { callback(response); },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { loadUsers, createUser, updateUser, deleteUser };
