import React from 'react';

const Header = ({ title, addQuestion, emptyListQuestions, showAddQuestion }) => {
  return (
    <div className="content_top -sticky -gray">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item -grow">
            <h1 className="header_title">
              {title}
            </h1>
          </div>
          <div className="header_grid-item">
            {!emptyListQuestions &&
              <button className="button" onClick={ addQuestion } disabled={ !showAddQuestion }>
                Add Question
              </button>}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
