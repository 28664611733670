import React, { useContext, useEffect, useRef } from 'react';
import { map } from "lodash";
import { IndexData } from "../../common/index-context";

const AVS = ({ data  }) => {
  const { getTourUseEffectArgs } = useContext(IndexData);
  const tourElement = useRef(null);
  const [ callback, condition ] = getTourUseEffectArgs('AVS', tourElement);
  useEffect(callback, condition);

  const legend = {
    top2: "Top 2 Box",
    top: "Top Box",
    bottom2: "Bottom 2 Box"
  };

  return (
    <div className="report-plate_col" ref={ tourElement }>
      <div className="report-plate">
        <div className="avs">
          {data && (
            <>
              <div className="avs_data">
                {map(data, (item, index) => {
                  if (!item || !item.title) {
                    return null;
                  }

                  return (
                    <div className="avs_data-section" key={ index }>
                      {item && (
                        <>
                          <div className="avs_data-section-title">
                            { item.title }
                          </div>
                          { item.hint && (
                            <div className="avs_data-section-hint">
                              { item.hint }
                            </div>
                          ) }
                          <ul className="avs_data-chart-list">
                            {map(Object.entries({ top2: item.top2_percent, top: item.top_percent, bottom2: item.bottom2_percent }), ([ key, percent ], indexInner) => (
                              <li className="avs_data-chart-item" key={ `${index}-${indexInner}` }>
                                <div className="avs_data-chart" style={ { 'width': `${percent}%` } }>
                                  <div className={ `avs_data-chart-line avs_color -${key}` } />
                                  <div className={ `avs_data-chart-value ${percent > 0 ? '' : '-zero'}` }>{percent}%</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="avs_legend">
                <ul className="avs_legend-list">
                  {map(Object.entries(legend), ([ key, value ], index) => (
                    <li className="avs_legend-item" key={ `${index}` }>
                      <div className={ `avs_legend-circle avs_color -${key}` } />
                      <div className={ `avs_legend-value` }>{value}</div>
                    </li>
                  ))}
                </ul>
              </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AVS;
