import React, { useState, useContext } from 'react';
import { trim, isEmpty } from 'lodash';
import Modal from '../../../../../common/components/modal';
import { copyProject } from '../../requests';
import { IndexData } from "../contexts";

const CopyProject = ({ project, onClose }) => {
  const { id, case_name, case_id, copy_path } = project;
  const { loadProjects } = useContext(IndexData);
  const [ model, setModel ] = useState({ caseName: '', caseId: '' });
  const [ errors, setErrors ] = useState({});

  const checkInvalid = () => {
    const tempErrors = {};
    if (trim(case_name) === trim(model.caseName)) {
      tempErrors.caseName = "New case name is the same as the previous one.";
    } else if (!trim(model.caseName)) {
      tempErrors.caseName = "New case name can not be blank";
    }

    if (trim(case_id) === trim(model.caseId)) {
      tempErrors.caseId = "New case id is the same as the previous one.";
    } else if (!trim(model.caseId)) {
      tempErrors.caseId = "New case id can not be blank";
    }
    setErrors(tempErrors);
    return !isEmpty(tempErrors);
  };

  const handleOnChange = (event, fieldName) => {
    setModel({ ...model, ...{ [fieldName]: event.target.value } });
  };

  const handleMakeCopy = () => {
    if (!checkInvalid()) {
      copyProject(id, { case_name: model.caseName, case_id: model.caseId }, copy_path, () => {
        loadProjects();
        onClose();
      });
    }
  };

  return (
    <Modal
      dialogClassName="-centered-form verizon-modifier"
      showCross
      onClose={ onClose }
    >
      <h2>Copy "{ case_name }"</h2>
      <div className="copy-form">
        <div className="label">New Case Name</div>
        <div className="form-group">
          <input
            type="text"
            className="form-field -block"
            value={ model.caseName }
            onChange={ (event) => handleOnChange(event, 'caseName') }
          />
          {
            !!errors.caseName &&
            <div className="error">{ errors.caseName }</div>
          }
        </div>
      </div>
      <div className="copy-form">
        <div className="label">New Case Id</div>
        <div className="form-group">
          <input
            type="text"
            className="form-field -block"
            value={ model.caseId }
            onChange={ (event) => handleOnChange(event, 'caseId') }
          />
          {
            !!errors.caseId &&
            <div className="error">{ errors.caseId }</div>
          }
        </div>
      </div>
      <div className="buttons">
        <button className="button -cancel" onClick={ onClose }>Cancel</button>
        <button className="button" onClick={ handleMakeCopy }>Start</button>
      </div>
    </Modal>
  );
};

export default CopyProject;
