import React from 'react';

const InfoText = () => {
  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full">
          <div className="form_section-text">
            Please describe the Category. All respondents must be Category Users and only those aware of
            the Target Brand will answer brand-specific questions.
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoText;
