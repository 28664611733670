import React, { useState } from 'react';
import { map } from "lodash";
import Http from "../../../common/http";

const Index = () => {
  const [ text, setText ] = useState('some text to test summary worker\nI want to be free\nYou want fly to stars');
  const [ result, setResult ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const checkJID = (jid) => {
    Http.get(`/summary_chatgpt/check?jid=${jid}`).then(
      (http) => {
        if (http.data.status !== 'finished' && http.data.status !== 'failed') {
          setTimeout(
            () => { checkJID(jid); },
            3000
          );
        } else {
          setResult(`${http.data.result}\n\n\n${result}`);
          setLoading(false);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      }
    );
  };

  const sendText = (convertToKeywords, azure = false) => {
    setLoading(true);
    Http.post('/summary_chatgpt/send_text', {
      text, convert_to_keywords: convertToKeywords, azure
    }).then(
      (http) => {
        setTimeout(
          () => {checkJID(http.data.jid);},
          1000
        );
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      }
    );
  };


  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();

    const wb = XLSX.read(data, { dense: true });
    const ws = wb.Sheets[wb.SheetNames[0]];
    const texts = map(ws['!data'], (el) => {
      return el[0].v;
    });
    setText(texts.join("\n"));
  };

  return (
    <div>
      <h4>Text</h4>
      <div>
        <div>Upload Excel File</div>
        <input type="file" onChange={ (e) => handleUpload(e) } />
      </div>
      <textarea
        rows="20"
        cols="120"
        value={ text }
        onChange={ (e) => { setText(e.target.value); } }
        disabled={ loading }
      />
      <br />
      <div style={ { display: 'flex' } }>
        <div>
          <button type="button" onClick={ () => { sendText(false); } } disabled={ loading }>Send (by ChatGPT)</button>
          <button type="button" onClick={ () => { sendText(true); } } disabled={ loading }>Convert to keywords &amp; send (by ChatGPT)</button>
        </div>
        <div style={ { marginLeft: '20px' } }>
          <button type="button" onClick={ () => { sendText(false, true); } } disabled={ loading }>Send (by Azure)</button>
          <button type="button" onClick={ () => { sendText(true, true); } } disabled={ loading }>Convert to keywords &amp; send (by Azure)</button>
        </div>
      </div>
      <br />
      <div style={ { display: 'flex' } }>
        <button type="button" onClick={ () => { setText(''); } } disabled={ loading }>Clear data</button>
      </div>
      <h4>Summary</h4>
      <textarea rows="10" cols="120" disabled value={ result } />
    </div>
  );
};

export default Index;
