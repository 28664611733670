import React, { useState } from 'react';
import PoweredByBuzzback
  from '../../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import Users from './users';
import VisibilityGroupModal from './visibilityGroupModal';
import { GeneralContext } from './contexts';

const App = (data) => {
  const [ showModal, setShowModal ] = useState(false);
  const [ editedUser, setEditedUser ] = useState({});
  const [ sortingField, setSortingField ] = useState(data.sorting_field);
  const [ sortingOrder, setSortingOrder ] = useState(data.sorting_order);
  const [ users, setUsers ] = useState(data.users);
  const [ createdVisibilityGroups, setCreatedVisibilityGroups ] = useState(data.visibility_groups);

  const handleLink = () => {
    setEditedUser({ id: '', email: '', visibility_groups: [] });
    setShowModal(true);
  };

  const handleRowClick = (user) => {
    setEditedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const generalContextValue = {
    createdVisibilityGroups, setCreatedVisibilityGroups, setUsers, closeModal,
    sortingField, setSortingField, sortingOrder, setSortingOrder
  };

  return (
    <GeneralContext.Provider value={ generalContextValue }>
      <div className="content -grey-background verizon-modifier -comparison -research">
        <div className="content_top -sticky -white">
          <header className="header">
            <div className="header_grid">
              <div className="header_grid-item"><h1 className="header_title">User Groups</h1></div>
              <div className="header_grid-item -gl-buttons">
                <button className="button" onClick={ handleLink }>Add User</button>
              </div>
            </div>
          </header>
        </div>

        <div className="content_body -relative -projects">
          <div className="projects">
            <div className="projects_list_table">
              <Users users={ users } handleRowClick={ handleRowClick } />
            </div>
          </div>
        </div>

        {
          showModal &&
          <VisibilityGroupModal user={ editedUser } setUser={ setEditedUser } />
        }
        <PoweredByBuzzback />
      </div>
    </GeneralContext.Provider>
  );
};

export default App;
