import React from "react";
import Icon from "./Icon";

const ActivityCard = ({ activity }) => {
  return (
    <div className="activity-card">
      <div className="activity-card_body">
        <div className="activity-card_updated">{activity.lastUpdate}</div>
        <div className="activity-card_title-row">
          <h2 className="activity-card_title">{activity.title}</h2>
          <Icon name="arrow-right" />
        </div>
        <div className="activity-card_values">
          <div className="activity-card_digits-wrapper">
            <span className="activity-card_value">{activity.value}</span>
            {activity.growth && (
              <div className="activity-card_growth">
                <Icon name="triangle-arrow-top" />
                &nbsp;{activity.growth}
              </div>
            )}
          </div>
          <div className="activity-card_measure">{activity.measure}</div>
        </div>
        <div className="activity-card_source">
          <div className="activity-card_source-name">
            {activity.source.name}
          </div>
          <div className="activity-card_source-update">
            {activity.source.lastUpdate}
          </div>
        </div>
      </div>
      <img src={activity.imgUrl} className="activity-card_image" />
    </div>
  );
};

export default ActivityCard;
