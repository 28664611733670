import React, { useState, useContext, useRef } from "react";
import { map, find, remove, trim } from "lodash";
import classnames from 'classnames';
import { baseContext } from "../../contexts";
import { createExport, checkStatus } from '../api';
import PortalModal from "../../common/portal";
import { withPrefix } from "../../helpers";
import CheckboxField from "../../common/portal/checkboxField";

const Popup = ({
  onClose, openEnds, projectDate,
  inProgress, getExportFile
}) => {
  const limit = 4;

  const { mediaKind, verizonType } = useContext(baseContext);
  const [ openEndIds, setOpenEndIds ] = useState([]);

  const handleClose = () => {
    setOpenEndIds([]);
    onClose();
  };

  const handleCreateExport = () => {
    getExportFile(
      openEndIds,
      () => {
        handleClose();
      }
    );
  };

  const checked = (item) => !!find(openEndIds, (el) => el === item.id);

  const toggleItem = (item, value) => {
    setOpenEndIds((oldList) => {
      if (value) {
        oldList.push(item.id);
      } else {
        remove(oldList, (el) => el === item.id);
      }
      return [ ...oldList ];
    });
  };

  const name = (item) => {
    const result = [ trim(item.first_name), trim(item.last_name) ].join(" ");
    return !result || result === " " ? null : trim(result);
  };
  const prospect = (item) => (
    item.prospect === "1" ? "VZW Prospect" : "VZW Customer"
  );
  const signature = (item) => {
    const customerName = name(item);
    const customerKind = prospect(item);
    const resArray = [ customerKind ];
    if (customerName) {
      resArray.unshift(customerName);
    }
    return trim(resArray.join(', '));
  };

  const idByItem = (item, index) => `Checkbox_${item.id}_${index}`;

  return (
    <PortalModal
      className="-sm"
      title={ `${withPrefix(verizonType, mediaKind)} Customer Experience (${projectDate}) Export` }
      open
      handleClose={ handleClose }
      saveClassName={ { "loader -loading-blink": inProgress } }
      buttonName={ inProgress ? "Processing..." : "Export" }
      disabledSave={ inProgress }
      saveCallback={ handleCreateExport }
      headerContent={
        <p className="exports-header">
          Question: { openEnds.text }
        </p>
      }
    >
      <>
        <ul className="exports-list -open-ends">
          {
            map(openEnds.answers, (item, index) => (
              <li key={ `Exports${item.id}${index}` }>
                {
                  <>
                    <div className="icon-col">
                      <CheckboxField
                        id={ idByItem(item, index) }
                        checked={ checked(item) }
                        onChange={ (value) => { toggleItem(item, value); } }
                        disabled={ (openEndIds.length >= limit && !checked(item)) || inProgress }
                      />
                    </div>
                    <div className="completed">
                      <label htmlFor={ idByItem(item, index) }>{ item.answer }</label>
                      <span className="date">{ signature(item) }</span>
                    </div>
                  </>
                }
              </li>
            ))
          }
        </ul>
      </>
    </PortalModal>
  );
};

const PulseExport = ({ projectId, openEnds, projectDate, children }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const [ open, setOpen ] = useState(false);
  const [ inProgress, setInProgress ] = useState(false);
  const [ , setTimeoutId ] = useState(null);
  const downloadLink = useRef(null);

  const interval = 5000;
  const waitingForExportToFinish = (id, callback) => {
    checkStatus(mediaKind, id, (data) => {
      if (data.count_in_progress) {
        setTimeoutId((oldTimeoutId) => {
          clearTimeout(oldTimeoutId);
          return setTimeout(() => {
            waitingForExportToFinish(id, callback);
          }, interval);
        });
      } else {
        callback(data);
      }
    });
  };

  const getExportFile = (openEndIds, callback) => {
    setInProgress(true);
    createExport(mediaKind, verizonType, projectId, 'pptx', 'pulse', openEndIds, null, (data) => {
      waitingForExportToFinish(data.id, (result) => {
        const resultData = result.user_downloads[0];
        setInProgress(false);
        if (resultData.status === "finished") {
          downloadLink.current.setAttribute("href", resultData.s3_filename);
          downloadLink.current.setAttribute("download", resultData.subtitle);
          downloadLink.current.click();
          callback && callback();
        }
      });
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (inProgress) {
      return;
    }
    if (!!openEnds && !!openEnds.answers) {
      setOpen(true);
    } else {
      getExportFile();
    }
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a
        href="#"
        className={
          classnames(
            "button -sm -bordered",
            { "loader -loading-blink": inProgress }
          )
        }
        onClick={ handleClick }
      >
        {inProgress && "Processing..."}
        { !inProgress && (children || "Export") }
      </a>
      {
        open &&
        <Popup
          onClose={ onClose }
          openEnds={ openEnds || [] }
          projectDate={ projectDate }
          inProgress={ inProgress }
          getExportFile={ getExportFile }
        />
      }
      <a ref={ downloadLink } href="" style={ { display: "none" } } />
    </>
  );
};

export default PulseExport;
