import React, { useState, useEffect, useRef } from 'react';
import { StateContext } from '../../common/contexts';
import { BAU } from '../plans_data';
import { Router,  initialState } from './router';

const {
  plans, allPlansInclude, headerButtons, welcomePlans, welcomePlanId
} = BAU;

const DesktopBAU = () => {
  const timerRef = useRef(null);
  const [ path, setPath ] = useState(initialState);
  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = `${main.className} -bau`;
  }, []);
  const updatePath = (value) => {
    if (path === value) {
      setPath("Reset");
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setPath(value);
        timerRef.current = null;
      }, 0);
    } else {
      clearTimeout(timerRef.current);
      setPath(value);
    }
  };
  return (
    <StateContext.Provider value={ [ path, updatePath ] }>
      <Router
        welcomePlanId={ welcomePlanId }
        plans={ plans }
        allPlansInclude={ allPlansInclude }
        headerButtons={ headerButtons }
        welcomePlans={ welcomePlans }
      />
    </StateContext.Provider>
  );
};

export default DesktopBAU;
