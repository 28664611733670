import React, { useContext } from 'react';
import cn from "classnames";
import { IndexData } from "../../common/index-context";
import GreenRed from './greenRed';
import Arrow from './arrow';
import Checkbox from "../components/checkbox";
import { baseContext } from '../../../respondent/common/contexts';

const TextHintPercent = ({ text, hint, percent, color, arrow }) => {
  const localColor = arrow ? 'octarine' : color;

  return (
    <div className="comparisons_plate-row">
      <div className="texts">
        <div className="title">{text}</div>
        { !!hint && <div className="note -sm">{hint}</div> }
      </div>
      <div className={ cn({ "value": localColor !== 'black', "value -normal-font": localColor === 'black' }, `-${localColor}`) }>
        {percent}
        {arrow && <Arrow color={ arrow } />}
      </div>
    </div>
  );
};

const Diagnostics = () => {
  const { data } = useContext(IndexData);
  const { model, setModel } = useContext(baseContext);

  return (
    <>
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Diagnostics</h2>
        <Checkbox
          key={ `diagnostics-report` }
          title="Add to Report"
          value={ model.diagnostics }
          paramName="diagnostics"
          onChange={ (v) => { setModel({ ...model, diagnostics: v }); } }
        />
      </div>
      <div className="comparisons_plates">
        { data.diagnostics &&
          <div className="comparisons_plates-grid">

            <div className="comparisons_plates-grid-item -one-third">
              <div className="comparisons_plate -full-height">
                <div className="comparisons_plate-top -center -colored">
                  ‘Big Idea’ Diagnostics
                </div>

                <h3 className="comparisons_plate-title -margin-bottom-sm">
                  Customer Need
                </h3>
                <div className="comparisons_plate-note -sm -black">
                  Category=&quot;{data.category}&quot;
                </div>

                <TextHintPercent
                  text="Category Need"
                  hint="“Absolutely” or “Very much” needed"
                  percent={ data.diagnostics.vcc_need.percent_text }
                  color={ data.diagnostics.vcc_need.color }
                  arrow={ data.diagnostics.vcc_need.arrow }
                />

                <TextHintPercent
                  text="Satisfaction with Category Solutions"
                  hint="“Extremely” or “Very” satisfied"
                  percent={ data.diagnostics.vcc_satisfaction.percent_text }
                  color={ data.diagnostics.vcc_satisfaction.color }
                  arrow={ data.diagnostics.vcc_satisfaction.arrow }
                />

                <hr />

                <h3 className="comparisons_plate-title -margin-bottom-md">
                  {data.branded_texts.solution}
                </h3>

                <TextHintPercent
                  text="Appeal"
                  hint="“Extremely” or “Very” appealing"
                  percent={ data.diagnostics.vcc_appeal.percent_text }
                  color={ data.diagnostics.vcc_appeal.color }
                  arrow={ data.diagnostics.vcc_appeal.arrow }
                />

                <TextHintPercent
                  text="Difference"
                  hint="Agree “Completely” or “Somewhat”"
                  percent={ data.diagnostics.vcc_difference.percent_text }
                  color={ data.diagnostics.vcc_difference.color }
                  arrow={ data.diagnostics.vcc_difference.arrow }
                />

                <TextHintPercent
                  text="Experience is Worthwhile"
                  hint="Agree “Completely” or “Somewhat”"
                  percent={ data.diagnostics.vcc_worthwhile.percent_text }
                  color={ data.diagnostics.vcc_worthwhile.color }
                  arrow={ data.diagnostics.vcc_worthwhile.arrow }
                />
              </div>
            </div>

            <div className="comparisons_plates-grid-item">
              <div className="comparisons_plate -full-height -flex-vertical">
                <div className="comparisons_plate-top -center -colored">
                  Experience Diagnostics
                </div>

                <div className="comparisons_grid -space-lg">
                  <div className="comparisons_grid-item">

                    <h3 className="comparisons_plate-title -margin-bottom-md">
                      Experience  Ease / Effort (Full Scale)
                    </h3>

                    <TextHintPercent
                      text="No effort/A little effort (Ease KPI)"
                      percent={ data.diagnostics.vcc_effort.code45.percent_text }
                      color={ data.diagnostics.vcc_effort.code45.color }
                      arrow={ data.diagnostics.vcc_effort.code45.arrow }
                    />

                    <TextHintPercent
                      text="Some effort"
                      percent={ data.diagnostics.vcc_effort.code3.percent_text }
                      color={ data.diagnostics.vcc_effort.code3.color }
                      arrow={ data.diagnostics.vcc_effort.code3.arrow }
                    />

                    <TextHintPercent
                      text="Moderate effort"
                      percent={ data.diagnostics.vcc_effort.code2.percent_text }
                      color={ data.diagnostics.vcc_effort.code2.color }
                      arrow={ data.diagnostics.vcc_effort.code2.arrow }
                    />

                    <TextHintPercent
                      text="A lot of effort"
                      percent={ data.diagnostics.vcc_effort.code1.percent_text }
                      color={ data.diagnostics.vcc_effort.code1.color }
                      arrow={ data.diagnostics.vcc_effort.code1.arrow }
                    />

                    <hr />

                    {data.branded !== "unbranded" && <AdditionalDiagnostics diagnostics={ data.diagnostics } />}

                  </div>
                  <div className="comparisons_grid-item -narrow">
                    {data.branded === "unbranded" && <AdditionalDiagnostics diagnostics={ data.diagnostics } />}
                    {data.branded !== "unbranded" &&
                      <ExperienceImpact diagnostics={ data.diagnostics } texts={ data.branded_texts } />}
                  </div>
                </div>

                <div className="comparisons_plate-spacer" />

                <div className="comparisons_plate-legend">
                  <GreenRed arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
                </div>

              </div>
            </div>

          </div>
        }
      </div>
    </>
  );
};

const ExperienceImpact = ({ diagnostics, texts }) => {
  return (
    <>
      <h3 className="comparisons_plate-title -margin-bottom-md">
        Experience Impact
      </h3>

      <TextHintPercent
        text={ texts.vcc_opinion_code3 }
        percent={ diagnostics.vcc_opinion.code3.percent_text }
        color={ diagnostics.vcc_opinion.code3.color }
        arrow={ diagnostics.vcc_opinion.code3.arrow }
      />

      <TextHintPercent
        text="No Impact"
        percent={ diagnostics.vcc_opinion.code2.percent_text }
        color={ diagnostics.vcc_opinion.code2.color }
        arrow={ diagnostics.vcc_opinion.code2.arrow }
      />

      <TextHintPercent
        text={ texts.vcc_opinion_code1 }
        percent={ diagnostics.vcc_opinion.code1.percent_text }
        color={ diagnostics.vcc_opinion.code1.color }
        arrow={ diagnostics.vcc_opinion.code1.arrow }
      />
    </>
  );
};

const AdditionalDiagnostics = ({ diagnostics }) => {
  return (
    <>
      <h3 className="comparisons_plate-title -margin-bottom-md">
        Additional Diagnostics
      </h3>

      <TextHintPercent
        text="Simple & Intuitive"
        hint="Agree “Completely” or “Somewhat”"
        percent={ diagnostics.vcc_simple.percent_text }
        color={ diagnostics.vcc_simple.color }
        arrow={ diagnostics.vcc_simple.arrow }
      />

      <TextHintPercent
        text="Honest No Surprises"
        hint="Agree “Completely” or “Somewhat”"
        percent={ diagnostics.vcc_honest.percent_text }
        color={ diagnostics.vcc_honest.color }
        arrow={ diagnostics.vcc_honest.arrow }
      />

      <TextHintPercent
        text="Clarity"
        hint="“Very” or “Somewhat” clear and understandable"
        percent={ diagnostics.vcc_process_clarity.percent_text }
        color={ diagnostics.vcc_process_clarity.color }
        arrow={ diagnostics.vcc_process_clarity.arrow }
      />
    </>
  );
};

export default Diagnostics;
