import React, { useEffect, useState } from 'react';
import Routes from './routes';
import { RouteContext } from './contexts';

// vars: mobile and conceptName
// don't use mobile flag (we concepts are only mobile)
// conceptNames: 'base' | 'A' | 'B'

const App = ({ conceptName }) => {
  const [ path, setPath ] = useState('/');
  const [ isDone, setDone ] = useState(false);

  const [ startTimeApp ] = useState(new Date());

  const [ btnPlansAvailable, setBtnPlansAvailable ] = useState(false);

  const delay = 15000;
  useEffect(() => {
    if (!btnPlansAvailable) {
      setTimeout(() => {
        setBtnPlansAvailable(true);
      }, delay);
    }
  }, []);

  const handleDone = () => {
    setDone(true);
  };

  return (
    !isDone
      ? <RouteContext.Provider value={ { path, setPath } }>
        <Routes
          conceptName={ conceptName } startTimeApp={ startTimeApp }
          onDone={ handleDone } btnPlansAvailable={ btnPlansAvailable }
        />
      </RouteContext.Provider>
      : null
  );
};

export default App;
