import { map } from 'lodash';
import { getElement } from '../../../common/dom_functions';

class StepsLogic {
  constructor(projectId, texts, updateElement, steps) {
    this.step = 0;
    this.updateElement = updateElement;
    this.list = map(
      steps(projectId, updateElement),
      (item) => {
        const newItem = {
          ...item,
          get text() {
            return texts[this.id - 1];
          }
        };
        newItem.callback = (domEl) => {
          if (item.callback) {
            item.callback(newItem, domEl);
          }
        };
        return newItem;
      }
    );
  }
  setFuncToGetTourElements(newFunc) {
    this.getTourElements = newFunc;
  }
  domEl() {
    const elements = this.getTourElements();
    let domEl = null;
    const currentElement = this.element();
    if (currentElement) {
      domEl = currentElement.elementKey ?
        elements[currentElement.elementKey]
        :
        getElement(currentElement.element);
      if (currentElement.elementChildren) {
        domEl = domEl.children[0];
      }
    }
    return domEl;
  }
  element() { return this.step >= this.list.length ? null : this.list[this.step]; }
  setStep(n) {
    this.step = n;
    const element = this.element();
    this.updateElement({ ...element });
    if (element) {
      element.callback(this.domEl());
    }
  }
  refresh() { this.updateElement({ ...this.element() }); }
  lastStep() { return this.step === this.list.length - 1; }
  nextStep(skipCallback) {
    this.setStep(this.step + 1);
    if (!this.element() && skipCallback) {
      skipCallback();
    }
  }
  isFirst() { return !this.step; }
}

export default StepsLogic;
