import Http from "../../../common/http";

export const updateAttrs = (projectId, conceptId, setData, payload) => {
  const url = `/express/api/projects/${projectId}/concept_tags/${conceptId}`;

  sendPostRequest(url, payload, setData);
};

export const bulkUpdateAttrs = (projectId, conceptId, setData, payload) => {
  const url = `/express/api/projects/${projectId}/concept_tags/${conceptId}/bulk_update`;

  sendPostRequest(url, payload, setData);
};

export const selectTag = (projectId, conceptId, setData, payload) => {
  const url = `/express/api/projects/${projectId}/concept_tags/${conceptId}/select_tag`;

  sendPostRequest(url, payload, setData);
};

export const clearTags = (projectId, conceptId, setData, payload = {}) => {
  const url = `/express/api/projects/${projectId}/concept_tags/${conceptId}/clear_tags`;

  sendPostRequest(url, payload, setData);
};

export const createTag = (projectId, conceptId, setData, payload = {}) => {
  const url = `/express/api/projects/${projectId}/concept_tags/${conceptId}/create_tag`;

  sendPostRequest(url, payload, setData);
};

export const updateNotes = (projectId, conceptId, payload = {}) => {
  const url = `/express/api/projects/${projectId}/concept_tags/${conceptId}/update_notes`;

  sendPostRequest(url, payload, () => {});
};

const sendPostRequest = (url, payload, callback) => {
  Http.post(url, { ...payload }).then(
    (response) => {
      callback(response.data.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export default { updateAttrs, selectTag, clearTags, createTag, bulkUpdateAttrs, updateNotes };
