import { find } from 'lodash';
import {
  customMetricsByLocation, defaultScorings,
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible
} from '../../common/logic/eligible.js';
import BaseForward from '../../common/logic/base_forward';
import {respondentQualified} from "../../../common/google-analytics/events";

class Forward extends BaseForward {
  constructor(options) {
    super(options);
    this.updateConceptStep = options.updateConceptStep;
    this.updateShelfStep = options.updateShelfStep;
    this.updateConceptIndex = options.updateConceptIndex;
  }

  forward(step, data, result, goNextConcept, projectId, antiFraud, gaTrackingId) {
    let newStep = step + 1, needForward = false;

    switch (newStep) {
      case 1: //demographics
        if (!data.demographics.length) {
          needForward = true;
        }
        break;
      case 2: //optional demographics
        if (demographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.optional_demographics.length) {
          needForward = true;
        }
        break;
      case 3: //screening
        if (optionalDemographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.screenings.length) {
          needForward = true;
        }
        break;
      case 4: // Qualified
        if (screeningsNotEligible(data, result.answers, gaTrackingId)) {
          newStep = this.screenedActionNum;
          needForward = true;
        }
        break;
      case 5:
        respondentQualified(gaTrackingId, { project_id: projectId, respondent_id: data.respondent_id });
        if (customMetricsByLocation(data.survey_metrics, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 6:
        if (customMetricsByLocation(data.scorings, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 7: // Concepts initializing
        // Check the step === 1 in nextConceptStep func
        goNextConcept();
        break;
      case 8:
        if (customMetricsByLocation(data.survey_metrics, 'default').length === 0) {
          needForward = true;
        }
        break;
      case 9:
        if (customMetricsByLocation(data.scorings, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
      case 10:
        if (customMetricsByLocation(data.survey_metrics, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
    }

    [ newStep, needForward ] = this.checkLastSteps(
      this, newStep, needForward, 11, data, result, projectId, antiFraud
    );
    if (!newStep) {
      return;
    }

    if (needForward) {
      this.forward(newStep, data, result, goNextConcept, projectId, antiFraud);
    } else {
      this.updateStep(newStep);
    }
  }

  nextConceptStep(conceptStep, conceptIndex, concept, data, goForward) {
    let nextStep = conceptStep + 1;
    let needForward = false;

    const scorings = defaultScorings(data.scorings);

    const purchaseInterest = find(scorings, (item) => (item.dm_name === 'purchase_interest'));
    const likelihoodToPurchase = find(scorings, (item) => (item.dm_name === 'likelihood_to_purchase'));
    const packageLiking = find(scorings, (item) => (item.dm_name === 'appeal'));
    const brandFit = find(scorings, (item) => (item.dm_name === 'brand_fit'));
    const packageDistinguishable = find(scorings, (item) => (item.dm_name === 'uniqueness'));
    const uniqueness = find(scorings, (item) => (item.dm_name === 'package_mc_uniqueness'));
    const customMetrics = defaultScorings(data.custom_metrics || []);

    switch (nextStep) {
      case 1: // Shelf Shot
        if (!concept.shelf_exercise) {
          needForward = true;
        }
        break;
      case 2: // Intro
        break;
      case 3: //TB
        if (!data.tb_enabled || !concept.show_thought_bubble) {
          needForward = true;
        }
        break;
      case 4: // CLAIMED PURCHASE INTEREST
        if (!purchaseInterest) {
          needForward = true;
        }
        break;
      case 5: // LIKELIHOOD TO PURCHASE OVER OTHER HARD SELTZERS
        if (!likelihoodToPurchase) {
          needForward = true;
        }
        break;
      case 6: // PACKAGE LIKING
        if (!packageLiking) {
          needForward = true;
        }
        break;
      case 7: // Uniqueness
        if (!uniqueness) {
          needForward = true;
        }
        break;
      case 8: // Markup
        if (!concept.focus_concept_id) {
          needForward = true;
        }
        break;
      case 9: // Attributes List with OR
        if (!data.package_attributes.length) {
          needForward = true;
        }
        break;
      case 10: // Attribute Ratings: IMAGERY COMMUNICATION
        if (!data.imagery_communication.length) {
          needForward = true;
        }
        break;
      case 11: // FIT WITH VIZZY
        if (!brandFit) {
          needForward = true;
        }
        break;
      case 12: // Custom Metrics
        if (!customMetrics.length) {
          needForward = true;
        }
        break;
      case 13: // DISTINGUISHABLE PACKAGE
        if (!packageDistinguishable) {
          needForward = true;
        }
        break;
      case 14: //Next concept
        if (conceptIndex === data.concepts.length - 1) {
          goForward();
          return;
        }

        this.updateConceptIndex(conceptIndex + 1);
        needForward = true;
        nextStep = 1;
        break;
    }

    if (needForward) {
      this.nextConceptStep(nextStep, conceptIndex, concept, data, goForward);
    } else {
      this.updateConceptStep(nextStep);
      this.updateShelfStep(1);
    }
  }

  nextShelfStep(shelfStep, concept, data, goForward) {
    const nextStep = shelfStep + 1;
    let needForward = false;
    const cartExercise = data.cart_exercise;
    const cartRanking = data.cart_ranking;
    const cartProductsLength = (concept.cart_exercise_data?.products_uniq || []).length;
    const shelfEnabled = data.shelf_enabled;

    switch (nextStep) {
      case 1: // PreIntro
        break;
      case 2: // Shelf View only
        break;
      case 3: //Shelf Preference
        if (!cartExercise || !cartRanking || cartProductsLength < 3) {
          needForward = true;
        }
        break;
      case 4: // Shelf Cart PreIntro
        if (!cartExercise) {
          needForward = true;
        }
        break;
      case 5: // Shelf Cart Exercise
        if (!cartExercise) {
          needForward = true;
        }
        break;
      case 6: // Shelf Find Time PreIntro
        if (!shelfEnabled) {
          needForward = true;
        }
        break;
      case 7: // Shelf Find Time Exercise
        if (!shelfEnabled) {
          needForward = true;
        }
        break;
      case 8: //Next concept step
        goForward();
        return;
    }

    if (needForward) {
      this.nextShelfStep(nextStep, concept, data, goForward);
    } else {
      this.updateShelfStep(nextStep);
    }
  }
}
export default Forward;
