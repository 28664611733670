import React from 'react';
import InfoToolTip from '../../common/infoToolTip';

const AnswersHeader = ({ title, text, img, infoText = null, children }) => (
  <div className="form_section">
    <div className="form_grid">
      <div className="form_grid-item -width-full">
        <div className="form_section-title -grid">
          <div className="form_section-title-item">
            <h3 className="colored-header">
              {title}
            </h3>
          </div>
          <div className="form_section-title-item">
            <InfoToolTip
              text={ infoText || title }
              img={ img }
              imgClassName="-bigger"
              position="bottom-right"
            />
          </div>
        </div>
        <div className="form_section-text">{text}</div>
        { !!children && children }
      </div>
    </div>
  </div>
);

export default AnswersHeader;
