import React from "react";
import cn from "classnames";

const Button = ({
  children,
  variant = "filled",
  size = "medium",
  className,
  onClick,
}) => {
  return (
    <button
      className={cn("button", `-${size}`, `-${variant}`, className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
