import React from 'react';

const Header = ({ backUrl }) => {
  return (
    <div className="content_top -sticky -gray">
      <header className="header">
        <div className="header_grid -first-button">
          <div className="header_grid-item">
            <a className="button" href={ backUrl }>
              <svg className="icon -arrow-up-left">
                <use xlinkHref="#svg_arrow-up-left" />
              </svg>
            </a>
          </div>
          <div className="header_grid-item">
            <h1 className="header_title">Multi Language Project</h1>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
