import React from "react";
import { filter } from "lodash";
import InfoToolTip from '../../common/infoToolTip';
import AnswerAdditionalLine from "../common/AnswerAdditionalLine";
import EditableGridItems from "../common/EditableGridItems";
import Checkbox from "../../common/Checkbox";
import Switchbox from "./Switchbox";

const GridProductUsage = ({ model, setModel, secondModel, setSecondModel, max, otherSpecify }) => {
  const handleOnChangeEnabled = (newBool) => {
    setModel({ ...model, enabled: newBool });
    if (!newBool) {
      setSecondModel({ ...secondModel, enabled: newBool });
    }
  };

  const handleOnChangeSecondEnabled = (newBool) => {
    setSecondModel({ ...secondModel, enabled: newBool });
  };

  const handleOnDeleteEditableGridItem = (index) => {
    if (model.grid_items[index].id) {
      model.grid_items[index]._destroy = '1';
    } else {
      model.grid_items.splice(index, 1);
    }
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleOnChangeEditableGridItem = (newText, index) => {
    model.grid_items[index].question = newText;
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleChangeOther = (newBool, newText) => {
    setModel({ ...model, other_answer: newBool, other_answer_text: newText });
  };

  const handleOnAddEditableGridItem = (newText) => {
    model.grid_items.push({
      id: null,
      question: newText,
      enabled: true,
      editable: true
    });

    setModel({ ...model, grid_items: model.grid_items });
  };

  const itemsCount = filter(model.grid_items, (item) => (item.enabled && !item._destroy)).length;
  const showAddNewItem = itemsCount < max;

  return (
    <div className="form_metrics-item -js-pu">
      <Checkbox label={ model.default_metric_title } bool={ model.enabled } onChange={ handleOnChangeEnabled } />
      <InfoToolTip>
        Brand Users will indicate which of the Target Brand’s products/services they have used. Input up to {max}.
      </InfoToolTip>
      {
        model.enabled &&
        <div className="form_metrics -inner">
          <div className="form_metrics-item">
            <Switchbox
              id="ps-id"
              label={ secondModel.default_metric_title }
              bool={ secondModel.enabled }
              onChange={ handleOnChangeSecondEnabled }
            />
            <InfoToolTip>
              Brand users will be asked how satisfied they are with the brand’s products they use.
            </InfoToolTip>
          </div>
          <ul className="form-question_answers">
            <EditableGridItems
              gridItems={ model.grid_items }
              onChange={ handleOnChangeEditableGridItem }
              onDelete={ handleOnDeleteEditableGridItem }
              showAddNewItem={ showAddNewItem }
              newItemPlaceholder={ `Add up to ${max - itemsCount} products` }
              onAdd={ handleOnAddEditableGridItem }
            />
            <AnswerAdditionalLine
              title={ otherSpecify }
              bool={ model.other_answer }
              text={ model.other_answer_text }
              onChange={ handleChangeOther }
            />
          </ul>
        </div>
      }
    </div>
  );
};

export default GridProductUsage;
