import Http from '../../../common/http';

const loadSpotlights = (mediaKind, verizonType, callback) => {
  Http.get(`/verizon/spotlights.json?media_kind=${mediaKind}&verizon_type=${verizonType}`).then(
    (response) => {
      const { current_group, available_groups, list } = response.data;
      callback && callback(list, current_group, available_groups);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const createSpotlight = (mediaKind, verizonType, spotlight, callback) => {
  Http.post(`/verizon/spotlights.json`, {
    media_kind: mediaKind, verizon_type: verizonType, spotlight
  }).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const updateSpotlight = (mediaKind, verizonType, id, spotlight, callback) => {
  Http.put(`/verizon/spotlights/${id}.json`, {
    media_kind: mediaKind, verizon_type: verizonType, spotlight
  }).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

const deleteSpotlight = (mediaKind, id, callback) => {
  Http.del(`/verizon/spotlights/${id}.json`, {
    media_kind: mediaKind
  }).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { loadSpotlights, createSpotlight, updateSpotlight, deleteSpotlight };
