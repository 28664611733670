import React, { useContext } from 'react';
import Header from "./common/header";
import Footer from "./common/footer";
import AboutHero from "./about/aboutHero";
import AboutStory from "./about/aboutStory";
import AboutValues from "./about/aboutValues";
import AboutPillars from "./about/aboutPillars";
import AboutSwitch from "./about/aboutSwitch";
import PlatePinned from "./common/platePinned";
import { DataContext } from "./contexts";

const About = ({ onContinue }) => {
  const { btnPlansAvailable } = useContext(DataContext);
  return (<div className="layout ">
    <Header clickableDroppedMenu />
    <AboutHero />
    <AboutStory />
    <AboutValues />
    <AboutPillars />
    <AboutSwitch />
    <Footer />
    {btnPlansAvailable && (
      <PlatePinned onContinue={ onContinue } />
    ) }
  </div>);
};

export default About;
