import React, { useContext } from 'react';
import { map } from 'lodash';
import { IndexData } from "../../common/index-context";
import { OpenEndContext } from '../contexts';

const CustomerChallenges = () => {
  const { data } = useContext(IndexData);
  const { challengesSummary } = useContext(OpenEndContext);

  return (
    <>
      <h2 className="comparisons_section-title">Customer Challenges</h2>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title">Customer Challenges with&nbsp;the&nbsp;Experience</h3>
              <hr />
              {
                challengesSummary &&
                <>
                  <p className="comparisons_plate-note -xs -margin-bottom-lg -black">
                    Unaided, top themes among the {data.challenges.percent_text} (n={data.challenges.n}) of respondents
                    who said the experience would require at least some effort.
                  </p>
                  {
                    map(challengesSummary, (vv, index) => (
                      <p className="comparisons_plate-p" key={ index }>
                        <b>{ vv[0] }: </b>{ vv[1] }
                      </p>
                    ))
                  }
                </>
              }
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default CustomerChallenges;
