import React from 'react';
import { InnerAccordion, accordionTitleFactory } from '../common/accordion';
import CompatibleTip from '../common/compatible-tip';
import InfoIcon from '../common/info-icon';
import { useInvariants, useHowToConnect } from './hooks';

const InvariantBox = ({ selected, onClick, children }) => {
  const className = [
    'how-to-connect-box-invariant',
    '-invariant-a',
    selected ? '-active': ''
  ].join(' ').trim();

  return <div className={className} onClick={onClick}>{children}</div>
};

const InvariantTitle = ({ children }) =>
  <p className="how-to-connect-box-invariant-title -invariant-a">{children}</p>

const Option = ({ count, price, connect, tvConnections }) => {
  return <div className="how-to-connect-option">
    {count === Infinity
      ? <div className="how-to-connect-option-title-wrapper">
          <InvariantTitle>Unlimited Fios TV Home App connections</InvariantTitle>
          <InfoIcon popup={true} />
        </div>
      : <InvariantTitle>
          {`${count} Fios TV Home App ${count === 1 ? 'connection' : 'connections'}`}
        </InvariantTitle> 
    }
    <ul className="how-to-connect-option-list">
      {
        count === Infinity && <>
          <li className="how-to-connect-option-list-item">Access on certain Apple or Fire TV devices</li>
          <li className="how-to-connect-option-list-item">Log in and watch on ALL of your Connected TV Devices (Up to 2 streams at one time)</li>
          <li className="how-to-connect-option-list-item">Watch on up to {tvConnections} TVs at any one time</li>
        </>
      }
      {
        count !== Infinity && <>
          <li className="how-to-connect-option-list-item">Access on certain Apple or Fire TV devices</li>
          <li className="how-to-connect-option-list-item">Log in and watch on {count} of your Connected TV device(s)</li>
          <li className="how-to-connect-option-list-item">Watch on up to {tvConnections} TVs at any one time</li>
        </>
      }
    </ul>
    {count !== Infinity && <div className="how-to-connect-option-price">
      <InvariantTitle>
        Included with selection
      </InvariantTitle>
    </div>}
    {count === Infinity && <div className="how-to-connect-option-price">
      <InvariantTitle>
        {`$${Math.abs(price)} ${price > 0 ? 'more' : 'less'}/mo`}
      </InvariantTitle>
    </div>}
  </div>;
}

const checkOptionsToShow = (variant) =>
  variant && variant.options.length > 0;

const Invatiants = () => {
  const {
    variant,
    option,
    invariants,
    updateVariant,
    updateOption
  } = useInvariants();

  return <>
    <h6 className="how-to-connect-title">How many set-top boxes?</h6>
    <ul className="how-to-connect-invariant-list">
      {invariants.map((invariant, idx) =>
        <li className="how-to-connect-invariant-list-item -invariant-a" key={idx}>
          <InvariantBox
            selected={variant === invariant}
            onClick={() => updateVariant(invariant)}>
            <InvariantTitle>
              {`${invariant.count} set-top ${invariant.count === 1 ? 'box' : 'boxes'}`}
            </InvariantTitle>
          </InvariantBox>
        </li>
      )}
    </ul>
    {checkOptionsToShow(variant) && <>
      <div className="how-to-connect-seporator"/>
      <h6 className="how-to-connect-title">How many Fios TV Home App connections?</h6>
      <ul className="how-to-connect-invariant-list">
        {variant.options.map((subVariant, idx) =>
          <li className="how-to-connect-invariant-list-item -sub" key={idx}>
            <InvariantBox
              selected={option === subVariant}
              onClick={() => updateOption(subVariant)}>
              <Option
                price={subVariant.priceToShow}
                count={subVariant.count}
                connect={subVariant.connect}
                tvConnections={subVariant.tvConnections} />
            </InvariantBox>
          </li>)}
      </ul>
    </>}
  </>
}

const HowToConnect = ({ open, canOpen, onChange }) => {
  const { sectionTitle, chipText, chipClassName } = useHowToConnect();
  return <InnerAccordion
    open={open}
    canOpen={canOpen}
    onChange={onChange}
    TitleComponent={accordionTitleFactory(sectionTitle, chipText, chipClassName)}>
    <div className="compatible-tip-wrapper">
      <CompatibleTip/>
    </div>
    <Invatiants />
  </InnerAccordion>;
};

export default HowToConnect;