import Http from '../../../common/http';

const sendProfileUpdateRequest = (
  user, goodCallback, badCallback
) => {
  Http.put('/express/api/profile', { user }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    }
  );
};

export { sendProfileUpdateRequest };
