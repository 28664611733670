import React from "react";
import InstructorProfile from "./InstructorProfile";

const InstructorsCompilation = ({ instructors }) => (
  <div className="instructors-compilation">
    <h3 className="instructors-compilation_title">
      More from your instructors
    </h3>
    <div className="instructors-compilation_list">
      {instructors.map(({ imgUrl, firstName, lastName, brand }, i) => (
        <div
          className={`instructors-compilation_item ${i === 0 ? "-active" : ""}`}
          key={i}
        >
          <InstructorProfile
            imgUrl={imgUrl}
            firstName={firstName}
            lastName={lastName}
            company={brand.title}
          />
        </div>
      ))}
    </div>
  </div>
);

export default InstructorsCompilation;
