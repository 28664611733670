import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { withPrefix } from "../helpers";
import { baseContext } from "../contexts";

const Header = ({ basepath }) => {
  const {
    mediaKind, isPreview, verizonType, oneSubDashboard, combine5gAndLte
  } = useContext(baseContext);
  const location = useLocation();
  const postfix = location.pathname === '/' ? '' : location.pathname;

  return (
    <div className="header">
      <div className="header_container">
        { oneSubDashboard && <div className="header_menu" >&nbsp;</div> }
        {
          (!isPreview && !oneSubDashboard) &&
          <ul className="header_menu">
            <li className="header_menu-item">
              <a
                href={ `${basepath}/v5g${postfix}` }
                className={
                  classnames(
                    'button-link header_menu-link',
                    { '-active': mediaKind === 'v5g' }
                  )
                }
              >
                { withPrefix(verizonType, 'v5g') }
              </a>
            </li>
            <li className="header_menu-item">
              <a
                href={ `${basepath}/lte${postfix}` }
                className={
                  classnames(
                    'button-link header_menu-link',
                    { '-active': mediaKind === 'lte' }
                  )
                }
              >
                { withPrefix(verizonType, 'lte') }
              </a>
            </li>
          </ul>
        }
        <div className="header_title">
          <div className="header_title-logo">
            <svg className="icon header_logo">
              <use xlinkHref={ `#svg_verizon-logo-short` } />
            </svg>
          </div>
          <h1 className="header_title-text">
            { !combine5gAndLte && <>FWA{' '}</>}
            {
              withPrefix(
                verizonType, mediaKind,
                'Customer Advisory Board', false,
                !!combine5gAndLte
              )
            }
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
