import React from "react";
import user from "../mocks/user";
import Avatar from "./Avatar";
import Icon from "./Icon";

const ActivityHeader = () => {
  return (
    <div className="activity-header">
      <div className="activity-header_user">
        <Avatar {...user} />
      </div>
      <h4 className="activity-header_title">Activities</h4>
      <div className="activity-header_actions">
        <div className="activity-header_action">
          <Icon name="history" />
        </div>
        <div className="activity-header_action">
          <Icon name="add-activity" />
        </div>
      </div>
    </div>
  );
};

export default ActivityHeader;
