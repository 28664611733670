import React from 'react';
import { find, flatten, map, filter } from 'lodash';
import ConceptView from './conceptView';

const Stimuli = ({
  ideaName, customerBenefit, reasonsToBelieve, processLeadInOptions, processLeadIn, processLeadInOther,
  processStepsKind, steps, additionalImageUrl, additionalImageNotes, branded,
  specificSteps, specificStepIndex, zoomImage, imageClassName
}) => {
  let leadInPhrase = '';
  let process = {};
  const leadInIsOther = (processLeadIn === 'other');
  if (processLeadIn) {
    if (leadInIsOther) {
      leadInPhrase = processLeadInOther;
    } else {
      const leadInOption = find(processLeadInOptions, (el) => el.value === processLeadIn);
      leadInPhrase = leadInOption?.title;
    }
  }
  if (processStepsKind) {
    if (processStepsKind === 'text') {
      const stepsToIterate = specificSteps ? [ specificSteps[specificStepIndex] ] : steps;
      const fullProcessHtml = flatten(
        [
          '<ul class="cit-steps-list">',
          map(
            filter(stepsToIterate, (el) => el.kind === 'text'),
            (el) => `<li>${el.notes}</li>`
          ),
          '</ul>'
        ]
      ).join('');
      process = { text: fullProcessHtml, notes: fullProcessHtml };
    } else if (specificSteps) {
      const specificKind = specificSteps[specificStepIndex];
      if (specificKind?.image_src) {
        process = { url: specificKind.image_src, notes: specificKind.notes };
      }
    } else {
      const fullKind = find(steps, (el) => el.kind === 'pdf_full');
      if (fullKind?.image_src) {
        process = { url: fullKind.image_src, notes: fullKind.notes };
      }
    }
  }
  return (
    <ConceptView
      title={ ideaName }
      customerBenefit={ customerBenefit }
      reasonsToBelieve={ reasonsToBelieve }
      leadInPhrase={ leadInPhrase }
      leadInIsOther={ leadInIsOther }
      processKind={ processStepsKind }
      process={ process }
      additionalImageUrl={ additionalImageUrl }
      additionalImageNotes={ additionalImageNotes }
      branded={ branded }
      zoomImage={ zoomImage }
      imageClassName={ imageClassName }
    />
  );
};

export default Stimuli;
