import React, { useContext } from 'react';
import { PanelServiceContext } from '../../../contexts';
import EditableInput from '../common/editableInput';

const QuotaScreenedInput = () => {
  const {
    getQuotaUrl, setQuotaUrl, inputsDisabled, getErrorByField
  } = useContext(PanelServiceContext);
  const quotaUrl = getQuotaUrl();
  const disabled = inputsDisabled();
  const error = getErrorByField('panel_service_quota_url');
  return (
    <EditableInput
      title="Quota Url"
      value={ quotaUrl }
      onChange={ setQuotaUrl }
      disabled={ disabled }
      error={ error }
    >
      Respondents will be redirected to this url after their disqualification
      {' '}
      because of the quota reach.
      {' '}
      Please make sure it includes <b>"%respid%"</b>
      {' '}
      - system will automatically replace this template with the actual respondent id.
      <br />
      For example: <b>https://test.provider?respid=%respid%</b>.
    </EditableInput>
  );
};

export default QuotaScreenedInput;
