import React from 'react';
import DesktopBAU from './bau/desktop';
import MobileBAU from './bau/mobile';
import DesktopFlex from './flex/desktop';
import MobileFlex from './flex/mobile';
import { SetContext } from './common/contexts';
import Cell1 from './cells/cell1';
import Cell2 from './cells/cell2';
import Cell3 from './cells/cell3';
import Cell4 from './cells/cell4';

// conceptName = 'BAU' | 'FLEX'
// concept_name in url: mobile_BAU, etc
const App = ({ conceptName, mobile }) => {
  const contextValue = {
    delayAnimation: 500
  };
  const Concept = () => {
    if (mobile) {
      switch (conceptName) {
        case 'BAU': return <MobileBAU />;
        case 'FLEX': return <MobileFlex />;
        default: return null;
      }
    } else {
      switch (conceptName) {
        case 'BAU': return <DesktopBAU />;
        case 'FLEX': return <DesktopFlex />;
        case 'CELL1': return <Cell1 />;
        case 'CELL2': return <Cell2 />;
        case 'CELL3': return <Cell3 />;
        case 'CELL4': return <Cell4 />;
        case 'CELL5': return <DesktopBAU />;
        default: return null;
      }
    }
  };

  return (
    <SetContext.Provider value={ contextValue }>
      <Concept />
    </SetContext.Provider>
  );
};

export default App;
