import React from 'react';

const LandingHowWorks = ({ waysToSave }) => {
  const stepsList = [
    { title: 'Become a member',
      text: 'Easily sign up, and keep your number and phone, or change both. You might even be able to activate ' +
        'service in less than 15 minutes through eSIM. No matter what, you’ll get unlimited data, messages, and minutes for one (really) low price.',
      icon: 'emoji-1'
    },
    { title: 'Experience our network',
      text: 'Activate through eSIM or a Visible SIM (it’s free & we’ll overnight it), and you’re ready to ' +
        'experience our network, powered by Verizon, 5G included.',
      icon: 'emoji-2'
    }
  ];
  if (waysToSave) {
    stepsList.push({
      title: 'Explore ways to save',
      text: 'Since Visible is wireless that gets better with friends, it makes sense to give you several ' +
        'ways for everyone to save. To save, join a Party or bring a friend every month.',
      icon: 'emoji-3'
    });
  }

  return (
    <section className="landing-how-works">
      <div className="landing-how-works_text">
        <h3 className="landing-how-works_title">
          How Visible works
        </h3>
        <div className="landing-how-works_p">
          We know how important it is to keep it simple. That’s why we created a wireless service that allows you access to everything you need to join, save, and stream—right from your phone.
        </div>
      </div>
      <ul className="landing-how-works_steps">
        {
          stepsList.map((step, index) => (
            <li className="landing-how-works_step" key={ `step-${index}` }>
              <div className="landing-how-works_step-icon">
                <svg className={ `icon -${step.icon}` }>
                  <use xlinkHref={ `#svg_${step.icon}` } />
                </svg>
              </div>
              <h4 className="landing-how-works_step-title">{step.title}</h4>
              <p className="landing-how-works_step-texts">{step.text}</p>
            </li>
          ))
        }
      </ul>
    </section>
  );
};

export default LandingHowWorks;
