import React from 'react';
import { map } from 'lodash';
import Level2Line from "./common/Level2Line";
import NewTagLine from "./common/NewTagLine";

const TagsOneLevel = ({ tags, header, tagType }) => {
  return (
    <div className="form_section-tabs -max-50vw">
      <div className="form_section">
        <div className="form_section-title">
          <h2>{header}</h2>
        </div>
        <div className="form_section-line">
          {
            map(tags, (tagObj) => (
              <Level2Line
                modalInfo={ { id: tagObj.value, category: header, text1: tagObj.tag, text2: '' } }
                key={ tagObj.value }
                tagObj={ tagObj }
                tagType={ tagType }
              />
            ))
          }

          <NewTagLine tagType={ tagType } />
        </div>
      </div>
    </div>
  );
};

export default TagsOneLevel;
