import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { assign } from 'lodash';
import { demoShareProject } from '../../requests';
import { IndexData } from './contexts';

const ProjectDemoShare = ({ project, mode }) => {
  const { setProjects, projects } = useContext(IndexData);
  const [ disabled, setDisabled ] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDisabled(true);
    const newDemoValue = !project.demo;
    demoShareProject(
      project.update, newDemoValue,
      (response) => {
        if (response?.response?.statusText === 'OK') {
          const newProjectsHash = response.data.projects[0];
          if (newProjectsHash.id === project.id) {
            assign(project, newProjectsHash);
            setProjects([ ...projects ]);
          }
        }
        setDisabled(false);
      },
      () => {
        setDisabled(false);
      });
  };
  return (
    <div
      key={ `${project.id}${project.demo}` }
      className={
        classnames(
          "project-demo-share-buttons",
          { '-cards': mode === 'cards', '-list': mode === 'list' }
        )
      }
    >
      <button
        className={ classnames("button set-demo-button", { '-on': project.demo }) }
        onClick={ handleClick }
        disabled={ disabled }
      >
        { project.demo ? 'Remove from Demo' : 'Add to Demo' }
      </button>
    </div>
  );
};

export default ProjectDemoShare;
