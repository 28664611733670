import React, { useContext, useState, useRef, useEffect } from 'react';
import classnames from "classnames";
import render_raw_html from '../../../common/render_raw_html';
import { spotlightsDetailsContext } from '../contexts';
import { stripHtml, customerSpotlightNote } from '../helpers';
import Video from './video';

const Column = ({ primary, children }) => {
  const className = [
    'customer-spotlight_column',
    primary ? '-primary' : '-secondary'
  ].filter(Boolean).join(' ').trim();
  return (<div className={ className }>
    {children}
  </div>);
};

const OuterRaw = ({ className, data }) => {
  return (
    <div
      className={ className }
      { ...render_raw_html(data) }
    />
  );
};

const Title = () => {
  const titleRef = useRef(null);
  const {
    id, name, getCollapsed, setCollapsed, initExpand, setExpandedObj
  } = useContext(spotlightsDetailsContext);

  const collapsed = getCollapsed();
  const toggleClick = (event) => {
    event.preventDefault();
    setExpandedObj(
      { ...{ id, current: (collapsed ? titleRef.current : null), action: 'toggle' } }
    );
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (initExpand) {
      setExpandedObj(
        { ...{ id, current: titleRef.current, action: null } }
      );
    }
  }, []);

  return (
    <h4 className="customer-spotlight_title" onClick={ toggleClick } ref={ titleRef }>
      <span>{ name }</span>
      <a href="#">{ collapsed ? "Expand" : "Collapse" }</a>
    </h4>
  );
};

const Avatar = () => {
  const { avatar_url } = useContext(spotlightsDetailsContext);
  return (
    <>
      { avatar_url && <img src={ avatar_url } alt="Member Spotlight" /> }
    </>

  );
};

const Notes = () => {
  const { base_info } = useContext(spotlightsDetailsContext);
  return (
    <>
      {
        !!stripHtml(base_info) &&
        <OuterRaw className="customer-spotlight_notes" data={ base_info } />
      }
    </>
  );
};

const InstallType = () => {
  const { install_type, noLeftSide, noBetterExperience } = useContext(spotlightsDetailsContext);
  return (
    <>
      {
        !!stripHtml(install_type) &&
        <div
          className={ classnames("customer-spotlight_install-type", { "-no-left-side": noLeftSide(), "-no-better-experience": noBetterExperience() }) }
        >
          <div className="highlight" { ...render_raw_html(install_type) } />
        </div>
      }
    </>
  );
};

const Description = () => {
  const { description, noLeftSide, noBetterExperience } = useContext(spotlightsDetailsContext);
  return (
    <>
      {
        !!stripHtml(description) &&
        <OuterRaw
          className={ classnames("customer-spotlight_description", { "-no-left-side": noLeftSide(), "-no-better-experience": noBetterExperience() }) }
          data={ description }
        />
      }
    </>
  );
};

const SelfImprove = () => {
  const { better_experience, noLeftSide } = useContext(spotlightsDetailsContext);
  return (
    <>
      {
        !!stripHtml(better_experience) &&
        <OuterRaw
          className={ classnames("customer-spotlight_self-improve", { "-no-left-side": noLeftSide() }) }
          data={ better_experience }
        />
      }
    </>
  );
};

const CustomerSpotlight = ({ item, expanded, setExpandedObj }) => {
  const [ collapsed, setCollapsed ] = useState(!expanded);
  const { base_info, avatar_url, video_url, better_experience } = item;
  const noLeftSide = () => (!stripHtml(base_info) && !avatar_url && !video_url);
  const noBetterExperience = () => (!stripHtml(better_experience));

  const value = {
    ...item,
    ...{
      getCollapsed: () => collapsed,
      setCollapsed,
      setExpandedObj,
      initExpand: expanded,
      noLeftSide,
      noBetterExperience
    }
  };

  return (
    <spotlightsDetailsContext.Provider value={ value }>
      <div className="customer-spotlight">
        <Title />
        {
          !collapsed &&
          <div className="customer-spotlight_row">
            {
              !noLeftSide() &&
              <Column>
                <div className="top-group">
                  <Avatar />
                  <Notes />
                </div>
                <Video />
              </Column>
            }
            <div className="with-spotlight-note">
              { customerSpotlightNote }
              <Column primary>
                <InstallType />
                <Description />
                <SelfImprove />
              </Column>
            </div>
          </div>
        }
      </div>
    </spotlightsDetailsContext.Provider>
  );
};

export default CustomerSpotlight;
