import React, { useLayoutEffect, useContext, useRef } from 'react';
import { BaseContext } from '../../contexts';
import remoteFiles from '../../../../../common/remote_files';

const Section3 = () => {
  const { setSectionRefs } = useContext(BaseContext);
  const benefitsAnchor = useRef(null);
  useLayoutEffect(() => {
    setSectionRefs((oldHash) => ({
      ...oldHash,
      ...{ benefits: { el: benefitsAnchor.current } }
    }));
  }, []);
  const {
    feature1Image12x, feature1Image22x,
    feature2Image12x,
    feature3Image12x, feature3Image22x, feature3Image32x
  } = remoteFiles.express.landing;
  return (
    <section className="features" ref={ benefitsAnchor }>
      <div className="container">
        <div className="features_item -feature-1">
          <div className="features_content -feature-1">
            <div className="features_title">
              <h3>Intuitive & Easy-To-Use</h3>
              <div className="features_title-icon circle-icon -color-5">
                <svg className="icon -toggles">
                  <use xlinkHref="#svg_toggles" />
                </svg>
              </div>
            </div>
            <div className="features_images -feature-1">
              <div
                className="features_image -tablet -image-1"
                style={ { backgroundImage: `url(${feature1Image12x})` } }
              />
              <div
                className="features_image -image-2"
                style={ { backgroundImage: `url(${feature1Image22x})` } }
              />
            </div>
            <ul className="features_list -feature-1">
              <li>Visual & engaging interface</li>
              <li>Designed for mobile devices</li>
              <li>Accommodates images and text ideas easily</li>
            </ul>
          </div>
        </div>
        <div className="features_item -feature-2">
          <div className="features_content -feature-2">
            <div className="features_title">
              <h3>Actionable Reporting. Fast.</h3>
              <div className="features_title-icon circle-icon -color-1">
                <svg className="icon -paper">
                  <use xlinkHref="#svg_paper" />
                </svg>
              </div>
            </div>
            <div className="features_images -feature-2">
              <div
                className="features_image -tablet -image-1"
                style={ { backgroundImage: `url(${feature2Image12x})` } }
              />
            </div>
            <ul className="features_list -feature-2">
              <li>Visual dashboards for faster learning</li>
              <li>Scores for ideas tested with benchmarks</li>
              <li>One-click export of reports & scorecards</li>
              <li>Results in just a few hours</li>
            </ul>
          </div>
        </div>
        <div className="features_item -feature-3">
          <div className="features_content -feature-3">
            <div className="features_title">
              <h3>A Relentless Focus on the Why</h3>
              <div className="features_title-icon circle-icon -color-4">
                <svg className="icon -cross-circles">
                  <use xlinkHref="#svg_cross-circles" />
                </svg>
              </div>
            </div>
            <div className="features_images -feature-3">
              <div
                className="features_image -image-1"
                style={ { backgroundImage: `url(${feature3Image12x})` } }
              />
              <div
                className="features_image -image-2"
                style={ { backgroundImage: `url(${feature3Image22x})` } }
              />
              <div
                className="features_image -tablet -image-3"
                style={ { backgroundImage: `url(${feature3Image32x})` } }
              />
            </div>
            <ul className="features_list -feature-3">
              <li>Heat maps illustrate elements that resonate</li>
              <li>Automatic word clouds for verbatims</li>
              <li>AAI filtering & sentiment analysis</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
