import React from "react";
import Icon from "./Icon";

const TrendCarousel = () => {
  return (
    <div className="trend-carousel">
      <div className="trend-carousel_slide">
        <div className="trend-carousel_gradient"></div>
        <div className="trend-carousel_slide-body">
          <div className="trend-carousel_intro-text">Trending now</div>
          <h1 className="trend-carousel_title">Race Against the Stopwatch</h1>
          <div className="trend-carousel_subtitle">
            <div className="trend-carousel_description">
              Intermediate with David S.
            </div>
            <div className="trend-carousel_category-indicator"></div>
            <div className="trend-carousel_category">Running</div>
            <div className="trend-carousel_duration">20:00</div>
          </div>
          <div className="trend-carousel_logo">
            <Icon name="pr-logo" />
          </div>
        </div>
      </div>
      <div className="trend-carousel_dots">
        <div className="trend-carousel_dot"></div>
        <div className="trend-carousel_dot -active"></div>
        <div className="trend-carousel_dot"></div>
        <div className="trend-carousel_dot"></div>
        <div className="trend-carousel_dot"></div>
      </div>
    </div>
  );
};

export default TrendCarousel;
