import React from 'react';
import HorBarTable from "./hor-bar-table";

const TabCategory = ({ data }) => {
  return (
    <>
      {data.category_purchase_frequency && <HorBarTable q={ data.category_purchase_frequency } colorClass="-green" />}
      {data.category_purchasing_channels && <HorBarTable q={ data.category_purchasing_channels } colorClass="-blue" />}
    </>
  );
};

export default TabCategory;
