import React, { useState, useContext } from 'react';
import Header from '../common/header';
import Card from '../common/card';
import QuantPortal from '../common/portal';
import PlanDetailsModal from '../common/planDetailsModal';
import PlanShortDescription from '../common/planShortDescription';
import CardPlanTitle from '../common/cardPlanTitle';
import {
  plansIncludesChoose,
  planDetailsShort,
  allPlansInclude,
  planList,
  getLargePlanDescription,
  getShortPlanDescription
} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';

const PlansInsideModal = ({ open, handleClose }) => {
  const { title, description } = allPlansInclude;
  return <QuantPortal title={title} open={open} handleClose={handleClose}>
    <div className="plans-include-details-modal">
      {description.map(([title, value], idx) => <div key={idx} className="plans-include-details-group">
        <h6 className="plans-include-details-subtitle">{title}</h6>
        <p className="plans-include-details-value">{value}</p>
      </div>)}
    </div>
  </QuantPortal>;
};

const renderPlans = (includes, idx) => {
  const { text, icon } = includes;
  return <li className="plans-include-details-list-item" key={idx}>
    {
      icon && <svg className={ `icon -${ icon } plans-include-details-list-item-icon` }>
        <use xlinkHref={ `#svg_${ icon }` }/>
      </svg>
    }
    <div className="plans-include-details-list-item-text">{text}</div>
  </li>;
}

const PlansInclude = () => {
  const [open, setOpen] = useState(false);
  const { filledLines } = useContext(LinesDataContext);
  const handleOpen = () => {
    Statistic.updateValue(`lines.${filledLines}.viewDetailsCount`);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <div className="plans-include-details">
    <h6 className="plans-include-details-title">
      All plans include
    </h6>
    <ul className="plans-include-details-list">
      {plansIncludesChoose.map(renderPlans)}
    </ul>
    <div className="plans-include-details-button">
      <button type="button" className="details-link" onClick={handleOpen}>View details</button>
    </div>
    <PlansInsideModal open={open} handleClose={handleClose}/>
  </div>
};

const PlanCard = ({ plan, onSelect, onShowDetails }) => {
  const { id, nameFormatted, price, description } = plan;
  return <Card className="-choose-plan">
    <CardPlanTitle className="-choose-plan" name={nameFormatted} price={price} tipInTwoLines />

    <div className="choose-plan-card-body">
      <PlanShortDescription description={description} />
      <div className="details-link-wrapper">
        <button type="button" className="details-link" onClick={() => onShowDetails(id)}>See plan details</button>
      </div>
    </div>

    <div className="choose-plan-card-footer">
      <button onClick={() => onSelect(plan)} className="button -bordered -full-width" type="button">Choose plan</button>
    </div>

  </Card>
};

const renderPlansList = ({ onSelect, onShowDetails }) => (plan, idx) => {
  const showDetails = (id) => onShowDetails(id, idx);
  return <li key={plan.id} className="choose-plan-card-item">
    <PlanCard
      accordionTitle="Included features & perks"
      plan={plan}
      onSelect={onSelect}
      onShowDetails={showDetails} />
  </li>
}

const PlansList = (props) => <ul className="choose-plan-card-list">
  { planDetailsShort.map(renderPlansList(props)) }
</ul>;

const PlanDetailsModalWrapper = ({ planId, handleClose, onSelect, planList, filledLines }) => {
  return planId
      ? <PlanDetailsModal
          planId={planId}
          open={!!planId}
          onSelect={onSelect}
          handleClose={handleClose}
          planList={planList}
          getPlanLong={getLargePlanDescription}
          getPlanShort={getShortPlanDescription}
          filledLines={filledLines}
      />
      : null
};

const ChooseLinePlan = ({ onContinue }) => {
  const { filledLines } = useContext(LinesDataContext);
  const [planId, setPlanId] = useState(null);
  const handeClese = () => { setPlanId(null); };
  const handleOpenDetails = (id, idx) => {
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsCount`);
    setPlanId(id);
  };
  const handleSelect = (plan, fromModal) => {
    const { id, name, price } = plan;
    const idx = planList.findIndex(planItem => planItem.id === id);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanName`, name);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPrice`, price);
    if (fromModal) {
      Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsSelected`);
      Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "Popup");
    }
    else{
      Statistic.updateValue(`lines.${filledLines}.plans.${idx}.Selected`);
      Statistic.updateValue(`lines.${filledLines}.selectedPlanSource`, "List");
    }
    onContinue(plan);
  };
  return <div className="layout">
    <Header/>
    <h6 className="choose-line-plane-title">Choose a plan for Line {filledLines + 1}.</h6>
    <div className="choose-line-plane content">
      <PlansInclude/>
      <PlansList onSelect={handleSelect} onShowDetails={handleOpenDetails} />
      <PlanDetailsModalWrapper onSelect={handleSelect} planId={planId}
                               handleClose={handeClese} planList={planList} filledLines={filledLines} />
    </div>
  </div>;
};

export default ChooseLinePlan;
