import React from 'react';
import remoteFiles from '../../../common/remote_files';

const LandingHero = ({ firstSentence }) => {
  const { landingHero } = remoteFiles.visibleCom;
  return (
    <section className="landing-hero">
      <div className="landing-hero_text">
        <h1 className="landing-hero_title">
          Unlimited data, unbelievable value.
        </h1>
        <div className="landing-hero_p">
          { firstSentence }
          { ' ' }
          Taxes and fees included!
          { ' ' }
          You can save even more when you bring a friend.
        </div>
      </div>
      <div
        className="landing-hero_image"
        style={ { backgroundImage: `url(${landingHero})` } }
      />

    </section>
  );
};

export default LandingHero;
