import React, { useContext } from "react";
import { map } from "lodash";
import { DataContext } from "../../data-context";
import Shelf from "./Shelf";

const ShelfList = () => {
  const { options, shelves } = useContext(DataContext);

  return (
    <>
      <div className="form_group">
        <div className="form_section">
          <h3 className="form_section-title">Shelf Preview</h3>
          <div className="form_section-text">
            Drag and drop package to put it to the shelf when editing.
          </div>
        </div>
      </div>
      <div className="form_group -relative">
        <div className="products-greed -wrapper -with-min-width">
          <ul className="products-greed-list">
            {
              map(shelves, (el, index) => (
                <li key={ `s-${index}` } className="products-greed-item">
                  <Shelf shelfIndex={ index } image={ options.images[el.image_id] } />
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};

export default ShelfList;
