import React, {
  createContext, useContext, useState, useLayoutEffect
} from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import runPreload from '../logic/preload';
import Forward from '../logic/forward';
import saveUserData from '../logic/save_user_data';
import PageLoader from '../../common/components/page_loader';
import Intro from '../../common/components/intro';
import Screening from '../../common/components/screening';
import Qualified from '../../common/components/qualified';
import CustomQualified from '../../common/components/custom_qualified';
import Preference from '../../common/components/metrics/preference';
import PreferenceReasons from '../../common/components/metrics/preference_reasons';
import renderRawHtml from '../../../../common/render_raw_html';
import { fraudContext } from "../../common/contexts";
import { LastStepsComponents } from '../../common/logic/last_steps';
import CustomMetricsInLocation from "../../common/components/metrics/custom_metrics_in_location";
import { customMetricsByLocation } from "../../common/logic/eligible";
import WithOpenEnd from '../../common/components/metrics/customMetricsOpenEnd/withOpenEnd';
import Concept from './concept';

const resultContext = createContext(
  {
    answers: [],
    loops: [],
    preference_views: [],
    preference_selections: [],
    preference_answers: [],
    shelf_results: [],
    express_cart_exercise_results: []
  }
);

const Index = ({
  updateStep, updateConceptStep,
  updateConceptIndex, updateQuestionIndex, updateShelfStep,
  data, cIndex, qIndex, step, loading, conceptStep, shelfStep,
  changeLoadingState
}) => {
  const {
    qualified_custom_text, monadic, concepts,
    demographics, optional_demographics, screenings,
    translations, concepts_for_preference, preference_all
  } = data;

  const preferenceTitle = preference_all ?
    translations.preference_all_title :
    translations.preference_title;

  useLayoutEffect(() => {
    runPreload([ ...concepts, ...concepts_for_preference ], [ 'url', 'shelf_url' ], changeLoadingState);
  }, []);

  const result = useContext(resultContext);
  const { projectId, antiFraud, fraudProcessing, gaTrackingId } = useContext(fraudContext);

  const saveRequest = (status) => {
    saveUserData(data.save_url, status, result, data);
  };
  const currentConcept = () => (concepts[cIndex]);
  const currentQuestion = (questions) => (questions[qIndex]);

  const [ forwardEntity ] = useState(new Forward({
    updateStep,
    runSave: (status) => saveRequest(status),
    updateConceptStep,
    updateConceptIndex,
    updateQuestionIndex,
    updateShelfStep
  }));
  const goForward = () => {
    forwardEntity.forward(step, data, result, goNextConcept, projectId, antiFraud, gaTrackingId);
  };

  const goNextQuestion = (questions) => {
    forwardEntity.nextQuestion(
      qIndex, questions, result, goForward
    );
  };
  const goNextConcept = () => {
    forwardEntity.nextConceptStep(
      conceptStep, cIndex, currentConcept(), data, goForward
    );
  };

  const goNextShelfStep = () => {
    forwardEntity.nextShelfStep(shelfStep, currentConcept(), data, goNextConcept);
  };

  const preferenceSelectedConcept = () => {
    const selection = result.preference_selections[0];
    if (!selection) {
      return null;
    }
    return find(concepts_for_preference, (c) => c.id === parseInt(selection.concept_id));
  };

  return (
    <>
      <PageLoader loading={ loading || fraudProcessing } />
      <section className="survey-layout">
        {
          !step &&
          <Intro
            task={ translations.base_intro }
            nextStep={ goForward }
          />
        }
        {
          step === 1 &&
          <Screening
            question={ currentQuestion(demographics) }
            nextStep={ () => { goNextQuestion(demographics); } }
            result={ result }
          />
        }
        {
          step === 2 &&
          <Screening
            question={ currentQuestion(optional_demographics) }
            nextStep={ () => { goNextQuestion(optional_demographics); } }
            result={ result }
          />
        }
        {
          step === 3 &&
          <Screening
            question={ currentQuestion(screenings) }
            nextStep={ () => { goNextQuestion(screenings); } }
            result={ result }
          />
        }
        {
          step === 4 && !qualified_custom_text &&
          <Qualified
            title={ translations.express_qualify_title }
            description={ translations.express_qualify_description }
            descriptionBottom={ translations.express_qualify_footer }
            nextStep={ goForward }
          />
        }
        {
          step === 4 && !!qualified_custom_text &&
          <CustomQualified
            content={ qualified_custom_text }
            nextStep={ goForward }
          />
        }
        {
          step === 5 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "at_the_start")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "at_the_start")); } }
            result={ result }
            mainResult={ result }
          />
        }
        {
          step === 6 &&
          <CustomMetricsInLocation
            location="at_the_start"
            concepts={ concepts }
            data={ data }
            goForward={ goForward }
            result={ result }
          />
        }
        {
          step === 7 &&
          <Concept
            concept={ currentConcept() }
            data={ data }
            nextStep={ goNextConcept }
            shelfStep={ shelfStep }
            nextShelfStep={ goNextShelfStep }
            monadic={ monadic }
            index={ conceptStep }
            conceptIndex={ cIndex }
            result={ result }
          />
        }
        {
          step === 8 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "default")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "default")); } }
            result={ result }
            mainResult={ result }
          />
        }
        {
          step === 9 &&
          <CustomMetricsInLocation
            location="at_the_end"
            concepts={ concepts }
            data={ data }
            goForward={ goForward }
            result={ result }
          />
        }
        {
          step === 10 &&
          <Preference
            title={ preferenceTitle }
            concepts={ concepts_for_preference }
            result={ result }
            nextStep={ goForward }
            bigger
          />
        }
        {
          step === 11 &&
          <PreferenceReasons
            concept={ preferenceSelectedConcept() }
            result={ result }
            note={ translations.reasons_for_preference_note }
            placeholder={ translations.reasons_for_preference_placeholder }
            nextStep={ goForward }
            forceImage
          >
            <div className="survey-header">
              <div className="survey-header_title">
                <div
                  className="survey-start_text -low-margin-bottom"
                  { ...renderRawHtml(translations.reasons_for_preference_title) }
                />
              </div>
            </div>
          </PreferenceReasons>
        }
        {
          step === 12 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "at_the_end")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "at_the_end")); } }
            result={ result }
            mainResult={ result }
          />
        }
        <LastStepsComponents
          step={ step }
          lastStepsFrom={ 13 }
          translations={ translations }
          data={ data }
          nextStep={ goForward }
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    updateStep: (step) => { return dispatch({ type: "UPDATE_STEP", value: step }); },
    changeLoadingState: (value) => { return dispatch({ type: "UPDATE_LOADING", value }); },
    updateConceptStep: (step) => { return dispatch({ type: "UPDATE_CONCEPT_STEP", value: step }); },
    updateConceptIndex: (cIndex) => { return dispatch({ type: "UPDATE_CONCEPT_INDEX", value: cIndex }); },
    updateShelfStep: (step) => { return dispatch({ type: "UPDATE_SHELF_STEP", value: step }); },
    updateQuestionIndex: (qIndex) => { return dispatch({ type: "UPDATE_QUESTION_INDEX", value: qIndex }); }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
