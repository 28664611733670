import React, { Fragment, useState, useContext, useEffect } from 'react';
import { map } from 'lodash';
import Modal from '../../../../../common/components/modal';
import renderRawHtml from '../../../../../common/render_raw_html';
import listData from '../../../common/types/list_data';
import { BaseContext } from '../../contexts';
import SvgIcon from "../../../../../common/components/svg-icon";

const Types = ({ projectsAllowance }) => {
  const [ readMoreOpened, setReadMoreOpened ] = useState(null);
  const { setSignUpOpen, getOpenRemoteReadMore } = useContext(BaseContext);
  const [ list ] = useState(() => (listData()));
  const titleField = 'landingTitle';

  const handleOpenReadMore = (express) => {
    setReadMoreOpened(express);
  };
  const handleCloseReadMore = () => {
    setReadMoreOpened(null);
  };

  const onReadMoreGetStarted = (_express, _title) => {
    handleCloseReadMore();
    setSignUpOpen(true);
  };

  const openRemoteReadMore = getOpenRemoteReadMore();
  useEffect(() => {
    const item = openRemoteReadMore.item;
    if (item) {
      handleOpenReadMore(item.express);
    }
  }, [ openRemoteReadMore ]);

  return (
    <>
      <ul className="intro_list">
        {
          map(list, (item, index) => (
            <Fragment key={ `Type${index}` } >
              {
                (!projectsAllowance || projectsAllowance[item.express]) &&
                <li className="intro_list-item">
                  <div
                    className="intro_item"
                    onClick={ () => { handleOpenReadMore(item.express); } }
                  >
                    <div className="intro_item-icon">
                      <SvgIcon name={ item.icon } />
                    </div>
                    <div className="intro_item-title" { ...renderRawHtml(item.landingTitle) } />
                    <div className="intro_item-text">
                      { item.landingDescription }
                    </div>
                  </div>
                </li>
              }
            </Fragment>
          ))
        }
      </ul>
      {
        readMoreOpened &&
        <Modal
          modal
          modalSize="md"
          showCross
          onClose={ handleCloseReadMore }
        >
          {
            map(list, (item, index) => (
              <Fragment key={ `ModalReadMore${index}` }>
                {
                  readMoreOpened === item.express &&
                  item.readMore(onReadMoreGetStarted, item, titleField)
                }
              </Fragment>
            ))
          }
        </Modal>
      }
    </>
  );
};

export default Types;
