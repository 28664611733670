import React, { useContext } from "react";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";
import { IndexData } from "../../common/index-context";

const ComparisonsMenu = () => {
  const { data } = useContext(IndexData);

  const menuList = [
    {
      id: 0,
      url: "#start",
      title: "",
      icon: "to-top",
      show: false
    },
    {
      id: 1,
      url: "#scorecard",
      title: "Scorecard",
      show: true
    },
    {
      id: 2,
      url: "#customer_challenges",
      title: "Customer Challenges",
      show: true
    },
    {
      id: 3,
      url: "#diagnostics",
      title: "Diagnostics",
      show: true
    },
    {
      id: 4,
      url: "#unhappy",
      title: "Unhappy Path Impact",
      show: data.include_deviation
    },
    {
      id: 5,
      url: "#process-effort",
      title: "Customer Feedback - Experience Effort",
      show: true
    },
    {
      id: 6,
      url: "#idea-only",
      title: "Customer Feedback - Idea Only",
      show: true
    },
    {
      id: 7,
      url: "#study",
      title: "Case Description",
      show: true
    },
    {
      id: 8,
      url: "#benchmark",
      title: "Methodology Details",
      show: true
    }
  ];

  return (
    <nav className="comparisons_menu">
      <ul className="comparisons_menu-list">
        {map(menuList, (item, index) => {
          if (item.show) {
            return (
              <li key={ index } className="comparisons_menu-item">
                <a className={ `comparisons_menu-link ${item.icon ? '-icon' : '-text'}` } href={ item.url }>
                  {!!item.icon &&
                    <SvgIcon name="to-top" />
                  }
                  {!!item.title && item.title}
                </a>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </nav>
  );
};

export default ComparisonsMenu;
