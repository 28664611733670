import React from 'react';
import SvgIcon from "../../../../../common/components/svg-icon";
import renderRawHtml from "../../../../../common/render_raw_html";
import { filterHumanView } from "../helpers";

const FiltersInfo = ({ filterParams, baseFilters, onClearFilters }) => {


  return (
    <div className="projects_controls-grid-item -v_center">
      <div className="filters_info" { ...renderRawHtml(filterHumanView(filterParams, baseFilters)) } />
      <div
        className="filters_clear"
        onClick={ onClearFilters }
      >
        <SvgIcon name="i-close-sm" className="-pointer" />
      </div>
    </div>
  );
};

export default FiltersInfo;
