import React from "react";

const getInitials = ({ firstName, lastName }) =>
  `${firstName ? firstName[0].toUpperCase() : ""}${
    lastName ? lastName[0].toUpperCase() : ""
  }`;

const Avatar = ({ imgUrl, firstName, lastName, size = "medium" }) => (
  <div className={`avatar -${size}`}>
    {imgUrl && <img className="avatar_image" src={imgUrl} />}
    {!imgUrl && (
      <div className="avatar_initials">
        {getInitials({ firstName, lastName })}
      </div>
    )}
  </div>
);

export default Avatar;
