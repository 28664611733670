import React, { useState, useContext } from 'react';
import Header from '../common/header';
import Card from '../common/card';
import CardPlanTitle from '../common/cardPlanTitle';
import PlanShortDescription from '../common/planShortDescription';
import PlanDetailsModal from '../common/planDetailsModal';
import {getShortPlanDescription, getLargePlanDescription, planList} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';

const AccordionView = ({ title, children, handleOpenChange }) => {
  const [open, setOpen] = useState(false);
  const handleChange = () => {
    handleOpenChange();
    setOpen(!open);
  };
  return <div className="accordion">
    <button onClick={handleChange} className="accordion-button">
      <span className={`accordion-button-text ${open ? '-open' : ''}`}>{title}</span>
    </button>
    {open && children}
  </div>
}

const EmptyCard = () => <div className="empty-card">
  <span className="empty-card-text">Not chosen yet</span>
</div>;

const PlanCard = ({ plan, onShowDetails, handleOpenChange, setClickedLine }) => {
  const { id, name, price, description } = plan;

  const handleClickSeeDetails = () => {
    onShowDetails(id);
    setClickedLine();
  };

  return <Card>
    <CardPlanTitle className="-checkout" name={name} price={price} />
    <div className="choose-plan-card-body">
      <AccordionView title="Included features & perks" handleOpenChange={handleOpenChange}>
        <div className="cards-item-description">
          <PlanShortDescription description={description} />
          <div className="details-link-wrapper">
            <button type="button" className="details-link" onClick={handleClickSeeDetails}>See plan details</button>
          </div>
        </div>
      </AccordionView>
    </div>
  </Card>
};

const PlanDetailsModalWrapper = ({ planId, handleClose, onSelect, planList, filledLines, clickedLineIndex }) => {
  return planId
      ? <PlanDetailsModal
          planId={planId}
          open={!!planId}
          onSelect={onSelect}
          handleClose={handleClose}
          planList={planList}
          getPlanLong={getLargePlanDescription}
          getPlanShort={getShortPlanDescription}
          filledLines={filledLines}
          clickedLineIndex={clickedLineIndex}
      />
      : null
};

const renderSelectedPlans = (onShowDetails, handleOpenChange, setClickedLine) => (plan, idx) => <li key={idx} className="cards-item">
  <h6 className="cards-item-title">Line {idx + 1}</h6>
  {
    plan === null
      ? <EmptyCard/>
      : <PlanCard
          accordionTitle="Included features & perks"
          plan={plan}
          handleOpenChange={() => handleOpenChange(idx)}
          onShowDetails={() => onShowDetails(plan.id, idx)}
          setClickedLine={() => setClickedLine(idx)}
      />}
</li>

const Checkout = ({ selectedPlans, onContinue, onBack, onSelectFromModal }) => {
  const { filledLines } = useContext(LinesDataContext);

  const total = selectedPlans
      .filter(Boolean)
      .reduce((acc, { price }) => price + acc, 0);

  const [planId, setPlanId] = useState(null);
  const [clickedLineIndex, setClickedLineIndex] = useState(null);

  const handleShowDetails = (id, idxLine) => {
    Statistic.updateValue(`lines.${idxLine}.selectedPlanPlanDetailsCount`)
    setPlanId(id);
  };

  const setClickedLine = (idx) => {
    setClickedLineIndex(idx);
  };

  const handleOpenChange = (idxLine) => {
    Statistic.updateValue(`lines.${idxLine}.selectedPlanFeaturesCount`);
  };

  const handleCloseDetails = () => { setPlanId(null); };

  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.changePlanCount`)
    onBack();
  }

  return <div className="layout checkout">
    <Header/>
    <div className="checkout-header -no-margin">
      <h6 className="checkout-title">You chose a plan for Line {filledLines}</h6>
      <div className="checkout-total">Total: ${total}</div>
    </div>
    <div className="content">
      <div className="checkout-back" onClick={handleBack}>
        <h6 className="checkout-back-title">
          <svg className={ `icon -dropdown -back`}>
            <use xlinkHref={ `#svg_arrow-down` }/>
          </svg>
          <span>Back</span>
        </h6>
      </div>
      <ul className="cards-list">
        {selectedPlans.map(renderSelectedPlans(
          handleShowDetails,
          handleOpenChange,
          setClickedLine
        ))}
      </ul>
    </div>
    <footer className="checkout-footer">
      <button className="button" type="button" onClick={onContinue}>Continue</button>
    </footer>

    <PlanDetailsModalWrapper planId={planId} handleClose={handleCloseDetails}
                             planList={planList} filledLines={filledLines - 1}
                             clickedLineIndex={clickedLineIndex}
    />
  </div>;
};

export default Checkout;