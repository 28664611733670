import React, { useContext, useState, useEffect } from 'react';
import { GeneralContext } from '../../../contexts';
import FivePointScale from '../five_point_scale';
import Intro from '../../intro';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ItemView from '../../ItemView';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';
import Stimuli from '../../stimuli';

const Idea = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    concept, ideaMetrics, result, translations, branded
  } = useContext(GeneralContext);

  const metricIndex = metricGroupStep - 2;

  const prepareCurrentQuestions = () => {
    if (metricIndex >= 0) {
      const question = ideaMetrics[metricIndex];
      question.text = fixQuestionTextTemplates(question, ideaMetrics, result, translations);
      return [ question ];
    }
    return [];
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  useEffect(() => {
    setCurrentQuestions(prepareCurrentQuestions());
  }, [ metricGroupStep ]);


  const nextStep = () => {
    if (metricIndex < ideaMetrics.length - 1) {
      setMetricGroupStep(metricGroupStep + 1);
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  const fullConceptData = concept.full_concept_data;

  return (
    <>
      {
        !metricGroupStep &&
        <Intro nextStep={ nextStep } >
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.idea_intro_top) } />
              <p className="intro-line" { ...renderRawHtml(translations.idea_intro_middle) } />
              <p className="intro-line" { ...renderRawHtml(translations.idea_intro_bottom) } />
            </div>
          </>
        </Intro>
      }
      {
        metricGroupStep === 1 &&
        <>
          <ItemView nextStep={ nextStep }>
            <div className="survey-start_description -left-aligned">
              <Stimuli
                ideaName={ fullConceptData.idea_name }
                customerBenefit={ fullConceptData.customer_benefit }
                reasonsToBelieve={ fullConceptData.reasons_to_believe }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
              />
            </div>
          </ItemView>
        </>
      }
      {
        metricIndex >= 0 && !!currentQuestions[0] &&
        <>
          <FivePointScale
            key={ currentQuestions[0].name }
            customConceptView={
              <Stimuli
                ideaName={ fullConceptData.idea_name }
                customerBenefit={ fullConceptData.customer_benefit }
                reasonsToBelieve={ fullConceptData.reasons_to_believe }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
              />
            }
            concept={ concept }
            questions={ currentQuestions }
            result={ result }
            nextStep={ nextStep }
          >
            <></>
          </FivePointScale>
        </>
      }
    </>
  );
};

export default Idea;
