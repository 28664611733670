import React, { useState, useEffect, useContext, useRef } from 'react';
import { IndexData } from '../../../common/index-context';
import { WithExchangeData } from "../../../common/components/express-open-end-card/exchange-data";
import LeftPanel from "../../../common/components/express-open-end-card/left-panel";

import { WordsData } from "../../../common/components/express-open-end-card/index";
import Http from "../../../../../common/http";
import TagsInputBrand from "./tags-input-brand";
import RightPanel from "./right-panel";


const Index = ({ metric, data, filter, loadWords, loadComments, projectId, info }) => {
  const [ loading, setLoading ] = useState(false);

  const tourTagsElement = useRef(null);
  const tourTextElement = useRef(null);
  const tourRightElement = useRef(null);
  const tourSwitchElement = useRef(null);

  const { getTourUseEffectArgs, loadData } = useContext(IndexData);

  const [ tagsCallback, tagsCondition ] = getTourUseEffectArgs('ubaTags', tourTagsElement);
  const [ textCallback, textCondition ] = getTourUseEffectArgs('ubaText', tourTextElement);
  const [ rightCallback, rightCondition ] = getTourUseEffectArgs(`ubaRight`, tourRightElement);
  const [ switchCallback, switchCondition ] = getTourUseEffectArgs(`ubaSwitch`, tourSwitchElement);

  useEffect(tagsCallback, tagsCondition);
  useEffect(textCallback, textCondition);
  useEffect(rightCallback, rightCondition);
  useEffect(switchCallback, switchCondition);

  const value = {
    loadWords,
    loadComments,
    pinTitle: '',
    unpinTitle: '',
    pinCount: 3
  };

  const onTagsChange = (vv) => {
    setLoading(true);
    Http.post(
      '/express/api/brand_snapshots/update_synonyms',
      {
        project_id: projectId,
        values: vv
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          loadData(null, null, null, null, {}, () => {
            setLoading(false);
          });
        }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  return (
    <WordsData.Provider value={ value }>
      <div className="comparisons_table page-container -with-bottom-margin -wordcloud -js-uba">
        <div className="header-with-controls">
          <h3 ref={ tourTextElement } className="js-uba-percent">
            Unaided Brand Awareness, <span className="-green">{ info.brand_name }: {info.percent}%</span>
          </h3>
        </div>
        <div className="header-with-controls -description">
          To include alternate spellings of the brand name in the Unaided Brand Awareness calculation, add them here.
        </div>
        <div className="tiles_row" ref={ tourTagsElement }>
          <div className="tile -no-min-height">
            <TagsInputBrand
              initTags={ info.synonyms }
              onTagsChange={ onTagsChange }
              placeholder="Add brand variants"
              projectId={ projectId }
              searchKeywords={ info.search_keywords }
            />
          </div>
        </div>

        <div className="tiles_row -wordcloud">
          { loading && <div className="loading-back" /> }
          <WithExchangeData>
            <div className="tile -sentiments-side">
              <div className="tile_inset -with-border">
                <LeftPanel
                  data={ data }
                  metric={ metric }
                  filter={ filter.demographics }
                  sentiment={ false }
                  tourName={ 'uba' }
                />
              </div>
            </div>
            <div className="tile -sentiments-main">
              <div className="tile_inset -with-border" ref={ tourRightElement }>
                <RightPanel
                  data={ data }
                  metric={ metric }
                  filter={ filter }
                  isAdmin={ false }
                  setLoading={ setLoading }
                  projectId={ projectId }
                />
              </div>
            </div>
          </WithExchangeData>
        </div>
      </div>
    </WordsData.Provider>
  );
};

export default Index;
