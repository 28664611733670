import React, { useState, useContext } from 'react';
import { getInvariantByDeviceCount } from '../data/how-toconnect.b';
import { updateCache, getCache } from './cache_store';

export const DataStorageContext = React.createContext({});

const useCheckout = (isDataFilled, data, total, statistic, onNext) => {
  if (!isDataFilled) return () => {};
  const { 
    fiosTVData,
    tvConnectionsData,
    howToConnectVariantData: { STBCount, ownDevicesCount, price },
  } = data;
  const hash = {
    ...statistic,
    total,
    planName: fiosTVData.title,
    planPrice: fiosTVData.price,
    tvConnectionsNum: tvConnectionsData.count,
    connectionTypeSetTopBoxNum: STBCount,
    connectionTypeStreamingNum: ownDevicesCount === Infinity ? 0 : ownDevicesCount,
    connectionTypeStreamingUnlimited: ownDevicesCount === Infinity,
    connectionTypePrice: price,
  };
  return () => {
    (window.parent || window).postMessage({
      kind: 'finishVerizonTv',
      result: hash
    }, "*");
    onNext();
  }
};

const useStatistic = (initial) => {
  const [statistic, setStatistic] = useState(initial);

  const updateCounterOf = (key) => {
    setStatistic((prevValue) => ({...prevValue, [key]: prevValue[key] + 1 }) );
  };

  return [statistic, updateCounterOf];
}

export const useDataStorage = (onNext) => {
  const [statistic, updateCounterOf] = useStatistic({
    changePlan: 0,
    changeTvConnection: 0,
    changeConnectionTypePrimary: 0,
    clickAdditionalOptions: 0,
    changeConnectionTypeSecondary: 0
  });
  const [fiosTVData, setFiosTVData] = useState(null);
  const updateFiosTVData = (data) => { 
    setTVConnectionsData(null);
    setHowToConnectVariantData(null);
    setFiosTVData(data);
  };
  const [tvConnectionsData, setTVConnectionsData] = useState(null);
  const updateTVConnectionsData = (data) => { 
    setHowToConnectVariantData(null);
    setTVConnectionsData(data);
  };
  const [howToConnectVariantData, setHowToConnectVariantData] = useState(null);
  const updateHowToConnectVariantData = (data) => { 
    setHowToConnectVariantData(data);
  };

  const total = (fiosTVData ? fiosTVData.price : 0) +
    (howToConnectVariantData ? howToConnectVariantData.price : 0);

  const data = {
    fiosTVData,
    tvConnectionsData,
    howToConnectVariantData,
  };

  const isDataFilled = !!(fiosTVData && tvConnectionsData && howToConnectVariantData);

  const onCheckout = useCheckout(isDataFilled, data, total, statistic, onNext);
    
  return {
    total,
    data,
    onCheckout,
    isDataFilled,
    updateCounterOf,
    updateFiosTVData,
    updateTVConnectionsData,
    updateHowToConnectVariantData
  }
};

export const useInvariants = () => {
  const { 
    data: { howToConnectVariantData, tvConnectionsData },
    updateHowToConnectVariantData,
    updateCounterOf,
  } = useContext(DataStorageContext);

  const tvConnectionsCount = tvConnectionsData ? tvConnectionsData.count : 1
  const invariants = getInvariantByDeviceCount(tvConnectionsCount);

  const updateVariant = (variant) => {
    const data = variant === howToConnectVariantData ? null : variant;
    updateHowToConnectVariantData(data);
  };

  return {
    variant: howToConnectVariantData,
    tvConnectionsCount,
    invariants,
    updateVariant,
    updateCounterOf,
  }
};

const invariantsFlters = {
  all: () => true,
  ownDevice: (invariant) => invariant.ownDevicesCount > 0,
  setTopBox: (invariant) =>
    invariant.ownDevicesCount === 0 && invariant.ownDevicesCount !== Infinity,
};

export const invariantsFilters = {
  all: 'All',
  ownDevice: 'Use my own device',
  setTopBox: 'Use Set-top box'
};

const findReconended = (list) => () => {
  return list
    .filter(invariant => invariant.recomended)
    .shift();
};

const splitInvariants = (list, recomended, filterName) => () => {
  const streaming = list.filter(invariant => invariant.ownDevicesCount !== Infinity);
  streaming.unshift(recomended);
  const unlimited = list.filter(invariant => streaming.indexOf(invariant) === -1);
  const filterMethod = invariantsFlters[filterName] || invariantsFlters['all'];
  return [
    streaming.filter(filterMethod),
    unlimited.filter(filterMethod)
  ];
};

export const useSplitedInvariants = () => {
  const data = useInvariants();

  const [recomended] = useState(findReconended(data.invariants));

  const [filterName, setFilterName] = useState('all');

  const [splitedInvariants, setSplitedInvariants] = useState(splitInvariants(data.invariants, recomended, filterName));

  const updateFilterName = (name) => { 
    setFilterName(name);
    setSplitedInvariants(splitInvariants(data.invariants, recomended, name))
  };

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptionsOpen = () => { 
    data.updateCounterOf('clickAdditionalOptions');
    setIsOptionsOpen(!isOptionsOpen);
  };

  const [isPopupOpen, setPopupOpen] = useState(null);

  const updatePopupOpen = (state) => { setPopupOpen(state); };

  return {
    ...data,
    filterName,
    updateFilterName,
    splitedInvariants,
    isOptionsOpen,
    toggleOptionsOpen,
    isPopupOpen,
    updatePopupOpen
  };
}

export const useTVConnections = (onNext) => {
  const { 
    data: { tvConnectionsData },
    updateTVConnectionsData,
    updateCounterOf
  } = useContext(DataStorageContext);
  const handleActiveChange = (data) => {
    const tvConnection = getCache('tvConnection');
    if (!!tvConnection && tvConnection !== data) updateCounterOf('changeTvConnection');
    updateCache('tvConnection', data);
    updateTVConnectionsData(data);
    onNext();
  };

  const title = tvConnectionsData === null
    ? 'How many TV connections?'
    : `${tvConnectionsData.count} ${tvConnectionsData.count === 1 ? 'TV' : 'TVs'}`;
  
  const chipText = tvConnectionsData === null
    ? 'Required'
    : 'Added';

  const chipClassName = tvConnectionsData === null
    ? '-red'
    : '-green';

  return {
    activeConnection: tvConnectionsData,
    handleActiveChange,
    title,
    chipText,
    chipClassName,
  }
};

export const useFiosTV = (onNext) => {
  const { 
    data: { fiosTVData }, 
    updateFiosTVData,
    updateCounterOf,
  } = useContext(DataStorageContext);
  const dataFilled = !!fiosTVData;
  const sectionTitle = dataFilled ? fiosTVData.title : 'More Fios TV';
  const chipTitle = dataFilled ? 'Added' : 'Required';
  const chipClassName = dataFilled ? '-green' : '-red';
  const handleChangePlan = (plan) => {
    const cachePlan = getCache('plan');
    if (!!cachePlan && cachePlan !== plan) updateCounterOf('changePlan');
    updateCache('plan', plan);
    updateFiosTVData(plan);
    onNext();
  };

  return {
    plan: fiosTVData,
    sectionTitle,
    chipTitle,
    chipClassName,
    handleChangePlan
  }
};

const getInitialState = () => ({
  fiosTV: false,
  tvConnections: false,
  howToConnect: false
});

export const useAccordionState = () => {
  const [open, setOpen] = useState(
    () => ({...getInitialState(), fiosTV: true})
  );

  const { data } = useContext(DataStorageContext); 
  
  const handleOpen = (stepName) => { setOpen((prevState) => ({
    ...getInitialState(),
    [stepName]: true
  })) };

  return { open, handleOpen, data };
}