import React, { useState, useContext } from 'react';
import { SelectablePerkList } from '../common/perkList';
import Header from '../common/header';
import { perkList } from './plansData';
import { LinesDataContext } from './contexts';
import { PlanCard } from '../common/planCard';
import { getLargePlanDescription, planDetailsLarge } from './plansData';
import Statistic from '../../statistic';
import PlanDetailsModal from '../common/planDetatailsModal';

const SelectPerkHeader = ({ perkCount, onBack }) => {
  return <header className="select-perk-header">
    <button className="select-perk-back" onClick={onBack}>Back</button>
    <h6 className="select-perk-header-title">
      Choose your bonus perk{perkCount > 1 ? 's' : ''}
    </h6>
  </header>;
};

const SelectPerkFooter = ({ perkCount, canContinue, onContinue }) => {
  return <footer className="select-perk-footer">
    <button
      className="button"
      disabled={!canContinue}
      onClick={onContinue}>
        Choose this plan and perk{perkCount > 1 ? 's' : ''}
    </button>
  </footer>;
};

const SelectPerk = ({ onContinue, onBack }) => {
  const { linesData, filledLines } = useContext(LinesDataContext);
  const plan = linesData[filledLines - 1];
  const planIndex = planDetailsLarge.findIndex(el => el.id === plan.id);
  const [selectedPerks, setSelectedPerks] = useState(Array(plan.perkCount).fill(void 0));
  const handleSelect = (perk, isNew, perkIdx) => {
    const list = [...selectedPerks];
    const emptyIndex = list.findIndex(el => el === void 0);
    if (isNew) {
      list[emptyIndex] = perk;
      Statistic.updateValue(`lines.${filledLines - 1}.plans.${planIndex}.perks.${perkIdx}.AddedInCustomizationCount`);
      const perkCounter = emptyIndex === 1 ? 'Second' : 'First';
      Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerk${perkCounter}Selected`, perk.id);
    }
    else {
      const perkIdx = list.findIndex(el => el === perk);
      list[perkIdx] = void 0;
      Statistic.updateValue(`lines.${filledLines - 1}.plans.${planIndex}.perks.${perkIdx}.RemovedInCustomizationCount`);
      const perkCounter = perkIdx === 1 ? 'Second' : 'First';
      Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerk${perkCounter}Selected`, 0);
    }
    setSelectedPerks(list);
  };
  const [detailsPlan, setDetailsPlan] = useState(null);
  const handleOpenChange = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.plans.${planIndex}.FeaturesInCustomizationCount`);
  };
  const handleShowDetails = (planId) => {
    Statistic.updateValue(`lines.${filledLines - 1}.plans.${planIndex}.PlanDetailsInCustomizationCount`);
    const plan = getLargePlanDescription(planId) || null;
    setDetailsPlan({...plan, name: `${plan.name} details`});
  };
  const handleCloseDetails = () => { setDetailsPlan(null); };
  const onDetailsClick = (perkIdx) => {
    Statistic.updateValue(`lines.${filledLines - 1}.plans.${planIndex}.perks.${perkIdx}.DetailsInCustomizationCount`);
  };
  const handleBack = () => {
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerkFirstSelected`, 0);
    Statistic.updateValue(`lines.${filledLines - 1}.selectedPlanPerkSecondSelected`, 0);
    onBack();
  };
  return <div className="layout select-perk -mvp">
    <Header/>
    <SelectPerkHeader perkCount={plan.perkCount} onBack={handleBack} />
    <div className="content">
      <PlanCard
        accordionTitle="Included features"
        plan={plan}
        handleOpenChange={handleOpenChange}
        onShowDetails={handleShowDetails}
        renderChildrenInAccordion={false}>
          <>
            <div className="hr" style={{ marginTop: 20 }} />
            <p className="select-perk-available">
              {plan.perkCount} Bonus perk{plan.perkCount > 1 && 's'} of your choice
            </p>
            <SelectablePerkList
              list={perkList}
              onSelect={handleSelect}
              maxSelectCount={plan.perkCount}
              selectedList={selectedPerks.filter(Boolean)}
              onDetailsClick={onDetailsClick} />
          </>
      </PlanCard>
    </div>
    <SelectPerkFooter
      perkCount={plan.perkCount}
      canContinue={selectedPerks.filter(Boolean).length === plan.perkCount}
      onContinue={() => onContinue(selectedPerks)} />
    <PlanDetailsModal
      open={!!detailsPlan}
      plan={detailsPlan}
      handleClose={handleCloseDetails} />
  </div>;
};

export default SelectPerk;