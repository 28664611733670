import React from "react";
import ActivityCard from "../../components/ActivityCard";
import ActivityHeader from "../../components/ActivityHeader";
import Layout from "../../components/Layout";
import activities from "../../mocks/activities";

const Activity = () => (
  <Layout className="-bg-extra-dark-gray" HeaderComponent={<ActivityHeader />}>
    <div className="mx-3 mb-5">
      {activities.map((activity, i) => (
        <ActivityCard activity={activity} key={i} />
      ))}
    </div>
  </Layout>
);

export default Activity;
