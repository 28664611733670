import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import classnames from 'classnames';
import render_raw_html from "../../../common/render_raw_html";
import {
  addClass, addElementClass, closestElement, removeClass
} from '../../../common/dom_functions';
import { IndexData } from '../common/index-context';
import StepsLogic from './steps_logic';

const BaseApp = ({
  projectId, redirectPath, texts, steps, tourName
}) => {
  const [ showBubble, setShowBubble ] = useState(false);
  const [ currentElement, updateCurrentElement ] = useState(null);
  const { getTourElements } = useContext(IndexData);
  const [ stepsInstance ] = useState(() => (
    new StepsLogic(
      projectId,
      texts,
      updateCurrentElement,
      steps,
      getTourElements
    )
  ));
  stepsInstance.setFuncToGetTourElements(getTourElements);
  const domEl = stepsInstance.domEl();

  useEffect(() => {
    if (domEl) {
      setShowBubble(true);
    }
  }, [ domEl, currentElement ]);

  const balloonClass = () => {
    removeClass(".tour-anchor-active", "tour-anchor-active");
    removeClass(".tour-anchor-parent-active", "tour-anchor-parent-active");

    if (!currentElement) {
      return "";
    }
    if (!domEl) {
      return '';
    }
    addElementClass(domEl, "tour-anchor-active");
    if (currentElement.elementKlass) {
      addElementClass(domEl, currentElement.elementKlass);
    }
    if (currentElement.parent) {
      addElementClass(closestElement(domEl, currentElement.parent), "tour-anchor-parent-active");
    }
    return `-${currentElement.pos} -tour-${tourName}-${currentElement.id} -bubble-${currentElement.id}`;
  };

  const balloonPosition = () => {
    const defaultPosition = [ -1000, -1000, { top: -1000, left: -1000 } ];

    if (!currentElement) {
      return defaultPosition;
    }
    if (!domEl) {
      return defaultPosition;
    }

    const box = domEl.getBoundingClientRect(),
      baseTop = box.top,
      baseLeft = box.left,
      baseBottom = baseTop + box.height,
      baseRight = baseLeft + box.width,
      baseCenter = baseLeft + (box.width / 2),
      baseMiddle = baseTop + (box.height / 2),
      pos = currentElement.pos;
    let top, left;

    if (pos.indexOf('top') >= 0) {
      top = baseTop;
    } else if (pos.indexOf('middle') >= 0) {
      top = baseMiddle;
    } else if (pos.indexOf('bottom') >= 0) {
      top = baseBottom;
    }

    if (pos.indexOf('left') >= 0) {
      left = baseLeft;
    } else if (pos.indexOf('center') >= 0) {
      left = baseCenter;
    } else if (pos.indexOf('right') >= 0) {
      left = baseRight;
    }
    return { top: `${top}px`, left: `${left}px` };
  };
  const position = balloonPosition();

  const popupClasses = balloonClass(currentElement, domEl); // Need to initialize it here as sometimes we need this when element is hidden

  useLayoutEffect(() => {
    addClass("body", "tour-mode");
    const handler = () => {
      stepsInstance.refresh();
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      stepsInstance.refresh();
    }, 100);
  }, [ currentElement ]);

  const nextStep = () => {
    setShowBubble(false);
    stepsInstance.nextStep();
  };
  const onSkip = () => {
    window.location.href = redirectPath;
  };
  return (
    <>
      {
        showBubble && currentElement &&
        <div
          className={ classnames("tour-hint", popupClasses) }
          style={ position }
        >
          <div className="tour-hint_content">
            <div className="tour-hint_text">
              <span { ...render_raw_html(currentElement.text) } />
            </div>
            <div className="tour-hint_buttons">
              {
                stepsInstance.lastStep() ?
                  <button
                    className="button tour-hint_button -finish"
                    type="button"
                    onClick={ onSkip }
                  >
                    Finish
                  </button>
                  :
                  <>
                    <button
                      className="button -secondary tour-hint_button"
                      type="button"
                      onClick={ onSkip }
                    >
                      Skip
                    </button>
                    <button
                      className="button tour-hint_button"
                      type="button"
                      onClick={ nextStep }
                    >
                      Next
                    </button>
                  </>
              }
            </div>
          </div>
        </div>
      }
      <div className="tour-hint_backdrop" />
    </>
  );
};

export default BaseApp;

