import React, { useState, useContext } from 'react';
import Header from '../flex-common/header';
import Card from '../common/card';
import QuantPortal from '../common/portal';
import PlanDetailsModalFlex from '../common/planDetailsModalFlex';
import PlanShortDescriptionFlex from '../common/planShortDescriptionFlex';
import CardPlanTitle from '../common/cardPlanTitle';
import {
  plansIncludesChoose,
  planDetailsShort,
  allPlansInclude,
  planList,
  getLargePlanDescription,
} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';

const renderAllIncludeDescription = (item, idx) => {
  const { title, text, showTextInAll } = item;
  const [showTextState, setShowTextState] = useState(typeof showTextInAll !== 'undefined' ? showTextInAll: true);

  const toggleClickHandle = () => {
    setShowTextState(!showTextState);
  };
  return <div key={idx} className="plans-include-details-group">
    <h6 className={`plans-include-details-subtitle toggle-text_title`} onClick={toggleClickHandle}>
      <svg className={ `icon -dropdown toggle-text_dropdown-icon ${showTextState ? '-up': '-down'}` }>
        <use xlinkHref={ `#svg_arrow-down` }/>
      </svg>
      {
        title.split('\n').map((value, idx) => <span key={idx}>{value}</span>)
      }
    </h6>
    {
      showTextState ? text.split('\n').map((value, idx) => <p key={idx} className="plans-include-details-value" dangerouslySetInnerHTML={{__html: value}}/>) : ''
    }
  </div>;
}

const PlansInsideModal = ({ open, handleClose }) => {
  const { title, description } = allPlansInclude;
  return <QuantPortal title={title} open={open} handleClose={handleClose}>
    <div className="plans-include-details-modal -flex">
      {description.map(renderAllIncludeDescription)}
    </div>
  </QuantPortal>;
};

const renderPlans = (includes, idx) => {
  const { text, icon } = includes;
  return <li className="plans-include-details-list-item" key={idx}>
    {
      icon && <svg className={ `icon -${ icon } plans-include-details-list-item-icon` }>
        <use xlinkHref={ `#svg_${ icon }` }/>
      </svg>
    }
    <div className="plans-include-details-list-item-text">{text}</div>
  </li>;
}

const PlansInclude = () => {
  const [open, setOpen] = useState(false);
  const { filledLines } = useContext(LinesDataContext);
  const handleOpen = () => {
    Statistic.updateValue(`lines.${filledLines}.viewDetailsCount`);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <div className="plans-include-details -flex">
    <h6 className="plans-include-details-title">
      All plans include:
    </h6>
    <ul className="plans-include-details-list">
      {plansIncludesChoose.map(renderPlans)}
    </ul>
    <div className="plans-include-details-button">
      <button type="button" className="details-link" onClick={handleOpen}>View details</button>
    </div>
    <PlansInsideModal open={open} handleClose={handleClose}/>
  </div>
};

const PlanCard = ({ plan, onSelect, onShowDetails, filledLines }) => {

  const { id, name, price, description, hasSwitch  } = plan;

  const [typePerksInPlan, setTypePerksInPlan] = useState(hasSwitch ? 'entertainment' : '');



  return <Card className="-choose-plan -flex">
    <CardPlanTitle className="-choose-plan -flex" name={name} price={price} tipInTwoLines>
      <button onClick={() => onSelect(plan, typePerksInPlan)} className="button -bordered -full-width" type="button">Select</button>
    </CardPlanTitle>

    <div className="choose-plan-card-body -choose-plan -flex">
      <PlanShortDescriptionFlex
        description={description} withTypes={true} filledLines={filledLines}
        typePerks={typePerksInPlan} setTypePerks={setTypePerksInPlan}
      />
    </div>

    <div className="choose-plan-card-footer -flex">
      <button type="button" className="details-link" onClick={() => onShowDetails(id)}>See plan details</button>
    </div>

  </Card>
};

const renderPlansList = ({ onSelect, onShowDetails, filledLines }) => (plan, idx) => {
  const showDetails = (id) => onShowDetails(id, idx);
  return <li key={plan.id} className="choose-plan-card-item">
    <PlanCard
      accordionTitle="Included features & perks"
      plan={plan}
      onSelect={onSelect}
      onShowDetails={showDetails}
      filledLines={filledLines}
    />
  </li>
}

const PlansList = (props) => <ul className="choose-plan-card-list">
  { planDetailsShort.map(renderPlansList(props)) }
</ul>;

const PlanDetailsModalWrapper = ({ planId, handleClose, planList, filledLines }) => {
  return planId
      ? <PlanDetailsModalFlex
          planId={planId}
          open={!!planId}
          handleClose={handleClose}
          planList={planList}
          getPlanLong={getLargePlanDescription}
          allPlansInclude={allPlansInclude}
          filledLines={filledLines}
      />
      : null
};

const ChooseLinePlan = ({ onContinue }) => {
  const { filledLines } = useContext(LinesDataContext);
  const [planId, setPlanId] = useState(null);
  const handeClese = () => { setPlanId(null); };
  const handleOpenDetails = (id, idx) => {
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsCount`);
    setPlanId(id);
  };

  const handleSelect = (plan, typePerksInPlan) => {
    const { id, name, price, hasSwitch } = plan;
    const idx = planList.findIndex(planItem => planItem.id === id);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanName`, name);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPrice`, price);
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.Selected`);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPerksBundle`, typePerksInPlan.toUpperCase());
    onContinue(plan, typePerksInPlan);
  };

  return <div className="layout">
    <Header/>
    <h6 className="choose-line-plane-title">Select a plan for Line {filledLines + 1}</h6>
    <div className="choose-line-plane content">
      <PlansInclude/>
      <PlansList
        onSelect={handleSelect} onShowDetails={handleOpenDetails} filledLines={filledLines}
      />
      <PlanDetailsModalWrapper planId={planId} handleClose={handeClese} planList={planList} filledLines={filledLines} />
    </div>
  </div>;
};

export default ChooseLinePlan;
