import { each } from 'lodash';

const runPreload = (scorings, attributes, concepts, dispatchChangeLoadingState) => {
  const hasConcepts = !!concepts && !!concepts.length
  const hasScorings = !!scorings && !!scorings.length
  const hasAttributes = !!attributes && !!attributes.length

  if ((hasScorings || hasAttributes) && hasConcepts) {
    const numToLoad = concepts.length;
    let currentlyLoaded = 0;
    each(concepts, (concept) => {
      const img = new Image();
      img.onload = function () {
        currentlyLoaded += 1;
        if (currentlyLoaded === numToLoad) {
          dispatchChangeLoadingState(false);
        }
      };
      img.src = concept.url;
      concept.object = img;
    });
  } else {
    dispatchChangeLoadingState(false);
  }
};

export default runPreload;
