import React from "react";
import { map, filter } from "lodash";
import InfoToolTip from '../../common/infoToolTip';
import EditableGridItems from "../common/EditableGridItems";
import Checkbox from "../../common/Checkbox";

const SingleWithCustomOptions = ({ model, setModel, max }) => {
  const handleOnChangeEnabled = (newBool) => {
    setModel({ ...model, enabled: newBool });
  };

  const handleOnChangeCustom = (newBool) => {
    model.custom = newBool;
    setModel({ ...model });
  };

  const handleOnDeleteEditableGridItem = (index) => {
    if (model.grid_items[index].id) {
      model.grid_items[index]._destroy = '1';
    } else {
      model.grid_items.splice(index, 1);
    }
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleOnChangeEditableGridItem = (newText, index) => {
    model.grid_items[index].question = newText;
    setModel({ ...model, grid_items: model.grid_items });
  };

  const handleOnAddEditableGridItem = (newText) => {
    model.grid_items.push({
      id: null,
      question: newText,
      enabled: true,
      editable: true
    });

    setModel({ ...model, grid_items: model.grid_items });
  };

  const itemsCount = filter(model.grid_items, (item) => (item.editable && !item._destroy)).length;
  const showAddNewItem = itemsCount < max;

  return (
    <div className="form_metrics-item -js-custom">
      <Checkbox
        label={ model.default_metric_title }
        bool={ model.enabled }
        onChange={ handleOnChangeEnabled }
      />
      <InfoToolTip>
        Respondents will indicate how often they purchase the category.
        <br />
        Default responses are shown – you may include your own instead.
      </InfoToolTip>
      {model.enabled &&
      <div className="form_metrics -inner">
        <div className="form_metrics-item">
          <Checkbox
            className="-js-use"
            label="Use custom options"
            bool={ model.custom }
            onChange={ handleOnChangeCustom }
          />
        </div>

        {!model.custom && map(filter(model.grid_items, (el) => !el.editable), (el, index) => (
          <div key={ index } className="form_metrics-item -compact">
            <div className="checkbox">
              <label className="checkbox_label -checked -grayed">
                {el.question}
              </label>
            </div>
          </div>
        ))}

        {model.custom &&
        <ul className="form-question_answers">
          <EditableGridItems
            gridItems={ model.grid_items }
            onChange={ handleOnChangeEditableGridItem }
            onDelete={ handleOnDeleteEditableGridItem }
            showAddNewItem={ showAddNewItem }
            newItemPlaceholder={ `Add up ${max - itemsCount} to custom options` }
            onAdd={ handleOnAddEditableGridItem }
          />
        </ul>}
      </div>}
    </div>
  );
};

export default SingleWithCustomOptions;
