import React, { useContext, useState, Fragment } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../common/render_raw_html';
import { LineDataContext, SetContext, PageContext } from '../../common/contexts';
import { Header } from '../../common/header';
import { Subheader } from '../../common/subheader';
import { FLEX } from '../../common/plans_data';

const { compareList } = FLEX;

const ComparePlanHead = ({
  plan, onPlanSelect
}) => {
  const { delayAnimation } = useContext(SetContext);
  const {
    getClickedSelectButton,
    setClickedSelectButton
  } = useContext(PageContext);
  const { setLoading } = useContext(LineDataContext);
  const clickedSelectButton = getClickedSelectButton();

  const clickSelectButton = (event, plan, packName) => {
    setClickedSelectButton({ planId: plan.id, packName });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setClickedSelectButton(null);
      onPlanSelect(event, plan, packName);
    }, delayAnimation);
  };

  return (
    <div className={ `compare-table_cell -head -flex ${plan.choosePerkPack?.length > 1 ? '-perk-pack' : ''}`.trim() }>
      <div className="plan-short -center">
        <h2 className="plan-short_title ng-binding" { ...renderRawHtml(plan.name) } />
        <div className="plan-short_description" { ...renderRawHtml(plan.description) } />
        <div className="plan-short_pricing">
          <div className="plan-short_pricing-grid">
            <h2 className="plan-short_pricing-value">${ plan.price }</h2>
            <div className="plan-short_pricing-hint">
              <div className="plan-short_pricing-hint-line">/line per month</div>
              <div className="plan-short_pricing-hint-line">{ plan.taxPhrase }</div>
            </div>
          </div>
        </div>
        {
          plan?.choosePerkPack ?
            <div className="compare-perks-buttons">
              {
                map(plan.choosePerkPack, ({ id, name }, idx) => (
                  <Fragment key={ idx }>
                    {
                      idx > 0 &&
                      <div className="plan-features_perks-pack_separator -small">or</div>
                    }
                    <button
                      key={ `select-perk-pack-btn-${idx}` }
                      className={
                        classnames(
                          'button -bordered -full-width plan-select-button',
                          { '-clicked': clickedSelectButton && clickedSelectButton.planId === plan.id && clickedSelectButton.packName === id }
                        )
                      }
                      onClick={ (event) => { clickSelectButton(event, plan, id); } }
                      disabled={
                        clickedSelectButton &&
                        clickedSelectButton.planId !== plan.id &&
                        clickedSelectButton.packName !== id
                      }
                    >
                      Select {name}
                    </button>
                  </Fragment>
                ))
              }
            </div>
            :
            <button
              className={
                classnames(
                  'button -bordered -full-width plan-select-button',
                  { '-clicked': clickedSelectButton && clickedSelectButton.planId === plan.id }
                )
              }
              onClick={ (event) => { clickSelectButton(event, plan); } }
              disabled={
                clickedSelectButton &&
                clickedSelectButton.planId !== plan.id
              }
            >
              Select
            </button>
        }
      </div>
    </div>
  );
};

const ComparePlanRowLine1 = (props) => {
  const { title, cellIndex, isPerkPack, updateVisibility, open, planCompFeatureDescription } = props;
  return (<div
    className={ `compare-table_cell -cell-${cellIndex} -flex ${isPerkPack ? '-perk-pack' : ''}`.trim() }
  // eslint-disable-next-line react/jsx-closing-bracket-location
  >
    <div className="compare-feature">
      <div className="compare-feature_name">
        <div className={ `compare-feature_header -cell-${cellIndex}` } onClick={ updateVisibility }>
          <span className="compare-feature_title">{title}</span>
          <span className={ `compare-feature_caret ${open ? "-open" : ""}`.trim() }>
            <svg className="icon -caret">
              <use xlinkHref="#svg_caret" />
            </svg>
          </span>
        </div>
        {planCompFeatureDescription && open && (
          <div className="compare-feature_description" { ...renderRawHtml(planCompFeatureDescription) } />
        )}
      </div>
    </div>
  </div>);
};

const ComparePlanRowLine2 = (props) => {
  const { cellIndex, isPerkPack, planCompFeatureValue, planCompFeatureNote } = props;
  return (
    <div
      className={ `compare-table_cell -cell-${cellIndex} -flex ${isPerkPack ? '-perk-pack' : ''}`.trim() }
    >
      <div className="compare-feature">
        <div className="compare-feature_value">
          <div className="compare-feature_value_icon">
            {planCompFeatureValue ? (
              <svg className="icon -checkmark-alt">
                <use xlinkHref="#svg_checkmark-alt" />
              </svg>
            ) : (
              <svg className="icon -close">
                <use xlinkHref="#svg_close" />
              </svg>
            )}
          </div>
          {planCompFeatureNote && (
            <div className="compare-feature_value_note" { ...renderRawHtml(planCompFeatureNote) } />
          )}
        </div>
      </div>
    </div>
  );
};

const ComparePlanRow = ({ row, plans, rowGroupId }) => {
  const [ open, setOpen ] = useState(false);
  const updateVisibility = () => {
    setOpen(!open);
  };
  return (
  // it's need to devide row to 2 rows for aligning in cell
    <div className="compare-table_row-wrapper">
      <div className="compare-table_row -top" key={ `comparePlanRow-${row.id}-line1` }>
        {
          map(plans, (plan, index) => {
            const isPerkPack = plan.choosePerkPack?.length > 0;
            const compares = isPerkPack
              ? plan.choosePerkPack.map(({ compare }) => compare)
              : [ plan.compare ];

            return map(compares, (compare, compareIdx) => {
              let planCompFeatureDescription = false;
              if (!compare[rowGroupId] && compareIdx > 0) {
                return null;
              }
              const group = compare[rowGroupId] || plan.compare[rowGroupId] || {};
              if (group[row.id]) {
                planCompFeatureDescription = group[row.id].about
                  ? group[row.id].about.description
                  : false;
              }
              return (
                <ComparePlanRowLine1
                  key={ `comparePlanItem${plan.id}${compareIdx}` }
                  title={ row.title }
                  cellIndex={ index }
                  isPerkPack={ isPerkPack }
                  updateVisibility={ updateVisibility }
                  open={ open }
                  planCompFeatureDescription={ planCompFeatureDescription }
                />
              );
            });
          })
        }
      </div>
      <div className="compare-table_row -bottom" key={ `comparePlanRow-${row.id}-line2` }>
        {
          map(plans, (plan, index) => {
            const isPerkPack = plan.choosePerkPack?.length > 0;
            const compares = isPerkPack
              ? plan.choosePerkPack.map(({ compare }) => compare)
              : [ plan.compare ];

            return map(compares, (compare, compareIdx) => {
              let planCompFeatureValue = false;
              let planCompFeatureNote = false;
              if (!compare[rowGroupId] && compareIdx > 0) {
                return null;
              }
              const group = compare[rowGroupId] || plan.compare[rowGroupId] || {};

              if (group[row.id]) {
                planCompFeatureValue = Boolean(group[row.id].value);
                planCompFeatureNote = group[row.id].about
                  ? group[row.id].about.note
                  : false;
              }
              return (
                <ComparePlanRowLine2
                  key={ `comparePlanItem${plan.id}${compareIdx}` }
                  cellIndex={ index }
                  isPerkPack={ isPerkPack }
                  planCompFeatureValue={ planCompFeatureValue }
                  planCompFeatureNote={ planCompFeatureNote }
                />
              );
            });
          })
        }
      </div>
    </div>
  );
};

const ComparePage = ({ updatedCurrentLine, onPlanSelect, toOverview }) => {
  const { plans } = useContext(LineDataContext);
  return (
    <div className="content -flex-compare-desktop">
      <Header />
      <Subheader currentLine={ updatedCurrentLine } />
      <div className="tabs">
        <div className="tab" onClick={ () => {toOverview();} }>Overview</div>
        <div className="tab -active">Compare</div>
      </div>
      <div className="compare-page -flex">
        <div className="inner-content -flex -compare -no-pt">
          <div className="compare-table">
            <div className="compare-table_row -head">
              {
                map(plans, (plan) => {
                  return (
                    <ComparePlanHead
                      key={ `comparePlanItem${plan.id}` }
                      plan={ plan }
                      onPlanSelect={ onPlanSelect }
                    />
                  );
                })
              }
            </div>

            {
              map(compareList, (compareRowGroup, index) => {
                return (
                  <div className="compare-table_group" key={ `comparePlanRowGroup-${compareRowGroup.id}` }>
                    <div className={ `compare-table_group-header -group-${index}` }>
                      <h4 className="compare-table_group-title">{compareRowGroup.title}</h4>
                    </div>
                    {compareRowGroup.subtitles && (
                      <div className="compare-table_row -subtitles">
                        {
                          map(plans, (plan) => {
                            const isPerkPack = plan.choosePerkPack?.length > 0;
                            const compares = isPerkPack
                              ? plan.choosePerkPack.map(({ compare }) => compare)
                              : [ plan.compare ];

                            return map(compares, (compare, compareIdx) => {
                              let subtitle = false;
                              if (compare[compareRowGroup.id] && compare[compareRowGroup.id].subtitle) {
                                subtitle = compare[compareRowGroup.id].subtitle;
                              }
                              return (
                                <div className={ `compare-table_cell -flex -subtitle ${plan.choosePerkPack?.length > 0 ? '-perk-pack' : ''}`.trim() } key={ `comparePlanSubtitleItem${plan.id}${compareIdx}` }>
                                  {compareIdx > 0 && <div className="plan-features_perks-pack_separator -subtitle">or</div>}
                                  {subtitle.title && <h4 className="compare-table_group-subtitle-title">{subtitle.title}</h4>}
                                  {subtitle.description && <div className="compare-table_group-subtitle-description">{subtitle.description}</div>}
                                </div>
                              );
                            });
                          })
                        }
                      </div>
                    )}
                    {
                      map(compareRowGroup.list, (compareRow, indexRow) => {
                        return (
                          <ComparePlanRow row={ compareRow } key={ indexRow } plans={ plans } rowGroupId={ compareRowGroup.id } />
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePage;
