import {
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible,
  customMetricsByLocation, defaultScorings
} from '../../common/logic/eligible.js';
import BaseForward from '../../common/logic/base_forward';
import {respondentQualified} from "../../../common/google-analytics/events";

class Forward extends BaseForward {
  constructor(options) {
    super(options);
    this.updateConceptStep = options.updateConceptStep;
    this.updateConceptIndex = options.updateConceptIndex;
  }

  forward(step, data, result, goNextConcept, projectId, antiFraud, gaTrackingId) {
    let newStep = step + 1, needForward = false;

    switch (newStep) {
      case 1: //demographics
        if (!data.demographics.length) {
          needForward = true;
        }
        break;
      case 2: //optional demographics
        if (demographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.optional_demographics.length) {
          needForward = true;
        }
        break;
      case 3: //screening
        if (optionalDemographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.screenings.length) {
          needForward = true;
        }
        break;
      case 4: // Qualified
        if (screeningsNotEligible(data, result.answers, gaTrackingId)) {
          newStep = this.screenedActionNum;
          needForward = true;
        }
        break;
      case 5:
        respondentQualified(gaTrackingId, { project_id: projectId, respondent_id: data.respondent_id });
        if (customMetricsByLocation(data.survey_metrics, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 6:
        if (customMetricsByLocation(data.scorings, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 7: // Concepts initializing
        // Check the step === 1 in nextConceptStep func
        goNextConcept();
        break;
      case 8:
        if (customMetricsByLocation(data.survey_metrics, 'default').length === 0) {
          needForward = true;
        }
        break;
      case 9:
        if (customMetricsByLocation(data.scorings, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
      case 10: // Preference
        if (!data.preference_enabled) {
          needForward = true;
        }
        break;
      case 11: // Reasons for Preference
        if (!data.preference_reasons) {
          needForward = true;
        }
        break;
      case 12:
        if (customMetricsByLocation(data.survey_metrics, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
    }

    [ newStep, needForward ] = this.checkLastSteps(
      this, newStep, needForward, 13, data, result, projectId, antiFraud
    );
    if (!newStep) {
      return;
    }

    if (needForward) {
      this.forward(newStep, data, result, goNextConcept, projectId, antiFraud);
    } else {
      this.updateStep(newStep);
    }
  }

  nextConceptStep(conceptStep, conceptIndex, concept, data, goForward) {
    let nextStep = conceptStep + 1, needForward = false;

    switch (nextStep) {
      case 1: // Video/Image
        break;
      case 2: // Thought Bubble
        if (!concept.show_thought_bubble) {
          needForward = true;
        }
        break;
      case 3: // Five-point scales
        if (!defaultScorings(data.scorings).length) {
          needForward = true;
        }
        break;
      case 4: // Markup
        if (!concept.focus_concept_id) {
          needForward = true;
        }
        break;
      case 5: // Attribute Ratings 1
        if (!data.attribute_ratings1.length) {
          needForward = true;
        }
        break;
      case 6: // Attribute Ratings 2
        if (!data.attribute_ratings2.length) {
          needForward = true;
        }
        break;
      case 7: //Next concept
        if (conceptIndex === data.concepts.length - 1) {
          goForward();
          return;
        }

        this.updateConceptIndex(conceptIndex + 1);
        needForward = true;
        nextStep = 0;
        break;
    }

    if (needForward) {
      this.nextConceptStep(nextStep, conceptIndex, concept, data, goForward);
    } else {
      this.updateConceptStep(nextStep);
    }
  }
}
export default Forward;
