import React, { useEffect, useState } from 'react';
import InfoToolTip from "../../../../express/admin/common/infoToolTip";

const ConceptItem = ({ concept, onChange, checked }) => {
  const id = `${concept.id}`;
  const [ val, setVal ] = useState(checked);
  const handleOnChange = () => {
    setVal(!val);
    onChange(concept.id, !val);
  };

  useEffect(() => {
    setVal(checked);
  }, [ checked ]);

  return (
    <div className="concept-row">
      <div className="selected-checkbox">
        <input
          id={ id }
          type="checkbox"
          checked={ val || false }
          onChange={ () => {} }
        />
        <label
          className="checkbox-label -flex"
          htmlFor={ id }
          onClick={ handleOnChange }
        />
      </div>
      <div className="concept-preview">
        <InfoToolTip
          remote
          position="top-center"
          showPopup
          attachTo={
            <>
              <div
                className="concept-image -pointer"
                style={ { 'backgroundImage': `url(${concept.src})` } }
              />
            </>
          }
        >
          <div className="image-as-reactjs-hover -with-border">
            <div className="info-tooltip_image">
              <img src={ concept.src } alt={ concept.name } />
            </div>
          </div>
        </InfoToolTip>
      </div>
      <span className="concept-title" onClick={ handleOnChange }>{ concept.name }</span>
    </div>
  );
};

export default ConceptItem;

