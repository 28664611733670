import React, { useContext } from 'react';
import { baseContext } from "../context";
import SvgIcon from "../../../../common/components/svg-icon";

const Metric = ({ title, data, counts }) => {
  const { box } = useContext(baseContext);

  const showWarning = (counts.brands < 3 || counts.concepts < 30) && !!data?.avg?.[box];

  return (
    <div className="concepts-database_metrics_element">
      {
        showWarning &&
        <div className="info-tooltip -absolute">
          <div className="concepts-database_warning-icon">
            <SvgIcon name="i-warning" />
          </div>
          <div className="info-tooltip_drop -top-center-near">
            <div className="info-tooltip_drop-inner -sm">
              We recommend at least 30 concepts and 3 brands
            </div>
          </div>
        </div>
      }
      <div className="concepts-database_metrics_element__value">
        { data?.avg?.[box] || "N/A" }
      </div>
      <div className="concepts-database_metrics_element__title">
        { title }
      </div>
    </div>
  );
};

export default Metric;
