import React, { useContext, useState, useEffect } from 'react';
import { GeneralContext } from '../../../contexts';
import FivePointScale from '../five_point_scale';
import Intro from '../../intro';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ItemView from '../../ItemView';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';
import Stimuli from '../../stimuli';

const Process = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    concept, processMetrics, result, translations, branded
  } = useContext(GeneralContext);

  const fullConceptData = concept.full_concept_data;

  const stepsCount = fullConceptData.specific_steps.length;
  const metricIndex = metricGroupStep - 2 - stepsCount;

  const prepareCurrentQuestions = () => {
    if (metricIndex >= 0) {
      const question = processMetrics[metricIndex];
      question.text = fixQuestionTextTemplates(question, processMetrics, result, translations);
      return [ question ];
    }
    return [];
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  useEffect(() => {
    setCurrentQuestions(prepareCurrentQuestions());
  }, [ metricGroupStep ]);

  const nextStep = () => {
    if (metricIndex < processMetrics.length - 1) {
      const pm = processMetrics[metricIndex + 1];
      //need to skip PROCESS VZ OPINION IMPACT question if unbranded project
      if (branded === 'unbranded' && pm && pm.title === 'PROCESS VZ OPINION IMPACT') {
        setMetricGroupStep(metricGroupStep + 2);
      } else {
        setMetricGroupStep(metricGroupStep + 1);
      }
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  return (
    <>
      {
        !metricGroupStep &&
        <Intro nextStep={ nextStep }>
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.process_intro) } />
            </div>
          </>
        </Intro>
      }
      {
        !!metricGroupStep && metricGroupStep <= stepsCount &&
        <>
          <ItemView key={ metricGroupStep } nextStep={ nextStep }>
            <div className="survey-start_description -left-aligned">
              <Stimuli
                processLeadInOptions={ fullConceptData.process_lead_in_options }
                processLeadIn={ (metricGroupStep - 1 === 0) ? fullConceptData.process_lead_in : null }
                processLeadInOther={ fullConceptData.process_lead_in_other }
                processStepsKind={ fullConceptData.process_steps_kind }
                steps={ fullConceptData.steps }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
                specificSteps={ fullConceptData.specific_steps }
                specificStepIndex={ metricGroupStep - 1 }
                // zoomImage
              />
            </div>
          </ItemView>
        </>
      }
      {
        !!metricGroupStep && metricGroupStep > stepsCount && metricIndex < 0 &&
        <Intro nextStep={ nextStep }>
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.process_after_steps_intro) } />
            </div>
          </>
        </Intro>
      }
      {
        metricIndex >= 0 && !!currentQuestions[0] &&
        <>
          <FivePointScale
            key={ currentQuestions[0].name }
            customConceptView={
              currentQuestions[0].title === 'PROCESS EFFORT OPEN END' ? null : (
                <Stimuli
                  imageClassName="-less-height"
                  processLeadInOptions={ fullConceptData.process_lead_in_options }
                  processLeadIn={ fullConceptData.process_lead_in }
                  processLeadInOther={ fullConceptData.process_lead_in_other }
                  processStepsKind={ fullConceptData.process_steps_kind }
                  steps={ fullConceptData.steps }
                  additionalImageUrl={ fullConceptData.additional_image_url }
                  additionalImageNotes={ fullConceptData.additional_image_notes }
                  branded={ fullConceptData.branded }
                  // zoomImage
                />
              )
            }
            concept={ concept }
            questions={ currentQuestions }
            result={ result }
            nextStep={ nextStep }
          >
            <></>
          </FivePointScale>
        </>
      }
    </>
  );
};

export default Process;
