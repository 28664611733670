import React, { useContext } from 'react';
import { baseContext } from '../../contexts';
import { withPrefix } from '../../helpers';

const Legend = ({ filtersPresent, total, mmWaveTotal, cBandTotal }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  return (
    <>
      {
        !filtersPresent &&
        <div className="legend">
          <div className="legend-note">
            Base: {total}N Total {withPrefix(verizonType, mediaKind, '', true)}.
            {' '}
            May vary slightly due to partial survey completion.
          </div>
        </div>
      }
      {
        filtersPresent &&
        <div className="legend">
          <span className="legend-item legend-total">
            Total {withPrefix(verizonType, mediaKind)}
            <span className="coma">,</span>
          </span>
          <span className="legend-item legend-mm-wave">
            mmWave*
            <span className="coma">,</span>
          </span>
          <span className="legend-item legend-c-band">
            C-Band*
          </span>
          <div className="legend-note">
            * C-Band and mmWave breakouts represent small base sizes.
            {' '}
            Read differences as directional only.
          </div>
          <div className="legend-note">
            Base: {total}N Total {withPrefix(verizonType, mediaKind, '', true)},
            {' '}
            {mmWaveTotal}N mmWave,
            {' '}
            {cBandTotal}N C-Band.
            {' '}
            May vary slightly due to partial survey completion.
          </div>
        </div>
      }
    </>
  );
};

export default Legend;
