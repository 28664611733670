import React, { useContext, useState } from 'react';
import { CreditsContext, LaunchContext } from '../../../contexts';
import ModalDialog from '../../modalDialog';

const CreditsLaunchButton = () => {
  const { loading, sendData, textLaunch } = useContext(LaunchContext);
  const { priceInCredits, enoughCredits, credits, paymentType } = useContext(CreditsContext);
  const [ showModal, setShowModal ] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleLaunchByCredits = () => {
    handleClose();
    sendData({ paymentType });
  };

  return (
    <>
      <button
        className="button"
        onClick={ handleShowModal }
        disabled={ loading || !enoughCredits }
      >
        { textLaunch }
      </button>
      {
        showModal &&
        <ModalDialog
          onLaunch={ handleLaunchByCredits }
          onClose={ handleClose }
        >
          <>
            <p>You are going to spend {priceInCredits} from available {credits} credits.</p>
            <p>
              Hit "Ready to Launch" button if you are ready for respondents to
              {' '}
              start coming through the study.
            </p>
          </>
        </ModalDialog>
      }
    </>
  );
};

export default CreditsLaunchButton;
