import React, { useContext, useEffect } from 'react';
import Header from "../common/components/header/index";
import { IndexData } from '../common/index-context';
import { WithLangData } from '../common/lang-data';
import CustomizeQuestionsModal from "../common/components/customize-questions-modal/customize-questions-modal";
import Comparisons from "./components/comparisons";
import EntityModal from "./components/entity-modal/index";

const AppInner = () => {
  const {
    init, data, loadData, openConceptAfterReload,
    customizedQuestionData, showEntityModal
  } = useContext(IndexData);

  useEffect(() => {
    loadData(null, null, null, null, {}, openConceptAfterReload);
  }, []);

  const reports = [
    {
      title: 'Packages Comparison Report',
      name: 'concept_comparison_package_mc',
      kinds: [ 'pptx', 'xlsx' ],
      customFontSize: true
    },
    { title: 'Individual Package Reports', name: 'package_mc_individual_item', kinds: [ 'pptx', 'xlsx' ] },
    { title: 'All Packages Verbatims Report', name: 'package_all_verbatims', kinds: [ 'xlsx' ] }
  ];
  if (init.isInternal) {
    reports.push({ title: 'Disqualified Respondents Report', name: 'disqualified', kinds: [ 'xlsx' ] });
    reports.push({ title: 'All Respondents Answers', name: 'package_mc_all_respondents', kinds: [ 'xlsx' ] });
    reports.push({ title: 'Quota Details', name: 'quotas_details', kinds: [ 'xlsx' ] });
  }

  const sortGroups = [
    { group_name: 'five_point_scale', hidden: false },
    'package_attribute_ratings',
    'preference'
  ];
  if (data.left_right) {
    sortGroups.push({ kind: 'left_right' });
  }
  if (init.project.is_shelf) {
    sortGroups.push({ kind: 'express_shelf_found' });
    sortGroups.push({ kind: 'express_shelf_time' });
    sortGroups.push({ group_name: 'five_point_scale', hidden: true, custom: "express_shelf_result" });
  }
  if (init.project.has_cart_exercise) {
    sortGroups.push({ kind: 'shelf_cart' });
    sortGroups.push({ kind: 'shelf_cart_competitors' });
  }
  if (init.project.has_ranking) {
    sortGroups.push({ kind: 'cart_ranking' });
    sortGroups.push({ kind: 'ranking_top3_competitors' });
  }
  sortGroups.push({ group_name: 'custom_metric', custom: 'custom_metrics' });

  return (
    <div className="content -loading-wrapper tour-parent-header">
      <Header
        reports={ reports }
        plural="packages"
        sortGroups={ sortGroups }
      />
      <WithLangData
        langCode={ data.language_code }
        langName={ data.language_name }
      >
        <Comparisons />
        { showEntityModal && <EntityModal /> }
        { customizedQuestionData && <CustomizeQuestionsModal /> }
      </WithLangData>
    </div>
  );
};

export default AppInner;
