import { saveUserDataBase } from '../../common/logic/respondent_data.js';
import { addKidsAnswersToSurveyData } from '../components/screening/kids';

const saveUserData = (url, status, result, data) => {
  const moreSurveyData = { ...addKidsAnswersToSurveyData(result) };

  saveUserDataBase(
    url, status, result, data, {}, moreSurveyData,
    [
      'pre_exposures',
      'idea_metrics',
      'process_metrics',
      'full_concept_metrics',
      'deviation_metrics',
      'qualified_demographic_metrics'
    ]
  );
};

export default saveUserData;
