import React from 'react';

const Layout = ({ children }) => {
  const currentDate = new Date;
  const currentYear = currentDate.getFullYear();
  return (
    <div className="layout-over">
      <div className="layout-over_box -center">
        <div className="auth-box">
          <div className="auth-box_header">
            <svg className="icon auth-box_header-logo">
              <use xlinkHref={ `#svg_verizon-logo-branded` } />
            </svg>
          </div>
          <div className="auth-box_content">{ children }</div>
        </div>
      </div>
      <div className="auth-footer">
        <div className="auth-footer_text">
          {/*© { currentYear }*/}
          Powered by <b>buzzback LLC</b>
        </div>
      </div>
    </div>
  );
};

export default Layout;
