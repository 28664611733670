import React, { useEffect, useRef, useState } from "react";

const ShelfHeader = ({ image }) => {
  const modalButtonRef = useRef(null);
  const [ popupVisible, setPopupVisible ] = useState(false);

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (modalButtonRef && modalButtonRef.current) {
      modalButtonRef.current.addEventListener('mouseenter', showPopup);
      modalButtonRef.current.addEventListener('touchstart', showPopup);
      modalButtonRef.current.addEventListener('mouseleave', hidePopup);
      modalButtonRef.current.addEventListener('touchend', hidePopup);
    }
    return () => {
      if (modalButtonRef && modalButtonRef.current) {
        modalButtonRef.current.removeEventListener('mouseenter', showPopup);
        modalButtonRef.current.removeEventListener('touchstart', showPopup);
        modalButtonRef.current.removeEventListener('mouseleave', hidePopup);
        modalButtonRef.current.removeEventListener('touchend', hidePopup);
      }
    };
  }, [ showPopup, hidePopup ]);

  return (
    <div className="products-greed-item-header">
      <span className="products-greed-item-title">
        {image.name}
      </span>
      <div className="products-greed-item-modal-wrapper">
        <span ref={ modalButtonRef } onContextMenu={ noop } className="products-greed-item-show-modal">
          Show package
        </span>
        {
          popupVisible &&
          <div className="tooltip -express-tooltip-preview fade top in -shelf-concept" role="tooltip">
            <div className="tooltip-arrow" />
            <div className="tooltip-inner">
              <div className="popup-title -no-description">{image.label}</div>
              <div className="popup-image">
                <img src={ image.url } alt="" />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

const noop = (event) => {
  if (event) {
    event.preventDefault();
  }
};

export default ShelfHeader;
