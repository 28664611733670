import React, { useContext } from 'react';
import classnames from 'classnames';
import { CreditsContext } from '../../../contexts';

const CreditsForm = () => {
  const { priceInCredits, enoughCredits, credits } = useContext(CreditsContext);
  return (
    <div className="form_section -credits-form">
      <div className="form-pay_price-grid -grow-with-max">
        <div className="form-pay_price-grid-item">
          <div className="form-group">
            <label className="form-label">Credits needed</label>
            <div
              className={
                classnames(
                  "form-pay_price",
                  { '-green': enoughCredits },
                  { '-red': !enoughCredits }
                )
              }
            >
              { priceInCredits }
            </div>
          </div>
        </div>
        <div className="form-pay_price-grid-item">
          <div className="form-group">
            <label className="form-label">Available Credits</label>
            <div className="form-pay_price">{ credits }</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditsForm;
