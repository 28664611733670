import React, { useLayoutEffect, useContext, useRef } from 'react';
import { BaseContext } from '../../contexts';
import Types from './types';

const Section2 = () => {
  const { setSectionRefs, projectsAllowance } = useContext(BaseContext);
  const solutionsAnchor = useRef(null);
  useLayoutEffect(() => {
    setSectionRefs((oldHash) => ({
      ...oldHash,
      ...{ solutions: { el: solutionsAnchor.current } }
    }));
  }, []);

  return (
    <section className="intro" ref={ solutionsAnchor }>
      <div className="container">
        <div className="intro_title">
          <h3>Introducing a New Way to Test & Learn</h3>
          <small>Ideal for fast turn & lean budgets. Available globally</small>
        </div>
        <Types projectsAllowance={ projectsAllowance }  />
      </div>
    </section>
  );
};

export default Section2;
