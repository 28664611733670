import React, { useState, useContext, useEffect } from 'react';
import classnames from 'classnames';
import TextField from '../common/portal/textField';
import Wysiwyg from '../../../common/components/wysiwyg';
import UploadButton from '../common/uploadButton';
import { baseContext, spotlightContext } from '../contexts';
import PortalModal from '../common/portal';
import { stripHtml } from '../helpers';
import { createSpotlight, deleteSpotlight, updateSpotlight } from './api';

const Edit = ({ row, isNew, handleClose }) => {
  const { mediaKind, verizonType } = useContext(baseContext);
  const { updateOverviewSpotlights } = useContext(spotlightContext);
  const [ valid, setValid ] = useState(false);
  const [ saving, setSaving ] = useState(false);
  const [ deleting, setDeleting ] = useState(false);
  const [ avatarUploading, setAvatarUploading ] = useState(false);
  const [ avatar, setAvatar ] = useState({ url: row.avatar_url, metaData: row.avatar_data });
  const [ name, setName ] = useState(row.name || "");
  const [ briefOverview, setBriefOverview ] = useState(row.brief_overview || "");
  const [ installType, setInstallType ] = useState(row.install_type || "");
  const [ description, setDescription ] = useState(row.description || "");
  const [ betterExperience, setBetterExperience ] = useState(row.better_experience || "");
  const [ baseInfo, setBaseInfo ] = useState(row.base_info || "");
  const [ videoUploading, setVideoUploading ] = useState(false);
  const [ video, setVideo ] = useState({
    url: row.video_url, metaData: row.video_data, filename: row.video_filename
  });

  const handleCancel = () => {
    handleClose();
  };

  const getAttributes = () => (
    {
      avatar_data: avatar.metaData,
      name,
      brief_overview: briefOverview,
      install_type: installType,
      description,
      better_experience: betterExperience,
      base_info: baseInfo,
      video_filename: video.filename,
      video_data: video.metaData
    }
  );

  const portalSaveButtonName = () => {
    if (saving) {
      return (isNew ? "Creating..." : "Updating...");
    }
    return (isNew ? "Create" : "Update");
  };

  const createRequest = (callback) => {
    createSpotlight(mediaKind, verizonType, getAttributes(), (data) => {
      callback && callback(data);
    });
  };

  const updateRequest = (callback) => {
    updateSpotlight(mediaKind, verizonType, row.id, getAttributes(), (data) => {
      callback && callback(data);
    });
  };

  const handleDelete = () => {
    if (window.confirm(`Do you really want to delete customer "${name}"?`)) {
      setDeleting(true);
      deleteSpotlight(mediaKind, row.id, (data) => {
        updateOverviewSpotlights(data);
        setDeleting(false);
        handleCancel();
      });
    }
  };

  const handleSave = () => {
    setSaving(true);
    const callback = (data) => {
      updateOverviewSpotlights(data);
      setSaving(false);
      handleCancel();
    };
    isNew ? createRequest(callback) : updateRequest(callback);
  };

  const onInitUploading = (setUploading) => () => {
    setUploading(true);
  };

  const handleUpload = (setUploading, setData) => (file) => {
    setData({
      url: file.previewSrc,
      metaData: file.metaData,
      filename: file.filename
    });
    setUploading(false);
  };

  const removeVideoFile = (event) => {
    event.preventDefault();
    setVideo({
      url: null,
      metaData: null,
      filename: null
    });
  };

  useEffect(() => {
    setValid(
      !!name && !!stripHtml(briefOverview) &&
      !!stripHtml(description)
    );
  }, [
    name, briefOverview, description
  ]);

  return (
    <PortalModal
      className="-sm spotlight-portal"
      title={ isNew ? "Create Spotlight" : "Edit Spotlight" }
      open
      handleClose={ handleCancel }
      saveCallback={ handleSave }
      deleteItem={ handleDelete }
      buttonName={ portalSaveButtonName() }
      labelDelete={ deleting ? "Deleting..." : "Delete" }
      showDelete={ !isNew }
      disabledSave={ avatarUploading || videoUploading || saving || deleting || !valid }
      disabledDelete={ saving || deleting }
    >
      <div className="customers-search">
        <h3>Overview Fields</h3>
        <div className="customer-search">
          <div className="customer-search_grid">
            <div className="customer-avatar-area">
              <div className="customer-search_avatar">
                { !!avatar.url && <img src={ avatar.url } alt={ name } /> }
              </div>
              <UploadButton
                className="button -avatar-upload -upper"
                needPreview
                allowedTypes={ [ 'image/jpeg', 'image/png', 'image/jpg' ] }
                onInit={ onInitUploading(setAvatarUploading) }
                onUploaded={ (files) => { handleUpload(setAvatarUploading, setAvatar)(files[0]); } }
                disabled={ avatarUploading }
              >
                Upload photo
              </UploadButton>
            </div>
            <div className="customer-search_info -edit">
              <div className="customer-search_name">
                <TextField
                  className=" -light"
                  placeholder="Enter customer name*"
                  value={ name }
                  onChange={ setName }
                />
              </div>
              <div className="customer-search_text">
                <Wysiwyg
                  editorClassName="more-than-middle-height -brief-overview"
                  placeholder="Enter spotlight text*"
                  value={ briefOverview }
                  onChange={ setBriefOverview }
                  enableAlign
                />
              </div>
            </div>
          </div>
        </div>

        <h3>Spotlight Details Fields</h3>

        <div className="customer-search">
          <div className="customer-search_grid -no-flex">
            <Wysiwyg
              availableFontSizes={ [ 15, 16, 17, 18, 19, 20, 21, 22, 23, 24 ] }
              availableColors={ [ "#df2d1f", "rgb(216, 217, 217)", "#000000", "#E2E4E3", "#ffffff" ] }
              editorClassName="more-than-middle-height -install-type"
              placeholder="Enter install type text"
              value={ installType }
              onChange={ setInstallType }
              enableAlign
            />
            <Wysiwyg
              editorClassName="more-than-middle-height -base-info"
              placeholder="Enter base info"
              value={ baseInfo }
              onChange={ setBaseInfo }
              enableAlign
            />
            <Wysiwyg
              editorClassName="biggest-height -description"
              placeholder="Enter description text*"
              value={ description }
              onChange={ setDescription }
              enableAlign
            />
            <Wysiwyg
              editorClassName="more-than-middle-height -experience"
              placeholder="Enter text about improving experience"
              value={ betterExperience }
              onChange={ setBetterExperience }
              enableAlign
            />
          </div>
        </div>

        <h3>Video</h3>

        <div className="customer-search">
          <div className="form-group_control -video">
            <UploadButton
              className={ classnames("button -sm -upper", { "loader -loading-blink": videoUploading }) }
              allowedTypes={ [ 'video/*' ] }
              filenameOnBottom
              forceInitialList
              initialList={ video.filename ? [ video.filename ] : null }
              onInit={ onInitUploading(setVideoUploading) }
              onUploaded={ (files) => { handleUpload(setVideoUploading, setVideo)(files[0]); } }
              disabled={ videoUploading }
              customProcessingLabel="Uploading Video File..."
              onRemove={ removeVideoFile }
            >
              Upload Video File
            </UploadButton>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default Edit;
