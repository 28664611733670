import React from 'react';
import { InnerAccordion, accordionTitleFactory } from '../common/accordion';
import { TVConnectionsB } from '../data';
import { useTVConnections } from './hooks';

const Connection = ({ count, price, showPrice, active, onClick }) => {
  const className = ['tv-connection', active ? '-active' : ''].join(' ').trim();
  return <div className={className} onClick={onClick}>
    <div className="tv-connection-content">
      <span className="tv-connection-title">{count}</span>
      {showPrice && 
        <span className="tv-connection-price">
          {price === 0 ? 'Included' : `$${price}.00/mo.`}
        </span>}
    </div>
  </div>
}

const renderConnections = (activeConnection, onChange) => (connection, idx) => {
  const { count, price, showPrice } = connection;
  return <li className="tv-connections-list-item" key={idx} >
    <Connection
      active={connection === activeConnection}
      count={count}
      price={price}
      showPrice={showPrice}
      onClick={() => onChange(connection)} />
  </li>
};

const ConnectionList = ({ activeConnection, onChange }) =>
  <ul className="tv-connections-list">
    {TVConnectionsB.map(renderConnections(activeConnection, onChange))}
  </ul>

const TVConnections = ({ open, onChange, canOpen, onNext }) => {
  const {
    activeConnection,
    handleActiveChange,
    title,
    chipText,
    chipClassName,
  } = useTVConnections(onNext);

  return <InnerAccordion 
    open={open}
    canOpen={canOpen}
    onChange={onChange}
    TitleComponent={accordionTitleFactory(title, chipText, chipClassName)}>
    <ConnectionList activeConnection={activeConnection} onChange={handleActiveChange}/>
  </InnerAccordion>;
};
export default TVConnections;