import React from 'react';
import cn from "classnames";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";

const EmailsList = ({ model, setModel, errors, limit }) => {
  const handleOnChange = (value, index) => {
    model.emails[index] = value;
    setModel({ ...model, emails: [ ...model.emails ] });
  };

  const addNewEmail = () => {
    model.emails.push('');
    setModel({ ...model, emails: [ ...model.emails ] });
  };

  const deleteEmail = (index) => {
    model.emails.splice(index, 1);
    setModel({ ...model, emails: [ ...model.emails ] });
  };

  return (
    <>
      {
        !model.emails.length &&
        <div className="form_section">
          <button
            id="-js-add"
            className="button"
            onClick={ addNewEmail }
          >
            Add Email
          </button>
        </div>
      }
      {
        map(model.emails, (email, index) => (
          <div className="form_section -flexible" key={ `emails${index}` }>
            <div className={ cn("form_grid-item -grow -js-process-step", { 'has-error': !!errors }) }>
              <input
                className="form-field -block"
                maxLength="250"
                size="250"
                type="text"
                value={ email }
                onChange={ (event) => handleOnChange(event.target.value, index) }
              />
            </div>
            <div className="form_grid-item -two-icons">
              <button
                type="button"
                className="button -secondary -circle -delete-color form-question_grid-button -dark-grey -list-right-margin"
                onClick={ () => { deleteEmail(index); } }
              >
                <SvgIcon name="i-delete" />
              </button>
              {
                (index === model.emails.length - 1) && (model.emails.length < limit) &&
                <button
                  type="button"
                  className="button -circle form-question_grid-button"
                  onClick={ addNewEmail }
                >
                  <SvgIcon name="i-add" />
                </button>
              }
            </div>
          </div>
        ))
      }
    </>
  );
};

export default EmailsList;
