import { find } from 'lodash';
import {
  customMetricsByLocation, defaultScorings,
  demographicsNotEligible,
  optionalDemographicsNotEligible,
  screeningsNotEligible,
  defaultNotCustom
} from '../../common/logic/eligible.js';
import BaseForward from '../../common/logic/base_forward';
import {respondentQualified} from "../../../common/google-analytics/events";

class Forward extends BaseForward {
  constructor(options) {
    super(options);
    this.updateToolIndex = options.updateToolIndex;
    this.updateConceptStep = options.updateConceptStep;
    this.updateConceptIndex = options.updateConceptIndex;
  }

  forward(step, data, result, projectId, antiFraud, gaTrackingId) {
    let newStep = step + 1, needForward = false;

    switch (newStep) {
      case 1: // title
        newStep = newStep + 1;
        break;
      case 2: //demographics
        if (!data.demographics.length) {
          needForward = true;
        }
        break;
      case 3: //optional demographics
        if (demographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.optional_demographics.length) {
          needForward = true;
        }
        break;
      case 4: //screening
        if (optionalDemographicsNotEligible(data, result.answers)) {
          newStep = this.screenedActionNum;
          needForward = true;
        } else if (!data.screenings.length) {
          needForward = true;
        }
        break;
      case 5: // Qualified
        if (screeningsNotEligible(data, result.answers, gaTrackingId)) {
          newStep = this.screenedActionNum;
          needForward = true;
        }
        break;
      case 6:
        respondentQualified(gaTrackingId, { project_id: projectId, respondent_id: data.respondent_id });
        if (customMetricsByLocation(data.survey_metrics, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 7:
        if (customMetricsByLocation(data.scorings, 'at_the_start').length === 0) {
          needForward = true;
        }
        break;
      case 8: // Swipe Tools
        if (!data.tools.length) {
          needForward = true;
        }
        break;
      case 9: { // Intro for Five point scale
        const noAttrs = !data.attribute_ratings.length;
        const no5pts = !defaultNotCustom(data.scorings).length;
        const customs = customMetricsByLocation(data.scorings, 'default');
        const noCustom = customs.length === 0 || customs.length > 0 && data.custom_metrics_at_the_end;

        if (noAttrs && no5pts && noCustom) {
          needForward = true;
        }
        break;
      }
      case 10: {// Five point scale
        const noAttrs = !data.attribute_ratings.length;
        const no5pts = !defaultNotCustom(data.scorings).length;
        const customs = customMetricsByLocation(data.scorings, 'default');
        const noCustom = customs.length === 0 || customs.length > 0 && data.custom_metrics_at_the_end;

        if (noAttrs && no5pts && noCustom) {
          needForward = true;
        }
        break;
      }
      case 11:
        if (customMetricsByLocation(data.survey_metrics, 'default').length === 0) {
          needForward = true;
        }
        break;
      case 12:
        if (customMetricsByLocation(data.scorings, 'default').length === 0 || !data.custom_metrics_at_the_end) {
          needForward = true;
        }
        break;
      case 13:
        if (customMetricsByLocation(data.scorings, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
      case 14: // Markup
        break;
      case 15:
        if (customMetricsByLocation(data.survey_metrics, 'at_the_end').length === 0) {
          needForward = true;
        }
        break;
    }

    [ newStep, needForward ] = this.checkLastSteps(
      this, newStep, needForward, 16, data, result, projectId, antiFraud
    );
    if (!newStep) {
      return;
    }

    if (needForward) {
      this.forward(newStep, data, result, projectId, antiFraud);
    } else {
      this.updateStep(newStep);
    }
  }

  nextTool(index, allTools, goForward) {
    this.nextItem(index, allTools.length, this.updateToolIndex, goForward);
  }

  nextConcept(index, fivePtConcepts, goForward) {
    this.nextItem(index, fivePtConcepts.length, this.updateConceptIndex, goForward);
  }

  nextConceptStep(conceptStep, conceptIndex, concept, data, goForward) {
    let nextStep = conceptStep + 1, needForward = false;

    switch (nextStep) {
      case 1: // Five-point scales
        if (!defaultScorings(data.scorings).length) {
          needForward = true;
        }
        break;
      case 2: // Attribute Ratings
        if (!data.attribute_ratings.length) {
          needForward = true;
        }
        break;
      case 3: //Next concept
        if (conceptIndex === data.five_pt_concepts.length - 1) {
          this.updateConceptIndex(0);
          goForward();
          return;
        }
        this.updateConceptIndex(conceptIndex + 1);
        nextStep = 0;
        break;
    }

    if (needForward) {
      this.nextConceptStep(nextStep, conceptIndex, concept, data, goForward);
    } else {
      this.updateConceptStep(nextStep);
    }
  }

  nextConceptStepCustomMetrics(conceptIndex, concept, data, goForward) {
    const nextConcept = (
      conceptIndex === data.five_pt_concepts.length - 1 ? {} : data.five_pt_concepts[conceptIndex + 1]
    );
    const allowedNextConcept = !!find(
      data.custom_metrics_concepts || [],
      (el) => el.confirmit_concept_id === nextConcept.confirmit_concept_id
    );

    if (allowedNextConcept) {
      this.updateConceptIndex(conceptIndex + 1);
    } else {
      goForward();
    }
  }
}
export default Forward;
