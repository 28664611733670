import React from 'react';

const LandingReview = () => {
  const reviewList = [
    { type: 'message',
      text: '“People are always shocked when I tell them I only pay $25! I have gotten a lot of friends to sign up with ' +
        'Visible because they were also fed up with the big guys.”',
      author: 'Name A, Visible member',
      position: 'left',
      color: 'mint',
      icon: 'emoji-4'
    },
    { type: 'logo',
      logo: 'forbes-logo'
    },
    { type: 'message',
      text: '“I love you guys and what you do. I have helped well over 30 people switch at this point.”',
      author: 'Marco C, Visible member',
      position: 'right',
      color: 'peach',
      icon: 'emoji-5'
    }
  ];

  return (
    <section className="landing-review">
      <h3 className="landing-review_title">
        They can vouch for us.
      </h3>
      <ul className="landing-review_list">
        {reviewList.map((review, index) => {
          return (
            <li className="landing-review_item" key={ `step-${index}` }>
              {review.type === 'logo' && (
                <div className="landing-review_logo">
                  <svg className={ `icon -${review.logo}` }>
                    <use xlinkHref={ `#svg_${review.logo}` } />
                  </svg>
                </div>
              )}
              {review.type === 'message' && (
                <div className={ `landing-review_message -${review.position}` }>
                  <div className={ `landing-review_avatar -${review.color}` }>
                    <svg className={ `icon -${review.icon}` }>
                      <use xlinkHref={ `#svg_${review.icon}` } />
                    </svg>
                  </div>
                  <div className="landing-review_plate">
                    <div className="landing-review_text">{review.text}</div>
                    <div className="landing-review_author">{review.author}</div>
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>

    </section>
  );
};

export default LandingReview;
