import React from 'react';
import { map } from "lodash";

const HiddenFields = ({ options, singles }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ '1' } />

      {map(singles, (bx, index) => (
        <CommonMetricFields key={ index } metric={ bx } />
      ))}
    </>
  );
};

const CommonMetricFields = ({ metric }) => {
  return (
    <>
      <input type="hidden" name="project[express_metrics][][id]" value={ metric.id || '' } />
      <input type="hidden" name="project[express_metrics][][kind]" value={ metric.kind } />
      <input type="hidden" name="project[express_metrics][][enabled]" value={ metric.enabled.toString() } />
      <input type="hidden" name="project[express_metrics][][default_metric_id]" value={ metric.default_metric_id } />
    </>
  );
};

export default HiddenFields;
