import React, { useContext, useState, useCallback, useRef } from 'react';
import SmoothScroll from 'smooth-scroll';
import { map, min, find, findIndex, filter } from 'lodash';
import classnames from 'classnames';
import { Header } from '../common/header';
import { Tabs, Tab, TabContext } from '../common/tabs';
import { PlanDataProvider } from '../common/contexts';
import {
  getCurrentLine, setHashData, getHashData,
  getLinesCount, setHashDataForTemplatePlan,
  getCustomBuiltDataFromCurrentLine, increaseVariable
} from '../common/statistics';
import Modal from '../common/modal';
import AllPlansIncludeWithModal from '../common/allPlansIncludedWithModal';
import ButtonMore from '../common/buttonMore';
import renderRawHtml from '../common/render_raw_html';
import { preparePopupData, scrollTop } from '../common/helper';
import PlanPerk from '../common/planPerk';

const statisticPlansKey = {
  6: 'planCustomizePlanEntertainmentClickedCount',
  7: 'planCustomizePlanGamingClickedCount',
  8: 'planCustomizePlanShoppingClickedCount'
};

const OurBestPlans = ({
  plans, corePlans, openDetailsModal, onSelect,
  onCustomize, onNavigate, onSeeAllPlansClick
}) => {
  const { setTab } = useContext(TabContext);
  const navigate = () => setTab('buildYourOwn');
  const onBuildYouOwn = () => {
    const count = getHashData(`lines.${getCurrentLine()}.planBuildOwnLinkClickedCount`);
    setHashData(`lines.${getCurrentLine()}.planBuildOwnLinkClickedCount`, count + 1);
    if (onNavigate) {
      onNavigate();
    }
    navigate();
  };

  const onCustomizeCurrentPlan = (plan) => () => {
    const key = statisticPlansKey[plan.id];
    if (key) {
      const count = getHashData(`lines.${getCurrentLine()}.${key}`);
      setHashData(`lines.${getCurrentLine()}.${key}`, count + 1);
    }
    onCustomize(plan);
    setTimeout(navigate, 0);
  };

  const minimumPrice = min(map(corePlans, (el) => el.priceTitle));

  return (<div className="best-plans">
    <div className="best-plans_grid">
      {map(plans, (plan, idx) => (<div className="best-plans_grid-item" key={ `best-plans-${idx}` }>
        <div className="plate -border">
          <div className="best-plan">
            <div className="best-plan_top">
              <div className="best-plan_top-grid">
                <div className="best-plan_top-grid-item">
                  <h4 className="best-plan_title" { ...renderRawHtml(plan.title) } />
                  {plan.popular && <div className="best-plan_popular-label">Most popular</div>}
                </div>
                <div className="best-plan_top-grid-item">
                  <div className="best-plan_price">${ plan.priceTitle }/mo</div>
                  <div className="best-plan_price-note">+ taxes & fees</div>
                </div>
              </div>
            </div>
            <div className="best-plan_content">
              <div className="plate -gray">
                <div className="best-plan_content-section">
                  <h4 className="best-plan_content-section-title">
                    { plan.core.title }
                  </h4>
                  <div className="best-plan_content-section-descr">
                    { plan.core.description }
                  </div>
                </div>
                <div className="best-plan_content-section">
                  <h4 className="best-plan_content-section-title">
                    { plan.core.subtitle }
                  </h4>
                  <ul className="best-plan_core-features">
                    {
                      map(plan.core.perks, (perk, key) => (
                        <li className="best-plan_core-feature-item" key={ `best-plans-core-perks-${key}` }>
                          <div className="best-plan_core-feature-icon">
                            <svg className={ `icon -${perk.icon}` }>
                              <use xlinkHref={ `#svg_${perk.icon}` } />
                            </svg>
                          </div>
                          <div>{perk.title}</div>
                        </li>)
                      )
                    }
                  </ul>
                  <div className="best-plan_more">
                    <ButtonMore
                      onClickMore={
                        () => openDetailsModal({
                          title: plan.core.title,
                          description: plan.core.description,
                          descriptions: map(plan.core.descriptions, ({ title, description }) => ({ title, description }))
                        }
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {
                !!plan?.core?.includedTitle &&
                <div className="build-own-plan_perk-placeholder -prebuilt">
                  {
                    !!plan.core.includedTitle &&
                    <div className="title" { ...renderRawHtml(plan.core.includedTitle) } />
                  }
                  {
                    !!plan.core.includedDescription &&
                    <div className="description" { ...renderRawHtml(plan.core.includedDescription) } />
                  }
                </div>
              }
              <div className="best-plan_perks">
                { map(plan.perks, (perk, key) => (
                  <div className="best-plan_perks-item" key={ `best-plans-perks-${key}` }>
                    <div className="plate -gray">
                      <PlanPerk perk={ perk } openDetailsModal={ openDetailsModal }  />
                    </div>
                  </div>
                )) }
              </div>
            </div>
            <div className="best-plan_buttons">
              <div className="best-plan_button">
                <button className="button -bordered -full-width" onClick={ () => onSelect(plan) }>Select</button>
              </div>
              <div className="best-plan_button">
                <button className="button-link -md" onClick={ onCustomizeCurrentPlan(plan) }>
                  Customize this plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>)) }
    </div>
    <div className="best-plans_grid -center">
      <div className="best-plans_grid-item -width-1-3">
        <div className="plate -border -button" onClick={ onBuildYouOwn }>
          <div className="best-plans_button-grid">
            <div className="best-plans_button-grid-item -text">
              <h4 className="plate_title best-plans_button-title">Want to build your own?</h4>
              <div className="best-plans_button-descr">
                <b>Starting at ${minimumPrice}</b>, you can customize your own plan.
              </div>
            </div>
            <div className="best-plans_button-grid-item">
              <svg className={ `icon -arrow best-plans_button-icon` }>
                <use xlinkHref={ `#svg_arrow` } />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="back-button_wrapper">
      <div className="plate -border -button" onClick={ onSeeAllPlansClick }>
        <div className="back-button">
          See all plans
          <svg className="icon -more">
            <use xlinkHref={ `#svg_caret` } />
          </svg>
        </div>
      </div>
    </div>
  </div>);
};

const RenderIncludedPerkPage = (
  {
    inModal, handleBackClick, perkGroups,
    isPerkChecked, onIncludedPerkClick, openDetailsModal
  }
) => (
  <div className="build-own_section">
    {
      !inModal &&
      <>
        <div className="build-own_back">
          <div
            className="build-own_back-link"
            onClick={ handleBackClick }
          >
            <svg className="icon -back">
              <use xlinkHref={ `#svg_caret` } />
            </svg>
            Back
          </div>
        </div>
        <div className="build-own_section-title-block">
          <h3 className="build-own_section-title">Select your included add-on.</h3>
          <div className="build-own_section-subtitle">Get one perk on us, then add more for $10/mo each.</div>
        </div>
      </>
    }
    <div className={ classnames("build-own_perks-groups", { '-in-modal': inModal }) }>
      {
        map(perkGroups, ({ title, perks }, key) => (
          <div className="build-own_perks-group" key={ `perks-group-${key}` }>
            <h4 className="build-own_perks-group-title">{title}</h4>
            <div className="build-own_grid">
              {
                map(perks, (perk, idx) => (
                  <div className="build-own_grid-item" key={ `perks-group-perk-${idx}` }>
                    <label className={ `plate -border -label ${isPerkChecked(perk.id) ? '-active' : ''}` }>
                      <div className="radio-plate">
                        <div className="radio -in-plate">
                          <input
                            type="radio"
                            name="perks"
                            checked={ isPerkChecked(perk.id) }
                            onChange={ onIncludedPerkClick(perk)
                            }
                          />
                          <div className="radio_icon" />
                        </div>
                        <PlanPerk
                          perk={ perk }
                          openDetailsModal={ openDetailsModal }
                        >
                          <div className="plan-perk_price">${perk.price}/mo value</div>
                        </PlanPerk>
                      </div>
                    </label>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  </div>
);

const BuildYourOwnPlan = ({
  perkGroups,
  plans,
  initialCore,
  initialIncludedPerks,
  initialAdditionalPerks,
  openDetailsModal,
  onSelect,
  onSeeAllPlansClick,
  scrollToTabHead,
  pricePerPerk
}) => {
  const [ selectedPlan, setSelectedPlan ] = useState(() => {
    return find(plans, (plan) => plan.core.id === initialCore?.id) || null;
  });
  const [ selectedIncludedPerks, setSelectedIncludedPerks ] = useState(initialIncludedPerks || []);
  const [ selectedAdditionalPerks, setSelectedAdditionalPerks ] = useState(initialAdditionalPerks || []);
  const [ temporarySelectedIncludedPerks, setTemporarySelectedIncludedPerks ] = useState([]);
  const [ temporarySelectedAdditionalPerks, setTemporarySelectedAdditionalPerks ] = useState([]);
  const [ selectPerkStep, setSelectPerkStep ] = useState(0);
  const [ editMode ] = useState(() => (!!(initialIncludedPerks || []).length));
  const [ changeIncludedPerkInModal, setChangeIncludedPerkInModal ] = useState(false);

  const isPerkChecked = (list, perkId) => {
    return !!find(list, (perk) => perk.id === perkId);
  };

  const isCoreChecked = useCallback(
    (coreId) => selectedPlan?.core.id === coreId,
    [ selectedPlan?.core.id ]
  );

  const onIncludedPerkClick = (perk) => () => {
    setSelectedIncludedPerks([ perk ]);
  };

  const onIncludedModalPerkClick = (perk) => () => {
    setTemporarySelectedIncludedPerks([ perk ]);
  };

  const onAdditionalPerkClick = (perk) => () => {
    const checked = isPerkChecked(selectedAdditionalPerks, perk.id);
    const list = [ ...selectedAdditionalPerks ];
    if (checked) {
      const index = findIndex(list, (el) => el.id === perk.id);
      list.splice(index, 1);
    } else {
      list.push(perk);
    }
    setSelectedAdditionalPerks(list);
  };

  const onCoreChange = (plan) => () => {
    setSelectedPlan(plan);
  };

  const classToToggle = "change-perk-step";
  const bodyEl = document.body || document.querySelector('body');

  const onContinue = () => {
    if (bodyEl.classList.contains(classToToggle)) {
      bodyEl.classList.remove(classToToggle);
    }
    onSelect({
      plan: selectedPlan,
      perks: selectedPlan?.noPerks ? [] : selectedIncludedPerks,
      perksAdditional: selectedPlan?.noPerks ? [] : selectedAdditionalPerks
    });
  };

  const updateStep = (stepIndex) => {
    setSelectPerkStep(stepIndex);
    if (stepIndex) {
      if (!bodyEl.classList.contains(classToToggle)) {
        bodyEl.classList.add(classToToggle);
      }
    } else if (bodyEl.classList.contains(classToToggle)) {
      bodyEl.classList.remove(classToToggle);
    }
  };

  const actionsBeforeSelectingAdditionalAddons = () => {
    setTemporarySelectedAdditionalPerks(selectedAdditionalPerks);
    const newArray = filter(
      selectedAdditionalPerks,
      (perk) => perk.id !== selectedIncludedPerks[0]?.id
    );
    setSelectedAdditionalPerks(newArray);
  };

  const handleFinishIncludedAddOnSelection = () => {
    updateStep(2);
    scrollToTabHead();
    actionsBeforeSelectingAdditionalAddons();
  };

  const handleFinishAdditionalPerksSelection = () => {
    onContinue();
  };

  const onNext = () => {
    updateStep(2);
    scrollTop();
    actionsBeforeSelectingAdditionalAddons();
  };

  const actionsToOpenPageToSelectIncludedAddon = () => {
    setTemporarySelectedIncludedPerks(selectedIncludedPerks);
  };

  const updatesToSelectIncludedAddon = () => {
    actionsToOpenPageToSelectIncludedAddon();
    scrollTop();
  };

  const handleToChooseIncludedAddOn = () => {
    updatesToSelectIncludedAddon();
    updateStep(1);
  };

  const handleChangeIncludedAddon = () => {
    increaseVariable("planClickChangeAddOnCount", getCurrentLine());
    actionsToOpenPageToSelectIncludedAddon();
    setChangeIncludedPerkInModal(true);
  };

  const handleBackClick = (setSelectedList, tempList) => () => {
    setSelectedList(tempList);
    let newStep;
    if (editMode) {
      newStep = 0;
    } else {
      newStep = selectPerkStep - 1 >= 0 ? selectPerkStep - 1 : 0;
    }
    updateStep(newStep);
    newStep ? scrollTop() : scrollToTabHead();
  };

  const handleIncludedPerkPageBackClick = handleBackClick(
    setSelectedIncludedPerks,
    temporarySelectedIncludedPerks
  );

  const handleCloseChangeIncludedPerkInModal = () => {
    setChangeIncludedPerkInModal(false);
  };

  const handleSaveAndClose = () => {
    setSelectedIncludedPerks(temporarySelectedIncludedPerks);
    setChangeIncludedPerkInModal(false);
  };

  const planPrice = (plan) => (
    (plan?.price || 0) +
    selectedAdditionalPerks.length * (pricePerPerk || 0) * (plan?.noPerks ? 0 : 1)
  );

  const additionalPerkDisabled = (perk) => (selectedIncludedPerks[0]?.id === perk.id);

  const noPerksNeedToSelect = selectedPlan?.noPerks;
  const needToSelectPerks = !selectedIncludedPerks.length && !noPerksNeedToSelect;
  const hashForMainContinueButton = {};
  if (noPerksNeedToSelect) {
    hashForMainContinueButton.title = 'Continue';
    hashForMainContinueButton.handleFunc = onContinue;
  } else if (needToSelectPerks) {
    hashForMainContinueButton.title = 'Choose your add-on';
    hashForMainContinueButton.handleFunc = handleToChooseIncludedAddOn;
  } else {
    hashForMainContinueButton.title = 'Next';
    hashForMainContinueButton.handleFunc = onNext;
    hashForMainContinueButton.buttonKlass = '-custom-next-button';
  }

  return (
    <div className="build-own">
      <div className="build-own_sections">
        {
          !selectPerkStep &&
          <div className="build-own_section">
            <div className="build-own_section-title-block">
              <h3 className="build-own_section-title">Start with a network option.</h3>
            </div>
            <div className="build-own_grid">
              {
                map(plans, (plan, key) => (
                  <div className="build-own_grid-item" key={ `build-own-core-perks-${key}` }>
                    <label className={ `plate -border -label ${isCoreChecked(plan.core.id) ? '-active' : ''}` }>
                      <div className="radio-plate">
                        <div className="radio -in-plate">
                          <input name="core" type="radio" checked={ isCoreChecked(plan.core.id) } onChange={ onCoreChange(plan) } />
                          <div className="radio_icon" />
                        </div>
                        <div className="build-own-plan">
                          <div className="build-own-plan_top">
                            <div className="build-own-plan_top-grid">
                              <div className="build-own-plan_top-grid-item">
                                <h4 className="build-own-plan_title">{plan.name}</h4>
                              </div>
                              <div className="build-own-plan_top-grid-item">
                                <div className="build-own-plan_price">${ planPrice(plan) }/mo</div>
                              </div>
                            </div>
                            <div className="build-own-plan_descr">{ plan.core.shortDescription }</div>
                          </div>
                          <div className="build-own-plan_content">
                            <h4 className="build-own-plan_content-title">
                              { plan.core.subtitle }
                            </h4>
                            <ul className="build-own-plan_features">
                              {
                                map(plan.core.perks, (perk, key) => (
                                  <li className="build-own-plan_feature-item" key={ `build-own-perks-${key}` }>
                                    <div className="build-own-plan_feature-icon">
                                      <svg className={ `icon -${perk.icon}` }>
                                        <use xlinkHref={ `#svg_${perk.icon}` } />
                                      </svg>
                                    </div>
                                    <div>{perk.title}</div>
                                  </li>
                                ))
                              }
                            </ul>
                            <div className="build-own-plan_more">
                              <ButtonMore
                                onClickMore={
                                  () => openDetailsModal({
                                    title: plan.core.title,
                                    description: plan.core.description,
                                    descriptions: map(plan.core.descriptions, ({ title, description }) => ({ title, description }))
                                  }
                                  )
                                }
                              />
                            </div>
                          </div>
                          {
                            !plan.noPerks &&
                            <div className="build-own-plan_bottom">
                              {
                                selectedIncludedPerks.length ? (
                                  map(selectedIncludedPerks, (perk, key) => (
                                    <div className="build-own-plan_perk" key={ `perk-${key}` }>
                                      <PlanPerk
                                        perk={ perk }
                                        openDetailsModal={ openDetailsModal }
                                        onChangeAddon={ handleChangeIncludedAddon }
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <>
                                    {
                                      !!plan?.core?.includedTitle &&
                                      <div className="build-own-plan_perk-placeholder">
                                        {
                                          !!plan.core.includedTitle &&
                                          <div className="title" { ...renderRawHtml(plan.core.includedTitle) } />
                                        }
                                        {
                                          !!plan.core.includedDescription &&
                                          <div className="description" { ...renderRawHtml(plan.core.includedDescription) } />
                                        }
                                      </div>
                                    }
                                  </>
                                )
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </label>
                  </div>
                ))
              }
            </div>
            <div className="back-button_wrapper">
              <div className="plate -border -button" onClick={ onSeeAllPlansClick }>
                <div className="back-button">
                  See all plans
                  <svg className="icon -more">
                    <use xlinkHref={ `#svg_caret` } />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        }
        {
          selectPerkStep === 1 &&
          <RenderIncludedPerkPage
            handleBackClick={ handleIncludedPerkPageBackClick }
            perkGroups={ perkGroups }
            isPerkChecked={ (perkId) => (isPerkChecked(selectedIncludedPerks, perkId)) }
            onIncludedPerkClick={ onIncludedPerkClick }
            openDetailsModal={ openDetailsModal }
          />
        }
        {
          selectPerkStep === 2 &&
          <div className="build-own_section">
            <div className="build-own_back" >
              <div
                className="build-own_back-link"
                onClick={
                  handleBackClick(
                    setSelectedAdditionalPerks,
                    temporarySelectedAdditionalPerks
                  )
                }
              >
                <svg className="icon -back">
                  <use xlinkHref={ `#svg_caret` } />
                </svg>
                Back
              </div>
            </div>
            <div className="build-own_section-title-block">
              <h3 className="build-own_section-title">Select additional add-ons for $10/mo each.</h3>
            </div>

            <div className="build-own_perks-groups">
              {
                map(perkGroups, ({ title, perks }, key) => (
                  <div className="build-own_perks-group" key={ `perks-group-${key}` }>
                    <h4 className="build-own_perks-group-title">{title}</h4>
                    <div className="build-own_grid">
                      {
                        map(perks, (perk, idx) => (
                          <div className="build-own_grid-item" key={ `perks-group-perk-${idx}` }>
                            <label className={ `plate -border -label ${isPerkChecked(selectedAdditionalPerks, perk.id) ? '-active' : ''}` }>
                              <div className="checkbox-plate">
                                <div className="checkbox -in-plate">
                                  <input
                                    type="checkbox"
                                    name="perks"
                                    checked={ isPerkChecked(selectedAdditionalPerks, perk.id) }
                                    onChange={ onAdditionalPerkClick(perk) }
                                    disabled={ additionalPerkDisabled(perk) }
                                  />
                                  <div className="checkbox_icon">
                                    <svg className="icon -checkbox">
                                      <use xlinkHref={ `#svg_checkbox` } />
                                    </svg>
                                  </div>
                                </div>
                                <PlanPerk
                                  perk={ perk }
                                  openDetailsModal={ openDetailsModal }
                                >
                                  {
                                    additionalPerkDisabled(perk) ?
                                      (
                                        <div className="plan-perk_price -greyed">
                                          Selected
                                        </div>
                                      ) :
                                      (
                                        <div className="plan-perk_price">
                                          ${pricePerPerk}/mo
                                          {' '}
                                          <span className="additional-value">
                                            (${perk.price}/mo value)
                                          </span>
                                        </div>
                                      )
                                  }
                                </PlanPerk>
                              </div>
                            </label>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        }
      </div>

      <div className="build-own_footer">
        <div className="build-own_footer-grid">
          <div className="build-own_footer-grid-item">
            <div className="build-own_footer-line-sticky">Line {getCurrentLine()}</div>
            <div className="build-own_footer-price">
              <span className="build-own_footer-price-num">${planPrice(selectedPlan)}</span>/mo
            </div>
            <div className="build-own_footer-note">Plus taxes & fees.</div>
          </div>
          <div className="build-own_footer-grid-item">
            {
              !selectPerkStep &&
                <div className="custom-footer-button">
                  <button
                    className={ classnames("button", hashForMainContinueButton.buttonKlass) }
                    disabled={ selectedPlan === null }
                    onClick={ hashForMainContinueButton.handleFunc }
                  >
                    { hashForMainContinueButton.title }
                  </button>
                </div>
            }
            {
              selectPerkStep === 1 &&
                <div className="custom-footer-button">
                  <button
                    className="button"
                    disabled={ !selectedIncludedPerks.length }
                    onClick={ handleFinishIncludedAddOnSelection }
                  >
                    Get more add-ons
                  </button>
                </div>
            }
            {
              selectPerkStep === 2 &&
                <div className="custom-footer-button">
                  <button
                    className="button"
                    onClick={ handleFinishAdditionalPerksSelection }
                  >
                    { selectedAdditionalPerks.length ? 'Continue' : 'No thanks, skip' }
                  </button>
                </div>
            }
          </div>
        </div>
      </div>
      {
        changeIncludedPerkInModal &&
        <Modal
          titleComponent={
            <div className="build-own_section-title-block">
              <h3 className="build-own_section-title">Select your included add-on.</h3>
              <div className="build-own_section-subtitle">Get one perk on us, then add more for $10/mo each.</div>
            </div>
          }
          classNameHeader="-space-between -perks-list-padding"
          classNameContent="-perks-list-padding"
          classNameDialog="-perks-selector"
          handleClose={ handleCloseChangeIncludedPerkInModal }
          showFooterButton
          footerButtonName="Save and Close"
          onFooterButtonClick={ handleSaveAndClose }
        >
          <div className="modal_content-inner">
            <RenderIncludedPerkPage
              inModal
              perkGroups={ perkGroups }
              isPerkChecked={ (perkId) => (isPerkChecked(temporarySelectedIncludedPerks, perkId))  }
              onIncludedPerkClick={ onIncludedModalPerkClick }
              openDetailsModal={ openDetailsModal }
            />
          </div>
        </Modal>
      }
    </div>
  );
};

const OverviewPage = ({ onPlanSelect, onSeeAllPlans, buildYourOwnFirst = false, pricePerPerk }) => {
  const { allPlansInclude, plans_prebuild,  plans_construct, perks, allPerksFlatten } = useContext(PlanDataProvider);
  const getLinesData = () => (getCustomBuiltDataFromCurrentLine(plans_prebuild, plans_construct, allPerksFlatten));
  const [ modalData, setModalData ] = useState(null);
  const [ initialPredefinedPlan, setInitialPredefinedPlan ] = useState(() => (getLinesData().templatePlan));
  const [ initialCore, setInitialCore ] = useState(() => (getLinesData().corePlan));
  const [ initialIncludedPerks, setInitialIncludedPerks ] = useState(() => (getLinesData().perks));
  const [ initialAdditionalPerks, setInitialAdditionalPerks ] = useState(() => (getLinesData().perksAdditional));
  const [ initialTabName ] = useState(() => {
    if (initialCore) {
      return "buildYourOwn";
    } else if (getLinesData().prebuildPlan) {
      return "bestPlans";
    }
    return null;
  });
  const [ fromWelcomeToBasic, setFromWelcomeToBasic ] = useState(null);
  const [ smoothScroll ] = useState(() => (new SmoothScroll()));
  const headRef = useRef(null);
  const editMode = (
    ((!getLinesData().prebuildPlan && getLinesData().corePlan) || initialPredefinedPlan) &&
      !!initialCore
  );
  const closeModal = () => {
    setFromWelcomeToBasic(null);
    setModalData(null);
  };
  const closeToBaseModalAndUpdateStatistic = () => {
    const line = getCurrentLine();
    const key = `lines.${line}.planBaseFromWelcomePopupCancelClickCount`;
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    closeModal();
  };
  const openModal = ({ title, description, descriptions }) => {
    setModalData(preparePopupData(title, description, descriptions));
  };

  const onBestPlanSelect = (plan) => {
    const { planId } = getHashData('lines.1');
    const linesCount = getLinesCount();
    if (linesCount !== 1 && planId === 5) {
      setFromWelcomeToBasic(plan);
    } else {
      onPlanSelect(plan);
    }
  };

  const onCustomPlanSelect = ({ plan, perks, perksAdditional }) => {
    const { planId } = getHashData('lines.1');
    const line = getCurrentLine();
    setHashData(`lines.${line}.planCustomBuilt`, true);
    setHashDataForTemplatePlan(line, initialPredefinedPlan, plan, perks);
    const selectedPlan = { ...plan, perks, perksAdditional };
    if (planId === 5) {
      setFromWelcomeToBasic(selectedPlan);
    } else {
      onPlanSelect(selectedPlan);
    }
  };

  const onCustomize = (plan) => {
    const { core, perks } = plan;
    setInitialPredefinedPlan(plan);
    setInitialCore(core);
    setInitialIncludedPerks(perks);
    setInitialAdditionalPerks(null);
    scrollToTabHead();
  };

  const resetInitial = () => {
    setInitialPredefinedPlan(null);
    setInitialCore(null);
    setInitialIncludedPerks(null);
    setInitialAdditionalPerks(null);
  };

  const onBestPlansClick = () => {
    const count = getHashData(`lines.${getCurrentLine()}.planOurBestPlansTabClickedCount`);
    setHashData(`lines.${getCurrentLine()}.planOurBestPlansTabClickedCount`, count + 1);
    resetInitial();
  };

  const onBuildYourOwnClick = () => {
    const count = getHashData(`lines.${getCurrentLine()}.planBuildYourOwnTabClickedCount`);
    setHashData(`lines.${getCurrentLine()}.planBuildYourOwnTabClickedCount`, count + 1);
  };

  const scrollToTabHead = () => {
    const scrollOptions = { speed: 300, offset: 20 };
    smoothScroll.animateScroll(headRef.current, null, scrollOptions);
  };

  const onConfirm = (key) => () => {
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    onPlanSelect(fromWelcomeToBasic, false, true);
    closeModal();
  };

  const onSeeAllPlansClick = () => {
    const currentLine = getCurrentLine();
    const key = `lines.${currentLine}.planClickSeeAllPlansNonWelcomePlansCount`;
    const value = getHashData(key);
    setHashData(key, value + 1);
    onSeeAllPlans();
  };

  return (
    <div className="content">
      <Header />
      <div className="builder">
        <div className="inner-content -percent-desktop">
          <div className="builder_top">
            <div className="builder_top-col">
              <h2 className="builder_title">
                {
                  editMode ?
                    <>Edit your plan.</> :
                    <>Pick your perfect plan.</>
                }
              </h2>
              {
                !editMode &&
                <div className="builder_subtitle">
                  Choose from our best plans or build your own to suit your needs.
                </div>
              }
            </div>
            <div className="builder_top-col">
              <AllPlansIncludeWithModal plans={ allPlansInclude } openDetailsModal={ openModal } />
            </div>
          </div>
          <Tabs ref={ headRef } initialTabName={ initialTabName }>
            <Tab title="Our best plans" name="bestPlans" weight={ 2 } onClick={ onBestPlansClick }>
              <OurBestPlans
                plans={ plans_prebuild }
                corePlans={ plans_construct }
                openDetailsModal={ openModal }
                onSelect={ onBestPlanSelect }
                onCustomize={ onCustomize }
                onNavigate={ scrollToTabHead }
                onSeeAllPlansClick={ onSeeAllPlansClick }
              />
            </Tab>
            <Tab title="Build your own" name="buildYourOwn" weight={ buildYourOwnFirst ? 1 : 3 } onClick={ onBuildYourOwnClick }>
              <BuildYourOwnPlan
                initialCore={ initialCore }
                initialIncludedPerks={ initialIncludedPerks }
                initialAdditionalPerks={ initialAdditionalPerks }
                perkGroups={ perks }
                plans={ plans_construct }
                openDetailsModal={ openModal }
                onSelect={ onCustomPlanSelect }
                scrollToTabHead={ scrollToTabHead }
                onSeeAllPlansClick={ onSeeAllPlansClick }
                pricePerPerk={ pricePerPerk }
              />
            </Tab>
          </Tabs>
        </div>
      </div>
      {
        !!fromWelcomeToBasic &&
        <Modal title="You'll need to choose new plans for each of your phone lines." handleClose={ closeToBaseModalAndUpdateStatistic }>
          <div className="modal_inner">
            <div className="modal_empty-content" />
            <div className="modal-actions">
              <button
                className="button"
                onClick={ onConfirm(`lines.${getCurrentLine()}.planBaseFromWelcomePopupContinueClickCount`) }
              >
                Continue
              </button>
              <button className="button -invert" onClick={ closeToBaseModalAndUpdateStatistic }>Cancel</button>
            </div>
          </div>
        </Modal>}
      {
        modalData &&
        <Modal title={ modalData.title } handleClose={ closeModal } showFooterButton>
          <div className="modal_content-inner" { ...renderRawHtml(modalData.description) } />
        </Modal>
      }
    </div>
  );
};

export default OverviewPage;
