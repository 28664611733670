import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';

const TagsDropdown = ({
  onSelectTag, onClearTags, onCreateTag, tagType, childMode, tags, selectedTags, disabled, allowCreate = true
}) => {
  const [ selectedOptions, setSelectedOptions ] = useState(selectedTags);
  const [ isLoading, setIsLoading ] = useState(false);
  useEffect(() => {
    if (selectedTags) {
      setSelectedOptions(selectedTags);
      setIsLoading(false);
    }
  }, [ selectedTags ]);
  const exists = (data) => filter(data, (item) => !item.value);

  const cleared = (options) => (!exists(options).length);

  const handleSelect = (data, selected) => {
    setIsLoading(true);
    switch (selected.action) {
      case 'clear':
        onClearTags(selected.removedValues);
        break;
      case "remove-value":
        onClearTags([ selected.removedValue ]);
        break;
      case "select-option":
        onSelectTag(selected.option.value);
        break;
      default:
        console.log(`Unsupported action: ${selected.action}`);
    }
  };

  const handleCreate = (value) => {
    onCreateTag(value, tagType, childMode);
  };

  const onKeyDown = (e) => {
    // catch the code of the key pressed
    if (e.keyCode === 8 && !e.target.value) {
      e.preventDefault();
    }
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => `${option.label}`,
    trim: true
  };

  const selectStyles = {
    control: (styles) => ({ ...styles, borderRadius: 20, fontSize: "13px" }),
    option: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93" }),
    menu: (provided) => ({ ...provided, fontSize: "13px", zIndex: 9999, color: "#787F93" }),
    multiValue: (styles, { data }) => (
      {
        ...styles, fontSize: "13px", borderRadius: 70, color: "#787F93",
        background: data.value === 0 ? "#FFF" : "#EFF4F5",
        paddingRight: data.value === 0 ? "5px" : 0
      }
    ),
    multiValueLabel: (styles) => ({ ...styles, fontSize: "13px", color: "#787F93", whiteSpace: "normal" }),
    valueContainer: (styles) => ({ ...styles, fontSize: "13px", paddingLeft: "5px" }),
    multiValueRemove: (styles, state) => {
      return state.data.value === 0 ? { ...styles, display: 'none' } : styles;
    }
    // indicatorSeparator: (styles) => ({ ...styles, display: 'none' })
    // indicatorsContainer: (styles) => ({ ...styles, height: '38px' })
  };

  const isValidNewOption = allowCreate ? undefined : () => false;
  const placeholder = allowCreate ? "Select or Create" : "Select";

  return (
    <div className="react-select-wrapper">
      <CreatableSelect
        className="react-select-container"
        classNamePrefix="react-select"
        options={ tags }
        placeholder={ placeholder }
        value={ selectedOptions }
        onChange={ handleSelect }
        onCreateOption={ handleCreate }
        isLoading={ isLoading }
        isDisabled={ disabled }
        isSearchable
        isMulti
        isClearable={ cleared(selectedOptions) }
        styles={ selectStyles }
        autosize={ false }
        maxMenuHeight={ 150 }
        filterOption={ createFilter(filterConfig) }
        onKeyDown={ onKeyDown }
        isValidNewOption={ isValidNewOption }
      />
    </div>
  );
};

export default TagsDropdown;
