import React, { useContext } from 'react';
import { map } from 'lodash';
import { LaunchContext } from '../../contexts';
import InfoToolTip from '../../../common/infoToolTip';
import DetailsAndConditions from './detailsAndConditions';

const NSizeForClaims = () => {
  const {
    sizeData, nSizes, nSize, setNSize, defaultNSize, textItemName,
    canSelectRespondentsData
  } = useContext(LaunchContext);
  const handleOnChange = (event) => {
    setNSize(event.target.value);
  };
  return (
    <div className="form_section -flexible -restrict-width">
      <div className="form-pay_price-grid -grow-with-max">
        <div className="form-pay_price-grid-item">
          <div className="form-group">
            <label className="form-label">
              Sample size:
              <InfoToolTip
                className="-force-margin"
                remote
                text={
                  <>
                    { defaultNSize } ensures an optimal number of exposures
                    {' '}
                    per { textItemName } for the MaxDiff exercise.
                  </>
                }
                position="top-right"
              />
            </label>
            {
              canSelectRespondentsData ? (
                <select
                  className="form-field form-pay_size-select"
                  value={ nSize }
                  onChange={ handleOnChange }
                >
                  {
                    map(nSizes, (option) => (
                      <option key={ option.id } value={ option.id }>{ option.text }</option>
                    ))
                  }
                </select>
              ) : (
                <div className="form-pay_price -nsize">
                  { sizeData.text }
                </div>
              )
            }
          </div>
        </div>
        <div className="form-pay_price-grid-item"><DetailsAndConditions /></div>
      </div>
    </div>
  );
};

export default NSizeForClaims;
