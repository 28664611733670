import { compact } from "lodash";
import { saveUserDataBase } from '../../common/logic/respondent_data.js';

const saveUserData = (url, status, result, data) => {
  const moreParams = {
    thought_bubble_answers_attributes: result.thought_bubbles,
    preference_answers_attributes: result.preference_answers,
    brand_group: result.brand_group
  };

  const userBrands = compact(result.unaided_brands);
  const moreSurveyData = {};
  if (userBrands.length > 0) {
    const texts = userBrands.join('. ');
    moreParams.text_answers_attributes = [ { answer: texts } ];
    moreSurveyData.BRAND_F2 = texts;
  }

  saveUserDataBase(url, status, result, data, moreParams, moreSurveyData);
};

export default saveUserData;
