import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { map, compact } from 'lodash';
import { baseContext } from "../../contexts";
import ValuesSlider from '../valuesSlider';
import DownloadPulseReport from "../../exports/downloadPulseReport";
import OverviewTitle from '../../common/overview-title';
import CircleChart from './cirleChart';
import Legend from './legend';

const SpecialView = ({ overview }) => {
  const { isPreview } = useContext(baseContext);
  const projectId = overview.id;
  const projectDate = overview.overview_date;
  const openEnds = overview.data.open_ends;

  const metricData = (hash, key) => (
    hash ? hash[key]?.combo_integer : 0
  );

  const total = overview.data?.base_n || 0;
  const mmWaveTotal = (overview.data?.filtered || {})['mmWave']?.base_n || 0;
  const cBandTotal = (overview.data?.filtered || {})['c-band']?.base_n || 0;

  const satisfactionHash = {
    title: 'Satisfaction',
    total: overview.data?.satisfaction?.top2box?.total || 0,
    basePercent: metricData(overview.data?.satisfaction, 'top2box'),
    "mmWave": metricData(((overview.data?.filtered || {})['mmWave'] || {}).satisfaction, 'top2box'),
    "c-band": metricData(((overview.data?.filtered || {})['c-band'] || {}).satisfaction, 'top2box')
  };

  const priceHash = {
    title: 'Worth the Price',
    total: overview.data?.values_me?.total || 0,
    basePercent: metricData(overview.data, 'values_me'),
    "mmWave": metricData((overview.data?.filtered || {})['mmWave'], 'values_me'),
    "c-band": metricData((overview.data?.filtered || {})['c-band'], 'values_me')
  };

  const stayHash = {
    title: 'Intent to Stay',
    total: overview.data?.staying?.total || 0,
    basePercent: metricData(overview.data, 'staying'),
    "mmWave": metricData((overview.data?.filtered || {})['mmWave'], 'staying'),
    "c-band": metricData((overview.data?.filtered || {})['c-band'], 'staying')
  };

  const issuesHash = {
    title: 'Issues Experienced',
    total: overview.data?.issues?.total || 0,
    data: overview.data ? overview.data?.issues : null,
    filtered: compact(
      map([ 'mmWave', 'c-band' ], (item) => (
        overview.data?.filtered ?
          overview.data?.filtered[item]?.issues :
          null
      ))
    )
  };

  const filtersPresent = !!(overview.data?.filtered || {})['mmWave'];

  return (
    <OverviewTitle overview={ overview } >
      <div className="section_body">
        <div className="active-report_grid -with-top-margin">
          <div className="active-report_grid-col">
            <div className="circle-title">
              <h3>{ satisfactionHash.title }</h3>
              <span>(T2B on 5pt scale, n={satisfactionHash.total})</span>
            </div>
            <div className="circle-content">
              <CircleChart
                multi={ filtersPresent }
                basePercent={ satisfactionHash.basePercent }
                secondPercent={ satisfactionHash.mmWave }
                thirdPercent={ satisfactionHash['c-band'] }
              />
            </div>
          </div>
          <div className="active-report_grid-col">
            <div className="circle-title">
              <h3>{ priceHash.title }</h3>
              <span>(% Agree, n={priceHash.total})</span>
            </div>
            <div className="circle-content">
              <CircleChart
                multi={ filtersPresent }
                basePercent={ priceHash.basePercent }
                secondPercent={ priceHash.mmWave }
                thirdPercent={ priceHash['c-band'] }
              />
            </div>
          </div>
          <div className="active-report_grid-col">
            <div className="circle-title">
              <h3>{ stayHash.title }</h3>
              <span>(% Agree, n={stayHash.total})</span>
            </div>
            <div className="circle-content">
              <CircleChart
                multi={ filtersPresent }
                basePercent={ stayHash.basePercent }
                secondPercent={ stayHash.mmWave }
                thirdPercent={ stayHash['c-band'] }
              />
            </div>
          </div>
          <div className="active-report_grid-col">
            <div className="active-report_item -centered">
              <div className="circle-title -with-line">
                <h3>{ issuesHash.title }</h3>
                <span>(n={ issuesHash.total })</span>
              </div>
              <ValuesSlider
                total={ issuesHash.total }
                data={ issuesHash.data }
                staticText={ issuesHash.staticText && issuesHash.staticText() }
                filtered={ issuesHash.filtered }
              />
            </div>
          </div>
        </div>
        <Legend
          filtersPresent={ filtersPresent }
          total={ total }
          mmWaveTotal={ mmWaveTotal }
          cBandTotal={ cBandTotal }
        />
      </div>
      <div className="section_footer">
        {
          !isPreview &&
          <NavLink to="/pulse" className="button -sm -bordered">
            Explore More
          </NavLink>
        }
        <DownloadPulseReport
          projectId={ projectId }
          openEnds={ openEnds }
          projectDate={ projectDate }
        />
      </div>
    </OverviewTitle>
  );
};

export default SpecialView;
