import React, { useState, useRef, useEffect } from 'react';
import { find } from "lodash";
import classnames from 'classnames';
import Email from "../common/screens/email";
import MobileNumber from "../common/screens/mobileNumber";
import Password from "../common/screens/password";
import PersonalInfo from "../common/screens/personalInfo";
import LoadingPage from "../common/screens/loadingPage";
import Benefits from "../common/screens/benefits";
import IdCheck from "../common/screens/idCheck";
import PPECashbackCategory from "../common/screens/ppeCashbackCategory";
import PPEAutoReload from "../common/screens/ppeAutoreload";
import BaseIntent from "../common/screens/baseIntent";
import BaseAddCard from "../common/screens/baseAddCard";
import { GlobalContext } from '../common/contexts/appContexts';
import Passkey from '../common/screens/passkey';
import ShoppingIntentAndNotifications from '../common/screens/shoppingIntentAndNotifications';
import Welcome from '../common/screens/welcome';
import FaceIdNotification from '../common/screens/faceIdNotification';
import CardToWallet from '../common/screens/cardToWallet';
import Celebration from '../common/screens/celebration';
import CloseFollowUp from '../common/components/closeFollowUp';
import Splash from '../common/screens/splash';
import { addSeenScreenNames } from '../common/helpers/stepsHelper';
import CompletedModal from "../common/components/completedModal";
import { fakeData } from './onboarding/fakeData';
import { initialData, sendData, finishingData } from './statistics';
import IntroSetup from './onboarding/components/introSetup';

const OnboardingBauApp = () => {
  const [ stepsHistory, setStepsHistory ] = useState([]);
  const [ baseInnerSteps, setBaseInnerSteps ] = useState([]);
  const [ innerStep, setInnerStep ] = useState(null);
  const [ showFollowUpModal, setShowFollowUpModal ] = useState(false);
  const [ showCompletedModal, setShowCompletedModal ] = useState(false);

  const currentDataRef = useRef(initialData());
  const mergeData = (newHash) => {
    currentDataRef.current = { ...currentDataRef.current, ...newHash };
  };

  const checkIfAllSetup = () => (
    currentDataRef.current.cashbackCategoryChosen &&
      currentDataRef.current.autoReload &&
      currentDataRef.current.cardIssued &&
      currentDataRef.current.pinSet
  );

  const goAway = (addition = {}) => {
    mergeData(addition || {});
    setShowCompletedModal(true);
    setStep(null);
  };

  const [ stepsOrderList ] = useState(() => (
    [
      {
        step: 'splash',
        nextStep: () => ('welcome'),
        skipStep: null
      },
      {
        step: 'welcome',
        nextStep: () => ('email'),
        skipStep: null
      },
      {
        step: 'email',
        nextStep: () => ('mobile_number'),
        skipStep: null
      },
      {
        step: 'mobile_number',
        nextStep: () => ('passkey'),
        skipStep: null
      },
      {
        step: 'passkey',
        nextStep: () => ('personal_info'),
        skipStep: () => ('password')
      },
      {
        step: 'password',
        nextStep: () => ('personal_info'),
        skipStep: null
      },
      {
        step: 'personal_info',
        nextStep: () => ('creating_loading'),
        skipStep: null
      },
      {
        step: 'creating_loading',
        nextStep: () => (currentDataRef.current.passkeySkip ? 'faceid_notification' : 'intro_setup'),
        skipStep: null
      },
      {
        step: 'faceid_notification',
        nextStep: () => ('intro_setup'),
        skipStep: null
      },
      {
        step: 'intro_setup',
        nextStep: () => ('id_check'),
        skipStep: () => ('benefits')
      },
      {
        step: 'id_check',
        nextStep: () => ('ppe_loading'),
        skipStep: () => ('base_intent')
      },
      {
        step: 'ppe_loading',
        nextStep: () => ('ppe_cashback_category'),
        skipStep: null
      },
      {
        step: 'ppe_cashback_category',
        nextStep: () => ('ppe_autoreload'),
        skipStep: () => ('ppe_autoreload')
      },
      {
        step: 'ppe_autoreload',
        nextStep: () => ('card_to_wallet'),
        skipStep: () => ('card_to_wallet')
      },
      {
        step: 'card_to_wallet',
        nextStep: () => (checkIfAllSetup() ? 'full_celebration' : 'low_celebration'),
        skipStep: () => (checkIfAllSetup() ? 'full_celebration' : 'low_celebration')
      },

      // Start-Bottom-Flow
      {
        step: 'benefits',
        nextStep: () => ('id_check'),
        skipStep: () => ('base_intent')
      },
      {
        step: 'base_intent',
        nextStep: () => ('base_add_card'),
        skipStep: () => ('base_add_card')
      },
      {
        step: 'base_add_card',
        nextStep: () => ('shopping_intent_and_notifications'),
        skipStep: () => ('shopping_intent_and_notifications')
      },
      {
        step: 'shopping_intent_and_notifications',
        nextStep: () => ('low_celebration'),
        skipStep: () => ('low_celebration')
      },
      // End-Bottom-Flow

      {
        step: 'low_celebration',
        nextStep: () => {
          goAway();
          return null;
        },
        skipStep: null
      },
      {
        step: 'full_celebration',
        nextStep: () => {
          goAway({ fullSetup: true });
          return null;
        },
        skipStep: null
      }
    ]
  ));
  const [ step, setStep ] = useState(stepsOrderList[0].step);

  useEffect(() => {
    addSeenScreenNames(currentDataRef.current, step, innerStep);
  }, [ step, innerStep ]);

  const goForward = () => {
    const currentStepInfo = find(stepsOrderList, (stepsItem) => (stepsItem.step === step));
    if (currentStepInfo?.nextStep) {
      stepsHistory.push(currentStepInfo.step);
      setStepsHistory([ ...stepsHistory ]);
      setStep(currentStepInfo?.nextStep());
      return;
    }
    setStep(null);
  };

  const goBack = () => {
    if (stepsHistory.length) {
      setStep(stepsHistory[stepsHistory.length - 1]);
      stepsHistory.splice(stepsHistory.length - 1, 1);
      setStepsHistory([ ...stepsHistory ]);
      return;
    }
    setStep(null);
  };

  const goSkip = () => {
    const currentStepInfo = find(stepsOrderList, (stepsItem) => (stepsItem.step === step));
    if (currentStepInfo?.skipStep) {
      stepsHistory.push(currentStepInfo.step);
      setStepsHistory([ ...stepsHistory ]);
      setStep(currentStepInfo?.skipStep());
      return;
    }

    setStep(null);
  };

  const handleInterruptApp = () => {
    goAway({
      interruptAppYesClick: true,
      interruptAppTopStep: step,
      interruptAppInnerStep: innerStep
    });
    setShowFollowUpModal(false);
  };

  const handleInterruptAppSkip = () => {
    mergeData({ interruptAppNoClick: true });
    setShowFollowUpModal(false);
  };

  const handleFinishStimuli = () => {
    sendData(finishingData(currentDataRef.current));
    setShowCompletedModal(false);
  };

  const globalContextValue = {
    fakeData,
    nextStep: goForward,
    prevStep: goBack,
    skipStep: goSkip,
    currentData: (currentDataRef.current),
    closeFullApp: () => {
      mergeData({ closeAppClick: true });
      setShowFollowUpModal(true);
    },
    mergeData, innerStep, setInnerStep,
    baseInnerSteps, setBaseInnerSteps
  };
  return (
    <GlobalContext.Provider value={ globalContextValue }>
      <div className={ classnames("container", { "-whole-white": !step }) }>
        { step === 'splash' && <Splash /> }
        { step === 'welcome' && <Welcome /> }
        { step === 'email' && <Email /> }
        { step === 'mobile_number' && <MobileNumber headerStep={ 1 } numberOfHeaderSteps={ 3 } /> }
        { step === 'passkey' && <Passkey headerStep={ 1 } numberOfHeaderSteps={ 3 } /> }
        { step === 'password' && <Password headerStep={ 1 } numberOfHeaderSteps={ 3 } /> }
        { step === 'personal_info' && <PersonalInfo headerStep={ 1 } numberOfHeaderSteps={ 3 } /> }
        {
          step === 'creating_loading' &&
          <LoadingPage title="Creating your PayPal account..." duration={ 3000 } delay={ 500 } />
        }
        { step === 'faceid_notification' && <FaceIdNotification /> }
        { step === 'intro_setup' && <IntroSetup headerStep={ 2 } numberOfHeaderSteps={ 3 } /> }
        { step === 'benefits' && <Benefits headerStep={ 2 } numberOfHeaderSteps={ 3 } /> }
        { step === 'id_check' && <IdCheck headerStep={ 2 } numberOfHeaderSteps={ 3 } /> }
        {
          step === 'ppe_loading' &&
          <LoadingPage
            title="Getting you ready to earn cash back and pay in stores..."
            duration={ 10000 }
            delay={ 500 }
          />
        }
        {
          step === 'ppe_cashback_category' &&
          <PPECashbackCategory headerStep={ 3 } numberOfHeaderSteps={ 3 } />
        }
        {
          step === 'ppe_autoreload' &&
          <PPEAutoReload headerStep={ 3 } numberOfHeaderSteps={ 3 } />
        }
        { step === 'base_intent' && <BaseIntent headerStep={ 3 } numberOfHeaderSteps={ 3 } /> }
        { step === 'base_add_card' && <BaseAddCard headerStep={ 3 } numberOfHeaderSteps={ 3 } /> }
        {
          step === 'shopping_intent_and_notifications' &&
          <ShoppingIntentAndNotifications headerStep={ 3 } numberOfHeaderSteps={ 3 } />
        }
        { step === 'card_to_wallet' && <CardToWallet headerStep={ 3 } numberOfHeaderSteps={ 3 } /> }
        {
          step === 'full_celebration' &&
          <Celebration
            title={ `All set, ${currentDataRef.current.firstName}! You’re ready to earn cash back, pay in stores, and more.` }
            text={ `Plus, you got your ${fakeData.cashBackBonus} cash back bonus. Happy spending.` }
          />
        }
        {
          step === 'low_celebration' &&
          <Celebration
            title={ `Welcome to PayPal, ${currentDataRef.current.firstName}!` }
            text="Time to explore all the ways you can pay, spend, and save smarter."
          />
        }
        {
          showFollowUpModal &&
          <CloseFollowUp onCloseYes={ handleInterruptApp } onCloseNo={ handleInterruptAppSkip } />
        }
        {
          showCompletedModal &&
          <CompletedModal returnSurvey={ handleFinishStimuli } />
        }
      </div>
    </GlobalContext.Provider>
  );
};

export default OnboardingBauApp;
