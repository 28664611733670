import React, { useContext, useEffect, useRef } from 'react';
import { isEmpty } from "lodash";
import cn from 'classnames';
import { selectGroup, IndexData } from "../../../common/index-context";
import TableFivePointsScales from "../../../common/components/entity-modal/table-five-points-scales";
import TableAttributeRatings from "../../../common/components/entity-modal/table-attribute-ratings";
import TablePreference from "../../../common/components/entity-modal/table-preference";
import ComparisonsLegend from "../../../common/components/comparisons-legend";
import ShelfEvaluationTable from '../../../common/components/entity-modal/shelf-evaluation-table';
import TableCustomMetrics from '../../../common/components/entity-modal/table-custom_metrics';
import ShoppingCartPerformers from '../../../common/components/entity-modal/shopping-cart-performers';
import RankingPerformers from './ranking-performers';
import TableLeftRight from './table-left-right';

const TableConcept = ({ data, current }) => {
  const shelfPresent = data.shelf_enabled || data.cart_exercise;
  const rankingEnabled = data.cart_exercise && data.cart_ranking && data.ranking[current.id].has_ranking;

  const fivePointScales = selectGroup(data, 'five_point_scale');
  const fivePointScalesPresent = !isEmpty(fivePointScales);
  const customMetrics = selectGroup(data, 'custom_metric');
  const customMetricsPresent = !isEmpty(customMetrics);
  const attributesPresent = data.left_right;
  const attributeRatings = selectGroup(data, 'package_mc_attribute_ratings');
  const attributeRatingsPresent = !isEmpty(attributeRatings);
  const preference = selectGroup(data, 'preference');
  const preferencePresent = !isEmpty(preference);

  const fpAcstAsAp = fivePointScalesPresent || customMetricsPresent || shelfPresent || preferencePresent;
  const atrAatr = attributesPresent || attributeRatingsPresent;

  const tourElement = useRef(null);
  const { getTourUseEffectArgs, averageMode } = useContext(IndexData);
  const [ callback, condition ] = getTourUseEffectArgs(
    "projectModalTable",
    tourElement
  );
  useEffect(callback, condition);

  return (
    <>
      <div
        className="concept-report_tables comparisons_grid"
        ref={ tourElement }
      >
        {
          fpAcstAsAp &&
          <>
            <div className="comparisons_col -grow">
              {
                fivePointScalesPresent &&
                <TableFivePointsScales
                  data={ data }
                  current={ current }
                  fivePointScales={ fivePointScales }
                  groupName="five_point_scale"
                  className="-long-first-cell"
                />
              }
              {
                customMetricsPresent &&
                <TableCustomMetrics
                  data={ data }
                  current={ current }
                  customMetrics={ customMetrics }
                  groupName="custom_metrics"
                  className="-long-first-cell"
                />
              }
              {
                shelfPresent &&
                <ShelfEvaluationTable
                  data={ data }
                  current={ current }
                />
              }
              {
                preferencePresent &&
                <TablePreference
                  data={ data }
                  current={ current }
                  preference={ preference }
                />
              }
            </div>
          </>
        }

        {
          atrAatr &&
          <div
            className={
              cn(
                "comparisons_col",
                {
                  '-grow': !fpAcstAsAp,
                  '-width-50': fpAcstAsAp
                }
              )
            }
          >
            { attributesPresent && <TableLeftRight current={ current } groupName="package_attributes" /> }
            {
              attributeRatingsPresent &&
              <TableAttributeRatings
                className="js-ar2"
                data={ data }
                current={ current }
                attributeRatings={ attributeRatings }
                groupName="package_attribute_ratings"
              />
            }
          </div>
        }
      </div>
      <ComparisonsLegend
        forModal
        averageMode={ averageMode }
        statTesting={ data.stat_testing }
      />

      {data.cart_exercise && <ShoppingCartPerformers data={ data } current={ current } />}

      {rankingEnabled && <RankingPerformers data={ data } current={ current } />}
    </>
  );
};

export default TableConcept;
