import React, { useContext, useEffect, useState } from 'react';
import { find, isEmpty, keys, map, filter, includes, concat, flatten, values } from "lodash";
import { baseContext } from "../context";
import { updateParams } from "../requests";
import SvgIcon from "../../../../common/components/svg-icon";
import BBPagination from "../../../../common/components/pagination";
import { keyTitle, uiTitle } from "../helpers";
import PropsToggle from "./props_toggle";
import ConceptsTableDataRow from "./concepts-table-data-row";
import DataFilters from "./data-filters/index";
import SortableTableHeader from "./sortable-table-header";
import ConceptTagsPopup from "./concept-tags-popup";

const ConceptsTable = () => {
  const {
    data, setData, setError, paramsId, conceptView,
    metricTitles, conceptGroup, statTesting,
    isAdmin, order, orderBy, page,
    allConceptsIds, selectedConcepts,
    currentCountry, currentProjectType, currentProductCategory,
    tableLoading, setTableLoading, setGlobalLoading, setSelectedConcepts,
    dataFilterIsOpen, setDataFilterIsOpen, itemName
  } = useContext(baseContext);

  const [ showPopup, setShowPopup ] = useState(false);

  const onFilterClick = () => {
    if (dataFilterIsOpen && flatten(data.data_filters.demographics).length === 0) {
      setDataFilterIsOpen(false);
    } else {
      setDataFilterIsOpen(true);
    }
  };

  const conceptsData = data.concepts_data;
  const [ currentConceptId, setCurrentConceptId ] = useState(null);
  const [ currentConcept, setCurrentConcept ] = useState({});
  const filteredIds = filter(allConceptsIds, (value) => !includes(selectedConcepts, value));

  useEffect(() => {
    if (data) {
      if (currentConceptId) {
        setCurrentConcept(find(data.concepts_data, (item) => item.id === currentConcept?.id));
      }
    }
  }, [ data ]);

  const conceptViews = {
    info: `${itemName} Info`,
    data: `${itemName} Data`
  };

  const StatTestingModes = {
    average: "Stat Testing vs Average",
    quintiles: "Quintiles"
  };

  const onConceptViewChange = (attrName, value) => {
    const keyValue = find(keys(conceptViews), (key) => conceptViews[key] === value);
    const payload = {};
    payload.concept_view = keyValue;
    setTableLoading(true);
    updateParams(paramsId, payload, setData, setError);
  };

  const onStatTestingChange = (attrName, value) => {
    const keyValue = find(keys(StatTestingModes), (key) => StatTestingModes[key] === value);
    const payload = {};
    payload.stat_testing = keyValue;
    setTableLoading(true);
    updateParams(paramsId, payload, setData, setError);
  };

  const onOrderChange = (field) => {
    const payload = {};
    if (orderBy === field && order === "desc") {
      payload.order = "asc";
    }
    if (orderBy === field && order === "asc") {
      payload.order = "desc";
    }
    if (orderBy !== field) {
      payload.order_by = field;
      payload.order = "asc";
    }
    setTableLoading(true);
    updateParams(paramsId, payload, setData, setError);
  };

  const onRowClick = (event, concept) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentConceptId(concept.id);
    setCurrentConcept(concept);
    setShowPopup(true);
  };

  const onConceptTagsPopupClose = () => {
    setShowPopup(false);
  };

  const onAddToFavoritesClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = {};
    if (filteredIds.length) {
      payload.selected_concepts = concat(selectedConcepts, filteredIds);
    } else {
      payload.selected_concepts = filter(selectedConcepts, (item) => !includes(allConceptsIds, item));
    }

    if (conceptGroup === 'included') {
      payload.no_calc = true;
      updateParams(paramsId, payload, () => setSelectedConcepts(payload.selected_concepts), setError);
    } else {
      if (isEmpty(payload.selected_concepts)) {
        payload.concept_group = "included";
        setGlobalLoading(true);
      }
      updateParams(paramsId, payload, setData, setError);
    }
  };

  const onPageChange = (newPage) => {
    const payload = { page: newPage };
    setTableLoading(true);
    updateParams(paramsId, payload, setData, setError);
  };

  const conceptNameTitleArr = [ itemName ];
  if (conceptNameTitleArr[0].toLowerCase() !== 'name') {
    conceptNameTitleArr.push('Name');
  }
  const conceptNameTitle = conceptNameTitleArr.join(' ');

  return (
    <>
      <div className="concepts-database_plate">
        <div className="concepts-database_plate-body">
          {
            (!!currentCountry && !!currentProjectType && !!currentProductCategory) &&
            <div className="concepts-database_concepts_table_settings">
              <div className="concepts-database_concepts_table_toggle">
                <PropsToggle
                  attrName="concept_view"
                  prefix="concept_view"
                  options={ conceptViews }
                  labelClass="-width-1-2"
                  currentValue={ conceptViews[conceptView] }
                  onChange={ onConceptViewChange }
                />
              </div>
              {
                conceptView === "data" && (conceptGroup === "included" || conceptGroup === "custom") &&
                <div className="concepts-database_concepts_table_toggle -big">
                  <PropsToggle
                    attrName="stat_testing"
                    prefix="stat_testing"
                    options={ StatTestingModes }
                    labelClass="-width-1-2"
                    currentValue={ StatTestingModes[statTesting] }
                    onChange={ onStatTestingChange }
                  />
                </div>
              }
              <div className="concepts-database_btn_element">
                <button
                  className="concepts-database_filter_button"
                  onClick={ onFilterClick }
                >
                  <SvgIcon className="icon-link_icon" name="i-sliders" />
                  <span className="icon-link_text">Data Filters</span>
                </button>
              </div>
            </div>
          }
          {dataFilterIsOpen && <DataFilters setFilterIsOpen={ setDataFilterIsOpen } />}
          {
            allConceptsIds.length > data.per_page &&
            <div className="concepts-database_pagination-block">
              <div className="projects_pagination">
                <BBPagination
                  totalRecords={ allConceptsIds.length }
                  perPage={ data.per_page }
                  current={ page }
                  pageClicked={ (p) => { onPageChange(p); } }
                  className="projects_pagination"
                />
              </div>
            </div>
          }
          {
            !isEmpty(conceptsData) &&
            <div className="concepts-database_concepts_table_container">
              <div className="concepts-database_concepts_table">
                { tableLoading && <div className="concepts-database_table-loader -with-loader" /> }
                <div className="concepts-database_y-container">
                  <table className="table table-comparison -concepts-database-sticky-head">
                    <thead>
                      <tr>
                        {
                          (conceptGroup === "included" || conceptGroup === "custom") &&
                          <th className="table-comparison_name-cell -nowrap -with-bigger-z-index">
                            <div className="concepts-database_icon">
                              <div className="info-tooltip -range">
                                <SvgIcon
                                  name={ !filteredIds.length ? "i-star-blue" : "i-star-gray" }
                                  onClick={ onAddToFavoritesClick }
                                />
                                <div className="info-tooltip_drop -right">
                                  <div className="info-tooltip_drop-inner -tiny">
                                    {
                                      !filteredIds.length ?
                                        <>Remove all from Custom Comparison</> :
                                        <>Add all to Custom Comparison</>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </th>
                        }
                        <th
                          className="table-comparison_name-cell -nowrap"
                        >
                          Image
                        </th>
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title={ conceptNameTitle }
                          orderTitle="name"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Include in DB Average"
                          orderTitle="include_db_average"
                        />
                        {
                          isAdmin &&
                          <SortableTableHeader
                            onOrderChange={ onOrderChange }
                            title="Include in Average Site-wide"
                            orderTitle="include_site_wide"
                          />
                        }
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Project Name"
                          orderTitle="project_title"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Sub-Category #1"
                          orderTitle="category_tag"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Sub-Category #2"
                          orderTitle="subcategory_tag"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Brand"
                          orderTitle="brand_tag"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Client vs Internal"
                          orderTitle="internal"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Priced vs Unpriced"
                          orderTitle="priced"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Stimuli Type"
                          orderTitle="stimuli_type"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Product Type"
                          orderTitle="product_type"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Year Tested"
                          orderTitle="year"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Target"
                          orderTitle="target"
                        />
                        {
                          conceptView === "data" &&
                        map(flatten(values(metricTitles)), (obj) => {
                          const key = keyTitle(obj);

                          return (
                            <SortableTableHeader
                              key={ key }
                              onOrderChange={ onOrderChange }
                              title={ uiTitle(obj) }
                              orderTitle={ key }
                            />
                          );
                        })
                        }
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Base Size"
                          orderTitle="respondents_count"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Incidence Range"
                          orderTitle="incidence_range"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Tags"
                          orderTitle="custom_tag"
                        />
                        <SortableTableHeader
                          onOrderChange={ onOrderChange }
                          title="Notes"
                          orderTitle="notes"
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        map(conceptsData, (concept, index) => (
                          <ConceptsTableDataRow
                            key={ `${concept.name}-${index}` }
                            concept={ concept }
                            onRowClick={ onRowClick }
                            loadingElement={
                              tableLoading && !index ?
                                <div className="concepts-database_table-loader -with-background" /> :
                                null
                            }
                          />
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
          {
            (!tableLoading && isEmpty(conceptsData) && currentCountry && currentProjectType && currentProductCategory) &&
            <>
              <div className="concepts-database_icon -lg">
                <SvgIcon name="i-no-data" />
              </div>
              <div className="concepts-database_no-data-text">
                No data. Try expanding the category.
              </div>
            </>
          }
        </div>
      </div>
      {
        showPopup &&
        <ConceptTagsPopup
          concept={ currentConcept }
          onClose={ onConceptTagsPopupClose }
        />
      }
    </>
  );
};

export default ConceptsTable;
