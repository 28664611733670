import Http from "../../../common/http";

const loadTableData = (mediaKind, verizonType, filterPrecode, callback) => {
  const params = [ `media_kind=${mediaKind}&verizon_type=${verizonType}` ];
  if (filterPrecode) {
    params.push(`filters[]=${filterPrecode}`);
  }
  Http.get(`/verizon/comparison.json?${params.join('&')}`).then(
    (response) => {
      callback && callback(response.data);
    },
    (reject) => {
      console.log('something went wrong', reject);
    }
  );
};

export { loadTableData };
