const TVConnections = [
  {
    count: 1,
    price: 0,
    showPrice: true
  },
  {
    count: 2,
    price: 12,
    showPrice: true
  },
  {
    count: 3,
    price: 24,
    showPrice: true
  },
  {
    count: 4,
    price: 30,
    showPrice: true
  },
  {
    count: 5,
    price: 36,
    showPrice: true
  },
  {
    count: 6,
    price: 42,
    showPrice: true
  }
];

export const TVConnectionsA = [...TVConnections];

export const TVConnectionsB = TVConnections.map(el => ({...el, showPrice: false }));

export const TVConnectionsC = [...TVConnections];