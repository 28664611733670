import AppleMusicUrl from "../../../../../../../images/quant/apple-music.png";

let linesCount = 0;
export const setLinesCount = count => { linesCount = count - 1; };
const prices = [
  [70, 80, 80, 90],
  [60, 70, 70, 80],
  [45, 55, 55, 65],
  [35, 45, 45, 55],
  [30, 40, 40, 50],
];

const getPriceById = (id) => prices[linesCount][id - 1];

const startUnlimitedBase = {
  id: 1,
  name: 'Start Unlimited',
};

const playUnlimitedBase = {
  id: 2,
  name: 'Play Unlimited',
};

const doUnlimitedBase = {
  id: 3,
  name: 'Do Unlimited',
};

const totalUnlimitedBase = {
  id: 4,
  name: 'Total Unlimited',
}

export const planList = [
  startUnlimitedBase,
  playUnlimitedBase,
  doUnlimitedBase,
  totalUnlimitedBase
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

export const planIds = planList.map(({ id }) => id);

export const plansIncludesChoose = [
  {
    text: 'Unlimited talk, text, & data',
    icon: 'unlimited-ttd'
  },
  {
    text: '4G LTE',
    icon: 'g-lte'
  },
  {
    text: 'Mexico & Canada talk, text, & data',
    icon: 'mexico-canada-ttd'
  },
  {
    text: 'International texting',
    icon: 'international-t'
  },
  {
    text: 'Call Filter',
    icon: 'call-filter'
  },
  {
    text: 'Verizon Up rewards',
    icon: 'verizon-up'
  }
];

const startUnlimitedShort = {
  ...startUnlimitedBase,
  description: [
    {
      title: 'Features',
      type: 'features',
      items: ['5G Nationwide']
    },
    {
      title: 'Perks',
      type: 'perks',
      itemsBySubtypes: true,
      items: [
        {
          subtype: 'entertainment',
          subitems: [
            'Disney+ 6 months on us',
            'Apple Arcade or Google Play Pass\n6 months on us',
            'Apple Music 6 months on us'
          ]
        }
      ]
    },
  ]
};

const playUnlimitedShort = {
  ...playUnlimitedBase,
  description: [
    {
      title: 'Features',
      type: 'features',
      items: [
        '5G Ultra Wideband',
        '5G Nationwide',
        'Unlimited Premium Network Access',
        'Unlimited mobile hotspot\n(25 GB high-speed data)',
        '600 GB Verizon Cloud storage'
      ]
    },
    {
      title: 'Perks',
      type: 'perks',
      itemsBySubtypes: true,
      items: [
        {
          subtype: 'entertainment',
          subitems: [
            {
              icon: 'Disney_Bundle',
              text: 'Disney+, Hulu, ESPN+ included'
            },
            {
              icon: 'Apple_Arcade_Google_Play_Pass_Black',
              text: 'Apple Arcade or Google Play Pass included'
            },
            'Apple Music 6 months on us'
          ]
        }
      ]
    },
  ]
};

const doUnlimitedShort = {
  ...doUnlimitedBase,
  description: [
    {
      title: 'Features',
      type: 'features',
      items: [
        '5G Ultra Wideband',
        '5G Nationwide',
        'Unlimited Premium Network Access',
        'Unlimited mobile hotspot\n(25 GB high-speed data)',
        '600 GB Verizon Cloud storage'
      ]
    },

    {
      title: 'Perks',
      type: 'perks',
      itemsBySubtypes: true,
      items: [
        {
          subtype: 'productivity',
          subitems: [
            '1 TravelPass day per month',
            '50% off a plan for a watch, tablet,\nHum, or hotspot',
            'Disney+ 6 months on us',
            'Apple Arcade or Google Play Pass\n6 months on us',
            'Apple Music 6 months on us'
          ]
        }
      ]
    },
  ]
};

const totalUnlimitedShort = {
  ...totalUnlimitedBase,
  description: [
    {
      title: 'Features',
      type: 'features',
      items: [
        '5G Ultra Wideband',
        '5G Nationwide',
        'Unlimited Premium Network Access',
        'Unlimited mobile hotspot\n(50 GB high-speed data)',
        '600 GB Verizon Cloud storage'
      ]
    },

    {
      title: 'All perks included',
      type: 'perks',
      itemsBySubtypes: true,
      items: [
        {
          subtype: 'entertainment',
          subitems: [
            {
              icon: 'Disney_Bundle',
              text: 'Disney+, Hulu, ESPN+ included'
            },
            {
              icon: 'Apple_Arcade_Google_Play_Pass_Black',
              text: 'Apple Arcade or Google Play Pass included'
            },
            {
              icon: 'Apple_Music',
              text: 'Apple Music included'
            },
          ]
        },
        {
          subtype: 'productivity',
          subitems: [
            '1 TravelPass day per month',
            '50% off a plan for a watch, tablet,\nHum, or hotspot'
          ]
        }
      ]
    },
  ]
};

export const planDetailsShort = [
  startUnlimitedShort,
  playUnlimitedShort,
  doUnlimitedShort,
  totalUnlimitedShort
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

const planShortDetailsDictionary = planDetailsShort.reduce((dictionary, element) => ({
  ...dictionary,
  [element.id]: element
}), Object.create(null));

export const getShortPlanDescription = (id) => planShortDetailsDictionary[id] || null;

export const allPlansInclude = {
  title: 'All plans include',
  description: [
    {
      title: 'Unlimited talk, text, & data',
      text: 'Stay in touch and never worry about overage charges with unlimited Talk, Text and Data on the network more people rely on.',
      showText: false,
      showTextInAll: true
    },
    {
      title: '4G LTE',
      text: 'Our 4G LTE network provides the high speeds and reliability Verizon is known for delivering. Includes 480p video streaming. In times of congestion, your data may be temporarily slower than other traffic.',
      showText: false,
      showTextInAll: true
    },
    {
      title: 'Mexico & Canada talk, text, & data',
      text: 'Talk, text and use unlimited data when traveling in Mexico and Canada. Data speeds are reduced to 2G after 0.5 GB/day. Also includes Unlimited talk and text to Mexico and Canada when you are in the US.',
      showText: false,
      showTextInAll: true
    },
    {
      title: 'International texting',
      text: 'Unlimited texting from the US to over 200 countries and territories worldwide.',
      showText: false,
      showTextInAll: true
    },
    {
      title: 'Call Filter',
      text: 'Call Filter is a service that helps you take control of your calls and gives you the tools to manage those calls as you choose. You can screen incoming calls, block (send directly to voicemail) spam numbers by risk-level, and report numbers as spam. Numbers considered risky will be labeled as potential spam.',
      showText: false,
      showTextInAll: true
    },
    {
      title: 'Verizon Up rewards',
      text: 'With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.',
      showText: false,
      showTextInAll: true
    },
  ]
};

const startUnlimitedLarge = {
  ...startUnlimitedBase,
  description: [
    { title: 'Features',
      articles: [
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        }
      ]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: 'Disney+ 6 months on us',
          text: 'All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic, including The Mandalorian, The Avengers, Mulan, and Hamilton. First 6 months on us, then $7.99/mo + taxes after. One subscription can be shared with everyone on your account. Cancel anytime. (For NM residents, Disney+ ends automatically after 6 mos. Add\'l terms apply. One offer per eligible Verizon account.)',
          icon: 'Disney'
        },
        {
          title: 'Apple Arcade or Google Play Pass\n6 months on us',
          text: 'Choice between:\n• Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 6 months on us, then $4.99/month + taxes after.\nOR\n• Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 6 months on us, then $4.99/month + taxes after.',
          icon: 'Apple_Arcade_Google_Play_Pass_Black'
        },
        {
          title: 'Apple Music 6 months on us',
          icon: 'Apple_Music',
          text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
          showText: false
        }
      ]
    }
  ]
}

const playUnlimitedLarge = {
  ...playUnlimitedBase,
  description: [
    {
      title: 'Features',
      articles: [
        {
          title: '5G Ultra Wideband',
          text: 'Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today\'s 4G networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. <strong>5G Ultra Wideband available only in parts of select cities;</strong> requires a 5G Ultra Wideband-capable device.'
        },
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        },
        {
          title: 'Unlimited Premium Network Access',
          text: 'Get access to unlimited 5G Nationwide / 4G LTE  premium  data per month. 5G access requires a 5G-capable device. Plus 720p HD streaming when you activate it in your plan settings.'
        },
        {
          title: 'Unlimited mobile hotspot\n(25 GB high-speed data)',
          text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 25 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.',
          showText: false
        },
        {
          title: '600 GB of Verizon Cloud storage',
          text: 'Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.',
          showText: false
        },
      ]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: 'Disney+, Hulu, ESPN+ included',
          text: 'Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places.   One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.',
          icon: 'Disney_Bundle'
        },
        {
          title: 'Apple Arcade or Google Play Pass included',
          text: 'Choice between:\n• Apple Arcade subscription included. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. A $4.99/month value.\nOR\n• Google Play Pass subscription included along with Play Points Gold Status. Your pass to hundreds of games and apps in one subscription. Available on Android. A $4.99/month value.',
          icon: 'Apple_Arcade_Google_Play_Pass_Black'
        },
        {
          title: 'Apple Music 6 months on us',
          icon: 'Apple_Music',
          text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
          showText: false
        }
      ]
    }
  ]
};

const doUnlimitedLarge = {
  ...doUnlimitedBase,
  description: [
    {
      title: 'Features',
      articles: [
        {
          title: '5G Ultra Wideband',
          text: 'Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today\'s 4G networks in the U.S. with massive capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. <strong>5G Ultra Wideband available only in parts of select cities;</strong> requires a 5G Ultra Wideband-capable device.'
        },
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        },
        {
          title: 'Unlimited Premium Network Access',
          text: 'Get access to unlimited 5G Nationwide / 4G LTE  premium  data per month. 5G access requires a 5G-capable device. Plus 720p HD streaming when you activate it in your plan settings.'
        },
        {
          title: 'Unlimited mobile hotspot\n(25 GB high-speed data)',
          text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 25 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.',
          showText: false
        },
        {
          title: '600 GB of Verizon Cloud storage',
          text: 'Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.',
          showText: false
        },
      ]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: '1 TravelPass day per month',
          text: 'TravelPass lets you use your domestic talk, text and data in more than 185 countries outside the US for a set daily fee. You can call within the country you\'re visiting and you can call back to the US. One Verizon TravelPass (good for one day) is included each month; you can store them in the "bank" and use them when you travel. Each TravelPass expires 12 months from issue date.',
        },
        {
          title: '50% off a plan for a watch, tablet, Hum, or hotspot',
          text: 'Get 50% off an Unlimited connected device plan. (not the hardware). Up to $15/month value. (Jetpack is a separate device that lets you share your Verizon wireless network connection with other devices. Hum turns almost any car into a smarter, safer, more connected car; check your vehicle health, get 24/7 pinpoint road assistance, and create a WiFi connection from your car.)',
        },
        {
          title: 'Disney+ 6 months on us',
          icon: 'Disney',
          text: 'All the best stories from Disney, Pixar, Marvel, Star Wars, and National Geographic, including The Mandalorian, The Avengers, Mulan, and Hamilton. First 6 months on us, then $7.99/mo + taxes after. One subscription can be shared with everyone on your account. Cancel anytime. (For NM residents, Disney+ ends automatically after 6 mos. Add\'l terms apply. One offer per eligible Verizon account.)',
          showText: false
        },
        {
          title: 'Apple Arcade or Google Play Pass\n6 months on us',
          icon: 'Apple_Arcade_Google_Play_Pass_Black',
          text: 'Choice between:' +
            '\n• Apple Arcade subscription. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. First 6 months on us, then $4.99/month + taxes after.' +
            '\nOR' +
            '\n• Google Play Pass subscription. Your pass to hundreds of games and apps in one subscription. Available on Android. First 6 months on us, then $4.99/month + taxes after.',
          showText: false
        },
        {
          title: 'Apple Music 6 months on us',
          icon: 'Apple_Music',
          text: 'Listen to 70 million songs ad-free, or download your favorite tracks and play them offline for 6 months - on us. Then $9.99/mo + taxes after. Cancel anytime. (For NM residents, Apple Music ends automatically after 6 mos.)',
          showText: false
        }
      ]
    }
  ]
};

const totalUnlimitedLarge = {
  ...totalUnlimitedBase,
  description: [
    {
      title: 'Features',
      articles: [
        {
          title: '5G Ultra Wideband',
          text: 'Unlimited access to the fastest 5G in the world means download speeds more than 25x faster than today\'s 4G networks in the U.S. with massive ' +
            'capacity and ultra-low lag, giving you our ultimate in performance. Includes Unlimited 5G Ultra Wideband mobile hotspot and 4K UHD streaming on capable devices. ' +
            '<strong>5G Ultra Wideband available only in parts of select cities;</strong> requires a 5G Ultra Wideband-capable device.'
        },
        {
          title: '5G Nationwide',
          text: 'Unlimited access to our 5G Nationwide network, available in 1800+ cities. 5G access requires a 5G Nationwide-capable device.'
        },
        {
          title: 'Unlimited Premium Network Access',
          text: 'Get access to unlimited 5G Nationwide / 4G LTE  premium  data per month. 5G access requires a 5G-capable device. Plus 720p HD streaming when you activate it in your plan settings.'
        },
        {
          title: 'Unlimited mobile hotspot\n(50 GB high-speed data)',
          text: 'Your smartphone becomes a Wi-Fi connection for other devices. Get up to 50 GB 4G LTE / 5G Nationwide data, then 600 Kbps speeds and during times of congestion may be temporarily slower than other traffic for the rest of the month. Mobile hotspot usage counts towards Premium Network Access data allotment. Unlimited 5G Ultra Wideband Mobile hotspot inside a 5G Ultra Wideband coverage area.',
          showText: false
        },
        {
          title: '600 GB of Verizon Cloud storage',
          text: 'Verizon Cloud is a wireless service that securely stores your files in the cloud. Files are encrypted during transfer, and only you can access and use them anytime on all your devices: phones, computers and tablets. Use it to back up your files and restore them after crashes, and transfer content in case of device damage, theft, loss or upgrade. 1 user. 1 phone. A $5.99/mo value.',
          showText: false
        },
      ]
    },
    {
      title: 'Perks',
      articles: [
        {
          title: 'Disney+, Hulu, ESPN+ included',
          text: 'Includes Disney Bundle: Disney+ as well as Hulu (a video streaming service that offers premium video content from television shows to feature-length movies), and ESPN+ (a video streaming service that offers access to thousands of live sporting events and exclusive originals). Includes shows like The Mandalorian, Handmaid’s Tale, and Peyton’s Places. One subscription that can be shared with everyone on your account. Stream on up to 4 devices (like a phone or SmartTV) simultaneously and get unlimited downloads on 10 devices. A $12.99/month value.',
          icon: 'Disney_Bundle'
        },
        {
          title: 'Apple Arcade or Google Play Pass included',
          text: 'Choice between:\n• Apple Arcade subscription included. Access to 100+ groundbreaking games without any ads or in-app purchases. Available on iOS. A $4.99/month value.\nOR\n•  Google Play Pass subscription included along with Play Points Gold Status. Your pass to hundreds of games and apps in one subscription. Available on Android. A $4.99/month value.',
          icon: 'Apple_Arcade_Google_Play_Pass_Black'
        },
        {
          title: 'Apple Music included',
          icon: 'Apple_Music',
          text: 'Apple Music subscription; allows you to listen to 70 million songs ad-free, or download your favorite tracks and play them offline. A $9.99/month value. Each line on an eligible plan gets a separate subscription.'
        },
        {
          title: '1 TravelPass day per month',
          text: 'TravelPass lets you use your domestic talk, text and data in more than 185 countries outside the US for a set daily fee. You can call within the country you\'re visiting and you can call back to the US. One Verizon TravelPass (good for one day) is included each month; you can store them in the "bank" and use them when you travel. Each TravelPass expires 12 months from issue date.',
        },
        {
          title: '50% off a plan for a watch, tablet, Hum, or hotspot',
          text: 'Get 50% off an Unlimited connected device plan. (not the hardware). Up to $15/month value. (Jetpack is a separate device that lets you share your Verizon wireless network connection with other devices. Hum turns almost any car into a smarter, safer, more connected car; check your vehicle health, get 24/7 pinpoint road assistance, and create a WiFi connection from your car.)',
        }
      ]
    }
  ]
};

export const planDetailsLarge = [
  startUnlimitedLarge,
  playUnlimitedLarge,
  doUnlimitedLarge,
  totalUnlimitedLarge,
].map(el => ({
  ...el,
  get price() { return getPriceById(this.id); }
}));

const planLargeDetailsDictionary = planDetailsLarge.reduce((dictionary, element) =>
  ({
    ...dictionary,
    [element.id]: element,
  }), Object.create(null));

export const getLargePlanDescription = (id) => planLargeDetailsDictionary[id] || null;
