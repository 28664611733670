import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../common/render_raw_html';
import { LineDataContext, SetContext, PageContext } from '../../common/contexts';
import { Header } from '../../common/header';
import { Subheader } from '../../common/subheader';
import { AllPlansInclude } from '../../common/allPlansIncluded';
import CharacteristicList from '../../common/characteristicList';
import { ScrollButton, ScrollButtonContext, REF_NAMES } from '../scrollButton';
import Modal from '../../common/modal';
import { getCurrentLine, getHashData, setHashData } from '../../common/statistics';

const OverviewPlan = ({
  plan,
  onPlanSelect,
  instantSelect,
  children
}) => {
  const { delayAnimation } = useContext(SetContext);
  const {
    getClickedSelectButton,
    setClickedSelectButton
  } = useContext(PageContext);
  const { setLoading } = useContext(LineDataContext);
  const clickedSelectButton = getClickedSelectButton();

  const clickSelectButton = (plan) => {
    setClickedSelectButton(plan.id);
    const select = () => {
      if (!instantSelect) {
        setLoading(false);
      }
      setClickedSelectButton(null);
      onPlanSelect(plan);
    };
    if (!instantSelect) {
      setLoading(true);
    }
    instantSelect ? select() : setTimeout(select, delayAnimation);
  };

  return (
    <div className="overview-list_item -bau">
      {plan.rateLabel && (
        <div className="overview-list_top-label">{plan.rateLabel}</div>
      )}
      <div className="plan-short">
        <h2 className="plan-short_title ng-binding" { ...renderRawHtml(plan.name) } />
        <div className="plan-short_description" { ...renderRawHtml(plan.description) } />
        <div className="plan-short_pricing">
          <div className="plan-short_pricing-grid">
            <h2 className="plan-short_pricing-value">${ plan.price }</h2>
            <div className="plan-short_pricing-hint">
              <div className="plan-short_pricing-hint-line">/line per month</div>
              <div className="plan-short_pricing-hint-line">{ plan.taxPhrase }</div>
            </div>
          </div>
          <div className="plan-short_pricing-tip">
            <b>${ plan.price + 10 }</b> without Auto Pay and <span className="row">paper-free billing.</span>
          </div>
          {plan.newPhoneText
            && <div className={ classnames('plan-shot_phone-discount', plan.newPhoneClasses) } { ...renderRawHtml(plan.newPhoneText) } />
          }
        </div>
      </div>
      <div className="plan-features">
        <CharacteristicList
          title={ plan.featuresTitle }
          list={ plan.features }
        />
        {plan.savings?.length > 0 && (
          <CharacteristicList
            title={ plan.savingsTitle }
            list={ plan.savings }
          />
        )}
        <CharacteristicList
          title={ plan.perksTitle }
          list={ plan.perks }
          note={ plan.perksNote }
          overTitle={ !plan.base ? 'Plan perks' : null }
          specialClassList={ plan.base ? '-first-col-1' : null }
          boxClassNames="-minus10"
          highlighted={ !plan.base }
        />
        {
          plan.other?.length > 0 &&
          <CharacteristicList
            list={ plan.other }
          />
        }
      </div>
      <div className="plan-buttons">
        <div className="plan-buttons_item">
          <button
            className={
              classnames(
                'button -full-width plan-select-button -invert',
                { '-clicked': clickedSelectButton }
              )
            }
            onClick={ () => { clickSelectButton(plan); } }
            disabled={ clickedSelectButton && clickedSelectButton !== plan.id }
          >
            Select
          </button>
        </div>
        {children || <></>}
      </div>
    </div>
  );
};

const OverviewPage = ({ currentLine, onPlanSelect, linesCount }) => {
  const { plans, headerButtons, welcomePlans, welcomePlanId } = useContext(LineDataContext);
  const { setRef } = useContext(ScrollButtonContext);
  const [ selectedModalPlan, setSelectedModalPlan ] = useState(null);
  const [ fromWelcomeToBasic, setFromWelcomeToBasic ] = useState(null);

  const handlePlanBase = (plan) => {
    const line = getCurrentLine();
    const currentPlanId = getHashData(`lines.${line}.planId`);
    if (linesCount > 1 && currentPlanId === welcomePlanId) {
      setFromWelcomeToBasic(plan);
    } else {
      onPlanSelect(plan);
    }
  };
  const handlePlanModal = (plan) => {
    const line = getCurrentLine();
    const currentPlanId = getHashData(`lines.${line}.planId`);
    if (linesCount > 1 && currentPlanId !== welcomePlanId) {
      setSelectedModalPlan(plan);
    } else {
      onPlanSelect(plan, true);
    }
  };
  const closeModal = () => {
    setSelectedModalPlan(null);
    setFromWelcomeToBasic(null);
  };
  const closeModalAndUpdateStatistic = () => {
    const line = getCurrentLine();
    const key = `lines.${line}.planWelcomePopupCancelClickCount`;
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    closeModal();
  };
  const closeToBaseModalAndUpdateStatistic = () => {
    const line = getCurrentLine();
    const key = `lines.${line}.planBaseFromWelcomePopupCancelClickCount`;
    const count = getHashData(key);
    setHashData(key, count ? count + 1 : 1);
    closeModal();
  };
  return (
    <div className="content">
      <Header />
      <Subheader currentLine={ currentLine } />
      <div className="overview-page -bau">
        <div className="inner-content -header">
          { map(headerButtons, (name) => <ScrollButton key={ name } name={ name } />) }
          <AllPlansInclude className="-bau" />
        </div>
        <div className="inner-content -base">
          <h4 className="overview-list-title" ref={ setRef(REF_NAMES.Unlimited) }>
            Mix and match the plans you want.
          </h4>
          <hr className="title-divider" />
          <span className="overview-list-description">
            {"There's a 5G Unlimited plan for everyone, so you only pay for what you need."}
          </span>
          <div className="overview-list -bau">
            {
              map(plans, (plan) => {
                return (
                  <OverviewPlan
                    key={ `overviewPlanItem${plan.id}` }
                    plan={ plan }
                    onPlanSelect={ handlePlanBase }
                    instantSelect
                  />
                );
              })
            }
            <hr className="plans-divider" />
            <h4 className="overview-list-title" ref={ setRef(REF_NAMES.Welcome) }>
              An entry-unlimited plan for all your lines.
            </h4>
            <hr className="title-divider" />
            <span className="overview-list-description">
              Enjoy reliable 5G performance with&nbsp;Welcome Unlimited.
            </span>
            {
              map(welcomePlans, (plan) => {
                return (
                  <OverviewPlan
                    key={ `overviewPlanItem${plan.id}` }
                    plan={ plan }
                    onPlanSelect={ handlePlanModal }
                    instantSelect
                  >
                    <div className="plan-button-explanation">
                      This plan will be applied to all phone lines on your&nbsp;account.
                    </div>
                  </OverviewPlan>
                );
              })
            }
          </div>
        </div>
        <footer className="footer">
          <span className="footer_item">Important plan information</span>
          <span className="footer_item">Important broadband information</span>
        </footer>
        {!!fromWelcomeToBasic && <Modal title="You'll need to choose new plans for each of your phone lines." handleClose={ closeToBaseModalAndUpdateStatistic }>
          <div className="modal_inner">
            <div className="modal_empty-content" />
            <div className="modal-actions">
              <button
                className="button" onClick={ () => {
                  const line = getCurrentLine();
                  const key = `lines.${line}.planBaseFromWelcomePopupContinueClickCount`;
                  const count = getHashData(key);
                  setHashData(key, count ? count + 1 : 1);
                  onPlanSelect(fromWelcomeToBasic, false, true);
                  closeModal();
                } }
              >
                Continue
              </button>
              <button className="button -invert" onClick={ closeToBaseModalAndUpdateStatistic }>Cancel</button>
            </div>
          </div>
        </Modal>}
        {!!selectedModalPlan && <Modal title="This plan will be applied to all of your phone lines." handleClose={ closeModalAndUpdateStatistic }>
          <div className="modal_inner">
            <div className="modal_empty-content" />
            <div className="modal-actions">
              <button
                className="button" onClick={ () => {
                  const line = getCurrentLine();
                  const key = `lines.${line}.planWelcomePopupContinueClickCount`;
                  const count = getHashData(key);
                  setHashData(key, count ? count + 1 : 1);
                  onPlanSelect(selectedModalPlan, true);
                  closeModal();
                } }
              >
                Continue
              </button>
              <button className="button -invert" onClick={ closeModalAndUpdateStatistic }>Cancel</button>
            </div>
          </div>
        </Modal>}
      </div>
    </div>
  );
};

export default OverviewPage;
