import React from 'react';
import { Provider } from 'react-redux';
import WithAppTopContexts from "../common/components/with_app_top_contexts";
import store from './store.js';
import Index from './components/index.js';

const App = (props) => {
  return (
    <Provider store={ store }>
      <WithAppTopContexts initProps={ props }>
        <Index { ...props } />
      </WithAppTopContexts>
    </Provider>
  );
};

export default App;
