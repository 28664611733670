import React from 'react';

const Index = () => {
  const handleNext = (value) => {
    const url = new URL(window.location.href);
    url.searchParams.append('anti_fraud', value);
    window.location.href = url.href;
  };

  return (
    <>
      <section className="survey-layout">
        <div className="survey-layout_content -start">
          <div className="survey-start -fraud">
            <svg className="svg -icon -shield">
              <use xlinkHref="#svg_shield" />
            </svg>
            <h3 className="survey-start_title -fraud">
              Would you like to test Fraud Check in preview?
            </h3>
            <button
              className="button -fraud -block"
              type="button"
              onClick={  () => { handleNext(1); } }
            >
              Yes, continue with Fraud Check
            </button>
            <button
              className="button -fraud -block -secondary"
              type="button"
              onClick={  () => { handleNext(0); } }
            >
              Without Fraud Check
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
