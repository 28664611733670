import React, { useEffect, useContext } from 'react';
import { GeneralContext } from '../contexts';
import { checkQuotas } from '../../common/logic/eligible';
import { fraudContext } from '../../common/contexts';

const WithCheckQuotas = ({ children }) => {
  const { data, filteredGroup, result, groupIndex, setLoading } = useContext(GeneralContext);
  const { gaTrackingId } = useContext(fraudContext);

  const testAndLaunchCheckQuotas = () => {
    const previousQuestion = groupIndex > 0 ? (filteredGroup(groupIndex - 1) || [])[0] : null;
    if (previousQuestion?.send_quotas_check_after) {
      setLoading(true);
      checkQuotas(
        data,
        result.answers,
        gaTrackingId,
        (data) => {
          if (!data.redirect) {
            setLoading(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    testAndLaunchCheckQuotas();
  }, [ groupIndex ]);

  return (children);
};

export default WithCheckQuotas;
