import React, { useContext } from 'react';
import classnames from 'classnames';
import Modal from '../../../../../common/components/modal';
import { IndexData } from "../../../common/index-context";
import SwitchModal from "../../../common/components/entity-modal/switch-modal";
import HeaderLinks from "../../../common/components/header-links";
import TopFilter from "../../../common/filters/app";
import ExpressLanguageSwitcher from '../../../common/components/express-language-switcher';
import ReasonsForPreference from "../../../common/components/entity-modal/reasons-for-preference";
import Header from "../../../common/components/entity-modal/header";
import Averages from "../../../common/averages";
import WarningMessage from "../../../common/components/warning-message";
import TableConcept from "./table-concept";

const EntityModal = () => {
  const {
    init, data, current, setCurrentConceptWithUrl, concepts, loadData,
    loadCurrent, loading, showSwitchModal, tourData
  } = useContext(IndexData);

  const reports = [
    { title: 'Individual Name Report', name: 'naming_scorecard', kinds: [ 'pptx', 'xlsx' ] },
    { title: 'Name Verbatims Report', name: 'naming_individual_verbatims', kinds: [ 'xlsx' ] }
  ];

  const prefReports = [
    { title: 'Names Wordcloud Report', name: 'naming_wordcloud', kinds: [ 'pptx' ] }
  ];

  return (
    <>
      <Modal
        modal
        modalSize="lg"
        modalClassName={
          classnames("-scorecard", { 'tour-mode': !!tourData })
        }
        className="concept-report h100"
      >
        <Header singular="name" />
        <Averages
          statTesting
          scorecard
          statTestingItemTitle="concept"
          data={ data }
          init={ init }
          loadData={ loadData }
        />

        <div className="modal-filters-header">
          <TopFilter
            statTesting
            scorecard
            data={ data }
            loadData={ loadData }
          >
            <HeaderLinks
              className="-no-min-width"
              exportUrl={ init.urls.export }
              reports={ reports }
              i18n={ init.i18n }
              data={ data }
              conceptId={ current.id }
            />
          </TopFilter>
          <ExpressLanguageSwitcher
            description={ data.translations.language_switcher_description }
            showLanguageSwitcher={ data.show_language_switcher }
          />
        </div>

        <WarningMessage
          data={ data }
          buzzbackUser={ init.isInternal }
          itemName={ init.project.item_name }
        />

        <div className="modal_body -scrollable concept-report_body">
          <div className="modal-data">
            { loading && <div className="page-loader -inner-loader" /> }
            <TableConcept data={ data } current={ current } />

            <ReasonsForPreference
              preferenceReasons={ data.preference_reasons }
              tbEnabled={ data.tb_enabled }
              current={ current }
              reports={ prefReports }
              init={ init }
              data={ data }
              loadCurrent={ loadCurrent }
              isAdmin={ init.isAdmin }
            />
          </div>
        </div>
      </Modal>
      {
        showSwitchModal &&
          <SwitchModal
            concepts={ concepts }
            current={ current }
            setCurrentWithUrl={ setCurrentConceptWithUrl }
            singular="name"
          />
      }
    </>
  );
};

export default EntityModal;
