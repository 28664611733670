import React, { useContext, useState } from "react";
import UploadButton from "../../../common/components/upload_button";
import Notification from "../common/notification";
import AdHeader from "./ad-header";
import HiddenInputs from "./hidden-inputs";
import UploadedVideo from "./uploaded-video";
import { globalContext } from "./contexts";

const Ad = ({ data, videoData, updateVideo, previousVideoData }) => {
  const [ uploading, setUploading ] = useState(false);
  const { increaseGlobalLoading, decreaseGlobalLoading } = useContext(globalContext);
  const [ notificationObject, updateNotificationObject ] = useState("");

  const onInitUploading = () => {
    increaseGlobalLoading();
    setUploading(true);
  };

  const onUploaded = (files) => {
    const [ file ] = files;
    const { cacheSrc: url, metaData } = file;
    updateVideo(url, metaData);
    decreaseGlobalLoading();
    setUploading(false);
  };

  const onError = () => {
    updateNotificationObject({
      message: "Can not upload file",
      success: false
    });
    decreaseGlobalLoading();
    setUploading(false);
    updateVideo(null, null);
  };

  const handleDeleteVideo = () => {
    updateVideo(null, null);
  };

  return (
    <div>
      <AdHeader
        title={ data.name }
        productUrl={ data.concept_url }
      />
      <div className="products-greed-item-image-wrapper">
        { videoData.url &&
        <div
          className="image-delete"
          title="Delete Video"
          onClick={ handleDeleteVideo }
        />}

        { videoData.url && <UploadedVideo src={ videoData.url } /> }
        { !videoData.url && <span className="products-greed-item-add-image-tip">Add video</span> }
        <HiddenInputs
          id={ data.id || 'null' }
          conceptId={ data.concept_id || 'null' }
          metaData={ videoData.metaData || 'null' }
        />
        <div className="products-greed-item-actions">
          <UploadButton
            className="products-greed-item-button -primary"
            allowedTypes={ [ '.mp4', '.avi', '.mov' ] }
            onInit={ onInitUploading }
            onUploaded={ onUploaded }
            onError={ onError }
          >
            Upload
          </UploadButton>
          {
            previousVideoData && previousVideoData.url !== videoData.url && !uploading &&
            <button
              type="button"
              onClick={ () => { updateVideo(previousVideoData.url, previousVideoData.metaData); } }
              className="products-greed-item-button -action"
            >
              Use previous video
            </button>
          }
        </div>
      </div>
      <Notification
        messageObject={ notificationObject }
        onDeactivate={ () => { updateNotificationObject(null); } }
        interval={ 10 }
      />
    </div>
  );
};

export default Ad;
