import React, { useEffect, useState, useRef } from 'react';
import { filter, find, isEmpty, map } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import QuestionsList from "../common/QuestionList";
import remoteFiles from '../../../../common/remote_files';
import PageLoader from "../../common/PageLoader";
import Single from "../../common/Single";
import SectionHeader from "../../common/SectionHeader";
import QuestionsOrderModal from "../../common/questions-order-modal";
import { initConditionList } from "../../common/custom_questions/helper";
import { QuestionsContext } from '../common/contexts';
import HiddenFields from "./HiddenFields";
import InfoText from "./InfoText";
import DiagnosticsText from "./DiagnosticsText";
import SingleWithCustomOptions from "./SingleWithCustomOptions";
import Field from "./Field";
import CustomQuestionsHeader from "./CustomQuestionsHeader";
import GridWithAdditional from "./GridWithAdditional";
import GridProductUsage from "./GridProductUsage";

const App = ({ data, options, errors, images }) => {
  const findMetric = (name) => (find(data.express_metrics, (el) => (el.default_metric_name === name)));
  const initScreenerQuestions = () => (
    map(data.custom_metrics, (q) => initConditionList(q, {}, images[q.id]))
  );

  const [ model, setModel ] = useState({
    tb: data.tb_enabled,
    reasons: data.preference_reasons
  });
  const [ customQuestions, setCustomQuestions ] = useState(initScreenerQuestions);
  const [ catPurchaseFr, setCatPurchaseFr ] = useState(() => (findMetric('category_purchase_frequency')));
  const [ catAttrImportance, setCatAttrImportance ] = useState(() => (findMetric('category_attribute_importance')));
  const [ brandPerfAttrs ] = useState(() => (findMetric('brand_performance_on_attributes')));
  const [ catPurchaseChannels, setCatPurchaseChannels ] = useState(() => (findMetric('category_purchasing_channels')));
  const [ brandAppeal, setBrandAppeal ] = useState(() => (findMetric('brand_appeal')));
  const [ brandValue, setBrandValue ] = useState(() => (findMetric('brand_value')));
  const [ brandSatisfaction, setBrandSatisfaction ] = useState(() => (findMetric('brand_satisfaction')));
  const [ brandProductUsage, setBrandProductUsage ] = useState(() => (findMetric('brand_product_usage')));
  const [ productSatisfaction, setProductSatisfaction ] = useState(() => (findMetric('product_satisfaction')));
  const [ nps, setNps ] = useState(() => (findMetric('nps')));

  const isFirstRun = useRef(true);
  const checkSum = useRef('');
  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);
  const [ questionsOrderData, setQuestionsOrderData ] = useState(null);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      checkSum.current = JSON.stringify([
        model, customQuestions, catPurchaseFr, catAttrImportance, brandPerfAttrs, catPurchaseChannels, brandAppeal,
        brandValue, brandSatisfaction, brandProductUsage, productSatisfaction, nps
      ]);
    } else {
      const checkSumNew = JSON.stringify([
        model, customQuestions, catPurchaseFr, catAttrImportance, brandPerfAttrs, catPurchaseChannels, brandAppeal,
        brandValue, brandSatisfaction, brandProductUsage, productSatisfaction, nps
      ]);
      hasChanges.current = initHasChanges.current || checkSum.current !== checkSumNew;
    }
  }, [
    model, customQuestions, catPurchaseFr, catAttrImportance, brandPerfAttrs, catPurchaseChannels, brandAppeal,
    brandValue, brandSatisfaction, brandProductUsage, productSatisfaction, nps
  ]);

  const filteredList = filter(customQuestions, (q) => !q._destroy);

  const addCustomQuestion = () => {
    if (filteredList.length >= options.max_custom_questions) {
      return;
    }
    setCustomQuestions(
      [
        ...customQuestions,
        {
          text: '',
          answers: [ "" ],
          kind: '',
          condition: {},
          condition_list: [],
          answers_order: 'specific',
          multi_condition: 1,
          target_group: 'all',
          survey_location: 'default',
          usage: 'all',
          scales: [ "" ],
          position: filteredList.length,
          tmpIndex: customQuestions.length
        }
      ]
    );
  };

  const {
    details51, details52, details53
  } = remoteFiles.express.steps.brandSnapshot;

  const onOpenQuestionsOrderModal = (event) => {
    event.preventDefault();
    setQuestionsOrderData(customQuestions);
  };

  const questionsContextValue = { options, allowReasons: options.allow_reasons };

  return (
    <WithSubmit
      formId="form"
      errors={ errors }
      hasChangesFunc={ () => (hasChanges) }
      options={ options }
    >
      <HiddenFields
        options={ options }
        cpf={ catPurchaseFr }
        cai={ catAttrImportance }
        bpa={ brandPerfAttrs }
        cpc={ catPurchaseChannels }
        singles={ [ brandAppeal, brandValue, brandSatisfaction, nps ] }
        bpu={ brandProductUsage }
        ps={ productSatisfaction }
        model={ model }
        customQuestions={ customQuestions }
      />
      <div className="content -relative -grey-background">
        <Header title={ options.project_title } />
        <div className="content_body">
          <div className="form">
            <div className="form_group -relative">
              {!!options.jid && <PageLoader options={ options } />}
              <InfoText />
              <div className="form_section">
                <SectionHeader
                  title="Category Evaluation"
                  infoText="These questions would be shown to all respondents."
                />
                <div className="form_metrics -md">
                  <SingleWithCustomOptions
                    model={ catPurchaseFr }
                    setModel={ setCatPurchaseFr }
                    max={ options.max_cpf }
                  />
                  <GridWithAdditional
                    label={ `${catAttrImportance.default_metric_title} / ${brandPerfAttrs.default_metric_title}` }
                    hint={ `Respondents will indicate how important each attribute is for the category and how much they agree the brand delivers for each. Here are a few suggestions - you can also add up to ${options.max_cai_bpa} of your own.` }
                    model={ catAttrImportance }
                    setModel={ setCatAttrImportance }
                    max={ options.max_cai_bpa }
                    placeholder={ (n) => (`Add up to ${n} additional attributes`) }
                  />
                  <GridWithAdditional
                    label={ catPurchaseChannels.default_metric_title }
                    hint={ `Respondents will indicate where they purchase the category. Default responses are shown – you may unselect and add your own.` }
                    model={ catPurchaseChannels }
                    setModel={ setCatPurchaseChannels }
                    max={ options.max_cpc }
                    countAll
                    placeholder={ (n) => (`Add up to ${n} custom options`) }
                    showOther
                    otherSpecify={ options.other_specify }
                  />
                </div>
              </div>
              <div className="form_section">
                <SectionHeader
                  title="Brand Evaluation"
                  infoText="These questions will be shown to those aware of the Target Brand. Some questions will only be asked to Brand Users."
                />
                <div className="form_metrics -md">
                  <Single
                    model={ brandAppeal }
                    setModel={ setBrandAppeal }
                    img={ details51 }
                    infoText="Those aware of the Target Brand will indicate how much they like it."
                  />
                  <Single
                    model={ brandValue }
                    setModel={ setBrandValue }
                    img={ details52 }
                    infoText="Those aware of the Target Brand will indicate whether the Target Brand's products/services are a good value."
                  />
                  <Single
                    model={ brandSatisfaction }
                    setModel={ setBrandSatisfaction }
                    img={ details53 }
                    infoText="Brand Users will indicate how satisfied they are with their experiences using the Target Brand."
                  />
                  <GridProductUsage
                    model={ brandProductUsage }
                    setModel={ setBrandProductUsage }
                    secondModel={ productSatisfaction }
                    setSecondModel={ setProductSatisfaction }
                    max={ options.max_pu }
                    otherSpecify={ options.other_specify }
                  />
                  <Single
                    model={ nps }
                    setModel={ setNps }
                    text="NPS / Likelihood to Recommend"
                    infoText="NPS stands for Net Promoter Score. This is a standard score used for determining your customer's likelihood to recommend a brand. It is used to measure satisfaction with the Brand experience."
                  />
                </div>
              </div>
              <div className="form_section -sm-space -padding-bottom">
                <DiagnosticsText />
                <div className="form_metrics -md">
                  <Field
                    className="tb"
                    bool={ model.tb }
                    label="Thought Bubble"
                    text="With Thought Bubble, respondents provide words to describe the brand in third party."
                    onChange={ (v) => { setModel({ ...model, tb: v }); } }
                  />
                  <Field
                    className="reasons"
                    bool={ model.reasons }
                    label="Reasons for Not Using"
                    text="Brand non-users will indicate why they do not use the brand."
                    onChange={ (v) => { setModel({ ...model, reasons: v }); } }
                  />
                </div>
              </div>
              <div className="form_section -sm-space -padding-bottom -max-screener-and-custom-metrics-width">
                <CustomQuestionsHeader
                  max={ options.max_custom_questions }
                  onAdd={ addCustomQuestion }
                  disabled={ filteredList.length >= options.max_custom_questions }
                />
              </div>
              {
                filteredList.length > 1 &&
                <div className="form_section-title-item -order-button">
                  {
                    <button className="button -secondary -white-back -blue" onClick={ onOpenQuestionsOrderModal } >
                      Change order
                    </button>
                  }
                </div>
              }
              <QuestionsContext.Provider value={ questionsContextValue }>
                <QuestionsList
                  options={ options }
                  questions={ customQuestions }
                  setQuestions={ setCustomQuestions }
                  images={ images }
                />
              </QuestionsContext.Provider>
            </div>
          </div>
        </div>
      </div>
      {
        !!questionsOrderData &&
        <QuestionsOrderModal
          questions={ questionsOrderData }
          setQuestionsOrderData={ setQuestionsOrderData }
          onSave={ setCustomQuestions }
        />
      }
    </WithSubmit>
  );
};

export default App;
