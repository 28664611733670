import React, { useState } from 'react';
import Field from '../../common/form/field';
import Checkbox from '../../common/form/checkbox';
import Button from '../../common/form/button';
import Modal from '../../../../common/components/modal';
import { sendLogInRequest } from '../requests';

const SignIn = ({ activated, onRestorePasswordClick, onClose }) => {
  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ rememberMe, setRememberMe ] = useState(false);

  const handleLogIn = (event) => {
    event.preventDefault();

    sendLogInRequest(email, password, rememberMe, (response) => {
      if (response.response.ok) {
        window.location.href = response.data.redirect_url;
      } else {
        setError(response.data.error || "Invalid email or password.");
      }
    });
  };

  const handleRestorePassword = (event) => {
    event.preventDefault();
    onRestorePasswordClick(event);
  };

  return (
    <Modal
      modal
      modalSize="sm"
      showCross={ !!onClose }
      onClose={ onClose }
    >
      <div className="modal_header">
        <div className="modal_title">
          Sign in
        </div>
      </div>
      <div className="modal_body">
        <form className="form">
          <div className="form_section">
            {
              activated && !error &&
              <p>
                Your account has been activated,
                { ' ' }
                please enter your credentials to sign in.
              </p>
            }
            {
              !!error &&
              <div className="form_group">
                <div className="form-error">
                  { error }
                </div>
              </div>
            }
            <Field
              title="Email"
              type="text"
              value={ email }
              onChange={ setEmail }
            />
            <Field
              title="Password"
              type="password"
              value={ password }
              onChange={ setPassword }
            />
          </div>
          <div className="form_section">
            <div className="form_buttons -grid">
              <div className="form_buttons-item -grow">
                <Button
                  className="-block"
                  title="Sign In"
                  onClick={ handleLogIn }
                />
              </div>
              <div className="form_buttons-item">
                <Checkbox
                  title="Remember me"
                  value={ rememberMe }
                  onChange={ setRememberMe }
                />
              </div>
            </div>
            <div className="form_buttons -center">
              <a href="#" onClick={ handleRestorePassword }>
                I forgot my password
              </a>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SignIn;
