import React from 'react';
import ThoughtBubble from '../../common/components/metrics/thought_bubble.js';
import FivePointScale from '../../common/components/metrics/five_point_scale.js';
import Markup from '../../common/components/metrics/markup.js';
import AttributeRatings from '../../common/components/metrics/attribute_ratings.js';
import { defaultScorings } from "../../common/logic/eligible";
import Intro from './concept/intro.js';

const Concept = ({
  data, concept, index,
  conceptIndex, nextStep,
  monadic, result
}) => {
  const { markup_tool, attribute_ratings, language_code, translations } = data;

  const scorings = defaultScorings(data.scorings);

  const tbKlass = data.tb_klass;

  const introText = (conceptIndex) => {
    if (monadic) {
      return translations.concept_monadic_intro;
    }

    if (!conceptIndex) {
      return translations.concept_sequential_intro_first;
    } else if (data.concepts.length - 1 === conceptIndex) {
      return translations.concept_sequential_intro_last;
    }
    return translations.concept_sequential_intro_next;
  };

  const maleSelected = () => (result.answers.findIndex("S3_1") > -1);

  return (
    <>
      {
        !index &&
        <Intro text={ introText(conceptIndex) } url={ concept.url } nextStep={ () => { nextStep(); } } /> }
      {
        index === 1 &&
        <ThoughtBubble
          concept={ concept }
          tbKlass={ tbKlass }
          title={ translations.thought_bubble_title }
          description={ translations.thought_bubble_description }
          showMan={ maleSelected() }
          result={ result }
          nextStep={ () => { nextStep(); } }
        />
      }
      {
        index === 2 &&
        <FivePointScale
          concept={ concept }
          questions={ scorings }
          result={ result }
          nextStep={ () => { nextStep(); } }
        />
      }
      {
        index === 3 &&
        <Markup
          conceptIds={ [ concept.focus_concept_id ] }
          markupTool={ markup_tool }
          nextStep={ () => { nextStep(); } }
          result={ result }
        />
      }
      {
        index === 4 &&
        <AttributeRatings
          title={ translations.attribute_ratings_title }
          concept={ concept }
          questions={ attribute_ratings }
          result={ result }
          nextStep={ () => { nextStep(); } }
          languageCode={ language_code }
        />
      }
    </>
  );
};

export default Concept;
