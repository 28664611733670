import React from "react";
import Button from "./Button";
import VideoPreview from "./VideoPreview";

const VideosList = ({ videos, showViewAll = true }) => (
  <div className="videos-list">
    {videos.map((video, i) => (
      <div className="videos-list_item" key={i}>
        <VideoPreview {...video} />
      </div>
    ))}
    {showViewAll && <Button variant="link">View all</Button>}
  </div>
);

export default VideosList;
