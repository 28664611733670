import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import PageLoader from "../../common/PageLoader";
import Single from "../../common/Single";
import CMList from "../../custom_metrics/app";
import HiddenFields from "./HiddenFields";

const App = ({ data, options, errors }) => {
  const [ singles, setSingles ] = useState(data.express_metrics);

  const isFirstRun = useRef(true);
  const checkSum = useRef('');
  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);
  const metricsHasChanges = useRef(false);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      checkSum.current = JSON.stringify([ singles ]);
    } else {
      const checkSumNew = JSON.stringify([ singles ]);
      hasChanges.current = initHasChanges.current || checkSum.current !== checkSumNew;
    }
  }, [ singles ]);

  const setSingleModel =  (v, index) => {
    singles[index] = v;
    setSingles([ ...singles ]);
  };

  return (
    <WithSubmit
      formId="form"
      errors={ errors }
      hasChangesFunc={ () => ({ current: hasChanges.current || metricsHasChanges.current }) }
      options={ options }
    >
      <HiddenFields
        options={ options }
        singles={ singles }
      />
      <div className="content -relative -grey-background">
        <Header title={ "Setting Up Details (CAT)" } />
        <div className="content_body">
          <div className="form">
            <div className="form_group -relative">
              {!!options.jid && <PageLoader options={ options } />}

              <div className="form_section">
                {singles.map((single, index) => (
                  <div className="form_metrics -md" key={ index }>
                    <Single
                      model={ single }
                      setModel={ (v) => { setSingleModel(v, index); } }
                      infoText="Those aware of the Target Brand will indicate how much they like it."
                    />
                  </div>
                ))}
              </div>

              <CMList
                data={ data }
                options={ options.metrics_options }
                grid_data={ options.metrics_grid_data }
                images={ options.metrics_images }
                metricsHasChanges={ metricsHasChanges }
                platform="verizon"
              />
            </div>
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
