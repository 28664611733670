import React, { useState, useEffect } from "react";
import { StateContext } from "../common/contexts";
import { Router,  initialState } from '../unlimited_common/router';
import { plans, allPlansInclude } from "./plans_data";

const ConceptUnlimitedBAU = () => {
  const [ path, updatePath ] = useState(initialState);
  useEffect(() => {
    const main = document.querySelector(".main-content");
    main.className = `${main.className} bau-concept`;
  }, []);
  return (
    <div className="top-split-div">
      <StateContext.Provider value={ [ path, updatePath ] }>
        <Router plans={ plans } allPlansInclude={ allPlansInclude } />
      </StateContext.Provider>
    </div>
  );
};

export default ConceptUnlimitedBAU;
