import React, { useState, useContext } from 'react';
import Header from '../common/header';
import QuantPortal from '../common/portal';
import PlanDetailsModal from '../common/planDetatailsModal';
import { 
  plansIncludes,
  planDetailsShort,
  allPlansInclude,
  getLargePlanDescription,
  perkList
} from './plansData';
import Statistic from '../../statistic';
import { LinesDataContext } from './contexts';
import { PerkList } from '../common/perkList';
import { PlanCard } from '../common/planCard';

const PerkListModal = ({ line, open, handleClose }) => {
  const handleDetailsClick = (idx) => {
    Statistic.updateValue(`lines.${line}.perks.${idx}.PlansListDetailsCount`);
  };
  return <QuantPortal title="Available bonus perks" open={open} handleClose={handleClose}>
    <div className="perk-wrapper">
      <PerkList list={perkList} onDetailsClick={handleDetailsClick} />
    </div>
  </QuantPortal>
};

const PlansInsideModal = ({ open, handleClose }) => {
  const { title, description } = allPlansInclude;
  return <QuantPortal title={title} open={open} handleClose={handleClose}>
    {description.map(([title, value], idx) =>
      <div key={idx} className="plans-include-details-group">
        <h6 className="plans-include-details-subtitle">{title}</h6>
        <p className="plans-include-details-value">{value}</p>
      </div>)}
  </QuantPortal>;
};

const renderPlans = (includes, idx) =>
  <li className="plans-include-details-list-item" key={idx}>{includes}</li>;

const PlansInclude = () => {
  const [open, setOpen] = useState(false);
  const { filledLines } = useContext(LinesDataContext);
  const handleOpen = () => {
    Statistic.updateValue(`lines.${filledLines}.viewDetailsCount`);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return <div>
    <div className="plans-include-details-head">
      <h6 className="plans-include-details-title">
        All plans include
      </h6>
      <button 
        type="button" 
        className="details-link" 
        onClick={handleOpen}>
          View details
        </button>
    </div>
    <ul className="plans-include-details-list">
      {plansIncludes.map(renderPlans)}
    </ul>
    <PlansInsideModal open={open} handleClose={handleClose}/>
  </div>
};

const renderPlansList = ({ onSelect, onShowDetails, onShowPerkList }) => (plan, idx) => {
  const showDetails = (id) => onShowDetails(id, idx);
  const select = () => onSelect(plan, idx);
  return <li
    key={plan.id}
    className="choose-plan-card-item">
    <PlanCard
      plan={plan}
      isAcardionStatic={true}
      renderChildrenInAccordion={false}
      handleOpenChange={() =>{}}
      onShowDetails={showDetails}>
      <div className="select-button-wrapper">
        {plan.perkCount > 0 && <>
          <div className="hr" style={{ marginBottom: 20 }} />
          <div className="perks-selector">
            <span className="perks-selector-text">
              {`${plan.perkCount} Bonus perk${plan.perkCount > 1 ? 's' : ''} of your choice`}
            </span>
            <button
              style={{ width: 'auto' }}
              type="button"
              className="details-link"
              onClick={() => onShowPerkList(idx)}>
              View perks
            </button>
          </div>
        </>}
        <button onClick={select} className="button -bordered" type="button">
          {plan.perkCount === 0 ? 'Choose plan' : 'Customize this plan'}
        </button>
      </div>
    </PlanCard>
  </li>
}

const PlansList = (props) => <ul className="choose-plan-card-list">
  { planDetailsShort.map(renderPlansList(props)) }
</ul>;

const PlanDetailsModalWrapper = ({ planId, handleClose }) => {
  const plan = getLargePlanDescription(planId);
  return plan 
    ? <PlanDetailsModal 
        plan={{...plan, name: `${plan.name} details`}}
        open={!!planId}
        handleClose={handleClose} />
    : null
};

const ChooseLinePlan = ({ onContinue }) => {
  const { filledLines } = useContext(LinesDataContext);
  const [planId, setPlanId] = useState(null);
  const handeClese = () => { setPlanId(null); };
  const handleOpen = (id, idx) => { 
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.PlanDetailsCount`);
    setPlanId(id);
  };
  const handleSelect = (plan, idx) => {
    const { name, price } = plan;
    Statistic.updateValue(`lines.${filledLines}.selectedPlanName`, name);
    Statistic.updateValue(`lines.${filledLines}.selectedPlanPrice`, price);
    Statistic.updateValue(`lines.${filledLines}.plans.${idx}.Selected`);
    onContinue(plan);
  };
  const [planIndex, setPlanIndex] = useState(undefined);
  const handleShowPerkList = (planIdx) => {
    Statistic.updateValue(`lines.${filledLines}.plans.${planIdx}.PerksListInListCount`);
    setPlanIndex(planIdx);
  };
  const handleHideperkList = () => {
    setPlanIndex(undefined);
  };
  return <div className="layout">
    <Header/>
    <h6 className="choose-line-plane-title">Choose a plan for Line {filledLines + 1}</h6>
    <div className="choose-line-plane content">
      <PlansInclude/>
      <PlansList
        onSelect={handleSelect}
        onShowDetails={handleOpen}
        onShowPerkList={handleShowPerkList} />
      <PlanDetailsModalWrapper planId={planId} handleClose={handeClese} />
      <PerkListModal
        line={filledLines}
        open={planIndex !== undefined}
        handleClose={handleHideperkList} />
    </div>
  </div>;
};

export default ChooseLinePlan;
