import React from 'react';
import Modal from '../../../../common/components/modal';
import SvgIcon from "../../../../common/components/svg-icon";
import Concept from "./concept";

const ConceptTagsPopup = ({ concept, onClose }) => {
  return (
    <Modal
      modalSize="md3"
      modalClassName="-left-0"
      backClassName="-full-screen"
    >
      <div className="modal-header -auto-height">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ onClose }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="concepts-database_modal_title">
          { concept.name }
        </h3>
      </div>
      <div className="concepts-database_modal_subtitle">
        <p>{ concept.project_title }</p>
      </div>
      <div className="modal_body -relative">
        <Concept
          concept={ concept }
          onClosePopup={ onClose }
        />
      </div>
    </Modal>
  );
};

export default ConceptTagsPopup;
