import React, { useEffect, useState, Fragment } from 'react';
import { map, remove, filter, find, includes } from 'lodash';

let onEnterState = false;

const Filters = (props) => {

  const subgroups = props.subgroups || [],
        options = props.options,
        onUpdate = props.onUpdate;

  const [inputFocus, updateInputFocus] = useState(false);
  const [search, updateSearch] = useState("");
  const [data, updateData] = useState({ options: options });

  const onSelect = (event, subgroup) => {
    data.options.push(subgroup.id);
    updateData({ options: data.options });
    updateSearch("");
    updateInputFocus(false);
    onUpdate(event);
  };

  const onDelete = (event, option) => {
    event.preventDefault();
    remove(data.options, (item) => item == option);
    updateData({ options: data.options });
    onUpdate(event);
  };

  const filteredSubgroups = (searchStr) => {
    const filteredBySelected = filter(subgroups, (item) => !includes(data.options, item.id));
    if (!searchStr) return filteredBySelected;

    const filteredString = searchStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    return filter(filteredBySelected, (item) => item.label.toLowerCase().match(new RegExp(filteredString)) );
  };

  const setOnEnter = (event) => { onEnterState = true; };

  const setOnLeave = (event) => { onEnterState = false; };

  const onInputFocus = (event) =>{ updateInputFocus(true); };
  const onInputBlur = (event) =>{ if (!onEnterState) updateInputFocus(false); };

  const onUpdateSearch = (event) => {
    updateSearch(event.target.value);
  };

  const filteredSubgroupsList = filteredSubgroups(search);

  return (
    <div className="combined-tags">
      <div className="host">
        <div className="tags">
          {
            (
              <ul className="tag-list">
                {
                  map(data.options, (option, index) => {
                    const tagTitle = find(subgroups, (item) => item.id == option).label;
                    return(
                      <li className="tag-item" key={ `filter${index}` }>
                        <span>{ tagTitle }</span>
                        <a className="remove-button" href="#" onClick={ (event) => { onDelete(event, option); } }>x</a>
                      </li>
                    )
                  })
                }
                <li className="tag-item-null">
                  <input
                    type="text"
                    placeholder="Select Subgroups..."
                    onFocus={ onInputFocus }
                    onBlur={ onInputBlur }
                    value={ search }
                    onChange={ onUpdateSearch }
                  />
                </li>
              </ul>
            )
          }
        </div>
        {
          inputFocus && !!filteredSubgroupsList.length &&
          <div className="autocomplete" onMouseEnter={ setOnEnter } onMouseLeave={ setOnLeave }>
            <ul className="suggestion-list">
              {
                map(filteredSubgroupsList, (subgroup, index) => {
                  return(
                    <li className="suggestion-item" key={ `suggested${index}` } onClick={ (event) => { onSelect(event, subgroup); } }>
                      <span>{ subgroup.label }</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        }
      </div>
    </div>
  );
};

export default Filters;