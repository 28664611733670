import React, { useContext, useState } from 'react';
import { StateContext, LineDataContext, SetContext } from '../../common/contexts';
import Lines from '../../common/lines';
import Checkout from '../../common/checkout';
import {
  setLinesCount, getLinesCount,
  setCurrentLine, getCurrentLine,
  getSelectedPlans, getTotal,
  setPlan, sendData, updateHashByKey, setHashData
} from '../../common/statistics';
import Page from '../../common/page';
import Loader from '../../common/loader';
import ComparePage from './compare_page';
import OverviewPage from './overview_page';

const scrollTop = () => {
  document.querySelector('html').scrollTop = 0;
};

const states = [
  "Lines",
  "Overview",
  "Compare",
  "Checkout"
];
const initialState = states[0];

const Route = ({ path, children }) => {
  const [ currentState ] = useContext(StateContext);
  return (
    <>{ path === currentState ? children : null }</>
  );
};

const Router = ({ plans, allPlansInclude }) => {
  const [ , updatePath ] = useContext(StateContext);
  const [ planStartTime, setPlanStartTime ] = useState(null);
  const { delayAnimation } = useContext(SetContext);
  const [ loading, setLoading ] = useState(false);
  const data = {
    plans,
    allPlansInclude,
    setLoading
  };

  const [ isReselect, setReselect ] = useState(false);
  // this state is need to update value in subheader after plan select slick with delay animation
  const [ updatedCurrentLine, setUpdatedCurrentLine ] = useState(getCurrentLine());

  const handleLinesNextStep = (event, linesCount) => {
    setPlanStartTime(new Date());
    updatePath("Overview");
    setLinesCount(linesCount);
    setCurrentLine(1);
  };

  const handlePlanSelect = (event, planObject, source, path) => {
    const timeAfterStart = Math.ceil(
      new Date(new Date() - planStartTime - delayAnimation).getTime() / 1000
    );
    setHashData(`lines.${getCurrentLine()}.planSelectTime`, timeAfterStart);
    setPlanStartTime(new Date());

    setPlan(planObject, getCurrentLine(), source, path, !isReselect);
    if (isReselect || (getCurrentLine() === getLinesCount())) {
      updatePath("Checkout");
    } else {
      setUpdatedCurrentLine(getCurrentLine() + 1);
      setCurrentLine(getCurrentLine() + 1);
      updatePath("Overview");
    }
    scrollTop();
  };

  const handlePlanReselect = (clickedLine) => {
    setPlanStartTime(new Date());
    updatePath("Overview");
    setCurrentLine(clickedLine);
    setUpdatedCurrentLine(clickedLine);
    setReselect(true);
  };

  return (
    <LineDataContext.Provider value={ data }>

      <Route path="Lines">
        <Lines nextStep={ handleLinesNextStep } />
      </Route>

      <Route path="Overview">
        <Page>
          <OverviewPage
            updatedCurrentLine={ updatedCurrentLine }
            onPlanSelect={ (event, planObject) => {
              handlePlanSelect(event, planObject, "overview", "Overview");
            } }
            toCompare={ () => {
              updateHashByKey(`lines.${getCurrentLine()}.compareClicked`);
              updatePath("Compare");
              scrollTop();
            } }
          />
        </Page>
      </Route>

      <Route path="Compare">
        <Page>
          <ComparePage
            updatedCurrentLine={ updatedCurrentLine }
            onPlanSelect={ (event, planObject) => {
              handlePlanSelect(event, planObject, "compare", "Compare");
            } }
            toOverview={ () => {
              updatePath("Overview");
              scrollTop();
            } }
          />
        </Page>
      </Route>

      <Route path="Checkout">
        <Checkout
          currentLine={ getCurrentLine() }
          selectedLines={ getLinesCount() }
          selectedPlans={ getSelectedPlans() }
          total={ getTotal() }
          nextStep={ (currentLine, isFinal) => {
            if (isFinal || isReselect) {
              sendData();
              updatePath("");
            }
          } }
          reselectPlan={ handlePlanReselect }
        />
      </Route>

      { loading && <Loader /> }

    </LineDataContext.Provider>
  );
};

export { Router, initialState };
