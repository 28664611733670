import React, { useContext } from 'react';
import classnames from 'classnames';
import { includes } from 'lodash';
import { AppContext } from '../contexts';

const EditStatus = () => {
  const {
    project, setProject, availableStatuses,
    globalDisabled, setNotificationObject, sendRequest, paths
  } = useContext(AppContext);
  const { status_name } = project;
  const updatePath = paths.status_update_path;
  const activeText = availableStatuses[status_name];
  const canPause = includes([ 'in_field' ], status_name);
  const pauseDisabled = status_name === 'closed' || globalDisabled;
  const stopDisabled = status_name === 'closed' || globalDisabled;

  const handleSendRequest = (newStatus, goodMessage) => {
    const prodege = project.panel_service === 'prodege';
    sendRequest(updatePath, { status: newStatus }, (response) => {
      setProject(response.data.projects);
      const complexMessage = [ goodMessage ];
      if (!prodege) {
        complexMessage.push('Please update the project status in the Custom Provider Dashboard.');
      }
      setNotificationObject({ message: complexMessage.join(' '), success: true });
    }, (response) => {
      setNotificationObject({
        message: response?.data?.error || `Something went wrong, please try again later.`,
        success: false
      });
    });
  };

  const handlePausePlay = () => {
    const newStatus = canPause ? 'pause' : 'resume';
    if (window.confirm(`Do you really want to ${newStatus} this project?`)) {
      handleSendRequest(newStatus, `Survey status is updated to ${newStatus}.`);
    }
  };
  const handleStop = () => {
    if (window.confirm("Do you really want to stop this project? Target number will automatically updated with the number of completes.")) {
      const newStatus = 'stop';
      handleSendRequest(newStatus, `Successfully stopped the survey.`);
    }
  };

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full edit-info-item-external">
          <h3 className="form_section-title">
            Express Project Status
          </h3>
          <div className="form-group">
            <div className="info-panel-note">
              {
                status_name === 'in_field' &&
                <p>
                  Survey is opened for respondents to come and pass it.
                  <br />
                  <b>Make sure the project is live in the Panel Service.</b>
                </p>
              }
              {
                status_name !== 'in_field' &&
                <p>
                  Survey is closed for respondents, they are not able to open it and pass it.
                  <br />
                  <b>
                    Make sure the project is {status_name === 'paused' ? 'paused' : 'stopped'}
                    {' '}
                    in the Panel Service.
                  </b>
                </p>
              }
            </div>
            <div className="active-status">
              <button
                className={
                  classnames(
                    "project-preview_status -active",
                    `-${status_name}`
                  )
                }
              >
                { activeText }
              </button>
              <div className="update-buttons">
                <button
                  className={
                    classnames(
                      "button pause-play survey-flow-button",
                      `-${canPause ? 'pause' : 'resume'
                      }`
                    )
                  }
                  onClick={ handlePausePlay }
                  disabled={ pauseDisabled }
                >
                  { canPause ? 'Pause' : 'Resume' }
                </button>
                <button
                  className="button -stop survey-flow-button"
                  onClick={ handleStop }
                  disabled={ stopDisabled }
                >
                  Stop
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStatus;
