import React, { Fragment, useContext } from "react";
import { map } from "lodash";
import { DataContext } from "./data-context";

const HiddenFields = () => {
  const {
    cartExercise, cartWithPrices, cartWithLimit,
    cartLimit, cartRanking, options, shelves
  } = useContext(DataContext);

  return (
    <>
      <input type="hidden" name="step" value={ options.step } />
      <input type="hidden" name="project[cart_exercise]" value={ cartExercise || 'false' } />
      <input type="hidden" name="project[cart_with_prices]" value={ cartWithPrices || 'false' } />
      <input type="hidden" name="project[cart_ranking]" value={ cartRanking || 'false' } />
      <input type="hidden" name="project[cart_with_limit]" value={ cartWithLimit || 'false' } />
      <input type="hidden" name="project[cart_limit]" value={ cartLimit || '' } />

      {map(shelves, (shelf, index) => (
        <ShelfHiddenFields key={ index } shelf={ shelf } />
      ))}
    </>
  );
};

const PlaceHiddenFields = ({ place }) => {
  const prefix = 'project[express_images][][cart_shelves][][cart_products][][places][]';
  return (
    <Fragment>
      <input type="hidden" name={ `${prefix}[id]` } value={ place.id || '' } />
      <input type="hidden" name={ `${prefix}[area_width]` } value={ place.area_width } />
      <input type="hidden" name={ `${prefix}[area_height]` } value={ place.area_height } />
      <input type="hidden" name={ `${prefix}[area_left]` } value={ place.area_left } />
      <input type="hidden" name={ `${prefix}[area_top]` } value={ place.area_top } />
      <input type="hidden" name={ `${prefix}[image_width]` } value={ place.image_width } />
      <input type="hidden" name={ `${prefix}[image_height]` } value={ place.image_height } />
      <input type="hidden" name={ `${prefix}[_destroy]` } value={ place._destroy || '' } />
    </Fragment>
  );
};

const ProductHiddenFields = ({ product }) => {
  const prefix = 'project[express_images][][cart_shelves][][cart_products][]';
  return (
    <Fragment>
      <input type="hidden" name={ `${prefix}[id]` } value={ product.id || '' } />
      <input type="hidden" name={ `${prefix}[name]` } value={ product.name || '' } />
      <input type="hidden" name={ `${prefix}[price]` } value={ product.price || '' } />
      <input type="hidden" name={ `${prefix}[image_id]` } value={ product.image_id || '' } />
      <input type="hidden" name={ `${prefix}[product_image_data]` } value={ product.product_image_data || '' } />
      <input type="hidden" name={ `${prefix}[target]` } value={ product.target || 'false' } />
      <input type="hidden" name={ `${prefix}[_destroy]` } value={ product._destroy || '' } />
      {map(product.places, (place, placeIndex) => (
        <PlaceHiddenFields key={ placeIndex } place={ place } />
      ))}
    </Fragment>
  );
};

const ShelfHiddenFields = ({ shelf }) => {
  const prefix = 'project[express_images][]';
  return (
    <Fragment>
      <input type="hidden" name={ `${prefix}[id]` } value={ shelf.image_id || '' } />
      <input type="hidden" name={ `${prefix}[cart_shelves][][id]` } value={ shelf.id || '' } />
      <input type="hidden" name={ `${prefix}[cart_shelves][][image_id]` } value={ shelf.image_id || '' } />
      <input type="hidden" name={ `${prefix}[cart_shelves][][shelf_image_data]` } value={ shelf.shelf_image_data || '' } />
      {map(shelf.cart_products, (product, index) => (
        <ProductHiddenFields key={ index } product={ product } />
      ))}
    </Fragment>
  );
};

export default HiddenFields;
