import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import cn from "classnames";
import { getMetricKind, IndexData } from "../../../common/index-context";
import StatusCircle from "../../../common/components/status-circle";
import { hideCustom, n0, showedAverageValue } from '../../../common/helpers';

const Header = ({ text }) => {
  return (
    <thead>
      <tr>
        <th className="table-comparison_name-cell" colSpan="2">
          <div className="table-comparison_title">
            { text }
          </div>
        </th>
        <th className="table-comparison_average-cell">Avg</th>
      </tr>
    </thead>
  );
};

const ValuesRow = ({ title, current, averageMode, init, data }) => {
  const metric = data.analytics[title];

  if (!metric) {
    return (null);
  }

  return (
    <tr>
      <td className="table-comparison_name-cell">{title}</td>
      <td>
        <StatusCircle values={ metric[current.id] } box="top2" />
      </td>
      <td className="table-comparison_average-cell">
        { showedAverageValue(metric, averageMode, false, init.isInternal, data.view) }
      </td>
    </tr>
  );
};

const TableLeftRight = ({ current, groupName }) => {
  const { init, data, averageMode } = useContext(IndexData);

  return (
    <table className="table table-comparison -long-first-cell" >
      <Header text={ getMetricKind(data, groupName) } />
      <tbody>
        {
          map(data.left_right_answers, (answerInfo) => (
            <ValuesRow
              key={ answerInfo.key }
              title={ answerInfo.title }
              current={ current }
              averageMode={ averageMode }
              init={ init }
              data={ data }
            />
          ))
        }
      </tbody>
    </table>
  );
};

export default TableLeftRight;
