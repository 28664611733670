import React from 'react';
import renderRawHtml from '../../../../common/render_raw_html.js';
import StartButton from './start_button.js';

const Intro = ({ nextStep, title, description, children }) => {
  return (
    <div className="survey-layout_content -start">
      <div className="survey-start">
        {
          !!title &&
          <h1 className="survey-start_title" { ...renderRawHtml(title) } />
        }
        {
          !!description &&
          <div className="survey-start_description" { ...renderRawHtml(description) } />
        }
        {
          !!children &&
          <div className="survey-start_description">{ children }</div>
        }
        <StartButton nextStep={ () => { nextStep(); } } />
      </div>
    </div>
  );
};

export default Intro;
