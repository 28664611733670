import React from 'react';
import StickyTip from '../common/stickyTip';
import BestPerks from '../common/bestPerks';
import Card from '../common/card';
import { PerkList } from '../common/perkList';
import { planList, bestPerksLanding, perkList } from './plansData';
import Statistic from "../../statistic";

const renderPlansList = () => ({ id, name, price, shortDescription, perkCount }) => {
  return <li key={id} className="billing-plans-list-item -mvp">
    <Card className="-landing">
      <div className="billing-plan-title">
        <span className="billing-plan-title_left">{name}</span>
        <span>${price}</span>
      </div>
      <p className="billing-plan-description">{shortDescription}</p>
      {perkCount > 0 && (
        <div className="billing-plan-perks-info -mvp">
          {`+${perkCount} Bonus perk${perkCount > 1 ? 's' : ''} of your choice`}
        </div>
      )}
    </Card>
  </li>
}

const BillingPlansList = () => {
  return <div className="billing-plans -add">
    <h6 className="billing-plans-title -add">
      Choose an unlimited plan.
    </h6>
    <ul className="billing-plans-list -add">
      {planList.map(renderPlansList())}
    </ul>
  </div>;
}

const PerksList = () => {
  const handleDetailsClick = (idx) => {
    Statistic.updateValue(`perks.${idx}.LandingDetailsCount`);
  };
  return <div className="billing-plans -add">
    <h6 className="billing-plans-title -add">
      Bonus perks available on Get More Unlimited:
    </h6>
    <PerkList list={perkList} onDetailsClick={handleDetailsClick} />
  </div>;
}

const Landing = ({ onContinue }) => {
  return <div className="layout ">
    <StickyTip className="-add" />
    <div className="landing content -paddings -add">
      <BestPerks featuresList={bestPerksLanding} modificator="-mvp"
                 title={"Choose an Unlimited plan or\ncustomize your own."}/>
      <BillingPlansList />
      <PerksList />
      <footer>
        <button onClick={onContinue} className="button" type="button">Get started</button>
      </footer>
    </div>
  </div>
};

export default Landing;
