import React from 'react';
import classnames from 'classnames';

const PlateOptions = ({ mode, setMode }) => {
  return (
    <div className="row-plate-options">
      <div className="static-text ng-binding">Sort by: newly added</div>

      <div className="mode-buttons">
        <input id="projects-view-cards" type="radio" value="cards" />
        <label
          htmlFor="projects-view-cards"
          className={ classnames({ '-active': mode === 'cards' }) }
          onClick={ () => {
            setMode('cards');
          } }
        >
          <svg className="icon -burger">
            <use xlinkHref="#svg_squares" />
          </svg>
          Grid
        </label>
        <input id="projects-view-list" type="radio" value="list" />
        <label
          htmlFor="projects-view-list"
          className={ classnames({ '-active': mode === 'list' }) }
          onClick={ () => {
            setMode('list');
          } }
        >
          <svg className="icon -burger">
            <use xlinkHref="#svg_burger" />
          </svg>
          List
        </label>
      </div>
    </div>
  );
};

export default PlateOptions;
