import { map } from 'lodash';
import { getLinesCount } from "../statistics";

const pricing = [
  [ 55, 80, 105,  130, 155],
  [ 65, 90, 115,  140, 165]
];

const five = {
  id: 1,
  name: "5GB",
  title: "5<span class=\"name-part\">GB</span>",
  description: "You want to send some emails, post on social and stream a little music.",
  features: [
    { title: "5 GB Premium 5G Nationwide / 4G LTE", description: "" },
    { title: "Unlimited Talk & Text", description: "Talk and text as much (or as little) as you want." },
    {
      title: "Carryover Data",
      description: "Keep your unused gigs until the end of the following month.",
      popupIcon: true
    },
    {
      title: "Safety Mode to avoid data overages",
      description: "Stay online without worrying about overage fees, keep using data at reduced speeds.",
      popupIcon: true
    },
    { title: "Data Boost $15/1 GB", description: "If you're running out of data or have used your entire allowance, you can purchase a Data Boost, which adds additional high-speed data to your account." },
    {
      title: "Verizon Up Rewards",
      description: "With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.",
      big: true,
      popupIcon: true
    }
  ]
};

const fifteen = {
  id: 2,
  name: "10GB",
  title: "10<span class=\"name-part\">GB</span>",
  description: "You want more time online to stream video or share data with your family.",
  best: true,
  features: [
    { title: "10 GB Premium 5G Nationwide / 4G LTE", description: "" },
    { title: "Unlimited Talk & Text", description: "Talk and text as much (or as little) as you want." },
    {
      title: "Carryover Data",
      description: "Keep your unused gigs until the end of the following month.",
      popupIcon: true
    },
    {
      title: "Safety Mode to avoid data overages",
      description: "Stay online without worrying about overage fees, keep using data at reduced speeds.",
      popupIcon: true
    },
    { title: "Data Boost $15/1 GB", description: "If you're running out of data or have used your entire allowance, you can purchase a Data Boost, which adds additional high-speed data to your account." },
    {
      title: "Verizon Up Rewards",
      description: "With Verizon Up, you simply get more. More rewards. More access. More choices. Every month and then some. All to just say thanks for being with us.",
      popupIcon: true
    }
  ]
};

const sharedPlans = map([five, fifteen], (el) =>
  ({
    ...el,
    get price() { return pricing[el.id - 1][getLinesCount() - 1]; }
  })
);

export { sharedPlans };