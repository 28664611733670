import React from "react";
import Avatar from "./Avatar";
import BrandUnderlineLogo from "./BrandUnderlineLogo";
import Card from "./Card";

const WorkoutCard = ({ workout }) => (
  <Card>
    <div className="workout-card_bg">
      <img src={workout.imgUrl} className="workout-card_image" />
      <div className="workout-card_duration">{workout.duration}</div>
    </div>
    <div className="workout-card_body">
      <div className="workout-card_title">{workout.title}</div>
      <div className="workout-card_info">
        <div className="workout-card_level">{workout.level}</div>
        <div className={`workout-card_dot -bg-${workout.category.color}`} />
        <div className="workout-card_category">{workout.category.title}</div>
      </div>
      <div className="workout-card_divider" />
      <div className="workout-card_footer">
        <div className="workout-card_coach">
          <div className="workout-card_avatar">
            <Avatar {...workout.coach} size="small" />
          </div>
          <div className="workout-card_coach-name">
            {workout.coach.firstName} {workout.coach.lastName}
          </div>
        </div>
        <div className="workout-card_brand">
          <BrandUnderlineLogo {...workout.brand} />
        </div>
      </div>
    </div>
  </Card>
);

export default WorkoutCard;
