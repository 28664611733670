import React from 'react';
import FiosTV from './fios-tv';
import TVConnections from './tv-connections';
import { useAccordionState } from './hooks';

const ConceptC = () => {
  const { 
    open: { fiosTV, tvConnections },
    data: { fiosTVData },
    handleOpen
  } = useAccordionState();
  
  const catFioTVOpen = true;

  const canTVConnectionsOpen = !!fiosTVData;

  return <div className="inner-accordion-list">
    <FiosTV
      open={fiosTV}
      canOpen={catFioTVOpen}
      onChange={() => handleOpen('fiosTV')}
      onNext={() => handleOpen('tvConnections')} />
    <TVConnections
      open={tvConnections}
      canOpen={canTVConnectionsOpen || tvConnections}
      onChange={() => handleOpen('tvConnections')}
      onNext={() => handleOpen('tvConnections')} />
  </div>;
}

export default ConceptC;